/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit,
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSON,
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSONTyped,
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitToJSON
} from './GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanCheckUsageLimitData
 */
export interface GetOrganizationPaymentPlanCheckUsageLimitData {
  /**
   *
   * @type {GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimitData
   */
  usageLimit: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataFromJSON(
  json: any
): GetOrganizationPaymentPlanCheckUsageLimitData {
  return GetOrganizationPaymentPlanCheckUsageLimitDataFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanCheckUsageLimitData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    usageLimit: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSON(json['usage_limit'])
  }
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataToJSON(
  value?: GetOrganizationPaymentPlanCheckUsageLimitData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    usage_limit: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitToJSON(value.usageLimit)
  }
}
