/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Visitor, VisitorFromJSON, VisitorFromJSONTyped, VisitorToJSON } from './Visitor'

/**
 *
 * @export
 * @interface GetVisitorResponseData
 */
export interface GetVisitorResponseData {
  /**
   *
   * @type {Visitor}
   * @memberof GetVisitorResponseData
   */
  visitor?: Visitor
}

export function GetVisitorResponseDataFromJSON(json: any): GetVisitorResponseData {
  return GetVisitorResponseDataFromJSONTyped(json, false)
}

export function GetVisitorResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVisitorResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    visitor: !exists(json, 'visitor') ? undefined : VisitorFromJSON(json['visitor'])
  }
}

export function GetVisitorResponseDataToJSON(value?: GetVisitorResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    visitor: VisitorToJSON(value.visitor)
  }
}
