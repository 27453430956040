import React from 'react'
import { LayoutUser } from 'src/components/commons'
import { UserInfo } from 'src/components/organisms/UserInfo'
import { DynamicMuiIcon, Colors, Pallete } from 'src/components/atoms'
import { Box, Modal, Typography } from '@material-ui/core'
import { useGetMe } from 'src/fixtures/modules/me/hooks'

export const PageUserInfo = () => {
  const { data: me, isLoading } = useGetMe()
  const user = me?.user

  return (
    <>
      <LayoutUser>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          bgcolor={Colors.functional.background.default}
          px="32px"
          overflow="auto"
        >
          <Box display="flex" alignItems="center" pt="16px" pb="24px">
            <DynamicMuiIcon variant="person" />
            <Box width="10px" />
            <Typography variant="h2">ユーザー情報</Typography>
          </Box>
          {user && (
            <UserInfo
              lastName={user.lastName || ''}
              firstName={user.firstName || ''}
              avatar={user.avatar?.url}
              email={user.encryptedEmail!}
              permission={user.permission}
            />
          )}
        </Box>
      </LayoutUser>
      <Modal open={isLoading}>
        <Box
          width={'100vw'}
          height={'100%'}
          position={'absolute'}
          bgcolor={Pallete.functional.background.opacityGray}
        />
      </Modal>
    </>
  )
}
