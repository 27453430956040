/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumUserOperationDisplayObjectItemResourceTypeFromJSON, EnumUserOperationDisplayObjectItemResourceTypeToJSON } from './EnumUserOperationDisplayObjectItemResourceType';
export function UserOperationDisplayObjectItemFromJSON(json) {
    return UserOperationDisplayObjectItemFromJSONTyped(json, false);
}
export function UserOperationDisplayObjectItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        resourceType: !exists(json, 'resource_type')
            ? undefined
            : EnumUserOperationDisplayObjectItemResourceTypeFromJSON(json['resource_type']),
        resourceId: !exists(json, 'resource_id') ? undefined : json['resource_id'],
        text: !exists(json, 'text') ? undefined : json['text']
    };
}
export function UserOperationDisplayObjectItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        resource_type: EnumUserOperationDisplayObjectItemResourceTypeToJSON(value.resourceType),
        resource_id: value.resourceId,
        text: value.text
    };
}
