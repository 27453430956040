/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { InboxItem, InboxItemFromJSON, InboxItemFromJSONTyped, InboxItemToJSON } from './InboxItem'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetInboxesResponseData
 */
export interface GetInboxesResponseData {
  /**
   *
   * @type {Array<InboxItem>}
   * @memberof GetInboxesResponseData
   */
  inboxItems?: Array<InboxItem>
  /**
   *
   * @type {PageInfo}
   * @memberof GetInboxesResponseData
   */
  pageInfo?: PageInfo
}

export function GetInboxesResponseDataFromJSON(json: any): GetInboxesResponseData {
  return GetInboxesResponseDataFromJSONTyped(json, false)
}

export function GetInboxesResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInboxesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    inboxItems: !exists(json, 'inbox_items') ? undefined : (json['inbox_items'] as Array<any>).map(InboxItemFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetInboxesResponseDataToJSON(value?: GetInboxesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    inbox_items: value.inboxItems === undefined ? undefined : (value.inboxItems as Array<any>).map(InboxItemToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
