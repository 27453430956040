import { Box, Typography } from '@material-ui/core'
import { FormikValues, useFormik } from 'formik'
import { useMemo, useEffect, useCallback, useState } from 'react'
import { Button, Input, Colors, DynamicMuiIcon } from 'src/components/atoms'
import { getInputImage } from 'src/fixtures/utils/image'
import { useGlobalStyles } from 'src/styles/theme'

export interface OgpSettingProps {
  title?: string
  description?: string
  imageUrl?: string
  onChange?: (field: string, value: string) => void
  onFileChange?: (file?: File) => void
}

export const OgpSetting = ({
  title = '',
  description = '',
  imageUrl = '',
  onChange,
  onFileChange
}: OgpSettingProps) => {
  const globalClasses = useGlobalStyles()

  const { handleChange, values } = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      title,
      description
    },
    onSubmit: () => {}
  })

  const titleLength = useMemo(() => values.title.length, [values.title])
  const descriptionLength = useMemo(() => values.description.length, [values.description])

  const [image, setImage] = useState<string | null>(null)
  useEffect(() => {
    setImage(imageUrl)
  }, [imageUrl])

  const handleUploadImage = useCallback(async () => {
    const image = await getInputImage()
    setImage(image.base64)
    onFileChange && onFileChange(image.file)
  }, [onFileChange])

  const handleDeleteImage = useCallback(() => {
    setImage('')
    onFileChange && onFileChange(undefined)
  }, [onFileChange])

  return (
    <Box
      border={`1px solid ${Colors.background.silver}`}
      borderRadius="4px"
      bgcolor={Colors.background.lightGray}
      p="10px"
    >
      <Typography variant="h5">OGPの設定</Typography>
      <Box mt="10px" />
      <Typography variant="subtitle2">SNS等に投稿した時の見た目を変更できます</Typography>
      <form>
        <Box display="flex" justifyContent="space-between" alignItems="end" mt="12px" mb="6px">
          <Typography variant="h6">タイトル</Typography>
          <Box color={Colors.base.middleGray}>
            <Typography variant="subtitle2">{titleLength}/100</Typography>
          </Box>
        </Box>
        <Input
          name="title"
          placeholder="タイトルを入力してください"
          fullWidth
          maxLength={100}
          value={values.title}
          onChange={handleChange}
          onBlur={e => onChange && onChange('ogpTitle', e.target.value)}
        />
        <Box display="flex" justifyContent="space-between" alignItems="end" mt="12px" mb="6px">
          <Typography variant="h6">ディスクリプション</Typography>
          <Box color={Colors.base.middleGray}>
            <Typography variant="subtitle2">{descriptionLength}/200</Typography>
          </Box>
        </Box>
        <Input
          name="description"
          multiline
          rows={3}
          placeholder="ディスクリプションを入力してください"
          fullWidth
          maxLength={200}
          value={values.description}
          onChange={handleChange}
          onBlur={e => onChange && onChange('ogpDescription', e.target.value)}
        />
        <Box mt="14px" />
        <Typography variant="h6">画像のアップロード</Typography>
        <Box mt="6px" />
        {image ? (
          <>
            <Box width={1} height="163px" mb="8px">
              <img src={image} alt="team-icon" className={globalClasses.imgContain} />
            </Box>
            <Box display="flex">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                border={`1px solid ${Colors.background.silver}`}
                borderRadius="4px"
                bgcolor={Colors.functional.background.default}
                p="3px"
                mr="12px"
              >
                <DynamicMuiIcon variant="delete" color="action" size="20px" onClick={handleDeleteImage} />
              </Box>
              <Button kind="primary" title="アップロード" size="small" onClick={handleUploadImage} />
            </Box>
          </>
        ) : (
          <Button kind="primary" title="アップロード" size="small" onClick={handleUploadImage} />
        )}
      </form>
    </Box>
  )
}
