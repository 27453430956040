import React, { useCallback, useState } from 'react'
import { Stripe, StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { CardElement, useStripe } from '@stripe/react-stripe-js'
import { Box } from '@material-ui/core'
import { Colors, Typography } from 'src/components/atoms'

export interface CardFormProps {
  onReady?: (stripe: Stripe, cardElement: StripeCardElement) => void
}

const stripeElementStyle = {
  base: {
    color: Colors.base.black,
    fontSize: '14px',
    '::placeholder': {
      color: '#A1A2B0'
    }
  },
  invalid: {
    iconColor: '#ff3860',
    color: '#ff3860'
  }
}

export const CardForm = ({ onReady }: CardFormProps) => {
  const stripe = useStripe()
  const [cardErrorMessage, setCardErrorMessage] = useState('')

  const onReadyForm = useCallback(
    element => {
      if (!stripe) return

      onReady?.(stripe, element)
    },
    [onReady, stripe]
  )

  const onChange = useCallback((event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setCardErrorMessage(event.error.message)
    } else {
      setCardErrorMessage('')
    }
  }, [])

  return (
    <Box>
      <Box
        sx={{
          padding: '10px 12px',
          bgcolor: '#fff',
          border: '1px solid #DADCE0',
          boxShadow: 'inset 0px 1px 2px rgba(10, 10, 10, 0.1)',
          borderRadius: '4px'
        }}
      >
        <CardElement
          options={{ hidePostalCode: true, style: stripeElementStyle }}
          onReady={onReadyForm}
          onChange={onChange}
        />
      </Box>
      {cardErrorMessage && (
        <Box mt="8px" color="#DC3059">
          <Typography fontSize="s">{cardErrorMessage}</Typography>
        </Box>
      )}
    </Box>
  )
}
