/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetLatestRecordPorterHistoryResponseData,
  GetLatestRecordPorterHistoryResponseDataFromJSON,
  GetLatestRecordPorterHistoryResponseDataFromJSONTyped,
  GetLatestRecordPorterHistoryResponseDataToJSON
} from './GetLatestRecordPorterHistoryResponseData'

/**
 *
 * @export
 * @interface GetLatestRecordPorterHistoryResponse
 */
export interface GetLatestRecordPorterHistoryResponse {
  /**
   *
   * @type {number}
   * @memberof GetLatestRecordPorterHistoryResponse
   */
  status?: number
  /**
   *
   * @type {GetLatestRecordPorterHistoryResponseData}
   * @memberof GetLatestRecordPorterHistoryResponse
   */
  data?: GetLatestRecordPorterHistoryResponseData
}

export function GetLatestRecordPorterHistoryResponseFromJSON(json: any): GetLatestRecordPorterHistoryResponse {
  return GetLatestRecordPorterHistoryResponseFromJSONTyped(json, false)
}

export function GetLatestRecordPorterHistoryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetLatestRecordPorterHistoryResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetLatestRecordPorterHistoryResponseDataFromJSON(json['data'])
  }
}

export function GetLatestRecordPorterHistoryResponseToJSON(value?: GetLatestRecordPorterHistoryResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetLatestRecordPorterHistoryResponseDataToJSON(value.data)
  }
}
