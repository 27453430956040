/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PurgeUserAndMailTemplateOrganizationRequest
 */
export interface PurgeUserAndMailTemplateOrganizationRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof PurgeUserAndMailTemplateOrganizationRequest
   */
  liveUserIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof PurgeUserAndMailTemplateOrganizationRequest
   */
  liveMailTemplateIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof PurgeUserAndMailTemplateOrganizationRequest
   */
  takeOverUserId?: string
  /**
   *
   * @type {string}
   * @memberof PurgeUserAndMailTemplateOrganizationRequest
   */
  planIdOfTransitionDst?: string
}

export function PurgeUserAndMailTemplateOrganizationRequestFromJSON(
  json: any
): PurgeUserAndMailTemplateOrganizationRequest {
  return PurgeUserAndMailTemplateOrganizationRequestFromJSONTyped(json, false)
}

export function PurgeUserAndMailTemplateOrganizationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PurgeUserAndMailTemplateOrganizationRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    liveUserIds: !exists(json, 'live_user_ids') ? undefined : json['live_user_ids'],
    liveMailTemplateIds: !exists(json, 'live_mail_template_ids') ? undefined : json['live_mail_template_ids'],
    takeOverUserId: !exists(json, 'take_over_user_id') ? undefined : json['take_over_user_id'],
    planIdOfTransitionDst: !exists(json, 'plan_id_of_transition_dst') ? undefined : json['plan_id_of_transition_dst']
  }
}

export function PurgeUserAndMailTemplateOrganizationRequestToJSON(
  value?: PurgeUserAndMailTemplateOrganizationRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    live_user_ids: value.liveUserIds,
    live_mail_template_ids: value.liveMailTemplateIds,
    take_over_user_id: value.takeOverUserId,
    plan_id_of_transition_dst: value.planIdOfTransitionDst
  }
}
