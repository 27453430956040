export var createConfigurationWithToken = function (_a) {
    var basePath = _a.basePath, fetchApi = _a.fetchApi, idToken = _a.idToken, type = _a.type;
    var config = {
        basePath: basePath,
        fetchApi: fetchApi
    };
    if (idToken) {
        config.headers = type === 'user' ? { 'X-Noco-User-Token': idToken } : { 'X-Noco-Visitor-Token': idToken };
    }
    return config;
};
