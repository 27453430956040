/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  EnumOrganizationUser,
  EnumOrganizationUserFromJSON,
  EnumOrganizationUserToJSON,
  GetOrganizationUsersResponse,
  GetOrganizationUsersResponseFromJSON,
  GetOrganizationUsersResponseToJSON,
  RestoreOrganizationUserResponse,
  RestoreOrganizationUserResponseFromJSON,
  RestoreOrganizationUserResponseToJSON,
  UpdateOrganizationUserRequest,
  UpdateOrganizationUserRequestFromJSON,
  UpdateOrganizationUserRequestToJSON,
  UpdateOrganizationUserResponse,
  UpdateOrganizationUserResponseFromJSON,
  UpdateOrganizationUserResponseToJSON
} from '../models'

export interface UserV1OrganizationUsersGetRequest {
  permission?: EnumOrganizationUser
  includeDiscarded?: boolean
  page?: number
  per?: number
}

export interface UserV1OrganizationUsersUserIdDeleteRequest {
  userId: string
}

export interface UserV1OrganizationUsersUserIdPutRequest {
  userId: string
  updateOrganizationUserRequest?: UpdateOrganizationUserRequest
}

export interface UserV1OrganizationUsersUserIdRestorePutRequest {
  userId: string
}

/**
 *
 */
export class ApiUserV1OrganizationUsersApi extends runtime.BaseAPI {
  /**
   * チームのユーザ一覧を取得する
   * index
   */
  async userV1OrganizationUsersGetRaw(
    requestParameters: UserV1OrganizationUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationUsersResponse>> {
    const queryParameters: any = {}

    if (requestParameters.permission !== undefined) {
      queryParameters['permission'] = requestParameters.permission
    }

    if (requestParameters.includeDiscarded !== undefined) {
      queryParameters['include_discarded'] = requestParameters.includeDiscarded
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization_users`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationUsersResponseFromJSON(jsonValue))
  }

  /**
   * チームのユーザ一覧を取得する
   * index
   */
  async userV1OrganizationUsersGet(
    requestParameters: UserV1OrganizationUsersGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationUsersResponse> {
    const response = await this.userV1OrganizationUsersGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームユーザの削除をする
   * destroy
   */
  async userV1OrganizationUsersUserIdDeleteRaw(
    requestParameters: UserV1OrganizationUsersUserIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling userV1OrganizationUsersUserIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization_users/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters.userId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * チームユーザの削除をする
   * destroy
   */
  async userV1OrganizationUsersUserIdDelete(
    requestParameters: UserV1OrganizationUsersUserIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1OrganizationUsersUserIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームユーザーの設定を更新する
   * update
   */
  async userV1OrganizationUsersUserIdPutRaw(
    requestParameters: UserV1OrganizationUsersUserIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationUserResponse>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling userV1OrganizationUsersUserIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization_users/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters.userId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationUserRequestToJSON(requestParameters.updateOrganizationUserRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationUserResponseFromJSON(jsonValue))
  }

  /**
   * チームユーザーの設定を更新する
   * update
   */
  async userV1OrganizationUsersUserIdPut(
    requestParameters: UserV1OrganizationUsersUserIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationUserResponse> {
    const response = await this.userV1OrganizationUsersUserIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームユーザーで論理削除されたユーザのリストア処理
   * restore
   */
  async userV1OrganizationUsersUserIdRestorePutRaw(
    requestParameters: UserV1OrganizationUsersUserIdRestorePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<RestoreOrganizationUserResponse>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling userV1OrganizationUsersUserIdRestorePut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization_users/{user_id}/restore`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters.userId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => RestoreOrganizationUserResponseFromJSON(jsonValue))
  }

  /**
   * チームユーザーで論理削除されたユーザのリストア処理
   * restore
   */
  async userV1OrganizationUsersUserIdRestorePut(
    requestParameters: UserV1OrganizationUsersUserIdRestorePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<RestoreOrganizationUserResponse> {
    const response = await this.userV1OrganizationUsersUserIdRestorePutRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
