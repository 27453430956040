/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  SiteMiscSetting,
  SiteMiscSettingFromJSON,
  SiteMiscSettingFromJSONTyped,
  SiteMiscSettingToJSON
} from './SiteMiscSetting'

/**
 *
 * @export
 * @interface GetSiteMiscSettingResponseData
 */
export interface GetSiteMiscSettingResponseData {
  /**
   *
   * @type {SiteMiscSetting}
   * @memberof GetSiteMiscSettingResponseData
   */
  siteMiscSetting?: SiteMiscSetting
}

export function GetSiteMiscSettingResponseDataFromJSON(json: any): GetSiteMiscSettingResponseData {
  return GetSiteMiscSettingResponseDataFromJSONTyped(json, false)
}

export function GetSiteMiscSettingResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSiteMiscSettingResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteMiscSetting: !exists(json, 'site_misc_setting') ? undefined : SiteMiscSettingFromJSON(json['site_misc_setting'])
  }
}

export function GetSiteMiscSettingResponseDataToJSON(value?: GetSiteMiscSettingResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_misc_setting: SiteMiscSettingToJSON(value.siteMiscSetting)
  }
}
