/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AuthContactMagicLinkLoginRequest,
  AuthContactMagicLinkLoginRequestFromJSON,
  AuthContactMagicLinkLoginRequestToJSON,
  AuthContactMagicLinkLoginResponse,
  AuthContactMagicLinkLoginResponseFromJSON,
  AuthContactMagicLinkLoginResponseToJSON
} from '../models'

export interface ContactV1MagicLinkSigninPostRequest {
  authContactMagicLinkLoginRequest?: AuthContactMagicLinkLoginRequest
}

/**
 *
 */
export class ApiContactV1AuthApi extends runtime.BaseAPI {
  /**
   * トークンとメールアドレスでのログイン
   * magic_link_signin
   */
  async contactV1MagicLinkSigninPostRaw(
    requestParameters: ContactV1MagicLinkSigninPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AuthContactMagicLinkLoginResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/contact/v1/magic_link_signin`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthContactMagicLinkLoginRequestToJSON(requestParameters.authContactMagicLinkLoginRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AuthContactMagicLinkLoginResponseFromJSON(jsonValue))
  }

  /**
   * トークンとメールアドレスでのログイン
   * magic_link_signin
   */
  async contactV1MagicLinkSigninPost(
    requestParameters: ContactV1MagicLinkSigninPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AuthContactMagicLinkLoginResponse> {
    const response = await this.contactV1MagicLinkSigninPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
