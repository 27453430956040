import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var StaffListApi = /** @class */ (function () {
    function StaffListApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    StaffListApi.initialize = function (basePath, fetchApi) {
        if (!StaffListApi.instance) {
            StaffListApi.instance = new StaffListApi(basePath, fetchApi);
        }
        return StaffListApi.instance;
    };
    StaffListApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1StaffListApi(new Configuration(config));
    };
    return StaffListApi;
}());
export { StaffListApi };
