/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateCompanySettingRequestCompanySetting
 */
export interface CreateCompanySettingRequestCompanySetting {
  /**
   *
   * @type {string}
   * @memberof CreateCompanySettingRequestCompanySetting
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CreateCompanySettingRequestCompanySetting
   */
  icon?: string
  /**
   *
   * @type {string}
   * @memberof CreateCompanySettingRequestCompanySetting
   */
  kind?: CreateCompanySettingRequestCompanySettingKindEnum
  /**
   *
   * @type {string}
   * @memberof CreateCompanySettingRequestCompanySetting
   */
  status?: CreateCompanySettingRequestCompanySettingStatusEnum
}

/**
 * @export
 */
export const CreateCompanySettingRequestCompanySettingKindEnum = {
  SingleField: 'single_field',
  MultipleField: 'multiple_field'
} as const
export type CreateCompanySettingRequestCompanySettingKindEnum =
  typeof CreateCompanySettingRequestCompanySettingKindEnum[keyof typeof CreateCompanySettingRequestCompanySettingKindEnum]

/**
 * @export
 */
export const CreateCompanySettingRequestCompanySettingStatusEnum = {
  Active: 'active',
  Inactive: 'inactive'
} as const
export type CreateCompanySettingRequestCompanySettingStatusEnum =
  typeof CreateCompanySettingRequestCompanySettingStatusEnum[keyof typeof CreateCompanySettingRequestCompanySettingStatusEnum]

export function CreateCompanySettingRequestCompanySettingFromJSON(
  json: any
): CreateCompanySettingRequestCompanySetting {
  return CreateCompanySettingRequestCompanySettingFromJSONTyped(json, false)
}

export function CreateCompanySettingRequestCompanySettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateCompanySettingRequestCompanySetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    icon: !exists(json, 'icon') ? undefined : json['icon'],
    kind: !exists(json, 'kind') ? undefined : json['kind'],
    status: !exists(json, 'status') ? undefined : json['status']
  }
}

export function CreateCompanySettingRequestCompanySettingToJSON(
  value?: CreateCompanySettingRequestCompanySetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    icon: value.icon,
    kind: value.kind,
    status: value.status
  }
}
