/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateDocumentRequestFromJSON(json) {
    return UpdateDocumentRequestFromJSONTyped(json, false);
}
export function UpdateDocumentRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        memo: !exists(json, 'memo') ? undefined : json['memo'],
        label: !exists(json, 'label') ? undefined : json['label']
    };
}
export function UpdateDocumentRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        title: value.title,
        description: value.description,
        memo: value.memo,
        label: value.label
    };
}
