/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationPaymentAddon
 */
export interface OrganizationPaymentAddon {
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentAddon
   */
  userOptionQuantity?: number
}

export function OrganizationPaymentAddonFromJSON(json: any): OrganizationPaymentAddon {
  return OrganizationPaymentAddonFromJSONTyped(json, false)
}

export function OrganizationPaymentAddonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPaymentAddon {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userOptionQuantity: !exists(json, 'user_option_quantity') ? undefined : json['user_option_quantity']
  }
}

export function OrganizationPaymentAddonToJSON(value?: OrganizationPaymentAddon | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_option_quantity: value.userOptionQuantity
  }
}
