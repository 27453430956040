/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { SiteShareForm, SiteShareFormFromJSON, SiteShareFormFromJSONTyped, SiteShareFormToJSON } from './SiteShareForm'

/**
 *
 * @export
 * @interface GetContactShareSiteShareFormsResponseData
 */
export interface GetContactShareSiteShareFormsResponseData {
  /**
   *
   * @type {Array<SiteShareForm>}
   * @memberof GetContactShareSiteShareFormsResponseData
   */
  shareForms?: Array<SiteShareForm>
}

export function GetContactShareSiteShareFormsResponseDataFromJSON(
  json: any
): GetContactShareSiteShareFormsResponseData {
  return GetContactShareSiteShareFormsResponseDataFromJSONTyped(json, false)
}

export function GetContactShareSiteShareFormsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactShareSiteShareFormsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    shareForms: !exists(json, 'share_forms')
      ? undefined
      : (json['share_forms'] as Array<any>).map(SiteShareFormFromJSON)
  }
}

export function GetContactShareSiteShareFormsResponseDataToJSON(
  value?: GetContactShareSiteShareFormsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    share_forms: value.shareForms === undefined ? undefined : (value.shareForms as Array<any>).map(SiteShareFormToJSON)
  }
}
