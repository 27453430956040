/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AuthContactMagicLinkLoginResponseDataVisitor,
  AuthContactMagicLinkLoginResponseDataVisitorFromJSON,
  AuthContactMagicLinkLoginResponseDataVisitorFromJSONTyped,
  AuthContactMagicLinkLoginResponseDataVisitorToJSON
} from './AuthContactMagicLinkLoginResponseDataVisitor'

/**
 *
 * @export
 * @interface AuthContactMagicLinkLoginResponseData
 */
export interface AuthContactMagicLinkLoginResponseData {
  /**
   *
   * @type {AuthContactMagicLinkLoginResponseDataVisitor}
   * @memberof AuthContactMagicLinkLoginResponseData
   */
  visitor?: AuthContactMagicLinkLoginResponseDataVisitor
}

export function AuthContactMagicLinkLoginResponseDataFromJSON(json: any): AuthContactMagicLinkLoginResponseData {
  return AuthContactMagicLinkLoginResponseDataFromJSONTyped(json, false)
}

export function AuthContactMagicLinkLoginResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthContactMagicLinkLoginResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    visitor: !exists(json, 'visitor')
      ? undefined
      : AuthContactMagicLinkLoginResponseDataVisitorFromJSON(json['visitor'])
  }
}

export function AuthContactMagicLinkLoginResponseDataToJSON(value?: AuthContactMagicLinkLoginResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    visitor: AuthContactMagicLinkLoginResponseDataVisitorToJSON(value.visitor)
  }
}
