/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  EnumListResourceType,
  EnumListResourceTypeFromJSON,
  EnumListResourceTypeToJSON,
  EnumMaterialableType,
  EnumMaterialableTypeFromJSON,
  EnumMaterialableTypeToJSON,
  GetMaterialListsResponse,
  GetMaterialListsResponseFromJSON,
  GetMaterialListsResponseToJSON,
  PinMaterialListRequest,
  PinMaterialListRequestFromJSON,
  PinMaterialListRequestToJSON
} from '../models'

export interface UserV1MaterialListsGetRequest {
  resourceType?: EnumListResourceType
  materialableType?: EnumMaterialableType
  page?: number
  per?: number
}

export interface UserV1MaterialListsPinPostRequest {
  pinMaterialListRequest?: PinMaterialListRequest
}

/**
 *
 */
export class ApiUserV1MaterialListApi extends runtime.BaseAPI {
  /**
   * マテリアルのピン設定一覧と、紐づくリソース件数を取得する
   * index
   */
  async userV1MaterialListsGetRaw(
    requestParameters: UserV1MaterialListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetMaterialListsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.resourceType !== undefined) {
      queryParameters['resource_type'] = requestParameters.resourceType
    }

    if (requestParameters.materialableType !== undefined) {
      queryParameters['materialable_type'] = requestParameters.materialableType
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/material_lists`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetMaterialListsResponseFromJSON(jsonValue))
  }

  /**
   * マテリアルのピン設定一覧と、紐づくリソース件数を取得する
   * index
   */
  async userV1MaterialListsGet(
    requestParameters: UserV1MaterialListsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetMaterialListsResponse> {
    const response = await this.userV1MaterialListsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * マテリアルのピン設定をする
   * pin
   */
  async userV1MaterialListsPinPostRaw(
    requestParameters: UserV1MaterialListsPinPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/material_lists/pin`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PinMaterialListRequestToJSON(requestParameters.pinMaterialListRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * マテリアルのピン設定をする
   * pin
   */
  async userV1MaterialListsPinPost(
    requestParameters: UserV1MaterialListsPinPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1MaterialListsPinPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
