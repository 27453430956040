/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumDocumentSlideProcessingStatusFromJSON, EnumDocumentSlideProcessingStatusToJSON } from './EnumDocumentSlideProcessingStatus';
export function ShallowDocumentFromJSON(json) {
    return ShallowDocumentFromJSONTyped(json, false);
}
export function ShallowDocumentFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        memo: !exists(json, 'memo') ? undefined : json['memo'],
        publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
        slideProcessingStatus: !exists(json, 'slide_processing_status')
            ? undefined
            : EnumDocumentSlideProcessingStatusFromJSON(json['slide_processing_status']),
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function ShallowDocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        organization_id: value.organizationId,
        title: value.title,
        description: value.description,
        memo: value.memo,
        published_at: value.publishedAt,
        slide_processing_status: EnumDocumentSlideProcessingStatusToJSON(value.slideProcessingStatus),
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
