export const getFileExtension = (fileName: string): string => {
  const splitList = fileName.split('.')
  if (splitList.length === 1) return ''

  const extension = splitList.pop()
  return extension?.toLowerCase() || ''
}

const imageExtentionList = ['jpg', 'jpeg', 'png', 'gif', 'webp']
export const isImageType = (fileName: string): boolean => {
  const extension = getFileExtension(fileName)
  return imageExtentionList.includes(extension)
}
