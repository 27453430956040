/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function AuditColumnValueObjectFromJSON(json) {
    return AuditColumnValueObjectFromJSONTyped(json, false);
}
export function AuditColumnValueObjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        displayText: !exists(json, 'display_text') ? undefined : json['display_text'],
        columnType: !exists(json, 'column_type') ? undefined : json['column_type'],
        columnId: !exists(json, 'column_id') ? undefined : json['column_id']
    };
}
export function AuditColumnValueObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        display_text: value.displayText,
        column_type: value.columnType,
        column_id: value.columnId
    };
}
