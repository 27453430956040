import { useCallback, useEffect, useMemo, useRef } from 'react'
import { ChatContentMenuType } from 'src/components/organisms'
import { ChatChannelEvent, Message, SubscribedMessagesType } from 'src/fixtures/types/chat-channel'
import { useAuthenticate } from '../auth/hooks'
import { useGetVisitor } from '../visitor/hooks'

const WS_ENDPOINT = process.env.WS_ENDPOINT ?? 'wss://api.stg-noco.sale/websocket'

export const useSubscribeChatChannel = (
  consumerType: 'User' | 'Visitor',
  onReceive: (message: Message, contactId: string, open: boolean, selectedMenu: ChatContentMenuType) => void,
  onError: (message: Message) => void,
  contactId: string,
  open: boolean,
  selectedMenu: ChatContentMenuType,
  isPreview?: boolean
) => {
  const { data: auth } = useAuthenticate()
  const { data: visitor } = useGetVisitor(isPreview)
  const authToken = useMemo(
    () => (consumerType === 'User' ? auth?.token : visitor?.token),
    [consumerType, auth?.token, visitor?.token]
  )
  const webSocket = useRef<WebSocket>()

  const subscribe = useCallback(
    (token: string) => {
      if (!webSocket.current && contactId) {
        const newWebSocket = new WebSocket(String(WS_ENDPOINT), ['actioncable-v1-json', consumerType, token])
        newWebSocket.onopen = () => {
          newWebSocket.send(
            JSON.stringify({
              identifier: JSON.stringify({ channel: 'ChatChannel', contact_id: contactId }),
              command: 'subscribe'
            })
          )
        }
        newWebSocket.onmessage = event => {
          const messageData: ChatChannelEvent = JSON.parse(event.data)
          switch (messageData.type) {
            case SubscribedMessagesType.Welcome:
              break
            case SubscribedMessagesType.Ping:
              break
            case SubscribedMessagesType.Confirm_Subscription:
              break
            case SubscribedMessagesType.Reject_Subscription:
              break
            case SubscribedMessagesType.Disconnect:
              // Note: 再接続
              if (token) {
                subscribe(token)
              }
              break
            default: {
              if (messageData.message) {
                const message: Message = JSON.parse(messageData.message)

                if (message.errors && message.errors.length > 0) {
                  onError(message)
                }
                if (message.data.chat_message) {
                  onReceive(message, contactId, open, selectedMenu)
                }
              }
              break
            }
          }
        }

        webSocket.current = newWebSocket
      }
    },
    [consumerType, contactId, open, selectedMenu, onError, onReceive, webSocket]
  )

  const disconnect = useCallback(() => {
    if (webSocket.current) {
      webSocket.current.onopen = null
      webSocket.current.onmessage = null
      webSocket.current.close()
      webSocket.current = undefined
    }
  }, [webSocket])

  useEffect(() => {
    if (authToken && contactId && !webSocket.current) {
      subscribe(authToken)
    }
  }, [authToken, subscribe, contactId])

  // アンマウント時にWebSocketを切断
  useEffect(() => {
    return () => {
      disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // openによって、チャットを受け取った際のイベントを切り替えるため、切断して接続する
  useEffect(() => {
    if (authToken && contactId && webSocket.current && open) {
      disconnect()
      subscribe(authToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return {
    subscribe,
    disconnect
  }
}
