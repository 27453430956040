/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateMePasswordRequestUser,
  UpdateMePasswordRequestUserFromJSON,
  UpdateMePasswordRequestUserFromJSONTyped,
  UpdateMePasswordRequestUserToJSON
} from './UpdateMePasswordRequestUser'

/**
 *
 * @export
 * @interface UpdateMePasswordRequest
 */
export interface UpdateMePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateMePasswordRequest
   */
  currentPassword: string
  /**
   *
   * @type {UpdateMePasswordRequestUser}
   * @memberof UpdateMePasswordRequest
   */
  user: UpdateMePasswordRequestUser
}

export function UpdateMePasswordRequestFromJSON(json: any): UpdateMePasswordRequest {
  return UpdateMePasswordRequestFromJSONTyped(json, false)
}

export function UpdateMePasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMePasswordRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    currentPassword: json['current_password'],
    user: UpdateMePasswordRequestUserFromJSON(json['user'])
  }
}

export function UpdateMePasswordRequestToJSON(value?: UpdateMePasswordRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    current_password: value.currentPassword,
    user: UpdateMePasswordRequestUserToJSON(value.user)
  }
}
