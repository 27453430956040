/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateOrganizationTriggerEmailRequestOrganizationFromJSON(json) {
    return UpdateOrganizationTriggerEmailRequestOrganizationFromJSONTyped(json, false);
}
export function UpdateOrganizationTriggerEmailRequestOrganizationFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        isTriggerResentEmail: !exists(json, 'is_trigger_resent_email') ? undefined : json['is_trigger_resent_email'],
        triggerResentTiming: !exists(json, 'trigger_resent_timing') ? undefined : json['trigger_resent_timing']
    };
}
export function UpdateOrganizationTriggerEmailRequestOrganizationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        is_trigger_resent_email: value.isTriggerResentEmail,
        trigger_resent_timing: value.triggerResentTiming
    };
}
