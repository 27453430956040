import React from 'react'
import Image from 'next/image'
import { createStyles, makeStyles, Typography, Box, Modal, Slide } from '@material-ui/core'
import { Colors, Pallete } from '../../atoms/Colors'
import { Button } from '../../atoms/Button'
import { Alert } from '../../atoms/Alert'

interface ModalChangeEmailConfirmProps {
  email: string
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)

export const ModalChangeEmailConfirm = ({ email, open, onClose }: ModalChangeEmailConfirmProps) => {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minWidth="580px"
          minHeight="138px"
          position="relative"
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.gray}`}
        >
          <Box padding="24px">
            <Alert status="warning" text="メールアドレスの変更はまだ完了していません" />

            <Box mt="16px" />

            <Typography variant="body2">
              {email} にメールを送信しました
              <br />
              1時間以内にメールの認証ボタンから、認証手続きを完了してください
            </Typography>

            <Box mt="32px" />

            <Box textAlign="center">
              <Image src="/images/user/email-change.svg" width={293} height={204} />
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            height="58px"
            bgcolor={Colors.background.lightGray}
            borderRadius="0 0 10px 10px"
            boxShadow={`inset 0px 1px 0px ${Colors.background.gray}`}
          >
            <Box mr="24px">
              <Button kind="neutral" variant="outlined" title="閉じる" onClick={onClose} />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
