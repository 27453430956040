import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import { Colors, SvgIcon, Typography } from 'src/components/atoms'
import { numberWithCommas } from 'src/fixtures/utils/number'
import { UserOption } from './type'

const userOptionList: UserOption[] = [
  {
    planName: 'ライト',
    grade: 'light',
    isAvailable: false
  },
  {
    planName: 'スタンダード',
    grade: 'standard_new',
    isAvailable: false
  },
  {
    planName: 'プレミアム',
    grade: 'premium',
    isAvailable: true,
    unitAmount: 3_000,
    desctiption: '1ユーザー枠  / 月額'
  },
  {
    planName: 'アドバンス',
    grade: 'advance',
    isAvailable: true,
    desctiption: 'お問い合わせください'
  }
]

const useStyles = makeStyles(() => {
  return createStyles({
    optionPlanBox: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderTopStyle: 'solid',
      borderTopWidth: '16px',
      borderBottom: `1px solid ${Colors.background.silver}`,
      textAlign: 'center',
      padding: '16px 0',
      '&.light': { borderTopColor: '#FFD43D' },
      '&.standard_new': { borderTopColor: '#76F4ED' },
      '&.premium': { borderTopColor: '#1DA7F5' },
      '&.advance': { borderTopColor: '#967CFD' }
    }
  })
})

export const UserOptionPricing = () => {
  const classes = useStyles()

  return (
    <Box display="flex">
      {userOptionList.map((userOption, i) => {
        return (
          <Box
            key={i}
            sx={{
              flex: '1',
              borderLeft: `1px solid ${Colors.background.silver}`,
              borderRight: `1px solid ${Colors.background.silver}`
            }}
          >
            <Box className={clsx(classes.optionPlanBox, userOption.grade)}>
              <Box mb="8px">
                <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
                  {userOption.planName}
                </Typography>
              </Box>
              {userOption.isAvailable ? (
                userOption.unitAmount && (
                  <Box mb="8px">
                    <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
                      {numberWithCommas(userOption.unitAmount)}円
                    </Typography>
                  </Box>
                )
              ) : (
                <Box>
                  <SvgIcon variant="close" size="15px" color={Colors.base.black} />
                </Box>
              )}
              {userOption.desctiption && (
                <Typography fontSize="s" lineheight="1">
                  {userOption.desctiption}
                </Typography>
              )}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
