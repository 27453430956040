import { Box } from '@material-ui/core'
import { OrganizationPaymentContractBasicInfo, OrganizationPlanDetail } from '@noco/http-client/lib/noco'
import React, { useCallback } from 'react'
import { SelectPlanCard } from 'src/components/molecules'
import { useGetOrganizationPaymentBillingInfo } from 'src/fixtures/modules/organizationPayment/hooks'
import { PaymentBillingForm, PaymentBillingPaidFormValue } from '../../PaymentBillingForm'
import { ButtonIcon, Colors, SvgIcon, Typography } from 'src/components/atoms'

export interface ContractSecondStepProps {
  plan: OrganizationPlanDetail
  backButtonDisabled?: boolean
  onSubmit: (contractBasicInfo: OrganizationPaymentContractBasicInfo) => void
  onBack: () => void
}

export const ContractSecondStep = ({ plan, backButtonDisabled = false, onSubmit, onBack }: ContractSecondStepProps) => {
  const { data } = useGetOrganizationPaymentBillingInfo()
  const contractBasicInfo = data?.contractBasicInfo

  const onSubmitContractForm = useCallback(
    (contractBasicInfo: OrganizationPaymentContractBasicInfo, _contractPaidInfo?: PaymentBillingPaidFormValue) => {
      onSubmit(contractBasicInfo)
    },
    [onSubmit]
  )

  if (!contractBasicInfo) return <></>

  return (
    <Box>
      <Box mb="16px">
        <SelectPlanCard planName={plan.name} unitAmount={plan.unitAmount} />
      </Box>

      <Box mb="16px">
        <PaymentBillingForm
          isPaid={false}
          contractBasicInfo={contractBasicInfo}
          onSubmit={onSubmitContractForm}
          submitTitle="入力情報の確認へ進む"
        />
      </Box>

      {!backButtonDisabled && (
        <Box display="flex" justifyContent="center" mb="24px" color={Colors.accent.keyBlue.default}>
          <ButtonIcon
            tabIndex={-1}
            iconBefore={<SvgIcon variant="leftArrow" size="16px" color="inherit" />}
            onClick={() => onBack()}
          >
            <Typography fontSize="s" fontWeight="bold" lineheight="1" color="inherit">
              戻る
            </Typography>
          </ButtonIcon>
        </Box>
      )}
    </Box>
  )
}
