import React from 'react'
import { Box } from '@material-ui/core'
import { PopupTemplate } from '..'
import { Colors, Label, Password, Typography } from 'src/components/atoms'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { User } from '@noco/http-client/lib/noco'

export interface ModalConfirmChangeRoleProps {
  user: User
  open: boolean
  kind?: 'roleChangeToAdmin' | 'resendEmailToAdmin'
  onClose: () => void
  onConfirm: (password: string) => void
}

export const changeRoleValidationSchema = yup.object().shape({
  password: yup.string().label('ログインパスワードを入力').required('この必須項目を入力してください。')
})

export const ModalConfirmChangeRole = ({
  kind = 'roleChangeToAdmin',
  user,
  open,
  onClose,
  onConfirm
}: ModalConfirmChangeRoleProps) => {
  const { values, handleChange, handleBlur, handleSubmit, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: { password: '' },
    validationSchema: changeRoleValidationSchema,
    onSubmit: values => onConfirm(values.password)
  })

  return (
    <PopupTemplate
      open={open}
      title={kind === 'roleChangeToAdmin' ? '管理者に権限変更' : '管理者にメールを再送'}
      hasCancel
      cancelButtonLabel="キャンセル"
      hasClose
      onClose={onClose}
      hasConfirm
      onConfirm={handleSubmit}
      confirmButtonLabel="OK"
      confirmButtonKind="primary"
    >
      <Box px="24px" pt="24px" pb="32px">
        <Typography fontSize="s" fontWeight="bold">
          {kind === 'roleChangeToAdmin' ? '変更内容' : '再送内容'}
        </Typography>
        <Box display="flex" alignItems="center" borderBottom={`1px solid ${Colors.background.gray}`} py="7px" mt="16px">
          <Box flex={1}>
            <Typography fontSize="s" fontWeight="normal">
              メールアドレス
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography fontSize="s" fontWeight="normal">
              {user.encryptedEmail}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" borderBottom={`1px solid ${Colors.background.gray}`} py="7px" mb="16px">
          <Box flex={1}>
            <Typography fontSize="s" fontWeight="normal">
              権限
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography fontSize="s" fontWeight="normal">
              管理者
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb="8px">
          <Typography fontSize="s" fontWeight="bold">
            ログインパスワードを入力
          </Typography>
          <Box mr="6px" />
          <Label type="negative" labelText="必須" size="small" />
        </Box>
        <Password
          name="password"
          password={values.password}
          helperText="この必須項目を入力してください。"
          onChange={handleChange}
          onBlur={handleBlur}
          required
          fullWidth
          error={touched.password ? errors.password : undefined}
        />
        {touched.password && errors.password ? null : <Box mt="24px" />}
      </Box>
    </PopupTemplate>
  )
}
