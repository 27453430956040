/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetDocumentViewHistorySessionsResponseData,
  GetDocumentViewHistorySessionsResponseDataFromJSON,
  GetDocumentViewHistorySessionsResponseDataFromJSONTyped,
  GetDocumentViewHistorySessionsResponseDataToJSON
} from './GetDocumentViewHistorySessionsResponseData'

/**
 *
 * @export
 * @interface GetDocumentViewHistorySessionsResponse
 */
export interface GetDocumentViewHistorySessionsResponse {
  /**
   *
   * @type {number}
   * @memberof GetDocumentViewHistorySessionsResponse
   */
  status?: number
  /**
   *
   * @type {GetDocumentViewHistorySessionsResponseData}
   * @memberof GetDocumentViewHistorySessionsResponse
   */
  data?: GetDocumentViewHistorySessionsResponseData
}

export function GetDocumentViewHistorySessionsResponseFromJSON(json: any): GetDocumentViewHistorySessionsResponse {
  return GetDocumentViewHistorySessionsResponseFromJSONTyped(json, false)
}

export function GetDocumentViewHistorySessionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentViewHistorySessionsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetDocumentViewHistorySessionsResponseDataFromJSON(json['data'])
  }
}

export function GetDocumentViewHistorySessionsResponseToJSON(
  value?: GetDocumentViewHistorySessionsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetDocumentViewHistorySessionsResponseDataToJSON(value.data)
  }
}
