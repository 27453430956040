import { AppBar, Box, createStyles, makeStyles, Modal, Slide, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Colors, DynamicMuiIcon, Tooltip } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { useGetSite } from 'src/fixtures/modules/site/hooks'
import { pushUserActionDataLayer } from 'src/utils/gtm'
import { useFormSubmitter } from './hooks'
import { SiteShareWithEmail } from './SiteShareWithEmail'
import { SiteShareWithLink } from './SiteShareWithLink'

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    root: {
      backgroundColor: Colors.background.gray,
      boxShadow: 'none'
    },
    tablist: {
      minHeight: 'auto !important'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      padding: '13px 0px',
      border: `1px solid ${Colors.background.gray}`,
      color: Colors.base.middleGray
    },
    selectedTab: {
      color: Colors.accent.keyPurple.default,
      background: Colors.functional.background.default,
      border: '1px solid transparent'
    },
    tabPanel: {
      padding: '24px !important',
      height: '100%'
    },
    tabsIndicator: {
      display: 'none'
    },
    slideForm: {
      overflow: 'hidden'
    }
  })
})

export interface ModalSiteShareProps {
  siteId: string
  open: boolean
  onClose: () => void
  onCancel: () => void
  setInstructions?: React.Dispatch<React.SetStateAction<string[] | undefined>>
}

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

type TabValueType = 'select' | 'all'

export const ModalSiteShare = ({ siteId, open, onClose, onCancel, setInstructions }: ModalSiteShareProps) => {
  const classes = useStyles()
  const [value, setValue] = useState<TabValueType>('all')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: siteResponse, mutate } = useGetSite(siteId)
  const [showConfirm, setShowConfirm] = useState(false)
  const formSubmitter = useFormSubmitter()
  const [isSubmitEnd, setIsSubmitEnd] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [progressCompleted, setProgressCompleted] = useState(false)
  const [sendStatus, setSendStatus] = useState(false)

  const handleChangeTab = useCallback(
    (event: ChangeEvent<{}>, newValue: TabValueType) => {
      if (!showConfirm || (showConfirm && window.confirm('メールがまだ送信されていません。画面を離れますか？'))) {
        setValue(newValue)
        setShowConfirm(false)
        return
      }
    },
    [showConfirm]
  )
  const selectRef = useRef<HTMLButtonElement>(null)
  const shareTabRef = useRef<HTMLButtonElement>(null)

  const handleConfirm = useCallback(() => {
    if (value === 'all') shareTabRef.current?.click()
    if (value === 'select') selectRef.current?.click()
  }, [value])

  useEffect(() => {
    if (isSubmitting) {
      // select タブ上での handleConfirm 処理を メール送信 として計測する
      if (value === 'select') pushUserActionDataLayer('site', 'send_email')
    }
  }, [isSubmitting, setIsSubmitting, value])

  useEffect(() => {
    if ((!isSubmitting && value === 'select') || value !== 'select') return
    setIsSending(true)
  }, [isSubmitting, value])

  const handleSubmit = useCallback(() => {
    if (value === 'select') {
      setIsSubmitting(true)
    }
    setProgressCompleted(false)
    mutate().then(() => {
      setIsSubmitting(false)
      setSendStatus(false)
    })
  }, [mutate, value])

  const handleSuccess = useCallback(() => {
    setIsSubmitting(false)
    setIsSubmitEnd(true)
  }, [setIsSubmitting])

  const handleClose = useCallback(() => {
    if (isSending) return
    if (!showConfirm || (showConfirm && window.confirm('メールがまだ送信されていません。画面を離れますか？'))) {
      onClose()
      setProgressCompleted(false)
      return
    }
  }, [isSending, onClose, showConfirm])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="764px" height={1}>
          <SlideForm
            buttonTitle={value === 'select' ? 'メール送信' : '保存'}
            headerTitle="サイトを共有"
            cancelButtonTitle={value === 'all' ? '閉じる' : 'キャンセル'}
            hideSettings
            isSubmitting={isSubmitting}
            onClose={handleClose}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            hideSubmitButton={value === 'all'}
            isSubmitEnd={isSubmitEnd}
            clearSubmitEnd={() => setIsSubmitEnd(false)}
            isSending={isSending}
            progressCompleted={progressCompleted}
            submitStatus={sendStatus}
          >
            <Box height={1} display="flex" flexDirection="column" overflow="hidden">
              <TabContext value={value}>
                {!isSending && !progressCompleted && (
                  <AppBar position="relative" className={classes.root}>
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="company add tablist"
                      classes={{ root: classes.tablist, indicator: classes.tabsIndicator }}
                      variant="fullWidth"
                    >
                      <Tab
                        value="select"
                        label={
                          <Box display="flex" alignItems="center">
                            <DynamicMuiIcon variant="account" size="20px" />
                            <Box mr="4px" />
                            <Tooltip
                              content={`選択した顧客だけが閲覧できる共有リンクを作成し、メール機能で資料サイトを共有できます。
共有された本人だけが閲覧でき、それ以外の方は閲覧できません。`}
                            >
                              <Typography variant="h5">連絡先を選択してメールで共有</Typography>
                            </Tooltip>
                          </Box>
                        }
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        value="all"
                        label={
                          <Box display="flex" alignItems="center">
                            <DynamicMuiIcon variant="link" size="20px" />
                            <Box mr="4px" />
                            <Tooltip content={`誰でも閲覧可能な共有リンクを作成できます。`}>
                              <Typography variant="h5">公開用URLを作成して共有</Typography>
                            </Tooltip>
                          </Box>
                        }
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        {...a11yProps(1)}
                      />
                    </TabList>
                  </AppBar>
                )}
                <Box display="flex" flexDirection="column" height="100%" overflow="hidden auto">
                  <TabPanel value="select" classes={{ root: classes.tabPanel }}>
                    <SiteShareWithEmail
                      ref={selectRef}
                      site={siteResponse?.site!}
                      formSubmitter={formSubmitter}
                      onSubmit={() => setIsSubmitting(true)}
                      onError={() => setIsSubmitting(false)}
                      //onSuccess={handleSuccess}
                      onChangeShowConfirm={showConfirm => setShowConfirm(showConfirm)}
                      setIsSubmitting={setIsSubmitting}
                      isSubmitting={isSubmitting}
                      isSubmitEnd={isSubmitEnd}
                      setIsSubmitEnd={setIsSubmitEnd}
                      isSending={isSending}
                      setIsSending={setIsSending}
                      progressCompleted={progressCompleted}
                      setProgressCompleted={setProgressCompleted}
                      setSubmitStatus={setSendStatus}
                    />
                  </TabPanel>
                  <TabPanel value="all" classes={{ root: classes.tabPanel }}>
                    <SiteShareWithLink
                      ref={shareTabRef}
                      site={siteResponse?.site!}
                      onSubmit={handleSubmit}
                      onError={() => setIsSubmitting(false)}
                      onSuccess={handleSuccess}
                      setInstructions={setInstructions}
                    />
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
