import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Colors } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { DocumentShareWithEmail } from '../ModalDocumentShare/DocumentShareWithEmail'
import { useConfirmDiscard } from 'src/fixtures/utils/url'
import { useFormSubmitter } from '../ModalDocumentShare/hooks'

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

interface ModalDocumentShareFromContactProps {
  open: boolean
  contactId: string
  onClose: () => void
  onCancel: () => void
}

export const ModalDocumentShareFromContact = ({
  open,
  contactId,
  onClose,
  onCancel
}: ModalDocumentShareFromContactProps) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitEnd, setIsSubmitEnd] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [progressCompleted, setProgressCompleted] = useState(false)
  const [sendStatus, setSendStatus] = useState(false)
  useConfirmDiscard(showConfirm)
  const formSubmitter = useFormSubmitter()

  const selectRef = useRef<HTMLButtonElement>(null)

  const handleConfirm = useCallback(() => {
    selectRef.current?.click()
  }, [])

  useEffect(() => {
    if (!isSubmitting) return
    setIsSending(true)
  }, [isSubmitting])

  const handleSuccess = useCallback(() => {
    setIsSubmitting(false)
    setSendStatus(false)
  }, [setIsSubmitting])

  // 画面をクローズする前に confirm する対応
  const handleClose = useCallback(() => {
    if (isSending) return
    if (showConfirm && window.confirm('変更がまだ保存されていません。画面を離れますか？')) {
      onClose()
      setShowConfirm(false)
      setProgressCompleted(false)
      return
    }
    if (!showConfirm) {
      onClose()
      setShowConfirm(false)
      setProgressCompleted(false)
    }
  }, [isSending, showConfirm, onClose])

  const buttonTitle = useMemo(() => {
    if (isSubmitting) return '送信中'
    return 'メール送信'
  }, [isSubmitting])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="764px" height={1}>
          <SlideForm
            buttonTitle={buttonTitle}
            headerTitle="資料を共有"
            hideSettings
            onClose={handleClose}
            onCancel={() => {
              onCancel()
              setShowConfirm(false)
            }}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            buttonDisabled={formSubmitter.isDisabled}
            isSubmitEnd={isSubmitEnd}
            clearSubmitEnd={() => setIsSubmitEnd(false)}
            isSending={isSending}
            progressCompleted={progressCompleted}
            submitStatus={sendStatus}
          >
            <Box bgcolor={Colors.functional.background.default} height={1} padding="24px">
              <DocumentShareWithEmail
                ref={selectRef}
                defaultContactId={contactId}
                formSubmitter={formSubmitter}
                isSubmitEnd={isSubmitEnd}
                setIsSubmitEnd={setIsSubmitEnd}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                onSubmit={() => setIsSubmitting(true)}
                onError={() => setIsSubmitting(false)}
                onSuccess={handleSuccess}
                onChangeShowConfirm={setShowConfirm}
                isSending={isSending}
                setIsSending={setIsSending}
                progressCompleted={progressCompleted}
                setProgressCompleted={setProgressCompleted}
                setSubmitStatus={setSendStatus}
              />
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
