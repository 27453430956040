import { Box } from '@material-ui/core'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { DocumentInformation } from 'src/components/organisms'

export const InformationContainer = ({ onClose }: { onClose?: () => void }) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }
  const { query } = useRouter()
  const { documentId } = query as { documentId: string }

  return (
    <Box width="100%" height="100%" bgcolor="white" px="10px" py="18px">
      <DocumentInformation documentId={documentId} open={open} onClose={handleClose} />
    </Box>
  )
}
