import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const MailAttention = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="74" height="40" viewBox="0 0 74 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.334 6.66667H6.66732C4.83398 6.66667 3.33398 8.16667 3.33398 10V30C3.33398 31.8333 4.83398 33.3333 6.66732 33.3333H33.334C35.1673 33.3333 36.6673 31.8333 36.6673 30V10C36.6673 8.16667 35.1673 6.66667 33.334 6.66667ZM32.6673 13.75L21.7673 20.5667C20.684 21.25 19.3173 21.25 18.234 20.5667L7.33398 13.75C6.91732 13.4833 6.66732 13.0333 6.66732 12.55C6.66732 11.4333 7.88398 10.7667 8.83398 11.35L20.0007 18.3333L31.1673 11.35C32.1173 10.7667 33.334 11.4333 33.334 12.55C33.334 13.0333 33.084 13.4833 32.6673 13.75Z"
        fill="#DE1B63"
      />
      <path
        d="M59 18.75C59.6875 18.75 60.25 19.3125 60.25 20V25C60.25 25.6875 59.6875 26.25 59 26.25C58.3125 26.25 57.75 25.6875 57.75 25V20C57.75 19.3125 58.3125 18.75 59 18.75ZM58.9875 12.5C52.0875 12.5 46.5 18.1 46.5 25C46.5 31.9 52.0875 37.5 58.9875 37.5C65.9 37.5 71.5 31.9 71.5 25C71.5 18.1 65.9 12.5 58.9875 12.5ZM59 35C53.475 35 49 30.525 49 25C49 19.475 53.475 15 59 15C64.525 15 69 19.475 69 25C69 30.525 64.525 35 59 35ZM60.25 31.25H57.75V28.75H60.25V31.25Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default MailAttention
