import { SelectorItemWithType } from 'src/components/organisms/AddressSearchBox'
import { FileUploadTask } from '../modules/chatMessagesAttachmentFile/type'
import { localStorageUtils, StorageKey } from './localstorage'

export type localStorageMaterialDraftType = {
  contactChatDraft?: {
    [key: string]: {
      message: string
      fileUploadTaskList: FileUploadTask[]
    }
  }
  documentShareDraft?: {
    [key: string]: {
      subject?: string
      body?: string
      contactList?: SelectorItemWithType[]
    }
  }
  siteShareDraft?: {
    [key: string]: {
      subject?: string
      body?: string
      contactList?: SelectorItemWithType[]
    }
  }
}

export class LocalStorageMaterialDraft {
  private _storage = localStorageUtils
  // ストレージが存在しない場合は空のオブジェクトを生成
  private _data: Record<string, localStorageMaterialDraftType> = JSON.parse(
    this._storage.get(StorageKey.DRAFT_MATERIAL(this._name)) ?? `{"draft_${this._name}":{}}`
  )
  constructor(private _name: string) {
    this._name = _name
  }

  private _getItem(key: string): localStorageMaterialDraftType {
    return this._data[`draft_${key}`]
  }

  private _save(): void {
    this._storage.set(StorageKey.DRAFT_MATERIAL(this._name), JSON.stringify(this._data))
  }

  // 連絡先のチャット
  // 連絡先のチャットの下書き情報を保存する。
  public saveContactChatDraft(userId: string, contactId: string, message: string) {
    this.convertContactChatDraft(userId, contactId, message)
    this._save()
  }
  // 連絡先のチャットのファイル下書き情報を保存する。
  public saveContactChatDraftFile(userId: string, contactId: string, fileUploadTaskList: FileUploadTask[] = []) {
    this.convertContactChatDraftFile(userId, contactId, fileUploadTaskList)
    this._save()
  }
  // IDから連絡先のチャットの下書き情報を取得する。
  public getContactChatDraftByContactId(userId: string, contactId: string) {
    if (!this._getItem(userId)) return
    const { contactChatDraft } = this._getItem(userId)
    return contactChatDraft?.[contactId]
  }
  // IDから連絡先のチャットの下書き情報を削除する。
  public removeContactChatDraftByContactId(userId: string, contactId: string) {
    const { contactChatDraft } = this._getItem(userId)
    delete contactChatDraft?.[contactId]
    this._save()
  }
  // 連絡先のチャットの下書きをlocalStorageに保存する形に整形する
  private convertContactChatDraft(userId: string, contactId: string, message: string) {
    const { contactChatDraft } = this._getItem(userId)
    if (contactChatDraft) {
      contactChatDraft[contactId] = {
        ...contactChatDraft[contactId],
        message: message
      }
    } else {
      this._data[`draft_${userId}`].contactChatDraft = {
        [contactId]: {
          message: message,
          fileUploadTaskList: []
        }
      }
    }
  }
  // 連絡先のチャットのファイルをlocalStorageに保存する形に整形する
  private convertContactChatDraftFile(userId: string, contactId: string, fileUploadTaskList: FileUploadTask[] = []) {
    const { contactChatDraft } = this._getItem(userId)
    if (contactChatDraft) {
      contactChatDraft[contactId] = {
        ...contactChatDraft[contactId],
        fileUploadTaskList
      }
    } else {
      this._data[`draft_${userId}`].contactChatDraft = {
        [contactId]: {
          fileUploadTaskList,
          message: ''
        }
      }
    }
  }

  // 資料共有メール
  // 資料共有メールの下書き情報を保存する。
  public saveDocumentShareDraft(
    userId: string,
    documentId: string,
    subject: string,
    body: string,
    contactList: SelectorItemWithType[]
  ) {
    this.convertDocumentShareDraft(userId, documentId, subject, body, contactList)
    this._save()
  }
  // IDから資料共有メールの下書き情報を取得する。
  public getDocumentShareDraftByDocumentId(userId: string, documentId: string) {
    if (!this._getItem(userId)) return
    const { documentShareDraft } = this._getItem(userId)
    return documentShareDraft?.[documentId]
  }
  // 資料共有メールの下書きタイトルを保存する。
  public saveDocumentShareDraftSubject(userId: string, documentId: string, subject: string) {
    this.convertDocumentShareDraftSubject(userId, documentId, subject)
    this._save()
  }
  // 資料共有メールの下書きタイトルをlocalStorageに保存する形に整形する
  private convertDocumentShareDraftSubject(userId: string, documentId: string, subject: string) {
    const documentShareDraft = this._data[`draft_${userId}`]?.documentShareDraft
    const draft = documentShareDraft?.[documentId]
    if (documentShareDraft) {
      documentShareDraft[documentId] = {
        subject: subject,
        body: draft?.body ?? '',
        contactList: draft?.contactList ?? []
      }
    } else {
      this._data[`draft_${userId}`].documentShareDraft = {
        [documentId]: {
          subject: subject,
          body: draft?.body ?? '',
          contactList: draft?.contactList ?? []
        }
      }
    }
  }
  // 資料共有メールの下書き本文を保存する。
  public saveDocumentShareDraftBody(userId: string, documentId: string, body: string) {
    this.convertDocumentShareDraftBody(userId, documentId, body)
    this._save()
  }
  // 資料共有メールの下書き本文をlocalStorageに保存する形に整形する
  private convertDocumentShareDraftBody(userId: string, documentId: string, body: string) {
    const documentShareDraft = this._data[`draft_${userId}`]?.documentShareDraft
    const draft = documentShareDraft?.[documentId]
    if (documentShareDraft) {
      documentShareDraft[documentId] = {
        subject: draft?.subject ?? '',
        body: body,
        contactList: draft?.contactList ?? []
      }
    } else {
      this._data[`draft_${userId}`].documentShareDraft = {
        [documentId]: {
          subject: draft?.subject ?? '',
          body: body,
          contactList: draft?.contactList ?? []
        }
      }
    }
  }
  // 資料共有メールの下書き宛先を保存する。
  public saveDocumentShareDraftContactList(userId: string, documentId: string, contactList: SelectorItemWithType[]) {
    this.convertDocumentShareDraftContactList(userId, documentId, contactList)
    this._save()
  }
  // 資料共有メールの下書き宛先をlocalStorageに保存する形に整形する
  private convertDocumentShareDraftContactList(
    userId: string,
    documentId: string,
    contactList: SelectorItemWithType[]
  ) {
    const documentShareDraft = this._data[`draft_${userId}`]?.documentShareDraft
    const draft = documentShareDraft?.[documentId]
    if (documentShareDraft) {
      documentShareDraft[documentId] = {
        subject: draft?.subject ?? '',
        body: draft?.body ?? '',
        contactList: contactList
      }
    } else {
      this._data[`draft_${userId}`].documentShareDraft = {
        [documentId]: {
          subject: draft?.subject ?? '',
          body: draft?.body ?? '',
          contactList: contactList
        }
      }
    }
  }
  // 資料共有メールの下書き情報を削除する。
  public removeDocumentShareDraftByDocumentId(userId: string, documentId: string) {
    const { documentShareDraft } = this._getItem(userId)
    delete documentShareDraft?.[documentId]
    this._save()
  }
  // 資料共有メールの下書き情報をlocalStorageに保存する形に整形する
  private convertDocumentShareDraft(
    userId: string,
    documentId: string,
    subject: string,
    body: string,
    contactList: SelectorItemWithType[]
  ) {
    const documentShareDraft = this._data[`draft_${userId}`]?.documentShareDraft
    if (documentShareDraft) {
      documentShareDraft[documentId] = {
        subject: subject,
        body: body,
        contactList: contactList
      }
    } else {
      this._data[`draft_${userId}`].documentShareDraft = {
        [documentId]: {
          subject: subject,
          body: body,
          contactList: contactList
        }
      }
    }
  }

  // サイト共有メール
  // サイト共有メールの下書き情報を保存する。
  public saveSiteShareDraft(
    userId: string,
    siteId: string,
    subject: string,
    body: string,
    contactList: SelectorItemWithType[]
  ) {
    this.convertSiteShareDraft(userId, siteId, subject, body, contactList)
    this._save()
  }
  // IDから資料共有メールの下書き情報を取得する。
  public getSiteShareDraftBySiteId(userId: string, siteId: string) {
    if (!this._getItem(userId)) return
    const { siteShareDraft } = this._getItem(userId)
    return siteShareDraft?.[siteId]
  }
  // サイト共有メールの下書き情報タイトルを保存する。
  public saveSiteShareDraftSubject(userId: string, siteId: string, subject: string) {
    this.convertSiteShareDraftSubject(userId, siteId, subject)
    this._save()
  }
  // サイト共有メールの下書き情報タイトルをlocalStorageに保存する形に整形する
  private convertSiteShareDraftSubject(userId: string, siteId: string, subject: string) {
    const siteShareDraft = this._data[`draft_${userId}`]?.siteShareDraft
    const draft = siteShareDraft?.[siteId]
    if (siteShareDraft) {
      siteShareDraft[siteId] = {
        subject: subject,
        body: draft?.body ?? '',
        contactList: draft?.contactList ?? []
      }
    } else {
      this._data[`draft_${userId}`].siteShareDraft = {
        [siteId]: {
          subject: subject,
          body: draft?.body ?? '',
          contactList: draft?.contactList ?? []
        }
      }
    }
  }
  // サイト共有メールの下書き情報本文を保存する。
  public saveSiteShareDraftBody(userId: string, siteId: string, body: string) {
    this.convertSiteShareDraftBody(userId, siteId, body)
    this._save()
  }
  // サイト共有メールの下書き情報本文をlocalStorageに保存する形に整形する
  private convertSiteShareDraftBody(userId: string, siteId: string, body: string) {
    const siteShareDraft = this._data[`draft_${userId}`]?.siteShareDraft
    const draft = siteShareDraft?.[siteId]
    if (siteShareDraft) {
      siteShareDraft[siteId] = {
        subject: draft?.subject ?? '',
        body: body,
        contactList: draft?.contactList ?? []
      }
    } else {
      this._data[`draft_${userId}`].siteShareDraft = {
        [siteId]: {
          subject: draft?.subject ?? '',
          body: body,
          contactList: draft?.contactList ?? []
        }
      }
    }
  }
  // サイト共有メールの下書き情報宛先を保存する。
  public saveSiteShareDraftContactList(userId: string, siteId: string, contactList: SelectorItemWithType[]) {
    this.convertSiteShareDraftContactList(userId, siteId, contactList)
    this._save()
  }
  // サイト共有メールの下書き情報宛先をlocalStorageに保存する形に整形する
  private convertSiteShareDraftContactList(userId: string, siteId: string, contactList: SelectorItemWithType[]) {
    const siteShareDraft = this._data[`draft_${userId}`]?.siteShareDraft
    const draft = siteShareDraft?.[siteId]
    if (siteShareDraft) {
      siteShareDraft[siteId] = {
        subject: draft?.subject ?? '',
        body: draft?.body ?? '',
        contactList: contactList
      }
    } else {
      this._data[`draft_${userId}`].siteShareDraft = {
        [siteId]: {
          subject: draft?.subject ?? '',
          body: draft?.body ?? '',
          contactList: contactList
        }
      }
    }
  }
  // サイト共有メールの下書き情報を削除する。
  public removeSiteShareDraftBySiteId(userId: string, siteId: string) {
    const { siteShareDraft } = this._getItem(userId)
    delete siteShareDraft?.[siteId]
    this._save()
  }
  // サイト共有メールの下書き情報をlocalStorageに保存する形に整形する
  private convertSiteShareDraft(
    userId: string,
    siteId: string,
    subject: string,
    body: string,
    contactList: SelectorItemWithType[]
  ) {
    const siteShareDraft = this._data[`draft_${userId}`]?.siteShareDraft
    if (siteShareDraft) {
      siteShareDraft[siteId] = {
        subject: subject,
        body: body,
        contactList: contactList
      }
    } else {
      this._data[`draft_${userId}`].siteShareDraft = {
        [siteId]: {
          subject: subject,
          body: body,
          contactList: contactList
        }
      }
    }
  }
}
