import { Box, createStyles, makeStyles, ButtonBase } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Pallete, SvgIcon, DynamicMuiIcon, Colors, Typography } from 'src/components/atoms'

const useStyles = makeStyles(() => {
  return createStyles({
    wordBreak: {
      wordBreak: 'break-all'
    },
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    },
    labelColor: {
      color: Colors.background.silver
    }
  })
})

export interface ProfileCardProps {
  userName?: string | null
  userAvatarUrl?: string | null
  birthplace?: string | null
  department?: string | null
  hobby?: string | null
  organizationName?: string
  position?: string | null
  selfIntroduction?: string | null
  socialLinkYoutube?: string | null
  socialLinkFacebook?: string | null
  socialLinkTwitter?: string | null
  socialLinkNote?: string | null
  socialLinkWebSite?: string | null
}

export const ProfileCard = ({
  birthplace,
  department,
  hobby,
  organizationName,
  position,
  selfIntroduction,
  socialLinkYoutube,
  socialLinkFacebook,
  socialLinkTwitter,
  socialLinkNote,
  socialLinkWebSite,
  userName,
  userAvatarUrl
}: ProfileCardProps) => {
  const classes = useStyles()
  const rows = useMemo(
    () => [
      { label: '所属部門', data: department },
      { label: '役職', data: position },
      { label: '出身地', data: birthplace },
      { label: '趣味', data: hobby }
    ],
    [department, position, birthplace, hobby]
  )

  const socialLinkList: Array<{ url: string | null | undefined; icon: React.ReactNode }> = useMemo(
    () => [
      { url: socialLinkYoutube, icon: <DynamicMuiIcon variant="youTube" size="16px" color="inherit" /> },
      {
        url: socialLinkFacebook,
        icon: <SvgIcon variant="facebook" size="16px" color={Pallete.functional.background.default} />
      },
      { url: socialLinkTwitter, icon: <DynamicMuiIcon variant="twitter" size="16px" color="inherit" /> },
      {
        url: socialLinkNote,
        icon: <SvgIcon variant="note" size="16px" color={Pallete.functional.background.default} />
      },
      { url: socialLinkWebSite, icon: <DynamicMuiIcon variant="link" size="16px" color="inherit" /> }
    ],
    [socialLinkYoutube, socialLinkFacebook, socialLinkTwitter, socialLinkNote, socialLinkWebSite]
  )

  return (
    <Box
      borderRadius="10px"
      bgcolor={Colors.base.navyBlue}
      p={4}
      maxWidth="280px"
      color={Pallete.functional.whiteText.default}
    >
      <Box display="flex">
        <Box width="52px" height="52px" mb={5} mr={3} position="relative" flexShrink="0">
          {userAvatarUrl ? (
            <img src={userAvatarUrl} className={classes.img} />
          ) : (
            <SvgIcon variant="avatar" size="52px" color={Pallete.functional.background.default} />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          mb={3}
          width="100%"
          paddingRight="26px"
          className={classes.wordBreak}
        >
          <Typography variant="subtitle2" lh="tight">
            {organizationName}
          </Typography>
          <Box mt="6px" />
          <Typography fontSize="m" fontWeight="bold" lh="none">
            {userName}
          </Typography>
          <Box display="flex" mt="6px">
            {socialLinkList.map(({ url, icon }, i) => {
              if (!url) {
                return null
              }
              return (
                <Box key={i} mr="16px" display="flex" alignItems="center">
                  <ButtonBase onClick={() => window.open(url, '_blank')} style={{ pointerEvents: 'auto' }}>
                    {icon}
                  </ButtonBase>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
      {rows.map((row, i) => {
        if (!row.data) {
          return null
        }
        return (
          <Box
            key={i}
            display="flex"
            alignItems="center"
            mb="12px"
            borderBottom={`solid 1px ${Colors.base.placeHolder}`}
            padding="4px 0px"
          >
            <Box width="74px" mr="10px">
              {/* xsのサイズ指定が効かない */}
              <Typography variant="subtitle2" lh="tight" className={classes.labelColor}>
                {row.label}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography fontSize="s" lh="tight">
                {row.data}
              </Typography>
            </Box>
          </Box>
        )
      })}
      {selfIntroduction && (
        <Box display="flex" flexDirection="column" className={classes.wordBreak}>
          <Box width="74px" mb="2px">
            <Typography variant="subtitle2" lh="none" className={classes.labelColor}>
              自己紹介
            </Typography>
          </Box>
          <Typography fontSize="s" lh="relaxed">
            {selfIntroduction}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
