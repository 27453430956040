/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateSiteSectionRequestSiteSection
 */
export interface UpdateSiteSectionRequestSiteSection {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteSectionRequestSiteSection
   */
  name?: string
}

export function UpdateSiteSectionRequestSiteSectionFromJSON(json: any): UpdateSiteSectionRequestSiteSection {
  return UpdateSiteSectionRequestSiteSectionFromJSONTyped(json, false)
}

export function UpdateSiteSectionRequestSiteSectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSiteSectionRequestSiteSection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name']
  }
}

export function UpdateSiteSectionRequestSiteSectionToJSON(value?: UpdateSiteSectionRequestSiteSection | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name
  }
}
