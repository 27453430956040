/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface FilterStaffPins
 */
export interface FilterStaffPins {
  /**
   *
   * @type {number}
   * @memberof FilterStaffPins
   */
  counts?: number | null
  /**
   *
   * @type {string}
   * @memberof FilterStaffPins
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof FilterStaffPins
   */
  text?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof FilterStaffPins
   */
  avatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {boolean}
   * @memberof FilterStaffPins
   */
  isPin?: boolean
}

export function FilterStaffPinsFromJSON(json: any): FilterStaffPins {
  return FilterStaffPinsFromJSONTyped(json, false)
}

export function FilterStaffPinsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterStaffPins {
  if (json === undefined || json === null) {
    return json
  }
  return {
    counts: !exists(json, 'counts') ? undefined : json['counts'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    text: !exists(json, 'text') ? undefined : json['text'],
    avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
    isPin: !exists(json, 'is_pin') ? undefined : json['is_pin']
  }
}

export function FilterStaffPinsToJSON(value?: FilterStaffPins | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    counts: value.counts,
    user_id: value.userId,
    text: value.text,
    avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
    is_pin: value.isPin
  }
}
