/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption
 */
export interface GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption
   */
  bundledUserQuantity: number
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption
   */
  currentAddonQuantity: number
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption
   */
  usageUserCount: number
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption
   */
  unitAmount: number
}

export function GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionFromJSON(
  json: any
): GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption {
  return GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionFromJSONTyped(json, false)
}

export function GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    bundledUserQuantity: json['bundled_user_quantity'],
    currentAddonQuantity: json['current_addon_quantity'],
    usageUserCount: json['usage_user_count'],
    unitAmount: json['unit_amount']
  }
}

export function GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionToJSON(
  value?: GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    bundled_user_quantity: value.bundledUserQuantity,
    current_addon_quantity: value.currentAddonQuantity,
    usage_user_count: value.usageUserCount,
    unit_amount: value.unitAmount
  }
}
