import { Box, makeStyles, Popover, createStyles, ClickAwayListener } from '@material-ui/core'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { SearchAndSuggestList } from './SearchAndSuggestList'

export interface SearchAndSuggestProps {
  type: 'contact' | 'company' | 'inbox'
  width?: string
  onClick: (id: string) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    paper: {
      width: '580px',
      height: '670px',
      border: `1px solid ${Colors.background.silver}`,
      borderRadius: '10px',
      boxShadow:
        '0px 12px 60px rgba(36, 31, 71, 0.15), 0px 16px 24px rgba(36, 31, 71, 0.1), 0px 4px 8px rgba(36, 31, 71, 0.08), 0px 0px 1px rgba(36, 31, 71, 0.08)'
    }
  })
})

export const SearchAndSuggest = ({ width, onClick, type }: SearchAndSuggestProps) => {
  const classes = useStyles()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [currentPageForSearch, setCurrentPageForSearch] = useState(1)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setIsOpen] = useState(false)
  const id = open ? 'simple-popover' : undefined

  const position = useMemo(() => {
    if (!anchorEl) return
    const rect = anchorEl.getBoundingClientRect()
    return {
      top: rect.top,
      left: rect.left
    }
  }, [anchorEl])

  const handleOpenOptionList = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setIsOpen(true)
  }, [])

  const handleCloseOptionList = useCallback(() => {
    setIsOpen(false)
    setSearchKeyword('')
  }, [])

  useEffect(() => {
    if (searchKeyword) {
      setCurrentPageForSearch(1)
    }
  }, [searchKeyword])

  return (
    <Box width={width}>
      <Box
        width={1}
        height="34px"
        border={`1px solid ${Colors.background.silver}`}
        borderRadius="4px"
        pl="14px"
        pr="4px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="inset 0px 1px 2px rgba(10, 10, 10, 0.1)"
        onClick={handleOpenOptionList}
      >
        <Box display="flex" alignItems="center" height="auto">
          <Box color={Colors.base.middleGray} display="flex" alignItems="center" mr="8px">
            <DynamicMuiIcon variant="search" size="20px" color="inherit" fontSize="inherit" />
          </Box>
          <Box color={Colors.base.placeHolder}>
            <Typography fontSize="xs" lineheight="12px">
              検索
            </Typography>
          </Box>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseOptionList}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorReference="anchorPosition"
        anchorPosition={position}
        PaperProps={{
          className: classes.paper
        }}
      >
        {open && (
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <SearchAndSuggestList
              currentPage={currentPageForSearch}
              keyword={searchKeyword}
              type={type}
              onClick={onClick}
              onClose={() => setIsOpen(false)}
              onCurrentPageChange={setCurrentPageForSearch}
              onSearchKeyword={setSearchKeyword}
            />
          </ClickAwayListener>
        )}
      </Popover>
    </Box>
  )
}

export const MemoizedSearchAndSuggest = memo(SearchAndSuggest)
