/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumOrganizationPolicySettingName = {
  TermOfService: 'term_of_service',
  PrivacyPolicy: 'privacy_policy',
  CookiePolicy: 'cookie_policy',
  GuidelinesInformationSecurity: 'guidelines_information_security'
} as const
export type EnumOrganizationPolicySettingName =
  typeof EnumOrganizationPolicySettingName[keyof typeof EnumOrganizationPolicySettingName]

export function EnumOrganizationPolicySettingNameFromJSON(json: any): EnumOrganizationPolicySettingName {
  return EnumOrganizationPolicySettingNameFromJSONTyped(json, false)
}

export function EnumOrganizationPolicySettingNameFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumOrganizationPolicySettingName {
  return json as EnumOrganizationPolicySettingName
}

export function EnumOrganizationPolicySettingNameToJSON(value?: EnumOrganizationPolicySettingName | null): any {
  return value as any
}
