import { Avatar, Box, createStyles, makeStyles } from '@material-ui/core'
import { ChatMessage } from '@noco/http-client/lib/noco'
import React from 'react'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { useGlobalStyles } from 'src/styles/theme'
import { Button, Colors, DynamicMuiIcon, Typography } from '..'
import { LinkParser } from '../LinkParser'

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      width: '36px',
      height: '36px',
      marginRight: '8px'
    }
  })
)

export interface MenuListItemChatTypeProps {
  chatMessage: ChatMessage
  isEU?: boolean
  avatar?: string
  title?: string
  body?: string
  buttonColor?: string
  onClick?: () => void
}

export const MenuListItemChatType = ({ chatMessage, isEU, onClick, buttonColor }: MenuListItemChatTypeProps) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  return (
    <Box
      p="18px"
      borderRadius="10px"
      border={`1px solid ${Colors.background.gray}`}
      bgcolor={Colors.background.lightGray}
    >
      <Box display="flex" alignItems="center" color={Colors.base.middleGray}>
        <DynamicMuiIcon variant="send" size="16px" color="inherit" />
        <Box pl="8px" color={Colors.base.black}>
          <Typography variant="subtitle2">チャット</Typography>
        </Box>
      </Box>
      <Box display="flex" mt="8px">
        {!isEU && chatMessage.sendedBy?.avatar?.url ? (
          <Box width="36px" height="36px" flexShrink={0} borderRadius="50%" overflow="hidden" mr="8px">
            <img src={chatMessage.sendedBy?.avatar?.url} alt="avatar" className={globalClasses.imgCover} />
          </Box>
        ) : (
          <Avatar className={classes.avatar} />
        )}
        <Box whiteSpace="pre-wrap">
          <Box display="flex">
            <Typography fontSize="xs" fontWeight="bold" style={{ marginRight: '4px' }}>
              {!isEU ? chatMessage.sendedBy?.displayName : 'あなたのチャット'}
            </Typography>

            <Typography fontSize="xs" fontWeight="normal" style={{ color: '#6D7784' }}>
              {chatMessage.createdAt
                ? dateFormat(dateStringToMilliseconds(chatMessage.createdAt), DateFormat.DATE_WITH_DAY)
                : ''}
            </Typography>
          </Box>
          <Typography variant="body2" lineheight="1.3">
            <Typography variant="body2" style={{ wordBreak: 'break-all' }}>
              <LinkParser text={chatMessage.message ?? ''} />
            </Typography>
          </Typography>
        </Box>
      </Box>
      {onClick && (
        <Box display="flex" justifyContent="center" mt="12px">
          <Button title="チャットをはじめる" width="264px" onClick={onClick} buttonColor={buttonColor} />
        </Box>
      )}
    </Box>
  )
}
