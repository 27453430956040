/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  DocumentContact,
  DocumentContactFromJSON,
  DocumentContactFromJSONTyped,
  DocumentContactToJSON
} from './DocumentContact'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetDocumentContactsResponseData
 */
export interface GetDocumentContactsResponseData {
  /**
   *
   * @type {Array<DocumentContact>}
   * @memberof GetDocumentContactsResponseData
   */
  documentContacts?: Array<DocumentContact>
  /**
   *
   * @type {PageInfo}
   * @memberof GetDocumentContactsResponseData
   */
  pageInfo?: PageInfo
}

export function GetDocumentContactsResponseDataFromJSON(json: any): GetDocumentContactsResponseData {
  return GetDocumentContactsResponseDataFromJSONTyped(json, false)
}

export function GetDocumentContactsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentContactsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentContacts: !exists(json, 'document_contacts')
      ? undefined
      : (json['document_contacts'] as Array<any>).map(DocumentContactFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetDocumentContactsResponseDataToJSON(value?: GetDocumentContactsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_contacts:
      value.documentContacts === undefined
        ? undefined
        : (value.documentContacts as Array<any>).map(DocumentContactToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
