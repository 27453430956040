/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  token?: string
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  password?: string
}

export function ResetPasswordRequestFromJSON(json: any): ResetPasswordRequest {
  return ResetPasswordRequestFromJSONTyped(json, false)
}

export function ResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    token: !exists(json, 'token') ? undefined : json['token'],
    password: !exists(json, 'password') ? undefined : json['password']
  }
}

export function ResetPasswordRequestToJSON(value?: ResetPasswordRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    token: value.token,
    password: value.password
  }
}
