/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumRecordPorterHistoryAction = {
    NextPlayImport: 'next_play_import',
    NextPlayBulkUpdate: 'next_play_bulk_update',
    FileDownload: 'file_download',
    ErrorFileDownload: 'error_file_download',
    ResultFileDownload: 'result_file_download'
};
export function EnumRecordPorterHistoryActionFromJSON(json) {
    return EnumRecordPorterHistoryActionFromJSONTyped(json, false);
}
export function EnumRecordPorterHistoryActionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumRecordPorterHistoryActionToJSON(value) {
    return value;
}
