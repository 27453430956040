/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function CompanyMemoFromJSON(json) {
    return CompanyMemoFromJSONTyped(json, false);
}
export function CompanyMemoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        companyId: !exists(json, 'company_id') ? undefined : json['company_id'],
        text: !exists(json, 'text') ? undefined : json['text'],
        discardedAt: !exists(json, 'discarded_at') ? undefined : json['discarded_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
        updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by'])
    };
}
export function CompanyMemoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        company_id: value.companyId,
        text: value.text,
        discarded_at: value.discardedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        created_by: UserForDisplayToJSON(value.createdBy),
        updated_by: UserForDisplayToJSON(value.updatedBy)
    };
}
