import { Box } from '@material-ui/core'
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { SelectTableRow, CustomButtonListType } from 'src/components/molecules'
import { TabValueType } from 'src/components/pages/PageInbox'
import { useChatSummariesReplied, useChatSummariesChangeUser } from 'src/fixtures/modules/chatSummary/hooks'
import {
  useContactsEmailTransactionsChangeUser,
  useContactsEmailTransactionsIgnoreErrors
} from 'src/fixtures/modules/contactEmailTransaction/hooks'
import { UserV1ChatSummariesGetRequest, EnumChatSummaryRepliedStatus } from '@noco/http-client/lib/noco'
import { ModalChangeUser } from 'src/components/modals'

export interface ShowDialogProps {
  selectedIds: string[]
  setSelection: (value: number[]) => void
  tabValue: TabValueType
  params: UserV1ChatSummariesGetRequest
  page: number
  per: number
  value: string
  onStartLoading?: () => void
  onEndLoading?: () => void
}

export const ShowDialog = ({
  selectedIds,
  tabValue,
  setSelection,
  params,
  page,
  per,
  value,
  onStartLoading,
  onEndLoading
}: ShowDialogProps) => {
  const [openModalChangeUser, setOpenModalChangeUser] = useState(false)
  const [userId, setUserId] = useState(value)

  // status変更
  const { handleUpdateChatSummariesReplied } = useChatSummariesReplied({
    ...params,
    page: page + 1,
    per
  })

  const handleUpdateStatus = useCallback(
    (repliedStatus: EnumChatSummaryRepliedStatus) => () => {
      onStartLoading?.()
      handleUpdateChatSummariesReplied(selectedIds, repliedStatus).then(() => onEndLoading?.())
      setSelection([])
    },
    [onStartLoading, handleUpdateChatSummariesReplied, selectedIds, setSelection, onEndLoading]
  )

  // 担当者変更
  const { handleUpdateChatSummariesChangeUser } = useChatSummariesChangeUser(
    {
      ...params,
      page: page + 1,
      per
    },
    tabValue
  )
  const handleChangeUser = useCallback(() => {
    onStartLoading?.()
    handleUpdateChatSummariesChangeUser(selectedIds, userId).then(() => onEndLoading?.())
    setSelection([])
  }, [onStartLoading, handleUpdateChatSummariesChangeUser, selectedIds, userId, setSelection, onEndLoading])

  // 送信エラーを無視
  const { handleUpdateContactsEmailTransactionsIgnoreErrors } = useContactsEmailTransactionsIgnoreErrors({
    ...params,
    page: page + 1,
    per
  })
  const handleUpdateIgnoreErrors = useCallback(() => {
    onStartLoading?.()
    handleUpdateContactsEmailTransactionsIgnoreErrors(selectedIds).then(() => onEndLoading?.())
    setSelection([])
  }, [onStartLoading, handleUpdateContactsEmailTransactionsIgnoreErrors, selectedIds, setSelection, onEndLoading])

  // 送信エラーの担当者変更
  const { handleUpdateContactsEmailTransactionsChangeUser } = useContactsEmailTransactionsChangeUser({
    ...params,
    page: page + 1,
    per
  })
  const handleSendErrorChangeUser = useCallback(() => {
    onStartLoading?.()
    handleUpdateContactsEmailTransactionsChangeUser(selectedIds, userId).then(() => onEndLoading?.())
    setSelection([])
  }, [onStartLoading, handleUpdateContactsEmailTransactionsChangeUser, selectedIds, userId, setSelection, onEndLoading])

  const handleCloseModal = useCallback(() => {
    setOpenModalChangeUser(false)
    setSelection([])
  }, [setSelection])

  const handleConfirmChangeUser = useMemo(() => {
    if (tabValue === 'error') {
      return handleSendErrorChangeUser
    } else {
      return handleChangeUser
    }
  }, [tabValue, handleChangeUser, handleSendErrorChangeUser])

  const handleConfirm = useCallback(() => {
    handleConfirmChangeUser()
    handleCloseModal()
    setUserId(value)
  }, [handleCloseModal, handleConfirmChangeUser, value])

  const customButtonList: CustomButtonListType[] = useMemo(() => {
    if (tabValue === 'incoming') {
      return [
        {
          showIcon: 'notification',
          buttonTitle: '未対応に変更',
          onConfirm: handleUpdateStatus(EnumChatSummaryRepliedStatus.Unresponsive)
        },
        {
          showIcon: 'doDisturbAlt',
          buttonTitle: '返信不要に変更',
          onConfirm: handleUpdateStatus(EnumChatSummaryRepliedStatus.NoReplyRequired)
        },
        {
          showIcon: 'checkCircle',
          buttonTitle: '返信済みに変更',
          onConfirm: handleUpdateStatus(EnumChatSummaryRepliedStatus.Sent)
        },
        { showIcon: 'hail', buttonTitle: '担当者を変更', onConfirm: () => setOpenModalChangeUser(true) }
      ]
    } else if (tabValue === 'send') {
      return [{ showIcon: 'hail', buttonTitle: '担当者を変更', onConfirm: () => setOpenModalChangeUser(true) }]
    } else {
      return [
        {
          showIcon: 'removeCircleOutline',
          buttonTitle: '送信エラーを無視',
          onConfirm: handleUpdateIgnoreErrors
        },
        { showIcon: 'hail', buttonTitle: '担当者を変更', onConfirm: () => setOpenModalChangeUser(true) }
      ]
    }
  }, [tabValue, handleUpdateStatus, handleUpdateIgnoreErrors])

  useEffect(() => {
    if (value) {
      setUserId(value)
    }
  }, [value])

  if (selectedIds.length == 0) {
    return null
  }
  return (
    <Box
      position="absolute"
      bottom="60px"
      px="16px"
      width="calc(100% - 235px)"
      bgcolor="transparent"
      zIndex={999}
      right={0}
    >
      <SelectTableRow
        count={selectedIds.length}
        selectedIds={selectedIds}
        onClose={() => setSelection([])}
        customButtonList={customButtonList}
      />
      <ModalChangeUser
        open={openModalChangeUser}
        count={selectedIds.length}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        title="担当者を変更"
        value={userId}
        onChangeUser={setUserId}
      />
    </Box>
  )
}
