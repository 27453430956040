import { Box } from '@material-ui/core'
import { EnumActivityStatus } from '@noco/http-client/lib/noco'
import React from 'react'
import { Colors, DynamicMuiIcon, MuiIconVariant, Typography } from 'src/components/atoms'

export interface ActivityStatusCellProps {
  value: EnumActivityStatus
}

const labelList: { [key in EnumActivityStatus]: labelData } = {
  notyet: { icon: 'email', label: '未閲覧' },
  chat: { icon: 'chatBubble', label: 'チャット' },
  first_view: { icon: 'drafts', label: '初回閲覧' },
  view: { icon: 'drafts', label: '閲覧' },
  opportunity: { icon: 'meeting', label: 'アポイント' },
  cta: { icon: 'circle', label: 'CTA' },
  download: { icon: 'fileDownload', label: 'ダウンロード' },
  view_profile: { icon: 'accessTime', label: 'プロフィール表示' }
}

interface labelData {
  icon: MuiIconVariant
  label: string
}

export const ActivityStatusCell = ({ value }: ActivityStatusCellProps) => {
  return (
    <Box display="flex" alignItems="center" py="10px">
      <Box
        display="flex"
        alignItems="center"
        px="9px"
        bgcolor={Colors.label.blue}
        borderRadius="4px"
        color={Colors.base.navyBlue}
      >
        <DynamicMuiIcon variant={labelList?.[value]?.icon} size="16px" />
        <Box width="8px" />
        <Typography fontSize="s" fontWeight="bold">
          {labelList[value]?.label}
        </Typography>
      </Box>
    </Box>
  )
}
