import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Setting = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.25 8.99984C15.25 8.80817 15.2417 8.62484 15.225 8.43317L16.775 7.25817C17.1083 7.00817 17.2 6.5415 16.9917 6.17484L15.4333 3.48317C15.225 3.1165 14.775 2.9665 14.3917 3.13317L12.6 3.8915C12.2917 3.67484 11.9667 3.48317 11.625 3.32484L11.3833 1.39984C11.3333 0.983171 10.975 0.666504 10.5583 0.666504H7.45C7.025 0.666504 6.66667 0.983171 6.61667 1.39984L6.375 3.32484C6.03334 3.48317 5.70834 3.67484 5.4 3.8915L3.60834 3.13317C3.225 2.9665 2.775 3.1165 2.56667 3.48317L1.00834 6.18317C0.800003 6.54984 0.89167 7.00817 1.225 7.2665L2.775 8.4415C2.75834 8.62484 2.75 8.80817 2.75 8.99984C2.75 9.1915 2.75834 9.37484 2.775 9.5665L1.225 10.7415C0.89167 10.9915 0.800003 11.4582 1.00834 11.8248L2.56667 14.5165C2.775 14.8832 3.225 15.0332 3.60834 14.8665L5.4 14.1082C5.70834 14.3248 6.03334 14.5165 6.375 14.6748L6.61667 16.5998C6.66667 17.0165 7.025 17.3332 7.44167 17.3332H10.55C10.9667 17.3332 11.325 17.0165 11.375 16.5998L11.6167 14.6748C11.9583 14.5165 12.2833 14.3248 12.5917 14.1082L14.3833 14.8665C14.7667 15.0332 15.2167 14.8832 15.425 14.5165L16.9833 11.8248C17.1917 11.4582 17.1 10.9998 16.7667 10.7415L15.2167 9.5665C15.2417 9.37484 15.25 9.1915 15.25 8.99984ZM9.03334 11.9165C7.425 11.9165 6.11667 10.6082 6.11667 8.99984C6.11667 7.3915 7.425 6.08317 9.03334 6.08317C10.6417 6.08317 11.95 7.3915 11.95 8.99984C11.95 10.6082 10.6417 11.9165 9.03334 11.9165Z" />
    </SvgIcon>
  )
}
export default Setting
