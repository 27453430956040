/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateVisitorRequest
 */
export interface CreateVisitorRequest {
  /**
   *
   * @type {string}
   * @memberof CreateVisitorRequest
   */
  organizationId: string
}

export function CreateVisitorRequestFromJSON(json: any): CreateVisitorRequest {
  return CreateVisitorRequestFromJSONTyped(json, false)
}

export function CreateVisitorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateVisitorRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationId: json['organization_id']
  }
}

export function CreateVisitorRequestToJSON(value?: CreateVisitorRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_id: value.organizationId
  }
}
