import React from 'react'
import { Box } from '@material-ui/core'
import { LabelFormRow } from 'src/components/molecules'
import { Colors, Password, Typography } from 'src/components/atoms'
import { useFormik } from 'formik'
import { useUpdateMePassword } from 'src/fixtures/modules/me/hooks'

export interface PasswordRowProps {}

export const PasswordRow = (_: PasswordRowProps) => {
  const { handleUpdateMePassword } = useUpdateMePassword()
  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: { currentPassword: '', newPassword: '' },

    onSubmit: values => {
      handleUpdateMePassword({
        currentPassword: values.currentPassword,
        user: {
          password: values.newPassword,
          passwordConfirmation: values.newPassword
        }
      })
      values.currentPassword = ''
      values.newPassword = ''
    }
  })

  return (
    <Box borderBottom={`1px solid ${Colors.background.silver}`} py="16px">
      <LabelFormRow
        label="パスワード"
        onSave={() => handleSubmit()}
        onCancel={() => {}}
        readElement={
          <Box display="flex" alignItems="center">
            <Typography fontSize="s" lh="tight">
              ・・・・・・・・
            </Typography>
          </Box>
        }
        editElement={
          <Box display="flex" alignItems="center">
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="column" pb="8px">
                <Box mb="4px">
                  <Typography fontSize="s" fontWeight="bold" lh="tight">
                    現在のパスワード
                  </Typography>
                </Box>
                <Password
                  width="260px"
                  name="currentPassword"
                  password={values.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <Box mb="4px">
                  <Typography fontSize="s" fontWeight="bold" lh="tight">
                    新しいパスワード
                  </Typography>
                </Box>
                <Password
                  width="260px"
                  name="newPassword"
                  password={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Box color={Colors.base.middleGray}>
                  <Typography fontSize="xs">アルファベットと数字と記号を含む12文字以上で設定してください</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />
    </Box>
  )
}
