/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ArchiveDocumentsRequest
 */
export interface ArchiveDocumentsRequest {
  /**
   *
   * @type {boolean}
   * @memberof ArchiveDocumentsRequest
   */
  archive?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof ArchiveDocumentsRequest
   */
  ids?: Array<string>
}

export function ArchiveDocumentsRequestFromJSON(json: any): ArchiveDocumentsRequest {
  return ArchiveDocumentsRequestFromJSONTyped(json, false)
}

export function ArchiveDocumentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveDocumentsRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    archive: !exists(json, 'archive') ? undefined : json['archive'],
    ids: !exists(json, 'ids') ? undefined : json['ids']
  }
}

export function ArchiveDocumentsRequestToJSON(value?: ArchiveDocumentsRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    archive: value.archive,
    ids: value.ids
  }
}
