import { useMediaQuery, Box, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { DocumentSlideDetail } from 'src/components/organisms/DocumentSlideDetail'
import { useGetCotnactDocument } from 'src/fixtures/modules/contactDocument/hooks'
import { useGetOrganizationPolicySettingSubdomain } from 'src/fixtures/modules/contactOrganization/hooks'
import { useGetVisitor } from 'src/fixtures/modules/visitor/hooks'
import { getSubdomainFromHost } from 'src/fixtures/utils/url'

export interface PageDocumentShareWithEmailProps {
  documentId: string
  siteId?: string
}

export const PageDocumentShareWithEmail = ({ documentId, siteId }: PageDocumentShareWithEmailProps) => {
  const theme = useTheme()
  const isPCView = useMediaQuery(theme.breakpoints.up('sm'))
  const { data } = useGetCotnactDocument(documentId, siteId)
  const document = data?.document
  const organization = data?.organization
  const canRequestToExtendExpiredAt = data?.canRequestToExtendExpiredAt
  const { data: visitor } = useGetVisitor()
  const [subdomain, setSubdomain] = useState('')
  const { data: policySettings } = useGetOrganizationPolicySettingSubdomain(subdomain)

  useEffect(() => {
    const subd = getSubdomainFromHost(window.location.host)
    if (subd) setSubdomain(subd)
  }, [])

  return (
    <>
      <Box width="100vw" height={isPCView ? '100vh' : '100%'}>
        {document && organization && visitor?.hasContact && (
          <DocumentSlideDetail
            isActivated={visitor?.hasContact}
            document={document}
            isPCView={isPCView}
            organization={organization}
            visitor={visitor}
            canRequestToExtendExpiredAt={canRequestToExtendExpiredAt}
            siteId={siteId}
            policySettings={policySettings}
            expiredOn={data.expiredOn}
          />
        )}
      </Box>
    </>
  )
}
