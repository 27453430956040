/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function UpdateOrganizationPaymentDiscountRequestFromJSON(json) {
    return UpdateOrganizationPaymentDiscountRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationPaymentDiscountRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        couponCode: json['coupon_code']
    };
}
export function UpdateOrganizationPaymentDiscountRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        coupon_code: value.couponCode
    };
}
