import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const LeftArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5001 8.22917L2.26676 4.99583L5.5001 1.7625C5.8251 1.4375 5.8251 0.9125 5.5001 0.5875C5.1751 0.2625 4.6501 0.2625 4.3251 0.5875L0.500098 4.4125C0.175098 4.7375 0.175098 5.2625 0.500098 5.5875L4.3251 9.4125C4.6501 9.7375 5.1751 9.7375 5.5001 9.4125C5.81676 9.0875 5.8251 8.55417 5.5001 8.22917Z" />
    </SvgIcon>
  )
}
export default LeftArrow
