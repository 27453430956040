import { Box } from '@material-ui/core'
import { EnumSupportStatus } from '@noco/http-client/lib/noco'
import React from 'react'
import { Colors, DynamicMuiIcon, MuiIconVariant, Typography } from 'src/components/atoms'

export interface SupportStatusCellProps {
  value: EnumSupportStatus
}

const labelList: { [key: string]: labelData } = {
  solved: {
    icon: 'checkCircle',
    label: '返信済み',
    iconColor: Colors.base.middleGray,
    textColor: Colors.base.middleGray
  },
  unsolved: {
    icon: 'checkCircleOutline',
    label: '未対応',
    iconColor: Colors.base.placeHolder,
    textColor: Colors.base.middleGray
  },
  send_email_error: {
    icon: 'errorOutlineOutlined',
    label: '送信エラー',
    iconColor: Colors.accent.keyRed.default,
    textColor: Colors.accent.keyRed.default
  }
}

interface labelData {
  icon: MuiIconVariant
  label: string
  iconColor: string
  textColor: string
}

export const SupportStatusCell = ({ value }: SupportStatusCellProps) => {
  return (
    <Box display="flex" alignItems="center" color={labelList[value].textColor}>
      <Box color={labelList[value].iconColor} mr="4px" display="flex" alignItems="center">
        <DynamicMuiIcon variant={labelList[value].icon} size="20px" color="inherit" />
      </Box>
      <Typography fontSize="s" fontWeight="bold">
        {labelList[value].label}
      </Typography>
    </Box>
  )
}
