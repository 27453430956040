/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ArchiveDocumentsRequestToJSON, ArchiveDocumentsResponseFromJSON, CopyDocumentRequestToJSON, CopyDocumentResponseFromJSON, CreateDocumentOpportunityUsersResponseFromJSON, CreateDocumentOpporunityUserRequestToJSON, CreateDocumentPublishRequestToJSON, CreateDocumentPublishResponseFromJSON, CreateDocumentShareWithContactRequestToJSON, CreateDocumentShareWithContactResponseFromJSON, FileUpdateDocumentResponseFromJSON, FileUploadDocumentResponseFromJSON, GetDocumentContactsResponseFromJSON, GetDocumentMiscSettingResponseFromJSON, GetDocumentOpportunityUsersResponseFromJSON, GetDocumentResponseFromJSON, GetDocumentSitesResponseFromJSON, GetDocumentViewHistoriesGraphResponseFromJSON, GetDocumentViewHistoriesGraphResponse1FromJSON, GetDocumentViewHistoriesResponseFromJSON, GetDocumentViewHistorySessionsResponseFromJSON, GetDocumentsResponseFromJSON, GetSettableDocumentOpportunityUsersResponseFromJSON, UpdateDocumentChatSettingRequestToJSON, UpdateDocumentChatSettingResponseFromJSON, UpdateDocumentCtaImageResponseFromJSON, UpdateDocumentCtaRequestToJSON, UpdateDocumentCtaResponseFromJSON, UpdateDocumentMessageRequestToJSON, UpdateDocumentMessageResponseFromJSON, UpdateDocumentMiscSettingRequestToJSON, UpdateDocumentMiscSettingResponseFromJSON, UpdateDocumentOpportunityRequestToJSON, UpdateDocumentOpportunityResponseFromJSON, UpdateDocumentRequestToJSON, UpdateDocumentResponseFromJSON, UpdateDocumentShareByLinkRequestToJSON, UpdateDocumentShareByLinkResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1DocumentApi extends runtime.BaseAPI {
    /**
     * 資料のアーカイブ設定
     * archive
     */
    async userV1DocumentsArchivePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/archive`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveDocumentsRequestToJSON(requestParameters.archiveDocumentsRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ArchiveDocumentsResponseFromJSON(jsonValue));
    }
    /**
     * 資料のアーカイブ設定
     * archive
     */
    async userV1DocumentsArchivePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1DocumentsArchivePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料のコピーを行う（コピーのみで複製は行わない）
     * copy
     */
    async userV1DocumentsCopyPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/copy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CopyDocumentRequestToJSON(requestParameters.copyDocumentRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CopyDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料のコピーを行う（コピーのみで複製は行わない）
     * copy
     */
    async userV1DocumentsCopyPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1DocumentsCopyPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャット設定を編集する
     * chat_setting
     */
    async userV1DocumentsDocumentIdChatSettingPutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdChatSettingPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/chat_setting`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentChatSettingRequestToJSON(requestParameters.updateDocumentChatSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentChatSettingResponseFromJSON(jsonValue));
    }
    /**
     * チャット設定を編集する
     * chat_setting
     */
    async userV1DocumentsDocumentIdChatSettingPut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdChatSettingPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料CTA画像を更新する
     * update
     */
    async userV1DocumentsDocumentIdCtaImagePutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdCtaImagePut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/cta_image`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentCtaImageResponseFromJSON(jsonValue));
    }
    /**
     * 資料CTA画像を更新する
     * update
     */
    async userV1DocumentsDocumentIdCtaImagePut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdCtaImagePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料CTAを編集する
     * cta
     */
    async userV1DocumentsDocumentIdCtaPutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdCtaPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/cta`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentCtaRequestToJSON(requestParameters.updateDocumentCtaRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentCtaResponseFromJSON(jsonValue));
    }
    /**
     * 資料CTAを編集する
     * cta
     */
    async userV1DocumentsDocumentIdCtaPut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdCtaPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料を送付したコンタクト一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdDocumentContactsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdDocumentContactsGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/document_contacts`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentContactsResponseFromJSON(jsonValue));
    }
    /**
     * 資料を送付したコンタクト一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdDocumentContactsGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdDocumentContactsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料のスライドを更新する
     * update
     */
    async userV1DocumentsDocumentIdFileUpdatePostRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdFileUpdatePost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/file_update`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => FileUpdateDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料のスライドを更新する
     * update
     */
    async userV1DocumentsDocumentIdFileUpdatePost(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdFileUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料の詳細情報を取得する
     * show
     */
    async userV1DocumentsDocumentIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料の詳細情報を取得する
     * show
     */
    async userV1DocumentsDocumentIdGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料メッセージを編集する
     * message
     */
    async userV1DocumentsDocumentIdMessagePutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMessagePut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/message`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentMessageRequestToJSON(requestParameters.updateDocumentMessageRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentMessageResponseFromJSON(jsonValue));
    }
    /**
     * 資料メッセージを編集する
     * message
     */
    async userV1DocumentsDocumentIdMessagePut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdMessagePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料設定の詳細情報を取得する
     * show
     */
    async userV1DocumentsDocumentIdMiscSettingGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMiscSettingGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/misc_setting`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentMiscSettingResponseFromJSON(jsonValue));
    }
    /**
     * 資料設定の詳細情報を取得する
     * show
     */
    async userV1DocumentsDocumentIdMiscSettingGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdMiscSettingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料設定のOGP画像の更新をする
     * ogp_file
     */
    async userV1DocumentsDocumentIdMiscSettingOgpFilePutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMiscSettingOgpFilePut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/misc_setting/ogp_file`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistoriesGraphResponse1FromJSON(jsonValue));
    }
    /**
     * 資料設定のOGP画像の更新をする
     * ogp_file
     */
    async userV1DocumentsDocumentIdMiscSettingOgpFilePut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdMiscSettingOgpFilePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料設定の詳細情報を更新する
     * update
     */
    async userV1DocumentsDocumentIdMiscSettingPutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMiscSettingPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/misc_setting`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentMiscSettingRequestToJSON(requestParameters.updateDocumentMiscSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentMiscSettingResponseFromJSON(jsonValue));
    }
    /**
     * 資料設定の詳細情報を更新する
     * update
     */
    async userV1DocumentsDocumentIdMiscSettingPut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdMiscSettingPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料商談設定を編集する
     * opportunity
     */
    async userV1DocumentsDocumentIdOpportunityPutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/opportunity`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentOpportunityRequestToJSON(requestParameters.updateDocumentOpportunityRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentOpportunityResponseFromJSON(jsonValue));
    }
    /**
     * 資料商談設定を編集する
     * opportunity
     */
    async userV1DocumentsDocumentIdOpportunityPut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdOpportunityPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料アポイント担当者一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdOpportunityUsersGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityUsersGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/opportunity_users`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentOpportunityUsersResponseFromJSON(jsonValue));
    }
    /**
     * 資料アポイント担当者一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdOpportunityUsersGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdOpportunityUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料アポイント担当者一覧を登録する
     * create
     */
    async userV1DocumentsDocumentIdOpportunityUsersPostRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityUsersPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/opportunity_users`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentOpporunityUserRequestToJSON(requestParameters.createDocumentOpporunityUserRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateDocumentOpportunityUsersResponseFromJSON(jsonValue));
    }
    /**
     * 資料アポイント担当者一覧を登録する
     * create
     */
    async userV1DocumentsDocumentIdOpportunityUsersPost(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdOpportunityUsersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料アポイント担当者に登録できるユーザー一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdOpportunityUsersUsersGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityUsersUsersGet.');
        }
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/opportunity_users/users`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSettableDocumentOpportunityUsersResponseFromJSON(jsonValue));
    }
    /**
     * 資料アポイント担当者に登録できるユーザー一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdOpportunityUsersUsersGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdOpportunityUsersUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料を公開・非公開を切り替える
     * publish
     */
    async userV1DocumentsDocumentIdPublishPutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdPublishPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/publish`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentPublishRequestToJSON(requestParameters.createDocumentPublishRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateDocumentPublishResponseFromJSON(jsonValue));
    }
    /**
     * 資料を公開・非公開を切り替える
     * publish
     */
    async userV1DocumentsDocumentIdPublishPut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdPublishPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料の情報を編集する
     * update
     */
    async userV1DocumentsDocumentIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentRequestToJSON(requestParameters.updateDocumentRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料の情報を編集する
     * update
     */
    async userV1DocumentsDocumentIdPut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料共有設定を編集する
     * share_by_link
     */
    async userV1DocumentsDocumentIdShareByLinkPutRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdShareByLinkPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/share_by_link`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentShareByLinkRequestToJSON(requestParameters.updateDocumentShareByLinkRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentShareByLinkResponseFromJSON(jsonValue));
    }
    /**
     * 資料共有設定を編集する
     * share_by_link
     */
    async userV1DocumentsDocumentIdShareByLinkPut(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdShareByLinkPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料をコンタクトに共有をする
     * share_to_contact
     */
    async userV1DocumentsDocumentIdShareToContactPostRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdShareToContactPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/share_to_contact`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDocumentShareWithContactRequestToJSON(requestParameters.createDocumentShareWithContactRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateDocumentShareWithContactResponseFromJSON(jsonValue));
    }
    /**
     * 資料をコンタクトに共有をする
     * share_to_contact
     */
    async userV1DocumentsDocumentIdShareToContactPost(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdShareToContactPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料に関連する資料サイト一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdSitesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdSitesGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/sites`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentSitesResponseFromJSON(jsonValue));
    }
    /**
     * 資料に関連する資料サイト一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdSitesGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdSitesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料閲覧履歴一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdViewHistoriesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/view_histories`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistoriesResponseFromJSON(jsonValue));
    }
    /**
     * 資料閲覧履歴一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdViewHistoriesGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdViewHistoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料閲覧履歴グラフを取得する
     * show
     */
    async userV1DocumentsDocumentIdViewHistoriesGraphGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesGraphGet.');
        }
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesGraphGet.');
        }
        const queryParameters = {};
        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }
        if (requestParameters.visitorSessionId !== undefined) {
            queryParameters['visitor_session_id'] = requestParameters.visitorSessionId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/view_histories/graph`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistoriesGraphResponseFromJSON(jsonValue));
    }
    /**
     * 資料閲覧履歴グラフを取得する
     * show
     */
    async userV1DocumentsDocumentIdViewHistoriesGraphGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdViewHistoriesGraphGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料閲覧履歴のセッション一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdViewHistoriesSessionsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesSessionsGet.');
        }
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesSessionsGet.');
        }
        const queryParameters = {};
        if (requestParameters.contactId !== undefined) {
            queryParameters['contact_id'] = requestParameters.contactId;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents/{document_id}/view_histories/sessions`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistorySessionsResponseFromJSON(jsonValue));
    }
    /**
     * 資料閲覧履歴のセッション一覧を取得する
     * index
     */
    async userV1DocumentsDocumentIdViewHistoriesSessionsGet(requestParameters, initOverrides) {
        const response = await this.userV1DocumentsDocumentIdViewHistoriesSessionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料を作成する
     * create
     */
    async userV1DocumentsFileUploadPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/documents/file_upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => FileUploadDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料を作成する
     * create
     */
    async userV1DocumentsFileUploadPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1DocumentsFileUploadPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料の一覧を取得する
     * index
     */
    async userV1DocumentsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.isArchived !== undefined) {
            queryParameters['is_archived'] = requestParameters.isArchived;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentsResponseFromJSON(jsonValue));
    }
    /**
     * 資料の一覧を取得する
     * index
     */
    async userV1DocumentsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1DocumentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料のアポイント担当者を削除する
     * destroy
     */
    async userV1OpportunityUsersIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling userV1OpportunityUsersIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/opportunity_users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentOpportunityUsersResponseFromJSON(jsonValue));
    }
    /**
     * 資料のアポイント担当者を削除する
     * destroy
     */
    async userV1OpportunityUsersIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1OpportunityUsersIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
