/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Avatar, AvatarFromJSON, AvatarFromJSONTyped, AvatarToJSON } from './Avatar'

/**
 *
 * @export
 * @interface SendedBy
 */
export interface SendedBy {
  /**
   *
   * @type {string}
   * @memberof SendedBy
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SendedBy
   */
  displayName?: string
  /**
   *
   * @type {Avatar}
   * @memberof SendedBy
   */
  avatar?: Avatar
}

export function SendedByFromJSON(json: any): SendedBy {
  return SendedByFromJSONTyped(json, false)
}

export function SendedByFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendedBy {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    avatar: !exists(json, 'avatar') ? undefined : AvatarFromJSON(json['avatar'])
  }
}

export function SendedByToJSON(value?: SendedBy | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    display_name: value.displayName,
    avatar: AvatarToJSON(value.avatar)
  }
}
