/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface VisitorVisitorContactRequestData
 */
export interface VisitorVisitorContactRequestData {
  /**
   *
   * @type {string}
   * @memberof VisitorVisitorContactRequestData
   */
  codeExpiredAt?: string | null
}

export function VisitorVisitorContactRequestDataFromJSON(json: any): VisitorVisitorContactRequestData {
  return VisitorVisitorContactRequestDataFromJSONTyped(json, false)
}

export function VisitorVisitorContactRequestDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VisitorVisitorContactRequestData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    codeExpiredAt: !exists(json, 'code_expired_at') ? undefined : json['code_expired_at']
  }
}

export function VisitorVisitorContactRequestDataToJSON(value?: VisitorVisitorContactRequestData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code_expired_at: value.codeExpiredAt
  }
}
