/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function JobLogFromJSON(json) {
    return JobLogFromJSONTyped(json, false);
}
export function JobLogFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        level: !exists(json, 'level') ? undefined : json['level'],
        position: !exists(json, 'position') ? undefined : json['position'],
        division: !exists(json, 'division') ? undefined : json['division'],
        description: !exists(json, 'description') ? undefined : json['description'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        jobId: !exists(json, 'job_id') ? undefined : json['job_id']
    };
}
export function JobLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        level: value.level,
        position: value.position,
        division: value.division,
        description: value.description,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        job_id: value.jobId
    };
}
