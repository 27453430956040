/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateContactResponseData,
  UpdateContactResponseDataFromJSON,
  UpdateContactResponseDataFromJSONTyped,
  UpdateContactResponseDataToJSON
} from './UpdateContactResponseData'

/**
 *
 * @export
 * @interface UpdateContactResponse
 */
export interface UpdateContactResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateContactResponse
   */
  status?: number
  /**
   *
   * @type {UpdateContactResponseData}
   * @memberof UpdateContactResponse
   */
  data?: UpdateContactResponseData
}

export function UpdateContactResponseFromJSON(json: any): UpdateContactResponse {
  return UpdateContactResponseFromJSONTyped(json, false)
}

export function UpdateContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateContactResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : UpdateContactResponseDataFromJSON(json['data'])
  }
}

export function UpdateContactResponseToJSON(value?: UpdateContactResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: UpdateContactResponseDataToJSON(value.data)
  }
}
