/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumChatMessageCreateCausedBy = {
    ChatMessage: 'chat_message',
    PublishToContact: 'publish_to_contact'
};
export function EnumChatMessageCreateCausedByFromJSON(json) {
    return EnumChatMessageCreateCausedByFromJSONTyped(json, false);
}
export function EnumChatMessageCreateCausedByFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumChatMessageCreateCausedByToJSON(value) {
    return value;
}
