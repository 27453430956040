import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var DocumentApi = /** @class */ (function () {
    function DocumentApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    DocumentApi.initialize = function (basePath, fetchApi) {
        if (!DocumentApi.instance) {
            DocumentApi.instance = new DocumentApi(basePath, fetchApi);
        }
        return DocumentApi.instance;
    };
    DocumentApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'contact'
        });
        return new NocoHttpClient.ApiContactV1DocumentApi(new Configuration(config));
    };
    return DocumentApi;
}());
export { DocumentApi };
