import { Box, createStyles, makeStyles, useMediaQuery } from '@material-ui/core'
import Link from 'next/link'
import { useMemo } from 'react'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import theme from 'src/styles/theme'

export interface ChatRestrictionBannerProps {
  size?: 'medium' | 'small'
}

const useStyles = makeStyles(() => {
  return createStyles({
    link: {
      textDecoration: 'none'
    }
  })
})

export const ChatRestrictionBanner = ({ size = 'medium' }: ChatRestrictionBannerProps) => {
  const classes = useStyles()
  const isDownMd = useMediaQuery(theme.breakpoints.down(990))
  const smallSize = useMemo(() => {
    return isDownMd || size === 'small'
  }, [isDownMd, size])

  return (
    <Box
      display={smallSize ? 'block' : 'flex'}
      alignItems="center"
      justifyContent="space-between"
      bgcolor={Colors.option.orange}
      color={Colors.functional.whiteText.default}
      p="8px 16px"
    >
      <Box mb={smallSize ? '4px' : 0}>
        <Box display="flex" alignItems="center" mb="6px">
          <DynamicMuiIcon variant="info" size="16px" />
          <Box mr="8px" />
          <Typography fontSize="s" fontWeight="bold" lineheight="14px">
            60日を経過したチャット履歴を閲覧いただけません
          </Typography>
        </Box>
        <Typography fontSize="s" lineheight="18.48px" letterSpacing="default">
          有料プランではチャット履歴を無制限に閲覧いただけます
        </Typography>
      </Box>
      <Box display="flex" alignItems="baseline">
        <Link href="/setting/team/pricing">
          <a className={classes.link}>
            <Button
              size={size}
              buttonColor={Colors.functional.whiteText.default}
              textColor={Colors.option.orange}
              hasBorder={false}
              kind="neutral"
              title="料金プランを見る"
            />
          </a>
        </Link>
      </Box>
    </Box>
  )
}
