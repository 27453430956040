/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { ChatSummary, ChatSummaryFromJSON, ChatSummaryFromJSONTyped, ChatSummaryToJSON } from './ChatSummary'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetChatSummariesResponseData
 */
export interface GetChatSummariesResponseData {
  /**
   *
   * @type {Array<ChatSummary>}
   * @memberof GetChatSummariesResponseData
   */
  chatSummaries?: Array<ChatSummary>
  /**
   *
   * @type {PageInfo}
   * @memberof GetChatSummariesResponseData
   */
  pageInfo?: PageInfo
}

export function GetChatSummariesResponseDataFromJSON(json: any): GetChatSummariesResponseData {
  return GetChatSummariesResponseDataFromJSONTyped(json, false)
}

export function GetChatSummariesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetChatSummariesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    chatSummaries: !exists(json, 'chat_summaries')
      ? undefined
      : (json['chat_summaries'] as Array<any>).map(ChatSummaryFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetChatSummariesResponseDataToJSON(value?: GetChatSummariesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    chat_summaries:
      value.chatSummaries === undefined ? undefined : (value.chatSummaries as Array<any>).map(ChatSummaryToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
