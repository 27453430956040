/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactMaterial,
  ContactMaterialFromJSON,
  ContactMaterialFromJSONTyped,
  ContactMaterialToJSON
} from './ContactMaterial'

/**
 *
 * @export
 * @interface GetContactMaterialsExpiredListResponseData
 */
export interface GetContactMaterialsExpiredListResponseData {
  /**
   *
   * @type {Array<ContactMaterial>}
   * @memberof GetContactMaterialsExpiredListResponseData
   */
  contactMaterials?: Array<ContactMaterial>
}

export function GetContactMaterialsExpiredListResponseDataFromJSON(
  json: any
): GetContactMaterialsExpiredListResponseData {
  return GetContactMaterialsExpiredListResponseDataFromJSONTyped(json, false)
}

export function GetContactMaterialsExpiredListResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactMaterialsExpiredListResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactMaterials: !exists(json, 'contact_materials')
      ? undefined
      : (json['contact_materials'] as Array<any>).map(ContactMaterialFromJSON)
  }
}

export function GetContactMaterialsExpiredListResponseDataToJSON(
  value?: GetContactMaterialsExpiredListResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_materials:
      value.contactMaterials === undefined
        ? undefined
        : (value.contactMaterials as Array<any>).map(ContactMaterialToJSON)
  }
}
