import { Contact, EnumSettingSettingType } from '@noco/http-client/lib/noco'
import React from 'react'
import { InformationRow } from 'src/components/atoms'

export interface ReadProps {
  contact: Contact
}

export const Read = ({ contact }: ReadProps) => {
  return (
    <>
      {contact.contactSettings?.map((item, i) => {
        if (item.settingType === EnumSettingSettingType.Company) {
          return (
            <InformationRow
              key={i}
              variant="url"
              label={item.name!}
              content={contact.company?.displayName!}
              link={`/companies/${contact.company?.id!}`}
              padding="6px 0px"
            />
          )
        } else if (item.settingType === EnumSettingSettingType.Url) {
          return (
            <InformationRow
              key={i}
              variant="url"
              label={item.name!}
              content={item.contactSettingValueText?.text!}
              link={item.contactSettingValueText?.text!}
              padding="6px 0px"
            />
          )
        } else {
          return (
            <InformationRow
              key={i}
              variant="plain"
              label={item.name!}
              content={item.contactSettingValueText?.text!}
              padding="6px 0px"
            />
          )
        }
      })}
    </>
  )
}
