import React, { useCallback, useMemo, useReducer, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS, Transform } from '@dnd-kit/utilities'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useGlobalStyles } from 'src/styles/theme'
import { ButtonList } from './ButtonList'
import { Colors, Input, Typography } from 'src/components/atoms'
import { SiteSectionDocument } from '@noco/http-client/lib/noco'
import clsx from 'clsx'
import { useDeleteSiteSectionDocument, useUpdateSiteSectionDocument } from 'src/fixtures/modules/site/hooks'
import { PageSiteMode } from '../../SiteDetail'
import { useRouter } from 'next/router'
import { EuSiteSectionDocument } from '@noco/http-client/lib/noco'

export interface DocumentItemProps {
  siteId: string
  document: SiteSectionDocument | EuSiteSectionDocument
  onChange?: ({ id, text, type }: onChangeDocumentItem) => void
  mode: PageSiteMode
}

export interface onChangeDocumentItem {
  id: string
  text: string
  type: keyof Pick<SiteSectionDocument, 'displayTitle' | 'presentationText'>
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      transform: ({ transform }: SectionStyleData) => (transform ? CSS.Transform.toString(transform) : ''),
      transition: ({ transition }: SectionStyleData) => transition,
      opacity: ({ isDragging }: SectionStyleData) => (isDragging ? 0.5 : 1)
    },
    documentWrap: {
      '& .image': {
        borderRadius: '4px',
        filter: `drop-shadow(0px 0px 1px ${Colors.base.placeHolder})`,
        objectPosition: 'bottom'
      },
      '&:hover': {
        '& $target': {
          display: 'flex',
          zIndex: '3'
        }
      }
    },
    inputStyle: {
      '& .MuiInput-root': {
        padding: '4px',

        '& .MuiInput-inputMultiline': {
          margin: '0',
          letterSpacing: '0.02em',
          lineHeight: '1.7 !important'
        }
      }
    },
    hoverStyle: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default,
        boxShadow: `0 0 0 1px ${Colors.accent.keyPurple.default} inset`,
        borderRadius: '6px'
      }
    },
    target: {
      display: 'none'
    }
  })
)
export interface SectionStyleData {
  transform?: Transform | null
  transition?: string
  isDragging?: boolean
}

export const DocumentItem = ({ siteId, document, mode }: DocumentItemProps) => {
  const { listeners, setNodeRef, transform, transition, isDragging, setActivatorNodeRef } = useSortable({
    id: document.id!,
    disabled: mode !== 'setting'
  })

  const router = useRouter()
  const siteShareId = router.query.shareId as string | undefined
  const classes = useStyles({ transform, transition, isDragging })
  const globalClasses = useGlobalStyles()
  const [isEditingTitle, toggleIsEditingTitle] = useReducer(value => !value, false)

  const [isEditingDescription, toggleIsEditingDescription] = useReducer(value => !value, false)
  const [titleText, setTitleText] = useState<string>(document.displayTitle || '')
  const [descriptionText, setDescriptionText] = useState<string>(document.presentationText || '')
  const { handleUpdateSiteSectionDocument } = useUpdateSiteSectionDocument(siteId, document.id!)
  const { handleDeleteSiteSectionDocument } = useDeleteSiteSectionDocument({
    siteId: siteId,
    siteSectionDocumentId: document.id!
  })

  const hoverBoxClassName = useMemo(
    () =>
      mode === 'setting'
        ? clsx(globalClasses.cursorPointer, classes.hoverStyle, globalClasses.wordBreakAll)
        : clsx(globalClasses.wordBreakAll),
    [globalClasses, classes, mode]
  )

  const handleDocumentClick = useCallback(() => {
    switch (mode) {
      case 'eu': {
        // MEMO: @snuffy 渡ってくる document は eu の場合は EuSiteSectionDocument になるがそれが問題
        const d = document as EuSiteSectionDocument
        let url = ''
        if (siteShareId) {
          // 誰でも
          const documentShareId = d.document?.publicLinkUlid
          url = `/share/s/${siteShareId}/d/${documentShareId}`
        } else {
          // 限定公開
          url = `/share/s/m/${siteId}/d/${d.document?.id}`
        }

        window.open(url, '_blank')
        break
      }

      case 'preview': {
        window.open(`/documents/${document?.document?.id}/preview`, '_blank')
        break
      }
      default:
        break
    }
  }, [document, mode, siteId, siteShareId])

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleText(e.target.value)
  }, [])

  const handleChangeDescription = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionText(e.target.value)
  }, [])

  const handleSaveTitle = useCallback(() => {
    toggleIsEditingTitle()
    handleUpdateSiteSectionDocument({ siteSectionDocument: { title: titleText } })
  }, [toggleIsEditingTitle, titleText, handleUpdateSiteSectionDocument])

  const handleSaveDescription = useCallback(() => {
    toggleIsEditingDescription()
    handleUpdateSiteSectionDocument({ siteSectionDocument: { presentationText: descriptionText } })
  }, [toggleIsEditingDescription, handleUpdateSiteSectionDocument, descriptionText])

  return (
    <div ref={setNodeRef} className={classes.root}>
      <Box position="relative" width={1} className={classes.documentWrap}>
        {mode === 'setting' && (
          <Box position="absolute" top="8px" right="12px" className={classes.target}>
            <ButtonList
              onDelete={handleDeleteSiteSectionDocument}
              onMove={() => {}}
              ref={setActivatorNodeRef}
              listeners={listeners}
            />
          </Box>
        )}

        <Box height="184px" mb="12px" onClick={handleDocumentClick}>
          <img
            src={
              document?.document?.largeThumbnail?.url
                ? document.document?.largeThumbnail?.url!
                : '/images/dummy/dummy_document.png'
            }
            alt="image"
            className={clsx(globalClasses.imgContain, globalClasses.cursorPointer, 'image')}
          />
        </Box>

        <Box mt="4px" className={classes.inputStyle}>
          {isEditingTitle && mode === 'setting' ? (
            <Input
              value={titleText}
              name="title"
              onBlur={handleSaveTitle}
              onChange={handleChangeTitle}
              autoFocus
              fullWidth
            />
          ) : (
            <Box
              className={globalClasses.lineclamp1}
              mb="4px"
              px="4px"
              // NOTE: 資料タイトルを変更できなくする
              // onClick={toggleIsEditingTitle}
            >
              <Typography variant="h4" fontSize="m" letterSpacing="default" fontWeight="bold" lineheight="24px">
                {titleText}
              </Typography>
            </Box>
          )}
          {isEditingDescription && mode === 'setting' ? (
            <Input
              value={descriptionText}
              name="description"
              onBlur={handleSaveDescription}
              onChange={handleChangeDescription}
              multiline
              fullWidth
              autoFocus
            />
          ) : (document.presentationText == null || document.presentationText === '') && mode === 'setting' ? (
            <Box className={hoverBoxClassName} onClick={toggleIsEditingDescription} color={Colors.base.placeHolder}>
              <Typography fontSize="s" letterSpacing="default" lh="relaxed">
                説明文を入力
              </Typography>
            </Box>
          ) : (
            <Box p="4px" className={hoverBoxClassName} onClick={toggleIsEditingDescription} color={Colors.base.black}>
              <Typography fontSize="s" letterSpacing="default" lh="relaxed">
                {document.presentationText}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}
