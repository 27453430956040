import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const EditCalendar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.11133 17.9167H3.27799C2.35299 17.9167 1.61133 17.1667 1.61133 16.25L1.61966 4.58333C1.61966 3.66667 2.35299 2.91667 3.27799 2.91667H4.11133V2.08333C4.11133 1.625 4.48633 1.25 4.94466 1.25C5.40299 1.25 5.77799 1.625 5.77799 2.08333V2.91667H12.4447V2.08333C12.4447 1.625 12.8197 1.25 13.278 1.25C13.7363 1.25 14.1113 1.625 14.1113 2.08333V2.91667H14.9447C15.8613 2.91667 16.6113 3.66667 16.6113 4.58333V9.58333H14.9447V7.91667H3.27799V16.25H9.11133V17.9167ZM17.553 13.7417L18.1447 13.15C18.4697 12.825 18.4697 12.3 18.1447 11.975L17.553 11.3833C17.228 11.0583 16.703 11.0583 16.378 11.3833L15.7863 11.975L17.553 13.7417ZM16.9613 14.3333L12.7863 18.5083C12.6363 18.6583 12.4197 18.75 12.203 18.75H11.1947C10.9613 18.75 10.778 18.5667 10.778 18.3333V17.325C10.778 17.1 10.8697 16.8917 11.0197 16.7333L15.1947 12.5583L16.9613 14.3333Z" />
    </SvgIcon>
  )
}
export default EditCalendar
