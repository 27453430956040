/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateSiteSectionRequestSiteSection
 */
export interface CreateSiteSectionRequestSiteSection {
  /**
   *
   * @type {string}
   * @memberof CreateSiteSectionRequestSiteSection
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof CreateSiteSectionRequestSiteSection
   */
  sort?: number
}

export function CreateSiteSectionRequestSiteSectionFromJSON(json: any): CreateSiteSectionRequestSiteSection {
  return CreateSiteSectionRequestSiteSectionFromJSONTyped(json, false)
}

export function CreateSiteSectionRequestSiteSectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateSiteSectionRequestSiteSection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    sort: !exists(json, 'sort') ? undefined : json['sort']
  }
}

export function CreateSiteSectionRequestSiteSectionToJSON(value?: CreateSiteSectionRequestSiteSection | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    sort: value.sort
  }
}
