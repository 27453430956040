import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CircleLine: React.VFC<SvgIconProps> = ({ color = '#4CC764', ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill={color}
      />
      <path
        d="M23.3363 13.1846C23.3363 9.0021 19.1433 5.6001 13.9904 5.6001C8.83753 5.6001 4.64453 9.00297 4.64453 13.1846C4.64453 16.934 7.96953 20.0735 12.4609 20.6676C12.7654 20.7332 13.1793 20.868 13.2843 21.1287C13.3788 21.365 13.3455 21.7351 13.3149 21.974C13.3149 21.974 13.2055 22.6337 13.1819 22.7737C13.1408 23.01 12.9938 23.6977 13.9913 23.2777C14.9888 22.8577 19.3734 20.1085 21.3343 17.8518C22.6888 16.3661 23.338 14.8593 23.338 13.1855L23.3363 13.1846Z"
        fill="white"
      />
      <path
        d="M20.2282 15.5996C20.3271 15.5996 20.4067 15.52 20.4067 15.4211V14.7578C20.4067 14.6598 20.3262 14.5793 20.2282 14.5793H18.4441V13.8907H20.2282C20.3271 13.8907 20.4067 13.8111 20.4067 13.7122V13.0498C20.4067 12.9518 20.3262 12.8713 20.2282 12.8713H18.4441V12.1827H20.2282C20.3271 12.1827 20.4067 12.1031 20.4067 12.0042V11.3418C20.4067 11.2438 20.3262 11.1633 20.2282 11.1633H17.6023C17.5035 11.1633 17.4238 11.243 17.4238 11.3418V11.3453V15.4176V15.4211C17.4238 15.52 17.5035 15.5996 17.6023 15.5996H20.2282V15.5996Z"
        fill={color}
      />
      <path
        d="M10.5153 15.5996C10.6142 15.5996 10.6938 15.52 10.6938 15.4211V14.7578C10.6938 14.6598 10.6133 14.5793 10.5153 14.5793H8.73119V11.3418C8.73119 11.2438 8.65069 11.1633 8.55269 11.1633H7.88944C7.79056 11.1633 7.71094 11.243 7.71094 11.3418V15.4176V15.4211C7.71094 15.52 7.79056 15.5996 7.88944 15.5996H10.5153V15.5996Z"
        fill={color}
      />
      <path
        d="M12.0948 11.1633H11.4324C11.3338 11.1633 11.2539 11.2432 11.2539 11.3418V15.4202C11.2539 15.5188 11.3338 15.5987 11.4324 15.5987H12.0948C12.1934 15.5987 12.2733 15.5188 12.2733 15.4202V11.3418C12.2733 11.2432 12.1934 11.1633 12.0948 11.1633Z"
        fill={color}
      />
      <path
        d="M16.6081 11.1633H15.9449C15.846 11.1633 15.7664 11.243 15.7664 11.3418V13.7647L13.9 11.2447C13.8956 11.2386 13.8904 11.2325 13.886 11.2263H13.8851C13.8816 11.2211 13.8781 11.2176 13.8746 11.2141L13.8711 11.2115C13.8711 11.2115 13.865 11.2062 13.8615 11.2036C13.8597 11.2027 13.858 11.201 13.8562 11.2001C13.8536 11.1975 13.8501 11.1957 13.8466 11.194C13.8449 11.1931 13.8431 11.1913 13.8414 11.1905C13.8379 11.1887 13.8352 11.187 13.8317 11.1852C13.83 11.1843 13.8282 11.1835 13.8265 11.1826C13.823 11.1808 13.8195 11.1791 13.816 11.1782L13.8099 11.1765C13.8099 11.1765 13.8029 11.1738 13.7994 11.173C13.7976 11.173 13.795 11.1721 13.7932 11.1712C13.7897 11.1712 13.7862 11.1695 13.7827 11.1686C13.7801 11.1686 13.7775 11.1686 13.7749 11.1677C13.7714 11.1677 13.7687 11.1668 13.7652 11.1668C13.7617 11.1668 13.7591 11.1668 13.7556 11.1668C13.7539 11.1668 13.7512 11.1668 13.7495 11.1668H13.0906C12.9917 11.1668 12.9121 11.2465 12.9121 11.3453V15.4246C12.9121 15.5235 12.9917 15.6031 13.0906 15.6031H13.7539C13.8527 15.6031 13.9324 15.5235 13.9324 15.4246V13.0026L15.8005 15.5261C15.8136 15.5445 15.8294 15.5593 15.8469 15.5707L15.8486 15.5725C15.8486 15.5725 15.8556 15.5768 15.86 15.5795C15.8617 15.5803 15.8635 15.5812 15.8652 15.5821C15.8679 15.5838 15.8714 15.5847 15.874 15.5865C15.8766 15.5882 15.8801 15.5891 15.8827 15.59C15.8845 15.59 15.8862 15.5917 15.888 15.5917C15.8924 15.5935 15.8959 15.5943 15.9002 15.5952H15.9029C15.9177 15.5996 15.9335 15.6022 15.9492 15.6022H16.6081C16.707 15.6022 16.7866 15.5226 16.7866 15.4237V11.3445C16.7866 11.2456 16.707 11.166 16.6081 11.166V11.1633Z"
        fill={color}
      />
    </SvgIcon>
  )
}
export default CircleLine
