/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPlanDetail,
  OrganizationPlanDetailFromJSON,
  OrganizationPlanDetailFromJSONTyped,
  OrganizationPlanDetailToJSON
} from './OrganizationPlanDetail'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlansResponseData
 */
export interface GetOrganizationPaymentPlansResponseData {
  /**
   *
   * @type {Array<OrganizationPlanDetail>}
   * @memberof GetOrganizationPaymentPlansResponseData
   */
  plans: Array<OrganizationPlanDetail>
}

export function GetOrganizationPaymentPlansResponseDataFromJSON(json: any): GetOrganizationPaymentPlansResponseData {
  return GetOrganizationPaymentPlansResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlansResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlansResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    plans: (json['plans'] as Array<any>).map(OrganizationPlanDetailFromJSON)
  }
}

export function GetOrganizationPaymentPlansResponseDataToJSON(
  value?: GetOrganizationPaymentPlansResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plans: (value.plans as Array<any>).map(OrganizationPlanDetailToJSON)
  }
}
