import React from 'react'
import { Box } from '@material-ui/core'
import { Input, SectionCard, Pallete, Typography } from 'src/components/atoms'
import { ColorPicker } from 'src/components/molecules'

export interface CTASettingCardProps {
  headline: string
  description: string

  color: string
  url: string
  image?: string
  buttonName: string

  onChangeHeadline: (e: React.FocusEvent<HTMLInputElement>) => void
  onChangeDescription: (e: React.FocusEvent<HTMLInputElement>) => void

  onChangeColor: (color: string) => void
  onChangeUrl: (e: React.FocusEvent<HTMLInputElement>) => void
  onChangeImage?: (value: string) => void

  onChangeButtonName: (e: React.FocusEvent<HTMLInputElement>) => void
}

// const useStyles = makeStyles(() =>
//   createStyles({
//     textColor: {
//       color: Colors.base.placeHolder,
//       fontStyle: 'normal',
//       fontSize: '14px',
//       lineHeight: '150%',
//       fontWeight: 'normal'
//     },
//     img: {
//       width: '100%',
//       height: '100%',
//       borderRadius: '4px',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       objectFit: 'contain'
//     }
//   })
// )

export const CTASettingCard = ({
  headline,
  description,
  color,
  url,
  buttonName,
  onChangeHeadline,
  onChangeDescription,
  onChangeColor,
  onChangeUrl,
  onChangeButtonName
}: CTASettingCardProps) => {
  // const classes = useStyles()
  // const { data: base64Image, handleInputImage, clear } = useInputImage()
  // const [uploading, setUploading] = useState<boolean>(false)

  // useEffect(() => {
  //   onChangeImage?.(base64Image ? base64Image : '')
  // }, [onChangeImage, base64Image])

  // const onClickUpload = useCallback(() => {
  //   handleInputImage()
  //   setUploading(true)
  // }, [handleInputImage])

  // const onCancelUploading = useCallback(() => {
  //   clear()
  //   setUploading(false)
  // }, [clear])

  return (
    <>
      <Typography variant="h5">表示設定</Typography>
      <Box mt="8px" />
      <SectionCard>
        <Typography variant="h6" fontWeight="normal">
          見出し（50文字以内）
        </Typography>
        <Box mt="6px" />
        <Input
          name="headline"
          placeholder="特別キャンペーン実施中！"
          multiline
          rows={3}
          resize="vertical"
          fullWidth
          backgroundColor={Pallete.functional.background.default}
          defaultValue={headline}
          onBlur={onChangeHeadline}
        />
        <Box mt="14px" />
        <Typography variant="h6" fontWeight="normal">
          説明文（200文字以内）
        </Typography>
        <Box mt="6px" />
        <Input
          name="description"
          placeholder="7/14~7/20まで期間限定で実施中です"
          multiline
          rows={5}
          resize="vertical"
          fullWidth
          backgroundColor={Pallete.functional.background.default}
          defaultValue={description}
          onBlur={onChangeDescription}
        />
        <Box mt="14px" />
        <Typography variant="h6" fontWeight="normal">
          ボタン名（12文字以内）
        </Typography>
        <Box mt="6px" />
        <Input
          name="detail"
          placeholder="詳しく見る"
          fullWidth
          backgroundColor={Pallete.functional.background.default}
          defaultValue={buttonName}
          onBlur={onChangeButtonName}
        />
        <Box mt="14px" />
        <Typography variant="h6">ボタンのカラー</Typography>
        <Box mt="6px" />
        <ColorPicker onChangeColor={onChangeColor} color={color} />
        <Box mt="14px" />
        <Typography variant="h6">リンク先のURL</Typography>
        <Box mt="6px" />
        <Input
          name="url"
          placeholder="https://www.example.co.jp/"
          fullWidth
          backgroundColor={Pallete.functional.background.default}
          defaultValue={url}
          onBlur={onChangeUrl}
        />
        <Box mt="14px" />
        {/* <Typography variant="h6">画像のアップロード</Typography>
        <Box mt="6px" />
        <Box
          borderRadius="4px"
          border={`solid 1px ${Pallete.functional.border.lightGray}`}
          width={1}
          height="130px"
          bgcolor={Pallete.functional.background.default}
          boxShadow="inset 0px 1px 2px rgba(10, 10, 10, 0.1)"
          mb="6px"
          position="relative"
        >
          {image ? (
            <img src={image} className={classes.img} />
          ) : (
            <Box px="12px" pt="6px">
              <Typography variant="subtitle1" className={classes.textColor}>
                画像のアップロード
              </Typography>
            </Box>
          )}
        </Box> */}
        {/* {uploading ? (
          <Button title="キャンセル" style={{ backgroundColor: '#6D7784' }} onClick={onCancelUploading} />
        ) : (
          <Button title="アップロード" style={{ backgroundColor: '#6D7784' }} onClick={onClickUpload} />
        )} */}
      </SectionCard>
    </>
  )
}
