/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationChatSetting,
  OrganizationChatSettingFromJSON,
  OrganizationChatSettingFromJSONTyped,
  OrganizationChatSettingToJSON
} from './OrganizationChatSetting'

/**
 *
 * @export
 * @interface EuDocumentMessageWithOrganizationChatSetting
 */
export interface EuDocumentMessageWithOrganizationChatSetting {
  /**
   *
   * @type {string}
   * @memberof EuDocumentMessageWithOrganizationChatSetting
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EuDocumentMessageWithOrganizationChatSetting
   */
  text?: string | null
  /**
   *
   * @type {number}
   * @memberof EuDocumentMessageWithOrganizationChatSetting
   */
  viewTimingSec?: number | null
  /**
   *
   * @type {boolean}
   * @memberof EuDocumentMessageWithOrganizationChatSetting
   */
  isView?: boolean
  /**
   *
   * @type {string}
   * @memberof EuDocumentMessageWithOrganizationChatSetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof EuDocumentMessageWithOrganizationChatSetting
   */
  updatedAt?: string
  /**
   *
   * @type {OrganizationChatSetting}
   * @memberof EuDocumentMessageWithOrganizationChatSetting
   */
  organizationChatSetting?: OrganizationChatSetting
}

export function EuDocumentMessageWithOrganizationChatSettingFromJSON(
  json: any
): EuDocumentMessageWithOrganizationChatSetting {
  return EuDocumentMessageWithOrganizationChatSettingFromJSONTyped(json, false)
}

export function EuDocumentMessageWithOrganizationChatSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EuDocumentMessageWithOrganizationChatSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    text: !exists(json, 'text') ? undefined : json['text'],
    viewTimingSec: !exists(json, 'view_timing_sec') ? undefined : json['view_timing_sec'],
    isView: !exists(json, 'is_view') ? undefined : json['is_view'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    organizationChatSetting: !exists(json, 'organization_chat_setting')
      ? undefined
      : OrganizationChatSettingFromJSON(json['organization_chat_setting'])
  }
}

export function EuDocumentMessageWithOrganizationChatSettingToJSON(
  value?: EuDocumentMessageWithOrganizationChatSetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    text: value.text,
    view_timing_sec: value.viewTimingSec,
    is_view: value.isView,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    organization_chat_setting: OrganizationChatSettingToJSON(value.organizationChatSetting)
  }
}
