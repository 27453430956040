import { useState } from 'react'
import { Box, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Colors, Pallete } from 'src/components/atoms'
import { Info, Close } from '@material-ui/icons'
import { Document, Organization } from '@noco/http-client/lib/noco'
import { useGlobalStyles } from 'src/styles/theme'
import { DocumentSlideHeaderTooltip } from '../../../DocumentSlideHeaderTooltip'
import Image from 'next/image'

export const PC_HEADER_HEIGHT = 60

const useStyles = makeStyles(() =>
  createStyles({
    toolTipCloseButton: {
      padding: 1,
      alignItems: 'baseline',
      marginLeft: 8,
      color: Pallete.functional.background.default,
      height: 'fit-content',
      pointerEvents: 'auto'
    },
    infoButton: { padding: 8, color: Colors.base.middleGray },
    nocoIcon: {
      position: 'absolute',
      left: 16
    }
  })
)

export const PageDocumentDetailPreviewPCHeader = ({
  document,
  organization,
  isEuView = false,
  siteId
}: {
  document: Document
  organization: Organization
  isEuView?: boolean
  siteId?: string
}) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  return (
    <Box
      width={1}
      minHeight={PC_HEADER_HEIGHT}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px 8px 10px 16px"
      borderBottom={`1px solid ${Colors.background.gray}`}
      bgcolor="#fff"
    >
      {organization.hideAd ? (
        organization.avatar?.url ? (
          <Box height="45px" width="210px">
            <img
              src={organization.avatar.url!}
              alt="カスタムロゴ"
              className={globalClasses.imgContain}
              style={{ objectPosition: '0 50%' }}
            />
          </Box>
        ) : (
          // NOTE: タイトルを中央寄せに
          <Box ml="210px" />
        )
      ) : (
        <Box
          display="flex"
          alignItems="center"
          width="210px"
          onClick={() => window.open('https://noco.sale/')}
          className={globalClasses.cursorPointer}
        >
          <Image alt="" src="/images/nocosell_logo.svg" height={24} width={84} className={classes.nocoIcon} />
        </Box>
      )}

      <Box display="flex" alignItems="center">
        <Typography variant="h3" style={{ width: '100%', wordBreak: 'break-all' }}>
          {document.title}
        </Typography>
        {document.description && (
          <HtmlTooltip
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box display="flex" alignContent="flex-start" style={{ wordBreak: 'break-all' }}>
                <Typography variant="body2">{document?.description}</Typography>
                <IconButton className={classes.toolTipCloseButton} onClick={handleTooltipClose}>
                  <Close />
                </IconButton>
              </Box>
            }
          >
            <IconButton className={classes.infoButton} onClick={handleTooltipOpen}>
              <Info />
            </IconButton>
          </HtmlTooltip>
        )}
      </Box>
      <Box display="flex">
        <DocumentSlideHeaderTooltip document={document} siteId={siteId} isEuView={isEuView} />
      </Box>
    </Box>
  )
}

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#3B5D81',
    padding: 16
  }
}))(Tooltip)
