import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Text = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#251D36" />
      <path
        d="M11.1681 26.8132V17.7137H7.84457V15.187H17.4475V17.7137H14.14V26.8132H11.1681ZM28.2489 26.8132H25.1331L23.008 23.247L20.8829 26.8132H17.7511L21.4421 20.6083L18.2145 15.187H21.3303L23.008 18.0016L24.6697 15.187H27.8015L24.5739 20.6243L28.2489 26.8132ZM31.86 26.8132V17.7137H28.5525V15.187H38.1554V17.7137H34.8319V26.8132H31.86Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Text
