/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ViewingStatusObject
 */
export interface ViewingStatusObject {
  /**
   *
   * @type {number}
   * @memberof ViewingStatusObject
   */
  countOfView?: number
  /**
   *
   * @type {number}
   * @memberof ViewingStatusObject
   */
  secondsOfView?: number
  /**
   *
   * @type {number}
   * @memberof ViewingStatusObject
   */
  pagesOfView?: number
  /**
   *
   * @type {string}
   * @memberof ViewingStatusObject
   */
  latestViewedAt?: string | null
}

export function ViewingStatusObjectFromJSON(json: any): ViewingStatusObject {
  return ViewingStatusObjectFromJSONTyped(json, false)
}

export function ViewingStatusObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewingStatusObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    countOfView: !exists(json, 'count_of_view') ? undefined : json['count_of_view'],
    secondsOfView: !exists(json, 'seconds_of_view') ? undefined : json['seconds_of_view'],
    pagesOfView: !exists(json, 'pages_of_view') ? undefined : json['pages_of_view'],
    latestViewedAt: !exists(json, 'latest_viewed_at') ? undefined : json['latest_viewed_at']
  }
}

export function ViewingStatusObjectToJSON(value?: ViewingStatusObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    count_of_view: value.countOfView,
    seconds_of_view: value.secondsOfView,
    pages_of_view: value.pagesOfView,
    latest_viewed_at: value.latestViewedAt
  }
}
