import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5955 0.425897C12.1473 0.981162 12.1327 1.86726 11.5631 2.40505L6.33273 7.34291C5.37144 8.25044 3.83348 8.21323 2.91957 7.26031L0.38698 4.61963C-0.154586 4.05495 -0.123995 3.16924 0.455309 2.64135C1.03461 2.11345 1.94326 2.14327 2.48482 2.70795L4.68473 5.00174L9.56513 0.394254C10.1348 -0.143535 11.0438 -0.129369 11.5955 0.425897Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default CheckedIcon
