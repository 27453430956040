import { UserV1InboxItemsGetRequest } from '@noco/http-client/lib/noco'
import { useCallback } from 'react'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'

export const useListInboxItems = (requestParameters: UserV1InboxItemsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const inboxApi = nocoSDK.client?.userService.inboxApi
  const fetcher = useCallback(async () => {
    if (!auth) return
    return await inboxApi
      ?.ApiFactory(auth.token)
      .userV1InboxItemsGet({ ...requestParameters, page: 1, per: 999 }, { cache: 'no-store' })
      .then(res => {
        return { ...res.data }
      })
  }, [auth, inboxApi, requestParameters])

  return useSWR(auth && SWRCachePath.listInboxItems(requestParameters), fetcher)
}

// NOTE: openapi 変更に伴い、コメントアウト
// export const useSendErrorsItems = (requestParameters: UserV1SendErrorsGetRequest) => {
//   const { data: auth } = useAuthenticate()
//   const sendErrorApi = nocoSDK.client?.userService.sendErrorApi
//   const fetcher = useCallback(async () => {
//     if (!auth) return
//     return await sendErrorApi
//       ?.ApiFactory(auth.token)
//       .userV1SendErrorsGet({ ...requestParameters, page: 1, per: 999 })
//       .then(res => {
//         return { ...res.data }
//       })
//   }, [auth, sendErrorApi, requestParameters])

//   return useSWR(auth && SWRCachePath.sendErrorItems(requestParameters), fetcher)
// }

export const useListInboxFilter = () => {
  const { data: auth } = useAuthenticate()
  const apiFilter = nocoSDK.client?.userService.filterApi

  const funcFilter = useCallback(async () => {
    if (!auth) return

    return await apiFilter
      ?.ApiFactory(auth.token)
      .userV1InboxFilterGet()
      .then(res => res.data)
  }, [auth, apiFilter])
  return useSWR(auth?.token && SWRCachePath.inboxFilter(), funcFilter)
}
