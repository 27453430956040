import React, { useCallback } from 'react'
import { ContactSettingValueMap } from 'src/fixtures/modules/contact/utils'
import { Contact } from '@noco/http-client/lib/noco'
import { SettingInputRow } from 'src/components/molecules'

export interface EditProps {
  contact: Contact
  settingMap: ContactSettingValueMap
  userId?: string
  onUpdateValue: (settingMap: ContactSettingValueMap) => void
}

export const Edit = ({ contact, settingMap, userId, onUpdateValue }: EditProps) => {
  const activeSettings = contact?.contactSettings

  // handle update
  const handleUpdateValue = useCallback(
    (settingId: string, fieldId: string, value: any) => {
      const newValueMap = { ...settingMap }
      newValueMap[settingId][fieldId] = value
      onUpdateValue(newValueMap)
    },
    [onUpdateValue, settingMap]
  )

  return (
    <>
      {activeSettings?.map(setting => {
        const values = settingMap[setting.id!]
        return (
          <SettingInputRow
            kind="contact"
            required={setting.isRequiredOnUser}
            key={setting.id}
            name={setting.id || ''}
            userId={userId}
            values={values}
            setting={setting}
            onChange={data => handleUpdateValue(setting.id!, data.fieldId, data.value)}
            gap="narrow"
          />
        )
      })}
    </>
  )
}
