/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CompanyForDisplay,
  CompanyForDisplayFromJSON,
  CompanyForDisplayFromJSONTyped,
  CompanyForDisplayToJSON
} from './CompanyForDisplay'
import {
  ContactSetting,
  ContactSettingFromJSON,
  ContactSettingFromJSONTyped,
  ContactSettingToJSON
} from './ContactSetting'
import {
  EnumSupportStatus,
  EnumSupportStatusFromJSON,
  EnumSupportStatusFromJSONTyped,
  EnumSupportStatusToJSON
} from './EnumSupportStatus'
import {
  ShallowChatSummary,
  ShallowChatSummaryFromJSON,
  ShallowChatSummaryFromJSONTyped,
  ShallowChatSummaryToJSON
} from './ShallowChatSummary'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Contact
   * @deprecated
   */
  status?: string
  /**
   *
   * @type {EnumSupportStatus}
   * @memberof Contact
   */
  supportStatus?: EnumSupportStatus
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  encryptedEmail?: string
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  displayName?: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof Contact
   */
  user?: UserForDisplay
  /**
   *
   * @type {CompanyForDisplay}
   * @memberof Contact
   */
  company?: CompanyForDisplay
  /**
   *
   * @type {Array<ContactSetting>}
   * @memberof Contact
   */
  contactSettings?: Array<ContactSetting>
  /**
   *
   * @type {boolean}
   * @memberof Contact
   */
  canChatSend?: boolean | null
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  archivedAt?: string | null
  /**
   *
   * @type {UserForDisplay}
   * @memberof Contact
   */
  createdBy?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof Contact
   */
  updatedBy?: UserForDisplay
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  updatedAt?: string
  /**
   *
   * @type {ShallowChatSummary}
   * @memberof Contact
   */
  chatSummary?: ShallowChatSummary
}

export function ContactFromJSON(json: any): Contact {
  return ContactFromJSONTyped(json, false)
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    status: !exists(json, 'status') ? undefined : json['status'],
    supportStatus: !exists(json, 'support_status') ? undefined : EnumSupportStatusFromJSON(json['support_status']),
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company']),
    contactSettings: !exists(json, 'contact_settings')
      ? undefined
      : (json['contact_settings'] as Array<any>).map(ContactSettingFromJSON),
    canChatSend: !exists(json, 'can_chat_send') ? undefined : json['can_chat_send'],
    archivedAt: !exists(json, 'archived_at') ? undefined : json['archived_at'],
    createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
    updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    chatSummary: !exists(json, 'chat_summary') ? undefined : ShallowChatSummaryFromJSON(json['chat_summary'])
  }
}

export function ContactToJSON(value?: Contact | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    status: value.status,
    support_status: EnumSupportStatusToJSON(value.supportStatus),
    encrypted_email: value.encryptedEmail,
    display_name: value.displayName,
    user: UserForDisplayToJSON(value.user),
    company: CompanyForDisplayToJSON(value.company),
    contact_settings:
      value.contactSettings === undefined ? undefined : (value.contactSettings as Array<any>).map(ContactSettingToJSON),
    can_chat_send: value.canChatSend,
    archived_at: value.archivedAt,
    created_by: UserForDisplayToJSON(value.createdBy),
    updated_by: UserForDisplayToJSON(value.updatedBy),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    chat_summary: ShallowChatSummaryToJSON(value.chatSummary)
  }
}
