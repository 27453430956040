/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Company, CompanyFromJSON, CompanyFromJSONTyped, CompanyToJSON } from './Company'

/**
 *
 * @export
 * @interface CreateCompanyResponseData
 */
export interface CreateCompanyResponseData {
  /**
   *
   * @type {Company}
   * @memberof CreateCompanyResponseData
   */
  company?: Company
}

export function CreateCompanyResponseDataFromJSON(json: any): CreateCompanyResponseData {
  return CreateCompanyResponseDataFromJSONTyped(json, false)
}

export function CreateCompanyResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateCompanyResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    company: !exists(json, 'company') ? undefined : CompanyFromJSON(json['company'])
  }
}

export function CreateCompanyResponseDataToJSON(value?: CreateCompanyResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company: CompanyToJSON(value.company)
  }
}
