import { Box } from '@material-ui/core'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Colors } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { ModalAlertShare, ModalAlertShareProps, ModalSiteShare } from 'src/components/modals'
import { FolderContent, FolderDescription, Header, TableColumn } from 'src/components/organisms'
import { ModalExpiryUpdate } from 'src/components/modals'
import {
  ActivityStatusCell,
  CompanyNameCell,
  ExpiryCell,
  InflowCell,
  NameCell
} from 'src/components/organisms/Table/TableParts'
import { HeaderRightSection } from './HeaderRightSection'
import {
  useGetSite,
  useListSiteContacts,
  useListSitesSiteIdDocuments,
  useUpdatePublish,
  useUpdateSiteContact
} from 'src/fixtures/modules/site/hooks'
import { useRouter } from 'next/router'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { SiteContact } from '@noco/http-client/lib/noco'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'

export const PageMultiDocumentDetail = () => {
  //- 閲覧期限の変更をする
  const [targetSiteContactId, setTargetSiteContactId] = useState<string | undefined>(undefined)
  const [targetSiteContactExpiryValue, setTargetSiteContactExpiryValue] = useState<SiteContact>({
    canViewable: false,
    expiredOn: null
  })
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [openShareAlert, setOpenShareAlert] = useState(false)

  const handleOpenModal = useCallback(() => {
    setIsOpenModal(true)
  }, [])
  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false)
  }, [])
  const router = useRouter()
  const siteId = router.query.id as string
  const { data: siteResponse } = useGetSite(siteId)
  const site = siteResponse?.site
  const { data: siteContactResponse } = useListSiteContacts(siteId)
  const { data: siteDocumentsResponse } = useListSitesSiteIdDocuments(siteId)
  const contactList = siteContactResponse?.siteContacts
  const [instructions, setInstructions] = useState<string[] | undefined>(undefined)

  const isPublished = !!site?.isPublished
  const { handleUpdatePublish } = useUpdatePublish(siteId)
  const handleChange = useCallback(() => {
    handleUpdatePublish(siteId, !isPublished)
  }, [siteId, isPublished, handleUpdatePublish])

  const { handleUpdateSiteContact } = useUpdateSiteContact(siteId)

  useEffect(() => {
    if (instructions?.includes('site_has_restricted_document')) {
      setOpenShareAlert(true)
    }
  }, [instructions])

  const alertShareData: Omit<ModalAlertShareProps, 'open' | 'onClose'> = useMemo(() => {
    if (instructions?.includes('site_has_restricted_document')) {
      return {
        title: 'リンクの共有をONにできません',
        description: `資料サイト「${siteResponse?.site?.title}」に含まれる以下の資料の「リンクの共有」がOFFであるため、この資料の「リンクの共有」をONにできません`,
        infoList: [
          {
            question: 'この資料サイトの「リンクの共有」をONにするには？',
            answerList: [
              {
                label: '資料の「リンクの共有」をONにする'
              },
              {
                label: '資料サイトから以下資料を削除する'
              }
            ]
          },
          {
            question: 'この資料サイトで表示している資料',
            answerList:
              siteDocumentsResponse?.siteDocuments?.map(document => ({
                label: document.displayName,
                link: `/documents/${document.id}`
              })) || []
          }
        ]
      }
    }
    return {
      title: '',
      description: '',
      infoList: []
    }
  }, [siteResponse?.site?.title, instructions, siteDocumentsResponse?.siteDocuments])

  const list = useMemo(
    () =>
      contactList?.map(contact => ({
        ...contact,
        contactCompany: contact.contact?.company,
        user: contact.contact?.user
      })) || [],
    [contactList]
  )

  const columns: TableColumn[] = useMemo(
    () => [
      {
        name: 'createdAt',
        title: '最新のアクティビティ日時',
        width: 199,
        getCellValue: (value: SiteContact) => {
          const date = value.actedAt || value.updatedAt
          return date ? format(new Date(date).getTime(), 'yyyy/MM/dd (E) HH:mm', { locale: ja }) : '-'
        }
      },
      { name: 'inflowSource', title: '共有手段', width: 84, render: InflowCell },
      {
        name: 'currentActivityStatusObject',
        title: 'サイトアクティビティ',
        width: 140,
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => <ActivityStatusCell value={value.activityStatus} />
      },
      {
        name: 'contact',
        title: '名前',
        width: 126,
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => {
          return <NameCell value={value} />
        }
      },
      {
        name: 'contactCompany',
        width: 180,
        title: '会社名',
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => {
          return value?.displayName ? <CompanyNameCell value={value?.displayName} /> : null
        }
      },
      {
        name: 'role',
        width: 126,
        title: '役職',
        getCellValue: (data: SiteContact) => data.contact?.position!
      },
      {
        name: 'user',
        title: '担当者',
        width: 137,
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => <NameCell value={value} iconSize="large" />
      },
      {
        name: 'expiredOn',
        width: 267,
        title: '閲覧権限・期限',
        getCellValue: (value: SiteContact) => {
          return {
            siteContactId: value.id,
            expiredOn: value.expiredOn,
            canViewable: value.canViewable
          }
        },
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => (
          <ExpiryCell
            onClick={e => {
              e.stopPropagation()
              setTargetSiteContactId(value.siteContactId)
            }}
            {...value}
          />
        )
      }
    ],
    []
  )

  // 閲覧期限変更モーダルにデータを渡す
  useEffect(() => {
    if (!contactList || !contactList.length || !targetSiteContactId) return
    const { canViewable, expiredOn }: SiteContact = contactList.filter(contact => {
      return contact.id === targetSiteContactId
    })[0]
    setTargetSiteContactExpiryValue({ canViewable, expiredOn })
  }, [targetSiteContactId, contactList])

  const handleExpiryConfirm = ({ canViewable, expiredOn }: SiteContact) => {
    if (!targetSiteContactId) {
      return
    }

    handleUpdateSiteContact({
      siteId,
      siteContactId: targetSiteContactId,
      updateSiteContactRequest: { siteContact: { canViewable, expiredOn } }
    })
    setTargetSiteContactId(undefined)
  }

  return (
    <Layout>
      <Box display="flex" flexDirection="column" height="100%" bgcolor={Colors.functional.background.default}>
        <Box borderBottom={`1px solid ${Colors.background.gray}`}>
          <Header
            textSize="small"
            iconSize="20px"
            icon="folder"
            title={site?.archivedAt ? 'アーカイブ' : 'すべて'}
            back
            rightSection={
              <HeaderRightSection
                onOpenModal={handleOpenModal}
                id={siteId}
                published={isPublished}
                onChange={handleChange}
                isArchived={!!site?.archivedAt}
                siteTitle={site?.title!}
              />
            }
          />
        </Box>
        <Box height="100%" flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
          <Box width={1} padding="16px 16px 0 16px" mb="24px">
            <FolderDescription site={site} />
          </Box>
          <FolderContent columns={columns} rows={list || []} numberOfRows={siteContactResponse?.pageInfo?.totalCount} />
        </Box>
      </Box>
      <ModalSiteShare
        open={isOpenModal}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        siteId={siteId}
        setInstructions={setInstructions}
      />
      <ModalExpiryUpdate
        open={Boolean(targetSiteContactId)}
        onClose={() => setTargetSiteContactId(undefined)}
        onCancel={() => setTargetSiteContactId(undefined)}
        onConfirm={handleExpiryConfirm}
        value={targetSiteContactExpiryValue}
      />

      <ModalAlertShare open={openShareAlert} onClose={() => setOpenShareAlert(false)} {...alertShareData} />
    </Layout>
  )
}
