import { Box, CircularProgress } from '@material-ui/core'
import { OrganizationPlanDetailGradeEnum } from '@noco/http-client/lib/noco'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { LayoutPayment } from 'src/components/commons'
import { ContractFreePlanStepper, ContractPaidPlanStepper } from 'src/components/organisms'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import { useGetPlanLimit, useListOrganizationPaymentPlans } from 'src/fixtures/modules/organizationPayment/hooks'

const FreePlanGrades: OrganizationPlanDetailGradeEnum[] = [
  OrganizationPlanDetailGradeEnum.Free,
  OrganizationPlanDetailGradeEnum.FreePremium
]

export const PagePlanDetail = ({ planId }: { planId: string }) => {
  const { data: dataOrganization } = useGetOrganization()
  const router = useRouter()
  const organization = dataOrganization?.organization
  // TODO: @endotakuya List ではなく Get で取得できるエンドポイントを追加する
  const { data: dataPlan } = useListOrganizationPaymentPlans()
  const plans = dataPlan?.plans
  const targetPlan = plans?.find(plan => plan.id === planId)

  const { needDowngrade, mutate } = useGetPlanLimit(planId)

  useEffect(() => {
    if (needDowngrade) {
      router.replace('/setting/plans/[id]/purge', `/setting/plans/${planId}/purge`)
    }
  }, [needDowngrade, planId, router])

  useEffect(() => {
    mutate()
  }, [mutate])

  if (needDowngrade)
    return (
      <Box width={'100vw'} height={'100vh'} display="flex" alignItems={'center'} justifyContent="center">
        <CircularProgress />
      </Box>
    )

  if (targetPlan?.grade === OrganizationPlanDetailGradeEnum.Trial) return <></>

  return (
    <>
      {organization && targetPlan && (
        <LayoutPayment title="プランの申し込み">
          {FreePlanGrades.includes(targetPlan.grade) ? (
            <ContractFreePlanStepper plan={targetPlan} />
          ) : (
            <ContractPaidPlanStepper plan={targetPlan} organization={organization} />
          )}
        </LayoutPayment>
      )}
    </>
  )
}
