/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CompanyForDisplay,
  CompanyForDisplayFromJSON,
  CompanyForDisplayFromJSONTyped,
  CompanyForDisplayToJSON
} from './CompanyForDisplay'
import {
  ContactWithAssociation,
  ContactWithAssociationFromJSON,
  ContactWithAssociationFromJSONTyped,
  ContactWithAssociationToJSON
} from './ContactWithAssociation'
import {
  EnumActivityStatus,
  EnumActivityStatusFromJSON,
  EnumActivityStatusFromJSONTyped,
  EnumActivityStatusToJSON
} from './EnumActivityStatus'
import {
  EnumSupportStatus,
  EnumSupportStatusFromJSON,
  EnumSupportStatusFromJSONTyped,
  EnumSupportStatusToJSON
} from './EnumSupportStatus'
import {
  MaterialableForDisplay,
  MaterialableForDisplayFromJSON,
  MaterialableForDisplayFromJSONTyped,
  MaterialableForDisplayToJSON
} from './MaterialableForDisplay'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface EmailTransaction
 */
export interface EmailTransaction {
  /**
   *
   * @type {string}
   * @memberof EmailTransaction
   */
  id?: string
  /**
   *
   * @type {EnumSupportStatus}
   * @memberof EmailTransaction
   */
  supportStatus?: EnumSupportStatus
  /**
   *
   * @type {EnumActivityStatus}
   * @memberof EmailTransaction
   */
  activityStatus?: EnumActivityStatus
  /**
   *
   * @type {string}
   * @memberof EmailTransaction
   */
  subject?: string
  /**
   *
   * @type {string}
   * @memberof EmailTransaction
   */
  sendedAt?: string
  /**
   *
   * @type {string}
   * @memberof EmailTransaction
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof EmailTransaction
   */
  updatedAt?: string
  /**
   *
   * @type {boolean}
   * @memberof EmailTransaction
   */
  isSolved?: boolean
  /**
   *
   * @type {ContactWithAssociation}
   * @memberof EmailTransaction
   */
  contact?: ContactWithAssociation
  /**
   *
   * @type {CompanyForDisplay}
   * @memberof EmailTransaction
   */
  company?: CompanyForDisplay
  /**
   *
   * @type {MaterialableForDisplay}
   * @memberof EmailTransaction
   */
  materialable?: MaterialableForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof EmailTransaction
   */
  user?: UserForDisplay
}

export function EmailTransactionFromJSON(json: any): EmailTransaction {
  return EmailTransactionFromJSONTyped(json, false)
}

export function EmailTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTransaction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    supportStatus: !exists(json, 'support_status') ? undefined : EnumSupportStatusFromJSON(json['support_status']),
    activityStatus: !exists(json, 'activity_status') ? undefined : EnumActivityStatusFromJSON(json['activity_status']),
    subject: !exists(json, 'subject') ? undefined : json['subject'],
    sendedAt: !exists(json, 'sended_at') ? undefined : json['sended_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    isSolved: !exists(json, 'is_solved') ? undefined : json['is_solved'],
    contact: !exists(json, 'contact') ? undefined : ContactWithAssociationFromJSON(json['contact']),
    company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company']),
    materialable: !exists(json, 'materialable') ? undefined : MaterialableForDisplayFromJSON(json['materialable']),
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user'])
  }
}

export function EmailTransactionToJSON(value?: EmailTransaction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    support_status: EnumSupportStatusToJSON(value.supportStatus),
    activity_status: EnumActivityStatusToJSON(value.activityStatus),
    subject: value.subject,
    sended_at: value.sendedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    is_solved: value.isSolved,
    contact: ContactWithAssociationToJSON(value.contact),
    company: CompanyForDisplayToJSON(value.company),
    materialable: MaterialableForDisplayToJSON(value.materialable),
    user: UserForDisplayToJSON(value.user)
  }
}
