/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import { GetUsersResponse, GetUsersResponseFromJSON, GetUsersResponseToJSON } from '../models'

/**
 *
 */
export class ApiContactV1UserApi extends runtime.BaseAPI {
  /**
   * ユーザ一覧の取得
   * index
   */
  async contactV1UsersGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetUsersResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/contact/v1/users`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetUsersResponseFromJSON(jsonValue))
  }

  /**
   * ユーザ一覧の取得
   * index
   */
  async contactV1UsersGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GetUsersResponse> {
    const response = await this.contactV1UsersGetRaw(initOverrides)
    return await response.value()
  }
}
