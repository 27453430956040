/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateSiteSectionRequestSiteSectionFromJSON, CreateSiteSectionRequestSiteSectionToJSON } from './CreateSiteSectionRequestSiteSection';
/**
 * @export
 */
export const CreateContactSettingRequestContactSettingFieldsInnerKindEnum = {
    Text: 'text',
    Number: 'number',
    Radio: 'radio',
    Dropdown: 'dropdown',
    Checkbox: 'checkbox',
    Date: 'date',
    Time: 'time'
};
export function CreateContactSettingRequestContactSettingFieldsInnerFromJSON(json) {
    return CreateContactSettingRequestContactSettingFieldsInnerFromJSONTyped(json, false);
}
export function CreateContactSettingRequestContactSettingFieldsInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: !exists(json, 'name') ? undefined : json['name'],
        sort: !exists(json, 'sort') ? undefined : json['sort'],
        kind: !exists(json, 'kind') ? undefined : json['kind'],
        contactSettingFieldItems: !exists(json, 'contact_setting_field_items')
            ? undefined
            : json['contact_setting_field_items'] === null
                ? null
                : json['contact_setting_field_items'].map(CreateSiteSectionRequestSiteSectionFromJSON)
    };
}
export function CreateContactSettingRequestContactSettingFieldsInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        sort: value.sort,
        kind: value.kind,
        contact_setting_field_items: value.contactSettingFieldItems === undefined
            ? undefined
            : value.contactSettingFieldItems === null
                ? null
                : value.contactSettingFieldItems.map(CreateSiteSectionRequestSiteSectionToJSON)
    };
}
