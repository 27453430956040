/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentPlanTransaction,
  OrganizationPaymentPlanTransactionFromJSON,
  OrganizationPaymentPlanTransactionFromJSONTyped,
  OrganizationPaymentPlanTransactionToJSON
} from './OrganizationPaymentPlanTransaction'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanTransactionsResponseData
 */
export interface GetOrganizationPaymentPlanTransactionsResponseData {
  /**
   *
   * @type {Date}
   * @memberof GetOrganizationPaymentPlanTransactionsResponseData
   */
  usageStartAt: Date
  /**
   *
   * @type {Array<OrganizationPaymentPlanTransaction>}
   * @memberof GetOrganizationPaymentPlanTransactionsResponseData
   */
  planTransactions: Array<OrganizationPaymentPlanTransaction>
}

export function GetOrganizationPaymentPlanTransactionsResponseDataFromJSON(
  json: any
): GetOrganizationPaymentPlanTransactionsResponseData {
  return GetOrganizationPaymentPlanTransactionsResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanTransactionsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanTransactionsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    usageStartAt: new Date(json['usage_start_at']),
    planTransactions: (json['plan_transactions'] as Array<any>).map(OrganizationPaymentPlanTransactionFromJSON)
  }
}

export function GetOrganizationPaymentPlanTransactionsResponseDataToJSON(
  value?: GetOrganizationPaymentPlanTransactionsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    usage_start_at: value.usageStartAt.toISOString(),
    plan_transactions: (value.planTransactions as Array<any>).map(OrganizationPaymentPlanTransactionToJSON)
  }
}
