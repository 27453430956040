/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetVisitorResponseData,
  GetVisitorResponseDataFromJSON,
  GetVisitorResponseDataFromJSONTyped,
  GetVisitorResponseDataToJSON
} from './GetVisitorResponseData'

/**
 *
 * @export
 * @interface CreateVisitorResponse
 */
export interface CreateVisitorResponse {
  /**
   *
   * @type {number}
   * @memberof CreateVisitorResponse
   */
  status?: number
  /**
   *
   * @type {GetVisitorResponseData}
   * @memberof CreateVisitorResponse
   */
  data?: GetVisitorResponseData
}

export function CreateVisitorResponseFromJSON(json: any): CreateVisitorResponse {
  return CreateVisitorResponseFromJSONTyped(json, false)
}

export function CreateVisitorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateVisitorResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetVisitorResponseDataFromJSON(json['data'])
  }
}

export function CreateVisitorResponseToJSON(value?: CreateVisitorResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetVisitorResponseDataToJSON(value.data)
  }
}
