import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { AttachmentFile } from '../type'
import { FileIcon, isTargetIcon } from './FileIcon'
import { getFileExtension } from '../utils'

export interface FileCardContentProps {
  attachmentFile: AttachmentFile
  isEditing?: boolean
}

export const FileCardContent = ({ attachmentFile, isEditing = false }: FileCardContentProps) => {
  const fileExtension = useMemo(() => getFileExtension(attachmentFile.title), [attachmentFile.title])
  const subTitle = useMemo(() => {
    return attachmentFile.humanizedFileSize ?? fileExtension
      ? `${attachmentFile.humanizedFileSize} | ${fileExtension}`
      : ''
  }, [attachmentFile.humanizedFileSize, fileExtension])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isTargetIcon(fileExtension) && (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '46px',
            height: '42px',
            borderRadius: '4px',
            marginRight: '10px',
            bgcolor: Colors.background.lightGray
          }}
        >
          <FileIcon variant={fileExtension} />
        </Box>
      )}

      <Box sx={{ flex: '1', overflow: 'hidden' }}>
        <Box sx={{ color: Colors.base.black }}>
          <Typography fontSize="s" letterSpacing="tight" lh="tight" noWrap>
            {attachmentFile.title}
          </Typography>
        </Box>
        <Box color={Colors.base.middleGray}>
          <Typography fontSize="xs" lh="tight" color="inherit">
            {subTitle}
          </Typography>
        </Box>
      </Box>

      {!isEditing && (
        <Box lineHeight={1}>
          <Box ml="8px" color={Colors.base.middleGray}>
            <DynamicMuiIcon variant="fileDownload" size="20px" color="inherit" />
          </Box>
        </Box>
      )}
    </Box>
  )
}
