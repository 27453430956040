import { Box } from '@material-ui/core'
import { UserForDisplay } from '@noco/http-client/lib/noco'
import React from 'react'
import Image from 'next/image'
import { Typography } from 'src/components/atoms'

export interface UserNameCellProps {
  value?: UserForDisplay
}

export const UserNameCell = ({ value }: UserNameCellProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="24px" height="24px" flexShrink={0} mr="8px" borderRadius="24px" overflow="hidden">
        <Image src={value?.avatar?.url || ''} alt={value?.displayName} width="100%" height="100%" objectFit="cover" />
      </Box>
      <Box width="4px" />
      <Typography fontSize="s" fontWeight="bold">
        {value?.lastName} {value?.firstName}
      </Typography>
    </Box>
  )
}
