/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetStaffListsResponseFromJSON, PinStaffListRequestToJSON } from '../models';
/**
 *
 */
export class ApiUserV1StaffListApi extends runtime.BaseAPI {
    /**
     * 担当者のピン設定一覧と、紐づくリソースの件数を取得する
     * index
     */
    async userV1StaffListsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource_type'] = requestParameters.resourceType;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/staff_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetStaffListsResponseFromJSON(jsonValue));
    }
    /**
     * 担当者のピン設定一覧と、紐づくリソースの件数を取得する
     * index
     */
    async userV1StaffListsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1StaffListsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 担当者のピン設定をする
     * pin
     */
    async userV1StaffListsPinPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/staff_lists/pin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PinStaffListRequestToJSON(requestParameters.pinStaffListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * 担当者のピン設定をする
     * pin
     */
    async userV1StaffListsPinPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1StaffListsPinPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
