/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ArchiveSitesRequestToJSON, ArchiveSitesResponseFromJSON, CopySiteRequestToJSON, CopySiteResponseFromJSON, CreateSiteRequestToJSON, CreateSiteResponseFromJSON, CreateSiteSectionDocumentRequestToJSON, CreateSiteSectionDocumentResponseFromJSON, CreateSiteSectionRequestToJSON, CreateSiteSectionResponseFromJSON, GetSiteContactsResponseFromJSON, GetSiteDocumentsResponseFromJSON, GetSiteMiscSettingResponseFromJSON, GetSiteResponseFromJSON, GetSiteSectionsResponseFromJSON, GetSiteViewHistoriesResponseFromJSON, GetSitesResponseFromJSON, PublishSiteRequestToJSON, PublishSiteResponseFromJSON, ShareByLinkSiteRequestToJSON, ShareByLinkSiteResponseFromJSON, ShareToContactSiteRequestToJSON, ShareToContactSiteResponseFromJSON, SortSiteSectionDocumentRequestToJSON, SortSiteSectionRequestToJSON, UpdateDefaultCoverPhotoSiteRequestToJSON, UpdateDefaultCoverPhotoSiteResponseFromJSON, UpdateSiteContactRequestToJSON, UpdateSiteContactResponseFromJSON, UpdateSiteMiscSettingOGPFileResponseFromJSON, UpdateSiteMiscSettingRequestToJSON, UpdateSiteMiscSettingResponseFromJSON, UpdateSiteRequestToJSON, UpdateSiteResponseFromJSON, UpdateSiteSectionDocumentRequestToJSON, UpdateSiteSectionDocumentResponseFromJSON, UpdateSiteSectionRequestToJSON, UpdateSiteSectionResponseFromJSON, UploadCoverPhotoSiteResponseFromJSON, UploadHeaderLogoSiteRequestToJSON, UploadHeaderLogoSiteResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1SiteApi extends runtime.BaseAPI {
    /**
     * 資料サイトのアーカイブ設定
     * archive
     */
    async userV1SitesArchivePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/archive`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveSitesRequestToJSON(requestParameters.archiveSitesRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ArchiveSitesResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのアーカイブ設定
     * archive
     */
    async userV1SitesArchivePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1SitesArchivePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのコピーを行う（コピーのみで複製は行わない）
     * copy
     */
    async userV1SitesCopyPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/copy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CopySiteRequestToJSON(requestParameters.copySiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CopySiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのコピーを行う（コピーのみで複製は行わない）
     * copy
     */
    async userV1SitesCopyPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1SitesCopyPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトの一覧を取得する
     * index
     */
    async userV1SitesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.isArchived !== undefined) {
            queryParameters['is_archived'] = requestParameters.isArchived;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSitesResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトの一覧を取得する
     * index
     */
    async userV1SitesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SitesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトの作成
     * create
     */
    async userV1SitesPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSiteRequestToJSON(requestParameters.createSiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトの作成
     * create
     */
    async userV1SitesPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1SitesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのカバー写真をデフォルトから選択したものを設定
     * default_photo
     */
    async userV1SitesSiteIdDefaultPhotoPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdDefaultPhotoPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/default_photo`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDefaultCoverPhotoSiteRequestToJSON(requestParameters.updateDefaultCoverPhotoSiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateDefaultCoverPhotoSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのカバー写真をデフォルトから選択したものを設定
     * default_photo
     */
    async userV1SitesSiteIdDefaultPhotoPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdDefaultPhotoPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトに関連する資料一覧を取得する
     * index
     */
    async userV1SitesSiteIdDocumentsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdDocumentsGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/documents`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSiteDocumentsResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトに関連する資料一覧を取得する
     * index
     */
    async userV1SitesSiteIdDocumentsGet(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdDocumentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトの詳細を取得する
     * show
     */
    async userV1SitesSiteIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトの詳細を取得する
     * show
     */
    async userV1SitesSiteIdGet(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイト設定を取得する
     * show
     */
    async userV1SitesSiteIdMiscSettingGetRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdMiscSettingGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/misc_setting`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSiteMiscSettingResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイト設定を取得する
     * show
     */
    async userV1SitesSiteIdMiscSettingGet(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdMiscSettingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイト設定のOGPファイルを更新する
     * ogp_file
     */
    async userV1SitesSiteIdMiscSettingOgpFilePutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdMiscSettingOgpFilePut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/misc_setting/ogp_file`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteMiscSettingOGPFileResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイト設定のOGPファイルを更新する
     * ogp_file
     */
    async userV1SitesSiteIdMiscSettingOgpFilePut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdMiscSettingOgpFilePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイト設定を更新する
     * update
     */
    async userV1SitesSiteIdMiscSettingPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdMiscSettingPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/misc_setting`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSiteMiscSettingRequestToJSON(requestParameters.updateSiteMiscSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteMiscSettingResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイト設定を更新する
     * update
     */
    async userV1SitesSiteIdMiscSettingPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdMiscSettingPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトの公開
     * publish
     */
    async userV1SitesSiteIdPublishPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdPublishPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/publish`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PublishSiteRequestToJSON(requestParameters.publishSiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => PublishSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトの公開
     * publish
     */
    async userV1SitesSiteIdPublishPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdPublishPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトの更新
     * update
     */
    async userV1SitesSiteIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSiteRequestToJSON(requestParameters.updateSiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトの更新
     * update
     */
    async userV1SitesSiteIdPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトの資料追加
     * create
     */
    async userV1SitesSiteIdSectionDocumentsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/section_documents`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSiteSectionDocumentRequestToJSON(requestParameters.createSiteSectionDocumentRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateSiteSectionDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトの資料追加
     * create
     */
    async userV1SitesSiteIdSectionDocumentsPost(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionDocumentsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクションの資料の削除
     * destroy
     */
    async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDelete.');
        }
        if (requestParameters.siteSectionDocumentId === null || requestParameters.siteSectionDocumentId === undefined) {
            throw new runtime.RequiredError('siteSectionDocumentId', 'Required parameter requestParameters.siteSectionDocumentId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/section_documents/{site_section_document_id}`
                .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
                .replace(`{${'site_section_document_id'}}`, encodeURIComponent(String(requestParameters.siteSectionDocumentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * 資料サイトのセクションの資料の削除
     * destroy
     */
    async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクションの資料の更新。section の移動も対応
     * update
     */
    async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPut.');
        }
        if (requestParameters.siteSectionDocumentId === null || requestParameters.siteSectionDocumentId === undefined) {
            throw new runtime.RequiredError('siteSectionDocumentId', 'Required parameter requestParameters.siteSectionDocumentId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/section_documents/{site_section_document_id}`
                .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
                .replace(`{${'site_section_document_id'}}`, encodeURIComponent(String(requestParameters.siteSectionDocumentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSiteSectionDocumentRequestToJSON(requestParameters.updateSiteSectionDocumentRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteSectionDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのセクションの資料の更新。section の移動も対応
     * update
     */
    async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクションの資料のソート更新
     * sort
     */
    async userV1SitesSiteIdSectionDocumentsSortPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSortPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/section_documents/sort`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SortSiteSectionDocumentRequestToJSON(requestParameters.sortSiteSectionDocumentRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * 資料サイトのセクションの資料のソート更新
     * sort
     */
    async userV1SitesSiteIdSectionDocumentsSortPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionDocumentsSortPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクション一覧を取得
     * index
     */
    async userV1SitesSiteIdSectionsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/sections`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSiteSectionsResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのセクション一覧を取得
     * index
     */
    async userV1SitesSiteIdSectionsGet(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクションの作成
     * create
     */
    async userV1SitesSiteIdSectionsPostRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/sections`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSiteSectionRequestToJSON(requestParameters.createSiteSectionRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateSiteSectionResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのセクションの作成
     * create
     */
    async userV1SitesSiteIdSectionsPost(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクションの削除
     * destroy
     */
    async userV1SitesSiteIdSectionsSiteSectionIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdDelete.');
        }
        if (requestParameters.siteSectionId === null || requestParameters.siteSectionId === undefined) {
            throw new runtime.RequiredError('siteSectionId', 'Required parameter requestParameters.siteSectionId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/sections/{site_section_id}`
                .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
                .replace(`{${'site_section_id'}}`, encodeURIComponent(String(requestParameters.siteSectionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * 資料サイトのセクションの削除
     * destroy
     */
    async userV1SitesSiteIdSectionsSiteSectionIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionsSiteSectionIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクションの更新
     * update
     */
    async userV1SitesSiteIdSectionsSiteSectionIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdPut.');
        }
        if (requestParameters.siteSectionId === null || requestParameters.siteSectionId === undefined) {
            throw new runtime.RequiredError('siteSectionId', 'Required parameter requestParameters.siteSectionId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/sections/{site_section_id}`
                .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
                .replace(`{${'site_section_id'}}`, encodeURIComponent(String(requestParameters.siteSectionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSiteSectionRequestToJSON(requestParameters.updateSiteSectionRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteSectionResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのセクションの更新
     * update
     */
    async userV1SitesSiteIdSectionsSiteSectionIdPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionsSiteSectionIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのセクションのソート更新
     * sort
     */
    async userV1SitesSiteIdSectionsSortPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsSortPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/sections/sort`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SortSiteSectionRequestToJSON(requestParameters.sortSiteSectionRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * 資料サイトのセクションのソート更新
     * sort
     */
    async userV1SitesSiteIdSectionsSortPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSectionsSortPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトをリンクからの共有設定
     * share_by_link
     */
    async userV1SitesSiteIdShareByLinkPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdShareByLinkPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/share_by_link`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShareByLinkSiteRequestToJSON(requestParameters.shareByLinkSiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ShareByLinkSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトをリンクからの共有設定
     * share_by_link
     */
    async userV1SitesSiteIdShareByLinkPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdShareByLinkPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトをcontactを指定しての共有設定
     * share_to_contact
     */
    async userV1SitesSiteIdShareToContactPostRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdShareToContactPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/share_to_contact`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareToContactSiteRequestToJSON(requestParameters.shareToContactSiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ShareToContactSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトをcontactを指定しての共有設定
     * share_to_contact
     */
    async userV1SitesSiteIdShareToContactPost(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdShareToContactPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのコンタクト一覧取得
     * index
     */
    async userV1SitesSiteIdSiteContactsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSiteContactsGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/site_contacts`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSiteContactsResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのコンタクト一覧取得
     * index
     */
    async userV1SitesSiteIdSiteContactsGet(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSiteContactsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのコンタクトの編集
     * update
     */
    async userV1SitesSiteIdSiteContactsSiteContactIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSiteContactsSiteContactIdPut.');
        }
        if (requestParameters.siteContactId === null || requestParameters.siteContactId === undefined) {
            throw new runtime.RequiredError('siteContactId', 'Required parameter requestParameters.siteContactId was null or undefined when calling userV1SitesSiteIdSiteContactsSiteContactIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/site_contacts/{site_contact_id}`
                .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
                .replace(`{${'site_contact_id'}}`, encodeURIComponent(String(requestParameters.siteContactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSiteContactRequestToJSON(requestParameters.updateSiteContactRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteContactResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのコンタクトの編集
     * update
     */
    async userV1SitesSiteIdSiteContactsSiteContactIdPut(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdSiteContactsSiteContactIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのカバー写真の更新
     * upload_cover_photo
     */
    async userV1SitesSiteIdUploadCoverPhotoPostRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdUploadCoverPhotoPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/upload_cover_photo`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UploadCoverPhotoSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのカバー写真の更新
     * upload_cover_photo
     */
    async userV1SitesSiteIdUploadCoverPhotoPost(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdUploadCoverPhotoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイトのヘッダーロゴの更新（※現在は使われていない）
     * upload_header_logo
     */
    async userV1SitesSiteIdUploadHeaderLogoPostRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdUploadHeaderLogoPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/upload_header_logo`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadHeaderLogoSiteRequestToJSON(requestParameters.uploadHeaderLogoSiteRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UploadHeaderLogoSiteResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイトのヘッダーロゴの更新（※現在は使われていない）
     * upload_header_logo
     */
    async userV1SitesSiteIdUploadHeaderLogoPost(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdUploadHeaderLogoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料サイト閲覧履歴一覧を取得する
     * index
     */
    async userV1SitesSiteIdViewHistoriesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId', 'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdViewHistoriesGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/sites/{site_id}/view_histories`.replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSiteViewHistoriesResponseFromJSON(jsonValue));
    }
    /**
     * 資料サイト閲覧履歴一覧を取得する
     * index
     */
    async userV1SitesSiteIdViewHistoriesGet(requestParameters, initOverrides) {
        const response = await this.userV1SitesSiteIdViewHistoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
