/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumActivityStatusFromJSON, EnumActivityStatusToJSON } from './EnumActivityStatus';
export function ActivityStatusObjectFromJSON(json) {
    return ActivityStatusObjectFromJSONTyped(json, false);
}
export function ActivityStatusObjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        activityStatus: !exists(json, 'activity_status') ? undefined : EnumActivityStatusFromJSON(json['activity_status']),
        countOfAction: !exists(json, 'count_of_action') ? undefined : json['count_of_action'],
        solvedAt: !exists(json, 'solved_at') ? undefined : json['solved_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function ActivityStatusObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        activity_status: EnumActivityStatusToJSON(value.activityStatus),
        count_of_action: value.countOfAction,
        solved_at: value.solvedAt,
        updated_at: value.updatedAt
    };
}
