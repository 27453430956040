import React from 'react'
import { LayoutUser } from 'src/components/commons'
import { Profile } from 'src/components/organisms'
import { ProfileCard } from 'src/components/organisms'
import { DynamicMuiIcon, Colors } from 'src/components/atoms'
import { Box, Typography } from '@material-ui/core'
import { useGetMe, useGetMeProfile } from 'src/fixtures/modules/me/hooks'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'

export const PageProfile = () => {
  const { data: organizationRes } = useGetOrganization()
  const organization = organizationRes?.organization
  const { data: meRes } = useGetMe()
  const user = meRes?.user
  const { data: profile } = useGetMeProfile()

  return (
    <LayoutUser>
      <Box
        display="flex"
        flexDirection="column"
        width={1}
        bgcolor={Colors.functional.background.default}
        px="32px"
        overflow="auto"
      >
        <Box display="flex" alignItems="center" pt="16px" pb="24px">
          <DynamicMuiIcon variant="profile" />
          <Box width="10px" />
          <Typography variant="h2">プロフィール</Typography>
        </Box>
        <Typography variant="subtitle1">
          資料共有の画面やチャットに、プロフィール情報を表示することができます
        </Typography>
        <Box display="flex" mt="32px">
          <Box width="600px">{profile && <Profile profile={profile} />}</Box>
          <Box width="292px" ml="24px">
            <ProfileCard
              birthplace={profile?.birthplace}
              department={profile?.department}
              hobby={profile?.hobby}
              organizationName={organization?.name}
              position={profile?.position}
              selfIntroduction={profile?.selfIntroduction}
              socialLinkYoutube={profile?.socialLinkYoutube}
              socialLinkFacebook={profile?.socialLinkFacebook}
              socialLinkTwitter={profile?.socialLinkTwitter}
              socialLinkNote={profile?.socialLinkNote}
              socialLinkWebSite={profile?.socialLinkWebSite}
              userName={`${user?.lastName}${user?.firstName}`}
              userAvatarUrl={user?.avatar?.url}
            />
            <Box mt="21px" />
          </Box>
        </Box>
      </Box>
    </LayoutUser>
  )
}
