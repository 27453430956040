/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumChatSummaryRepliedStatus,
  EnumChatSummaryRepliedStatusFromJSON,
  EnumChatSummaryRepliedStatusFromJSONTyped,
  EnumChatSummaryRepliedStatusToJSON
} from './EnumChatSummaryRepliedStatus'

/**
 *
 * @export
 * @interface InboxFilterChatSummaryStatusesInner
 */
export interface InboxFilterChatSummaryStatusesInner {
  /**
   *
   * @type {EnumChatSummaryRepliedStatus}
   * @memberof InboxFilterChatSummaryStatusesInner
   */
  key?: EnumChatSummaryRepliedStatus
  /**
   *
   * @type {string}
   * @memberof InboxFilterChatSummaryStatusesInner
   */
  text?: string
}

export function InboxFilterChatSummaryStatusesInnerFromJSON(json: any): InboxFilterChatSummaryStatusesInner {
  return InboxFilterChatSummaryStatusesInnerFromJSONTyped(json, false)
}

export function InboxFilterChatSummaryStatusesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InboxFilterChatSummaryStatusesInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    key: !exists(json, 'key') ? undefined : EnumChatSummaryRepliedStatusFromJSON(json['key']),
    text: !exists(json, 'text') ? undefined : json['text']
  }
}

export function InboxFilterChatSummaryStatusesInnerToJSON(value?: InboxFilterChatSummaryStatusesInner | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    key: EnumChatSummaryRepliedStatusToJSON(value.key),
    text: value.text
  }
}
