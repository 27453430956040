import {
  Box,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  CircularProgress,
  createStyles,
  makeStyles
} from '@material-ui/core'
import React, { ChangeEvent, useCallback, useRef, useEffect } from 'react'
import { Colors, Pallete, Typography, TypographyProps, DynamicMuiIcon } from '..'
import clsx from 'clsx'

export interface ButtonProps extends MuiButtonProps {
  title: string
  width?: string
  kind?: 'primary' | 'secondary' | 'negative' | 'attention' | 'neutral' | 'white'
  variant?: 'contained' | 'outlined'
  size?: 'small' | 'medium' | 'large'
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  disabled?: boolean
  buttonColor?: string
  textColor?: string
  hasBorder?: boolean
  loading?: boolean
  completed?: boolean
  clearCompleted?: () => void
  onSelectFile?: (file: FileList) => void
  starticonmargin?: string
  typography?: TypographyProps
}

const getButtonColors = () => {
  return {
    primary: { default: Colors.accent.keyPurple.default, hover: Colors.accent.keyPurple.default },
    secondary: { default: Colors.accent.keyBlue.default, hover: Colors.accent.keyBlue.default },
    negative: { default: Colors.accent.keyRed.default, hover: Colors.accent.keyRed.default },
    attention: { default: Colors.accent.keyRed.default, hover: Colors.accent.keyRed.default }
  }
}

const getBgColor = ({ kind = 'primary', variant, disabled }: Partial<ButtonProps>, isHover?: boolean) => {
  if (disabled) return `${Colors.base.placeHolder} !important`
  else if (variant === 'outlined' || kind === 'neutral') return 'transparent'
  else if (kind === 'white') return Colors.functional.background.default

  return isHover ? getButtonColors()[kind].hover : getButtonColors()[kind].default
}

const getTextColor = ({ kind = 'primary', variant, disabled }: Partial<ButtonProps>, isHover?: boolean) => {
  if (disabled) return `${Colors.background.silver} !important`
  else if (kind === 'white') return Colors.base.middleGray
  else if (kind === 'neutral') {
    return isHover ? Colors.base.black : Colors.base.middleGray
  } else if (variant === 'outlined') return getButtonColors()[kind].default
  return Pallete.functional.whiteText.default
}

const getBorder = ({ kind = 'primary', variant, disabled }: Partial<ButtonProps>, isHover?: boolean) => {
  if (kind === 'neutral' && !disabled)
    return `1px solid ${isHover ? Colors.base.placeHolder : Colors.background.silver}`
  else if (kind === 'white') return Colors.background.silver
  else if (kind === 'neutral' || disabled || variant === 'contained') return 'none'
  const borderColor = isHover ? getButtonColors()[kind].hover : getButtonColors()[kind].default
  return `1px solid ${borderColor}`
}

const getStylesBySize = ({ size = 'medium' }: Partial<ButtonProps>) => {
  // NOTE: 高さは height で固定, 横幅は可変で padding 指定 (@locco) ... large だけ横幅固定に変更の可能性あり
  return {
    large: {
      height: '46px',
      padding: '0px 64px'
    },
    medium: {
      height: '34px',
      padding: '0px 16px'
    },
    small: {
      height: '28px',
      padding: '0px 8px'
    }
  }[size]
}

const getIconSize = (size: ButtonProps['size']) => {
  if (size === 'small') {
    return '16px'
  } else if (size === 'large') {
    return '32px'
  }
  return '24px'
}

const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      width: ({ width = 'auto' }: ButtonProps) => width,
      height: ({ size = 'medium' }: ButtonProps) => getStylesBySize({ size }).height,
      padding: ({ size = 'medium' }: ButtonProps) => getStylesBySize({ size }).padding,
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'none',
      border: ({ kind = 'primary', variant = 'contained', disabled = false, hasBorder = true }: ButtonProps) =>
        hasBorder ? getBorder({ kind, variant, disabled }) : 'none',
      color: ({ kind = 'primary', variant = 'contained', disabled = false, textColor, loading }: ButtonProps) =>
        textColor ? textColor : getTextColor({ kind, variant, disabled: disabled || loading }),
      backgroundColor: ({
        kind = 'primary',
        variant = 'contained',
        buttonColor,
        disabled = false,
        loading
      }: ButtonProps) => (buttonColor ? buttonColor : getBgColor({ kind, variant, disabled: disabled || loading })),
      '&:hover': {
        backgroundColor: ({ kind = 'primary', variant = 'contained', buttonColor, disabled = false }: ButtonProps) =>
          buttonColor ? buttonColor : getBgColor({ kind, variant, disabled }, true),
        color: ({ kind = 'primary', variant = 'contained', disabled = false, textColor }: ButtonProps) =>
          textColor ? textColor : getTextColor({ kind, variant, disabled }, true),
        border: ({ kind = 'primary', variant = 'contained', disabled = false, hasBorder = true }: ButtonProps) =>
          hasBorder ? getBorder({ kind, variant, disabled }, true) : 'none'
      },
      '&:disabled': {
        backgroundColor: `${Colors.base.placeHolder} !important`,
        color: `${Colors.background.silver} !important`
      }
    },
    startIcon: {
      margin: ({ starticonmargin }: { starticonmargin?: string }) => starticonmargin
    }
  })
})

export const Button = (props: ButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const buttonWidth = useRef<number>()
  const { title, size, className, loading, onSelectFile, typography, completed, clearCompleted, ...otherProps } = props
  const classes = useStyles(props)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onSelectFile && e.target.files && onSelectFile(e.target.files)
    },
    [onSelectFile]
  )

  useEffect(() => {
    if (buttonRef.current) {
      buttonWidth.current = buttonRef.current.offsetWidth
    }
  }, [buttonRef])

  // 完了した場合、3秒間の間は完了表示をする
  if (completed) {
    setTimeout(() => {
      clearCompleted?.()
    }, 64)

    return (
      <MuiButton
        className={clsx(classes.button, className)}
        {...otherProps}
        disabled
        classes={{ disabled: classes.button }}
        style={{ width: buttonWidth.current }}
      >
        <Box sx={{ display: 'flex' }}>
          <DynamicMuiIcon variant="check" size={getIconSize(size)} />
        </Box>
      </MuiButton>
    )
  }

  return (
    <MuiButton
      className={clsx(classes.button, className)}
      ref={buttonRef}
      classes={{ startIcon: classes.startIcon }}
      component={onSelectFile ? 'label' : 'button'}
      {...otherProps}
      disabled={otherProps.disabled || loading}
    >
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size={getIconSize(size)} />
        </Box>
      ) : (
        <Typography variant={size === 'large' ? 'h4' : 'h5'} {...typography}>
          {title}
        </Typography>
      )}
      {onSelectFile && <input type="file" accept="image/*" hidden onChange={handleChange} />}
    </MuiButton>
  )
}
