import { Box, ButtonBase, IconButton, useMediaQuery } from '@material-ui/core'
import { Document } from '@noco/http-client/lib/noco'
import { Close } from '@material-ui/icons'
import React from 'react'
import { Colors } from 'src/components/atoms'
import theme from 'src/styles/theme'

export interface CTADialogProps {
  cta: Document['documentCta']
  onClose?: () => void
  onClickCtaButton?: () => void
}
export const CTADialog = ({ cta, onClose, onClickCtaButton }: CTADialogProps) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      sx={{
        // width: '100%',
        width: '100vw',
        maxWidth: isDownSm ? '100%' : '375px',
        minHeight: '200px',
        display: 'flex',
        bgcolor: 'white',
        borderRadius: '6px',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #DADCE0',
        boxShadow:
          '0px 0px 1px 0px #241F4714, 0px 4px 8px 0px #241F4714, 0px 16px 24px 0px #241F471A, 0px 12px 60px 0px #241F4726',
        position: 'relative'
      }}
    >
      <Box sx={{ width: '100%', textAlign: 'center', padding: isDownSm ? '18px' : '35px' }}>
        <Box
          sx={{ fontSize: '18px', lineHeight: '27px', letterSpacing: '2%', fontWeight: 'bold', marginBottom: '8px' }}
        >
          {cta?.title || 'タイトルが設定されていません'}
        </Box>
        <Box sx={{ marginBottom: '8px', whiteSpace: 'pre-wrap' }}>{cta?.description}</Box>
        <ButtonBase
          style={{
            backgroundColor: cta?.buttonColor || Colors.accent.keyRed.default,
            color: 'white',
            padding: '9px 16px',
            borderRadius: '4px',
            fontWeight: 'bold',
            height: '32px',
            minWidth: '116px'
          }}
          onClick={() => {
            window.open(cta?.buttonUrl || '', '_blank')
            onClickCtaButton?.()
          }}
        >
          {cta?.buttonName || 'no title'}
        </ButtonBase>
      </Box>

      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  )
}
