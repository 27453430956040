/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationResponseData,
  GetOrganizationResponseDataFromJSON,
  GetOrganizationResponseDataFromJSONTyped,
  GetOrganizationResponseDataToJSON
} from './GetOrganizationResponseData'

/**
 *
 * @export
 * @interface UpdateOrganizationTriggerEmailiResponse
 */
export interface UpdateOrganizationTriggerEmailiResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateOrganizationTriggerEmailiResponse
   */
  status?: number
  /**
   *
   * @type {GetOrganizationResponseData}
   * @memberof UpdateOrganizationTriggerEmailiResponse
   */
  data?: GetOrganizationResponseData
}

export function UpdateOrganizationTriggerEmailiResponseFromJSON(json: any): UpdateOrganizationTriggerEmailiResponse {
  return UpdateOrganizationTriggerEmailiResponseFromJSONTyped(json, false)
}

export function UpdateOrganizationTriggerEmailiResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationTriggerEmailiResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetOrganizationResponseDataFromJSON(json['data'])
  }
}

export function UpdateOrganizationTriggerEmailiResponseToJSON(
  value?: UpdateOrganizationTriggerEmailiResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationResponseDataToJSON(value.data)
  }
}
