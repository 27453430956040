/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateSiteMiscSettingRequestSiteMiscSetting
 */
export interface UpdateSiteMiscSettingRequestSiteMiscSetting {
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteMiscSettingRequestSiteMiscSetting
   */
  isPublicSearchEngine?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateSiteMiscSettingRequestSiteMiscSetting
   */
  ogpTitle?: string
  /**
   *
   * @type {string}
   * @memberof UpdateSiteMiscSettingRequestSiteMiscSetting
   */
  ogpDescription?: string
}

export function UpdateSiteMiscSettingRequestSiteMiscSettingFromJSON(
  json: any
): UpdateSiteMiscSettingRequestSiteMiscSetting {
  return UpdateSiteMiscSettingRequestSiteMiscSettingFromJSONTyped(json, false)
}

export function UpdateSiteMiscSettingRequestSiteMiscSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSiteMiscSettingRequestSiteMiscSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    isPublicSearchEngine: !exists(json, 'is_public_search_engine') ? undefined : json['is_public_search_engine'],
    ogpTitle: !exists(json, 'ogp_title') ? undefined : json['ogp_title'],
    ogpDescription: !exists(json, 'ogp_description') ? undefined : json['ogp_description']
  }
}

export function UpdateSiteMiscSettingRequestSiteMiscSettingToJSON(
  value?: UpdateSiteMiscSettingRequestSiteMiscSetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_public_search_engine: value.isPublicSearchEngine,
    ogp_title: value.ogpTitle,
    ogp_description: value.ogpDescription
  }
}
