import { Box } from '@material-ui/core'
import { EnumChatSummaryRepliedStatus } from '@noco/http-client/lib/noco'
import React from 'react'
import { Colors, DynamicMuiIcon, MuiIconVariant, Typography } from 'src/components/atoms'

export interface RepliedStatusCellProps {
  value: EnumChatSummaryRepliedStatus
}

const labelList: { [key: string]: labelData } | undefined = {
  unresponsive: {
    icon: 'notificationImportantOutlined',
    label: '未対応',
    iconColor: Colors.accent.keyPurple.default,
    textColor: Colors.accent.keyPurple.default
  },
  no_reply_required: {
    icon: 'doDisturbAlt',
    label: '返信不要',
    iconColor: Colors.base.placeHolder,
    textColor: Colors.base.middleGray
  },
  sent: {
    icon: 'checkCircle',
    label: '返信済み',
    iconColor: Colors.base.placeHolder,
    textColor: Colors.base.middleGray
  }
  // error: {
  //   icon: 'errorOutlineOutlined',
  //   label: '送信エラー',
  //   iconColor: Colors.accent.keyRed.default,
  //   textColor: Colors.accent.keyRed.default
  // }
}

interface labelData {
  icon: MuiIconVariant
  label: string
  iconColor: string
  textColor: string
}

export const RepliedStatusCell = ({ value }: RepliedStatusCellProps) => {
  if (!labelList[value]) {
    return <Box>aaa</Box>
  }
  return (
    <Box display="flex" alignItems="center" color={labelList[value].textColor} overflow="hidden">
      <Box color={labelList[value].iconColor} mr="4px" display="flex" alignItems="center">
        <DynamicMuiIcon variant={labelList[value].icon} size="20px" color="inherit" />
      </Box>
      <Typography fontSize="s" fontWeight="bold" noWrap>
        {labelList[value].label}
      </Typography>
    </Box>
  )
}
