/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumCtaPopupPositionFromJSON, EnumCtaPopupPositionToJSON } from './EnumCtaPopupPosition';
import { UploadedFileFromJSON, UploadedFileToJSON } from './UploadedFile';
export function DocumentCtaFromJSON(json) {
    return DocumentCtaFromJSONTyped(json, false);
}
export function DocumentCtaFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        page: !exists(json, 'page') ? undefined : json['page'],
        popupPosition: !exists(json, 'popup_position') ? undefined : EnumCtaPopupPositionFromJSON(json['popup_position']),
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        buttonName: !exists(json, 'button_name') ? undefined : json['button_name'],
        buttonColor: !exists(json, 'button_color') ? undefined : json['button_color'],
        buttonUrl: !exists(json, 'button_url') ? undefined : json['button_url'],
        image: !exists(json, 'image') ? undefined : UploadedFileFromJSON(json['image']),
        isView: !exists(json, 'is_view') ? undefined : json['is_view'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function DocumentCtaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        page: value.page,
        popup_position: EnumCtaPopupPositionToJSON(value.popupPosition),
        title: value.title,
        description: value.description,
        button_name: value.buttonName,
        button_color: value.buttonColor,
        button_url: value.buttonUrl,
        image: UploadedFileToJSON(value.image),
        is_view: value.isView,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
