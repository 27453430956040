import { OrganizationPolicySetting, Document, Organization } from '@noco/http-client/lib/noco'
import React, { useEffect, useState } from 'react'
import { ModalAuthentication } from 'src/components/modals/ModalAuthentication'
import { ModalSendEmailWithAuthenticationCode } from 'src/components/modals/ModalSendEmailWithAuthenticationCode'
import { useSendAuthenticationCode, useVerifyAuthenticationCode } from 'src/fixtures/modules/contactAuth/hooks'

export interface ModalTowAuthenticateWithEmailProps {
  title: string
  open: boolean
  siteId?: string
  document: Document
  organization: Organization
  policySettings?: OrganizationPolicySetting[]
  isPreview?: boolean
  onSuccess: () => void
  onClose?: () => void
}
export const ModalTowAuthenticateWithEmail = ({
  document,
  organization,
  siteId,
  policySettings,
  open,
  isPreview,
  onClose,
  onSuccess
}: ModalTowAuthenticateWithEmailProps) => {
  const documentId = document.id
  const [email, setEmail] = useState('')
  const {
    handleSendAuthCodeWithEmail,
    isLoading: isLoadingSend,
    error: errorSend,
    email: sentEmailAddress
  } = useSendAuthenticationCode()
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false)
  const { handleVerifyAuthCode, isLoading, error } = useVerifyAuthenticationCode(isPreview)
  const [isSubmittingCode, setIsSubmittingCode] = useState(false)
  const [isSubmittingSendEmail, setIsSubmittingSendEmail] = useState(false)

  useEffect(() => {
    setOpenSendEmailModal(open)
  }, [open])

  // authentication code 送信周り
  useEffect(() => {
    // 送信がうまくいった場合
    if (isSubmittingCode && !isLoading && !error) {
      setIsSubmittingCode(false)
      setOpenSendEmailModal(false)
      setEmail('')
      onSuccess()
    }
  }, [error, isLoading, isSubmittingCode, onSuccess])

  useEffect(() => {
    if (isSubmittingSendEmail && !isLoadingSend && !errorSend) {
      setEmail(sentEmailAddress)
      setIsSubmittingSendEmail(false)
    }
  }, [errorSend, isLoadingSend, isSubmittingSendEmail, onSuccess, sentEmailAddress])

  return (
    <>
      {/* メールアドレスを入力するフォーム */}
      <ModalSendEmailWithAuthenticationCode
        title={document.title!}
        policySettings={policySettings}
        organizationName={organization.name}
        onSubmit={email => {
          if (documentId) {
            handleSendAuthCodeWithEmail({ emailAddress: email, documentId, siteId })
            setIsSubmittingSendEmail(true)
          }
        }}
        open={openSendEmailModal}
        onClose={() => onClose?.()}
      />
      {/* コード入力 */}
      <ModalAuthentication
        open={!!email}
        email={email || ''}
        resend={() => {
          if (documentId) {
            handleSendAuthCodeWithEmail({ emailAddress: email, documentId, siteId })
          }
        }}
        onSubmit={code => {
          setIsSubmittingCode(true)
          handleVerifyAuthCode({ code })
        }}
        onClose={() => {
          setEmail('')
        }}
      />
    </>
  )
}
