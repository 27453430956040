/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CopyMailTemplateRequest,
  CopyMailTemplateRequestFromJSON,
  CopyMailTemplateRequestToJSON,
  CopyMailTemplateResponse,
  CopyMailTemplateResponseFromJSON,
  CopyMailTemplateResponseToJSON,
  CreateMailTemplateRequest,
  CreateMailTemplateRequestFromJSON,
  CreateMailTemplateRequestToJSON,
  CreateMailTemplateResponse,
  CreateMailTemplateResponseFromJSON,
  CreateMailTemplateResponseToJSON,
  DeleteMailTemplateResponse,
  DeleteMailTemplateResponseFromJSON,
  DeleteMailTemplateResponseToJSON,
  GetMailTemplateResponse,
  GetMailTemplateResponseFromJSON,
  GetMailTemplateResponseToJSON,
  GetMailTemplatesResponse,
  GetMailTemplatesResponseFromJSON,
  GetMailTemplatesResponseToJSON,
  UpdateMailTemplateRequest,
  UpdateMailTemplateRequestFromJSON,
  UpdateMailTemplateRequestToJSON,
  UpdateMailTemplateResponse,
  UpdateMailTemplateResponseFromJSON,
  UpdateMailTemplateResponseToJSON,
  VariablesMailTemplateResponse,
  VariablesMailTemplateResponseFromJSON,
  VariablesMailTemplateResponseToJSON
} from '../models'

export interface UserV1MailTemplatesCopyPostRequest {
  copyMailTemplateRequest?: CopyMailTemplateRequest
}

export interface UserV1MailTemplatesGetRequest {
  page?: number
  per?: number
}

export interface UserV1MailTemplatesMailTemplateIdDeleteRequest {
  mailTemplateId: string
}

export interface UserV1MailTemplatesMailTemplateIdGetRequest {
  mailTemplateId: string
}

export interface UserV1MailTemplatesMailTemplateIdPutRequest {
  mailTemplateId: string
  updateMailTemplateRequest?: UpdateMailTemplateRequest
}

export interface UserV1MailTemplatesPostRequest {
  createMailTemplateRequest?: CreateMailTemplateRequest
}

/**
 *
 */
export class ApiUserV1MailTemplateApi extends runtime.BaseAPI {
  /**
   * メールテンプレートを複製する
   * copy
   */
  async userV1MailTemplatesCopyPostRaw(
    requestParameters: UserV1MailTemplatesCopyPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CopyMailTemplateResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/mail_templates/copy`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CopyMailTemplateRequestToJSON(requestParameters.copyMailTemplateRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CopyMailTemplateResponseFromJSON(jsonValue))
  }

  /**
   * メールテンプレートを複製する
   * copy
   */
  async userV1MailTemplatesCopyPost(
    requestParameters: UserV1MailTemplatesCopyPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CopyMailTemplateResponse> {
    const response = await this.userV1MailTemplatesCopyPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールテンプレートの一覧を取得する
   * index
   */
  async userV1MailTemplatesGetRaw(
    requestParameters: UserV1MailTemplatesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetMailTemplatesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/mail_templates`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetMailTemplatesResponseFromJSON(jsonValue))
  }

  /**
   * メールテンプレートの一覧を取得する
   * index
   */
  async userV1MailTemplatesGet(
    requestParameters: UserV1MailTemplatesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetMailTemplatesResponse> {
    const response = await this.userV1MailTemplatesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールテンプレートを削除する
   * delete
   */
  async userV1MailTemplatesMailTemplateIdDeleteRaw(
    requestParameters: UserV1MailTemplatesMailTemplateIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<DeleteMailTemplateResponse>> {
    if (requestParameters.mailTemplateId === null || requestParameters.mailTemplateId === undefined) {
      throw new runtime.RequiredError(
        'mailTemplateId',
        'Required parameter requestParameters.mailTemplateId was null or undefined when calling userV1MailTemplatesMailTemplateIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/mail_templates/{mail_template_id}`.replace(
          `{${'mail_template_id'}}`,
          encodeURIComponent(String(requestParameters.mailTemplateId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => DeleteMailTemplateResponseFromJSON(jsonValue))
  }

  /**
   * メールテンプレートを削除する
   * delete
   */
  async userV1MailTemplatesMailTemplateIdDelete(
    requestParameters: UserV1MailTemplatesMailTemplateIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<DeleteMailTemplateResponse> {
    const response = await this.userV1MailTemplatesMailTemplateIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールテンプレートの詳細を取得する
   * show
   */
  async userV1MailTemplatesMailTemplateIdGetRaw(
    requestParameters: UserV1MailTemplatesMailTemplateIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetMailTemplateResponse>> {
    if (requestParameters.mailTemplateId === null || requestParameters.mailTemplateId === undefined) {
      throw new runtime.RequiredError(
        'mailTemplateId',
        'Required parameter requestParameters.mailTemplateId was null or undefined when calling userV1MailTemplatesMailTemplateIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/mail_templates/{mail_template_id}`.replace(
          `{${'mail_template_id'}}`,
          encodeURIComponent(String(requestParameters.mailTemplateId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetMailTemplateResponseFromJSON(jsonValue))
  }

  /**
   * メールテンプレートの詳細を取得する
   * show
   */
  async userV1MailTemplatesMailTemplateIdGet(
    requestParameters: UserV1MailTemplatesMailTemplateIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetMailTemplateResponse> {
    const response = await this.userV1MailTemplatesMailTemplateIdGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールテンプレートを更新する
   * update
   */
  async userV1MailTemplatesMailTemplateIdPutRaw(
    requestParameters: UserV1MailTemplatesMailTemplateIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateMailTemplateResponse>> {
    if (requestParameters.mailTemplateId === null || requestParameters.mailTemplateId === undefined) {
      throw new runtime.RequiredError(
        'mailTemplateId',
        'Required parameter requestParameters.mailTemplateId was null or undefined when calling userV1MailTemplatesMailTemplateIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/mail_templates/{mail_template_id}`.replace(
          `{${'mail_template_id'}}`,
          encodeURIComponent(String(requestParameters.mailTemplateId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateMailTemplateRequestToJSON(requestParameters.updateMailTemplateRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateMailTemplateResponseFromJSON(jsonValue))
  }

  /**
   * メールテンプレートを更新する
   * update
   */
  async userV1MailTemplatesMailTemplateIdPut(
    requestParameters: UserV1MailTemplatesMailTemplateIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateMailTemplateResponse> {
    const response = await this.userV1MailTemplatesMailTemplateIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールテンプレートを作成する
   * create
   */
  async userV1MailTemplatesPostRaw(
    requestParameters: UserV1MailTemplatesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateMailTemplateResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/mail_templates`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateMailTemplateRequestToJSON(requestParameters.createMailTemplateRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateMailTemplateResponseFromJSON(jsonValue))
  }

  /**
   * メールテンプレートを作成する
   * create
   */
  async userV1MailTemplatesPost(
    requestParameters: UserV1MailTemplatesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateMailTemplateResponse> {
    const response = await this.userV1MailTemplatesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールテンプレートで使用する変数を取得する
   * variables
   */
  async userV1MailTemplatesVariablesGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<VariablesMailTemplateResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/mail_templates/variables`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => VariablesMailTemplateResponseFromJSON(jsonValue))
  }

  /**
   * メールテンプレートで使用する変数を取得する
   * variables
   */
  async userV1MailTemplatesVariablesGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<VariablesMailTemplateResponse> {
    const response = await this.userV1MailTemplatesVariablesGetRaw(initOverrides)
    return await response.value()
  }
}
