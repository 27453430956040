/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { FilterContentFromJSON, FilterContentToJSON } from './FilterContent';
export function FilterMaterialObjectFromJSON(json) {
    return FilterMaterialObjectFromJSONTyped(json, false);
}
export function FilterMaterialObjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        document: !exists(json, 'document') ? undefined : FilterContentFromJSON(json['document']),
        site: !exists(json, 'site') ? undefined : FilterContentFromJSON(json['site'])
    };
}
export function FilterMaterialObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document: FilterContentToJSON(value.document),
        site: FilterContentToJSON(value.site)
    };
}
