import { Box, ButtonBase, Slide, Typography } from '@material-ui/core'
import { useState } from 'react'
import { DynamicMuiIcon } from 'src/components/atoms'

export const HistoryContainer = ({ onClose }: { onClose?: () => void }) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  return (
    <Slide direction="left" in={open}>
      <Box width="350px" minHeight={'100%'} bgcolor="white" px="10px" py="18px">
        <Box display="flex" justifyContent="space-between">
          <Typography>変更履歴</Typography>
          <ButtonBase onClick={handleClose}>
            <DynamicMuiIcon variant="close" size="20px" color="action" />
          </ButtonBase>
        </Box>
      </Box>
    </Slide>
  )
}
