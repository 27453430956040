/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationPolicySettingFromJSON, OrganizationPolicySettingToJSON } from './OrganizationPolicySetting';
export function UpdateOrganizationPolicySettingResponseDataFromJSON(json) {
    return UpdateOrganizationPolicySettingResponseDataFromJSONTyped(json, false);
}
export function UpdateOrganizationPolicySettingResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organizationPolicySetting: !exists(json, 'organization_policy_setting')
            ? undefined
            : OrganizationPolicySettingFromJSON(json['organization_policy_setting'])
    };
}
export function UpdateOrganizationPolicySettingResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization_policy_setting: OrganizationPolicySettingToJSON(value.organizationPolicySetting)
    };
}
