import { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { Box, ButtonBase, ClickAwayListener, IconButton, InputBase } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { Button, Colors, DynamicMuiIcon, Pallete, SvgIcon, Typography } from 'src/components/atoms'
import { DocumentToolBar } from 'src/components/organisms/DocumentToolBar'
import { PageDocumentDetailPreviewPCHeader, PC_HEADER_HEIGHT } from './Header'
import { ModalDocumentPageSelect } from 'src/components/modals/ModalDocumentPageSelect'
import { FloatingWindowButton } from '../FloatingWindowButton'
import { CTADialog } from 'src/components/organisms/CTADialog'
import { Document, OrganizationPolicySetting } from '@noco/http-client/lib/noco/models'
import { Organization, Visitor } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { useDocumentSlideDetail } from '../hooks'
import { PopupPosition } from '../../DocumentSettingCta'
import { CONTROLLER_SHOW_TIMEOUT } from '../SP/contant'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { LinkImage } from '../LinkImage'
import { useGetOpenDocumentSlideHeaderTooltip } from 'src/fixtures/utils/modal'

export const PC_FOOTER_HEIGHT = 60

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      background: Colors.background.lightGray,
      overflow: 'hidden',
      position: 'relative'
    },
    content: {
      position: 'relative',
      flexGrow: 1,
      width: '100%'
    },
    preview: {
      height: '100%',
      width: '100%',
      marginBottom: PC_FOOTER_HEIGHT,

      '& .react-transform-component': {
        justifyContent: 'center'
      }
    },

    currentSlideImg: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: ({ isOverImageHeight }: { isOverImageHeight: boolean }) => (isOverImageHeight ? 'fit-content' : '100%'),
      maxHeight: '100%',
      '& > img': {
        objectFit: 'contain',
        maxHeight: '100%',
        filter: `drop-shadow(0px 0px 1px ${Colors.background.silver})`
      }
    },
    actionMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: PC_FOOTER_HEIGHT,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      zIndex: 100,
      boxShadow: 'inset 0px 1px 0px #EEF1F4',
      backgroundColor: 'white',
      flexShrink: 0
    },
    pageNumberInput: {
      color: '#A0A0A0',
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 700,
      width: 34,
      height: 20,
      border: '1px solid #DDDDDD',
      boxShadow: 'inset 0px 1px 2px rgba(10, 10, 10, 0.1)',
      borderRadius: 4,
      padding: 0
    },
    documentTitle: { lineHeight: 1, marginLeft: 8 },
    pageNumInput: {
      color: Colors.base.middleGray,
      display: 'flex',
      alignItems: 'center',
      marginRight: 4,
      marginLeft: 4,
      lineHeight: 1
    }
  })
)

const INITIAL_SCALE = 1
const TOOLBAR_SCALE = 10
const MIN_SCALE = 0.5
const MAX_SLACE = 2

export const PCPageDocumentDetailPreview = ({
  document,
  isPreview,
  organization,
  visitor,
  expiredOn,
  siteId,
  documentShareId,
  siteShareId,
  canRequestToExtendExpiredAt,
  policySettings,
  isActivated
}: {
  document: Document
  isPreview: boolean
  organization: Organization
  visitor?: Visitor
  expiredOn?: string | null
  documentShareId?: string
  siteShareId?: string
  siteId?: string
  canRequestToExtendExpiredAt?: boolean
  policySettings?: OrganizationPolicySetting[]
  isActivated: boolean
}) => {
  const [open, setOpen] = useState(false)
  const [isInitFloatingWindowButton, setIsInitFloatingWindowButton] = useState(false)
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null)
  const [scale, setScale] = useState(INITIAL_SCALE)
  const documentWrapperRef = useRef(null)
  const {
    currentSlide,
    totalPage,
    openPageSelectModal,
    isReviewRequestLoading,
    displayCta,
    shownPage,
    setShownPage,
    onOpenPageSelectModal,
    onClosePageSelectModal,
    setOpenCta,
    onPrevPage,
    onNextPage,
    onReviewRequest,
    handleCreateTrackCTA
  } = useDocumentSlideDetail({ document, isPreview, organization, siteId, canRequestToExtendExpiredAt, isActivated })

  const { data: openDocumentSlideHeaderTooltip } = useGetOpenDocumentSlideHeaderTooltip()

  const ctaPosition = useMemo(() => {
    return {
      [PopupPosition.CENTER]: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
      [PopupPosition.TOP_LEFT]: { top: '0%', left: '0%', transform: 'translate(0%, 0%)' },
      [PopupPosition.TOP_RIGHT]: { top: '0%', left: '100%', transform: 'translate(-100%, 0%)' },
      [PopupPosition.BOTTOM_LEFT]: { top: '100%', left: '0%', transform: 'translate(0%, -100%)' },
      [PopupPosition.BOTTOM_RIGHT]: { top: '100%', left: '100%', transform: 'translate(-100%, -100%)' }
    }[document.documentCta?.popupPosition || PopupPosition.CENTER]
  }, [document])
  const onResetScale = useCallback(() => {
    transformComponentRef.current?.resetTransform()
    transformComponentRef.current?.centerView(INITIAL_SCALE)
    setScale(INITIAL_SCALE)
  }, [transformComponentRef])

  const onFullSizePreview = useCallback(() => {
    transformComponentRef.current?.zoomToElement('preview-page-wrapper')
  }, [transformComponentRef])

  const onChaneScale = useCallback(
    (value: number | number[]) => {
      const isNumber = (item: number | number[]): item is number => {
        return typeof item === 'number'
      }
      if (isNumber(value)) {
        setScale(value / TOOLBAR_SCALE)
        transformComponentRef.current?.centerView(value / TOOLBAR_SCALE)
      }
    },
    [transformComponentRef, setScale]
  )

  // keyboard event 検知
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (open || openPageSelectModal || openDocumentSlideHeaderTooltip) return
      if (!isActivated) return
      if (event.key == 'ArrowLeft') {
        onPrevPage()
      } else if (event.key === 'ArrowUp') {
        onNextPage()
      } else if (event.key == 'ArrowRight') {
        onNextPage()
      } else if (event.key == 'ArrowDown') {
        onPrevPage()
      }
    },
    [open, openPageSelectModal, openDocumentSlideHeaderTooltip, isActivated, onPrevPage, onNextPage]
  )

  const [isOverImageHeight, setIsOverImageHeight] = useState(false)
  const classes = useStyles({ isOverImageHeight })
  const [showController, setShowController] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>()

  const handleSetTimeout = useCallback(() => {
    setShowController(true)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const tId = setTimeout(() => {
      setShowController(false)
    }, CONTROLLER_SHOW_TIMEOUT)
    setTimeoutId(tId)
  }, [timeoutId])

  useEffect(() => {
    window.document.addEventListener('keydown', handleKeydown)
    return () => window.document.removeEventListener('keydown', handleKeydown)
  }, [handleKeydown, totalPage])

  useEffect(() => {
    const handler = () => {
      const wrapperElement = window.document.getElementById('imageWrapper') as HTMLDivElement | undefined
      const imgElement = window.document.getElementsByClassName('image-map__content__img')?.[0] as
        | HTMLImageElement
        | undefined

      const isOver = wrapperElement && imgElement ? wrapperElement.clientHeight > imgElement.clientHeight : false
      setIsOverImageHeight(isOver)
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  return (
    <div className={classes.root}>
      {isActivated && (
        <Box
          style={{
            ...ctaPosition,
            position: 'absolute',
            pointerEvents: displayCta ? 'auto' : 'none',
            zIndex: 9999,
            opacity: displayCta ? 1 : 0,
            transition: '512ms',
            padding: '32px'
          }}
        >
          <ClickAwayListener disableReactTree onClickAway={() => setOpenCta(false)}>
            <CTADialog
              cta={document.documentCta}
              onClickCtaButton={() => handleCreateTrackCTA()}
              onClose={() => setOpenCta(false)}
            />
          </ClickAwayListener>
        </Box>
      )}

      <Box
        className={classes.content}
        height={isPreview ? `calc(100vh - ${PC_HEADER_HEIGHT}px)` : '100vh'}
        onClick={() => {
          handleSetTimeout()
        }}
      >
        <Box height="100%" display="flex" flexDirection="column">
          <PageDocumentDetailPreviewPCHeader
            document={document}
            organization={organization}
            isEuView={!!visitor}
            siteId={siteId}
          />

          <Box sx={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
            <Box
              width="100%"
              height="100%"
              position={'absolute'}
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              justifyContent="space-between"
              style={{ pointerEvents: 'none', zIndex: 99, opacity: showController ? 1.0 : 0, transition: '512ms' }}
            >
              <ButtonBase
                style={{
                  height: '100%',
                  width: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  pointerEvents: showController ? 'auto' : 'none',
                  backgroundColor: 'rgba(0,0,0,0.3)'
                }}
                onClick={() => {
                  onPrevPage()
                  handleSetTimeout()
                }}
              >
                <Box
                  width="50px"
                  height="50px"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="44px"
                >
                  <KeyboardArrowLeft style={{ color: 'white', fontSize: '44px' }} />
                </Box>
              </ButtonBase>
              <ButtonBase
                style={{
                  height: '100%',
                  width: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  pointerEvents: showController ? 'auto' : 'none',
                  backgroundColor: 'rgba(0,0,0,0.4)'
                }}
                onClick={() => {
                  onNextPage()
                  handleSetTimeout()
                }}
              >
                <Box
                  width="50px"
                  height="50px"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt="44px"
                >
                  <KeyboardArrowRight style={{ color: 'white', fontSize: '44px' }} />
                </Box>
              </ButtonBase>
            </Box>
            {canRequestToExtendExpiredAt ? (
              <Box
                className={classes.preview}
                height={1}
                display="flex"
                width={1}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                bgcolor={Pallete.functional.background.default}
              >
                <Box mb="16px">
                  <Typography fontSize="xxl" fontWeight="bold">
                    資料の公開が終了しました
                  </Typography>
                </Box>
                <Box mb="16px">
                  <Button
                    disabled={isReviewRequestLoading}
                    title="資料の閲覧をリクエストする"
                    onClick={onReviewRequest}
                  />
                </Box>
                <img style={{ width: '428px' }} src="/images/request_document.png" />
              </Box>
            ) : currentSlide ? (
              <Box {...{ ref: documentWrapperRef }} width={1} height={1} className={classes.preview}>
                <TransformWrapper
                  ref={transformComponentRef}
                  wheel={{ disabled: true }}
                  doubleClick={{ disabled: true }}
                  minScale={MIN_SCALE}
                  maxScale={MAX_SLACE}
                  initialScale={INITIAL_SCALE}
                  centerOnInit
                >
                  <TransformComponent
                    contentStyle={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'grab',
                      position: 'relative'
                    }}
                    wrapperStyle={{
                      width: '100%',
                      height: '100%'
                    }}
                  >
                    <Box
                      height="100%"
                      maxHeight={'100%'}
                      display="flex"
                      position="relative"
                      justifyContent="center"
                      alignItems="center"
                      id="imageWrapper"
                    >
                      <LinkImage
                        className={classes.currentSlideImg}
                        imgSrc={currentSlide.file?.url}
                        links={currentSlide.slideImageLinks}
                      />
                    </Box>
                  </TransformComponent>
                </TransformWrapper>
              </Box>
            ) : (
              <Box className={classes.preview}>
                <Box height={1} display="flex" alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={'50%'}
                    height={1}
                    flex={1}
                    px="46px"
                    bgcolor="#FFF7E2"
                  >
                    <img src="/images/pr-slide.png" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                  </Box>

                  <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    height={1}
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                    bgcolor={Pallete.functional.background.default}
                  >
                    <Box
                      position="absolute"
                      top={16}
                      right={16}
                      borderRadius="4px"
                      border={`2px solid ${Colors.accent.keyPurple.default}`}
                    >
                      <Typography variant="h5" style={{ padding: '0 8px', color: Colors.accent.keyPurple.default }}>
                        PR
                      </Typography>
                    </Box>

                    <img src="/images/nocosell_logo.svg" width={120} />

                    <Box mt="40px">
                      <Typography variant="h1">クラウドで顧客獲得はじめよう</Typography>
                    </Box>

                    <Box mt="40px">
                      <Typography
                        variant="body2"
                        style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
                      >{`ｎｏｃｏセールスは 資料を共有するだけで\n資料の閲覧追跡・顧客管理・コミュニケーションまで行える\nクラウド顧客獲得ツールです`}</Typography>
                    </Box>

                    <Box mt="20px">
                      <Button
                        title="無料ではじめる"
                        onClick={() => {
                          window.open(
                            'https://noco.sale/?utm_source=noco_sales&utm_medium=service_pr&utm_campaign=last_page_pr',
                            '_blank'
                          )
                        }}
                      />
                    </Box>

                    <Box mt="60px">
                      <Typography variant="h5" style={{ textAlign: 'center', color: Colors.accent.keyPurple.default }}>
                        この資料はノコセルで作成されています
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.actionMenu}>
            <Box display="flex" flexDirection="column" position="absolute" left={16}>
              <Box display="flex" alignItems="center" mb={1}>
                <SvgIcon variant="company" color={Colors.base.middleGray} size="14px" />
                <Typography className={classes.documentTitle} variant="subtitle2">
                  {organization.name}
                </Typography>
              </Box>

              {expiredOn && (
                <Box display="flex" alignItems="center">
                  <DynamicMuiIcon variant="meeting" size="14px" color="action" />
                  <Typography className={classes.documentTitle} variant="subtitle2">
                    閲覧期限 {dateFormat(dateStringToMilliseconds(expiredOn), DateFormat.DATE_WITH_DAY)} まで
                  </Typography>
                </Box>
              )}
            </Box>

            <Box display="flex" alignItems="center" zIndex={999} bgcolor={'white'}>
              <IconButton onClick={onPrevPage}>
                <SvgIcon variant="leftArrow" color={Colors.base.middleGray} size="12px" />
              </IconButton>

              <Box className={classes.pageNumInput}>
                <InputBase
                  inputProps={{ className: classes.pageNumberInput }}
                  value={shownPage ?? ''}
                  onChange={e => {
                    const inputValue = parseInt(e.target.value, 10)
                    if (!Number.isNaN(inputValue) && inputValue <= totalPage && inputValue > 0) {
                      setShownPage(inputValue)
                    } else if (e.target.value === '') {
                      setShownPage(null)
                    }
                  }}
                />
                <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                  &nbsp;/&nbsp;{totalPage}ページ
                </Typography>
              </Box>

              <IconButton onClick={onNextPage}>
                <SvgIcon variant="rightArrow" color={Colors.base.middleGray} size="12px" />
              </IconButton>
            </Box>

            <Box position="absolute" right={8}>
              <DocumentToolBar
                scale={scale}
                onChaneScale={onChaneScale}
                onChangeDrawerStatus={onOpenPageSelectModal}
                onResetScale={onResetScale}
                onFullSizePreview={onFullSizePreview}
              />
            </Box>
          </Box>

          <Box position={'absolute'} bottom="72px" right="16px" zIndex={99}>
            <FloatingWindowButton
              isActivated={isActivated}
              policySettings={policySettings}
              document={document}
              siteId={siteId}
              organization={organization}
              visitor={visitor}
              isPreview={isPreview}
              documentShareId={documentShareId}
              siteShareId={siteShareId}
              open={open}
              setOpen={setOpen}
              init={isInitFloatingWindowButton}
              setInit={setIsInitFloatingWindowButton}
            />
          </Box>
        </Box>
      </Box>
      <ModalDocumentPageSelect
        slideImages={document?.currentSlide?.slideImages ?? []}
        open={openPageSelectModal}
        onClose={onClosePageSelectModal}
        setShownPage={setShownPage}
      />
    </div>
  )
}
