/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { BaseFilter, BaseFilterFromJSON, BaseFilterFromJSONTyped, BaseFilterToJSON } from './BaseFilter'
import {
  CompanyFilterAllOf,
  CompanyFilterAllOfFromJSON,
  CompanyFilterAllOfFromJSONTyped,
  CompanyFilterAllOfToJSON
} from './CompanyFilterAllOf'
import {
  FilterBasicResourceList,
  FilterBasicResourceListFromJSON,
  FilterBasicResourceListFromJSONTyped,
  FilterBasicResourceListToJSON
} from './FilterBasicResourceList'
import { FilterCounts, FilterCountsFromJSON, FilterCountsFromJSONTyped, FilterCountsToJSON } from './FilterCounts'
import { FilterStaff, FilterStaffFromJSON, FilterStaffFromJSONTyped, FilterStaffToJSON } from './FilterStaff'

/**
 *
 * @export
 * @interface CompanyFilter
 */
export interface CompanyFilter {
  /**
   *
   * @type {FilterCounts}
   * @memberof CompanyFilter
   */
  normal?: FilterCounts
  /**
   *
   * @type {FilterCounts}
   * @memberof CompanyFilter
   */
  archived?: FilterCounts
  /**
   *
   * @type {FilterCounts}
   * @memberof CompanyFilter
   */
  duplicated?: FilterCounts
  /**
   *
   * @type {FilterStaff}
   * @memberof CompanyFilter
   */
  staffList?: FilterStaff
  /**
   *
   * @type {FilterBasicResourceList}
   * @memberof CompanyFilter
   */
  companyList?: FilterBasicResourceList
}

export function CompanyFilterFromJSON(json: any): CompanyFilter {
  return CompanyFilterFromJSONTyped(json, false)
}

export function CompanyFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFilter {
  if (json === undefined || json === null) {
    return json
  }
  return {
    normal: !exists(json, 'normal') ? undefined : FilterCountsFromJSON(json['normal']),
    archived: !exists(json, 'archived') ? undefined : FilterCountsFromJSON(json['archived']),
    duplicated: !exists(json, 'duplicated') ? undefined : FilterCountsFromJSON(json['duplicated']),
    staffList: !exists(json, 'staff_list') ? undefined : FilterStaffFromJSON(json['staff_list']),
    companyList: !exists(json, 'company_list') ? undefined : FilterBasicResourceListFromJSON(json['company_list'])
  }
}

export function CompanyFilterToJSON(value?: CompanyFilter | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    normal: FilterCountsToJSON(value.normal),
    archived: FilterCountsToJSON(value.archived),
    duplicated: FilterCountsToJSON(value.duplicated),
    staff_list: FilterStaffToJSON(value.staffList),
    company_list: FilterBasicResourceListToJSON(value.companyList)
  }
}
