/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateDefaultCoverPhotoSiteRequest
 */
export interface UpdateDefaultCoverPhotoSiteRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateDefaultCoverPhotoSiteRequest
   */
  defaultImageId?: string
}

export function UpdateDefaultCoverPhotoSiteRequestFromJSON(json: any): UpdateDefaultCoverPhotoSiteRequest {
  return UpdateDefaultCoverPhotoSiteRequestFromJSONTyped(json, false)
}

export function UpdateDefaultCoverPhotoSiteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDefaultCoverPhotoSiteRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    defaultImageId: !exists(json, 'default_image_id') ? undefined : json['default_image_id']
  }
}

export function UpdateDefaultCoverPhotoSiteRequestToJSON(value?: UpdateDefaultCoverPhotoSiteRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    default_image_id: value.defaultImageId
  }
}
