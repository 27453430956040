/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetInboxesResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1InboxApi extends runtime.BaseAPI {
    /**
     * 受信リストアイテム一覧を取得する
     * index
     */
    async userV1InboxItemsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }
        if (requestParameters.userIds) {
            queryParameters['user_ids[]'] = requestParameters.userIds;
        }
        if (requestParameters.documentId !== undefined) {
            queryParameters['document_id'] = requestParameters.documentId;
        }
        if (requestParameters.documentIds) {
            queryParameters['document_ids[]'] = requestParameters.documentIds;
        }
        if (requestParameters.siteId !== undefined) {
            queryParameters['site_id'] = requestParameters.siteId;
        }
        if (requestParameters.siteIds) {
            queryParameters['site_ids[]'] = requestParameters.siteIds;
        }
        if (requestParameters.updatedOnFrom !== undefined) {
            queryParameters['updated_on_from'] = requestParameters.updatedOnFrom;
        }
        if (requestParameters.updatedOnTo !== undefined) {
            queryParameters['updated_on_to'] = requestParameters.updatedOnTo;
        }
        if (requestParameters.activityStatus !== undefined) {
            queryParameters['activity_status'] = requestParameters.activityStatus;
        }
        if (requestParameters.activityStatuses) {
            queryParameters['activity_statuses[]'] = requestParameters.activityStatuses;
        }
        if (requestParameters.supportStatus !== undefined) {
            queryParameters['support_status'] = requestParameters.supportStatus;
        }
        if (requestParameters.supportStatuses) {
            queryParameters['support_statuses[]'] = requestParameters.supportStatuses;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/inbox_items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetInboxesResponseFromJSON(jsonValue));
    }
    /**
     * 受信リストアイテム一覧を取得する
     * index
     */
    async userV1InboxItemsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1InboxItemsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
