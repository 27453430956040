/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ForgotPasswordMailRequest
 */
export interface ForgotPasswordMailRequest {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordMailRequest
   */
  organizationSubdomain?: string
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordMailRequest
   */
  encryptedEmail?: string
}

export function ForgotPasswordMailRequestFromJSON(json: any): ForgotPasswordMailRequest {
  return ForgotPasswordMailRequestFromJSONTyped(json, false)
}

export function ForgotPasswordMailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ForgotPasswordMailRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationSubdomain: !exists(json, 'organization_subdomain') ? undefined : json['organization_subdomain'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email']
  }
}

export function ForgotPasswordMailRequestToJSON(value?: ForgotPasswordMailRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_subdomain: value.organizationSubdomain,
    encrypted_email: value.encryptedEmail
  }
}
