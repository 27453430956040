/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateMeProfileRequestUserProfileFromJSON(json) {
    return UpdateMeProfileRequestUserProfileFromJSONTyped(json, false);
}
export function UpdateMeProfileRequestUserProfileFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        department: !exists(json, 'department') ? undefined : json['department'],
        position: !exists(json, 'position') ? undefined : json['position'],
        birthplace: !exists(json, 'birthplace') ? undefined : json['birthplace'],
        hobby: !exists(json, 'hobby') ? undefined : json['hobby'],
        selfIntroduction: !exists(json, 'self_introduction') ? undefined : json['self_introduction'],
        socialLinkYoutube: !exists(json, 'social_link_youtube') ? undefined : json['social_link_youtube'],
        socialLinkFacebook: !exists(json, 'social_link_facebook') ? undefined : json['social_link_facebook'],
        socialLinkTwitter: !exists(json, 'social_link_twitter') ? undefined : json['social_link_twitter'],
        socialLinkNote: !exists(json, 'social_link_note') ? undefined : json['social_link_note'],
        socialLinkWebSite: !exists(json, 'social_link_web_site') ? undefined : json['social_link_web_site']
    };
}
export function UpdateMeProfileRequestUserProfileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        department: value.department,
        position: value.position,
        birthplace: value.birthplace,
        hobby: value.hobby,
        self_introduction: value.selfIntroduction,
        social_link_youtube: value.socialLinkYoutube,
        social_link_facebook: value.socialLinkFacebook,
        social_link_twitter: value.socialLinkTwitter,
        social_link_note: value.socialLinkNote,
        social_link_web_site: value.socialLinkWebSite
    };
}
