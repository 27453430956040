/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateContactChatMessageRequestAttachableObjectsInnerFromJSON, CreateContactChatMessageRequestAttachableObjectsInnerToJSON } from './CreateContactChatMessageRequestAttachableObjectsInner';
import { UploadingFileFromJSON, UploadingFileToJSON } from './UploadingFile';
export function CreateContactChatMessageRequestFromJSON(json) {
    return CreateContactChatMessageRequestFromJSONTyped(json, false);
}
export function CreateContactChatMessageRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        message: !exists(json, 'message') ? undefined : json['message'],
        attachableObjects: !exists(json, 'attachable_objects')
            ? undefined
            : json['attachable_objects'].map(CreateContactChatMessageRequestAttachableObjectsInnerFromJSON),
        files: !exists(json, 'files') ? undefined : json['files'].map(UploadingFileFromJSON)
    };
}
export function CreateContactChatMessageRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        message: value.message,
        attachable_objects: value.attachableObjects === undefined
            ? undefined
            : value.attachableObjects.map(CreateContactChatMessageRequestAttachableObjectsInnerToJSON),
        files: value.files === undefined ? undefined : value.files.map(UploadingFileToJSON)
    };
}
