import { Box, BoxProps } from '@material-ui/core'
import React, { ReactNode, VFC } from 'react'
import { Colors } from 'src/components/atoms'

export interface LayoutCardProps {
  direction?: 'ltr' | 'rtl'
  innerCardProps?: BoxProps
  innerCardComponent: ReactNode
  outerCardComponent: ReactNode
  maxWidth?: number
  maxHeight?: number
  justify?: string
  isSP?: boolean
  isReversedSpDirection?: boolean
  hideLogo?: boolean
}

export const LayoutCard: VFC<LayoutCardProps> = ({
  direction = 'ltr',
  innerCardProps,
  innerCardComponent,
  outerCardComponent,
  maxWidth,
  maxHeight,
  justify,
  isSP = false,
  isReversedSpDirection = false,
  hideLogo = false
}) => {
  return (
    <Box
      bgcolor={Colors.background.lightGray}
      width={1}
      height={'auto'}
      minHeight={!isSP && '100vh'}
      display="flex"
      alignItems="center"
      justifyContent={justify}
      flexDirection={
        isSP ? (isReversedSpDirection ? 'column-reverse' : 'column') : direction === 'ltr' ? 'row' : 'row-reverse'
      }
      p={isSP ? '16px 20px' : '40px'}
    >
      {!hideLogo && (
        <Box
          position="absolute"
          top={isSP ? '16px' : '40px'}
          left={direction === 'ltr' ? (isSP ? '20px' : '40px') : 'auto'}
          right={direction === 'rtl' ? (isSP ? '20px' : '40px') : 'auto'}
        >
          <img src="/images/nocosell_logo.svg" alt="nocosell" width="124px" height="48px" />
        </Box>
      )}

      <Box
        width={1}
        height={isSP ? 1 : 'auto'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        p={isSP ? '64px 0 32px' : ''}
      >
        {outerCardComponent}
      </Box>
      <Box
        width={1}
        height={isSP ? 1 : 'auto'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        <Box
          bgcolor={Colors.functional.background.default}
          borderRadius="10px"
          width={1}
          height={isSP ? 1 : 'auto'}
          p={isSP ? '25px' : '40px'}
          border="1px solid #E9ECF1"
          boxShadow={'0px 0px 1px 0px #0000000A,0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A'}
          {...innerCardProps}
        >
          {innerCardComponent}
        </Box>
      </Box>
    </Box>
  )
}
