import { Box } from '@material-ui/core'
import { OrganizationPaymentCardInfoBrandEnum } from '@noco/http-client/lib/noco'
import React, { useMemo } from 'react'
import { CreditCardBrandIcon, Typography } from 'src/components/atoms'

export interface CreditCardProps {
  brand?: OrganizationPaymentCardInfoBrandEnum
  last4?: string
  expYear?: number
  expMonth?: number
}

export const CreditCardDetail = ({ brand, last4, expYear, expMonth }: CreditCardProps) => {
  const cardExpire = useMemo(() => {
    if (expYear == null || expMonth == null) return

    const convExpMonth = ('0' + String(expMonth)).slice(-2)
    const convExpYear = String(expYear).slice(-2)
    return `${convExpMonth}/${convExpYear}`
  }, [expYear, expMonth])

  return (
    <Box display="flex" alignItems="center" border="1px solid #DADCE0" bgcolor="#fff" padding="30px 16px">
      {brand && (
        <Box mr="32px">
          <CreditCardBrandIcon variant={brand} />
        </Box>
      )}
      {last4 && (
        <Box display="flex" alignItems="center" mr="24px">
          <Box mr="8px">
            <Typography fontSize="s" fontWeight="bold">
              カード番号
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="s">************{last4}</Typography>
          </Box>
        </Box>
      )}
      {cardExpire && (
        <Box display="flex" alignItems="center">
          <Box mr="8px">
            <Typography fontSize="s" fontWeight="bold">
              有効期限
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="s">{cardExpire}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
