/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  VisitorMaterialableTrack,
  VisitorMaterialableTrackFromJSON,
  VisitorMaterialableTrackFromJSONTyped,
  VisitorMaterialableTrackToJSON
} from './VisitorMaterialableTrack'

/**
 *
 * @export
 * @interface CreateMaterialableTrackResponseData
 */
export interface CreateMaterialableTrackResponseData {
  /**
   *
   * @type {VisitorMaterialableTrack}
   * @memberof CreateMaterialableTrackResponseData
   */
  materialableTrack?: VisitorMaterialableTrack
}

export function CreateMaterialableTrackResponseDataFromJSON(json: any): CreateMaterialableTrackResponseData {
  return CreateMaterialableTrackResponseDataFromJSONTyped(json, false)
}

export function CreateMaterialableTrackResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateMaterialableTrackResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    materialableTrack: !exists(json, 'materialable_track')
      ? undefined
      : VisitorMaterialableTrackFromJSON(json['materialable_track'])
  }
}

export function CreateMaterialableTrackResponseDataToJSON(value?: CreateMaterialableTrackResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    materialable_track: VisitorMaterialableTrackToJSON(value.materialableTrack)
  }
}
