/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumUserOperationKind,
  EnumUserOperationKindFromJSON,
  EnumUserOperationKindFromJSONTyped,
  EnumUserOperationKindToJSON
} from './EnumUserOperationKind'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'
import {
  UserOperationDisplayObject,
  UserOperationDisplayObjectFromJSON,
  UserOperationDisplayObjectFromJSONTyped,
  UserOperationDisplayObjectToJSON
} from './UserOperationDisplayObject'

/**
 *
 * @export
 * @interface UserOperation
 */
export interface UserOperation {
  /**
   *
   * @type {string}
   * @memberof UserOperation
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UserOperation
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof UserOperation
   */
  operableType: string
  /**
   *
   * @type {string}
   * @memberof UserOperation
   */
  operableId: string
  /**
   *
   * @type {EnumUserOperationKind}
   * @memberof UserOperation
   */
  kind: EnumUserOperationKind
  /**
   *
   * @type {string}
   * @memberof UserOperation
   */
  label: string
  /**
   *
   * @type {object}
   * @memberof UserOperation
   */
  description?: object | null
  /**
   *
   * @type {string}
   * @memberof UserOperation
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof UserOperation
   */
  updatedAt: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof UserOperation
   */
  user: UserForDisplay
  /**
   *
   * @type {UserOperationDisplayObject}
   * @memberof UserOperation
   */
  displayObject: UserOperationDisplayObject
}

export function UserOperationFromJSON(json: any): UserOperation {
  return UserOperationFromJSONTyped(json, false)
}

export function UserOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOperation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    userId: json['user_id'],
    operableType: json['operable_type'],
    operableId: json['operable_id'],
    kind: EnumUserOperationKindFromJSON(json['kind']),
    label: json['label'],
    description: !exists(json, 'description') ? undefined : json['description'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    user: UserForDisplayFromJSON(json['user']),
    displayObject: UserOperationDisplayObjectFromJSON(json['display_object'])
  }
}

export function UserOperationToJSON(value?: UserOperation | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    user_id: value.userId,
    operable_type: value.operableType,
    operable_id: value.operableId,
    kind: EnumUserOperationKindToJSON(value.kind),
    label: value.label,
    description: value.description,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    user: UserForDisplayToJSON(value.user),
    display_object: UserOperationDisplayObjectToJSON(value.displayObject)
  }
}
