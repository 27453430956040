import { pickBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
export * from './auth'
export * from './cookie'
export * from './term'

export const deleteUndefined = (data: object) => {
  return pickBy(data, v => v !== undefined)
}
export const wait = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(ms)
    }, ms)
  })
}

export function deleteNoValue<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as unknown as T
}

// 選択 id を取得する id map
type SelectIdMap = Map<string, boolean>
export const useSelectedIdMap = () => {
  const [selectMap, setSelectMap] = useState<SelectIdMap>(new Map())
  const [selectIdList, setSelectIdList] = useState<string[]>([])
  const [selectedCount, setSelectedCount] = useState(0)

  const handleSelectId = useCallback(
    (id: string) => {
      const newMap = new Map(selectMap)
      const value = newMap.get(id)
      newMap.set(id, !value)
      setSelectMap(newMap)
    },
    [selectMap]
  )
  useEffect(() => {
    const userIds: string[] = []
    const count = Array.from(selectMap).filter(([key, value]) => {
      if (value) userIds.push(key)
      return !!value
    }).length
    setSelectedCount(count)
    setSelectIdList(userIds)
  }, [selectMap])

  return { selectMap, selectIdList, selectedCount, handleSelectId }
}
