/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateChatMessageRequest1
 */
export interface UpdateChatMessageRequest1 {
  /**
   *
   * @type {string}
   * @memberof UpdateChatMessageRequest1
   */
  message?: string
}

export function UpdateChatMessageRequest1FromJSON(json: any): UpdateChatMessageRequest1 {
  return UpdateChatMessageRequest1FromJSONTyped(json, false)
}

export function UpdateChatMessageRequest1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateChatMessageRequest1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    message: !exists(json, 'message') ? undefined : json['message']
  }
}

export function UpdateChatMessageRequest1ToJSON(value?: UpdateChatMessageRequest1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    message: value.message
  }
}
