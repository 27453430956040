/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumEmailTransactionableSendStatus,
  EnumEmailTransactionableSendStatusFromJSON,
  EnumEmailTransactionableSendStatusFromJSONTyped,
  EnumEmailTransactionableSendStatusToJSON
} from './EnumEmailTransactionableSendStatus'

/**
 *
 * @export
 * @interface ChatMessageContactEmailTransaction
 */
export interface ChatMessageContactEmailTransaction {
  /**
   *
   * @type {string}
   * @memberof ChatMessageContactEmailTransaction
   */
  id?: string
  /**
   *
   * @type {EnumEmailTransactionableSendStatus}
   * @memberof ChatMessageContactEmailTransaction
   */
  sendStatus?: EnumEmailTransactionableSendStatus | null
}

export function ChatMessageContactEmailTransactionFromJSON(json: any): ChatMessageContactEmailTransaction {
  return ChatMessageContactEmailTransactionFromJSONTyped(json, false)
}

export function ChatMessageContactEmailTransactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChatMessageContactEmailTransaction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    sendStatus: !exists(json, 'send_status')
      ? undefined
      : EnumEmailTransactionableSendStatusFromJSON(json['send_status'])
  }
}

export function ChatMessageContactEmailTransactionToJSON(value?: ChatMessageContactEmailTransaction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    send_status: EnumEmailTransactionableSendStatusToJSON(value.sendStatus)
  }
}
