/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumOrganizationPolicySettingName,
  EnumOrganizationPolicySettingNameFromJSON,
  EnumOrganizationPolicySettingNameFromJSONTyped,
  EnumOrganizationPolicySettingNameToJSON
} from './EnumOrganizationPolicySettingName'

/**
 *
 * @export
 * @interface OrganizationPolicySetting
 */
export interface OrganizationPolicySetting {
  /**
   *
   * @type {string}
   * @memberof OrganizationPolicySetting
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationPolicySetting
   */
  label?: string
  /**
   *
   * @type {EnumOrganizationPolicySettingName}
   * @memberof OrganizationPolicySetting
   */
  name?: EnumOrganizationPolicySettingName
  /**
   *
   * @type {string}
   * @memberof OrganizationPolicySetting
   */
  url?: string | null
  /**
   *
   * @type {string}
   * @memberof OrganizationPolicySetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationPolicySetting
   */
  updatedAt?: string
}

export function OrganizationPolicySettingFromJSON(json: any): OrganizationPolicySetting {
  return OrganizationPolicySettingFromJSONTyped(json, false)
}

export function OrganizationPolicySettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPolicySetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    label: !exists(json, 'label') ? undefined : json['label'],
    name: !exists(json, 'name') ? undefined : EnumOrganizationPolicySettingNameFromJSON(json['name']),
    url: !exists(json, 'url') ? undefined : json['url'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function OrganizationPolicySettingToJSON(value?: OrganizationPolicySetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    label: value.label,
    name: EnumOrganizationPolicySettingNameToJSON(value.name),
    url: value.url,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
