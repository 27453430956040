/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'
import { UserProfile, UserProfileFromJSON, UserProfileFromJSONTyped, UserProfileToJSON } from './UserProfile'

/**
 *
 * @export
 * @interface GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
 */
export interface GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner {
  /**
   *
   * @type {string}
   * @memberof GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
   */
  opportunityToolUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
   */
  userName?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
   */
  userAvatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {string}
   * @memberof GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
   */
  organizationName?: string
  /**
   *
   * @type {UserProfile}
   * @memberof GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
   */
  userProfile?: UserProfile
}

export function GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSON(
  json: any
): GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner {
  return GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSONTyped(json, false)
}

export function GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    opportunityToolUrl: !exists(json, 'opportunity_tool_url') ? undefined : json['opportunity_tool_url'],
    userName: !exists(json, 'user_name') ? undefined : json['user_name'],
    userAvatar: !exists(json, 'user_avatar')
      ? undefined
      : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['user_avatar']),
    organizationName: !exists(json, 'organization_name') ? undefined : json['organization_name'],
    userProfile: !exists(json, 'user_profile') ? undefined : UserProfileFromJSON(json['user_profile'])
  }
}

export function GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerToJSON(
  value?: GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    opportunity_tool_url: value.opportunityToolUrl,
    user_name: value.userName,
    user_avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.userAvatar),
    organization_name: value.organizationName,
    user_profile: UserProfileToJSON(value.userProfile)
  }
}
