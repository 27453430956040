import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Card, Typography } from 'src/components/atoms'
import { ContentRow } from './ContentRow'

export interface ContractCardProps {
  planText: string
  startAtText: string
  endAtText: string
}
export const ContractCard = ({ planText, startAtText, endAtText }: ContractCardProps) => {
  // eslint-disable-next-line no-irregular-whitespace
  const termText = useMemo(() => `1か月間　|　${startAtText} 〜 ${endAtText}`, [endAtText, startAtText])
  return (
    <Card sx={{ pt: '30px' }}>
      <Typography fontSize="s" fontWeight="bold">
        ご契約内容
      </Typography>
      <Box height="22px" />

      <ContentRow label="ご利用中のプラン" value={planText} />
      <ContentRow label="ご契約期間" value={termText} />
      <ContentRow label="ご契約終了日" value={`${endAtText}`} />
    </Card>
  )
}
