/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateContactChatMessageRequestAttachableObjectsInner,
  CreateContactChatMessageRequestAttachableObjectsInnerFromJSON,
  CreateContactChatMessageRequestAttachableObjectsInnerFromJSONTyped,
  CreateContactChatMessageRequestAttachableObjectsInnerToJSON
} from './CreateContactChatMessageRequestAttachableObjectsInner'
import { UploadingFile, UploadingFileFromJSON, UploadingFileFromJSONTyped, UploadingFileToJSON } from './UploadingFile'

/**
 *
 * @export
 * @interface CreateContactChatMessageRequest
 */
export interface CreateContactChatMessageRequest {
  /**
   *
   * @type {string}
   * @memberof CreateContactChatMessageRequest
   */
  message?: string
  /**
   *
   * @type {Array<CreateContactChatMessageRequestAttachableObjectsInner>}
   * @memberof CreateContactChatMessageRequest
   */
  attachableObjects?: Array<CreateContactChatMessageRequestAttachableObjectsInner>
  /**
   *
   * @type {Array<UploadingFile>}
   * @memberof CreateContactChatMessageRequest
   */
  files?: Array<UploadingFile>
}

export function CreateContactChatMessageRequestFromJSON(json: any): CreateContactChatMessageRequest {
  return CreateContactChatMessageRequestFromJSONTyped(json, false)
}

export function CreateContactChatMessageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactChatMessageRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    message: !exists(json, 'message') ? undefined : json['message'],
    attachableObjects: !exists(json, 'attachable_objects')
      ? undefined
      : (json['attachable_objects'] as Array<any>).map(CreateContactChatMessageRequestAttachableObjectsInnerFromJSON),
    files: !exists(json, 'files') ? undefined : (json['files'] as Array<any>).map(UploadingFileFromJSON)
  }
}

export function CreateContactChatMessageRequestToJSON(value?: CreateContactChatMessageRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    message: value.message,
    attachable_objects:
      value.attachableObjects === undefined
        ? undefined
        : (value.attachableObjects as Array<any>).map(CreateContactChatMessageRequestAttachableObjectsInnerToJSON),
    files: value.files === undefined ? undefined : (value.files as Array<any>).map(UploadingFileToJSON)
  }
}
