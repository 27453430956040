/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  UpdateDocumentContactRequest,
  UpdateDocumentContactRequestFromJSON,
  UpdateDocumentContactRequestToJSON,
  UpdateDocumentContactResponse,
  UpdateDocumentContactResponseFromJSON,
  UpdateDocumentContactResponseToJSON
} from '../models'

export interface UserV1DocumentContactsDocumentContactIdPutRequest {
  documentContactId: string
  updateDocumentContactRequest?: UpdateDocumentContactRequest
}

/**
 *
 */
export class ApiUserV1DocumentContactApi extends runtime.BaseAPI {
  /**
   * 資料連絡先を更新する
   * update
   */
  async userV1DocumentContactsDocumentContactIdPutRaw(
    requestParameters: UserV1DocumentContactsDocumentContactIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentContactResponse>> {
    if (requestParameters.documentContactId === null || requestParameters.documentContactId === undefined) {
      throw new runtime.RequiredError(
        'documentContactId',
        'Required parameter requestParameters.documentContactId was null or undefined when calling userV1DocumentContactsDocumentContactIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/document_contacts/{document_contact_id}`.replace(
          `{${'document_contact_id'}}`,
          encodeURIComponent(String(requestParameters.documentContactId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentContactRequestToJSON(requestParameters.updateDocumentContactRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentContactResponseFromJSON(jsonValue))
  }

  /**
   * 資料連絡先を更新する
   * update
   */
  async userV1DocumentContactsDocumentContactIdPut(
    requestParameters: UserV1DocumentContactsDocumentContactIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentContactResponse> {
    const response = await this.userV1DocumentContactsDocumentContactIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
