import { Box, ButtonBase } from '@material-ui/core'
import React, { useMemo, useState, useCallback } from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { OgpSetting, SettingToggleSectionCard, SettingToggleSectionCardProps } from '..'
import { ModalAlertShare, ModalAlertShareProps, ModalUpgrade } from 'src/components/modals'
import {
  useGetSiteMiscSetting,
  useGetSite,
  useUpdateSite,
  useUpdateSiteMiscSetting,
  useUpdateSiteMiscSettingOgpFile
} from '../../../fixtures/modules/site/hooks'
import { useGetOrganizationPaymentCurrentPlan } from '../../../fixtures/modules/organizationPayment/hooks'
import { UpdateSiteMiscSettingRequest, UpdateSiteRequest } from '@noco/http-client/lib/noco'

export interface MultiDocumentsSettingProps {
  onClose: () => void
  siteId: string
}

export const MultiDocumentsSetting = ({ onClose, siteId }: MultiDocumentsSettingProps) => {
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const { data: site } = useGetSite(siteId)
  const { data: siteMiscSetting } = useGetSiteMiscSetting(siteId)
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const { handleUpdateSite } = useUpdateSite(siteId)
  const { handleUpdateSiteMiscSetting } = useUpdateSiteMiscSetting(siteId)
  const { handleUpdateSiteMiscSettingOgpFile } = useUpdateSiteMiscSettingOgpFile(siteId)

  const alertShareData: Omit<ModalAlertShareProps, 'open' | 'onClose'> = useMemo(
    () => ({
      title: '設定を有効にできません',
      description: `資料サイト「${site?.site?.title}」の「リンクの共有」がOFFであるため、設定を有効にできません`,
      infoList: [
        {
          question: '設定を有効にするには？',
          answerList: [
            {
              label: '資料サイトの「リンクの共有」をONにする必要があります'
            }
          ]
        }
      ],
      image: '/images/alert-share/site-restricted-to-open.svg'
    }),
    [site?.site?.title]
  )

  const handleUpgradeOpen = useCallback(() => {
    setUpgradeOpen(true)
  }, [])

  const handleUpgradeClose = useCallback(() => {
    setUpgradeOpen(false)
  }, [])

  const handleChangeIsPublicSearchEngine = useCallback(() => {
    if (!siteMiscSetting?.isPublicSearchEngine && site?.site?.publicationStatus !== 'open') {
      setOpenAlert(true)
    } else {
      handleUpdateSiteMiscSetting({
        siteMiscSetting: {
          ...siteMiscSetting,
          isPublicSearchEngine: !(siteMiscSetting?.isPublicSearchEngine ?? false)
        }
      } as UpdateSiteMiscSettingRequest)
    }
  }, [handleUpdateSiteMiscSetting, siteMiscSetting, site?.site?.publicationStatus])

  const handleChangeIsHeaderCustom = useCallback(() => {
    if (!planGrade?.plan.canHideAd) return handleUpgradeOpen()
    if (site?.site == null) return

    handleUpdateSite({
      ...site.site,
      isHeaderCustom: !site.site.isHeaderCustom
    } as UpdateSiteRequest)
  }, [handleUpdateSite, handleUpgradeOpen, planGrade, site])

  const handleChangeIsFooterCustom = useCallback(() => {
    if (!planGrade?.plan.canHideAd) return handleUpgradeOpen()
    if (site?.site == null) return

    handleUpdateSite({
      ...site.site,
      isFooterCustom: !site.site.isFooterCustom
    } as UpdateSiteRequest)
  }, [handleUpdateSite, handleUpgradeOpen, planGrade, site])

  const handleChangeFooterText = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!planGrade?.plan.canHideAd) return handleUpgradeOpen()
      if (site?.site == null) return

      handleUpdateSite({
        ...site.site,
        footerText: event.target.value
      } as UpdateSiteRequest)
    },
    [handleUpdateSite, handleUpgradeOpen, planGrade, site]
  )

  const handleOgpSettingChange = useCallback(
    (field: string, value: string) => {
      if (siteMiscSetting == null) return

      handleUpdateSiteMiscSetting({
        siteMiscSetting: { ...siteMiscSetting, [field]: value }
      } as UpdateSiteMiscSettingRequest)
    },
    [handleUpdateSiteMiscSetting, siteMiscSetting]
  )

  const handleOgpImageChange = useCallback(
    (file?: File) => {
      handleUpdateSiteMiscSettingOgpFile(file)
    },
    [handleUpdateSiteMiscSettingOgpFile]
  )

  const optionList: SettingToggleSectionCardProps[] = useMemo(
    () => [
      {
        title: 'ヘッダーロゴのカスタマイズ',
        description: 'チーム設定で設定されたチームのロゴを表示します',
        checked: !!site?.site?.isHeaderCustom,
        onChange: handleChangeIsHeaderCustom
      },
      {
        title: 'フッターのカスタマイズ',
        description: '資料サイトのフッターにお好きなテキストを設定できます',
        checked: !!site?.site?.isFooterCustom,
        onChange: handleChangeIsFooterCustom,
        inputTitle: 'フッターテキスト',
        text: site?.site?.footerText!,
        placeholder: 'Powered by noco',
        onChangeText: handleChangeFooterText
      }
    ],
    [handleChangeIsFooterCustom, handleChangeIsHeaderCustom, handleChangeFooterText, site]
  )

  return (
    <>
      <Box mb="36px">
        <Box display="flex" justifyContent="space-between" alignItems="center" color={Colors.base.middleGray} mb="8px">
          <Box display="flex" alignItems="center">
            <DynamicMuiIcon variant="setting" size="20px" />
            <Box pl="8px" color={Colors.base.black}>
              <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
                設定
              </Typography>
            </Box>
          </Box>
          <ButtonBase onClick={onClose}>
            <DynamicMuiIcon variant="close" size="20px" />
          </ButtonBase>
        </Box>
        <Box mb="16px">
          <SettingToggleSectionCard
            title="検索エンジンへの公開"
            description="資料がGoogle等の検索エンジンの登録対象になります"
            checked={!!siteMiscSetting?.isPublicSearchEngine}
            onChange={handleChangeIsPublicSearchEngine}
          />
        </Box>

        <OgpSetting
          title={siteMiscSetting?.ogpTitle ?? undefined}
          description={siteMiscSetting?.ogpDescription ?? undefined}
          imageUrl={siteMiscSetting?.ogpFile?.url ?? undefined}
          onChange={handleOgpSettingChange}
          onFileChange={handleOgpImageChange}
        />
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" color={Colors.base.middleGray} mb="8px">
          <Box pl="8px" color={Colors.base.black}>
            <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
              有料プラン
            </Typography>
          </Box>
          <ButtonBase
            onClick={() => {
              window.open('/setting/team/pricing', '_blank')
            }}
          >
            <Box display="flex" alignItems="center" color={Colors.accent.keyBlue.default}>
              <DynamicMuiIcon variant="openNew" size="20px" />
              <Box ml="6px" />
              <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
                プランを見る
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
        {optionList.map((option, i) => (
          <Box key={i} mb="16px">
            <SettingToggleSectionCard {...option} />
          </Box>
        ))}
      </Box>
      <ModalUpgrade open={upgradeOpen} onClose={handleUpgradeClose} />
      <ModalAlertShare open={openAlert} onClose={() => setOpenAlert(false)} {...alertShareData} />
    </>
  )
}
