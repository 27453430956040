import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const AdsClick = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.70673 10.8968C4.58673 10.7968 2.90007 9.05015 2.90007 6.90348C2.90007 4.69682 4.6934 2.90348 6.90007 2.90348C9.04673 2.90348 10.7934 4.59015 10.8934 6.71015L9.4934 6.29015C9.22007 5.11015 8.16007 4.23682 6.90007 4.23682C5.42673 4.23682 4.2334 5.43015 4.2334 6.90348C4.2334 8.16348 5.10673 9.22348 6.28673 9.49682L6.70673 10.8968ZM13.5667 6.90348C13.5667 7.10348 13.5601 7.30348 13.5401 7.50348L12.2267 7.11015C12.2334 7.04348 12.2334 6.97015 12.2334 6.90348C12.2334 3.95682 9.84673 1.57015 6.90007 1.57015C3.9534 1.57015 1.56673 3.95682 1.56673 6.90348C1.56673 9.85015 3.9534 12.2368 6.90007 12.2368C6.96673 12.2368 7.04007 12.2368 7.10673 12.2301L7.50006 13.5435C7.30007 13.5635 7.10007 13.5701 6.90007 13.5701C3.22007 13.5701 0.233398 10.5835 0.233398 6.90348C0.233398 3.22348 3.22007 0.236816 6.90007 0.236816C10.5801 0.236816 13.5667 3.22348 13.5667 6.90348ZM11.0534 9.74348L12.5667 9.23682C12.8734 9.13682 12.8667 8.69682 12.5601 8.60348L7.4934 7.08348C7.24007 7.01015 7.00007 7.24348 7.08007 7.49682L8.60007 12.5635C8.6934 12.8768 9.1334 12.8835 9.2334 12.5701L9.74007 11.0568L12.3467 13.6635C12.4801 13.7968 12.6867 13.7968 12.8201 13.6635L13.6667 12.8168C13.8001 12.6835 13.8001 12.4768 13.6667 12.3435L11.0534 9.74348Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default AdsClick
