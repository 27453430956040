/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserFromJSON, UserToJSON } from './User';
export function DocumentOpportunityUserFromJSON(json) {
    return DocumentOpportunityUserFromJSONTyped(json, false);
}
export function DocumentOpportunityUserFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        user: !exists(json, 'user') ? undefined : UserFromJSON(json['user'])
    };
}
export function DocumentOpportunityUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        user: UserToJSON(value.user)
    };
}
