/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateSiteSectionDocumentRequestSiteSectionDocumentFromJSON(json) {
    return UpdateSiteSectionDocumentRequestSiteSectionDocumentFromJSONTyped(json, false);
}
export function UpdateSiteSectionDocumentRequestSiteSectionDocumentFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        siteSectionId: !exists(json, 'site_section_id') ? undefined : json['site_section_id'],
        sort: !exists(json, 'sort') ? undefined : json['sort'],
        title: !exists(json, 'title') ? undefined : json['title'],
        presentationText: !exists(json, 'presentation_text') ? undefined : json['presentation_text']
    };
}
export function UpdateSiteSectionDocumentRequestSiteSectionDocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        site_section_id: value.siteSectionId,
        sort: value.sort,
        title: value.title,
        presentation_text: value.presentationText
    };
}
