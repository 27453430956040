/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CompanyForDisplay,
  CompanyForDisplayFromJSON,
  CompanyForDisplayFromJSONTyped,
  CompanyForDisplayToJSON
} from './CompanyForDisplay'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface ContactWithAssociation
 */
export interface ContactWithAssociation {
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  encryptedEmail?: string
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   * @deprecated
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  updatedAt?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof ContactWithAssociation
   */
  avatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {string}
   * @memberof ContactWithAssociation
   */
  position?: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof ContactWithAssociation
   */
  user?: UserForDisplay
  /**
   *
   * @type {CompanyForDisplay}
   * @memberof ContactWithAssociation
   */
  company?: CompanyForDisplay
}

export function ContactWithAssociationFromJSON(json: any): ContactWithAssociation {
  return ContactWithAssociationFromJSONTyped(json, false)
}

export function ContactWithAssociationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactWithAssociation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
    position: !exists(json, 'position') ? undefined : json['position'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company'])
  }
}

export function ContactWithAssociationToJSON(value?: ContactWithAssociation | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    encrypted_email: value.encryptedEmail,
    first_name: value.firstName,
    last_name: value.lastName,
    display_name: value.displayName,
    status: value.status,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
    position: value.position,
    user: UserForDisplayToJSON(value.user),
    company: CompanyForDisplayToJSON(value.company)
  }
}
