/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  DocumentChatSetting,
  DocumentChatSettingFromJSON,
  DocumentChatSettingFromJSONTyped,
  DocumentChatSettingToJSON
} from './DocumentChatSetting'
import { DocumentCta, DocumentCtaFromJSON, DocumentCtaFromJSONTyped, DocumentCtaToJSON } from './DocumentCta'
import {
  DocumentMiscSetting,
  DocumentMiscSettingFromJSON,
  DocumentMiscSettingFromJSONTyped,
  DocumentMiscSettingToJSON
} from './DocumentMiscSetting'
import {
  DocumentOpportunity,
  DocumentOpportunityFromJSON,
  DocumentOpportunityFromJSONTyped,
  DocumentOpportunityToJSON
} from './DocumentOpportunity'
import { DocumentShare, DocumentShareFromJSON, DocumentShareFromJSONTyped, DocumentShareToJSON } from './DocumentShare'
import { DocumentSlide, DocumentSlideFromJSON, DocumentSlideFromJSONTyped, DocumentSlideToJSON } from './DocumentSlide'
import {
  EnumPublicationStatus,
  EnumPublicationStatusFromJSON,
  EnumPublicationStatusFromJSONTyped,
  EnumPublicationStatusToJSON
} from './EnumPublicationStatus'
import {
  EnumSharingStatus,
  EnumSharingStatusFromJSON,
  EnumSharingStatusFromJSONTyped,
  EnumSharingStatusToJSON
} from './EnumSharingStatus'
import {
  EuDocumentMessageWithOrganizationChatSetting,
  EuDocumentMessageWithOrganizationChatSettingFromJSON,
  EuDocumentMessageWithOrganizationChatSettingFromJSONTyped,
  EuDocumentMessageWithOrganizationChatSettingToJSON
} from './EuDocumentMessageWithOrganizationChatSetting'
import { UploadedFile, UploadedFileFromJSON, UploadedFileFromJSONTyped, UploadedFileToJSON } from './UploadedFile'

/**
 *
 * @export
 * @interface EuDocument
 */
export interface EuDocument {
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  organizationId?: string
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  description?: string | null
  /**
   *
   * @type {UploadedFile}
   * @memberof EuDocument
   */
  thumbnail?: UploadedFile | null
  /**
   *
   * @type {DocumentSlide}
   * @memberof EuDocument
   */
  currentSlide?: DocumentSlide | null
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  publishedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  archivedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof EuDocument
   */
  updatedAt?: string
  /**
   *
   * @type {boolean}
   * @memberof EuDocument
   */
  isPublished?: boolean
  /**
   *
   * @type {EnumSharingStatus}
   * @memberof EuDocument
   */
  sharingStatus?: EnumSharingStatus
  /**
   *
   * @type {EnumPublicationStatus}
   * @memberof EuDocument
   */
  publicationStatus?: EnumPublicationStatus
  /**
   *
   * @type {DocumentCta}
   * @memberof EuDocument
   */
  documentCta?: DocumentCta
  /**
   *
   * @type {DocumentOpportunity}
   * @memberof EuDocument
   */
  documentOpportunity?: DocumentOpportunity
  /**
   *
   * @type {EuDocumentMessageWithOrganizationChatSetting}
   * @memberof EuDocument
   */
  documentMessage?: EuDocumentMessageWithOrganizationChatSetting
  /**
   *
   * @type {DocumentChatSetting}
   * @memberof EuDocument
   */
  documentChatSetting?: DocumentChatSetting
  /**
   *
   * @type {DocumentShare}
   * @memberof EuDocument
   */
  documentShare?: DocumentShare
  /**
   *
   * @type {DocumentMiscSetting}
   * @memberof EuDocument
   */
  documentMiscSetting?: DocumentMiscSetting
}

export function EuDocumentFromJSON(json: any): EuDocument {
  return EuDocumentFromJSONTyped(json, false)
}

export function EuDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EuDocument {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    thumbnail: !exists(json, 'thumbnail') ? undefined : UploadedFileFromJSON(json['thumbnail']),
    currentSlide: !exists(json, 'current_slide') ? undefined : DocumentSlideFromJSON(json['current_slide']),
    publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
    archivedAt: !exists(json, 'archived_at') ? undefined : json['archived_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    isPublished: !exists(json, 'is_published') ? undefined : json['is_published'],
    sharingStatus: !exists(json, 'sharing_status') ? undefined : EnumSharingStatusFromJSON(json['sharing_status']),
    publicationStatus: !exists(json, 'publication_status')
      ? undefined
      : EnumPublicationStatusFromJSON(json['publication_status']),
    documentCta: !exists(json, 'document_cta') ? undefined : DocumentCtaFromJSON(json['document_cta']),
    documentOpportunity: !exists(json, 'document_opportunity')
      ? undefined
      : DocumentOpportunityFromJSON(json['document_opportunity']),
    documentMessage: !exists(json, 'document_message')
      ? undefined
      : EuDocumentMessageWithOrganizationChatSettingFromJSON(json['document_message']),
    documentChatSetting: !exists(json, 'document_chat_setting')
      ? undefined
      : DocumentChatSettingFromJSON(json['document_chat_setting']),
    documentShare: !exists(json, 'document_share') ? undefined : DocumentShareFromJSON(json['document_share']),
    documentMiscSetting: !exists(json, 'document_misc_setting')
      ? undefined
      : DocumentMiscSettingFromJSON(json['document_misc_setting'])
  }
}

export function EuDocumentToJSON(value?: EuDocument | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    organization_id: value.organizationId,
    title: value.title,
    description: value.description,
    thumbnail: UploadedFileToJSON(value.thumbnail),
    current_slide: DocumentSlideToJSON(value.currentSlide),
    published_at: value.publishedAt,
    archived_at: value.archivedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    is_published: value.isPublished,
    sharing_status: EnumSharingStatusToJSON(value.sharingStatus),
    publication_status: EnumPublicationStatusToJSON(value.publicationStatus),
    document_cta: DocumentCtaToJSON(value.documentCta),
    document_opportunity: DocumentOpportunityToJSON(value.documentOpportunity),
    document_message: EuDocumentMessageWithOrganizationChatSettingToJSON(value.documentMessage),
    document_chat_setting: DocumentChatSettingToJSON(value.documentChatSetting),
    document_share: DocumentShareToJSON(value.documentShare),
    document_misc_setting: DocumentMiscSettingToJSON(value.documentMiscSetting)
  }
}
