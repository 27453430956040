/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateSiteSectionDocumentResponseData,
  CreateSiteSectionDocumentResponseDataFromJSON,
  CreateSiteSectionDocumentResponseDataFromJSONTyped,
  CreateSiteSectionDocumentResponseDataToJSON
} from './CreateSiteSectionDocumentResponseData'

/**
 *
 * @export
 * @interface UpdateSiteSectionDocumentResponse
 */
export interface UpdateSiteSectionDocumentResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateSiteSectionDocumentResponse
   */
  status?: number
  /**
   *
   * @type {CreateSiteSectionDocumentResponseData}
   * @memberof UpdateSiteSectionDocumentResponse
   */
  data?: CreateSiteSectionDocumentResponseData
}

export function UpdateSiteSectionDocumentResponseFromJSON(json: any): UpdateSiteSectionDocumentResponse {
  return UpdateSiteSectionDocumentResponseFromJSONTyped(json, false)
}

export function UpdateSiteSectionDocumentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSiteSectionDocumentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : CreateSiteSectionDocumentResponseDataFromJSON(json['data'])
  }
}

export function UpdateSiteSectionDocumentResponseToJSON(value?: UpdateSiteSectionDocumentResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CreateSiteSectionDocumentResponseDataToJSON(value.data)
  }
}
