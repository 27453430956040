/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface OrganizationUserEmailSetting
 */
export interface OrganizationUserEmailSetting {
  /**
   *
   * @type {string}
   * @memberof OrganizationUserEmailSetting
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationUserEmailSetting
   */
  subjectName?: string
  /**
   *
   * @type {boolean}
   * @memberof OrganizationUserEmailSetting
   */
  isDefault?: boolean
  /**
   *
   * @type {string}
   * @memberof OrganizationUserEmailSetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationUserEmailSetting
   */
  updatedAt?: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof OrganizationUserEmailSetting
   */
  user?: UserForDisplay
}

export function OrganizationUserEmailSettingFromJSON(json: any): OrganizationUserEmailSetting {
  return OrganizationUserEmailSettingFromJSONTyped(json, false)
}

export function OrganizationUserEmailSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationUserEmailSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    subjectName: !exists(json, 'subject_name') ? undefined : json['subject_name'],
    isDefault: !exists(json, 'is_default') ? undefined : json['is_default'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user'])
  }
}

export function OrganizationUserEmailSettingToJSON(value?: OrganizationUserEmailSetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    subject_name: value.subjectName,
    is_default: value.isDefault,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    user: UserForDisplayToJSON(value.user)
  }
}
