/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumMaterialContactType = {
  DocumentContact: 'document_contact',
  SiteContact: 'site_contact'
} as const
export type EnumMaterialContactType = typeof EnumMaterialContactType[keyof typeof EnumMaterialContactType]

export function EnumMaterialContactTypeFromJSON(json: any): EnumMaterialContactType {
  return EnumMaterialContactTypeFromJSONTyped(json, false)
}

export function EnumMaterialContactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumMaterialContactType {
  return json as EnumMaterialContactType
}

export function EnumMaterialContactTypeToJSON(value?: EnumMaterialContactType | null): any {
  return value as any
}
