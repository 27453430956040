import { Toaster } from 'src/components/atoms'
export const ErrorRedirect = {
  NotFound: { redirect: { destination: '/404', permanent: false } },
  InternalServerError: { redirect: { destination: '/500', permanent: false } },
  UserSignIn: { redirect: { destination: '/signin', permanent: false } },
  UserPermission: { redirect: { destination: '/permission_error', permanent: false } },
  TrialEnd: { redirect: { destination: '/trial-end', permanent: false } },
  Pricing: { redirect: { destination: '/setting/team/pricing', permanent: true } }
}
// api 通信のエラーハンドリング
export const handleError = async (
  error: unknown,
  options: {
    setError?: React.Dispatch<React.SetStateAction<Error | undefined>>
    setInstructions?: React.Dispatch<React.SetStateAction<string[] | undefined>>
    tag?: string
  },
  notify: boolean = true
) => {
  let errorMessage = `${options.tag ? `${options.tag} ` : ''}Error occurred`

  // api とは関係のない runtime error の場合
  if (error instanceof Error) {
    errorMessage += ` :${error.message}`
    options.setError?.(error)
  }
  // api の response error の場合
  if (error instanceof Response) {
    const bodyString = await error.text()
    const body = isJson(bodyString) ? JSON.parse(bodyString) : bodyString
    const code = error.status
    let message = ''
    if (typeof body === 'object') {
      message = body.errors.join('\n')
    } else {
      message = body
    }
    if (message) {
      errorMessage = message
    }
    // ページリロード時の表示
    if (code == 401) {
      errorMessage = 'ページリロードをしてください。\n同じ問題が発生し続ける場合は、ログインをしなおしてください'
    }
    options.setInstructions?.(body.instructions)
    options.setError?.(new Error(message))
  }
  if (notify) Toaster.error(errorMessage.toString())

  console.error(JSON.stringify({ errorMessage, error }))
}

export const getEUViewErrorRedirect = (err: unknown) => {
  console.error('START: EUViewSSRError ========================>')
  console.error(err)
  console.error('END  : EUViewSSRError ========================>')
  // api 通信の結果エラーが発生しているパターン
  if (err instanceof Response) {
    switch (err.status) {
      case 404:
        return ErrorRedirect.NotFound
      case 500:
        return ErrorRedirect.InternalServerError
      default:
        return ErrorRedirect.NotFound
    }
  }

  // MEMO: Response でない場合は runtime のロジックエラーなので、暫定的に500に飛ばす
  return ErrorRedirect.InternalServerError
}

export const getUserViewErrorRedirect = (err: unknown) => {
  console.error('START: UserViewSSRError ========================>')
  console.error(err)
  console.error('END  : UserViewSSRError ========================>')
  // api 通信の結果エラーが発生しているパターン
  if (err instanceof Response) {
    switch (err.status) {
      case 404:
        return ErrorRedirect.NotFound
      case 500:
        return ErrorRedirect.InternalServerError
      default:
        return ErrorRedirect.NotFound
    }
  }

  // MEMO: Response でない場合は runtime のロジックエラーなので、暫定的に500に飛ばす
  return ErrorRedirect.InternalServerError
}

export const isJson = (text: string) => {
  try {
    JSON.parse(text)
  } catch (e) {
    return false
  }
  return true
}
