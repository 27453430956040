import React, { useMemo } from 'react'
import { Document, EnumDocumentSlideProcessingStatus } from '@noco/http-client/lib/noco/models'
import { Box } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import {
  documentSlideProcessingStatusIcon,
  documentSlideProcessingStatusTextMap
} from 'src/fixtures/modules/documents/utils'

export const DocumentSlideConvertStatusText = ({ document }: { document: Document }) => {
  const showProgressStatusText = useMemo(() => {
    const status = document.slideProcessingStatus
    const statusEnum = EnumDocumentSlideProcessingStatus
    return (
      status === statusEnum.CurrentVersionProccesing ||
      status === statusEnum.CurrentVersionFailed ||
      status === statusEnum.NextVersionProccesing ||
      status === statusEnum.NextVersionFailed
    )
  }, [document.slideProcessingStatus])
  return showProgressStatusText ? (
    <Box display="flex" color={Colors.accent.keyRed.default} alignItems="center">
      <DynamicMuiIcon
        size={18}
        variant={
          documentSlideProcessingStatusIcon[
            document.slideProcessingStatus || EnumDocumentSlideProcessingStatus.CurrentVersionCompleted
          ] || 'error'
        }
      />
      <Box width="6px" />
      {/* paragraph/S_14px_tight_normal */}
      <Typography fontSize="s" lh="tight">
        {
          documentSlideProcessingStatusTextMap[
            document.slideProcessingStatus || EnumDocumentSlideProcessingStatus.CurrentVersionCompleted
          ]
        }
      </Typography>
    </Box>
  ) : (
    <></>
  )
}
