import {
  UserV1SelectorCompaniesGetRequest,
  UserV1SelectorCompanyListsGetRequest,
  UserV1SelectorContactListsGetRequest,
  UserV1SelectorContactsGetRequest,
  UserV1SelectorUsersGetRequest,
  UserV1SelectorDocumentsGetRequest,
  UserV1SelectorSitesGetRequest,
  UserV1SelectorChatMessagesGetRequest,
  UserV1SelectorMailTemplatesGetRequest,
  UserV1SelectorShareTargetsGetRequest,
  UserV1SelectorMaterialablesGetRequest
} from '@noco/http-client/lib/noco'
import { useCallback } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'
import { useGetCurrentCacheKeys } from '../utils/hooks'

/**
 * セレクトボックスとかで使うためのデータ(普通のリストよりも軽量)を取得できる
 */

export const useListSelectorCompanies = (params: UserV1SelectorCompaniesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorCompaniesGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorCompanies(params), func)
}

export const useListSelectorCompaniesList = (params: UserV1SelectorCompanyListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorCompanyListsGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorCompaniesList(params), func)
}

export const useListSelectorCompaniesListAll = () => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const getCompaniesList = async (page: number) => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorCompanyListsGet({ page, per: 100 })
      .then(res => {
        return res.data
      })
  }

  const func = async () => {
    const data = await getCompaniesList(1)
    if (!data) {
      return []
    } else if (!data.pageInfo) {
      throw new Error('unexpected error')
    }

    const totalPages = data.pageInfo.totalPages
    if (totalPages === 0 || totalPages === undefined) {
      return []
    }

    const companyList = []
    companyList.push(...(data.selectorItems || []))
    if (totalPages > 1) {
      for (let i = 2; i <= totalPages; i++) {
        const data2 = await getCompaniesList(i)
        if (!data2?.pageInfo) {
          throw new Error('unexpected error')
        }
        companyList.push(...(data2.selectorItems || []))
      }
    }

    return companyList
  }
  return useSWR(auth && SWRCachePath.listSelectorCompaniesListAll(), func)
}

export const useListSelectorContacts = (params: UserV1SelectorContactsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorContactsGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorContacts(params), func)
}

export const useListSelectorContactsList = (params: UserV1SelectorContactListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorContactListsGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorContactsList(params), func)
}

export const useListSelectorContactsListAll = () => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const getContactsList = async (page: number) => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorContactListsGet({ page, per: 100 })
      .then(res => {
        return res.data
      })
  }

  const func = async () => {
    const data = await getContactsList(1)
    if (!data) {
      return []
    } else if (!data.pageInfo) {
      throw new Error('unexpected error')
    }

    const totalPages = data.pageInfo.totalPages
    if (totalPages === 0 || totalPages === undefined) {
      return []
    }

    const contactList = []
    contactList.push(...(data.selectorItems || []))
    if (totalPages > 1) {
      for (let i = 2; i <= totalPages; i++) {
        const data2 = await getContactsList(i)
        if (!data2?.pageInfo) {
          throw new Error('unexpected error')
        }
        contactList.push(...(data2.selectorItems || []))
      }
    }

    return contactList
  }

  return useSWR(auth && SWRCachePath.listSelectorContactsListAll(), func)
}

export const useListSelectorUsersList = (params: UserV1SelectorUsersGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorUsersGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorUsersList(params), func)
}

export const useListSelectorDocuments = (params: UserV1SelectorDocumentsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorDocumentsGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorDocuments(params), func)
}

export const useListSelectorSites = (params: UserV1SelectorSitesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorSitesGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorSites(params), func)
}

export const useListSelectorShareTargets = (params: UserV1SelectorShareTargetsGetRequest) => {
  const { mutate: globalMutate } = useSWRConfig()
  const currentCacheKeys = useGetCurrentCacheKeys('listSelectorShareTargets')
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorShareTargetsGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  const revalidateAllMutation = () => {
    const mutations = currentCacheKeys.map(key => globalMutate(key, undefined, { revalidate: true }))
    Promise.all(mutations)
  }

  return {
    ...useSWR(auth && SWRCachePath.listSelectorShareTargets(params), func),
    revalidateAllMutation
  }
}

export const useListMailTemplates = (params: UserV1SelectorMailTemplatesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorMailTemplatesGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorMailTemplates(params), func)
}

export const useListSelectorInboxChats = (params: UserV1SelectorChatMessagesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorChatMessagesGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])
  return useSWR(auth && SWRCachePath.listSelectorInboxChats(params), func)
}

export const useListSelectorMaterialables = (params: UserV1SelectorMaterialablesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const selectorApi = nocoSDK.client?.userService.selectorApi
  const func = useCallback(async () => {
    if (!auth) return
    return await selectorApi
      ?.ApiFactory(auth.token)
      .userV1SelectorMaterialablesGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, selectorApi])

  return useSWR(auth && SWRCachePath.listSelectorMaterialables(params), func)
}
