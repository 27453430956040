import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import { useMemo } from 'react'
import { Colors } from 'src/components/atoms'
import { Option, SearchAndSuggestOptionData } from '..'
import { useListSelectorInboxChats } from 'src/fixtures/modules/selector/hooks'
import { SelectorItem } from '@noco/http-client/lib/noco'
import clsx from 'clsx'
import { Paging } from '../../Paging'

interface InboxSuggestListProps {
  currentPage: number
  keyword: string
  onSelect: (value: SearchAndSuggestOptionData['value']) => () => void
  onCurrentPageChange: (page: number) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      height: '100%',
      textAlign: 'left'
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    }
  })
})

export const InboxSuggestList = ({ currentPage, keyword, onSelect, onCurrentPageChange }: InboxSuggestListProps) => {
  const classes = useStyles()

  const { data: resForSearch } = useListSelectorInboxChats({
    text: keyword,
    page: currentPage,
    per: 10
  })

  // 検索用のoption listの生成
  const options = useMemo(() => {
    const suggestList =
      resForSearch?.selectorItems?.map((item: SelectorItem) => {
        return {
          text: item.text,
          contact: item.extend?.contact,
          dateTime: item.extend?.datetime
        }
      }) || []
    return suggestList?.map(list => {
      return {
        value: list.contact?.id!,
        company: list.contact?.companyName,
        text: list.text,
        name: list.contact?.displayName,
        kana: list.contact?.displayNameKana,
        dateTime: list.dateTime
      }
    })
  }, [resForSearch?.selectorItems])

  return (
    <>
      <Box overflow="hidden auto" height="600px" borderTop={`1px solid ${Colors.background.gray}`}>
        {options?.map((option, i) => {
          return (
            <Box key={i} borderBottom={`1px solid ${Colors.background.gray}`} height="88px">
              <ButtonBase
                onClick={onSelect(option.value)}
                key={option.value}
                className={clsx(classes.buttonBase, classes.hoverButton)}
              >
                <Box width={1} px="24px" py="8px">
                  <Option option={option} type="inbox" />
                </Box>
              </ButtonBase>
            </Box>
          )
        })}
      </Box>
      <Box bgcolor={Colors.background.lightGray} height="34px">
        <Paging
          totalCount={resForSearch?.pageInfo?.totalCount!}
          totalPages={resForSearch?.pageInfo?.totalPages!}
          currentPage={currentPage}
          onCurrentPageChange={onCurrentPageChange}
        />
      </Box>
    </>
  )
}
