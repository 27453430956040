/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateContactCompanyRequest
 */
export interface UpdateContactCompanyRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateContactCompanyRequest
   */
  companyId?: string
}

export function UpdateContactCompanyRequestFromJSON(json: any): UpdateContactCompanyRequest {
  return UpdateContactCompanyRequestFromJSONTyped(json, false)
}

export function UpdateContactCompanyRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateContactCompanyRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyId: !exists(json, 'company_id') ? undefined : json['company_id']
  }
}

export function UpdateContactCompanyRequestToJSON(value?: UpdateContactCompanyRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_id: value.companyId
  }
}
