import { Box } from '@material-ui/core'
import { useMemo } from 'react'
import { Button, DynamicMuiIcon, OptionListType, SelectBox, Typography } from 'src/components/atoms'
import { useListSelectorCompaniesListAll, useListSelectorContactsListAll } from 'src/fixtures/modules/selector/hooks'

export interface ExportListProps {
  mode: 'company' | 'contact'
  onSubmit: () => void
  onSetExporting: () => void
  onChangeList: (value: string) => void
  selectedListId: string
}

export const ExportList = ({ mode, onSubmit, onChangeList, selectedListId }: ExportListProps) => {
  const { data: companyList } = useListSelectorCompaniesListAll()
  const { data: contactList } = useListSelectorContactsListAll()

  const options: OptionListType[] = useMemo(() => {
    const list = mode === 'company' ? companyList : contactList
    const tmpList =
      list?.map(item => ({
        value: item.id!,
        label: item.text!
      })) || []

    return [
      {
        value: 'all',
        label: 'すべて'
      },
      ...tmpList
    ]
  }, [mode, companyList, contactList])

  return (
    <>
      <Typography fontSize="s" fontWeight={600} lineheight="14px">
        {mode === 'company' ? '会社' : '連絡先'}のリストを選択してください
      </Typography>
      <Box mt="8px" />
      <SelectBox
        height="34px"
        listWidth={mode === 'company' ? '268px' : '282px'}
        placeholder="選択してください"
        optionList={options}
        onChange={e => onChangeList(e.target.value as string)}
        value={selectedListId}
      />
      <Box mt="16px" />
      <Button
        kind="primary"
        variant="outlined"
        title={`${mode === 'company' ? '会社' : '連絡先'}リストのエクスポートを実行`}
        startIcon={<DynamicMuiIcon variant="getApp" />}
        onClick={onSubmit}
      />
    </>
  )
}
