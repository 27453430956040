import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ToastProps } from 'react-toast-notifications'
import { Colors } from '../Colors'

export const Toast = ({ children, appearance }: ToastProps) => {
  return (
    <Snackbar open={true} autoHideDuration={200} style={{ zIndex: 9999999 }}>
      <Alert
        severity={appearance}
        style={{ backgroundColor: Colors.secondary.default, height: '50px', color: 'white', minWidth: '355px' }}
      >
        {children}
      </Alert>
    </Snackbar>
  )
}
