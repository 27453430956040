import React from 'react'
import { Box, Checkbox as MuiCheckbox } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { Colors } from '../Colors'
import { SvgIcon } from '../SvgIcon'
import clsx from 'clsx'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&&:hover': {
        backgroundColor: 'transparent'
      }
    },
    checkboxIcon: {
      width: ({ size = 'medium' }: CheckboxProps) => (size === 'small' ? '16px' : '18px'),
      height: ({ size = 'medium' }: CheckboxProps) => (size === 'small' ? '16px' : '18px'),
      borderRadius: '2px',
      border: `1px solid ${Colors.base.placeHolder}`,
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2
      },
      'input:hover ~ &': {
        backgroundColor: 'transparent'
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)'
      }
    },
    checkedIcon: {
      display: 'inherit',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      backgroundColor: Colors.primary.default,
      'input:hover ~ &': {
        backgroundColor: Colors.primary.default
      }
    }
  })
)

const CheckedIcon = (props: Pick<CheckboxProps, 'className' | 'size'>) => {
  const classes = useStyles(props)
  return (
    <Box className={clsx(props.className, classes.checkedIcon)}>
      <SvgIcon variant="checkedIcon" size="14px" color="white" />
    </Box>
  )
}

export const Checkbox = (props: CheckboxProps) => {
  const classes = useStyles(props)
  return (
    <MuiCheckbox
      className={classes.root}
      disableRipple
      checkedIcon={<CheckedIcon className={clsx(classes.checkboxIcon, props.className)} />}
      icon={<span className={clsx(classes.checkboxIcon, props.className)} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  )
}
