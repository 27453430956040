/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EuSiteSectionDocument,
  EuSiteSectionDocumentFromJSON,
  EuSiteSectionDocumentFromJSONTyped,
  EuSiteSectionDocumentToJSON
} from './EuSiteSectionDocument'

/**
 *
 * @export
 * @interface EuSiteSection
 */
export interface EuSiteSection {
  /**
   *
   * @type {string}
   * @memberof EuSiteSection
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSection
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof EuSiteSection
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof EuSiteSection
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSection
   */
  updatedAt?: string
  /**
   *
   * @type {Array<EuSiteSectionDocument>}
   * @memberof EuSiteSection
   */
  siteSectionDocuments?: Array<EuSiteSectionDocument>
}

export function EuSiteSectionFromJSON(json: any): EuSiteSection {
  return EuSiteSectionFromJSONTyped(json, false)
}

export function EuSiteSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EuSiteSection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    siteSectionDocuments: !exists(json, 'site_section_documents')
      ? undefined
      : (json['site_section_documents'] as Array<any>).map(EuSiteSectionDocumentFromJSON)
  }
}

export function EuSiteSectionToJSON(value?: EuSiteSection | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    sort: value.sort,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    site_section_documents:
      value.siteSectionDocuments === undefined
        ? undefined
        : (value.siteSectionDocuments as Array<any>).map(EuSiteSectionDocumentToJSON)
  }
}
