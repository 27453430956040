import { DocumentSlideImageLink } from '@noco/http-client/lib/noco'
import { Area, ImageMap } from '@qiuz/react-image-map'
import React, { useCallback, useMemo } from 'react'
import { getLinkRectData } from '../utils'
export interface LinkImageProps {
  className: string
  imgSrc?: string | null
  links?: DocumentSlideImageLink[]
  onLoad?: () => void
}
export const LinkImage = ({ imgSrc, className, links, onLoad }: LinkImageProps) => {
  const linkMaps = useMemo(() => {
    if (!links) return []
    return links.map(item => {
      const rect = getLinkRectData(item)
      return {
        top: rect?.top + '%',
        left: rect?.left + '%',
        width: rect?.width + '%',
        height: rect?.height + '%',
        style: {
          display: 'flex',
          cursor: 'pointer',
          //   for: debug
          //   backgroundColor: 'rgba(0,0,0,0.4)',
          zIndex: 9999999
        }
      } as Area
    })
  }, [links])

  const handleClickMap = useCallback(
    (_: Area, index: number) => {
      const uri = links?.[index].uri
      if (uri) window.open(uri, '_target')
    },
    [links]
  )
  return (
    <ImageMap className={className} src={imgSrc || ''} map={linkMaps} onMapClick={handleClickMap} onLoad={onLoad} />
  )
}
