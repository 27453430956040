import { useCallback, useRef, useState, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { Box, InputAdornment } from '@material-ui/core'
import { MuiIconVariant } from '../../atoms/DynamicMuiIcon'
import { Button, DynamicMuiIcon, SvgIcon, Tooltip } from 'src/components/atoms'
import { Colors } from 'src/components/atoms/Colors'
import { Input } from 'src/components/atoms/Input'
import { Typography } from 'src/components/atoms/Typography'
import { makeStyles, createStyles, TextField } from '@material-ui/core'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import { SelectDeviceTabs } from 'src/components/molecules/SelectDeviceTabs'
import { PagePreviewContent } from '../../pages/PagePreviewContent'
import { useFormik } from 'formik'
import {
  useCreateMailTemplate,
  useGetMailTemplateVariables,
  useUpdateMailTemplate
} from '../../../fixtures/modules/mailTemplates/hooks'
import { useListSelectorDocuments, useListSelectorSites } from '../../../fixtures/modules/selector/hooks'
import { LayoutSinglePage } from '../../commons/LayoutSinglePage/index'
import { useGetMe } from '../../../fixtures/modules/me/hooks'
import { useGetOrganization } from '../../../fixtures/modules/organization/hooks'

interface PageTeamMailTemplateEditProps {
  item?: { id?: string; name: string; subject: string; body: string }
}

const buttonIcon: { [K: string]: MuiIconVariant } = {
  company_name: 'business',
  contact_name: 'account',
  organization_name: 'apartment',
  user_name: 'person',
  document_title: 'document',
  site_title: 'document'
}

const initMailTemplateValues = {
  id: undefined,
  name: '',
  subject: '',
  body: `{{company_name}}
{{contact_name}}様`
}

const useStyles = makeStyles(() => {
  return createStyles({
    inputBox: {
      '&:hover': {
        boxShadow: 'rgb(10 10 10 / 10%) 0px 1px 2px inset',
        borderColor: '#ababab',
        transition: '512ms'
      }
    },
    subject: {
      width: '100%',
      '& .MuiInput-root': {
        border: 'none',
        boxShadow: 'none'
      },
      '& .MuiInputBase-input': {
        padding: '12px 10px'
      }
    },
    body: {
      width: '100%',
      '& .MuiInput-root': {
        border: 'none',
        boxShadow: 'none',
        '& textarea': {
          minHeight: '300px !important',
          resize: 'vertical',
          padding: '12px 0',
          margin: '0 12px'
        }
      }
    },
    avatarIcon: {
      borderRadius: '50%',
      objectFit: 'cover'
    }
  })
})

export const PageTeamMailTemplateEdit: React.VFC<PageTeamMailTemplateEditProps> = ({
  item = initMailTemplateValues
}) => {
  const classes = useStyles()
  const router = useRouter()
  const [view, setView] = useState('pc')
  const subjectRef = useRef<HTMLInputElement>(null)
  const bodyRef = useRef<HTMLTextAreaElement>(null)
  const { handleCreateMailTemplate } = useCreateMailTemplate({})
  const { handleUpdateMailTemplate } = useUpdateMailTemplate(item.id!, {})
  const { data: variables } = useGetMailTemplateVariables()

  // NOTE: プレビューで置換する値を取得
  const { data: documentsResult } = useListSelectorDocuments({})
  const { data: sitesResult } = useListSelectorSites({})
  const { data: me } = useGetMe()
  const { data: organization } = useGetOrganization()

  // NOTE: 1件目の資料名 or 【資料名】
  const dummyDocumentsTitle = useMemo(() => {
    if (!documentsResult?.selectorItems || !documentsResult.selectorItems?.[0]?.text) return '【資料名】'
    return documentsResult?.selectorItems[0].text
  }, [documentsResult])

  // NOTE: 1件目のサイト名 or 【資料サイト名】
  const dummySitesTitle = useMemo(() => {
    if (!sitesResult?.selectorItems || !sitesResult.selectorItems?.[0]?.text) return '【資料サイト名】'
    return sitesResult?.selectorItems[0].text
  }, [sitesResult])

  // NOTE: プレビューで置換する値
  const dummyText = useMemo<{ [K: string]: string }>(
    () => ({
      company_name: '【顧客の会社名】',
      contact_name: '【顧客名（無い場合はメールアドレス）】',
      organization_name: `${organization?.organization?.name || ''}`,
      user_name: `${me?.user?.lastName || ''}${me?.user?.firstName || ''}`,
      document_title: dummyDocumentsTitle,
      site_title: dummySitesTitle
    }),
    [dummyDocumentsTitle, dummySitesTitle, me, organization]
  )

  const { values, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: item.name,
      subject: item.subject,
      body: item.body
    },
    onSubmit: values => {
      try {
        if (item.id) handleUpdateMailTemplate({ mailTemplate: values })
        else handleCreateMailTemplate({ mailTemplate: values })
      } catch (e) {
        return
      }
      router.push('/setting/team/email_templates')
    }
  })

  const replacedSubject = useMemo(() => {
    return (
      variables?.reduce((acc, item) => acc.replaceAll(`{{${item.name}}}`, dummyText[item.name]), values.subject) || ''
    )
  }, [dummyText, values.subject, variables])

  const replacedBody = useMemo(() => {
    return variables?.reduce((acc, item) => acc.replaceAll(`{{${item.name}}}`, dummyText[item.name]), values.body) || ''
  }, [dummyText, values.body, variables])

  const handlePcView = useCallback(() => setView('pc'), [])
  const handleSpView = useCallback(() => setView('sp'), [])

  const insertText = useCallback(
    (text: string) => {
      const appendText = `{{${text}}}`

      const isSubject = document.activeElement === subjectRef.current
      const isBody = document.activeElement === bodyRef.current

      const targetName = isSubject ? 'subject' : 'body'
      const targetElement = isSubject ? subjectRef.current : bodyRef.current

      if (targetElement == null || targetElement.selectionStart === null) return

      const selectionStart = targetElement.selectionStart

      // NOTE: 0文字目にカーソルがあれば先頭に、要素がアクティブじゃなければ末尾に挿入する
      const after =
        selectionStart === 0
          ? !isSubject && !isBody
            ? `${values[targetName]}${appendText}`
            : `${appendText}${values[targetName]}`
          : `${values[targetName].slice(0, selectionStart)}${appendText}${values[targetName].slice(selectionStart)}`

      targetElement.blur()
      setFieldValue(targetName, after)
    },
    [setFieldValue, values]
  )

  const handleBack = useCallback(() => {
    // NOTE: 変更がなければ確認しなくてよい
    const shouldConfirm = values.name !== item.name || values.subject !== item.subject || values.body !== item.body

    if (!shouldConfirm) {
      router.push('/setting/team/email_templates')
      return
    }

    if (window.confirm('変更が破棄されてしまいます。よろしいですか？')) {
      // NOTE: 編集するidが変わらなければ再度編集した際にinputの値が置き換わらないので戻しておく
      setFieldValue('name', item.name)
      setFieldValue('subject', item.subject)
      setFieldValue('body', item.body)

      router.push('/setting/team/email_templates')
    }
  }, [item, setFieldValue, values, router])

  useEffect(() => {
    window.addEventListener('popstate', handleBack)

    return () => {
      window.removeEventListener('popstate', handleBack)
    }
  }, [handleBack])

  return (
    <LayoutSinglePage title="メールテンプレートの編集" back onBack={handleBack} isOverflowScrollable={false}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          bgcolor: Colors.functional.background.default
        }}
      >
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden auto'
          }}
          p="24px"
        >
          <form onSubmit={handleSubmit}>
            <Typography variant="h5">テンプレート名</Typography>
            <Box mt="8px" />
            <Input
              type="text"
              name="name"
              borderRadius="4"
              width="100%"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <Box
              sx={{
                borderBottom: `1px solid ${Colors.background.silver}`
              }}
              mt="28px"
              mb="24px"
            />

            <Box display="flex" alignItems="center">
              <Typography variant="h5">差し込み文字の挿入</Typography>
              <Box width="4px" />
              <Tooltip content={'「差し込み文字」を挿入することで、送信時件名と本文に自動で記載します。'}>
                <DynamicMuiIcon variant="helpRounded" size="16px" color="action" />
              </Tooltip>
            </Box>

            <Box flexDirection="row" mt="8px" mb="20px">
              {(variables || []).map(item => (
                <Box display="inline-flex" mr="8px" mb="8px" key={item.name}>
                  <Button
                    kind="neutral"
                    startIcon={<DynamicMuiIcon variant={buttonIcon[item.name]} />}
                    title={item.label}
                    onMouseDown={() => insertText(item.name)}
                  />
                </Box>
              ))}
            </Box>

            <Typography variant="h5">テンプレート内容</Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: `1px solid ${Colors.background.silver}`,
                borderRadius: '4px'
              }}
              mt="8px"
              mb="24px"
              className={classes.inputBox}
            >
              <TextField
                type="text"
                name="subject"
                className={classes.subject}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <Box color={Colors.base.middleGray} pl="4px">
                        <Typography variant="body2">件名</Typography>
                      </Box>
                    </InputAdornment>
                  )
                }}
                value={values.subject}
                onBlur={handleBlur}
                onChange={handleChange}
                inputRef={subjectRef}
              />
              <Box
                sx={{
                  borderBottom: `1px solid ${Colors.background.silver}`
                }}
                mx="12px"
              />
              <TextField
                type="text"
                name="body"
                multiline
                className={classes.body}
                value={values.body}
                onBlur={handleBlur}
                inputRef={bodyRef}
                onChange={handleChange}
              />
            </Box>

            <Button width="80px" kind="primary" title="保存" type="submit" />
          </form>
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden auto'
          }}
          borderLeft={`1px solid ${Colors.background.silver}`}
          bgcolor={Colors.background.lightGray}
          px="24px"
          py="24px"
        >
          <Typography variant="h5">プレビュー</Typography>

          <Box mt="6px" mb="12px" textAlign="center">
            <SelectDeviceTabs
              onChangePCPreview={handlePcView}
              onChangeSPPreview={handleSpView}
              isPCPreview={view === 'pc'}
            />
          </Box>

          <PagePreviewContent header={null} isPCPreview={view === 'pc'}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '40px'
              }}
            >
              <Box style={{ lineHeight: 0 }}>
                <Image alt="" src="/images/nocosell_logo.svg" height={24} width={84} />
              </Box>

              <Box mt="22px" />

              <Typography variant="h3" align="center">
                {replacedSubject}
              </Typography>

              <Box mt="24px" />

              <Typography>
                {dummyText.company_name}
                <br />
                {dummyText.contact_name} 様<br />
                <br />
                {dummyText.organization_name}の{dummyText.user_name}さんから、
                <br />
                メッセージが届きました
              </Typography>

              <Box mt="24px" />

              <Box
                sx={{
                  border: `1px solid ${Colors.background.silver}`,
                  borderRadius: '6px',
                  whiteSpace: 'pre-wrap'
                }}
                py="12px"
                px="15px"
              >
                <Box mb="12px" display="flex" alignItems="center">
                  {me?.user?.avatar?.url ? (
                    <Image src={me.user.avatar.url} width="16px" height="16px" className={classes.avatarIcon} />
                  ) : (
                    <SvgIcon variant="avatar" size="16px" color={Colors.base.middleGray} />
                  )}
                  <Box width="8px" />
                  <Typography variant="h5">{dummyText.name}</Typography>
                  <Box width="10px" />
                  <Typography variant="body2">
                    {format(new Date(), 'yyyy年MM月dd日（水） HH:mm', { locale: ja })}
                  </Typography>
                </Box>

                <Box fontSize="16px" lineHeight="1.7">
                  {replacedBody}
                </Box>

                <Box px="15px" mt="16px" borderBottom={`1px solid ${Colors.background.silver}`} />

                <Box maxWidth="360px" height="200px" bgcolor={Colors.background.lightGray} my="26px" mx="auto" />

                <Box display="flex" justifyContent="center">
                  <Button width="120px" kind="primary" title="資料を開く" />
                </Box>
              </Box>
            </Box>
          </PagePreviewContent>
        </Box>
      </Box>
    </LayoutSinglePage>
  )
}
