/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { UserWithToken, UserWithTokenFromJSON, UserWithTokenFromJSONTyped, UserWithTokenToJSON } from './UserWithToken'

/**
 *
 * @export
 * @interface ResetPasswordResponseData
 */
export interface ResetPasswordResponseData {
  /**
   *
   * @type {UserWithToken}
   * @memberof ResetPasswordResponseData
   */
  user?: UserWithToken
}

export function ResetPasswordResponseDataFromJSON(json: any): ResetPasswordResponseData {
  return ResetPasswordResponseDataFromJSONTyped(json, false)
}

export function ResetPasswordResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResetPasswordResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    user: !exists(json, 'user') ? undefined : UserWithTokenFromJSON(json['user'])
  }
}

export function ResetPasswordResponseDataToJSON(value?: ResetPasswordResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user: UserWithTokenToJSON(value.user)
  }
}
