/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateSiteSectionRequestSiteSection,
  CreateSiteSectionRequestSiteSectionFromJSON,
  CreateSiteSectionRequestSiteSectionFromJSONTyped,
  CreateSiteSectionRequestSiteSectionToJSON
} from './CreateSiteSectionRequestSiteSection'

/**
 *
 * @export
 * @interface CreateContactSettingRequestContactSettingFieldsInner
 */
export interface CreateContactSettingRequestContactSettingFieldsInner {
  /**
   *
   * @type {string}
   * @memberof CreateContactSettingRequestContactSettingFieldsInner
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof CreateContactSettingRequestContactSettingFieldsInner
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof CreateContactSettingRequestContactSettingFieldsInner
   */
  kind?: CreateContactSettingRequestContactSettingFieldsInnerKindEnum
  /**
   *
   * @type {Array<CreateSiteSectionRequestSiteSection>}
   * @memberof CreateContactSettingRequestContactSettingFieldsInner
   */
  contactSettingFieldItems?: Array<CreateSiteSectionRequestSiteSection> | null
}

/**
 * @export
 */
export const CreateContactSettingRequestContactSettingFieldsInnerKindEnum = {
  Text: 'text',
  Number: 'number',
  Radio: 'radio',
  Dropdown: 'dropdown',
  Checkbox: 'checkbox',
  Date: 'date',
  Time: 'time'
} as const
export type CreateContactSettingRequestContactSettingFieldsInnerKindEnum =
  typeof CreateContactSettingRequestContactSettingFieldsInnerKindEnum[keyof typeof CreateContactSettingRequestContactSettingFieldsInnerKindEnum]

export function CreateContactSettingRequestContactSettingFieldsInnerFromJSON(
  json: any
): CreateContactSettingRequestContactSettingFieldsInner {
  return CreateContactSettingRequestContactSettingFieldsInnerFromJSONTyped(json, false)
}

export function CreateContactSettingRequestContactSettingFieldsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactSettingRequestContactSettingFieldsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    kind: !exists(json, 'kind') ? undefined : json['kind'],
    contactSettingFieldItems: !exists(json, 'contact_setting_field_items')
      ? undefined
      : json['contact_setting_field_items'] === null
      ? null
      : (json['contact_setting_field_items'] as Array<any>).map(CreateSiteSectionRequestSiteSectionFromJSON)
  }
}

export function CreateContactSettingRequestContactSettingFieldsInnerToJSON(
  value?: CreateContactSettingRequestContactSettingFieldsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    sort: value.sort,
    kind: value.kind,
    contact_setting_field_items:
      value.contactSettingFieldItems === undefined
        ? undefined
        : value.contactSettingFieldItems === null
        ? null
        : (value.contactSettingFieldItems as Array<any>).map(CreateSiteSectionRequestSiteSectionToJSON)
  }
}
