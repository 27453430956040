import React, { useMemo } from 'react'
import { SvgIconTypeMap, createStyles, makeStyles } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import * as SvgIcon from './SvgComponents'

export type IconVariant =
  | 'user'
  | 'mail'
  | 'chat'
  | 'rocket'
  | 'material'
  | 'calendar'
  | 'customBranding'
  | 'inport'
  | 'export'
  | 'ad'
  | 'role'

export const BoardIconMap: { [key in IconVariant]: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> } = {
  user: SvgIcon.User,
  mail: SvgIcon.Mail,
  chat: SvgIcon.Chat,
  rocket: SvgIcon.Rocket,
  material: SvgIcon.Material,
  calendar: SvgIcon.Calendar,
  customBranding: SvgIcon.CustomBranding,
  inport: SvgIcon.Import,
  export: SvgIcon.Export,
  ad: SvgIcon.Ad,
  role: SvgIcon.Role
}

export interface BoardIconProps {
  variant: IconVariant
}

const useStyles = makeStyles(() => {
  return createStyles({
    icon: {
      color: 'inherit',
      fontSize: 'inherit',
      height: 'auto',
      lineHeight: 0,
      width: 'auto'
    }
  })
})

export const BoardIcon = ({ variant }: BoardIconProps) => {
  const classes = useStyles()
  const Icon = useMemo(() => BoardIconMap[variant], [variant])

  return <Icon classes={{ root: classes.icon }} />
}
