import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const People = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#CFEDC6" />
      <mask
        id="mask0_2773_131007"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <circle cx="24" cy="24" r="24" fill="#CFEDC6" />
      </mask>
      <g mask="url(#mask0_2773_131007)">
        <path
          d="M24.0012 4.62476C15.7564 4.62476 9.07266 9.51924 9.07268 17.5499C9.07252 20.2318 8.39397 33.8032 9.07268 43.9817H38.9297C39.6084 37.1961 38.9297 17.5499 38.9297 17.5499C38.9297 9.51924 32.246 4.62476 24.0012 4.62476Z"
          fill="#564444"
        />
        <path
          d="M5.00024 48.2365C5.00024 45.5478 6.32497 44.213 8.73509 43.0211C12.2514 41.2822 17.8805 39.1018 24.0002 39.1018C30.1199 39.1018 35.749 41.2822 39.2653 43.0211C41.6754 44.213 43.0001 45.5478 43.0001 48.2365V60.1671C43.0001 60.9166 42.3925 61.5243 41.643 61.5243H6.35738C5.60785 61.5243 5.00024 60.9166 5.00024 60.1671V48.2365Z"
          fill="#8AAEE4"
        />
        <path
          d="M19.9299 34.481H28.0728V38.5524C24.7632 40.5354 23.044 40.4684 19.9299 38.5524V34.481Z"
          fill="#FFE1C6"
        />
        <path
          d="M13.9652 29.9155C11.5873 30.2948 9.35223 28.6746 8.97297 26.2967C8.59371 23.9188 10.2139 21.6837 12.5918 21.3044L13.9652 29.9155Z"
          fill="#F1BFA3"
        />
        <path
          d="M33.4987 29.9155C36.1918 30.345 38.7232 28.5101 39.1527 25.817C39.5822 23.124 37.7473 20.5926 35.0542 20.1631L33.4987 29.9155Z"
          fill="#F1BFA3"
        />
        <path
          d="M36.2153 19.5512C36.2153 23.567 35.8425 29.0512 32.1439 33.4279C30.6817 35.1583 27.4689 37.194 24.0011 37.194C20.5333 37.194 17.5887 35.1583 15.8583 33.4279C12.4654 30.035 11.7869 23.6226 11.7869 19.5512C11.7869 12.8055 17.2554 8.69409 24.0011 8.69409C30.7468 8.69409 36.2153 12.8055 36.2153 19.5512Z"
          fill="#FFE1C6"
        />
        <path
          d="M38.9269 17.5499C38.9269 17.5499 38.9158 19.3842 38.9269 19.852C38.5023 19.852 36.5752 19.5533 36.2127 19.5533C32.8197 19.5533 21.9626 17.5499 19.9269 10.7319C19.2484 14.1247 15.1769 19.5533 11.1056 21.589C9.74839 19.5533 9.06982 18.1961 9.06982 17.5499C9.06983 9.51924 15.7535 4.62476 23.9983 4.62476C32.2431 4.62476 38.9269 9.51924 38.9269 17.5499Z"
          fill="#855E5E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8868 8.7615C11.3366 10.9693 9.74874 14.1738 9.74874 18.1962V24.3034H8.3916V18.1962C8.3916 13.7865 10.1456 10.2053 12.9985 7.73546C15.8416 5.27407 19.739 3.94629 23.9987 3.94629C28.2584 3.94629 32.1558 5.27407 34.9989 7.73546C37.8517 10.2053 39.6058 13.7865 39.6058 18.1962V21.5891H38.2486V18.1962C38.2486 14.1738 36.6608 10.9693 34.1106 8.7615C31.5506 6.54524 27.9838 5.30343 23.9987 5.30343C20.0136 5.30343 16.4467 6.54524 13.8868 8.7615Z"
          fill="#222B35"
        />
        <path
          d="M10.4309 26.3401C8.93186 26.3401 7.71663 24.8211 7.71663 22.9473C7.71663 21.0735 8.93186 19.5544 10.4309 19.5544V26.3401Z"
          fill="#222B35"
        />
        <path
          d="M37.5718 26.3401C39.0708 26.3401 40.2861 24.8211 40.2861 22.9473C40.2861 21.0735 39.0708 19.5544 37.5718 19.5544V26.3401Z"
          fill="#222B35"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4309 26.3401V19.5544H11.788V26.3401H10.4309Z"
          fill="#6D7784"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5718 26.3401V19.5544H36.2146V26.3401H37.5718Z"
          fill="#6D7784"
        />
        <path
          d="M23.8553 39.9335L19.648 37.8299C19.088 37.5499 18.4113 37.8475 18.2393 38.4496L17.4199 41.3172C17.2975 41.7458 17.4735 42.2037 17.8515 42.4399L21.4752 44.7048C22.0511 45.0647 22.8106 44.7582 22.9753 44.0993L23.9599 40.1609C23.9826 40.07 23.9392 39.9755 23.8553 39.9335Z"
          fill="#F4F4F4"
        />
        <path
          d="M24.1444 39.9335L28.3518 37.8299C28.9118 37.5499 29.5885 37.8475 29.7605 38.4496L30.5798 41.3172C30.7023 41.7458 30.5263 42.2037 30.1483 42.4399L26.5246 44.7048C25.9487 45.0647 25.1892 44.7582 25.0244 44.0993L24.0398 40.1609C24.0171 40.07 24.0606 39.9755 24.1444 39.9335Z"
          fill="#F4F4F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0208 32.6014C35.1895 32.3228 36.2144 31.0896 36.2144 30.411V26.3396H37.5715V30.411C37.5715 31.5155 36.6784 32.4839 36.1818 33.3042C35.6831 34.128 34.9345 34.744 33.9661 35.2014C32.071 36.0965 30.58 36.4324 26.7286 36.518L26.6985 35.1612C30.5572 35.0754 31.7805 34.7328 33.3865 33.9743C34.1686 33.6049 34.6886 33.1503 35.0208 32.6014Z"
          fill="#222B35"
        />
        <rect x="22.6433" y="34.481" width="5.42855" height="2.71428" rx="1.35714" fill="#222B35" />
        <path
          d="M24.0009 33.1255C25.8678 33.1255 27.0927 32.0523 27.6755 31.0164C28.043 30.3632 27.4647 29.7327 26.7152 29.7327H21.2867C20.5371 29.7327 19.9905 30.3598 20.4007 30.9871C21.0838 32.032 22.4398 33.1255 24.0009 33.1255Z"
          fill="#DF8181"
        />
        <path
          d="M17.2157 24.3039C17.2157 24.3039 17.2158 26.3396 18.7667 26.3396C20.3175 26.3396 20.3175 22.2682 18.7667 22.2682C18.1665 22.2682 17.7986 22.573 17.573 22.9468C17.2157 23.5388 17.2157 24.3039 17.2157 24.3039Z"
          fill="#2C2F31"
        />
        <path
          d="M18.5703 19.554C18.5703 19.554 17.2131 19.5541 17.2131 20.3295C17.2131 21.1049 19.9274 21.1049 19.9274 20.3295C19.9274 20.0294 19.7242 19.8455 19.475 19.7327C19.0803 19.554 18.5703 19.554 18.5703 19.554Z"
          fill="#855E5E"
        />
        <path
          d="M29.4295 19.554C29.4295 19.554 30.7866 19.5541 30.7866 20.3295C30.7866 21.1049 28.0723 21.1049 28.0723 20.3295C28.0723 20.0294 28.2756 19.8455 28.5247 19.7327C28.9195 19.554 29.4295 19.554 29.4295 19.554Z"
          fill="#855E5E"
        />
        <path
          d="M30.7861 24.3039C30.7861 24.3039 30.7859 26.3396 29.235 26.3396C27.6842 26.3396 27.6842 22.2682 29.235 22.2682C29.8352 22.2682 30.2031 22.573 30.4287 22.9468C30.786 23.5388 30.7861 24.3039 30.7861 24.3039Z"
          fill="#2C2F31"
        />
      </g>
    </SvgIcon>
  )
}
export default People
