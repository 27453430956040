/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CompanyFromJSON, CompanyToJSON } from './Company';
export function AppendContactsCompanyResponseDataFromJSON(json) {
    return AppendContactsCompanyResponseDataFromJSONTyped(json, false);
}
export function AppendContactsCompanyResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companies: !exists(json, 'companies') ? undefined : json['companies'].map(CompanyFromJSON)
    };
}
export function AppendContactsCompanyResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        companies: value.companies === undefined ? undefined : value.companies.map(CompanyToJSON)
    };
}
