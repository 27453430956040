import { Box } from '@material-ui/core'
import React from 'react'
import { useStepperContext } from '../StepperContext'
import { StepperItem } from '../StepperItem'

export interface StepperItemData {
  label: string
}
export interface StepperProps {
  items: StepperItemData[]
  onClick?: (index: number) => void
}
export const Stepper = ({ items, onClick }: StepperProps) => {
  const { currentIndex } = useStepperContext()
  return (
    <Box sx={{ display: 'flex', width: '100%', height: '70px' }}>
      {items.map((item, index) => {
        return (
          <StepperItem
            onClick={() => onClick?.(index)}
            key={index}
            isFirst={index === 0}
            isLast={index === items.length - 1}
            index={index}
            label={item.label}
            currentIndex={currentIndex}
          />
        )
      })}
    </Box>
  )
}
