/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateContactSettingRequestContactSetting
 */
export interface CreateContactSettingRequestContactSetting {
  /**
   *
   * @type {string}
   * @memberof CreateContactSettingRequestContactSetting
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CreateContactSettingRequestContactSetting
   */
  icon?: string
  /**
   *
   * @type {string}
   * @memberof CreateContactSettingRequestContactSetting
   */
  kind?: CreateContactSettingRequestContactSettingKindEnum
}

/**
 * @export
 */
export const CreateContactSettingRequestContactSettingKindEnum = {
  SingleField: 'single_field',
  MultipleField: 'multiple_field'
} as const
export type CreateContactSettingRequestContactSettingKindEnum =
  typeof CreateContactSettingRequestContactSettingKindEnum[keyof typeof CreateContactSettingRequestContactSettingKindEnum]

export function CreateContactSettingRequestContactSettingFromJSON(
  json: any
): CreateContactSettingRequestContactSetting {
  return CreateContactSettingRequestContactSettingFromJSONTyped(json, false)
}

export function CreateContactSettingRequestContactSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactSettingRequestContactSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    icon: !exists(json, 'icon') ? undefined : json['icon'],
    kind: !exists(json, 'kind') ? undefined : json['kind']
  }
}

export function CreateContactSettingRequestContactSettingToJSON(
  value?: CreateContactSettingRequestContactSetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    icon: value.icon,
    kind: value.kind
  }
}
