import { createStyles, makeStyles, TextField, InputProps, Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors, Pallete } from '../Colors'
import { Button } from '../'

export interface TextPaneInputProps {
  value: string
  title?: string
  onClick: () => void
  onChange: InputProps['onChange']
  placeholder?: string
  minRows?: number
  maxRows?: number
  name?: string
  disabled?: boolean
  multiline?: boolean
  variant?: 'standard' | 'outlined'
  minHeight?: string | number
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: 'none',
      minHeight: ({ minHeight = '37px' }: { minHeight?: string | number }) => minHeight
    },
    inputMultiline: {
      paddingTop: 0,
      paddingBottom: 0
    },
    placeholderColor: {
      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: `${Colors.base.placeHolder} !important`
        }
      }
    }
  })
)

export const TextPaneInput = ({
  value,
  disabled,
  minRows = 2,
  maxRows = 4,
  onChange,
  name,
  placeholder,
  multiline = false,
  variant,
  minHeight,
  title = '',
  onClick
}: TextPaneInputProps) => {
  const classes = useStyles({ minHeight })
  const inputProps: Partial<InputProps> = useMemo(() => {
    return {
      classes: {
        root: classes.root,
        inputMultiline: classes.inputMultiline
      }
    }
  }, [classes])

  return (
    <Box
      minHeight="88px"
      bgcolor={Pallete.functional.background.default}
      border="1px solid #DADCE0"
      boxSizing="border-box"
      borderRadius="4px"
    >
      <TextField
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        fullWidth
        variant={variant}
        value={value}
        disabled={disabled}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        InputProps={inputProps}
        className={classes.placeholderColor}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height="50px"
        pr="8px"
        boxShadow={`inset 0px 1px 0px ${Colors.background.gray}`}
      >
        <Button title={title} onClick={onClick} />
      </Box>
    </Box>
  )
}
