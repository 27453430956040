import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Company = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00002 3.83333V2.16667C9.00002 1.25 8.25002 0.5 7.33335 0.5H2.33335C1.41669 0.5 0.666687 1.25 0.666687 2.16667V13.8333C0.666687 14.75 1.41669 15.5 2.33335 15.5H15.6667C16.5834 15.5 17.3334 14.75 17.3334 13.8333V5.5C17.3334 4.58333 16.5834 3.83333 15.6667 3.83333H9.00002ZM4.00002 13.8333H2.33335V12.1667H4.00002V13.8333ZM4.00002 10.5H2.33335V8.83333H4.00002V10.5ZM4.00002 7.16667H2.33335V5.5H4.00002V7.16667ZM4.00002 3.83333H2.33335V2.16667H4.00002V3.83333ZM7.33335 13.8333H5.66669V12.1667H7.33335V13.8333ZM7.33335 10.5H5.66669V8.83333H7.33335V10.5ZM7.33335 7.16667H5.66669V5.5H7.33335V7.16667ZM7.33335 3.83333H5.66669V2.16667H7.33335V3.83333ZM14.8334 13.8333H9.00002V12.1667H10.6667V10.5H9.00002V8.83333H10.6667V7.16667H9.00002V5.5H14.8334C15.2917 5.5 15.6667 5.875 15.6667 6.33333V13C15.6667 13.4583 15.2917 13.8333 14.8334 13.8333ZM14 7.16667H12.3334V8.83333H14V7.16667ZM14 10.5H12.3334V12.1667H14V10.5Z" />
    </SvgIcon>
  )
}
export default Company
