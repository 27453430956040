/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumUserOperationDisplayObjectItemResourceType = {
    Document: 'Document',
    Site: 'Site'
};
export function EnumUserOperationDisplayObjectItemResourceTypeFromJSON(json) {
    return EnumUserOperationDisplayObjectItemResourceTypeFromJSONTyped(json, false);
}
export function EnumUserOperationDisplayObjectItemResourceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumUserOperationDisplayObjectItemResourceTypeToJSON(value) {
    return value;
}
