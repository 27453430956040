import { useSWRConfig } from 'swr'

export const useGetCurrentCacheKeys = (baseKey: string) => {
  const { cache } = useSWRConfig()

  if (!(cache instanceof Map)) {
    throw new Error('revalidateAllMutation requires the cache provider to be a Map instance')
  }

  const re = new RegExp(`^${baseKey}?`)
  const keys = []
  for (const key of cache.keys()) {
    if (re.test(key)) {
      keys.push(key)
    }
  }

  return keys
}
