import { DataTypeProvider, SelectionStateProps, TableColumnWidthInfo } from '@devexpress/dx-react-grid'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { UserV1DocumentsGetRequest } from '@noco/http-client/lib/noco'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { ModalDocumentRegister, ModalFolderCreate, PopupTemplate } from 'src/components/modals'
import { SelectTableRow, CustomButtonListType } from 'src/components/molecules'
import { Header, SubSideFolderList, Table, TableColumn, TableProps, useTablePagination } from 'src/components/organisms'
import {
  DatetimeCell,
  DocumentTitleCell,
  LabelCell,
  NameCell,
  PublicationStatusCell,
  CountOfContactFromEmailCell,
  CountOfViewedByContactFromEmailCell,
  RateOfViewedByContactFromEmailCell,
  CountOfViewedByPublicLinkCell,
  SecondOfAverageBrowsingTimeCell,
  CountOfOpportunityCell,
  RateOfOpportunityCell,
  CountOfCtaCell
} from 'src/components/organisms/Table/TableParts'
import { useArchiveDocument, useListDocumentFilter, useListDocuments } from 'src/fixtures/modules/documents/hooks'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
import { ModalSelectTableColumn, ModalSelectTableColumnOption } from 'src/components/modals/ModalSelectTableColumn'
import { localStorageUtils, StorageKey } from 'src/fixtures/utils/localstorage'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'

const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

const columns: TableColumn[] = [
  {
    name: 'title',
    title: '資料名',
    width: 290,
    fixed: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <DocumentTitleCell imageUrl={row.thumbnail.url} value={row.title} />
    )
  },
  { name: 'createdBy', width: 132, title: '作成者', render: NameCell },
  { name: 'createdAt', width: 152, title: '作成日', render: DatetimeCell },
  { name: 'publicationStatus', width: 86, title: '公開状態', render: PublicationStatusCell },
  { name: 'label', width: 109, title: 'ラベル', render: LabelCell },
  { name: 'memo', width: 168, title: 'メモ' },
  {
    name: 'countOfContactFromEmail',
    title: 'メール送付済みの人数',
    width: 168,
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <CountOfContactFromEmailCell value={row.statisticObject?.countOfContactFromEmail} />
    ),
    getCellValue: (row): number => row.statisticObject?.countOfContactFromEmail
  },
  {
    name: 'countOfViewedByContactFromEmail',
    title: 'メール経由の閲覧者数',
    width: 168,
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <CountOfViewedByContactFromEmailCell value={row.statisticObject?.countOfViewedByContactFromEmail} />
    ),
    getCellValue: (row): number => row.statisticObject?.countOfViewedByContactFromEmail
  },
  {
    name: 'rateOfViewedByContactFromEmail',
    width: 168,
    title: 'メール経由による閲覧率',
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <RateOfViewedByContactFromEmailCell value={row.statisticObject?.rateOfViewedByContactFromEmail} />
    ),
    getCellValue: (row): number => row.statisticObject?.rateOfViewedByContactFromEmail
  },
  {
    name: 'countOfViewedByPublicLink',
    width: 168,
    title: '公開用URLの閲覧者数',
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <CountOfViewedByPublicLinkCell value={row.statisticObject?.countOfViewedByPublicLink} />
    ),
    getCellValue: (row): number => row.statisticObject?.countOfViewedByPublicLink
  },
  {
    name: 'secondOfAverageBrowsingTime',
    width: 168,
    title: '一人あたりの閲覧時間',
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <SecondOfAverageBrowsingTimeCell value={row.statisticObject?.secondOfAverageBrowsingTime} />
    ),
    getCellValue: (row): number => row.statisticObject?.secondOfAverageBrowsingTime
  },
  {
    name: 'countOfOpportunity',
    width: 168,
    title: 'アポイントのクリック数',
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <CountOfOpportunityCell value={row.statisticObject?.countOfOpportunity} />
    ),
    getCellValue: (row): number => row.statisticObject?.countOfOpportunity
  },
  {
    name: 'rateOfOpportunity',
    width: 168,
    title: 'アポイントのクリック率',
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <RateOfOpportunityCell value={row.statisticObject?.rateOfOpportunity} />
    ),
    getCellValue: (row): number => row.statisticObject?.rateOfOpportunity
  },
  {
    name: 'countOfCta',
    width: 168,
    title: 'CTAのクリック数',
    sortingEnabled: true,
    render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
      <CountOfCtaCell value={row.statisticObject?.countOfCta} />
    ),
    getCellValue: (row): number => row.statisticObject?.countOfCta
  }
]

const useStyles = makeStyles(() =>
  createStyles({
    DocumentTable: {
      '& .MuiTableCell-body': {
        height: '52px'
      }
    }
  })
)

export const PageDocument = ({ mode }: { mode: 'normal' | 'archive' }) => {
  const classes = useStyles()
  const { page, per, totalPages, setTotalPages, changePage, changePerPage, resetPagination } =
    useTablePagination('document')
  const router = useRouter()
  const currentPage = router.asPath

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isOpenModalFolderCreate, setIsOpenModalFolderCreate] = useState<boolean>(false)
  const [isOpenModalSelectTableColumn, setIsOpenModalSelectTableColumn] = useState(false)
  const [storagedColumns, setStoragedColumns] = useState<ModalSelectTableColumnOption[]>([])
  const [reloading] = useState(false)

  // MEMO: テーブル再描画用のステート
  const [rerendering, setRerendering] = useState(false)

  const [DocumentName, params] = useMemo(() => {
    const params: UserV1DocumentsGetRequest = {
      page: page + 1,
      per,
      isArchived: mode === 'archive' ? true : false,
      orderBy: 'updated_at-desc'
    }
    const DocumentName = mode === 'archive' ? 'すべて' : 'アーカイブ'
    return [DocumentName, params]
  }, [mode, page, per])

  const { data: org } = useGetOrganization()
  const { data: listResponse, mutate } = useListDocuments(params)
  const documentList = listResponse?.documents
  const { data: filterData } = useListDocumentFilter()
  const countNormal = filterData?.filter?.normal?.counts ?? 0
  const countArchive = filterData?.filter?.archived?.counts ?? 0

  const folderList = {
    normal: { path: '/documents', count: countNormal },
    archive: {
      path: '/documents?mode=archive',
      count: countArchive
    }
  }

  const [selection, setSelection] = useState<Array<number>>([])
  const selectedIds = useMemo<string[]>(() => {
    return selection.map(rowNumber => documentList?.[rowNumber]?.id as string)
  }, [selection, documentList])

  const [confirmOpenModal, setConfirmOpenModal] = useState(false)

  const handleConfirmCreate = useCallback((name: string) => {
    alert(name)
  }, [])
  const handleClose = useCallback(() => {
    setIsOpenModal(false)
  }, [])
  const handleCloseModalFolderCreate = useCallback(() => {
    setIsOpenModalFolderCreate(false)
  }, [])
  const { handleSaveArchived, isLoading: isLoadingArchiveDocument } = useArchiveDocument(params)
  const handleClickArchiveCloseButton = useCallback(() => {
    setSelection([])
  }, [])
  const handleCloseModal = useCallback(() => {
    setConfirmOpenModal(false)
    setSelection([])
  }, [])
  const handleClickModalOkButton = useCallback(
    (isArchived: boolean) => () => {
      if (!selectedIds || selectedIds.length == 0) {
        return
      }
      handleSaveArchived(selectedIds, isArchived)
      handleCloseModal()
    },
    [selectedIds, handleSaveArchived, handleCloseModal]
  )

  const customButtonList: CustomButtonListType[] = useMemo(() => {
    if (mode === 'archive') {
      return [{ buttonTitle: 'アーカイブから戻す', onConfirm: () => setConfirmOpenModal(true) }]
    } else;
    return [{ showIcon: 'archive', buttonTitle: 'アーカイブ', onConfirm: () => setConfirmOpenModal(true) }]
  }, [mode])

  const showDialog = useMemo(() => {
    return (
      <Box position="absolute" bottom="50px" px="16px" width={1} zIndex={999}>
        <SelectTableRow
          count={selectedIds.length}
          onClose={handleClickArchiveCloseButton}
          customButtonList={customButtonList}
        />
      </Box>
    )
  }, [selectedIds, handleClickArchiveCloseButton, customButtonList])

  const isShowTable = useMemo(
    () => !reloading && !isLoadingArchiveDocument && storagedColumns.length > 0 && columns.length > 0,
    [reloading, columns.length, storagedColumns.length, isLoadingArchiveDocument]
  )

  // MEMO: @snuffy ページマウント時に意図的に再取得してあげる(revalidate を防ぐため)
  useEffect(() => {
    mutate()
  }, [mutate])

  useEffect(() => {
    resetPagination()
    mutate()
  }, [mode, mutate, resetPagination])

  useEffect(() => {
    if (listResponse?.pageInfo?.totalPages && listResponse?.pageInfo?.totalPages !== totalPages) {
      setTotalPages(listResponse?.pageInfo?.totalPages)
    }
  }, [listResponse?.pageInfo?.totalPages, setTotalPages, totalPages])

  useEffect(() => {
    if (!org?.organization?.id) {
      return
    }
    const res = localStorageUtils.get<ModalSelectTableColumnOption[]>(
      StorageKey.DOCUMENT_TABLE_COLUMNS(org.organization.id)
    )
    if (res) {
      const newStoragedColumns = columns
        // MEMO: local に保存したデータにあれば取得、なければ column から生成を行って表示する
        .map(columnItem => {
          const item = res.find(localItem => columnItem.name === localItem.name)
          return item || { ...columnItem, checked: true }
        })
        // MEMO: local に保存した順番に並び替え
        .sort((a, b) => {
          const aIndex = res.findIndex(item => item.name === a.name)
          const bIndex = res.findIndex(item => item.name === b.name)
          if (aIndex === -1 || bIndex === -1) return 0
          return aIndex - bIndex
        })

      setStoragedColumns(newStoragedColumns)
    } else {
      setStoragedColumns(columns.map(column => ({ ...column, checked: true })))
    }
  }, [columns, org?.organization?.id])

  const onConfirmModalSelectTableColumn = useCallback(
    (result: ModalSelectTableColumnOption[]) => {
      if (org?.organization?.id) {
        localStorageUtils.set(StorageKey.DOCUMENT_TABLE_COLUMNS(org.organization.id), result)
      }

      // MEMO: テーブルのカラムが増える場合 runtime error を起こす場合があるので、table の再描画前に一度消す
      setRerendering(true)
      setStoragedColumns(result)
      setTimeout(() => {
        setRerendering(false)
      }, 32)
    },
    [org?.organization?.id]
  )

  const handleUpdateTableColumnWidth = useCallback(
    (nextColumnWidths: TableColumnWidthInfo[]) => {
      if (org?.organization?.id) {
        const next: ModalSelectTableColumnOption[] = storagedColumns.map(item => {
          const find = nextColumnWidths.find(i => i.columnName === item.name)
          if (find) {
            return { ...item, width: Number(find.width) }
          }
          return item
        })
        setStoragedColumns(next)
        localStorageUtils.set(StorageKey.DOCUMENT_TABLE_COLUMNS(org.organization.id), next)
      }
    },
    [org?.organization?.id, storagedColumns]
  )

  const { hasPermission: isCreatable } = useFeatureFlag('document', 'create')

  return (
    <>
      <Layout>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box bgcolor={Colors.functional.background.default} borderBottom={`1px solid ${Colors.background.silver}`}>
            <Header
              iconSize="28px"
              icon="document"
              title="資料"
              rightSection={
                <Button
                  title="資料の登録"
                  startIcon={<DynamicMuiIcon variant="add" />}
                  onClick={() => isCreatable && setIsOpenModal(true)}
                  disabled={!isCreatable}
                />
              }
            />
          </Box>
          <Box height="100%" flexGrow={1} display="flex" overflow="hidden">
            <Box
              flexShrink={0}
              width="174px"
              bgcolor={Colors.functional.background.default}
              height={1}
              borderRight={`1px solid ${Colors.background.silver}`}
            >
              <SubSideFolderList folderList={folderList} currentPage={currentPage} />
            </Box>
            <Box position="relative" width={1} height={1} pt="16px" overflow="auto" className={classes.DocumentTable}>
              {!rerendering && isShowTable && (
                <DynamicTable
                  columns={storagedColumns
                    .filter(item => item.checked)
                    .map(({ checked: _checked, name, width }) => {
                      return { ...columns.find(item => item.name === name), width } as TableColumn
                    })
                    .filter(Boolean)}
                  rows={documentList || []}
                  onClickRow={row => router.push('/documents/[documentId]', `/documents/${row.id}`)}
                  selectable
                  selection={selection}
                  setSelection={setSelection as TableProps<SelectionStateProps>['setSelection']}
                  page={page}
                  per={per}
                  totalPages={totalPages}
                  changePage={page => {
                    changePage(page)
                    setSelection([])
                  }}
                  changePerPage={perPage => {
                    changePerPage(perPage)
                    setSelection([])
                  }}
                  numberOfRows={listResponse?.pageInfo?.totalCount}
                  showTotalCount
                  onOpenModalSelectTableColumn={() => setIsOpenModalSelectTableColumn(true)}
                  handleUpdateTableColumnWidth={handleUpdateTableColumnWidth}
                  droppable
                />
              )}
              {selectedIds.length > 0 && showDialog}
            </Box>
          </Box>
        </Box>
        <ModalDocumentRegister open={isOpenModal} onConfirm={() => {}} onClose={handleClose} onCancel={handleClose} />
        {/* NOTE: 現状未使用だが、フォルダ実装の時に使用 */}
        <ModalFolderCreate
          open={isOpenModalFolderCreate}
          onClose={handleCloseModalFolderCreate}
          onCancel={handleCloseModalFolderCreate}
          onConfirm={handleConfirmCreate}
        />
        <PopupTemplate
          open={confirmOpenModal}
          title={`資料を${customButtonList[0].buttonTitle}`}
          subTitle={`${selectedIds.length}件  選択中`}
          onClose={handleCloseModal}
          hasClose
          hasCancel
          hasConfirm
          onConfirm={handleClickModalOkButton(mode === 'archive')}
          cancelButtonLabel="キャンセル"
          confirmButtonLabel="OK"
        >
          <Box p="24px">
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {`選択した資料を${DocumentName}へ移動します\n本当によろしいですか？`}
            </Typography>
          </Box>
        </PopupTemplate>
      </Layout>
      <ModalSelectTableColumn
        initialColumnItems={storagedColumns}
        open={isOpenModalSelectTableColumn}
        onClose={() => setIsOpenModalSelectTableColumn(false)}
        onCancel={() => setIsOpenModalSelectTableColumn(false)}
        onConfirm={onConfirmModalSelectTableColumn}
        hideSettings
      />
    </>
  )
}
