import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { Colors } from 'src/components/atoms'
import { TrialBanner, TRIAL_BANNER_HEIGHT } from 'src/components/molecules'
import { SideMenu, MIN_SIDE_MENU_SIZE } from 'src/components/organisms'
import { useGetOrganizationStatus } from 'src/fixtures/modules/organization/hooks'

export interface LayoutProps {
  children: ReactNode
}

export const Layout: React.VFC<LayoutProps> = ({ children }) => {
  const { isTrialing, organization } = useGetOrganizationStatus()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isTrialing && <TrialBanner trialEndAt={organization?.trialEndAt ?? undefined} />}
      <Box
        bgcolor={Colors.background.lightGray}
        width="100vw"
        height="100%"
        display="flex"
        position="relative"
        style={{ overflowX: 'hidden' }}
      >
        <Box
          position="fixed"
          height="100%"
          top={0}
          left={0}
          zIndex={1001}
          pt={isTrialing ? `${TRIAL_BANNER_HEIGHT}px` : 0}
        >
          <SideMenu />
        </Box>
        <Box width={MIN_SIDE_MENU_SIZE} flex={'1 0 auto'} />
        <Box width={`calc(100% - ${MIN_SIDE_MENU_SIZE}px)`} height="100%">
          {children}
        </Box>
      </Box>
    </Box>
  )
}
