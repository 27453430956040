/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ResetPasswordResponseDataFromJSON, ResetPasswordResponseDataToJSON } from './ResetPasswordResponseData';
export function ResetPasswordResponseFromJSON(json) {
    return ResetPasswordResponseFromJSONTyped(json, false);
}
export function ResetPasswordResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        status: !exists(json, 'status') ? undefined : json['status'],
        data: !exists(json, 'data') ? undefined : ResetPasswordResponseDataFromJSON(json['data'])
    };
}
export function ResetPasswordResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        status: value.status,
        data: ResetPasswordResponseDataToJSON(value.data)
    };
}
