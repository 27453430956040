import React from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { ClientTimeLineItem } from 'src/components/molecules/ClientTimeLineItem'
import { useDiscardTimeLineMemo, useUpdateTimeLineMemo } from 'src/fixtures/modules/contact/hooks'
import { ContactTimeline } from '@noco/http-client/lib/noco'
import { Colors, Tooltip, Typography } from 'src/components/atoms'

const useStyles = makeStyles(() =>
  createStyles({
    viewMore: {
      cursor: 'pointer'
    }
  })
)

export interface ModalClientDetailProps {
  contactId: string
  item: ContactTimeline
  onViewMoreTimeLine: () => void
}

export const LatestTimeLine = ({ contactId, item, onViewMoreTimeLine }: ModalClientDetailProps) => {
  const { handleDiscardTimeLineMemo } = useDiscardTimeLineMemo(contactId)
  const { handleUpdateTimeLineMemo } = useUpdateTimeLineMemo(contactId)
  const classes = useStyles()
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
        <Tooltip
          content={`顧客の最新の行動履歴を表示します。すべての行動履歴を確認するには、「もっと見る」から「タイムライン」を表示してください。`}
        >
          <Typography variant="h5">最新タイムライン</Typography>
        </Tooltip>

        <Box className={classes.viewMore} onClick={onViewMoreTimeLine} color={Colors.accent.keyBlue.default}>
          <Typography fontSize="s" lineheight="14px" fontWeight="bold">
            もっと見る
          </Typography>
        </Box>
      </Box>

      <Box>
        <ClientTimeLineItem
          item={item}
          onDelete={handleDiscardTimeLineMemo}
          onEdit={handleUpdateTimeLineMemo}
          type="contact"
          size="small"
        />
      </Box>
    </Box>
  )
}
