/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from './User'

/**
 *
 * @export
 * @interface GetMeResponseData
 */
export interface GetMeResponseData {
  /**
   *
   * @type {User}
   * @memberof GetMeResponseData
   */
  user?: User
}

export function GetMeResponseDataFromJSON(json: any): GetMeResponseData {
  return GetMeResponseDataFromJSONTyped(json, false)
}

export function GetMeResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMeResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    user: !exists(json, 'user') ? undefined : UserFromJSON(json['user'])
  }
}

export function GetMeResponseDataToJSON(value?: GetMeResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user: UserToJSON(value.user)
  }
}
