/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetEuOrganizationChatSettingResponseData,
  GetEuOrganizationChatSettingResponseDataFromJSON,
  GetEuOrganizationChatSettingResponseDataFromJSONTyped,
  GetEuOrganizationChatSettingResponseDataToJSON
} from './GetEuOrganizationChatSettingResponseData'

/**
 *
 * @export
 * @interface GetEuOrganizationChatSettingResponse
 */
export interface GetEuOrganizationChatSettingResponse {
  /**
   *
   * @type {number}
   * @memberof GetEuOrganizationChatSettingResponse
   */
  status?: number
  /**
   *
   * @type {GetEuOrganizationChatSettingResponseData}
   * @memberof GetEuOrganizationChatSettingResponse
   */
  data?: GetEuOrganizationChatSettingResponseData
}

export function GetEuOrganizationChatSettingResponseFromJSON(json: any): GetEuOrganizationChatSettingResponse {
  return GetEuOrganizationChatSettingResponseFromJSONTyped(json, false)
}

export function GetEuOrganizationChatSettingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEuOrganizationChatSettingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetEuOrganizationChatSettingResponseDataFromJSON(json['data'])
  }
}

export function GetEuOrganizationChatSettingResponseToJSON(value?: GetEuOrganizationChatSettingResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetEuOrganizationChatSettingResponseDataToJSON(value.data)
  }
}
