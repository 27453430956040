/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  FilterStaffPins,
  FilterStaffPinsFromJSON,
  FilterStaffPinsFromJSONTyped,
  FilterStaffPinsToJSON
} from './FilterStaffPins'

/**
 *
 * @export
 * @interface FilterStaff
 */
export interface FilterStaff {
  /**
   *
   * @type {Array<FilterStaffPins>}
   * @memberof FilterStaff
   */
  pins?: Array<FilterStaffPins>
  /**
   *
   * @type {number}
   * @memberof FilterStaff
   */
  sizeAll?: number
}

export function FilterStaffFromJSON(json: any): FilterStaff {
  return FilterStaffFromJSONTyped(json, false)
}

export function FilterStaffFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterStaff {
  if (json === undefined || json === null) {
    return json
  }
  return {
    pins: !exists(json, 'pins') ? undefined : (json['pins'] as Array<any>).map(FilterStaffPinsFromJSON),
    sizeAll: !exists(json, 'size_all') ? undefined : json['size_all']
  }
}

export function FilterStaffToJSON(value?: FilterStaff | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    pins: value.pins === undefined ? undefined : (value.pins as Array<any>).map(FilterStaffPinsToJSON),
    size_all: value.sizeAll
  }
}
