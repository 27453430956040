import { AppBar, Box, createStyles, makeStyles, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { SiteContact } from '@noco/http-client/lib/noco'
import { useRouter } from 'next/router'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { Colors, Tooltip } from 'src/components/atoms'
import { Table, TableProps } from 'src/components/organisms'
import dynamic from 'next/dynamic'
const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

type TabValueType = 'contact' | 'analytics'

export type FolderContentProps = TableProps<SiteContact>

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.functional.background.default,
      boxShadow: 'none',
      borderBottom: `1px solid ${Colors.background.gray}`,
      zIndex: 1
    },
    scrollableTabPanel: {
      height: '100%',
      backgroundColor: `${Colors.background.lightGray}`,
      padding: '16px 0 0 0'
    },
    tablist: {
      padding: '0 16px',
      minHeight: 'auto !important',
      flex: '1 0 auto'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      minWidth: '74px',
      padding: '9px 16px',
      borderRadius: '6px 6px 0px 0px',
      color: Colors.base.middleGray,
      '&.Mui-selected': {
        color: Colors.accent.keyPurple.default,
        background: Colors.selected.background
      }
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

export const FolderContent = ({ columns, rows, numberOfRows }: FolderContentProps) => {
  const [value, setValue] = useState<TabValueType>('contact')

  const handleChange = useCallback((event: ChangeEvent<{}>, newValue: TabValueType) => {
    setValue(newValue)
  }, [])

  const classes = useStyles()

  const router = useRouter()
  const handleClickRow = useCallback(
    (id: string) => {
      router.push('/contacts/[id]', `/contacts/${id}`)
    },
    [router]
  )

  return (
    <TabContext value={value}>
      <AppBar position="relative" className={classes.root}>
        <TabList
          onChange={handleChange}
          aria-label="company add tablist"
          classes={{ root: classes.tablist }}
          indicatorColor="primary"
        >
          <Tab
            value="contact"
            label={
              <Tooltip content={' 資料サイトを共有した顧客を表示します。'}>
                <Typography variant="h5">連絡先</Typography>
              </Tooltip>
            }
            classes={{ root: classes.tab }}
            {...a11yProps(0)}
          />
          {/* MEMO: @snuffy 正式版以降で分析タブは出現する */}
          {/* <Tab
            value="analytics"
            label={<Typography variant="h5">分析</Typography>}
            classes={{ root: classes.tab }}
            {...a11yProps(1)}
          /> */}
        </TabList>
      </AppBar>
      <Box height="100%" flexGrow={1} overflow="auto">
        <TabPanel value="contact" classes={{ root: classes.scrollableTabPanel }}>
          <Box height="100%">
            <DynamicTable
              columns={columns}
              rows={rows}
              onClickRow={row => handleClickRow(row.contactId!)}
              numberOfRows={numberOfRows}
            />
          </Box>
        </TabPanel>
        <TabPanel value="analytics">analytics</TabPanel>
      </Box>
    </TabContext>
  )
}
