/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  FeaturePermitSetting,
  FeaturePermitSettingFromJSON,
  FeaturePermitSettingFromJSONTyped,
  FeaturePermitSettingToJSON
} from './FeaturePermitSetting'
import {
  OrganizationPaymentPlanBasic,
  OrganizationPaymentPlanBasicFromJSON,
  OrganizationPaymentPlanBasicFromJSONTyped,
  OrganizationPaymentPlanBasicToJSON
} from './OrganizationPaymentPlanBasic'
import {
  OrganizationPlanBasicAllOf,
  OrganizationPlanBasicAllOfFromJSON,
  OrganizationPlanBasicAllOfFromJSONTyped,
  OrganizationPlanBasicAllOfToJSON
} from './OrganizationPlanBasicAllOf'

/**
 *
 * @export
 * @interface OrganizationPlanBasic
 */
export interface OrganizationPlanBasic {
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanBasic
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanBasic
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  trial: boolean
  /**
   * 有料かどうか
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  paid: boolean
  /**
   * 連絡先のインポート
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canImportContact: boolean
  /**
   * 連絡先のエクスポート
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canExportContact: boolean
  /**
   * 会社のインポート
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canImportCompany: boolean
  /**
   * 会社のエクスポート
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canExportCompany: boolean
  /**
   * プランにバンドルされたメールテープレート登録が有限か
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  emailTemplateFinite: boolean
  /**
   * プランにバンドルされたメールテープレート登録上限
   * @type {number}
   * @memberof OrganizationPlanBasic
   */
  emailTemplateQuantity: number | null
  /**
   * トリガーメール
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canUseTriggerEmail: boolean
  /**
   * 資料ファイルのダウンロード・印刷設定
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canDownloadMaterial: boolean
  /**
   * 資料の公開期限
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canSetMaterialPublicationDue: boolean
  /**
   * 広告の非表示
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canHideAd: boolean
  /**
   * ユーザー権限管理
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canManageUserPermission: boolean
  /**
   * 60日以上前のチャット閲覧許可
   * @type {boolean}
   * @memberof OrganizationPlanBasic
   */
  canViewChatsOlderThan60Days: boolean
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanBasic
   */
  grade: OrganizationPlanBasicGradeEnum
  /**
   *
   * @type {number}
   * @memberof OrganizationPlanBasic
   */
  gradeAsNumber: number
  /**
   * プランにバンドルされたユーザー登録上限
   * @type {number}
   * @memberof OrganizationPlanBasic
   */
  userQuantity: number
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanBasic
   */
  stripePriceId: string
}

/**
 * @export
 */
export const OrganizationPlanBasicGradeEnum = {
  Trial: 'trial',
  Free: 'free',
  Entry: 'entry',
  Standard: 'standard',
  Light: 'light',
  StandardNew: 'standard_new',
  Premium: 'premium',
  FreePremium: 'free_premium'
} as const
export type OrganizationPlanBasicGradeEnum =
  typeof OrganizationPlanBasicGradeEnum[keyof typeof OrganizationPlanBasicGradeEnum]

export function OrganizationPlanBasicFromJSON(json: any): OrganizationPlanBasic {
  return OrganizationPlanBasicFromJSONTyped(json, false)
}

export function OrganizationPlanBasicFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPlanBasic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    trial: json['trial'],
    paid: json['paid'],
    canImportContact: json['can_import_contact'],
    canExportContact: json['can_export_contact'],
    canImportCompany: json['can_import_company'],
    canExportCompany: json['can_export_company'],
    emailTemplateFinite: json['email_template_finite'],
    emailTemplateQuantity: json['email_template_quantity'],
    canUseTriggerEmail: json['can_use_trigger_email'],
    canDownloadMaterial: json['can_download_material'],
    canSetMaterialPublicationDue: json['can_set_material_publication_due'],
    canHideAd: json['can_hide_ad'],
    canManageUserPermission: json['can_manage_user_permission'],
    canViewChatsOlderThan60Days: json['can_view_chats_older_than_60_days'],
    grade: json['grade'],
    gradeAsNumber: json['grade_as_number'],
    userQuantity: json['user_quantity'],
    stripePriceId: json['stripe_price_id']
  }
}

export function OrganizationPlanBasicToJSON(value?: OrganizationPlanBasic | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    trial: value.trial,
    paid: value.paid,
    can_import_contact: value.canImportContact,
    can_export_contact: value.canExportContact,
    can_import_company: value.canImportCompany,
    can_export_company: value.canExportCompany,
    email_template_finite: value.emailTemplateFinite,
    email_template_quantity: value.emailTemplateQuantity,
    can_use_trigger_email: value.canUseTriggerEmail,
    can_download_material: value.canDownloadMaterial,
    can_set_material_publication_due: value.canSetMaterialPublicationDue,
    can_hide_ad: value.canHideAd,
    can_manage_user_permission: value.canManageUserPermission,
    can_view_chats_older_than_60_days: value.canViewChatsOlderThan60Days,
    grade: value.grade,
    grade_as_number: value.gradeAsNumber,
    user_quantity: value.userQuantity,
    stripe_price_id: value.stripePriceId
  }
}
