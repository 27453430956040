import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DocumentShareWithEmail } from './DocumentShareWithEmail'
import { DocumentShareWithLink } from './DocumentShareWithLink'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { AppBar, Box, createStyles, makeStyles, Modal, Slide, Tab, Typography } from '@material-ui/core'
import { Colors, DynamicMuiIcon, SvgIcon, Tooltip } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { useGetDocument } from 'src/fixtures/modules/documents/hooks'
import { pushUserActionDataLayer } from 'src/utils/gtm'
import { useFormSubmitter } from './hooks'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.background.gray,
      boxShadow: 'none'
    },
    tablist: {
      minHeight: 'auto !important'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      padding: '13px 0px',
      border: `1px solid ${Colors.background.gray}`,
      color: Colors.base.middleGray
    },
    selectedTab: {
      color: Colors.accent.keyPurple.default,
      background: Colors.functional.background.default,
      border: '1px solid transparent'
    },
    tabsIndicator: {
      display: 'none'
    },
    tabPanel: {
      padding: '24px !important',
      height: '100%'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

type TabValueType = 'select' | 'all'

interface ModalDocumentShareProps {
  documentId: string
  open: boolean
  onClose: () => void
  onCancel: () => void
  setInstructions?: React.Dispatch<React.SetStateAction<string[] | undefined>>
}

export const ModalDocumentShare = ({
  documentId,
  open,
  onClose,
  onCancel,
  setInstructions
}: ModalDocumentShareProps) => {
  const classes = useStyles()
  const [value, setValue] = useState<TabValueType>('all')
  const { data: document, mutate } = useGetDocument(documentId)
  const [showConfirm, setShowConfirm] = useState(false)
  const formSubmitter = useFormSubmitter()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitEnd, setIsSubmitEnd] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [sendStatus, setSendStatus] = useState(false)
  const [progressCompleted, setProgressCompleted] = useState(false)

  const handleChangeTab = useCallback(
    (event: ChangeEvent<{}>, newValue: TabValueType) => {
      if (!showConfirm || (showConfirm && window.confirm('メールがまだ送信されていません。画面を離れますか？'))) {
        setValue(newValue)
        setShowConfirm(false)
        return
      }
    },
    [showConfirm]
  )
  const selectRef = useRef<HTMLButtonElement>(null)
  const shareTabRef = useRef<HTMLButtonElement>(null)

  const handleConfirm = useCallback(() => {
    if (value === 'all') shareTabRef.current?.click()
    if (value === 'select') selectRef.current?.click()
  }, [value])

  useEffect(() => {
    if (isSubmitting) {
      // select タブ上での handleConfirm 処理を メール送信 として計測する
      if (value === 'select') pushUserActionDataLayer('document', 'send_email')
    }
  }, [isSubmitting, setIsSubmitting, value])

  useEffect(() => {
    if ((!isSubmitting && value === 'select') || value !== 'select') return
    setIsSending(true)
  }, [isSubmitting, value])

  const handleSubmit = useCallback(() => {
    if (value === 'select') {
      setIsSubmitting(true)
    }
    setProgressCompleted(false)
    mutate().then(() => {
      setIsSubmitting(false)
      setSendStatus(false)
    })
  }, [mutate, value])

  const handleClose = useCallback(() => {
    if (isSending) return
    if (!showConfirm || (showConfirm && window.confirm('メールがまだ送信されていません。画面を離れますか？'))) {
      onClose()
      setProgressCompleted(false)
      return
    }
  }, [isSending, onClose, showConfirm])

  const buttonTitle = useMemo(() => {
    if (value === 'select') {
      if (isSubmitting) return '送信中'
      return 'メール送信'
    }
  }, [value, isSubmitting])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="764px" height={1}>
          <SlideForm
            buttonTitle={buttonTitle}
            headerTitle="資料を共有"
            cancelButtonTitle={value === 'all' ? '閉じる' : 'キャンセル'}
            hideSettings
            onClose={handleClose}
            onCancel={() => {
              onCancel()
              setShowConfirm(false)
            }}
            onConfirm={handleConfirm}
            isSubmitting={isSubmitting}
            hideSubmitButton={value === 'all'}
            buttonDisabled={formSubmitter.isDisabled}
            isSubmitEnd={isSubmitEnd}
            clearSubmitEnd={() => setIsSubmitEnd(false)}
            isSending={isSending}
            progressCompleted={progressCompleted}
            submitStatus={sendStatus}
          >
            <Box
              bgcolor={Colors.functional.background.default}
              height={1}
              display="flex"
              flexDirection="column"
              overflow="hidden"
            >
              <TabContext value={value}>
                {!isSending && !progressCompleted && (
                  <AppBar position="relative" className={classes.root}>
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="document share tablist"
                      classes={{ root: classes.tablist, indicator: classes.tabsIndicator }}
                      variant="fullWidth"
                    >
                      <Tab
                        value="select"
                        label={
                          <Box display="flex" alignItems="center">
                            <SvgIcon
                              variant="avatar"
                              size="18px"
                              color={value === 'select' ? Colors.accent.keyPurple.default : Colors.base.middleGray}
                            />
                            <Box ml="5px" />
                            <Tooltip
                              content={`選択した顧客だけが閲覧できる共有リンクを作成し、メール機能で資料を共有できます。

                共有された本人だけが閲覧でき、それ以外の方は閲覧できません。`}
                            >
                              <Typography variant="h5">連絡先を選択してメールで共有</Typography>
                            </Tooltip>
                          </Box>
                        }
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        value="all"
                        label={
                          <Box display="flex" alignItems="center">
                            <DynamicMuiIcon variant="link" size="18px" color="inherit" />
                            <Box ml="5px" />
                            <Tooltip content={`誰でも閲覧可能な共有リンクを作成できます。`}>
                              <Typography variant="h5">公開用URLを作成して共有</Typography>
                            </Tooltip>
                          </Box>
                        }
                        classes={{ root: classes.tab, selected: classes.selectedTab }}
                        {...a11yProps(1)}
                      />
                    </TabList>
                  </AppBar>
                )}
                <Box display="flex" flexDirection="column" height="100%" overflow="hidden auto">
                  <TabPanel classes={{ root: classes.tabPanel }} value="select">
                    {document && (
                      <DocumentShareWithEmail
                        ref={selectRef}
                        defaultDocument={document}
                        formSubmitter={formSubmitter}
                        onError={() => setIsSubmitting(false)}
                        onChangeShowConfirm={showConfirm => setShowConfirm(showConfirm)}
                        setIsSubmitting={setIsSubmitting}
                        isSubmitting={isSubmitting}
                        isSubmitEnd={isSubmitEnd}
                        setIsSubmitEnd={setIsSubmitEnd}
                        onSubmit={() => setIsSubmitting(true)}
                        isSending={isSending}
                        setIsSending={setIsSending}
                        progressCompleted={progressCompleted}
                        setProgressCompleted={setProgressCompleted}
                        setSubmitStatus={setSendStatus}
                      />
                    )}
                  </TabPanel>
                  <TabPanel classes={{ root: classes.tabPanel }} value="all">
                    {document && (
                      <DocumentShareWithLink
                        ref={shareTabRef}
                        document={document}
                        onSubmit={handleSubmit}
                        onError={() => setIsSubmitting(false)}
                        setInstructions={setInstructions}
                      />
                    )}
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
