/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { HowDidYouKnowEnumFromJSON, HowDidYouKnowEnumToJSON } from './HowDidYouKnowEnum';
import { OrganizationPaymentCardInfoFromJSON, OrganizationPaymentCardInfoToJSON } from './OrganizationPaymentCardInfo';
export function OrganizationPaymentContractPaidInfoFromJSON(json) {
    return OrganizationPaymentContractPaidInfoFromJSONTyped(json, false);
}
export function OrganizationPaymentContractPaidInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        stripePaymentMethodId: !exists(json, 'stripe_payment_method_id') ? undefined : json['stripe_payment_method_id'],
        card: !exists(json, 'card') ? undefined : OrganizationPaymentCardInfoFromJSON(json['card']),
        billingEmail: !exists(json, 'billing_email') ? undefined : json['billing_email'],
        howDidYouKnow: !exists(json, 'how_did_you_know') ? undefined : HowDidYouKnowEnumFromJSON(json['how_did_you_know']),
        consent: !exists(json, 'consent') ? undefined : json['consent']
    };
}
export function OrganizationPaymentContractPaidInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        stripe_payment_method_id: value.stripePaymentMethodId,
        card: OrganizationPaymentCardInfoToJSON(value.card),
        billing_email: value.billingEmail,
        how_did_you_know: HowDidYouKnowEnumToJSON(value.howDidYouKnow),
        consent: value.consent
    };
}
