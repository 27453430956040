import React, { useEffect, useState, useCallback } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Toaster } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { useRouter } from 'next/router'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'

import { useCreateContact } from 'src/fixtures/modules/contact/hooks'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import {
  ContactSettingValueMap,
  convertFromContactSettingValueMap,
  initializeContactSettingValueMap
} from 'src/fixtures/modules/contact/utils'
import { ContactCreateForm, ContactCreateFormChangeValueProps } from 'src/components/molecules'
import { removeFreeCompanyFromContactSettings } from 'src/fixtures/modules/contactSetting/utils'
import { UserV1ContactsGetRequest } from '@noco/http-client/lib/noco'
import { useConfirmDiscard } from 'src/fixtures/utils/url'

interface ModalContactRegisterProps {
  open: boolean
  companyId?: string
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
  params: UserV1ContactsGetRequest
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
)

export const ModalContactRegister = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  companyId,
  params
}: ModalContactRegisterProps) => {
  const classes = useStyles()
  const router = useRouter()
  const { data: me } = useGetMe()
  const { data: responseSetting } = useListContactSettings()
  const activeSettings = responseSetting?.activeContactSettings
  const [settingValues, setSettingValues] = useState<ContactSettingValueMap>({})
  const [isSubitting, setIsSubmitting] = useState(false)
  const { handleCreateContact, isLoading, error } = useCreateContact(params)
  const [userId, setUserId] = useState<string | undefined>(me?.user?.id!)
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm)

  const handleResetForm = useCallback(() => {
    const initialSettingValueMap = initializeContactSettingValueMap(
      removeFreeCompanyFromContactSettings(activeSettings!) || []
    )
    setSettingValues(initialSettingValueMap)
  }, [activeSettings])

  const handleChangeUser = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setUserId(e.target.value as string)
    setShowConfirm(true)
  }, [])

  const handleUpdateValue = useCallback(
    ({ settingId, fieldId, value }: ContactCreateFormChangeValueProps) => {
      const newValueMap = { ...settingValues }
      newValueMap[settingId][fieldId] = value
      setSettingValues(newValueMap)
      setShowConfirm(true)
    },
    [settingValues]
  )

  // 連絡先作成
  const handleCreate = useCallback(() => {
    if (!me) return Toaster.error('ユーザーがありません')
    if (!userId) return Toaster.error('担当者を選択してください')
    const contactSettingFieldValues = convertFromContactSettingValueMap(settingValues)
    handleCreateContact(
      {
        contact: {
          userId,
          contactSettingFieldValues
        }
      },
      companyId
    )
    setIsSubmitting(true)
    setShowConfirm(false)
  }, [companyId, handleCreateContact, me, settingValues, userId])

  // 画面をクローズする前に confirm する対応
  const handleClose = useCallback(() => {
    if (showConfirm && window.confirm('変更がまだ保存されていません。画面を離れますか？')) {
      onClose()
      setShowConfirm(false)
      return
    }

    if (!showConfirm) {
      onClose()
      setShowConfirm(false)
    }
  }, [showConfirm, onClose])

  // MEMO: (@snuffy) 連絡先作成が完了した場合に此処が呼ばれる
  useEffect(() => {
    if (isSubitting && !isLoading && !error) {
      onConfirm()
      setIsSubmitting(false)
      handleResetForm()
    }
  }, [isSubitting, isLoading, error, onConfirm, handleResetForm])

  useEffect(() => {
    if (me?.user?.id) {
      setUserId(me?.user?.id)
    }
  }, [me?.user?.id])

  useEffect(() => {
    if (me) setUserId(me?.user?.id!)
  }, [me])

  // 初期化
  useEffect(() => {
    handleResetForm()
  }, [handleResetForm])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="連絡先を登録"
            isSubmitting={isLoading}
            toolTipText="連絡先（顧客）の項目を自由にカスタマイズできます。"
            onClose={handleClose}
            onCancel={() => {
              onCancel()
              setShowConfirm(false)
            }}
            onConfirm={handleCreate}
            onClickSettings={() => router.push('/setting/team/contact')}
          >
            <ContactCreateForm
              userId={userId!}
              settingValues={settingValues}
              onChangeUser={handleChangeUser}
              onChangeValues={handleUpdateValue}
            />
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
