/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AppendContactListRequestToJSON, AppendContactListResponseFromJSON, CreateContactListRequestToJSON, CreateContactListResponseFromJSON, GetContactListsResponseFromJSON, PinContactListRequestToJSON, RemoveContactListRequestToJSON, RemoveContactListResponseFromJSON, UpdateContactListRequestToJSON, UpdateContactListResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ContactListApi extends runtime.BaseAPI {
    /**
     * コンタクト一覧にコンタクトを追加する
     * append
     */
    async userV1ContactListsContactListIdAppendPostRaw(requestParameters, initOverrides) {
        if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
            throw new runtime.RequiredError('contactListId', 'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdAppendPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_lists/{contact_list_id}/append`.replace(`{${'contact_list_id'}}`, encodeURIComponent(String(requestParameters.contactListId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppendContactListRequestToJSON(requestParameters.appendContactListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => AppendContactListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧にコンタクトを追加する
     * append
     */
    async userV1ContactListsContactListIdAppendPost(requestParameters, initOverrides) {
        const response = await this.userV1ContactListsContactListIdAppendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を削除する
     * destroy
     */
    async userV1ContactListsContactListIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
            throw new runtime.RequiredError('contactListId', 'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contact_lists/{contact_list_id}`.replace(`{${'contact_list_id'}}`, encodeURIComponent(String(requestParameters.contactListId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * コンタクト一覧を削除する
     * destroy
     */
    async userV1ContactListsContactListIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1ContactListsContactListIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を更新する
     * create
     */
    async userV1ContactListsContactListIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
            throw new runtime.RequiredError('contactListId', 'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_lists/{contact_list_id}`.replace(`{${'contact_list_id'}}`, encodeURIComponent(String(requestParameters.contactListId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactListRequestToJSON(requestParameters.updateContactListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateContactListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧を更新する
     * create
     */
    async userV1ContactListsContactListIdPut(requestParameters, initOverrides) {
        const response = await this.userV1ContactListsContactListIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧にコンタクトを削除する
     * remove
     */
    async userV1ContactListsContactListIdRemovePostRaw(requestParameters, initOverrides) {
        if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
            throw new runtime.RequiredError('contactListId', 'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdRemovePost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_lists/{contact_list_id}/remove`.replace(`{${'contact_list_id'}}`, encodeURIComponent(String(requestParameters.contactListId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveContactListRequestToJSON(requestParameters.removeContactListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => RemoveContactListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧にコンタクトを削除する
     * remove
     */
    async userV1ContactListsContactListIdRemovePost(requestParameters, initOverrides) {
        const response = await this.userV1ContactListsContactListIdRemovePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を取得する
     * index
     */
    async userV1ContactListsGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contact_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactListsResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧を取得する
     * index
     */
    async userV1ContactListsGet(initOverrides) {
        const response = await this.userV1ContactListsGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧にpin設定をする
     * pin
     */
    async userV1ContactListsPinPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_lists/pin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PinContactListRequestToJSON(requestParameters.pinContactListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * コンタクト一覧にpin設定をする
     * pin
     */
    async userV1ContactListsPinPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactListsPinPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を作成する
     * create
     */
    async userV1ContactListsPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactListRequestToJSON(requestParameters.createContactListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateContactListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧を作成する
     * create
     */
    async userV1ContactListsPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactListsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
