import { Box, Modal, Slide } from '@material-ui/core'
import {
  DocumentShare,
  OrganizationPolicySetting,
  RequestContactSettingFieldValue,
  SiteShare
} from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from 'src/components/atoms'
import { SettingInputRow } from 'src/components/molecules'
import { PolicySentence } from 'src/components/molecules/PolicySentence'
import {
  ContactSettingValueMap,
  convertFromContactSettingValueMap,
  initializeContactSettingValueMap
} from 'src/fixtures/modules/contact/utils'
import { VisitorAuthModalBase } from '../VisitorAuthModalBase'

interface ModalVisitorConnectToContactProps {
  policySettings?: OrganizationPolicySetting[]
  documentId?: string
  siteId?: string
  open: boolean
  onClose: () => void
  onSubmit: (param: RequestContactSettingFieldValue[] | undefined) => void
  documentShareForms?: DocumentShare['documentShareForms']
  siteShareForms?: SiteShare['siteShareForms']
  organizationName?: string
}
export const ModalVisitorConnectToContact = ({
  documentId,
  siteId,
  open,
  onClose,
  onSubmit,
  documentShareForms,
  siteShareForms,
  policySettings,
  organizationName
}: ModalVisitorConnectToContactProps) => {
  const [settingValues, setSettingValues] = useState<ContactSettingValueMap>({})
  const settingValueKeys = useMemo(() => Object.keys(settingValues), [settingValues])
  const [isInit, setIsInit] = useState(false)
  const targetForms = siteShareForms ? siteShareForms : documentShareForms

  // 初期化
  useEffect(() => {
    const targetForms = siteShareForms
      ? siteShareForms.map(item => item.contactSetting!)
      : documentShareForms?.map(item => item.contactSetting!)
    if (open && targetForms && settingValueKeys.length === 0 && !isInit) {
      const initalSettingValueMap = initializeContactSettingValueMap(targetForms, true)
      setSettingValues(initalSettingValueMap)
      setIsInit(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentShareForms, settingValues, siteId, siteShareForms, settingValueKeys.length])

  // フォームの更新
  useEffect(() => {
    const targetForms = siteShareForms
      ? siteShareForms.map(item => item.contactSetting!)
      : documentShareForms?.map(item => item.contactSetting!)

    if (settingValueKeys.length > 0 && targetForms && settingValueKeys.length !== targetForms.length) {
      const keys = settingValueKeys.filter(valueId => targetForms.some(item => item.id === valueId))
      const newSettingsValues = initializeContactSettingValueMap(targetForms, true)
      keys.forEach(key => {
        newSettingsValues[key] = settingValues[key]
      })
      setSettingValues({ newSettingsValues })
      setSettingValues(newSettingsValues)
    }
  }, [documentShareForms, settingValues, siteId, siteShareForms, settingValueKeys.length, settingValueKeys])

  // handle update
  const handleUpdateValue = useCallback(
    (settingId: string, fieldId: string, value: any) => {
      const newValueMap = { ...settingValues }
      if (!newValueMap[settingId]) newValueMap[settingId] = {}
      newValueMap[settingId][fieldId] = value
      setSettingValues(newValueMap)
    },
    [settingValues]
  )
  const handleSubmit = useCallback(() => {
    const contactSettingFieldValues = convertFromContactSettingValueMap(settingValues)
    onSubmit?.(contactSettingFieldValues)
    setIsInit(false)
  }, [onSubmit, settingValues])

  // 簡易バリデーション
  const valid = useMemo(() => {
    const settingValuesMap = settingValues
    return targetForms?.every(item => {
      if (item.isRequired) {
        const settingId = item.contactSetting?.id
        const fieldIds = item.contactSetting?.contactSettingFields?.map(item => item.id)
        if (!settingId || !fieldIds) return false

        const fieldMaps = settingValuesMap?.[settingId]
        if (!fieldMaps) return false
        return Object.keys(fieldMaps).every(key => !!fieldMaps[key])
      } else {
        return true
      }
    })
  }, [settingValues, targetForms])

  // モーダルを閉じた時に状態をリセット
  useEffect(() => {
    if (!open) {
      setIsInit(false)
      setSettingValues({})
    }
  }, [open, targetForms])

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '12px' }}
    >
      <Slide in={open} direction="up">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <VisitorAuthModalBase title="資料の閲覧には、下記ご入力ください">
            {documentId &&
              documentShareForms?.map(item => {
                const values = settingValues[item.contactSetting!.id!]
                return (
                  <Box key={item.id}>
                    <SettingInputRow
                      name={item.contactSetting?.name || 'name'}
                      kind="contact"
                      required={item.isRequired}
                      setting={item.contactSetting!}
                      values={values}
                      onChange={data => handleUpdateValue(item.contactSetting!.id!, data.fieldId, data.value)}
                      isEuView
                    />
                  </Box>
                )
              })}

            {siteId &&
              siteShareForms?.map(item => {
                const values = settingValues[item.contactSetting!.id!]
                return (
                  <Box key={item.id}>
                    <SettingInputRow
                      isEuView
                      name={item.contactSetting?.name || 'name'}
                      kind="contact"
                      required={item.isRequired}
                      setting={item.contactSetting!}
                      values={values}
                      onChange={data => handleUpdateValue(item.contactSetting!.id!, data.fieldId, data.value)}
                    />
                  </Box>
                )
              })}
            <PolicySentence policySettings={policySettings} organizationName={organizationName} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '18px' }}>
              <Button width="116px " title="閲覧する" onClick={handleSubmit} disabled={!valid} />
            </Box>
          </VisitorAuthModalBase>
        </Box>
      </Slide>
    </Modal>
  )
}
