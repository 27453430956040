/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateOrganizationRequest
 */
export interface UpdateOrganizationRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationRequest
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationRequest
   */
  organizationSubdomain?: string
}

export function UpdateOrganizationRequestFromJSON(json: any): UpdateOrganizationRequest {
  return UpdateOrganizationRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    organizationSubdomain: !exists(json, 'organization_subdomain') ? undefined : json['organization_subdomain']
  }
}

export function UpdateOrganizationRequestToJSON(value?: UpdateOrganizationRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    organization_subdomain: value.organizationSubdomain
  }
}
