/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSON(json) {
    return UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSONTyped(json, false);
}
export function UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        subjectName: !exists(json, 'subject_name') ? undefined : json['subject_name']
    };
}
export function UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        subject_name: value.subjectName
    };
}
