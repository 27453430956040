import clsx from 'clsx'
import { Box, ButtonBase, ClickAwayListener, IconButton } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Button, Colors, Pallete, SvgIcon, Typography } from 'src/components/atoms'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Document, Organization, OrganizationPolicySetting, Visitor } from '@noco/http-client/lib/noco/models'
import { CTADialog } from '../../CTADialog'
import { DocumentSlideDetailProps } from '../hooks'
import { FloatingWindowButton } from '../FloatingWindowButton'
import { ModalDocumentPageSelect } from 'src/components/modals/ModalDocumentPageSelect'

import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

import { useCallback, useEffect, useState } from 'react'
import { CONTROLLER_SHOW_TIMEOUT } from './contant'
import { LinkImage } from '../LinkImage'

type Props = DocumentSlideDetailProps & {
  open: boolean
  siteId?: string
  document: Document
  organization: Organization
  policySettings?: OrganizationPolicySetting[]
  visitor?: Visitor
  ctaPosition: {
    top: string
    left: string
    transform: string
  }
  documentShareId?: string
  siteShareId?: string
  isPreview: boolean
  slideHeight: number | string
  isSpHorizontalMode?: boolean
  isInitFloatingWindowButton: boolean
  onNextPage?: () => void
  onPrevPage?: () => void
  setOpen: (open: boolean) => void
  setIsInitFloatingWindowButton: (isInit: boolean) => void
  setSwiper?: (swiper: any) => void
  setOpenCta?: (open: boolean) => void
  setSlideHeight: (height: number) => void
  changeShownPage: (page: null | number) => void
  isActivated: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      background: Colors.background.lightGray
    },
    swiper: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      width: 'calc(100% - 350px)'
    },
    currentSlideImg: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      height: ({ isOverImageHeight }: { isOverImageHeight: boolean }) => (isOverImageHeight ? 'fit-content' : '100%'),
      maxHeight: '100%',
      '& > img': {
        objectFit: 'contain',
        maxHeight: '100%',
        position: 'relative',
        filter: `drop-shadow(0px 0px 1px ${Colors.background.silver})`,
        transform: 'translate3d(0,0,0)'
      }
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 80,
      zIndex: 100,
      background: '#fff',
      boxShadow: 'inset 0px 1px 0px #EEF1F4',
      gridArea: 'footer',
      flexShrink: 0
    },
    documentTitle: { lineHeight: 1, marginLeft: 8 },
    actionMenu: { background: 'white', borderTop: '1px solid #DADCE0', borderBottom: '1px solid #DADCE0', height: 40 }
  })
)

export const SpHorizontalMode = ({
  open,
  siteId,
  organization,
  policySettings,
  document,
  totalPage,
  shownPage,
  ctaPosition,
  slideImages,
  displayCta,
  visitor,
  isPreview,
  documentShareId,
  siteShareId,
  isSpHorizontalMode,
  isInitFloatingWindowButton,
  openPageSelectModal,
  onClosePageSelectModal,
  onOpenPageSelectModal,
  onNextPage,
  onPrevPage,
  setSwiper,
  setShownPage,
  setOpenCta,
  handleCreateTrackCTA,
  setIsInitFloatingWindowButton,
  setOpen,
  changeShownPage,
  isActivated
}: Props) => {
  const [showController, setShowController] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>()

  const handleSetTimeout = useCallback(() => {
    setShowController(true)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const tId = setTimeout(() => {
      setShowController(false)
    }, CONTROLLER_SHOW_TIMEOUT)
    setTimeoutId(tId)
  }, [timeoutId])

  const [isOverImageHeight, setIsOverImageHeight] = useState(false)
  const classes = useStyles({ isOverImageHeight })

  useEffect(() => {
    const handler = () => {
      const wrapperElement = window.document.getElementById('imageWrapper') as HTMLDivElement | undefined
      const imgElement = window.document.getElementsByClassName('image-map__content__img')?.[0] as
        | HTMLImageElement
        | undefined

      const isOver = wrapperElement && imgElement ? wrapperElement.clientHeight > imgElement.clientHeight : false
      setIsOverImageHeight(isOver)
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  useEffect(() => {
    const wrapperElement = window.document.getElementById('imageWrapper') as HTMLDivElement | undefined
    const imgElement = window.document.getElementsByClassName('image-map__content__img')?.[0] as
      | HTMLImageElement
      | undefined

    const isOver = wrapperElement && imgElement ? wrapperElement.clientHeight > imgElement.clientHeight : false
    setIsOverImageHeight(isOver)
  }, [])

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100vh"
      bgcolor="black"
      position={'relative'}
      style={{ height: '100dvh' }}
    >
      {/* CTA */}
      <Box
        style={{
          ...ctaPosition,
          position: 'absolute',
          pointerEvents: displayCta ? 'auto' : 'none',
          zIndex: 9999,
          opacity: displayCta ? 1 : 0,
          transition: '512ms',
          padding: '32px',
          maxWidth: '500px',
          width: '100%'
        }}
      >
        <ClickAwayListener disableReactTree onClickAway={() => setOpenCta(false)}>
          <CTADialog
            cta={document.documentCta}
            onClose={() => setOpenCta(false)}
            onClickCtaButton={() => handleCreateTrackCTA()}
          />
        </ClickAwayListener>
      </Box>
      <Box width="100%" height="100vh" position="relative" style={{ height: '100dvh', display: 'flex' }}>
        <Swiper
          style={{ height: '100dvh', position: 'relative' }}
          className={clsx(classes.swiper, {
            [classes.contentShift]: open
          })}
          initialSlide={(shownPage || 1) - 1}
          onSwiper={setSwiper}
          onClick={() => {
            handleSetTimeout()
          }}
          onSlideChange={swipe => {
            setShownPage(swipe.activeIndex + 1)
          }}
        >
          <Box
            width="100%"
            height="100%"
            position={'absolute'}
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="space-between"
            style={{ pointerEvents: 'none', zIndex: 9999999, opacity: showController ? 1.0 : 0, transition: '512ms' }}
          >
            <ButtonBase
              style={{
                height: 'calc(100% - 54px)',
                width: '60px',
                display: 'flex',
                alignItems: 'center',
                pointerEvents: showController ? 'auto' : 'none',
                backgroundColor: 'rgba(0,0,0,0.3)'
              }}
              onClick={() => {
                onPrevPage()
                handleSetTimeout()
              }}
            >
              <Box
                width="50px"
                height="50px"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt="44px"
              >
                <KeyboardArrowLeft style={{ color: 'white', fontSize: '44px' }} />
              </Box>
            </ButtonBase>
            <ButtonBase
              style={{
                height: 'calc(100% - 54px)',
                width: '60px',
                display: 'flex',
                alignItems: 'center',
                pointerEvents: showController ? 'auto' : 'none',
                backgroundColor: 'rgba(0,0,0,0.4)'
              }}
              onClick={() => {
                onNextPage()
                handleSetTimeout()
              }}
            >
              <Box
                width="50px"
                height="50px"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt="44px"
              >
                <KeyboardArrowRight style={{ color: 'white', fontSize: '44px' }} />
              </Box>
            </ButtonBase>
          </Box>
          {slideImages.map((slideImage, index) => {
            const currentPage = (shownPage ?? 0) - 1
            const shownImage = currentPage - 3 <= index && index <= currentPage + 3
            return (
              <SwiperSlide
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                key={slideImage.file?.url}
              >
                {shownImage && (
                  <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <LinkImage
                      className={classes.currentSlideImg}
                      imgSrc={slideImage.file?.url}
                      links={slideImage.slideImageLinks}
                    />
                  </Box>
                )}
              </SwiperSlide>
            )
          })}
          {!organization.hideAd && (
            <SwiperSlide>
              <Box
                position="relative"
                style={{
                  padding: '16px'
                }}
                display="flex"
                alignItems="center"
                bgcolor={Pallete.functional.background.default}
                height="100%"
              >
                <Box
                  position="absolute"
                  top={16}
                  left={16}
                  borderRadius="4px"
                  border={`2px solid ${Colors.accent.keyPurple.default}`}
                >
                  <Typography variant="h5" style={{ padding: '0 8px', color: Colors.accent.keyPurple.default }}>
                    PR
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  height={1}
                  flex={1}
                  flexDirection="column"
                  justifyContent="center"
                >
                  <img src="/images/nocosell_logo.svg" width={120} />
                  <Box mt="20px">
                    <Typography variant="h2">クラウドで顧客獲得はじめよう</Typography>
                  </Box>

                  <Box mt="20px">
                    <Typography
                      variant="body2"
                      style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
                    >{`ノコセルは 資料を共有するだけで\n資料の閲覧追跡・顧客管理・コミュニケーションまで行える\nクラウド顧客獲得ツールです`}</Typography>
                  </Box>

                  <Box mt="20px">
                    <Button
                      title="無料ではじめる"
                      onClick={() => {
                        window.open(
                          'https://noco.sale/?utm_source=noco_sales&utm_medium=service_pr&utm_campaign=last_page_pr',
                          '_blank'
                        )
                      }}
                    />
                  </Box>

                  <Box mt="20px">
                    <Typography variant="h5" style={{ textAlign: 'center', color: Colors.accent.keyPurple.default }}>
                      この資料はノコセルで作成されています
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          )}
        </Swiper>
      </Box>
      <Box
        position={'absolute'}
        zIndex={99999}
        {...(open
          ? {
              right: '0px',
              overflow: 'scroll',
              bgcolor: Pallete.functional.background.default,
              height: '100%'
            }
          : {
              right: '12px',
              top: '8px'
            })}
        style={{
          boxShadow: open
            ? '0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
            : 'none'
        }}
      >
        <FloatingWindowButton
          isSp
          policySettings={policySettings}
          document={document}
          siteId={siteId}
          organization={organization}
          visitor={visitor}
          isPreview={isPreview}
          documentShareId={documentShareId}
          siteShareId={siteShareId}
          isSpHorizontalMode={isSpHorizontalMode}
          init={isInitFloatingWindowButton}
          setInit={setIsInitFloatingWindowButton}
          open={open}
          setOpen={setOpen}
          isActivated={isActivated}
        />
      </Box>
      <Box
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={1}
        bottom={0}
        height={54}
        zIndex={99}
        px="10px"
        style={{
          transition: '512ms',
          opacity: showController ? 1.0 : 0,
          pointerEvents: showController ? 'auto' : 'none'
        }}
        bgcolor="rgba(0, 0, 0, 0.3)"
        className={clsx({
          [classes.contentShift]: open
        })}
      >
        <Box>
          <IconButton onClick={onPrevPage}>
            <SvgIcon variant="leftArrow" color={Pallete.functional.background.default} size="14px" />
          </IconButton>

          <IconButton onClick={onNextPage}>
            <SvgIcon variant="rightArrow" color={Pallete.functional.background.default} size="14px" />
          </IconButton>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="h5" style={{ lineHeight: 1, color: Pallete.functional.background.default }}>
            {shownPage ?? 1}/{totalPage}
          </Typography>
          <Box ml={1}>
            <IconButton onClick={onOpenPageSelectModal}>
              <SvgIcon variant="viewModule" color={Pallete.functional.background.default} size="18px" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <ModalDocumentPageSelect
        slideImages={document?.currentSlide?.slideImages ?? []}
        open={openPageSelectModal}
        onClose={onClosePageSelectModal}
        setShownPage={changeShownPage}
      />
    </Box>
  )
}
