/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EuSiteSectionDocumentDocument,
  EuSiteSectionDocumentDocumentFromJSON,
  EuSiteSectionDocumentDocumentFromJSONTyped,
  EuSiteSectionDocumentDocumentToJSON
} from './EuSiteSectionDocumentDocument'

/**
 *
 * @export
 * @interface EuSiteSectionDocument
 */
export interface EuSiteSectionDocument {
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocument
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocument
   */
  displayTitle?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocument
   */
  presentationText?: string | null
  /**
   *
   * @type {number}
   * @memberof EuSiteSectionDocument
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocument
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocument
   */
  updatedAt?: string
  /**
   *
   * @type {EuSiteSectionDocumentDocument}
   * @memberof EuSiteSectionDocument
   */
  document?: EuSiteSectionDocumentDocument
}

export function EuSiteSectionDocumentFromJSON(json: any): EuSiteSectionDocument {
  return EuSiteSectionDocumentFromJSONTyped(json, false)
}

export function EuSiteSectionDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EuSiteSectionDocument {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    displayTitle: !exists(json, 'display_title') ? undefined : json['display_title'],
    presentationText: !exists(json, 'presentation_text') ? undefined : json['presentation_text'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    document: !exists(json, 'document') ? undefined : EuSiteSectionDocumentDocumentFromJSON(json['document'])
  }
}

export function EuSiteSectionDocumentToJSON(value?: EuSiteSectionDocument | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    display_title: value.displayTitle,
    presentation_text: value.presentationText,
    sort: value.sort,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    document: EuSiteSectionDocumentDocumentToJSON(value.document)
  }
}
