import { useCallback, useState } from 'react'
import { SWRCachePath } from '../swr-cach-path'
import { IAuth } from 'src/fixtures/types/auth'
import { handleError } from 'src/fixtures/utils/error'
import { useGetMe, useGetMeProfile } from '../me/hooks'
import { useRouter } from 'next/router'
import { getTokenFromCookie, removeTokenFromCookie, setTokenToCookie, wait } from 'src/fixtures/utils'
import useSWR from 'swr'
import { nocoSDK } from '../initialize'
import { Toaster } from 'src/components/atoms'
import { toCamel } from 'convert-keys'
import { InvitedToOrganizationSignupRequest } from '@noco/http-client/lib/noco'
import { getSubdomainFromHost } from 'src/fixtures/utils/url'
import { useStaticSWR } from 'src/fixtures/utils/swr'
import { useGetOrganization } from '../organization/hooks'

// token 情報とかを cookie から取得
export const useAuthenticate = () => {
  const [isLoading, setIsLoading] = useState(true)
  const func = useCallback(async () => {
    const cookie = getTokenFromCookie()
    setIsLoading(false)
    return cookie.token ? ({ token: cookie.token } as IAuth) : undefined
  }, [setIsLoading])
  const response = useSWR<IAuth | undefined>(SWRCachePath.getAuthenticate(), func, {})
  return { ...response, isLoading }
}

// token 情報とかを cookie から取得

// ログインする
export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateAuth } = useAuthenticate()
  const { mutate: mutateMe } = useGetMe()
  const { mutate: mutateOrg } = useGetOrganization()
  const { mutate: mutateProfile } = useGetMeProfile()
  const router = useRouter()
  const authApi = nocoSDK.client?.userService.authApi

  const handleLogin = useCallback(
    async (email: string, password: string, requestSubdomain: string) => {
      try {
        nocoSDK.initalize()
        setError(undefined)
        setIsLoading(true)
        const res: any = await authApi
          ?.ApiFactory()
          .userSignin({ signinRequest: { encryptedEmail: email, password, organizationSubdomain: requestSubdomain } })
        const token = res.data.user.token as string
        const host = window.location.host
        const hasSubdomain = getSubdomainFromHost(host)
        const subdomain = getSubdomainFromHost(host) || requestSubdomain
        // const baseDomain = process.env.DOMAIN_NAME
        setTokenToCookie({ token }, undefined)
        await mutateAuth({ token })
        await mutateMe()
        Promise.all([mutateOrg(), mutateProfile()])

        if (hasSubdomain) {
          router.push('/documents')
        }
        // host に subdomain がなかった場合
        else {
          window.location.href = `${window.location.protocol}//${subdomain}.${process.env.DOMAIN_NAME}/documents`
        }
        Toaster.success('ログインが完了しました！')
      } catch (err) {
        await handleError(err, { setError, tag: 'Login' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi, mutateAuth, mutateMe, mutateOrg, mutateProfile, router]
  )

  return { error, isLoading, handleLogin }
}

export const useLogout = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateAuth } = useAuthenticate()
  const { mutate: mutateMe } = useGetMe()
  const router = useRouter()

  const handleLogout = useCallback(async () => {
    try {
      setError(undefined)
      setIsLoading(true)
      removeTokenFromCookie()
      await Promise.all([mutateAuth(undefined), mutateMe(undefined)])
      Toaster.success('ログアウトしました')
      await wait(512)
      router.replace('/signin')
    } catch (err) {
      await handleError(err, { setError, tag: 'Logout' })
    } finally {
      setIsLoading(false)
    }
  }, [mutateAuth, mutateMe, router])

  return { error, isLoading, handleLogout }
}

export const useSignup = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const router = useRouter()
  const authApi = nocoSDK.client?.userService.authApi
  const { mutate: mutateEmail } = useGetUserLocalAuthEmail()
  const handleSignUp = useCallback(
    async (invitedToOrganizationSignupRequest: InvitedToOrganizationSignupRequest, email?: string) => {
      try {
        setError(undefined)
        setIsLoading(true)
        await authApi?.ApiFactory().userV1AuthInvitedToOrganizationSignupPost({ invitedToOrganizationSignupRequest })
        mutateEmail(email)
        Toaster.success('アカウント登録が完了しました。')
        await wait(512)
        router.replace('/signin')
      } catch (err) {
        await handleError(err, { setError, tag: 'Logout' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi, mutateEmail, router]
  )

  return { error, isLoading, handleSignUp }
}

export const useForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const authApi = nocoSDK.client?.userService.authApi
  const handleForgotPassword = useCallback(
    async (email: string, subdomain: string) => {
      try {
        setError(undefined)
        setIsLoading(true)
        await authApi?.ApiFactory().userV1ForgotPasswordMailPost({
          forgotPasswordMailRequest: { encryptedEmail: email, organizationSubdomain: subdomain }
        })
        await wait(512)
        Toaster.success('メールを送信しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Logout' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi]
  )

  return { error, isLoading, handleForgotPassword }
}

export const useSendOrganizationNameCheckMail = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const authApi = nocoSDK.client?.userService.authApi
  const handleSend = useCallback(
    async (email: string) => {
      try {
        setError(undefined)
        setIsLoading(true)
        await authApi?.ApiFactory().userV1ForgotOrganizationMailPost({
          forgotOrganizationMailRequest: { encryptedEmail: email }
        })

        await wait(512)
        Toaster.success('メールを送信しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Logout' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi]
  )

  return { error, isLoading, handleSend }
}

export const useResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const authApi = nocoSDK.client?.userService.authApi
  const router = useRouter()

  const handleResetPassword = useCallback(
    async (token: string, password: string) => {
      try {
        setError(undefined)
        setIsLoading(true)
        await authApi?.ApiFactory().userV1ResetPasswordPost({
          resetPasswordRequest: { password, token }
        })

        await wait(512)
        Toaster.success('パスワードリセットを行いました。ログイン画面に行きます')
        router.replace('/signin')
      } catch (err) {
        await handleError(err, { setError, tag: 'Logout' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi, router]
  )

  return { error, isLoading, handleResetPassword }
}

export const useGetUserInfoFromToken = (token?: string) => {
  const authApi = nocoSDK.client?.userService.authApi
  const func = useCallback(async () => {
    if (!token) return undefined
    try {
      const res = await authApi?.ApiFactory().userV1AuthInvitedToOrganizationCheckGet({ token: token })
      if (!res) return undefined
      const converted = toCamel(res) as any
      return converted.data as {
        user: { id: number; encryptedEmail: string }
        organization: {
          id: number
          name: string
          organizationSubdomain: string
        }
      }
    } catch (err) {
      throw new Error('エラーが発生しました。')
    }
  }, [authApi, token])
  return useSWR(token && SWRCachePath.userInfoFromToken(token), token ? func : null)
}

export const useForgotTeamId = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const authApi = nocoSDK.client?.userService.authApi
  const router = useRouter()

  const handleForgotTeamId = useCallback(
    async (email: string) => {
      try {
        setError(undefined)
        setIsLoading(true)
        await authApi?.ApiFactory().userV1ForgotOrganizationMailPost({
          forgotOrganizationMailRequest: { encryptedEmail: email }
        })

        await wait(512)
        Toaster.success('メールアドレスにメールを送りました')
        router.replace('/signin')
      } catch (err) {
        await handleError(err, { setError, tag: 'email' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi, router]
  )

  return { error, isLoading, handleForgotTeamId }
}

export const useGetUserLocalAuthEmail = () => {
  return useStaticSWR<string | undefined>(SWRCachePath.getUserSignInEmail(), undefined)
}
