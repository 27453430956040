import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Csv = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#00733B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1716 27.6166H21.6616V30.8369H13.1716V27.6166ZM24.3113 11.1633H32.8283V14.3566H24.3113V11.1633ZM13.1716 11.1633H21.6616V14.3566H13.1716V11.1633ZM24.3113 16.5756H32.8283V19.7959H24.3113V16.5756ZM13.1716 16.5756H21.6616V19.7959H13.1716V16.5756ZM24.3113 22.2043H32.8283V25.4246H24.3113V22.2043ZM13.1716 22.2043H21.6616V25.4246H13.1716V22.2043ZM24.3113 27.6166H32.8283V30.8369H24.3113V27.6166Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Csv
