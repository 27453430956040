/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumTimelineContentType = {
  Memo: 'memo',
  Audit: 'audit',
  Action: 'action',
  UserOperation: 'user_operation'
} as const
export type EnumTimelineContentType = typeof EnumTimelineContentType[keyof typeof EnumTimelineContentType]

export function EnumTimelineContentTypeFromJSON(json: any): EnumTimelineContentType {
  return EnumTimelineContentTypeFromJSONTyped(json, false)
}

export function EnumTimelineContentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumTimelineContentType {
  return json as EnumTimelineContentType
}

export function EnumTimelineContentTypeToJSON(value?: EnumTimelineContentType | null): any {
  return value as any
}
