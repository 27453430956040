/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateCompanySettingRequestCompanySetting,
  CreateCompanySettingRequestCompanySettingFromJSON,
  CreateCompanySettingRequestCompanySettingFromJSONTyped,
  CreateCompanySettingRequestCompanySettingToJSON
} from './CreateCompanySettingRequestCompanySetting'
import {
  CreateCompanySettingRequestCompanySettingFieldsInner,
  CreateCompanySettingRequestCompanySettingFieldsInnerFromJSON,
  CreateCompanySettingRequestCompanySettingFieldsInnerFromJSONTyped,
  CreateCompanySettingRequestCompanySettingFieldsInnerToJSON
} from './CreateCompanySettingRequestCompanySettingFieldsInner'

/**
 *
 * @export
 * @interface CreateCompanySettingRequest
 */
export interface CreateCompanySettingRequest {
  /**
   *
   * @type {CreateCompanySettingRequestCompanySetting}
   * @memberof CreateCompanySettingRequest
   */
  companySetting?: CreateCompanySettingRequestCompanySetting
  /**
   *
   * @type {Array<CreateCompanySettingRequestCompanySettingFieldsInner>}
   * @memberof CreateCompanySettingRequest
   */
  companySettingFields?: Array<CreateCompanySettingRequestCompanySettingFieldsInner>
}

export function CreateCompanySettingRequestFromJSON(json: any): CreateCompanySettingRequest {
  return CreateCompanySettingRequestFromJSONTyped(json, false)
}

export function CreateCompanySettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateCompanySettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companySetting: !exists(json, 'company_setting')
      ? undefined
      : CreateCompanySettingRequestCompanySettingFromJSON(json['company_setting']),
    companySettingFields: !exists(json, 'company_setting_fields')
      ? undefined
      : (json['company_setting_fields'] as Array<any>).map(CreateCompanySettingRequestCompanySettingFieldsInnerFromJSON)
  }
}

export function CreateCompanySettingRequestToJSON(value?: CreateCompanySettingRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_setting: CreateCompanySettingRequestCompanySettingToJSON(value.companySetting),
    company_setting_fields:
      value.companySettingFields === undefined
        ? undefined
        : (value.companySettingFields as Array<any>).map(CreateCompanySettingRequestCompanySettingFieldsInnerToJSON)
  }
}
