/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateSiteSectionRequestSiteSection,
  CreateSiteSectionRequestSiteSectionFromJSON,
  CreateSiteSectionRequestSiteSectionFromJSONTyped,
  CreateSiteSectionRequestSiteSectionToJSON
} from './CreateSiteSectionRequestSiteSection'

/**
 *
 * @export
 * @interface CreateSiteSectionRequest
 */
export interface CreateSiteSectionRequest {
  /**
   *
   * @type {CreateSiteSectionRequestSiteSection}
   * @memberof CreateSiteSectionRequest
   */
  siteSection?: CreateSiteSectionRequestSiteSection
}

export function CreateSiteSectionRequestFromJSON(json: any): CreateSiteSectionRequest {
  return CreateSiteSectionRequestFromJSONTyped(json, false)
}

export function CreateSiteSectionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateSiteSectionRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteSection: !exists(json, 'site_section')
      ? undefined
      : CreateSiteSectionRequestSiteSectionFromJSON(json['site_section'])
  }
}

export function CreateSiteSectionRequestToJSON(value?: CreateSiteSectionRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_section: CreateSiteSectionRequestSiteSectionToJSON(value.siteSection)
  }
}
