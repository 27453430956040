import { Box, ButtonBase, createStyles, makeStyles, Popover, TextareaAutosize } from '@material-ui/core'
import { Site } from '@noco/http-client/lib/noco'
import React, { useState, useCallback, useMemo, useRef, FormEvent, useEffect } from 'react'
import {
  Input,
  DynamicMuiIcon,
  Colors,
  SelectBox,
  Chip,
  Typography,
  OptionListType,
  Id,
  SvgIcon,
  Tooltip
} from 'src/components/atoms'
import { AddressSearchBox, SelectorItemWithType } from 'src/components/organisms/AddressSearchBox'
import { useShareSiteToContact } from 'src/fixtures/modules/site/hooks'
import { useListTemplates } from 'src/fixtures/modules/template/hooks'
import { OptionData, SelectDatePicker, SendEmailContents } from 'src/components/molecules'
import { useGlobalStyles } from 'src/styles/theme'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { ModalConfirmToPaidPlan } from 'src/components/modals'
import { LinkIcon } from 'src/components/atoms/LinkIcon'
import { LocalStorageMaterialDraft } from 'src/fixtures/utils/localStorageMaterialDraft'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { useConfirmDiscard } from 'src/fixtures/utils/url'
import { FormSubmitter } from '../hooks'

export interface SiteShareWithEmailProps {
  site: Site
  formSubmitter?: FormSubmitter
  isSubmitting: boolean
  isSubmitEnd: boolean
  onSubmit?: () => void
  onError?: () => void
  onSuccess?: () => void
  onChangeShowConfirm?: (showConfirm: boolean) => void
  setIsSubmitting: (isSubmitting: boolean) => void
  setIsSubmitEnd: (isSubmitEnd: boolean) => void
  isSending: boolean
  setIsSending: (isSending: boolean) => void
  progressCompleted: boolean
  setProgressCompleted: (progressCompleted: boolean) => void
  setSubmitStatus: (submitStatus: boolean) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    customBox: {
      border: `1px solid ${Colors.accent.keyPurple.default}`
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    },
    wordKeep: {
      wordBreak: 'keep-all'
    },
    textarea: {
      overflow: 'hidden auto !important',
      resize: 'vertical',
      width: '100%',
      border: 'none',
      paddingTop: '16px',
      color: Colors.base.black,
      fontSize: '14px',
      lineHeight: '18,48px',
      letterSpacing: '4%',
      marginBottom: '6px',
      '&:focus': {
        outline: 'none'
      },
      '&::placeholder': {
        fontFamily: 'Hiragino Kaku Gothic Pro',
        color: Colors.base.placeHolder,
        fontWeight: 300
      }
    },
    customLink: {
      display: 'inline-flex',
      alignItems: 'center',
      border: `1px solid ${Colors.background.gray}`,
      padding: '8px',
      borderRadius: '6px',
      textDecoration: 'none'
    }
  })
})

const optionAuthList: OptionListType[] = [
  {
    value: 'unexpiredViewable',
    label: '閲覧可（期限なし）'
  },
  {
    value: 'expiredViewable',
    label: '閲覧可（期限あり）'
  }
]

export const SiteShareWithEmail = React.forwardRef<HTMLButtonElement, SiteShareWithEmailProps>(function Select(
  {
    site,
    formSubmitter,
    setIsSubmitting,
    isSubmitting,
    isSubmitEnd,
    setIsSubmitEnd,
    onError,
    onSubmit,
    onSuccess,
    onChangeShowConfirm,
    isSending,
    setIsSending,
    progressCompleted,
    setProgressCompleted,
    setSubmitStatus
  },
  ref
) {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  //- テンプレート
  const { data: templateList } = useListTemplates()
  const [selectedTemplateValue, setSelectedTemplateValue] = useState('')
  //- 連絡先（宛先）
  const [contactList, setContactList] = useState<SelectorItemWithType[]>([])
  const addressBoxRef = useRef<HTMLElement>(null)
  const [openAddressSerachBox, setOpenAddressSearchbox] = useState(false)
  const [isError, setIsError] = useState<Error | undefined>(undefined)
  const { data: me } = useGetMe()

  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [authValue, setAuthValue] = useState('unexpiredViewable')
  const [expiredValue, setExpiredValue] = useState<string | null>(site.siteShare?.expiredOn!)

  // upgrade modal
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const [showConfirm, setShowConfirm] = useState(false)
  // 送信をしなかった場合に画面を離れようとした時の破棄確認
  useConfirmDiscard(showConfirm)
  // 送信中の破棄確認
  useConfirmDiscard(isSending, '資料のメールを送信中です。画面を離れますか？')

  const localStorageMaterialDraft = useMemo(() => {
    return typeof window !== 'undefined' && me ? new LocalStorageMaterialDraft(me?.user?.id!) : undefined
  }, [me])

  const handleResetForm = useCallback(() => {
    setBody('')
    setSubject('')
    setAuthValue('unexpiredViewable')
    setExpiredValue(null)
    setSelectedTemplateValue('')
    setContactList([])
    setShowConfirm(false)
  }, [])

  //- 送信
  const { handleShareSiteToContact, isLoading, error } = useShareSiteToContact(site.id!, () => {
    handleResetForm()
    onSuccess?.()
    setIsSubmitting(false)
    setIsSubmitEnd(true)
  })

  // * 送信可能かどうかを判断する
  useEffect(() => {
    if (contactList.length >= 1 && subject !== '' && body !== '') {
      setSubmitStatus(true)
    } else {
      setSubmitStatus(false)
    }
  }, [body, contactList, setIsSubmitting, setSubmitStatus, subject])

  // * 送信エラー時のstate変更
  useEffect(() => {
    if (error === undefined) return
    setIsError(error)
    setIsSubmitting(false)
    setIsSending(false)
    setProgressCompleted(true)
  }, [error, setIsSending, setIsSubmitting, setProgressCompleted])

  //- 変更時 viewable value
  useEffect(() => {
    switch (authValue) {
      case 'unexpiredViewable':
        setExpiredValue(null)
        break
      case 'expiredViewable':
        expiredValue
          ? setExpiredValue(expiredValue)
          : setExpiredValue(format(new Date().getTime(), 'yyyy-MM-dd', { locale: ja }))
        break
      default:
        return
    }
  }, [authValue, expiredValue])

  const handleCalendarConfirm = useCallback(selectedExpiredValue => {
    setExpiredValue(selectedExpiredValue)
    setShowConfirm(true)
  }, [])

  const templateOptionList: OptionData[] | undefined = useMemo(
    () => templateList?.map(item => ({ value: item.id!, label: item.name! })),
    [templateList]
  )

  const position = useMemo(() => {
    if (!addressBoxRef.current) return
    const rect = addressBoxRef.current.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [addressBoxRef])

  const handleTemplateChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (!templateList) return

      const emailTemplateId = event.target.value as string
      const template = templateList.find(t => t.id == emailTemplateId)

      setSubject(template?.subject || '')
      setBody(template?.body || '')
      setSelectedTemplateValue(emailTemplateId)

      if (template && site) {
        localStorageMaterialDraft?.saveDocumentShareDraftSubject(me?.user?.id!, site.id!, template?.subject || '')
        localStorageMaterialDraft?.saveSiteShareDraftBody(me?.user?.id!, site.id!, template?.body || '')
      }
    },
    [localStorageMaterialDraft, me?.user?.id, site, templateList]
  )

  const changeSubject = useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setSubject(value)
      localStorageMaterialDraft?.saveSiteShareDraftSubject(me?.user?.id!, site?.id!, value)
      setShowConfirm(true)
    },
    [site?.id, localStorageMaterialDraft, me?.user?.id]
  )

  const changeBody = useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
      setBody(value)
      localStorageMaterialDraft?.saveSiteShareDraftBody(me?.user?.id!, site?.id!, value)
      setShowConfirm(true)
    },
    [site?.id, localStorageMaterialDraft, me?.user?.id]
  )

  const handleChangeContactList = useCallback(
    async (contactList: SelectorItemWithType[]) => {
      setContactList(contactList)
      setOpenAddressSearchbox(false)
      localStorageMaterialDraft?.saveSiteShareDraftContactList(me?.user?.id!, site?.id!, contactList)
      setShowConfirm(true)
    },
    [setContactList, site?.id, localStorageMaterialDraft, me?.user?.id]
  )

  const removeEmail = useCallback(
    async (id: Id) => {
      const newList = contactList.filter(c => c.id != id)
      await setContactList(newList)
      localStorageMaterialDraft?.saveSiteShareDraftContactList(me?.user?.id!, site?.id!, newList)
      setOpenAddressSearchbox(false)
      setShowConfirm(true)
    },
    [contactList, setContactList, localStorageMaterialDraft, me?.user?.id, site?.id]
  )

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      handleShareSiteToContact({
        messageBody: body,
        messageSubject: subject,
        contactIds: contactList.find(item => item.type === 'contact')
          ? contactList.filter(item => item.type === 'contact').map(item => item.id!)
          : undefined,
        contactListIds: contactList.find(item => item.type === 'contactList')
          ? contactList.filter(item => item.type === 'contactList').map(item => item.id!)
          : undefined,
        companyIds: contactList.find(item => item.type === 'companyList')
          ? contactList.filter(item => item.type === 'companyList').map(item => item.id!)
          : undefined,
        expiredOn: expiredValue
      })
      localStorageMaterialDraft?.removeSiteShareDraftBySiteId(me?.user?.id!, site?.id!)
      setIsSubmitting(true)
      setShowConfirm(false)
    },

    [
      handleShareSiteToContact,
      body,
      subject,
      contactList,
      expiredValue,
      localStorageMaterialDraft,
      me?.user?.id,
      site?.id,
      setIsSubmitting
    ]
  )

  useEffect(() => {
    if (!isLoading && isSubmitting && !isSubmitEnd && error) {
      setIsSubmitting(false)
      onError?.()
    } else if (!isLoading && isSubmitting && !isSubmitEnd && !error) {
      setIsSubmitEnd(true)
      onSubmit?.()
    } else if (!isLoading && isSubmitting && isSubmitEnd && !error) {
      setIsSubmitting(false)
      setIsSubmitEnd(false)
      onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSubmitting, isSubmitEnd, error])

  useEffect(() => {
    const SiteShareDraft = localStorageMaterialDraft?.getSiteShareDraftBySiteId(me?.user?.id!, site?.id!)
    if (SiteShareDraft) {
      setSubject(SiteShareDraft?.subject!)
      setBody(SiteShareDraft?.body!)
      setContactList(SiteShareDraft?.contactList!)
    }
  }, [site?.id, localStorageMaterialDraft, me])

  const handleTemplateEdit = useCallback(() => {
    window.open('/setting/team/email_templates/new', '_blank')
  }, [])

  useEffect(() => {
    onChangeShowConfirm?.(showConfirm)
  }, [onChangeShowConfirm, showConfirm])

  return (
    <>
      {isSending || progressCompleted ? (
        <SendEmailContents
          isSubmitting={isSubmitting}
          isSending={isSending}
          setIsSending={setIsSending}
          setProgressCompleted={isCompleted => {
            setProgressCompleted(isCompleted)
            handleResetForm()
          }}
          progressCompleted={progressCompleted}
          isError={isError}
          setIsError={setIsError}
        />
      ) : (
        <>
          <form
            onSubmit={handleSubmit}
            style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}
          >
            <Typography variant="h6" fontSize="s">
              メール本文
            </Typography>
            <Box mt="8px" display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Box width="202px" mr="10px">
                  <SelectBox
                    placeholder="テンプレートを選択"
                    value={selectedTemplateValue}
                    name="template"
                    optionList={templateOptionList || []}
                    onChange={handleTemplateChange}
                    height="34px"
                    placeholderIcon={<SvgIcon variant="contentPaste" color="inherit" size="18px" />}
                  />
                </Box>
                <Box color={Colors.accent.keyBlue.default}>
                  <ButtonBase onClick={handleTemplateEdit}>
                    <DynamicMuiIcon variant="add" color="inherit" fontSize="small" />
                    <Box mr="10px" />
                    <Typography variant="h5">テンプレート新規作成</Typography>
                  </ButtonBase>
                </Box>
              </Box>
              {/* <Box>
            <Button
              startIcon={<DynamicMuiIcon variant="openNew" color="inherit" fontSize="inherit" />}
              title="プレビュー"
              kind="secondary"
              variant="outlined"
              href={`/multi-documents/${site.id}/preview`}
            />
          </Box> */}
            </Box>

            {addressBoxRef?.current && (
              <Popover
                id={'id'}
                open={openAddressSerachBox}
                anchorEl={addressBoxRef.current}
                onClose={() => setOpenAddressSearchbox(false)}
                // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                // anchorReference="anchorPosition"
                anchorPosition={position}
                PaperProps={{
                  className: classes.paper,
                  style: { width: (addressBoxRef.current && addressBoxRef.current.offsetWidth - 2) || 0 }
                }}
              >
                <AddressSearchBox
                  selectedList={contactList}
                  onSelect={handleChangeContactList}
                  onClose={() => setOpenAddressSearchbox(false)}
                  onContactCreating={isContactCreating => formSubmitter?.setIsDisabled(isContactCreating)}
                />
              </Popover>
            )}
            <Box
              border={`1px solid ${Colors.background.silver}`}
              borderRadius="4px"
              mt="18px"
              px="12px"
              display="flex"
              flexDirection="column"
              overflow="hidden"
              maxHeight="60%"
            >
              <Box
                {...{ ref: addressBoxRef }}
                borderRadius="4px"
                display="flex"
                flexWrap="wrap"
                className={globalClasses.cursorPointer}
                onClick={() => setOpenAddressSearchbox(true)}
              >
                <Tooltip
                  content={
                    'メールの送信先を指定します（To扱いとなります）。CCおよびBCCでのメール送信には対応していません。'
                  }
                >
                  <ButtonBase style={{ padding: '12px 4px 12px 0px' }}>
                    <Box color={Colors.base.middleGray}>
                      <Typography variant="h6" fontSize="s" fontWeight="normal" lineheight="14px" letterSpacing="none">
                        宛先
                      </Typography>
                    </Box>
                    {contactList.length === 0 && (
                      <Box color={Colors.base.placeHolder} ml="14px">
                        <Typography fontSize="s" fontWeight="normal" lineheight="14px" letterSpacing="none">
                          連絡先を選択、もしくはリストから一括選択
                        </Typography>
                      </Box>
                    )}
                  </ButtonBase>
                </Tooltip>
                {contactList.map(p => {
                  return <Chip key={p.id} id={`${p.id}`} onClose={removeEmail} label={p.text || ''} />
                })}
              </Box>
              <Box borderBottom={`1px solid ${Colors.background.silver}`} />
              <Box display="flex" alignItems="center" className={classes.wordKeep}>
                <Box color={Colors.base.middleGray}>
                  <Typography variant="h6" fontSize="s" fontWeight="normal" lineheight="14px" letterSpacing="none">
                    件名
                  </Typography>
                </Box>
                <Input
                  name="title"
                  value={subject}
                  fullWidth
                  borderStyle="none"
                  boxShadow="none"
                  placeholder="資料送付の件"
                  onChange={changeSubject}
                />
              </Box>
              <Box borderBottom={`1px solid ${Colors.background.silver}`} />
              <TextareaAutosize
                name="message"
                value={body}
                minRows={8}
                placeholder="本文を入力..."
                className={classes.textarea}
                onChange={changeBody}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  borderTop: `1px solid ${Colors.background.gray}`,
                  py: '12px'
                }}
              >
                <Box mr="12px" flexShrink="0">
                  <Typography fontSize="s" lineheight="1" style={{ color: '##6D7784' }}>
                    添付
                  </Typography>
                </Box>
                <LinkIcon href={`/multi-documents/${site.id}/preview`} isBlank className={classes.customLink}>
                  <Box sx={{ color: Colors.accent.keyBlue.default, mr: '8px', lineHeight: '0' }}>
                    <DynamicMuiIcon variant="computer" size="32px" color="inherit" />
                  </Box>
                  <Box sx={{ mr: '6px' }}>
                    <Typography
                      fontSize="s"
                      lineheight="1.32"
                      style={{ color: Colors.accent.keyBlue.default, wordBreak: 'break-word' }}
                    >
                      {site.title || ''}
                      <Box
                        sx={{
                          color: Colors.accent.keyBlue.default,
                          lineHeight: '0',
                          display: 'initial',
                          marginLeft: '6px'
                        }}
                        style={{ verticalAlign: 'text-bottom' }}
                      >
                        <DynamicMuiIcon variant="openNew" size="16px" color="inherit" />
                      </Box>
                    </Typography>
                  </Box>
                </LinkIcon>
              </Box>
            </Box>
            <Box mt="25px" />
            <Typography variant="h6" fontSize="s" lineheight="14px">
              メールに添付する資料サイト
            </Typography>
            <Box mt="8px" />
            <Input
              name="site"
              disabled
              fullWidth
              large
              value={site.title!}
              startAdornment={
                <Box
                  pl="11px"
                  color={Colors.base.middleGray}
                  fontSize="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicMuiIcon variant="computer" color="action" fontSize="inherit" />
                </Box>
              }
            />
            <Box mt="25px" />
            <Box width="fit-content">
              <Tooltip
                content={
                  '資料の閲覧期限が設定できます。資料の共有後に期限を変更する場合は、資料サイトの「連絡先」タブから設定できます。'
                }
              >
                <>
                  <Typography variant="h5" fontSize="s" lineheight="14px">
                    閲覧可能な期限の設定
                  </Typography>
                </>
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center" mt="8px" position="relative">
              {!planGrade?.plan.canSetMaterialPublicationDue && (
                <Box
                  width="100%"
                  height="100%"
                  zIndex={999}
                  position="absolute"
                  top={0}
                  onClick={() => {
                    setOpenModalUpgrade(true)
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                />
              )}

              <SelectBox
                value={authValue}
                name="term"
                optionList={optionAuthList}
                onChange={e => {
                  setAuthValue(e.target.value as string)
                }}
                height="34px"
              />
              <Box mr="24px" />
              <SelectDatePicker
                disabled={!expiredValue}
                expiredValue={expiredValue}
                onConfirm={handleCalendarConfirm}
              />
            </Box>
            <button ref={ref} type="submit" hidden />
          </form>
          <ModalConfirmToPaidPlan
            currentPlanGrade={planGrade?.plan.grade}
            open={openModalUpgrade}
            lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
            standardNewText="スタンダードプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
            onClose={() => setOpenModalUpgrade(false)}
          />
        </>
      )}
    </>
  )
})
