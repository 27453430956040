/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationUserEmailSetting,
  OrganizationUserEmailSettingFromJSON,
  OrganizationUserEmailSettingFromJSONTyped,
  OrganizationUserEmailSettingToJSON
} from './OrganizationUserEmailSetting'

/**
 *
 * @export
 * @interface UpdateOrganizationUserEmailSettingResponseData
 */
export interface UpdateOrganizationUserEmailSettingResponseData {
  /**
   *
   * @type {OrganizationUserEmailSetting}
   * @memberof UpdateOrganizationUserEmailSettingResponseData
   */
  organizationUserEmailSetting?: OrganizationUserEmailSetting
}

export function UpdateOrganizationUserEmailSettingResponseDataFromJSON(
  json: any
): UpdateOrganizationUserEmailSettingResponseData {
  return UpdateOrganizationUserEmailSettingResponseDataFromJSONTyped(json, false)
}

export function UpdateOrganizationUserEmailSettingResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationUserEmailSettingResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationUserEmailSetting: !exists(json, 'organization_user_email_setting')
      ? undefined
      : OrganizationUserEmailSettingFromJSON(json['organization_user_email_setting'])
  }
}

export function UpdateOrganizationUserEmailSettingResponseDataToJSON(
  value?: UpdateOrganizationUserEmailSettingResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_user_email_setting: OrganizationUserEmailSettingToJSON(value.organizationUserEmailSetting)
  }
}
