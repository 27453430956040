/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DocumentChatSetting
 */
export interface DocumentChatSetting {
  /**
   *
   * @type {string}
   * @memberof DocumentChatSetting
   */
  id?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentChatSetting
   */
  canChat?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentChatSetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof DocumentChatSetting
   */
  updatedAt?: string
}

export function DocumentChatSettingFromJSON(json: any): DocumentChatSetting {
  return DocumentChatSettingFromJSONTyped(json, false)
}

export function DocumentChatSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentChatSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    canChat: !exists(json, 'can_chat') ? undefined : json['can_chat'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function DocumentChatSettingToJSON(value?: DocumentChatSetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    can_chat: value.canChat,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
