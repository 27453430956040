/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ContactListFromJSON, ContactListToJSON } from './ContactList';
export function CreateContactListResponseDataFromJSON(json) {
    return CreateContactListResponseDataFromJSONTyped(json, false);
}
export function CreateContactListResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contactList: !exists(json, 'contact_list') ? undefined : ContactListFromJSON(json['contact_list'])
    };
}
export function CreateContactListResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact_list: ContactListToJSON(value.contactList)
    };
}
