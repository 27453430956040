import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Colors, SvgIcon, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

export interface CompanyNameCellProps {
  value: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    company: {
      display: 'inline',
      '& p': {
        display: 'inline'
      }
    }
  })
})

export const CompanyNameCell = ({ value }: CompanyNameCellProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  return (
    <Box display="flex" alignItems="center">
      <SvgIcon variant="company" size="16px" color={Colors.base.middleGray} />
      <Box display="inline" width="4px" flexShrink={0} />
      <Box whiteSpace="pre-wrap" className={(classes.company, globalClasses.lineclamp1)}>
        <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
