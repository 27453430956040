/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserOperationDisplayObjectItemFromJSON, UserOperationDisplayObjectItemToJSON } from './UserOperationDisplayObjectItem';
export function UserOperationDisplayObjectFromJSON(json) {
    return UserOperationDisplayObjectFromJSONTyped(json, false);
}
export function UserOperationDisplayObjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        target: !exists(json, 'target') ? undefined : UserOperationDisplayObjectItemFromJSON(json['target']),
        source: !exists(json, 'source') ? undefined : UserOperationDisplayObjectItemFromJSON(json['source']),
        operate: !exists(json, 'operate') ? undefined : UserOperationDisplayObjectItemFromJSON(json['operate'])
    };
}
export function UserOperationDisplayObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        target: UserOperationDisplayObjectItemToJSON(value.target),
        source: UserOperationDisplayObjectItemToJSON(value.source),
        operate: UserOperationDisplayObjectItemToJSON(value.operate)
    };
}
