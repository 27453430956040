/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateContactMemoRequestContactMemo,
  CreateContactMemoRequestContactMemoFromJSON,
  CreateContactMemoRequestContactMemoFromJSONTyped,
  CreateContactMemoRequestContactMemoToJSON
} from './CreateContactMemoRequestContactMemo'

/**
 *
 * @export
 * @interface CreateContactMemoRequest
 */
export interface CreateContactMemoRequest {
  /**
   *
   * @type {CreateContactMemoRequestContactMemo}
   * @memberof CreateContactMemoRequest
   */
  contactMemo?: CreateContactMemoRequestContactMemo
}

export function CreateContactMemoRequestFromJSON(json: any): CreateContactMemoRequest {
  return CreateContactMemoRequestFromJSONTyped(json, false)
}

export function CreateContactMemoRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactMemoRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactMemo: !exists(json, 'contact_memo')
      ? undefined
      : CreateContactMemoRequestContactMemoFromJSON(json['contact_memo'])
  }
}

export function CreateContactMemoRequestToJSON(value?: CreateContactMemoRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_memo: CreateContactMemoRequestContactMemoToJSON(value.contactMemo)
  }
}
