/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateDocumentChatSettingRequestDocumentChatSettingFromJSON, UpdateDocumentChatSettingRequestDocumentChatSettingToJSON } from './UpdateDocumentChatSettingRequestDocumentChatSetting';
export function UpdateDocumentChatSettingRequestFromJSON(json) {
    return UpdateDocumentChatSettingRequestFromJSONTyped(json, false);
}
export function UpdateDocumentChatSettingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        documentChatSetting: !exists(json, 'document_chat_setting')
            ? undefined
            : UpdateDocumentChatSettingRequestDocumentChatSettingFromJSON(json['document_chat_setting'])
    };
}
export function UpdateDocumentChatSettingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document_chat_setting: UpdateDocumentChatSettingRequestDocumentChatSettingToJSON(value.documentChatSetting)
    };
}
