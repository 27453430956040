/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetCompanyCompanyListsResponseData,
  GetCompanyCompanyListsResponseDataFromJSON,
  GetCompanyCompanyListsResponseDataFromJSONTyped,
  GetCompanyCompanyListsResponseDataToJSON
} from './GetCompanyCompanyListsResponseData'

/**
 *
 * @export
 * @interface GetCompanyCompanyListsResponse
 */
export interface GetCompanyCompanyListsResponse {
  /**
   *
   * @type {number}
   * @memberof GetCompanyCompanyListsResponse
   */
  status: number
  /**
   *
   * @type {GetCompanyCompanyListsResponseData}
   * @memberof GetCompanyCompanyListsResponse
   */
  data: GetCompanyCompanyListsResponseData
}

export function GetCompanyCompanyListsResponseFromJSON(json: any): GetCompanyCompanyListsResponse {
  return GetCompanyCompanyListsResponseFromJSONTyped(json, false)
}

export function GetCompanyCompanyListsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCompanyCompanyListsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetCompanyCompanyListsResponseDataFromJSON(json['data'])
  }
}

export function GetCompanyCompanyListsResponseToJSON(value?: GetCompanyCompanyListsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetCompanyCompanyListsResponseDataToJSON(value.data)
  }
}
