/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetMeResponseData,
  GetMeResponseDataFromJSON,
  GetMeResponseDataFromJSONTyped,
  GetMeResponseDataToJSON
} from './GetMeResponseData'

/**
 *
 * @export
 * @interface ConfirmMeChangeEmailResponse
 */
export interface ConfirmMeChangeEmailResponse {
  /**
   *
   * @type {number}
   * @memberof ConfirmMeChangeEmailResponse
   */
  status?: number
  /**
   *
   * @type {GetMeResponseData}
   * @memberof ConfirmMeChangeEmailResponse
   */
  data?: GetMeResponseData
}

export function ConfirmMeChangeEmailResponseFromJSON(json: any): ConfirmMeChangeEmailResponse {
  return ConfirmMeChangeEmailResponseFromJSONTyped(json, false)
}

export function ConfirmMeChangeEmailResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ConfirmMeChangeEmailResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetMeResponseDataFromJSON(json['data'])
  }
}

export function ConfirmMeChangeEmailResponseToJSON(value?: ConfirmMeChangeEmailResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetMeResponseDataToJSON(value.data)
  }
}
