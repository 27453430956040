import React from 'react'
import { Box } from '@material-ui/core'
import { Colors } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { TeamInfoList } from 'src/components/organisms/TeamInfoList'
import { Header } from 'src/components/organisms'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'

export const PageTeamInfo = () => {
  const { data } = useGetOrganization()
  const organization = data?.organization
  return (
    <LayoutTeam>
      <Box width={1} bgcolor={Colors.functional.background.default} px="16px">
        <Header icon="roomPreferences" title="チーム情報" back={false} textSize="large" iconSize="32px" />
        <Box px="16px">{organization && <TeamInfoList organization={organization} />}</Box>
      </Box>
    </LayoutTeam>
  )
}
