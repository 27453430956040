/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { DefualtImage, DefualtImageFromJSON, DefualtImageFromJSONTyped, DefualtImageToJSON } from './DefualtImage'

/**
 *
 * @export
 * @interface GetDefaultImagesResponseData
 */
export interface GetDefaultImagesResponseData {
  /**
   *
   * @type {Array<DefualtImage>}
   * @memberof GetDefaultImagesResponseData
   */
  defaultImages?: Array<DefualtImage>
}

export function GetDefaultImagesResponseDataFromJSON(json: any): GetDefaultImagesResponseData {
  return GetDefaultImagesResponseDataFromJSONTyped(json, false)
}

export function GetDefaultImagesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDefaultImagesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    defaultImages: !exists(json, 'default_images')
      ? undefined
      : (json['default_images'] as Array<any>).map(DefualtImageFromJSON)
  }
}

export function GetDefaultImagesResponseDataToJSON(value?: GetDefaultImagesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    default_images:
      value.defaultImages === undefined ? undefined : (value.defaultImages as Array<any>).map(DefualtImageToJSON)
  }
}
