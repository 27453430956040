/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SigninRequest
 */
export interface SigninRequest {
  /**
   *
   * @type {string}
   * @memberof SigninRequest
   */
  organizationSubdomain?: string
  /**
   *
   * @type {string}
   * @memberof SigninRequest
   */
  encryptedEmail?: string
  /**
   *
   * @type {string}
   * @memberof SigninRequest
   */
  password?: string
}

export function SigninRequestFromJSON(json: any): SigninRequest {
  return SigninRequestFromJSONTyped(json, false)
}

export function SigninRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigninRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationSubdomain: !exists(json, 'organization_subdomain') ? undefined : json['organization_subdomain'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
    password: !exists(json, 'password') ? undefined : json['password']
  }
}

export function SigninRequestToJSON(value?: SigninRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_subdomain: value.organizationSubdomain,
    encrypted_email: value.encryptedEmail,
    password: value.password
  }
}
