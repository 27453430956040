/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationPolicySettingResponseData,
  UpdateOrganizationPolicySettingResponseDataFromJSON,
  UpdateOrganizationPolicySettingResponseDataFromJSONTyped,
  UpdateOrganizationPolicySettingResponseDataToJSON
} from './UpdateOrganizationPolicySettingResponseData'

/**
 *
 * @export
 * @interface UpdateOrganizationPolicySettingResponse
 */
export interface UpdateOrganizationPolicySettingResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateOrganizationPolicySettingResponse
   */
  status?: number
  /**
   *
   * @type {UpdateOrganizationPolicySettingResponseData}
   * @memberof UpdateOrganizationPolicySettingResponse
   */
  data?: UpdateOrganizationPolicySettingResponseData
}

export function UpdateOrganizationPolicySettingResponseFromJSON(json: any): UpdateOrganizationPolicySettingResponse {
  return UpdateOrganizationPolicySettingResponseFromJSONTyped(json, false)
}

export function UpdateOrganizationPolicySettingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPolicySettingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : UpdateOrganizationPolicySettingResponseDataFromJSON(json['data'])
  }
}

export function UpdateOrganizationPolicySettingResponseToJSON(
  value?: UpdateOrganizationPolicySettingResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: UpdateOrganizationPolicySettingResponseDataToJSON(value.data)
  }
}
