/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateChatMessageAttachmentFileResponseData,
  CreateChatMessageAttachmentFileResponseDataFromJSON,
  CreateChatMessageAttachmentFileResponseDataFromJSONTyped,
  CreateChatMessageAttachmentFileResponseDataToJSON
} from './CreateChatMessageAttachmentFileResponseData'

/**
 *
 * @export
 * @interface CreateChatMessageAttachmentFileResponse
 */
export interface CreateChatMessageAttachmentFileResponse {
  /**
   *
   * @type {number}
   * @memberof CreateChatMessageAttachmentFileResponse
   */
  status: number
  /**
   *
   * @type {CreateChatMessageAttachmentFileResponseData}
   * @memberof CreateChatMessageAttachmentFileResponse
   */
  data: CreateChatMessageAttachmentFileResponseData
}

export function CreateChatMessageAttachmentFileResponseFromJSON(json: any): CreateChatMessageAttachmentFileResponse {
  return CreateChatMessageAttachmentFileResponseFromJSONTyped(json, false)
}

export function CreateChatMessageAttachmentFileResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateChatMessageAttachmentFileResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: CreateChatMessageAttachmentFileResponseDataFromJSON(json['data'])
  }
}

export function CreateChatMessageAttachmentFileResponseToJSON(
  value?: CreateChatMessageAttachmentFileResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CreateChatMessageAttachmentFileResponseDataToJSON(value.data)
  }
}
