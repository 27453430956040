/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SiteFromJSON, SiteToJSON } from './Site';
export function GetSiteResponseDataFromJSON(json) {
    return GetSiteResponseDataFromJSONTyped(json, false);
}
export function GetSiteResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        site: !exists(json, 'site') ? undefined : SiteFromJSON(json['site'])
    };
}
export function GetSiteResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        site: SiteToJSON(value.site)
    };
}
