/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumRecordPorterHistoryStep = {
    FileCheck: 'file_check',
    Import: 'import',
    Export: 'export',
    BulkUpdate: 'bulk_update'
};
export function EnumRecordPorterHistoryStepFromJSON(json) {
    return EnumRecordPorterHistoryStepFromJSONTyped(json, false);
}
export function EnumRecordPorterHistoryStepFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumRecordPorterHistoryStepToJSON(value) {
    return value;
}
