/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationAdvertisementRequestOrganization,
  UpdateOrganizationAdvertisementRequestOrganizationFromJSON,
  UpdateOrganizationAdvertisementRequestOrganizationFromJSONTyped,
  UpdateOrganizationAdvertisementRequestOrganizationToJSON
} from './UpdateOrganizationAdvertisementRequestOrganization'

/**
 *
 * @export
 * @interface UpdateOrganizationAdvertisementRequest
 */
export interface UpdateOrganizationAdvertisementRequest {
  /**
   *
   * @type {UpdateOrganizationAdvertisementRequestOrganization}
   * @memberof UpdateOrganizationAdvertisementRequest
   */
  organization?: UpdateOrganizationAdvertisementRequestOrganization
}

export function UpdateOrganizationAdvertisementRequestFromJSON(json: any): UpdateOrganizationAdvertisementRequest {
  return UpdateOrganizationAdvertisementRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationAdvertisementRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationAdvertisementRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organization: !exists(json, 'organization')
      ? undefined
      : UpdateOrganizationAdvertisementRequestOrganizationFromJSON(json['organization'])
  }
}

export function UpdateOrganizationAdvertisementRequestToJSON(
  value?: UpdateOrganizationAdvertisementRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization: UpdateOrganizationAdvertisementRequestOrganizationToJSON(value.organization)
  }
}
