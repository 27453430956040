/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { MailTemplate, MailTemplateFromJSON, MailTemplateFromJSONTyped, MailTemplateToJSON } from './MailTemplate'
import {
  MailTemplateVariableObject,
  MailTemplateVariableObjectFromJSON,
  MailTemplateVariableObjectFromJSONTyped,
  MailTemplateVariableObjectToJSON
} from './MailTemplateVariableObject'

/**
 *
 * @export
 * @interface DeleteMailTemplateResponseData
 */
export interface DeleteMailTemplateResponseData {
  /**
   *
   * @type {MailTemplate}
   * @memberof DeleteMailTemplateResponseData
   */
  mailTemplate?: MailTemplate
  /**
   *
   * @type {Array<MailTemplateVariableObject>}
   * @memberof DeleteMailTemplateResponseData
   */
  mailTemplateVariableObjects?: Array<MailTemplateVariableObject>
}

export function DeleteMailTemplateResponseDataFromJSON(json: any): DeleteMailTemplateResponseData {
  return DeleteMailTemplateResponseDataFromJSONTyped(json, false)
}

export function DeleteMailTemplateResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteMailTemplateResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mailTemplate: !exists(json, 'mail_template') ? undefined : MailTemplateFromJSON(json['mail_template']),
    mailTemplateVariableObjects: !exists(json, 'mail_template_variable_objects')
      ? undefined
      : (json['mail_template_variable_objects'] as Array<any>).map(MailTemplateVariableObjectFromJSON)
  }
}

export function DeleteMailTemplateResponseDataToJSON(value?: DeleteMailTemplateResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mail_template: MailTemplateToJSON(value.mailTemplate),
    mail_template_variable_objects:
      value.mailTemplateVariableObjects === undefined
        ? undefined
        : (value.mailTemplateVariableObjects as Array<any>).map(MailTemplateVariableObjectToJSON)
  }
}
