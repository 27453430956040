import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Export = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.334 17.1604H14.984V25.4937C14.984 26.4104 15.734 27.1604 16.6507 27.1604H23.3173C24.234 27.1604 24.984 26.4104 24.984 25.4937V17.1604H27.634C29.1173 17.1604 29.8673 15.3604 28.8173 14.3104L21.1673 6.66041C20.5173 6.01041 19.4673 6.01041 18.8173 6.66041L11.1673 14.3104C10.1173 15.3604 10.8507 17.1604 12.334 17.1604ZM8.33398 32.1604C8.33398 33.0771 9.08398 33.8271 10.0007 33.8271H30.0007C30.9173 33.8271 31.6673 33.0771 31.6673 32.1604C31.6673 31.2437 30.9173 30.4937 30.0007 30.4937H10.0007C9.08398 30.4937 8.33398 31.2437 8.33398 32.1604Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84154C1.50202 1.98324 2.89359 1.98324 3.75188 2.84154L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.9497C-0.214574 5.0914 -0.214574 3.69983 0.643721 2.84154Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default Export
