import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import * as Base from './SvgComponents'

export type IconVariant =
  | 'avatar'
  | 'company'
  | 'removeCircle'
  | 'setting'
  | 'leftArrow'
  | 'rightArrow'
  | 'pulldown'
  | 'close'
  | 'checkedIcon'
  | 'checkList'
  | 'contentPaste'
  | 'editCalendar'
  | 'share'
  | 'uploadFile'
  | 'addBox'
  | 'addSquare'
  | 'viewModule'
  | 'openInFull'
  | 'people'
  | 'noco'
  | 'note'
  | 'youtube'
  | 'facebook'
  | 'twitter'
  | 'link'
  | 'glitter'
  | 'rocket'
  | 'adsClick'
  | 'circleLine'
  | 'circleNote'
  | 'circleTwitter'
  | 'editNote'
  | 'guide'

export interface IconProps {
  variant: IconVariant
  size?: number | string
  color?: string
}

const useStyles = makeStyles((_: Theme) => {
  return createStyles({
    root: {
      verticalAlign: 'middle'
    }
  })
})

const DEFAULT_SIZE = '32px'
const DEFAULT_COLOR = '#ffffff'

export const SvgIcon = (props: IconProps) => {
  const { size, variant, color } = props
  const classes = useStyles(props)
  const inlineStyle = {
    fontSize: size || DEFAULT_SIZE,
    color: color || DEFAULT_COLOR
  }

  switch (variant) {
    case 'avatar':
      return <Base.Avatar classes={classes} style={inlineStyle} />
    case 'company':
      return <Base.Company classes={classes} style={inlineStyle} />
    case 'removeCircle':
      return <Base.RemoveCircle classes={classes} style={inlineStyle} />
    case 'setting':
      return <Base.Setting classes={classes} style={inlineStyle} />
    case 'leftArrow':
      return <Base.LeftArrow classes={classes} style={inlineStyle} />
    case 'rightArrow':
      return <Base.RightArrow classes={classes} style={inlineStyle} />
    case 'close':
      return <Base.Close classes={classes} style={inlineStyle} />
    case 'pulldown':
      return <Base.Pulldown classes={classes} style={inlineStyle} />
    case 'checkedIcon':
      return <Base.CheckedIcon classes={classes} style={inlineStyle} />
    case 'checkList':
      return <Base.CheckList classes={classes} style={inlineStyle} />
    case 'contentPaste':
      return <Base.ContentPaste classes={classes} style={inlineStyle} />
    case 'share':
      return <Base.Share classes={classes} style={inlineStyle} />
    case 'uploadFile':
      return <Base.UploadFile classes={classes} style={inlineStyle} />
    case 'addBox':
      return <Base.AddBox classes={classes} style={inlineStyle} />
    case 'addSquare':
      return <Base.AddSquare classes={classes} style={inlineStyle} />
    case 'viewModule':
      return <Base.ViewModule classes={classes} style={inlineStyle} />
    case 'openInFull':
      return <Base.OpenInFull classes={classes} style={inlineStyle} />
    case 'people':
      return <Base.People classes={classes} style={inlineStyle} />
    case 'noco':
      return <Base.NocoLogo classes={classes} style={inlineStyle} />
    case 'note':
      return <Base.Note classes={classes} style={inlineStyle} />
    case 'youtube':
      return <Base.Youtube classes={classes} style={inlineStyle} />
    case 'facebook':
      return <Base.Facebook classes={classes} style={inlineStyle} color={color} />
    case 'twitter':
      return <Base.Twitter classes={classes} style={inlineStyle} />
    case 'link':
      return <Base.Link classes={classes} style={inlineStyle} />
    case 'glitter':
      return <Base.Glitter classes={classes} style={inlineStyle} />
    case 'rocket':
      return <Base.Rocket classes={classes} style={inlineStyle} />
    case 'editCalendar':
      return <Base.EditCalendar classes={classes} style={inlineStyle} />
    case 'adsClick':
      return <Base.AdsClick classes={classes} style={inlineStyle} />
    case 'circleLine':
      return <Base.CircleLine classes={classes} style={inlineStyle} />
    case 'circleNote':
      return <Base.CircleNote classes={classes} style={inlineStyle} />
    case 'circleTwitter':
      return <Base.CircleTwitter classes={classes} style={inlineStyle} />
    case 'editNote':
      return <Base.EditNote classes={classes} style={inlineStyle} />
    case 'guide':
      return <Base.Guide classes={classes} style={inlineStyle} />
  }
}
