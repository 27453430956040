import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, PaymentMethod, Stripe, StripeCardElement } from '@stripe/stripe-js'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { createStyles, makeStyles, Box, Modal, ButtonBase, Slide } from '@material-ui/core'
import { Button, DynamicMuiIcon, Toaster, Typography, Colors, Pallete } from 'src/components/atoms'
import { SupportedCreditCardBrand } from 'src/components/molecules/SupportedCreditCardBrand'
import { CardForm } from './CardForm'

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY || '')

export interface ModalCreditCardFormProps {
  open: boolean
  width?: string
  onClose: () => void
  onSubmit?: (paymentMethod: PaymentMethod) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: Colors.base.black
    }
  })
)

export const ModalCreditCardForm = ({
  open,
  width = '580px',
  onClose,
  onSubmit
}: PropsWithChildren<ModalCreditCardFormProps>) => {
  const [stripe, setStripe] = useState<Stripe | null>()
  const [cardElement, setCardElement] = useState<StripeCardElement | null>()
  const classes = useStyles()

  const onReady = useCallback((stripe, cardElement) => {
    setStripe(stripe)
    setCardElement(cardElement)
  }, [])

  const onClick = useCallback(async () => {
    if (stripe == null || cardElement == null) {
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })
    if (error || !paymentMethod) {
      Toaster.error(error?.message || 'カード情報の登録に失敗しました')
      return
    }

    onSubmit?.(paymentMethod)
  }, [stripe, cardElement, onSubmit])

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={width}
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.silver}`}
          overflow="hidden"
        >
          <Box
            px="24px"
            py="16px"
            borderBottom={`1px solid ${Colors.background.silver}`}
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h3">クレジットカードのご登録</Typography>
            </Box>
            <ButtonBase onClick={onClose}>
              <DynamicMuiIcon variant="close" color="action" size="28px" />
            </ButtonBase>
          </Box>

          <Box p="24px">
            <Box mb="16px">
              <SupportedCreditCardBrand />
            </Box>
            <Box>
              <Box mb="8px">
                <Typography fontSize="s" fontWeight="bold">
                  カード情報
                </Typography>
              </Box>
              <Elements stripe={stripePromise}>
                <CardForm onReady={onReady} />
              </Elements>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            py="12px"
            bgcolor={Colors.background.lightGray}
            boxShadow={`inset 0px 1px 0px ${Colors.background.silver}`}
          >
            <Box display="flex" mr="24px">
              <Button kind="neutral" variant="outlined" title="キャンセル" onClick={onClose} />
              <Box ml="12px" />
              <Button title="登録" onClick={onClick} />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
