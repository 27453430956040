/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GetUsersResponseDataUsersInnerUserAvatarFromJSON, GetUsersResponseDataUsersInnerUserAvatarToJSON } from './GetUsersResponseDataUsersInnerUserAvatar';
export function OrganizationChatSettingFromJSON(json) {
    return OrganizationChatSettingFromJSONTyped(json, false);
}
export function OrganizationChatSettingFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        autoResponseName: !exists(json, 'auto_response_name') ? undefined : json['auto_response_name'],
        avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
        chatColor: !exists(json, 'chat_color') ? undefined : json['chat_color'],
        chatButtonImage: !exists(json, 'chat_button_image')
            ? undefined
            : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['chat_button_image']),
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function OrganizationChatSettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        auto_response_name: value.autoResponseName,
        avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
        chat_color: value.chatColor,
        chat_button_image: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.chatButtonImage),
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
