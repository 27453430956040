import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Button } from 'src/components/atoms'
import { PaymentCompletedContractCard } from 'src/components/molecules'

export interface ContractFourthStepProps {
  isContract?: boolean
  onConfirm: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      padding: '0 24px'
    }
  })
)

export const ContractFourthStep = ({ isContract = false, onConfirm }: ContractFourthStepProps) => {
  const classes = useStyles()
  const confirmMessage = isContract
    ? 'ご契約ありがとうございました！\n決済が完了しました'
    : 'プランを変更しました！\n引き続きノコセルをご活用ください'

  return (
    <Box>
      <Box mb="24px">
        <PaymentCompletedContractCard message={confirmMessage} />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          tabIndex={-1}
          className={classes.submitButton}
          title="ご契約内容へ移動する"
          onClick={() => onConfirm()}
          width="217px"
          size="large"
        />
      </Box>
    </Box>
  )
}
