/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { BillingInvoiceDiscountFromJSON, BillingInvoiceDiscountToJSON } from './BillingInvoiceDiscount';
import { BillingInvoiceItemFromJSON, BillingInvoiceItemToJSON } from './BillingInvoiceItem';
export function BillingInvoiceFromJSON(json) {
    return BillingInvoiceFromJSONTyped(json, false);
}
export function BillingInvoiceFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        items: json['items'].map(BillingInvoiceItemFromJSON),
        subtotal: json['subtotal'],
        tax: json['tax'],
        total: json['total'],
        discount: !exists(json, 'discount') ? undefined : BillingInvoiceDiscountFromJSON(json['discount'])
    };
}
export function BillingInvoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        items: value.items.map(BillingInvoiceItemToJSON),
        subtotal: value.subtotal,
        tax: value.tax,
        total: value.total,
        discount: BillingInvoiceDiscountToJSON(value.discount)
    };
}
