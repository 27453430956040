/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AuthContactMagicLinkLoginRequest
 */
export interface AuthContactMagicLinkLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AuthContactMagicLinkLoginRequest
   */
  token?: string
  /**
   *
   * @type {string}
   * @memberof AuthContactMagicLinkLoginRequest
   */
  encryptedEmail?: string
}

export function AuthContactMagicLinkLoginRequestFromJSON(json: any): AuthContactMagicLinkLoginRequest {
  return AuthContactMagicLinkLoginRequestFromJSONTyped(json, false)
}

export function AuthContactMagicLinkLoginRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthContactMagicLinkLoginRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    token: !exists(json, 'token') ? undefined : json['token'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email']
  }
}

export function AuthContactMagicLinkLoginRequestToJSON(value?: AuthContactMagicLinkLoginRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    token: value.token,
    encrypted_email: value.encryptedEmail
  }
}
