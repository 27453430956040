import { Box } from '@material-ui/core'
import { OrganizationPaymentCardInfoBrandEnum } from '@noco/http-client/lib/noco'
import { PaymentMethod } from '@stripe/stripe-js'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { Button, Card, Typography } from 'src/components/atoms'
import { ModalCreditCardForm } from 'src/components/modals'
import { CreditCardDetail, CreditCardProps, SupportedCreditCardBrand } from 'src/components/molecules'

export interface PaymentMethodProps {
  stripePaymentMethodId?: string
  creditCard?: CreditCardProps
}

export interface PaymentMethodCardProps {
  paymentMethod?: PaymentMethodProps
  onChange?: (paymentMethod: PaymentMethodProps) => void
  actionComponent?: ReactElement
}

export const PaymentMethodCard = ({ paymentMethod, onChange, actionComponent }: PaymentMethodCardProps) => {
  const [openCreditCardModal, setOpenCreditCardModal] = useState(false)
  const openModal = useCallback(() => {
    setOpenCreditCardModal(true)
  }, [])
  const handleCloseModal = useCallback(() => {
    setOpenCreditCardModal(false)
  }, [])
  const handleSubmitModal = useCallback(
    (paymentMethod: PaymentMethod) => {
      const data: PaymentMethodProps = {
        stripePaymentMethodId: paymentMethod.id,
        creditCard: {
          last4: paymentMethod.card?.last4,
          brand: paymentMethod.card?.brand as OrganizationPaymentCardInfoBrandEnum,
          expYear: paymentMethod.card?.exp_year,
          expMonth: paymentMethod.card?.exp_month
        }
      }
      onChange?.(data)
      setOpenCreditCardModal(false)
    },
    [onChange]
  )
  const hasPaymentmethod = useMemo(() => {
    return paymentMethod?.stripePaymentMethodId && paymentMethod?.creditCard
  }, [paymentMethod?.stripePaymentMethodId, paymentMethod?.creditCard])

  const customComponent = useMemo<ReactElement | undefined>(() => {
    if (actionComponent) {
      return actionComponent
    } else {
      return hasPaymentmethod ? (
        <Button hasBorder={false} title="変更" kind="secondary" variant="outlined" size="small" onClick={openModal} />
      ) : undefined
    }
  }, [actionComponent, hasPaymentmethod, openModal])

  return (
    <Card label="お支払い情報" actionComponent={customComponent} sx={{ bgcolor: '#fff' }}>
      {hasPaymentmethod ? (
        <Typography fontSize="l" fontWeight="bold" lineheight="150%">
          <CreditCardDetail {...paymentMethod?.creditCard} />
        </Typography>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box flex="1" mr="12px">
            <SupportedCreditCardBrand />
          </Box>
          <Box flexShrink="0">
            <Button title="クレジットカード情報を入力" onClick={openModal} />
          </Box>
        </Box>
      )}

      <ModalCreditCardForm open={openCreditCardModal} onClose={handleCloseModal} onSubmit={handleSubmitModal} />
    </Card>
  )
}
