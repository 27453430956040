/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CancelContactChatMessageResponse,
  CancelContactChatMessageResponseFromJSON,
  CancelContactChatMessageResponseToJSON,
  CreateContactChatMessageRequest,
  CreateContactChatMessageRequestFromJSON,
  CreateContactChatMessageRequestToJSON,
  CreateContactChatMessageResponse,
  CreateContactChatMessageResponseFromJSON,
  CreateContactChatMessageResponseToJSON,
  GetContactChatMessagesResponse,
  GetContactChatMessagesResponseFromJSON,
  GetContactChatMessagesResponseToJSON
} from '../models'

export interface UserV1ChatMessagesChatMessageIdCancelPutRequest {
  chatMessageId: string
}

export interface UserV1ContactsContactIdChatMessagesGetRequest {
  contactId: string
  fromAt?: string
  page?: number
  per?: number
}

export interface UserV1ContactsContactIdChatMessagesPostRequest {
  contactId: string
  createContactChatMessageRequest?: CreateContactChatMessageRequest
}

/**
 *
 */
export class ApiUserV1ContactChatMessageApi extends runtime.BaseAPI {
  /**
   * チャットメッセージをキャンセルする
   * cancel
   */
  async userV1ChatMessagesChatMessageIdCancelPutRaw(
    requestParameters: UserV1ChatMessagesChatMessageIdCancelPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CancelContactChatMessageResponse>> {
    if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
      throw new runtime.RequiredError(
        'chatMessageId',
        'Required parameter requestParameters.chatMessageId was null or undefined when calling userV1ChatMessagesChatMessageIdCancelPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/chat_messages/{chat_message_id}/cancel`.replace(
          `{${'chat_message_id'}}`,
          encodeURIComponent(String(requestParameters.chatMessageId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CancelContactChatMessageResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージをキャンセルする
   * cancel
   */
  async userV1ChatMessagesChatMessageIdCancelPut(
    requestParameters: UserV1ChatMessagesChatMessageIdCancelPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CancelContactChatMessageResponse> {
    const response = await this.userV1ChatMessagesChatMessageIdCancelPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャットメッセージを取得する
   * index
   */
  async userV1ContactsContactIdChatMessagesGetRaw(
    requestParameters: UserV1ContactsContactIdChatMessagesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactChatMessagesResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdChatMessagesGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.fromAt !== undefined) {
      queryParameters['from_at'] = requestParameters.fromAt
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/chat_messages`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactChatMessagesResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージを取得する
   * index
   */
  async userV1ContactsContactIdChatMessagesGet(
    requestParameters: UserV1ContactsContactIdChatMessagesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactChatMessagesResponse> {
    const response = await this.userV1ContactsContactIdChatMessagesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャットメッセージを作成する
   * create
   */
  async userV1ContactsContactIdChatMessagesPostRaw(
    requestParameters: UserV1ContactsContactIdChatMessagesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateContactChatMessageResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdChatMessagesPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/chat_messages`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateContactChatMessageRequestToJSON(requestParameters.createContactChatMessageRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateContactChatMessageResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージを作成する
   * create
   */
  async userV1ContactsContactIdChatMessagesPost(
    requestParameters: UserV1ContactsContactIdChatMessagesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateContactChatMessageResponse> {
    const response = await this.userV1ContactsContactIdChatMessagesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
