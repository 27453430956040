/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ChatMessageAttachmentIdTypeFromJSON, ChatMessageAttachmentIdTypeToJSON } from './ChatMessageAttachmentIdType';
export function CreateContactChatMessageRequestAttachableObjectsInnerFromJSON(json) {
    return CreateContactChatMessageRequestAttachableObjectsInnerFromJSONTyped(json, false);
}
export function CreateContactChatMessageRequestAttachableObjectsInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: !exists(json, 'type') ? undefined : ChatMessageAttachmentIdTypeFromJSON(json['type']),
        id: !exists(json, 'id') ? undefined : json['id'],
        sortNumber: !exists(json, 'sort_number') ? undefined : json['sort_number']
    };
}
export function CreateContactChatMessageRequestAttachableObjectsInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        type: ChatMessageAttachmentIdTypeToJSON(value.type),
        id: value.id,
        sort_number: value.sortNumber
    };
}
