import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const RemoveCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.83317 10.0001C5.83317 10.4584 6.20817 10.8334 6.6665 10.8334H13.3332C13.7915 10.8334 14.1665 10.4584 14.1665 10.0001C14.1665 9.54175 13.7915 9.16675 13.3332 9.16675H6.6665C6.20817 9.16675 5.83317 9.54175 5.83317 10.0001ZM9.99984 1.66675C5.39984 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.39984 18.3334 9.99984 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.99984 1.66675ZM9.99984 16.6667C6.32484 16.6667 3.33317 13.6751 3.33317 10.0001C3.33317 6.32508 6.32484 3.33341 9.99984 3.33341C13.6748 3.33341 16.6665 6.32508 16.6665 10.0001C16.6665 13.6751 13.6748 16.6667 9.99984 16.6667Z" />
    </SvgIcon>
  )
}
export default RemoveCircle
