import { Box, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { Header, PricingTable } from 'src/components/organisms'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { useGetOrganizationStatus } from 'src/fixtures/modules/organization/hooks'
import { UserOptionPricing } from './UserOptionPriing'
import {
  useGetOrganizationPaymentCurrentPlan,
  useListOrganizationPaymentPlans
} from 'src/fixtures/modules/organizationPayment/hooks'
import { useRouter } from 'next/router'
import { ModalLeadToPaidPlan } from 'src/components/modals'
import { OrganizationPlanDetail } from '@noco/http-client/lib/noco'

const paidPlanPrecautionList = [
  'クレジットカードによる毎月１回分のお引き落としになります',
  '決済完了と同時にサービスの提供を開始し、当社との契約期間中、継続的にサービスを提供いたします',
  '有料プランは、お客様から解約のご連絡がない限り、お手続き無く、自動的に契約が更新されます',
  'プランのアップグレードはご契約期間中いつでも行なえます。プランのダウングレードは契約更新日の1ヶ月前よりお手続きいただけます',
  '契約終了日時の1時間前までにサービス内でご解約手続きすることにより、契約期間末日をもって契約をご解約することができます。契約期間途中でのご解約を行うことはできません',
  'サービスご利用に関する個別のご契約書、秘密保持契約書（NDA）や覚書、業務委託契約書等の締結を取り交わすことはできません',
  '個別のセキュリティチェックをご希望される場合は、事務手数料 100,000円/回（税別）にてご対応しております。詳しくはサポートデスクまでお問い合わせください',
  '弊社による審査の結果によっては契約を締結できない可能性があります'
]

const useStyles = makeStyles(() =>
  createStyles({
    precautionList: {
      paddingInlineStart: '24px'
    },
    precautionListItem: {
      '&:not(&:last-child)': {
        marginBottom: '20px'
      }
    },
    comparisonLink: {
      padding: '0 24px'
    }
  })
)

export const PagePricing = () => {
  const classes = useStyles()
  const router = useRouter()
  const [plan, setPlan] = useState<OrganizationPlanDetail | undefined>()
  const [open, setOpen] = useState<boolean>(false)
  const { data: me } = useGetMe()
  const { data: plansData } = useListOrganizationPaymentPlans()
  const selectablePlanList = plansData?.plans
  const { data: currentPlanData } = useGetOrganizationPaymentCurrentPlan()
  const currentPlan = currentPlanData?.plan
  const currentPlanCoupon = currentPlanData?.coupon
  const { status } = useGetOrganizationStatus()

  const handleRouterTo = useCallback(
    (id: string) => {
      router.push('/setting/plans/[id]', `/setting/plans/${id}`)
    },
    [router]
  )
  const handleSelectPlan = useCallback(
    (plan: OrganizationPlanDetail) => {
      setPlan(plan)
      switch (plan.grade) {
        case 'light':
          return handleRouterTo(plan.id)
        case 'standard_new':
          return handleRouterTo(plan.id)
        case 'premium':
          return handleRouterTo(plan.id)
        default:
          return
      }
    },
    [handleRouterTo]
  )
  const handleCloseModal = useCallback(() => {
    setPlan(undefined)
    setOpen(false)
  }, [])

  return (
    <LayoutTeam>
      <Box width="100%" bgcolor={Colors.functional.background.default} padding="0 16px 5vh 16px" overflow="hidden auto">
        <Header icon="map" title="プラン表" back={false} textSize="large" iconSize="32px" />
        <Box px="16px">
          <Box width="100%" maxWidth="980px">
            <Box mb="40px">
              {currentPlan && selectablePlanList && (
                <PricingTable
                  currentPlan={currentPlan}
                  targetPlans={selectablePlanList}
                  isAdmin={me?.user?.permission === 'administrator'}
                  onSelectPlan={handleSelectPlan}
                  status={status}
                  isCouponApplied={currentPlanCoupon}
                />
              )}
              <Box display="flex" flexDirection="column" alignItems="flex-end" pt="16px">
                <Typography fontSize="s" lineheight="1.32">
                  ※ 料金は税別です
                </Typography>
                <Typography fontSize="s" lineheight="1.32">
                  ※ 有料プランのご契約期間は1か月間です
                </Typography>
                <Box mt="16px">
                  <Button
                    kind="secondary"
                    variant="outlined"
                    size="large"
                    startIcon={<DynamicMuiIcon variant="openNew" size="20px" />}
                    title="プラン別の機能一覧はこちら"
                    className={classes.comparisonLink}
                    onClick={() => window.open(`https://noco.sale/comparison`, '_blank')}
                  />
                </Box>
              </Box>
            </Box>

            <Box mb="40px">
              <Box mb="16px">
                <Typography fontSize="m" fontWeight="bold" lineheight="1">
                  ユーザー枠の追加料金
                </Typography>
              </Box>
              <Box mb="16px">
                <Typography fontSize="s" lineheight="1.32">
                  ご利用される人数に応じて、ご利用ユーザー枠を追加ください （プレミアムプラン以上が対象です）
                </Typography>
              </Box>
              <UserOptionPricing />
              <Box textAlign="right" pt="16px">
                <Typography fontSize="s" lineheight="1.32">
                  ※ 料金は税別です
                </Typography>
              </Box>
            </Box>

            <Box>
              <Box mb="16px">
                <Typography fontSize="m" fontWeight="bold" lineheight="1">
                  有料プランのご契約に関する注意事項
                </Typography>
              </Box>
              <Box p="16px" borderRadius="6px" bgcolor={Colors.background.lightGray}>
                <ul className={classes.precautionList}>
                  {paidPlanPrecautionList.map((precaution, i) => (
                    <li key={i} className={classes.precautionListItem}>
                      <Typography fontSize="s" fontWeight="bold" lineheight="1.32" letterSpacing="tight">
                        {precaution}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalLeadToPaidPlan open={open} onClose={handleCloseModal} isVisibleContractFrePlaneButton={!!plan} />
    </LayoutTeam>
  )
}
