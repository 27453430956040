import { Box, createStyles, FormControl, makeStyles, Select, MenuItem, MenuProps, Typography } from '@material-ui/core'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import React, { ReactElement, useMemo } from 'react'
import { Colors, Pallete } from '../Colors'
import { SvgIcon } from '../SvgIcon'

export interface OptionListType {
  value: string | number
  label?: string
  avatar?: string
  startElement?: ReactElement
  disabled?: boolean
}

export interface SelectBoxProps {
  placeholder?: string
  selectablePlaceholder?: boolean
  value: string | string[] | number
  multiple?: boolean
  name?: string
  optionList: OptionListType[]
  height?: string | number
  size?: 'small' | 'medium'
  listWidth?: string
  onChange: SelectInputProps['onChange']
  placeholderIcon?: ReactElement
  disabled?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: ({ listWidth }: SelectBoxProps) => {
        return listWidth!
      },
      height: ({ height, size = 'small' }: SelectBoxProps) => {
        if (height !== undefined) return height
        switch (size) {
          case 'small':
            return '28px'
          case 'medium':
            return '34px'
          default:
            return '28px'
        }
      },
      background: `${Pallete.functional.background.default}`,
      '& fieldset': {
        borderRadius: '4px',
        border: ({ disabled = false }: SelectBoxProps) => (disabled ? 'none' : `1px solid ${Colors.background.silver}`)
      },
      '& .MuiOutlinedInput-root': {
        height: '100%',
        '&.Mui-focused fieldset': {
          border: `1px solid ${Colors.base.black}`
        },
        '&:hover fieldset': {
          border: ({ disabled = false }: SelectBoxProps) => (disabled ? 'none' : `1px solid ${Colors.base.black}`)
        },
        '&.Mui-disabled': {
          background: Colors.background.gray
        }
      }
    },
    selectRoot: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: '12px !important',
      color: ({ value }: SelectBoxProps) => (value ? 'inherit' : Colors.base.placeHolder),
      '&.MuiSelect-select:focus': {
        background: `${Pallete.functional.background.default}`
      }
    },
    menuRoot: {
      '&.MuiListItem-root': {
        minHeight: 0,
        zIndex: 9999,
        height: '34px',
        '&.Mui-selected': {
          background: `${Colors.background.lightGray}`
        },
        '&.Mui-focusVisible': {
          background: `${Colors.background.lightGray}`
        },
        '&.Mui-selected:hover': {
          background: `${Colors.background.lightGray}`
        },
        '&:hover': {
          background: `${Colors.background.lightGray}`
        },
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px'
        }
      }
    },
    textTruncate: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      lineHeight: 1
    }
  })
)

const IconComponentContents: React.VFC = () => (
  <Box
    position="absolute"
    right={0}
    width="28px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    style={{ pointerEvents: 'none' }}
  >
    <SvgIcon variant="pulldown" size="10px" color={Colors.base.black} />
  </Box>
)

export const SelectBox = (props: SelectBoxProps) => {
  const {
    placeholder,
    selectablePlaceholder = false,
    value,
    name,
    optionList,

    onChange,
    multiple = false,
    placeholderIcon,
    disabled = false
  } = props
  const classes = useStyles(props)

  const menuProps: Partial<MenuProps> = useMemo(() => {
    return {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      getContentAnchorEl: null,
      marginThreshold: 12,
      PaperProps: {
        style: {
          marginTop: '7px',
          border: `1px solid ${Colors.accent.keyPurple.default}`,
          maxHeight: '216px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)'
        }
      }
    }
  }, [])

  const optionListWithPlaceholder = useMemo(() => {
    if (!placeholder) return optionList

    return [
      {
        value: '',
        label: placeholder,
        startElement: placeholderIcon,
        disabled: !selectablePlaceholder
      },
      ...optionList
    ]
  }, [placeholder, placeholderIcon, optionList, selectablePlaceholder])

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <Select
        classes={{ root: classes.selectRoot }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        IconComponent={IconComponentContents}
        displayEmpty
        multiple={multiple}
        MenuProps={menuProps}
        inputProps={{
          name: name,
          id: `${name}-label`,
          'aria-label': 'Without label'
        }}
        disabled={disabled}
      >
        {optionListWithPlaceholder.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            classes={{
              root: classes.menuRoot
            }}
            disabled={option.disabled}
          >
            <Box display="flex" alignItems="center" width={1}>
              {option.startElement}
              <Box width="6px" />
              <Typography variant="subtitle1" className={classes.textTruncate}>
                {option.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
