/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner,
  UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerFromJSON,
  UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerFromJSONTyped,
  UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerToJSON
} from './UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner'

/**
 *
 * @export
 * @interface UpdateCompanySettingRequestCompanySettingFieldsInner
 */
export interface UpdateCompanySettingRequestCompanySettingFieldsInner {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanySettingRequestCompanySettingFieldsInner
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof UpdateCompanySettingRequestCompanySettingFieldsInner
   */
  name?: string
  /**
   *
   * @type {Array<UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner>}
   * @memberof UpdateCompanySettingRequestCompanySettingFieldsInner
   */
  companySettingFieldItems?: Array<UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner> | null
}

export function UpdateCompanySettingRequestCompanySettingFieldsInnerFromJSON(
  json: any
): UpdateCompanySettingRequestCompanySettingFieldsInner {
  return UpdateCompanySettingRequestCompanySettingFieldsInnerFromJSONTyped(json, false)
}

export function UpdateCompanySettingRequestCompanySettingFieldsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCompanySettingRequestCompanySettingFieldsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    companySettingFieldItems: !exists(json, 'company_setting_field_items')
      ? undefined
      : json['company_setting_field_items'] === null
      ? null
      : (json['company_setting_field_items'] as Array<any>).map(
          UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerFromJSON
        )
  }
}

export function UpdateCompanySettingRequestCompanySettingFieldsInnerToJSON(
  value?: UpdateCompanySettingRequestCompanySettingFieldsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    company_setting_field_items:
      value.companySettingFieldItems === undefined
        ? undefined
        : value.companySettingFieldItems === null
        ? null
        : (value.companySettingFieldItems as Array<any>).map(
            UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerToJSON
          )
  }
}
