import { EnumUserPermission, OrganizationStatusEnum } from '@noco/http-client/lib/noco'
import { isBefore, isValid } from 'date-fns'
import { GetServerSidePropsContext, NextPageContext, Redirect } from 'next'
import { getTokenFromCookie } from '.'
import { nocoSDK } from '../modules/initialize'
import { ErrorRedirect } from './error'

// 表示が制限されうるパターン
export type RestrictedReason = 'expiredTrial' | 'view' | 'expiredCoupon'

export interface userAuthenticationOptions {
  loginRequired?: boolean
  restricted?: {
    [key in RestrictedReason]?: { canRead: EnumUserPermission[] }
  }
}
export const userAuthenticate = async (
  ctx: NextPageContext | GetServerSidePropsContext,
  { loginRequired, restricted }: userAuthenticationOptions
) => {
  const { token } = getTokenFromCookie(ctx)
  let redirect: { redirect: Redirect } | undefined = undefined
  let permission: EnumUserPermission | undefined = undefined
  if (loginRequired) {
    if (!token) {
      redirect = ErrorRedirect.UserSignIn
      return { token, redirect }
    }
    nocoSDK.initalize()
    const meApi = nocoSDK.client?.userService.meApi
    const organizationApi = nocoSDK.client?.userService.organizationApi

    try {
      const res = await meApi?.ApiFactory(token).userV1MeGet()
      const me = res?.data?.user
      permission = me?.permission
      if (!me) redirect = ErrorRedirect.UserSignIn
      // ページ閲覧に制限がかかる場合
      if (restricted) {
        // NOTE: トライアルの有効期限切れの判定の場合
        // 管理者は閲覧することができるので、管理者以外の権限者が閲覧できるかどうかの判定に含まれる
        // 権限チェックの内容が増えてきた場合には promise all で check 検討
        const res = await organizationApi?.ApiFactory(token).userV1OrganizationGet()
        const organization = res?.data?.organization
        if (!organization || !me) return { token, redirect: ErrorRedirect.NotFound }
        const status = organization.status
        const isTrialing = status === OrganizationStatusEnum.Trialing
        const isPaused = status === OrganizationStatusEnum.Paused

        if (restricted.expiredTrial) {
          const { canRead } = restricted.expiredTrial
          const now = new Date()
          const trialEndAt = organization?.trialEndAt ? new Date(organization.trialEndAt) : undefined

          if (isTrialing) {
            // NOTE: 期限切れの判定
            // trialing にする場合に、前段の draft status ではデータが不要なので、trialEndAt が存在しない場合が存在する。
            // なので、その場合トライアル有効期限切れと同様の対応を行う
            // refs: https://github.com/nocoinc/noco-client/pull/1677#discussion_r918791551
            if (!trialEndAt || (trialEndAt && isValid(trialEndAt) && isBefore(trialEndAt, now))) {
              const permission = me.permission
              const isAdmin = permission === EnumUserPermission.Administrator
              const hasPermission = permission && canRead.includes(permission)

              // トライアル終了していたとしても、権限がある場合は閲覧可能
              if (hasPermission) return { token }

              // 管理者以外の場合は、Trial 終了ページに飛ばす。管理者の場合は plan ページにリダイレクトする。
              redirect = isAdmin ? ErrorRedirect.Pricing : ErrorRedirect.TrialEnd
              return { redirect, token }
            }
          }
        }

        // クーポン期限切れによりstatus pausedとなった場合
        if (restricted.expiredCoupon && isPaused) {
          const { canRead } = restricted.expiredCoupon
          const permission = me.permission
          const isAdmin = permission === EnumUserPermission.Administrator
          const hasPermission = permission && canRead.includes(permission)

          // status pausedとなったとしても、権限がある場合は閲覧可能
          if (hasPermission) return { token }
          // 管理者以外の場合は、Trial 終了ページに飛ばす。管理者の場合は plan ページにリダイレクトする。
          redirect = isAdmin ? ErrorRedirect.Pricing : ErrorRedirect.TrialEnd
          return { redirect, token }
        }
        // 単なる表示制限の場合
        if (restricted.view) {
          const permission = me?.permission
          const { canRead } = restricted.view
          const hasPermission = permission && canRead.includes(permission)
          if (hasPermission) return { token }
          return { redirect: ErrorRedirect.UserPermission, token }
        }
      }
    } catch (err) {
      if (err instanceof Response) {
        console.error(err)
        switch (err.status) {
          case 401:
            redirect = ErrorRedirect.UserSignIn
            break
          case 500:
            redirect = ErrorRedirect.InternalServerError
            break
          case 404:
            redirect = ErrorRedirect.NotFound
            break
          default:
            redirect = ErrorRedirect.UserSignIn
            break
        }
      }
      // Response 以外の時には runtime error
      else {
        redirect = ErrorRedirect.InternalServerError
      }
    }
  }
  return { token, redirect, permission }
}
