/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * 共有状態
 * @export
 */
export const EnumSharingStatus = {
    Open: 'open',
    Restricted: 'restricted'
};
export function EnumSharingStatusFromJSON(json) {
    return EnumSharingStatusFromJSONTyped(json, false);
}
export function EnumSharingStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumSharingStatusToJSON(value) {
    return value;
}
