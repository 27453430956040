import React from 'react'
import { Box, createStyles, makeStyles, ListItem } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography, MuiIconVariant, Tooltip } from 'src/components/atoms'
import { ExpandMore, ExpandLess } from './Icons'
import { Collapse } from './Collapse'
import { FilterContentPins, FilterContentPins1 } from '@noco/http-client/lib/noco'

export interface ExpandListProps {
  listTitle: string
  listTitleTip?: string
  subListTitle: string
  modalTitle: string
  pinsList: FilterContentPins[] | undefined
  allCount: number | undefined
  icon: MuiIconVariant
  open: boolean
  activeId: string
  activeType: string
  onListClick: () => void
  onLabelClick: (id: string | undefined, type: string) => () => void
  allLists: FilterContentPins1[]
  onMaterialListsPinConfirm?: (ids: string[], type?: string) => void
  materialableType: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    ListItem: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '4px',
      paddingBottom: '4px',
      height: 30,
      marginTop: '12px',
      '&:hover': {
        backgroundColor: Colors.selected.background,
        color: Colors.primary.default
      }
    }
  })
})

export const ExpandList = ({
  listTitle,
  listTitleTip = '',
  subListTitle,
  modalTitle,
  pinsList,
  allCount,
  icon,
  open,
  activeId,
  activeType,
  onListClick,
  onLabelClick,
  allLists,
  onMaterialListsPinConfirm,
  materialableType
}: ExpandListProps) => {
  const classes = useStyles()
  return (
    <>
      <Box px="14px">
        {listTitleTip ? (
          <Box width="fit-content">
            <Tooltip content={listTitleTip} placement="right">
              <Typography fontSize="m" lineheight="16px" fontWeight="bold">
                {listTitle}
              </Typography>
            </Tooltip>
          </Box>
        ) : (
          <Typography fontSize="m" lineheight="16px" fontWeight="bold">
            {listTitle}
          </Typography>
        )}
      </Box>
      <ListItem button className={classes.ListItem} onClick={onListClick}>
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} pl="14px" pr="11px">
          <Box display="flex" alignItems="center">
            <DynamicMuiIcon variant={icon} size="20px" color="action" />
            <Box mr="10px" />
            <Typography variant="body2">{subListTitle}</Typography>
          </Box>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItem>
      <Collapse
        open={open}
        pinsList={pinsList}
        allCount={allCount}
        modalTitle={modalTitle}
        icon={icon}
        onLabelClick={onLabelClick}
        activeId={activeId}
        activeType={activeType}
        allLists={allLists}
        onMaterialListsPinConfirm={onMaterialListsPinConfirm}
        materialableType={materialableType}
      />
    </>
  )
}
