/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ContactTimelinesResponseDataFromJSON, ContactTimelinesResponseDataToJSON } from './ContactTimelinesResponseData';
export function ContactTimelinesResponseFromJSON(json) {
    return ContactTimelinesResponseFromJSONTyped(json, false);
}
export function ContactTimelinesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        status: !exists(json, 'status') ? undefined : json['status'],
        data: !exists(json, 'data') ? undefined : ContactTimelinesResponseDataFromJSON(json['data'])
    };
}
export function ContactTimelinesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        status: value.status,
        data: ContactTimelinesResponseDataToJSON(value.data)
    };
}
