/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function MailTemplateFromJSON(json) {
    return MailTemplateFromJSONTyped(json, false);
}
export function MailTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json['id'],
        organizationId: json['organization_id'],
        name: json['name'],
        subject: json['subject'],
        body: json['body'],
        createdAt: json['created_at'],
        updatedAt: json['updated_at'],
        createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
        updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by'])
    };
}
export function MailTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        organization_id: value.organizationId,
        name: value.name,
        subject: value.subject,
        body: value.body,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        created_by: UserForDisplayToJSON(value.createdBy),
        updated_by: UserForDisplayToJSON(value.updatedBy)
    };
}
