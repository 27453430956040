import React, { ErrorInfo } from 'react'
import { PageError } from 'src/components/pages'
import { hasSentryAuthToken } from 'src/fixtures/utils/sentry'
class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: {}) {
    super(props)
    this.state = {
      hasError: false
    }
  }
  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (!hasSentryAuthToken) {
      console.warn('dosent have SentryAuthToken')
    }
    console.error(JSON.stringify({ error, errorInfo }))
  }

  render() {
    if (this.state.hasError) {
      return <PageError statusCode={500} />
    }
    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
