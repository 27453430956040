/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface ShallowContact
 */
export interface ShallowContact {
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   */
  encryptedEmail?: string
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   */
  firstName?: string | null
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   */
  lastName?: string | null
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   * @deprecated
   */
  status?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof ShallowContact
   */
  avatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ShallowContact
   */
  updatedAt?: string
}

export function ShallowContactFromJSON(json: any): ShallowContact {
  return ShallowContactFromJSONTyped(json, false)
}

export function ShallowContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShallowContact {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function ShallowContactToJSON(value?: ShallowContact | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    encrypted_email: value.encryptedEmail,
    first_name: value.firstName,
    last_name: value.lastName,
    display_name: value.displayName,
    status: value.status,
    avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
