/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumListResourceType = {
  Contact: 'contact',
  Company: 'company',
  InboxItem: 'inbox_item'
} as const
export type EnumListResourceType = typeof EnumListResourceType[keyof typeof EnumListResourceType]

export function EnumListResourceTypeFromJSON(json: any): EnumListResourceType {
  return EnumListResourceTypeFromJSONTyped(json, false)
}

export function EnumListResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumListResourceType {
  return json as EnumListResourceType
}

export function EnumListResourceTypeToJSON(value?: EnumListResourceType | null): any {
  return value as any
}
