/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GetUsersResponseDataUsersInnerUserAvatarFromJSON, GetUsersResponseDataUsersInnerUserAvatarToJSON } from './GetUsersResponseDataUsersInnerUserAvatar';
import { UserProfileFromJSON, UserProfileToJSON } from './UserProfile';
export function GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSON(json) {
    return GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSONTyped(json, false);
}
export function GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        opportunityToolUrl: !exists(json, 'opportunity_tool_url') ? undefined : json['opportunity_tool_url'],
        userName: !exists(json, 'user_name') ? undefined : json['user_name'],
        userAvatar: !exists(json, 'user_avatar')
            ? undefined
            : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['user_avatar']),
        organizationName: !exists(json, 'organization_name') ? undefined : json['organization_name'],
        userProfile: !exists(json, 'user_profile') ? undefined : UserProfileFromJSON(json['user_profile'])
    };
}
export function GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        opportunity_tool_url: value.opportunityToolUrl,
        user_name: value.userName,
        user_avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.userAvatar),
        organization_name: value.organizationName,
        user_profile: UserProfileToJSON(value.userProfile)
    };
}
