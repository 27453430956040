/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface InviteOrganizationResponseData
 */
export interface InviteOrganizationResponseData {
  /**
   *
   * @type {boolean}
   * @memberof InviteOrganizationResponseData
   */
  isFirstInvitedUser: boolean
}

export function InviteOrganizationResponseDataFromJSON(json: any): InviteOrganizationResponseData {
  return InviteOrganizationResponseDataFromJSONTyped(json, false)
}

export function InviteOrganizationResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteOrganizationResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    isFirstInvitedUser: json['is_first_invited_user']
  }
}

export function InviteOrganizationResponseDataToJSON(value?: InviteOrganizationResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_first_invited_user: value.isFirstInvitedUser
  }
}
