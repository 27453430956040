/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumRecordPorterHistoryActionFromJSON, EnumRecordPorterHistoryActionToJSON } from './EnumRecordPorterHistoryAction';
import { EnumRecordPorterHistoryOperationFromJSON, EnumRecordPorterHistoryOperationToJSON } from './EnumRecordPorterHistoryOperation';
import { EnumRecordPorterHistoryProgressStatusFromJSON, EnumRecordPorterHistoryProgressStatusToJSON } from './EnumRecordPorterHistoryProgressStatus';
import { EnumRecordPorterHistoryStepFromJSON, EnumRecordPorterHistoryStepToJSON } from './EnumRecordPorterHistoryStep';
import { RecordPorterHistoryContactListsInnerFromJSON, RecordPorterHistoryContactListsInnerToJSON } from './RecordPorterHistoryContactListsInner';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function RecordPorterHistoryFromJSON(json) {
    return RecordPorterHistoryFromJSONTyped(json, false);
}
export function RecordPorterHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
        originalFileName: !exists(json, 'original_file_name') ? undefined : json['original_file_name'],
        outputFileName: !exists(json, 'output_file_name') ? undefined : json['output_file_name'],
        totalCount: !exists(json, 'total_count') ? undefined : json['total_count'],
        successCount: !exists(json, 'success_count') ? undefined : json['success_count'],
        errorCount: !exists(json, 'error_count') ? undefined : json['error_count'],
        operation: !exists(json, 'operation') ? undefined : EnumRecordPorterHistoryOperationFromJSON(json['operation']),
        step: !exists(json, 'step') ? undefined : EnumRecordPorterHistoryStepFromJSON(json['step']),
        progressStatus: !exists(json, 'progress_status')
            ? undefined
            : EnumRecordPorterHistoryProgressStatusFromJSON(json['progress_status']),
        action: !exists(json, 'action') ? undefined : EnumRecordPorterHistoryActionFromJSON(json['action']),
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
        hasNextRecordPorterHistory: !exists(json, 'has_next_record_porter_history')
            ? undefined
            : json['has_next_record_porter_history'],
        contactLists: !exists(json, 'contact_lists')
            ? undefined
            : json['contact_lists'].map(RecordPorterHistoryContactListsInnerFromJSON),
        companyLists: !exists(json, 'company_lists')
            ? undefined
            : json['company_lists'].map(RecordPorterHistoryContactListsInnerFromJSON),
        file: !exists(json, 'file') ? undefined : json['file']
    };
}
export function RecordPorterHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        organization_id: value.organizationId,
        original_file_name: value.originalFileName,
        output_file_name: value.outputFileName,
        total_count: value.totalCount,
        success_count: value.successCount,
        error_count: value.errorCount,
        operation: EnumRecordPorterHistoryOperationToJSON(value.operation),
        step: EnumRecordPorterHistoryStepToJSON(value.step),
        progress_status: EnumRecordPorterHistoryProgressStatusToJSON(value.progressStatus),
        action: EnumRecordPorterHistoryActionToJSON(value.action),
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        user: UserForDisplayToJSON(value.user),
        has_next_record_porter_history: value.hasNextRecordPorterHistory,
        contact_lists: value.contactLists === undefined
            ? undefined
            : value.contactLists.map(RecordPorterHistoryContactListsInnerToJSON),
        company_lists: value.companyLists === undefined
            ? undefined
            : value.companyLists.map(RecordPorterHistoryContactListsInnerToJSON),
        file: value.file
    };
}
