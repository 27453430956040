import { Box, ButtonBase, createStyles, Input, makeStyles, Typography as DeprecatedTypography } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { ChatMessage, Document, Organization, OrganizationChatSetting, Visitor } from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Colors, Pallete, Typography } from 'src/components/atoms'
import { ChatMessageItem } from 'src/components/molecules/ChatMessageItem'
import { useCreateChatMessage } from 'src/fixtures/modules/user/hooks'
import Image from 'next/image'
import { useConfirmDiscard } from 'src/fixtures/utils/url'

const useStyles = makeStyles(() =>
  createStyles({
    chatRoom: { background: Pallete.functional.background.default },
    recommendNegotiation: {
      position: 'sticky',
      display: 'flex',
      flexDirection: 'column',
      top: 0,
      zIndex: 1000,
      width: '100%'
    },
    messageInputSection: {
      background: Colors.background.lightGray,
      position: 'sticky',
      bottom: 0,
      maxHeight: '30%',
      overflow: 'hidden',
      flex: '1 0 auto'
    },
    messageInput: { flex: 1, marginRight: 8, border: 'none', '& .MuiInput-inputMultiline': { padding: 0 } },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    submitbutton: {
      alignSelf: 'flex-end'
    }
  })
)

interface ChatRoomProps {
  document: Document
  chatMessages: ChatMessage[]
  visitor?: Visitor
  isAvailableSettingNegotiationOpportunity?: boolean
  onCreateMessage?: () => void
  onSettingNegotiationOpportunity?: () => void
  isPreview?: boolean
  siteId?: string
  organizationChatSetting?: OrganizationChatSetting
  organization?: Organization
  onChangeFormMessage?: (formMessage: string) => void
}

export const ChatRoom = ({
  document,
  chatMessages: _chatMessages,
  visitor,
  isAvailableSettingNegotiationOpportunity,
  onSettingNegotiationOpportunity,
  isPreview = false,
  siteId,
  organizationChatSetting,
  organization,
  onChangeFormMessage
}: ChatRoomProps) => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement | null>(null)
  const [message, changeMessage] = useState('')
  const { handleCreateChatMessage, isLoading, error } = useCreateChatMessage()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm)

  const onCreateMessage = useCallback(async () => {
    if (!isPreview) {
      handleCreateChatMessage({ message, documentId: document?.id, siteId: siteId })
      setIsSubmitting(true)
      setShowConfirm(false)
    }
  }, [document?.id, handleCreateChatMessage, isPreview, message, siteId])

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      changeMessage('')
      setIsSubmitting(false)
      if (ref.current) {
        ref.current.scroll({ top: ref.current.scrollHeight, behavior: 'smooth' })
      }
    }
  }, [isLoading, error, isSubmitting])

  useEffect(() => {
    onChangeFormMessage?.(message)
  }, [message, onChangeFormMessage])

  useEffect(() => {
    if (ref.current) {
      ref.current.scroll({ top: ref.current.scrollHeight, behavior: 'smooth' })
    }
  }, [_chatMessages])

  const chatMessages: ChatMessage[] = useMemo(() => {
    if (isPreview) {
      return [
        {
          id: '1',
          message: `資料をご覧いただきありがとうございます！何かお困りのことがあればこちからご相談ください。料金・システム・導入方法などをご相談いただくことが多いです。`,
          sendedBy: {
            id: 'サンプル担当者',
            displayName: '国村 リカ',
            avatar: { url: '/images/kunimura.png' }
          },
          createdAt: new Date().toString()
        },
        {
          id: '2',
          message: `アポイントメントを希望したいです！`,
          sendedBy: {
            id: visitor?.contactId
          },
          createdAt: new Date().toString()
        }
      ]
    } else {
      return _chatMessages
    }
  }, [_chatMessages, isPreview, visitor])

  return (
    <Box width={1} display="flex" flexDirection="column" position="relative" className={classes.chatRoom}>
      {isAvailableSettingNegotiationOpportunity && (
        <Box
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.recommendNegotiation}
        >
          <Box py={1} px={4} width={1} bgcolor={Colors.background.lightGray}>
            <ButtonBase onClick={onSettingNegotiationOpportunity} style={{ width: '100%' }}>
              <Box
                width={1}
                color={Colors.accent.keyBlue.default}
                py={1}
                px={2}
                border={`1px solid ${Colors.accent.keyBlue.default}`}
                borderRadius={4}
              >
                <DeprecatedTypography variant="h6">商談を希望する</DeprecatedTypography>
              </Box>
            </ButtonBase>
          </Box>
        </Box>
      )}

      <Box {...{ ref }} px={4} my={3} height="100%" overflow={'auto'}>
        <Box width={1} display="flex" alignItems="center" justifyContent="center" mb="12px">
          <Send fontSize="small" htmlColor={Colors.base.middleGray} />
          <Box ml={1}>
            <DeprecatedTypography variant="h4">チャット</DeprecatedTypography>
          </Box>
        </Box>
        {chatMessages.map(item => {
          return (
            <Box key={item.id} pb={4}>
              {/* TODO: isPreviewだけを渡す実装に後日リファクタ */}
              <ChatMessageItem
                item={item}
                isEuView={Boolean(visitor)}
                isPreview={isPreview}
                visitor={visitor}
                organizationChatSetting={organizationChatSetting}
              />
            </Box>
          )
        })}
        {!organization?.hideAd && (
          <Box>
            <a
              href="https://noco.sale/?utm_source=noco_sales&utm_medium=service_pr&utm_campaign=message_home_bottom"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              <Image src="/images/nocosell_icon-2.svg" width="17px" height="17px" />
              <Box ml="6px" />
              <Typography fontSize="xs" letterSpacing="none" fontWeight="bold">
                <Box color={Colors.accent.keyPurple.default}>ノコセル</Box>
              </Typography>
              <Typography fontSize="xs" letterSpacing="none" fontWeight="bold">
                <Box color={Colors.base.placeHolder}>を利用しています</Box>
              </Typography>
            </a>
          </Box>
        )}
      </Box>

      <Box py={1} px={3} className={classes.messageInputSection}>
        <form
          onSubmit={e => {
            e.preventDefault()
            onCreateMessage()
          }}
          style={{ display: 'flex', alignItems: 'center', overflow: 'hidden', height: '100%' }}
        >
          <Input
            value={message}
            placeholder="メッセージを入力"
            className={classes.messageInput}
            multiline
            style={{ display: 'flex', overflowY: 'auto', width: '100%', height: '100%', alignItems: 'flex-start' }}
            onKeyDown={e => {
              if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                onCreateMessage()
              }
            }}
            onChange={({ target: { value } }) => {
              if (changeMessage) {
                changeMessage(value)
                setShowConfirm(true)
              }
            }}
          />
          <Button
            title="送信"
            type="submit"
            disabled={!isPreview && (isLoading || !message)}
            loading={isLoading}
            className={classes.submitbutton}
            buttonColor={organizationChatSetting?.chatColor ?? Colors.accent.keyPurple.default}
          />
        </form>
      </Box>
    </Box>
  )
}
