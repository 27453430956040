import { SvgIconProps } from '@material-ui/core'
import React from 'react'

const NocoLogo = (props: SvgIconProps) => {
  return (
    <svg width="84" height="24" viewBox="0 0 84 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4474_150745)">
        <path
          d="M80.219 3.82998C79.6998 2.96121 78.958 2.24641 78.0701 1.75923C77.1065 1.26038 76.037 1 74.9516 1C73.8662 1 72.7967 1.26038 71.8331 1.75923C70.9253 2.25888 70.1683 2.99258 69.6412 3.88382C69.114 4.77506 68.8359 5.79121 68.8359 6.82631C68.8359 7.86142 69.114 8.87756 69.6412 9.76881C70.1683 10.66 70.9253 11.3937 71.8331 11.8934C72.7561 12.3923 73.7891 12.6535 74.8387 12.6535H74.9537C76.0413 12.6727 77.1154 12.4108 78.0718 11.8934C78.9591 11.4053 79.7006 10.6907 80.2207 9.82265C80.7318 8.90675 81.0001 7.87565 81.0001 6.82716C81.0001 5.77867 80.7318 4.74756 80.2207 3.83167L80.219 3.82998ZM72.2593 6.81956C72.2377 6.2873 72.3487 5.75801 72.5824 5.27916C72.8004 4.84313 73.1327 4.47424 73.5439 4.2117C73.9508 3.96149 74.4192 3.82904 74.8971 3.82914H74.9554C75.4396 3.82367 75.9153 3.95671 76.3263 4.21254C76.7326 4.47498 77.0609 4.8416 77.2768 5.2741C77.4923 5.75883 77.6037 6.28329 77.6037 6.81364C77.6037 7.344 77.4923 7.86846 77.2768 8.35319C77.059 8.78331 76.7307 9.14791 76.3254 9.40968C75.9061 9.66434 75.4247 9.79903 74.9338 9.79903C74.443 9.79903 73.9616 9.66434 73.5422 9.40968C73.1324 9.15094 72.8004 8.78613 72.5815 8.35404C72.3468 7.8827 72.2348 7.35989 72.2559 6.83391V6.82293L72.2593 6.81956Z"
          fill="#2C2248"
        />
        <path
          d="M53.918 3.82998C53.3988 2.96121 52.657 2.24641 51.7691 1.75923C50.8055 1.26038 49.736 1 48.6506 1C47.5652 1 46.4957 1.26038 45.5321 1.75923C44.6243 2.25888 43.8673 2.99258 43.3402 3.88382C42.813 4.77506 42.5349 5.79121 42.5349 6.82631C42.5349 7.86142 42.813 8.87756 43.3402 9.76881C43.8673 10.66 44.6243 11.3937 45.5321 11.8934C46.4551 12.3923 47.4881 12.6535 48.5377 12.6535H48.6527C49.7403 12.6727 50.8144 12.4108 51.7708 11.8934C52.658 11.4053 53.3996 10.6907 53.9197 9.82265C54.4307 8.90675 54.699 7.87565 54.699 6.82716C54.699 5.77867 54.4307 4.74756 53.9197 3.83167L53.918 3.82998ZM45.9583 6.81956C45.9366 6.2873 46.0476 5.75801 46.2813 5.27916C46.4994 4.84313 46.8317 4.47424 47.2429 4.2117C47.6498 3.96149 48.1182 3.82904 48.596 3.82914H48.6544C49.1386 3.82367 49.6143 3.95671 50.0252 4.21254C50.4316 4.47498 50.7599 4.8416 50.9758 5.2741C51.1913 5.75883 51.3026 6.28329 51.3026 6.81364C51.3026 7.344 51.1913 7.86846 50.9758 8.35319C50.758 8.78331 50.4297 9.14791 50.0244 9.40968C49.605 9.66434 49.1236 9.79903 48.6328 9.79903C48.142 9.79903 47.6606 9.66434 47.2412 9.40968C46.8313 9.15094 46.4993 8.78613 46.2805 8.35404C46.0458 7.8827 45.9338 7.35989 45.9549 6.83391V6.82293L45.9583 6.81956Z"
          fill="#2C2248"
        />
        <path
          d="M38.9922 2.59603C38.2293 1.83447 37.2575 1.31589 36.1994 1.10586C35.1414 0.895827 34.0448 1.00377 33.0482 1.41603C32.0517 1.8283 31.1999 2.52638 30.6005 3.42203C30.0012 4.31767 29.6813 5.37065 29.6812 6.44786V12.2885H32.9557V6.44786C32.9557 5.87245 33.1846 5.32062 33.592 4.91375C33.9995 4.50688 34.5521 4.2783 35.1283 4.2783C35.7045 4.2783 36.2571 4.50688 36.6645 4.91375C37.072 5.32062 37.3009 5.87245 37.3009 6.44786V12.2885H40.5779L40.5906 6.44786C40.5929 5.73212 40.4527 5.02306 40.1783 4.36184C39.904 3.70062 39.5008 3.1004 38.9922 2.59603V2.59603Z"
          fill="#2C2248"
        />
        <path
          d="M65.3713 8.156L65.2952 8.28184C65.2022 8.43147 65.0963 8.57275 64.9789 8.70409L64.8529 8.83246C64.7286 8.95337 64.5967 9.06621 64.4579 9.17026L64.2634 9.30201C64.0265 9.44574 63.7725 9.55922 63.5074 9.63981C63.1723 9.73402 62.8254 9.77953 62.4773 9.77494C61.974 9.78422 61.4785 9.6491 61.0498 9.38561C60.6326 9.12712 60.2924 8.76173 60.0645 8.32744C59.825 7.86736 59.7027 7.3554 59.7085 6.83687C59.6992 6.30691 59.8182 5.78254 60.0552 5.3083C60.2737 4.8742 60.6109 4.51081 61.0278 4.26026C61.3885 4.04766 61.7939 3.92215 62.2117 3.89374L62.4477 3.88192H62.4773C62.5762 3.88192 62.6769 3.88192 62.7733 3.89459L63.0329 3.92752C63.1987 3.95779 63.3621 4.00013 63.5217 4.0542C63.8456 4.16308 64.1512 4.31994 64.4283 4.51953C64.6323 4.66837 64.8235 4.8339 65 5.01441C65.0999 5.13132 65.191 5.25553 65.2723 5.386L65.3493 5.5093L67.5481 3.69444L67.4796 3.59816C66.9038 2.79124 66.1424 2.13419 65.2594 1.68229C64.3764 1.23038 63.3976 0.996832 62.4054 1.00128C58.3461 1.00128 56.4915 4.02211 56.4915 6.82843C56.4915 8.34855 57.0039 9.76227 57.9359 10.812C59.0049 12.0171 60.55 12.6547 62.4046 12.6547H62.4494C63.4368 12.6549 64.4099 12.4192 65.2876 11.9675C66.1652 11.5158 66.922 10.861 67.4948 10.0578L67.5642 9.96073L65.3713 8.156Z"
          fill="#2C2248"
        />
        <path
          d="M44.157 17.4537L42 17.6852L42.1605 19.3241L44.157 19.0926V20.9074C44.157 22.9815 45.2104 23 47.5781 23C47.9693 23 48.19 22.9907 50.2166 22.963C50.2567 22.2778 50.2969 21.9259 50.3972 21.25C49.5946 21.4074 47.7887 21.4352 46.9159 21.4352C46.0732 21.4352 45.9929 21.2037 45.9929 20.7778V18.8796L48.2201 18.6296C47.8489 19.1852 47.3774 19.6667 47.0764 19.963C47.3272 20.0741 48.0295 20.3704 48.6716 20.7593C48.9324 20.3981 50.1965 18.5833 50.4975 18.1759C50.5577 18.0926 50.8687 17.6852 50.9389 17.5926L49.7752 16.7315C49.5745 16.8426 49.3137 16.9167 49.093 16.9352L45.9929 17.2593V15.3981C46.0832 15.3611 46.2337 15.3148 46.2337 15.213C46.2337 15.0926 46.0832 15.0833 45.9929 15.0833L44.157 15V17.4537Z"
          fill="#2C2248"
        />
        <path d="M52.2322 18.2315V19.8981H60.9604V18.2315H52.2322Z" fill="#2C2248" />
        <path
          d="M63.7106 15.3889V17.7778C63.7106 18.0278 63.6905 19.1481 63.2792 19.9074C63.0785 20.287 62.5167 21.0463 61.7743 21.537C62.4665 21.9352 62.8377 22.213 63.2491 22.6574C64.6335 21.5278 65.5967 19.9537 65.5967 17.8426V15.787C65.707 15.75 65.8174 15.7037 65.8174 15.5926C65.8174 15.4815 65.7171 15.4722 65.5666 15.463L63.7106 15.3889ZM66.3491 15.287V21.4074C66.3491 21.6759 66.309 21.8889 66.2989 21.9907L67.553 22.7315C67.7637 22.537 67.9443 22.3796 68.0947 22.2685C68.9776 21.6389 70.5828 20.5 71.5359 19.1481C71.0644 18.5648 70.8437 18.2037 70.6029 17.6389C70.0009 18.463 69.098 19.4537 68.2051 20.0463V15.7037C68.3857 15.5926 68.4258 15.5648 68.4258 15.4907C68.4258 15.3796 68.3155 15.3796 68.2051 15.3704L66.3491 15.287Z"
          fill="#2C2248"
        />
        <path
          d="M77.0773 17.0093C76.1242 19.787 73.2951 21.1296 71.9106 21.5556C72.2617 21.8426 72.6429 22.1852 73.2048 22.9907C74.8802 22.3241 76.3148 21.037 76.9669 20.3426C77.6291 20.787 78.843 21.8241 79.6657 22.8704C79.9566 22.5185 80.6589 21.7685 81 21.4537C80.2676 20.6759 78.5019 19.3889 77.9903 19.0648C78.5521 18.2407 78.8129 17.6574 79.2543 16.6852C79.3547 16.4537 79.465 16.2407 79.6155 16.0556L78.7327 15.3056C78.4818 15.4074 78.0605 15.4074 77.9802 15.4074H72.8938V17.0093H77.0773Z"
          fill="#2C2248"
        />
        <path d="M16.0341 22.0593L16.0259 22.0648L16.0341 22.0593Z" fill="url(#paint0_linear_4474_150745)" />
        <path d="M16.0262 22.0646L15.9595 22.1021L16.0262 22.0646Z" fill="white" />
        <path
          d="M16.6155 21.711C16.5222 21.7607 16.3268 21.8913 16.0337 22.0595C16.2282 21.9497 16.4254 21.8339 16.6155 21.711Z"
          fill="white"
        />
        <path
          d="M5.17714 21.8078C5.29345 21.8821 5.41324 21.9533 5.53653 22.0212C5.41441 21.9498 5.2949 21.8758 5.17627 21.8008L5.17714 21.8078Z"
          fill="white"
        />
        <path
          d="M23.4914 13.4691C23.5047 9.34179 21.3927 5.40933 17.7227 3.31353C17.6072 3.25395 17.4888 3.19153 17.3733 3.13762C17.2142 3.07048 17.0579 3.01089 16.9045 2.95415C16.7979 2.91375 16.7037 2.84625 16.6313 2.75833C16.5588 2.67041 16.5107 2.56513 16.4915 2.4529C16.4714 2.33926 16.4812 2.22234 16.5198 2.11359C16.5585 2.00484 16.6248 1.90798 16.7122 1.83248C16.7992 1.75863 16.904 1.70874 17.0162 1.68778C17.3294 1.63107 17.6456 1.59223 17.9633 1.57145C18.3829 1.52227 18.2862 1.1695 17.9927 1.03142V1.04183C16.0049 0.191317 13.835 -0.146868 11.6822 0.0583257C9.52938 0.26352 7.46271 1.00552 5.67194 2.21618C3.88116 3.42685 2.42366 5.06739 1.43327 6.98716C0.442883 8.90693 -0.0486523 11.0444 0.00379795 13.2033C0.00379795 17.7354 2.00309 21.3794 5.6162 23.67C5.40216 21.386 5.12845 16.4142 8.76619 10.9448C7.91382 9.46001 7.65432 8.1019 8.358 6.73812C8.97055 6.93507 9.51577 7.29908 9.93214 7.78906C10.3485 8.27904 10.6194 8.87549 10.7143 9.51108C11.3053 9.24976 11.9462 9.12064 12.5924 9.13278C12.7335 9.13278 12.8765 9.13845 13.011 9.14885C12.9886 8.58238 13.0801 8.01716 13.2802 7.48662C13.4803 6.95607 13.7849 6.47098 14.1759 6.06001C14.3653 6.58018 14.5336 7.10791 14.6807 7.64321C14.7965 8.00758 14.8462 8.38967 14.8275 8.7715C14.8208 8.96398 14.7689 9.1522 14.676 9.32098C14.6424 9.37927 14.6044 9.43491 14.5623 9.48743C15.9318 10.0114 16.8675 11.065 16.8675 12.2793C16.8675 13.4634 15.9754 14.4839 14.6589 15.0305L13.1739 15.5772C13.3908 15.9337 14.0774 16.0359 14.7839 15.7493C15.8674 16.5702 17.4784 20.3523 18.041 23.5698C19.7202 22.4827 21.0992 20.9919 22.0515 19.2342C23.0038 17.4765 23.4989 15.5082 23.4914 13.5097C23.4914 13.4956 23.4914 13.4823 23.4914 13.4691Z"
          fill="url(#paint1_linear_4474_150745)"
        />
        <path
          d="M12.1105 11.2426C11.9367 11.1047 11.617 11.1712 11.4703 11.3804C11.3585 11.5618 11.2834 11.7633 11.2491 11.9734C11.1526 12.4932 11.675 13.1209 12.2234 12.8336C12.3315 12.7774 12.4207 12.6909 12.4803 12.5848C12.5721 12.4029 12.6058 12.1973 12.5768 11.9956C12.5382 11.6572 12.2408 11.3467 12.1105 11.2426Z"
          fill="#9C3DEF"
        />
        <path
          d="M15.3423 18.6086C14.9786 18.0393 14.4611 17.5842 13.8495 17.2959C13.238 17.0075 12.5573 16.8976 11.8859 16.9789C11.2145 17.0601 10.5798 17.3292 10.0549 17.755C9.52998 18.1809 9.1363 18.7462 8.91921 19.3858C9.19537 19.7117 9.72742 20.0087 9.8906 20.2614C10.1156 20.5738 10.1156 21.081 9.82011 21.3047C9.52464 21.5284 9.15772 21.4493 8.82362 21.3673H8.81396C8.90757 21.7729 9.07093 22.1592 9.29676 22.509C11.0242 25.0016 15.7962 21.0491 15.3423 18.6086Z"
          fill="url(#paint2_linear_4474_150745)"
        />
        <path
          d="M14.7397 21.2389C14.6577 20.6411 15.3133 20.1194 15.7971 19.7655C15.7137 19.3564 15.5598 18.9649 15.3423 18.6084C15.7961 21.0489 11.0241 25.0014 9.30054 22.5059C9.6763 23.0637 10.2016 23.5046 10.8163 23.7785C11.4311 24.0523 12.1106 24.148 12.7772 24.0546C13.4439 23.9612 14.0707 23.6824 14.5863 23.2502C15.1018 22.8179 15.4853 22.2496 15.6928 21.6101C15.6291 21.6254 15.5646 21.6373 15.4997 21.6458C15.1829 21.6873 14.787 21.5532 14.7397 21.2389Z"
          fill="#8164F5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4474_150745"
          x1="-0.814657"
          y1="22.062"
          x2="21.923"
          y2="22.062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#50ADFF" />
          <stop offset="0.19" stopColor="#A033FF" />
          <stop offset="1" stopColor="#FF5C87" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4474_150745"
          x1="34.9043"
          y1="5.2716"
          x2="11.5281"
          y2="-10.2872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#50ADFF" />
          <stop offset="0.294875" stopColor="#9C3DEF" />
          <stop offset="0.541667" stopColor="#9C3DEF" />
          <stop offset="1" stopColor="#FF5CC8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4474_150745"
          x1="9.46498"
          y1="16.9745"
          x2="16.1227"
          y2="21.9225"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0260417" stopColor="#D27AE4" />
          <stop offset="0.598958" stopColor="#B53CE4" />
        </linearGradient>
        <clipPath id="clip0_4474_150745">
          <rect width="84" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default NocoLogo
