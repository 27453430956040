import { Box } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useGetContact, useListContactTimelines } from 'src/fixtures/modules/contact/hooks'
import { CompanyInfo } from './CompanyInfo'
import { Contact } from '@noco/http-client/lib/noco'
import { removeFreeCompanyFromContactSettings } from 'src/fixtures/modules/contactSetting/utils'
import { ContactInfo, ContactInfoValues } from './ContactInfo'
import { LatestTimeLine } from './LatestTimeLine'
import { Colors, Typography } from 'src/components/atoms'
import { UpdateInformation } from 'src/components/molecules'
import { ContactList } from './ContactList'

export interface ContactContent {
  contactId: string
  onSubmit: (values: ContactInfoValues) => void
  onViewMoreTimeLine: () => void
  userId?: string
  onChangeUserId: (userId: string) => void
}

export const ContactContent = ({ onSubmit, contactId, onViewMoreTimeLine, userId, onChangeUserId }: ContactContent) => {
  const { data } = useGetContact(contactId)
  const { data: timelineRes } = useListContactTimelines(data?.contact?.id)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [initializedContact, setInitializedContact] = useState<Contact>()

  const onChangeEdit = useCallback(() => {
    setIsEditMode(prev => !prev)
  }, [])

  useEffect(() => {
    if (!data?.contact) return
    const { contactSettings, ...rest } = data?.contact
    setInitializedContact({
      contactSettings: removeFreeCompanyFromContactSettings(contactSettings!),
      ...rest
    })
  }, [data])

  return (
    <>
      <ContactInfo
        contact={initializedContact || {}}
        isEditMode={isEditMode}
        userId={userId}
        onSubmit={onSubmit}
        onChangeEdit={onChangeEdit}
        onChangeUserId={onChangeUserId}
      />
      {!isEditMode && (
        <>
          <Box mt="32px">
            <ContactList
              contactId={contactId}
              contactDisplayName={data?.contact?.displayName ?? data?.contact?.encryptedEmail ?? ''}
            />
          </Box>
          {timelineRes?.contactTimelines && timelineRes?.contactTimelines.length > 0 && (
            <Box mt="32px">
              <LatestTimeLine
                contactId={contactId}
                item={timelineRes?.contactTimelines[timelineRes?.contactTimelines.length - 1]}
                onViewMoreTimeLine={onViewMoreTimeLine}
              />
            </Box>
          )}
          <Box height="32px" />
          <CompanyInfo contactId={contactId} />
          <Box my="35px">
            <UpdateInformation
              createdAt={data?.contact?.createdAt!}
              createdBy={data?.contact?.createdBy!}
              updatedAt={data?.contact?.updatedAt!}
              updatedBy={data?.contact?.updatedBy!}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Box color={Colors.base.middleGray} mr="8px">
              <Typography fontSize="xs" lineheight="12px">
                連絡先ID
              </Typography>
            </Box>
            <Typography fontSize="xs" lineheight="12px">
              {contactId}
            </Typography>
          </Box>
        </>
      )}
    </>
  )
}
