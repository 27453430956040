import { createStyles, makeStyles, Box } from '@material-ui/core'
import React from 'react'
import { Colors, SvgIcon, Pallete, Typography } from '..'

export interface LabelProps {
  labelText: string
  type?: 'default' | 'outlined' | 'delete' | 'negative' | 'neutral'
  color?: string
  size?: 'normal' | 'small'
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      backgroundColor: ({ type, color }: LabelProps) => {
        switch (type) {
          case 'outlined':
            return 'transparent'
          case 'negative':
            return Colors.accent.keyRed.default
          case 'neutral':
            return Colors.base.middleGray
          default:
            return color
        }
      },
      color: ({ type }: LabelProps) => {
        switch (type) {
          case 'outlined':
            return Colors.accent.keyRed.default
          case 'negative':
          case 'neutral':
            return Pallete.functional.whiteText.default
          default:
            return Colors.base.black
        }
      },
      border: ({ type }: LabelProps) => (type === 'outlined' ? `1px solid ${Colors.accent.keyRed.default}` : 'none')
    }
  })
)

export const Label = (props: LabelProps) => {
  const { type = 'default', labelText, size = 'normal' } = props
  const classes = useStyles(props)
  return (
    <Box
      display="inline-flex"
      padding={size === 'small' ? '2px 6px' : '4px 8px'}
      borderRadius="2px"
      alignItems="center"
      justifyContent="center"
      className={classes.label}
    >
      <Typography
        fontWeight={['default', 'delete'].some(t => t === type) ? 'normal' : 'bold'}
        fontSize={['negative', 'neutral'].some(t => t === type) ? 'xs' : 's'}
        lineheight="1"
      >
        {labelText}
      </Typography>
      {type === 'delete' && (
        <Box ml="8px" display="flex">
          <SvgIcon variant="close" size="9px" color="inherit" />
        </Box>
      )}
    </Box>
  )
}
