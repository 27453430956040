import { useCallback, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { CalendarToday } from '@material-ui/icons'
import { Colors } from 'src/components/atoms'
import { AppointmentListItem } from 'src/components/molecules'
import { useListCotnactDocumentOpportunityUsers } from 'src/fixtures/modules/contactDocument/hooks'
import { useCreateTrack } from '../../../fixtures/modules/ContactMaterialableTrack/hooks'
import { EnumVisitorMaterialableTrackEvent } from '@noco/http-client/lib/noco'

export interface AppointmentListProps {
  documentId: string
  siteId?: string
}

export const AppointmentList = ({ documentId, siteId }: AppointmentListProps) => {
  const { data, mutate } = useListCotnactDocumentOpportunityUsers(documentId)
  const { handleCreateTrack } = useCreateTrack()

  const handleOpportunity = useCallback(() => {
    handleCreateTrack({
      event: EnumVisitorMaterialableTrackEvent.Opportunity,
      documentId,
      siteId
    })
  }, [documentId, handleCreateTrack, siteId])

  const handleViewProfile = useCallback(() => {
    handleCreateTrack({
      event: EnumVisitorMaterialableTrackEvent.ViewProfile,
      documentId,
      siteId
    })
  }, [documentId, handleCreateTrack, siteId])

  useEffect(() => {
    mutate()
  }, [mutate])

  return (
    <Box width={1} display="flex" flexDirection="column" position="relative">
      <Box px={3} my={3}>
        <Box width={1} display="flex" flexDirection="column" alignItems="center" mb="24px">
          <Box display="flex" alignItems="center" justifyContent="center" mb="8px">
            <CalendarToday fontSize="small" htmlColor={Colors.base.middleGray} />
            <Box ml={1}>
              <Typography variant="h4">アポイント</Typography>
            </Box>
          </Box>
          <Typography variant="subtitle2" style={{ color: Colors.base.middleGray }}>
            以下担当者のカレンダーからご予約ください
          </Typography>
        </Box>
        {(data ?? []).length === 0 && (
          <Typography
            variant="subtitle2"
            style={{ color: Colors.base.middleGray, textAlign: 'center', fontSize: '14px' }}
          >
            現在アポイント対応可能な担当者がいません
          </Typography>
        )}
        {(data ?? [])?.map(opportunityUser => (
          <Box mb={3} key={opportunityUser.id}>
            <AppointmentListItem
              user={opportunityUser}
              onOpportunity={handleOpportunity}
              onViewProfile={handleViewProfile}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
