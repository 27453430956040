/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumVisitorMaterialableTrackEvent,
  EnumVisitorMaterialableTrackEventFromJSON,
  EnumVisitorMaterialableTrackEventFromJSONTyped,
  EnumVisitorMaterialableTrackEventToJSON
} from './EnumVisitorMaterialableTrackEvent'

/**
 *
 * @export
 * @interface VisitorMaterialableTrack
 */
export interface VisitorMaterialableTrack {
  /**
   *
   * @type {string}
   * @memberof VisitorMaterialableTrack
   */
  id?: string
  /**
   *
   * @type {EnumVisitorMaterialableTrackEvent}
   * @memberof VisitorMaterialableTrack
   */
  event?: EnumVisitorMaterialableTrackEvent
  /**
   *
   * @type {number}
   * @memberof VisitorMaterialableTrack
   */
  elapsedSecond?: number
  /**
   *
   * @type {string}
   * @memberof VisitorMaterialableTrack
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof VisitorMaterialableTrack
   */
  updatedAt?: string
}

export function VisitorMaterialableTrackFromJSON(json: any): VisitorMaterialableTrack {
  return VisitorMaterialableTrackFromJSONTyped(json, false)
}

export function VisitorMaterialableTrackFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VisitorMaterialableTrack {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    event: !exists(json, 'event') ? undefined : EnumVisitorMaterialableTrackEventFromJSON(json['event']),
    elapsedSecond: !exists(json, 'elapsed_second') ? undefined : json['elapsed_second'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function VisitorMaterialableTrackToJSON(value?: VisitorMaterialableTrack | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    event: EnumVisitorMaterialableTrackEventToJSON(value.event),
    elapsed_second: value.elapsedSecond,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
