import { Box, createStyles, makeStyles } from '@material-ui/core'
import { ContactWithAssociation } from '@noco/http-client/lib/noco'
import React from 'react'
import { Colors, SvgIcon, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

export interface ContactCompanyCellProps {
  value: ContactWithAssociation
}

const useStyles = makeStyles(() => {
  return createStyles({
    name: {
      display: 'inline',
      '& p': {
        display: 'inline'
      }
    }
  })
})

export const ContactCompanyCell = ({ value }: ContactCompanyCellProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  return (
    <Box width={1} display="flex" alignItems="center">
      {value && (
        <>
          <SvgIcon variant="company" size="16px" color={Colors.base.middleGray} />
          <Box display="inline" mr="4px" flexShrink={0} />
          <Box whiteSpace="pre-wrap" className={(classes.name, globalClasses.lineclamp1)}>
            <Typography fontSize="s" letterSpacing="tight" lineheight="14px">
              {value.displayName}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}
