/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateMeAvatarRequestUser,
  UpdateMeAvatarRequestUserFromJSON,
  UpdateMeAvatarRequestUserFromJSONTyped,
  UpdateMeAvatarRequestUserToJSON
} from './UpdateMeAvatarRequestUser'

/**
 *
 * @export
 * @interface UpdateMeAvatarRequest
 */
export interface UpdateMeAvatarRequest {
  /**
   *
   * @type {UpdateMeAvatarRequestUser}
   * @memberof UpdateMeAvatarRequest
   */
  user?: UpdateMeAvatarRequestUser
}

export function UpdateMeAvatarRequestFromJSON(json: any): UpdateMeAvatarRequest {
  return UpdateMeAvatarRequestFromJSONTyped(json, false)
}

export function UpdateMeAvatarRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeAvatarRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    user: !exists(json, 'user') ? undefined : UpdateMeAvatarRequestUserFromJSON(json['user'])
  }
}

export function UpdateMeAvatarRequestToJSON(value?: UpdateMeAvatarRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user: UpdateMeAvatarRequestUserToJSON(value.user)
  }
}
