/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumChatSummaryRepliedStatusFromJSON, EnumChatSummaryRepliedStatusToJSON } from './EnumChatSummaryRepliedStatus';
export function UpdateChatSummariesRepliedRequestFromJSON(json) {
    return UpdateChatSummariesRepliedRequestFromJSONTyped(json, false);
}
export function UpdateChatSummariesRepliedRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ids: !exists(json, 'ids') ? undefined : json['ids'],
        repliedStatus: !exists(json, 'replied_status')
            ? undefined
            : EnumChatSummaryRepliedStatusFromJSON(json['replied_status'])
    };
}
export function UpdateChatSummariesRepliedRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ids: value.ids,
        replied_status: EnumChatSummaryRepliedStatusToJSON(value.repliedStatus)
    };
}
