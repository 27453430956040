/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumSupportStatus = {
    Solved: 'solved',
    Unsolved: 'unsolved',
    SendEmailError: 'send_email_error'
};
export function EnumSupportStatusFromJSON(json) {
    return EnumSupportStatusFromJSONTyped(json, false);
}
export function EnumSupportStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumSupportStatusToJSON(value) {
    return value;
}
