import { EnumPublicationStatus } from '@noco/http-client/lib/noco'
import React from 'react'
import { IconText } from 'src/components/atoms'
import { publicationStatusIconTextMap, publicationStatusTextMap } from 'src/fixtures/utils/text'

export interface PublicationStatusCellProps {
  value: EnumPublicationStatus
}

export const PublicationStatusCell = ({ value }: PublicationStatusCellProps) => {
  return <IconText icon={publicationStatusIconTextMap[value]} text={publicationStatusTextMap[value]} />
}
