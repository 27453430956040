import { toast, TypeOptions } from 'react-toastify'
import { Colors } from '../Colors'
import { ToastType } from '.'

export const toastBackgroundColorMaps: { [key in ToastType]: string } = {
  info: Colors.primary.default,
  error: 'rgba(222, 27, 99, 1)',
  warning: Colors.warning.default,
  success: Colors.option.green
}

export const toastColorMaps: { [key in ToastType]: string } = {
  info: 'white',
  error: 'white',
  warning: 'white',
  success: 'white'
}

export const toastTypeMaps: { [key in ToastType]: TypeOptions } = {
  info: toast.TYPE.INFO,
  success: toast.TYPE.SUCCESS,
  error: toast.TYPE.ERROR,
  warning: toast.TYPE.WARNING
}
