/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetCompaniesResponseData,
  GetCompaniesResponseDataFromJSON,
  GetCompaniesResponseDataFromJSONTyped,
  GetCompaniesResponseDataToJSON
} from './GetCompaniesResponseData'

/**
 *
 * @export
 * @interface GetCompaniesResponse
 */
export interface GetCompaniesResponse {
  /**
   *
   * @type {number}
   * @memberof GetCompaniesResponse
   */
  status?: number
  /**
   *
   * @type {GetCompaniesResponseData}
   * @memberof GetCompaniesResponse
   */
  data?: GetCompaniesResponseData
}

export function GetCompaniesResponseFromJSON(json: any): GetCompaniesResponse {
  return GetCompaniesResponseFromJSONTyped(json, false)
}

export function GetCompaniesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompaniesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetCompaniesResponseDataFromJSON(json['data'])
  }
}

export function GetCompaniesResponseToJSON(value?: GetCompaniesResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetCompaniesResponseDataToJSON(value.data)
  }
}
