import { Box, ButtonBase, makeStyles, Popover, createStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Colors, DynamicMuiIcon, Input, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { useListSelectorCompanies } from 'src/fixtures/modules/selector/hooks'
import { useCreateCompany } from 'src/fixtures/modules/company/hooks'
import { useListCompanySettings } from 'src/fixtures/modules/companySetting/hooks'
import { useGetMe } from 'src/fixtures/modules/me/hooks'

export interface SelectCompanyProps {
  value?: string
  userId?: string
  onChange?: (value: string) => void
}

export interface SelectCompanyOptionData {
  label: string
  value: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      height: '34px',
      alignItems: 'center',
      display: 'flex',
      '& p': {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all'
      }
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    }
  })
})

const OPTION_HEIGHT = 34

export const SelectCompany = ({ value, userId, onChange }: SelectCompanyProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setIsOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setInputValue('')
    setIsOpen(false)
    setAnchorEl(null)
  }, [])

  const [open, setIsOpen] = useState(false)
  const id = open ? 'simple-popover' : undefined
  const [inputValue, setInputValue] = useState('')
  const [allOptions, setAllOptions] = useState<SelectCompanyOptionData[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState(value)
  const [selectedCompanyLabel, setSelectedCompanyLabel] = useState('')
  const [keyword, setKeyword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: me } = useGetMe()

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { data: res, mutate: mutateListSelectorCompanies } = useListSelectorCompanies({
    page: 1,
    per: 9999,
    text: keyword
  })
  const companySettings = useListCompanySettings()
  const companySettingsName = companySettings.data?.activeCompanySettings?.find(s => s.name === '会社名')

  const { handleCreateCompany, createdCompany, isLoading, error } = useCreateCompany({
    params: {
      userId: userId
    }
  })

  const handleAddCompany = useCallback(
    (value: string) => {
      setIsSubmitting(true)
      handleCreateCompany({
        company: {
          userId: userId || me?.user?.id,
          companySettingFieldValues: [
            {
              companySettingId: companySettingsName?.id!,
              companySettingFieldId:
                companySettingsName?.companySettingFields && companySettingsName?.companySettingFields[0].id,
              values: [{ value: value }]
            }
          ]
        }
      })
    },
    [companySettingsName?.companySettingFields, companySettingsName?.id, handleCreateCompany, userId, me]
  )

  const options: SelectCompanyOptionData[] = useMemo(() => {
    const opt = res?.selectorItems?.map(c => ({ value: c.id!, label: c.text! })) || []
    //- NOTE: 会社名 label を id から取得時に options から検索するので、すべての会社情報を持つ options が必要
    if (keyword === '') setAllOptions(opt)
    return opt
  }, [res, keyword])

  const handleChange = useCallback((value: string) => {
    setInputValue(value)
    setKeyword(value)
  }, [])

  const handleSelect = useCallback(
    ({ value, label }: SelectCompanyOptionData) => {
      setSelectedCompanyId(value)
      setSelectedCompanyLabel(label)
      onChange?.(value)
      handleClose()
    },
    [onChange, handleClose]
  )

  const handleClear = useCallback(() => {
    setSelectedCompanyId(undefined)
    setSelectedCompanyLabel('')
    setInputValue('')
    setKeyword('')
    onChange?.('')
  }, [onChange])

  const handleInputTextClear = useCallback(() => {
    setInputValue('')
    setKeyword('')
  }, [])

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      handleChange('')
      handleSelect({ value: createdCompany?.id!, label: createdCompany?.name! })
      mutateListSelectorCompanies()
      setIsSubmitting(false)
    }
  }, [
    createdCompany,
    isSubmitting,
    isLoading,
    error,
    handleChange,
    handleSelect,
    mutateListSelectorCompanies,
    setInputValue
  ])

  useEffect(() => {
    const { label } = allOptions.find(option => option.value === selectedCompanyId) || {}
    if (label) setSelectedCompanyLabel(label)
  }, [selectedCompanyId, allOptions])

  const position = useMemo(() => {
    if (!anchorEl) return
    const rect = anchorEl.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [anchorEl])

  return (
    <Box>
      <ButtonBase className={classes.buttonBase} onClick={handleClick}>
        <Box
          width={1}
          height="34px"
          border={`1px solid ${Colors.background.silver}`}
          borderRadius="4px"
          pl="14px"
          pr="4px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="inset 0px 1px 2px rgba(10, 10, 10, 0.1)"
          overflow="hidden"
        >
          <Box display="flex" alignItems="center">
            <Box color={Colors.base.middleGray} display="flex" alignItems="center" mr="8px">
              <DynamicMuiIcon variant="business" size="20px" color="inherit" fontSize="inherit" />
              {!selectedCompanyId && (
                <Box color={Colors.base.placeHolder} pl="8px">
                  <Typography fontSize="s" lineheight="14px">
                    検索もしくは登録してください
                  </Typography>
                </Box>
              )}
            </Box>
            <Box height="14px" className={globalClasses.lineclamp1}>
              <Typography fontSize="s" lineheight="14px">
                {selectedCompanyLabel}
              </Typography>
            </Box>
          </Box>
          {selectedCompanyId && (
            <Box
              p="8px"
              color={Colors.base.middleGray}
              display="flex"
              alignItems="center"
              className={globalClasses.cursorPointer}
              onClick={handleClear}
            >
              <DynamicMuiIcon variant="close" size="20px" color="inherit" fontSize="inherit" />
            </Box>
          )}
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorReference="anchorPosition"
        anchorPosition={position}
        PaperProps={{
          className: classes.paper,
          style: { width: (anchorEl && anchorEl.offsetWidth - 2) || 0 }
        }}
      >
        <Box pt="12px" pb="16px">
          <Box px="12px">
            <Input
              onChange={e => handleChange(e.currentTarget.value as string)}
              name="companyName"
              width="100%"
              value={inputValue}
              placeholder="検索もしくは登録する"
              startAdornment={
                <Box
                  pl="14px"
                  color={Colors.base.middleGray}
                  fontSize="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
                </Box>
              }
              endAdornment={
                <Box
                  p="10px"
                  color={Colors.base.middleGray}
                  fontSize="16px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={handleInputTextClear}
                  className={globalClasses.cursorPointer}
                >
                  <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
                </Box>
              }
            />
          </Box>
          <Box height="10px" />
          {inputValue && (
            <ButtonBase
              className={classes.buttonBase}
              onClick={() => {
                handleAddCompany(inputValue)
              }}
            >
              <Box
                width={1}
                display="flex"
                alignItems="center"
                color={Colors.accent.keyPurple.default}
                py="8px"
                px="20px"
              >
                <Box display="flex" justifyContent="center" alignItems="center" fontSize="16px" mr="10px">
                  <DynamicMuiIcon variant="add" color="inherit" fontSize="inherit" />
                </Box>
                <Typography variant="body2">新しい会社{`"${inputValue}"`}</Typography>
              </Box>
            </ButtonBase>
          )}
          <Box overflow="auto" maxHeight={OPTION_HEIGHT * 7}>
            {options.map(option => {
              return (
                <ButtonBase
                  onClick={() => handleSelect(option)}
                  key={option.value}
                  className={clsx(classes.buttonBase, classes.hoverButton)}
                >
                  <Box px="20px" width={1} className={clsx(classes.buttonBase, globalClasses.lineclamp1)}>
                    <Typography variant="body2">{option.label}</Typography>
                  </Box>
                </ButtonBase>
              )
            })}
            {options.length === 0 && (
              <Box px="20px" width={1} className={clsx(classes.buttonBase, globalClasses.lineclamp1)}>
                <Typography variant="body2">選択できる項目がありません</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
