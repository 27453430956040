/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Job, JobFromJSON, JobFromJSONTyped, JobToJSON } from './Job'

/**
 *
 * @export
 * @interface PurgeUserAndMailTemplateOrganizationResponseData
 */
export interface PurgeUserAndMailTemplateOrganizationResponseData {
  /**
   *
   * @type {Job}
   * @memberof PurgeUserAndMailTemplateOrganizationResponseData
   */
  job?: Job
}

export function PurgeUserAndMailTemplateOrganizationResponseDataFromJSON(
  json: any
): PurgeUserAndMailTemplateOrganizationResponseData {
  return PurgeUserAndMailTemplateOrganizationResponseDataFromJSONTyped(json, false)
}

export function PurgeUserAndMailTemplateOrganizationResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PurgeUserAndMailTemplateOrganizationResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    job: !exists(json, 'job') ? undefined : JobFromJSON(json['job'])
  }
}

export function PurgeUserAndMailTemplateOrganizationResponseDataToJSON(
  value?: PurgeUserAndMailTemplateOrganizationResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    job: JobToJSON(value.job)
  }
}
