import React from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  createStyles,
  makeStyles,
  CircularProgress
} from '@material-ui/core'
import { Colors, Typography } from 'src/components/atoms'
import { BulkUpdateHistoryRow } from 'src/components/organisms/BulkUpdateHistory/BulkUpdateHistoryRow'
import { useListRecordPorterHistory } from 'src/fixtures/modules/recordPorterHistory/hooks'

export interface BulkUpdateHistoryProps {}

const items = [
  { id: 'dateTime', label: '日時', width: '191px' },
  { id: 'operation', label: '操作', width: '190px' },
  { id: 'step', label: 'ステップ', width: '132px' },
  { id: 'status', label: 'ステータス', width: '240px' },
  { id: 'fileName', label: 'ファイル名', width: '178px' },
  { id: 'success_count', label: '件数', width: '108px' },
  { id: 'error_count', label: 'エラー件数', width: '108px' },
  { id: 'user', label: '実行者', width: '135px' }
]

const useStyles = makeStyles(() =>
  createStyles({
    tableHeadBorder: {
      borderRight: `1px solid ${Colors.background.silver}`
    },
    tableHeadHeight: {
      height: 'unset'
    },
    tabHeadPadding: {
      padding: '0px 0px 0px 8px !important'
    },
    tableBodyHeight: {
      height: '100%'
    }
  })
)

export const BulkUpdateHistory = (_: BulkUpdateHistoryProps) => {
  const classes = useStyles()

  const { data: res } = useListRecordPorterHistory()
  const histories = res?.recordPorterHistories

  return (
    <Box width={1} overflow="auto" position="sticky" height="100%" top={0} left={0}>
      <Table stickyHeader style={{ height: histories && histories.length > 0 ? 'unset' : '100%' }}>
        <TableHead>
          <TableRow>
            {items.map((item, i) => (
              <TableCell key={item.id} classes={{ root: classes.tabHeadPadding, head: classes.tableHeadHeight }}>
                <Box
                  display="flex"
                  alignItems="center"
                  height="28px"
                  color={Colors.base.middleGray}
                  minWidth={item.width}
                  className={i === 7 ? undefined : classes.tableHeadBorder}
                >
                  <Typography fontSize="xs" fontWeight={600} lineheight="12px">
                    {item.label}
                  </Typography>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: classes.tableBodyHeight }}>
          {histories ? (
            histories.length > 0 ? (
              histories.map((history, i) => (
                <TableRow key={`operation_${i}`}>
                  <BulkUpdateHistoryRow history={history} />
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography fontSize="s" lineheight="14px" letterSpacing="tight">
                      データがありません
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  )
}
