import { ReactElement, CompositionEventHandler } from 'react'

import { makeStyles, createStyles, Theme, Box, TextField, InputProps as MuiInputProps } from '@material-ui/core'
import { Colors, inputStatusStyles } from '../Colors'
import { Typography } from '../Typography'
export type InputProps = {
  name: string
  type?: string
  value?: string | object
  disabled?: boolean
  width?: number | string
  margin?: number | string
  padding?: number | string
  resize?: string
  error?: string
  rows?: number
  helperText?: string
  placeholder?: string
  fullWidth?: boolean
  multiline?: boolean
  startAdornment?: ReactElement
  endAdornment?: ReactElement
  onChange?: MuiInputProps['onChange']
  onBlur?: MuiInputProps['onBlur']
  required?: boolean
  maxValue?: number
  minValue?: number
  maxLength?: number
  borderRadius?: string
  large?: boolean
  borderStyle?: string
  backgroundColor?: string
  boxShadow?: string
  defaultValue?: string
  autoFocus?: boolean
  isAlignCenter?: boolean
  readonly?: boolean
  onCompositionStart?: CompositionEventHandler<HTMLElement>
  onCompositionEnd?: CompositionEventHandler<HTMLElement>
}

const getStatusStyle = ({ error, disabled, helperText }: InputProps) => {
  if (disabled) return inputStatusStyles.disabled
  if (error) return inputStatusStyles.error
  if (helperText) inputStatusStyles.success
  return inputStatusStyles.success
}

const useStyles = makeStyles(({ typography: { body1 } }: Theme) => {
  return createStyles({
    root: {
      backgroundColor: inputStatusStyles.normal.backgroundColor,
      borderRadius: ({ borderRadius }: InputProps) => borderRadius,
      width: ({ width, fullWidth }: InputProps) => (fullWidth ? '100%' : width || '260px'),
      '& .MuiInputBase-input': {
        lineHeight: 1,
        borderRadius: ({ borderRadius }: InputProps) => borderRadius,
        padding: ({ padding, large = false }: InputProps) => {
          return padding || large ? '10px 12px' : '8px 12px'
        },
        '&::placeholder': {
          color: `${Colors.base.placeHolder} !important`,
          fontWeight: 300,
          opacity: 'unset'
        }
      },
      '& .MuiInput-root': {
        ...body1,
        boxShadow: ({ boxShadow = 'inset 0px 1px 2px rgba(10, 10, 10, 0.1)' }: InputProps) => boxShadow,
        height: ({ multiline, large = false }: InputProps) => {
          if (multiline) return 'auto'
          return large ? '40px' : '34px'
        },
        borderWidth: '1px',
        borderStyle: ({ borderStyle = 'solid' }: InputProps) => borderStyle,
        borderRadius: ({ borderRadius }: InputProps) => borderRadius,
        backgroundColor: ({ backgroundColor }: InputProps) => backgroundColor,
        color: inputStatusStyles.normal.color,
        borderColor: inputStatusStyles.normal.borderColor,
        '&:hover': {
          borderColor: inputStatusStyles.hover.borderColor
        },
        '&.Mui-error': {
          ...inputStatusStyles.error
        },
        '&.Mui-disabled': {
          ...inputStatusStyles.disabled
        },
        '& .MuiInput-inputMultiline': {
          padding: ({ padding }: InputProps) => padding || '0',
          margin: ({ margin }: InputProps) => margin || '0px 12px',
          resize: ({ resize }: InputProps) => resize
        },
        // フォーカスされた時
        '&.Mui-focused': {
          borderColor: (props: InputProps) => getStatusStyle(props).borderColor,
          '&:hover': {
            borderColor: (props: InputProps) => getStatusStyle(props).borderColor
          }
        },
        '& input': {
          textAlign: ({ isAlignCenter }: InputProps) => isAlignCenter && 'center'
        }
      },
      '& .MuiInputBase-multiline': {
        padding: '10px 0',

        '& .MuiInputBase-input': {
          lineHeight: '132%'
        }
      },
      // helper text
      '& .MuiFormHelperText-root': {
        bottom: '-28px',
        wordBreak: 'keep-all',
        fontSize: '14px'
      }
    }
  })
})

export const Input = (props: InputProps) => {
  const {
    onChange,
    onBlur,
    type = 'text',
    value,
    placeholder = '',
    fullWidth = false,
    error,
    disabled = false,
    multiline = false,
    startAdornment,
    endAdornment,
    rows,
    helperText,
    required = false,
    maxValue = 9999,
    minValue = 0,
    maxLength,
    name,
    borderStyle,
    backgroundColor,
    resize,
    boxShadow,
    padding,
    margin,
    defaultValue,
    autoFocus,
    readonly = false,
    onCompositionStart = undefined,
    onCompositionEnd = undefined
  } = props
  const classes = useStyles(props)

  return (
    <Box width={fullWidth ? '100%' : props.width || '260px'}>
      <TextField
        defaultValue={defaultValue}
        InputProps={{
          startAdornment,
          endAdornment,
          readOnly: readonly,
          inputProps: {
            min: minValue,
            max: maxValue,
            maxLength: maxLength,
            borderstyle: borderStyle,
            backgroundcolor: backgroundColor,
            boxshadow: boxShadow,
            padding: padding,
            margin: margin,
            resize: resize
          }
        }}
        required={required}
        type={type}
        classes={classes}
        value={value}
        placeholder={placeholder}
        fullWidth={fullWidth}
        disabled={disabled}
        error={Boolean(error)}
        multiline={multiline}
        onBlur={onBlur}
        rows={rows}
        helperText={helperText}
        onChange={onChange}
        name={name}
        autoFocus={autoFocus}
        onCompositionStart={onCompositionStart}
        onCompositionEnd={onCompositionEnd}
      />

      {error && (
        <Box color={Colors.error.default}>
          <Typography variant="caption" fontSize="xs" style={{ color: Colors.error.default }}>
            {error}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
