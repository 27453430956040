import React, { useCallback } from 'react'
import { Box, Typography, ButtonBase } from '@material-ui/core'
import { Colors, Input, DynamicMuiIcon, Button } from 'src/components/atoms'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'

export interface CheckboxItemType {
  id?: string
  name?: string
  _delete?: boolean
}
export interface FormMultipleProps {
  checkboxItems: CheckboxItemType[]
  onChange: (items: CheckboxItemType[]) => void
}

export const FormMultiple = ({ checkboxItems, onChange }: FormMultipleProps) => {
  const handleChange = useCallback(
    (i: number) => (text: string) => {
      const newCheckboxItems = [...checkboxItems]
      newCheckboxItems[i].name = text
      onChange(newCheckboxItems)
    },
    [onChange, checkboxItems]
  )

  const onAdd = useCallback(() => {
    const newCheckboxItems = [...checkboxItems, {}]
    onChange(newCheckboxItems)
  }, [onChange, checkboxItems])

  const handleDelete = useCallback(
    (i: number, item: CheckboxItemType) => () => {
      const newCheckboxItems = [...checkboxItems]
      // NOTE: 編集は論理削除
      if (item.id) {
        newCheckboxItems[i]['_delete'] = true
        //- NOTE: sort対応 論理削除アイテムを配列の最後へ
        const splicedItemArray: CheckboxItemType[] = newCheckboxItems.splice(i, 1)
        newCheckboxItems.push(...splicedItemArray)
      }
      // NOTE: 新規は削除
      else {
        newCheckboxItems.splice(i, 1)
      }
      onChange(newCheckboxItems)
    },
    [onChange, checkboxItems]
  )

  const reorder = useCallback((list: CheckboxItemType[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }, [])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) return
    const checkboxItemsList = reorder(checkboxItems, result.source.index, result.destination.index)
    onChange(checkboxItemsList)
  }

  return (
    <>
      <Box borderTop={`1px solid ${Colors.background.silver}`} />
      <Box mt="28px" />
      <Typography variant="h6">選択肢</Typography>
      <Box mt="6px">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="checkboxItemsList">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {checkboxItems &&
                  checkboxItems.map(
                    (item, i) =>
                      !item._delete && (
                        <Draggable key={i} index={i} draggableId={`draggable-${i}`}>
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ marginBottom: '12px', ...provided.draggableProps.style }}
                            >
                              <Row
                                key={i}
                                index={i}
                                item={item.name!}
                                onChange={handleChange(i)}
                                onDelete={handleDelete(i, item)}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Button kind="secondary" variant="outlined" title="選択肢を追加" width="100%" onClick={onAdd} />
    </>
  )
}

export interface RowProps {
  index: number
  item: string
  onChange: (value: string) => void
  onDelete: () => void
}

export const Row = ({ index, item, onChange, onDelete }: RowProps) => {
  return (
    <Box display="flex" alignItems="center">
      <DynamicMuiIcon variant="dragIndicator" size="20px" />
      <Box ml="4px" />
      <Input
        name="item"
        value={item}
        placeholder={`選択肢${index + 1}`}
        onChange={({ target: { value } }) => onChange(value)}
        fullWidth
      />
      <Box mr="4px" />
      <ButtonBase onClick={onDelete}>
        <DynamicMuiIcon variant="removeCircle" color="action" size="20px" />
      </ButtonBase>
    </Box>
  )
}
