/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumOrganizationPolicySettingName = {
    TermOfService: 'term_of_service',
    PrivacyPolicy: 'privacy_policy',
    CookiePolicy: 'cookie_policy',
    GuidelinesInformationSecurity: 'guidelines_information_security'
};
export function EnumOrganizationPolicySettingNameFromJSON(json) {
    return EnumOrganizationPolicySettingNameFromJSONTyped(json, false);
}
export function EnumOrganizationPolicySettingNameFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumOrganizationPolicySettingNameToJSON(value) {
    return value;
}
