/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { RequestCompanySettingFieldValueFromJSON, RequestCompanySettingFieldValueToJSON } from './RequestCompanySettingFieldValue';
export function CreateCompanyRequestCompanyFromJSON(json) {
    return CreateCompanyRequestCompanyFromJSONTyped(json, false);
}
export function CreateCompanyRequestCompanyFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userId: !exists(json, 'user_id') ? undefined : json['user_id'],
        companySettingFieldValues: !exists(json, 'company_setting_field_values')
            ? undefined
            : json['company_setting_field_values'].map(RequestCompanySettingFieldValueFromJSON)
    };
}
export function CreateCompanyRequestCompanyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user_id: value.userId,
        company_setting_field_values: value.companySettingFieldValues === undefined
            ? undefined
            : value.companySettingFieldValues.map(RequestCompanySettingFieldValueToJSON)
    };
}
