/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetContactDocumentResponseFromJSON, GetContactShareDocumentResponseFromJSON, GetContactShareDocumentShareFormsResponseFromJSON, GetDocumentOpportunityUsersResponse1FromJSON, PutContactShareDocumentVerifyMaterialContactableResponseFromJSON } from '../models';
/**
 *
 */
export class ApiContactV1DocumentApi extends runtime.BaseAPI {
    /**
     * 資料の閲覧
     * show
     */
    async contactV1DocumentsDocumentIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling contactV1DocumentsDocumentIdGet.');
        }
        const queryParameters = {};
        if (requestParameters.siteId !== undefined) {
            queryParameters['site_id'] = requestParameters.siteId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/documents/{document_id}`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料の閲覧
     * show
     */
    async contactV1DocumentsDocumentIdGet(requestParameters, initOverrides) {
        const response = await this.contactV1DocumentsDocumentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料アポイント担当者一覧を取得する
     * index
     */
    async contactV1DocumentsDocumentIdOpportunityUsersGetRaw(requestParameters, initOverrides) {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId', 'Required parameter requestParameters.documentId was null or undefined when calling contactV1DocumentsDocumentIdOpportunityUsersGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/documents/{document_id}/opportunity_users`.replace(`{${'document_id'}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentOpportunityUsersResponse1FromJSON(jsonValue));
    }
    /**
     * 資料アポイント担当者一覧を取得する
     * index
     */
    async contactV1DocumentsDocumentIdOpportunityUsersGet(requestParameters, initOverrides) {
        const response = await this.contactV1DocumentsDocumentIdOpportunityUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料の共有設定でリンクを知っている人誰でも閲覧できる
     * share
     */
    async contactV1DocumentsSharePublicLinkUlidGetRaw(requestParameters, initOverrides) {
        if (requestParameters.publicLinkUlid === null || requestParameters.publicLinkUlid === undefined) {
            throw new runtime.RequiredError('publicLinkUlid', 'Required parameter requestParameters.publicLinkUlid was null or undefined when calling contactV1DocumentsSharePublicLinkUlidGet.');
        }
        const queryParameters = {};
        if (requestParameters.siteSharePublicLinkUlid !== undefined) {
            queryParameters['site_share_public_link_ulid'] = requestParameters.siteSharePublicLinkUlid;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/documents/share/{public_link_ulid}`.replace(`{${'public_link_ulid'}}`, encodeURIComponent(String(requestParameters.publicLinkUlid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactShareDocumentResponseFromJSON(jsonValue));
    }
    /**
     * 資料の共有設定でリンクを知っている人誰でも閲覧できる
     * share
     */
    async contactV1DocumentsSharePublicLinkUlidGet(requestParameters, initOverrides) {
        const response = await this.contactV1DocumentsSharePublicLinkUlidGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 共有フォームを取得する
     * share_forms
     */
    async contactV1DocumentsSharePublicLinkUlidShareFormsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.publicLinkUlid === null || requestParameters.publicLinkUlid === undefined) {
            throw new runtime.RequiredError('publicLinkUlid', 'Required parameter requestParameters.publicLinkUlid was null or undefined when calling contactV1DocumentsSharePublicLinkUlidShareFormsGet.');
        }
        const queryParameters = {};
        if (requestParameters.siteSharePublicLinkUlid !== undefined) {
            queryParameters['site_share_public_link_ulid'] = requestParameters.siteSharePublicLinkUlid;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/documents/share/{public_link_ulid}/share_forms`.replace(`{${'public_link_ulid'}}`, encodeURIComponent(String(requestParameters.publicLinkUlid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactShareDocumentShareFormsResponseFromJSON(jsonValue));
    }
    /**
     * 共有フォームを取得する
     * share_forms
     */
    async contactV1DocumentsSharePublicLinkUlidShareFormsGet(requestParameters, initOverrides) {
        const response = await this.contactV1DocumentsSharePublicLinkUlidShareFormsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 有効なtokenを持っているeuに対して、対象のmaterial_contactableを作成する
     * verify_material_contactable
     */
    async contactV1DocumentsSharePublicLinkUlidVerifyMaterialContactablePutRaw(requestParameters, initOverrides) {
        if (requestParameters.publicLinkUlid === null || requestParameters.publicLinkUlid === undefined) {
            throw new runtime.RequiredError('publicLinkUlid', 'Required parameter requestParameters.publicLinkUlid was null or undefined when calling contactV1DocumentsSharePublicLinkUlidVerifyMaterialContactablePut.');
        }
        const queryParameters = {};
        if (requestParameters.siteSharePublicLinkUlid !== undefined) {
            queryParameters['site_share_public_link_ulid'] = requestParameters.siteSharePublicLinkUlid;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/documents/share/{public_link_ulid}/verify_material_contactable`.replace(`{${'public_link_ulid'}}`, encodeURIComponent(String(requestParameters.publicLinkUlid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => PutContactShareDocumentVerifyMaterialContactableResponseFromJSON(jsonValue));
    }
    /**
     * 有効なtokenを持っているeuに対して、対象のmaterial_contactableを作成する
     * verify_material_contactable
     */
    async contactV1DocumentsSharePublicLinkUlidVerifyMaterialContactablePut(requestParameters, initOverrides) {
        const response = await this.contactV1DocumentsSharePublicLinkUlidVerifyMaterialContactablePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
