/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationSignupRequest
 */
export interface OrganizationSignupRequest {
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupRequest
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupRequest
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupRequest
   */
  tel?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupRequest
   */
  companyName?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupRequest
   */
  token?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupRequest
   */
  organizationSubdomain?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupRequest
   */
  password?: string
}

export function OrganizationSignupRequestFromJSON(json: any): OrganizationSignupRequest {
  return OrganizationSignupRequestFromJSONTyped(json, false)
}

export function OrganizationSignupRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationSignupRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    tel: !exists(json, 'tel') ? undefined : json['tel'],
    companyName: !exists(json, 'company_name') ? undefined : json['company_name'],
    token: !exists(json, 'token') ? undefined : json['token'],
    organizationSubdomain: !exists(json, 'organization_subdomain') ? undefined : json['organization_subdomain'],
    password: !exists(json, 'password') ? undefined : json['password']
  }
}

export function OrganizationSignupRequestToJSON(value?: OrganizationSignupRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    first_name: value.firstName,
    last_name: value.lastName,
    tel: value.tel,
    company_name: value.companyName,
    token: value.token,
    organization_subdomain: value.organizationSubdomain,
    password: value.password
  }
}
