import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Movie = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#FA0000" />
      <path
        d="M23.008 10.6611C17.269 10.6611 12.607 15.2988 12.607 21.008C12.607 26.7171 17.269 31.3388 23.008 31.3388C28.7471 31.3388 33.3929 26.7171 33.3929 21.008C33.3929 15.2988 28.731 10.6771 23.008 10.6611ZM27.3806 21.3278C27.3002 21.4717 27.1877 21.5837 27.043 21.6636L21.1111 24.6222C20.7413 24.7981 20.2912 24.6541 20.1144 24.2863C20.0501 24.1904 20.034 24.0784 20.034 23.9505V18.0494C20.034 17.6336 20.3716 17.3138 20.7735 17.3138C20.886 17.3138 20.9986 17.3298 21.1111 17.3938L27.043 20.3363C27.4128 20.5282 27.5575 20.96 27.3806 21.3278Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Movie
