/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactForDisplay,
  ContactForDisplayFromJSON,
  ContactForDisplayFromJSONTyped,
  ContactForDisplayToJSON
} from './ContactForDisplay'
import {
  ViewHistoryGraphObject,
  ViewHistoryGraphObjectFromJSON,
  ViewHistoryGraphObjectFromJSONTyped,
  ViewHistoryGraphObjectToJSON
} from './ViewHistoryGraphObject'

/**
 *
 * @export
 * @interface GetDocumentViewHistoriesGraphResponseData
 */
export interface GetDocumentViewHistoriesGraphResponseData {
  /**
   *
   * @type {ContactForDisplay}
   * @memberof GetDocumentViewHistoriesGraphResponseData
   */
  contact?: ContactForDisplay
  /**
   *
   * @type {Array<ViewHistoryGraphObject>}
   * @memberof GetDocumentViewHistoriesGraphResponseData
   */
  graphObjects?: Array<ViewHistoryGraphObject>
  /**
   *
   * @type {string}
   * @memberof GetDocumentViewHistoriesGraphResponseData
   */
  startedAt?: string
}

export function GetDocumentViewHistoriesGraphResponseDataFromJSON(
  json: any
): GetDocumentViewHistoriesGraphResponseData {
  return GetDocumentViewHistoriesGraphResponseDataFromJSONTyped(json, false)
}

export function GetDocumentViewHistoriesGraphResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentViewHistoriesGraphResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contact: !exists(json, 'contact') ? undefined : ContactForDisplayFromJSON(json['contact']),
    graphObjects: !exists(json, 'graph_objects')
      ? undefined
      : (json['graph_objects'] as Array<any>).map(ViewHistoryGraphObjectFromJSON),
    startedAt: !exists(json, 'started_at') ? undefined : json['started_at']
  }
}

export function GetDocumentViewHistoriesGraphResponseDataToJSON(
  value?: GetDocumentViewHistoriesGraphResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact: ContactForDisplayToJSON(value.contact),
    graph_objects:
      value.graphObjects === undefined
        ? undefined
        : (value.graphObjects as Array<any>).map(ViewHistoryGraphObjectToJSON),
    started_at: value.startedAt
  }
}
