/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ActivityStatusObject,
  ActivityStatusObjectFromJSON,
  ActivityStatusObjectFromJSONTyped,
  ActivityStatusObjectToJSON
} from './ActivityStatusObject'
import {
  EnumMaterialContactType,
  EnumMaterialContactTypeFromJSON,
  EnumMaterialContactTypeFromJSONTyped,
  EnumMaterialContactTypeToJSON
} from './EnumMaterialContactType'
import {
  EnumMaterialableType,
  EnumMaterialableTypeFromJSON,
  EnumMaterialableTypeFromJSONTyped,
  EnumMaterialableTypeToJSON
} from './EnumMaterialableType'
import { Materialable, MaterialableFromJSON, MaterialableFromJSONTyped, MaterialableToJSON } from './Materialable'
import {
  ViewingStatusObject,
  ViewingStatusObjectFromJSON,
  ViewingStatusObjectFromJSONTyped,
  ViewingStatusObjectToJSON
} from './ViewingStatusObject'

/**
 *
 * @export
 * @interface ContactMaterial
 */
export interface ContactMaterial {
  /**
   *
   * @type {EnumMaterialContactType}
   * @memberof ContactMaterial
   */
  materialContactType?: EnumMaterialContactType
  /**
   *
   * @type {string}
   * @memberof ContactMaterial
   */
  materialContactId?: string
  /**
   *
   * @type {string}
   * @memberof ContactMaterial
   */
  contactId?: string
  /**
   *
   * @type {boolean}
   * @memberof ContactMaterial
   */
  isExpired?: boolean
  /**
   *
   * @type {string}
   * @memberof ContactMaterial
   */
  expiredOn?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContactMaterial
   */
  canViewable?: boolean
  /**
   *
   * @type {EnumMaterialableType}
   * @memberof ContactMaterial
   */
  materialableType?: EnumMaterialableType
  /**
   *
   * @type {string}
   * @memberof ContactMaterial
   */
  materialableId?: string
  /**
   *
   * @type {string}
   * @memberof ContactMaterial
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof ContactMaterial
   */
  invitedAt?: string | null
  /**
   *
   * @type {ViewingStatusObject}
   * @memberof ContactMaterial
   */
  viewingStatusObject?: ViewingStatusObject
  /**
   *
   * @type {Array<ActivityStatusObject>}
   * @memberof ContactMaterial
   */
  activityStatusObjects?: Array<ActivityStatusObject>
  /**
   *
   * @type {Materialable}
   * @memberof ContactMaterial
   */
  materialable?: Materialable
}

export function ContactMaterialFromJSON(json: any): ContactMaterial {
  return ContactMaterialFromJSONTyped(json, false)
}

export function ContactMaterialFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactMaterial {
  if (json === undefined || json === null) {
    return json
  }
  return {
    materialContactType: !exists(json, 'material_contact_type')
      ? undefined
      : EnumMaterialContactTypeFromJSON(json['material_contact_type']),
    materialContactId: !exists(json, 'material_contact_id') ? undefined : json['material_contact_id'],
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
    isExpired: !exists(json, 'is_expired') ? undefined : json['is_expired'],
    expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
    canViewable: !exists(json, 'can_viewable') ? undefined : json['can_viewable'],
    materialableType: !exists(json, 'materialable_type')
      ? undefined
      : EnumMaterialableTypeFromJSON(json['materialable_type']),
    materialableId: !exists(json, 'materialable_id') ? undefined : json['materialable_id'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    invitedAt: !exists(json, 'invited_at') ? undefined : json['invited_at'],
    viewingStatusObject: !exists(json, 'viewing_status_object')
      ? undefined
      : ViewingStatusObjectFromJSON(json['viewing_status_object']),
    activityStatusObjects: !exists(json, 'activity_status_objects')
      ? undefined
      : (json['activity_status_objects'] as Array<any>).map(ActivityStatusObjectFromJSON),
    materialable: !exists(json, 'materialable') ? undefined : MaterialableFromJSON(json['materialable'])
  }
}

export function ContactMaterialToJSON(value?: ContactMaterial | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    material_contact_type: EnumMaterialContactTypeToJSON(value.materialContactType),
    material_contact_id: value.materialContactId,
    contact_id: value.contactId,
    is_expired: value.isExpired,
    expired_on: value.expiredOn,
    can_viewable: value.canViewable,
    materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
    materialable_id: value.materialableId,
    display_name: value.displayName,
    invited_at: value.invitedAt,
    viewing_status_object: ViewingStatusObjectToJSON(value.viewingStatusObject),
    activity_status_objects:
      value.activityStatusObjects === undefined
        ? undefined
        : (value.activityStatusObjects as Array<any>).map(ActivityStatusObjectToJSON),
    materialable: MaterialableToJSON(value.materialable)
  }
}
