import { Box, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Message, Toaster, Typography } from 'src/components/atoms'
import { LayoutPayment } from 'src/components/commons'
import { ManageUserQuantityCard } from 'src/components/molecules'
import {
  useGetOrganizationAddonUserOption,
  useGetOrganizationPaymentCurrentPlan,
  useUpdateAddonUserOption
} from 'src/fixtures/modules/organizationPayment/hooks'

export interface PageContractUserOptionProps {}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      padding: '0 24px'
    }
  })
)

export const PageContractUserOption = (_: PageContractUserOptionProps) => {
  const classes = useStyles()
  const { data: currentPlan } = useGetOrganizationPaymentCurrentPlan()
  const plan = currentPlan?.plan
  const { data: dataAddonUserOption } = useGetOrganizationAddonUserOption()
  const addonUserOption = dataAddonUserOption?.addonUserOption
  const [newUserOptionQuantity, setNewUserOptionQuantity] = useState<number>(0)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { isLoading, error, handleUpdateAddonUserOption } = useUpdateAddonUserOption()

  const handleChangeUserQuantity = useCallback((userOptionQuantity, isValid) => {
    setIsValid(isValid)
    setNewUserOptionQuantity(userOptionQuantity)
  }, [])

  const handleClick = () => {
    handleUpdateAddonUserOption({ addon: { userOptionQuantity: newUserOptionQuantity } })
    setIsSubmitting(true)
  }

  // 更新完了時の処理
  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setIsSubmitting(false)
      Toaster.success('ユーザーご利用枠を更新しました')
    }
  }, [isSubmitting, isLoading, error])

  // ユーザーご利用枠の追加ができないプランの場合は表示しない
  if (!plan?.optionUserPlan) return <></>

  return (
    <LayoutPayment
      title="ユーザーご利用枠の変更"
      previousPage={{ title: 'ご契約内容へ戻る', path: '/setting/team/contract' }}
    >
      <Box maxWidth="680px" margin="0 auto">
        {plan && addonUserOption && (
          <Box mb="32px">
            <ManageUserQuantityCard
              plan={plan}
              bundledUserQuantity={addonUserOption?.bundledUserQuantity || 0}
              usageUserCount={addonUserOption?.usageUserCount || 0}
              userOptionQuantity={addonUserOption?.currentAddonQuantity || 0}
              unitAmount={plan.optionUserPlan?.unitAmount || 0}
              onChange={handleChangeUserQuantity}
            />
          </Box>
        )}

        {error && (
          <Box mb="24px" padding="0 24px">
            <Message type="error">
              <Typography fontSize="s" fontWeight="bold">
                {error.message}
              </Typography>
            </Message>
          </Box>
        )}

        <Box display="flex" justifyContent="center" mb="24px">
          <Button
            tabIndex={-1}
            className={classes.submitButton}
            title="更新する"
            onClick={() => !isLoading && isValid && handleClick()}
            disabled={isLoading || !isValid}
            loading={isLoading}
            width="217px"
            size="large"
          />
        </Box>
      </Box>
    </LayoutPayment>
  )
}
