/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateContactSettingRequestContactSetting,
  CreateContactSettingRequestContactSettingFromJSON,
  CreateContactSettingRequestContactSettingFromJSONTyped,
  CreateContactSettingRequestContactSettingToJSON
} from './CreateContactSettingRequestContactSetting'
import {
  CreateContactSettingRequestContactSettingFieldsInner,
  CreateContactSettingRequestContactSettingFieldsInnerFromJSON,
  CreateContactSettingRequestContactSettingFieldsInnerFromJSONTyped,
  CreateContactSettingRequestContactSettingFieldsInnerToJSON
} from './CreateContactSettingRequestContactSettingFieldsInner'

/**
 *
 * @export
 * @interface CreateContactSettingRequest
 */
export interface CreateContactSettingRequest {
  /**
   *
   * @type {CreateContactSettingRequestContactSetting}
   * @memberof CreateContactSettingRequest
   */
  contactSetting?: CreateContactSettingRequestContactSetting
  /**
   *
   * @type {Array<CreateContactSettingRequestContactSettingFieldsInner>}
   * @memberof CreateContactSettingRequest
   */
  contactSettingFields?: Array<CreateContactSettingRequestContactSettingFieldsInner>
}

export function CreateContactSettingRequestFromJSON(json: any): CreateContactSettingRequest {
  return CreateContactSettingRequestFromJSONTyped(json, false)
}

export function CreateContactSettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactSettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactSetting: !exists(json, 'contact_setting')
      ? undefined
      : CreateContactSettingRequestContactSettingFromJSON(json['contact_setting']),
    contactSettingFields: !exists(json, 'contact_setting_fields')
      ? undefined
      : (json['contact_setting_fields'] as Array<any>).map(CreateContactSettingRequestContactSettingFieldsInnerFromJSON)
  }
}

export function CreateContactSettingRequestToJSON(value?: CreateContactSettingRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_setting: CreateContactSettingRequestContactSettingToJSON(value.contactSetting),
    contact_setting_fields:
      value.contactSettingFields === undefined
        ? undefined
        : (value.contactSettingFields as Array<any>).map(CreateContactSettingRequestContactSettingFieldsInnerToJSON)
  }
}
