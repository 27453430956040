import { Box } from '@material-ui/core'
import { OrganizationPlanBasicGradeEnum } from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { ModalConfirmToPaidPlan, ModalInvitationMember } from 'src/components/modals'
import { Header, MemberTable } from 'src/components/organisms'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { useListOrganizationUsers } from 'src/fixtures/modules/organization/hooks'
import {
  useGetOrganizationAddonUserOption,
  useGetOrganizationPaymentCurrentPlan
} from 'src/fixtures/modules/organizationPayment/hooks'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'

export const PageTeamMember = () => {
  const [open, setOpen] = useState(false)
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { hasPermission: isInvocable } = useFeatureFlag('user', 'create')
  const { data: me } = useGetMe()
  const { data: planData } = useGetOrganizationPaymentCurrentPlan()
  const plan = planData?.plan
  const { data: addonUserOptionData } = useGetOrganizationAddonUserOption()
  const addonUserOption = addonUserOptionData?.addonUserOption
  const { data, mutate: mutateUsers } = useListOrganizationUsers()
  const users = data?.users
  const keptUserCount = useMemo(() => users?.filter(user => !user.discardedAt).length || 0, [users])
  const isStandard = useMemo<boolean>(() => {
    if (!plan) return false
    return plan.grade === OrganizationPlanBasicGradeEnum.Standard
  }, [plan])

  const handleClick = useCallback(() => {
    const limit = addonUserOption?.bundledUserQuantity || 0
    if (keptUserCount >= limit) {
      setOpenModalUpgrade(true)
      return
    }
    setOpen(true)
  }, [addonUserOption?.bundledUserQuantity, keptUserCount])

  useEffect(() => {
    mutateUsers()
  }, [mutateUsers])

  return (
    <>
      <LayoutTeam>
        <Box
          width={1}
          py="12px"
          px="16px"
          bgcolor={Colors.functional.background.default}
          display="flex"
          flexDirection="column"
        >
          <Header
            textSize="large"
            iconSize="32px"
            icon="group"
            title="ユーザー管理"
            rightSection={
              <Button
                startIcon={<DynamicMuiIcon variant="add" size="16px" />}
                title="ユーザー招待"
                disabled={!isInvocable}
                onClick={handleClick}
              />
            }
            back={false}
          />
          <MemberTable userList={users || []} isAdmin={me?.user?.permission === 'administrator'} />
        </Box>
      </LayoutTeam>
      <ModalInvitationMember
        open={open}
        plan={plan}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        onClickSettings={() => setOpen(false)}
      />

      <ModalConfirmToPaidPlan
        currentPlanGrade={plan?.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、ユーザーを招待することはできません。プレミアムプランなら、ユーザー招待に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、これ以上ユーザーを招待することはできません。プレミアムプランなら、ユーザー招待に加え、営業を強化するさまざまな機能がご利用いただけます"
        premiumPlanConfirmInfo={{
          title: 'ユーザーご利用枠を増やして、チームで顧客獲得！',
          description:
            '現在のユーザーご利用数が上限に達しているため、これ以上ユーザーを追加することはできません。「ご契約内容」ページから「ご利用枠数の追加」をしてください',
          submitPath: isStandard ? '/setting/team/contract/user_option' : undefined,
          submitTitle: isStandard ? 'ユーザーご利用枠の変更' : undefined
        }}
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
}
