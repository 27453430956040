/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  SortSiteSectionRequestSiteSectionsInner,
  SortSiteSectionRequestSiteSectionsInnerFromJSON,
  SortSiteSectionRequestSiteSectionsInnerFromJSONTyped,
  SortSiteSectionRequestSiteSectionsInnerToJSON
} from './SortSiteSectionRequestSiteSectionsInner'

/**
 *
 * @export
 * @interface SortSiteSectionDocumentRequest
 */
export interface SortSiteSectionDocumentRequest {
  /**
   *
   * @type {Array<SortSiteSectionRequestSiteSectionsInner>}
   * @memberof SortSiteSectionDocumentRequest
   */
  siteSectionDocuments?: Array<SortSiteSectionRequestSiteSectionsInner>
}

export function SortSiteSectionDocumentRequestFromJSON(json: any): SortSiteSectionDocumentRequest {
  return SortSiteSectionDocumentRequestFromJSONTyped(json, false)
}

export function SortSiteSectionDocumentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SortSiteSectionDocumentRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteSectionDocuments: !exists(json, 'site_section_documents')
      ? undefined
      : (json['site_section_documents'] as Array<any>).map(SortSiteSectionRequestSiteSectionsInnerFromJSON)
  }
}

export function SortSiteSectionDocumentRequestToJSON(value?: SortSiteSectionDocumentRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_section_documents:
      value.siteSectionDocuments === undefined
        ? undefined
        : (value.siteSectionDocuments as Array<any>).map(SortSiteSectionRequestSiteSectionsInnerToJSON)
  }
}
