import { Box } from '@material-ui/core'
import { OrganizationPolicySetting } from '@noco/http-client/lib/noco'
import { Document, Organization, Visitor } from '@noco/http-client/lib/noco/models'
import { isMobile } from 'react-device-detect'
import { PCPageDocumentDetailPreview } from './PC'
import { SPPageDocumentDetailPreview } from './SP'

interface DocumentSlideDetailProps {
  isPreview?: boolean
  isPCView?: boolean
  document: Document
  siteId?: string
  documentShareId?: string
  siteShareId?: string
  organization: Organization
  visitor?: Visitor
  canRequestToExtendExpiredAt?: boolean
  policySettings?: OrganizationPolicySetting[]
  expiredOn?: string | null
  isActivated: boolean
}

export const DocumentSlideDetail = ({
  isPreview = false,
  isPCView = true,
  document,
  organization,
  documentShareId,
  siteShareId,
  visitor,
  siteId,
  canRequestToExtendExpiredAt,
  policySettings,
  expiredOn,
  isActivated
}: DocumentSlideDetailProps) => {
  // UAがPCで画面幅が確保できた場合はPC版を表示
  const isDesktopMode = !isMobile && isPCView
  return document && organization ? (
    <Box position="relative" height={isPCView ? '100%' : '-webkit-fill-available'}>
      {isDesktopMode ? (
        <PCPageDocumentDetailPreview
          isActivated={isActivated}
          isPreview={isPreview}
          document={document}
          organization={organization}
          visitor={visitor}
          siteId={siteId}
          documentShareId={documentShareId}
          siteShareId={siteShareId}
          canRequestToExtendExpiredAt={canRequestToExtendExpiredAt}
          expiredOn={expiredOn}
          policySettings={policySettings}
        />
      ) : (
        <SPPageDocumentDetailPreview
          isActivated={isActivated}
          isPreview={isPreview}
          document={document}
          organization={organization}
          visitor={visitor}
          siteId={siteId}
          documentShareId={documentShareId}
          siteShareId={siteShareId}
          isSpHorizontalMode={isPCView}
          canRequestToExtendExpiredAt={canRequestToExtendExpiredAt}
          expiredOn={expiredOn}
          policySettings={policySettings}
        />
      )}
    </Box>
  ) : (
    <></>
  )
}
