/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationUsersResponseData,
  GetOrganizationUsersResponseDataFromJSON,
  GetOrganizationUsersResponseDataFromJSONTyped,
  GetOrganizationUsersResponseDataToJSON
} from './GetOrganizationUsersResponseData'

/**
 *
 * @export
 * @interface GetOrganizationUsersResponse
 */
export interface GetOrganizationUsersResponse {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationUsersResponse
   */
  status?: number
  /**
   *
   * @type {GetOrganizationUsersResponseData}
   * @memberof GetOrganizationUsersResponse
   */
  data?: GetOrganizationUsersResponseData
}

export function GetOrganizationUsersResponseFromJSON(json: any): GetOrganizationUsersResponse {
  return GetOrganizationUsersResponseFromJSONTyped(json, false)
}

export function GetOrganizationUsersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationUsersResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetOrganizationUsersResponseDataFromJSON(json['data'])
  }
}

export function GetOrganizationUsersResponseToJSON(value?: GetOrganizationUsersResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationUsersResponseDataToJSON(value.data)
  }
}
