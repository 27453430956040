/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { FilterStaff, FilterStaffFromJSON, FilterStaffFromJSONTyped, FilterStaffToJSON } from './FilterStaff'
import {
  InboxFilterChatSummaryStatusesInner,
  InboxFilterChatSummaryStatusesInnerFromJSON,
  InboxFilterChatSummaryStatusesInnerFromJSONTyped,
  InboxFilterChatSummaryStatusesInnerToJSON
} from './InboxFilterChatSummaryStatusesInner'

/**
 *
 * @export
 * @interface InboxFilter
 */
export interface InboxFilter {
  /**
   *
   * @type {FilterStaff}
   * @memberof InboxFilter
   */
  staffList?: FilterStaff
  /**
   *
   * @type {Array<InboxFilterChatSummaryStatusesInner>}
   * @memberof InboxFilter
   */
  chatSummaryStatuses?: Array<InboxFilterChatSummaryStatusesInner>
}

export function InboxFilterFromJSON(json: any): InboxFilter {
  return InboxFilterFromJSONTyped(json, false)
}

export function InboxFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): InboxFilter {
  if (json === undefined || json === null) {
    return json
  }
  return {
    staffList: !exists(json, 'staff_list') ? undefined : FilterStaffFromJSON(json['staff_list']),
    chatSummaryStatuses: !exists(json, 'chat_summary_statuses')
      ? undefined
      : (json['chat_summary_statuses'] as Array<any>).map(InboxFilterChatSummaryStatusesInnerFromJSON)
  }
}

export function InboxFilterToJSON(value?: InboxFilter | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    staff_list: FilterStaffToJSON(value.staffList),
    chat_summary_statuses:
      value.chatSummaryStatuses === undefined
        ? undefined
        : (value.chatSummaryStatuses as Array<any>).map(InboxFilterChatSummaryStatusesInnerToJSON)
  }
}
