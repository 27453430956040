import { Box } from '@material-ui/core'
import { Colors, Typography } from 'src/components/atoms'

export interface SectionTitleProps {
  title: string
  index: number
}

export const SectionTitle = ({ title, index }: SectionTitleProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box
        width="24px"
        height="24px"
        borderRadius="4px"
        border={`2px solid ${Colors.accent.keyPurple.default}`}
        color={Colors.accent.keyPurple.default}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mr="12px"
      >
        <Typography fontSize="s" fontWeight="bold">
          {index}
        </Typography>
      </Box>
      <Typography fontSize="m" fontWeight="bold">
        {title}
      </Typography>
    </Box>
  )
}
