/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { CompanyList, CompanyListFromJSON, CompanyListFromJSONTyped, CompanyListToJSON } from './CompanyList'

/**
 *
 * @export
 * @interface GetCompanyListsResponseData
 */
export interface GetCompanyListsResponseData {
  /**
   *
   * @type {Array<CompanyList>}
   * @memberof GetCompanyListsResponseData
   */
  companyLists?: Array<CompanyList>
}

export function GetCompanyListsResponseDataFromJSON(json: any): GetCompanyListsResponseData {
  return GetCompanyListsResponseDataFromJSONTyped(json, false)
}

export function GetCompanyListsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCompanyListsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyLists: !exists(json, 'company_lists')
      ? undefined
      : (json['company_lists'] as Array<any>).map(CompanyListFromJSON)
  }
}

export function GetCompanyListsResponseDataToJSON(value?: GetCompanyListsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_lists:
      value.companyLists === undefined ? undefined : (value.companyLists as Array<any>).map(CompanyListToJSON)
  }
}
