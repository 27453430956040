import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const ViewModule = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.225 1.00008V4.75008C10.225 5.20842 9.85 5.58342 9.39167 5.58342H6.61667C6.15833 5.58342 5.78333 5.20842 5.78333 4.75008V1.00008C5.78333 0.541748 6.15833 0.166748 6.61667 0.166748H9.39167C9.85 0.166748 10.225 0.541748 10.225 1.00008ZM11.8917 5.58342H14.6667C15.125 5.58342 15.5 5.20842 15.5 4.75008V1.00008C15.5 0.541748 15.125 0.166748 14.6667 0.166748H11.8917C11.4333 0.166748 11.0583 0.541748 11.0583 1.00008V4.75008C11.0583 5.20842 11.425 5.58342 11.8917 5.58342ZM10.225 11.0001V7.25008C10.225 6.79175 9.85 6.41675 9.39167 6.41675H6.61667C6.15833 6.41675 5.78333 6.79175 5.78333 7.25008V11.0001C5.78333 11.4584 6.15833 11.8334 6.61667 11.8334H9.39167C9.85 11.8334 10.225 11.4584 10.225 11.0001ZM11.0583 7.25008V11.0001C11.0583 11.4584 11.4333 11.8334 11.8917 11.8334H14.6667C15.125 11.8334 15.5 11.4584 15.5 11.0001V7.25008C15.5 6.79175 15.125 6.41675 14.6667 6.41675H11.8917C11.425 6.41675 11.0583 6.79175 11.0583 7.25008ZM4.10833 6.41675H1.33333C0.875 6.41675 0.5 6.79175 0.5 7.25008V11.0001C0.5 11.4584 0.875 11.8334 1.33333 11.8334H4.10833C4.56667 11.8334 4.94167 11.4584 4.94167 11.0001V7.25008C4.94167 6.79175 4.575 6.41675 4.10833 6.41675ZM4.94167 4.75008V1.00008C4.94167 0.541748 4.56667 0.166748 4.10833 0.166748H1.33333C0.875 0.166748 0.5 0.541748 0.5 1.00008V4.75008C0.5 5.20842 0.875 5.58342 1.33333 5.58342H4.10833C4.575 5.58342 4.94167 5.20842 4.94167 4.75008Z" />
    </SvgIcon>
  )
}
export default ViewModule
