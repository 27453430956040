/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateSiteSectionRequestSiteSectionFromJSON, UpdateSiteSectionRequestSiteSectionToJSON } from './UpdateSiteSectionRequestSiteSection';
export function CreateCompanyListRequestFromJSON(json) {
    return CreateCompanyListRequestFromJSONTyped(json, false);
}
export function CreateCompanyListRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companyList: !exists(json, 'company_list')
            ? undefined
            : UpdateSiteSectionRequestSiteSectionFromJSON(json['company_list'])
    };
}
export function CreateCompanyListRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company_list: UpdateSiteSectionRequestSiteSectionToJSON(value.companyList)
    };
}
