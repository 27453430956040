import { Box, BoxProps } from '@material-ui/core'
import React from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { numberWithCommas } from 'src/fixtures/utils/number'

export interface InvoiceItemRowProps {
  itemName: string
  amount: number
  sx?: BoxProps['sx']
}

export const InvoiceItemRow = ({ itemName, amount, sx }: InvoiceItemRowProps) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', py: '12px', borderBottom: '1px solid #EEF1F4', ...sx }}
    >
      <Box sx={{ color: Colors.base.black, boxSizing: 'border-box', flexShrink: 0 }}>
        <Typography fontSize="s" lineheight="1" color="inherit">
          {itemName}
        </Typography>
      </Box>
      <Box sx={{ color: Colors.base.black }}>
        <Typography fontSize="s" lineheight="1" color="inherit">
          ¥ {numberWithCommas(amount)}
        </Typography>
      </Box>
    </Box>
  )
}
