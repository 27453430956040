import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { Colors } from '../Colors'
import { DynamicMuiIcon, MuiIconVariant } from '../DynamicMuiIcon'

export type MessageType = 'info' | 'error' | 'warn'

export interface MessageProps {
  type: MessageType
  children?: ReactNode
}

interface MessageBox {
  icon: MuiIconVariant
  iconColor: string
  borderColor: string
  color: string
  bgcolor: string
}

const messageBoxMap: { [key in MessageType]: MessageBox } = {
  info: {
    icon: 'errorOutlineOutlined',
    iconColor: Colors.accent.keyBlue.default,
    borderColor: Colors.accent.keyBlue.default,
    color: Colors.accent.keyBlue.default,
    bgcolor: '#F5F9FF'
  },
  error: {
    icon: 'errorOutlineOutlined',
    iconColor: Colors.accent.keyRed.default,
    borderColor: Colors.accent.keyRed.default,
    color: Colors.accent.keyRed.default,
    bgcolor: '#FBE9F1'
  },
  warn: {
    icon: 'warningAmber',
    iconColor: Colors.base.black,
    borderColor: '#FF8E5E',
    color: Colors.base.black,
    bgcolor: '#FFFBD9'
  }
}

export const Message = ({ type, children }: MessageProps) => {
  return (
    <Box
      sx={{
        padding: '12px',
        borderLeft: '3px solid',
        borderColor: messageBoxMap[type].borderColor,
        borderRadius: '4px',
        bgcolor: messageBoxMap[type].bgcolor,
        color: messageBoxMap[type].color,
        fontSize: '14px'
      }}
    >
      <Box display="flex" alignItems="center">
        <Box display="inline-flex" mr="8px" color={messageBoxMap[type].iconColor}>
          <DynamicMuiIcon variant={messageBoxMap[type].icon} size="20px" color="inherit" />
        </Box>
        {children}
      </Box>
    </Box>
  )
}
