import { useCallback, useState } from 'react'
import { useAuthenticate } from '../modules/auth/hooks'
import { nocoSDK } from '../modules/initialize'
import { handleError } from 'src/fixtures/utils/error'

export const useGetKenall = () => {
  const [address1, setAddress1] = useState<string | undefined>(undefined)
  const [address2, setAddress2] = useState<string | undefined>(undefined)
  const [address3, setAddress3] = useState<string | undefined>(undefined)
  const [prefecture, setPrefecture] = useState<string | undefined>(undefined)
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const kenallApi = nocoSDK.client?.userService.utilKenallApi
  const handleGetRequestKenall = useCallback(
    async (zipcode: string) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        const res = await kenallApi?.ApiFactory(auth.token).userV1UtilAddressGet({ postalcode: zipcode })
        if (!res || !res.data) throw new Error('error: response is null')
        const { address1: add1, address2: add2, address3: add3, prefecture: pref1 } = res.data
        setAddress1(add1)
        setAddress2(add2)
        setAddress3(add3)
        setPrefecture(pref1)
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive site' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, kenallApi]
  )

  const clear = useCallback(() => {
    setAddress1(undefined)
    setAddress2(undefined)
    setAddress3(undefined)
    setPrefecture(undefined)
  }, [])

  return { handleGetRequestKenall, address1, address2, address3, prefecture, isLoading, error, clear }
}
