import { CompanySettingFieldItem, ContactSettingFieldItem } from '@noco/http-client/lib/noco'
import { ContactSettingField } from '@noco/http-client/lib/noco'
import React, { useCallback } from 'react'
import { CheckBoxList, Input, RadioList, SelectBox, Textarea } from 'src/components/atoms'
import { SelectCompany } from 'src/components/organisms'
import { SelectDatePicker } from 'src/components/molecules'

export type FieldItem = ContactSettingFieldItem & CompanySettingFieldItem
export interface FieldInputProps {
  field: ContactSettingField
  fieldItems: FieldItem[]
  name: string
  userId?: string
  value: any
  onChange: (e: any) => void
  onBlur?: (e: any, fieldData: { fieldValidate: string | undefined | null }) => void
}

export const FieldInput = ({ field, onChange, onBlur, userId, value, name, fieldItems }: FieldInputProps) => {
  const handleCalendarInput = useCallback(
    value => {
      onChange({ target: { value } })
    },
    [onChange]
  )
  const handleCalendarClear = useCallback(
    value => {
      onChange({ target: { value } })
    },
    [onChange]
  )

  return (
    <>
      {(field.kind === 'number' || field.kind === 'text' || field.kind === 'time') && (
        <Input
          value={value}
          name={name}
          type={field.kind}
          fullWidth
          placeholder={field.placeholder!}
          onChange={e => onChange(e)}
          onBlur={e => {
            onBlur?.(e, {
              fieldValidate: field.fieldValidate
            })
          }}
        />
      )}

      {(field.kind === 'multiple_text' || field.kind === 'textarea') && (
        <Textarea
          value={value}
          name={name}
          placeholder={field.placeholder!}
          onChange={onChange}
          variant="outlined"
          multiline
        />
      )}

      {field.kind === 'dropdown' && !field.itemUrl && (
        <SelectBox
          value={value || ''}
          placeholder="選択してください"
          optionList={fieldItems?.map(o => ({ label: o.name!, value: String(o.id) })) || []}
          onChange={e => onChange?.({ target: { value: e.target.value } })}
        />
      )}

      {field.kind === 'radio' && (
        <RadioList
          value={value || ''}
          name={name}
          onChange={onChange}
          optionList={fieldItems?.map(o => ({ label: o.name!, value: String(o.id) })) || []}
        />
      )}

      {/* 会社 */}
      {field.itemUrl && field.itemUrl === '/user/v1/selector/companies' && (
        <SelectCompany
          value={value}
          userId={userId!}
          onChange={companyId => onChange?.({ target: { value: companyId } })}
        />
      )}

      {field.kind === 'checkbox' && (
        <CheckBoxList
          value={value}
          onChange={values => onChange({ target: { value: values } })}
          optionList={fieldItems?.map(o => ({ label: o.name!, value: String(o.id) })) || []}
        />
      )}

      {field.kind === 'date' && (
        <SelectDatePicker
          disabled={false}
          expiredValue={value}
          onConfirm={handleCalendarInput}
          onClear={handleCalendarClear}
        />
      )}
    </>
  )
}
