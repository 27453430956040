import { parseCookies, setCookie, destroyCookie } from 'nookies'
import { GetServerSidePropsContext, NextPageContext } from 'next'
import { ICookie } from 'src/fixtures/types'

export const getTokenFromCookie = (ctx?: NextPageContext | GetServerSidePropsContext): Partial<ICookie> => {
  const { uid, token, contactToken, magicLinkToken, showCookieAgreement } = parseCookies(ctx)
  return { uid, token, contactToken, magicLinkToken, showCookieAgreement }
}

export const setTokenToCookie = (
  { uid, token, contactToken, magicLinkToken, showCookieAgreement }: Partial<ICookie>,
  ctx?: NextPageContext | GetServerSidePropsContext,
  domain?: string
): void => {
  uid &&
    setCookie(ctx, 'uid', uid, {
      domain,
      maxAge: 3 * 30 * 24 * 60 * 60, // three month
      path: '/',
      secure: true
    })
  token &&
    setCookie(ctx, 'token', token, {
      domain,
      maxAge: 3 * 30 * 24 * 60 * 60, // three month
      path: '/',
      secure: true
    })
  contactToken &&
    setCookie(ctx, 'contactToken', contactToken, {
      domain,
      maxAge: 3 * 30 * 24 * 60 * 60, // three month
      path: '/',
      secure: true
    })
  magicLinkToken &&
    setCookie(ctx, 'magicLinkToken', magicLinkToken, {
      domain,
      maxAge: 3 * 30 * 24 * 60 * 60, // three month
      path: '/',
      secure: true
    })
  showCookieAgreement &&
    setCookie(ctx, 'showCookieAgreement', showCookieAgreement, {
      domain,
      maxAge: 3 * 30 * 24 * 60 * 60, // three month
      path: '/',
      secure: true
    })
}

export const removeTokenFromCookie = (ctx?: NextPageContext): void => {
  const path = '/'
  destroyCookie(ctx, 'uid', { path })
  destroyCookie(ctx, 'token', { path })
  destroyCookie(ctx, 'contactToken', { path })
  destroyCookie(ctx, 'magicLinkToken', { path })
}

export const resetCookies = async () => {
  const cookies = getTokenFromCookie()
  setTokenToCookie(cookies)
}
