/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function OrganizationPaymentUsageFromJSON(json) {
    return OrganizationPaymentUsageFromJSONTyped(json, false);
}
export function OrganizationPaymentUsageFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        users: json['users'],
        documents: json['documents'],
        sites: json['sites'],
        chats: !exists(json, 'chats') ? undefined : json['chats'],
        mailTemplates: json['mail_templates']
    };
}
export function OrganizationPaymentUsageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        users: value.users,
        documents: value.documents,
        sites: value.sites,
        chats: value.chats,
        mail_templates: value.mailTemplates
    };
}
