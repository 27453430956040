/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  VisitorVisitorContactRequestData,
  VisitorVisitorContactRequestDataFromJSON,
  VisitorVisitorContactRequestDataFromJSONTyped,
  VisitorVisitorContactRequestDataToJSON
} from './VisitorVisitorContactRequestData'

/**
 *
 * @export
 * @interface Visitor
 */
export interface Visitor {
  /**
   *
   * @type {string}
   * @memberof Visitor
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Visitor
   */
  organizationId?: string
  /**
   *
   * @type {boolean}
   * @memberof Visitor
   */
  hasContact?: boolean
  /**
   *
   * @type {string}
   * @memberof Visitor
   */
  token?: string
  /**
   *
   * @type {string}
   * @memberof Visitor
   */
  contactId?: string
  /**
   *
   * @type {VisitorVisitorContactRequestData}
   * @memberof Visitor
   */
  visitorContactRequestData?: VisitorVisitorContactRequestData | null
}

export function VisitorFromJSON(json: any): Visitor {
  return VisitorFromJSONTyped(json, false)
}

export function VisitorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Visitor {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    hasContact: !exists(json, 'has_contact') ? undefined : json['has_contact'],
    token: !exists(json, 'token') ? undefined : json['token'],
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
    visitorContactRequestData: !exists(json, 'visitor_contact_request_data')
      ? undefined
      : VisitorVisitorContactRequestDataFromJSON(json['visitor_contact_request_data'])
  }
}

export function VisitorToJSON(value?: Visitor | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    organization_id: value.organizationId,
    has_contact: value.hasContact,
    token: value.token,
    contact_id: value.contactId,
    visitor_contact_request_data: VisitorVisitorContactRequestDataToJSON(value.visitorContactRequestData)
  }
}
