import { Box } from '@material-ui/core'

import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Password, Input, Link, Typography } from 'src/components/atoms'
import { useGetUserLocalAuthEmail, useLogin } from 'src/fixtures/modules/auth/hooks'

interface SendValue {
  email: string
  password: string
  subdomain?: string
}

export interface SignInProps {
  subdomain?: string
}
export const SignIn = ({ subdomain }: SignInProps) => {
  const { handleLogin, isLoading, error } = useLogin()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: email, mutate: mutateEmail } = useGetUserLocalAuthEmail()

  const { setFieldValue, resetForm, getFieldProps, handleSubmit, handleChange, values, handleBlur, isValid } =
    useFormik<SendValue>({
      enableReinitialize: true,
      validateOnMount: true,
      initialValues: {
        subdomain,
        email: '',
        password: ''
      },
      validate: values => {
        const errors: Partial<SendValue> = {}
        if (subdomain) {
          if (!values.password) errors.email = 'パスワードは必須です'
          if (!values.email) errors.email = 'メールアドレスは必須です'
        }

        if (!values.subdomain) errors.subdomain = 'チームIDは必須です'
        return errors
      },
      onSubmit: ({ email, password, subdomain: formSubdomain }) => {
        if (!formSubdomain) return

        if (!subdomain) {
          location.href = `${location.protocol}//${formSubdomain}.${process.env.DOMAIN_NAME}/signin`
          setIsSubmitting(true)
          return
        }

        handleLogin(email, password, formSubdomain)
        setIsSubmitting(true)
      }
    })

  const disabledButton = useMemo(() => {
    if (!subdomain) return !values.subdomain
    return !isValid
  }, [isValid, subdomain, values.subdomain])

  useEffect(() => {
    if (subdomain) {
      setFieldValue('subdomain', subdomain)
    }
  }, [setFieldValue, subdomain])

  useEffect(() => {
    if (email) {
      setFieldValue('email', email)
    }
  }, [setFieldValue, email])

  // 送信成功
  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setIsSubmitting(false)
      resetForm()
      mutateEmail(undefined)
    }
  }, [isSubmitting, isLoading, error, resetForm, mutateEmail])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h1" fontSize="xxl" fontWeight="bold">
          ログインする
        </Typography>
        <Box mt="24px" />
        <Typography variant="h5" fontSize="s" fontWeight="bold">
          チームID
        </Typography>
        <Box mt="6px" />
        {subdomain ? (
          <Typography variant="subtitle1">{subdomain}</Typography>
        ) : (
          <>
            <Input {...getFieldProps('subdomain')} fullWidth required />
            <Box height="8px" />
            <Typography fontSize="s" lh="relaxed">
              チームIDはユーザー登録時のメールに記載されています
            </Typography>
            <Box mt="20px" />
          </>
        )}
        {subdomain && (
          <>
            <Box mt="24px" />
            <Typography variant="h5">メールアドレス</Typography>
            <Box mt="6px" />
            <Input
              {...getFieldProps('email')}
              fullWidth
              required
              large
              onChange={e => {
                handleChange(e)
                mutateEmail(e.target.value)
              }}
              onBlur={() => {
                setFieldValue('email', values.email.trim())
              }}
            />
            <Box mt="24px" />
            <Typography variant="h5">パスワード</Typography>
            <Box mt="6px" />
            <Box display="flex" alignItems="flex-end" pb="4px">
              <Password
                name="password"
                password={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                height="large"
              />
            </Box>
            <Box mt="24px" />
          </>
        )}
        <Button
          title={subdomain ? 'ログイン' : '次へ'}
          size="large"
          width="100%"
          type="submit"
          disabled={disabledButton}
          loading={isLoading}
        />
      </form>
      <Box mt="24px" display="flex" alignItems="center" flexDirection="column">
        {!subdomain && (
          <Box mb="6px">
            <Typography variant="subtitle1">
              チームIDを忘れた場合は
              <Link text="こちら" url="/signin/forgot/teamid" />
            </Typography>
          </Box>
        )}
        <Typography variant="subtitle1">
          パスワードを忘れた場合は
          <Link text="こちら" url="/signin/forgot/password" />
        </Typography>
      </Box>
    </>
  )
}
