/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UploadHeaderLogoSiteRequest
 */
export interface UploadHeaderLogoSiteRequest {
  /**
   *
   * @type {string}
   * @memberof UploadHeaderLogoSiteRequest
   */
  data?: string
  /**
   *
   * @type {string}
   * @memberof UploadHeaderLogoSiteRequest
   */
  fileName?: string
}

export function UploadHeaderLogoSiteRequestFromJSON(json: any): UploadHeaderLogoSiteRequest {
  return UploadHeaderLogoSiteRequestFromJSONTyped(json, false)
}

export function UploadHeaderLogoSiteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UploadHeaderLogoSiteRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data') ? undefined : json['data'],
    fileName: !exists(json, 'file_name') ? undefined : json['file_name']
  }
}

export function UploadHeaderLogoSiteRequestToJSON(value?: UploadHeaderLogoSiteRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: value.data,
    file_name: value.fileName
  }
}
