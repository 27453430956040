/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface StatisticObject
 */
export interface StatisticObject {
  /**
   *
   * @type {string}
   * @memberof StatisticObject
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof StatisticObject
   */
  materialableId?: string
  /**
   *
   * @type {string}
   * @memberof StatisticObject
   */
  materialableType?: string
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfUniqueContact?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfUniqueVisitor?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfUniqueEndUser?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfPageView?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  secondOfTotalBrowsingTime?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  secondOfAverageBrowsingTime?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfContactFromEmail?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfViewedByContactFromEmail?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  rateOfViewedByContactFromEmail?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfViewedByPublicLink?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfOpportunity?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  rateOfOpportunity?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfCta?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  rateOfCta?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  countOfAbandonment?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  rateOfAbandonment?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  secondOfTotalSpendTime?: number
  /**
   *
   * @type {number}
   * @memberof StatisticObject
   */
  secondOfAverageSpendTime?: number
  /**
   *
   * @type {string}
   * @memberof StatisticObject
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof StatisticObject
   */
  updatedAt?: string
}

export function StatisticObjectFromJSON(json: any): StatisticObject {
  return StatisticObjectFromJSONTyped(json, false)
}

export function StatisticObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    materialableId: !exists(json, 'materialable_id') ? undefined : json['materialable_id'],
    materialableType: !exists(json, 'materialable_type') ? undefined : json['materialable_type'],
    countOfUniqueContact: !exists(json, 'count_of_unique_contact') ? undefined : json['count_of_unique_contact'],
    countOfUniqueVisitor: !exists(json, 'count_of_unique_visitor') ? undefined : json['count_of_unique_visitor'],
    countOfUniqueEndUser: !exists(json, 'count_of_unique_end_user') ? undefined : json['count_of_unique_end_user'],
    countOfPageView: !exists(json, 'count_of_page_view') ? undefined : json['count_of_page_view'],
    secondOfTotalBrowsingTime: !exists(json, 'second_of_total_browsing_time')
      ? undefined
      : json['second_of_total_browsing_time'],
    secondOfAverageBrowsingTime: !exists(json, 'second_of_average_browsing_time')
      ? undefined
      : json['second_of_average_browsing_time'],
    countOfContactFromEmail: !exists(json, 'count_of_contact_from_email')
      ? undefined
      : json['count_of_contact_from_email'],
    countOfViewedByContactFromEmail: !exists(json, 'count_of_viewed_by_contact_from_email')
      ? undefined
      : json['count_of_viewed_by_contact_from_email'],
    rateOfViewedByContactFromEmail: !exists(json, 'rate_of_viewed_by_contact_from_email')
      ? undefined
      : json['rate_of_viewed_by_contact_from_email'],
    countOfViewedByPublicLink: !exists(json, 'count_of_viewed_by_public_link')
      ? undefined
      : json['count_of_viewed_by_public_link'],
    countOfOpportunity: !exists(json, 'count_of_opportunity') ? undefined : json['count_of_opportunity'],
    rateOfOpportunity: !exists(json, 'rate_of_opportunity') ? undefined : json['rate_of_opportunity'],
    countOfCta: !exists(json, 'count_of_cta') ? undefined : json['count_of_cta'],
    rateOfCta: !exists(json, 'rate_of_cta') ? undefined : json['rate_of_cta'],
    countOfAbandonment: !exists(json, 'count_of_abandonment') ? undefined : json['count_of_abandonment'],
    rateOfAbandonment: !exists(json, 'rate_of_abandonment') ? undefined : json['rate_of_abandonment'],
    secondOfTotalSpendTime: !exists(json, 'second_of_total_spend_time')
      ? undefined
      : json['second_of_total_spend_time'],
    secondOfAverageSpendTime: !exists(json, 'second_of_average_spend_time')
      ? undefined
      : json['second_of_average_spend_time'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function StatisticObjectToJSON(value?: StatisticObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    materialable_id: value.materialableId,
    materialable_type: value.materialableType,
    count_of_unique_contact: value.countOfUniqueContact,
    count_of_unique_visitor: value.countOfUniqueVisitor,
    count_of_unique_end_user: value.countOfUniqueEndUser,
    count_of_page_view: value.countOfPageView,
    second_of_total_browsing_time: value.secondOfTotalBrowsingTime,
    second_of_average_browsing_time: value.secondOfAverageBrowsingTime,
    count_of_contact_from_email: value.countOfContactFromEmail,
    count_of_viewed_by_contact_from_email: value.countOfViewedByContactFromEmail,
    rate_of_viewed_by_contact_from_email: value.rateOfViewedByContactFromEmail,
    count_of_viewed_by_public_link: value.countOfViewedByPublicLink,
    count_of_opportunity: value.countOfOpportunity,
    rate_of_opportunity: value.rateOfOpportunity,
    count_of_cta: value.countOfCta,
    rate_of_cta: value.rateOfCta,
    count_of_abandonment: value.countOfAbandonment,
    rate_of_abandonment: value.rateOfAbandonment,
    second_of_total_spend_time: value.secondOfTotalSpendTime,
    second_of_average_spend_time: value.secondOfAverageSpendTime,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
