/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateChatMessageRequestToJSON, CreateChatMessageRequest1ToJSON, CreateChatMessageResponseFromJSON, GetChatMessagesResponseFromJSON, ReviewRequestChatMessageResponseFromJSON, UpdateChatMessageRequest1ToJSON, UpdateChatMessageResponseFromJSON } from '../models';
/**
 *
 */
export class ApiContactV1ChatMessageApi extends runtime.BaseAPI {
    /**
     */
    async contactV1ChatMessagesChatMessageIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
            throw new runtime.RequiredError('chatMessageId', 'Required parameter requestParameters.chatMessageId was null or undefined when calling contactV1ChatMessagesChatMessageIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/chat_messages/{chat_message_id}`.replace(`{${'chat_message_id'}}`, encodeURIComponent(String(requestParameters.chatMessageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async contactV1ChatMessagesChatMessageIdDelete(requestParameters, initOverrides) {
        const response = await this.contactV1ChatMessagesChatMessageIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャットメッセージの編集をする
     * update
     */
    async contactV1ChatMessagesChatMessageIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
            throw new runtime.RequiredError('chatMessageId', 'Required parameter requestParameters.chatMessageId was null or undefined when calling contactV1ChatMessagesChatMessageIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/contact/v1/chat_messages/{chat_message_id}`.replace(`{${'chat_message_id'}}`, encodeURIComponent(String(requestParameters.chatMessageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChatMessageRequest1ToJSON(requestParameters.updateChatMessageRequest1)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージの編集をする
     * update
     */
    async contactV1ChatMessagesChatMessageIdPut(requestParameters, initOverrides) {
        const response = await this.contactV1ChatMessagesChatMessageIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャットメッセージを取得する
     * index
     */
    async contactV1ChatMessagesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.fromAt !== undefined) {
            queryParameters['from_at'] = requestParameters.fromAt;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/chat_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetChatMessagesResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージを取得する
     * index
     */
    async contactV1ChatMessagesGet(requestParameters = {}, initOverrides) {
        const response = await this.contactV1ChatMessagesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャットメッセージを作成する
     * create
     */
    async contactV1ChatMessagesPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/contact/v1/chat_messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateChatMessageRequestToJSON(requestParameters.createChatMessageRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージを作成する
     * create
     */
    async contactV1ChatMessagesPost(requestParameters = {}, initOverrides) {
        const response = await this.contactV1ChatMessagesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャットメッセージから資料の延長リクエスト
     * review_request
     */
    async contactV1ChatMessagesReviewRequestPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/contact/v1/chat_messages/review_request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateChatMessageRequest1ToJSON(requestParameters.createChatMessageRequest1)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ReviewRequestChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージから資料の延長リクエスト
     * review_request
     */
    async contactV1ChatMessagesReviewRequestPost(requestParameters = {}, initOverrides) {
        const response = await this.contactV1ChatMessagesReviewRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
