/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CompanySetting,
  CompanySettingFromJSON,
  CompanySettingFromJSONTyped,
  CompanySettingToJSON
} from './CompanySetting'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {string}
   * @memberof Company
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Company
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Company
   */
  archivedAt?: string | null
  /**
   *
   * @type {UserForDisplay}
   * @memberof Company
   */
  user?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof Company
   */
  createdBy?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof Company
   */
  updatedBy?: UserForDisplay
  /**
   *
   * @type {string}
   * @memberof Company
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof Company
   */
  updatedAt?: string
  /**
   *
   * @type {Array<CompanySetting>}
   * @memberof Company
   */
  companySettings?: Array<CompanySetting>
}

export function CompanyFromJSON(json: any): Company {
  return CompanyFromJSONTyped(json, false)
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    archivedAt: !exists(json, 'archived_at') ? undefined : json['archived_at'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
    updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    companySettings: !exists(json, 'company_settings')
      ? undefined
      : (json['company_settings'] as Array<any>).map(CompanySettingFromJSON)
  }
}

export function CompanyToJSON(value?: Company | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    archived_at: value.archivedAt,
    user: UserForDisplayToJSON(value.user),
    created_by: UserForDisplayToJSON(value.createdBy),
    updated_by: UserForDisplayToJSON(value.updatedBy),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    company_settings:
      value.companySettings === undefined ? undefined : (value.companySettings as Array<any>).map(CompanySettingToJSON)
  }
}
