/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateOrganizationPaymentDiscountRequest
 */
export interface UpdateOrganizationPaymentDiscountRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationPaymentDiscountRequest
   */
  couponCode: string
}

export function UpdateOrganizationPaymentDiscountRequestFromJSON(json: any): UpdateOrganizationPaymentDiscountRequest {
  return UpdateOrganizationPaymentDiscountRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentDiscountRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentDiscountRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    couponCode: json['coupon_code']
  }
}

export function UpdateOrganizationPaymentDiscountRequestToJSON(
  value?: UpdateOrganizationPaymentDiscountRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    coupon_code: value.couponCode
  }
}
