import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Colors, OptionListType, SelectBox, Typography } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { DocumentContact } from '@noco/http-client/lib/noco'
import { ja } from 'date-fns/locale'
import { format } from 'date-fns'
import { SelectDatePicker } from 'src/components/molecules'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { ModalConfirmToPaidPlan } from '../ModalConfirmToPaidPlan'

export interface ModalExpiryUpdateProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: ({ expiredOn, canViewable }: DocumentContact) => void
  value: DocumentContact
}

const optionAuthList: OptionListType[] = [
  {
    value: 'unexpiredViewable',
    label: '閲覧可（期限なし）'
  },
  {
    value: 'expiredViewable',
    label: '閲覧可（期限あり）'
  },
  {
    value: 'noViewable',
    label: '閲覧不可'
  }
]

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    buttonBase: {
      width: '100%',
      '&.Mui-disabled': {
        background: Colors.background.gray
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    }
  })
)

export const ModalExpiryUpdate = ({ open, onClose, onCancel, onConfirm, value }: ModalExpiryUpdateProps) => {
  const classes = useStyles()
  const [authValue, setAuthValue] = useState('')
  const [expiredValue, setExpiredValue] = useState<string | null>(null)
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { data: planResponse } = useGetOrganizationPaymentCurrentPlan()

  const canSetMaterialPublicationDue = useMemo(
    () => planResponse?.plan.canSetMaterialPublicationDue,
    [planResponse?.plan.canSetMaterialPublicationDue]
  )

  //- initial value
  useEffect(() => {
    if (!value.canViewable) {
      setAuthValue('noViewable')
      return
    }

    if (value.expiredOn) {
      setAuthValue('expiredViewable')
      setExpiredValue(value.expiredOn)
    } else {
      setAuthValue('unexpiredViewable')
    }
  }, [value])

  //- 変更時 value
  useEffect(() => {
    switch (authValue) {
      case 'noViewable':
        setExpiredValue(null)
        break
      case 'unexpiredViewable':
        setExpiredValue(null)
        break
      case 'expiredViewable':
        if (expiredValue) return
        setExpiredValue(format(new Date().getTime(), 'yyyy-MM-dd', { locale: ja }))
        break
      default:
        return
    }
  }, [authValue, expiredValue])

  const handleCalendarConfirm = useCallback(selectedExpiredValue => {
    setExpiredValue(selectedExpiredValue)
  }, [])

  const handleConfirm = useCallback(() => {
    const canViewable = authValue !== 'noViewable'
    onConfirm({ canViewable, expiredOn: expiredValue })
  }, [authValue, onConfirm, expiredValue])

  return (
    <>
      <Modal open={open} onClose={onClose} className={classes.modal}>
        <Slide direction="left" in={open}>
          <Box width="376px" height={1}>
            <SlideForm
              headerTitle="閲覧権限・期限を変更"
              onClose={onClose}
              onCancel={onCancel}
              onConfirm={handleConfirm}
              hideSettings
              buttonTitle="保存"
            >
              <Box px="24px" pt="24px">
                <Typography fontSize="s" fontWeight="bold">
                  閲覧権限
                </Typography>
                <Box mt="6px" />
                <SelectBox
                  listWidth="328px"
                  optionList={optionAuthList}
                  value={authValue}
                  size="medium"
                  onChange={e => {
                    if (!canSetMaterialPublicationDue && e.target.value === 'expiredViewable') {
                      setOpenModalUpgrade(true)
                      return
                    }

                    setAuthValue(e.target.value as string)
                  }}
                />
                <Box mt="20px" />
                <Typography fontSize="s" fontWeight="bold">
                  閲覧期限
                </Typography>
                <Box mt="6px" />
                <SelectDatePicker
                  disabled={!expiredValue}
                  expiredValue={expiredValue}
                  onConfirm={handleCalendarConfirm}
                />
              </Box>
            </SlideForm>
          </Box>
        </Slide>
      </Modal>

      <ModalConfirmToPaidPlan
        currentPlanGrade={planResponse?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
}
