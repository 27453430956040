import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var ChatMessageApi = /** @class */ (function () {
    function ChatMessageApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    ChatMessageApi.initialize = function (basePath, fetchApi) {
        if (!ChatMessageApi.instance) {
            ChatMessageApi.instance = new ChatMessageApi(basePath, fetchApi);
        }
        return ChatMessageApi.instance;
    };
    ChatMessageApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'contact'
        });
        return new NocoHttpClient.ApiContactV1ChatMessageApi(new Configuration(config));
    };
    return ChatMessageApi;
}());
export { ChatMessageApi };
