/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumVisitorMaterialableTrackEvent = {
  View: 'view',
  Cta: 'cta',
  Opportunity: 'opportunity',
  Download: 'download',
  ViewProfile: 'view_profile',
  Printing: 'printing',
  SnsShare: 'sns_share'
} as const
export type EnumVisitorMaterialableTrackEvent =
  typeof EnumVisitorMaterialableTrackEvent[keyof typeof EnumVisitorMaterialableTrackEvent]

export function EnumVisitorMaterialableTrackEventFromJSON(json: any): EnumVisitorMaterialableTrackEvent {
  return EnumVisitorMaterialableTrackEventFromJSONTyped(json, false)
}

export function EnumVisitorMaterialableTrackEventFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumVisitorMaterialableTrackEvent {
  return json as EnumVisitorMaterialableTrackEvent
}

export function EnumVisitorMaterialableTrackEventToJSON(value?: EnumVisitorMaterialableTrackEvent | null): any {
  return value as any
}
