import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    chatText: {
      wordBreak: 'break-all',
      fontSize: '14px',
      lineHeight: '23.8px',
      letterSpacing: '0.04em'
    }
  })
})

export const Chat = ({ chat }: { chat?: string }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  return (
    <Box display="flex" height="24px">
      {chat && (
        <Box mr="6px">
          <Typography className={`${classes.chatText} ${globalClasses.lineclamp1}`}>{chat}</Typography>
        </Box>
      )}
    </Box>
  )
}
