import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Button, Colors, DynamicMuiIcon, SvgIcon, Tooltip, Typography } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { ModalConfirmToPaidPlan, ModalContactExport, ModalContactRegister, PopupTemplate } from 'src/components/modals'
import { ActionMenu, ActionMenuItemType, MemoizedSearchAndSuggest } from 'src/components/molecules'
import {
  Header,
  Table,
  TableProps,
  FilteringMenu,
  useTablePagination,
  TableColumn,
  FloatingDialog
} from 'src/components/organisms'
import { useRouter } from 'next/router'
import {
  useListContacts,
  useListFilterContacts,
  useCreateContactList,
  useListContactLists,
  useUpdateContactListsPin,
  useDeleteContactList,
  useUpdateContactListName,
  useArchiveContact,
  useRemoveContactListContactId,
  useUpdateContactList
} from 'src/fixtures/modules/contact/hooks'
import {
  useListMaterialDocumentsLists,
  useListMaterialSitesLists,
  useUpdateMaterialDocumentsListsPin,
  useUpdateMaterialSitesListsPin
} from 'src/fixtures/modules/materialList/hook'
import { useListStaffLists, useUpdateStaffListsPin } from 'src/fixtures/modules/staffList/hook'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { useListSelectorContactsList } from 'src/fixtures/modules/selector/hooks'
import {
  Contact,
  UserV1ContactsGetRequest,
  EnumListResourceType,
  EnumMaterialableType
} from '@noco/http-client/lib/noco'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { convertContactTableColumns } from 'src/fixtures/modules/contact/utils'
import dynamic from 'next/dynamic'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
import { ModalSelectTableColumn, ModalSelectTableColumnOption } from 'src/components/modals/ModalSelectTableColumn'
import { localStorageUtils, StorageKey } from 'src/fixtures/utils/localstorage'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid'

const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

interface HeaderRightSectionProps {
  menuItemList: ActionMenuItemType[]
  isImportable: boolean
  onClick: () => void
  onClickImport: () => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    import: {
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${Colors.background.silver}`,
      borderRadius: '4px'
    },
    importButton: {
      padding: '8px 8px 8px 16px'
    },
    action: {
      height: '28px',
      color: Colors.base.middleGray
    }
  })
})
const HeaderRightSection: React.VFC<HeaderRightSectionProps> = ({
  menuItemList,
  isImportable,
  onClick,
  onClickImport
}) => {
  const classes = useStyles()
  return (
    <>
      <Button startIcon={<DynamicMuiIcon variant="add" size="16px" />} title="連絡先の登録" onClick={onClick} />
      <Box mr="12px" />
      <Box className={classes.import} color={isImportable ? Colors.base.middleGray : Colors.background.silver}>
        <Tooltip content={`Excelファイルの顧客情報を、連絡先にインポートできます。`}>
          <ButtonBase onClick={onClickImport}>
            <Box className={classes.importButton}>
              <Typography fontWeight={600} fontSize="s" lineheight="14px">
                連絡先のインポート
              </Typography>
            </Box>
          </ButtonBase>
        </Tooltip>
        <Box width="1px" height="28px" bgcolor={Colors.background.silver} />
        <ActionMenu
          menuItemList={menuItemList}
          icon={
            <Box className={classes.action}>
              <DynamicMuiIcon
                variant="arrowDropDown"
                size="28px"
                htmlColor={isImportable ? 'inherit' : Colors.background.silver}
              />
            </Box>
          }
        />
      </Box>
    </>
  )
}

export const PageContact = () => {
  const { data: org } = useGetOrganization()
  const [storagedColumns, setStoragedColumns] = useState<ModalSelectTableColumnOption[]>([])
  const [isOpenModalSelectTableColumn, setIsOpenModalSelectTableColumn] = useState(false)
  const { page, per, totalPages, setTotalPages, changePage, changePerPage, resetPagination } =
    useTablePagination('contact')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalExportOpen, setModalExportOpen] = useState(false)
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)
  const [searchParams, setSearchParams] = useState<UserV1ContactsGetRequest>({ text: '' })
  const [activeId, setActiveId] = useState('1')
  const [activeType, setActiveType] = useState('all')
  const [selection, setSelection] = useState<Array<number>>([])
  const [showNoDataImage, setShowNoDataImage] = useState(false)
  const [reloading, setReloading] = useState(false)

  // MEMO: テーブル再描画用のステート
  const [rerendering, setRerendering] = useState(false)

  const [openImportModalUpgrade, setOpenImportModalUpgrade] = useState(false)
  const [openExportModalUpgrade, setOpenExportModalUpgrade] = useState(false)
  const [openBulkUpdateModalUpgrade, setOpenBulkUpdateModalUpgrade] = useState(false)
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const { hasPermission } = useFeatureFlag('contact', 'export')

  const router = useRouter()
  const { data: responseContactSettings } = useListContactSettings()
  const { data: filterList } = useListFilterContacts()
  const { data: res, mutate } = useListContacts({
    ...searchParams,
    page: page + 1,
    per
  })

  useEffect(() => {
    mutate()
  }, [mutate, res])

  const contactList = useMemo(() => {
    return res?.contacts || []
  }, [res])

  const columns: TableColumn[] = useMemo(() => {
    if (responseContactSettings?.activeContactSettings == null) return []

    const activeContactSettings = [...responseContactSettings?.activeContactSettings, { id: 'user', name: '担当者' }]
    const activeSettingColumns = convertContactTableColumns(activeContactSettings)

    return activeSettingColumns
  }, [responseContactSettings?.activeContactSettings])

  const selectedIds = useMemo(() => {
    return contactList
      ? selection.map(rowNumber => {
          return contactList[rowNumber].id as string
        })
      : []
  }, [selection, contactList])

  const [title, labelName] = useMemo(() => {
    let title = activeType == 'archive' ? 'アーカイブから戻す' : 'アーカイブ'
    let labelName = activeType == 'archive' ? 'すべて' : 'アーカイブ'
    return [title, labelName]
  }, [activeType])

  const isExportable = useMemo(() => {
    return hasPermission && planGrade?.plan.canExportContact
  }, [hasPermission, planGrade?.plan.canExportContact])

  const handleClick = useCallback(() => setModalOpen(true), [])

  const handleClickImport = useCallback(() => {
    if (!planGrade?.plan.canImportContact) {
      setOpenImportModalUpgrade(true)
      return
    }
    router.push('/bulk_update?mode=import')
  }, [planGrade?.plan.canImportContact, router])

  const handleClickExport = useCallback(() => {
    if (!hasPermission) return
    if (!planGrade?.plan.canExportContact) {
      setOpenExportModalUpgrade(true)
      return
    }
    setModalExportOpen(true)
  }, [hasPermission, planGrade?.plan.canExportContact])

  const handleClickBulkUpdate = useCallback(() => {
    if (!hasPermission) return
    if (!planGrade?.plan.canExportContact) {
      setOpenBulkUpdateModalUpgrade(true)
      return
    }
    router.push('/bulk_update?mode=contact')
  }, [hasPermission, planGrade?.plan.canExportContact, router])

  const menuItemList: ActionMenuItemType[] = useMemo(() => {
    let menuList: ActionMenuItemType[] = [
      {
        label: '連絡先のエクスポート',
        icon: (
          <DynamicMuiIcon
            variant="getApp"
            size="20px"
            htmlColor={isExportable ? Colors.base.middleGray : Colors.background.silver}
          />
        ),
        color: isExportable ? Colors.base.black : Colors.background.silver,
        toolTipText: '連絡先のリストを、Excelファイルでエクスポートできます。',
        onClick: handleClickExport
      },
      {
        label: '連絡先の一括編集',
        icon: (
          <SvgIcon
            variant="editNote"
            size="20px"
            color={isExportable ? Colors.base.middleGray : Colors.background.silver}
          />
        ),
        color: isExportable ? Colors.base.black : Colors.background.silver,
        onClick: handleClickBulkUpdate
      }
    ]
    return menuList
  }, [isExportable, handleClickExport, handleClickBulkUpdate])

  const handleClickRow = useCallback(
    (id: string) => {
      router.push('/contacts/[contactId]', `/contacts/${id}`)
    },
    [router]
  )

  const handleClearSelection = useCallback(() => {
    setSelection([])
  }, [])

  const handleClickSetActive = useCallback((id: string, type: string) => {
    setActiveId(id)
    setActiveType(type)
  }, [])

  const handleClickCloseModalArchive = useCallback(() => {
    setModalArchiveOpen(false)
    setSelection([])
  }, [])

  const { handleSaveArchived, isLoading: isLoadingArchiveContact } = useArchiveContact({
    ...searchParams,
    page: page + 1,
    per
  })
  const handleClickModalArchiveConfirm = useCallback(
    (isArchived: boolean) => () => {
      if (!selectedIds || selectedIds.length == 0) {
        return
      }
      handleSaveArchived(selectedIds, isArchived)
      setModalArchiveOpen(false)
      setSelection([])
    },
    [selectedIds, handleSaveArchived]
  )

  const handleChangeParams = useCallback(
    (parameters: UserV1ContactsGetRequest) => {
      if (!parameters) {
        resetPagination()
        return
      }
      const { page: _page, per: _per, ...otherPrams } = parameters
      resetPagination()
      setSearchParams(otherPrams)
    },
    [resetPagination]
  )

  const { data: ContactList } = useListContactLists()
  const AllContactLists = ContactList?.contactLists

  const { data: materialDocList } = useListMaterialDocumentsLists({
    resourceType: EnumListResourceType.Contact,
    materialableType: EnumMaterialableType.Document
  })
  const allDocumentLists = materialDocList?.materialLists

  const { data: materialSiteList } = useListMaterialSitesLists({
    resourceType: EnumListResourceType.Contact,
    materialableType: EnumMaterialableType.Site
  })
  const allSiteLists = materialSiteList?.materialLists

  const { data: staffList } = useListStaffLists({
    resourceType: EnumListResourceType.Contact,
    page: 1,
    per: 999
  })
  const allStaffLists = staffList?.staffLists

  const { handleUpdateContactListsPin } = useUpdateContactListsPin()
  const handleContactListsPinConfirm = useCallback(
    (contactListIds: string[]) => {
      handleUpdateContactListsPin(contactListIds)
    },
    [handleUpdateContactListsPin]
  )
  const { handleUpdateMaterialDocumentsListsPin } = useUpdateMaterialDocumentsListsPin({
    resourceType: EnumListResourceType.Contact,
    materialableType: EnumMaterialableType.Document,
    page: 1,
    per: 999
  })
  const { handleUpdateMaterialSitesListsPin } = useUpdateMaterialSitesListsPin({
    resourceType: EnumListResourceType.Contact,
    materialableType: EnumMaterialableType.Site,
    page: 1,
    per: 999
  })
  const handleMaterialListsPinConfirm = useCallback(
    (ids: string[], type?: string) => {
      if (type === 'document') {
        handleUpdateMaterialDocumentsListsPin(ids, EnumMaterialableType.Document)
      } else {
        handleUpdateMaterialSitesListsPin(ids, EnumMaterialableType.Site)
      }
    },
    [handleUpdateMaterialDocumentsListsPin, handleUpdateMaterialSitesListsPin]
  )
  const { handleUpdateStaffListsPin } = useUpdateStaffListsPin({
    resourceType: EnumListResourceType.Contact,
    page: 1,
    per: 999
  })
  const handleStaffListsPinConfirm = useCallback(
    (userIds: string[]) => {
      handleUpdateStaffListsPin(userIds)
    },
    [handleUpdateStaffListsPin]
  )

  const { handleCreateContactList, isLoading: isLoadingCreateContactList } = useCreateContactList()
  const handleContactListConfirm = useCallback(
    (listName: string, contactIds: string[]) => {
      handleCreateContactList(listName, contactIds)
      setSelection([])
    },
    [handleCreateContactList]
  )

  const { handleUpdateContactList, isLoading: isLoadingUpdateContactList } = useUpdateContactList({
    ...searchParams,
    page: page + 1,
    per
  })
  const handleUpdateContactListConfirm = useCallback(
    (contactListId: string, contactIds: string[]) => {
      handleUpdateContactList(contactListId, contactIds)
      setSelection([])
    },
    [handleUpdateContactList]
  )

  const { handleDeleteContactList, isLoading: isLoadingDeleteContactList } = useDeleteContactList()
  const handleDeleteContactListConfirm = useCallback(
    (contactListId: string) => {
      handleDeleteContactList(contactListId)
    },
    [handleDeleteContactList]
  )

  const { handleUpdateContactListName, isLoading: isLoadingUpdateContactListName } = useUpdateContactListName()
  const handleUpdateContactListNameConfirm = useCallback(
    (contactListId: string, name: string) => {
      handleUpdateContactListName(contactListId, name)
    },
    [handleUpdateContactListName]
  )

  const searchForm = useMemo(() => {
    return (
      <Box left="312px" position="fixed">
        <MemoizedSearchAndSuggest type="contact" width="328px" onClick={handleClickRow} />
      </Box>
    )
  }, [handleClickRow])

  const isShowTable = useMemo(
    () =>
      !reloading &&
      !isLoadingCreateContactList &&
      !isLoadingUpdateContactList &&
      !isLoadingUpdateContactListName &&
      !isLoadingDeleteContactList &&
      !isLoadingArchiveContact &&
      storagedColumns.length > 0 &&
      columns.length > 0,
    [
      reloading,
      columns.length,
      storagedColumns.length,
      isLoadingArchiveContact,
      isLoadingCreateContactList,
      isLoadingDeleteContactList,
      isLoadingUpdateContactList,
      isLoadingUpdateContactListName
    ]
  )

  useEffect(() => {
    if (!org?.organization?.id) {
      return
    }
    const res = localStorageUtils.get<ModalSelectTableColumnOption[]>(
      StorageKey.CONTACTS_TABLE_COLUMNS(org.organization.id)
    )
    if (res) {
      const newStoragedColums = columns
        // MEMO: local に保存したデータにあれば取得、なければ column から生成を行って表示する
        .map(columnItem => {
          const item = res.find(localItem => columnItem.name === localItem.name)
          return item || { ...columnItem, checked: true }
        })
        // MEMO: local に保存した順番に並び替え
        .sort((a, b) => {
          const aIndex = res.findIndex(item => item.name === a.name)
          const bIndex = res.findIndex(item => item.name === b.name)
          if (aIndex === -1 || bIndex === -1) return 0
          return aIndex - bIndex
        })

      setStoragedColumns(newStoragedColums)
    } else {
      setStoragedColumns(columns.map(column => ({ ...column, checked: true })))
    }
  }, [columns, org?.organization?.id])

  const image = useMemo(() => {
    if (showNoDataImage) {
      return '/images/no_result.png'
    } else ''
  }, [showNoDataImage])

  const onConfirmModalSelectTableColumn = useCallback(
    (result: ModalSelectTableColumnOption[]) => {
      if (org?.organization?.id) {
        localStorageUtils.set(StorageKey.CONTACTS_TABLE_COLUMNS(org.organization.id), result)
      }

      // MEMO: テーブルのカラムが増える場合 runtime error を起こす場合があるので、table の再描画前に一度消す
      setRerendering(true)
      setStoragedColumns(result)
      setTimeout(() => {
        setRerendering(false)
      }, 32)
    },
    [org?.organization?.id]
  )

  const handleUpdateTableColumnWidth = useCallback(
    (nextColumnWidths: TableColumnWidthInfo[]) => {
      if (org?.organization?.id) {
        const next: ModalSelectTableColumnOption[] = storagedColumns.map(item => {
          const find = nextColumnWidths.find(i => i.columnName === item.name)
          if (find) {
            return { ...item, width: Number(find.width) }
          }
          return item
        })
        setStoragedColumns(next)
        localStorageUtils.set(StorageKey.CONTACTS_TABLE_COLUMNS(org.organization.id), next)
      }
    },
    [org?.organization?.id, storagedColumns]
  )

  const onClickSettings = useCallback(() => {
    router.push('/setting/team/contact')
  }, [router])

  useEffect(() => {
    if (res?.pageInfo?.totalPages && res?.pageInfo?.totalPages !== totalPages) {
      setTotalPages(res?.pageInfo?.totalPages)
    }
  }, [res?.pageInfo?.totalPages, setTotalPages, totalPages])

  const { handleRemoveContactListContactId } = useRemoveContactListContactId({
    ...searchParams,
    page: page + 1,
    per
  })
  const handleClickRemoveContact = useCallback(() => {
    handleRemoveContactListContactId(activeId, selectedIds)
    setModalArchiveOpen(false)
    setSelection([])
  }, [activeId, selectedIds, handleRemoveContactListContactId, setModalArchiveOpen, setSelection])

  return (
    <>
      <Layout>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box bgcolor={Colors.functional.background.default} borderBottom={`1px solid ${Colors.background.silver}`}>
            <Header
              iconSize="28px"
              icon="account"
              title="連絡先"
              leftSection={searchForm}
              rightSection={
                <HeaderRightSection
                  menuItemList={menuItemList}
                  isImportable={planGrade?.plan.canImportContact!}
                  onClick={handleClick}
                  onClickImport={handleClickImport}
                />
              }
              back={false}
            />
          </Box>
          <Box height="100%" flexGrow={1} display="flex" overflow="hidden">
            <FilteringMenu
              filter={filterList?.filter}
              filterType="contact"
              activeId={activeId}
              activeType={activeType}
              onClickSetActive={handleClickSetActive}
              onChangeParams={params => {
                handleChangeParams(params)
                setReloading(true)
                setTimeout(() => setReloading(false), 256)
              }}
              filteringListCounts={res?.pageInfo?.totalCount}
              selectedContactIds={selectedIds}
              onClearSelection={handleClearSelection}
              onCreateContactList={handleContactListConfirm}
              allCompanyOrContactLists={AllContactLists}
              allDocumentLists={allDocumentLists}
              allSiteLists={allSiteLists}
              allStaffLists={allStaffLists}
              onListsPinConfirm={handleContactListsPinConfirm}
              onMaterialListsPinConfirm={handleMaterialListsPinConfirm}
              onStaffListsPinConfirm={handleStaffListsPinConfirm}
              onDeleteList={handleDeleteContactListConfirm}
              onUpdateListName={handleUpdateContactListNameConfirm}
              onSetNoDataImage={setShowNoDataImage}
            />
            <Box pt="16px" flex={1} overflow="auto">
              {!rerendering && isShowTable && (
                <DynamicTable
                  selectable
                  columns={storagedColumns
                    .filter(item => item.checked)
                    .map(({ checked: _checked, name, width }) => {
                      return { ...columns.find(item => item.name === name), width } as TableColumn
                    })
                    .filter(Boolean)}
                  rows={contactList}
                  selection={selection}
                  onClickRow={row => handleClickRow(row.id!)}
                  setSelection={setSelection as TableProps<Contact>['setSelection']}
                  page={page}
                  per={per}
                  totalPages={totalPages}
                  changePage={page => {
                    changePage(page)
                    setSelection([])
                  }}
                  changePerPage={perPage => {
                    changePerPage(perPage)
                    setSelection([])
                  }}
                  numberOfRows={res?.pageInfo?.totalCount}
                  showNoDataImage={image}
                  showTotalCount
                  onOpenModalSelectTableColumn={() => setIsOpenModalSelectTableColumn(true)}
                  handleUpdateTableColumnWidth={handleUpdateTableColumnWidth}
                />
              )}
            </Box>
            <FloatingDialog
              domain="contact"
              selectedIds={selectedIds}
              activeType={activeType}
              setModalArchiveOpen={setModalArchiveOpen}
              setSelection={setSelection}
              onCreateListConfirm={handleContactListConfirm}
              onUpdateListConfirm={handleUpdateContactListConfirm}
              onClickRemove={handleClickRemoveContact}
              useList={useListSelectorContactsList}
            />
          </Box>
        </Box>
      </Layout>
      <ModalContactRegister
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => setModalOpen(false)}
        params={{ ...searchParams, page: page + 1, per }}
      />
      <ModalContactExport
        open={modalExportOpen}
        onClose={() => setModalExportOpen(false)}
        onConfirm={() => setModalExportOpen(false)}
      />
      <PopupTemplate
        open={modalArchiveOpen}
        title={`連絡先を${title}`}
        subTitle={`${selectedIds.length}件  選択中`}
        onClose={handleClickCloseModalArchive}
        hasClose
        hasCancel
        hasConfirm
        onConfirm={handleClickModalArchiveConfirm(activeType === 'archive')}
        cancelButtonLabel="キャンセル"
        confirmButtonLabel="OK"
      >
        <Box p="24px">
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {`選択した連絡先を${labelName}へ移動します\n本当によろしいですか？`}
          </Typography>
        </Box>
      </PopupTemplate>
      {/* 連絡先 import */}
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openImportModalUpgrade}
        lightPlanText="ライトプランでは、会社・連絡先のインポートはできません。プレミアムプランなら、会社・連絡先のインポートに加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText=""
        onClose={() => setOpenImportModalUpgrade(false)}
      />

      {/* 連絡先 export */}
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openExportModalUpgrade}
        lightPlanText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenExportModalUpgrade(false)}
      />

      {/* 連絡先 一括編集 */}
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openBulkUpdateModalUpgrade}
        lightPlanText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenBulkUpdateModalUpgrade(false)}
      />
      <ModalSelectTableColumn
        initialColumnItems={storagedColumns}
        open={isOpenModalSelectTableColumn}
        onClose={() => setIsOpenModalSelectTableColumn(false)}
        onCancel={() => setIsOpenModalSelectTableColumn(false)}
        onConfirm={onConfirmModalSelectTableColumn}
        onClickSettings={onClickSettings}
      />
    </>
  )
}
