import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var MeApi = /** @class */ (function () {
    function MeApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    MeApi.initialize = function (basePath, fetchApi) {
        if (!MeApi.instance) {
            MeApi.instance = new MeApi(basePath, fetchApi);
        }
        return MeApi.instance;
    };
    MeApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1MeApi(new Configuration(config));
    };
    return MeApi;
}());
export { MeApi };
