import React from 'react'
import { Box } from '@material-ui/core'
import { useRouter } from 'next/router'
import { Colors, DynamicMuiIcon, SvgIcon, Typography } from 'src/components/atoms'
import { DisplaySettingAppointment } from 'src/components/organisms'
import { DisplayAppointmentInChat } from 'src/components/organisms/DisplayAppointmentInChat'
import {
  useGetDocument,
  useListDocumentRegisteredOpportunityUsers,
  useUpdateDocumentOpportunity
} from 'src/fixtures/modules/documents/hooks'
import { useGlobalStyles } from 'src/styles/theme'

export const AppointmentContainer = ({ onClose }: { onClose?: () => void }) => {
  const globalClasses = useGlobalStyles()
  const { query } = useRouter()
  const { documentId } = query as { documentId: string }

  const { data: document } = useGetDocument(documentId)
  const { handleUpdateDocumentOpportunity } = useUpdateDocumentOpportunity(documentId)
  const { data: registeredOpportunityUsers } = useListDocumentRegisteredOpportunityUsers(documentId)
  const appointment = document?.documentOpportunity

  const handleClose = () => {
    onClose?.()
  }

  return (
    <Box width="100%" flex="1 0 auto" bgcolor="white" px="10px" height="100%" py="18px">
      {documentId && (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="8px" px="8px">
            <Box display="flex" alignItems="center">
              <SvgIcon variant="editCalendar" size="20px" color={Colors.base.middleGray} />
              <Box mr="6px" />
              <Typography fontSize="s" fontWeight="bold" lineheight="20px">
                アポイント
              </Typography>
            </Box>
            {onClose && (
              <Box
                height="20px"
                color={Colors.base.middleGray}
                className={globalClasses.cursorPointer}
                onClick={handleClose}
              >
                <DynamicMuiIcon variant="close" size="20px" />
              </Box>
            )}
          </Box>
          <DisplayAppointmentInChat
            documentId={documentId}
            checked={appointment?.isView!}
            userList={registeredOpportunityUsers?.documentOpportunityUsers ?? []}
            onToggleSwitch={() =>
              handleUpdateDocumentOpportunity({ documentOpportunity: { isView: !appointment?.isView } })
            }
          />
          <Box mt="32px" />
          <DisplaySettingAppointment documentId={documentId} />
          <Box pt="32px" />
        </>
      )}
    </Box>
  )
}
