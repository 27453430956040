import { DocumentShareForm, EnumDocumentSlideProcessingStatus } from '@noco/http-client/lib/noco'
import { MuiIconVariant } from 'src/components/atoms'

export const convertDocumentShareFormsToRequestType = (documentShareForms: DocumentShareForm[]) => {
  return documentShareForms.map(form => ({
    contactSettingId: form.contactSetting?.id,
    isRequired: form.isRequired
  }))
}

export const documentSlideProcessingStatusTextMap: { [key in EnumDocumentSlideProcessingStatus]: string } = {
  current_version_completed: '完了',
  current_version_proccesing: 'PDFファイルを処理中',
  current_version_failed: 'PDFファイルの処理に失敗しました',
  next_version_failed: 'PDFファイルの処理に失敗しました',
  next_version_proccesing: 'PDFファイルを処理中'
}

export const documentSlideProcessingStatusIcon: {
  [key in EnumDocumentSlideProcessingStatus]: MuiIconVariant | undefined
} = {
  current_version_completed: undefined,
  current_version_proccesing: 'sync',
  next_version_proccesing: 'sync',
  current_version_failed: 'error',
  next_version_failed: 'error'
}

export const getEuDocumentMetaTitle = ({
  documentTitle,
  organizationName,
  hideAd
}: {
  documentTitle?: string
  organizationName?: string
  hideAd?: boolean
}) => {
  // 広告非表示の場合には資料タイトル + org 名を表示する
  if (hideAd) {
    return `${documentTitle || ''}${organizationName ? ` ${organizationName}` : ''}`
  }
}
