/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  SelectorItemExtend,
  SelectorItemExtendFromJSON,
  SelectorItemExtendFromJSONTyped,
  SelectorItemExtendToJSON
} from './SelectorItemExtend'

/**
 *
 * @export
 * @interface SelectorItem
 */
export interface SelectorItem {
  /**
   *
   * @type {string}
   * @memberof SelectorItem
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SelectorItem
   */
  text?: string
  /**
   *
   * @type {SelectorItemExtend}
   * @memberof SelectorItem
   */
  extend?: SelectorItemExtend
}

export function SelectorItemFromJSON(json: any): SelectorItem {
  return SelectorItemFromJSONTyped(json, false)
}

export function SelectorItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    text: !exists(json, 'text') ? undefined : json['text'],
    extend: !exists(json, 'extend') ? undefined : SelectorItemExtendFromJSON(json['extend'])
  }
}

export function SelectorItemToJSON(value?: SelectorItem | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    text: value.text,
    extend: SelectorItemExtendToJSON(value.extend)
  }
}
