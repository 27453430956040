/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ChatMessageAttachmentFile,
  ChatMessageAttachmentFileFromJSON,
  ChatMessageAttachmentFileFromJSONTyped,
  ChatMessageAttachmentFileToJSON
} from './ChatMessageAttachmentFile'
import {
  MaterialContactable,
  MaterialContactableFromJSON,
  MaterialContactableFromJSONTyped,
  MaterialContactableToJSON
} from './MaterialContactable'

/**
 *
 * @export
 * @interface ChatMessageAttachment
 */
export interface ChatMessageAttachment {
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachment
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachment
   */
  contactChatMessageId?: string
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachment
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachment
   */
  updatedAt?: string
  /**
   *
   * @type {MaterialContactable}
   * @memberof ChatMessageAttachment
   */
  materialContactable?: MaterialContactable
  /**
   *
   * @type {ChatMessageAttachmentFile}
   * @memberof ChatMessageAttachment
   */
  chatMessageAttachmentFile?: ChatMessageAttachmentFile
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachment
   */
  type?: ChatMessageAttachmentTypeEnum
}

/**
 * @export
 */
export const ChatMessageAttachmentTypeEnum = {
  MaterialContactable: 'material_contactable',
  Image: 'image',
  File: 'file'
} as const
export type ChatMessageAttachmentTypeEnum =
  typeof ChatMessageAttachmentTypeEnum[keyof typeof ChatMessageAttachmentTypeEnum]

export function ChatMessageAttachmentFromJSON(json: any): ChatMessageAttachment {
  return ChatMessageAttachmentFromJSONTyped(json, false)
}

export function ChatMessageAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageAttachment {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    contactChatMessageId: !exists(json, 'contact_chat_message_id') ? undefined : json['contact_chat_message_id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    materialContactable: !exists(json, 'material_contactable')
      ? undefined
      : MaterialContactableFromJSON(json['material_contactable']),
    chatMessageAttachmentFile: !exists(json, 'chat_message_attachment_file')
      ? undefined
      : ChatMessageAttachmentFileFromJSON(json['chat_message_attachment_file']),
    type: !exists(json, 'type') ? undefined : json['type']
  }
}

export function ChatMessageAttachmentToJSON(value?: ChatMessageAttachment | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    contact_chat_message_id: value.contactChatMessageId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    material_contactable: MaterialContactableToJSON(value.materialContactable),
    chat_message_attachment_file: ChatMessageAttachmentFileToJSON(value.chatMessageAttachmentFile),
    type: value.type
  }
}
