import { UserV1StaffListsGetRequest } from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'
import { useListFilterContacts } from '../contact/hooks'
import { useListFilterCompany } from '../company/hooks'
import { useListInboxFilter } from '../inbox/hooks'

export const useListStaffLists = (params: UserV1StaffListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const staffListApi = nocoSDK.client?.userService.staffListApi
  const funcSite = useCallback(async () => {
    if (!auth) return

    return await staffListApi
      ?.ApiFactory(auth.token)
      .userV1StaffListsGet(params)
      .then(res => res.data)
  }, [auth, params, staffListApi])
  return useSWR(auth?.token && SWRCachePath.listStaffLists(params), funcSite)
}

export const useUpdateStaffListsPin = (params: UserV1StaffListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListFilterInbox } = useListInboxFilter()
  const { mutate: mutateListStaffLists } = useListStaffLists(params)
  const staffListApi = nocoSDK.client?.userService.staffListApi

  const handleUpdateStaffListsPin = useCallback(
    async (userIds: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await staffListApi?.ApiFactory(auth.token).userV1StaffListsPinPost({ pinStaffListRequest: { userIds } })

        if (params.resourceType === 'contact') {
          await mutateListFilterContacts()
        } else if (params.resourceType === 'company') {
          await mutateListFilterCompany()
        } else await mutateListFilterInbox()

        await mutateListStaffLists()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update StaffListApi' })
      } finally {
        setIsLoading(false)
      }
    },
    [
      auth,
      staffListApi,
      me,
      mutateListFilterContacts,
      mutateListFilterCompany,
      mutateListFilterInbox,
      mutateListStaffLists,
      params.resourceType
    ]
  )

  return { error, isLoading, handleUpdateStaffListsPin }
}
