/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'
import { SiteDocument, SiteDocumentFromJSON, SiteDocumentFromJSONTyped, SiteDocumentToJSON } from './SiteDocument'

/**
 *
 * @export
 * @interface GetSiteDocumentsResponseData
 */
export interface GetSiteDocumentsResponseData {
  /**
   *
   * @type {Array<SiteDocument>}
   * @memberof GetSiteDocumentsResponseData
   */
  siteDocuments?: Array<SiteDocument>
  /**
   *
   * @type {PageInfo}
   * @memberof GetSiteDocumentsResponseData
   */
  pageInfo?: PageInfo
}

export function GetSiteDocumentsResponseDataFromJSON(json: any): GetSiteDocumentsResponseData {
  return GetSiteDocumentsResponseDataFromJSONTyped(json, false)
}

export function GetSiteDocumentsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSiteDocumentsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteDocuments: !exists(json, 'site_documents')
      ? undefined
      : (json['site_documents'] as Array<any>).map(SiteDocumentFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetSiteDocumentsResponseDataToJSON(value?: GetSiteDocumentsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_documents:
      value.siteDocuments === undefined ? undefined : (value.siteDocuments as Array<any>).map(SiteDocumentToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
