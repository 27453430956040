import { Box } from '@material-ui/core'
import React from 'react'
import { CreditCardBrandIcon } from 'src/components/atoms'

export const CreditCardBrandList = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box mr="2px">
        <CreditCardBrandIcon variant="visa" />
      </Box>
      <Box mr="2px">
        <CreditCardBrandIcon variant="mastercard" />
      </Box>
      <Box mr="2px">
        <CreditCardBrandIcon variant="amex" />
      </Box>
      <Box mr="2px">
        <CreditCardBrandIcon variant="jcb" />
      </Box>
      <Box mr="2px">
        <CreditCardBrandIcon variant="diners" />
      </Box>
      <Box>
        <CreditCardBrandIcon variant="discover" />
      </Box>
    </Box>
  )
}
