/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ChatMessageAttachmentFile,
  ChatMessageAttachmentFileFromJSON,
  ChatMessageAttachmentFileFromJSONTyped,
  ChatMessageAttachmentFileToJSON
} from './ChatMessageAttachmentFile'

/**
 *
 * @export
 * @interface CreateChatMessageAttachmentFileResponseData
 */
export interface CreateChatMessageAttachmentFileResponseData {
  /**
   *
   * @type {ChatMessageAttachmentFile}
   * @memberof CreateChatMessageAttachmentFileResponseData
   */
  chatMessageAttachmentFile?: ChatMessageAttachmentFile
}

export function CreateChatMessageAttachmentFileResponseDataFromJSON(
  json: any
): CreateChatMessageAttachmentFileResponseData {
  return CreateChatMessageAttachmentFileResponseDataFromJSONTyped(json, false)
}

export function CreateChatMessageAttachmentFileResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateChatMessageAttachmentFileResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    chatMessageAttachmentFile: !exists(json, 'chat_message_attachment_file')
      ? undefined
      : ChatMessageAttachmentFileFromJSON(json['chat_message_attachment_file'])
  }
}

export function CreateChatMessageAttachmentFileResponseDataToJSON(
  value?: CreateChatMessageAttachmentFileResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    chat_message_attachment_file: ChatMessageAttachmentFileToJSON(value.chatMessageAttachmentFile)
  }
}
