/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CommonTimestamps,
  CommonTimestampsFromJSON,
  CommonTimestampsFromJSONTyped,
  CommonTimestampsToJSON
} from './CommonTimestamps'
import {
  CompanyForDisplay,
  CompanyForDisplayFromJSON,
  CompanyForDisplayFromJSONTyped,
  CompanyForDisplayToJSON
} from './CompanyForDisplay'
import { ContactAction, ContactActionFromJSON, ContactActionFromJSONTyped, ContactActionToJSON } from './ContactAction'
import {
  ContactForDisplay,
  ContactForDisplayFromJSON,
  ContactForDisplayFromJSONTyped,
  ContactForDisplayToJSON
} from './ContactForDisplay'
import {
  EnumActivityStatus,
  EnumActivityStatusFromJSON,
  EnumActivityStatusFromJSONTyped,
  EnumActivityStatusToJSON
} from './EnumActivityStatus'
import {
  EnumSupportStatus,
  EnumSupportStatusFromJSON,
  EnumSupportStatusFromJSONTyped,
  EnumSupportStatusToJSON
} from './EnumSupportStatus'
import {
  InboxItemAllOf,
  InboxItemAllOfFromJSON,
  InboxItemAllOfFromJSONTyped,
  InboxItemAllOfToJSON
} from './InboxItemAllOf'
import {
  MaterialableForDisplay,
  MaterialableForDisplayFromJSON,
  MaterialableForDisplayFromJSONTyped,
  MaterialableForDisplayToJSON
} from './MaterialableForDisplay'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface InboxItem
 */
export interface InboxItem {
  /**
   *
   * @type {Date}
   * @memberof InboxItem
   */
  createdAt?: Date
  /**
   *
   * @type {Date}
   * @memberof InboxItem
   */
  updatedAt?: Date
  /**
   *
   * @type {string}
   * @memberof InboxItem
   */
  id?: string
  /**
   *
   * @type {EnumActivityStatus}
   * @memberof InboxItem
   */
  activityStatus?: EnumActivityStatus
  /**
   *
   * @type {EnumSupportStatus}
   * @memberof InboxItem
   */
  supportStatus?: EnumSupportStatus
  /**
   *
   * @type {boolean}
   * @memberof InboxItem
   */
  isSolved?: boolean
  /**
   *
   * @type {string}
   * @memberof InboxItem
   */
  additionalText?: string | null
  /**
   *
   * @type {string}
   * @memberof InboxItem
   */
  latestContactActionedAt?: string
  /**
   *
   * @type {ContactForDisplay}
   * @memberof InboxItem
   */
  contact?: ContactForDisplay
  /**
   *
   * @type {CompanyForDisplay}
   * @memberof InboxItem
   */
  company?: CompanyForDisplay
  /**
   *
   * @type {MaterialableForDisplay}
   * @memberof InboxItem
   */
  materialable?: MaterialableForDisplay
  /**
   *
   * @type {ContactAction}
   * @memberof InboxItem
   */
  contactAction?: ContactAction
  /**
   *
   * @type {UserForDisplay}
   * @memberof InboxItem
   */
  user?: UserForDisplay
}

export function InboxItemFromJSON(json: any): InboxItem {
  return InboxItemFromJSONTyped(json, false)
}

export function InboxItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InboxItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
    updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    id: !exists(json, 'id') ? undefined : json['id'],
    activityStatus: !exists(json, 'activity_status') ? undefined : EnumActivityStatusFromJSON(json['activity_status']),
    supportStatus: !exists(json, 'support_status') ? undefined : EnumSupportStatusFromJSON(json['support_status']),
    isSolved: !exists(json, 'is_solved') ? undefined : json['is_solved'],
    additionalText: !exists(json, 'additional_text') ? undefined : json['additional_text'],
    latestContactActionedAt: !exists(json, 'latest_contact_actioned_at')
      ? undefined
      : json['latest_contact_actioned_at'],
    contact: !exists(json, 'contact') ? undefined : ContactForDisplayFromJSON(json['contact']),
    company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company']),
    materialable: !exists(json, 'materialable') ? undefined : MaterialableForDisplayFromJSON(json['materialable']),
    contactAction: !exists(json, 'contact_action') ? undefined : ContactActionFromJSON(json['contact_action']),
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user'])
  }
}

export function InboxItemToJSON(value?: InboxItem | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    id: value.id,
    activity_status: EnumActivityStatusToJSON(value.activityStatus),
    support_status: EnumSupportStatusToJSON(value.supportStatus),
    is_solved: value.isSolved,
    additional_text: value.additionalText,
    latest_contact_actioned_at: value.latestContactActionedAt,
    contact: ContactForDisplayToJSON(value.contact),
    company: CompanyForDisplayToJSON(value.company),
    materialable: MaterialableForDisplayToJSON(value.materialable),
    contact_action: ContactActionToJSON(value.contactAction),
    user: UserForDisplayToJSON(value.user)
  }
}
