/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ResponseDataSelectorCommon,
  ResponseDataSelectorCommonFromJSON,
  ResponseDataSelectorCommonToJSON,
  ResponseDataSelectorObjectCommon,
  ResponseDataSelectorObjectCommonFromJSON,
  ResponseDataSelectorObjectCommonToJSON
} from '../models'

export interface UserV1SelectorChatMessagesGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorCompaniesGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorCompanyListsGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorContactListsGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorContactsGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorDocumentsGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorMailTemplatesGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorMaterialablesGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  canShareToContact?: boolean
  page?: number
  per?: number
}

export interface UserV1SelectorShareTargetsGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorSitesGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1SelectorUsersGetRequest {
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

/**
 *
 */
export class ApiUserV1SelectorApi extends runtime.BaseAPI {
  /**
   * selector向け チャットメッセージ一覧
   * index
   */
  async userV1SelectorChatMessagesGetRaw(
    requestParameters: UserV1SelectorChatMessagesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/chat_messages`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け チャットメッセージ一覧
   * index
   */
  async userV1SelectorChatMessagesGet(
    requestParameters: UserV1SelectorChatMessagesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorChatMessagesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 会社一覧
   * index
   */
  async userV1SelectorCompaniesGetRaw(
    requestParameters: UserV1SelectorCompaniesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/companies`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 会社一覧
   * index
   */
  async userV1SelectorCompaniesGet(
    requestParameters: UserV1SelectorCompaniesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorCompaniesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 会社リスト一覧
   * index
   */
  async userV1SelectorCompanyListsGetRaw(
    requestParameters: UserV1SelectorCompanyListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/company_lists`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 会社リスト一覧
   * index
   */
  async userV1SelectorCompanyListsGet(
    requestParameters: UserV1SelectorCompanyListsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorCompanyListsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 連絡先リスト一覧
   * index
   */
  async userV1SelectorContactListsGetRaw(
    requestParameters: UserV1SelectorContactListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/contact_lists`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 連絡先リスト一覧
   * index
   */
  async userV1SelectorContactListsGet(
    requestParameters: UserV1SelectorContactListsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorContactListsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 連絡先一覧
   * index
   */
  async userV1SelectorContactsGetRaw(
    requestParameters: UserV1SelectorContactsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/contacts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 連絡先一覧
   * index
   */
  async userV1SelectorContactsGet(
    requestParameters: UserV1SelectorContactsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorContactsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 資料一覧
   * index
   */
  async userV1SelectorDocumentsGetRaw(
    requestParameters: UserV1SelectorDocumentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/documents`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 資料一覧
   * index
   */
  async userV1SelectorDocumentsGet(
    requestParameters: UserV1SelectorDocumentsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorDocumentsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け メールテンプレート一覧
   * index
   */
  async userV1SelectorMailTemplatesGetRaw(
    requestParameters: UserV1SelectorMailTemplatesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/mail_templates`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け メールテンプレート一覧
   * index
   */
  async userV1SelectorMailTemplatesGet(
    requestParameters: UserV1SelectorMailTemplatesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorMailTemplatesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 共有可能資料・資料サイト一覧
   * index
   */
  async userV1SelectorMaterialablesGetRaw(
    requestParameters: UserV1SelectorMaterialablesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorObjectCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.canShareToContact !== undefined) {
      queryParameters['can_share_to_contact'] = requestParameters.canShareToContact
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/materialables`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorObjectCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 共有可能資料・資料サイト一覧
   * index
   */
  async userV1SelectorMaterialablesGet(
    requestParameters: UserV1SelectorMaterialablesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorObjectCommon> {
    const response = await this.userV1SelectorMaterialablesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 共有先選択一覧
   * index
   */
  async userV1SelectorShareTargetsGetRaw(
    requestParameters: UserV1SelectorShareTargetsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorObjectCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/share_targets`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorObjectCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 共有先選択一覧
   * index
   */
  async userV1SelectorShareTargetsGet(
    requestParameters: UserV1SelectorShareTargetsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorObjectCommon> {
    const response = await this.userV1SelectorShareTargetsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け 資料サイト一覧
   * index
   */
  async userV1SelectorSitesGetRaw(
    requestParameters: UserV1SelectorSitesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/sites`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け 資料サイト一覧
   * index
   */
  async userV1SelectorSitesGet(
    requestParameters: UserV1SelectorSitesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorSitesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * selector向け ユーザー一覧
   * index
   */
  async userV1SelectorUsersGetRaw(
    requestParameters: UserV1SelectorUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataSelectorCommon>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/selector/users`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue))
  }

  /**
   * selector向け ユーザー一覧
   * index
   */
  async userV1SelectorUsersGet(
    requestParameters: UserV1SelectorUsersGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataSelectorCommon> {
    const response = await this.userV1SelectorUsersGetRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
