/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumMailTemplateVariableObjectType = {
    Regular: 'regular'
};
export function EnumMailTemplateVariableObjectTypeFromJSON(json) {
    return EnumMailTemplateVariableObjectTypeFromJSONTyped(json, false);
}
export function EnumMailTemplateVariableObjectTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumMailTemplateVariableObjectTypeToJSON(value) {
    return value;
}
