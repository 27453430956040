/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateMeProfileRequestUserProfile,
  UpdateMeProfileRequestUserProfileFromJSON,
  UpdateMeProfileRequestUserProfileFromJSONTyped,
  UpdateMeProfileRequestUserProfileToJSON
} from './UpdateMeProfileRequestUserProfile'

/**
 *
 * @export
 * @interface UpdateMeProfileRequest
 */
export interface UpdateMeProfileRequest {
  /**
   *
   * @type {UpdateMeProfileRequestUserProfile}
   * @memberof UpdateMeProfileRequest
   */
  userProfile?: UpdateMeProfileRequestUserProfile
}

export function UpdateMeProfileRequestFromJSON(json: any): UpdateMeProfileRequest {
  return UpdateMeProfileRequestFromJSONTyped(json, false)
}

export function UpdateMeProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeProfileRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userProfile: !exists(json, 'user_profile')
      ? undefined
      : UpdateMeProfileRequestUserProfileFromJSON(json['user_profile'])
  }
}

export function UpdateMeProfileRequestToJSON(value?: UpdateMeProfileRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_profile: UpdateMeProfileRequestUserProfileToJSON(value.userProfile)
  }
}
