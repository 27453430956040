import { Box } from '@material-ui/core'
import React from 'react'
import { Colors, Typography } from '..'

export interface NotificationBadgeProps {
  count: number
}

export const NotificationBadge = ({ count }: NotificationBadgeProps) => {
  return (
    <Box
      width={1}
      p="5px"
      bgcolor={Colors.accent.keyRed.default}
      borderRadius="50px"
      color={Colors.functional.whiteText.default}
      display="flex"
      justifyContent="center"
    >
      <Typography fontSize="xs" fontWeight="bold" lineheight="12px">
        {Math.min(count, 99)}
        {count > 99 ? '+' : ''}
      </Typography>
    </Box>
  )
}
