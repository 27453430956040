/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SelectorItemExtendContact
 */
export interface SelectorItemExtendContact {
  /**
   *
   * @type {string}
   * @memberof SelectorItemExtendContact
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SelectorItemExtendContact
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof SelectorItemExtendContact
   */
  displayNameKana?: string | null
  /**
   *
   * @type {string}
   * @memberof SelectorItemExtendContact
   */
  companyName?: string | null
}

export function SelectorItemExtendContactFromJSON(json: any): SelectorItemExtendContact {
  return SelectorItemExtendContactFromJSONTyped(json, false)
}

export function SelectorItemExtendContactFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SelectorItemExtendContact {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    displayNameKana: !exists(json, 'display_name_kana') ? undefined : json['display_name_kana'],
    companyName: !exists(json, 'company_name') ? undefined : json['company_name']
  }
}

export function SelectorItemExtendContactToJSON(value?: SelectorItemExtendContact | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    display_name: value.displayName,
    display_name_kana: value.displayNameKana,
    company_name: value.companyName
  }
}
