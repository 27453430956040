/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ActivityStatusObject,
  ActivityStatusObjectFromJSON,
  ActivityStatusObjectFromJSONTyped,
  ActivityStatusObjectToJSON
} from './ActivityStatusObject'
import {
  ContactWithAssociation,
  ContactWithAssociationFromJSON,
  ContactWithAssociationFromJSONTyped,
  ContactWithAssociationToJSON
} from './ContactWithAssociation'
import {
  EnumInflowSource,
  EnumInflowSourceFromJSON,
  EnumInflowSourceFromJSONTyped,
  EnumInflowSourceToJSON
} from './EnumInflowSource'
import {
  EnumSupportStatus,
  EnumSupportStatusFromJSON,
  EnumSupportStatusFromJSONTyped,
  EnumSupportStatusToJSON
} from './EnumSupportStatus'

/**
 *
 * @export
 * @interface SiteContact
 */
export interface SiteContact {
  /**
   *
   * @type {string}
   * @memberof SiteContact
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SiteContact
   */
  siteId?: string
  /**
   *
   * @type {string}
   * @memberof SiteContact
   */
  contactId?: string
  /**
   *
   * @type {string}
   * @memberof SiteContact
   */
  expiredOn?: string | null
  /**
   *
   * @type {boolean}
   * @memberof SiteContact
   */
  canViewable?: boolean
  /**
   *
   * @type {EnumInflowSource}
   * @memberof SiteContact
   */
  inflowSource?: EnumInflowSource
  /**
   *
   * @type {string}
   * @memberof SiteContact
   */
  actedAt?: string
  /**
   *
   * @type {string}
   * @memberof SiteContact
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof SiteContact
   */
  updatedAt?: string
  /**
   *
   * @type {ActivityStatusObject}
   * @memberof SiteContact
   */
  currentActivityStatusObject?: ActivityStatusObject
  /**
   *
   * @type {Array<ActivityStatusObject>}
   * @memberof SiteContact
   */
  activityStatusObjects?: Array<ActivityStatusObject>
  /**
   *
   * @type {EnumSupportStatus}
   * @memberof SiteContact
   */
  supportStatus?: EnumSupportStatus
  /**
   *
   * @type {ContactWithAssociation}
   * @memberof SiteContact
   */
  contact?: ContactWithAssociation
}

export function SiteContactFromJSON(json: any): SiteContact {
  return SiteContactFromJSONTyped(json, false)
}

export function SiteContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteContact {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    siteId: !exists(json, 'site_id') ? undefined : json['site_id'],
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
    expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
    canViewable: !exists(json, 'can_viewable') ? undefined : json['can_viewable'],
    inflowSource: !exists(json, 'inflow_source') ? undefined : EnumInflowSourceFromJSON(json['inflow_source']),
    actedAt: !exists(json, 'acted_at') ? undefined : json['acted_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    currentActivityStatusObject: !exists(json, 'current_activity_status_object')
      ? undefined
      : ActivityStatusObjectFromJSON(json['current_activity_status_object']),
    activityStatusObjects: !exists(json, 'activity_status_objects')
      ? undefined
      : (json['activity_status_objects'] as Array<any>).map(ActivityStatusObjectFromJSON),
    supportStatus: !exists(json, 'support_status') ? undefined : EnumSupportStatusFromJSON(json['support_status']),
    contact: !exists(json, 'contact') ? undefined : ContactWithAssociationFromJSON(json['contact'])
  }
}

export function SiteContactToJSON(value?: SiteContact | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    site_id: value.siteId,
    contact_id: value.contactId,
    expired_on: value.expiredOn,
    can_viewable: value.canViewable,
    inflow_source: EnumInflowSourceToJSON(value.inflowSource),
    acted_at: value.actedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    current_activity_status_object: ActivityStatusObjectToJSON(value.currentActivityStatusObject),
    activity_status_objects:
      value.activityStatusObjects === undefined
        ? undefined
        : (value.activityStatusObjects as Array<any>).map(ActivityStatusObjectToJSON),
    support_status: EnumSupportStatusToJSON(value.supportStatus),
    contact: ContactWithAssociationToJSON(value.contact)
  }
}
