/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentAddon,
  OrganizationPaymentAddonFromJSON,
  OrganizationPaymentAddonFromJSONTyped,
  OrganizationPaymentAddonToJSON
} from './OrganizationPaymentAddon'

/**
 *
 * @export
 * @interface CreateOrganizationPaymentScheduleBillingInvoiceRequest
 */
export interface CreateOrganizationPaymentScheduleBillingInvoiceRequest {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceRequest
   */
  planId: string
  /**
   *
   * @type {OrganizationPaymentAddon}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceRequest
   */
  addon?: OrganizationPaymentAddon
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceRequest
   */
  couponCode?: string
}

export function CreateOrganizationPaymentScheduleBillingInvoiceRequestFromJSON(
  json: any
): CreateOrganizationPaymentScheduleBillingInvoiceRequest {
  return CreateOrganizationPaymentScheduleBillingInvoiceRequestFromJSONTyped(json, false)
}

export function CreateOrganizationPaymentScheduleBillingInvoiceRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateOrganizationPaymentScheduleBillingInvoiceRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    planId: json['plan_id'],
    addon: !exists(json, 'addon') ? undefined : OrganizationPaymentAddonFromJSON(json['addon']),
    couponCode: !exists(json, 'coupon_code') ? undefined : json['coupon_code']
  }
}

export function CreateOrganizationPaymentScheduleBillingInvoiceRequestToJSON(
  value?: CreateOrganizationPaymentScheduleBillingInvoiceRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan_id: value.planId,
    addon: OrganizationPaymentAddonToJSON(value.addon),
    coupon_code: value.couponCode
  }
}
