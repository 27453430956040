/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateCompanyRequestCompanyFromJSON, CreateCompanyRequestCompanyToJSON } from './CreateCompanyRequestCompany';
export function CreateCompanyRequestFromJSON(json) {
    return CreateCompanyRequestFromJSONTyped(json, false);
}
export function CreateCompanyRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        company: !exists(json, 'company') ? undefined : CreateCompanyRequestCompanyFromJSON(json['company']),
        contactId: !exists(json, 'contact_id') ? undefined : json['contact_id']
    };
}
export function CreateCompanyRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company: CreateCompanyRequestCompanyToJSON(value.company),
        contact_id: value.contactId
    };
}
