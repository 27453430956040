/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  userName?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof UserProfile
   */
  userAvatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  organizationName?: string
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  department?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  position?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  birthplace?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  hobby?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  selfIntroduction?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  socialLinkYoutube?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  socialLinkFacebook?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  socialLinkTwitter?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  socialLinkNote?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  socialLinkWebSite?: string | null
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  updatedAt?: string
}

export function UserProfileFromJSON(json: any): UserProfile {
  return UserProfileFromJSONTyped(json, false)
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    userName: !exists(json, 'user_name') ? undefined : json['user_name'],
    userAvatar: !exists(json, 'user_avatar')
      ? undefined
      : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['user_avatar']),
    organizationName: !exists(json, 'organization_name') ? undefined : json['organization_name'],
    department: !exists(json, 'department') ? undefined : json['department'],
    position: !exists(json, 'position') ? undefined : json['position'],
    birthplace: !exists(json, 'birthplace') ? undefined : json['birthplace'],
    hobby: !exists(json, 'hobby') ? undefined : json['hobby'],
    selfIntroduction: !exists(json, 'self_introduction') ? undefined : json['self_introduction'],
    socialLinkYoutube: !exists(json, 'social_link_youtube') ? undefined : json['social_link_youtube'],
    socialLinkFacebook: !exists(json, 'social_link_facebook') ? undefined : json['social_link_facebook'],
    socialLinkTwitter: !exists(json, 'social_link_twitter') ? undefined : json['social_link_twitter'],
    socialLinkNote: !exists(json, 'social_link_note') ? undefined : json['social_link_note'],
    socialLinkWebSite: !exists(json, 'social_link_web_site') ? undefined : json['social_link_web_site'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function UserProfileToJSON(value?: UserProfile | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    user_name: value.userName,
    user_avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.userAvatar),
    organization_name: value.organizationName,
    department: value.department,
    position: value.position,
    birthplace: value.birthplace,
    hobby: value.hobby,
    self_introduction: value.selfIntroduction,
    social_link_youtube: value.socialLinkYoutube,
    social_link_facebook: value.socialLinkFacebook,
    social_link_twitter: value.socialLinkTwitter,
    social_link_note: value.socialLinkNote,
    social_link_web_site: value.socialLinkWebSite,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
