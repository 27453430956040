import { useGetContactOrganizationChatSetting } from 'src/fixtures/modules/contactOrganization/hooks'
import { useGetOrganizationChatSetting } from 'src/fixtures/modules/organization/hooks'

export const useGetChatSetting = (isPreview = false) => {
  const { data: getEuOrganizationChatSettingResponse } = useGetContactOrganizationChatSetting(isPreview)
  const { data: getOrganizationChatSettingResponseData } = useGetOrganizationChatSetting(isPreview)

  return {
    organizationChatSetting:
      getEuOrganizationChatSettingResponse?.organizationChatSetting ||
      getOrganizationChatSettingResponseData?.organizationChatSetting
  }
}
