import { useCallback, useMemo } from 'react'
import { Box, Typography, ButtonBase, Slide } from '@material-ui/core'
import { Input, DynamicMuiIcon, InformationRow, Tooltip } from 'src/components/atoms'
import { UpdateInformation } from 'src/components/molecules'
import { useGlobalStyles } from 'src/styles/theme'
import { useGetDocument, useUpdateDocument } from 'src/fixtures/modules/documents/hooks'
import { DocumentLabelSelect } from '../../atoms'
import { publicationStatusIconTextMap, publicationStatusTextMap } from 'src/fixtures/utils/text'

export interface DocumentInformationProps {
  documentId: string
  open: boolean
  onClose: () => void
}

export const DocumentInformation = ({ documentId, open, onClose }: DocumentInformationProps) => {
  const globalClasses = useGlobalStyles()
  const { handleUpdateDocument } = useUpdateDocument(documentId)
  const { data: document } = useGetDocument(documentId)

  const InfoList = useMemo(() => {
    return [
      {
        variant: 'textWithIcon',
        label: '作成者',
        icon: (
          <Box width="20px" height="20px" borderRadius="50%" overflow="hidden">
            {document?.createdBy?.avatar.url ? (
              <img src={document?.createdBy?.avatar.url} alt="avatar" className={globalClasses.imgCover} />
            ) : (
              <DynamicMuiIcon variant="account" size="20px" color="action" />
            )}
          </Box>
        ),
        content: document?.createdBy?.displayName
      },
      {
        variant: 'textWithIcon',
        label: '公開状態',
        icon: document?.publicationStatus && (
          <DynamicMuiIcon
            variant={publicationStatusIconTextMap[document?.publicationStatus]}
            size="20px"
            color="action"
          />
        ),
        content: document?.publicationStatus && publicationStatusTextMap[document?.publicationStatus]
      },
      {
        variant: 'plain',
        label: 'アポイント設定',
        content: document?.documentOpportunity?.isView ? 'あり' : '-'
      },
      {
        variant: 'plain',
        label: 'スライド枚数',
        content: `${document?.currentSlide?.slideImages?.length}ページ`
      },
      {
        variant: 'plain',
        label: 'CTA設置スライド',
        content: document?.documentCta?.isView ? `${document.documentCta.page}ページ目` : '-'
      }
    ]
  }, [document, globalClasses])

  const handleLabelChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      handleUpdateDocument({ label: value })
    },
    [handleUpdateDocument]
  )

  return (
    <Box display="flex" alignItems="center" justifyContent="right" pl="6px" pr="6px">
      <Slide direction="left" in={open}>
        <Box width={1} overflow="auto">
          <Box display="flex" alignItems="center" mb="16px">
            <DynamicMuiIcon variant="info" size="20px" color="action" />
            <Box display="flex" flex={1} pl="6px">
              <Typography variant="h5">資料情報</Typography>
            </Box>
            <Box width="20px" display="flex" justifyContent="center">
              <ButtonBase onClick={onClose}>
                <DynamicMuiIcon variant="close" size="20px" color="action" />
              </ButtonBase>
            </Box>
          </Box>

          <Box mt="10px" />

          <Box width="fit-content">
            <Tooltip content={'ラベルを選択することで、資料をカテゴリ別に整理できます。'}>
              <Typography variant="h6">ラベル</Typography>
            </Tooltip>
          </Box>
          <Box mt="6px" />
          <DocumentLabelSelect value={document?.label || ''} onChange={handleLabelChange} />

          <Box mt="18px" />
          <Box width="fit-content">
            <Tooltip content={'共有相手に表示される資料の名称です。'}>
              <Typography variant="h6">資料名</Typography>
            </Tooltip>
          </Box>
          <Box mt="6px" />
          <Input
            name="documentName"
            placeholder="noco営業資料"
            fullWidth
            defaultValue={document?.title}
            onBlur={e => handleUpdateDocument({ title: e.currentTarget.value })}
          />

          <Box mt="18px" />
          <Box width="fit-content">
            <Tooltip content={'共有相手に表示される資料の説明文です。'}>
              <Typography variant="h6">資料の説明</Typography>
            </Tooltip>
          </Box>
          <Box mt="6px" />
          <Input
            name="explanation"
            placeholder="顧客に向けた資料の説明文を入力"
            multiline
            rows={3}
            fullWidth
            defaultValue={document?.description || ''}
            onBlur={e => handleUpdateDocument({ description: e.currentTarget.value })}
          />

          <Box mt="18px" />
          <Box width="fit-content">
            <Tooltip content={'社内の情報共有のために利用するメモです。外部には公開されません。'}>
              <Typography variant="h6">社内メモ</Typography>
            </Tooltip>
          </Box>
          <Box mt="6px" />
          <Input
            name="memo"
            placeholder="資料に関するメモを記入"
            multiline
            rows={3}
            fullWidth
            defaultValue={document?.memo || ''}
            onBlur={e => handleUpdateDocument({ memo: e.currentTarget.value })}
          />
          <Box mt="32px" />
          {InfoList.map(info => (
            <InformationRow
              key={info.label}
              variant={info.variant}
              label={info.label}
              icon={info.icon}
              content={info.content!}
              disableCopy
              padding="9px 0px"
            />
          ))}
          <Box mt="32px" />
          <UpdateInformation
            createdAt={document?.createdAt!}
            createdBy={document?.createdBy!}
            updatedAt={document?.updatedAt}
            updatedBy={document?.updatedBy}
            createdLabel="作成日"
          />
        </Box>
      </Slide>
    </Box>
  )
}
