/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateDocumentCtaRequestDocumentCtaFromJSON(json) {
    return UpdateDocumentCtaRequestDocumentCtaFromJSONTyped(json, false);
}
export function UpdateDocumentCtaRequestDocumentCtaFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        page: !exists(json, 'page') ? undefined : json['page'],
        popupPosition: !exists(json, 'popup_position') ? undefined : json['popup_position'],
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        buttonName: !exists(json, 'button_name') ? undefined : json['button_name'],
        buttonColor: !exists(json, 'button_color') ? undefined : json['button_color'],
        buttonUrl: !exists(json, 'button_url') ? undefined : json['button_url'],
        isView: !exists(json, 'is_view') ? undefined : json['is_view']
    };
}
export function UpdateDocumentCtaRequestDocumentCtaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        page: value.page,
        popup_position: value.popupPosition,
        title: value.title,
        description: value.description,
        button_name: value.buttonName,
        button_color: value.buttonColor,
        button_url: value.buttonUrl,
        is_view: value.isView
    };
}
