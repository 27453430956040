import { makeStyles, createStyles, SvgIconProps } from '@material-ui/core'
import {
  AccountCircle,
  AddBoxOutlined,
  BusinessRounded,
  CropOriginal,
  InsertDriveFile,
  Notifications,
  PermIdentity,
  SupervisorAccount,
  Close,
  Search,
  Add,
  Edit,
  Visibility,
  VisibilityOff,
  Phone,
  MoreVert,
  DragIndicator,
  Wc,
  HomeWork,
  AssignmentInd,
  Home,
  RecordVoiceOver,
  Public,
  Room,
  FreeBreakfast,
  Work,
  KeyboardArrowDown,
  Person,
  AccountBox,
  CalendarToday,
  MeetingRoom,
  Dashboard,
  FileDownload,
  Group,
  Groups,
  ChatBubble,
  Policy,
  ListAlt,
  Delete,
  RemoveCircle,
  TextFields,
  WrapText,
  Pin,
  PushPin,
  RadioButtonChecked,
  List,
  AccessTime,
  ContentCopyRounded,
  BuildRounded,
  EmailRounded,
  Error,
  ErrorOutline,
  FmdGood,
  HelpRounded,
  HistoryRounded,
  InsertLinkRounded,
  IosShare,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardReturnRounded,
  Lock,
  LocalPoliceRounded,
  ManageAccounts,
  MoneyRounded,
  MarkEmailUnreadRounded,
  OpenInNew,
  PhoneRounded,
  RecentActorsRounded,
  RoomPreferencesRounded,
  Help,
  PictureAsPdf,
  Folder,
  Archive,
  Summarize,
  Info,
  InfoOutlined,
  TripOrigin,
  Settings,
  EventNote,
  Send,
  PlayArrow,
  HelpOutline,
  Drafts,
  TrackChanges,
  ExitToApp,
  Web,
  WarningAmber,
  Cancel,
  Image,
  CheckCircle,
  CheckCircleOutline,
  ErrorOutlineOutlined,
  Map,
  YouTube,
  Facebook,
  Twitter,
  OpenWith,
  FilterAlt,
  Laptop,
  ExpandMore,
  ExpandLess,
  InsertChart,
  Inbox,
  Campaign,
  Apartment,
  DoDisturbAlt,
  NotificationImportantOutlined,
  AddToPhotosRounded,
  NoteRounded,
  Message,
  ComputerRounded,
  ForwardToInbox,
  PhoneAndroid,
  ContactPage,
  School,
  LiveTv,
  Hail,
  RemoveCircleOutline,
  Print,
  Reply,
  Share,
  Sync,
  HorizontalRule,
  TrendingFlat,
  FileUpload,
  SellOutlined,
  Payment,
  UploadFile,
  Quiz,
  ImportContacts,
  GetAppRounded,
  ArrowDropDownRounded,
  CreateNewFolder,
  AttachFile,
  Check
} from '@material-ui/icons'
import { useMemo } from 'react'
import clsx from 'clsx'

export const MuiIconMap = {
  crop: CropOriginal,
  account: AccountCircle,
  addBoxOutlined: AddBoxOutlined,
  business: BusinessRounded,
  document: InsertDriveFile,
  notification: Notifications,
  personal: PermIdentity,
  team: SupervisorAccount,
  close: Close,
  search: Search,
  add: Add,
  edit: Edit,
  visibility: Visibility,
  visibilityOff: VisibilityOff,
  phone: Phone,
  dots: MoreVert,
  dragIndicator: DragIndicator,
  gender: Wc,
  address: HomeWork,
  role: AssignmentInd,
  home: Home,
  language: RecordVoiceOver,
  country: Public,
  city: Room,
  hobby: FreeBreakfast,
  work: Work,
  arrowDown: KeyboardArrowDown,
  person: Person,
  profile: AccountBox,
  manageAccounts: ManageAccounts,
  meeting: CalendarToday,
  meetingRoom: MeetingRoom,
  dashboard: Dashboard,
  fileDownload: FileDownload,
  group: Group,
  groups: Groups,
  chatBubble: ChatBubble,
  policy: Policy,
  listAlt: ListAlt,
  delete: Delete,
  removeCircle: RemoveCircle,
  textFields: TextFields,
  wrapText: WrapText,
  pin: Pin,
  pushPin: PushPin,
  radioButtonChecked: RadioButtonChecked,
  list: List,
  accessTime: AccessTime,
  actors: RecentActorsRounded,
  copy: ContentCopyRounded,
  email: EmailRounded,
  error: Error,
  errorOutline: ErrorOutline,
  helpRounded: HelpRounded,
  build: BuildRounded,
  iosShare: IosShare,
  arrowLeft: KeyboardArrowLeft,
  arrowRight: KeyboardArrowRight,
  keyboardReturn: KeyboardReturnRounded,
  link: InsertLinkRounded,
  lock: Lock,
  location: FmdGood,
  localPolice: LocalPoliceRounded,
  money: MoneyRounded,
  markEmail: MarkEmailUnreadRounded,
  openNew: OpenInNew,
  phoneRounded: PhoneRounded,
  roomPreferences: RoomPreferencesRounded,
  help: Help,
  history: HistoryRounded,
  pdf: PictureAsPdf,
  folder: Folder,
  archive: Archive,
  summarize: Summarize,
  info: Info,
  infoOutlined: InfoOutlined,
  circle: TripOrigin,
  setting: Settings,
  eventNote: EventNote,
  send: Send,
  playArrow: PlayArrow,
  helpOutline: HelpOutline,
  drafts: Drafts,
  trackChanges: TrackChanges,
  exit: ExitToApp,
  web: Web,
  cancel: Cancel,
  image: Image,
  checkCircle: CheckCircle,
  checkCircleOutline: CheckCircleOutline,
  errorOutlineOutlined: ErrorOutlineOutlined,
  map: Map,
  youTube: YouTube,
  facebook: Facebook,
  twitter: Twitter,
  openWith: OpenWith,
  filterAlt: FilterAlt,
  laptop: Laptop,
  expandMore: ExpandMore,
  expandLess: ExpandLess,
  computer: ComputerRounded,
  chart: InsertChart,
  inbox: Inbox,
  campaign: Campaign,
  apartment: Apartment,
  doDisturbAlt: DoDisturbAlt,
  notificationImportantOutlined: NotificationImportantOutlined,
  addToPhotosRoundedIcon: AddToPhotosRounded,
  noteRounded: NoteRounded,
  message: Message,
  forwardToInbox: ForwardToInbox,
  mobile: PhoneAndroid,
  career: ContactPage,
  school: School,
  liveTv: LiveTv,
  hail: Hail,
  removeCircleOutline: RemoveCircleOutline,
  print: Print,
  reply: Reply,
  share: Share,
  sync: Sync,
  warningAmber: WarningAmber,
  horizontalRule: HorizontalRule,
  trendingFlat: TrendingFlat,
  fileUpload: FileUpload,
  sellOutlined: SellOutlined,
  payment: Payment,
  uploadFile: UploadFile,
  quiz: Quiz,
  importContacts: ImportContacts,
  getApp: GetAppRounded,
  arrowDropDown: ArrowDropDownRounded,
  createNewFolder: CreateNewFolder,
  attachFile: AttachFile,
  check: Check
} as const

export type MuiIconVariant = keyof typeof MuiIconMap

export interface MuiIconProps {
  size?: string | number
}

const getSize = ({ size }: Partial<MuiIconProps>) => {
  if (typeof size === 'number') return `${size}px`
  else return size
}

const useStyles = makeStyles(() => {
  return createStyles({
    icon: {
      width: ({ size }: MuiIconProps) => getSize({ size }),
      height: ({ size }: MuiIconProps) => getSize({ size })
    }
  })
})

export const DynamicMuiIcon = ({
  variant,
  className,
  ...otherProps
}: { variant: MuiIconVariant } & MuiIconProps & SvgIconProps) => {
  const classes = useStyles(otherProps)
  const Icon = useMemo(() => {
    return MuiIconMap[variant]
  }, [variant])
  return <Icon className={clsx(classes.icon, className)} {...otherProps} />
}
