import React from 'react'
import { ContactTimeline, EnumTimelineContentType } from '@noco/http-client/lib/noco'
import { ClientTimeLineItemMemo } from './ClientTimeLineItemMemo'
import { ClientTimeLineItemAction } from './ClientTimeLineItemAction'
import { ClientTimeLineItemAudit } from './ClientTimeLineItemAudit'
import { ClientTimeLineItemUserOperation } from './ClientTimeLineItemUserOperation'

export type TimeLineChangeType = 'contact' | 'company'

interface ClientTimeLineItemProps {
  item: ContactTimeline
  size?: 'small' | 'medium'
  onDelete?: (contactMemoId: string) => Promise<void>
  onEdit?: (contactMemoId: string, memo: string) => Promise<void>
  type: TimeLineChangeType
}

export const ClientTimeLineItem = ({ item, onDelete, onEdit, type, size = 'medium' }: ClientTimeLineItemProps) => {
  if (item.contentType === EnumTimelineContentType.Memo) {
    return <ClientTimeLineItemMemo item={item} onDelete={onDelete} onEdit={onEdit} size={size} />
  } else if (item.contentType === EnumTimelineContentType.Action) {
    return <ClientTimeLineItemAction item={item} size={size} />
  } else if (item.contentType === EnumTimelineContentType.Audit) {
    return <ClientTimeLineItemAudit item={item} type={type} size={size} />
  }

  return <ClientTimeLineItemUserOperation item={item} size={size} />
}
