/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationPaymentCardInfo
 */
export interface OrganizationPaymentCardInfo {
  /**
   *
   * @type {string}
   * @memberof OrganizationPaymentCardInfo
   */
  last4?: string
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentCardInfo
   */
  expYear?: number
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentCardInfo
   */
  expMonth?: number
  /**
   *
   * @type {string}
   * @memberof OrganizationPaymentCardInfo
   */
  brand?: OrganizationPaymentCardInfoBrandEnum
}

/**
 * @export
 */
export const OrganizationPaymentCardInfoBrandEnum = {
  Amex: 'amex',
  Diners: 'diners',
  Discover: 'discover',
  Jcb: 'jcb',
  Mastercard: 'mastercard',
  Visa: 'visa',
  Unionpay: 'unionpay',
  Unknown: 'unknown'
} as const
export type OrganizationPaymentCardInfoBrandEnum =
  typeof OrganizationPaymentCardInfoBrandEnum[keyof typeof OrganizationPaymentCardInfoBrandEnum]

export function OrganizationPaymentCardInfoFromJSON(json: any): OrganizationPaymentCardInfo {
  return OrganizationPaymentCardInfoFromJSONTyped(json, false)
}

export function OrganizationPaymentCardInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPaymentCardInfo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    last4: !exists(json, 'last4') ? undefined : json['last4'],
    expYear: !exists(json, 'exp_year') ? undefined : json['exp_year'],
    expMonth: !exists(json, 'exp_month') ? undefined : json['exp_month'],
    brand: !exists(json, 'brand') ? undefined : json['brand']
  }
}

export function OrganizationPaymentCardInfoToJSON(value?: OrganizationPaymentCardInfo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    last4: value.last4,
    exp_year: value.expYear,
    exp_month: value.expMonth,
    brand: value.brand
  }
}
