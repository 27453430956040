/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumMailTemplateVariableObjectType,
  EnumMailTemplateVariableObjectTypeFromJSON,
  EnumMailTemplateVariableObjectTypeFromJSONTyped,
  EnumMailTemplateVariableObjectTypeToJSON
} from './EnumMailTemplateVariableObjectType'

/**
 *
 * @export
 * @interface MailTemplateVariableObject
 */
export interface MailTemplateVariableObject {
  /**
   *
   * @type {EnumMailTemplateVariableObjectType}
   * @memberof MailTemplateVariableObject
   */
  type: EnumMailTemplateVariableObjectType
  /**
   *
   * @type {number}
   * @memberof MailTemplateVariableObject
   */
  sort: number
  /**
   *
   * @type {string}
   * @memberof MailTemplateVariableObject
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof MailTemplateVariableObject
   */
  label: string
}

export function MailTemplateVariableObjectFromJSON(json: any): MailTemplateVariableObject {
  return MailTemplateVariableObjectFromJSONTyped(json, false)
}

export function MailTemplateVariableObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MailTemplateVariableObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: EnumMailTemplateVariableObjectTypeFromJSON(json['type']),
    sort: json['sort'],
    name: json['name'],
    label: json['label']
  }
}

export function MailTemplateVariableObjectToJSON(value?: MailTemplateVariableObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: EnumMailTemplateVariableObjectTypeToJSON(value.type),
    sort: value.sort,
    name: value.name,
    label: value.label
  }
}
