/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumActivityStatus = {
    Notyet: 'notyet',
    Chat: 'chat',
    FirstView: 'first_view',
    View: 'view',
    Opportunity: 'opportunity',
    Download: 'download',
    ViewProfile: 'view_profile',
    Cta: 'cta'
};
export function EnumActivityStatusFromJSON(json) {
    return EnumActivityStatusFromJSONTyped(json, false);
}
export function EnumActivityStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumActivityStatusToJSON(value) {
    return value;
}
