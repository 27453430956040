/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { MailTemplate, MailTemplateFromJSON, MailTemplateFromJSONTyped, MailTemplateToJSON } from './MailTemplate'

/**
 *
 * @export
 * @interface CreateMailTemplateResponseData
 */
export interface CreateMailTemplateResponseData {
  /**
   *
   * @type {MailTemplate}
   * @memberof CreateMailTemplateResponseData
   */
  mailTemplate?: MailTemplate
}

export function CreateMailTemplateResponseDataFromJSON(json: any): CreateMailTemplateResponseData {
  return CreateMailTemplateResponseDataFromJSONTyped(json, false)
}

export function CreateMailTemplateResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateMailTemplateResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mailTemplate: !exists(json, 'mail_template') ? undefined : MailTemplateFromJSON(json['mail_template'])
  }
}

export function CreateMailTemplateResponseDataToJSON(value?: CreateMailTemplateResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mail_template: MailTemplateToJSON(value.mailTemplate)
  }
}
