import React, { useMemo } from 'react'
import { AttachmentFile, AttachmentType, DeleteCardProps } from './type'
import { FileCard } from './FileCard'
import { ImageCard } from './ImageCard'
import { MaterialCard, MaterialType } from './MaterialCard'

export interface AttachmentCardProps {
  type: AttachmentType
  attachmentFile: AttachmentFile
  onDelete?: () => void
}

export const AttachmentCard = ({ type, attachmentFile, onDelete }: AttachmentCardProps) => {
  const deleteProps = useMemo<DeleteCardProps>(() => {
    return { isDeletable: !!(!attachmentFile?.url || onDelete), onDelete: onDelete }
  }, [attachmentFile?.url, onDelete])

  switch (type) {
    case 'document':
    case 'site':
      return <MaterialCard type={type as MaterialType} attachmentFile={attachmentFile} deleteProps={deleteProps} />
    case 'file':
      return <FileCard attachmentFile={attachmentFile} deleteProps={deleteProps} />
    case 'image':
      return <ImageCard attachmentFile={attachmentFile} deleteProps={deleteProps} />
    default:
      return null
  }
}
