/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Avatar
 */
export interface Avatar {
  /**
   *
   * @type {string}
   * @memberof Avatar
   */
  url?: string
}

export function AvatarFromJSON(json: any): Avatar {
  return AvatarFromJSONTyped(json, false)
}

export function AvatarFromJSONTyped(json: any, ignoreDiscriminator: boolean): Avatar {
  if (json === undefined || json === null) {
    return json
  }
  return {
    url: !exists(json, 'url') ? undefined : json['url']
  }
}

export function AvatarToJSON(value?: Avatar | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    url: value.url
  }
}
