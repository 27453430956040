/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumMaterialableType,
  EnumMaterialableTypeFromJSON,
  EnumMaterialableTypeFromJSONTyped,
  EnumMaterialableTypeToJSON
} from './EnumMaterialableType'
import { UploadedFile, UploadedFileFromJSON, UploadedFileFromJSONTyped, UploadedFileToJSON } from './UploadedFile'

/**
 *
 * @export
 * @interface Materialable
 */
export interface Materialable {
  /**
   *
   * @type {EnumMaterialableType}
   * @memberof Materialable
   */
  materialableType?: EnumMaterialableType
  /**
   *
   * @type {string}
   * @memberof Materialable
   */
  materialableId?: string
  /**
   *
   * @type {string}
   * @memberof Materialable
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof Materialable
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof Materialable
   */
  memo?: string | null
  /**
   *
   * @type {UploadedFile}
   * @memberof Materialable
   */
  thumbnail?: UploadedFile | null
  /**
   *
   * @type {UploadedFile}
   * @memberof Materialable
   */
  thumbnailSlideImage?: UploadedFile | null
  /**
   *
   * @type {string}
   * @memberof Materialable
   */
  publishedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof Materialable
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof Materialable
   */
  updatedAt?: string
}

export function MaterialableFromJSON(json: any): Materialable {
  return MaterialableFromJSONTyped(json, false)
}

export function MaterialableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Materialable {
  if (json === undefined || json === null) {
    return json
  }
  return {
    materialableType: !exists(json, 'materialable_type')
      ? undefined
      : EnumMaterialableTypeFromJSON(json['materialable_type']),
    materialableId: !exists(json, 'materialable_id') ? undefined : json['materialable_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    thumbnail: !exists(json, 'thumbnail') ? undefined : UploadedFileFromJSON(json['thumbnail']),
    thumbnailSlideImage: !exists(json, 'thumbnail_slide_image')
      ? undefined
      : UploadedFileFromJSON(json['thumbnail_slide_image']),
    publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function MaterialableToJSON(value?: Materialable | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
    materialable_id: value.materialableId,
    title: value.title,
    description: value.description,
    memo: value.memo,
    thumbnail: UploadedFileToJSON(value.thumbnail),
    thumbnail_slide_image: UploadedFileToJSON(value.thumbnailSlideImage),
    published_at: value.publishedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
