/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AppendContactsCompanyResponseDataFromJSON, AppendContactsCompanyResponseDataToJSON } from './AppendContactsCompanyResponseData';
export function AppendContactsCompanyResponseFromJSON(json) {
    return AppendContactsCompanyResponseFromJSONTyped(json, false);
}
export function AppendContactsCompanyResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        status: !exists(json, 'status') ? undefined : json['status'],
        data: !exists(json, 'data') ? undefined : AppendContactsCompanyResponseDataFromJSON(json['data'])
    };
}
export function AppendContactsCompanyResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        status: value.status,
        data: AppendContactsCompanyResponseDataToJSON(value.data)
    };
}
