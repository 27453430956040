import React from 'react'
import dynamic from 'next/dynamic'
import { Table } from 'src/components/organisms'
import { useFunctions } from './hooks'
const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

export interface CompanyContactTableProps {
  companyId: string
}

export const CompanyContactTable = (props: CompanyContactTableProps) => {
  const { contactList, columns, numberOfRows, handleChangePage, handleChangePerPage, totalPages, page, per } =
    useFunctions(props)

  return (
    <DynamicTable
      columns={columns}
      rows={contactList}
      numberOfRows={numberOfRows}
      page={page}
      per={per}
      totalPages={totalPages}
      changePage={page => handleChangePage(page)}
      changePerPage={perPage => handleChangePerPage(perPage)}
    />
  )
}
