/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactSetting,
  ContactSettingFromJSON,
  ContactSettingFromJSONTyped,
  ContactSettingToJSON
} from './ContactSetting'

/**
 *
 * @export
 * @interface GetContactSettingsResponseData
 */
export interface GetContactSettingsResponseData {
  /**
   *
   * @type {Array<ContactSetting>}
   * @memberof GetContactSettingsResponseData
   */
  activeContactSettings?: Array<ContactSetting>
  /**
   *
   * @type {Array<ContactSetting>}
   * @memberof GetContactSettingsResponseData
   */
  inactiveContactSettings?: Array<ContactSetting>
}

export function GetContactSettingsResponseDataFromJSON(json: any): GetContactSettingsResponseData {
  return GetContactSettingsResponseDataFromJSONTyped(json, false)
}

export function GetContactSettingsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactSettingsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activeContactSettings: !exists(json, 'active_contact_settings')
      ? undefined
      : (json['active_contact_settings'] as Array<any>).map(ContactSettingFromJSON),
    inactiveContactSettings: !exists(json, 'inactive_contact_settings')
      ? undefined
      : (json['inactive_contact_settings'] as Array<any>).map(ContactSettingFromJSON)
  }
}

export function GetContactSettingsResponseDataToJSON(value?: GetContactSettingsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    active_contact_settings:
      value.activeContactSettings === undefined
        ? undefined
        : (value.activeContactSettings as Array<any>).map(ContactSettingToJSON),
    inactive_contact_settings:
      value.inactiveContactSettings === undefined
        ? undefined
        : (value.inactiveContactSettings as Array<any>).map(ContactSettingToJSON)
  }
}
