/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentPlanResponseData,
  GetOrganizationPaymentPlanResponseDataFromJSON,
  GetOrganizationPaymentPlanResponseDataFromJSONTyped,
  GetOrganizationPaymentPlanResponseDataToJSON
} from './GetOrganizationPaymentPlanResponseData'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanResponse
 */
export interface GetOrganizationPaymentPlanResponse {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentPlanResponse
   */
  status: number
  /**
   *
   * @type {GetOrganizationPaymentPlanResponseData}
   * @memberof GetOrganizationPaymentPlanResponse
   */
  data: GetOrganizationPaymentPlanResponseData
}

export function GetOrganizationPaymentPlanResponseFromJSON(json: any): GetOrganizationPaymentPlanResponse {
  return GetOrganizationPaymentPlanResponseFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetOrganizationPaymentPlanResponseDataFromJSON(json['data'])
  }
}

export function GetOrganizationPaymentPlanResponseToJSON(value?: GetOrganizationPaymentPlanResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationPaymentPlanResponseDataToJSON(value.data)
  }
}
