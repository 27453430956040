/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const HowDidYouKnowEnum = {
  Google: 'google',
  Sales: 'sales',
  OnlineAds: 'online_ads',
  Direct: 'direct',
  ServiceSite: 'service_site',
  WebinarOrExhibition: 'webinar_or_exhibition',
  SnsOrBlog: 'sns_or_blog',
  MediaArticles: 'media_articles',
  ReferralsOrRecommendations: 'referrals_or_recommendations',
  CampaignOrCoupon: 'campaign_or_coupon',
  ReviewSite: 'review_site',
  Others: 'others'
} as const
export type HowDidYouKnowEnum = typeof HowDidYouKnowEnum[keyof typeof HowDidYouKnowEnum]

export function HowDidYouKnowEnumFromJSON(json: any): HowDidYouKnowEnum {
  return HowDidYouKnowEnumFromJSONTyped(json, false)
}

export function HowDidYouKnowEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): HowDidYouKnowEnum {
  return json as HowDidYouKnowEnum
}

export function HowDidYouKnowEnumToJSON(value?: HowDidYouKnowEnum | null): any {
  return value as any
}
