import { Box } from '@material-ui/core'
import { ja } from 'date-fns/locale'
import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

export interface DatetimeCellProps {
  value: string
  dateFormat?: string
}

export const DatetimeCell = ({ value, dateFormat = 'yyyy/MM/dd (E)' }: DatetimeCellProps) => {
  const globalClasses = useGlobalStyles()
  const dateTime = useMemo(() => format(new Date(value), dateFormat, { locale: ja }), [value, dateFormat])
  return (
    <Box display="flex" alignItems="center" whiteSpace="pre-wrap" className={globalClasses.lineclamp1}>
      <Typography fontSize="s" lh="tight">
        {dateTime}
      </Typography>
    </Box>
  )
}
