import { Box } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { RestrictedBoard } from 'src/components/organisms'
import { PaymentBanner } from 'src/components/molecules'
import { LayoutPayment } from 'src/components/commons'
import { Typography } from 'src/components/atoms'
import { useGetFreePlan, useGetPlanLimit } from 'src/fixtures/modules/organizationPayment/hooks'

export const PagePriorConfirmFreePlan = () => {
  const router = useRouter()
  const { freePlan } = useGetFreePlan()
  const { needDowngrade } = useGetPlanLimit(freePlan?.id)

  const handleClickButton = useCallback(() => {
    if (!freePlan) return
    if (needDowngrade) {
      router.push('/setting/plans/[id]/purge', `/setting/plans/${freePlan.id}/purge`)
    } else if (freePlan) {
      router.push('/setting/plans/[id]', `/setting/plans/${freePlan.id}`)
    }
  }, [freePlan, needDowngrade, router])

  return (
    <LayoutPayment title="無料プランの利用お手続き" width="976px">
      <Box mb="16px" textAlign="center">
        <Typography fontSize="xl" fontWeight="bold" lineheight="1.5">
          無料プランご利用の前に、ご確認ください
        </Typography>
      </Box>
      <Box mb="16px" textAlign="center">
        <Typography fontSize="s" lineheight="1">
          無料プランでは、ご利用できる機能が限定されます
        </Typography>
      </Box>
      <Box mb="32px">
        <RestrictedBoard onClick={handleClickButton} />
      </Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: '591px',
          margin: '0 auto'
        }}
      >
        <PaymentBanner onClick={() => router.push('/setting/team/pricing')} />
      </Box>
    </LayoutPayment>
  )
}
