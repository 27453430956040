import { Box } from '@material-ui/core'
import { CompanySetting, CompanySettingField, ContactSetting, ContactSettingField } from '@noco/http-client/lib/noco'
import { useCallback, useEffect } from 'react'
import { Label, Tooltip, Typography } from 'src/components/atoms'
import { useGetKenall } from 'src/fixtures/utils/zipCode'
import { FieldInput, FieldInputProps } from './FieldInput'

export interface SettingInputRowProps {
  kind: 'contact' | 'company'
  setting: ContactSetting & CompanySetting
  name: string
  required?: boolean
  userId?: string
  isEuView?: boolean
  values: { [fieldId: string]: any }
  gap?: 'narrow' | 'normal'
  toolTipText?: string
  onChange: (data: { fieldId: string; value: any }) => void
  onBlur?: (e: any) => void
}

export const SettingInputRow = ({
  setting,
  kind,
  onChange,
  userId,
  values,
  required,
  gap = 'normal',
  isEuView = false,
  toolTipText = '',
  ...otherProps
}: SettingInputRowProps) => {
  const settingFieldName = kind === 'contact' ? 'contactSettingFields' : 'companySettingFields'
  const fields = setting?.[settingFieldName] as Array<ContactSettingField | CompanySettingField>
  const { handleGetRequestKenall, address1, address2, address3, prefecture, clear: clearKenall } = useGetKenall()

  const handleBlurInput: FieldInputProps['onBlur'] = useCallback(
    (e, { fieldValidate }) => {
      // MEMO: postal code で自動補完
      const keyId = fields[0].id!
      if (fieldValidate === 'post_code') {
        const postalCode = e.target.value.trim() as string
        onChange({ fieldId: keyId, value: postalCode })
        if (/[0-9]{7}/.test(postalCode)) {
          clearKenall()
          handleGetRequestKenall(postalCode)
        }
      }
      if (fieldValidate === 'email') {
        const emailValue = values[keyId]
        const trimmedEmail = emailValue?.trim()
        onChange({ fieldId: keyId, value: trimmedEmail })
      }
    },
    [clearKenall, handleGetRequestKenall, onChange, fields, values]
  )

  // ケンオールを使った住所の自動補完実装
  useEffect(() => {
    if (address1) {
      const address1Field = fields.find(item => item.name === '市区町村')
      if (address1Field) onChange({ fieldId: address1Field.id!, value: address1 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address1])
  useEffect(() => {
    if (address2) {
      const address2Field = fields.find(item => item.name === '番地')
      if (address2Field) onChange({ fieldId: address2Field.id!, value: address2 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address2])
  useEffect(() => {
    if (address3) {
      const address3Field = fields.find(item => item.name === 'ビル・マンション名')
      if (address3Field) onChange({ fieldId: address3Field.id!, value: address3 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address3])
  useEffect(() => {
    if (prefecture) {
      const prefsField = fields.find(item => item.name === '都道府県') as CompanySettingField
      const prefItem = prefsField.companySettingFieldItems?.find(item => item.name === prefecture)

      if (prefsField && prefItem?.id) onChange({ fieldId: prefsField.id!, value: prefItem?.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefecture])

  return (
    <Box display="flex" flexDirection={setting.settingType === 'contact_name' ? 'row' : 'column'}>
      {fields
        .filter(item => (isEuView ? !item.isHideOnEu : !item.isHideOnUser))
        .sort((a, b) => {
          if (a.sort !== undefined && b.sort === undefined) return -1
          if (a.sort === undefined && b.sort !== undefined) return 1
          if (a.sort && b.sort) return a.sort - b.sort
          return 0
        })
        .map((f, fieldIndex) => {
          const settingFieldCount = setting?.[settingFieldName]?.length || 0
          // MEMO: @snuffy 現状 contact name だけ 2column 構成なので暫定的に出し分けている
          const mr = setting.settingType === `${kind}_name` && fieldIndex !== settingFieldCount - 1 ? '8px' : '0px'
          const mb =
            setting.settingType === `${kind}_name` && fieldIndex !== settingFieldCount - 1
              ? '0px'
              : gap === 'narrow'
              ? '14px'
              : '24px'
          const fieldItems =
            (isCompanySettingField(f, kind) ? f.companySettingFieldItems : f.contactSettingFieldItems) || []

          return (
            <Box mr={mr} mb={mb} key={f.id} width={1}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {toolTipText ? (
                  <Tooltip content={toolTipText}>
                    <Typography fontSize="s" lineheight="14px" fontWeight="bold">
                      {f.label}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography fontSize="s" lineheight="14px" fontWeight="bold">
                    {f.label}
                  </Typography>
                )}
                <Box width="6px" />
                {required !== undefined && required && <Label type="negative" labelText="必須" size="small" />}
              </Box>
              <Box mt="6px" />
              <FieldInput
                {...otherProps}
                value={values?.[f.id!]}
                fieldItems={fieldItems}
                field={f}
                userId={userId}
                onChange={e => onChange({ fieldId: f.id!, value: e.target.value })}
                onBlur={handleBlurInput}
              />
            </Box>
          )
        })}
    </Box>
  )
}

const isCompanySettingField = (
  setting: ContactSettingField | CompanySettingField,
  type: 'contact' | 'company'
): setting is CompanySettingField => {
  return setting && type === 'company'
}
