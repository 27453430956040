import { Box, Typography } from '@material-ui/core'
import { EnumCtaPopupPosition } from '@noco/http-client/lib/noco/models/EnumCtaPopupPosition'
import { useCallback } from 'react'
import { Colors, OptionListType, SelectBox } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { PopupPosition } from '..'

export type PositionListItem = {
  value: PopupPosition
  label: string
  alignItems: string
  justifyContent: string
}
export interface SelectDiplayPopupProps {
  currentPage: string
  pageOptionList: OptionListType[]
  position: PopupPosition
  onChangePosition?: (value: PopupPosition) => void
  onChangePage?: (value: number) => void
}

const positionList: PositionListItem[] = [
  {
    value: EnumCtaPopupPosition.TopLeft,
    label: '左上',
    alignItems: 'start',
    justifyContent: 'start'
  },
  {
    value: EnumCtaPopupPosition.TopRight,
    label: '右上',
    alignItems: 'start',
    justifyContent: 'end'
  },
  {
    value: EnumCtaPopupPosition.BottomLeft,
    label: '左下',
    alignItems: 'end',
    justifyContent: 'start'
  },
  {
    value: EnumCtaPopupPosition.Center,
    label: '中央',
    alignItems: 'center',
    justifyContent: 'center'
  }
]

export const SelectDisplayPopup = ({
  currentPage,
  pageOptionList,
  position,
  onChangePage,
  onChangePosition
}: SelectDiplayPopupProps) => {
  const handleClick = useCallback(
    (item: PositionListItem) => {
      onChangePosition?.(item.value)
    },
    [onChangePosition]
  )

  const globalStyles = useGlobalStyles()

  return (
    <Box
      bgcolor={Colors.background.lightGray}
      border={`1px solid ${Colors.background.silver}`}
      borderRadius="4px"
      p="14px 12px 4px"
    >
      <Typography variant="h6">ポップアップを表示するページ数</Typography>
      <Box mt="6px" />
      <SelectBox
        name="currentPage"
        value={currentPage}
        listWidth="176px"
        placeholder="選択してください"
        optionList={pageOptionList}
        onChange={e => onChangePage?.(e.target.value as number)}
      />
      <Box border={`1px solid ${Colors.background.silver}`} mt="12px" mb="14px" />
      <Typography variant="h6">ポップアップ表示位置</Typography>
      <Box mt="14px" />
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {positionList.map((item, i) => (
          <Box
            key={i}
            width="144px"
            textAlign="center"
            color={Colors.base.middleGray}
            mb="8px"
            className={globalStyles.cursorPointer}
            onClick={() => handleClick(item)}
          >
            <Typography variant="h6">{item.label}</Typography>
            <Box
              display="flex"
              justifyContent={item.justifyContent}
              alignItems={item.alignItems}
              border={`1px solid ${position === item.value ? Colors.primary.default : Colors.background.silver}`}
              borderRadius="4px"
              height="76px"
              bgcolor={Colors.functional.background.default}
              p="4px"
            >
              <Box width="60px" height="24px" borderRadius="2px" bgcolor={Colors.background.gray} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
