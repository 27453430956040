/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInner,
  UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInnerFromJSON,
  UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInnerFromJSONTyped,
  UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInnerToJSON
} from './UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInner'

/**
 *
 * @export
 * @interface UpdateDocumentShareByLinkRequestDocumentShare
 */
export interface UpdateDocumentShareByLinkRequestDocumentShare {
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentShareByLinkRequestDocumentShare
   */
  expiredOn?: string | null
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentShareByLinkRequestDocumentShare
   */
  userId?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentShareByLinkRequestDocumentShare
   */
  isActive?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentShareByLinkRequestDocumentShare
   */
  isFormActive?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentShareByLinkRequestDocumentShare
   */
  isAuthCodeActive?: boolean
  /**
   *
   * @type {Array<UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInner>}
   * @memberof UpdateDocumentShareByLinkRequestDocumentShare
   */
  documentShareForms?: Array<UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInner>
}

export function UpdateDocumentShareByLinkRequestDocumentShareFromJSON(
  json: any
): UpdateDocumentShareByLinkRequestDocumentShare {
  return UpdateDocumentShareByLinkRequestDocumentShareFromJSONTyped(json, false)
}

export function UpdateDocumentShareByLinkRequestDocumentShareFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentShareByLinkRequestDocumentShare {
  if (json === undefined || json === null) {
    return json
  }
  return {
    expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
    isFormActive: !exists(json, 'is_form_active') ? undefined : json['is_form_active'],
    isAuthCodeActive: !exists(json, 'is_auth_code_active') ? undefined : json['is_auth_code_active'],
    documentShareForms: !exists(json, 'document_share_forms')
      ? undefined
      : (json['document_share_forms'] as Array<any>).map(
          UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInnerFromJSON
        )
  }
}

export function UpdateDocumentShareByLinkRequestDocumentShareToJSON(
  value?: UpdateDocumentShareByLinkRequestDocumentShare | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    expired_on: value.expiredOn,
    user_id: value.userId,
    is_active: value.isActive,
    is_form_active: value.isFormActive,
    is_auth_code_active: value.isAuthCodeActive,
    document_share_forms:
      value.documentShareForms === undefined
        ? undefined
        : (value.documentShareForms as Array<any>).map(
            UpdateDocumentShareByLinkRequestDocumentShareDocumentShareFormsInnerToJSON
          )
  }
}
