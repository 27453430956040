import { Box } from '@material-ui/core'
import React from 'react'
import { Colors, Tooltip } from 'src/components/atoms'
import { FolderLink } from './FolderLink'

export interface SubSideFolderListProps {
  folderList: {
    normal: FolderData
    archive: FolderData
  }
  currentPage: string
}

export interface FolderData {
  path: string
  count: number
}

export const SubSideFolderList = ({ folderList, currentPage }: SubSideFolderListProps) => {
  const { normal, archive } = folderList
  return (
    <Box width={1} py="16px">
      <Box pb="12px" borderBottom={`1px solid ${Colors.background.silver}`}>
        <FolderLink path={normal.path} label="すべて" count={normal.count} isActive={currentPage === normal.path} />
      </Box>
      <Box pt="12px">
        <Tooltip
          content={
            '利用が少ない資料サイトを一時保管している場所です。アーカイブにある資料サイトは、非公開の状態になります。また、アーカイブの状態から元に戻すことができます。'
          }
        >
          <Box width={1}>
            <FolderLink
              path={archive.path}
              icon="archive"
              label="アーカイブ"
              count={archive.count}
              isActive={currentPage === archive.path}
            />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}
