/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * current_version_proccesing: 現在バージョンのスライドを処理中
 * current_version_completed: 現在バージョンのスライドが正常終了
 * current_version_failed: 現在バージョンのスライドが異常終了
 * next_version_proccesing: 次期バージョンのスライドを処理中
 * next_version_failed: 次期バージョンのスライドが異常終了
 * @export
 */
export const EnumDocumentSlideProcessingStatus = {
    CurrentVersionProccesing: 'current_version_proccesing',
    CurrentVersionCompleted: 'current_version_completed',
    CurrentVersionFailed: 'current_version_failed',
    NextVersionProccesing: 'next_version_proccesing',
    NextVersionFailed: 'next_version_failed'
};
export function EnumDocumentSlideProcessingStatusFromJSON(json) {
    return EnumDocumentSlideProcessingStatusFromJSONTyped(json, false);
}
export function EnumDocumentSlideProcessingStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumDocumentSlideProcessingStatusToJSON(value) {
    return value;
}
