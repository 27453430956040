/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumRecordPorterHistoryProgressStatus = {
    Progress: 'progress',
    Complete: 'complete',
    Error: 'error'
};
export function EnumRecordPorterHistoryProgressStatusFromJSON(json) {
    return EnumRecordPorterHistoryProgressStatusFromJSONTyped(json, false);
}
export function EnumRecordPorterHistoryProgressStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumRecordPorterHistoryProgressStatusToJSON(value) {
    return value;
}
