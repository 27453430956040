/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OptionUserPlan
 */
export interface OptionUserPlan {
  /**
   *
   * @type {string}
   * @memberof OptionUserPlan
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof OptionUserPlan
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof OptionUserPlan
   */
  unitAmount: number
  /**
   *
   * @type {string}
   * @memberof OptionUserPlan
   */
  stripePriceId: string
}

export function OptionUserPlanFromJSON(json: any): OptionUserPlan {
  return OptionUserPlanFromJSONTyped(json, false)
}

export function OptionUserPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionUserPlan {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    unitAmount: json['unit_amount'],
    stripePriceId: json['stripe_price_id']
  }
}

export function OptionUserPlanToJSON(value?: OptionUserPlan | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    unit_amount: value.unitAmount,
    stripe_price_id: value.stripePriceId
  }
}
