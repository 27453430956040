import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Colors } from 'src/components/atoms'
import { AttachmentFile, DeleteCardProps } from '../type'
import { Card } from '../Card'
import { FileCardContent } from './FileCardContent'
import { downloadFileFromURL } from 'src/fixtures/utils/file'

export type FileType = 'file'

export interface FileCardProps {
  attachmentFile: AttachmentFile
  deleteProps: DeleteCardProps
}

const useStyles = makeStyles(() =>
  createStyles({
    linkBox: {
      display: 'block',
      padding: '8px',
      textDecoration: 'none',
      color: Colors.base.black,
      cursor: 'pointer'
    }
  })
)

export const FileCard = ({ attachmentFile, deleteProps }: FileCardProps) => {
  const classes = useStyles()

  return (
    <Card deleteProps={deleteProps}>
      {deleteProps.isDeletable ? (
        <Box p="8px">
          <FileCardContent attachmentFile={attachmentFile} isEditing />
        </Box>
      ) : (
        <Box
          className={classes.linkBox}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            downloadFileFromURL(attachmentFile.url!, attachmentFile.title)
          }}
        >
          <FileCardContent attachmentFile={attachmentFile} />
        </Box>
      )}
    </Card>
  )
}
