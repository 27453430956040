import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var SendErrorApi = /** @class */ (function () {
    function SendErrorApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    SendErrorApi.initialize = function (basePath, fetchApi) {
        if (!SendErrorApi.instance) {
            SendErrorApi.instance = new SendErrorApi(basePath, fetchApi);
        }
        return SendErrorApi.instance;
    };
    SendErrorApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1InboxApi(new Configuration(config));
    };
    return SendErrorApi;
}());
export { SendErrorApi };
