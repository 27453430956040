import { Box } from '@material-ui/core'
import React from 'react'
import { DocumentSetting } from 'src/components/organisms/DocumentSetting'

interface Props {
  documentId?: string
  onClose?: () => void
}

export const SettingContainer: React.VFC<Props> = ({ documentId, onClose }) => {
  return (
    <Box width="100%" px="10px" py="18px">
      <DocumentSetting documentId={documentId} onClose={() => onClose?.()} />
    </Box>
  )
}
