import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { SiteSection, SortSiteSectionRequestSiteSectionsInner } from '@noco/http-client/lib/noco'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, DynamicMuiIcon, Colors } from 'src/components/atoms'
import {
  useCreateSiteSection,
  useUpdateSiteSectionDocumentSort,
  useUpdateSiteSectionSort
} from 'src/fixtures/modules/site/hooks'
import { PageSiteMode } from '../SiteDetail'
import { DocumentItem } from './components/DocumentItem'
import { DocumentSection } from './components/DocumentSection'

const useStyles = makeStyles(() =>
  createStyles({
    buttonBase: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default,
        borderRadius: '6px',
        cursor: 'pointer'
      }
    }
  })
)

export interface DocumentSectionListProps {
  siteId: string
  items: SiteSection[]
  isSP?: boolean
  mode: PageSiteMode
}

export const DocumentSectionList = ({ siteId, items, isSP, mode }: DocumentSectionListProps) => {
  const classes = useStyles()
  const [activeId, setActiveId] = useState<string>('')
  const [activeContainerId, setActiveContainerId] = useState<string>('')
  const { handleUpdateSiteSectionDocumentSort } = useUpdateSiteSectionDocumentSort(siteId)
  const { handleUpdateSiteSectionSort } = useUpdateSiteSectionSort(siteId)
  const { handleCreateSiteSection } = useCreateSiteSection(siteId)
  const selectedDocumentIds = useMemo(() => {
    return items.flatMap(item => item.siteSectionDocuments).map(document => document?.document?.id!)
  }, [items])

  const handleAddSection = useCallback(() => {
    handleCreateSiteSection({ siteSection: { name: '', sort: items.length + 2 } })
  }, [handleCreateSiteSection, items])

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      setActiveId('')
      setActiveContainerId('')
      const activeContainerId = active.data?.current?.sortable.containerId

      if (!over) return

      // MEMO: Drop 先がネストしてる場合、 Drag してるものと Drop 先が一致していないことががあるので先に判定をしておく
      const overType = over.data?.current?.type
      const activeType = active.data?.current?.type

      if (overType !== activeType) return

      const current = over.data?.current
      if (!current) return

      // NOTE: セクションの入れ替えの場合
      if (current.type === 'container') {
        const oldIndex = items.findIndex(item => item.id === active.id)
        const newIndex = items.findIndex(item => item.id === over.id)
        const _items = arrayMove(items, oldIndex, newIndex)
        const siteSections: SortSiteSectionRequestSiteSectionsInner[] = _items.map((item, i) => {
          return {
            id: item.id,
            sort: i + 1
          }
        })
        handleUpdateSiteSectionSort({ siteSections })
      }

      // NOTE: ドキュメントの入れ替えの場合
      else if (active.id !== over.id) {
        const _items = [...items]
        const currentIndex = items.findIndex(item => item.id === activeContainerId)
        const currentList = _items[currentIndex].siteSectionDocuments
        if (currentList && currentIndex !== -1) {
          const oldIndex = currentList.findIndex(item => item.id === active.id)
          const newIndex = currentList.findIndex(item => item.id === over.id)
          const newList = arrayMove(currentList, oldIndex, newIndex)
          const siteSectionDocuments: SortSiteSectionRequestSiteSectionsInner[] = newList.map((item, i) => {
            return {
              id: item.id,
              sort: i + 1
            }
          })
          _items && handleUpdateSiteSectionDocumentSort({ siteSectionDocuments })
        }
      }
    },
    [handleUpdateSiteSectionDocumentSort, handleUpdateSiteSectionSort, items]
  )

  const handleDragStart = useCallback((event: DragStartEvent) => {
    if (event.active.data?.current?.type === 'container') {
      setActiveContainerId(event.active.id as string)
    } else {
      setActiveId(event.active.id as string)
    }
  }, [])

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { delay: 200, tolerance: 20 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 200, tolerance: 20 } })
  )

  const activeItem = useMemo(() => {
    const _items = items.flatMap(list => list.siteSectionDocuments)
    return _items.find(item => item?.id === activeId)
  }, [activeId, items])

  const activeContainer = useMemo(() => {
    return items.find(item => item.id === activeContainerId)
  }, [activeContainerId, items])

  return (
    <Box width={1}>
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <SortableContext items={items.map(item => item.id!)}>
          {items.map(item => (
            <Box key={item.id} mb="36px">
              <DocumentSection
                siteId={siteId}
                section={item}
                isSP={isSP}
                selectedDocumentIds={selectedDocumentIds}
                mode={mode}
              />
            </Box>
          ))}
        </SortableContext>
        {mode === 'setting' && (
          <Button
            title="セクションを追加"
            startIcon={<DynamicMuiIcon variant="add" />}
            variant="outlined"
            width="100%"
            size="large"
            onClick={handleAddSection}
            className={classes.buttonBase}
          />
        )}
        <DragOverlay>
          {activeItem && <DocumentItem siteId={siteId} document={activeItem} mode={mode} />}
          {activeContainer && <DocumentSection siteId={siteId} section={activeContainer} mode={mode} />}
        </DragOverlay>
      </DndContext>
    </Box>
  )
}
