// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole } from '@sentry/integrations'

// MEMO: sentry dsn は秘匿情報ではない
const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  'https://f7758887404a43d0871b698989c94bbc@o1183491.ingest.sentry.io/6355465'

const hasSentryAuthToken = !!process.env.SENTRY_AUTH_TOKEN

if (hasSentryAuthToken) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.APP_ENV || 'development',
    integrations: [
      new CaptureConsole({
        levels: ['error']
      })
    ],
    ignoreErrors: ['gtag/js'],
    // error sample rate
    sampleRate: 1,
    tracesSampleRate: 0.001
  })
} else {
  console.warn('sentry の初期化に失敗しました。SENTRY_SECURITY_TOKEN_CLIENT がありません')
}
