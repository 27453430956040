import { Box, Slide } from '@material-ui/core'
import { Site } from '@noco/http-client/lib/noco'
import { useState } from 'react'
import { SiteInformation } from 'src/components/organisms'

export interface InformationContainerProps {
  onClose?: () => void
  site: Site
}

export const InformationContainer = ({ onClose, site }: InformationContainerProps) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  return (
    <Slide direction="left" in={open}>
      <Box width="100%" height="100%" bgcolor="white" px="10px" py="18px">
        <SiteInformation site={site} onClose={handleClose} />
      </Box>
    </Slide>
  )
}
