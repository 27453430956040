import React from 'react'
import { Box } from '@material-ui/core'
import { LabelFormRow } from 'src/components/molecules'
import { Colors, Input, Typography } from 'src/components/atoms'
import { useFormik } from 'formik'
import { useStyles } from './styles'
import { useUpdateMe } from 'src/fixtures/modules/me/hooks'
import { UserNameProps } from './type'

export const UserName = ({ firstName, lastName }: UserNameProps) => {
  const classes = useStyles()
  const { handleUpdateMe } = useUpdateMe()
  const { values, getFieldProps, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: { firstName, lastName },
    onSubmit: values => {
      handleUpdateMe({ user: { firstName: values.firstName, lastName: values.lastName } })
    }
  })

  return (
    <Box borderBottom={`1px solid ${Colors.background.silver}`} py="16px">
      <LabelFormRow
        label="ユーザー名"
        onSave={() => handleSubmit()}
        onCancel={() => {
          setFieldValue('firstName', firstName)
          setFieldValue('lastName', lastName)
        }}
        readElement={
          <>
            <Box display="flex" alignItems="center" mb="4px">
              <Typography fontSize="s" lh="tight">
                {values.lastName}
              </Typography>
              &nbsp;
              <Typography fontSize="s" lh="tight">
                {values.firstName}
              </Typography>
            </Box>
            <Typography variant="subtitle2" className={classes.textColor}>
              顧客と社内に公開される情報です
            </Typography>
          </>
        }
        editElement={
          <Box display="flex" alignItems="center" pr="113px">
            <Box display="flex" flexDirection="column" flex={1}>
              <Box mb="4px">
                <Typography fontSize="s" fontWeight="bold" lh="tight">
                  姓
                </Typography>
              </Box>
              <Input {...getFieldProps('lastName')} />
            </Box>
            <Box width="12px" />
            <Box display="flex" flexDirection="column" flex={1}>
              <Box mb="4px">
                <Typography fontSize="s" fontWeight="bold" lh="tight">
                  名
                </Typography>
              </Box>
              <Input {...getFieldProps('firstName')} />
            </Box>
          </Box>
        }
      />
    </Box>
  )
}
