import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Close = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3501 0.661633C14.8951 0.206633 14.1601 0.206633 13.7051 0.661633L8.00009 6.35497L2.29509 0.649966C1.84009 0.194966 1.10509 0.194966 0.650088 0.649966C0.195088 1.10497 0.195088 1.83997 0.650088 2.29497L6.35509 7.99997L0.650088 13.705C0.195088 14.16 0.195088 14.895 0.650088 15.35C1.10509 15.805 1.84009 15.805 2.29509 15.35L8.00009 9.64497L13.7051 15.35C14.1601 15.805 14.8951 15.805 15.3501 15.35C15.8051 14.895 15.8051 14.16 15.3501 13.705L9.64509 7.99997L15.3501 2.29497C15.7934 1.85163 15.7934 1.10497 15.3501 0.661633Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default Close
