import { Box } from '@material-ui/core'
import { Button, Card, Typography } from 'src/components/atoms'
import Image from 'next/image'
import { center } from '../styles'
import { useRouter } from 'next/router'
import { useGetPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { useMemo } from 'react'

export const StepComplete = () => {
  const router = useRouter()
  const planId = router.query.id as string
  const { plan: nextPlan } = useGetPlan(planId)
  const nextPlanNameText = useMemo(() => nextPlan?.name, [nextPlan])

  return (
    <Box>
      <Card sx={{ pb: '36.5px' }}>
        <Box sx={{ textAlign: 'center', mb: '27px' }}>
          <Typography fontSize="s" lineheight="23.8px" style={{ whiteSpace: 'pre-wrap' }}>
            {`データの選択が完了しました！`}
          </Typography>
          <Box height="12px" />
          <Typography fontSize="s" lineheight="23.8px" style={{ whiteSpace: 'pre-wrap' }}>
            {`${nextPlanNameText}ご利用手続きにお進みください`}
          </Typography>
        </Box>
        <Box sx={{ ...center }}>
          <Image src="/images/leave/complete.svg" width={315} height={218} objectFit={'contain'} />
        </Box>
      </Card>
      <Box sx={{ ...center, mt: '24px' }}>
        <Button
          size="large"
          onClick={() => router.push('/setting/plans/[id]', `/setting/plans/${planId}`)}
          title={`${nextPlanNameText}の利用を開始する`}
        />
      </Box>
    </Box>
  )
}
