/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateDocumentChatSettingRequestDocumentChatSetting
 */
export interface UpdateDocumentChatSettingRequestDocumentChatSetting {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentChatSettingRequestDocumentChatSetting
   */
  canChat?: boolean
}

export function UpdateDocumentChatSettingRequestDocumentChatSettingFromJSON(
  json: any
): UpdateDocumentChatSettingRequestDocumentChatSetting {
  return UpdateDocumentChatSettingRequestDocumentChatSettingFromJSONTyped(json, false)
}

export function UpdateDocumentChatSettingRequestDocumentChatSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentChatSettingRequestDocumentChatSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    canChat: !exists(json, 'can_chat') ? undefined : json['can_chat']
  }
}

export function UpdateDocumentChatSettingRequestDocumentChatSettingToJSON(
  value?: UpdateDocumentChatSettingRequestDocumentChatSetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    can_chat: value.canChat
  }
}
