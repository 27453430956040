import { Box, Typography, createStyles, makeStyles } from '@material-ui/core'
import { useRouter } from 'next/router'
import { Button, ButtonIcon, Colors, DynamicMuiIcon, SvgIcon } from 'src/components/atoms'
import { DocumentSlideConvertStatusText } from 'src/components/molecules'
import { useGetDocument } from 'src/fixtures/modules/documents/hooks'

export interface DocumentSettingHeader {
  iconText: string
  headerTitleText?: string
  onClickBack?: () => void
  siteId?: string
  documentId?: string
  type?: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    iconText: {
      color: Colors.base.middleGray
    },
    button: {
      '& .MuiButton-startIcon': {
        marginRight: '6px',
        marginLeft: '0'
      }
    }
  })
})

export const DocumentSettingHeader = ({
  iconText,
  headerTitleText,
  onClickBack,
  siteId,
  documentId,
  type
}: DocumentSettingHeader) => {
  const classes = useStyles()
  const router = useRouter()
  const { data: document } = useGetDocument(documentId)

  return (
    <Box height="60px" display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" justifyContent="flex-start" pl="6px">
        <ButtonIcon
          iconBefore={<SvgIcon variant="leftArrow" size="14px" color={Colors.base.middleGray} />}
          onClick={() => (onClickBack ? onClickBack?.() : router.back())}
        >
          <Typography variant="h5" className={classes.iconText}>
            {iconText}
          </Typography>
        </ButtonIcon>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h3">{headerTitleText}</Typography>
        {document && (
          <>
            <Box width="8px" />
            <DocumentSlideConvertStatusText document={document} />
          </>
        )}
      </Box>
      <Box justifyContent="space-between" display="flex" alignItems="center" pr="16px">
        <Button
          classes={{ root: classes.button }}
          startIcon={<DynamicMuiIcon variant="playArrow" size="16px" />}
          size="medium"
          kind="neutral"
          variant="outlined"
          title="プレビュー"
          onClick={() =>
            window.open(
              `${type === `site` ? `/multi-documents` : `/documents`}/${siteId || documentId}/preview`,
              undefined,
              '_blank'
            )
          }
        />
        {/* <DynamicMuiIcon variant="lock" color="action" size="16px" />
        <DynamicMuiIcon variant="helpOutline" color="action" size="16px" /> */}
      </Box>
    </Box>
  )
}
