/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * wait,proccesing は処理中。 completed は正常終了。 failed は異常終了。
 * @export
 */
export const EnumSlideProcessingStatus = {
    Wait: 'wait',
    Proccesing: 'proccesing',
    Completed: 'completed',
    Failed: 'failed'
};
export function EnumSlideProcessingStatusFromJSON(json) {
    return EnumSlideProcessingStatusFromJSONTyped(json, false);
}
export function EnumSlideProcessingStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumSlideProcessingStatusToJSON(value) {
    return value;
}
