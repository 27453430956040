/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetEuOrganizationChatSettingResponseFromJSON, GetEuOrganizationDisplaySettingResponseFromJSON, GetEuOrganizationPolicySettingsResponseFromJSON } from '../models';
/**
 *
 */
export class ApiContactV1OrganizationApi extends runtime.BaseAPI {
    /**
     * チームのチャット表示設定の情報を取得する
     * show
     */
    async contactV1OrganizationChatSettingGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/organization/chat_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetEuOrganizationChatSettingResponseFromJSON(jsonValue));
    }
    /**
     * チームのチャット表示設定の情報を取得する
     * show
     */
    async contactV1OrganizationChatSettingGet(initOverrides) {
        const response = await this.contactV1OrganizationChatSettingGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * 表示設定の取得
     * index
     */
    async contactV1OrganizationDisplaySettingGetRaw(requestParameters, initOverrides) {
        if (requestParameters.organizationSubdomain === null || requestParameters.organizationSubdomain === undefined) {
            throw new runtime.RequiredError('organizationSubdomain', 'Required parameter requestParameters.organizationSubdomain was null or undefined when calling contactV1OrganizationDisplaySettingGet.');
        }
        const queryParameters = {};
        if (requestParameters.organizationSubdomain !== undefined) {
            queryParameters['organization_subdomain'] = requestParameters.organizationSubdomain;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/organization/display_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetEuOrganizationDisplaySettingResponseFromJSON(jsonValue));
    }
    /**
     * 表示設定の取得
     * index
     */
    async contactV1OrganizationDisplaySettingGet(requestParameters, initOverrides) {
        const response = await this.contactV1OrganizationDisplaySettingGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ポリシーの取得
     * index
     */
    async contactV1OrganizationPolicySettingsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.organizationSubdomain === null || requestParameters.organizationSubdomain === undefined) {
            throw new runtime.RequiredError('organizationSubdomain', 'Required parameter requestParameters.organizationSubdomain was null or undefined when calling contactV1OrganizationPolicySettingsGet.');
        }
        const queryParameters = {};
        if (requestParameters.organizationSubdomain !== undefined) {
            queryParameters['organization_subdomain'] = requestParameters.organizationSubdomain;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/organization/policy_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetEuOrganizationPolicySettingsResponseFromJSON(jsonValue));
    }
    /**
     * ポリシーの取得
     * index
     */
    async contactV1OrganizationPolicySettingsGet(requestParameters, initOverrides) {
        const response = await this.contactV1OrganizationPolicySettingsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
