/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SortSiteSectionRequestSiteSectionsInner
 */
export interface SortSiteSectionRequestSiteSectionsInner {
  /**
   *
   * @type {string}
   * @memberof SortSiteSectionRequestSiteSectionsInner
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof SortSiteSectionRequestSiteSectionsInner
   */
  sort?: number
}

export function SortSiteSectionRequestSiteSectionsInnerFromJSON(json: any): SortSiteSectionRequestSiteSectionsInner {
  return SortSiteSectionRequestSiteSectionsInnerFromJSONTyped(json, false)
}

export function SortSiteSectionRequestSiteSectionsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SortSiteSectionRequestSiteSectionsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    sort: !exists(json, 'sort') ? undefined : json['sort']
  }
}

export function SortSiteSectionRequestSiteSectionsInnerToJSON(
  value?: SortSiteSectionRequestSiteSectionsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    sort: value.sort
  }
}
