import { Box, useMediaQuery } from '@material-ui/core'
import React, { ReactNode, VFC } from 'react'
import { Typography } from 'src/components/atoms'
import theme from 'src/styles/theme'
import { isMobile } from 'react-device-detect'

export interface VisitorAuthModalBaseProps {
  title?: string
  children: ReactNode
}
export const VisitorAuthModalBase: VFC<VisitorAuthModalBaseProps> = ({ title, children }) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  const isSpViewHorizontal = isMobile && isDownSm
  return (
    <Box
      sx={{
        border: '1px solid #E9ECF1',
        boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
        borderRadius: '10px',
        minWidth: isDownSm ? '100%' : '500px',
        maxWidth: isSpViewHorizontal ? '500px' : isDownSm ? '100%' : '500px',
        maxHeight: '80vh',
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          p: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: '1px solid #DADCE0'
        }}
      >
        <Typography variant="h3">{title}</Typography>
      </Box>

      <Box sx={{ px: '24px', pt: '28px', pb: '32px', height: '100%', overflow: 'auto' }}>{children}</Box>
    </Box>
  )
}
