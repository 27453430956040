/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactSettingFieldItem,
  ContactSettingFieldItemFromJSON,
  ContactSettingFieldItemFromJSONTyped,
  ContactSettingFieldItemToJSON
} from './ContactSettingFieldItem'

/**
 *
 * @export
 * @interface ContactSettingFieldValue
 */
export interface ContactSettingFieldValue {
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldValue
   */
  id?: string
  /**
   *
   * @type {ContactSettingFieldItem}
   * @memberof ContactSettingFieldValue
   */
  contactSettingFieldItem?: ContactSettingFieldItem
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldValue
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldValue
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldValue
   */
  updatedAt?: string
}

export function ContactSettingFieldValueFromJSON(json: any): ContactSettingFieldValue {
  return ContactSettingFieldValueFromJSONTyped(json, false)
}

export function ContactSettingFieldValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ContactSettingFieldValue {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    contactSettingFieldItem: !exists(json, 'contact_setting_field_item')
      ? undefined
      : ContactSettingFieldItemFromJSON(json['contact_setting_field_item']),
    value: !exists(json, 'value') ? undefined : json['value'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function ContactSettingFieldValueToJSON(value?: ContactSettingFieldValue | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    contact_setting_field_item: ContactSettingFieldItemToJSON(value.contactSettingFieldItem),
    value: value.value,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
