/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationSelectTriggerResentTimingsInner
 */
export interface OrganizationSelectTriggerResentTimingsInner {
  /**
   *
   * @type {string}
   * @memberof OrganizationSelectTriggerResentTimingsInner
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSelectTriggerResentTimingsInner
   */
  displayName?: string
}

export function OrganizationSelectTriggerResentTimingsInnerFromJSON(
  json: any
): OrganizationSelectTriggerResentTimingsInner {
  return OrganizationSelectTriggerResentTimingsInnerFromJSONTyped(json, false)
}

export function OrganizationSelectTriggerResentTimingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationSelectTriggerResentTimingsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    key: !exists(json, 'key') ? undefined : json['key'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name']
  }
}

export function OrganizationSelectTriggerResentTimingsInnerToJSON(
  value?: OrganizationSelectTriggerResentTimingsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    key: value.key,
    display_name: value.displayName
  }
}
