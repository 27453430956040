/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateMaterialableTrackRequestToJSON, CreateMaterialableTrackResponseFromJSON, PingMaterialableTrackResponseFromJSON } from '../models';
/**
 *
 */
export class ApiContactV1MaterialableTrackApi extends runtime.BaseAPI {
    /**
     * MaterialableTrack の閲覧時間更新
     * update
     */
    async contactV1MaterialableTracksMaterialableTrackIdPingPutRaw(requestParameters, initOverrides) {
        if (requestParameters.materialableTrackId === null || requestParameters.materialableTrackId === undefined) {
            throw new runtime.RequiredError('materialableTrackId', 'Required parameter requestParameters.materialableTrackId was null or undefined when calling contactV1MaterialableTracksMaterialableTrackIdPingPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/contact/v1/materialable_tracks/{materialable_track_id}/ping`.replace(`{${'materialable_track_id'}}`, encodeURIComponent(String(requestParameters.materialableTrackId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => PingMaterialableTrackResponseFromJSON(jsonValue));
    }
    /**
     * MaterialableTrack の閲覧時間更新
     * update
     */
    async contactV1MaterialableTracksMaterialableTrackIdPingPut(requestParameters, initOverrides) {
        const response = await this.contactV1MaterialableTracksMaterialableTrackIdPingPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * MaterialableTrack の作成または更新
     * create
     */
    async contactV1MaterialableTracksPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/contact/v1/materialable_tracks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateMaterialableTrackRequestToJSON(requestParameters.createMaterialableTrackRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateMaterialableTrackResponseFromJSON(jsonValue));
    }
    /**
     * MaterialableTrack の作成または更新
     * create
     */
    async contactV1MaterialableTracksPost(requestParameters = {}, initOverrides) {
        const response = await this.contactV1MaterialableTracksPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
