/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetContactMaterialsResponseData,
  GetContactMaterialsResponseDataFromJSON,
  GetContactMaterialsResponseDataFromJSONTyped,
  GetContactMaterialsResponseDataToJSON
} from './GetContactMaterialsResponseData'

/**
 *
 * @export
 * @interface GetContactMaterialsResponse
 */
export interface GetContactMaterialsResponse {
  /**
   *
   * @type {number}
   * @memberof GetContactMaterialsResponse
   */
  status?: number
  /**
   *
   * @type {GetContactMaterialsResponseData}
   * @memberof GetContactMaterialsResponse
   */
  data?: GetContactMaterialsResponseData
}

export function GetContactMaterialsResponseFromJSON(json: any): GetContactMaterialsResponse {
  return GetContactMaterialsResponseFromJSONTyped(json, false)
}

export function GetContactMaterialsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactMaterialsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetContactMaterialsResponseDataFromJSON(json['data'])
  }
}

export function GetContactMaterialsResponseToJSON(value?: GetContactMaterialsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetContactMaterialsResponseDataToJSON(value.data)
  }
}
