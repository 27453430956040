/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateMeRequestUser,
  UpdateMeRequestUserFromJSON,
  UpdateMeRequestUserFromJSONTyped,
  UpdateMeRequestUserToJSON
} from './UpdateMeRequestUser'

/**
 *
 * @export
 * @interface UpdateMeRequest
 */
export interface UpdateMeRequest {
  /**
   *
   * @type {UpdateMeRequestUser}
   * @memberof UpdateMeRequest
   */
  user?: UpdateMeRequestUser
}

export function UpdateMeRequestFromJSON(json: any): UpdateMeRequest {
  return UpdateMeRequestFromJSONTyped(json, false)
}

export function UpdateMeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    user: !exists(json, 'user') ? undefined : UpdateMeRequestUserFromJSON(json['user'])
  }
}

export function UpdateMeRequestToJSON(value?: UpdateMeRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user: UpdateMeRequestUserToJSON(value.user)
  }
}
