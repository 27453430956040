import { Box } from '@material-ui/core'
import { Typography, Colors } from 'src/components/atoms'
import { ja } from 'date-fns/locale'
import { format, isValid } from 'date-fns'
import React, { useMemo } from 'react'

export interface ReceivedDatetimeCellProps {
  value: string
}

export const ReceivedDatetimeCell = ({ value }: ReceivedDatetimeCellProps) => {
  const dateText = useMemo(() => {
    const originalDate = new Date(value)
    const date = removeTime(originalDate)
    const today = removeTime(new Date())
    const dateOneYearAgo = removeTime(new Date())
    dateOneYearAgo.setFullYear(dateOneYearAgo.getFullYear() - 1)

    let dateAndFormat: [Date, string]

    if (!isValid(originalDate)) return '表示なし'
    if (date.valueOf() === today.valueOf()) {
      dateAndFormat = [originalDate, 'HH:mm']
    } else if (dateOneYearAgo.valueOf() > today.valueOf()) {
      dateAndFormat = [originalDate, 'yyyy/MM/dd (E)']
    } else {
      dateAndFormat = [originalDate, 'MM/dd (E)']
    }
    return formatDate(dateAndFormat)
  }, [value])

  return (
    <Box display="flex" alignItems="center" overflow="hidden" color={Colors.base.middleGray}>
      <Typography fontSize="xs" noWrap>
        {dateText}
      </Typography>
    </Box>
  )
}

const formatDate = (dateAndFormat: [Date, string]) => format(dateAndFormat[0], dateAndFormat[1], { locale: ja })
const removeTime = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate())
