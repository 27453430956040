/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface ContactMemo
 */
export interface ContactMemo {
  /**
   *
   * @type {string}
   * @memberof ContactMemo
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactMemo
   */
  contactId?: string
  /**
   *
   * @type {string}
   * @memberof ContactMemo
   */
  text?: string | null
  /**
   *
   * @type {string}
   * @memberof ContactMemo
   */
  discardedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof ContactMemo
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactMemo
   */
  updatedAt?: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof ContactMemo
   */
  createdBy?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof ContactMemo
   */
  updatedBy?: UserForDisplay
}

export function ContactMemoFromJSON(json: any): ContactMemo {
  return ContactMemoFromJSONTyped(json, false)
}

export function ContactMemoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactMemo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
    text: !exists(json, 'text') ? undefined : json['text'],
    discardedAt: !exists(json, 'discarded_at') ? undefined : json['discarded_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
    updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by'])
  }
}

export function ContactMemoToJSON(value?: ContactMemo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    contact_id: value.contactId,
    text: value.text,
    discarded_at: value.discardedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    created_by: UserForDisplayToJSON(value.createdBy),
    updated_by: UserForDisplayToJSON(value.updatedBy)
  }
}
