/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UserOperationDisplayObjectItem,
  UserOperationDisplayObjectItemFromJSON,
  UserOperationDisplayObjectItemFromJSONTyped,
  UserOperationDisplayObjectItemToJSON
} from './UserOperationDisplayObjectItem'

/**
 *
 * @export
 * @interface UserOperationDisplayObject
 */
export interface UserOperationDisplayObject {
  /**
   *
   * @type {UserOperationDisplayObjectItem}
   * @memberof UserOperationDisplayObject
   */
  target?: UserOperationDisplayObjectItem
  /**
   *
   * @type {UserOperationDisplayObjectItem}
   * @memberof UserOperationDisplayObject
   */
  source?: UserOperationDisplayObjectItem
  /**
   *
   * @type {UserOperationDisplayObjectItem}
   * @memberof UserOperationDisplayObject
   */
  operate?: UserOperationDisplayObjectItem
}

export function UserOperationDisplayObjectFromJSON(json: any): UserOperationDisplayObject {
  return UserOperationDisplayObjectFromJSONTyped(json, false)
}

export function UserOperationDisplayObjectFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserOperationDisplayObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    target: !exists(json, 'target') ? undefined : UserOperationDisplayObjectItemFromJSON(json['target']),
    source: !exists(json, 'source') ? undefined : UserOperationDisplayObjectItemFromJSON(json['source']),
    operate: !exists(json, 'operate') ? undefined : UserOperationDisplayObjectItemFromJSON(json['operate'])
  }
}

export function UserOperationDisplayObjectToJSON(value?: UserOperationDisplayObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    target: UserOperationDisplayObjectItemToJSON(value.target),
    source: UserOperationDisplayObjectItemToJSON(value.source),
    operate: UserOperationDisplayObjectItemToJSON(value.operate)
  }
}
