import { Box, createStyles, makeStyles, Modal, Slide, Typography } from '@material-ui/core'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { Input } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

export interface ModalFolderCreateProps {
  open: boolean
  onClose: () => void
  onConfirm: (name: string) => void
  onCancel: () => void
}

export const ModalFolderCreate = ({ open, onClose, onCancel, onConfirm }: ModalFolderCreateProps) => {
  const classes = useStyles()
  const [name, setName] = useState<string>('')
  const handleConfirm = useCallback(() => {
    onConfirm(name)
  }, [name, onConfirm])
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value)
    },
    [setName]
  )
  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="フォルダを新規作成"
            onClose={onClose}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            hideSettings
          >
            <Box px="24px" pt="18px">
              <Typography variant="h6">フォルダ名</Typography>
              <Box height="6px" />
              <Input name="folderName" fullWidth onChange={handleChange} />
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
