/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CompanyFromJSON, CompanyToJSON } from './Company';
import { CompanySettingFromJSON, CompanySettingToJSON } from './CompanySetting';
export function UpdateCompanyResponseDataFromJSON(json) {
    return UpdateCompanyResponseDataFromJSONTyped(json, false);
}
export function UpdateCompanyResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        company: !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        companySettings: !exists(json, 'company_settings')
            ? undefined
            : json['company_settings'].map(CompanySettingFromJSON)
    };
}
export function UpdateCompanyResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company: CompanyToJSON(value.company),
        company_settings: value.companySettings === undefined ? undefined : value.companySettings.map(CompanySettingToJSON)
    };
}
