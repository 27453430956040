import {
  AppendContactsCompanyRequest,
  Company,
  CreateCompanyRequest,
  UpdateCompanyRequest,
  UserV1CompaniesCompanyIdListsGetRequest,
  UserV1CompaniesGetRequest
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { useGetContact, useListContacts } from '../contact/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'

export const useListCompanies = (requestParameters: UserV1CompaniesGetRequest, cacheVariant = 'default') => {
  const { data: auth } = useAuthenticate()
  const companyApi = nocoSDK.client?.userService.companyApi
  const func = useCallback(async () => {
    if (!auth) return
    return await companyApi
      ?.ApiFactory(auth.token)
      .userV1CompaniesGet(requestParameters, { cache: 'no-store' })
      .then(res => {
        const convertedList = res.data?.companies?.map(item => {
          const listRow: { [setttingId in string]: string | undefined } = {}
          item.companySettings?.forEach(setting => {
            listRow[setting.id!] = setting.companySettingValueText?.text!
          })
          return { ...item, ...listRow }
        })

        return { ...res.data, companies: convertedList }
      })
  }, [auth, companyApi, requestParameters])

  return useSWR(auth && SWRCachePath.listCompanies(requestParameters, cacheVariant), func)
}

// PageCompany Header部 検索Box用の仮API
// Tableに表示するdateの取得と分けるため、同じAPIをKeyだけ別にして実装
export const useListCompaniesForSearch = (requestParameters: UserV1CompaniesGetRequest) => {
  return useListCompanies(requestParameters, 'search')
}

export const useGetCompany = (companyId: string) => {
  const { data: auth } = useAuthenticate()
  const companyApi = nocoSDK.client?.userService.companyApi
  const func = useCallback(async () => {
    if (!auth) return undefined
    return await companyApi
      ?.ApiFactory(auth.token)
      .userV1CompaniesCompanyIdGet({ companyId: companyId })
      .then(item => {
        return item.data?.company
      })
  }, [auth, companyApi, companyId])
  return useSWR(auth && SWRCachePath.getCompany(companyId), func, {
    onError: err => handleError(err, { tag: 'Get contact list' })
  })
}

export const useListCompanyTimelines = (companyId?: string) => {
  const { data: auth } = useAuthenticate()
  const contactApi = nocoSDK.client?.userService.companyApi
  const func = useCallback(async () => {
    if (!auth || !companyId) return undefined
    return await contactApi
      ?.ApiFactory(auth.token)
      .userV1CompaniesCompanyIdTimelinesGet({ companyId: companyId })
      .then(item => {
        return item.data
      })
  }, [auth, contactApi, companyId])
  return useSWR(auth && companyId && SWRCachePath.listCompanyTimelines(companyId), func, {
    onError: err => handleError(err, { tag: 'Get company timelines' })
  })
}

export const useCreateTimeLineMemo = (companyId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListCompanyTimelines(companyId)
  const contactApi = nocoSDK.client?.userService.companyApi

  const handleCreateTimeLineMemo = useCallback(
    async (memo: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactApi?.ApiFactory(auth.token).userV1CompaniesCompanyIdMemosPost({
          companyId,
          createCompanyMemoRequest: {
            companyMemo: {
              text: memo
            }
          }
        })

        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactApi, companyId, me, mutate]
  )

  return { error, isLoading, handleCreateTimeLineMemo }
}

export const useUpdateTimeLineMemo = (companyId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListCompanyTimelines(companyId)
  const companyMemoApi = nocoSDK.client?.userService.companyMemoApi

  const handleUpdateTimeLineMemo = useCallback(
    async (companyMemoId: string, memo: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyMemoApi?.ApiFactory(auth.token).userV1CompaniesCompanyIdMemosCompanyMemoIdPut({
          companyId,
          companyMemoId,
          updateCompanyMemoRequest: {
            companyMemo: {
              text: memo
            }
          }
        })

        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyMemoApi, companyId, me, mutate]
  )

  return { error, isLoading, handleUpdateTimeLineMemo }
}

export const useDeleteTimeLineMemo = (companyId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListCompanyTimelines(companyId)
  const companyMemoApi = nocoSDK.client?.userService.companyMemoApi

  const handleDeleteTimeLineMemo = useCallback(
    async (companyMemoId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyMemoApi?.ApiFactory(auth.token).userV1CompaniesCompanyIdMemosCompanyMemoIdDelete({
          companyId,
          companyMemoId
        })

        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyMemoApi, companyId, me, mutate]
  )

  return { error, isLoading, handleDeleteTimeLineMemo }
}

export const useCreateCompany = ({
  contactId = '',
  params = {}
}: {
  contactId?: string
  params?: UserV1CompaniesGetRequest
}) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateContact } = useGetContact(contactId)
  const { mutate: mutateListCompanies } = useListCompanies(params)
  const companyApi = nocoSDK.client?.userService.companyApi
  const [createdCompany, setCreatedCompany] = useState<Company | null>(null)

  const handleCreateCompany = useCallback(
    async (createCompanyRequest: CreateCompanyRequest) => {
      try {
        if (!auth || !me) return
        setIsLoading(true)
        setCreatedCompany(null)
        setError(undefined)
        const res = await companyApi?.ApiFactory(auth.token).userV1CompaniesPost({ createCompanyRequest })
        if (!res?.data?.company) throw new Error('company not exist')
        setCreatedCompany(res.data.company)
        mutateContact()
        mutateListCompanies()
        Toaster.success('会社を作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyApi, me, mutateContact, mutateListCompanies]
  )

  const clearCreatedCompany = useCallback(() => {
    setCreatedCompany(null)
  }, [setCreatedCompany])

  return { createdCompany, clearCreatedCompany, error, isLoading, handleCreateCompany }
}

export const useUpdateCompany = (companyId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateCompany } = useGetCompany(companyId)
  const companyApi = nocoSDK.client?.userService.companyApi

  const handleUpdateCompany = useCallback(
    async (updateCompanyRequest: UpdateCompanyRequest) => {
      try {
        if (!auth || !me) return
        setIsLoading(true)
        setError(undefined)
        await companyApi?.ApiFactory(auth.token).userV1CompaniesCompanyIdPut({
          companyId,
          updateCompanyRequest
        })
        await mutateCompany()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyApi, companyId, me, mutateCompany]
  )

  return { error, isLoading, handleUpdateCompany }
}

export const useUpdateCompanyAppendContact = (companyId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListContacts({ companyId })
  const companyApi = nocoSDK.client?.userService.companyApi

  const handleUpdateCompanyAppendContact = useCallback(
    async (appendContactsCompanyRequest: AppendContactsCompanyRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await companyApi?.ApiFactory(auth.token).userV1CompaniesCompanyIdAppendContactsPut({
          companyId,
          appendContactsCompanyRequest
        })
        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Append contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyApi, companyId, me, mutate]
  )

  return { error, isLoading, handleUpdateCompanyAppendContact }
}

export const useListFilterCompany = () => {
  const { data: auth } = useAuthenticate()
  const filterApi = nocoSDK.client?.userService.filterApi
  const func = useCallback(async () => {
    if (!auth) return
    return await filterApi
      ?.ApiFactory(auth.token)
      .userV1CompanyFilterGet()
      .then(res => res.data)
  }, [auth, filterApi])
  return useSWR(auth && SWRCachePath.listCompanyFilter(), func)
}

export const useListCompanyLists = () => {
  const { data: auth } = useAuthenticate()
  const companyListApi = nocoSDK.client?.userService.companyListApi
  const func = useCallback(async () => {
    if (!auth) return

    return await companyListApi
      ?.ApiFactory(auth.token)
      .userV1CompanyListsGet()
      .then(res => res.data)
  }, [auth, companyListApi])
  return useSWR(auth?.token && SWRCachePath.listCompanyLists(), func)
}

export const useCreateCompanyList = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanyLists } = useListCompanyLists()
  const companyListApi = nocoSDK.client?.userService.companyListApi

  const handleCreateCompanyList = useCallback(
    async (name: string, ids: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        const response = await companyListApi
          ?.ApiFactory(auth.token)
          .userV1CompanyListsPost({ createCompanyListRequest: { companyList: { name } } })
        const id = response?.data?.companyList?.id
        if (!id) return
        await companyListApi?.ApiFactory(auth.token).userV1CompanyListsCompanyListIdAppendPost({
          companyListId: id,
          appendCompanyListRequest: { companyIds: ids }
        })

        await mutateListFilterCompany(), mutateListCompanyLists()
        Toaster.success('リストを作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create CompanyList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyListApi, me, mutateListFilterCompany, mutateListCompanyLists]
  )

  return { error, isLoading, handleCreateCompanyList }
}

export const useUpdateCompanyListsPin = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanyLists } = useListCompanyLists()
  const companyListApi = nocoSDK.client?.userService.companyListApi

  const handleUpdateCompanyListsPin = useCallback(
    async (ids: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyListApi
          ?.ApiFactory(auth.token)
          .userV1CompanyListsPinPost({ pinCompanyListRequest: { companyListIds: ids } })

        await mutateListFilterCompany(), mutateListCompanyLists()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update CompanyListsPin' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyListApi, me, mutateListFilterCompany, mutateListCompanyLists]
  )

  return { error, isLoading, handleUpdateCompanyListsPin }
}

export const useUpdateCompanyListName = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanyLists } = useListCompanyLists()
  const companyListApi = nocoSDK.client?.userService.companyListApi

  const handleUpdateCompanyListName = useCallback(
    async (companyListId: string, name: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyListApi?.ApiFactory(auth.token).userV1CompanyListsCompanyListIdPut({
          companyListId: companyListId,
          updateCompanyListRequest: { companyList: { name } }
        })

        await mutateListFilterCompany(), mutateListCompanyLists()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update CompanyList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyListApi, me, mutateListFilterCompany, mutateListCompanyLists]
  )
  return { error, isLoading, handleUpdateCompanyListName }
}

export const useDeleteCompanyList = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanyLists } = useListCompanyLists()
  const companyListApi = nocoSDK.client?.userService.companyListApi

  const handleDeleteCompanyList = useCallback(
    async (companyListId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyListApi?.ApiFactory(auth.token).userV1CompanyListsCompanyListIdDelete({
          companyListId
        })

        await mutateListFilterCompany(), mutateListCompanyLists()
        Toaster.success('リストを削除しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Delete CompanyList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyListApi, me, mutateListFilterCompany, mutateListCompanyLists]
  )
  return { error, isLoading, handleDeleteCompanyList }
}

export const useArchiveCompany = (params: UserV1CompaniesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [isPosted, setIsPosted] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanies } = useListCompanies(params)
  const companyApi = nocoSDK.client?.userService.companyApi

  const handleSaveArchived = useCallback(
    async (ids: string[], isArchived: boolean) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyApi
          ?.ApiFactory(auth.token)
          .userV1CompaniesArchivePut({ archiveCompaniesRequest: { archive: !isArchived, ids } })
        await mutateListFilterCompany(), mutateListCompanies()
        Toaster.success(`会社をアーカイブ${isArchived ? 'から移動' : ''}しました`)
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive Companies' })
      } finally {
        setIsLoading(false)
        setIsPosted(true)
      }
    },
    [auth, me, companyApi, mutateListFilterCompany, mutateListCompanies]
  )
  return { error, isLoading, handleSaveArchived, isPosted }
}

export const useRemoveCompanyListCompanyId = (params: UserV1CompaniesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanies } = useListCompanies(params)
  const companyListApi = nocoSDK.client?.userService.companyListApi

  const handleRemoveCompanyListCompanyId = useCallback(
    async (companyListId: string, companyIds: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyListApi?.ApiFactory(auth.token).userV1CompanyListsCompanyListIdRemovePost({
          companyListId,
          removeCompanyListRequest: { companyIds }
        })
        await mutateListFilterCompany(), mutateListCompanies()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Remove CompanyList CompanyId' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyListApi, me, mutateListFilterCompany, mutateListCompanies]
  )
  return { error, isLoading, handleRemoveCompanyListCompanyId }
}

export const useUpdateCompanyList = (params: UserV1CompaniesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanies } = useListCompanies(params)
  const companyListApi = nocoSDK.client?.userService.companyListApi

  const handleUpdateCompanyList = useCallback(
    async (companyListId: string, companyIds: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await companyListApi?.ApiFactory(auth.token).userV1CompanyListsCompanyListIdAppendPost({
          companyListId,
          appendCompanyListRequest: { companyIds }
        })

        await mutateListFilterCompany(), mutateListCompanies()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update CompanyList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companyListApi, me, mutateListFilterCompany, mutateListCompanies]
  )

  return { error, isLoading, handleUpdateCompanyList }
}

export const useListCompanyListsByCompanyId = (params: UserV1CompaniesCompanyIdListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const companyApi = nocoSDK.client?.userService.companyApi
  const func = useCallback(async () => {
    if (!auth) return
    return await companyApi
      ?.ApiFactory(auth.token)
      .userV1CompaniesCompanyIdListsGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, companyApi])

  return useSWR(auth && SWRCachePath.listCompanyListsByCompanyId(params), func)
}
