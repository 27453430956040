import { Box, createStyles, makeStyles } from '@material-ui/core'
import {
  BillingInvoice,
  Coupon,
  OrganizationPaymentContractBasicInfo,
  OrganizationPaymentContractPaidInfo,
  OrganizationPlanDetail,
  ScheduleBillingContract
} from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonIcon, Card, Checkbox, Colors, SvgIcon, Typography } from 'src/components/atoms'
import { Message } from 'src/components/atoms/Message'
import { SelectPlanCard } from 'src/components/molecules'
import {
  useContractPaidPlan,
  useCreateOrganizationPaymentScheduleBillingInvoice
} from 'src/fixtures/modules/organizationPayment/hooks'
import { PaymentBillingDetail } from '../../PaymentBillingDetail'
import { PaymentCouponCard } from '../../PaymentCouponCard'
import { PaymentMethodCard, PaymentMethodProps } from '../../PaymentMethodCard'
import { PaymentScheduleBillingInvoice } from '../../PaymentScheduleBillingInvoice'

export interface ContractThirdStepProps {
  plan: OrganizationPlanDetail
  userOptionQuantity: number
  contractBasicInfo?: OrganizationPaymentContractBasicInfo
  contractPaidInfo?: OrganizationPaymentContractPaidInfo
  paymentMethod?: PaymentMethodProps
  coupon?: Coupon
  onSubmit: () => void
  onBack: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      padding: '0 24px'
    }
  })
)

export const ContractThirdStep = ({
  plan,
  userOptionQuantity,
  contractBasicInfo,
  contractPaidInfo,
  paymentMethod,
  coupon,
  onSubmit,
  onBack
}: ContractThirdStepProps) => {
  const classes = useStyles()
  const [consent, setConsent] = useState(false)
  const [scheduleBillingContract, setScheduleBillingContract] = useState<ScheduleBillingContract>()
  const [scheduleBillingInvoice, setScheduleBillingInvoice] = useState<BillingInvoice>()
  const [scheduleBillingNextInvoice, setScheduleBillingNextInvoice] = useState<BillingInvoice>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { handleCreateScheduleBillingInvoice } = useCreateOrganizationPaymentScheduleBillingInvoice()
  const { isLoading, error, handleContractPaidPlan } = useContractPaidPlan()
  const backButton = useMemo(() => {
    return (
      <Button
        hasBorder={false}
        title="変更"
        kind="secondary"
        variant="outlined"
        size="small"
        onClick={() => onBack()}
      />
    )
  }, [onBack])

  const handleClick = () => {
    if (!contractBasicInfo || !contractPaidInfo || !consent) return

    const contractPaidInfoValues = { ...contractPaidInfo, consent }
    handleContractPaidPlan(
      { contractBasicInfo, contractPaidInfo: contractPaidInfoValues },
      { planId: plan.id, addon: { userOptionQuantity } },
      coupon?.code ? { couponCode: coupon.code } : undefined
    )
    setIsSubmitting(true)
  }

  const handleScheduleBilingInvoice = useCallback(
    async (planId: string, userOptionQuantity: number) => {
      const data = await handleCreateScheduleBillingInvoice({
        planId,
        addon: { userOptionQuantity },
        couponCode: coupon?.code
      })
      setScheduleBillingContract(data?.scheduleBillingContract)
      setScheduleBillingInvoice(data?.scheduleBillingInvoice)
      setScheduleBillingNextInvoice(data?.scheduleBillingNextInvoice)
    },
    [coupon?.code, handleCreateScheduleBillingInvoice]
  )

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      onSubmit()
      setIsSubmitting(false)
    }
  }, [isSubmitting, setIsSubmitting, isLoading, error, onSubmit])

  useEffect(() => {
    handleScheduleBilingInvoice(plan.id, userOptionQuantity)
  }, [handleScheduleBilingInvoice, plan.id, userOptionQuantity])

  return (
    <Box>
      <Box mb="16px">
        <SelectPlanCard planName={plan.name} unitAmount={plan.unitAmount} />
      </Box>

      {paymentMethod && (
        <Box mb="16px">
          <PaymentMethodCard paymentMethod={paymentMethod} actionComponent={backButton} />
        </Box>
      )}

      {coupon && (
        <Box mb="16px">
          <PaymentCouponCard coupon={coupon} actionComponent={backButton} />
        </Box>
      )}

      {contractBasicInfo && (
        <Box mb="16px">
          <Card label="ご契約者情報" sx={{ bgcolor: '#fff' }} actionComponent={backButton}>
            <PaymentBillingDetail
              isPaid={true}
              contractBasicInfo={contractBasicInfo}
              contractPaidInfo={contractPaidInfo}
            />
          </Card>
        </Box>
      )}

      {scheduleBillingContract && scheduleBillingInvoice && scheduleBillingNextInvoice && (
        <Box mb="16px">
          <Card sx={{ bgcolor: '#fff' }}>
            <PaymentScheduleBillingInvoice
              isPaid={true}
              scheduleBillingContract={scheduleBillingContract}
              scheduleBillingInvoice={scheduleBillingInvoice}
              scheduleBillingNextInvoice={scheduleBillingNextInvoice}
            />
          </Card>
        </Box>
      )}

      <Box mb="24px">
        <Card label="ご確認事項" sx={{ bgcolor: '#fff' }}>
          <Box mb="16px">
            <Message type="info">
              <Typography fontSize="s" fontWeight="bold" lineheight="1.32">
                クレジットカードによる毎月１回分のお引き落としになります
              </Typography>
            </Message>
          </Box>
          <Box mb="16px">
            <Message type="info">
              <Typography fontSize="s" fontWeight="bold" lineheight="1.32">
                決済完了と同時にサービスの提供を開始し、当社との契約期間中、継続的にサービスを提供いたします
              </Typography>
            </Message>
          </Box>
          <Box mb="16px">
            <Message type="info">
              <Typography fontSize="s" fontWeight="bold" lineheight="1.32">
                本プランは、お客様から解約のご連絡がない限り、お手続き無く、自動的に契約が更新されます
              </Typography>
            </Message>
          </Box>
          <Box mb="16px">
            <Message type="info">
              <Typography fontSize="s" fontWeight="bold" lineheight="1.32">
                契約終了日時の1時間前までにサービス内でご解約手続きすることにより、契約期間末日をもって契約をご解約することができます。契約期間途中でのご解約を行うことはできません
              </Typography>
            </Message>
          </Box>
          <Box>
            <Message type="info">
              <Typography fontSize="s" fontWeight="bold" lineheight="1.32">
                弊社による審査の結果によっては契約を締結できない可能性があります
              </Typography>
            </Message>
          </Box>
        </Card>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center" mb="24px">
        <Checkbox value={consent} onChange={() => setConsent(v => !v)} tabIndex={-1} />
        <Typography fontSize="s">上記内容を確認しました</Typography>
      </Box>

      {error && (
        <Box mb="24px" padding="0 24px">
          <Message type="error">
            <Typography fontSize="s" fontWeight="bold">
              {error.message}
            </Typography>
          </Message>
        </Box>
      )}

      <Box display="flex" justifyContent="center" mb="24px">
        <Button
          tabIndex={-1}
          className={classes.submitButton}
          title="プランを申し込む"
          onClick={() => !isLoading && handleClick()}
          disabled={isLoading || !consent}
          loading={isLoading}
          width="217px"
          size="large"
        />
      </Box>

      <Box display="flex" justifyContent="center" mb="24px" color={Colors.accent.keyBlue.default}>
        <ButtonIcon
          tabIndex={-1}
          iconBefore={<SvgIcon variant="leftArrow" size="16px" color="inherit" />}
          onClick={() => onBack()}
        >
          <Typography fontSize="s" fontWeight="bold" lineheight="1" color="inherit">
            戻る
          </Typography>
        </ButtonIcon>
      </Box>
    </Box>
  )
}
