import { AppBar, Box, CircularProgress, createStyles, makeStyles, Tab, Typography } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Tooltip } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { DocumentDetail, Header, Table, TableColumn, useTablePagination } from 'src/components/organisms'
import {
  ActivityStatusCell,
  CompanyNameCell,
  ExpiryCell,
  InflowCell,
  NameCell
} from 'src/components/organisms/Table/TableParts'
import { useRouter } from 'next/router'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { ModalDocumentRegister, ModalExpiryUpdate, PopupTemplate } from 'src/components/modals'
import { HeaderRightSection } from './HeaderRight'
import {
  useArchiveDocument,
  useGetDocument,
  useListDocumentContacts,
  useListDocumentViewHistories
} from 'src/fixtures/modules/documents/hooks'
import { ContactForDisplay, DocumentContact } from '@noco/http-client/lib/noco'
import { format } from 'date-fns'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { ja } from 'date-fns/locale'
import { useUpdateDocumentContact } from 'src/fixtures/modules/documentContact/hooks'
import { ModalDocumentViewHistories } from 'src/components/modals/ModalDocumentViewHistories'
import { AnaliticsTable } from 'src/components/organisms/AnaliticsTable'
import { ActionMenuItemType } from 'src/components/molecules'
import dynamic from 'next/dynamic'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

type TabValueType = 'contact' | 'analytics' | 'version'

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    appBar: {
      backgroundColor: Colors.functional.background.default,
      borderBottom: `1px solid ${Colors.background.silver}`,
      boxShadow: 'none',
      padding: '0 16px',
      zIndex: 0
    },
    tablist: {
      minHeight: 'auto !important'
    },
    tab: {
      opacity: 1,
      minWidth: 'auto',
      minHeight: 'auto',
      padding: '10px 16px',
      color: Colors.base.placeHolder,
      borderRadius: '6px 6px 0 0'
    },
    selectedTab: {
      color: Colors.accent.keyPurple.default,
      background: Colors.selected.background,
      borderBottom: `2px solid ${Colors.accent.keyPurple.default}`
    },
    tabsIndicator: {
      display: 'none'
    },
    tabPanel: {
      height: '100%',
      padding: '16px 0 0'
    }
  })
)

export const PageDocumentDetail = ({ documentId }: { documentId: string }) => {
  const { page, per, totalPages, setTotalPages, changePage, changePerPage, resetPagination } =
    useTablePagination('document-detail')
  const classes = useStyles()
  const router = useRouter()
  const [value, setValue] = useState<TabValueType>('contact')
  const [targetContact, setTargetContact] = useState<ContactForDisplay>()
  const { data: res } = useListDocumentContacts({ documentId, page: page + 1, per })
  const { data: viewHistorieRes } = useListDocumentViewHistories({ documentId, page: page + 1, per })
  const contactList = res?.documentContacts
  const [updateSlideModal, setUpdateSlideModal] = useState(false)
  const { hasPermission: isUpdatable } = useFeatureFlag('document', 'update')
  const { hasPermission: isDeletable } = useFeatureFlag('document', 'delete')

  //- 閲覧期限の変更をする Document
  const [targetDocumentContactId, setTargetDocumentContactId] = useState<string | undefined>(undefined)
  const [targetDocumentContactExpiryValue, setTargetDocumentContactExpiryValue] = useState<DocumentContact>({
    canViewable: false,
    expiredOn: null
  })
  const { data: document } = useGetDocument(documentId)
  const { handleUpdateDocumentContact } = useUpdateDocumentContact(documentId, { page: page + 1, per })

  const list = useMemo(
    () =>
      contactList?.map(contact => ({
        ...contact,
        contactCompany: contact.contact?.company,
        user: contact.contact?.user
      })) || [],
    [contactList]
  )

  const columns: TableColumn[] = useMemo(
    () => [
      { name: 'inflowSource', title: '共有手段', width: 84, render: InflowCell },
      {
        name: 'createdAt',
        title: '最新のアクティビティ日時',
        width: 170,
        getCellValue: (value: DocumentContact) => {
          const date = value.actedAt || value.updatedAt
          return date ? format(new Date(date).getTime(), 'yyyy/MM/dd (E) HH:mm', { locale: ja }) : '-'
        }
      },
      {
        name: 'currentActivityStatusObject',
        title: '最新のアクティビティ',
        width: 138,
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => <ActivityStatusCell value={value.activityStatus} />
      },
      {
        name: 'contact',
        title: '名前',
        width: 116,
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => <NameCell value={value} />
      },
      {
        name: 'contactCompany',
        width: 180,
        title: '会社名',
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => {
          return <CompanyNameCell value={value?.displayName || ''} />
        }
      },
      {
        name: 'role',
        width: 110,
        title: '役職',
        getCellValue: (data: DocumentContact): string => data.contact?.position!
      },
      {
        name: 'user',
        title: '担当者',
        width: 137,
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => <NameCell value={value} iconSize="large" />
      },
      {
        name: 'expiredOn',
        width: 200,
        title: '閲覧権限・期限',
        getCellValue: (value: DocumentContact) => {
          return {
            documentContactId: value.id,
            expiredOn: value.expiredOn,
            canViewable: value.canViewable
          }
        },
        render: ({ value }: DataTypeProvider.ValueFormatterProps) => (
          <ExpiryCell
            onClick={e => {
              e.stopPropagation()
              setTargetDocumentContactId(value.documentContactId)
            }}
            {...value}
          />
        )
      }
    ],
    []
  )

  // 閲覧期限変更モーダルにデータを渡す
  useEffect(() => {
    if (!contactList || !contactList.length || !targetDocumentContactId) return
    const { canViewable, expiredOn }: DocumentContact = contactList.filter(contact => {
      return contact.id === targetDocumentContactId
    })[0]
    setTargetDocumentContactExpiryValue({ canViewable, expiredOn })
  }, [targetDocumentContactId, contactList])

  const handleChange = useCallback(
    (_event: ChangeEvent<{}>, newValue: TabValueType) => {
      setValue(newValue)
      resetPagination()
    },
    [resetPagination, setValue]
  )
  const handleClickRow = useCallback(
    (id: string) => {
      router.push('/contacts/[id]', `/contacts/${id}`)
    },
    [router]
  )

  const handleExpiryConfirm = ({ canViewable, expiredOn }: DocumentContact) => {
    handleUpdateDocumentContact({
      documentContactId: targetDocumentContactId!,
      updateDocumentContactRequest: { documentContact: { canViewable, expiredOn } }
    })

    setTargetDocumentContactId(undefined)
  }

  useEffect(() => {
    if (value === 'contact') {
      if (res?.pageInfo?.totalPages && res?.pageInfo?.totalPages !== totalPages) {
        setTotalPages(res?.pageInfo?.totalPages)
      }
    } else if (value === 'analytics') {
      if (viewHistorieRes?.pageInfo?.totalPages && viewHistorieRes?.pageInfo?.totalPages !== totalPages) {
        setTotalPages(viewHistorieRes?.pageInfo?.totalPages)
      }
    }
  }, [viewHistorieRes?.pageInfo?.totalPages, res?.pageInfo?.totalPages, setTotalPages, totalPages, value])

  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)

  const title = useMemo(() => {
    return document?.archivedAt ? 'アーカイブから取り出す' : 'アーカイブ'
  }, [document?.archivedAt])

  const {
    handleSaveArchived,
    error: archiveError,
    isLoading: isLoadingArchive,
    isPosted
  } = useArchiveDocument({ page: 1, per: 25 })

  const handleClickModalArchiveConfirm = useCallback(
    (isArchived: boolean) => () => {
      handleSaveArchived([documentId], isArchived)
      setModalArchiveOpen(false)
    },
    [documentId, handleSaveArchived]
  )

  useEffect(() => {
    if (isPosted && !archiveError) router.push('/documents')
  }, [archiveError, isPosted, router])

  const menuItemList: ActionMenuItemType[] = useMemo(
    () => [
      {
        label: '新しい資料に差し替える',
        onClick: () => setUpdateSlideModal(true),

        icon: <DynamicMuiIcon variant="uploadFile" />,
        toolTipText:
          'ファイルをアップロードして資料の更新ができます。更新中は古いバージョンが表示されますが、数分後、自動で新しいバージョンを表示します。'
      },
      {
        label: title,
        onClick: () => isDeletable && setModalArchiveOpen(true),
        icon: <DynamicMuiIcon variant="archive" />,
        toolTipText:
          '利用が少ない資料をアーカイブ（一時保管）できます。資料をアーカイブすると、非公開になります。また、アーカイブの状態から元に戻すことができます。',
        disabled: !isDeletable
      }
    ],
    [isDeletable, title]
  )

  return (
    <>
      <Layout>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {isLoadingArchive && (
            <Box
              sx={{
                position: 'fixed',
                zIndex: 9999,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: 'rgba(0,0,0,0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Box bgcolor={Colors.functional.background.default} borderBottom={`1px solid ${Colors.background.silver}`}>
            {document && (
              <Header
                textSize="small"
                iconSize="20px"
                icon="folder"
                title={document.archivedAt ? 'アーカイブ' : 'すべて'}
                rightSection={
                  document && (
                    <HeaderRightSection
                      document={document}
                      menuItemList={menuItemList}
                      hasEditPermission={isUpdatable}
                    />
                  )
                }
                back
              />
            )}
          </Box>
          <Box height="100%" flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
            <Box bgcolor={Colors.functional.background.default} px="16px" pt="16px">
              {document && (
                <DocumentDetail
                  document={document}
                  createdAt={document?.createdAt || ''}
                  createdBy={document?.user}
                  tag={document.label || ''}
                  title={document?.title || ''}
                />
              )}
              <Box pt="20px" />
            </Box>
            <TabContext value={value}>
              <AppBar position="relative" className={classes.appBar}>
                <TabList
                  onChange={handleChange}
                  aria-label="company add tablist"
                  classes={{ root: classes.tablist, indicator: classes.tabsIndicator }}
                >
                  <Tab
                    value="contact"
                    label={
                      <Tooltip content={' 資料を共有した顧客情報を表示します。'}>
                        <Typography variant="h5">連絡先別のアクティビティ</Typography>
                      </Tooltip>
                    }
                    classes={{ root: classes.tab, selected: classes.selectedTab }}
                    {...a11yProps(0)}
                  />
                  <Tab
                    value="analytics"
                    label={
                      <Tooltip content={'資料を閲覧した顧客情報を表示します。顧客ごとの閲覧分析を確認できます。'}>
                        <Typography variant="h5">閲覧履歴と分析</Typography>
                      </Tooltip>
                    }
                    classes={{ root: classes.tab, selected: classes.selectedTab }}
                    {...a11yProps(1)}
                  />
                  {/* <Tab
                  value="analytics"
                  label={<Typography variant="h5">分析</Typography>}
                  classes={{ root: classes.tab, selected: classes.selectedTab }}
                  {...a11yProps(2)}
                /> */}
                  {/* <Tab
                  value="version"
                  label={<Typography variant="h5">バージョン管理</Typography>}
                  classes={{ root: classes.tab, selected: classes.selectedTab }}
                  {...a11yProps(2)}
                /> */}
                </TabList>
              </AppBar>
              <Box height="100%" flexGrow={1} overflow="auto">
                <TabPanel value="contact" className={classes.tabPanel}>
                  <Box height="100%">
                    <DynamicTable
                      columns={columns}
                      rows={list || []}
                      onClickRow={row => handleClickRow(row.contactId!)}
                      page={page}
                      per={per}
                      totalPages={totalPages}
                      changePage={changePage}
                      changePerPage={changePerPage}
                      numberOfRows={res?.pageInfo?.totalCount}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="analytics" className={classes.tabPanel}>
                  <Box height="100%">
                    <AnaliticsTable
                      histories={viewHistorieRes?.viewHistories || []}
                      onClickGraphButton={row => setTargetContact(row.contact)}
                      page={page}
                      per={per}
                      totalPages={totalPages}
                      changePage={changePage}
                      changePerPage={changePerPage}
                      numberOfRows={viewHistorieRes?.pageInfo?.totalCount}
                      onClickRow={row => row?.contact && handleClickRow(row.contact.id!)}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="version">version</TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Box>

        <ModalExpiryUpdate
          open={Boolean(targetDocumentContactId)}
          onClose={() => setTargetDocumentContactId(undefined)}
          onCancel={() => setTargetDocumentContactId(undefined)}
          onConfirm={handleExpiryConfirm}
          value={targetDocumentContactExpiryValue}
        />
        <ModalDocumentViewHistories
          open={!!targetContact}
          contact={targetContact}
          documentId={documentId}
          onClose={() => setTargetContact(undefined)}
        />
        <PopupTemplate
          open={modalArchiveOpen}
          title={`資料を${title}`}
          onClose={() => setModalArchiveOpen(false)}
          hasClose
          hasCancel
          hasConfirm
          onConfirm={handleClickModalArchiveConfirm(!!document?.archivedAt)}
          cancelButtonLabel="キャンセル"
          confirmButtonLabel="OK"
        >
          <Box p="24px">
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {`選択した資料をアーカイブ${document?.archivedAt ? 'から' : 'へ'}移動します\n本当によろしいですか？`}
            </Typography>
          </Box>
        </PopupTemplate>
      </Layout>

      <ModalDocumentRegister
        open={updateSlideModal}
        onCancel={() => setUpdateSlideModal(false)}
        onConfirm={() => setUpdateSlideModal(false)}
        onClose={() => setUpdateSlideModal(false)}
        documentId={documentId}
      />
    </>
  )
}
