/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Document, DocumentFromJSON, DocumentFromJSONTyped, DocumentToJSON } from './Document'

/**
 *
 * @export
 * @interface FileUploadDocumentResponseData
 */
export interface FileUploadDocumentResponseData {
  /**
   *
   * @type {Document}
   * @memberof FileUploadDocumentResponseData
   */
  document?: Document
  /**
   *
   * @type {boolean}
   * @memberof FileUploadDocumentResponseData
   */
  isFirstCreatedDocument: boolean
}

export function FileUploadDocumentResponseDataFromJSON(json: any): FileUploadDocumentResponseData {
  return FileUploadDocumentResponseDataFromJSONTyped(json, false)
}

export function FileUploadDocumentResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FileUploadDocumentResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    document: !exists(json, 'document') ? undefined : DocumentFromJSON(json['document']),
    isFirstCreatedDocument: json['is_first_created_document']
  }
}

export function FileUploadDocumentResponseDataToJSON(value?: FileUploadDocumentResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document: DocumentToJSON(value.document),
    is_first_created_document: value.isFirstCreatedDocument
  }
}
