import React, { useMemo } from 'react'
import Image from 'next/image'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { AttachmentFile } from '../type'
import { downloadFileFromURL } from 'src/fixtures/utils/file'
import { getFileExtension } from '../utils'

export interface ImageCardContentProps {
  attachmentFile: AttachmentFile
  isEditing?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    downloadButton: {
      cursor: 'pointer'
    }
  })
)

export const ImageCardContent = ({ attachmentFile, isEditing = false }: ImageCardContentProps) => {
  const classes = useStyles()
  const subTitle = useMemo(() => {
    const fileExtension = getFileExtension(attachmentFile.title)
    return attachmentFile.humanizedFileSize ?? fileExtension
      ? `${attachmentFile.humanizedFileSize} | ${fileExtension}`
      : ''
  }, [attachmentFile.humanizedFileSize, attachmentFile.title])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '46px',
          height: '42px',
          borderRadius: '4px',
          marginRight: '10px',
          bgcolor: Colors.background.lightGray
        }}
      >
        <Image
          src={attachmentFile.thumbnailImageUrl || attachmentFile.url!}
          alt={attachmentFile.title}
          layout="fill"
          objectFit="cover"
        />
      </Box>

      <Box sx={{ flex: '1', overflow: 'hidden' }}>
        <Box sx={{ color: Colors.base.black }}>
          <Typography fontSize="s" letterSpacing="tight" lh="tight" noWrap>
            {attachmentFile.title}
          </Typography>
        </Box>
        <Box color={Colors.base.middleGray}>
          <Typography fontSize="xs" lh="tight" color="inherit">
            {subTitle}
          </Typography>
        </Box>
      </Box>

      {!isEditing && (
        <Box lineHeight={1}>
          <Box
            ml="8px"
            className={classes.downloadButton}
            color={Colors.base.middleGray}
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              downloadFileFromURL(attachmentFile.url!, attachmentFile.title)
            }}
          >
            <DynamicMuiIcon variant="fileDownload" size="20px" color="inherit" />
          </Box>
        </Box>
      )}
    </Box>
  )
}
