/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetStaffListsResponseData,
  GetStaffListsResponseDataFromJSON,
  GetStaffListsResponseDataFromJSONTyped,
  GetStaffListsResponseDataToJSON
} from './GetStaffListsResponseData'

/**
 *
 * @export
 * @interface GetStaffListsResponse
 */
export interface GetStaffListsResponse {
  /**
   *
   * @type {number}
   * @memberof GetStaffListsResponse
   */
  status?: number
  /**
   *
   * @type {GetStaffListsResponseData}
   * @memberof GetStaffListsResponse
   */
  data?: GetStaffListsResponseData
}

export function GetStaffListsResponseFromJSON(json: any): GetStaffListsResponse {
  return GetStaffListsResponseFromJSONTyped(json, false)
}

export function GetStaffListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStaffListsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetStaffListsResponseDataFromJSON(json['data'])
  }
}

export function GetStaffListsResponseToJSON(value?: GetStaffListsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetStaffListsResponseDataToJSON(value.data)
  }
}
