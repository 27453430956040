import React from 'react'
import { Box, Collapse as MuiCollapse, createStyles, makeStyles } from '@material-ui/core'
import { Colors, MuiIconVariant } from 'src/components/atoms'
import { ShowAllList } from './ShowAllList'
import { Label } from './Label'
import { Count } from './Count'
import { FilterContentPins, FilterContentPins1 } from '@noco/http-client/lib/noco'

const useStyles = makeStyles(() => {
  return createStyles({
    childItemWrap: {
      '& .childItem': {
        padding: '4px 14px 4px 24px',
        height: '30px'
      }
    }
  })
})

export interface CollapseProps {
  open: boolean
  allCount: number | undefined
  modalTitle: string
  icon: MuiIconVariant
  pinsList: FilterContentPins[] | undefined
  onLabelClick: (id: string | undefined, type: string) => () => void
  activeId: string
  activeType: string
  allLists: FilterContentPins1[]
  onMaterialListsPinConfirm?: (ids: string[], type?: string) => void
  materialableType: string
}

export interface allList {
  label: string
  count: number
  isPin?: boolean
}

export const Collapse = ({
  open,
  allCount,
  modalTitle,
  icon,
  pinsList,
  onLabelClick,
  activeId,
  activeType,
  allLists,
  onMaterialListsPinConfirm,
  materialableType
}: CollapseProps) => {
  const classes = useStyles()
  return (
    <MuiCollapse in={open} timeout="auto" unmountOnExit>
      <Box width="100%" bgcolor={Colors.background.lightGray} className={classes.childItemWrap}>
        {pinsList?.map(({ materialableId, text, counts, materialableType, isPin }, i) => {
          if (!isPin) {
            return
          }
          return (
            <Label
              key={i}
              label={text}
              rightComponent={<Count count={counts} />}
              icon={icon}
              active={activeId === materialableId && activeType === materialableType}
              onClick={onLabelClick(materialableId, `${materialableType === 'document' ? 'document' : 'site'}`)}
            />
          )
        })}
        <ShowAllList
          allCount={allCount}
          title={modalTitle}
          variableList={allLists}
          icon={icon}
          onPinConfirm={onMaterialListsPinConfirm}
          materialableType={materialableType}
        />
      </Box>
    </MuiCollapse>
  )
}
