/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * @export
 */
export const OrganizationPaymentCardInfoBrandEnum = {
    Amex: 'amex',
    Diners: 'diners',
    Discover: 'discover',
    Jcb: 'jcb',
    Mastercard: 'mastercard',
    Visa: 'visa',
    Unionpay: 'unionpay',
    Unknown: 'unknown'
};
export function OrganizationPaymentCardInfoFromJSON(json) {
    return OrganizationPaymentCardInfoFromJSONTyped(json, false);
}
export function OrganizationPaymentCardInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        last4: !exists(json, 'last4') ? undefined : json['last4'],
        expYear: !exists(json, 'exp_year') ? undefined : json['exp_year'],
        expMonth: !exists(json, 'exp_month') ? undefined : json['exp_month'],
        brand: !exists(json, 'brand') ? undefined : json['brand']
    };
}
export function OrganizationPaymentCardInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        last4: value.last4,
        exp_year: value.expYear,
        exp_month: value.expMonth,
        brand: value.brand
    };
}
