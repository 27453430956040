import { Box, Modal, createStyles, makeStyles, Slide } from '@material-ui/core'
import React, { useState, useCallback } from 'react'
import { Typography } from 'src/components/atoms'
import { SlideForm, SelectAndCreateList } from 'src/components/organisms'
import { SelectorItemObject } from '@noco/http-client/lib/noco'

export interface AddOrRemoveContactProps {
  count?: number
  selectedIds?: string[]
  keyword?: string
  selectorList?: SelectorItemObject
  onArchivedBoxClose: () => void
  onSetKeyword?: (value: string) => void
  onCreateList?: (listName: string, contactIds: string[]) => void
  onUpdateList?: (contactListId: string, contactIds: string[]) => void
  modalAddContactOpen: boolean
  onSetModalAddContactOpen?: (value: boolean) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

export const AddOrRemoveContact = ({
  count,
  selectedIds,
  keyword,
  selectorList,
  onArchivedBoxClose,
  onSetKeyword,
  onCreateList,
  onUpdateList,
  modalAddContactOpen,
  onSetModalAddContactOpen
}: AddOrRemoveContactProps) => {
  const classes = useStyles()

  const [selectedListId, setSelectedListId] = useState('')
  const [selectedLabel, setSelectedLabel] = useState('')

  const handleCloseModal = useCallback(() => {
    onSetModalAddContactOpen?.(false)
    onArchivedBoxClose()
  }, [onArchivedBoxClose, onSetModalAddContactOpen])

  const handleConfirm = useCallback(() => {
    if (selectedListId) {
      onUpdateList?.(selectedListId, selectedIds!)
    } else if (!selectedListId && selectedLabel) {
      onCreateList?.(selectedLabel, selectedIds!)
    }
    onSetModalAddContactOpen?.(false)
    onArchivedBoxClose()
  }, [
    onArchivedBoxClose,
    onCreateList,
    onUpdateList,
    selectedIds,
    selectedListId,
    selectedLabel,
    onSetModalAddContactOpen
  ])

  return (
    <Modal open={modalAddContactOpen} onClose={handleCloseModal} className={classes.modal}>
      <Slide direction="left" in={modalAddContactOpen}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="リストに追加"
            headerSubTitle={count != null ? `${count}件 選択中` : undefined}
            hideSettings
            onClose={handleCloseModal}
            onCancel={handleCloseModal}
            onConfirm={handleConfirm}
            buttonDisabled={!selectedLabel}
          >
            <Box px="24px" py="16px">
              <Box>
                <Typography fontSize="s" lineheight="14px" fontWeight="bold">
                  リスト名
                </Typography>
                <Box mt="10px" />
                <SelectAndCreateList
                  selectorList={selectorList?.selectorItems}
                  onSetKeyword={onSetKeyword}
                  keyword={keyword}
                  onSetListId={setSelectedListId}
                  selectedLabel={selectedLabel}
                  onSetSelectedLabel={setSelectedLabel}
                />
              </Box>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
