import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { ShallowChatMessage, EnumChatMessageRepliedStatus } from '@noco/http-client/lib/noco'

export interface MessageCellProps {
  value?: ShallowChatMessage[]
}

export const MessageCell = ({ value }: MessageCellProps) => {
  const materialableIconVariant = useMemo(() => {
    return value?.[0].materialable?.materialableType === 'site' ? 'computer' : 'document'
  }, [value])

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        {value?.[0].repliedStatus != EnumChatMessageRepliedStatus.None && (
          <Box
            display="flex"
            mr="6px"
            color={
              value?.[0].repliedStatus === EnumChatMessageRepliedStatus.Replied
                ? Colors.base.placeHolder
                : Colors.accent.keyRed.default
            }
          >
            <DynamicMuiIcon
              variant={value?.[0].repliedStatus === EnumChatMessageRepliedStatus.Replied ? 'reply' : 'errorOutline'}
              size="16px"
            />
          </Box>
        )}
        <Box overflow="hidden">
          <Typography fontSize="s" lineheight="14px" noWrap>
            {value?.[0].message}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt="8px">
        <Box display="flex" color={Colors.base.placeHolder} mr="3px">
          <DynamicMuiIcon variant={materialableIconVariant} size="16px" />
        </Box>
        <Box color={Colors.base.middleGray} overflow="hidden">
          <Typography fontSize="xs" lineheight="12px" noWrap>
            {value?.[0].materialable?.title}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
