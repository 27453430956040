/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateDocumentMessageRequestDocumentMessageFromJSON, UpdateDocumentMessageRequestDocumentMessageToJSON } from './UpdateDocumentMessageRequestDocumentMessage';
export function UpdateDocumentMessageRequestFromJSON(json) {
    return UpdateDocumentMessageRequestFromJSONTyped(json, false);
}
export function UpdateDocumentMessageRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        documentMessage: !exists(json, 'document_message')
            ? undefined
            : UpdateDocumentMessageRequestDocumentMessageFromJSON(json['document_message'])
    };
}
export function UpdateDocumentMessageRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document_message: UpdateDocumentMessageRequestDocumentMessageToJSON(value.documentMessage)
    };
}
