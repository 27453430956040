import { Box } from '@material-ui/core'
import React from 'react'
import { SendedBy } from '@noco/http-client/lib/noco'
import { Colors, SvgIcon, Typography } from 'src/components/atoms'

export interface SenderNameCellProps {
  value: SendedBy
}

export const SenderNameCell = ({ value }: SenderNameCellProps) => {
  return (
    <Box display="flex" alignItems="center" overflow="hidden">
      {value.avatar ? (
        <Box width="24px" height="24px" borderRadius="50%" overflow="hidden" mr="6px" flexShrink={0}>
          <img src={value.avatar.url} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
      ) : (
        <>
          <SvgIcon variant="avatar" size="24px" color={Colors.base.middleGray} />
          <Box width="6px" />
        </>
      )}
      <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px" noWrap>
        {value.displayName}
      </Typography>
    </Box>
  )
}
