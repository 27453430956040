/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ContactSettingFromJSON, ContactSettingToJSON } from './ContactSetting';
export function GetContactSettingsResponseDataFromJSON(json) {
    return GetContactSettingsResponseDataFromJSONTyped(json, false);
}
export function GetContactSettingsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        activeContactSettings: !exists(json, 'active_contact_settings')
            ? undefined
            : json['active_contact_settings'].map(ContactSettingFromJSON),
        inactiveContactSettings: !exists(json, 'inactive_contact_settings')
            ? undefined
            : json['inactive_contact_settings'].map(ContactSettingFromJSON)
    };
}
export function GetContactSettingsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        active_contact_settings: value.activeContactSettings === undefined
            ? undefined
            : value.activeContactSettings.map(ContactSettingToJSON),
        inactive_contact_settings: value.inactiveContactSettings === undefined
            ? undefined
            : value.inactiveContactSettings.map(ContactSettingToJSON)
    };
}
