import { EnumPublicationStatus } from '@noco/http-client/lib/noco'
import { MuiIconVariant } from 'src/components/atoms'

export const publicationStatusTextMap: { [key in EnumPublicationStatus]: string } = {
  open: '誰でも',
  restricted: '限定',
  unpublished: '非公開'
}

export const publicationStatusIconTextMap: { [key in EnumPublicationStatus]: MuiIconVariant } = {
  open: 'country',
  restricted: 'lock',
  unpublished: 'cancel'
}
