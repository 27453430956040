/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateMeAvatarRequestUserFromJSON, UpdateMeAvatarRequestUserToJSON } from './UpdateMeAvatarRequestUser';
export function UpdateOrganizationAvatarRequestFromJSON(json) {
    return UpdateOrganizationAvatarRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationAvatarRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organization: !exists(json, 'organization') ? undefined : UpdateMeAvatarRequestUserFromJSON(json['organization'])
    };
}
export function UpdateOrganizationAvatarRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization: UpdateMeAvatarRequestUserToJSON(value.organization)
    };
}
