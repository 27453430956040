/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationPaymentLimit
 */
export interface OrganizationPaymentLimit {
  /**
   *
   * @type {boolean}
   * @memberof OrganizationPaymentLimit
   */
  finite: boolean
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentLimit
   */
  value: number | null
}

export function OrganizationPaymentLimitFromJSON(json: any): OrganizationPaymentLimit {
  return OrganizationPaymentLimitFromJSONTyped(json, false)
}

export function OrganizationPaymentLimitFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPaymentLimit {
  if (json === undefined || json === null) {
    return json
  }
  return {
    finite: json['finite'],
    value: json['value']
  }
}

export function OrganizationPaymentLimitToJSON(value?: OrganizationPaymentLimit | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    finite: value.finite,
    value: value.value
  }
}
