import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Tooltip } from 'src/components/atoms'
import { UserName, Avatar, Email, PasswordRow, Logout } from './UserInfoParts'
import { permissionTextMap } from 'src/fixtures/modules/user/utils'
import { EnumUserPermission } from '@noco/http-client/lib/noco'

export interface UserInfoProps {
  lastName: string
  firstName: string
  avatar?: string
  email: string
  permission?: string
}

export const UserInfo = ({ lastName, firstName, avatar, email, permission }: UserInfoProps) => {
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <UserName firstName={firstName} lastName={lastName} />
      <Avatar avatarUrl={avatar} />
      <Email email={email} />
      <PasswordRow />
      <Box borderBottom={`1px solid ${Colors.background.silver}`} py="16px">
        <Box display="flex" alignItems="center" height="34px">
          <Box display="flex" alignItems="center" width="150px">
            <Typography variant="h5">権限</Typography>
            <Box mr="6px" />
            <Tooltip
              content={
                'あなたの管理権限です。それぞれの管理権限は、管理者のみが「チーム設定」>「ユーザー管理」から変更できます。'
              }
            >
              <DynamicMuiIcon variant="helpRounded" color="action" size="16px" />
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            {permissionTextMap[permission as EnumUserPermission]}
          </Box>
        </Box>
      </Box>
      <Logout />
    </Box>
  )
}
