import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Input, SelectBox, Colors, Password, Label } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { OptionListType, Chip, Id, Typography } from 'src/components/atoms'
import { useInviteOrganization } from 'src/fixtures/modules/organization/hooks'
import { EnumUserPermission, OrganizationPlanDetail } from '@noco/http-client/lib/noco'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { EmailEventValueType, FormValues } from './type'
import { pushUserActionDataLayer } from 'src/utils/gtm'

interface ModalInvitationMemberProps {
  open: boolean
  plan?: OrganizationPlanDetail
  onClose: () => void
  onCancel: () => void
  onClickSettings?: () => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    chipBox: {
      minHeight: '70px',
      border: `1px solid ${Colors.background.silver}`,
      '&:focus-within': {
        border: `1px solid ${Colors.accent.keyPurple.default} !important`
      }
    },
    onChip: {
      cursor: 'pointer'
    }
  })
})

export const ModalInvitationMember = ({
  open,
  plan,
  onClose,
  onCancel,
  onClickSettings
}: ModalInvitationMemberProps) => {
  const classes = useStyles()
  const [email, setEmail] = useState<EmailEventValueType>('')
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [emailList, setEmailList] = useState<string[]>([])
  const [composing, setComposing] = useState(false)
  const { handleInvite, error, isFirstInvitedUser } = useInviteOrganization()
  const emailRegex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

  const invitationValidationSchema = yup.lazy(values => {
    if (values.permission === EnumUserPermission.Administrator) {
      return yup.object().shape({
        emailList: yup.array().of(
          yup.object().shape({
            email: yup.string().email('メールアドレスの形式で入力してください').required('メールアドレスは必須です')
          })
        ),
        permission: yup.string(),
        password: yup.string().required('この必須項目を入力してください。')
      })
    } else {
      return yup.object().shape({
        emailList: yup.array().of(
          yup.object().shape({
            email: yup.string().email('メールアドレスの形式で入力してください').required('メールアドレスは必須です')
          })
        ),
        permission: yup.string()
      })
    }
  })

  const initialValues: FormValues = {
    emailList: [],
    permission: plan?.canManageUserPermission ? EnumUserPermission.Member : EnumUserPermission.Administrator,
    password: undefined
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: invitationValidationSchema,
    onSubmit: async values => {
      await handleInvite(
        emailList.map(email => ({
          email,
          permission: values.permission,
          password: values.password
        }))
      )
      setEmailList([])
      onClose?.()
    }
  })

  const optionList = useMemo<OptionListType[]>(() => {
    if (plan?.canManageUserPermission) {
      return [
        { value: EnumUserPermission.Administrator, label: '管理者' },
        { value: EnumUserPermission.Manager, label: 'マネージャー' },
        { value: EnumUserPermission.Member, label: 'メンバー' },
        { value: EnumUserPermission.Assistant, label: 'アシスタント' }
      ]
    } else {
      return [{ value: EnumUserPermission.Administrator, label: '管理者' }]
    }
  }, [plan?.canManageUserPermission])

  const cancelAndResetValues = useCallback(() => {
    onCancel()
    setEmailList([])
  }, [onCancel])

  const closeAndResetValues = useCallback(() => {
    onClose()
    setEmailList([])
  }, [onClose])

  const removeEmail = useCallback(
    (value: Id) => {
      const newEmailList = emailList.filter(email => email !== value)
      setEmailList(newEmailList)
    },
    [emailList]
  )

  useEffect(() => {
    if (!email && !error) setEmailInvalid(false)
  }, [email, setEmail, error])

  const handleAddUser = useCallback(() => {
    if (!email) return
    // * 入力されたEmailを配列に変換(カンマ、スペース区切り)
    const splitEmailArr = email.replace(/,|\s+/g, ' ').split(' ')
    // * Regex通過したEmailの配列
    const passedEmailArr: string[] = []
    // * Regex通過しなかったEmailの配列
    const failedEmailArr: string[] = []
    // * 配列の要素１つずつにRegexを実行
    splitEmailArr.forEach(email => {
      if (emailRegex.test(email.trim())) {
        passedEmailArr.push(email.trim())
      } else {
        failedEmailArr.push(email.trim())
      }
    })
    // * 既存のemailListとRegexを通過した配列を合わせて重複を削除
    const newEmailArr = Array.from(new Set([...emailList, ...passedEmailArr]))
    // * Regexに通過しなかったEmailListを文字列に変換(スペース区切り)
    const regexFailedEmail = failedEmailArr.length > 0 ? failedEmailArr.join(' ') : ''
    setEmailList(newEmailArr)
    setEmail(regexFailedEmail.trim())
    setEmailInvalid(Boolean(failedEmailArr.length))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, emailList])

  const handleChangePermission = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      if (e.target.value !== EnumUserPermission.Administrator) {
        formik.setFieldValue('password', undefined)
      }
      formik.setFieldValue('permission', e.target.value)
    },
    [formik]
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      // * 入力変換が完了してからEnterを押す
      if (!composing && e.key === 'Enter') {
        handleAddUser()
      } else if (!email && e.key === 'Backspace') {
        // * inputが空の場合にemailListの末尾の要素を削除
        const lastEmail = emailList.slice(-1)[0]
        const removedEmailList = emailList.filter(email => email !== lastEmail)
        setEmailList(removedEmailList)
      } else {
        return
      }
    },
    [composing, email, handleAddUser, emailList]
  )

  useEffect(() => {
    if (isFirstInvitedUser) {
      pushUserActionDataLayer('user', 'invite', 'first_invited_user')
    }
  }, [isFirstInvitedUser])

  return (
    <Modal open={open} onClose={closeAndResetValues} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="ユーザーを招待"
            onClose={closeAndResetValues}
            onCancel={cancelAndResetValues}
            onConfirm={() => {
              formik.handleSubmit()
            }}
            onClickSettings={onClickSettings}
            hideSettings
          >
            <Box bgcolor={Colors.functional.background.default} height={1} padding="24px">
              <Box mb="18px">
                <Typography fontSize="s" lh="relaxed">
                  改行することで、複数人まとめて招待できます
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" mb="20px">
                <Box mb="4px">
                  <Typography fontSize="s" fontWeight="bold">
                    送信先
                  </Typography>
                </Box>
                <Box borderRadius="4px" className={classes.chipBox} onKeyDown={handleKeyDown}>
                  {emailList.length > 0 && (
                    <Box pt="5px" display="flex" flexWrap="wrap">
                      {emailList.map((email, i) => {
                        return <Chip key={i} onClose={removeEmail} label={email} id={email} />
                      })}
                    </Box>
                  )}
                  <Input
                    borderRadius="4px"
                    fullWidth
                    name="emailAddress"
                    onChange={e => setEmail(e.target.value as EmailEventValueType)}
                    onBlur={handleAddUser}
                    value={email}
                    borderStyle="none"
                    placeholder="招待したいユーザーのメールアドレスを入力"
                    boxShadow="none"
                    padding="0 8px"
                    onCompositionStart={e => setComposing(e.isTrusted)}
                    onCompositionEnd={e => setComposing(e.isTrusted)}
                  />
                </Box>
                {error || emailInvalid ? (
                  <>
                    <Box mt="8px" />
                    <Typography variant="caption" fontSize="xs" style={{ color: Colors.error.default }}>
                      {error && formik.errors.emailList}
                      {emailInvalid && 'メールアドレスの形式で入力してください'}
                    </Typography>
                  </>
                ) : null}
              </Box>
              <Box display="flex" flexDirection="column" mb="16px">
                <Box mb="4px">
                  <Typography fontSize="s" fontWeight="bold">
                    管理権限
                  </Typography>
                </Box>
                <SelectBox
                  height="34px"
                  listWidth="328px"
                  placeholder="選択してください"
                  optionList={optionList}
                  name="permission"
                  value={formik.values.permission}
                  onChange={handleChangePermission}
                />
              </Box>
              {formik.values.permission === EnumUserPermission.Administrator && (
                <>
                  <Box display="flex" alignItems="center" mb="4px">
                    <Typography fontSize="s" fontWeight="bold">
                      ログインパスワードを入力
                    </Typography>
                    <Box mr="6px" />
                    <Label type="negative" labelText="必須" size="small" />
                  </Box>
                  <Password
                    name="password"
                    fullWidth
                    password={formik.values.password!}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password ? formik.errors.password : undefined}
                  />
                </>
              )}
              <Box>
                <Typography fontSize="s" lh="relaxed">
                  ※ 招待リンクの有効期限は24時間です
                </Typography>
              </Box>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
