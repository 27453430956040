import { ToastOptions } from 'react-toastify'

export const initialStyles: ToastOptions['style'] = {
  backgroundColor: 'black',
  padding: '0px 16px',
  minWidth: '200px',
  minHeight: '60px',
  fontSize: '16px',
  fontFamily: [
    '"游ゴシック体"',
    'YuGothic',
    '"游ゴシック"',
    'Yu Gothic',
    '"Roboto"',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(',')
}
