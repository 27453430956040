import { Box, Divider, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Board, Button, Typography } from 'src/components/atoms'
import { Colors } from 'src/components/atoms'
import { BoardItem, BoardItemProps } from './BoardItem'

export interface RestrictedBoardProps {
  onClick: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      margin: '32px 0',
      backgroundColor: Colors.background.gray
    }
  })
)

const dataLimitFeatureBoardItems: Pick<BoardItemProps, 'text' | 'icon'>[] = [
  { text: `利用ユーザー数\n1名だけご利用可能`, icon: 'user' },
  { text: `メールテンプレート\n１つだけご利用可能`, icon: 'mail' },
  { text: `チャット履歴\n60日分のみ閲覧可能`, icon: 'chat' }
]

const unavailableFeatureBoardItems: Pick<BoardItemProps, 'text' | 'icon'>[] = [
  { text: `資料未閲覧者への\n追客メール`, icon: 'rocket' },
  { text: `資料ダウンロード・\n印刷の設定`, icon: 'material' },
  { text: `資料の閲覧期限`, icon: 'calendar' },
  { text: `ユーザー権限管理`, icon: 'role' },
  { text: `インポート機能`, icon: 'inport' },
  { text: `エクスポート機能`, icon: 'export' },
  { text: `カスタムブランディング`, icon: 'customBranding' },
  { text: `広告の非表示`, icon: 'ad' }
]

export const RestrictedBoard = ({ onClick }: RestrictedBoardProps) => {
  const classes = useStyles()

  return (
    <Box width="100%" maxWidth="976px">
      <Board>
        <Box>
          <Box mb="16px" textAlign="center">
            <Typography fontSize="l" letterSpacing="default" fontWeight="bold" lineheight="27px">
              データ制限がかかる機能
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: '678px',
              display: 'grid',
              gridTemplateColumns: 'repeat(3, minmax(auto, 210px))',
              gridGap: '24px',
              margin: '0 auto'
            }}
          >
            {dataLimitFeatureBoardItems.map((item, i) => (
              <BoardItem key={i} text={item.text} icon={item.icon} />
            ))}
          </Box>
          <Divider classes={{ root: classes.divider }} />
          <Box mb="16px" textAlign="center">
            <Typography fontSize="l" letterSpacing="default" fontWeight="bold" lineheight="27px">
              ご利用できない機能
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))',
              gridGap: '24px',
              marginBottom: '32px'
            }}
          >
            {unavailableFeatureBoardItems.map((item, i) => (
              <BoardItem key={i} text={item.text} icon={item.icon} />
            ))}
          </Box>
          <Box display="flex" justifyContent="center">
            <Button title="無料プランを利用する" width="300px" size="large" onClick={onClick}></Button>
          </Box>
        </Box>
        <Box></Box>
      </Board>
    </Box>
  )
}
