import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import {
  MenuListItem,
  MenuListItemProps,
  Colors,
  DynamicMuiIcon,
  Pallete,
  MenuListItemChatType,
  Typography,
  ButtonIcon,
  SvgIcon
} from 'src/components/atoms'
import styled, { css } from 'styled-components'
import { ChatRoom, AppointmentList } from 'src/components/organisms'
import { ChatMessage, Document, Organization, OrganizationChatSetting, Visitor } from '@noco/http-client/lib/noco'
import Image from 'next/image'

const SlideInContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${Pallete.functional.background.default};
  ${({ isVisible }) =>
    !isVisible &&
    css`
      transition: transform 250ms ease-in-out;
      height: 0;
      opacity: 1;
      transform: translateX(100%);
    `};
  ${({ isVisible }) =>
    isVisible &&
    css`
      transition: transform 250ms ease-in-out 150ms;
      height: 100%;
      opacity: 1;
      transform: translateX(0);
    `};
`

export type ChatContentMenuType = 'chat' | 'appointment' | null

export interface ChatContentProps {
  companyName: string
  document: Document
  siteId?: string
  onClose: () => void
  onBack: () => void
  menuList: MenuListItemProps[]
  selectedMenu: ChatContentMenuType
  selectChatRoom: (menu: ChatContentMenuType) => void
  visitor?: Visitor
  organizationChatSetting?: OrganizationChatSetting
  organization?: Organization
  chatMessages?: ChatMessage[]
}

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: 'inherit',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
)

export const ChatContent = ({
  document,
  selectedMenu,
  selectChatRoom,
  companyName,
  onClose,
  onBack,
  menuList,
  visitor,
  siteId,
  organizationChatSetting,
  organization,
  chatMessages = []
}: ChatContentProps) => {
  const classes = useStyles()
  const onSettingNegotiationOpportunity = useCallback(() => {
    selectChatRoom('appointment')
  }, [selectChatRoom])

  const [hasChatMessage, setHasChatMessage] = useState(false)

  const handleBack = useCallback(() => {
    if (
      !hasChatMessage ||
      (hasChatMessage && window.confirm('メッセージがまだ送信されていません。ページを離れますか？'))
    ) {
      onBack()
    }
  }, [hasChatMessage, onBack])

  const handleClose = useCallback(() => {
    if (
      !hasChatMessage ||
      (hasChatMessage && window.confirm('メッセージがまだ送信されていません。チャットを閉じますか？'))
    ) {
      onClose()
    }
  }, [hasChatMessage, onClose])
  return (
    <Box display="flex" flexDirection="column" overflow="hidden" height={1} minHeight={1}>
      <Box
        width={1}
        minWidth={360}
        className="handle"
        style={{ cursor: 'grab' }}
        px={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py="10px"
        borderBottom={`1px solid ${Colors.background.gray}`}
      >
        <Box display="flex" alignItems="center">
          {selectedMenu ? (
            <Box mr="8px" width={104}>
              <ButtonIcon
                style={{ paddingLeft: '0px' }}
                iconBefore={<SvgIcon variant="leftArrow" size="14px" color={Colors.base.middleGray} />}
                onClick={handleBack}
              >
                <Box color={Colors.base.middleGray}>
                  <Typography variant="h5">戻る</Typography>
                </Box>
              </ButtonIcon>
            </Box>
          ) : (
            // カスタムブランディング実装
            <Box mr="8px" display={'flex'} alignItems="center">
              {!organization?.hideAd ? (
                <img
                  src="/images/nocosell_logo.svg"
                  alt="nsロゴ"
                  style={{ objectFit: 'contain', maxWidth: '40px', height: '40px' }}
                />
              ) : (
                organization.avatar?.url && (
                  <img
                    src={organization.avatar.url!}
                    alt="カスタムロゴ"
                    style={{ objectFit: 'contain', maxWidth: '40px', height: '40px' }}
                  />
                )
              )}
            </Box>
          )}
          <Box width={1}>
            <Typography variant="h5">{companyName}</Typography>
          </Box>
        </Box>
        <ButtonBase color={Colors.base.middleGray} onClick={handleClose} className="cancel">
          <DynamicMuiIcon variant="close" color="inherit" size="20px" />
        </ButtonBase>
      </Box>
      <Box overflow="auto" height={1}>
        <SlideInContainer isVisible={!!selectedMenu}>
          {selectedMenu === 'chat' && (
            <ChatRoom
              document={document}
              siteId={siteId}
              chatMessages={chatMessages ?? []}
              isAvailableSettingNegotiationOpportunity={document.documentOpportunity?.isView}
              visitor={visitor}
              onSettingNegotiationOpportunity={onSettingNegotiationOpportunity}
              isPreview={!visitor}
              organizationChatSetting={organizationChatSetting}
              organization={organization}
              onChangeFormMessage={message => {
                setHasChatMessage(!!message)
              }}
            />
          )}
          {selectedMenu === 'appointment' && <AppointmentList documentId={String(document.id)} siteId={siteId} />}
        </SlideInContainer>

        {!selectedMenu && (
          <Box overflow="auto" px={4} pt="20px">
            {menuList.map((chat, i) => {
              if (chat.kind === 'chat' && !document.documentChatSetting?.canChat) return <></>
              if (chat.kind === 'chat' && chatMessages[chatMessages.length - 1]) {
                const chatMessage = chatMessages[chatMessages.length - 1]
                return (
                  <Box key={i} mb="12px">
                    <MenuListItemChatType
                      chatMessage={chatMessage}
                      isEU={visitor?.contactId === chatMessage.sendedById}
                      {...chat}
                    />
                  </Box>
                )
              }
              return (
                <Box key={i} mb="12px">
                  <MenuListItem {...chat} />
                </Box>
              )
            })}
            {!organization?.hideAd && (
              <Box mt="22px">
                <a
                  href="https://noco.sale/?utm_source=noco_sales&utm_medium=service_pr&utm_campaign=message_home_bottom"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.link}
                >
                  <Image src="/images/nocosell_icon.svg" width="17px" height="17px" />
                  <Box ml="6px" />
                  <Typography fontSize="xs" letterSpacing="none" fontWeight="bold">
                    <Box color={Colors.accent.keyPurple.default}>ノコセル</Box>
                  </Typography>
                  <Typography fontSize="xs" letterSpacing="none" fontWeight="bold">
                    <Box color={Colors.base.placeHolder}>を利用しています</Box>
                  </Typography>
                </a>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
