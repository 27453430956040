import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import { LayoutCard } from 'src/components/commons/LayoutCard'
import { ResetPassword } from 'src/components/organisms'
import theme, { useGlobalStyles } from 'src/styles/theme'

export const PageResetPassword = () => {
  const globalClasses = useGlobalStyles()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <LayoutCard
      direction="rtl"
      justify="center"
      isSP={isDownSm}
      innerCardProps={{
        minHeight: !isDownSm && '85vh'
      }}
      innerCardComponent={<ResetPassword />}
      outerCardComponent={<img src="/images/reset-password.svg" className={globalClasses.imgContain} />}
    />
  )
}
