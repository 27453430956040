import { EnumUserPermission, User } from '@noco/http-client/lib/noco'

export const permissionTextMap: { [key in EnumUserPermission]: string } = {
  administrator: '管理者',
  assistant: 'アシスタント',
  manager: 'マネージャー',
  member: 'メンバー'
}

export const getUserFullName = (user?: User) => {
  return `${user?.lastName ? user.lastName : ''} ${user?.firstName ? user.firstName : ''}`
}
