import React from 'react'

import { ComposedChart, Tooltip, Legend, Line, Bar, XAxis, YAxis, CartesianGrid } from 'recharts'
import { convertMs } from 'src/fixtures/utils/time'
import { CustomTooltip, PayloadItem } from './CustomTooltip'
import { Document } from '@noco/http-client/lib/noco'

export type DataItem = {
  name: string
  vt: number
  pv: number
}

type Props = {
  data?: DataItem[]
  document?: Document
}

export const ChartViewsAndAverageViewingTimePerPage = ({ data = [], document }: Props) => {
  return (
    <ComposedChart
      width={640}
      height={470}
      data={data}
      margin={{
        top: 5,
        right: -10,
        bottom: 0,
        left: 18
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" scale="auto" />
      <YAxis yAxisId="viewTime" type="number" dataKey="vt" name="weight" tickFormatter={value => convertMs(value)} />
      <YAxis
        yAxisId="pageViews"
        type="number"
        dataKey="pv"
        name="weight"
        orientation="right"
        tickFormatter={value => `${value}回`}
      />
      <Tooltip
        content={props => (
          <CustomTooltip
            {...props}
            payload={props.payload as PayloadItem[] | undefined}
            slideImages={document?.currentSlide?.slideImages || []}
          />
        )}
        isAnimationActive={false}
      />
      <Legend />
      {/* TODO: 正式版以降 @toshi1127 色を定数化 */}
      <Bar yAxisId="viewTime" name="閲覧時間" dataKey="vt" barSize={10} fill="#FFA216" />
      <Line yAxisId="pageViews" name="閲覧数" type="monotone" dataKey="pv" stroke="#F153A9" />
    </ComposedChart>
  )
}
