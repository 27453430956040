import { Box, BoxProps } from '@material-ui/core'
import React, { ReactNode, VFC } from 'react'
import { Colors } from 'src/components/atoms'

export interface ShareLinkFormSectionProps {
  children: ReactNode
  mb?: BoxProps['mb']
  p?: BoxProps['p']
}
export const ShareLinkFormSection: VFC<ShareLinkFormSectionProps> = ({ children, mb, p = '16px' }) => {
  return (
    <Box
      component="section"
      bgcolor={Colors.background.lightGray}
      border={`1px solid ${Colors.background.gray}`}
      borderRadius="4px"
      p={p}
      mb={mb}
      overflow="hidden"
    >
      {children}
    </Box>
  )
}
