/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ContactSettingFieldItem
 */
export interface ContactSettingFieldItem {
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldItem
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldItem
   */
  name?: string
  /**
   *
   * @type {boolean}
   * @memberof ContactSettingFieldItem
   */
  isInputText?: boolean
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldItem
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingFieldItem
   */
  updatedAt?: string
}

export function ContactSettingFieldItemFromJSON(json: any): ContactSettingFieldItem {
  return ContactSettingFieldItemFromJSONTyped(json, false)
}

export function ContactSettingFieldItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSettingFieldItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    isInputText: !exists(json, 'is_input_text') ? undefined : json['is_input_text'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function ContactSettingFieldItemToJSON(value?: ContactSettingFieldItem | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    is_input_text: value.isInputText,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
