import { Box, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors, DynamicMuiIcon } from 'src/components/atoms'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import { useGlobalStyles } from 'src/styles/theme'
import { UserForDisplay } from '@noco/http-client/lib/noco'

export interface UpdateInformationProps {
  createdAt: string | number
  createdBy: UserForDisplay
  updatedAt?: string | number
  updatedBy?: UserForDisplay
  createdLabel?: string
}

export const UpdateInformation = ({
  createdAt,
  createdBy,
  updatedAt,
  updatedBy,
  createdLabel = '登録日'
}: UpdateInformationProps) => {
  const globalClasses = useGlobalStyles()
  const dataList = useMemo(() => {
    let data = [{ label: createdLabel, date: createdAt, user: createdBy }]
    if (updatedAt && updatedBy) {
      data.push({ label: '更新日', date: updatedAt, user: updatedBy })
    }
    return data
  }, [createdAt, createdBy, updatedAt, updatedBy, createdLabel])

  return (
    <Box>
      {dataList.map((data, i) => {
        return (
          <Box key={data.label} display="flex" alignItems="center" mb={i !== dataList.length && '4px'}>
            <Box color={Colors.base.middleGray} mr="6px" flexShrink={0}>
              <Typography variant="subtitle2">{data.label}</Typography>
            </Box>
            {data.date && (
              <Box flexShrink={0} mr="6px">
                <Typography variant="body2">
                  {format(new Date(data.date), 'yyyy/MM/dd (E) HH:mm', { locale: ja })}
                </Typography>
              </Box>
            )}
            {data.user ? (
              <>
                <Box width="18px" height="18px" borderRadius="50%" overflow="hidden" mr="4px" flexShrink={0}>
                  {data.user?.avatar?.url ? (
                    <img
                      src={data.user?.avatar?.url}
                      alt="avatar"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  ) : (
                    <DynamicMuiIcon variant="account" size="18px" color="action" />
                  )}
                </Box>
                <Typography variant="body2" className={globalClasses.lineclamp1}>
                  {data.user.displayName}
                </Typography>
              </>
            ) : (
              <Typography variant="body2">-</Typography>
            )}
          </Box>
        )
      })}
    </Box>
  )
}
