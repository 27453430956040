/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { UploadedFile, UploadedFileFromJSON, UploadedFileFromJSONTyped, UploadedFileToJSON } from './UploadedFile'

/**
 *
 * @export
 * @interface EuSiteSectionDocumentDocument
 */
export interface EuSiteSectionDocumentDocument {
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocumentDocument
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocumentDocument
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocumentDocument
   */
  publishedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocumentDocument
   */
  archivedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocumentDocument
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocumentDocument
   */
  updatedAt?: string
  /**
   *
   * @type {UploadedFile}
   * @memberof EuSiteSectionDocumentDocument
   */
  thumbnail?: UploadedFile | null
  /**
   *
   * @type {UploadedFile}
   * @memberof EuSiteSectionDocumentDocument
   */
  largeThumbnail?: UploadedFile | null
  /**
   *
   * @type {string}
   * @memberof EuSiteSectionDocumentDocument
   */
  publicLinkUlid?: string
}

export function EuSiteSectionDocumentDocumentFromJSON(json: any): EuSiteSectionDocumentDocument {
  return EuSiteSectionDocumentDocumentFromJSONTyped(json, false)
}

export function EuSiteSectionDocumentDocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EuSiteSectionDocumentDocument {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
    archivedAt: !exists(json, 'archived_at') ? undefined : json['archived_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    thumbnail: !exists(json, 'thumbnail') ? undefined : UploadedFileFromJSON(json['thumbnail']),
    largeThumbnail: !exists(json, 'large_thumbnail') ? undefined : UploadedFileFromJSON(json['large_thumbnail']),
    publicLinkUlid: !exists(json, 'public_link_ulid') ? undefined : json['public_link_ulid']
  }
}

export function EuSiteSectionDocumentDocumentToJSON(value?: EuSiteSectionDocumentDocument | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    title: value.title,
    published_at: value.publishedAt,
    archived_at: value.archivedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    thumbnail: UploadedFileToJSON(value.thumbnail),
    large_thumbnail: UploadedFileToJSON(value.largeThumbnail),
    public_link_ulid: value.publicLinkUlid
  }
}
