import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var OrganizationUsersApi = /** @class */ (function () {
    function OrganizationUsersApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    OrganizationUsersApi.initialize = function (basePath, fetchApi) {
        if (!OrganizationUsersApi.instance) {
            OrganizationUsersApi.instance = new OrganizationUsersApi(basePath, fetchApi);
        }
        return OrganizationUsersApi.instance;
    };
    OrganizationUsersApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1OrganizationUsersApi(new Configuration(config));
    };
    return OrganizationUsersApi;
}());
export { OrganizationUsersApi };
