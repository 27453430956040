/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateOrganizationPaymentCancelRequest
 */
export interface UpdateOrganizationPaymentCancelRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateOrganizationPaymentCancelRequest
   */
  kindList: Array<string>
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationPaymentCancelRequest
   */
  reason: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrganizationPaymentCancelRequest
   */
  consent: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationPaymentCancelRequest
   */
  password: string
}

export function UpdateOrganizationPaymentCancelRequestFromJSON(json: any): UpdateOrganizationPaymentCancelRequest {
  return UpdateOrganizationPaymentCancelRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentCancelRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentCancelRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    kindList: json['kind_list'],
    reason: json['reason'],
    consent: json['consent'],
    password: json['password']
  }
}

export function UpdateOrganizationPaymentCancelRequestToJSON(
  value?: UpdateOrganizationPaymentCancelRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    kind_list: value.kindList,
    reason: value.reason,
    consent: value.consent,
    password: value.password
  }
}
