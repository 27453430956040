import { Box, Modal, Slide } from '@material-ui/core'
import React, { useState, useCallback } from 'react'
import { Button, Typography, Label, Input, Colors } from 'src/components/atoms'
import { Organization, OrganizationPolicySetting } from '@noco/http-client/lib/noco'
import { PolicySentence } from 'src/components/molecules/PolicySentence'
import * as yup from 'yup'

export interface ModalSendEmailWithAuthenticationCodeProps {
  title: string
  onSubmit: (email: string) => void
  open: boolean
  onClose: () => void
  policySettings?: OrganizationPolicySetting[]
  organizationName?: string
  document?: Document
  organization?: Organization
}

const formValidationSchema = yup.object().shape({
  email: yup.string().email('メールアドレスの形式で入力してください').required('メールアドレスは必須です')
})

export const ModalSendEmailWithAuthenticationCode = ({
  onClose,
  onSubmit,
  policySettings,
  organizationName,
  open,
  title
}: ModalSendEmailWithAuthenticationCodeProps) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | null>(null)

  const handleValidate = useCallback(email => {
    const newEmail = email
    try {
      formValidationSchema.validateSync({ email: newEmail })
      setError(null)
    } catch (error: unknown) {
      const typedError = error as { errors: string[] }
      if (typedError.errors && typedError.errors.length > 0) setError(typedError.errors[0])
    }
    setEmail(newEmail)
  }, [])

  const handleBlur = useCallback(() => {
    const trimmedEmail = email.trim()
    handleValidate(trimmedEmail)
    setEmail(trimmedEmail)
  }, [email, handleValidate])

  return (
    <Modal
      open={open}
      onClose={onClose}
      // zIndex 暫定対応
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999999 }}
    >
      <Slide in={open} direction="up">
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mx: '12px' }}>
          <Box
            sx={{
              border: '1px solid #E9ECF1',
              boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A',
              borderRadius: '10px',
              width: '100%',
              maxWidth: '500px',
              bgcolor: 'white',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                p: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: '1px solid #DADCE0'
              }}
            >
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Box sx={{ px: '44px', py: '34px' }}>
              <Box
                sx={{
                  textAlign: 'center',
                  mb: '20px'
                }}
              >
                <Typography fontSize="s" lineheight="18.48px" fontWeight={300}>
                  本人認証のため、メールアドレスをご記入の上、送信ください
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: '8.5px' }}>
                <Typography variant="body2" fontWeight="bold">
                  メールアドレス
                </Typography>
                <Box width="12px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Input
                large
                name="email"
                placeholder="yamada@example.com"
                value={email}
                onChange={e => handleValidate(e.target.value)}
                fullWidth
                type="text"
                onBlur={handleBlur}
                error={error ? error : undefined}
              />
              <PolicySentence policySettings={policySettings} organizationName={organizationName} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: Colors.background.lightGray,
                borderRadius: '0px 0px 10px 10px',
                py: '16px'
              }}
            >
              <Button
                width="216px"
                size="large"
                title="資料を見る"
                disabled={!!error || !email}
                onClick={() => onSubmit(email)}
              />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
