import { Box } from '@material-ui/core'
import React, { useMemo, useCallback, useState } from 'react'
import { Colors } from 'src/components/atoms'
import { Table, TableProps } from 'src/components/organisms'
import { Menu } from 'src/components/pages/PageInbox/Menu'
import { ShowDialog } from 'src/components/pages/PageInbox/ShowDialog'
import { TabValueType, SelectedCondition } from './'
import {
  InboxFilter,
  FilterStaffPins1,
  ChatSummary,
  GetMeResponseData,
  EmailTransaction
} from '@noco/http-client/lib/noco'
import {
  NameCell,
  ContactCompanyNameCell,
  MessageCell,
  RepliedStatusCell,
  ReceivedDatetimeCell,
  SenderNameCell
} from 'src/components/organisms/Table/TableParts'
import { GetRequest } from 'src/components/modals/ModalInboxFilter'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import dynamic from 'next/dynamic'
const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table
const ModalClientDetail = dynamic(() => import('../../modals/ModalClientDetail'), { ssr: false })

type ChatList = ChatSummary & EmailTransaction

export interface InboxContainerProps {
  openModalInboxFilter?: boolean
  onSetOpenModalInboxFilter?: (value: boolean) => void
  filter: InboxFilter | undefined
  params: GetRequest
  onSetParams: (value: {}) => void
  allStaffList: FilterStaffPins1[] | undefined
  onStaffListsPinConfirm: (userIds: string[]) => void
  tabValue: TabValueType
  selectedCondition?: SelectedCondition | undefined
  setSelectedCondition?: (condition: SelectedCondition | undefined) => void
  chatList: ChatList[]
  loginUser: GetMeResponseData | undefined
  onClearCondition?: () => void
  page: number
  per: number
  totalPages: number
  changePage: (page: number) => void
  changePerPage: (perPage: number) => void
  resetPagination: () => void
  reload?: () => void
  numberOfRows?: number
  showNoDataImage?: string
  openModalClientDetail?: boolean
  onSetOpenModalClientDetail?: (value: boolean) => void
  contactIdForModalClientDetail?: string
  onClickRow: (contactId: string) => void
  onRefreshCount?: () => void
}

export const InboxContainer = ({
  openModalInboxFilter,
  onSetOpenModalInboxFilter,
  filter,
  params,
  onSetParams,
  allStaffList,
  onStaffListsPinConfirm,
  tabValue,
  selectedCondition,
  setSelectedCondition,
  chatList,
  loginUser,
  onClearCondition,
  page,
  per,
  totalPages,
  changePage,
  changePerPage,
  resetPagination,
  reload,
  numberOfRows,
  showNoDataImage,
  openModalClientDetail,
  onSetOpenModalClientDetail,
  contactIdForModalClientDetail,
  onClickRow,
  onRefreshCount
}: InboxContainerProps) => {
  const [selection, setSelection] = useState<Array<number>>([])
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const handleClose = useCallback(() => {
    // reload the data
    reload?.()
    onSetOpenModalClientDetail?.(false)
  }, [reload, onSetOpenModalClientDetail])

  const [chatSummaryId, setChatSummaryId] = useState<string | undefined>()

  const customChatList = useMemo(
    () =>
      chatList?.map(list => {
        return {
          ...list,
          user: list.contact?.user,
          sender: tabValue === 'error' ? list.user : list.chatMessages?.[0].sendedBy,
          contact: tabValue === 'error' ? { ...list.contact, company: list.company } : list.contact,
          chatMessages:
            tabValue === 'error'
              ? [{ message: list.subject, materialable: { title: list.materialable?.displayName } }]
              : list.chatMessages,
          createdAt: tabValue === 'error' ? list.createdAt : list.chatMessages?.[0].createdAt
        }
      }) ?? [],
    [chatList, tabValue]
  )

  const selectedIds = useMemo(() => {
    return customChatList
      ? selection.map(rowNumber => {
          return customChatList[rowNumber].id as string
        })
      : []
  }, [selection, customChatList])

  const inBoxColumns = [
    {
      name: 'contact',
      title: '名前',
      width: 185,
      render: ContactCompanyNameCell
    },
    {
      name: 'chatMessages',
      width: 370,
      title: 'メッセージ',
      render: MessageCell
    },
    {
      name: 'repliedStatus',
      width: 110,
      title: 'ステータス',
      render: RepliedStatusCell
    },
    {
      name: 'user',
      width: 137,
      title: '担当者',
      render: ({ row }: DataTypeProvider.ValueFormatterProps) => <NameCell value={row.user} iconSize="large" />
    },
    { name: 'createdAt', width: 168, title: '受信日時', render: ReceivedDatetimeCell }
  ]

  const sendOrErrorColumns = [
    {
      name: 'contact',
      title: '名前',
      width: 185,
      render: ContactCompanyNameCell
    },
    {
      name: 'chatMessages',
      width: 370,
      title: 'メッセージ',
      render: MessageCell
    },
    { name: 'sender', width: 135, title: '送信者', render: SenderNameCell },
    {
      name: 'user',
      width: 137,
      title: '担当者',
      render: ({ row }: DataTypeProvider.ValueFormatterProps) => <NameCell value={row.user} iconSize="large" />
    },
    { name: 'createdAt', width: 168, title: '送信日時', render: ReceivedDatetimeCell }
  ]

  return (
    <>
      <Box display="flex" height="100%">
        <Box display="flex" flexDirection="column">
          <Box
            pt="14px"
            pr="14px"
            width="191px"
            bgcolor={Colors.functional.background.default}
            overflow="auto"
            flexShrink={0}
            flex={1}
          >
            <Menu
              open={openModalInboxFilter}
              tabValue={tabValue}
              onOpen={() => onSetOpenModalInboxFilter?.(true)}
              filter={filter || {}}
              params={params}
              onSetParams={onSetParams}
              allStaffList={allStaffList}
              onStaffListsPinConfirm={onStaffListsPinConfirm}
              selectedCondition={selectedCondition}
              setSelectedCondition={setSelectedCondition}
              loginUser={loginUser}
              onClearCondition={onClearCondition}
              resetPagination={resetPagination}
              selectedIds={selectedIds}
            />
          </Box>
          <Box height="34px" bgcolor={Colors.background.lightGray} />
        </Box>
        <Box flex={1} height="100%" overflow="auto" pt="14px" bgcolor="#fff">
          {!isLoadingTable && (
            <DynamicTable
              selectable
              columns={tabValue === 'incoming' ? inBoxColumns : sendOrErrorColumns}
              rows={customChatList}
              onClickRow={row => {
                setChatSummaryId(row.id)
                onClickRow(row.contact?.id!)
              }}
              padding={false}
              showTotalCount
              rowHeight="60px"
              page={page}
              per={per}
              totalPages={totalPages}
              changePage={page => {
                changePage(page)
                setSelection([])
              }}
              changePerPage={perPage => {
                changePerPage(perPage)
                setSelection([])
              }}
              numberOfRows={numberOfRows}
              showNoDataImage={showNoDataImage}
              selection={selection}
              setSelection={setSelection as TableProps<ChatList>['setSelection']}
            />
          )}
        </Box>
        <ShowDialog
          selectedIds={selectedIds}
          setSelection={setSelection}
          tabValue={tabValue}
          params={params}
          page={page}
          per={per}
          value={loginUser?.user?.id!}
          onStartLoading={() => setIsLoadingTable(true)}
          onEndLoading={() => setIsLoadingTable(false)}
        />
      </Box>
      <ModalClientDetail
        chatSummaryId={chatSummaryId}
        open={openModalClientDetail!}
        contactId={contactIdForModalClientDetail!}
        onClose={() => {
          handleClose()
          setChatSummaryId(undefined)
        }}
        onRefreshCount={onRefreshCount}
      />
    </>
  )
}
