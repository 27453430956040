/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationUserRequestUser,
  UpdateOrganizationUserRequestUserFromJSON,
  UpdateOrganizationUserRequestUserFromJSONTyped,
  UpdateOrganizationUserRequestUserToJSON
} from './UpdateOrganizationUserRequestUser'

/**
 *
 * @export
 * @interface UpdateOrganizationUserRequest
 */
export interface UpdateOrganizationUserRequest {
  /**
   *
   * @type {UpdateOrganizationUserRequestUser}
   * @memberof UpdateOrganizationUserRequest
   */
  user?: UpdateOrganizationUserRequestUser
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationUserRequest
   */
  password?: string | null
}

export function UpdateOrganizationUserRequestFromJSON(json: any): UpdateOrganizationUserRequest {
  return UpdateOrganizationUserRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationUserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationUserRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    user: !exists(json, 'user') ? undefined : UpdateOrganizationUserRequestUserFromJSON(json['user']),
    password: !exists(json, 'password') ? undefined : json['password']
  }
}

export function UpdateOrganizationUserRequestToJSON(value?: UpdateOrganizationUserRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user: UpdateOrganizationUserRequestUserToJSON(value.user),
    password: value.password
  }
}
