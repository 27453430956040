/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ChangeUserContactsEmailTransactionsRequestToJSON, ChangeUserContactsEmailTransactionsResponseFromJSON, GetContactsEmailTransactionsSendErrorsResponseFromJSON, GetCountContactsEmailTransactionsResponseFromJSON, IgnoreErrorsContactsEmailTransactionsRequestToJSON, IgnoreErrorsContactsEmailTransactionsResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ContactEmailTransactionApi extends runtime.BaseAPI {
    /**
     * 送信エラーになっているメールのコンタクトの担当者の変更
     * change_user
     */
    async userV1ContactsEmailTransactionsChangeUserPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/email_transactions/change_user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeUserContactsEmailTransactionsRequestToJSON(requestParameters.changeUserContactsEmailTransactionsRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ChangeUserContactsEmailTransactionsResponseFromJSON(jsonValue));
    }
    /**
     * 送信エラーになっているメールのコンタクトの担当者の変更
     * change_user
     */
    async userV1ContactsEmailTransactionsChangeUserPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactsEmailTransactionsChangeUserPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクトへの送信エラーのメールを無視する
     * count
     */
    async userV1ContactsEmailTransactionsCountGetRaw(requestParameters, initOverrides) {
        if (requestParameters.sendStatus === null || requestParameters.sendStatus === undefined) {
            throw new runtime.RequiredError('sendStatus', 'Required parameter requestParameters.sendStatus was null or undefined when calling userV1ContactsEmailTransactionsCountGet.');
        }
        const queryParameters = {};
        if (requestParameters.sendStatus !== undefined) {
            queryParameters['send_status'] = requestParameters.sendStatus;
        }
        if (requestParameters.sendUserIds) {
            queryParameters['send_user_ids[]'] = requestParameters.sendUserIds;
        }
        if (requestParameters.documentIds) {
            queryParameters['document_ids[]'] = requestParameters.documentIds;
        }
        if (requestParameters.siteIds) {
            queryParameters['site_ids[]'] = requestParameters.siteIds;
        }
        if (requestParameters.sendedAtOnFrom !== undefined) {
            queryParameters['sended_at_on_from'] = requestParameters.sendedAtOnFrom;
        }
        if (requestParameters.sendedAtOnTo !== undefined) {
            queryParameters['sended_at_on_to'] = requestParameters.sendedAtOnTo;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/email_transactions/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetCountContactsEmailTransactionsResponseFromJSON(jsonValue));
    }
    /**
     * コンタクトへの送信エラーのメールを無視する
     * count
     */
    async userV1ContactsEmailTransactionsCountGet(requestParameters, initOverrides) {
        const response = await this.userV1ContactsEmailTransactionsCountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクトへの送信エラーのメールを無視する
     * ignore_errors
     */
    async userV1ContactsEmailTransactionsIgnoreErrorsPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/email_transactions/ignore_errors`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IgnoreErrorsContactsEmailTransactionsRequestToJSON(requestParameters.ignoreErrorsContactsEmailTransactionsRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => IgnoreErrorsContactsEmailTransactionsResponseFromJSON(jsonValue));
    }
    /**
     * コンタクトへの送信エラーのメールを無視する
     * ignore_errors
     */
    async userV1ContactsEmailTransactionsIgnoreErrorsPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactsEmailTransactionsIgnoreErrorsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 送信エラーの一覧取得
     */
    async userV1ContactsEmailTransactionsSendErrorsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.sendUserIds) {
            queryParameters['send_user_ids[]'] = requestParameters.sendUserIds;
        }
        if (requestParameters.documentIds) {
            queryParameters['document_ids[]'] = requestParameters.documentIds;
        }
        if (requestParameters.siteIds) {
            queryParameters['site_ids[]'] = requestParameters.siteIds;
        }
        if (requestParameters.sendedAtOnFrom !== undefined) {
            queryParameters['sended_at_on_from'] = requestParameters.sendedAtOnFrom;
        }
        if (requestParameters.sendedAtOnTo !== undefined) {
            queryParameters['sended_at_on_to'] = requestParameters.sendedAtOnTo;
        }
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/email_transactions/send_errors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactsEmailTransactionsSendErrorsResponseFromJSON(jsonValue));
    }
    /**
     * 送信エラーの一覧取得
     */
    async userV1ContactsEmailTransactionsSendErrorsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactsEmailTransactionsSendErrorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
