import {
  UserV1ContactsEmailTransactionsSendErrorsGetRequest,
  UserV1ContactsEmailTransactionsCountGetRequest,
  EnumEmailTransactionableSendStatus
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import useSWR from 'swr'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'
import { useGetMe } from '../me/hooks'
import { SWRConfiguration } from 'swr/dist/types'

export const useListSendErrors = (
  params: UserV1ContactsEmailTransactionsSendErrorsGetRequest,
  config?: SWRConfiguration
) => {
  const { data: auth } = useAuthenticate()
  const sendErrorsApi = nocoSDK.client?.userService.contactEmailTransactionApi
  const func = useCallback(async () => {
    if (!auth) return

    return await sendErrorsApi
      ?.ApiFactory(auth.token)
      .userV1ContactsEmailTransactionsSendErrorsGet(params)
      .then(res => res.data)
  }, [auth, sendErrorsApi, params])
  return useSWR(auth?.token && SWRCachePath.listSendErrors(params), func, config)
}

export const useListSendErrorsCount = (
  params: UserV1ContactsEmailTransactionsCountGetRequest,
  config?: SWRConfiguration
) => {
  const { data: auth } = useAuthenticate()
  const sendErrorsApi = nocoSDK.client?.userService.contactEmailTransactionApi
  const func = useCallback(async () => {
    if (!auth) return

    return await sendErrorsApi
      ?.ApiFactory(auth.token)
      .userV1ContactsEmailTransactionsCountGet(params)
      .then(res => res.data)
  }, [auth, sendErrorsApi, params])
  return useSWR(auth?.token && SWRCachePath.listSendErrorsCount(params), func, config)
}

export const useContactsEmailTransactionsChangeUser = (
  params: UserV1ContactsEmailTransactionsSendErrorsGetRequest,
  config?: SWRConfiguration
) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListSendErrors } = useListSendErrors(params, config)
  const { page, per, ...newParams } = params
  const { mutate: mutateListSendErrorsCount } = useListSendErrorsCount(
    {
      ...newParams,
      sendStatus: EnumEmailTransactionableSendStatus.Fail
    },
    config
  )
  const sendErrorsApi = nocoSDK.client?.userService.contactEmailTransactionApi
  const handleUpdateContactsEmailTransactionsChangeUser = useCallback(
    async (ids: string[], userId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await sendErrorsApi?.ApiFactory(auth.token).userV1ContactsEmailTransactionsChangeUserPut({
          changeUserContactsEmailTransactionsRequest: { ids, userId }
        })
        await mutateListSendErrors(), mutateListSendErrorsCount()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update ContactsEmailTransactionsChangeUser' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, sendErrorsApi, me, mutateListSendErrors, mutateListSendErrorsCount]
  )

  return { error, isLoading, handleUpdateContactsEmailTransactionsChangeUser }
}

export const useContactsEmailTransactionsIgnoreErrors = (
  params: UserV1ContactsEmailTransactionsSendErrorsGetRequest,
  config?: SWRConfiguration
) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListSendErrors } = useListSendErrors(params, config)
  const { page, per, ...newParams } = params
  const { mutate: mutateListSendErrorsCount } = useListSendErrorsCount(
    {
      ...newParams,
      sendStatus: EnumEmailTransactionableSendStatus.Fail
    },
    config
  )
  const sendErrorsApi = nocoSDK.client?.userService.contactEmailTransactionApi

  const handleUpdateContactsEmailTransactionsIgnoreErrors = useCallback(
    async (ids: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await sendErrorsApi?.ApiFactory(auth.token).userV1ContactsEmailTransactionsIgnoreErrorsPut({
          ignoreErrorsContactsEmailTransactionsRequest: { ids }
        })
        await mutateListSendErrors(), mutateListSendErrorsCount()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update ContactsEmailTransactionsIgnoreErrors' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, sendErrorsApi, me, mutateListSendErrors, mutateListSendErrorsCount]
  )

  return { error, isLoading, handleUpdateContactsEmailTransactionsIgnoreErrors }
}
