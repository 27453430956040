/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumMaterialableType,
  EnumMaterialableTypeFromJSON,
  EnumMaterialableTypeFromJSONTyped,
  EnumMaterialableTypeToJSON
} from './EnumMaterialableType'

/**
 *
 * @export
 * @interface FilterContentPins1
 */
export interface FilterContentPins1 {
  /**
   *
   * @type {number}
   * @memberof FilterContentPins1
   */
  counts?: number
  /**
   *
   * @type {EnumMaterialableType}
   * @memberof FilterContentPins1
   */
  materialableType?: EnumMaterialableType
  /**
   *
   * @type {string}
   * @memberof FilterContentPins1
   */
  materialableId?: string
  /**
   *
   * @type {string}
   * @memberof FilterContentPins1
   */
  text?: string
  /**
   *
   * @type {boolean}
   * @memberof FilterContentPins1
   */
  isPin?: boolean
}

export function FilterContentPins1FromJSON(json: any): FilterContentPins1 {
  return FilterContentPins1FromJSONTyped(json, false)
}

export function FilterContentPins1FromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterContentPins1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    counts: !exists(json, 'counts') ? undefined : json['counts'],
    materialableType: !exists(json, 'materialable_type')
      ? undefined
      : EnumMaterialableTypeFromJSON(json['materialable_type']),
    materialableId: !exists(json, 'materialable_id') ? undefined : json['materialable_id'],
    text: !exists(json, 'text') ? undefined : json['text'],
    isPin: !exists(json, 'is_pin') ? undefined : json['is_pin']
  }
}

export function FilterContentPins1ToJSON(value?: FilterContentPins1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    counts: value.counts,
    materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
    materialable_id: value.materialableId,
    text: value.text,
    is_pin: value.isPin
  }
}
