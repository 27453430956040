/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateMailTemplateRequestMailTemplate
 */
export interface CreateMailTemplateRequestMailTemplate {
  /**
   *
   * @type {string}
   * @memberof CreateMailTemplateRequestMailTemplate
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateMailTemplateRequestMailTemplate
   */
  subject: string
  /**
   *
   * @type {string}
   * @memberof CreateMailTemplateRequestMailTemplate
   */
  body: string
}

export function CreateMailTemplateRequestMailTemplateFromJSON(json: any): CreateMailTemplateRequestMailTemplate {
  return CreateMailTemplateRequestMailTemplateFromJSONTyped(json, false)
}

export function CreateMailTemplateRequestMailTemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateMailTemplateRequestMailTemplate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    subject: json['subject'],
    body: json['body']
  }
}

export function CreateMailTemplateRequestMailTemplateToJSON(value?: CreateMailTemplateRequestMailTemplate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    subject: value.subject,
    body: value.body
  }
}
