import { useCallback, useState } from 'react'
import { nocoSDK } from '../initialize'
import { useAuthenticate } from '../auth/hooks'
import { UserV1ChatMessagesAttachmentFilesPostRequest } from '@noco/http-client/lib/noco'
import { handleError } from 'src/fixtures/utils/error'
import { FileUploadTask } from './type'
import { AttachmentType } from 'src/components/molecules/AttachmentCard/type'

export const useCreateChatMessagesAttachmentFile = () => {
  const [fileUploadTaskList, setFileUploadTaskList] = useState<FileUploadTask[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: auth } = useAuthenticate()
  const { chatMessagesAttachmentFileApi } = nocoSDK?.client?.userService || {}

  const spliceFileUploadTaskList = useCallback(
    (baseId: string, type: AttachmentType, newFileUploadTask?: FileUploadTask) => {
      setFileUploadTaskList((previousFileUploadTaskList: FileUploadTask[]) => {
        const taskIndex = previousFileUploadTaskList.findIndex(task => task.id === baseId && task.type === type)

        if (taskIndex !== -1) {
          if (newFileUploadTask) {
            previousFileUploadTaskList.splice(taskIndex, 1, newFileUploadTask)
          } else {
            previousFileUploadTaskList.splice(taskIndex, 1)
          }
        }
        return [...previousFileUploadTaskList]
      })
    },
    []
  )

  const handleCreateChatMessagesAttachmentFile = useCallback(
    async (
      id: string,
      type: AttachmentType,
      createChatMessagesAttachmentFileRequest: UserV1ChatMessagesAttachmentFilesPostRequest
    ) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)

        const res = await chatMessagesAttachmentFileApi
          ?.ApiFactory(auth.token)
          .userV1ChatMessagesAttachmentFilesPost(createChatMessagesAttachmentFileRequest)

        const attachmentFile = res?.data?.chatMessageAttachmentFile
        if (!attachmentFile) return

        const newFileUploadTask: FileUploadTask = {
          id: attachmentFile.id,
          type,
          status: 'success',
          attachmentFile: {
            title: attachmentFile.file.filename,
            thumbnailImageUrl: attachmentFile.file.url,
            humanizedFileSize: attachmentFile.file.humanizedFileSize
          }
        }
        spliceFileUploadTaskList(id, type, newFileUploadTask)
      } catch (err) {
        await handleError(err, { setError, tag: 'Create ChatMessages Attachment File ' })
        spliceFileUploadTaskList(id, type)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, chatMessagesAttachmentFileApi, spliceFileUploadTaskList]
  )

  return { fileUploadTaskList, setFileUploadTaskList, error, isLoading, handleCreateChatMessagesAttachmentFile }
}

export const useGetChatMessagesAttachmentFileLazy = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: auth } = useAuthenticate()
  const { chatMessagesAttachmentFileApi } = nocoSDK?.client?.userService || {}

  const handleGetChatMessagesAttachmentFile = useCallback(
    async (chatMessageAttachmentFileId: string) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)

        const res = await chatMessagesAttachmentFileApi
          ?.ApiFactory(auth.token)
          .userV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGet({
            chatMessageAttachmentFileId
          })

        return res?.data?.chatMessageAttachmentFile
      } catch (err) {
        await handleError(err, { setError, tag: 'Get ChatMessages Attachment File ' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, chatMessagesAttachmentFileApi]
  )

  return { handleGetChatMessagesAttachmentFile, error, isLoading }
}
