/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { MaterialableViewHistorySessionFromJSON, MaterialableViewHistorySessionToJSON } from './MaterialableViewHistorySession';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetDocumentViewHistorySessionsResponseDataFromJSON(json) {
    return GetDocumentViewHistorySessionsResponseDataFromJSONTyped(json, false);
}
export function GetDocumentViewHistorySessionsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        viewHistorySessions: !exists(json, 'view_history_sessions')
            ? undefined
            : json['view_history_sessions'].map(MaterialableViewHistorySessionFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetDocumentViewHistorySessionsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        view_history_sessions: value.viewHistorySessions === undefined
            ? undefined
            : value.viewHistorySessions.map(MaterialableViewHistorySessionToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
