/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateChatSummariesChangeUserRequestFromJSON(json) {
    return UpdateChatSummariesChangeUserRequestFromJSONTyped(json, false);
}
export function UpdateChatSummariesChangeUserRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ids: !exists(json, 'ids') ? undefined : json['ids'],
        userId: !exists(json, 'user_id') ? undefined : json['user_id']
    };
}
export function UpdateChatSummariesChangeUserRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ids: value.ids,
        user_id: value.userId
    };
}
