import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var MaterialableTrackApi = /** @class */ (function () {
    function MaterialableTrackApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    MaterialableTrackApi.initialize = function (basePath, fetchApi) {
        if (!MaterialableTrackApi.instance) {
            MaterialableTrackApi.instance = new MaterialableTrackApi(basePath, fetchApi);
        }
        return MaterialableTrackApi.instance;
    };
    MaterialableTrackApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'contact'
        });
        return new NocoHttpClient.ApiContactV1MaterialableTrackApi(new Configuration(config));
    };
    return MaterialableTrackApi;
}());
export { MaterialableTrackApi };
