/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DocumentMiscSettingFromJSON, DocumentMiscSettingToJSON } from './DocumentMiscSetting';
export function GetDocumentMiscSettingResponseDataFromJSON(json) {
    return GetDocumentMiscSettingResponseDataFromJSONTyped(json, false);
}
export function GetDocumentMiscSettingResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        documentMiscSetting: !exists(json, 'document_misc_setting')
            ? undefined
            : DocumentMiscSettingFromJSON(json['document_misc_setting'])
    };
}
export function GetDocumentMiscSettingResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document_misc_setting: DocumentMiscSettingToJSON(value.documentMiscSetting)
    };
}
