/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ChatMessageAttachmentFromJSON, ChatMessageAttachmentToJSON } from './ChatMessageAttachment';
import { ChatMessageContactEmailTransactionFromJSON, ChatMessageContactEmailTransactionToJSON } from './ChatMessageContactEmailTransaction';
import { EnumChatMessageCreateCausedByFromJSON, EnumChatMessageCreateCausedByToJSON } from './EnumChatMessageCreateCausedBy';
import { EnumChatMessageSendedByTypeFromJSON, EnumChatMessageSendedByTypeToJSON } from './EnumChatMessageSendedByType';
import { EnumEmailTransactionableSendStatusFromJSON, EnumEmailTransactionableSendStatusToJSON } from './EnumEmailTransactionableSendStatus';
import { MaterialableFromJSON, MaterialableToJSON } from './Materialable';
import { SendedByFromJSON, SendedByToJSON } from './SendedBy';
import { UserProfileFromJSON, UserProfileToJSON } from './UserProfile';
export function ChatMessageFromJSON(json) {
    return ChatMessageFromJSONTyped(json, false);
}
export function ChatMessageFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
        documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
        createCausedBy: !exists(json, 'create_caused_by')
            ? undefined
            : EnumChatMessageCreateCausedByFromJSON(json['create_caused_by']),
        message: !exists(json, 'message') ? undefined : json['message'],
        files: !exists(json, 'files') ? undefined : json['files'],
        sendEmailStatus: !exists(json, 'send_email_status')
            ? undefined
            : EnumEmailTransactionableSendStatusFromJSON(json['send_email_status']),
        sendedByType: !exists(json, 'sended_by_type')
            ? undefined
            : EnumChatMessageSendedByTypeFromJSON(json['sended_by_type']),
        sendedById: !exists(json, 'sended_by_id') ? undefined : json['sended_by_id'],
        readAt: !exists(json, 'read_at') ? undefined : json['read_at'],
        editedAt: !exists(json, 'edited_at') ? undefined : json['edited_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        contactEmailTransaction: !exists(json, 'contact_email_transaction')
            ? undefined
            : ChatMessageContactEmailTransactionFromJSON(json['contact_email_transaction']),
        userEmailTransaction: !exists(json, 'user_email_transaction')
            ? undefined
            : ChatMessageContactEmailTransactionFromJSON(json['user_email_transaction']),
        chatMessageAttachments: !exists(json, 'chat_message_attachments')
            ? undefined
            : json['chat_message_attachments'] === null
                ? null
                : json['chat_message_attachments'].map(ChatMessageAttachmentFromJSON),
        materialable: !exists(json, 'materialable') ? undefined : MaterialableFromJSON(json['materialable']),
        sendedBy: !exists(json, 'sended_by') ? undefined : SendedByFromJSON(json['sended_by']),
        userProfile: !exists(json, 'user_profile') ? undefined : UserProfileFromJSON(json['user_profile'])
    };
}
export function ChatMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        contact_id: value.contactId,
        document_id: value.documentId,
        create_caused_by: EnumChatMessageCreateCausedByToJSON(value.createCausedBy),
        message: value.message,
        files: value.files,
        send_email_status: EnumEmailTransactionableSendStatusToJSON(value.sendEmailStatus),
        sended_by_type: EnumChatMessageSendedByTypeToJSON(value.sendedByType),
        sended_by_id: value.sendedById,
        read_at: value.readAt,
        edited_at: value.editedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        contact_email_transaction: ChatMessageContactEmailTransactionToJSON(value.contactEmailTransaction),
        user_email_transaction: ChatMessageContactEmailTransactionToJSON(value.userEmailTransaction),
        chat_message_attachments: value.chatMessageAttachments === undefined
            ? undefined
            : value.chatMessageAttachments === null
                ? null
                : value.chatMessageAttachments.map(ChatMessageAttachmentToJSON),
        materialable: MaterialableToJSON(value.materialable),
        sended_by: SendedByToJSON(value.sendedBy),
        user_profile: UserProfileToJSON(value.userProfile)
    };
}
