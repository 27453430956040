/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { RequestContactSettingFieldValueValuesInnerFromJSON, RequestContactSettingFieldValueValuesInnerToJSON } from './RequestContactSettingFieldValueValuesInner';
export function RequestCompanySettingFieldValueFromJSON(json) {
    return RequestCompanySettingFieldValueFromJSONTyped(json, false);
}
export function RequestCompanySettingFieldValueFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companySettingId: !exists(json, 'company_setting_id') ? undefined : json['company_setting_id'],
        companySettingFieldId: !exists(json, 'company_setting_field_id') ? undefined : json['company_setting_field_id'],
        values: !exists(json, 'values')
            ? undefined
            : json['values'] === null
                ? null
                : json['values'].map(RequestContactSettingFieldValueValuesInnerFromJSON)
    };
}
export function RequestCompanySettingFieldValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company_setting_id: value.companySettingId,
        company_setting_field_id: value.companySettingFieldId,
        values: value.values === undefined
            ? undefined
            : value.values === null
                ? null
                : value.values.map(RequestContactSettingFieldValueValuesInnerToJSON)
    };
}
