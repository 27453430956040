/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  GetOrganizationChatSettingResponse,
  GetOrganizationChatSettingResponseFromJSON,
  GetOrganizationChatSettingResponseToJSON,
  GetOrganizationLabelSettingResponse,
  GetOrganizationLabelSettingResponseFromJSON,
  GetOrganizationLabelSettingResponseToJSON,
  GetOrganizationPolicySettingsResponse,
  GetOrganizationPolicySettingsResponseFromJSON,
  GetOrganizationPolicySettingsResponseToJSON,
  GetOrganizationResponse,
  GetOrganizationResponseFromJSON,
  GetOrganizationResponseToJSON,
  InviteOrganizationRequest,
  InviteOrganizationRequestFromJSON,
  InviteOrganizationRequestToJSON,
  InviteOrganizationResponse,
  InviteOrganizationResponseFromJSON,
  InviteOrganizationResponseToJSON,
  PurgeUserAndMailTemplateOrganizationRequest,
  PurgeUserAndMailTemplateOrganizationRequestFromJSON,
  PurgeUserAndMailTemplateOrganizationRequestToJSON,
  PurgeUserAndMailTemplateOrganizationResponse,
  PurgeUserAndMailTemplateOrganizationResponseFromJSON,
  PurgeUserAndMailTemplateOrganizationResponseToJSON,
  UpdateOrganizationAdvertisementRequest,
  UpdateOrganizationAdvertisementRequestFromJSON,
  UpdateOrganizationAdvertisementRequestToJSON,
  UpdateOrganizationAdvertisementResponse,
  UpdateOrganizationAdvertisementResponseFromJSON,
  UpdateOrganizationAdvertisementResponseToJSON,
  UpdateOrganizationAvatarRequest,
  UpdateOrganizationAvatarRequestFromJSON,
  UpdateOrganizationAvatarRequestToJSON,
  UpdateOrganizationAvatarResponse,
  UpdateOrganizationAvatarResponseFromJSON,
  UpdateOrganizationAvatarResponseToJSON,
  UpdateOrganizationChatSettingAvatarResponse,
  UpdateOrganizationChatSettingAvatarResponseFromJSON,
  UpdateOrganizationChatSettingAvatarResponseToJSON,
  UpdateOrganizationChatSettingChatButtonImageResponse,
  UpdateOrganizationChatSettingChatButtonImageResponseFromJSON,
  UpdateOrganizationChatSettingChatButtonImageResponseToJSON,
  UpdateOrganizationChatSettingRequest,
  UpdateOrganizationChatSettingRequestFromJSON,
  UpdateOrganizationChatSettingRequestToJSON,
  UpdateOrganizationChatSettingResponse,
  UpdateOrganizationChatSettingResponseFromJSON,
  UpdateOrganizationChatSettingResponseToJSON,
  UpdateOrganizationPolicySettingRequest,
  UpdateOrganizationPolicySettingRequestFromJSON,
  UpdateOrganizationPolicySettingRequestToJSON,
  UpdateOrganizationPolicySettingResponse,
  UpdateOrganizationPolicySettingResponseFromJSON,
  UpdateOrganizationPolicySettingResponseToJSON,
  UpdateOrganizationRequest,
  UpdateOrganizationRequestFromJSON,
  UpdateOrganizationRequestToJSON,
  UpdateOrganizationResponse,
  UpdateOrganizationResponseFromJSON,
  UpdateOrganizationResponseToJSON,
  UpdateOrganizationTriggerEmailRequest,
  UpdateOrganizationTriggerEmailRequestFromJSON,
  UpdateOrganizationTriggerEmailRequestToJSON,
  UpdateOrganizationTriggerEmailiResponse,
  UpdateOrganizationTriggerEmailiResponseFromJSON,
  UpdateOrganizationTriggerEmailiResponseToJSON
} from '../models'

export interface UserV1OrganizationAdvertisementPutRequest {
  updateOrganizationAdvertisementRequest?: UpdateOrganizationAdvertisementRequest
}

export interface UserV1OrganizationAvatarPutRequest {
  updateOrganizationAvatarRequest?: UpdateOrganizationAvatarRequest
}

export interface UserV1OrganizationChatSettingAvatarPutRequest {
  file?: Blob
}

export interface UserV1OrganizationChatSettingChatButtonImagePutRequest {
  file?: Blob
}

export interface UserV1OrganizationChatSettingPutRequest {
  updateOrganizationChatSettingRequest?: UpdateOrganizationChatSettingRequest
}

export interface UserV1OrganizationInvitePostRequest {
  inviteOrganizationRequest?: InviteOrganizationRequest
}

export interface UserV1OrganizationPolicySettingsOrganizationPolicySettingIdPutRequest {
  organizationPolicySettingId: string
  updateOrganizationPolicySettingRequest?: UpdateOrganizationPolicySettingRequest
}

export interface UserV1OrganizationPurgeUserAndMailTemplatePostRequest {
  purgeUserAndMailTemplateOrganizationRequest?: PurgeUserAndMailTemplateOrganizationRequest
}

export interface UserV1OrganizationPutRequest {
  updateOrganizationRequest?: UpdateOrganizationRequest
}

export interface UserV1OrganizationTriggerEmailPutRequest {
  updateOrganizationTriggerEmailRequest?: UpdateOrganizationTriggerEmailRequest
}

/**
 *
 */
export class ApiUserV1OrganizationApi extends runtime.BaseAPI {
  /**
   * 広告設定を変更する
   * update
   */
  async userV1OrganizationAdvertisementPutRaw(
    requestParameters: UserV1OrganizationAdvertisementPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationAdvertisementResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/advertisement`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationAdvertisementRequestToJSON(requestParameters.updateOrganizationAdvertisementRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationAdvertisementResponseFromJSON(jsonValue)
    )
  }

  /**
   * 広告設定を変更する
   * update
   */
  async userV1OrganizationAdvertisementPut(
    requestParameters: UserV1OrganizationAdvertisementPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationAdvertisementResponse> {
    const response = await this.userV1OrganizationAdvertisementPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームアイコンを設定する
   * update
   */
  async userV1OrganizationAvatarPutRaw(
    requestParameters: UserV1OrganizationAvatarPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationAvatarResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/avatar`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationAvatarRequestToJSON(requestParameters.updateOrganizationAvatarRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationAvatarResponseFromJSON(jsonValue))
  }

  /**
   * チームアイコンを設定する
   * update
   */
  async userV1OrganizationAvatarPut(
    requestParameters: UserV1OrganizationAvatarPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationAvatarResponse> {
    const response = await this.userV1OrganizationAvatarPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームのチャット表示設定のアイコンの更新をする
   * avatar
   */
  async userV1OrganizationChatSettingAvatarPutRaw(
    requestParameters: UserV1OrganizationChatSettingAvatarPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationChatSettingAvatarResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/organization/chat_setting/avatar`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationChatSettingAvatarResponseFromJSON(jsonValue)
    )
  }

  /**
   * チームのチャット表示設定のアイコンの更新をする
   * avatar
   */
  async userV1OrganizationChatSettingAvatarPut(
    requestParameters: UserV1OrganizationChatSettingAvatarPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationChatSettingAvatarResponse> {
    const response = await this.userV1OrganizationChatSettingAvatarPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームのチャット表示設定のチャットボタン画像の更新をする（※現在は使われていない）
   * chat_button_image
   */
  async userV1OrganizationChatSettingChatButtonImagePutRaw(
    requestParameters: UserV1OrganizationChatSettingChatButtonImagePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationChatSettingChatButtonImageResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/organization/chat_setting/chat_button_image`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationChatSettingChatButtonImageResponseFromJSON(jsonValue)
    )
  }

  /**
   * チームのチャット表示設定のチャットボタン画像の更新をする（※現在は使われていない）
   * chat_button_image
   */
  async userV1OrganizationChatSettingChatButtonImagePut(
    requestParameters: UserV1OrganizationChatSettingChatButtonImagePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationChatSettingChatButtonImageResponse> {
    const response = await this.userV1OrganizationChatSettingChatButtonImagePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームのチャット表示設定の情報を取得する
   * show
   */
  async userV1OrganizationChatSettingGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationChatSettingResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/chat_setting`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationChatSettingResponseFromJSON(jsonValue))
  }

  /**
   * チームのチャット表示設定の情報を取得する
   * show
   */
  async userV1OrganizationChatSettingGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationChatSettingResponse> {
    const response = await this.userV1OrganizationChatSettingGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * チームのチャット表示設定の更新をする
   * update
   */
  async userV1OrganizationChatSettingPutRaw(
    requestParameters: UserV1OrganizationChatSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationChatSettingResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/chat_setting`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationChatSettingRequestToJSON(requestParameters.updateOrganizationChatSettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationChatSettingResponseFromJSON(jsonValue))
  }

  /**
   * チームのチャット表示設定の更新をする
   * update
   */
  async userV1OrganizationChatSettingPut(
    requestParameters: UserV1OrganizationChatSettingPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationChatSettingResponse> {
    const response = await this.userV1OrganizationChatSettingPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * ログインしているユーザが所属しているチーム情報を取得する
   * show
   */
  async userV1OrganizationGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationResponseFromJSON(jsonValue))
  }

  /**
   * ログインしているユーザが所属しているチーム情報を取得する
   * show
   */
  async userV1OrganizationGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationResponse> {
    const response = await this.userV1OrganizationGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * チームへユーザを招待する
   * invite
   */
  async userV1OrganizationInvitePostRaw(
    requestParameters: UserV1OrganizationInvitePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<InviteOrganizationResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/invite`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InviteOrganizationRequestToJSON(requestParameters.inviteOrganizationRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => InviteOrganizationResponseFromJSON(jsonValue))
  }

  /**
   * チームへユーザを招待する
   * invite
   */
  async userV1OrganizationInvitePost(
    requestParameters: UserV1OrganizationInvitePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<InviteOrganizationResponse> {
    const response = await this.userV1OrganizationInvitePostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料に設定できるラベル一覧を取得する（現状固定値）
   * show
   */
  async userV1OrganizationLabelSettingDocumentGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationLabelSettingResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/label_setting/document`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationLabelSettingResponseFromJSON(jsonValue))
  }

  /**
   * 資料に設定できるラベル一覧を取得する（現状固定値）
   * show
   */
  async userV1OrganizationLabelSettingDocumentGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationLabelSettingResponse> {
    const response = await this.userV1OrganizationLabelSettingDocumentGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * チームポリシーの表示設定情報一覧を取得する
   * index
   */
  async userV1OrganizationPolicySettingsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPolicySettingsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/policy_settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPolicySettingsResponseFromJSON(jsonValue))
  }

  /**
   * チームポリシーの表示設定情報一覧を取得する
   * index
   */
  async userV1OrganizationPolicySettingsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPolicySettingsResponse> {
    const response = await this.userV1OrganizationPolicySettingsGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * チームポリシーの表示情報を設定する
   * update
   */
  async userV1OrganizationPolicySettingsOrganizationPolicySettingIdPutRaw(
    requestParameters: UserV1OrganizationPolicySettingsOrganizationPolicySettingIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationPolicySettingResponse>> {
    if (
      requestParameters.organizationPolicySettingId === null ||
      requestParameters.organizationPolicySettingId === undefined
    ) {
      throw new runtime.RequiredError(
        'organizationPolicySettingId',
        'Required parameter requestParameters.organizationPolicySettingId was null or undefined when calling userV1OrganizationPolicySettingsOrganizationPolicySettingIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/policy_settings/{organization_policy_setting_id}`.replace(
          `{${'organization_policy_setting_id'}}`,
          encodeURIComponent(String(requestParameters.organizationPolicySettingId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationPolicySettingRequestToJSON(requestParameters.updateOrganizationPolicySettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationPolicySettingResponseFromJSON(jsonValue)
    )
  }

  /**
   * チームポリシーの表示情報を設定する
   * update
   */
  async userV1OrganizationPolicySettingsOrganizationPolicySettingIdPut(
    requestParameters: UserV1OrganizationPolicySettingsOrganizationPolicySettingIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationPolicySettingResponse> {
    const response = await this.userV1OrganizationPolicySettingsOrganizationPolicySettingIdPutRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * 選択したユーザーとメールテンプレート以外を削除する
   * purge_user_and_mail_template
   */
  async userV1OrganizationPurgeUserAndMailTemplatePostRaw(
    requestParameters: UserV1OrganizationPurgeUserAndMailTemplatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PurgeUserAndMailTemplateOrganizationResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/purge_user_and_mail_template`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PurgeUserAndMailTemplateOrganizationRequestToJSON(
          requestParameters.purgeUserAndMailTemplateOrganizationRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PurgeUserAndMailTemplateOrganizationResponseFromJSON(jsonValue)
    )
  }

  /**
   * 選択したユーザーとメールテンプレート以外を削除する
   * purge_user_and_mail_template
   */
  async userV1OrganizationPurgeUserAndMailTemplatePost(
    requestParameters: UserV1OrganizationPurgeUserAndMailTemplatePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PurgeUserAndMailTemplateOrganizationResponse> {
    const response = await this.userV1OrganizationPurgeUserAndMailTemplatePostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * ログインしているユーザが所属しているチーム情報を編集する
   * update
   */
  async userV1OrganizationPutRaw(
    requestParameters: UserV1OrganizationPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationRequestToJSON(requestParameters.updateOrganizationRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationResponseFromJSON(jsonValue))
  }

  /**
   * ログインしているユーザが所属しているチーム情報を編集する
   * update
   */
  async userV1OrganizationPut(
    requestParameters: UserV1OrganizationPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationResponse> {
    const response = await this.userV1OrganizationPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * トリガーメールの設定を更新する
   * update
   */
  async userV1OrganizationTriggerEmailPutRaw(
    requestParameters: UserV1OrganizationTriggerEmailPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationTriggerEmailiResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/trigger_email`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationTriggerEmailRequestToJSON(requestParameters.updateOrganizationTriggerEmailRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationTriggerEmailiResponseFromJSON(jsonValue)
    )
  }

  /**
   * トリガーメールの設定を更新する
   * update
   */
  async userV1OrganizationTriggerEmailPut(
    requestParameters: UserV1OrganizationTriggerEmailPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationTriggerEmailiResponse> {
    const response = await this.userV1OrganizationTriggerEmailPutRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
