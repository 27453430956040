/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateContactSettingRequestContactSetting,
  CreateContactSettingRequestContactSettingFromJSON,
  CreateContactSettingRequestContactSettingFromJSONTyped,
  CreateContactSettingRequestContactSettingToJSON
} from './CreateContactSettingRequestContactSetting'
import {
  UpdateContactSettingRequestContactSettingFieldsInner,
  UpdateContactSettingRequestContactSettingFieldsInnerFromJSON,
  UpdateContactSettingRequestContactSettingFieldsInnerFromJSONTyped,
  UpdateContactSettingRequestContactSettingFieldsInnerToJSON
} from './UpdateContactSettingRequestContactSettingFieldsInner'

/**
 *
 * @export
 * @interface UpdateContactSettingRequest
 */
export interface UpdateContactSettingRequest {
  /**
   *
   * @type {CreateContactSettingRequestContactSetting}
   * @memberof UpdateContactSettingRequest
   */
  contactSetting?: CreateContactSettingRequestContactSetting
  /**
   *
   * @type {Array<UpdateContactSettingRequestContactSettingFieldsInner>}
   * @memberof UpdateContactSettingRequest
   */
  contactSettingFields?: Array<UpdateContactSettingRequestContactSettingFieldsInner>
}

export function UpdateContactSettingRequestFromJSON(json: any): UpdateContactSettingRequest {
  return UpdateContactSettingRequestFromJSONTyped(json, false)
}

export function UpdateContactSettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateContactSettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactSetting: !exists(json, 'contact_setting')
      ? undefined
      : CreateContactSettingRequestContactSettingFromJSON(json['contact_setting']),
    contactSettingFields: !exists(json, 'contact_setting_fields')
      ? undefined
      : (json['contact_setting_fields'] as Array<any>).map(UpdateContactSettingRequestContactSettingFieldsInnerFromJSON)
  }
}

export function UpdateContactSettingRequestToJSON(value?: UpdateContactSettingRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_setting: CreateContactSettingRequestContactSettingToJSON(value.contactSetting),
    contact_setting_fields:
      value.contactSettingFields === undefined
        ? undefined
        : (value.contactSettingFields as Array<any>).map(UpdateContactSettingRequestContactSettingFieldsInnerToJSON)
  }
}
