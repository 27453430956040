import { Box, ButtonBase, CircularProgress } from '@material-ui/core'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { Button, Card, Checkbox, SelectBox, Typography } from 'src/components/atoms'
import { useListOrganizationUsers } from 'src/fixtures/modules/organization/hooks'
import { useGetOrganizationPaymentCurrentPlan, useGetPlanLimit } from 'src/fixtures/modules/organizationPayment/hooks'
import { center } from '../styles'
import Image from 'next/image'
import { getUserFullName } from 'src/fixtures/modules/user/utils'
import { useRouter } from 'next/router'

import { useSelectedIdMap } from 'src/fixtures/utils'
import { WarningMesasge } from './WarningMessage'
import { User } from '@noco/http-client/lib/noco'
export interface StepSelectUserProps {
  onNext: (data: { stayUserIds: string[]; takeOverUserId: string }) => void
}

export const StepSelectUser = ({ onNext }: StepSelectUserProps) => {
  const { data } = useGetOrganizationPaymentCurrentPlan()
  const plan = data?.plan
  const { data: userRes } = useListOrganizationUsers()
  const list = userRes?.users
  const router = useRouter()
  const planId = router.query.id as string

  const { userCountLimit } = useGetPlanLimit(planId)
  const [takeOverUserId, setTakeOverUserId] = useState('')

  const {
    selectMap: stayUserIdSelectMap,
    selectIdList: stayUserIds,
    selectedCount,
    handleSelectId: handleSelectUserId
  } = useSelectedIdMap()

  const isMaxSelected = useMemo(() => {
    return selectedCount >= userCountLimit.incoming
  }, [selectedCount, userCountLimit])

  const takeOverUserOptions = useMemo(() => {
    return list?.map((item: User) => ({
      label: getUserFullName(item),
      value: item.id!,
      discarded: Boolean(item.discardedAt)
    }))
  }, [list])

  const filteredDiscardedOptions = useMemo(() => {
    return takeOverUserOptions?.filter(item => {
      return !item.discarded
    })
  }, [takeOverUserOptions])

  const isValid = useMemo(() => selectedCount > 0 && takeOverUserId, [selectedCount, takeOverUserId])

  const isUserSelected = useCallback((id: string) => !!stayUserIdSelectMap.get(id), [stayUserIdSelectMap])

  const handleClickUser = useCallback(
    (item: User, isChecked: boolean) => {
      if (isMaxSelected && !isChecked) return

      if (userCountLimit.incoming === 1) {
        handleSelectUserId(item.id!)
        setTakeOverUserId(item.id!)
      }
      handleSelectUserId(item.id!)
    },
    [userCountLimit, isMaxSelected, handleSelectUserId, setTakeOverUserId]
  )

  useEffect(() => {
    if (!filteredDiscardedOptions) return
    if (filteredDiscardedOptions.length === 1) {
      const userId = filteredDiscardedOptions[0].value
      if (isUserSelected(userId)) return

      handleSelectUserId(userId)
      setTakeOverUserId(userId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDiscardedOptions])

  return plan ? (
    <Box width={'100%'}>
      <Box mb="24px">{planId && <WarningMesasge nextPlanId={planId} />}</Box>
      <Box mb="24px">
        <Card label="ユーザーを選択" sx={{ py: '24px', bgcolor: 'white' }}>
          <Box sx={{ py: '8px' }}>
            <Typography fontSize="s" lh="tight" style={{ whiteSpace: 'pre-wrap' }}>
              {`引き続きご利用になるユーザーを${userCountLimit.incoming}名選択してください\nその他のユーザーはご利用できなくなります`}
            </Typography>
            <Box height="22px" />
            {/* MEMO: @yuokawara 解除したユーザーまで選択肢に表示してしまう為、discardedAtにて判定する処置 */}
            {list
              ?.filter(item => !item.discardedAt)
              .map((item, index) => {
                const isChecked = isUserSelected(item.id!)

                return (
                  <Box key={index}>
                    <ButtonBase
                      onClick={() => {
                        handleClickUser(item, isChecked)
                      }}
                      style={{ display: 'flex', alignItems: 'center', marginLeft: '-1px' }}
                    >
                      <Checkbox checked={isChecked || false} disabled={isMaxSelected && !isChecked} />
                      <Box sx={{ width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', mr: '8px' }}>
                        <Image src={item.avatar?.url || ''} alt="avatar" width="24px" height="24px" objectFit="cover" />
                      </Box>
                      <Typography fontSize="s" lineheight="tight">
                        {getUserFullName(item)}
                      </Typography>
                    </ButtonBase>
                  </Box>
                )
              })}
          </Box>
        </Card>
      </Box>

      <Box mb="24px">
        <Card label="営業担当者・お問い合わせ担当者の引き継ぎ" sx={{ py: '24px', bgcolor: 'white' }}>
          <Box sx={{ py: '22px' }}>
            <Typography fontSize="s" lh="tight">
              {`ご利用できなくなるユーザーの引き継ぎ先を選択してください\n連絡先・会社の担当者が以下のユーザーに再設定されます`}
            </Typography>

            <Box mt="16px" width="316px">
              <SelectBox
                disabled={userCountLimit.incoming === 1}
                optionList={filteredDiscardedOptions || []}
                value={takeOverUserId}
                placeholder="選択してください"
                onChange={e => {
                  setTakeOverUserId(e.target.value as string)
                }}
              />
            </Box>
          </Box>
        </Card>
      </Box>
      <Box sx={{ ...center, mt: '24px' }}>
        <Button
          size="large"
          onClick={() => onNext({ stayUserIds, takeOverUserId })}
          title={'メールテンプレートを選択へ進む'}
          disabled={!isValid}
        />
      </Box>

      <Box height="48px" />
    </Box>
  ) : (
    <Box sx={{ ...center }}>
      <CircularProgress />
    </Box>
  )
}
