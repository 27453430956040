import { createStyles, makeStyles, TextField, InputProps, InputAdornment } from '@material-ui/core'
import { DynamicMuiIcon } from 'src/components/atoms'
import { Colors } from '../Colors'

export type searchFormProps = {
  value: string
  onChange: InputProps['onChange']
  name?: string
  placeholder?: string
  width?: number | string
  fullWidth?: boolean
  height?: 'large' | 'medium'
}

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      '& .MuiInput-root': {
        borderRadius: '4px',
        border: `1px solid ${Colors.background.silver}`,
        width: ({ width, fullWidth }: searchFormProps) => (fullWidth ? '100%' : width || '328px'),
        height: ({ height = 'medium' }: searchFormProps) => {
          switch (height) {
            case 'large':
              return '40px'
            case 'medium':
              return '34px'
            default:
              return '28px'
          }
        },
        '& .Mui-focused': {
          border: `1px solid ${Colors.accent.keyPurple.default}`
        },
        '&:hover': {
          border: `1px solid ${Colors.accent.keyPurple.default}`
        }
      },
      '& .MuiInput-input': {
        paddingLeft: '8px'
      }
    },
    icon: {
      position: 'relative',
      left: '8px',
      color: Colors.base.middleGray
    }
  })
})

export const SearchForm = (props: searchFormProps): JSX.Element => {
  const { placeholder, value, name, onChange } = props
  const classes = useStyles(props)

  return (
    <TextField
      classes={{ root: classes.root }}
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" classes={{ root: classes.icon }}>
            <DynamicMuiIcon variant="search" fontSize="small" size={20} />
          </InputAdornment>
        )
      }}
    />
  )
}
