/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumMaterialableTypeFromJSON, EnumMaterialableTypeToJSON } from './EnumMaterialableType';
import { UploadedFileFromJSON, UploadedFileToJSON } from './UploadedFile';
export function MaterialableFromJSON(json) {
    return MaterialableFromJSONTyped(json, false);
}
export function MaterialableFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        materialableType: !exists(json, 'materialable_type')
            ? undefined
            : EnumMaterialableTypeFromJSON(json['materialable_type']),
        materialableId: !exists(json, 'materialable_id') ? undefined : json['materialable_id'],
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        memo: !exists(json, 'memo') ? undefined : json['memo'],
        thumbnail: !exists(json, 'thumbnail') ? undefined : UploadedFileFromJSON(json['thumbnail']),
        thumbnailSlideImage: !exists(json, 'thumbnail_slide_image')
            ? undefined
            : UploadedFileFromJSON(json['thumbnail_slide_image']),
        publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function MaterialableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
        materialable_id: value.materialableId,
        title: value.title,
        description: value.description,
        memo: value.memo,
        thumbnail: UploadedFileToJSON(value.thumbnail),
        thumbnail_slide_image: UploadedFileToJSON(value.thumbnailSlideImage),
        published_at: value.publishedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
