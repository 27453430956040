import React from 'react'
import {
  createStyles,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  RadioGroupProps
} from '@material-ui/core'
import { OptionListType } from '..'
import { Colors } from '../Colors'

export interface RadioListProps {
  value: string | number
  name: string
  optionList: OptionListType[]
  onChange: RadioGroupProps['onChange']
}

const useStyles = makeStyles(() => {
  return createStyles({
    label: {
      marginLeft: '0px',
      marginBottom: '8px',
      '& .MuiFormControlLabel-label': {
        fontSize: '14px'
      }
    },
    radio: {
      color: Colors.label.gray,
      marginRight: '8px'
    }
  })
})

export const RadioList = ({ value, name, optionList, onChange }: RadioListProps) => {
  const classes = useStyles()

  return (
    <FormControl>
      <RadioGroup name={name} value={value} onChange={onChange} defaultValue={optionList[0].value}>
        {optionList.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            className={classes.label}
            control={<Radio color="primary" className={classes.radio} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
