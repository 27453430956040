import { Box, makeStyles, createStyles } from '@material-ui/core'
import { Colors, Typography, Button } from 'src/components/atoms'
import { PRIVACY_LINK } from 'src/fixtures/utils/constant'

export interface CookieAgreementProps {
  open: boolean
  onClick: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: Colors.functional.whiteText.default,
      textDecorationColor: Colors.functional.whiteText.default
    }
  })
)

export const CookieAgreement = ({ open, onClick }: CookieAgreementProps) => {
  const classes = useStyles()

  return open ? (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        color: Colors.functional.whiteText.default,
        bgcolor: Colors.accent.keyPurple.opacity90,
        p: '16px'
      }}
    >
      <Typography fontSize="s" lh="relaxed" fontWeight="bold">
        お客様の利便性を向上させるためにクッキーを使用しています。収集するデータとその使用方法の詳細については、
        <a href={PRIVACY_LINK} target="_blank" rel="noreferrer" className={classes.root}>
          プライバシーポリシー
        </a>
        をご覧ください
      </Typography>
      <Box sx={{ mt: { xs: '10px', md: '0px' }, mr: { xs: '0px', md: '94px' } }} />
      <Button title="OK" kind="white" textColor={Colors.accent.keyPurple.default} onClick={onClick} />
    </Box>
  ) : (
    <></>
  )
}
