/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { DiscardCompanyMemoResponseFromJSON, UpdateCompanyMemoRequestToJSON, UpdateCompanyMemoResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1CompanyMemoApi extends runtime.BaseAPI {
    /**
     */
    async userV1CompaniesCompanyIdMemosCompanyMemoIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDelete.');
        }
        if (requestParameters.companyMemoId === null || requestParameters.companyMemoId === undefined) {
            throw new runtime.RequiredError('companyMemoId', 'Required parameter requestParameters.companyMemoId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/companies/{company_id}/memos/{company_memo_id}`
                .replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId)))
                .replace(`{${'company_memo_id'}}`, encodeURIComponent(String(requestParameters.companyMemoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async userV1CompaniesCompanyIdMemosCompanyMemoIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdMemosCompanyMemoIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPutRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPut.');
        }
        if (requestParameters.companyMemoId === null || requestParameters.companyMemoId === undefined) {
            throw new runtime.RequiredError('companyMemoId', 'Required parameter requestParameters.companyMemoId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/companies/{company_id}/memos/{company_memo_id}/discard`
                .replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId)))
                .replace(`{${'company_memo_id'}}`, encodeURIComponent(String(requestParameters.companyMemoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => DiscardCompanyMemoResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPut(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1CompaniesCompanyIdMemosCompanyMemoIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdPut.');
        }
        if (requestParameters.companyMemoId === null || requestParameters.companyMemoId === undefined) {
            throw new runtime.RequiredError('companyMemoId', 'Required parameter requestParameters.companyMemoId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/companies/{company_id}/memos/{company_memo_id}`
                .replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId)))
                .replace(`{${'company_memo_id'}}`, encodeURIComponent(String(requestParameters.companyMemoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyMemoRequestToJSON(requestParameters.updateCompanyMemoRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateCompanyMemoResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1CompaniesCompanyIdMemosCompanyMemoIdPut(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdMemosCompanyMemoIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
