import {
  CreateContactSettingRequest,
  UpdateContactSettingRequest,
  UpdateSortContactSettingRequest
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'

export const useListContactSettings = () => {
  const { data: auth } = useAuthenticate()
  const contactSettingApi = nocoSDK.client?.userService.contactSettingApi
  const func = useCallback(async () => {
    if (!auth) return
    return await contactSettingApi
      ?.ApiFactory(auth.token)
      .userV1ContactSettingsGet()
      .then(item => item.data)
  }, [auth, contactSettingApi])
  return useSWR(auth && SWRCachePath.listContactSettings(), func)
}

export const useCreateContactSettings = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateList } = useListContactSettings()
  const contactSettingApi = nocoSDK.client?.userService.contactSettingApi

  const handleCreateContactSettings = useCallback(
    async (createContactSettingRequest: CreateContactSettingRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await contactSettingApi?.ApiFactory(auth.token).userV1ContactSettingsPost({ createContactSettingRequest })
        mutateList()
        Toaster.success('連絡先の項目を作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create contact setting' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactSettingApi, me, mutateList]
  )

  return { error, isLoading, handleCreateContactSettings }
}

export const useUpdateContactSettings = (contactSettingId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateList } = useListContactSettings()
  const contactSettingApi = nocoSDK.client?.userService.contactSettingApi

  const handleUpdateContactSettings = useCallback(
    async (updateContactSettingRequest: UpdateContactSettingRequest) => {
      try {
        if (!auth || !me || !contactSettingId) return
        setError(undefined)
        setIsLoading(true)
        await contactSettingApi
          ?.ApiFactory(auth.token)
          .userV1ContactSettingsContactSettingIdPut({ contactSettingId, updateContactSettingRequest })
        mutateList()
        Toaster.success('連絡先の項目を編集しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update contact settings' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactSettingId, me, contactSettingApi, mutateList]
  )

  return { error, isLoading, handleUpdateContactSettings }
}

export const useDeleteContactSettings = (contactSettingId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateList } = useListContactSettings()
  const contactSettingApi = nocoSDK.client?.userService.contactSettingApi

  const handleDeleteContactSettings = useCallback(async () => {
    try {
      if (!auth || !me || !contactSettingId) return
      setError(undefined)
      setIsLoading(true)
      await contactSettingApi?.ApiFactory(auth.token).userV1ContactSettingsContactSettingIdDelete({ contactSettingId })
      mutateList()
      Toaster.success('削除完了')
    } catch (err) {
      await handleError(err, { setError, tag: 'Delete contact settings' })
    } finally {
      setIsLoading(false)
    }
  }, [auth, contactSettingId, me, mutateList, contactSettingApi])

  return { error, isLoading, handleDeleteContactSettings }
}

export const useSortContactSettings = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListContactSettings()
  const contactSettingApi = nocoSDK.client?.userService.contactSettingApi

  const handleSortContactSettings = useCallback(
    async (updateSortContactSettingRequest: UpdateSortContactSettingRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await contactSettingApi
          ?.ApiFactory(auth.token)
          .userV1ContactSettingsSortPost({ updateSortContactSettingRequest })
        mutate()
      } catch (err) {
        await handleError(err, { setError, tag: 'Sort contact setting' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutate, contactSettingApi]
  )

  return { error, isLoading, handleSortContactSettings }
}
