import {
  InviteOrganizationRequest,
  UpdateOrganizationAvatarRequest,
  UpdateOrganizationRequest,
  UpdateOrganizationUserRequest,
  UpdateOrganizationTriggerEmailRequest,
  UpdateOrganizationPolicySettingRequest,
  OrganizationSignupMailRequest,
  OrganizationSignupRequest,
  UpdateOrganizationAdvertisementRequest,
  PurgeUserAndMailTemplateOrganizationRequest,
  EnumOrganizationUser,
  UpdateOrganizationChatSettingRequestOrganizationChatSetting,
  OrganizationStatusEnum
} from '@noco/http-client/lib/noco'
import { useCallback, useMemo, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { wait } from 'src/fixtures/utils'
import { handleError } from 'src/fixtures/utils/error'
import useSWR, { useSWRConfig } from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'
nocoSDK.initalize()
const organizationUsersApi = nocoSDK.client?.userService.organizationUsersApi
const organizationApi = nocoSDK.client?.userService.organizationApi
const organizationUserEmailSettingsApi = nocoSDK.client?.userService.organizationUserEmailSettingsApi

export const useGetOrganization = () => {
  const { data: auth } = useAuthenticate()
  const func = useCallback(async () => {
    if (!auth) return
    return await organizationApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationGet()
      .then(res => res.data)
  }, [auth])
  return useSWR(auth && SWRCachePath.getOrganization(), func)
}

export const useGetOrganizationStatus = () => {
  const { data: organizationData } = useGetOrganization()
  const organization = organizationData?.organization

  const isTrialing = useMemo<boolean>(() => {
    if (!organization) return false

    return organization.status === OrganizationStatusEnum.Trialing
  }, [organization])

  return { isTrialing, status: organization?.status, organization }
}

export const useListOrganizationUsers = () => {
  const { data: auth } = useAuthenticate()

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationUsersApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationUsersGet({ includeDiscarded: true }, { cache: 'no-store' })
      .then(res => res.data)
  }, [auth])
  return useSWR(auth && SWRCachePath.listOrganizationUsers(), func)
}

export const useListOrganizationAdministrators = () => {
  const { data: auth } = useAuthenticate()

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationUsersApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationUsersGet({ permission: EnumOrganizationUser.Administrator, includeDiscarded: true })
      .then(res => res.data)
  }, [auth])
  return useSWR(auth && SWRCachePath.listOrganizationAdministrators(), func)
}

export const useUpdateOrganizationUser = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListOrganizationUsers()
  const handleUpdateOrganizationUser = useCallback(
    async (userId: string, updateOrganizationUserRequest: UpdateOrganizationUserRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationUsersApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationUsersUserIdPut({ userId, updateOrganizationUserRequest })
        await mutate()
        Toaster.success('アップデートしました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Organization' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutate]
  )

  return { error, isLoading, handleUpdateOrganizationUser }
}

export const useDeleteOrganizationUser = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListOrganizationUsers()
  const handleDeleteOrganizationUser = useCallback(
    async (userId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationUsersApi?.ApiFactory(auth.token).userV1OrganizationUsersUserIdDelete({ userId })
        await mutate()
        Toaster.success('ユーザーを削除しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Organization' })

        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutate]
  )

  return { error, isLoading, handleDeleteOrganizationUser }
}
export const useRestoreOrganizationUser = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListOrganizationUsers()
  const handleRestoreOrganizationUser = useCallback(
    async (userId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationUsersApi?.ApiFactory(auth.token).userV1OrganizationUsersUserIdRestorePut({ userId })
        await mutate()
        Toaster.success('ユーザーの解除をキャンセルしました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Organization' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutate]
  )

  return { error, isLoading, handleRestoreOrganizationUser }
}

export const useUpdateOrganization = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateOrganization } = useGetOrganization()
  const handleUpdateOrganization = useCallback(
    async (
      updateOrganizationRequest: UpdateOrganizationRequest,
      updateOrganizationAvatarRequest?: UpdateOrganizationAvatarRequest
    ) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationApi?.ApiFactory(auth.token).userV1OrganizationPut({ updateOrganizationRequest })
        if (updateOrganizationAvatarRequest) {
          await organizationApi?.ApiFactory(auth.token).userV1OrganizationAvatarPut({ updateOrganizationAvatarRequest })
        }
        await mutateOrganization()
        Toaster.success('チームをアップデートしました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Organization' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutateOrganization]
  )

  return { error, isLoading, handleUpdateOrganization }
}

export const useUpdateOrganizationAdvertisement = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useGetOrganization()
  const handleUpdateOrganizationAdvertisement = useCallback(
    async (updateOrganizationAdvertisementRequest: UpdateOrganizationAdvertisementRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationAdvertisementPut({ updateOrganizationAdvertisementRequest })
        await mutate()
        Toaster.success('アップデートしました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update UpdateOrganizationAdvertisement' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutate]
  )
  return { error, isLoading, handleUpdateOrganizationAdvertisement }
}

// organization への招待
export const useInviteOrganization = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [isFirstInvitedUser, setIsFirstInvitedUser] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateOrganization } = useGetOrganization()
  const { mutate: mutateListOrganizationUsers } = useListOrganizationUsers()

  const handleInvite = useCallback(
    async (
      data: {
        email: InviteOrganizationRequest['encryptedEmail']
        permission: InviteOrganizationRequest['permission']
        password?: InviteOrganizationRequest['password']
      }[]
    ) => {
      try {
        if (!auth || !me || !organizationApi) return
        setError(undefined)
        setIsLoading(true)

        const p = data.map(item => {
          return organizationApi
            ?.ApiFactory(auth.token)
            .userV1OrganizationInvitePost({
              inviteOrganizationRequest: {
                encryptedEmail: item.email,
                permission: item.permission,
                password: item.password!
              }
            })
            .catch(async err => {
              const errRes = await err.json()
              if (errRes.messages && errRes.messages[0]) {
                Toaster.error(`送信先： ${item.email}\n${errRes.messages[0]}`)
                throw err
              }

              Toaster.error(`送信先： ${item.email}\nユーザーの招待に失敗しました`)
              throw err
            })
        })
        const resList = await Promise.all(p)
        // NOTE: 1件でも res.data.isFirstInvitedUser が True であれば
        //       初めてユーザーを招待したとみなす
        setIsFirstInvitedUser(resList.some(res => res.data.isFirstInvitedUser))
        const mutationPromises = [mutateOrganization(), mutateListOrganizationUsers()]
        await Promise.all(mutationPromises)
        await Toaster.success('招待が完了しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Organization' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutateListOrganizationUsers, mutateOrganization]
  )

  return { error, isLoading, handleInvite, isFirstInvitedUser }
}

export const useGetOrganizationUserOptionList = () => {
  const { data: organizationUserRes } = useListOrganizationUsers()
  const organizationUserList = organizationUserRes?.users

  const userOptionList = useMemo(() => {
    return organizationUserList?.map(item => ({
      value: item.id!,
      label: `${item.lastName} ${item.firstName}`,
      avatar: item.avatar?.url
    }))
  }, [organizationUserList])

  return { userOptionList }
}

// policy
export const useGetPolicySettings = () => {
  const { data: auth } = useAuthenticate()
  const func = useCallback(async () => {
    if (!auth) return
    return await organizationApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPolicySettingsGet({})
      .then(res => res.data)
  }, [auth])

  return useSWR(auth && SWRCachePath.listPolicySettings(), func)
}

export const useUpdatePolicySettings = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutatePolicySettings } = useGetPolicySettings()
  const handleUpdatePolicySettings = useCallback(
    async (
      organizationPolicySettingId: string,
      updateOrganizationPolicySettingRequest: UpdateOrganizationPolicySettingRequest
    ) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationApi?.ApiFactory(auth.token).userV1OrganizationPolicySettingsOrganizationPolicySettingIdPut({
          organizationPolicySettingId,
          updateOrganizationPolicySettingRequest
        })
        await mutatePolicySettings()
        Toaster.success('ポリシーをアップデートしました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Policy Settings' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutatePolicySettings]
  )

  return { error, isLoading, handleUpdatePolicySettings }
}

export const useGetOrganizationTriggerOptionList = () => {
  const { data: organizationRes } = useGetOrganization()
  const organizationTriggerList = organizationRes?.organization?.selectTriggerResentTimings

  const TriggerOptionList = useMemo(() => {
    return organizationTriggerList?.map(item => ({
      value: item.key!,
      label: item.displayName
    }))
  }, [organizationTriggerList])

  return { TriggerOptionList }
}

export const useUpdateTriggerEmail = () => {
  const { data: auth } = useAuthenticate()
  const { data: me } = useGetMe()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetOrganization()

  const handleUpdateTriggerEmail = useCallback(
    async (updateOrganizationTriggerEmailRequest: UpdateOrganizationTriggerEmailRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationApi?.ApiFactory(auth.token).userV1OrganizationTriggerEmailPut({
          updateOrganizationTriggerEmailRequest
        })
        await mutate()
        Toaster.success('トリガーメールを設定しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Trigger Email' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutate]
  )

  return { error, isLoading, handleUpdateTriggerEmail }
}

export const useCreateOrganization = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetOrganization()
  const authApi = nocoSDK.client?.userService.authApi

  const handleCreateOrganizationSignupMail = useCallback(
    async (param: OrganizationSignupMailRequest) => {
      try {
        setIsLoading(true)
        setError(undefined)
        await authApi?.ApiFactory().userV1OrganizationSignupMailPost({ organizationSignupMailRequest: param })
        Toaster.success('ご本人確認のメールを送信しました。')
        mutate()
      } catch (err) {
        await handleError(err, { setError, tag: 'Create organization' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi, mutate]
  )

  return { error, isLoading, handleCreateOrganizationSignupMail }
}

export const useIntialLoginOrganization = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isPosted, setIsPosted] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const authApi = nocoSDK.client?.userService.authApi

  const handleInitialLoginOrganization = useCallback(
    async (param: OrganizationSignupRequest) => {
      try {
        setError(undefined)
        setIsLoading(true)
        await authApi?.ApiFactory().userV1OrganizationSignupPost({ organizationSignupRequest: param })
        Toaster.success('チームの作成が完了しました！')
        await wait(512)
        // TODO: (@snuffy) stgかどうかを判定して入れる
        const signinUrl = `${window.location.protocol}//${param.organizationSubdomain}.${process.env.DOMAIN_NAME}/initial_signin`
        // MEMO: (@snuffy) subomain 遷移なので href 遷移
        window.location.href = signinUrl
      } catch (err) {
        await handleError(err, { setError, tag: 'inital login to organization' })
      } finally {
        setIsLoading(false)
        setIsPosted(true)
      }
    },
    [authApi]
  )

  return { error, isLoading, isPosted, handleInitialLoginOrganization }
}

export const useCheckOrganizationSignupToken = (token?: string) => {
  const [instructions, setInstructions] = useState<string[] | undefined>()
  const [error, setError] = useState<Error | undefined>()
  const authApi = nocoSDK.client?.userService.authApi
  const func = useCallback(async () => {
    try {
      if (!token) return
      setError(undefined)
      return await authApi
        ?.ApiFactory()
        .userV1OrganizationSignupCheckGet({ token })
        .then(item => item.data)
    } catch (err) {
      await handleError(err, { setError, setInstructions, tag: 'Check Organization Token' }, false)
    }
  }, [token, authApi])
  const swrRes = useSWR(authApi && SWRCachePath.checkOrganizationSignupToken(token), func)

  return { ...swrRes, instructions, error, isLoading: !swrRes.data && !swrRes.error && !error }
}

export const useGetOrganizationUserEmailSettings = () => {
  const { data: auth } = useAuthenticate()
  const func = useCallback(async () => {
    if (!auth) return
    return await organizationUserEmailSettingsApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationUserEmailSettingsGet({ cache: 'no-store' })
      .then(res => res.data)
  }, [auth])
  return useSWR(auth && SWRCachePath.listOrganizationUserEmailSettings(), func)
}

export const useUpdateUserEmailSettings = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateUserEmailSettings } = useGetOrganizationUserEmailSettings()
  const handleUpdateUserEmailSettings = useCallback(
    async (id: string, senderName: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await organizationUserEmailSettingsApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationUserEmailSettingsOrganizationUserEmailSettingIdPut({
            organizationUserEmailSettingId: id,
            updateOrganizationUserEmailSettingRequest: { organizationUserEmailSetting: { subjectName: senderName } }
          })
        await mutateUserEmailSettings()
        Toaster.success('送信者名を変更しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update User Email Settings' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutateUserEmailSettings]
  )

  return { error, isLoading, handleUpdateUserEmailSettings }
}

export const useGetOrganizationLabelSettingDocumentList = () => {
  const { data: auth } = useAuthenticate()

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationLabelSettingDocumentGet()
      .then(res => res.data)
  }, [auth])
  return useSWR(auth && SWRCachePath.listDocumentLabels(), func)
}

export const usePurgeUserAndMailTemplate = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const organizationApi = nocoSDK.client?.userService.organizationApi
  const { mutate: mutateOrganization } = useGetOrganization()
  const { mutate: mutatePlanLimit } = useSWRConfig()
  const handlePurgeUserAndMailTemplate = useCallback(
    async (purgeUserAndMailTemplateOrganizationRequest: PurgeUserAndMailTemplateOrganizationRequest) => {
      try {
        setError(undefined)
        if (!auth || !me) return
        setIsLoading(true)
        await organizationApi?.ApiFactory(auth.token).userV1OrganizationPurgeUserAndMailTemplatePost({
          purgeUserAndMailTemplateOrganizationRequest
        })
        const mutateList = [mutateOrganization()]
        const planId = purgeUserAndMailTemplateOrganizationRequest.planIdOfTransitionDst
        if (planId) mutateList.push(mutatePlanLimit(SWRCachePath.getPlanLimit(planId)))
        await Promise.all(mutateList)
      } catch (err) {
        await handleError(err, { setError, tag: 'Purge user and mail template' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, mutateOrganization, mutatePlanLimit, organizationApi]
  )

  return { error, isLoading, handlePurgeUserAndMailTemplate }
}

export const useGetOrganizationChatSetting = (isPreview = true) => {
  const { data: auth } = useAuthenticate()
  const func = useCallback(async () => {
    if (!auth || !isPreview) return
    return await organizationApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationChatSettingGet({ cache: 'no-store' })
      .then(res => res.data)
  }, [auth, isPreview])
  const swrRes = useSWR(auth && isPreview ? SWRCachePath.getOrganizationUserChatSettings() : null, func)

  return { ...swrRes, isLoading: !swrRes.data && !swrRes.error }
}

export const useUpdateChatSetting = () => {
  const { data: auth } = useAuthenticate()
  const { mutate } = useGetOrganizationChatSetting()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const handleUpdateChatSetting = useCallback(
    async (param: UpdateOrganizationChatSettingRequestOrganizationChatSetting) => {
      if (!auth) return
      setError(undefined)
      try {
        await organizationApi?.ApiFactory(auth.token).userV1OrganizationChatSettingPut({
          updateOrganizationChatSettingRequest: { organizationChatSetting: param }
        })
        setIsLoading(true)
        await mutate()
        Toaster.success('チームのチャット表示設定を更新しました。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Trigger Email' })
      } finally {
        setIsLoading(false)
      }
    },
    [mutate, auth]
  )
  return { error, isLoading, handleUpdateChatSetting }
}

export const useUpdateAvatar = () => {
  const { data: auth } = useAuthenticate()
  const { mutate } = useGetOrganizationChatSetting()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const handleUpdateAvatar = useCallback(
    async (file?: File) => {
      if (!auth) return
      setError(undefined)
      try {
        await organizationApi?.ApiFactory(auth.token).userV1OrganizationChatSettingAvatarPut({ file })
        setIsLoading(true)
        await mutate()
        Toaster.success('チームアイコンを更新しました。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Trigger Email' })
      } finally {
        setIsLoading(false)
      }
    },
    [mutate, auth]
  )
  return { error, isLoading, handleUpdateAvatar }
}

export const useUpdateButtonImage = () => {
  const { data: auth } = useAuthenticate()
  const { mutate } = useGetOrganizationChatSetting()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const handleUpdateButtonImage = useCallback(
    async (file: Blob) => {
      if (!auth) return
      setError(undefined)
      try {
        await organizationApi?.ApiFactory(auth.token).userV1OrganizationChatSettingChatButtonImagePut({
          file
        })
        setIsLoading(true)
        await mutate()
        Toaster.success('チャットボタンの画像を更新しました。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Trigger Email' })
      } finally {
        setIsLoading(false)
      }
    },
    [mutate, auth]
  )
  return { error, isLoading, handleUpdateButtonImage }
}
