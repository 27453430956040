/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumRecordPorterHistoryProgressStatus = {
  Progress: 'progress',
  Complete: 'complete',
  Error: 'error'
} as const
export type EnumRecordPorterHistoryProgressStatus =
  typeof EnumRecordPorterHistoryProgressStatus[keyof typeof EnumRecordPorterHistoryProgressStatus]

export function EnumRecordPorterHistoryProgressStatusFromJSON(json: any): EnumRecordPorterHistoryProgressStatus {
  return EnumRecordPorterHistoryProgressStatusFromJSONTyped(json, false)
}

export function EnumRecordPorterHistoryProgressStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumRecordPorterHistoryProgressStatus {
  return json as EnumRecordPorterHistoryProgressStatus
}

export function EnumRecordPorterHistoryProgressStatusToJSON(value?: EnumRecordPorterHistoryProgressStatus | null): any {
  return value as any
}
