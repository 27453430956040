import React, { useMemo } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography, Tooltip } from 'src/components/atoms'
import { TabValueType } from '../'
import { SelectedCondition } from 'src/components/pages/PageInbox'
import { EnumChatSummaryRepliedStatus } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'

const useStyles = makeStyles(() => {
  return createStyles({
    chip: {
      backgroundColor: Colors.background.gray,
      flexDirection: 'row',
      alignItems: 'center',
      padding: '4px 6px',
      borderRadius: '4px',
      margin: '0 6px 6px 0',
      fontSize: '13px',
      lineHeight: '132%',
      wordBreak: 'break-all'
    }
  })
})

export interface InboxFilteredItems {
  condition: SelectedCondition | undefined
  tabValue: TabValueType
}

export const InboxFilteredItems = ({ condition, tabValue }: InboxFilteredItems) => {
  const classes = useStyles()

  const from = useMemo(() => (tabValue === 'incoming' ? 'latestSendedAtOnFrom' : 'sendedAtOnFrom'), [tabValue])
  const to = useMemo(() => (tabValue === 'incoming' ? 'latestSendedAtOnTo' : 'sendedAtOnTo'), [tabValue])
  const ids = useMemo(() => (tabValue === 'incoming' ? 'userIds' : 'sendUserIds'), [tabValue])

  let fromDate: string | null = useMemo(() => {
    return null
  }, [])
  let endDate: string | null = useMemo(() => {
    return null
  }, [])

  if (condition?.[from]) {
    fromDate = dateFormat(dateStringToMilliseconds(`${condition[from]}`), DateFormat.DATE_WITH_DAY)
    endDate = dateFormat(dateStringToMilliseconds(`${condition[to]}`), DateFormat.DATE_WITH_DAY)
  }

  const [sent, unresponsive, noReplyRequired] = useMemo(() => {
    return [
      condition?.repliedStatuses?.includes(EnumChatSummaryRepliedStatus.Sent),
      condition?.repliedStatuses?.includes(EnumChatSummaryRepliedStatus.Unresponsive),
      condition?.repliedStatuses?.includes(EnumChatSummaryRepliedStatus.NoReplyRequired)
    ]
  }, [condition?.repliedStatuses])

  return (
    <Box mx="14px" mt="28px">
      <Box display="flex" alignItems="center">
        <DynamicMuiIcon variant="filterAlt" size="16px" color="inherit" />
        <Box mr="6px" />
        <Typography fontSize="s" fontWeight="bold" lineheight="14px">
          絞り込み条件
        </Typography>
      </Box>
      {condition?.[ids] && (
        <Box mt="16px">
          <Typography fontSize="xs" fontWeight="bold">
            担当者
          </Typography>
          <Box mt="4px" />
          <Box>
            {condition?.[ids]?.map((name, index) => (
              <Box display="inline-flex" className={classes.chip} key={index}>
                {name}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {condition?.[from] && (
        <Box mt="17px">
          <Typography fontSize="xs" fontWeight="bold">
            期間
          </Typography>
          <Box mt="6px">
            {condition[from] === condition[to] ? (
              <Box display="inline-flex" className={classes.chip}>
                {fromDate!}
              </Box>
            ) : (
              <Box display="inline-flex" className={classes.chip}>
                {fromDate!}〜{endDate!}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {condition?.documentIds && (
        <Box mt="16px">
          <Typography fontSize="xs" fontWeight="bold">
            資料
          </Typography>
          <Box mt="4px" />
          <Box>
            {condition.documentIds.map((name, index) => (
              <Box display="inline-flex" className={classes.chip} key={index}>
                {name}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {condition?.siteIds && (
        <Box mt="16px">
          <Typography fontSize="xs" fontWeight="bold">
            資料サイト
          </Typography>
          <Box mt="4px" />
          <Box>
            {condition.siteIds.map((name, index) => (
              <Box display="inline-flex" className={classes.chip} key={index}>
                {name}
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {condition?.repliedStatuses && (
        <Box mt="16px">
          <Typography fontSize="xs" fontWeight="bold">
            ステータス
          </Typography>
          <Box mt="4px" />
          <Box>
            {sent && (
              <Box display="inline-flex" className={classes.chip}>
                返信済み
              </Box>
            )}
            {unresponsive && (
              <Tooltip
                content={`hover（ステータス）
                  「未対応」・・・新着のチャットメッセージが届くと、自動で付与されるステータスです。返信済み/返信不要のステータスに変更することで、顧客とのコミュニケーション状態が把握できます。`}
              >
                <Box display="inline-flex" className={classes.chip}>
                  未対応
                </Box>
              </Tooltip>
            )}
            {noReplyRequired && (
              <Box display="inline-flex" className={classes.chip}>
                返信不要
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
