/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  SelectorItemObject,
  SelectorItemObjectFromJSON,
  SelectorItemObjectFromJSONTyped,
  SelectorItemObjectToJSON
} from './SelectorItemObject'

/**
 *
 * @export
 * @interface ResponseDataSelectorObjectCommonData
 */
export interface ResponseDataSelectorObjectCommonData {
  /**
   *
   * @type {Array<SelectorItemObject>}
   * @memberof ResponseDataSelectorObjectCommonData
   */
  selectorItemObjects?: Array<SelectorItemObject>
}

export function ResponseDataSelectorObjectCommonDataFromJSON(json: any): ResponseDataSelectorObjectCommonData {
  return ResponseDataSelectorObjectCommonDataFromJSONTyped(json, false)
}

export function ResponseDataSelectorObjectCommonDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResponseDataSelectorObjectCommonData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    selectorItemObjects: !exists(json, 'selector_item_objects')
      ? undefined
      : (json['selector_item_objects'] as Array<any>).map(SelectorItemObjectFromJSON)
  }
}

export function ResponseDataSelectorObjectCommonDataToJSON(value?: ResponseDataSelectorObjectCommonData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    selector_item_objects:
      value.selectorItemObjects === undefined
        ? undefined
        : (value.selectorItemObjects as Array<any>).map(SelectorItemObjectToJSON)
  }
}
