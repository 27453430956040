import React from 'react'
import { Box } from '@material-ui/core'
import { FormikContextType } from 'formik'
import { Colors } from 'src/components/atoms'
import { LabelFormRow } from 'src/components/molecules'
import { RowDataType } from '.'
import { UserProfile } from '@noco/http-client/lib/noco'

export const ProfileRow = ({
  formik,
  label,
  field,
  readElement,
  editElement
}: {
  formik: FormikContextType<UserProfile>
} & RowDataType) => (
  <Box borderBottom={`1px solid ${Colors.background.silver}`} py="16px">
    <LabelFormRow
      label={label}
      readElement={readElement}
      editElement={editElement}
      onSave={formik.handleSubmit}
      onCancel={formik.resetForm}
      canSubmit={field && !!formik.errors[field]}
    />
  </Box>
)
