/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetContactResponseData,
  GetContactResponseDataFromJSON,
  GetContactResponseDataFromJSONTyped,
  GetContactResponseDataToJSON
} from './GetContactResponseData'

/**
 *
 * @export
 * @interface GetContactResponse
 */
export interface GetContactResponse {
  /**
   *
   * @type {number}
   * @memberof GetContactResponse
   */
  status?: number
  /**
   *
   * @type {GetContactResponseData}
   * @memberof GetContactResponse
   */
  data?: GetContactResponseData
}

export function GetContactResponseFromJSON(json: any): GetContactResponse {
  return GetContactResponseFromJSONTyped(json, false)
}

export function GetContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetContactResponseDataFromJSON(json['data'])
  }
}

export function GetContactResponseToJSON(value?: GetContactResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetContactResponseDataToJSON(value.data)
  }
}
