import { Box, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useGlobalStyles } from 'src/styles/theme'
import { Button, Colors, DynamicMuiIcon, MuiIconVariant } from '..'

import { LinkParser } from '../LinkParser'

export interface MenuListItemProps {
  avatar?: string
  kind?: 'chat' | 'appointment' | 'notification' | 'default'
  title?: string
  body?: string
  buttonTitle?: string
  buttonColor?: string
  onClick?: () => void
}

export interface MenuListItemSectionData {
  title: string
  icon: MuiIconVariant
  defaultButtonTitle: string
}

const sectionDataList: { [key: string]: MenuListItemSectionData } = {
  chat: {
    title: 'チャット',
    icon: 'send',
    defaultButtonTitle: 'チャットをはじめる'
  },
  appointment: {
    title: 'アポイント',
    icon: 'meeting',
    defaultButtonTitle: '説明を希望する'
  },
  notification: {
    title: 'お知らせ',
    icon: 'circle',
    defaultButtonTitle: '詳しく見る'
  }
}

export const MenuListItem = ({
  avatar,
  kind = 'default',
  title,
  body,
  onClick,
  buttonTitle,
  buttonColor
}: MenuListItemProps) => {
  const globalClasses = useGlobalStyles()
  const sectionButtonTitle = useMemo(
    () => buttonTitle || sectionDataList[kind]?.defaultButtonTitle,
    [buttonTitle, kind]
  )

  return (
    <Box
      p="18px"
      borderRadius="10px"
      border={`1px solid ${Colors.background.gray}`}
      bgcolor={Colors.background.lightGray}
    >
      {kind !== 'default' && (
        <Box display="flex" alignItems="center" color={Colors.base.middleGray}>
          <DynamicMuiIcon variant={sectionDataList[kind].icon} size="16px" color="inherit" />
          <Box pl="8px" color={Colors.base.black}>
            <Typography variant="subtitle2">{sectionDataList[kind].title}</Typography>
          </Box>
        </Box>
      )}
      <Box display="flex" mt={kind === 'default' ? 0 : '8px'}>
        {avatar && (
          <Box width="36px" height="36px" flexShrink={0} borderRadius="50%" overflow="hidden" mr="8px">
            <img src={avatar} alt="avatar" className={globalClasses.imgCover} />
          </Box>
        )}
        <Box whiteSpace="pre-wrap">
          {title && (
            <Box mb="8px">
              <Typography variant="h4">{title}</Typography>
            </Box>
          )}
          {body && (
            <Typography variant="body2">
              <LinkParser text={body} />
            </Typography>
          )}
        </Box>
      </Box>
      {onClick && sectionButtonTitle && (
        <Box display="flex" justifyContent="center" mt="12px">
          <Button
            title={sectionButtonTitle}
            width="264px"
            onClick={onClick}
            buttonColor={buttonColor || Colors.accent.keyRed.default}
          />
        </Box>
      )}
    </Box>
  )
}
