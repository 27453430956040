/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * @export
 */
export const CreateCompanySettingRequestCompanySettingKindEnum = {
    SingleField: 'single_field',
    MultipleField: 'multiple_field'
};
/**
 * @export
 */
export const CreateCompanySettingRequestCompanySettingStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
};
export function CreateCompanySettingRequestCompanySettingFromJSON(json) {
    return CreateCompanySettingRequestCompanySettingFromJSONTyped(json, false);
}
export function CreateCompanySettingRequestCompanySettingFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: !exists(json, 'name') ? undefined : json['name'],
        icon: !exists(json, 'icon') ? undefined : json['icon'],
        kind: !exists(json, 'kind') ? undefined : json['kind'],
        status: !exists(json, 'status') ? undefined : json['status']
    };
}
export function CreateCompanySettingRequestCompanySettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        icon: value.icon,
        kind: value.kind,
        status: value.status
    };
}
