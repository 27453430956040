/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { FilterContentPins1FromJSON, FilterContentPins1ToJSON } from './FilterContentPins1';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetMaterialListsResponseDataFromJSON(json) {
    return GetMaterialListsResponseDataFromJSONTyped(json, false);
}
export function GetMaterialListsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        materialLists: !exists(json, 'material_lists')
            ? undefined
            : json['material_lists'].map(FilterContentPins1FromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetMaterialListsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        material_lists: value.materialLists === undefined ? undefined : value.materialLists.map(FilterContentPins1ToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
