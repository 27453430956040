import { Box } from '@material-ui/core'
import { DocumentSlide } from '@noco/http-client/lib/noco'
import { useMemo } from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { convertMs } from 'src/fixtures/utils/time'

export interface CustomTooltipProps {
  active?: boolean
  payload?: PayloadItem[]
  label?: string
  slideImages: DocumentSlide['slideImages']
}

export interface PayloadItem {
  name: string
  value: number
  dataKey: 'vt' | 'pv'
}

export const CustomTooltip = ({ active, payload, label, slideImages }: CustomTooltipProps) => {
  const currentImage = useMemo(() => {
    const page = label ? Number(label?.replace('p', '') || 0) : 0
    return slideImages?.[page - 1]?.file?.url || `https://placehold.jp/320x240.png`
  }, [label, slideImages])

  if (active && payload && label) {
    return (
      <Box
        width="280px"
        padding="10px"
        borderRadius="12px"
        border={`1px solid ${Colors.background.gray}`}
        bgcolor="#fff"
        boxShadow="0px 0px 2.117643356323242px 0px #241F4705,0px 8.470573425292969px 16.941146850585938px 0px #241F470D,0px 33.882293701171875px 29.647008895874023px 0px #241F4705,0px 25.411724090576172px 127.05860900878906px 0px #241F471A"
      >
        <Box width={1} height="144px" bgcolor={Colors.background.silver} mb="10px">
          <img
            src={currentImage}
            width="100%"
            height="100%"
            style={{ objectFit: 'contain', objectPosition: '50% 50%' }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color={Colors.base.black}
          borderBottom={`1px solid ${Colors.background.gray}`}
          width={1}
          padding="2px 6px"
          mb="6px"
        >
          <Typography variant="caption">ページ数</Typography>
          <Typography variant="h5">{label}</Typography>
        </Box>
        {payload.map((payloadItem, _index) => {
          if (payloadItem.dataKey === 'vt') {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                color={Colors.base.black}
                borderBottom={`1px solid ${Colors.background.gray}`}
                width={1}
                padding="2px 6px"
                mb="6px"
              >
                <Typography variant="caption">{payloadItem.name}</Typography>
                <Typography variant="h5">{convertMs(payloadItem.value)}</Typography>
              </Box>
            )
          }
          if (payloadItem.dataKey === 'pv') {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                color={Colors.base.black}
                borderBottom={`1px solid ${Colors.background.gray}`}
                width={1}
                padding="2px 6px"
                mb="6px"
              >
                <Typography variant="caption">{payloadItem.name}</Typography>
                <Typography variant="h5">{payloadItem.value}回</Typography>
              </Box>
            )
          }
        })}
      </Box>
    )
  }

  return null
}
