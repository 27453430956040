/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateDocumentOpportunityRequestDocumentOpportunity
 */
export interface UpdateDocumentOpportunityRequestDocumentOpportunity {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentOpportunityRequestDocumentOpportunity
   */
  isView?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentOpportunityRequestDocumentOpportunity
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentOpportunityRequestDocumentOpportunity
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentOpportunityRequestDocumentOpportunity
   */
  buttonName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentOpportunityRequestDocumentOpportunity
   */
  buttonColor?: string
}

export function UpdateDocumentOpportunityRequestDocumentOpportunityFromJSON(
  json: any
): UpdateDocumentOpportunityRequestDocumentOpportunity {
  return UpdateDocumentOpportunityRequestDocumentOpportunityFromJSONTyped(json, false)
}

export function UpdateDocumentOpportunityRequestDocumentOpportunityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentOpportunityRequestDocumentOpportunity {
  if (json === undefined || json === null) {
    return json
  }
  return {
    isView: !exists(json, 'is_view') ? undefined : json['is_view'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    buttonName: !exists(json, 'button_name') ? undefined : json['button_name'],
    buttonColor: !exists(json, 'button_color') ? undefined : json['button_color']
  }
}

export function UpdateDocumentOpportunityRequestDocumentOpportunityToJSON(
  value?: UpdateDocumentOpportunityRequestDocumentOpportunity | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_view: value.isView,
    title: value.title,
    description: value.description,
    button_name: value.buttonName,
    button_color: value.buttonColor
  }
}
