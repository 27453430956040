import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Pdf = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#CE375C" />
      <path
        d="M8.80026 26.5V15.5H13.4562C14.6089 15.5 15.5221 15.816 16.2108 16.4631C16.8994 17.0951 17.2437 17.9528 17.2437 19.0212C17.2437 20.0896 16.8994 20.9473 16.2108 21.5793C15.5221 22.2264 14.6089 22.5424 13.4562 22.5424H11.5998V26.5H8.80026ZM11.5998 20.1498H13.1418C13.561 20.1498 13.8903 20.0595 14.1149 19.8488C14.3394 19.6532 14.4592 19.3824 14.4592 19.0212C14.4592 18.6601 14.3394 18.3892 14.1149 18.1936C13.8903 17.9829 13.561 17.8926 13.1418 17.8926H11.5998V20.1498V20.1498ZM18.3965 26.5V15.5H22.2739C23.0374 15.5 23.756 15.6053 24.4297 15.8311C25.1034 16.0568 25.7172 16.3728 26.2561 16.8092C26.7951 17.2305 27.2292 17.8023 27.5436 18.5246C27.843 19.2469 28.0077 20.0746 28.0077 21.0075C28.0077 21.9254 27.843 22.7531 27.5436 23.4754C27.2292 24.1977 26.7951 24.7695 26.2561 25.1908C25.7172 25.6272 25.1034 25.9432 24.4297 26.1689C23.756 26.3947 23.0374 26.5 22.2739 26.5H18.3965V26.5ZM21.1361 24.1074H21.9446C22.3787 24.1074 22.7829 24.0622 23.1572 23.9569C23.5165 23.8516 23.8608 23.686 24.1902 23.4603C24.5045 23.2346 24.759 22.9186 24.9387 22.4973C25.1183 22.0759 25.2082 21.5793 25.2082 21.0075C25.2082 20.4207 25.1183 19.9241 24.9387 19.5027C24.759 19.0814 24.5045 18.7654 24.1902 18.5397C23.8608 18.314 23.5165 18.1484 23.1572 18.0431C22.7829 17.9378 22.3787 17.8926 21.9446 17.8926H21.1361V24.1074V24.1074ZM29.4149 26.5V15.5H37.1997V17.8926H32.2145V19.6532H36.1967V22.0308H32.2145V26.5H29.4149Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Pdf
