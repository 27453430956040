import { Box, createStyles, makeStyles } from '@material-ui/core'
import { OrganizationPaymentContractPaidInfo } from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Message, Toaster, Typography } from 'src/components/atoms'
import { LayoutPayment } from 'src/components/commons'
import { PaymentMethodCard, PaymentMethodProps } from 'src/components/organisms'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import {
  useGetOrganizationPaymentBillingInfo,
  useUpdateOrganizationPaymentBillingInfoPaid
} from 'src/fixtures/modules/organizationPayment/hooks'

export interface PageContractPaymentMethodProps {}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      padding: '0 24px'
    }
  })
)

export const PageContractPaymentMethod = (_: PageContractPaymentMethodProps) => {
  const classes = useStyles()
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodProps>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { data: dataOrganization } = useGetOrganization()
  const organization = dataOrganization?.organization

  const { data: dataBillingInfo } = useGetOrganizationPaymentBillingInfo()
  const contractPaidInfo = dataBillingInfo?.contractPaidInfo

  const { isLoading, error, handleUpdateBillingInfoPaid } = useUpdateOrganizationPaymentBillingInfoPaid()

  // 初期化
  useEffect(() => {
    if (!contractPaidInfo) return

    setPaymentMethod({
      stripePaymentMethodId: contractPaidInfo.stripePaymentMethodId,
      creditCard: contractPaidInfo.card
    })
  }, [contractPaidInfo])

  const handleChange = useCallback((paymentMethod: PaymentMethodProps) => {
    setPaymentMethod(paymentMethod)
  }, [])

  const handleClick = () => {
    const contractPaidInfoValues: OrganizationPaymentContractPaidInfo = {
      stripePaymentMethodId: paymentMethod?.stripePaymentMethodId,
      card: { last4: paymentMethod?.creditCard?.last4 }
    }
    handleUpdateBillingInfoPaid({ contractBasicInfo: {}, contractPaidInfo: contractPaidInfoValues })
    setIsSubmitting(true)
  }

  // 更新完了時の処理
  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setIsSubmitting(false)
      Toaster.success('お支払い情報を更新しました')
    }
  }, [isSubmitting, isLoading, error])

  // Organization が取得できない、または契約中のプランが有料プランではない場合、クレジットカードの変更はさせない
  // TODO: @enta データの取得は問題ないが、状態によってページにアクセスさせたくない場合の実装方法を確認
  if (!organization || !organization.plan?.paid) return <></>

  return (
    <LayoutPayment
      title="お支払い情報の変更"
      previousPage={{ title: 'ご契約内容へ戻る', path: '/setting/team/contract' }}
    >
      <Box maxWidth="680px" margin="0 auto">
        <Box mb="24px">
          <PaymentMethodCard paymentMethod={paymentMethod} onChange={handleChange} />
        </Box>

        {error && (
          <Box mb="24px" padding="0 24px">
            <Message type="error">
              <Typography fontSize="s" fontWeight="bold">
                {error.message}
              </Typography>
            </Message>
          </Box>
        )}

        <Box display="flex" justifyContent="center" mb="24px">
          <Button
            tabIndex={-1}
            className={classes.submitButton}
            title="更新する"
            onClick={() => !isLoading && handleClick()}
            disabled={isLoading}
            width="217px"
            size="large"
          />
        </Box>
      </Box>
    </LayoutPayment>
  )
}
