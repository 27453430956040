/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { IndexSite, IndexSiteFromJSON, IndexSiteFromJSONTyped, IndexSiteToJSON } from './IndexSite'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetSitesResponseData
 */
export interface GetSitesResponseData {
  /**
   *
   * @type {Array<IndexSite>}
   * @memberof GetSitesResponseData
   */
  sites?: Array<IndexSite>
  /**
   *
   * @type {PageInfo}
   * @memberof GetSitesResponseData
   */
  pageInfo?: PageInfo
}

export function GetSitesResponseDataFromJSON(json: any): GetSitesResponseData {
  return GetSitesResponseDataFromJSONTyped(json, false)
}

export function GetSitesResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSitesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sites: !exists(json, 'sites') ? undefined : (json['sites'] as Array<any>).map(IndexSiteFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetSitesResponseDataToJSON(value?: GetSitesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sites: value.sites === undefined ? undefined : (value.sites as Array<any>).map(IndexSiteToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
