import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Colors, SvgIcon, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { ContactWithAssociation } from '@noco/http-client/lib/noco'
import clsx from 'clsx'

export interface ContactCompanyNameCellProps {
  value: ContactWithAssociation
}

const useStyles = makeStyles(() => {
  return createStyles({
    display: {
      display: 'unset'
    }
  })
})

export const ContactCompanyNameCell = ({ value }: ContactCompanyNameCellProps) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center">
      <Box ml="4px" mr="6px">
        {value?.avatar ? (
          <Box width="40px" height="40px" borderRadius="50%" overflow="hidden" mr="6px" flexShrink={0}>
            <img src={value.avatar.url} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Box>
        ) : (
          <>
            <SvgIcon variant="avatar" size="44px" color={Colors.base.middleGray} />
            <Box width="6px" />
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column" width={1} className={clsx(globalClasses.lineclamp1, classes.display)}>
        <Typography fontSize="xs" lineheight="12px" noWrap>
          {value.company?.displayName}
        </Typography>
        <Box mt="6px" />
        <Typography fontSize="s" lineheight="14px" fontWeight="bold" noWrap>
          {value.displayName}
        </Typography>
      </Box>
    </Box>
  )
}
