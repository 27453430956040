/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumUserPermissionFromJSON, EnumUserPermissionToJSON } from './EnumUserPermission';
export function InviteOrganizationRequestFromJSON(json) {
    return InviteOrganizationRequestFromJSONTyped(json, false);
}
export function InviteOrganizationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
        permission: !exists(json, 'permission') ? undefined : EnumUserPermissionFromJSON(json['permission']),
        urlSpecifiedForDevelopment: !exists(json, 'url_specified_for_development')
            ? undefined
            : json['url_specified_for_development'],
        password: !exists(json, 'password') ? undefined : json['password']
    };
}
export function InviteOrganizationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        encrypted_email: value.encryptedEmail,
        permission: EnumUserPermissionToJSON(value.permission),
        url_specified_for_development: value.urlSpecifiedForDevelopment,
        password: value.password
    };
}
