/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetEuOrganizationPolicySettingsResponseData,
  GetEuOrganizationPolicySettingsResponseDataFromJSON,
  GetEuOrganizationPolicySettingsResponseDataFromJSONTyped,
  GetEuOrganizationPolicySettingsResponseDataToJSON
} from './GetEuOrganizationPolicySettingsResponseData'

/**
 *
 * @export
 * @interface GetEuOrganizationPolicySettingsResponse
 */
export interface GetEuOrganizationPolicySettingsResponse {
  /**
   *
   * @type {number}
   * @memberof GetEuOrganizationPolicySettingsResponse
   */
  status?: number
  /**
   *
   * @type {GetEuOrganizationPolicySettingsResponseData}
   * @memberof GetEuOrganizationPolicySettingsResponse
   */
  data?: GetEuOrganizationPolicySettingsResponseData
}

export function GetEuOrganizationPolicySettingsResponseFromJSON(json: any): GetEuOrganizationPolicySettingsResponse {
  return GetEuOrganizationPolicySettingsResponseFromJSONTyped(json, false)
}

export function GetEuOrganizationPolicySettingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEuOrganizationPolicySettingsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetEuOrganizationPolicySettingsResponseDataFromJSON(json['data'])
  }
}

export function GetEuOrganizationPolicySettingsResponseToJSON(
  value?: GetEuOrganizationPolicySettingsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetEuOrganizationPolicySettingsResponseDataToJSON(value.data)
  }
}
