import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Excel = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#00733B" />
      <path
        d="M20.966 20.7119L16.754 14.6279H20.282L23 19.0919L25.61 14.6279H29.012L24.89 20.6579L29.498 27.3719H25.97L22.856 22.2419L19.832 27.3719H16.502L20.966 20.7119Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Excel
