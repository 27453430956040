/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption,
  GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionFromJSON,
  GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionFromJSONTyped,
  GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionToJSON
} from './GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption'

/**
 *
 * @export
 * @interface GetOrganizationPaymentAddonUserOptionResponseData
 */
export interface GetOrganizationPaymentAddonUserOptionResponseData {
  /**
   *
   * @type {GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption}
   * @memberof GetOrganizationPaymentAddonUserOptionResponseData
   */
  addonUserOption: GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOption
}

export function GetOrganizationPaymentAddonUserOptionResponseDataFromJSON(
  json: any
): GetOrganizationPaymentAddonUserOptionResponseData {
  return GetOrganizationPaymentAddonUserOptionResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationPaymentAddonUserOptionResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentAddonUserOptionResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    addonUserOption: GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionFromJSON(json['addon_user_option'])
  }
}

export function GetOrganizationPaymentAddonUserOptionResponseDataToJSON(
  value?: GetOrganizationPaymentAddonUserOptionResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    addon_user_option: GetOrganizationPaymentAddonUserOptionResponseDataAddonUserOptionToJSON(value.addonUserOption)
  }
}
