import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, SvgIcon, Tooltip, Typography } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { ModalCompanyExport, ModalCompanyRegister, ModalConfirmToPaidPlan, PopupTemplate } from 'src/components/modals'
import { ActionMenu, ActionMenuItemType, MemoizedSearchAndSuggest } from 'src/components/molecules'
import {
  Header,
  Table,
  TableProps,
  FilteringMenu,
  useTablePagination,
  TableColumn,
  FloatingDialog
} from 'src/components/organisms'
import {
  useListCompanies,
  useListFilterCompany,
  useListCompanyLists,
  useCreateCompanyList,
  useUpdateCompanyListsPin,
  useDeleteCompanyList,
  useUpdateCompanyListName,
  useUpdateCompanyList,
  useArchiveCompany,
  useRemoveCompanyListCompanyId
} from 'src/fixtures/modules/company/hooks'
import { useListCompanySettings } from 'src/fixtures/modules/companySetting/hooks'
import { useListStaffLists, useUpdateStaffListsPin } from 'src/fixtures/modules/staffList/hook'
import { useListSelectorCompaniesList } from 'src/fixtures/modules/selector/hooks'
import { convertCompanyTableColumns } from 'src/fixtures/modules/company/utils'
import { UserV1CompaniesGetRequest, Company, EnumListResourceType } from '@noco/http-client/lib/noco'
import dynamic from 'next/dynamic'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
import { ModalSelectTableColumn, ModalSelectTableColumnOption } from 'src/components/modals/ModalSelectTableColumn'
import { localStorageUtils, StorageKey } from 'src/fixtures/utils/localstorage'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid'

const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

interface HeaderRightSectionProps {
  menuItemList: ActionMenuItemType[]
  isImportable: boolean
  onClick: () => void
  onClickImport: () => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    import: {
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${Colors.background.silver}`,
      borderRadius: '4px'
    },
    importButton: {
      padding: '8px 8px 8px 16px'
    },
    action: {
      height: '28px',
      color: Colors.base.middleGray
    }
  })
})

const HeaderRightSection: React.VFC<HeaderRightSectionProps> = ({
  menuItemList,
  isImportable,
  onClick,
  onClickImport
}) => {
  const classes = useStyles()
  return (
    <>
      <Button startIcon={<DynamicMuiIcon variant="add" size="16px" />} title="会社の登録" onClick={onClick} />
      <Box mr="12px" />
      <Box className={classes.import} color={isImportable ? Colors.base.middleGray : Colors.background.silver}>
        <Tooltip content={`Excelファイルの顧客情報を、会社にインポートできます。`}>
          <ButtonBase onClick={onClickImport}>
            <Box className={classes.importButton}>
              <Typography fontWeight={600} fontSize="s" lineheight="14px">
                会社のインポート
              </Typography>
            </Box>
          </ButtonBase>
        </Tooltip>
        <Box width="1px" height="28px" bgcolor={Colors.background.silver} />
        <ActionMenu
          menuItemList={menuItemList}
          icon={
            <Box className={classes.action}>
              <DynamicMuiIcon
                variant="arrowDropDown"
                size="28px"
                htmlColor={isImportable ? 'inherit' : Colors.background.silver}
              />
            </Box>
          }
        />
      </Box>
    </>
  )
}

export const PageCompany = () => {
  const [storagedColumns, setStoragedColumns] = useState<ModalSelectTableColumnOption[]>([])
  const [isOpenModalSelectTableColumn, setIsOpenModalSelectTableColumn] = useState(false)
  const { page, per, totalPages, setTotalPages, changePage, changePerPage, resetPagination } =
    useTablePagination('company')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalExportOpen, setModalExportOpen] = useState(false)
  const [searchParams, setSearchParams] = useState<UserV1CompaniesGetRequest>({ text: '' })
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)
  const [activeId, setActiveId] = useState('1')
  const [activeType, setActiveType] = useState('all')
  const [selection, setSelection] = useState<Array<number>>([])
  const [showNoDataImage, setShowNoDataImage] = useState(false)
  const [reloading, setReloading] = useState(false)
  // MEMO: テーブル再描画用のステート
  const [rerendering, setRerendering] = useState(false)

  const router = useRouter()
  const { data: org } = useGetOrganization()
  const { data: res, mutate } = useListCompanies({ ...searchParams, page: page + 1, per })
  const { data: responseContactSettings } = useListCompanySettings()
  const { data: filterList } = useListFilterCompany()

  const [openImportModalUpgrade, setOpenImportModalUpgrade] = useState(false)
  const [openExportModalUpgrade, setOpenExportModalUpgrade] = useState(false)
  const [openBulkUpdateModalUpgrade, setOpenBulkUpdateModalUpgrade] = useState(false)
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const { hasPermission } = useFeatureFlag('company', 'export')

  useEffect(() => {
    mutate()
  }, [mutate, res])

  const companyList = useMemo(() => {
    return res?.companies || []
  }, [res])

  const columns: TableColumn[] = useMemo(() => {
    if (responseContactSettings?.activeCompanySettings == null) return []

    const activeCompanySettings = [...responseContactSettings?.activeCompanySettings, { id: 'user', name: '担当者' }]
    const activeSettingColumns = convertCompanyTableColumns(activeCompanySettings)

    return activeSettingColumns
  }, [responseContactSettings?.activeCompanySettings])

  const selectedIds = useMemo(() => {
    return companyList
      ? selection.map(rowNumber => {
          return companyList[rowNumber].id as string
        })
      : []
  }, [selection, companyList])

  const [title, labelName] = useMemo(() => {
    let title = activeType === 'archive' ? 'アーカイブから戻す' : 'アーカイブ'
    let labelName = activeType === 'archive' ? 'すべて' : 'アーカイブ'
    return [title, labelName]
  }, [activeType])

  const isExportable = useMemo(() => {
    return hasPermission && planGrade?.plan.canExportCompany
  }, [hasPermission, planGrade?.plan.canExportCompany])

  const handleClick = useCallback(() => setModalOpen(true), [])

  const handleClickImport = useCallback(() => {
    if (!planGrade?.plan.canImportCompany) {
      setOpenImportModalUpgrade(true)
      return
    }
    router.push('/bulk_update?mode=import')
  }, [planGrade?.plan.canImportCompany, router])

  const handleClickExport = useCallback(() => {
    if (!hasPermission) return
    if (!planGrade?.plan.canExportCompany) {
      setOpenExportModalUpgrade(true)
      return
    }
    setModalExportOpen(true)
  }, [hasPermission, planGrade?.plan.canExportCompany])

  const handleClickBulkUpdate = useCallback(() => {
    if (!hasPermission) return
    if (!planGrade?.plan.canExportCompany) {
      setOpenBulkUpdateModalUpgrade(true)
      return
    }
    router.push('/bulk_update?mode=company')
  }, [hasPermission, planGrade?.plan.canExportCompany, router])

  const menuItemList: ActionMenuItemType[] = useMemo(() => {
    let menuList: ActionMenuItemType[] = [
      {
        label: '会社のエクスポート',
        icon: (
          <DynamicMuiIcon
            variant="getApp"
            size="20px"
            htmlColor={isExportable ? Colors.base.middleGray : Colors.background.silver}
          />
        ),
        color: isExportable ? Colors.base.black : Colors.background.silver,
        toolTipText: '会社のリストを、Excelファイルでエクスポートできます。',
        onClick: handleClickExport
      },
      {
        label: '会社の一括編集',
        icon: (
          <SvgIcon
            variant="editNote"
            size="20px"
            color={isExportable ? Colors.base.middleGray : Colors.background.silver}
          />
        ),
        color: isExportable ? Colors.base.black : Colors.background.silver,
        onClick: handleClickBulkUpdate
      }
    ]
    return menuList
  }, [isExportable, handleClickExport, handleClickBulkUpdate])

  const handleClickRow = useCallback(
    (id: string) => {
      router.push('/companies/[id]', `/companies/${id}`)
    },
    [router]
  )

  const handleClearSelection = useCallback(() => {
    setSelection([])
  }, [])

  const handleClickSetActive = useCallback((id: string, type: string) => {
    setActiveId(id)
    setActiveType(type)
  }, [])

  const handleClickCloseModalArchive = useCallback(() => {
    setModalArchiveOpen(false)
    setSelection([])
  }, [])

  const { handleSaveArchived, isLoading: isLoadingArchive } = useArchiveCompany({
    ...searchParams,
    page: page + 1,
    per
  })

  const handleClickModalArchiveConfirm = useCallback(
    (isArchived: boolean) => () => {
      if (!selectedIds || selectedIds.length == 0) {
        return
      }
      handleSaveArchived(selectedIds, isArchived)
      setModalArchiveOpen(false)
      setSelection([])
    },
    [selectedIds, handleSaveArchived]
  )

  const handleChangeSearchParams = useCallback(
    (parameters: UserV1CompaniesGetRequest) => {
      if (!parameters) {
        resetPagination()
        return
      }
      const { page: _page, per: _per, ...otherPrams } = parameters
      resetPagination()
      setSearchParams(otherPrams)
    },
    [resetPagination]
  )

  const { data: CompanyList } = useListCompanyLists()
  const AllCompanyLists = CompanyList?.companyLists

  const { data: staffList } = useListStaffLists({
    resourceType: EnumListResourceType.Company,
    page: 1,
    per: 999
  })
  const allStaffLists = staffList?.staffLists

  const { handleUpdateCompanyListsPin } = useUpdateCompanyListsPin()
  const handleCompanyListsPinConfirm = useCallback(
    (companyListIds: string[]) => {
      handleUpdateCompanyListsPin(companyListIds)
    },
    [handleUpdateCompanyListsPin]
  )
  const { handleUpdateStaffListsPin } = useUpdateStaffListsPin({
    resourceType: EnumListResourceType.Company,
    page: 1,
    per: 999
  })
  const handleStaffListsPinConfirm = useCallback(
    (userIds: string[]) => {
      handleUpdateStaffListsPin(userIds)
    },
    [handleUpdateStaffListsPin]
  )

  const { handleCreateCompanyList, isLoading: isLoadingCreateCompanyList } = useCreateCompanyList()
  const handleCompanyListConfirm = useCallback(
    (listName: string, companyIds: string[]) => {
      handleCreateCompanyList(listName, companyIds)
      setSelection([])
    },
    [handleCreateCompanyList]
  )

  const { handleUpdateCompanyList, isLoading: isLoadingUpdateCompanyList } = useUpdateCompanyList({
    ...searchParams,
    page: page + 1,
    per
  })
  const handleUpdateCompanyListConfirm = useCallback(
    (companyListId: string, companyIds: string[]) => {
      handleUpdateCompanyList(companyListId, companyIds)
      setSelection([])
    },
    [handleUpdateCompanyList]
  )

  const { handleDeleteCompanyList, isLoading: isLoadingDelete } = useDeleteCompanyList()
  const handleDeleteCompanyListConfirm = useCallback(
    (companyListId: string) => {
      handleDeleteCompanyList(companyListId)
    },
    [handleDeleteCompanyList]
  )

  const { handleUpdateCompanyListName } = useUpdateCompanyListName()
  const handleUpdateCompanyNameConfirm = useCallback(
    (companyListId: string, name: string) => {
      handleUpdateCompanyListName(companyListId, name)
    },
    [handleUpdateCompanyListName]
  )

  const searchForm = useMemo(() => {
    return (
      <Box left="312px" position="fixed">
        <MemoizedSearchAndSuggest type="company" width="328px" onClick={handleClickRow} />
      </Box>
    )
  }, [handleClickRow])

  const isShowTable = useMemo(
    () =>
      !reloading &&
      !isLoadingUpdateCompanyList &&
      !isLoadingDelete &&
      !isLoadingCreateCompanyList &&
      !isLoadingArchive &&
      storagedColumns.length > 0 &&
      columns.length > 0,
    [
      columns.length,
      storagedColumns.length,
      isLoadingArchive,
      isLoadingCreateCompanyList,
      isLoadingDelete,
      isLoadingUpdateCompanyList,
      reloading
    ]
  )

  const image = useMemo(() => {
    if (showNoDataImage) {
      return '/images/no_result.png'
    } else ''
  }, [showNoDataImage])

  useEffect(() => {
    if (res?.pageInfo?.totalPages && res?.pageInfo?.totalPages !== totalPages) {
      setTotalPages(res?.pageInfo?.totalPages)
    }
  }, [res?.pageInfo?.totalPages, setTotalPages, totalPages])

  const { handleRemoveCompanyListCompanyId } = useRemoveCompanyListCompanyId({ ...searchParams, page: page + 1, per })
  const handleClickRemoveCompany = useCallback(() => {
    handleRemoveCompanyListCompanyId(activeId, selectedIds)
    setModalArchiveOpen(false)
    setSelection([])
  }, [activeId, selectedIds, handleRemoveCompanyListCompanyId, setModalArchiveOpen, setSelection])

  const onConfirmModalSelectTableColumn = useCallback(
    (result: ModalSelectTableColumnOption[]) => {
      if (org?.organization?.id) {
        localStorageUtils.set(StorageKey.COMPANY_TABLE_COLUMNS(org.organization.id), result)
      }
      // MEMO: テーブルのカラムが増える場合 runtime error を起こす場合があるので、table の再描画前に一度消す
      setRerendering(true)
      setStoragedColumns(result)
      setTimeout(() => {
        setRerendering(false)
      }, 32)
    },
    [org?.organization?.id]
  )

  const handleUpdateTableColumnWidth = useCallback(
    (nextColumnWidths: TableColumnWidthInfo[]) => {
      if (org?.organization?.id) {
        const next: ModalSelectTableColumnOption[] = storagedColumns.map(item => {
          const find = nextColumnWidths.find(i => i.columnName === item.name)
          if (find) {
            return { ...item, width: Number(find.width) }
          }
          return item
        })
        setStoragedColumns(next)
        localStorageUtils.set(StorageKey.COMPANY_TABLE_COLUMNS(org.organization.id), next)
      }
    },
    [org?.organization?.id, storagedColumns]
  )

  const onClickSettings = useCallback(() => {
    router.push('/setting/team/company')
  }, [router])

  useEffect(() => {
    if (!org?.organization?.id) {
      return
    }
    const res = localStorageUtils.get<ModalSelectTableColumnOption[]>(
      StorageKey.COMPANY_TABLE_COLUMNS(org.organization.id)
    )
    if (res) {
      const newStoragedColums = columns
        // MEMO: local に保存したデータにあれば取得、なければ column から生成を行って表示する
        .map(columnItem => {
          const item = res.find(localItem => columnItem.name === localItem.name)
          return item || { ...columnItem, checked: true }
        })
        // MEMO: local に保存した順番に並び替え
        .sort((a, b) => {
          const aIndex = res.findIndex(item => item.name === a.name)
          const bIndex = res.findIndex(item => item.name === b.name)
          if (aIndex === -1 || bIndex === -1) return 0
          return aIndex - bIndex
        })

      setStoragedColumns(newStoragedColums)
    } else {
      setStoragedColumns(columns.map(column => ({ ...column, checked: true })))
    }
  }, [columns, org?.organization?.id])

  return (
    <>
      <Layout>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Box
            bgcolor={Colors.functional.background.default}
            borderBottom={`1px solid ${Colors.background.silver}`}
            position="sticky"
            top={0}
            zIndex={1000}
          >
            <Header
              iconSize="28px"
              icon="business"
              title="会社"
              leftSection={searchForm}
              rightSection={
                <HeaderRightSection
                  menuItemList={menuItemList}
                  isImportable={planGrade?.plan.canImportCompany!}
                  onClick={handleClick}
                  onClickImport={handleClickImport}
                />
              }
              back={false}
            />
          </Box>
          <Box height="100%" flexGrow={1} display="flex" overflow="hidden">
            <FilteringMenu
              filterType="company"
              filter={filterList?.filter}
              activeId={activeId}
              activeType={activeType}
              onClickSetActive={handleClickSetActive}
              onChangeParams={params => {
                handleChangeSearchParams(params)
                setReloading(true)
                setTimeout(() => setReloading(false), 256)
              }}
              filteringListCounts={res?.pageInfo?.totalCount}
              selectedContactIds={selectedIds}
              onClearSelection={handleClearSelection}
              onCreateContactList={handleCompanyListConfirm}
              allCompanyOrContactLists={AllCompanyLists}
              allStaffLists={allStaffLists}
              onListsPinConfirm={handleCompanyListsPinConfirm}
              onStaffListsPinConfirm={handleStaffListsPinConfirm}
              onDeleteList={handleDeleteCompanyListConfirm}
              onUpdateListName={handleUpdateCompanyNameConfirm}
              onSetNoDataImage={setShowNoDataImage}
            />
            <Box pt="16px" flex={1} overflow="auto">
              {!rerendering && isShowTable && (
                <DynamicTable
                  selectable
                  columns={storagedColumns
                    .filter(item => item.checked)
                    .map(({ checked: _checked, name, width }) => {
                      return { ...columns.find(item => item.name === name), width } as TableColumn
                    })
                    .filter(Boolean)}
                  rows={companyList}
                  onClickRow={row => {
                    handleClickRow(row.id!)
                  }}
                  selection={selection}
                  setSelection={setSelection as TableProps<Company>['setSelection']}
                  page={page}
                  per={per}
                  totalPages={totalPages}
                  changePage={page => {
                    changePage(page)
                    setSelection([])
                  }}
                  changePerPage={perPage => {
                    changePerPage(perPage)
                    setSelection([])
                  }}
                  numberOfRows={res?.pageInfo?.totalCount}
                  showNoDataImage={image}
                  showTotalCount
                  onOpenModalSelectTableColumn={() => setIsOpenModalSelectTableColumn(true)}
                  handleUpdateTableColumnWidth={handleUpdateTableColumnWidth}
                />
              )}
            </Box>
            <FloatingDialog
              domain="company"
              selectedIds={selectedIds}
              activeType={activeType}
              setModalArchiveOpen={setModalArchiveOpen}
              setSelection={setSelection}
              onCreateListConfirm={handleCompanyListConfirm}
              onUpdateListConfirm={handleUpdateCompanyListConfirm}
              onClickRemove={handleClickRemoveCompany}
              useList={useListSelectorCompaniesList}
            />
          </Box>
        </Box>
      </Layout>
      <ModalCompanyRegister
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => setModalOpen(false)}
        params={{ ...searchParams, page: page + 1, per }}
      />
      <ModalCompanyExport
        open={modalExportOpen}
        onClose={() => setModalExportOpen(false)}
        onConfirm={() => setModalExportOpen(false)}
      />
      <PopupTemplate
        open={modalArchiveOpen}
        title={`会社を${title}`}
        subTitle={`${selectedIds.length}件  選択中`}
        onClose={handleClickCloseModalArchive}
        hasClose
        hasCancel
        hasConfirm
        onConfirm={handleClickModalArchiveConfirm(activeType === 'archive')}
        cancelButtonLabel="キャンセル"
        confirmButtonLabel="OK"
      >
        <Box p="24px">
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {`選択した会社を${labelName}へ移動します\n本当によろしいですか？`}
          </Typography>
        </Box>
      </PopupTemplate>

      {/* 会社 import */}
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openImportModalUpgrade}
        lightPlanText="ライトプランでは、会社・連絡先のインポートはできません。プレミアムプランなら、会社・連絡先のインポートに加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText=""
        onClose={() => setOpenImportModalUpgrade(false)}
      />

      {/* 会社 export */}
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openExportModalUpgrade}
        lightPlanText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenExportModalUpgrade(false)}
      />

      {/* 会社 一括編集 */}
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openBulkUpdateModalUpgrade}
        lightPlanText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenBulkUpdateModalUpgrade(false)}
      />
      <ModalSelectTableColumn
        initialColumnItems={storagedColumns}
        open={isOpenModalSelectTableColumn}
        onClose={() => setIsOpenModalSelectTableColumn(false)}
        onCancel={() => setIsOpenModalSelectTableColumn(false)}
        onConfirm={onConfirmModalSelectTableColumn}
        onClickSettings={onClickSettings}
      />
    </>
  )
}
