/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumUserPermission,
  EnumUserPermissionFromJSON,
  EnumUserPermissionFromJSONTyped,
  EnumUserPermissionToJSON
} from './EnumUserPermission'

/**
 *
 * @export
 * @interface UpdateOrganizationUserRequestUser
 */
export interface UpdateOrganizationUserRequestUser {
  /**
   *
   * @type {EnumUserPermission}
   * @memberof UpdateOrganizationUserRequestUser
   */
  permission?: EnumUserPermission
}

export function UpdateOrganizationUserRequestUserFromJSON(json: any): UpdateOrganizationUserRequestUser {
  return UpdateOrganizationUserRequestUserFromJSONTyped(json, false)
}

export function UpdateOrganizationUserRequestUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationUserRequestUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    permission: !exists(json, 'permission') ? undefined : EnumUserPermissionFromJSON(json['permission'])
  }
}

export function UpdateOrganizationUserRequestUserToJSON(value?: UpdateOrganizationUserRequestUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    permission: EnumUserPermissionToJSON(value.permission)
  }
}
