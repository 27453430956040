import { useCallback, useState } from 'react'

export const convertFileToBase64 = (file: File): Promise<{ base64: string; fileName: string }> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      resolve({ base64: reader.result as string, fileName: file.name })
    }
    reader.onerror = function (error) {
      reject(error)
    }
  })
}

export const getInputImage = (): Promise<{ base64: string; fileName: string; file: File }> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement
      if (target.files && target.files.length > 0) {
        const file = target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          resolve({ base64: reader.result as string, fileName: file.name, file })
        }
        reader.onerror = function (error) {
          reject(error)
        }
      }
    }
    input.click()
  })
}

export const useInputImage = () => {
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [base64, setBase64] = useState<string | undefined>()
  const [name, setName] = useState('')
  const [imageFile, setImageFile] = useState<File | undefined>()

  const handleInputImage = useCallback(async () => {
    try {
      setIsLoading(true)
      const { base64: inputBase64, fileName, file } = await getInputImage()
      setError(undefined)
      if (file.size > 1000000) throw new Error('画像のアップロードに失敗しました。画像の最大サイズは1MBまでです。')
      setBase64(inputBase64)
      setName(fileName)
      setImageFile(file)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [])
  const clear = useCallback(() => {
    setBase64(undefined)
    setName('')
    setImageFile(undefined)
    setError(undefined)
  }, [])
  return { error, isLoading, handleInputImage, data: base64, name, imageFile, clear }
}
