import React, { useCallback } from 'react'
import { Box, createStyles, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '..'
import { Typography } from '../Typography'

export interface ToggleSwitchProps {
  checked: boolean
  disabled?: boolean
  onLabel?: string
  offLabel?: string
  width?: number
  onChange?: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: ({ width = 58 }: Partial<ToggleSwitchProps>) => width,
      height: '24px',
      padding: '0px',
      '& .MuiSwitch-switchBase.Mui-checked': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '& .MuiIconButton-root': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    switchBase: {
      color: Colors.base.middleGray,
      backgroundColor: 'none',
      padding: '1px',
      '&$hover': {
        backgroundColor: 'none'
      },
      '&$checked': {
        '& + $track': {
          backgroundColor: Colors.accent.keyPurple.default
        }
      },
      '&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: Colors.base.middleGray
      }
    },
    thumb: {
      color: 'white',
      width: '20px',
      height: '20px',
      margin: '1px'
    },
    track: {
      borderRadius: '20px',
      backgroundColor: Colors.base.middleGray,
      opacity: '1 !important'
    },
    checked: {
      transform: ({ width = 58 }: Partial<ToggleSwitchProps>) => `translateX(${width - 24}px) !important`
    },
    cursor: {
      cursor: ({ disabled }: Partial<ToggleSwitchProps>) => (!disabled ? 'pointer' : 'default')
    }
  })
)

export const ToggleSwitch = ({
  checked,
  disabled = false,
  onLabel = 'ON',
  offLabel = 'OFF',
  width = 62,
  onChange
}: ToggleSwitchProps) => {
  const classes = useStyles({ width, disabled })
  const handleChange = useCallback(() => {
    if (!disabled) onChange?.()
  }, [disabled, onChange])

  return (
    <Box
      position="relative"
      onClick={handleChange}
      width={width}
      display="flex"
      height="24px"
      className={classes.cursor}
    >
      <Switch
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        checked={checked}
        disabled={disabled}
      />
      <Box
        position="absolute"
        top={0}
        left={checked ? '8px' : ''}
        right={checked ? '' : '8px'}
        color="white"
        height={1}
        display="flex"
        alignItems="center"
      >
        <Typography variant="h6" fontSize="s" lineheight="14px">
          {checked ? onLabel : offLabel}
        </Typography>
      </Box>
    </Box>
  )
}
