import { Box, createStyles, makeStyles } from '@material-ui/core'
import { ReactNode, useState } from 'react'
import { Colors } from 'src/components/atoms'

interface PagePreviewContentProps {
  header: ReactNode
  children: ReactNode
  isPCPreview?: boolean
}

const useStyles = makeStyles(() => {
  return createStyles({
    spMode: {
      border: '1px solid #EEF1F4',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      borderRadius: '6px',
      margin: '24px auto 64px',
      height: '744px',
      overflowY: 'auto'
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: Colors.background.lightGray
    }
  })
})

export const usePreview = () => {
  const [isPCPreview, setIsPCPreview] = useState(true)
  const onChangePCPreview = () => {
    setIsPCPreview(true)
  }
  const onChangeSPPreview = () => {
    setIsPCPreview(false)
  }
  return {
    isPCPreview,
    onChangePCPreview,
    onChangeSPPreview
  }
}

export const PagePreviewContent = ({ header, children, isPCPreview = true }: PagePreviewContentProps) => {
  const classes = useStyles()
  return (
    <Box position="relative" className={classes.root}>
      {header}
      <Box
        width={isPCPreview ? '100%' : '375px'}
        bgcolor={Colors.functional.background.default}
        className={!isPCPreview ? classes.spMode : ''}
      >
        {children}
      </Box>
    </Box>
  )
}
