/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactForDisplay,
  ContactForDisplayFromJSON,
  ContactForDisplayFromJSONTyped,
  ContactForDisplayToJSON
} from './ContactForDisplay'
import {
  DocumentForDisplay,
  DocumentForDisplayFromJSON,
  DocumentForDisplayFromJSONTyped,
  DocumentForDisplayToJSON
} from './DocumentForDisplay'
import {
  EnumActivityStatus,
  EnumActivityStatusFromJSON,
  EnumActivityStatusFromJSONTyped,
  EnumActivityStatusToJSON
} from './EnumActivityStatus'
import {
  EnumInflowSource,
  EnumInflowSourceFromJSON,
  EnumInflowSourceFromJSONTyped,
  EnumInflowSourceToJSON
} from './EnumInflowSource'
import {
  SiteForDisplay,
  SiteForDisplayFromJSON,
  SiteForDisplayFromJSONTyped,
  SiteForDisplayToJSON
} from './SiteForDisplay'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface ContactAction
 */
export interface ContactAction {
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  contactId?: string
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  documentId?: string
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  siteId?: string
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  userId?: string
  /**
   *
   * @type {EnumActivityStatus}
   * @memberof ContactAction
   */
  activityStatus?: EnumActivityStatus
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  label?: string
  /**
   *
   * @type {number}
   * @memberof ContactAction
   */
  countOfAction?: number
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactAction
   */
  updatedAt?: string
  /**
   *
   * @type {EnumInflowSource}
   * @memberof ContactAction
   */
  inflowSource?: EnumInflowSource
  /**
   *
   * @type {ContactForDisplay}
   * @memberof ContactAction
   */
  contact?: ContactForDisplay
  /**
   *
   * @type {DocumentForDisplay}
   * @memberof ContactAction
   */
  document?: DocumentForDisplay
  /**
   *
   * @type {SiteForDisplay}
   * @memberof ContactAction
   */
  site?: SiteForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof ContactAction
   */
  user?: UserForDisplay
}

export function ContactActionFromJSON(json: any): ContactAction {
  return ContactActionFromJSONTyped(json, false)
}

export function ContactActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactAction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    siteId: !exists(json, 'site_id') ? undefined : json['site_id'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    activityStatus: !exists(json, 'activity_status') ? undefined : EnumActivityStatusFromJSON(json['activity_status']),
    label: !exists(json, 'label') ? undefined : json['label'],
    countOfAction: !exists(json, 'count_of_action') ? undefined : json['count_of_action'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    inflowSource: !exists(json, 'inflow_source') ? undefined : EnumInflowSourceFromJSON(json['inflow_source']),
    contact: !exists(json, 'contact') ? undefined : ContactForDisplayFromJSON(json['contact']),
    document: !exists(json, 'document') ? undefined : DocumentForDisplayFromJSON(json['document']),
    site: !exists(json, 'site') ? undefined : SiteForDisplayFromJSON(json['site']),
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user'])
  }
}

export function ContactActionToJSON(value?: ContactAction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    contact_id: value.contactId,
    document_id: value.documentId,
    site_id: value.siteId,
    user_id: value.userId,
    activity_status: EnumActivityStatusToJSON(value.activityStatus),
    label: value.label,
    count_of_action: value.countOfAction,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    inflow_source: EnumInflowSourceToJSON(value.inflowSource),
    contact: ContactForDisplayToJSON(value.contact),
    document: DocumentForDisplayToJSON(value.document),
    site: SiteForDisplayToJSON(value.site),
    user: UserForDisplayToJSON(value.user)
  }
}
