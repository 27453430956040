/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumChatMessageSendedByType = {
    User: 'User',
    Contact: 'Contact'
};
export function EnumChatMessageSendedByTypeFromJSON(json) {
    return EnumChatMessageSendedByTypeFromJSONTyped(json, false);
}
export function EnumChatMessageSendedByTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumChatMessageSendedByTypeToJSON(value) {
    return value;
}
