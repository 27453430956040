/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetOrganizationUsersResponseFromJSON, RestoreOrganizationUserResponseFromJSON, UpdateOrganizationUserRequestToJSON, UpdateOrganizationUserResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1OrganizationUsersApi extends runtime.BaseAPI {
    /**
     * チームのユーザ一覧を取得する
     * index
     */
    async userV1OrganizationUsersGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.permission !== undefined) {
            queryParameters['permission'] = requestParameters.permission;
        }
        if (requestParameters.includeDiscarded !== undefined) {
            queryParameters['include_discarded'] = requestParameters.includeDiscarded;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization_users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationUsersResponseFromJSON(jsonValue));
    }
    /**
     * チームのユーザ一覧を取得する
     * index
     */
    async userV1OrganizationUsersGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームユーザの削除をする
     * destroy
     */
    async userV1OrganizationUsersUserIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling userV1OrganizationUsersUserIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization_users/{user_id}`.replace(`{${'user_id'}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * チームユーザの削除をする
     * destroy
     */
    async userV1OrganizationUsersUserIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationUsersUserIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームユーザーの設定を更新する
     * update
     */
    async userV1OrganizationUsersUserIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling userV1OrganizationUsersUserIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization_users/{user_id}`.replace(`{${'user_id'}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationUserRequestToJSON(requestParameters.updateOrganizationUserRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationUserResponseFromJSON(jsonValue));
    }
    /**
     * チームユーザーの設定を更新する
     * update
     */
    async userV1OrganizationUsersUserIdPut(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationUsersUserIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームユーザーで論理削除されたユーザのリストア処理
     * restore
     */
    async userV1OrganizationUsersUserIdRestorePutRaw(requestParameters, initOverrides) {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling userV1OrganizationUsersUserIdRestorePut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization_users/{user_id}/restore`.replace(`{${'user_id'}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => RestoreOrganizationUserResponseFromJSON(jsonValue));
    }
    /**
     * チームユーザーで論理削除されたユーザのリストア処理
     * restore
     */
    async userV1OrganizationUsersUserIdRestorePut(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationUsersUserIdRestorePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
