import { Box, CircularProgress, Typography } from '@material-ui/core'
import {
  ContactSetting,
  UpdateSortContactSettingRequestContactSettingsInnerStatusEnum
} from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { ModalCustomFormCreate } from 'src/components/modals'
import { DraggableData } from 'src/components/molecules'
import { Header, SelectAttribute } from 'src/components/organisms'
import { createDraggableSettingList } from 'src/fixtures/modules/contact/utils'
import { useListContactSettings, useSortContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { removeFreeCompanyFromContactSettings } from 'src/fixtures/modules/contactSetting/utils'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'

export const PageTeamContactSetting = () => {
  const [windowReady, setWindowReady] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [targetEditSetting, setTargetEditSetting] = useState<ContactSetting>()
  const [settings, setSettings] = useState<DraggableData[]>([])
  const { hasPermission: isCreatable } = useFeatureFlag('contactSetting', 'create')
  const { hasPermission: isUpdatable } = useFeatureFlag('contactSetting', 'update')
  const { data: responseContactSettings } = useListContactSettings()
  const { handleSortContactSettings, isLoading: isSortLoading } = useSortContactSettings()

  //- NOTE: handleEdit 時に find しやすいように concat
  const rawContactSettings = useMemo(() => {
    if (responseContactSettings)
      return responseContactSettings?.activeContactSettings?.concat(responseContactSettings?.inactiveContactSettings!)
  }, [responseContactSettings])

  const handleClick = useCallback(() => setModalOpen(true), [])

  const handleEdit = useCallback(
    item => {
      setTargetEditSetting(rawContactSettings?.find(setting => setting.id === item.id))
      setModalOpen(true)
    },
    [rawContactSettings]
  )

  const handleClose = useCallback(() => {
    setTargetEditSetting(undefined)
    setModalOpen(false)
  }, [])

  const handleCancel = useCallback(() => {
    setTargetEditSetting(undefined)
    setModalOpen(false)
  }, [])

  useEffect(() => {
    if (responseContactSettings) {
      const settingList = createDraggableSettingList({
        activeContactSettings: removeFreeCompanyFromContactSettings(responseContactSettings.activeContactSettings!),
        inactiveContactSettings: removeFreeCompanyFromContactSettings(responseContactSettings.inactiveContactSettings!)
      })
      setSettings(settingList)
    }
  }, [responseContactSettings])

  useEffect(() => {
    setWindowReady(true)
  }, [])

  return (
    <>
      <LayoutTeam>
        <Box
          height="100%"
          width={1}
          px="16px"
          pb="32px"
          bgcolor={Colors.functional.background.default}
          display="flex"
          flexDirection="column"
        >
          <Header
            textSize="large"
            iconSize="32px"
            icon="listAlt"
            title="連絡先の項目"
            rightSection={
              <Button
                startIcon={<DynamicMuiIcon variant="add" size="16px" />}
                title="新規項目作成"
                disabled={!isCreatable}
                onClick={handleClick}
              />
            }
            back={false}
          />
          <Box mt="16px" px="16px">
            <Typography variant="subtitle1">連絡先に表示する情報を設定できます</Typography>
          </Box>
          <Box
            height={1}
            mt="16px"
            mx="16px"
            borderRadius="6px"
            border={`1px solid ${Colors.background.silver}`}
            overflow={'hidden'}
          >
            {windowReady && !isSortLoading ? (
              <SelectAttribute
                domain="contact"
                items={settings}
                labelLeft="選択可能な項目"
                labelRight="連絡先で表示する項目"
                hasEditPermission={isUpdatable}
                onChange={items => {
                  handleSortContactSettings({
                    contactSettings:
                      items.map((item, idx) => ({
                        id: item.id,
                        sort: idx + 1,
                        status: item.isSelected
                          ? UpdateSortContactSettingRequestContactSettingsInnerStatusEnum.Active
                          : UpdateSortContactSettingRequestContactSettingsInnerStatusEnum.Inactive
                      })) || []
                  })
                }}
                onEdit={handleEdit}
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </LayoutTeam>
      <ModalCustomFormCreate
        domain="contact"
        open={modalOpen}
        defaultValue={targetEditSetting}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={() => setModalOpen(false)}
      />
    </>
  )
}
