/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationPaymentUsage
 */
export interface OrganizationPaymentUsage {
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentUsage
   */
  users: number
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentUsage
   */
  documents: number
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentUsage
   */
  sites: number
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentUsage
   */
  chats?: number
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentUsage
   */
  mailTemplates: number
}

export function OrganizationPaymentUsageFromJSON(json: any): OrganizationPaymentUsage {
  return OrganizationPaymentUsageFromJSONTyped(json, false)
}

export function OrganizationPaymentUsageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPaymentUsage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    users: json['users'],
    documents: json['documents'],
    sites: json['sites'],
    chats: !exists(json, 'chats') ? undefined : json['chats'],
    mailTemplates: json['mail_templates']
  }
}

export function OrganizationPaymentUsageToJSON(value?: OrganizationPaymentUsage | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    users: value.users,
    documents: value.documents,
    sites: value.sites,
    chats: value.chats,
    mail_templates: value.mailTemplates
  }
}
