/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AuthVisitorAuthenticationCodeRequestResponseVisitor,
  AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSON,
  AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSONTyped,
  AuthVisitorAuthenticationCodeRequestResponseVisitorToJSON
} from './AuthVisitorAuthenticationCodeRequestResponseVisitor'

/**
 *
 * @export
 * @interface AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse
 */
export interface AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse {
  /**
   *
   * @type {AuthVisitorAuthenticationCodeRequestResponseVisitor}
   * @memberof AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse
   */
  visitor?: AuthVisitorAuthenticationCodeRequestResponseVisitor
}

export function AuthVisitorAuthenticationCodeRequestWithEmailAddressResponseFromJSON(
  json: any
): AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse {
  return AuthVisitorAuthenticationCodeRequestWithEmailAddressResponseFromJSONTyped(json, false)
}

export function AuthVisitorAuthenticationCodeRequestWithEmailAddressResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    visitor: !exists(json, 'visitor')
      ? undefined
      : AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSON(json['visitor'])
  }
}

export function AuthVisitorAuthenticationCodeRequestWithEmailAddressResponseToJSON(
  value?: AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    visitor: AuthVisitorAuthenticationCodeRequestResponseVisitorToJSON(value.visitor)
  }
}
