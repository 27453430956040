import { Box } from '@material-ui/core'
import { OrganizationPolicySetting } from '@noco/http-client/lib/noco'
import React, { useMemo } from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { PRIVACY_LINK } from 'src/fixtures/utils/constant'

export interface PolicySentenceProps {
  policySettings?: OrganizationPolicySetting[]
  organizationName?: string
}
export const PolicySentence = ({ policySettings, organizationName }: PolicySentenceProps) => {
  const filterdPolicy = useMemo(() => policySettings?.filter(item => !!item.url) || [], [policySettings])

  const nocoPlivacyPolicy = useMemo(
    () => (
      <Typography
        variant="body2"
        fontSize="xs"
        lineheight="relaxed"
        letterSpacing="default"
        style={{ display: 'inline' }}
      >
        <a
          style={{ textDecoration: 'none', color: Colors.accent.keyBlue.default }}
          href={PRIVACY_LINK}
          target="_blank"
          rel="noreferrer"
        >
          プライバシーポリシー
        </a>
      </Typography>
    ),
    []
  )

  // MEMO: @yuokawara Policy等のURLが全て無い場合,名称を表示させない為の処理。
  if (policySettings && filterdPolicy.length === 0)
    return (
      <Box mt="20px" sx={{ display: 'block', textAlign: 'center' }}>
        <Typography
          variant="body2"
          fontSize="xs"
          lineheight="relaxed"
          letterSpacing="default"
          color="inherit"
          style={{ display: 'inline' }}
        >
          サービスの
        </Typography>
        {nocoPlivacyPolicy}
        <Typography
          variant="body2"
          fontSize="xs"
          lineheight="relaxed"
          letterSpacing="default"
          style={{ display: 'inline' }}
        >
          に同意の上で送信してください
        </Typography>
      </Box>
    )
  return policySettings && policySettings.length && filterdPolicy.length > 0 ? (
    <Box mt="20px" sx={{ display: 'block', textAlign: 'center' }}>
      <Typography
        variant="body2"
        fontSize="xs"
        lineheight="relaxed"
        letterSpacing="default"
        color="inherit"
        style={{ display: 'inline' }}
      >
        {organizationName}の
      </Typography>
      {filterdPolicy.map((item, index) => {
        return (
          <>
            <Typography
              key={index}
              variant="body2"
              fontSize="xs"
              lineheight="relaxed"
              letterSpacing="default"
              color="inherit"
              style={{ display: 'inline' }}
            >
              <a
                style={{ textDecoration: 'none', color: Colors.accent.keyBlue.default }}
                href={item.url!}
                target="_blank"
                rel="noreferrer"
              >
                {item.label}
              </a>
            </Typography>
            {filterdPolicy.length - 1 !== index && (
              <Typography
                variant="body2"
                fontSize="xs"
                lineheight="relaxed"
                letterSpacing="default"
                color="inherit"
                style={{ display: 'inline' }}
              >
                ・
              </Typography>
            )}
          </>
        )
      })}
      <Typography
        variant="body2"
        fontSize="xs"
        lineheight="relaxed"
        letterSpacing="default"
        color="inherit"
        style={{ display: 'inline' }}
      >
        、およびサービスの
      </Typography>
      {nocoPlivacyPolicy}
      {Boolean(filterdPolicy.length) && (
        <Typography
          variant="body2"
          fontSize="xs"
          lineheight="relaxed"
          letterSpacing="default"
          style={{ display: 'inline' }}
        >
          に同意の上で送信してください
        </Typography>
      )}
    </Box>
  ) : (
    <Box mt="20px" mb="26px" />
  )
}
