/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumUserPermission = {
  Administrator: 'administrator',
  Manager: 'manager',
  Member: 'member',
  Assistant: 'assistant'
} as const
export type EnumUserPermission = typeof EnumUserPermission[keyof typeof EnumUserPermission]

export function EnumUserPermissionFromJSON(json: any): EnumUserPermission {
  return EnumUserPermissionFromJSONTyped(json, false)
}

export function EnumUserPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumUserPermission {
  return json as EnumUserPermission
}

export function EnumUserPermissionToJSON(value?: EnumUserPermission | null): any {
  return value as any
}
