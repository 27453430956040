import { Box } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { format, isValid } from 'date-fns'
import React, { useMemo } from 'react'
import { Colors, DynamicMuiIcon, IconText, Input, SvgIcon, Typography, Tooltip } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { ja } from 'date-fns/locale'
import { useRouter } from 'next/router'
import { Document, User } from '@noco/http-client/lib/noco'
import { useUpdateDocument } from 'src/fixtures/modules/documents/hooks'
import { publicationStatusIconTextMap, publicationStatusTextMap } from 'src/fixtures/utils/text'
import { DocumentLabelItem } from '../../atoms'
import { DocumentSlideConvertStatusText } from 'src/components/molecules'
import { convertMs } from 'src/fixtures/utils/time'

const useStyles = makeStyles(() =>
  createStyles({
    preview: {
      height: '100%',
      margin: 'auto',
      display: 'block'
    }
  })
)

export interface UpdateInformationProps {
  createdAt: string
  createdBy?: User
  title: string
  tag: string
  document: Document
}

export const DocumentDetail = ({ createdAt, createdBy, tag, title, document }: UpdateInformationProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const router = useRouter()
  const documentId = router.query.documentId as string
  const formattedCreatedAt = useMemo(() => {
    return isValid(new Date(createdAt)) ? format(new Date(createdAt), 'yyyy/MM/dd (E) HH:mm', { locale: ja }) : '-'
  }, [createdAt])

  const { handleUpdateDocument } = useUpdateDocument(document.id!)

  const secondOfAverageBrowsingTime = useMemo(() => {
    const seconds = document.statisticObject?.secondOfAverageBrowsingTime
    return convertMs(seconds ?? 0)
  }, [document.statisticObject])

  return (
    <>
      <Box display="flex">
        <Box mr="20px">
          <Box
            position="relative"
            width="256px"
            height="144px"
            border={`1px solid ${Colors.background.silver}`}
            bgcolor={`${Colors.background.silver}`}
            overflow={'hidden'}
          >
            <img src={String(document.thumbnail?.url)} className={classes.preview} />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            borderRadius="0 0 4px 4px"
            p="6px 8px"
            bgcolor={Colors.base.placeHolder}
            color={Colors.functional.whiteText.default}
          >
            <Typography variant="h6">全{document.currentSlide?.slideImages?.length}ページ</Typography>

            <Box
              onClick={() =>
                window.open(
                  `/documents/${documentId}/preview`,
                  undefined,
                  'width=500,toolbar=yes,menubar=yes,scrollbars=yes'
                )
              }
            >
              <Box display="flex" alignItems="center" className={globalClasses.cursorPointer}>
                <DynamicMuiIcon variant="openNew" size="16px" />
                <Box mr="6px" />
                <Typography variant="h6">プレビュー</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box width={1} maxWidth="1080px">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3">{title}</Typography>
            {document && (
              <>
                <Box width="8px" />
                <DocumentSlideConvertStatusText document={document} />
              </>
            )}
          </Box>

          <Box display="flex" width={1} justifyContent="flex-start">
            <Box width={1} mr="24px">
              <Box display="flex" alignItems="center" flexWrap="wrap" color={Colors.base.black}>
                <Box flexShrink={0} borderRadius="50%" overflow="hidden" width="20px" height="20px" mr="6px" my="8px">
                  <img src={createdBy?.avatar?.url} alt="avatar" className={globalClasses.imgCover} />
                </Box>
                <Typography fontSize="s" lh="none">
                  {createdBy?.lastName}&nbsp;{createdBy?.firstName}
                </Typography>
                <Box mr="12px" />
                <DynamicMuiIcon variant="meeting" size="16px" color="action" />
                <Box mr="6px" />
                <Typography fontSize="s" lh="none">
                  {formattedCreatedAt}
                </Typography>
                <Box mr="12px" />
                {document.publicationStatus && (
                  <Tooltip
                    // eslint-disable-next-line no-irregular-whitespace
                    content={`非公開：顧客は閲覧できません\n限定　：指定した顧客のみが閲覧できます\n誰でも：公開用URLを使って誰でも閲覧できます`}
                  >
                    <IconText
                      icon={publicationStatusIconTextMap[document.publicationStatus]}
                      text={publicationStatusTextMap[document.publicationStatus]}
                    />
                  </Tooltip>
                )}
                <Box mr="12px" />
                <DocumentLabelItem value={tag} />
              </Box>
              <Box display="flex" justifyContent="space-between" width={1} maxWidth="534px" mt="8px">
                <Box width="100%" minWidth="212px" mr="32px">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <DynamicMuiIcon variant="email" size="16px" />
                      <Box mr="6px" />
                      <Tooltip
                        content={`メール機能を使って、資料を送付した顧客の人数です。\n\n資料を閲覧していない顧客も含みます。`}
                      >
                        <Typography variant="caption">メール送付済の人数</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">{document.statisticObject?.countOfContactFromEmail}</Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <DynamicMuiIcon variant="drafts" size="16px" />
                      <Box mr="6px" />
                      <Tooltip content={`メール機能を使って、資料を送付した顧客のうち、閲覧した人数です。`}>
                        <Typography variant="caption">メール経由の閲覧者数</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">{document.statisticObject?.countOfViewedByContactFromEmail}</Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <DynamicMuiIcon variant="drafts" size="16px" />
                      <Box mr="6px" />
                      <Tooltip content={`メール機能を使って、資料を共有した顧客のうち、閲覧した人数の割合です。`}>
                        <Typography variant="caption">メール経由による閲覧率</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">
                      {Math.round((document.statisticObject?.rateOfViewedByContactFromEmail || 0) * 100)}%
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <DynamicMuiIcon variant="link" size="16px" />
                      <Box mr="6px" />
                      <Tooltip
                        content={`公開用URLから、資料を閲覧した人数です。メールアドレスを登録していない閲覧者は別々にカウントされます`}
                      >
                        <Typography variant="caption">公開用URLの閲覧者数</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">{document.statisticObject?.countOfViewedByPublicLink}</Typography>
                  </Box>
                </Box>

                <Box width="100%" minWidth="212px">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <DynamicMuiIcon variant="accessTime" size="16px" />
                      <Box mr="6px" />
                      <Tooltip content={`顧客1人あたりの資料の閲覧時間です。`}>
                        <Typography variant="caption">一人あたりの閲覧時間</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">{secondOfAverageBrowsingTime}</Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <SvgIcon variant="editCalendar" size="16px" color={Colors.base.middleGray} />
                      <Box mr="6px" />
                      <Tooltip
                        content={`アポイント機能を設定した資料から、連携先の日程調整ツールのページに遷移した数です。`}
                      >
                        <Typography variant="caption">アポイントのクリック数</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">{document.statisticObject?.countOfOpportunity}</Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <SvgIcon variant="editCalendar" size="16px" color={Colors.base.middleGray} />
                      <Box mr="6px" />
                      <Tooltip
                        content={`アポイント機能を設定した資料から、連携先の日程調整ツールのページに遷移した顧客の割合です。`}
                      >
                        <Typography variant="caption">アポイントのクリック率</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">
                      {Math.round((document.statisticObject?.rateOfOpportunity || 0) * 100)}%
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    color={Colors.base.middleGray}
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    width={1}
                    mb="6px"
                  >
                    <Box display="flex" alignItems="center">
                      <SvgIcon variant="adsClick" size="16px" color={Colors.base.middleGray} />
                      <Box mr="6px" />
                      <Tooltip
                        content={`CTA機能により、資料上に表示されたポップアップ形式のバナーに設置されたボタンをクリックした回数です。`}
                      >
                        <Typography variant="caption">CTAのクリック数</Typography>
                      </Tooltip>
                    </Box>
                    <Typography variant="h5">{document.statisticObject?.countOfCta}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box width={1} color={Colors.base.black} mt="8px">
              <Box display="flex" alignItems="center" mb="8px">
                <Tooltip content={'社内の情報共有のために利用するメモです。 外部には公開されません。'}>
                  <>
                    <DynamicMuiIcon variant="summarize" size="16px" color="action" />
                    <Box mr="6px" />
                    <Typography fontSize="s" lh="none">
                      社内メモ
                    </Typography>
                  </>
                </Tooltip>
              </Box>
              <Box width={1}>
                <Input
                  name="description"
                  defaultValue={document.memo ?? ''}
                  onBlur={e => handleUpdateDocument({ memo: e.target.value as string })}
                  multiline
                  rows={5}
                  placeholder="メモを入力"
                  width="100%"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
