import React, { useMemo, useCallback, useState } from 'react'
import { Box } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Button, Typography, Tooltip } from 'src/components/atoms'
import { ModalContactCompanyFilter } from 'src/components/modals/ModalContactCompanyFilter'
import { ShowAllList } from './ShowAllList'
import { Label } from './Label'
import { Count } from './Count'
import { Avatar, Dots } from './Icons'
import { ExpandList } from './ExpandList'
import { FilteredItems } from './FilteredItems'
import {
  ContactFilter,
  CompanyFilter,
  UserV1ContactsGetRequest,
  FilterContentPins,
  ContactList,
  CompanyList,
  FilterContentPins1,
  FilterStaffPins1,
  FilterBasicResourceListPins
} from '@noco/http-client/lib/noco'
import { format } from 'date-fns'
import { useDateRangePicker } from 'src/components/atoms/DateRangePicker'

export interface FilteringMenuProps {
  filter: ContactFilter | CompanyFilter | undefined
  filterType: 'contact' | 'company'
  activeId: string
  activeType: string
  onClickSetActive: (id: string, type: string) => void
  onChangeParams: (params: UserV1ContactsGetRequest) => void
  filteringListCounts: number | undefined
  selectedContactIds?: string[]
  onClearSelection: () => void
  onCreateContactList: (listName: string, contactIds: string[]) => void
  allCompanyOrContactLists: ContactList[] | CompanyList[] | undefined
  allDocumentLists?: FilterContentPins1[] | undefined
  allSiteLists?: FilterContentPins1[] | undefined
  allStaffLists: FilterStaffPins1[] | undefined
  onListsPinConfirm: (ids: string[]) => void
  onMaterialListsPinConfirm?: (ids: string[], type?: string) => void
  onStaffListsPinConfirm: (userIds: string[]) => void
  onDeleteList: (contactId: string) => void
  onUpdateListName: (contactListId: string, name: string) => void
  onSetNoDataImage?: (value: boolean) => void
}

const isContactFilter = (
  object: any
): object is Omit<ContactFilter, 'material' | 'contactList'> &
  Required<Pick<ContactFilter, 'material' | 'contactList'>> => {
  return object?.material && object?.contactList
}
const isCompanyFilter = (
  object: any
): object is Omit<CompanyFilter, 'companyList'> & Required<Pick<CompanyFilter, 'companyList'>> => {
  return object?.companyList
}

export const FilteringMenu = ({
  filter,
  filterType,
  activeId,
  activeType,
  onClickSetActive,
  onChangeParams,
  filteringListCounts,
  selectedContactIds,
  onClearSelection,
  allCompanyOrContactLists,
  allDocumentLists,
  allSiteLists,
  allStaffLists,
  onListsPinConfirm,
  onMaterialListsPinConfirm,
  onStaffListsPinConfirm,
  onDeleteList,
  onUpdateListName,
  onSetNoDataImage
}: FilteringMenuProps) => {
  let pinsDocumentList: FilterContentPins[] = []
  let pinsSiteList: FilterContentPins[] = []
  let pinsVariableList: FilterBasicResourceListPins[] = []

  if (isContactFilter(filter)) {
    pinsDocumentList = filter.material.document?.pins as FilterContentPins[]
    pinsSiteList = filter.material.site?.pins as FilterContentPins[]
    pinsVariableList = filter.contactList?.pins as FilterBasicResourceListPins[]
  } else if (isCompanyFilter(filter)) {
    pinsVariableList = filter?.companyList?.pins as FilterBasicResourceListPins[]
  }

  const pinsStaffList = useMemo(() => filter?.staffList?.pins, [filter])
  const [openDocumentList, setOpenDocumentList] = useState(false)
  const [openSiteList, setOpenSiteList] = useState(false)
  const [openModalContactCompanyFilter, setOpenModalContactCompanyFilter] = useState(false)
  const [listValue, setListValue] = useState('')
  const [staffListValue, setStaffListValue] = useState<string[]>([])
  const [createdDateFromTo, setCreateDateFromTo] = useState<string[]>([])
  const [documentListValue, setDocumentListValue] = useState<string[]>([])
  const [siteListValue, setSiteListValue] = useState<string[]>([])
  const [updatedParams, setUpdatedParams] = useState<UserV1ContactsGetRequest>({})
  const [showFilteredItems, setShowFilteredItems] = useState(false)
  const [isShownDots, setIsShownDots] = useState(false)
  const DateRange = useDateRangePicker({})

  const labelList = useMemo(
    () => [
      {
        id: '1',
        label: 'すべて',
        filteredType: 'all',
        count: showFilteredItems && activeType === 'all' ? filteringListCounts : filter?.normal?.counts
      },
      {
        id: '2',
        label: 'アーカイブ',
        filteredType: 'archive',
        count: showFilteredItems && activeType === 'archive' ? filteringListCounts : filter?.archived?.counts
      }
    ],
    [filter, showFilteredItems, filteringListCounts, activeType]
  )

  const filteredItems = useMemo(() => {
    return [listValue, staffListValue, createdDateFromTo, documentListValue, siteListValue]
  }, [listValue, staffListValue, createdDateFromTo, documentListValue, siteListValue])

  const updateParams = useCallback(
    (
      field: 'contactListId' | 'userIds' | 'createdOnFrom' | 'createdOnTo' | 'documentIds' | 'siteIds',
      value: string | string[]
    ) => {
      if (field === 'createdOnFrom' || field === 'createdOnTo') {
        if (activeType === 'archive') {
          setUpdatedParams({ ...updatedParams, createdOnFrom: value[0], createdOnTo: value[1], isArchived: true })
          onChangeParams({ ...updatedParams, createdOnFrom: value[0], createdOnTo: value[1], isArchived: true })
        } else {
          setUpdatedParams({ ...updatedParams, createdOnFrom: value[0], createdOnTo: value[1] })
          onChangeParams({ ...updatedParams, createdOnFrom: value[0], createdOnTo: value[1] })
        }
      } else {
        if (activeType === 'archive') {
          setUpdatedParams({ ...updatedParams, [field]: value as (string & string[]) | undefined, isArchived: true })
          onChangeParams({ ...updatedParams, [field]: value as (string & string[]) | undefined, isArchived: true })
        } else {
          setUpdatedParams({ ...updatedParams, [field]: value as (string & string[]) | undefined })
          onChangeParams({ ...updatedParams, [field]: value as (string & string[]) | undefined })
        }
      }
    },
    [onChangeParams, updatedParams, activeType]
  )

  const handleChangeListValue = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      setListValue(value)
      updateParams('contactListId', value)
    },
    [updateParams]
  )
  const handleChangeStaffListValue = useCallback(
    (value: string[]) => {
      if (value.length > 0) {
        setStaffListValue(value)
        updateParams('userIds', value)
      } else {
        setStaffListValue(value)
        const newUpdatedParams = { ...updatedParams }
        delete newUpdatedParams.userIds
        if (activeType === 'archive') {
          setUpdatedParams({ ...newUpdatedParams, isArchived: true })
          onChangeParams({ ...newUpdatedParams, isArchived: true })
        } else {
          setUpdatedParams({ ...newUpdatedParams })
          onChangeParams({ ...newUpdatedParams })
        }
      }
    },
    [updateParams, onChangeParams, updatedParams, activeType]
  )

  const handleChangeCreatedDateFromTo = useCallback(
    (value: string[]) => {
      setCreateDateFromTo(value)
      updateParams('createdOnFrom', value)
    },
    [updateParams]
  )

  const handleChangeCalendarValue = useCallback(
    value => {
      handleChangeCreatedDateFromTo(
        value && value.startDate ? [format(value.startDate, 'yyyy-MM-dd'), format(value.endDate, 'yyyy-MM-dd')] : []
      )
    },
    [handleChangeCreatedDateFromTo]
  )

  const handleChangeDocumentListValue = useCallback(
    (value: string[]) => {
      if (value.length > 0) {
        setDocumentListValue(value)
        updateParams('documentIds', value)
      } else {
        setDocumentListValue(value)
        const newUpdatedParams = { ...updatedParams }
        delete newUpdatedParams.documentIds
        if (activeType === 'archive') {
          setUpdatedParams({ ...newUpdatedParams, isArchived: true })
          onChangeParams({ ...newUpdatedParams, isArchived: true })
        } else {
          setUpdatedParams({ ...newUpdatedParams })
          onChangeParams({ ...newUpdatedParams })
        }
      }
    },
    [updateParams, onChangeParams, updatedParams, activeType]
  )
  const handleChangeSiteListValue = useCallback(
    (value: string[]) => {
      if (value.length > 0) {
        setSiteListValue(value)
        updateParams('siteIds', value)
      } else {
        setSiteListValue(value)
        const newUpdatedParams = { ...updatedParams }
        delete newUpdatedParams.siteIds
        if (activeType === 'archive') {
          setUpdatedParams({ ...newUpdatedParams, isArchived: true })
          onChangeParams({ ...newUpdatedParams, isArchived: true })
        } else {
          setUpdatedParams({ ...newUpdatedParams })
          onChangeParams({ ...newUpdatedParams })
        }
      }
    },
    [updateParams, onChangeParams, updatedParams, activeType]
  )

  const handleModalOpen = useCallback(() => {
    if (activeType === 'archive') {
      setUpdatedParams({ ...updatedParams, isArchived: true })
    } else {
      setUpdatedParams({ ...updatedParams })
    }
    setOpenModalContactCompanyFilter(true)
    setShowFilteredItems(false)
    onSetNoDataImage?.(false)
  }, [onSetNoDataImage, updatedParams, activeType])

  const handleModalCancel = useCallback(
    (type?: string) => {
      const blankDate = { startDate: undefined, endDate: undefined, key: 'selection' }
      DateRange.setDateRange(blankDate)
      setListValue('')
      setStaffListValue([])
      setCreateDateFromTo([])
      setDocumentListValue([])
      setSiteListValue([])
      if (activeType === 'archive') {
        setUpdatedParams({ isArchived: true })
        onChangeParams({ isArchived: true })
      } else {
        setUpdatedParams({})
        onChangeParams({})
      }
      if (type) {
        setOpenModalContactCompanyFilter(false)
      }
    },
    [onChangeParams, DateRange, activeType]
  )

  const handleModalConfirm = useCallback(() => {
    setOpenModalContactCompanyFilter(false)
    if (filteredItems.length > 0) {
      setShowFilteredItems(true)
      onSetNoDataImage?.(true)
      onClickSetActive(activeId, activeType)
    }
  }, [filteredItems, onClickSetActive, onSetNoDataImage, activeId, activeType])

  const handleDocumentListClick = useCallback(() => {
    setOpenDocumentList(!openDocumentList)
  }, [openDocumentList])

  const handleSiteListClick = useCallback(() => {
    setOpenSiteList(!openSiteList)
  }, [openSiteList])

  const activeTypeMapping = useMemo(() => {
    return {
      document: 'documentId',
      site: 'siteId',
      list: isContactFilter(filter) ? 'contactListId' : 'companyListId',
      staff: 'userId',
      all: null,
      userIds: 'userIds',
      archive: 'isArchived'
    }
  }, [filter])

  type MapKeys = 'document' | 'site' | 'list' | 'staff' | 'all' | 'archive'

  const handleConvertParams = useCallback(
    (type: MapKeys, id: string | string[]) => {
      const convertedParam = activeTypeMapping[type]
      if (!convertedParam) {
        const newUpdatedParams = { ...updatedParams }
        delete newUpdatedParams.isArchived
        setUpdatedParams({ ...newUpdatedParams })
        onChangeParams({})
        return
      } else if (convertedParam === 'isArchived') {
        const params1 = { isArchived: true }
        onChangeParams(params1)
        return
      } else {
        const params2 = { [convertedParam]: id }
        const newUpdatedParams = { ...updatedParams }
        delete newUpdatedParams.isArchived
        setUpdatedParams({ ...newUpdatedParams })
        onChangeParams(params2)
      }
    },
    [activeTypeMapping, onChangeParams, updatedParams]
  )

  const handleSetActive = useCallback(
    (id: string | undefined, type: string) => () => {
      onClickSetActive(id!, type)
      handleConvertParams(type as MapKeys, id as string)
      onClearSelection()
    },
    [handleConvertParams, onClickSetActive, onClearSelection]
  )

  const handleClearShowFilteredItems = useCallback(() => {
    handleModalCancel()
    setShowFilteredItems(false)
    onSetNoDataImage?.(false)
    onClickSetActive(activeId, activeType)
    handleConvertParams(activeType as MapKeys, activeId)
  }, [onClickSetActive, onSetNoDataImage, handleModalCancel, handleConvertParams, activeId, activeType])

  const mappedContactOrCompanyList = useMemo(
    () =>
      allCompanyOrContactLists?.map(list => {
        return {
          ...list,
          text: list.name,
          counts: list.countsOfListItem
        }
      }) ?? [],
    [allCompanyOrContactLists]
  )
  const mappedAllDocumentLists = useMemo(
    () =>
      allDocumentLists?.map(list => {
        return {
          ...list,
          id: list.materialableId
        }
      }) ?? [],
    [allDocumentLists]
  )
  const mappedAllSiteLists = useMemo(
    () =>
      allSiteLists?.map(list => {
        return {
          ...list,
          id: list.materialableId
        }
      }) ?? [],
    [allSiteLists]
  )
  const mappedStaffList = useMemo(
    () =>
      allStaffLists?.map(list => {
        return {
          ...list,
          id: list.userId
        }
      }) ?? [],
    [allStaffLists]
  )

  return (
    <>
      <Box
        width="240px"
        borderRight={`1px solid ${Colors.background.silver}`}
        bgcolor={showFilteredItems ? Colors.functional.background.default : Colors.functional.background.default}
        overflow="auto"
        flexShrink={0}
      >
        <Box p="14px 0 24px 0">
          <Box m="0 14px">
            <Tooltip content={`複数の条件で、${filterType === 'contact' ? '連絡先' : '会社'}を絞り込むことができます`}>
              <Button
                kind={showFilteredItems ? 'primary' : 'neutral'}
                width="100%"
                variant="outlined"
                title="詳細な絞り込み"
                startIcon={<DynamicMuiIcon variant="filterAlt" size="16px" color="inherit" />}
                onClick={handleModalOpen}
                disabled={selectedContactIds && selectedContactIds.length > 0}
              />
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" mt="16px" width="100%">
            {labelList.map(({ label, count, filteredType, id }, i) => {
              return (
                <Label
                  key={i}
                  label={label}
                  rightComponent={<Count count={count} />}
                  active={activeId === id && activeType === filteredType}
                  onClick={handleSetActive(id, filteredType)}
                />
              )
            })}
          </Box>
        </Box>
        <Box borderTop={`1px solid ${Colors.background.silver}`} />
        {showFilteredItems ? (
          <FilteredItems
            listValue={listValue}
            userIdList={staffListValue}
            createdDateFromTo={createdDateFromTo}
            documentIdList={documentListValue}
            siteIdList={siteListValue}
            variableList={allCompanyOrContactLists}
            staffList={allStaffLists}
            documentList={allDocumentLists}
            siteList={allSiteLists}
            isContactFilter={isContactFilter(filter)}
            onClearShowFilteredItems={handleClearShowFilteredItems}
          />
        ) : (
          <Box my="32px">
            {isContactFilter(filter) && (
              <>
                <ExpandList
                  listTitle="コンテンツ"
                  listTitleTip={`共有した資料で、${
                    filterType === 'contact' ? '連絡先' : '会社'
                  }を絞り込むことができます`}
                  subListTitle="資料"
                  modalTitle="すべての資料"
                  pinsList={pinsDocumentList}
                  allCount={allDocumentLists ? allDocumentLists.length : 0}
                  icon="document"
                  open={openDocumentList}
                  onListClick={handleDocumentListClick}
                  activeId={activeId}
                  activeType={activeType}
                  onLabelClick={handleSetActive}
                  allLists={mappedAllDocumentLists}
                  materialableType="document"
                  onMaterialListsPinConfirm={onMaterialListsPinConfirm}
                />
                <Box mt="32px" />
                <ExpandList
                  listTitle="チャネル"
                  listTitleTip={`共有した資料サイトで、${
                    filterType === 'contact' ? '連絡先' : '会社'
                  }を絞り込むことができます`}
                  subListTitle="資料サイト"
                  modalTitle="すべての資料サイト"
                  pinsList={pinsSiteList}
                  allCount={allSiteLists ? allSiteLists.length : 0}
                  icon="computer"
                  open={openSiteList}
                  onListClick={handleSiteListClick}
                  activeId={activeId}
                  activeType={activeType}
                  onLabelClick={handleSetActive}
                  allLists={mappedAllSiteLists}
                  materialableType="site"
                  onMaterialListsPinConfirm={onMaterialListsPinConfirm}
                />
              </>
            )}
            <Box width="fit-content">
              <Tooltip
                content={`選択した${
                  filterType === 'contact' ? '連絡先' : '会社'
                }にリスト名をつけて、作成・管理できます。`}
                placement="right"
              >
                <Box
                  mt="32px"
                  mb="12px"
                  mx="14px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flex={1}
                >
                  <Typography fontSize="m" lineheight="16px" fontWeight="bold">
                    リスト
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
            {pinsVariableList?.map(({ text, id, counts }, i) => {
              return (
                <Box key={i} onMouseEnter={() => setIsShownDots(true)} onMouseLeave={() => setIsShownDots(false)}>
                  <Label
                    label={text}
                    rightComponent={
                      <>
                        {isShownDots ? (
                          <Dots
                            label={text}
                            contactId={id}
                            onUpdateListName={onUpdateListName}
                            onDeleteList={onDeleteList}
                            onShowCount={setIsShownDots}
                          />
                        ) : (
                          <Count count={counts} />
                        )}
                      </>
                    }
                    align="center"
                    active={activeId === id && activeType === 'list'}
                    onClick={handleSetActive(id, 'list')}
                  />
                </Box>
              )
            })}
            <ShowAllList
              allCount={allCompanyOrContactLists ? allCompanyOrContactLists.length : 0}
              title="すべてのリスト"
              variableList={mappedContactOrCompanyList}
              icon="pushPin"
              onPinConfirm={onListsPinConfirm}
            />
            <Box width="fit-content">
              <Tooltip
                content={`担当者で、${filterType === 'contact' ? '連絡先' : '会社'}を絞り込むことができます。`}
                placement="right"
              >
                <Box mt="32px" mb="15px" mx="14px">
                  <Typography fontSize="m" lineheight="16px" fontWeight="bold">
                    担当者
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
            {pinsStaffList?.map(({ text, counts, avatar, userId }, i) => {
              return (
                <Label
                  key={i}
                  label={text}
                  rightComponent={<Count count={counts} />}
                  avatar={<Avatar avatar={avatar?.url} />}
                  active={activeId === userId && activeType === 'staff'}
                  onClick={handleSetActive(userId, 'staff')}
                />
              )
            })}
            <ShowAllList
              allCount={allStaffLists ? allStaffLists.length : 0}
              title="すべての担当者"
              variableList={mappedStaffList}
              icon="pushPin"
              onPinConfirm={onStaffListsPinConfirm}
            />
          </Box>
        )}
      </Box>
      <ModalContactCompanyFilter
        open={openModalContactCompanyFilter}
        onClose={handleModalCancel}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
        list={mappedContactOrCompanyList}
        isContactFilter={isContactFilter(filter)}
        filteringCount={filteringListCounts}
        listValue={listValue}
        staffListValue={staffListValue}
        dateRange={DateRange}
        documentListValue={documentListValue}
        siteListValue={siteListValue}
        onChangeListValue={handleChangeListValue}
        onChangeStaffListValue={handleChangeStaffListValue}
        onChangeDateRange={handleChangeCalendarValue}
        onChangeDocumentListValue={handleChangeDocumentListValue}
        onChangeSiteListValue={handleChangeSiteListValue}
      />
    </>
  )
}
