/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetChatSummariesCountResponseFromJSON, GetChatSummariesResponseFromJSON, GetChatSummariesSendedByUserResponseFromJSON, UpdateChatSummariesChangeUserRequestToJSON, UpdateChatSummariesRepliedRequestToJSON, UpdateChatSummariesRepliedResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ChatSummaryApi extends runtime.BaseAPI {
    /**
     * 受信メッセージから担当者の変更
     * index
     */
    async userV1ChatSummariesChangeUserPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/chat_summaries/change_user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChatSummariesChangeUserRequestToJSON(requestParameters.updateChatSummariesChangeUserRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateChatSummariesRepliedResponseFromJSON(jsonValue));
    }
    /**
     * 受信メッセージから担当者の変更
     * index
     */
    async userV1ChatSummariesChangeUserPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1ChatSummariesChangeUserPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 受信メッセージのカウント
     * count
     */
    async userV1ChatSummariesCountGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.repliedStatus !== undefined) {
            queryParameters['replied_status'] = requestParameters.repliedStatus;
        }
        if (requestParameters.userIds) {
            queryParameters['user_ids[]'] = requestParameters.userIds;
        }
        if (requestParameters.documentIds) {
            queryParameters['document_ids[]'] = requestParameters.documentIds;
        }
        if (requestParameters.siteIds) {
            queryParameters['site_ids[]'] = requestParameters.siteIds;
        }
        if (requestParameters.repliedStatuses) {
            queryParameters['replied_statuses[]'] = requestParameters.repliedStatuses;
        }
        if (requestParameters.latestSendedAtOnFrom !== undefined) {
            queryParameters['latest_sended_at_on_from'] = requestParameters.latestSendedAtOnFrom;
        }
        if (requestParameters.latestSendedAtOnTo !== undefined) {
            queryParameters['latest_sended_at_on_to'] = requestParameters.latestSendedAtOnTo;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/chat_summaries/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetChatSummariesCountResponseFromJSON(jsonValue));
    }
    /**
     * 受信メッセージのカウント
     * count
     */
    async userV1ChatSummariesCountGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1ChatSummariesCountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 受信メッセージの一覧取得
     * index
     */
    async userV1ChatSummariesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.userIds) {
            queryParameters['user_ids[]'] = requestParameters.userIds;
        }
        if (requestParameters.documentIds) {
            queryParameters['document_ids[]'] = requestParameters.documentIds;
        }
        if (requestParameters.siteIds) {
            queryParameters['site_ids[]'] = requestParameters.siteIds;
        }
        if (requestParameters.repliedStatuses) {
            queryParameters['replied_statuses[]'] = requestParameters.repliedStatuses;
        }
        if (requestParameters.latestSendedAtOnFrom !== undefined) {
            queryParameters['latest_sended_at_on_from'] = requestParameters.latestSendedAtOnFrom;
        }
        if (requestParameters.latestSendedAtOnTo !== undefined) {
            queryParameters['latest_sended_at_on_to'] = requestParameters.latestSendedAtOnTo;
        }
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/chat_summaries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetChatSummariesResponseFromJSON(jsonValue));
    }
    /**
     * 受信メッセージの一覧取得
     * index
     */
    async userV1ChatSummariesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1ChatSummariesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 受信メッセージの応答更新
     * replied
     */
    async userV1ChatSummariesRepliedPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/chat_summaries/replied`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChatSummariesRepliedRequestToJSON(requestParameters.updateChatSummariesRepliedRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateChatSummariesRepliedResponseFromJSON(jsonValue));
    }
    /**
     * 受信メッセージの応答更新
     * replied
     */
    async userV1ChatSummariesRepliedPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1ChatSummariesRepliedPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 送信済みメッセージサマリーの一覧取得
     * sended_by_user
     */
    async userV1ChatSummariesSendedByUserGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.sendUserIds) {
            queryParameters['send_user_ids[]'] = requestParameters.sendUserIds;
        }
        if (requestParameters.documentIds) {
            queryParameters['document_ids[]'] = requestParameters.documentIds;
        }
        if (requestParameters.siteIds) {
            queryParameters['site_ids[]'] = requestParameters.siteIds;
        }
        if (requestParameters.sendedAtOnFrom !== undefined) {
            queryParameters['sended_at_on_from'] = requestParameters.sendedAtOnFrom;
        }
        if (requestParameters.sendedAtOnTo !== undefined) {
            queryParameters['sended_at_on_to'] = requestParameters.sendedAtOnTo;
        }
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/chat_summaries/sended_by_user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetChatSummariesSendedByUserResponseFromJSON(jsonValue));
    }
    /**
     * 送信済みメッセージサマリーの一覧取得
     * sended_by_user
     */
    async userV1ChatSummariesSendedByUserGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1ChatSummariesSendedByUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
