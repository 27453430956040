import { differenceInDays } from 'date-fns'
import React, { useMemo } from 'react'
import { PlanRestrictionBanner, PLAN_RESTRICTION_BANNER_HEIGHT } from '../PlanRestrictionBanner'

interface TrialBannerProps {
  trialEndAt?: string | Date
}

export const TRIAL_BANNER_HEIGHT = PLAN_RESTRICTION_BANNER_HEIGHT

export const TrialBanner = ({ trialEndAt }: TrialBannerProps) => {
  const days = useMemo<number>(() => {
    if (!trialEndAt) return 0

    const date = typeof trialEndAt === 'string' ? new Date(trialEndAt) : trialEndAt
    const diff = trialEndAt ? differenceInDays(date, new Date()) : 0
    return diff < 0 ? 0 : diff
  }, [trialEndAt])

  return (
    <PlanRestrictionBanner
      title="プレミアムプランの機能を15日間 無料でご利用いただけます "
      description="プレミアムプランの無料お試し期間終了後、有料プランをご選択いただけます"
      remainingDays={days}
    />
  )
}
