/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumInflowSource = {
  Email: 'email',
  PublicLink: 'public_link'
} as const
export type EnumInflowSource = typeof EnumInflowSource[keyof typeof EnumInflowSource]

export function EnumInflowSourceFromJSON(json: any): EnumInflowSource {
  return EnumInflowSourceFromJSONTyped(json, false)
}

export function EnumInflowSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumInflowSource {
  return json as EnumInflowSource
}

export function EnumInflowSourceToJSON(value?: EnumInflowSource | null): any {
  return value as any
}
