/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AuditFromJSON, AuditToJSON } from './Audit';
import { ContactActionFromJSON, ContactActionToJSON } from './ContactAction';
import { ContactMemoFromJSON, ContactMemoToJSON } from './ContactMemo';
import { EnumTimelineContentTypeFromJSON, EnumTimelineContentTypeToJSON } from './EnumTimelineContentType';
import { UserOperationFromJSON, UserOperationToJSON } from './UserOperation';
export function ContactTimelineFromJSON(json) {
    return ContactTimelineFromJSONTyped(json, false);
}
export function ContactTimelineFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        contentType: !exists(json, 'content_type') ? undefined : EnumTimelineContentTypeFromJSON(json['content_type']),
        contentTypes: !exists(json, 'content_types')
            ? undefined
            : json['content_types'].map(EnumTimelineContentTypeFromJSON),
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        userOperation: !exists(json, 'user_operation') ? undefined : UserOperationFromJSON(json['user_operation']),
        action: !exists(json, 'action') ? undefined : ContactActionFromJSON(json['action']),
        memo: !exists(json, 'memo') ? undefined : ContactMemoFromJSON(json['memo']),
        audit: !exists(json, 'audit') ? undefined : AuditFromJSON(json['audit'])
    };
}
export function ContactTimelineToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        content_type: EnumTimelineContentTypeToJSON(value.contentType),
        content_types: value.contentTypes === undefined
            ? undefined
            : value.contentTypes.map(EnumTimelineContentTypeToJSON),
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        user_operation: UserOperationToJSON(value.userOperation),
        action: ContactActionToJSON(value.action),
        memo: ContactMemoToJSON(value.memo),
        audit: AuditToJSON(value.audit)
    };
}
