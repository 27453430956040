import { Box, Typography } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd'
import { Colors } from 'src/components/atoms'
import { PopupTemplate } from 'src/components/modals'
import { DraggableData, Draggable } from 'src/components/molecules'

export interface SelectAttributeProps {
  items: DraggableData[]
  labelLeft: string
  labelRight: string
  onChange: (items: DraggableData[]) => void
  domain?: 'company' | 'contact'
  showSwitch?: boolean
  switchOnLabel?: string
  switchOffLabel?: string
  hasEditPermission?: boolean
  onEdit?: (item: DraggableData) => void
  disableToggleAccordion?: boolean
}

export const SelectAttribute = ({
  domain,
  items,
  labelLeft,
  labelRight,
  onChange,
  showSwitch,
  switchOnLabel,
  switchOffLabel,
  disableToggleAccordion,
  hasEditPermission = false,
  onEdit
}: SelectAttributeProps) => {
  const [openProhibitionModal, setOpenProhibitionModal] = useState(false)
  const notSelected = useMemo(() => items.filter(item => !item.isSelected), [items])
  const selected = useMemo(() => items.filter(item => item.isSelected), [items])
  const droppableList = useMemo(() => {
    return [
      { id: 'notSelected', width: '284px', items: notSelected },
      { id: 'selected', width: '100%', items: selected }
    ]
  }, [notSelected, selected])

  const moveInSameSpace = useCallback(
    (arg: { sourceIndex: number; destinationIndex: number; list: 'selected' | 'notSelected' }) => {
      const sourceList = arg.list === 'selected' ? selected : notSelected
      const anotherList = arg.list === 'selected' ? notSelected : selected
      const movedItem = sourceList[arg.sourceIndex]
      const newSourceList = [...sourceList]
      newSourceList.splice(arg.sourceIndex, 1)
      newSourceList.splice(arg.destinationIndex, 0, movedItem)
      return [...newSourceList, ...anotherList]
    },
    [notSelected, selected]
  )

  const moveToDifferentSpace = useCallback(
    (arg: { sourceIndex: number; destinationIndex: number; moveTo: 'selected' | 'notSelected' }) => {
      const isSelected = arg.moveTo === 'selected'
      const sourceList = isSelected ? notSelected : selected
      const destinationList = isSelected ? selected : notSelected
      const movedItem = sourceList[arg.sourceIndex]
      // switchDisabled (トグルボタンを切り換えることができない) が true の場合にはセクションを跨いでの移動が禁止されているためエラーを出す
      if (movedItem.switchDisabled) {
        setOpenProhibitionModal(true)
        return [...sourceList, ...destinationList]
      }
      const newItem = { ...movedItem, isSelected }

      const newSourceList = [...sourceList]
      newSourceList.splice(arg.sourceIndex, 1)
      const newDestinationList = [...destinationList]
      newDestinationList.splice(arg.destinationIndex, 0, newItem)
      return [...newSourceList, ...newDestinationList]
    },
    [notSelected, selected]
  )

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result
      if (!destination) return

      let newItems
      const sourceId = source.droppableId
      const destinationId = destination.droppableId as 'selected' | 'notSelected'
      if (sourceId === destinationId) {
        newItems = moveInSameSpace({
          sourceIndex: source.index,
          destinationIndex: destination.index,
          list: destinationId
        })
      } else {
        newItems = moveToDifferentSpace({
          sourceIndex: source.index,
          destinationIndex: destination.index,
          moveTo: destinationId
        })
      }
      onChange(newItems)
    },
    [onChange, moveInSameSpace, moveToDifferentSpace]
  )

  const handleCheck = useCallback(
    (item: DraggableData, checked: boolean) => {
      const newItems = [...items]

      const index = newItems.findIndex(i => i === item)
      const newItem = { ...newItems[index], switchValue: checked }
      newItems[index] = newItem
      onChange(newItems)
    },
    [items, onChange]
  )

  return (
    <>
      <Box display="flex" width={1} height={1} overflow={'hidden'}>
        <DragDropContext onDragEnd={handleDragEnd}>
          {droppableList.map((droppable, i) => (
            <Box
              key={i}
              width={droppable.width}
              flexShrink={i}
              p="16px"
              overflow={'auto'}
              borderRight={i === 0 ? `1px solid ${Colors.background.silver}` : ''}
            >
              <Droppable droppableId={droppable.id}>
                {(provided: DroppableProvided) => (
                  <div ref={provided.innerRef} style={{ height: '100%' }}>
                    <Typography variant="subtitle1">
                      {droppable.id === 'notSelected' ? labelLeft : labelRight}
                    </Typography>
                    <Box height="8px" />
                    {droppable.items.map((item, i) => {
                      return (
                        <Draggable
                          key={`droppable_${i}`}
                          item={item}
                          index={i}
                          droppableId={droppable.id}
                          domain={domain!}
                          onLabel={switchOnLabel}
                          offLabel={switchOffLabel}
                          showSwitch={showSwitch}
                          disabled={!hasEditPermission}
                          switchDisabled={item.switchDisabled}
                          disableToggleAccordion={disableToggleAccordion}
                          hasEditPermission={hasEditPermission}
                          onChange={handleCheck}
                          onEdit={onEdit}
                        />
                      )
                    })}
                    {provided.placeholder}
                    {droppable.id === 'selected' && (
                      <>
                        <Box height="8px" />
                        <Box textAlign="center" color={Colors.base.middleGray}>
                          <Typography variant="subtitle1">ドラッグ＆ドロップで項目を移動できます</Typography>
                        </Box>
                      </>
                    )}
                    <Box height="32px" />
                  </div>
                )}
              </Droppable>
            </Box>
          ))}
        </DragDropContext>
      </Box>

      <PopupTemplate
        open={openProhibitionModal}
        title={'エラー'}
        hasClose
        onClose={() => setOpenProhibitionModal(false)}
        hasCancel
        cancelButtonLabel="閉じる"
      >
        <Box px="24px" pt="24px" pb="32px">
          <Box fontWeight="bold" mb="21px">
            <Typography>別セクションに移動ができない項目です</Typography>
          </Box>
        </Box>
      </PopupTemplate>
    </>
  )
}
