import React from 'react'
import { Typography, TypographyProps } from 'src/components/atoms'

export interface CountProps {
  count: number | null | undefined
  fontSize?: TypographyProps['fontSize']
  lineHeight?: TypographyProps['lineheight']
}

export const Count = ({ count, fontSize = 'xs', lineHeight = '12px' }: CountProps) => {
  return (
    <Typography fontSize={fontSize} lineheight={lineHeight} fontWeight="bold">
      {count}
    </Typography>
  )
}
