/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumChatMessageRepliedStatusFromJSON, EnumChatMessageRepliedStatusToJSON } from './EnumChatMessageRepliedStatus';
import { EnumChatMessageSendedByTypeFromJSON, EnumChatMessageSendedByTypeToJSON } from './EnumChatMessageSendedByType';
import { EnumEmailTransactionableSendStatusFromJSON, EnumEmailTransactionableSendStatusToJSON } from './EnumEmailTransactionableSendStatus';
import { MaterialableFromJSON, MaterialableToJSON } from './Materialable';
import { SendedByFromJSON, SendedByToJSON } from './SendedBy';
export function ShallowChatMessageFromJSON(json) {
    return ShallowChatMessageFromJSONTyped(json, false);
}
export function ShallowChatMessageFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
        documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
        message: !exists(json, 'message') ? undefined : json['message'],
        sendEmailStatus: !exists(json, 'send_email_status')
            ? undefined
            : EnumEmailTransactionableSendStatusFromJSON(json['send_email_status']),
        repliedStatus: !exists(json, 'replied_status')
            ? undefined
            : EnumChatMessageRepliedStatusFromJSON(json['replied_status']),
        sendedByType: !exists(json, 'sended_by_type')
            ? undefined
            : EnumChatMessageSendedByTypeFromJSON(json['sended_by_type']),
        sendedById: !exists(json, 'sended_by_id') ? undefined : json['sended_by_id'],
        readAt: !exists(json, 'read_at') ? undefined : json['read_at'],
        editedAt: !exists(json, 'edited_at') ? undefined : json['edited_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        materialable: !exists(json, 'materialable') ? undefined : MaterialableFromJSON(json['materialable']),
        sendedBy: !exists(json, 'sended_by') ? undefined : SendedByFromJSON(json['sended_by'])
    };
}
export function ShallowChatMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        contact_id: value.contactId,
        document_id: value.documentId,
        message: value.message,
        send_email_status: EnumEmailTransactionableSendStatusToJSON(value.sendEmailStatus),
        replied_status: EnumChatMessageRepliedStatusToJSON(value.repliedStatus),
        sended_by_type: EnumChatMessageSendedByTypeToJSON(value.sendedByType),
        sended_by_id: value.sendedById,
        read_at: value.readAt,
        edited_at: value.editedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        materialable: MaterialableToJSON(value.materialable),
        sended_by: SendedByToJSON(value.sendedBy)
    };
}
