/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Document, DocumentFromJSON, DocumentFromJSONTyped, DocumentToJSON } from './Document'

/**
 *
 * @export
 * @interface CopyDocumentResponseData
 */
export interface CopyDocumentResponseData {
  /**
   *
   * @type {Document}
   * @memberof CopyDocumentResponseData
   */
  document?: Document
}

export function CopyDocumentResponseDataFromJSON(json: any): CopyDocumentResponseData {
  return CopyDocumentResponseDataFromJSONTyped(json, false)
}

export function CopyDocumentResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CopyDocumentResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    document: !exists(json, 'document') ? undefined : DocumentFromJSON(json['document'])
  }
}

export function CopyDocumentResponseDataToJSON(value?: CopyDocumentResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document: DocumentToJSON(value.document)
  }
}
