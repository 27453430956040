import { useCallback } from 'react'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'

export const useListTemplates = () => {
  const { data: auth } = useAuthenticate()
  const templateApi = nocoSDK.client?.userService.mailTemplateApi
  const func = useCallback(async () => {
    return await templateApi
      ?.ApiFactory(auth?.token)
      .userV1MailTemplatesGet({ page: 1, per: 100 })
      .then(res => res.data?.mailTemplates)
  }, [templateApi, auth])

  return useSWR(auth?.token && SWRCachePath.getTemplate(), func)
}
