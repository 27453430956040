/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting
 */
export interface UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting {
  /**
   *
   * @type {boolean}
   * @memberof UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting
   */
  onFirstView?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting
   */
  onView?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting
   */
  onChat?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting
   */
  onCta?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting
   */
  onViewSite?: boolean
}

export function UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingFromJSON(
  json: any
): UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting {
  return UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingFromJSONTyped(json, false)
}

export function UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    onFirstView: !exists(json, 'on_first_view') ? undefined : json['on_first_view'],
    onView: !exists(json, 'on_view') ? undefined : json['on_view'],
    onChat: !exists(json, 'on_chat') ? undefined : json['on_chat'],
    onCta: !exists(json, 'on_cta') ? undefined : json['on_cta'],
    onViewSite: !exists(json, 'on_view_site') ? undefined : json['on_view_site']
  }
}

export function UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingToJSON(
  value?: UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    on_first_view: value.onFirstView,
    on_view: value.onView,
    on_chat: value.onChat,
    on_cta: value.onCta,
    on_view_site: value.onViewSite
  }
}
