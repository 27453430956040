import React, { ReactElement, useMemo, VFC } from 'react'
import { Box, Tooltip as MuiTooltip } from '@material-ui/core'
import { Typography } from 'src/components/atoms'
import { DynamicMuiIcon, MuiIconVariant } from '../DynamicMuiIcon'
import { Colors } from '../Colors'
export interface TooltipProps {
  content: ReactElement | string
  children: ReactElement
  icon?: MuiIconVariant | boolean
  iconColor?: string
  iconSize?: string
  iconPosition?: 'forward' | 'backward'
  arrow?: boolean
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

export const Tooltip: VFC<TooltipProps> = ({
  children,
  content,
  iconPosition = 'backward',
  iconSize = '14px',
  iconColor = Colors.base.middleGray,
  icon,
  arrow = true,
  placement = undefined
}) => {
  const [showForward, showBackward] = useMemo(() => {
    return [icon && iconPosition === 'forward', icon && iconPosition === 'backward']
  }, [iconPosition, icon])

  return (
    <MuiTooltip title={<Typography fontSize="s">{content}</Typography>} arrow={arrow} placement={placement}>
      <Box sx={{ display: 'flex', alignItems: 'center' }} style={{ cursor: 'pointer' }}>
        {showForward && (
          <Box sx={{ display: 'flex', alignItems: 'center', mr: '2px' }}>
            <DynamicMuiIcon variant={typeof icon === 'string' ? icon : 'help'} size={iconSize} />
          </Box>
        )}
        {children}
        {showBackward && (
          <Box sx={{ display: 'flex', alignItems: 'center', ml: '2px' }}>
            <DynamicMuiIcon
              variant={typeof icon === 'string' ? icon : 'help'}
              size={iconSize}
              style={{ color: iconColor }}
            />
          </Box>
        )}
      </Box>
    </MuiTooltip>
  )
}
