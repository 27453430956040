/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactSettingField,
  ContactSettingFieldFromJSON,
  ContactSettingFieldFromJSONTyped,
  ContactSettingFieldToJSON
} from './ContactSettingField'
import {
  EnumSettingSettingType,
  EnumSettingSettingTypeFromJSON,
  EnumSettingSettingTypeFromJSONTyped,
  EnumSettingSettingTypeToJSON
} from './EnumSettingSettingType'
import {
  EnumSettingStatus,
  EnumSettingStatusFromJSON,
  EnumSettingStatusFromJSONTyped,
  EnumSettingStatusToJSON
} from './EnumSettingStatus'
import {
  SettingValueText,
  SettingValueTextFromJSON,
  SettingValueTextFromJSONTyped,
  SettingValueTextToJSON
} from './SettingValueText'

/**
 *
 * @export
 * @interface ContactSetting
 */
export interface ContactSetting {
  /**
   *
   * @type {string}
   * @memberof ContactSetting
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactSetting
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ContactSetting
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof ContactSetting
   */
  icon?: string
  /**
   *
   * @type {number}
   * @memberof ContactSetting
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof ContactSetting
   */
  kind?: string
  /**
   *
   * @type {EnumSettingSettingType}
   * @memberof ContactSetting
   */
  settingType?: EnumSettingSettingType
  /**
   *
   * @type {EnumSettingStatus}
   * @memberof ContactSetting
   */
  status?: EnumSettingStatus
  /**
   *
   * @type {number}
   * @memberof ContactSetting
   */
  numberOfColumns?: number | null
  /**
   *
   * @type {boolean}
   * @memberof ContactSetting
   */
  isRequired?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContactSetting
   */
  isRequiredOnUser?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContactSetting
   */
  isRequiredOnEu?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContactSetting
   */
  canEdit?: boolean
  /**
   *
   * @type {string}
   * @memberof ContactSetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactSetting
   */
  updatedAt?: string
  /**
   *
   * @type {Array<ContactSettingField>}
   * @memberof ContactSetting
   */
  contactSettingFields?: Array<ContactSettingField>
  /**
   *
   * @type {SettingValueText}
   * @memberof ContactSetting
   */
  contactSettingValueText?: SettingValueText | null
}

export function ContactSettingFromJSON(json: any): ContactSetting {
  return ContactSettingFromJSONTyped(json, false)
}

export function ContactSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    label: !exists(json, 'label') ? undefined : json['label'],
    icon: !exists(json, 'icon') ? undefined : json['icon'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    kind: !exists(json, 'kind') ? undefined : json['kind'],
    settingType: !exists(json, 'setting_type') ? undefined : EnumSettingSettingTypeFromJSON(json['setting_type']),
    status: !exists(json, 'status') ? undefined : EnumSettingStatusFromJSON(json['status']),
    numberOfColumns: !exists(json, 'number_of_columns') ? undefined : json['number_of_columns'],
    isRequired: !exists(json, 'is_required') ? undefined : json['is_required'],
    isRequiredOnUser: !exists(json, 'is_required_on_user') ? undefined : json['is_required_on_user'],
    isRequiredOnEu: !exists(json, 'is_required_on_eu') ? undefined : json['is_required_on_eu'],
    canEdit: !exists(json, 'can_edit') ? undefined : json['can_edit'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    contactSettingFields: !exists(json, 'contact_setting_fields')
      ? undefined
      : (json['contact_setting_fields'] as Array<any>).map(ContactSettingFieldFromJSON),
    contactSettingValueText: !exists(json, 'contact_setting_value_text')
      ? undefined
      : SettingValueTextFromJSON(json['contact_setting_value_text'])
  }
}

export function ContactSettingToJSON(value?: ContactSetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    label: value.label,
    icon: value.icon,
    sort: value.sort,
    kind: value.kind,
    setting_type: EnumSettingSettingTypeToJSON(value.settingType),
    status: EnumSettingStatusToJSON(value.status),
    number_of_columns: value.numberOfColumns,
    is_required: value.isRequired,
    is_required_on_user: value.isRequiredOnUser,
    is_required_on_eu: value.isRequiredOnEu,
    can_edit: value.canEdit,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    contact_setting_fields:
      value.contactSettingFields === undefined
        ? undefined
        : (value.contactSettingFields as Array<any>).map(ContactSettingFieldToJSON),
    contact_setting_value_text: SettingValueTextToJSON(value.contactSettingValueText)
  }
}
