/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Company, CompanyFromJSON, CompanyFromJSONTyped, CompanyToJSON } from './Company'

/**
 *
 * @export
 * @interface AppendContactsCompanyResponseData
 */
export interface AppendContactsCompanyResponseData {
  /**
   *
   * @type {Array<Company>}
   * @memberof AppendContactsCompanyResponseData
   */
  companies?: Array<Company>
}

export function AppendContactsCompanyResponseDataFromJSON(json: any): AppendContactsCompanyResponseData {
  return AppendContactsCompanyResponseDataFromJSONTyped(json, false)
}

export function AppendContactsCompanyResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppendContactsCompanyResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companies: !exists(json, 'companies') ? undefined : (json['companies'] as Array<any>).map(CompanyFromJSON)
  }
}

export function AppendContactsCompanyResponseDataToJSON(value?: AppendContactsCompanyResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    companies: value.companies === undefined ? undefined : (value.companies as Array<any>).map(CompanyToJSON)
  }
}
