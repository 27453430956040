import { CreateMaterialableTrackRequest, EnumVisitorMaterialableTrackEvent } from '@noco/http-client/lib/noco'

import { useState, useCallback } from 'react'
import { handleError } from 'src/fixtures/utils/error'
import { useStaticSWR } from 'src/fixtures/utils/swr'
import { useContactAuthentication } from '../contactAuth/hooks'
import { nocoSDK } from '../initialize'

export const useGetViewTrackId = () => {
  return useStaticSWR<string>('getViewTrackId', undefined)
}

export const useCreateTrack = () => {
  const { data: auth } = useContactAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateViewTrackId } = useGetViewTrackId()

  const handleCreateTrack = useCallback(
    async (param: CreateMaterialableTrackRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        const res = await nocoSDK.client?.contactService.materialableTrackApi
          ?.ApiFactory(auth.token)
          .contactV1MaterialableTracksPost({ createMaterialableTrackRequest: param })
        const track = res?.data?.materialableTrack
        if (!track) throw new Error('error create track')
        if (track.event! === EnumVisitorMaterialableTrackEvent.View) {
          mutateViewTrackId(track.id)
        }
      } catch (err) {
        await handleError(err, { setError, tag: 'Create track' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mutateViewTrackId]
  )

  return { error, isLoading, handleCreateTrack }
}

export const usePingViewTrack = () => {
  const { data: auth } = useContactAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: viewTrackId } = useGetViewTrackId()

  const handlePingViewTrack = useCallback(async () => {
    try {
      if (!auth || !viewTrackId) return
      setError(undefined)
      setIsLoading(true)
      await nocoSDK.client?.contactService.materialableTrackApi
        ?.ApiFactory(auth.token)
        .contactV1MaterialableTracksMaterialableTrackIdPingPut({ materialableTrackId: viewTrackId })
    } catch (err) {
      await handleError(err, { setError, tag: 'Ping view track ping' })
    } finally {
      setIsLoading(false)
    }
  }, [auth, viewTrackId])

  return { error, isLoading, handlePingViewTrack }
}
