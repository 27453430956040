/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ResetPasswordResponseData,
  ResetPasswordResponseDataFromJSON,
  ResetPasswordResponseDataFromJSONTyped,
  ResetPasswordResponseDataToJSON
} from './ResetPasswordResponseData'

/**
 *
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
  /**
   *
   * @type {number}
   * @memberof ResetPasswordResponse
   */
  status?: number
  /**
   *
   * @type {ResetPasswordResponseData}
   * @memberof ResetPasswordResponse
   */
  data?: ResetPasswordResponseData
}

export function ResetPasswordResponseFromJSON(json: any): ResetPasswordResponse {
  return ResetPasswordResponseFromJSONTyped(json, false)
}

export function ResetPasswordResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : ResetPasswordResponseDataFromJSON(json['data'])
  }
}

export function ResetPasswordResponseToJSON(value?: ResetPasswordResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: ResetPasswordResponseDataToJSON(value.data)
  }
}
