import { Box, createStyles, IconButton, makeStyles } from '@material-ui/core'
import { Document, EnumVisitorMaterialableTrackEvent } from '@noco/http-client/lib/noco'
import { DynamicMuiIcon } from 'src/components/atoms'
import { useCallback } from 'react'
import axios from 'axios'
import { ModalDocumentSlideShare } from 'src/components/modals'
import { useCreateTrack } from 'src/fixtures/modules/ContactMaterialableTrack/hooks'
import { useGetOpenDocumentSlideHeaderTooltip } from 'src/fixtures/utils/modal'

interface Props {
  document: Document
  isEuView?: boolean
  siteId?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: 8
    }
  })
)

export const DocumentSlideHeaderTooltip: React.VFC<Props> = ({ document, isEuView = false, siteId }) => {
  const classes = useStyles()
  const { data: open, mutate: setOpen } = useGetOpenDocumentSlideHeaderTooltip()

  const handleOpen = useCallback(() => setOpen(true), [setOpen])
  const handleClose = useCallback(() => setOpen(false), [setOpen])

  const { handleCreateTrack } = useCreateTrack()
  const handleDownload = useCallback(async () => {
    if (document.currentSlide?.file?.url == null) return
    if (isEuView) {
      handleCreateTrack({ event: EnumVisitorMaterialableTrackEvent.Download, documentId: document.id, siteId })
    }
    const { data } = await axios.get<Blob>(document.currentSlide?.file?.url, { responseType: 'blob' })
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = window.document.createElement('a')
    link.href = url
    link.setAttribute('download', `${document.title}.pdf`)
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  }, [document.currentSlide?.file?.url, document.id, document.title, handleCreateTrack, isEuView, siteId])

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end" width="210px" height="40px">
        {document.documentMiscSetting?.isShowPrintingButton && (
          <Box>
            <a href={`${document.currentSlide?.file?.url}`} target="_blank" rel="noreferrer">
              <IconButton className={classes.button}>
                <DynamicMuiIcon variant="print" size="24px" />
              </IconButton>
            </a>
          </Box>
        )}

        {document.documentMiscSetting?.isShowDownloadButton && (
          <Box ml="4px">
            <IconButton className={classes.button} onClick={handleDownload}>
              <DynamicMuiIcon variant="fileDownload" size="24px" />
            </IconButton>
          </Box>
        )}

        {document.publicationStatus === 'open' &&
          (document.documentMiscSetting?.isShowShareButton || document.documentMiscSetting?.isShowSnsButton) && (
            <Box ml="4px">
              <IconButton className={classes.button} onClick={handleOpen}>
                <DynamicMuiIcon variant="share" size="24px" />
              </IconButton>
            </Box>
          )}

        {/* // TODO: 暫定対応でコメントアウト https://github.com/nocoinc/noco-sales-issues/issues/881 */}
        {/* <Box ml="4px">
          <IconButton className={classes.button} onClick={handleHelp}>
            <DynamicMuiIcon variant="helpOutline" size="24px" />
          </IconButton>
        </Box> */}

        <ModalDocumentSlideShare document={document} open={open || false} onClose={handleClose} />
      </Box>
    </>
  )
}
