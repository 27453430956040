/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { MaterialableViewHistoryFromJSON, MaterialableViewHistoryToJSON } from './MaterialableViewHistory';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetDocumentViewHistoriesResponseDataFromJSON(json) {
    return GetDocumentViewHistoriesResponseDataFromJSONTyped(json, false);
}
export function GetDocumentViewHistoriesResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        viewHistories: !exists(json, 'view_histories')
            ? undefined
            : json['view_histories'].map(MaterialableViewHistoryFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetDocumentViewHistoriesResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        view_histories: value.viewHistories === undefined
            ? undefined
            : value.viewHistories.map(MaterialableViewHistoryToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
