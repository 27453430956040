import { Box } from '@material-ui/core'
import { OrganizationPaymentContractBasicInfo } from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Message, Toaster, Typography } from 'src/components/atoms'
import { LayoutPayment } from 'src/components/commons'
import { PaymentBillingForm, PaymentBillingPaidFormValue } from 'src/components/organisms'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import {
  useGetOrganizationPaymentBillingInfo,
  useUpdateOrganizationPaymentBillingInfoFree,
  useUpdateOrganizationPaymentBillingInfoPaid
} from 'src/fixtures/modules/organizationPayment/hooks'

export interface PageContractBillingInfomationProps {}

export const PageContractBillingInfomation = (_: PageContractBillingInfomationProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { data: dataOrganization } = useGetOrganization()
  const organization = dataOrganization?.organization

  const { data: dataBillingInfo } = useGetOrganizationPaymentBillingInfo()
  const contractBasicInfo = dataBillingInfo?.contractBasicInfo
  const contractPaidInfo = dataBillingInfo?.contractPaidInfo

  const {
    isLoading: isFreeLoading,
    error: freeError,
    handleUpdateBillingInfoFree
  } = useUpdateOrganizationPaymentBillingInfoFree()
  const {
    isLoading: isPaidLoading,
    error: paidError,
    handleUpdateBillingInfoPaid
  } = useUpdateOrganizationPaymentBillingInfoPaid()

  const hasBillingInfo = useMemo(() => {
    if (organization?.status !== 'active') {
      return false
    } else if (organization?.plan?.paid && contractBasicInfo && contractPaidInfo) {
      return true
    } else if (contractBasicInfo) {
      return true
    } else {
      return false
    }
  }, [contractBasicInfo, contractPaidInfo, organization?.plan?.paid, organization?.status])
  const isLoading = useMemo(() => {
    return isFreeLoading || isPaidLoading
  }, [isFreeLoading, isPaidLoading])
  const error = useMemo(() => {
    return freeError || paidError
  }, [freeError, paidError])
  const isPaid = useMemo<boolean>(() => !!organization?.plan?.paid, [organization?.plan?.paid])

  const handleSubmit = useCallback(
    (contractBasicInfo: OrganizationPaymentContractBasicInfo, contractPaidInfo?: PaymentBillingPaidFormValue) => {
      if (isPaid) {
        handleUpdateBillingInfoPaid({ contractBasicInfo, contractPaidInfo: contractPaidInfo || {} })
      } else {
        handleUpdateBillingInfoFree({ contractBasicInfo })
      }
      setIsSubmitting(true)
    },
    [handleUpdateBillingInfoFree, handleUpdateBillingInfoPaid, isPaid]
  )

  // 更新完了時の処理
  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setIsSubmitting(false)
      Toaster.success('ご契約者情報を更新しました')
    }
  }, [isSubmitting, isLoading, error])

  // Organization が取得できない場合、ページへアクセスさせない
  // TODO: @enta データの取得は問題ないが、状態によってページにアクセスさせたくない場合の実装方法を確認
  if (!organization) return <></>

  return (
    <LayoutPayment
      title="ご契約者情報の変更"
      previousPage={{ title: 'ご契約内容へ戻る', path: '/setting/team/contract' }}
    >
      <Box maxWidth="680px" margin="0 auto">
        <Box>
          {hasBillingInfo && contractBasicInfo && (
            <PaymentBillingForm
              isPaid={isPaid}
              contractBasicInfo={contractBasicInfo}
              contractPaidInfo={contractPaidInfo}
              onSubmit={handleSubmit}
              submitTitle="更新する"
            />
          )}
        </Box>

        {error && (
          <Box mt="24px" padding="0 24px">
            <Message type="error">
              <Typography fontSize="s" fontWeight="bold">
                {error.message}
              </Typography>
            </Message>
          </Box>
        )}
      </Box>
    </LayoutPayment>
  )
}
