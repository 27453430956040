import React, { useState } from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { EnumUserPermission, User } from '@noco/http-client/lib/noco'
import { Colors } from 'src/components/atoms'
import {
  useDeleteOrganizationUser,
  useInviteOrganization,
  useRestoreOrganizationUser,
  useUpdateOrganizationUser
} from 'src/fixtures/modules/organization/hooks'
import { MemberRow } from './MemberRow'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { ModalConfirmToPaidPlan } from 'src/components/modals'

export interface MemberTableProps {
  userList: User[]
  isAdmin?: boolean
}

const items = [
  { id: 'name', label: 'ユーザー', width: '100%' },
  { id: 'email', label: 'メールアドレス', width: '100%' },
  { id: 'permission', label: '権限', width: '100%' },
  { id: 'status', label: 'ステータス', width: '100%' },
  { id: 'access', label: '最終アクセス', width: '100%' }
]

export const MemberTable = ({ userList, isAdmin = false }: MemberTableProps) => {
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { handleInvite } = useInviteOrganization()
  const { handleUpdateOrganizationUser } = useUpdateOrganizationUser()
  const { handleDeleteOrganizationUser } = useDeleteOrganizationUser()
  const { handleRestoreOrganizationUser } = useRestoreOrganizationUser()
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()

  return (
    <>
      <Box width={1} overflow="auto" mt="24px" px="8px">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {items.map((item, i) => (
                <TableCell key={`row_${i}`}>
                  <Box
                    display="flex"
                    alignItems="center"
                    height="34px"
                    color={Colors.base.middleGray}
                    width={item.width}
                  >
                    <Typography variant="h6">{item.label}</Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user, i) => (
              <TableRow key={`user_${i}`}>
                <MemberRow
                  user={user}
                  isAdmin={isAdmin}
                  onChangeRole={role => {
                    if (!planGrade?.plan.canManageUserPermission) {
                      setOpenModalUpgrade(true)
                      return
                    }
                    handleUpdateOrganizationUser(user.id!, { user: { permission: role as EnumUserPermission } })
                  }}
                  onResend={(_, password) => {
                    handleInvite([{ email: user.encryptedEmail, permission: user.permission, password }])
                  }}
                  onDelete={() => {
                    handleDeleteOrganizationUser(user.id!)
                  }}
                  onRestore={() => {
                    handleRestoreOrganizationUser(user.id!)
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、ユーザー管理権限の設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、ユーザー管理権限の設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
}
