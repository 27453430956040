/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumUserOperationDisplayObjectItemResourceType,
  EnumUserOperationDisplayObjectItemResourceTypeFromJSON,
  EnumUserOperationDisplayObjectItemResourceTypeFromJSONTyped,
  EnumUserOperationDisplayObjectItemResourceTypeToJSON
} from './EnumUserOperationDisplayObjectItemResourceType'

/**
 *
 * @export
 * @interface UserOperationDisplayObjectItem
 */
export interface UserOperationDisplayObjectItem {
  /**
   *
   * @type {EnumUserOperationDisplayObjectItemResourceType}
   * @memberof UserOperationDisplayObjectItem
   */
  resourceType?: EnumUserOperationDisplayObjectItemResourceType
  /**
   *
   * @type {string}
   * @memberof UserOperationDisplayObjectItem
   */
  resourceId?: string
  /**
   *
   * @type {string}
   * @memberof UserOperationDisplayObjectItem
   */
  text?: string
}

export function UserOperationDisplayObjectItemFromJSON(json: any): UserOperationDisplayObjectItem {
  return UserOperationDisplayObjectItemFromJSONTyped(json, false)
}

export function UserOperationDisplayObjectItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserOperationDisplayObjectItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    resourceType: !exists(json, 'resource_type')
      ? undefined
      : EnumUserOperationDisplayObjectItemResourceTypeFromJSON(json['resource_type']),
    resourceId: !exists(json, 'resource_id') ? undefined : json['resource_id'],
    text: !exists(json, 'text') ? undefined : json['text']
  }
}

export function UserOperationDisplayObjectItemToJSON(value?: UserOperationDisplayObjectItem | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    resource_type: EnumUserOperationDisplayObjectItemResourceTypeToJSON(value.resourceType),
    resource_id: value.resourceId,
    text: value.text
  }
}
