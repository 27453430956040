/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AppendCompanyListRequest
 */
export interface AppendCompanyListRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof AppendCompanyListRequest
   */
  companyIds?: Array<string>
}

export function AppendCompanyListRequestFromJSON(json: any): AppendCompanyListRequest {
  return AppendCompanyListRequestFromJSONTyped(json, false)
}

export function AppendCompanyListRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppendCompanyListRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyIds: !exists(json, 'company_ids') ? undefined : json['company_ids']
  }
}

export function AppendCompanyListRequestToJSON(value?: AppendCompanyListRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_ids: value.companyIds
  }
}
