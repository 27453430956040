/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetOrganizationLabelSettingResponseData
 */
export interface GetOrganizationLabelSettingResponseData {
  /**
   *
   * @type {Array<string>}
   * @memberof GetOrganizationLabelSettingResponseData
   */
  documentLabels: Array<string>
}

export function GetOrganizationLabelSettingResponseDataFromJSON(json: any): GetOrganizationLabelSettingResponseData {
  return GetOrganizationLabelSettingResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationLabelSettingResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationLabelSettingResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentLabels: json['document_labels']
  }
}

export function GetOrganizationLabelSettingResponseDataToJSON(
  value?: GetOrganizationLabelSettingResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_labels: value.documentLabels
  }
}
