import { Box } from '@material-ui/core'
import { OrganizationPaymentContractBasicInfo, OrganizationPaymentContractPaidInfo } from '@noco/http-client/lib/noco'
import React, { useMemo } from 'react'
import { PrefectureList } from 'src/fixtures/utils/prefecture'
import { ContentRow } from './ContentRow'

export interface PaymentBillingDetailProps {
  isPaid?: boolean
  contractBasicInfo: OrganizationPaymentContractBasicInfo
  contractPaidInfo?: OrganizationPaymentContractPaidInfo
}

export const PaymentBillingDetail = ({
  isPaid = false,
  contractBasicInfo,
  contractPaidInfo
}: PaymentBillingDetailProps) => {
  const dasherizedPostalCode = useMemo(() => {
    const postalCode = contractBasicInfo.postalCode
    if (!postalCode) return ''

    return postalCode.substring(0, 3) + '-' + postalCode.substring(3)
  }, [contractBasicInfo.postalCode])

  const address = useMemo(() => {
    const { prefectureCode, city, line1, line2 } = contractBasicInfo
    const prefecture = PrefectureList.find(item => item.code === prefectureCode)?.name
    return `${prefecture || ''} ${city || ''} ${line1 || ''} ${line2 || ''}`
  }, [contractBasicInfo])

  const fullName = useMemo(() => {
    return `${contractBasicInfo.lastName} ${contractBasicInfo.firstName}`
  }, [contractBasicInfo.firstName, contractBasicInfo.lastName])

  return (
    <Box width="100%">
      <ContentRow label="会社名" value={contractBasicInfo.companyName} />
      <ContentRow label="郵便番号" value={dasherizedPostalCode} />
      <ContentRow label="住所" value={address} />
      <ContentRow label="ご担当者さまの氏名" value={fullName} />
      <ContentRow label="ご担当者さまの電話番号" value={contractBasicInfo.phoneNumber} />
      <ContentRow label="ご担当者さまのメールアドレス" value={contractBasicInfo.contactEmail} />
      {isPaid && contractPaidInfo?.billingEmail && (
        <ContentRow label="領収書送付先のメールアドレス" value={contractPaidInfo?.billingEmail} />
      )}
    </Box>
  )
}
