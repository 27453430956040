import React from 'react'
import { createStyles, makeStyles, Typography, Box, Modal, Slide } from '@material-ui/core'
import { Colors, Pallete } from '../../atoms/Colors'
import { Button } from '../../atoms/Button'

interface ModalTeamDeleteConfirmationProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
  onSubmit: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    lineBreak: {
      whiteSpace: 'pre-line'
    }
  })
)

export const ModalTeamDeleteConfirmation = ({
  open,
  onClose,
  onCancel,
  onSubmit
}: ModalTeamDeleteConfirmationProps) => {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minWidth="580px"
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.silver}`}
        >
          <Box p={6}>
            <Typography variant="body2" className={classes.lineBreak}>
              チームを削除します。{`\n`}よろしいですか？
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            py={3}
            bgcolor={Colors.background.lightGray}
            borderRadius="0 0 10px 10px"
            boxShadow={`inset 0px 1px 0px ${Colors.background.silver}`}
          >
            <Box display="flex" mr="24px">
              <Button kind="neutral" variant="outlined" title="キャンセル" onClick={onCancel} />
              <Box mr="12px" />
              <Button kind="negative" title="OK" onClick={onSubmit} />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
