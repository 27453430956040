import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  createStyles,
  makeStyles
} from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Draggable as DndDraggable } from 'react-beautiful-dnd'
import {
  Colors,
  DynamicMuiIcon,
  IconVariant,
  MuiIconMap,
  MuiIconVariant,
  SvgIcon,
  ToggleSwitch,
  Typography
} from 'src/components/atoms'
import { ModalConfirmDeleteSetting } from 'src/components/modals'
import { useDeleteCompanySettings } from 'src/fixtures/modules/companySetting/hooks'
import { useDeleteContactSettings } from 'src/fixtures/modules/contactSetting/hooks'

export interface DraggableData {
  id: string
  label: string
  isSelected: boolean
  sort: number
  icon: MuiIconVariant | IconVariant
  description?: string
  switchValue?: boolean
  // switch を操作することが許されない + 別セクションへの移動ができない
  switchDisabled?: boolean
  isListType?: boolean
  canEdit?: boolean
  // editor ボタンを周りを消す
  hideEdit?: boolean
}

export interface DraggableProps {
  item: DraggableData
  index: number
  droppableId: string
  domain: 'company' | 'contact'
  showSwitch?: boolean
  disabled?: boolean
  switchDisabled?: boolean
  hasEditPermission?: boolean
  onLabel?: string
  offLabel?: string
  onChange?: (item: DraggableData, checked: boolean) => void
  onEdit?: (item: DraggableData) => void
  disableToggleAccordion?: boolean
}

export const DraggableContent = ({ item }: Pick<DraggableProps, 'item'>) => {
  return (
    <Box display="flex" alignItems="center">
      <Box color={Colors.base.placeHolder} display="flex" alignItems="center">
        <DynamicMuiIcon variant="dragIndicator" size="16px" color="inherit" />
      </Box>
      <Box width="6px" />
      {item.icon in MuiIconMap ? (
        <DynamicMuiIcon variant={item.icon as MuiIconVariant} size="20px" />
      ) : (
        <SvgIcon variant={(item.icon as IconVariant) || 'avatar'} size="20px" color={Colors.base.black} />
      )}
      <Box width="6px" />
      <Typography fontSize="s" lh="tight" letterSpacing="normal">
        {item.label}
      </Typography>
    </Box>
  )
}

export interface AccordionStyleData extends Pick<DraggableProps, 'droppableId'> {
  expanded: boolean
}
const useStyles = makeStyles(() => {
  return createStyles({
    accordionRoot: {
      boxShadow: 'none'
    },
    accordionSummary: ({ droppableId, expanded }: AccordionStyleData) => ({
      border: `1px solid ${Colors.background.silver}`,
      backgroundColor:
        droppableId === 'notSelected' ? Colors.background.lightGray : Colors.functional.background.default,
      borderRadius: expanded ? '4px 4px 0 0' : '4px',
      padding: '0 8px 0 12px',
      minHeight: '34px !important',
      '& .MuiAccordionSummary-content': {
        margin: '0'
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '0'
      },
      '& .MuiAccordionSummary-expandIcon': {
        padding: '8px 12px',
        '&:hover': {
          backgroundColor: 'transparent !important'
        }
      }
    }),
    accordionDetail: ({ droppableId }: AccordionStyleData) => ({
      borderLeft: `1px solid ${Colors.background.silver}`,
      borderBottom: `1px solid ${Colors.background.silver}`,
      borderRight: `1px solid ${Colors.background.silver}`,
      borderRadius: '0 0 4px 4px',
      padding: '12px',
      marginBottom: '8px',
      backgroundColor:
        droppableId === 'notSelected' ? Colors.background.lightGray : Colors.functional.background.default
    })
  })
})

export const Draggable = ({
  item,
  index,
  droppableId,
  domain,
  showSwitch,
  switchDisabled = false,
  disabled = false,
  hasEditPermission = false,
  onLabel,
  offLabel,
  onChange,
  onEdit,
  disableToggleAccordion = false
}: DraggableProps) => {
  const [expanded, setExpanded] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { handleDeleteCompanySettings } = useDeleteCompanySettings(item.id)
  const { handleDeleteContactSettings } = useDeleteContactSettings(item.id)
  const classes = useStyles({ droppableId, expanded })

  const handleChangeSwitch = useCallback(() => {
    onChange?.(item, !item.switchValue)
  }, [item, onChange])

  const handleEdit = useCallback(() => {
    onEdit?.(item)
  }, [item, onEdit])

  const handleDeleteConfirm = useCallback(() => {
    if (domain === 'company') {
      handleDeleteCompanySettings()
    } else {
      handleDeleteContactSettings()
    }
    setDeleteModalOpen(false)
  }, [domain, handleDeleteCompanySettings, handleDeleteContactSettings])

  return (
    <Box>
      <DndDraggable key={item.id} draggableId={item.id} index={index} isDragDisabled={disabled}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ position: 'relative', marginBottom: '8px', ...provided.draggableProps.style }}
          >
            {item.description ? (
              <Accordion expanded={disableToggleAccordion ? true : expanded} className={classes.accordionRoot}>
                <AccordionSummary
                  onClick={() => setExpanded(!expanded)}
                  className={classes.accordionSummary}
                  expandIcon={disableToggleAccordion ? null : <DynamicMuiIcon variant="arrowDown" size="16px" />}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                    <DraggableContent item={item} />
                    {showSwitch && !switchDisabled && (
                      <ToggleSwitch
                        checked={!!item.switchValue}
                        onChange={handleChangeSwitch}
                        disabled={switchDisabled}
                        onLabel={onLabel}
                        offLabel={offLabel}
                      />
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  <Typography fontSize="xs" lh="none">
                    {item.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Box
                borderRadius="4px"
                border={`1px solid ${Colors.background.silver}`}
                minHeight="34px"
                padding="4px 0"
                bgcolor={
                  droppableId === 'notSelected' ? Colors.background.lightGray : Colors.functional.background.default
                }
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={e => {
                  if (e.defaultPrevented) {
                    return
                  }
                }}
              >
                <Box
                  width={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pl="12px"
                  mr={item.hideEdit ? '8px' : '0px'}
                >
                  <DraggableContent item={item} />
                  {showSwitch && !switchDisabled && (
                    <ToggleSwitch
                      checked={!!item.switchValue}
                      onChange={handleChangeSwitch}
                      disabled={switchDisabled}
                      onLabel={onLabel}
                      offLabel={offLabel}
                    />
                  )}
                  {!item.hideEdit && (
                    <Box display="flex" alignItems="center">
                      {/* NOTE: オリジナル項目 */}
                      {item.canEdit && (
                        <Box pr="14px" pl="6px" display="flex" alignItems="center">
                          <ButtonBase onClick={() => hasEditPermission && handleEdit()} disabled={!hasEditPermission}>
                            <DynamicMuiIcon
                              variant="edit"
                              size="16px"
                              htmlColor={hasEditPermission ? Colors.base.middleGray : Colors.background.silver}
                            />
                          </ButtonBase>
                          <Box mr="8px" />
                          <ButtonBase
                            onClick={() => hasEditPermission && setDeleteModalOpen(true)}
                            disabled={!hasEditPermission}
                          >
                            <DynamicMuiIcon
                              variant="delete"
                              size="16px"
                              htmlColor={hasEditPermission ? Colors.base.middleGray : Colors.background.silver}
                            />
                          </ButtonBase>
                        </Box>
                      )}
                      {/* NOTE: プリセット項目 && 複数項目 */}
                      {!item.canEdit && item.isListType && (
                        <Box pr="14px" pl="6px" display="flex" alignItems="center">
                          <ButtonBase onClick={() => hasEditPermission && handleEdit()} disabled={!hasEditPermission}>
                            <DynamicMuiIcon
                              variant="edit"
                              size="16px"
                              htmlColor={hasEditPermission ? Colors.base.middleGray : Colors.background.silver}
                            />
                          </ButtonBase>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </div>
        )}
      </DndDraggable>
      <ModalConfirmDeleteSetting
        domain={domain}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  )
}
