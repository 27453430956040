/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateDocumentPublishRequest
 */
export interface CreateDocumentPublishRequest {
  /**
   *
   * @type {boolean}
   * @memberof CreateDocumentPublishRequest
   */
  publish?: boolean
}

export function CreateDocumentPublishRequestFromJSON(json: any): CreateDocumentPublishRequest {
  return CreateDocumentPublishRequestFromJSONTyped(json, false)
}

export function CreateDocumentPublishRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateDocumentPublishRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    publish: !exists(json, 'publish') ? undefined : json['publish']
  }
}

export function CreateDocumentPublishRequestToJSON(value?: CreateDocumentPublishRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    publish: value.publish
  }
}
