import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Avatar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.3493 9.35016L18.2993 1.30016C17.6827 0.683496 16.8327 0.333496 15.9493 0.333496H3.99935C2.16602 0.333496 0.682683 1.8335 0.682683 3.66683L0.666016 30.3335C0.666016 32.1668 2.14935 33.6668 3.98268 33.6668H23.9993C25.8327 33.6668 27.3327 32.1668 27.3327 30.3335V11.7168C27.3327 10.8335 26.9827 9.9835 26.3493 9.35016ZM18.666 22.0002H15.666V27.0002C15.666 27.9168 14.916 28.6668 13.9993 28.6668C13.0827 28.6668 12.3327 27.9168 12.3327 27.0002V22.0002H9.34935C8.59935 22.0002 8.23268 21.1002 8.76601 20.5835L13.4327 15.9335C13.766 15.6168 14.2827 15.6168 14.616 15.9335L19.266 20.5835C19.766 21.1002 19.3993 22.0002 18.666 22.0002ZM17.3327 12.0002C16.416 12.0002 15.666 11.2502 15.666 10.3335V2.8335L24.8327 12.0002H17.3327Z" />
    </SvgIcon>
  )
}
export default Avatar
