import React from 'react'
import { Box } from '@material-ui/core'
import { SectionCard, ToggleSwitch, Typography, Input } from 'src/components/atoms'
import { useFormik, FormikValues } from 'formik'

export interface SettingToggleSectionCardProps {
  title: string
  description?: string
  checked: boolean
  onChange: () => void
  inputTitle?: string
  text?: string
  placeholder?: string
  onChangeText?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export const SettingToggleSectionCard = ({
  title,
  description = '',
  checked,
  onChange,
  inputTitle = '',
  text = '',
  placeholder = '',
  onChangeText
}: SettingToggleSectionCardProps) => {
  const { handleChange, values } = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: { text },
    onSubmit: () => {}
  })

  return (
    <Box>
      <SectionCard>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={description ? '11px' : '0px'}>
          <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
            {title}
          </Typography>
          <ToggleSwitch checked={checked} onChange={onChange} />
        </Box>
        <Typography variant="subtitle2">{description}</Typography>
        {inputTitle && (
          <Box mt="12px">
            <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
              {inputTitle}
            </Typography>
            <Box mt="6px" />
            <Input
              name="text"
              value={values.text}
              onChange={handleChange}
              onBlur={onChangeText}
              placeholder={placeholder}
              fullWidth
            />
          </Box>
        )}
      </SectionCard>
    </Box>
  )
}
