/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateCompanyListResponseData,
  CreateCompanyListResponseDataFromJSON,
  CreateCompanyListResponseDataFromJSONTyped,
  CreateCompanyListResponseDataToJSON
} from './CreateCompanyListResponseData'

/**
 *
 * @export
 * @interface RemoveCompanyListResponse
 */
export interface RemoveCompanyListResponse {
  /**
   *
   * @type {number}
   * @memberof RemoveCompanyListResponse
   */
  status?: number
  /**
   *
   * @type {CreateCompanyListResponseData}
   * @memberof RemoveCompanyListResponse
   */
  data?: CreateCompanyListResponseData
}

export function RemoveCompanyListResponseFromJSON(json: any): RemoveCompanyListResponse {
  return RemoveCompanyListResponseFromJSONTyped(json, false)
}

export function RemoveCompanyListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveCompanyListResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : CreateCompanyListResponseDataFromJSON(json['data'])
  }
}

export function RemoveCompanyListResponseToJSON(value?: RemoveCompanyListResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CreateCompanyListResponseDataToJSON(value.data)
  }
}
