import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const PowerPoint = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#D8432B" />
      <path
        d="M18.212 14.6279H22.946C23.606 14.6279 24.23 14.6879 24.818 14.8079C25.406 14.9279 25.916 15.1379 26.348 15.4379C26.792 15.7259 27.14 16.1219 27.392 16.6259C27.656 17.1179 27.788 17.7419 27.788 18.4979C27.788 19.2419 27.668 19.8659 27.428 20.3699C27.188 20.8739 26.858 21.2759 26.438 21.5759C26.018 21.8759 25.52 22.0919 24.944 22.2239C24.368 22.3439 23.744 22.4039 23.072 22.4039H21.02V27.3719H18.212V14.6279ZM21.02 20.0279H22.892C23.144 20.0279 23.384 20.0039 23.612 19.9559C23.852 19.9079 24.062 19.8299 24.242 19.7219C24.434 19.6019 24.584 19.4459 24.692 19.2539C24.812 19.0499 24.872 18.7979 24.872 18.4979C24.872 18.1739 24.794 17.9159 24.638 17.7239C24.494 17.5199 24.302 17.3639 24.062 17.2559C23.834 17.1479 23.576 17.0819 23.288 17.0579C23 17.0219 22.724 17.0039 22.46 17.0039H21.02V20.0279Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default PowerPoint
