/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationAvatar,
  OrganizationAvatarFromJSON,
  OrganizationAvatarFromJSONTyped,
  OrganizationAvatarToJSON
} from './OrganizationAvatar'
import {
  OrganizationChatSetting,
  OrganizationChatSettingFromJSON,
  OrganizationChatSettingFromJSONTyped,
  OrganizationChatSettingToJSON
} from './OrganizationChatSetting'
import {
  OrganizationPolicySetting,
  OrganizationPolicySettingFromJSON,
  OrganizationPolicySettingFromJSONTyped,
  OrganizationPolicySettingToJSON
} from './OrganizationPolicySetting'

/**
 *
 * @export
 * @interface EuOrganization
 */
export interface EuOrganization {
  /**
   *
   * @type {string}
   * @memberof EuOrganization
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EuOrganization
   */
  name?: string
  /**
   *
   * @type {boolean}
   * @memberof EuOrganization
   */
  hideAd?: boolean
  /**
   *
   * @type {OrganizationAvatar}
   * @memberof EuOrganization
   */
  avatar?: OrganizationAvatar
  /**
   *
   * @type {Array<OrganizationPolicySetting>}
   * @memberof EuOrganization
   */
  policySettings?: Array<OrganizationPolicySetting>
  /**
   *
   * @type {OrganizationChatSetting}
   * @memberof EuOrganization
   */
  chatSetting?: OrganizationChatSetting
}

export function EuOrganizationFromJSON(json: any): EuOrganization {
  return EuOrganizationFromJSONTyped(json, false)
}

export function EuOrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EuOrganization {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    hideAd: !exists(json, 'hide_ad') ? undefined : json['hide_ad'],
    avatar: !exists(json, 'avatar') ? undefined : OrganizationAvatarFromJSON(json['avatar']),
    policySettings: !exists(json, 'policy_settings')
      ? undefined
      : (json['policy_settings'] as Array<any>).map(OrganizationPolicySettingFromJSON),
    chatSetting: !exists(json, 'chat_setting') ? undefined : OrganizationChatSettingFromJSON(json['chat_setting'])
  }
}

export function EuOrganizationToJSON(value?: EuOrganization | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    hide_ad: value.hideAd,
    avatar: OrganizationAvatarToJSON(value.avatar),
    policy_settings:
      value.policySettings === undefined
        ? undefined
        : (value.policySettings as Array<any>).map(OrganizationPolicySettingToJSON),
    chat_setting: OrganizationChatSettingToJSON(value.chatSetting)
  }
}
