import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Pulldown = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 8.923">
      <g transform="translate(11 7.923) rotate(180)" fill={props.color} strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M 10.97787952423096 7.423078536987305 L 10 7.423078536987305 L 0 7.423078536987305 L -0.9778800010681152 7.423078536987305 L -0.4053399860858917 6.630328178405762 L 4.594659805297852 -0.2927516996860504 L 5 -0.8539816737174988 L 5.405340194702148 -0.2927516996860504 L 10.40534019470215 6.630328178405762 L 10.97787952423096 7.423078536987305 Z"
          stroke="none"
        />
        <path
          d="M 5 -1.9073486328125e-06 L 0 6.923078536987305 L 10 6.923078536987305 L 5 -1.9073486328125e-06 M 5 -1.000001907348633 C 5.321100234985352 -1.000001907348633 5.622680187225342 -0.845801830291748 5.810679912567139 -0.5854916572570801 L 10.8106803894043 6.337588310241699 C 11.03055000305176 6.64201831817627 11.06132030487061 7.043978214263916 10.89035987854004 7.378328323364258 C 10.71940040588379 7.712688446044922 10.37553024291992 7.923078536987305 10 7.923078536987305 L 0 7.923078536987305 C -0.3755302429199219 7.923078536987305 -0.7194004058837891 7.712688446044922 -0.8903598785400391 7.378328323364258 C -1.061320304870605 7.043978214263916 -1.030550003051758 6.64201831817627 -0.8106803894042969 6.337588310241699 L 4.189320087432861 -0.5854916572570801 C 4.377319812774658 -0.845801830291748 4.678899765014648 -1.000001907348633 5 -1.000001907348633 Z"
          stroke="none"
          fill={props.color}
        />
      </g>
    </SvgIcon>
  )
}
export default Pulldown
