import { Box } from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useStepperContext } from '../StepperContext'

export interface StepperBoardProps {
  boardItems: { renderItem: ReactElement }[]
}
export const StepperBoard = ({ boardItems }: StepperBoardProps) => {
  const { currentIndex } = useStepperContext()
  const wrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (wrapperRef.current) {
      const target = wrapperRef.current
      const scrollLeft = currentIndex * target.clientWidth
      target.scroll({ left: scrollLeft, behavior: 'smooth' })
    }
  }, [currentIndex])
  return (
    <Box {...{ ref: wrapperRef }} sx={{ display: 'flex', alignItems: 'flex-start', overflow: 'hidden' }}>
      {boardItems.map((item, index) => {
        return (
          <Box key={index} sx={{ width: '100%', height: '100%', flex: '1 0 auto', padding: '0 60px' }}>
            {item.renderItem}
          </Box>
        )
      })}
    </Box>
  )
}
