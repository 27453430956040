import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Select } from './Select'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { AppBar, Box, createStyles, makeStyles, Modal, Slide, Tab, Typography } from '@material-ui/core'
import { Colors, Toaster } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { ContactCreateForm, ContactCreateFormChangeValueProps } from 'src/components/molecules'
import {
  ContactSettingValueMap,
  convertFromContactSettingValueMap,
  initializeContactSettingValueMap
} from 'src/fixtures/modules/contact/utils'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { useRouter } from 'next/router'
import { useCreateContact } from 'src/fixtures/modules/contact/hooks'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { useUpdateCompanyAppendContact } from 'src/fixtures/modules/company/hooks'
import { useConfirmDiscard } from 'src/fixtures/utils/url'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.background.lightGray,
      boxShadow: 'none'
    },
    tablist: {
      minHeight: 'auto !important'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      padding: '10px 0px',
      border: `1px solid ${Colors.background.gray}`,
      color: Colors.base.placeHolder
    },
    selectedTab: {
      color: Colors.accent.keyPurple.default,
      background: Colors.functional.background.default,
      border: '1px solid transparent'
    },
    tabsIndicator: {
      display: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

type TabValueType = 'select' | 'create'

interface ModalContactAddProps {
  companyId: string
  open: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
}

export const ModalContactAdd = ({ companyId, open, onClose, onCancel, onConfirm }: ModalContactAddProps) => {
  const classes = useStyles()
  const router = useRouter()
  const { data: me } = useGetMe()
  const { data: responseSetting } = useListContactSettings()
  const activeSettings = responseSetting?.activeContactSettings
  const [value, setValue] = useState<TabValueType>('select')
  const [userId, setUserId] = useState<string | undefined>()
  const [settingValues, setSettingValues] = useState<ContactSettingValueMap>({})
  const [selectedContactIds, setSelectedContactIds] = useState<Array<string>>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm)

  const { handleCreateContact, isLoading, error } = useCreateContact({ companyId })
  const {
    handleUpdateCompanyAppendContact,
    isLoading: isLoadingInSelect,
    error: errorInSelect
  } = useUpdateCompanyAppendContact(companyId)

  // 初期化
  useEffect(() => {
    const initalSettingValueMap = initializeContactSettingValueMap(activeSettings || [])

    // 会社のデフォルト値
    const companySetting = activeSettings?.find(setting => setting.settingType === 'company')!
    const contactSettingCompanyId = companySetting?.id
    const contactSettingFieldsId = companySetting?.contactSettingFields?.[0].id
    initalSettingValueMap[contactSettingCompanyId!] = { [contactSettingFieldsId!]: companyId }

    setSettingValues(initalSettingValueMap)
  }, [activeSettings, companyId])

  const handleClickSettings = useCallback(() => {
    router.push('/setting/team/contact')
    router.route
  }, [router])

  const handleChangeTab = useCallback(
    (event: ChangeEvent<{}>, newValue: TabValueType) => {
      if (showConfirm && window.confirm('変更がまだ保存されていません。画面を離れますか？')) {
        setValue(newValue)
        return
      }
      if (!showConfirm) {
        setValue(newValue)
      }
    },
    [showConfirm]
  )

  const handleChangeUser = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setUserId(e.target.value as string)
    setShowConfirm(true)
  }, [])

  const handleUpdateValue = useCallback(
    ({ settingId, fieldId, value }: ContactCreateFormChangeValueProps) => {
      const newValueMap = { ...settingValues }
      newValueMap[settingId][fieldId] = value
      setSettingValues(newValueMap)
      setShowConfirm(true)
    },
    [settingValues]
  )

  const handleChangeSelect = useCallback(contactList => {
    setSelectedContactIds(contactList)
  }, [])

  // 連絡先作成
  const handleCreate = useCallback(() => {
    if (!me) return Toaster.error('ユーザーがありません')

    if (value === 'create') {
      if (!userId) return Toaster.error('担当者を選択してください')
      const contactSettingFieldValues = convertFromContactSettingValueMap(settingValues)

      handleCreateContact(
        {
          contact: {
            userId,
            contactSettingFieldValues
          }
        },
        companyId
      )

      setIsSubmitting(true)
    } else if (value === 'select') {
      if (selectedContactIds.length === 0) return Toaster.error('連絡先を選択してください')
      handleUpdateCompanyAppendContact({ contactIds: selectedContactIds })

      setIsSubmitting(true)
    }
  }, [
    companyId,
    handleCreateContact,
    me,
    settingValues,
    userId,
    handleUpdateCompanyAppendContact,
    selectedContactIds,
    value
  ])

  // 画面をクローズする前に confirm する対応
  const handleClose = useCallback(() => {
    if (showConfirm && window.confirm('変更がまだ保存されていません。画面を離れますか？')) {
      onClose()
      return
    }

    if (!showConfirm) {
      onClose()
    }

    setShowConfirm(false)
  }, [showConfirm, onClose])

  useEffect(() => {
    if (isSubmitting && !isLoading && !error && !isLoadingInSelect && !errorInSelect) {
      onConfirm()
      setIsSubmitting(false)
    }
  }, [isSubmitting, isLoading, error, isLoadingInSelect, errorInSelect, onConfirm])

  useEffect(() => setUserId(me?.user?.id), [me])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="連絡先を追加"
            hideSettings={value === 'select'}
            onClose={handleClose}
            onCancel={onCancel}
            onConfirm={handleCreate}
            onClickSettings={handleClickSettings}
            isSubmitting={isLoading || isLoadingInSelect}
          >
            <Box bgcolor={Colors.functional.background.default} height={1}>
              <TabContext value={value}>
                <AppBar position="relative" className={classes.root}>
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="company add tablist"
                    classes={{ root: classes.tablist, indicator: classes.tabsIndicator }}
                    variant="fullWidth"
                  >
                    <Tab
                      value="select"
                      label={<Typography variant="h5">登録データから追加</Typography>}
                      classes={{ root: classes.tab, selected: classes.selectedTab }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      value="create"
                      label={<Typography variant="h5">新規登録</Typography>}
                      classes={{ root: classes.tab, selected: classes.selectedTab }}
                      {...a11yProps(1)}
                    />
                  </TabList>
                </AppBar>
                <Box>
                  <TabPanel value="select" style={{ padding: 0 }}>
                    <Select onChange={handleChangeSelect} />
                  </TabPanel>
                  <TabPanel value="create" style={{ padding: 0 }}>
                    <ContactCreateForm
                      userId={userId!}
                      settingValues={settingValues}
                      onChangeUser={handleChangeUser}
                      onChangeValues={handleUpdateValue}
                    />
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
