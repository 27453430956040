import { Box, CircularProgress } from '@material-ui/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Colors, SelectBox, SvgIcon, Typography } from 'src/components/atoms'
import { useBulkGetMailTemplates } from 'src/fixtures/modules/mailTemplates/hooks'
import {
  useGetOrganizationUserOptionList,
  useListOrganizationUsers,
  usePurgeUserAndMailTemplate
} from 'src/fixtures/modules/organization/hooks'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { getUserFullName } from 'src/fixtures/modules/user/utils'
import { center } from '../styles'
import { StepperBaseProps } from '../types'
import Image from 'next/image'
import { BackButton } from '../BackButton'
import { useRouter } from 'next/router'
import { WarningMesasge } from './WarningMessage'

export interface StepConfirmationProps extends StepperBaseProps {
  stayUserIds: string[]
  takeOverUserId?: string
  mailTemplateIds: string[]
  onNext?: () => void
  onBack?: () => void
}

export const StepConfirmation = ({
  onNext,
  onBack,
  stayUserIds,
  takeOverUserId,
  mailTemplateIds
}: StepConfirmationProps) => {
  const { data } = useGetOrganizationPaymentCurrentPlan()
  const plan = data?.plan
  const { data: userRes } = useListOrganizationUsers()
  const { userOptionList } = useGetOrganizationUserOptionList()
  const list = userRes?.users
  const { data: mailTemplateRes } = useBulkGetMailTemplates(mailTemplateIds)
  const router = useRouter()
  const dstPlanId = router.query.id as string

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { handlePurgeUserAndMailTemplate, isLoading, error } = usePurgeUserAndMailTemplate()

  const stayUserList = useMemo(() => {
    return list?.filter(item => stayUserIds.includes(item.id!))
  }, [list, stayUserIds])

  const isValid = useMemo(
    () => stayUserList && stayUserList.length > 0 && takeOverUserId,
    [stayUserList, takeOverUserId]
  )

  const handlePurge = useCallback(() => {
    if (takeOverUserId) {
      handlePurgeUserAndMailTemplate({
        planIdOfTransitionDst: dstPlanId,
        liveMailTemplateIds: mailTemplateIds,
        liveUserIds: stayUserIds,
        takeOverUserId: takeOverUserId
      })
      setIsSubmitting(true)
    }
  }, [dstPlanId, handlePurgeUserAndMailTemplate, mailTemplateIds, stayUserIds, takeOverUserId])

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      onNext?.()
    }
  }, [isLoading, error, isSubmitting, onNext])

  return plan ? (
    <Box width={'100%'}>
      <Box mb="24px">{dstPlanId && <WarningMesasge nextPlanId={dstPlanId} />}</Box>
      <Box mb="24px">
        <Card label="選択したユーザー" sx={{ py: '30px', bgcolor: 'white' }}>
          {stayUserList?.map(stayUser => {
            return (
              <Box key={stayUser.id} sx={{ py: '8px', borderBottom: '1px solid #E9ECF1' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '-1px' }}>
                  <SvgIcon variant="checkedIcon" color={Colors.accent.keyPurple.default} size={15} />
                  <Box width="12px" />
                  <Box sx={{ width: '24px', height: '24px', borderRadius: '50%', overflow: 'hidden', mr: '8px' }}>
                    <Image
                      src={stayUser?.avatar?.url || ''}
                      alt="avatar"
                      width="24px"
                      height="24px"
                      objectFit="cover"
                    />
                  </Box>
                  <Typography fontSize="s" lineheight="tight">
                    {`${getUserFullName(stayUser)}  (${stayUser?.encryptedEmail})`}
                  </Typography>
                </Box>
              </Box>
            )
          })}
        </Card>
      </Box>
      <Box mb="24px">
        <Card label="選択した営業担当者・お問い合わせ担当者" sx={{ py: '30px', bgcolor: 'white' }}>
          <Box sx={{ py: '6px' }}>
            <Box width="316px">
              <SelectBox
                optionList={userOptionList || []}
                value={takeOverUserId || ''}
                placeholder="選択してください"
                disabled
                onChange={undefined}
              />
            </Box>
          </Box>
        </Card>
      </Box>
      <Box>
        <Card label="選択したメールテンプレート" sx={{ py: '30px', bgcolor: 'white' }}>
          {mailTemplateRes?.map(mailTemplate => {
            return (
              <Box key={mailTemplate.id} sx={{ py: '8px', borderBottom: '1px solid #E9ECF1' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '-1px' }}>
                  <SvgIcon variant="checkedIcon" color={Colors.accent.keyPurple.default} size={15} />
                  <Box width="12px" />
                  <Typography fontSize="s" lineheight="tight">
                    {`${mailTemplate?.name}`}
                  </Typography>
                </Box>
              </Box>
            )
          })}
          {mailTemplateRes?.length === 0 ? (
            <Typography fontSize="s" lineheight="tight">
              {`選択されているメールテンプレートはありません。`}
            </Typography>
          ) : (
            <Box />
          )}
        </Card>
      </Box>
      <Box sx={{ ...center, mt: '24px' }}>
        <Button
          size="large"
          onClick={handlePurge}
          title={'この内容で変更する'}
          disabled={!isValid}
          loading={isLoading}
        />
      </Box>
      <Box sx={{ ...center, mt: '24px', mb: '31px' }}>
        <BackButton onClick={() => onBack?.()} />
      </Box>
      <Box height="48px" />
    </Box>
  ) : (
    <Box sx={{ ...center }}>
      <CircularProgress />
    </Box>
  )
}
