/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumPublicationStatusFromJSON, EnumPublicationStatusToJSON } from './EnumPublicationStatus';
import { EnumSharingStatusFromJSON, EnumSharingStatusToJSON } from './EnumSharingStatus';
import { EuSiteSectionFromJSON, EuSiteSectionToJSON } from './EuSiteSection';
import { SiteCoverPhotoFromJSON, SiteCoverPhotoToJSON } from './SiteCoverPhoto';
import { SiteMiscSettingFromJSON, SiteMiscSettingToJSON } from './SiteMiscSetting';
import { SiteShareFromJSON, SiteShareToJSON } from './SiteShare';
export function EuSiteFromJSON(json) {
    return EuSiteFromJSONTyped(json, false);
}
export function EuSiteFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
        publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        coverPhoto: !exists(json, 'cover_photo') ? undefined : SiteCoverPhotoFromJSON(json['cover_photo']),
        isConfidential: !exists(json, 'is_confidential') ? undefined : json['is_confidential'],
        isHeaderCustom: !exists(json, 'is_header_custom') ? undefined : json['is_header_custom'],
        isFooterCustom: !exists(json, 'is_footer_custom') ? undefined : json['is_footer_custom'],
        footerText: !exists(json, 'footer_text') ? undefined : json['footer_text'],
        confidentialLabelName: !exists(json, 'confidential_label_name') ? undefined : json['confidential_label_name'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        isPublished: !exists(json, 'is_published') ? undefined : json['is_published'],
        sharingStatus: !exists(json, 'sharing_status') ? undefined : EnumSharingStatusFromJSON(json['sharing_status']),
        publicationStatus: !exists(json, 'publication_status')
            ? undefined
            : EnumPublicationStatusFromJSON(json['publication_status']),
        headerLogo: !exists(json, 'header_logo') ? undefined : SiteCoverPhotoFromJSON(json['header_logo']),
        siteSections: !exists(json, 'site_sections')
            ? undefined
            : json['site_sections'].map(EuSiteSectionFromJSON),
        siteShare: !exists(json, 'site_share') ? undefined : SiteShareFromJSON(json['site_share']),
        siteMiscSetting: !exists(json, 'site_misc_setting') ? undefined : SiteMiscSettingFromJSON(json['site_misc_setting'])
    };
}
export function EuSiteToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        organization_id: value.organizationId,
        published_at: value.publishedAt,
        title: value.title,
        description: value.description,
        cover_photo: SiteCoverPhotoToJSON(value.coverPhoto),
        is_confidential: value.isConfidential,
        is_header_custom: value.isHeaderCustom,
        is_footer_custom: value.isFooterCustom,
        footer_text: value.footerText,
        confidential_label_name: value.confidentialLabelName,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        is_published: value.isPublished,
        sharing_status: EnumSharingStatusToJSON(value.sharingStatus),
        publication_status: EnumPublicationStatusToJSON(value.publicationStatus),
        header_logo: SiteCoverPhotoToJSON(value.headerLogo),
        site_sections: value.siteSections === undefined ? undefined : value.siteSections.map(EuSiteSectionToJSON),
        site_share: SiteShareToJSON(value.siteShare),
        site_misc_setting: SiteMiscSettingToJSON(value.siteMiscSetting)
    };
}
