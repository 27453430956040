import { Box, Popover } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, SvgIcon, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { ModalCompanyAdd } from 'src/components/modals'
import { CompanyInfoList } from '../..'
import { useGetContact, useUpdateContactCompany } from 'src/fixtures/modules/contact/hooks'

export interface CompanyInfoProps {
  contactId: string
}

export const CompanyInfo = ({ contactId }: CompanyInfoProps) => {
  const globalClasses = useGlobalStyles()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { data: contact } = useGetContact(contactId)
  const { handleUpdateContactCompany } = useUpdateContactCompany(contactId)

  const handlePopover = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  //- 会社情報を変更
  const handleChange = useCallback(() => {
    setAnchorEl(null)
    setIsOpen(true)
  }, [])
  //- 会社情報を外す
  const handleRemove = useCallback(() => {
    handleUpdateContactCompany({
      companyId: undefined
    })
    setAnchorEl(null)
  }, [handleUpdateContactCompany])
  //- 会社情報を追加
  const handleAdd = useCallback(() => {
    setAnchorEl(null)
    setIsOpen(true)
  }, [])
  //- 会社情報追加モーダル
  const handleClose = useCallback(() => {
    setAnchorEl(null)
    setIsOpen(false)
  }, [])
  const handleConfirm = useCallback(() => {
    setAnchorEl(null)
    setIsOpen(false)
  }, [])

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="8px">
        <Typography fontSize="s" lineheight="14px" fontWeight="bold">
          会社情報
        </Typography>

        <Button
          style={{ padding: '0px 6px' }}
          size="small"
          kind="secondary"
          variant="outlined"
          startIcon={<SvgIcon variant="setting" size="16px" color={Colors.accent.keyBlue.default} />}
          title="設定"
          onClick={handlePopover}
          starticonmargin="0px 4px 0px 0px"
        />
        <Popover
          PaperProps={{ style: { marginTop: '34px' } }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          <Box className={globalClasses.tooltip}>
            {contact?.company ? (
              <>
                <Box display="flex" alignItems="center" p="8px 12px" onClick={handleChange}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="20px"
                    color={Colors.base.middleGray}
                    mr="4px"
                  >
                    <DynamicMuiIcon variant="edit" color="inherit" fontSize="inherit" />
                  </Box>
                  <Typography fontSize="s" lineheight="14px">
                    会社情報を変更
                  </Typography>
                </Box>
                <Box height="1px" bgcolor={Colors.background.gray} />
                <Box display="flex" alignItems="center" p="8px 12px" onClick={handleRemove}>
                  <Box display="flex" alignItems="center" mr="4px">
                    <SvgIcon variant="removeCircle" size="20px" color={Colors.base.middleGray} />
                  </Box>
                  <Typography fontSize="s" lineheight="14px">
                    会社情報を外す
                  </Typography>
                </Box>
              </>
            ) : (
              <Box display="flex" alignItems="center" p="8px 12px" onClick={handleAdd}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="20px"
                  color={Colors.base.middleGray}
                  mr="4px"
                >
                  <DynamicMuiIcon variant="add" color="inherit" fontSize="inherit" />
                </Box>
                <Typography fontSize="s" lineheight="14px">
                  会社情報を追加
                </Typography>
              </Box>
            )}
          </Box>
        </Popover>
      </Box>
      <Box>
        {contact?.company ? (
          <CompanyInfoList company={contact?.company} />
        ) : (
          <Box color={Colors.base.black}>
            <Typography fontSize="xs" lineheight="15.84px">
              設定から会社情報を追加できます
            </Typography>
          </Box>
        )}
      </Box>
      <ModalCompanyAdd
        open={isOpen}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleConfirm}
        companyId={contact?.company?.id}
      />
    </>
  )
}
