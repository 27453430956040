/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CompanySettingFromJSON, CompanySettingToJSON } from './CompanySetting';
export function GetCompanySettingsResponseDataFromJSON(json) {
    return GetCompanySettingsResponseDataFromJSONTyped(json, false);
}
export function GetCompanySettingsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        activeCompanySettings: !exists(json, 'active_company_settings')
            ? undefined
            : json['active_company_settings'].map(CompanySettingFromJSON),
        inactiveCompanySettings: !exists(json, 'inactive_company_settings')
            ? undefined
            : json['inactive_company_settings'].map(CompanySettingFromJSON)
    };
}
export function GetCompanySettingsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        active_company_settings: value.activeCompanySettings === undefined
            ? undefined
            : value.activeCompanySettings.map(CompanySettingToJSON),
        inactive_company_settings: value.inactiveCompanySettings === undefined
            ? undefined
            : value.inactiveCompanySettings.map(CompanySettingToJSON)
    };
}
