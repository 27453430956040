import { Box, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Button, Colors, Input } from 'src/components/atoms'
import { useSendOrganizationNameCheckMail } from 'src/fixtures/modules/auth/hooks'
import * as yup from 'yup'

export interface FormProps {
  onClick: (email: string) => void
}

interface FormValue {
  email: string
}

export const formValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('メールアドレスの形式で入力してください')
    .label('メールアドレス')
    .required('メールアドレスは必須です')
})

export const Form = ({ onClick }: FormProps) => {
  const { handleSend, isLoading, error } = useSendOrganizationNameCheckMail()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const formik = useFormik<FormValue>({
    initialValues: {
      email: ''
    },
    isInitialValid: false,
    validationSchema: formValidationSchema,
    onSubmit: values => {
      handleSend(values.email)
      setIsSubmitting(true)
    }
  })
  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      onClick(formik.values.email)
      setIsSubmitting(false)
      formik.resetForm()
    }
  }, [isLoading, error, isSubmitting, onClick, formik])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box color={Colors.base.black}>
        <Typography variant="h1">チームIDを調べる</Typography>
        <Box mt="20px" />
        <Typography variant="body2">
          メールアドレスをご入力ください
          <br />
          登録しているチームIDをメールでご案内します
        </Typography>
        <Box mt="24px" />
        <Typography variant="h5">ご登録のメールアドレス</Typography>
        <Box mt="6px" />
        <Input
          name="email"
          type="text"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={e => {
            formik.handleBlur(e)
            formik.setFieldValue('email', formik.values.email.trim())
          }}
          required
          fullWidth
          large
          error={formik.touched.email ? formik.errors.email : undefined}
        />
        <Box mt="24px" />
        <Button title="送信" loading={isLoading} size="large" width="100%" type="submit" disabled={!formik.isValid} />
      </Box>
    </form>
  )
}
