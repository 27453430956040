/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AuditColumnFromJSON, AuditColumnToJSON } from './AuditColumn';
import { EnumAuditExtendActionFromJSON, EnumAuditExtendActionToJSON } from './EnumAuditExtendAction';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function AuditFromJSON(json) {
    return AuditFromJSONTyped(json, false);
}
export function AuditFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
        action: !exists(json, 'action') ? undefined : json['action'],
        extendAction: !exists(json, 'extend_action') ? undefined : EnumAuditExtendActionFromJSON(json['extend_action']),
        columns: !exists(json, 'columns') ? undefined : json['columns'].map(AuditColumnFromJSON)
    };
}
export function AuditToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        created_at: value.createdAt,
        user: UserForDisplayToJSON(value.user),
        action: value.action,
        extend_action: EnumAuditExtendActionToJSON(value.extendAction),
        columns: value.columns === undefined ? undefined : value.columns.map(AuditColumnToJSON)
    };
}
