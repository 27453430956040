/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateContactRequestContactFromJSON, CreateContactRequestContactToJSON } from './CreateContactRequestContact';
export function UpdateContactRequestFromJSON(json) {
    return UpdateContactRequestFromJSONTyped(json, false);
}
export function UpdateContactRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contact: !exists(json, 'contact') ? undefined : CreateContactRequestContactFromJSON(json['contact'])
    };
}
export function UpdateContactRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact: CreateContactRequestContactToJSON(value.contact)
    };
}
