/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { FilterContent, FilterContentFromJSON, FilterContentFromJSONTyped, FilterContentToJSON } from './FilterContent'

/**
 *
 * @export
 * @interface FilterMaterialObject
 */
export interface FilterMaterialObject {
  /**
   *
   * @type {FilterContent}
   * @memberof FilterMaterialObject
   */
  document?: FilterContent
  /**
   *
   * @type {FilterContent}
   * @memberof FilterMaterialObject
   */
  site?: FilterContent
}

export function FilterMaterialObjectFromJSON(json: any): FilterMaterialObject {
  return FilterMaterialObjectFromJSONTyped(json, false)
}

export function FilterMaterialObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterMaterialObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    document: !exists(json, 'document') ? undefined : FilterContentFromJSON(json['document']),
    site: !exists(json, 'site') ? undefined : FilterContentFromJSON(json['site'])
  }
}

export function FilterMaterialObjectToJSON(value?: FilterMaterialObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document: FilterContentToJSON(value.document),
    site: FilterContentToJSON(value.site)
  }
}
