import { Box, createStyles, makeStyles } from '@material-ui/core'
import { User } from '@noco/http-client/lib/noco'
import { useMemo } from 'react'
import { ButtonIcon, Colors, SvgIcon, Typography } from 'src/components/atoms'
import { useGetOrganization, useListOrganizationAdministrators } from 'src/fixtures/modules/organization/hooks'
import { getUserFullName } from 'src/fixtures/modules/user/utils'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '56px',
      lineHeight: '1.5',
      fontWeight: 'bold',
      whiteSpace: 'pre-line'
    },
    list: {
      paddingInlineStart: '24px',
      marginBottom: '24px'
    }
  })
)

interface Administrator {
  fullName: string
  encryptedEmail: string
}

export const PageTrialEnd = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const { data: dataOrganization } = useGetOrganization()
  const organization = dataOrganization?.organization

  const { data: dataUsers } = useListOrganizationAdministrators()
  const administratorList: Administrator[] = useMemo(() => {
    return (dataUsers?.users || ([] as User[])).map(user => {
      return {
        fullName: getUserFullName(user),
        encryptedEmail: user.encryptedEmail || ''
      }
    })
  }, [dataUsers?.users])

  const redirectToServiceSite = () => {
    window.location.href = `${window.location.protocol}//${process.env.DOMAIN_NAME}/`
    return null
  }

  return (
    <Box width="100%" height="100vh" p="40px" bgcolor={Colors.background.lightGray}>
      <Box width="100%" height="100%" maxWidth="1180px" margin="0 auto">
        <Box position="relative" width={1} height={1}>
          <img src="/images/nocosell_logo.svg" alt="logo" width="104px" height="30px" />
          <Box mt="60px" px="100px" position="relative" zIndex={5} color={Colors.base.black}>
            <Typography variant="h1" className={classes.title} color="inherit">
              プレミアムプランの{`\n`}お試し期間が終了しました
            </Typography>
            <Box height="24px" />
            {organization?.name && (
              <Typography fontSize="m" lineheight="1.7" letterSpacing="tight" color="inherit">
                チーム「{organization.name}」の管理者にお問い合わせください
              </Typography>
            )}
            <Box height="24px" />
            <Typography fontSize="m" lineheight="1.7" letterSpacing="tight" fontWeight="bold" color="inherit">
              管理者
            </Typography>
            <ul className={classes.list}>
              {administratorList.map((administrator, index) => (
                <li key={index}>
                  <Typography fontSize="m" lineheight="27.2px" letterSpacing="tight" color="inherit">
                    {administrator.fullName} （{administrator.encryptedEmail}）
                  </Typography>
                </li>
              ))}
            </ul>
            <Box color={Colors.accent.keyBlue.default}>
              <ButtonIcon
                onClick={redirectToServiceSite}
                iconAfter={<SvgIcon variant="rightArrow" size="14px" color="inherit" />}
              >
                <Typography fontSize="s" color="inherit" fontWeight="bold">
                  ノコセルサービスサイト
                </Typography>
              </ButtonIcon>
            </Box>
          </Box>
          <Box position="absolute" zIndex={0} right={0} bottom="30px" width="456px" height="314px">
            <img src="/images/errors/permission.svg" alt="errorImage" className={globalClasses.imgContain} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
