import { useCallback } from 'react'
import { createStyles, makeStyles, Box, Modal, Slide } from '@material-ui/core'
import { SlideForm } from 'src/components/organisms'
import { Input, Typography } from 'src/components/atoms'
import { useFormik } from 'formik'
import { OrganizationPolicySetting } from '@noco/http-client/lib/noco'
import { SendValue } from './type'

interface ModalPolicyProps {
  id?: string
  label?: string
  url?: string | null
  open: boolean
  onCancel: () => void
  onClose: () => void
  onConfirm: (item: OrganizationPolicySetting) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
)

export const ModalPolicy = ({
  id = '',
  label = '',
  url = '',
  open,
  onCancel,
  onClose,
  onConfirm
}: ModalPolicyProps) => {
  const classes = useStyles()

  const formik = useFormik<SendValue>({
    enableReinitialize: true,
    initialValues: { label, url },
    onSubmit: values => {
      onConfirm({ id: id, label: values.label, url: values.url })
      onClose()
    }
  })

  const cancelAndResetValues = useCallback(() => {
    formik.resetForm()
    onCancel()
  }, [formik, onCancel])

  const closeAndResetValues = useCallback(() => {
    formik.resetForm()
    onClose()
  }, [formik, onClose])

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="ポリシーを編集"
            onClose={cancelAndResetValues}
            onCancel={closeAndResetValues}
            onConfirm={formik.handleSubmit}
            hideSettings
          >
            <Box p="24px">
              <Typography fontSize="s" fontWeight="bold">
                ポリシー名（最大20文字）
              </Typography>
              <Box mt="5px" />
              <Input name="label" value={formik.values.label} fullWidth onChange={formik.handleChange} />
              <Box mt="16px" />
              <Typography fontSize="s" fontWeight="bold">
                URL
              </Typography>
              <Box mt="5px" />
              <Input
                name="url"
                value={formik.values.url || ''}
                placeholder="https://"
                fullWidth
                onChange={formik.handleChange}
              />
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
