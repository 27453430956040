/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { SiteContact, SiteContactFromJSON, SiteContactFromJSONTyped, SiteContactToJSON } from './SiteContact'

/**
 *
 * @export
 * @interface UpdateSiteContactResponseData
 */
export interface UpdateSiteContactResponseData {
  /**
   *
   * @type {SiteContact}
   * @memberof UpdateSiteContactResponseData
   */
  siteContact?: SiteContact
}

export function UpdateSiteContactResponseDataFromJSON(json: any): UpdateSiteContactResponseData {
  return UpdateSiteContactResponseDataFromJSONTyped(json, false)
}

export function UpdateSiteContactResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSiteContactResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteContact: !exists(json, 'site_contact') ? undefined : SiteContactFromJSON(json['site_contact'])
  }
}

export function UpdateSiteContactResponseDataToJSON(value?: UpdateSiteContactResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_contact: SiteContactToJSON(value.siteContact)
  }
}
