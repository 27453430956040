/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInner,
  GetUsersResponseDataUsersInnerFromJSON,
  GetUsersResponseDataUsersInnerFromJSONTyped,
  GetUsersResponseDataUsersInnerToJSON
} from './GetUsersResponseDataUsersInner'

/**
 *
 * @export
 * @interface GetUsersResponseData
 */
export interface GetUsersResponseData {
  /**
   *
   * @type {Array<GetUsersResponseDataUsersInner>}
   * @memberof GetUsersResponseData
   */
  users?: Array<GetUsersResponseDataUsersInner>
}

export function GetUsersResponseDataFromJSON(json: any): GetUsersResponseData {
  return GetUsersResponseDataFromJSONTyped(json, false)
}

export function GetUsersResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    users: !exists(json, 'users')
      ? undefined
      : (json['users'] as Array<any>).map(GetUsersResponseDataUsersInnerFromJSON)
  }
}

export function GetUsersResponseDataToJSON(value?: GetUsersResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    users: value.users === undefined ? undefined : (value.users as Array<any>).map(GetUsersResponseDataUsersInnerToJSON)
  }
}
