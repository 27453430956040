/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PinContactListRequest
 */
export interface PinContactListRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof PinContactListRequest
   */
  contactListIds?: Array<string>
}

export function PinContactListRequestFromJSON(json: any): PinContactListRequest {
  return PinContactListRequestFromJSONTyped(json, false)
}

export function PinContactListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PinContactListRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactListIds: !exists(json, 'contact_list_ids') ? undefined : json['contact_list_ids']
  }
}

export function PinContactListRequestToJSON(value?: PinContactListRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_list_ids: value.contactListIds
  }
}
