import Link from 'next/link'
import React from 'react'

export interface LinkCellProps {
  value: string
}

export const LinkCell = ({ value }: LinkCellProps) => {
  return (
    <>
      {value ? (
        <Link href={value!}>
          <a target="_blank" rel="noopener noreferrer">
            {value!}
          </a>
        </Link>
      ) : (
        <></>
      )}
    </>
  )
}
