import React, { useState, useCallback, useEffect } from 'react'
import { Box, createStyles, makeStyles, Modal, Typography, Slide } from '@material-ui/core'
import { Colors, OptionListType, SelectBox, Input, SvgIcon, DynamicMuiIcon } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { useFormik } from 'formik'
import { CheckboxItemType, FormMultiple } from './FormMultiple'
import {
  ContactSetting,
  CompanySetting,
  CreateCompanySettingRequest,
  CreateContactSettingRequest,
  UpdateContactSettingRequest,
  UpdateCompanySettingRequest,
  CompanySettingField,
  ContactSettingField,
  ContactSettingFieldItem,
  CompanySettingFieldItem,
  CreateContactSettingRequestContactSettingKindEnum,
  CreateContactSettingRequestContactSettingFieldsInnerKindEnum,
  CreateCompanySettingRequestCompanySettingKindEnum,
  CreateCompanySettingRequestCompanySettingFieldsInnerKindEnum
} from '@noco/http-client/lib/noco'
import { useCreateContactSettings, useUpdateContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { useCreateCompanySettings, useUpdateCompanySettings } from 'src/fixtures/modules/companySetting/hooks'

export interface ModalCustomFormCreateProps {
  domain: 'contact' | 'company'
  open: boolean
  defaultValue?: ContactSetting | CompanySetting
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
}

const optionItemList: OptionListType[] = [
  {
    value: 'text',
    label: '一行テキスト',
    startElement: <DynamicMuiIcon variant="textFields" color="action" size="16px" />
  },
  {
    value: 'multiple_text',
    label: '複数行テキスト',
    startElement: <DynamicMuiIcon variant="wrapText" color="action" size="16px" />
  },
  {
    value: 'radio',
    label: 'ラジオボタン（単一選択）',
    startElement: <DynamicMuiIcon variant="radioButtonChecked" color="action" size="16px" />
  },

  {
    value: 'dropdown',
    label: 'ドロップダウンメニュー（単一選択）',
    startElement: <DynamicMuiIcon variant="wrapText" color="action" size="16px" />
  },
  {
    value: 'checkbox',
    label: 'チェックボックス（複数選択）',
    startElement: <SvgIcon variant="checkList" color={Colors.base.middleGray} size="16px" />
  },
  {
    value: 'number',
    label: '数字',
    startElement: <DynamicMuiIcon variant="pin" color="action" size="16px" />
  },
  {
    value: 'date',
    label: '日付',
    startElement: <DynamicMuiIcon variant="meeting" color="action" size="16px" />
  },
  {
    value: 'time',
    label: '時刻',
    startElement: <DynamicMuiIcon variant="accessTime" color="action" size="16px" />
  }
]

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: Colors.background.lightGray,
      boxShadow: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
)

export const ModalCustomFormCreate = ({
  domain,
  open,
  defaultValue,
  onClose,
  onCancel,
  onConfirm
}: ModalCustomFormCreateProps) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [checkboxItems, setCheckboxItems] = useState<CheckboxItemType[]>([])
  const {
    handleCreateContactSettings,
    isLoading: isLoadingCreateContact,
    error: errorCreateContact
  } = useCreateContactSettings()
  const {
    handleCreateCompanySettings,
    isLoading: isLoadingCreateCompany,
    error: errorCreateCompany
  } = useCreateCompanySettings()
  const {
    handleUpdateContactSettings,
    isLoading: isLoadingUpdateContact,
    error: errorUpdateContact
  } = useUpdateContactSettings(defaultValue?.id)
  const {
    handleUpdateCompanySettings,
    isLoading: isLoadingUpdateCompany,
    error: errorUpdateCompany
  } = useUpdateCompanySettings(defaultValue?.id)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      label: defaultValue?.label,
      kind:
        domain === 'contact'
          ? (defaultValue as ContactSetting)?.contactSettingFields?.[0].kind
          : (defaultValue as CompanySetting)?.companySettingFields?.[0].kind,
      optionItemList: optionItemList
    },
    onSubmit: async values => {
      //- NOTE: 連絡先
      if (domain === 'contact') {
        //- NOTE: 連絡先 編集
        if (defaultValue) {
          let params: UpdateContactSettingRequest
          //- NOTE: オリジナル項目
          if (defaultValue.canEdit) {
            params = {
              contactSetting: {
                name: values.label
              },
              contactSettingFields: [
                {
                  id: (defaultValue as ContactSetting)?.contactSettingFields?.[0].id,
                  name: values.label,
                  contactSettingFieldItems:
                    checkboxItems.map((i, idx) => ({ id: i.id, name: i.name, sort: idx, _delete: i._delete })) ||
                    undefined
                }
              ]
            }
          }
          //- NOTE: プリセット項目
          else {
            params = {
              contactSettingFields: [
                {
                  id: (defaultValue as ContactSetting)?.contactSettingFields?.[0].id,
                  contactSettingFieldItems:
                    checkboxItems.map((i, idx) => ({ id: i.id, name: i.name, sort: idx, _delete: i._delete })) ||
                    undefined
                }
              ]
            }
          }
          handleUpdateContactSettings(params)
        }
        //- NOTE: 連絡先 新規
        else {
          const kind = values.kind as CreateContactSettingRequestContactSettingFieldsInnerKindEnum
          const icon = values.optionItemList.find(item => item.value === kind)?.startElement?.props?.variant as
            | string
            | undefined
          const contactSettingFields: CreateContactSettingRequest['contactSettingFields'] = [
            {
              kind,
              name: values.label,
              contactSettingFieldItems: checkboxItems.map((i, idx) => ({ name: i.name, sort: idx })) || undefined
            }
          ]
          handleCreateContactSettings({
            contactSetting: {
              kind: CreateContactSettingRequestContactSettingKindEnum.SingleField,
              name: values.label,
              icon
            },
            contactSettingFields
          })
        }
      }
      //- NOTE: 会社
      else {
        //- NOTE: 会社 編集
        if (defaultValue) {
          let params: UpdateCompanySettingRequest
          //- NOTE: オリジナル項目
          if (defaultValue.canEdit) {
            params = {
              companySetting: {
                name: values.label
              },
              companySettingFields: [
                {
                  id: (defaultValue as CompanySetting)?.companySettingFields?.[0].id,
                  name: values.label,
                  companySettingFieldItems:
                    checkboxItems.map((i, idx) => ({ id: i.id, name: i.name, sort: idx, _delete: i._delete })) ||
                    undefined
                }
              ]
            }
          }
          //- NOTE: プリセット項目
          else {
            params = {
              companySettingFields: [
                {
                  id: (defaultValue as CompanySetting)?.companySettingFields?.[0].id,
                  companySettingFieldItems:
                    checkboxItems.map((i, idx) => ({ id: i.id, name: i.name, sort: idx, _delete: i._delete })) ||
                    undefined
                }
              ]
            }
          }
          handleUpdateCompanySettings(params)
        } else {
          //- NOTE: 会社 新規
          const kind = values.kind as CreateCompanySettingRequestCompanySettingFieldsInnerKindEnum
          const icon = values.optionItemList.find(item => item.value === kind)?.startElement?.props?.variant as
            | string
            | undefined
          const companySettingFields: CreateCompanySettingRequest['companySettingFields'] = [
            {
              kind,
              name: values.label,
              companySettingFieldItems: checkboxItems.map((i, idx) => ({ name: i.name, sort: idx })) || undefined
            }
          ]
          handleCreateCompanySettings({
            companySetting: {
              kind: CreateCompanySettingRequestCompanySettingKindEnum.SingleField,
              name: values.label,
              icon
            },
            companySettingFields
          })
        }
      }
      setIsSubmitting(true)
    }
  })

  const handleCancel = useCallback(() => {
    setCheckboxItems([])
    formik.resetForm()
    onCancel()
  }, [formik, onCancel])

  const handleClose = useCallback(() => {
    setCheckboxItems([])
    formik.resetForm()
    onClose()
  }, [formik, onClose])

  useEffect(() => {
    let settingField: ContactSettingField | CompanySettingField | undefined =
      domain === 'contact'
        ? (defaultValue as ContactSetting)?.contactSettingFields?.[0]
        : (defaultValue as CompanySetting)?.companySettingFields?.[0]

    let settingFieldItems: Array<ContactSettingFieldItem> | Array<CompanySettingFieldItem> | null | undefined =
      domain === 'contact'
        ? (settingField as ContactSettingField)?.contactSettingFieldItems
        : (settingField as CompanySettingField)?.companySettingFieldItems

    const defaultKind = settingField?.kind
    if (defaultKind === 'checkbox' || defaultKind === 'dropdown' || defaultKind === 'radio') {
      setCheckboxItems(
        settingFieldItems?.map(item => ({
          id: item.id,
          name: item.name
        }))!
      )
    }
  }, [defaultValue, domain])

  useEffect(() => {
    if (
      isSubmitting &&
      !isLoadingCreateCompany &&
      !isLoadingCreateContact &&
      !isLoadingUpdateContact &&
      !isLoadingUpdateCompany &&
      !errorCreateCompany &&
      !errorCreateContact &&
      !errorUpdateContact &&
      !errorUpdateCompany
    ) {
      //- 初期化
      setCheckboxItems([])
      formik.resetForm()
      onConfirm()
      setIsSubmitting(false)
    }
  }, [
    isSubmitting,
    isLoadingCreateCompany,
    isLoadingCreateContact,
    isLoadingUpdateContact,
    isLoadingUpdateCompany,
    errorCreateCompany,
    errorCreateContact,
    errorUpdateContact,
    errorUpdateCompany,
    formik,
    onConfirm
  ])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle={defaultValue ? '項目を編集' : '項目を新規作成'}
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={formik.handleSubmit}
            hideSettings
            buttonTitle="保存して項目を追加"
          >
            <Box px="24px" pt="16px">
              <Box>
                <Typography variant="h5">項目ラベル</Typography>
                <Box mt="8px" />
                <Input fullWidth {...formik.getFieldProps('label')} disabled={defaultValue && !defaultValue?.canEdit} />
              </Box>
              <Box mt="16px" />
              <Box>
                <Typography variant="h5">項目の種類</Typography>
                <Box mt="8px" />
                <SelectBox
                  size="medium"
                  listWidth="328px"
                  placeholder="選択してください"
                  optionList={formik.values.optionItemList}
                  disabled={!!defaultValue}
                  {...formik.getFieldProps('kind')}
                />
              </Box>
              <Box mt="24px" />
              {(formik.values.kind === 'radio' ||
                formik.values.kind === 'checkbox' ||
                formik.values.kind === 'dropdown') && (
                <FormMultiple
                  checkboxItems={checkboxItems}
                  onChange={items => {
                    setCheckboxItems([...items])
                  }}
                />
              )}
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
