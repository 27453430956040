/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AuthVisitorAuthenticationCodeRequestResponseVisitorVisitorContactRequestDataFromJSON, AuthVisitorAuthenticationCodeRequestResponseVisitorVisitorContactRequestDataToJSON } from './AuthVisitorAuthenticationCodeRequestResponseVisitorVisitorContactRequestData';
export function AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSON(json) {
    return AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSONTyped(json, false);
}
export function AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        visitorContactRequestData: !exists(json, 'visitor_contact_request_data')
            ? undefined
            : AuthVisitorAuthenticationCodeRequestResponseVisitorVisitorContactRequestDataFromJSON(json['visitor_contact_request_data'])
    };
}
export function AuthVisitorAuthenticationCodeRequestResponseVisitorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        visitor_contact_request_data: AuthVisitorAuthenticationCodeRequestResponseVisitorVisitorContactRequestDataToJSON(value.visitorContactRequestData)
    };
}
