/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetOrganizationPaymentPlanCheckUsageLimitDataFromJSON, GetOrganizationPaymentPlanCheckUsageLimitDataToJSON } from './GetOrganizationPaymentPlanCheckUsageLimitData';
export function GetOrganizationPaymentPlanCheckUsageLimitFromJSON(json) {
    return GetOrganizationPaymentPlanCheckUsageLimitFromJSONTyped(json, false);
}
export function GetOrganizationPaymentPlanCheckUsageLimitFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        status: json['status'],
        data: GetOrganizationPaymentPlanCheckUsageLimitDataFromJSON(json['data'])
    };
}
export function GetOrganizationPaymentPlanCheckUsageLimitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        status: value.status,
        data: GetOrganizationPaymentPlanCheckUsageLimitDataToJSON(value.data)
    };
}
