import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Guide = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12,23.03c2.85-2.4,5.7-4.79,8.55-7.19V.81c-2.85,1.91-5.7,3.82-8.55,5.73v16.49Z" />
      <path
        style={{ fill: 'white' }}
        d="M12,22.84c-2.79-2.34-5.59-4.67-8.38-7.01V1.16c2.79,1.86,5.59,3.73,8.38,5.59v16.08Z"
      />
      <path d="M20.73.5c-.11-.06-.23-.09-.35-.09-.15,0-.29.04-.42.13l-4.19,2.79-3.77,2.52-3.77-2.52L4.04.54c-.13-.08-.27-.13-.42-.13-.12,0-.24.03-.35.09-.24.13-.4.38-.4.66v14.67c0,.22.1.43.27.58l4.19,3.5,4.19,3.5s.07.04.11.06c.02.01.04.03.05.04,0,0,.02,0,.03,0,.09.04.19.06.29.06,0,0,0,0,0,0h0s0,0,0,0c.1,0,.2-.03.29-.06,0,0,.02,0,.03,0,.05-.02.09-.05.13-.08.01,0,.03-.01.04-.02l4.19-3.5,4.19-3.5c.17-.14.27-.35.27-.58V1.16c0-.28-.15-.53-.4-.66ZM20.38,15.83c-2.54,2.13-5.09,4.25-7.63,6.38h0c-.25.21-.5.42-.75.63-.25-.21-.5-.42-.75-.64h0c-2.54-2.12-5.09-4.25-7.63-6.37V1.16c2.79,1.86,5.59,3.73,8.38,5.59,2.79-1.86,5.59-3.73,8.38-5.59v14.67Z" />
    </SvgIcon>
  )
}

export default Guide
