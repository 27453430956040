import { Box, ButtonBase } from '@material-ui/core'
import { useState, useCallback, useMemo } from 'react'
import { Button, Card, Checkbox, Typography, Input, Label } from 'src/components/atoms'
import { BackButton } from '../../BackButton'
import { center } from '../../styles'
import { optionList } from '../../utils'
import { CheckMap } from './type'

interface StepReasonSelectionProps {
  onNext: (checkedList: string[], otherReason?: string) => void
  onBack?: () => void
}
export const StepReasonSelection = ({ onNext, onBack }: StepReasonSelectionProps) => {
  const [otherReason, setOtherReason] = useState('')
  const [checkMap, setCheckMap] = useState<CheckMap>(() => {
    const map: CheckMap = {}
    optionList.forEach(item => (map[item.value] = false))
    return map
  })

  const isValid = useMemo(() => {
    return Object.keys(checkMap).some(key => checkMap[key])
  }, [checkMap])

  const handleClickCheck = useCallback(
    (id: string, value: boolean) => {
      const newMap = { ...checkMap }
      newMap[id] = value
      setCheckMap(newMap)
    },
    [checkMap]
  )

  const handleSubmit = useCallback(() => {
    const list = Object.keys(checkMap).filter(key => checkMap[key])
    onNext(list, otherReason)
  }, [checkMap, onNext, otherReason])

  return (
    <Box>
      <Card>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: '16px' }}>
          <Typography fontSize="s" fontWeight="bold" lineheight="1">
            ご解約の理由を教えてください （複数選択）
          </Typography>
          <Box width="6px" />
          <Label type="negative" labelText="必須" size="small" />
        </Box>
        <Box>
          {optionList.map((item, index) => {
            const isChecked = checkMap[item.value]
            return (
              <Box
                key={index}
                onClick={() => handleClickCheck(item.value, !isChecked)}
                sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #E9ECF1' }}
              >
                <Checkbox checked={isChecked} />
                <ButtonBase onClick={() => handleClickCheck(item.value, !isChecked)}>
                  <Typography fontSize="s">{item.label}</Typography>
                </ButtonBase>
              </Box>
            )
          })}

          <Box sx={{ mt: '24px' }}>その他</Box>
          <Box>
            <Input
              name="otherReason"
              fullWidth
              multiline
              rows={2}
              value={otherReason}
              onChange={e => setOtherReason(e.currentTarget.value)}
              resize="vertical"
            />
          </Box>
        </Box>
      </Card>

      <Box sx={{ ...center, mt: '24px' }}>
        <Button onClick={handleSubmit} title={'お手続きの確認に進む'} disabled={!isValid} />
      </Box>

      <Box sx={{ ...center, mt: '24px', mb: '31px' }}>
        <BackButton onClick={() => onBack?.()} />
      </Box>
    </Box>
  )
}
