/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  EnumListResourceType,
  EnumListResourceTypeFromJSON,
  EnumListResourceTypeToJSON,
  GetStaffListsResponse,
  GetStaffListsResponseFromJSON,
  GetStaffListsResponseToJSON,
  PinStaffListRequest,
  PinStaffListRequestFromJSON,
  PinStaffListRequestToJSON
} from '../models'

export interface UserV1StaffListsGetRequest {
  resourceType?: EnumListResourceType
  page?: number
  per?: number
}

export interface UserV1StaffListsPinPostRequest {
  pinStaffListRequest?: PinStaffListRequest
}

/**
 *
 */
export class ApiUserV1StaffListApi extends runtime.BaseAPI {
  /**
   * 担当者のピン設定一覧と、紐づくリソースの件数を取得する
   * index
   */
  async userV1StaffListsGetRaw(
    requestParameters: UserV1StaffListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetStaffListsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.resourceType !== undefined) {
      queryParameters['resource_type'] = requestParameters.resourceType
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/staff_lists`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetStaffListsResponseFromJSON(jsonValue))
  }

  /**
   * 担当者のピン設定一覧と、紐づくリソースの件数を取得する
   * index
   */
  async userV1StaffListsGet(
    requestParameters: UserV1StaffListsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetStaffListsResponse> {
    const response = await this.userV1StaffListsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 担当者のピン設定をする
   * pin
   */
  async userV1StaffListsPinPostRaw(
    requestParameters: UserV1StaffListsPinPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/staff_lists/pin`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PinStaffListRequestToJSON(requestParameters.pinStaffListRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * 担当者のピン設定をする
   * pin
   */
  async userV1StaffListsPinPost(
    requestParameters: UserV1StaffListsPinPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1StaffListsPinPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
