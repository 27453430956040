/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GetUsersResponseDataUsersInnerUserAvatarFromJSON, GetUsersResponseDataUsersInnerUserAvatarToJSON } from './GetUsersResponseDataUsersInnerUserAvatar';
export function FilterStaffPinsFromJSON(json) {
    return FilterStaffPinsFromJSONTyped(json, false);
}
export function FilterStaffPinsFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        counts: !exists(json, 'counts') ? undefined : json['counts'],
        userId: !exists(json, 'user_id') ? undefined : json['user_id'],
        text: !exists(json, 'text') ? undefined : json['text'],
        avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
        isPin: !exists(json, 'is_pin') ? undefined : json['is_pin']
    };
}
export function FilterStaffPinsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        counts: value.counts,
        user_id: value.userId,
        text: value.text,
        avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
        is_pin: value.isPin
    };
}
