/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ChangeUserContactsEmailTransactionsRequest,
  ChangeUserContactsEmailTransactionsRequestFromJSON,
  ChangeUserContactsEmailTransactionsRequestToJSON,
  ChangeUserContactsEmailTransactionsResponse,
  ChangeUserContactsEmailTransactionsResponseFromJSON,
  ChangeUserContactsEmailTransactionsResponseToJSON,
  EnumEmailTransactionableSendStatus,
  EnumEmailTransactionableSendStatusFromJSON,
  EnumEmailTransactionableSendStatusToJSON,
  GetContactsEmailTransactionsSendErrorsResponse,
  GetContactsEmailTransactionsSendErrorsResponseFromJSON,
  GetContactsEmailTransactionsSendErrorsResponseToJSON,
  GetCountContactsEmailTransactionsResponse,
  GetCountContactsEmailTransactionsResponseFromJSON,
  GetCountContactsEmailTransactionsResponseToJSON,
  IgnoreErrorsContactsEmailTransactionsRequest,
  IgnoreErrorsContactsEmailTransactionsRequestFromJSON,
  IgnoreErrorsContactsEmailTransactionsRequestToJSON,
  IgnoreErrorsContactsEmailTransactionsResponse,
  IgnoreErrorsContactsEmailTransactionsResponseFromJSON,
  IgnoreErrorsContactsEmailTransactionsResponseToJSON
} from '../models'

export interface UserV1ContactsEmailTransactionsChangeUserPutRequest {
  changeUserContactsEmailTransactionsRequest?: ChangeUserContactsEmailTransactionsRequest
}

export interface UserV1ContactsEmailTransactionsCountGetRequest {
  sendStatus: EnumEmailTransactionableSendStatus
  sendUserIds?: Array<string>
  documentIds?: Array<string>
  siteIds?: Array<string>
  sendedAtOnFrom?: string
  sendedAtOnTo?: string
}

export interface UserV1ContactsEmailTransactionsIgnoreErrorsPutRequest {
  ignoreErrorsContactsEmailTransactionsRequest?: IgnoreErrorsContactsEmailTransactionsRequest
}

export interface UserV1ContactsEmailTransactionsSendErrorsGetRequest {
  sendUserIds?: Array<string>
  documentIds?: Array<string>
  siteIds?: Array<string>
  sendedAtOnFrom?: string
  sendedAtOnTo?: string
  text?: string
  page?: number
  per?: number
  orderBy?: string
}

/**
 *
 */
export class ApiUserV1ContactEmailTransactionApi extends runtime.BaseAPI {
  /**
   * 送信エラーになっているメールのコンタクトの担当者の変更
   * change_user
   */
  async userV1ContactsEmailTransactionsChangeUserPutRaw(
    requestParameters: UserV1ContactsEmailTransactionsChangeUserPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChangeUserContactsEmailTransactionsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/email_transactions/change_user`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ChangeUserContactsEmailTransactionsRequestToJSON(
          requestParameters.changeUserContactsEmailTransactionsRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      ChangeUserContactsEmailTransactionsResponseFromJSON(jsonValue)
    )
  }

  /**
   * 送信エラーになっているメールのコンタクトの担当者の変更
   * change_user
   */
  async userV1ContactsEmailTransactionsChangeUserPut(
    requestParameters: UserV1ContactsEmailTransactionsChangeUserPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChangeUserContactsEmailTransactionsResponse> {
    const response = await this.userV1ContactsEmailTransactionsChangeUserPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクトへの送信エラーのメールを無視する
   * count
   */
  async userV1ContactsEmailTransactionsCountGetRaw(
    requestParameters: UserV1ContactsEmailTransactionsCountGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetCountContactsEmailTransactionsResponse>> {
    if (requestParameters.sendStatus === null || requestParameters.sendStatus === undefined) {
      throw new runtime.RequiredError(
        'sendStatus',
        'Required parameter requestParameters.sendStatus was null or undefined when calling userV1ContactsEmailTransactionsCountGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sendStatus !== undefined) {
      queryParameters['send_status'] = requestParameters.sendStatus
    }

    if (requestParameters.sendUserIds) {
      queryParameters['send_user_ids[]'] = requestParameters.sendUserIds
    }

    if (requestParameters.documentIds) {
      queryParameters['document_ids[]'] = requestParameters.documentIds
    }

    if (requestParameters.siteIds) {
      queryParameters['site_ids[]'] = requestParameters.siteIds
    }

    if (requestParameters.sendedAtOnFrom !== undefined) {
      queryParameters['sended_at_on_from'] = requestParameters.sendedAtOnFrom
    }

    if (requestParameters.sendedAtOnTo !== undefined) {
      queryParameters['sended_at_on_to'] = requestParameters.sendedAtOnTo
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/email_transactions/count`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetCountContactsEmailTransactionsResponseFromJSON(jsonValue)
    )
  }

  /**
   * コンタクトへの送信エラーのメールを無視する
   * count
   */
  async userV1ContactsEmailTransactionsCountGet(
    requestParameters: UserV1ContactsEmailTransactionsCountGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetCountContactsEmailTransactionsResponse> {
    const response = await this.userV1ContactsEmailTransactionsCountGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクトへの送信エラーのメールを無視する
   * ignore_errors
   */
  async userV1ContactsEmailTransactionsIgnoreErrorsPutRaw(
    requestParameters: UserV1ContactsEmailTransactionsIgnoreErrorsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<IgnoreErrorsContactsEmailTransactionsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/email_transactions/ignore_errors`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: IgnoreErrorsContactsEmailTransactionsRequestToJSON(
          requestParameters.ignoreErrorsContactsEmailTransactionsRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      IgnoreErrorsContactsEmailTransactionsResponseFromJSON(jsonValue)
    )
  }

  /**
   * コンタクトへの送信エラーのメールを無視する
   * ignore_errors
   */
  async userV1ContactsEmailTransactionsIgnoreErrorsPut(
    requestParameters: UserV1ContactsEmailTransactionsIgnoreErrorsPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<IgnoreErrorsContactsEmailTransactionsResponse> {
    const response = await this.userV1ContactsEmailTransactionsIgnoreErrorsPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 送信エラーの一覧取得
   */
  async userV1ContactsEmailTransactionsSendErrorsGetRaw(
    requestParameters: UserV1ContactsEmailTransactionsSendErrorsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactsEmailTransactionsSendErrorsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.sendUserIds) {
      queryParameters['send_user_ids[]'] = requestParameters.sendUserIds
    }

    if (requestParameters.documentIds) {
      queryParameters['document_ids[]'] = requestParameters.documentIds
    }

    if (requestParameters.siteIds) {
      queryParameters['site_ids[]'] = requestParameters.siteIds
    }

    if (requestParameters.sendedAtOnFrom !== undefined) {
      queryParameters['sended_at_on_from'] = requestParameters.sendedAtOnFrom
    }

    if (requestParameters.sendedAtOnTo !== undefined) {
      queryParameters['sended_at_on_to'] = requestParameters.sendedAtOnTo
    }

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/email_transactions/send_errors`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetContactsEmailTransactionsSendErrorsResponseFromJSON(jsonValue)
    )
  }

  /**
   * 送信エラーの一覧取得
   */
  async userV1ContactsEmailTransactionsSendErrorsGet(
    requestParameters: UserV1ContactsEmailTransactionsSendErrorsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactsEmailTransactionsSendErrorsResponse> {
    const response = await this.userV1ContactsEmailTransactionsSendErrorsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
