import { DocumentContact, UserV1DocumentContactsDocumentContactIdPutRequest } from '@noco/http-client/lib/noco'
import { useCallback } from 'react'
import { Toaster } from 'src/components/atoms'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { handleError } from 'src/fixtures/utils/error'
import { useListDocumentContacts } from '../documents/hooks'
import { useListContactExpiredMaterials, useListContactMaterials } from '../contact/hooks'

export const useUpdateDocumentContact = (documentId: string, options?: { page?: number; per?: number }) => {
  const { data: auth } = useAuthenticate()
  const documentContactApi = nocoSDK.client?.userService.documentContactApi
  const { mutate } = useListDocumentContacts({ documentId, ...options })

  const handleUpdateDocumentContact = useCallback(
    async ({ documentContactId, updateDocumentContactRequest }: UserV1DocumentContactsDocumentContactIdPutRequest) => {
      try {
        if (!auth) return
        await documentContactApi
          ?.ApiFactory(auth.token)
          .userV1DocumentContactsDocumentContactIdPut({ documentContactId, updateDocumentContactRequest })
        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        handleError(err, { tag: 'Update document contact' })
      }
    },
    [auth, documentContactApi, mutate]
  )

  return { handleUpdateDocumentContact }
}

export const useExpiredDocumentContact = (contactId: string) => {
  const { mutate } = useListContactMaterials(contactId)
  const { mutate: mutateListContactExpiredMaterials } = useListContactExpiredMaterials({ contactId })
  const { handleUpdateDocumentContact } = useUpdateDocumentContact('')

  const handleExpiredDocumentContact = useCallback(
    async ({ id, canViewable, expiredOn }: DocumentContact) => {
      await handleUpdateDocumentContact({
        documentContactId: id!,
        updateDocumentContactRequest: { documentContact: { canViewable, expiredOn } }
      })
      await mutate()
      await mutateListContactExpiredMaterials()
    },

    [handleUpdateDocumentContact, mutate, mutateListContactExpiredMaterials]
  )

  return { handleExpiredDocumentContact }
}
