import { Box } from '@material-ui/core'
import React from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { CreditCardBrandList } from '../CreditCardBrandList'

export const SupportedCreditCardBrand = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="8px 16px"
      borderRadius="6px"
      bgcolor={Colors.background.lightGray}
    >
      <Typography fontSize="s" lineheight="1">
        対応ブランド
      </Typography>
      <CreditCardBrandList />
    </Box>
  )
}
