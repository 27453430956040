/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  RequestBodyExportCommon,
  RequestBodyExportCommonFromJSON,
  RequestBodyExportCommonToJSON,
  ResponseDataPorterCommon,
  ResponseDataPorterCommonFromJSON,
  ResponseDataPorterCommonToJSON
} from '../models'

export interface UserV1PorterBulkUpdateCompaniesPostRequest {
  file?: Blob
  isSendEmail?: boolean | null
}

export interface UserV1PorterBulkUpdateContactsPostRequest {
  file?: Blob
  isSendEmail?: boolean | null
}

export interface UserV1PorterExportCompaniesPostRequest {
  requestBodyExportCommon?: RequestBodyExportCommon
}

export interface UserV1PorterExportContactsPostRequest {
  requestBodyExportCommon?: RequestBodyExportCommon
}

export interface UserV1PorterImportContactCompaniesPostRequest {
  file?: Blob
  isSendEmail?: boolean | null
}

/**
 *
 */
export class ApiUserV1PorterApi extends runtime.BaseAPI {
  /**
   * 会社の一括更新
   * bulk_update
   */
  async userV1PorterBulkUpdateCompaniesPostRaw(
    requestParameters: UserV1PorterBulkUpdateCompaniesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataPorterCommon>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    if (requestParameters.isSendEmail !== undefined) {
      formParams.append('is_send_email', requestParameters.isSendEmail as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/porter/bulk_update/companies`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataPorterCommonFromJSON(jsonValue))
  }

  /**
   * 会社の一括更新
   * bulk_update
   */
  async userV1PorterBulkUpdateCompaniesPost(
    requestParameters: UserV1PorterBulkUpdateCompaniesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataPorterCommon> {
    const response = await this.userV1PorterBulkUpdateCompaniesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 連絡先の一括更新
   * bulk_update
   */
  async userV1PorterBulkUpdateContactsPostRaw(
    requestParameters: UserV1PorterBulkUpdateContactsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataPorterCommon>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    if (requestParameters.isSendEmail !== undefined) {
      formParams.append('is_send_email', requestParameters.isSendEmail as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/porter/bulk_update/contacts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataPorterCommonFromJSON(jsonValue))
  }

  /**
   * 連絡先の一括更新
   * bulk_update
   */
  async userV1PorterBulkUpdateContactsPost(
    requestParameters: UserV1PorterBulkUpdateContactsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataPorterCommon> {
    const response = await this.userV1PorterBulkUpdateContactsPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社のエクスポート
   * export
   */
  async userV1PorterExportCompaniesPostRaw(
    requestParameters: UserV1PorterExportCompaniesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataPorterCommon>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/porter/export/companies`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RequestBodyExportCommonToJSON(requestParameters.requestBodyExportCommon)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataPorterCommonFromJSON(jsonValue))
  }

  /**
   * 会社のエクスポート
   * export
   */
  async userV1PorterExportCompaniesPost(
    requestParameters: UserV1PorterExportCompaniesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataPorterCommon> {
    const response = await this.userV1PorterExportCompaniesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 連絡先のエクスポート
   * export
   */
  async userV1PorterExportContactsPostRaw(
    requestParameters: UserV1PorterExportContactsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataPorterCommon>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/porter/export/contacts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RequestBodyExportCommonToJSON(requestParameters.requestBodyExportCommon)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataPorterCommonFromJSON(jsonValue))
  }

  /**
   * 連絡先のエクスポート
   * export
   */
  async userV1PorterExportContactsPost(
    requestParameters: UserV1PorterExportContactsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataPorterCommon> {
    const response = await this.userV1PorterExportContactsPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 連絡先・会社インポートで使用するフォーマットファイルの作成
   * format_export
   */
  async userV1PorterFormatExportContactCompaniesPostRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataPorterCommon>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/porter/format_export/contact_companies`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataPorterCommonFromJSON(jsonValue))
  }

  /**
   * 連絡先・会社インポートで使用するフォーマットファイルの作成
   * format_export
   */
  async userV1PorterFormatExportContactCompaniesPost(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataPorterCommon> {
    const response = await this.userV1PorterFormatExportContactCompaniesPostRaw(initOverrides)
    return await response.value()
  }

  /**
   * 連絡先・会社インポート
   * import
   */
  async userV1PorterImportContactCompaniesPostRaw(
    requestParameters: UserV1PorterImportContactCompaniesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResponseDataPorterCommon>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    if (requestParameters.isSendEmail !== undefined) {
      formParams.append('is_send_email', requestParameters.isSendEmail as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/porter/import/contact_companies`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResponseDataPorterCommonFromJSON(jsonValue))
  }

  /**
   * 連絡先・会社インポート
   * import
   */
  async userV1PorterImportContactCompaniesPost(
    requestParameters: UserV1PorterImportContactCompaniesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResponseDataPorterCommon> {
    const response = await this.userV1PorterImportContactCompaniesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
