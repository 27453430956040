import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { Colors } from 'src/components/atoms'

const Circle = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99935 1.66675C5.39102 1.66675 1.66602 5.39175 1.66602 10.0001C1.66602 14.6084 5.39102 18.3334 9.99935 18.3334C14.6077 18.3334 18.3327 14.6084 18.3327 10.0001C18.3327 5.39175 14.6077 1.66675 9.99935 1.66675ZM9.99935 16.6667C6.31602 16.6667 3.33268 13.6834 3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C13.6827 3.33341 16.666 6.31675 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z"
        fill={props.color || Colors.base.black}
      />
    </SvgIcon>
  )
}

export default Circle
