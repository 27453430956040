import React from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Typography, Colors, DynamicMuiIcon } from 'src/components/atoms'
import clsx from 'clsx'

const useStyles = makeStyles(() => {
  return createStyles({
    linefeed: {
      whiteSpace: 'pre-wrap'
    },
    rotation: {
      animation: '$rotation 2s infinite linear'
    },
    '@keyframes rotation': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(-360deg)'
      }
    }
  })
})

export const CheckFile = () => {
  const classes = useStyles()

  return (
    <Box display="flex" color={Colors.base.middleGray} className={classes.linefeed}>
      <DynamicMuiIcon variant="sync" size="20px" className={clsx(classes.rotation, classes['@keyframes rotation'])} />
      <Box ml="6px" />
      <Typography fontWeight={600} fontSize="s" lineheight="18.48px" letterSpacing="tight">
        ファイルをエクスポート中です。完了したら、ファイルダウンロードリンクが記載されたメールを送信します
      </Typography>
    </Box>
  )
}
