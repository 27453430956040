/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface VerifyEmailRequest
 */
export interface VerifyEmailRequest {
  /**
   *
   * @type {string}
   * @memberof VerifyEmailRequest
   */
  email?: string
}

export function VerifyEmailRequestFromJSON(json: any): VerifyEmailRequest {
  return VerifyEmailRequestFromJSONTyped(json, false)
}

export function VerifyEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyEmailRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email']
  }
}

export function VerifyEmailRequestToJSON(value?: VerifyEmailRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    email: value.email
  }
}
