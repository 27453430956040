/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateContactSettingRequestToJSON, GetContactSettingsResponseFromJSON, UpdateContactSettingRequestToJSON, UpdateSortContactSettingRequestToJSON, UpdateSortContactSettingsResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ContactSettingApi extends runtime.BaseAPI {
    /**
     * コンタクト設定項目の削除
     * destroy
     */
    async userV1ContactSettingsContactSettingIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.contactSettingId === null || requestParameters.contactSettingId === undefined) {
            throw new runtime.RequiredError('contactSettingId', 'Required parameter requestParameters.contactSettingId was null or undefined when calling userV1ContactSettingsContactSettingIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contact_settings/{contact_setting_id}`.replace(`{${'contact_setting_id'}}`, encodeURIComponent(String(requestParameters.contactSettingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * コンタクト設定項目の削除
     * destroy
     */
    async userV1ContactSettingsContactSettingIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1ContactSettingsContactSettingIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト設定項目の編集をする
     * update
     */
    async userV1ContactSettingsContactSettingIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.contactSettingId === null || requestParameters.contactSettingId === undefined) {
            throw new runtime.RequiredError('contactSettingId', 'Required parameter requestParameters.contactSettingId was null or undefined when calling userV1ContactSettingsContactSettingIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_settings/{contact_setting_id}`.replace(`{${'contact_setting_id'}}`, encodeURIComponent(String(requestParameters.contactSettingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactSettingRequestToJSON(requestParameters.updateContactSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * コンタクト設定項目の編集をする
     * update
     */
    async userV1ContactSettingsContactSettingIdPut(requestParameters, initOverrides) {
        const response = await this.userV1ContactSettingsContactSettingIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト設定項目の一覧を取得する
     * index
     */
    async userV1ContactSettingsGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contact_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactSettingsResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト設定項目の一覧を取得する
     * index
     */
    async userV1ContactSettingsGet(initOverrides) {
        const response = await this.userV1ContactSettingsGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * コンタクト設定項目の作成をする
     * create
     */
    async userV1ContactSettingsPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_settings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactSettingRequestToJSON(requestParameters.createContactSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * コンタクト設定項目の作成をする
     * create
     */
    async userV1ContactSettingsPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactSettingsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト設定項目のソート修正
     * sort
     */
    async userV1ContactSettingsSortPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contact_settings/sort`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSortContactSettingRequestToJSON(requestParameters.updateSortContactSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateSortContactSettingsResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト設定項目のソート修正
     * sort
     */
    async userV1ContactSettingsSortPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactSettingsSortPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
