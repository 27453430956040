import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const EditNote = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.611 7.91667C11.611 8.375 11.236 8.75 10.7777 8.75H3.27767C2.81934 8.75 2.44434 8.375 2.44434 7.91667C2.44434 7.45833 2.81934 7.08333 3.27767 7.08333H10.7777C11.236 7.08333 11.611 7.45833 11.611 7.91667ZM2.44434 4.58333C2.44434 5.04167 2.81934 5.41667 3.27767 5.41667H10.7777C11.236 5.41667 11.611 5.04167 11.611 4.58333C11.611 4.125 11.236 3.75 10.7777 3.75H3.27767C2.81934 3.75 2.44434 4.125 2.44434 4.58333ZM8.27767 11.25C8.27767 10.7917 7.90267 10.4167 7.44434 10.4167H3.27767C2.81934 10.4167 2.44434 10.7917 2.44434 11.25C2.44434 11.7083 2.81934 12.0833 3.27767 12.0833H7.44434C7.90267 12.0833 8.27767 11.7083 8.27767 11.25ZM14.9527 9.475L15.5443 8.88333C15.8693 8.55833 16.3943 8.55833 16.7193 8.88333L17.311 9.475C17.636 9.8 17.636 10.325 17.311 10.65L16.7193 11.2417L14.9527 9.475ZM14.361 10.0667L10.061 14.3667C9.986 14.4417 9.94434 14.5417 9.94434 14.6583V15.8333C9.94434 16.0667 10.1277 16.25 10.361 16.25H11.536C11.6443 16.25 11.7527 16.2083 11.8277 16.125L16.1277 11.825L14.361 10.0667Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default EditNote
