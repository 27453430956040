/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumMaterialableType,
  EnumMaterialableTypeFromJSON,
  EnumMaterialableTypeFromJSONTyped,
  EnumMaterialableTypeToJSON
} from './EnumMaterialableType'

/**
 *
 * @export
 * @interface MaterialableForDisplay
 */
export interface MaterialableForDisplay {
  /**
   *
   * @type {EnumMaterialableType}
   * @memberof MaterialableForDisplay
   */
  materiableType?: EnumMaterialableType
  /**
   *
   * @type {string}
   * @memberof MaterialableForDisplay
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof MaterialableForDisplay
   */
  displayName?: string
}

export function MaterialableForDisplayFromJSON(json: any): MaterialableForDisplay {
  return MaterialableForDisplayFromJSONTyped(json, false)
}

export function MaterialableForDisplayFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialableForDisplay {
  if (json === undefined || json === null) {
    return json
  }
  return {
    materiableType: !exists(json, 'materiable_type')
      ? undefined
      : EnumMaterialableTypeFromJSON(json['materiable_type']),
    id: !exists(json, 'id') ? undefined : json['id'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name']
  }
}

export function MaterialableForDisplayToJSON(value?: MaterialableForDisplay | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    materiable_type: EnumMaterialableTypeToJSON(value.materiableType),
    id: value.id,
    display_name: value.displayName
  }
}
