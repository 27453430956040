/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumMaterialableTypeFromJSON, EnumMaterialableTypeToJSON } from './EnumMaterialableType';
export function FilterContentPinsFromJSON(json) {
    return FilterContentPinsFromJSONTyped(json, false);
}
export function FilterContentPinsFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        counts: !exists(json, 'counts') ? undefined : json['counts'],
        materialableType: !exists(json, 'materialable_type')
            ? undefined
            : EnumMaterialableTypeFromJSON(json['materialable_type']),
        materialableId: !exists(json, 'materialable_id') ? undefined : json['materialable_id'],
        text: !exists(json, 'text') ? undefined : json['text'],
        isPin: !exists(json, 'is_pin') ? undefined : json['is_pin']
    };
}
export function FilterContentPinsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        counts: value.counts,
        materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
        materialable_id: value.materialableId,
        text: value.text,
        is_pin: value.isPin
    };
}
