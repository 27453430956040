import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { Box, ButtonBase, CssBaseline, Drawer, Typography } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Colors, SvgIcon } from 'src/components/atoms'
import { DocumentToolBar } from 'src/components/organisms/DocumentToolBar'
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Document } from '@noco/http-client/lib/noco'
import { CTADialog } from 'src/components/organisms/CTADialog'
import { PopupPosition } from 'src/components/organisms'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      background: Colors.background.lightGray
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      top: 'auto',
      width: drawerWidth,
      background: Colors.background.lightGray,
      borderRight: `2px solid ${Colors.background.silver}`
    },
    content: {
      position: 'relative',
      // height: '100vh',
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    menuDocumentListItem: {
      width: '100%',
      borderRadius: 4,
      filter: `drop-shadow(0px 0px 1px ${Colors.background.silver})`
    },
    preview: {
      height: 'calc(100% - 60px)',
      width: '100%',
      '& .react-transform-component': {
        justifyContent: 'center'
      }
    },
    currentSlideImg: {
      maxHeight: '100%',
      width: '100%',
      objectFit: 'contain',
      filter: `drop-shadow(0px 0px 1px ${Colors.background.silver})`
    },
    actionMenu: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 60,
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      zIndex: 100,
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      boxShadow: 'inset 0px 1px 0px #EEF1F4',
      backgroundColor: 'white'
    },
    menuPreviewItem: {
      width: '100%',
      background: Colors.background.lightGray
    },
    isActiveMenuPreviewItem: {
      background: Colors.selected.background
    },
    menuPreviewItemButton: {
      width: '100%',
      borderLeft: `3px solid ${Colors.background.lightGray}`
    },
    isActiveMenuPreviewItemButton: {
      borderLeft: `3px solid ${Colors.primary.default}`
    },
    toolBarWrap: {
      background: theme.palette.background.default,
      border: `1px solid ${Colors.background.gray}`,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      borderRadius: 10,
      width: 420,
      height: 44,
      margin: 'auto'
    },
    ctaLabel: {
      background: theme.palette.background.default,
      border: `1px solid ${Colors.background.gray}`,
      borderRadius: 10,
      position: 'absolute',
      top: '0',
      right: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px',
      color: Colors.accent.keyRed.default
    }
  })
)

const INITIAL_SCALE = 1
const TOOLBAR_SCALE = 10
const MIN_SCALE = 0.5
const MAX_SLACE = 2

export const Main = ({ document }: { document: Document }) => {
  const classes = useStyles()
  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null)
  const [shownPage, setShownPage] = useState(1)
  const [open, setOpen] = useState(true)
  const [openCta, setOpenCta] = useState(false)
  const [scale, setScale] = useState(INITIAL_SCALE)
  const documentWrapperRef = useRef(null)

  const totalPage = document.currentSlide?.slideImages?.length ?? 0
  const currentSlide = useMemo(
    () => document.currentSlide?.slideImages?.find(item => item.numberOfPage === shownPage),
    [document.currentSlide?.slideImages, shownPage]
  )

  const displayCta = useMemo(() => {
    return document.documentCta?.isView && shownPage === document.documentCta.page && openCta
  }, [document.documentCta?.isView, document.documentCta?.page, shownPage, openCta])

  const ctaPosition = useMemo(() => {
    return {
      [PopupPosition.CENTER]: { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
      [PopupPosition.TOP_LEFT]: { top: '0%', left: '0%', transform: 'translate(0%, 0%)' },
      [PopupPosition.TOP_RIGHT]: { top: '0%', left: '100%', transform: 'translate(-100%, 0%)' },
      [PopupPosition.BOTTOM_LEFT]: { top: '100%', left: '0%', transform: 'translate(0%, -100%)' },
      [PopupPosition.BOTTOM_RIGHT]: { top: '100%', left: '100%', transform: 'translate(-100%, -100%)' }
    }[(document.documentCta?.popupPosition || PopupPosition.CENTER) as PopupPosition]
  }, [document])

  const onChangeDrawerStatus = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setOpen(!open)
    },
    [setOpen, open]
  )

  const onResetScale = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      transformComponentRef.current?.resetTransform()
      transformComponentRef.current?.centerView(INITIAL_SCALE)
      setScale(INITIAL_SCALE)
    },
    [transformComponentRef]
  )

  const onFullSizePreview = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      transformComponentRef.current?.zoomToElement('setting-page-wrapper')
    },
    [transformComponentRef]
  )

  const renderMenuDocumentList = useMemo(() => {
    return Array.from(new Array(totalPage), (el, index) => {
      const pageNumber = index + 1
      const hasCta = pageNumber === document.documentCta?.page
      const onChangePage = () => {
        setShownPage(pageNumber)
      }
      const slideImage = document.currentSlide?.slideImages?.find(item => item.numberOfPage === pageNumber)
      return (
        <ButtonBase
          className={clsx(classes.menuPreviewItem, {
            [classes.isActiveMenuPreviewItem]: shownPage === pageNumber
          })}
          key={`page_${pageNumber}`}
          onClick={onChangePage}
        >
          <Box
            className={clsx(classes.menuPreviewItemButton, {
              [classes.isActiveMenuPreviewItemButton]: shownPage === pageNumber
            })}
            my={1.5}
            mr={2}
            onClick={onChangePage}
          >
            <Box display="flex" alignItems="center" width="100%">
              <Box mx={2} color={shownPage === pageNumber ? Colors.primary.default : Colors.base.middleGray} width={16}>
                <Typography variant="h5">{index + 1}</Typography>
              </Box>

              <Box position="relative">
                <img className={classes.menuDocumentListItem} src={String(slideImage?.file?.url)} />
                {hasCta && (
                  <Box className={classes.ctaLabel}>
                    <SvgIcon variant="adsClick" color={Colors.accent.keyRed.default} size={13} />
                    <Box width={8} />
                    <Typography variant="h5">CTA</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </ButtonBase>
      )
    })
  }, [totalPage, setShownPage, shownPage, classes, document])

  const onChaneScale = useCallback(
    (value: number | number[]) => {
      const isNumber = (item: number | number[]): item is number => {
        return typeof item === 'number'
      }
      if (isNumber(value)) {
        setScale(value / TOOLBAR_SCALE)
        transformComponentRef.current?.centerView(value / TOOLBAR_SCALE)
      }
    },
    [transformComponentRef, setScale]
  )

  return (
    <>
      <div className={classes.root} ref={documentWrapperRef}>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <Box>
            {renderMenuDocumentList}
            {/* absolute 対応しているので header 分の高さを下に敷いている*/}
            <Box height="60px" width="1px" />
          </Box>
        </Drawer>

        <Box
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <Box height="100%" display="flex" position="relative" flexDirection="column">
            {document.documentCta?.isView && shownPage === document.documentCta.page && (
              <ButtonBase
                onClick={() => setOpenCta(v => !v)}
                style={{
                  top: 0,
                  right: 0,
                  zIndex: 9999,
                  position: 'absolute',
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  border: '1px solid #DADCE0',
                  height: '28px',
                  padding: '0 6px'
                }}
              >
                {/* CTA  */}
                <SvgIcon variant="adsClick" size="16px" color={Colors.base.middleGray} />
                <Box ml={2} sx={{ color: Colors.base.middleGray, fontSize: '14px', fontWeight: 'bold' }}>
                  CTAを{openCta ? '非表示' : '表示'}
                </Box>
              </ButtonBase>
            )}
            <Box width={1} height={1} className={classes.preview}>
              {/* <ClickAwayListener disableReactTree onClickAway={() => setOpenCta(false)}> */}
              <Box
                style={{
                  ...ctaPosition,
                  position: 'absolute',
                  pointerEvents: displayCta ? 'auto' : 'none',
                  zIndex: 9999,
                  opacity: displayCta ? 1 : 0,
                  transition: '512ms'
                }}
              >
                <CTADialog cta={document.documentCta} onClose={() => setOpenCta(false)} />
              </Box>
              {/* </ClickAwayListener> */}

              <TransformWrapper
                ref={transformComponentRef}
                wheel={{ disabled: true }}
                doubleClick={{ disabled: true }}
                minScale={MIN_SCALE}
                maxScale={MAX_SLACE}
                initialScale={INITIAL_SCALE}
                centerOnInit
              >
                <TransformComponent
                  contentStyle={{ width: '100%', height: '100%' }}
                  wrapperStyle={{ width: '100%', height: '100%' }}
                >
                  {currentSlide && (
                    <img
                      id="setting-page-wrapper"
                      className={classes.currentSlideImg}
                      src={String(currentSlide.file?.url)}
                    />
                  )}
                </TransformComponent>
              </TransformWrapper>
            </Box>
          </Box>

          <Box className={classes.actionMenu}>
            <DocumentToolBar
              scale={scale}
              onChaneScale={onChaneScale}
              onChangeDrawerStatus={onChangeDrawerStatus}
              onResetScale={onResetScale}
              onFullSizePreview={onFullSizePreview}
            />
          </Box>
        </Box>
      </div>
    </>
  )
}
