import { Box } from '@material-ui/core'
import React, { useCallback } from 'react'
import { Colors, DynamicMuiIcon, Input } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { ContactName } from '../ContactName'
import { CompanyInformation } from '../CompanyInformation'
import { MailAndPhone } from '../MailAndPhone'
import { CompanyName } from '../CompanyName'
import { Address } from '../Address'
import { Chat } from '../Chat'
import { CompanySuggestList } from './CompanySuggestList'
import { ContactSuggestList } from './ContactSuggestList'
import { InboxSuggestList } from './InboxSuggestList'

export interface SearchAndSuggestListProps {
  currentPage: number
  keyword: string
  type: 'contact' | 'company' | 'inbox'
  onClick: (id: string) => void
  onClose: () => void
  onCurrentPageChange: (page: number) => void
  onSearchKeyword: (value: string) => void
}

export interface SearchAndSuggestOptionData {
  label:
    | {
        [x: string]: string | undefined
      }[]
    | undefined
  value: string
}

export const SearchAndSuggestList = ({
  onSearchKeyword,
  keyword,
  onClick,
  onClose,
  currentPage,
  onCurrentPageChange,
  type
}: SearchAndSuggestListProps) => {
  const globalClasses = useGlobalStyles()

  const handleCloseOptionList = useCallback(() => {
    onSearchKeyword('')
  }, [onSearchKeyword])

  const handleChangeKeyword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        onSearchKeyword(event.target.value)
      } else {
        onSearchKeyword('')
      }
    },
    [onSearchKeyword]
  )
  const handleSelectOption = useCallback(
    (value: SearchAndSuggestOptionData['value']) => () => {
      onClick(value)
      handleCloseOptionList()
      onClose()
    },
    [onClick, onClose, handleCloseOptionList]
  )

  return (
    <Box>
      <Input
        onChange={handleChangeKeyword}
        name="companyName"
        width="100%"
        autoFocus
        borderRadius="10px 10px 0px 0px"
        value={keyword}
        borderStyle="none"
        boxShadow="none"
        startAdornment={
          <Box
            pl="14px"
            color={Colors.base.middleGray}
            fontSize="18px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
          </Box>
        }
        endAdornment={
          keyword ? (
            <Box
              p="10px"
              color={Colors.base.middleGray}
              fontSize="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={handleCloseOptionList}
              className={globalClasses.cursorPointer}
            >
              <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
            </Box>
          ) : undefined
        }
      />

      {type === 'company' && (
        <CompanySuggestList
          keyword={keyword}
          currentPage={currentPage}
          onSelect={handleSelectOption}
          onCurrentPageChange={onCurrentPageChange}
        />
      )}
      {type === 'contact' && (
        <ContactSuggestList
          keyword={keyword}
          currentPage={currentPage}
          onSelect={handleSelectOption}
          onCurrentPageChange={onCurrentPageChange}
        />
      )}
      {type === 'inbox' && (
        <InboxSuggestList
          keyword={keyword}
          currentPage={currentPage}
          onSelect={handleSelectOption}
          onCurrentPageChange={onCurrentPageChange}
        />
      )}
    </Box>
  )
}

interface OptionProps {
  option: {
    value: string
    label?:
      | {
          [x: string]: string | undefined
        }[]
      | undefined
    company?: string | null
    text?: string
    name?: string
    kana?: string | null
    dateTime?: string | null
  }
  type: 'contact' | 'company' | 'inbox'
}
export const Option = ({ option, type }: OptionProps) => {
  let contactName = undefined
  let kana = undefined
  let department = undefined
  let position = undefined
  let mail = undefined
  let tel = undefined
  let mobile = undefined
  let address = undefined

  option.label?.map(label => {
    const keyName = Object.keys(label)[0]
    switch (keyName) {
      case '名前':
        contactName = label[keyName]
        break
      case '名前（ふりがな）':
        kana = label[keyName]
        break
      case '所属部門名':
        department = label[keyName]
        break
      case '役職':
        position = label[keyName]
        break
      case 'メールアドレス':
      case '代表メールアドレス':
        mail = label[keyName]
        break
      case '電話番号':
      case '代表電話番号':
        tel = label[keyName]
        break
      case '携帯電話番号':
        mobile = label[keyName]
        break
      case '住所':
        address = label[keyName]
        break
    }
  })
  return (
    <Box display="flex" flexDirection="column">
      {type === 'contact' && (
        <Box>
          <ContactName name={contactName} kana={kana} />
          <CompanyInformation companyName={option.company} department={department} position={position} />
          <MailAndPhone mail={mail} tel={tel} mobile={mobile} />
        </Box>
      )}
      {type === 'company' && (
        <Box>
          <CompanyName name={option.company} />
          <MailAndPhone mail={mail} tel={tel} type="company" />
          <Address address={address} />
        </Box>
      )}
      {type === 'inbox' && (
        <Box>
          <CompanyInformation companyName={option.company} dateTime={option.dateTime} type="inbox" />
          <ContactName name={option.name} kana={option.kana} />
          <Chat chat={option.text} />
        </Box>
      )}
    </Box>
  )
}
