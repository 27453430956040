/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetDocumentOpportunityUsersResponseData,
  GetDocumentOpportunityUsersResponseDataFromJSON,
  GetDocumentOpportunityUsersResponseDataFromJSONTyped,
  GetDocumentOpportunityUsersResponseDataToJSON
} from './GetDocumentOpportunityUsersResponseData'

/**
 *
 * @export
 * @interface GetDocumentOpportunityUsersResponse
 */
export interface GetDocumentOpportunityUsersResponse {
  /**
   *
   * @type {number}
   * @memberof GetDocumentOpportunityUsersResponse
   */
  status?: number
  /**
   *
   * @type {GetDocumentOpportunityUsersResponseData}
   * @memberof GetDocumentOpportunityUsersResponse
   */
  data?: GetDocumentOpportunityUsersResponseData
}

export function GetDocumentOpportunityUsersResponseFromJSON(json: any): GetDocumentOpportunityUsersResponse {
  return GetDocumentOpportunityUsersResponseFromJSONTyped(json, false)
}

export function GetDocumentOpportunityUsersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentOpportunityUsersResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetDocumentOpportunityUsersResponseDataFromJSON(json['data'])
  }
}

export function GetDocumentOpportunityUsersResponseToJSON(value?: GetDocumentOpportunityUsersResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetDocumentOpportunityUsersResponseDataToJSON(value.data)
  }
}
