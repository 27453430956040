/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumMaterialableType,
  EnumMaterialableTypeFromJSON,
  EnumMaterialableTypeFromJSONTyped,
  EnumMaterialableTypeToJSON
} from './EnumMaterialableType'

/**
 *
 * @export
 * @interface PinMaterialListRequest
 */
export interface PinMaterialListRequest {
  /**
   *
   * @type {EnumMaterialableType}
   * @memberof PinMaterialListRequest
   */
  materialableType?: EnumMaterialableType
  /**
   *
   * @type {Array<string>}
   * @memberof PinMaterialListRequest
   */
  materialableIds?: Array<string>
}

export function PinMaterialListRequestFromJSON(json: any): PinMaterialListRequest {
  return PinMaterialListRequestFromJSONTyped(json, false)
}

export function PinMaterialListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PinMaterialListRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    materialableType: !exists(json, 'materialable_type')
      ? undefined
      : EnumMaterialableTypeFromJSON(json['materialable_type']),
    materialableIds: !exists(json, 'materialable_ids') ? undefined : json['materialable_ids']
  }
}

export function PinMaterialListRequestToJSON(value?: PinMaterialListRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
    materialable_ids: value.materialableIds
  }
}
