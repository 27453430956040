import { createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import * as BaseIcon from './SvgComponents'

const icons = [
  'ai',
  'csv',
  'xls',
  'xlsx',
  'mp4',
  'mov',
  'mp3',
  'm4a',
  'pdf',
  'ppt',
  'pptx',
  'psd',
  'svg',
  'txt',
  'doc',
  'docx',
  'zip'
] as const
export type IconVariant = typeof icons[number]

export const isTargetIcon = (extention: string): extention is IconVariant => {
  return icons.includes(extention as IconVariant)
}

export interface IconProps {
  variant: IconVariant
}

const useStyles = makeStyles(() => {
  return createStyles({
    icon: {
      width: '46px',
      height: '42px'
    }
  })
})

export const FileIcon = ({ variant }: IconProps) => {
  const classes = useStyles()

  switch (variant) {
    case 'ai':
      return <BaseIcon.Ai classes={{ root: classes.icon }} />
    case 'csv':
      return <BaseIcon.Csv classes={{ root: classes.icon }} />
    case 'xls':
    case 'xlsx':
      return <BaseIcon.Excel classes={{ root: classes.icon }} />
    case 'mp4':
    case 'mov':
      return <BaseIcon.Movie classes={{ root: classes.icon }} />
    case 'mp3':
    case 'm4a':
      return <BaseIcon.Music classes={{ root: classes.icon }} />
    case 'pdf':
      return <BaseIcon.Pdf classes={{ root: classes.icon }} />
    case 'ppt':
    case 'pptx':
      return <BaseIcon.PowerPoint classes={{ root: classes.icon }} />
    case 'psd':
      return <BaseIcon.Psd classes={{ root: classes.icon }} />
    case 'svg':
      return <BaseIcon.Svg classes={{ root: classes.icon }} />
    case 'txt':
      return <BaseIcon.Text classes={{ root: classes.icon }} />
    case 'doc':
    case 'docx':
      return <BaseIcon.Word classes={{ root: classes.icon }} />
    case 'zip':
      return <BaseIcon.Zip classes={{ root: classes.icon }} />
  }
}
