/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetLatestRecordPorterHistoryResponseFromJSON, GetRecordPorterHistoriesResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1RecordPorterHistoryApi extends runtime.BaseAPI {
    /**
     * インポート/エクスポート履歴一覧取得
     * index
     */
    async userV1RecordPorterHistoriesGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/record_porter_histories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetRecordPorterHistoriesResponseFromJSON(jsonValue));
    }
    /**
     * インポート/エクスポート履歴一覧取得
     * index
     */
    async userV1RecordPorterHistoriesGet(initOverrides) {
        const response = await this.userV1RecordPorterHistoriesGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * インポート/エクスポートの最終結果を取得
     * latest
     */
    async userV1RecordPorterHistoriesLatestGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.operation !== undefined) {
            queryParameters['operation'] = requestParameters.operation;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/record_porter_histories/latest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetLatestRecordPorterHistoryResponseFromJSON(jsonValue));
    }
    /**
     * インポート/エクスポートの最終結果を取得
     * latest
     */
    async userV1RecordPorterHistoriesLatestGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1RecordPorterHistoriesLatestGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
