/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetMailTemplatesResponseData,
  GetMailTemplatesResponseDataFromJSON,
  GetMailTemplatesResponseDataFromJSONTyped,
  GetMailTemplatesResponseDataToJSON
} from './GetMailTemplatesResponseData'

/**
 *
 * @export
 * @interface GetMailTemplatesResponse
 */
export interface GetMailTemplatesResponse {
  /**
   *
   * @type {number}
   * @memberof GetMailTemplatesResponse
   */
  status?: number
  /**
   *
   * @type {GetMailTemplatesResponseData}
   * @memberof GetMailTemplatesResponse
   */
  data?: GetMailTemplatesResponseData
}

export function GetMailTemplatesResponseFromJSON(json: any): GetMailTemplatesResponse {
  return GetMailTemplatesResponseFromJSONTyped(json, false)
}

export function GetMailTemplatesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMailTemplatesResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetMailTemplatesResponseDataFromJSON(json['data'])
  }
}

export function GetMailTemplatesResponseToJSON(value?: GetMailTemplatesResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetMailTemplatesResponseDataToJSON(value.data)
  }
}
