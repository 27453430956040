import { Box, CircularProgress, createStyles, makeStyles, Switch } from '@material-ui/core'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, Tooltip, Typography } from 'src/components/atoms'
import { ModalAlertShare, ModalAlertShareProps, PopupTemplate } from 'src/components/modals'
import { ActionMenu, ActionMenuItemType } from 'src/components/molecules'
import { useArchiveSiteDetail } from 'src/fixtures/modules/site/hooks'

export interface HeaderRightSectionProps {
  onOpenModal: () => void
  id: string
  published: boolean | undefined
  onChange: () => void
  isArchived: boolean
  siteTitle: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '80px',
      height: '24px',
      padding: '0px',
      '& .MuiSwitch-switchBase.Mui-checked': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '& .MuiIconButton-root': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    switchBase: {
      color: Colors.base.middleGray,
      backgroundColor: 'none',
      padding: '1px',
      '&$hover': {
        backgroundColor: 'none'
      },
      '&$checked': {
        '& + $track': {
          backgroundColor: Colors.accent.keyPurple.default,
          '&:after': {
            content: "'公開'",
            left: '14px'
          },
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    thumb: {
      color: 'white',
      width: '20px',
      height: '20px',
      margin: '1px'
    },
    track: {
      borderRadius: '20px',
      backgroundColor: Colors.base.middleGray,
      opacity: '1 !important',
      '&:after, &:before': {
        color: 'white',
        fontSize: '14px',
        fontWeight: '700',
        position: 'absolute'
      },
      '&:before': {
        content: "'非公開'",
        right: '10px'
      }
    },
    checked: {
      transform: 'translateX(56px) !important'
    },
    button: {
      '& .MuiButton-startIcon': {
        marginRight: '6px',
        marginLeft: '0'
      }
    }
  })
)

export const HeaderRightSection = ({
  onOpenModal,
  id,
  published,
  onChange,
  isArchived,
  siteTitle
}: HeaderRightSectionProps) => {
  const classes = useStyles()
  const router = useRouter()
  const [confirmOpenModal, setConfirmOpenModal] = useState(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)

  const {
    handleSaveArchivedSiteDetail,
    error: archiveError,
    isLoading: isLoadingArchive,
    isPosted
  } = useArchiveSiteDetail(id)
  const handleCloseModal = useCallback(() => {
    setConfirmOpenModal(false)
  }, [])
  const handleClickModalOkButton = useCallback(() => {
    handleSaveArchivedSiteDetail([id], isArchived)
    handleCloseModal()
  }, [isArchived, id, handleSaveArchivedSiteDetail, handleCloseModal])

  const title = useMemo(() => {
    return isArchived ? 'アーカイブから取り出す' : 'アーカイブ'
  }, [isArchived])

  const alertShareData: Omit<ModalAlertShareProps, 'open' | 'onClose'> = useMemo(
    () => ({
      title: 'サイトの共有はできません',
      description: `資料サイト「${siteTitle}」が非公開のため、サイトの共有はできません`,
      infoList: [
        {
          question: 'サイトの共有をするには？',
          answerList: [
            {
              label: 'サイトを公開にする'
            }
          ]
        }
      ],
      image: '/images/alert-share/site-unpublished-to-open.svg'
    }),
    [siteTitle]
  )

  useEffect(() => {
    if (isPosted && !archiveError) router.push('/multi-documents')
  }, [archiveError, isPosted, router])

  const menuItemList: ActionMenuItemType[] = useMemo(
    () => [
      {
        label: title,
        onClick: () => setConfirmOpenModal(true),
        icon: <DynamicMuiIcon variant="archive" />,
        toolTipText:
          '利用が少ない資料サイトをアーカイブ（一時保管）できます。資料サイトをアーカイブすると、非公開になります。また、アーカイブの状態から元に戻すことができます。'
      }
    ],
    [title]
  )

  const handleShareClick = useCallback(() => {
    if (published) {
      onOpenModal()
    } else {
      setOpenAlert(true)
    }
  }, [onOpenModal, published])

  return (
    <>
      {isLoadingArchive && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0,0,0,0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <ModalAlertShare open={openAlert} onClose={() => setOpenAlert(false)} {...alertShareData} />

      <Box display="flex" alignItems="center">
        <Tooltip
          content={
            '公開すると、資料サイトの共有ができます。非公開にすると、資料サイトを共有することができません。また、既に共有済みの資料サイトを非公開にすることもできます。'
          }
        >
          <Switch
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked
            }}
            checked={published as boolean}
            onChange={isArchived ? undefined : onChange}
            disabled={isArchived}
          />
        </Tooltip>
        <Box width="1px" height="24px" bgcolor={Colors.background.silver} mx="12px" />

        <Tooltip content={'資料サイトの各種設定や表示を自由にカスタマイズできます。'}>
          <Button
            title="サイトの設定"
            kind="secondary"
            variant="outlined"
            startIcon={<DynamicMuiIcon variant="build" size="16px" />}
            size="medium"
            href={`/multi-documents/${id}/setting`}
            disabled={isArchived}
            classes={{ root: classes.button }}
          />
        </Tooltip>
        <Box width="12px" />

        <Tooltip
          content={
            '資料サイトを顧客に共有できます。資料サイトが非公開の場合やアップロード処理中の場合は「サイトの設定」ボタンをクリックできません。'
          }
        >
          <Button
            title="サイトの共有"
            startIcon={<DynamicMuiIcon variant="iosShare" size="16px" />}
            size="medium"
            buttonColor={!published ? Colors.functional.disable.button : undefined}
            onClick={handleShareClick}
            disabled={isArchived}
            classes={{ root: classes.button }}
          />
        </Tooltip>

        <Box ml="12px">
          <ActionMenu menuItemList={menuItemList} />
        </Box>
      </Box>
      <PopupTemplate
        open={confirmOpenModal}
        title={`資料サイトを${title}`}
        onClose={handleCloseModal}
        hasClose
        hasCancel
        hasConfirm
        onConfirm={handleClickModalOkButton}
        cancelButtonLabel="キャンセル"
        confirmButtonLabel="OK"
      >
        <Box p="24px">
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {`選択した資料サイトをアーカイブへ移動します\n本当によろしいですか？`}
          </Typography>
        </Box>
      </PopupTemplate>
    </>
  )
}
