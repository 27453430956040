/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentPlanCheckUsageLimitData,
  GetOrganizationPaymentPlanCheckUsageLimitDataFromJSON,
  GetOrganizationPaymentPlanCheckUsageLimitDataFromJSONTyped,
  GetOrganizationPaymentPlanCheckUsageLimitDataToJSON
} from './GetOrganizationPaymentPlanCheckUsageLimitData'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanCheckUsageLimit
 */
export interface GetOrganizationPaymentPlanCheckUsageLimit {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimit
   */
  status: number
  /**
   *
   * @type {GetOrganizationPaymentPlanCheckUsageLimitData}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimit
   */
  data: GetOrganizationPaymentPlanCheckUsageLimitData
}

export function GetOrganizationPaymentPlanCheckUsageLimitFromJSON(
  json: any
): GetOrganizationPaymentPlanCheckUsageLimit {
  return GetOrganizationPaymentPlanCheckUsageLimitFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanCheckUsageLimitFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanCheckUsageLimit {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetOrganizationPaymentPlanCheckUsageLimitDataFromJSON(json['data'])
  }
}

export function GetOrganizationPaymentPlanCheckUsageLimitToJSON(
  value?: GetOrganizationPaymentPlanCheckUsageLimit | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationPaymentPlanCheckUsageLimitDataToJSON(value.data)
  }
}
