import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { Colors } from 'src/components/atoms'

const Cross = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2496 4.75843C14.9246 4.43343 14.3996 4.43343 14.0746 4.75843L9.99961 8.8251L5.92461 4.7501C5.59961 4.4251 5.07461 4.4251 4.74961 4.7501C4.42461 5.0751 4.42461 5.6001 4.74961 5.9251L8.82461 10.0001L4.74961 14.0751C4.42461 14.4001 4.42461 14.9251 4.74961 15.2501C5.07461 15.5751 5.59961 15.5751 5.92461 15.2501L9.99961 11.1751L14.0746 15.2501C14.3996 15.5751 14.9246 15.5751 15.2496 15.2501C15.5746 14.9251 15.5746 14.4001 15.2496 14.0751L11.1746 10.0001L15.2496 5.9251C15.5663 5.60843 15.5663 5.0751 15.2496 4.75843Z"
        fill={props.color || Colors.base.black}
      />
    </SvgIcon>
  )
}

export default Cross
