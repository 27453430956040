/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ArchiveDocumentsRequest,
  ArchiveDocumentsRequestFromJSON,
  ArchiveDocumentsRequestToJSON,
  ArchiveDocumentsResponse,
  ArchiveDocumentsResponseFromJSON,
  ArchiveDocumentsResponseToJSON,
  CopyDocumentRequest,
  CopyDocumentRequestFromJSON,
  CopyDocumentRequestToJSON,
  CopyDocumentResponse,
  CopyDocumentResponseFromJSON,
  CopyDocumentResponseToJSON,
  CreateDocumentOpportunityUsersResponse,
  CreateDocumentOpportunityUsersResponseFromJSON,
  CreateDocumentOpportunityUsersResponseToJSON,
  CreateDocumentOpporunityUserRequest,
  CreateDocumentOpporunityUserRequestFromJSON,
  CreateDocumentOpporunityUserRequestToJSON,
  CreateDocumentPublishBadResponse,
  CreateDocumentPublishBadResponseFromJSON,
  CreateDocumentPublishBadResponseToJSON,
  CreateDocumentPublishRequest,
  CreateDocumentPublishRequestFromJSON,
  CreateDocumentPublishRequestToJSON,
  CreateDocumentPublishResponse,
  CreateDocumentPublishResponseFromJSON,
  CreateDocumentPublishResponseToJSON,
  CreateDocumentShareWithContactRequest,
  CreateDocumentShareWithContactRequestFromJSON,
  CreateDocumentShareWithContactRequestToJSON,
  CreateDocumentShareWithContactResponse,
  CreateDocumentShareWithContactResponseFromJSON,
  CreateDocumentShareWithContactResponseToJSON,
  FileUpdateDocumentResponse,
  FileUpdateDocumentResponseFromJSON,
  FileUpdateDocumentResponseToJSON,
  FileUploadDocumentResponse,
  FileUploadDocumentResponseFromJSON,
  FileUploadDocumentResponseToJSON,
  GetDocumentContactsResponse,
  GetDocumentContactsResponseFromJSON,
  GetDocumentContactsResponseToJSON,
  GetDocumentMiscSettingResponse,
  GetDocumentMiscSettingResponseFromJSON,
  GetDocumentMiscSettingResponseToJSON,
  GetDocumentOpportunityUsersResponse,
  GetDocumentOpportunityUsersResponseFromJSON,
  GetDocumentOpportunityUsersResponseToJSON,
  GetDocumentResponse,
  GetDocumentResponseFromJSON,
  GetDocumentResponseToJSON,
  GetDocumentSitesResponse,
  GetDocumentSitesResponseFromJSON,
  GetDocumentSitesResponseToJSON,
  GetDocumentViewHistoriesGraphResponse,
  GetDocumentViewHistoriesGraphResponseFromJSON,
  GetDocumentViewHistoriesGraphResponseToJSON,
  GetDocumentViewHistoriesGraphResponse1,
  GetDocumentViewHistoriesGraphResponse1FromJSON,
  GetDocumentViewHistoriesGraphResponse1ToJSON,
  GetDocumentViewHistoriesResponse,
  GetDocumentViewHistoriesResponseFromJSON,
  GetDocumentViewHistoriesResponseToJSON,
  GetDocumentViewHistorySessionsResponse,
  GetDocumentViewHistorySessionsResponseFromJSON,
  GetDocumentViewHistorySessionsResponseToJSON,
  GetDocumentsResponse,
  GetDocumentsResponseFromJSON,
  GetDocumentsResponseToJSON,
  GetSettableDocumentOpportunityUsersResponse,
  GetSettableDocumentOpportunityUsersResponseFromJSON,
  GetSettableDocumentOpportunityUsersResponseToJSON,
  UpdateDocumentChatSettingRequest,
  UpdateDocumentChatSettingRequestFromJSON,
  UpdateDocumentChatSettingRequestToJSON,
  UpdateDocumentChatSettingResponse,
  UpdateDocumentChatSettingResponseFromJSON,
  UpdateDocumentChatSettingResponseToJSON,
  UpdateDocumentCtaImageResponse,
  UpdateDocumentCtaImageResponseFromJSON,
  UpdateDocumentCtaImageResponseToJSON,
  UpdateDocumentCtaRequest,
  UpdateDocumentCtaRequestFromJSON,
  UpdateDocumentCtaRequestToJSON,
  UpdateDocumentCtaResponse,
  UpdateDocumentCtaResponseFromJSON,
  UpdateDocumentCtaResponseToJSON,
  UpdateDocumentMessageRequest,
  UpdateDocumentMessageRequestFromJSON,
  UpdateDocumentMessageRequestToJSON,
  UpdateDocumentMessageResponse,
  UpdateDocumentMessageResponseFromJSON,
  UpdateDocumentMessageResponseToJSON,
  UpdateDocumentMiscSettingRequest,
  UpdateDocumentMiscSettingRequestFromJSON,
  UpdateDocumentMiscSettingRequestToJSON,
  UpdateDocumentMiscSettingResponse,
  UpdateDocumentMiscSettingResponseFromJSON,
  UpdateDocumentMiscSettingResponseToJSON,
  UpdateDocumentOpportunityRequest,
  UpdateDocumentOpportunityRequestFromJSON,
  UpdateDocumentOpportunityRequestToJSON,
  UpdateDocumentOpportunityResponse,
  UpdateDocumentOpportunityResponseFromJSON,
  UpdateDocumentOpportunityResponseToJSON,
  UpdateDocumentRequest,
  UpdateDocumentRequestFromJSON,
  UpdateDocumentRequestToJSON,
  UpdateDocumentResponse,
  UpdateDocumentResponseFromJSON,
  UpdateDocumentResponseToJSON,
  UpdateDocumentShareByLinkBadResponse,
  UpdateDocumentShareByLinkBadResponseFromJSON,
  UpdateDocumentShareByLinkBadResponseToJSON,
  UpdateDocumentShareByLinkRequest,
  UpdateDocumentShareByLinkRequestFromJSON,
  UpdateDocumentShareByLinkRequestToJSON,
  UpdateDocumentShareByLinkResponse,
  UpdateDocumentShareByLinkResponseFromJSON,
  UpdateDocumentShareByLinkResponseToJSON
} from '../models'

export interface UserV1DocumentsArchivePutRequest {
  archiveDocumentsRequest?: ArchiveDocumentsRequest
}

export interface UserV1DocumentsCopyPutRequest {
  copyDocumentRequest?: CopyDocumentRequest
}

export interface UserV1DocumentsDocumentIdChatSettingPutRequest {
  documentId: string
  updateDocumentChatSettingRequest?: UpdateDocumentChatSettingRequest
}

export interface UserV1DocumentsDocumentIdCtaImagePutRequest {
  documentId: string
  file?: Blob
}

export interface UserV1DocumentsDocumentIdCtaPutRequest {
  documentId: string
  updateDocumentCtaRequest?: UpdateDocumentCtaRequest
}

export interface UserV1DocumentsDocumentIdDocumentContactsGetRequest {
  documentId: string
  page?: number
  per?: number
  orderBy?: string
  text?: string
}

export interface UserV1DocumentsDocumentIdFileUpdatePostRequest {
  documentId: string
  file?: Blob
}

export interface UserV1DocumentsDocumentIdGetRequest {
  documentId: string
}

export interface UserV1DocumentsDocumentIdMessagePutRequest {
  documentId: string
  updateDocumentMessageRequest?: UpdateDocumentMessageRequest
}

export interface UserV1DocumentsDocumentIdMiscSettingGetRequest {
  documentId: string
}

export interface UserV1DocumentsDocumentIdMiscSettingOgpFilePutRequest {
  documentId: string
  file?: Blob
}

export interface UserV1DocumentsDocumentIdMiscSettingPutRequest {
  documentId: string
  updateDocumentMiscSettingRequest?: UpdateDocumentMiscSettingRequest
}

export interface UserV1DocumentsDocumentIdOpportunityPutRequest {
  documentId: string
  updateDocumentOpportunityRequest?: UpdateDocumentOpportunityRequest
}

export interface UserV1DocumentsDocumentIdOpportunityUsersGetRequest {
  documentId: string
}

export interface UserV1DocumentsDocumentIdOpportunityUsersPostRequest {
  documentId: string
  createDocumentOpporunityUserRequest?: CreateDocumentOpporunityUserRequest
}

export interface UserV1DocumentsDocumentIdOpportunityUsersUsersGetRequest {
  documentId: string
  text?: string
  id?: string
  ids?: Array<string>
  page?: number
  per?: number
}

export interface UserV1DocumentsDocumentIdPublishPutRequest {
  documentId: string
  createDocumentPublishRequest?: CreateDocumentPublishRequest
}

export interface UserV1DocumentsDocumentIdPutRequest {
  documentId: string
  updateDocumentRequest?: UpdateDocumentRequest
}

export interface UserV1DocumentsDocumentIdShareByLinkPutRequest {
  documentId: string
  updateDocumentShareByLinkRequest?: UpdateDocumentShareByLinkRequest
}

export interface UserV1DocumentsDocumentIdShareToContactPostRequest {
  documentId: string
  createDocumentShareWithContactRequest?: CreateDocumentShareWithContactRequest
}

export interface UserV1DocumentsDocumentIdSitesGetRequest {
  documentId: string
  page?: number
  per?: number
}

export interface UserV1DocumentsDocumentIdViewHistoriesGetRequest {
  documentId: string
  page?: number
  per?: number
}

export interface UserV1DocumentsDocumentIdViewHistoriesGraphGetRequest {
  documentId: string
  contactId: string
  visitorSessionId?: string
}

export interface UserV1DocumentsDocumentIdViewHistoriesSessionsGetRequest {
  documentId: string
  contactId: string
  page?: number
  per?: number
}

export interface UserV1DocumentsFileUploadPostRequest {
  file?: Blob
}

export interface UserV1DocumentsGetRequest {
  text?: string
  isArchived?: boolean
  page?: number
  per?: number
  orderBy?: string
  name?: string
}

export interface UserV1OpportunityUsersIdDeleteRequest {
  id: string
}

/**
 *
 */
export class ApiUserV1DocumentApi extends runtime.BaseAPI {
  /**
   * 資料のアーカイブ設定
   * archive
   */
  async userV1DocumentsArchivePutRaw(
    requestParameters: UserV1DocumentsArchivePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ArchiveDocumentsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/archive`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ArchiveDocumentsRequestToJSON(requestParameters.archiveDocumentsRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ArchiveDocumentsResponseFromJSON(jsonValue))
  }

  /**
   * 資料のアーカイブ設定
   * archive
   */
  async userV1DocumentsArchivePut(
    requestParameters: UserV1DocumentsArchivePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ArchiveDocumentsResponse> {
    const response = await this.userV1DocumentsArchivePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料のコピーを行う（コピーのみで複製は行わない）
   * copy
   */
  async userV1DocumentsCopyPutRaw(
    requestParameters: UserV1DocumentsCopyPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CopyDocumentResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/copy`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CopyDocumentRequestToJSON(requestParameters.copyDocumentRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CopyDocumentResponseFromJSON(jsonValue))
  }

  /**
   * 資料のコピーを行う（コピーのみで複製は行わない）
   * copy
   */
  async userV1DocumentsCopyPut(
    requestParameters: UserV1DocumentsCopyPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CopyDocumentResponse> {
    const response = await this.userV1DocumentsCopyPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャット設定を編集する
   * chat_setting
   */
  async userV1DocumentsDocumentIdChatSettingPutRaw(
    requestParameters: UserV1DocumentsDocumentIdChatSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentChatSettingResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdChatSettingPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/chat_setting`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentChatSettingRequestToJSON(requestParameters.updateDocumentChatSettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentChatSettingResponseFromJSON(jsonValue))
  }

  /**
   * チャット設定を編集する
   * chat_setting
   */
  async userV1DocumentsDocumentIdChatSettingPut(
    requestParameters: UserV1DocumentsDocumentIdChatSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentChatSettingResponse> {
    const response = await this.userV1DocumentsDocumentIdChatSettingPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料CTA画像を更新する
   * update
   */
  async userV1DocumentsDocumentIdCtaImagePutRaw(
    requestParameters: UserV1DocumentsDocumentIdCtaImagePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentCtaImageResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdCtaImagePut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/cta_image`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentCtaImageResponseFromJSON(jsonValue))
  }

  /**
   * 資料CTA画像を更新する
   * update
   */
  async userV1DocumentsDocumentIdCtaImagePut(
    requestParameters: UserV1DocumentsDocumentIdCtaImagePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentCtaImageResponse> {
    const response = await this.userV1DocumentsDocumentIdCtaImagePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料CTAを編集する
   * cta
   */
  async userV1DocumentsDocumentIdCtaPutRaw(
    requestParameters: UserV1DocumentsDocumentIdCtaPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentCtaResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdCtaPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/cta`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentCtaRequestToJSON(requestParameters.updateDocumentCtaRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentCtaResponseFromJSON(jsonValue))
  }

  /**
   * 資料CTAを編集する
   * cta
   */
  async userV1DocumentsDocumentIdCtaPut(
    requestParameters: UserV1DocumentsDocumentIdCtaPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentCtaResponse> {
    const response = await this.userV1DocumentsDocumentIdCtaPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料を送付したコンタクト一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdDocumentContactsGetRaw(
    requestParameters: UserV1DocumentsDocumentIdDocumentContactsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentContactsResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdDocumentContactsGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/document_contacts`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentContactsResponseFromJSON(jsonValue))
  }

  /**
   * 資料を送付したコンタクト一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdDocumentContactsGet(
    requestParameters: UserV1DocumentsDocumentIdDocumentContactsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentContactsResponse> {
    const response = await this.userV1DocumentsDocumentIdDocumentContactsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料のスライドを更新する
   * update
   */
  async userV1DocumentsDocumentIdFileUpdatePostRaw(
    requestParameters: UserV1DocumentsDocumentIdFileUpdatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<FileUpdateDocumentResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdFileUpdatePost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/file_update`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => FileUpdateDocumentResponseFromJSON(jsonValue))
  }

  /**
   * 資料のスライドを更新する
   * update
   */
  async userV1DocumentsDocumentIdFileUpdatePost(
    requestParameters: UserV1DocumentsDocumentIdFileUpdatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<FileUpdateDocumentResponse> {
    const response = await this.userV1DocumentsDocumentIdFileUpdatePostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料の詳細情報を取得する
   * show
   */
  async userV1DocumentsDocumentIdGetRaw(
    requestParameters: UserV1DocumentsDocumentIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentResponseFromJSON(jsonValue))
  }

  /**
   * 資料の詳細情報を取得する
   * show
   */
  async userV1DocumentsDocumentIdGet(
    requestParameters: UserV1DocumentsDocumentIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentResponse> {
    const response = await this.userV1DocumentsDocumentIdGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料メッセージを編集する
   * message
   */
  async userV1DocumentsDocumentIdMessagePutRaw(
    requestParameters: UserV1DocumentsDocumentIdMessagePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentMessageResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMessagePut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/message`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentMessageRequestToJSON(requestParameters.updateDocumentMessageRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentMessageResponseFromJSON(jsonValue))
  }

  /**
   * 資料メッセージを編集する
   * message
   */
  async userV1DocumentsDocumentIdMessagePut(
    requestParameters: UserV1DocumentsDocumentIdMessagePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentMessageResponse> {
    const response = await this.userV1DocumentsDocumentIdMessagePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料設定の詳細情報を取得する
   * show
   */
  async userV1DocumentsDocumentIdMiscSettingGetRaw(
    requestParameters: UserV1DocumentsDocumentIdMiscSettingGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentMiscSettingResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMiscSettingGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/misc_setting`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentMiscSettingResponseFromJSON(jsonValue))
  }

  /**
   * 資料設定の詳細情報を取得する
   * show
   */
  async userV1DocumentsDocumentIdMiscSettingGet(
    requestParameters: UserV1DocumentsDocumentIdMiscSettingGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentMiscSettingResponse> {
    const response = await this.userV1DocumentsDocumentIdMiscSettingGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料設定のOGP画像の更新をする
   * ogp_file
   */
  async userV1DocumentsDocumentIdMiscSettingOgpFilePutRaw(
    requestParameters: UserV1DocumentsDocumentIdMiscSettingOgpFilePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentViewHistoriesGraphResponse1>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMiscSettingOgpFilePut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/misc_setting/ogp_file`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistoriesGraphResponse1FromJSON(jsonValue))
  }

  /**
   * 資料設定のOGP画像の更新をする
   * ogp_file
   */
  async userV1DocumentsDocumentIdMiscSettingOgpFilePut(
    requestParameters: UserV1DocumentsDocumentIdMiscSettingOgpFilePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentViewHistoriesGraphResponse1> {
    const response = await this.userV1DocumentsDocumentIdMiscSettingOgpFilePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料設定の詳細情報を更新する
   * update
   */
  async userV1DocumentsDocumentIdMiscSettingPutRaw(
    requestParameters: UserV1DocumentsDocumentIdMiscSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentMiscSettingResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdMiscSettingPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/misc_setting`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentMiscSettingRequestToJSON(requestParameters.updateDocumentMiscSettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentMiscSettingResponseFromJSON(jsonValue))
  }

  /**
   * 資料設定の詳細情報を更新する
   * update
   */
  async userV1DocumentsDocumentIdMiscSettingPut(
    requestParameters: UserV1DocumentsDocumentIdMiscSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentMiscSettingResponse> {
    const response = await this.userV1DocumentsDocumentIdMiscSettingPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料商談設定を編集する
   * opportunity
   */
  async userV1DocumentsDocumentIdOpportunityPutRaw(
    requestParameters: UserV1DocumentsDocumentIdOpportunityPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentOpportunityResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/opportunity`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentOpportunityRequestToJSON(requestParameters.updateDocumentOpportunityRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentOpportunityResponseFromJSON(jsonValue))
  }

  /**
   * 資料商談設定を編集する
   * opportunity
   */
  async userV1DocumentsDocumentIdOpportunityPut(
    requestParameters: UserV1DocumentsDocumentIdOpportunityPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentOpportunityResponse> {
    const response = await this.userV1DocumentsDocumentIdOpportunityPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料アポイント担当者一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdOpportunityUsersGetRaw(
    requestParameters: UserV1DocumentsDocumentIdOpportunityUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentOpportunityUsersResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityUsersGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/opportunity_users`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentOpportunityUsersResponseFromJSON(jsonValue))
  }

  /**
   * 資料アポイント担当者一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdOpportunityUsersGet(
    requestParameters: UserV1DocumentsDocumentIdOpportunityUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentOpportunityUsersResponse> {
    const response = await this.userV1DocumentsDocumentIdOpportunityUsersGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料アポイント担当者一覧を登録する
   * create
   */
  async userV1DocumentsDocumentIdOpportunityUsersPostRaw(
    requestParameters: UserV1DocumentsDocumentIdOpportunityUsersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateDocumentOpportunityUsersResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityUsersPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/opportunity_users`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateDocumentOpporunityUserRequestToJSON(requestParameters.createDocumentOpporunityUserRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateDocumentOpportunityUsersResponseFromJSON(jsonValue))
  }

  /**
   * 資料アポイント担当者一覧を登録する
   * create
   */
  async userV1DocumentsDocumentIdOpportunityUsersPost(
    requestParameters: UserV1DocumentsDocumentIdOpportunityUsersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateDocumentOpportunityUsersResponse> {
    const response = await this.userV1DocumentsDocumentIdOpportunityUsersPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料アポイント担当者に登録できるユーザー一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdOpportunityUsersUsersGetRaw(
    requestParameters: UserV1DocumentsDocumentIdOpportunityUsersUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSettableDocumentOpportunityUsersResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdOpportunityUsersUsersGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.id !== undefined) {
      queryParameters['id'] = requestParameters.id
    }

    if (requestParameters.ids) {
      queryParameters['ids[]'] = requestParameters.ids
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/opportunity_users/users`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetSettableDocumentOpportunityUsersResponseFromJSON(jsonValue)
    )
  }

  /**
   * 資料アポイント担当者に登録できるユーザー一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdOpportunityUsersUsersGet(
    requestParameters: UserV1DocumentsDocumentIdOpportunityUsersUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSettableDocumentOpportunityUsersResponse> {
    const response = await this.userV1DocumentsDocumentIdOpportunityUsersUsersGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料を公開・非公開を切り替える
   * publish
   */
  async userV1DocumentsDocumentIdPublishPutRaw(
    requestParameters: UserV1DocumentsDocumentIdPublishPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateDocumentPublishResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdPublishPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/publish`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CreateDocumentPublishRequestToJSON(requestParameters.createDocumentPublishRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateDocumentPublishResponseFromJSON(jsonValue))
  }

  /**
   * 資料を公開・非公開を切り替える
   * publish
   */
  async userV1DocumentsDocumentIdPublishPut(
    requestParameters: UserV1DocumentsDocumentIdPublishPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateDocumentPublishResponse> {
    const response = await this.userV1DocumentsDocumentIdPublishPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料の情報を編集する
   * update
   */
  async userV1DocumentsDocumentIdPutRaw(
    requestParameters: UserV1DocumentsDocumentIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentRequestToJSON(requestParameters.updateDocumentRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentResponseFromJSON(jsonValue))
  }

  /**
   * 資料の情報を編集する
   * update
   */
  async userV1DocumentsDocumentIdPut(
    requestParameters: UserV1DocumentsDocumentIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentResponse> {
    const response = await this.userV1DocumentsDocumentIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料共有設定を編集する
   * share_by_link
   */
  async userV1DocumentsDocumentIdShareByLinkPutRaw(
    requestParameters: UserV1DocumentsDocumentIdShareByLinkPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDocumentShareByLinkResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdShareByLinkPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/share_by_link`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDocumentShareByLinkRequestToJSON(requestParameters.updateDocumentShareByLinkRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDocumentShareByLinkResponseFromJSON(jsonValue))
  }

  /**
   * 資料共有設定を編集する
   * share_by_link
   */
  async userV1DocumentsDocumentIdShareByLinkPut(
    requestParameters: UserV1DocumentsDocumentIdShareByLinkPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDocumentShareByLinkResponse> {
    const response = await this.userV1DocumentsDocumentIdShareByLinkPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料をコンタクトに共有をする
   * share_to_contact
   */
  async userV1DocumentsDocumentIdShareToContactPostRaw(
    requestParameters: UserV1DocumentsDocumentIdShareToContactPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateDocumentShareWithContactResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdShareToContactPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/share_to_contact`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateDocumentShareWithContactRequestToJSON(requestParameters.createDocumentShareWithContactRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateDocumentShareWithContactResponseFromJSON(jsonValue))
  }

  /**
   * 資料をコンタクトに共有をする
   * share_to_contact
   */
  async userV1DocumentsDocumentIdShareToContactPost(
    requestParameters: UserV1DocumentsDocumentIdShareToContactPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateDocumentShareWithContactResponse> {
    const response = await this.userV1DocumentsDocumentIdShareToContactPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料に関連する資料サイト一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdSitesGetRaw(
    requestParameters: UserV1DocumentsDocumentIdSitesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentSitesResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdSitesGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/sites`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentSitesResponseFromJSON(jsonValue))
  }

  /**
   * 資料に関連する資料サイト一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdSitesGet(
    requestParameters: UserV1DocumentsDocumentIdSitesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentSitesResponse> {
    const response = await this.userV1DocumentsDocumentIdSitesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料閲覧履歴一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdViewHistoriesGetRaw(
    requestParameters: UserV1DocumentsDocumentIdViewHistoriesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentViewHistoriesResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/view_histories`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistoriesResponseFromJSON(jsonValue))
  }

  /**
   * 資料閲覧履歴一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdViewHistoriesGet(
    requestParameters: UserV1DocumentsDocumentIdViewHistoriesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentViewHistoriesResponse> {
    const response = await this.userV1DocumentsDocumentIdViewHistoriesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料閲覧履歴グラフを取得する
   * show
   */
  async userV1DocumentsDocumentIdViewHistoriesGraphGetRaw(
    requestParameters: UserV1DocumentsDocumentIdViewHistoriesGraphGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentViewHistoriesGraphResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesGraphGet.'
      )
    }

    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesGraphGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.contactId !== undefined) {
      queryParameters['contact_id'] = requestParameters.contactId
    }

    if (requestParameters.visitorSessionId !== undefined) {
      queryParameters['visitor_session_id'] = requestParameters.visitorSessionId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/view_histories/graph`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistoriesGraphResponseFromJSON(jsonValue))
  }

  /**
   * 資料閲覧履歴グラフを取得する
   * show
   */
  async userV1DocumentsDocumentIdViewHistoriesGraphGet(
    requestParameters: UserV1DocumentsDocumentIdViewHistoriesGraphGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentViewHistoriesGraphResponse> {
    const response = await this.userV1DocumentsDocumentIdViewHistoriesGraphGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料閲覧履歴のセッション一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdViewHistoriesSessionsGetRaw(
    requestParameters: UserV1DocumentsDocumentIdViewHistoriesSessionsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentViewHistorySessionsResponse>> {
    if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
      throw new runtime.RequiredError(
        'documentId',
        'Required parameter requestParameters.documentId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesSessionsGet.'
      )
    }

    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1DocumentsDocumentIdViewHistoriesSessionsGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.contactId !== undefined) {
      queryParameters['contact_id'] = requestParameters.contactId
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents/{document_id}/view_histories/sessions`.replace(
          `{${'document_id'}}`,
          encodeURIComponent(String(requestParameters.documentId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentViewHistorySessionsResponseFromJSON(jsonValue))
  }

  /**
   * 資料閲覧履歴のセッション一覧を取得する
   * index
   */
  async userV1DocumentsDocumentIdViewHistoriesSessionsGet(
    requestParameters: UserV1DocumentsDocumentIdViewHistoriesSessionsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentViewHistorySessionsResponse> {
    const response = await this.userV1DocumentsDocumentIdViewHistoriesSessionsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料を作成する
   * create
   */
  async userV1DocumentsFileUploadPostRaw(
    requestParameters: UserV1DocumentsFileUploadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<FileUploadDocumentResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/documents/file_upload`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => FileUploadDocumentResponseFromJSON(jsonValue))
  }

  /**
   * 資料を作成する
   * create
   */
  async userV1DocumentsFileUploadPost(
    requestParameters: UserV1DocumentsFileUploadPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<FileUploadDocumentResponse> {
    const response = await this.userV1DocumentsFileUploadPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料の一覧を取得する
   * index
   */
  async userV1DocumentsGetRaw(
    requestParameters: UserV1DocumentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.isArchived !== undefined) {
      queryParameters['is_archived'] = requestParameters.isArchived
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    if (requestParameters.name !== undefined) {
      queryParameters['name'] = requestParameters.name
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/documents`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentsResponseFromJSON(jsonValue))
  }

  /**
   * 資料の一覧を取得する
   * index
   */
  async userV1DocumentsGet(
    requestParameters: UserV1DocumentsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentsResponse> {
    const response = await this.userV1DocumentsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料のアポイント担当者を削除する
   * destroy
   */
  async userV1OpportunityUsersIdDeleteRaw(
    requestParameters: UserV1OpportunityUsersIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDocumentOpportunityUsersResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling userV1OpportunityUsersIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/opportunity_users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(requestParameters.id))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDocumentOpportunityUsersResponseFromJSON(jsonValue))
  }

  /**
   * 資料のアポイント担当者を削除する
   * destroy
   */
  async userV1OpportunityUsersIdDelete(
    requestParameters: UserV1OpportunityUsersIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDocumentOpportunityUsersResponse> {
    const response = await this.userV1OpportunityUsersIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
