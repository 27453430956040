/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Audit, AuditFromJSON, AuditFromJSONTyped, AuditToJSON } from './Audit'
import { ContactAction, ContactActionFromJSON, ContactActionFromJSONTyped, ContactActionToJSON } from './ContactAction'
import { ContactMemo, ContactMemoFromJSON, ContactMemoFromJSONTyped, ContactMemoToJSON } from './ContactMemo'
import {
  EnumTimelineContentType,
  EnumTimelineContentTypeFromJSON,
  EnumTimelineContentTypeFromJSONTyped,
  EnumTimelineContentTypeToJSON
} from './EnumTimelineContentType'
import { UserOperation, UserOperationFromJSON, UserOperationFromJSONTyped, UserOperationToJSON } from './UserOperation'

/**
 *
 * @export
 * @interface ContactTimeline
 */
export interface ContactTimeline {
  /**
   *
   * @type {string}
   * @memberof ContactTimeline
   */
  id?: string
  /**
   *
   * @type {EnumTimelineContentType}
   * @memberof ContactTimeline
   */
  contentType?: EnumTimelineContentType
  /**
   *
   * @type {Array<EnumTimelineContentType>}
   * @memberof ContactTimeline
   */
  contentTypes?: Array<EnumTimelineContentType>
  /**
   *
   * @type {string}
   * @memberof ContactTimeline
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactTimeline
   */
  updatedAt?: string
  /**
   *
   * @type {UserOperation}
   * @memberof ContactTimeline
   */
  userOperation?: UserOperation
  /**
   *
   * @type {ContactAction}
   * @memberof ContactTimeline
   */
  action?: ContactAction
  /**
   *
   * @type {ContactMemo}
   * @memberof ContactTimeline
   */
  memo?: ContactMemo
  /**
   *
   * @type {Audit}
   * @memberof ContactTimeline
   */
  audit?: Audit
}

export function ContactTimelineFromJSON(json: any): ContactTimeline {
  return ContactTimelineFromJSONTyped(json, false)
}

export function ContactTimelineFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactTimeline {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    contentType: !exists(json, 'content_type') ? undefined : EnumTimelineContentTypeFromJSON(json['content_type']),
    contentTypes: !exists(json, 'content_types')
      ? undefined
      : (json['content_types'] as Array<any>).map(EnumTimelineContentTypeFromJSON),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    userOperation: !exists(json, 'user_operation') ? undefined : UserOperationFromJSON(json['user_operation']),
    action: !exists(json, 'action') ? undefined : ContactActionFromJSON(json['action']),
    memo: !exists(json, 'memo') ? undefined : ContactMemoFromJSON(json['memo']),
    audit: !exists(json, 'audit') ? undefined : AuditFromJSON(json['audit'])
  }
}

export function ContactTimelineToJSON(value?: ContactTimeline | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    content_type: EnumTimelineContentTypeToJSON(value.contentType),
    content_types:
      value.contentTypes === undefined
        ? undefined
        : (value.contentTypes as Array<any>).map(EnumTimelineContentTypeToJSON),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    user_operation: UserOperationToJSON(value.userOperation),
    action: ContactActionToJSON(value.action),
    memo: ContactMemoToJSON(value.memo),
    audit: AuditToJSON(value.audit)
  }
}
