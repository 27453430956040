/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  RequestContactSettingFieldValueValuesInner,
  RequestContactSettingFieldValueValuesInnerFromJSON,
  RequestContactSettingFieldValueValuesInnerFromJSONTyped,
  RequestContactSettingFieldValueValuesInnerToJSON
} from './RequestContactSettingFieldValueValuesInner'

/**
 *
 * @export
 * @interface RequestCompanySettingFieldValue
 */
export interface RequestCompanySettingFieldValue {
  /**
   *
   * @type {string}
   * @memberof RequestCompanySettingFieldValue
   */
  companySettingId?: string
  /**
   *
   * @type {string}
   * @memberof RequestCompanySettingFieldValue
   */
  companySettingFieldId?: string
  /**
   *
   * @type {Array<RequestContactSettingFieldValueValuesInner>}
   * @memberof RequestCompanySettingFieldValue
   */
  values?: Array<RequestContactSettingFieldValueValuesInner> | null
}

export function RequestCompanySettingFieldValueFromJSON(json: any): RequestCompanySettingFieldValue {
  return RequestCompanySettingFieldValueFromJSONTyped(json, false)
}

export function RequestCompanySettingFieldValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RequestCompanySettingFieldValue {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companySettingId: !exists(json, 'company_setting_id') ? undefined : json['company_setting_id'],
    companySettingFieldId: !exists(json, 'company_setting_field_id') ? undefined : json['company_setting_field_id'],
    values: !exists(json, 'values')
      ? undefined
      : json['values'] === null
      ? null
      : (json['values'] as Array<any>).map(RequestContactSettingFieldValueValuesInnerFromJSON)
  }
}

export function RequestCompanySettingFieldValueToJSON(value?: RequestCompanySettingFieldValue | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_setting_id: value.companySettingId,
    company_setting_field_id: value.companySettingFieldId,
    values:
      value.values === undefined
        ? undefined
        : value.values === null
        ? null
        : (value.values as Array<any>).map(RequestContactSettingFieldValueValuesInnerToJSON)
  }
}
