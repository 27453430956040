/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DocumentContactFromJSON, DocumentContactToJSON } from './DocumentContact';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetDocumentContactsResponseDataFromJSON(json) {
    return GetDocumentContactsResponseDataFromJSONTyped(json, false);
}
export function GetDocumentContactsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        documentContacts: !exists(json, 'document_contacts')
            ? undefined
            : json['document_contacts'].map(DocumentContactFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetDocumentContactsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document_contacts: value.documentContacts === undefined
            ? undefined
            : value.documentContacts.map(DocumentContactToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
