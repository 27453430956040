import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'

export function createQueryParams<T extends {}>(params: T) {
  const queryParams = Object.keys(params)
    .map(key => `${key}=${params[key as keyof T]}`)
    .join('&')
  return queryParams
}

export const getSubdomainFromHost = (host?: string) => {
  // TODO: preview 環境の場合 app から始まる subdomain のページの場合どうするかを検討
  if (process.env.APP_ENV === 'preview') return 'demo-0802'
  const subdomain = host?.split(process.env.DOMAIN_NAME ?? '')[0]?.replace('.', '')
  return subdomain !== 'app' ? subdomain : undefined
}

// eu用の link を取得するために用いる
export const getShareUrl = (url: string) => {
  // MEMO: preview 時には subdomain の利用ができないので origin は現在開いている location に置き換えている。
  if (process.env.APP_ENV === 'preview') {
    const a = document.createElement('a')
    a.href = url
    const pathname = a.pathname
    return `${location.origin}${pathname}`
  }

  if (window.location.hostname.indexOf('localhost') !== -1) {
    return url?.replace('stg-nocosell.site', 'localhost:3000').replace('https://', 'http://')
  }

  return url
}

export const useConfirmDiscard = (
  showConfirm: boolean = true,
  confirmSentence = '変更が送信されていません、移動しますか？'
) => {
  const router = useRouter()
  const lastHistoryState = useRef(global.history?.state)
  useEffect(() => {
    const storeLastHistoryState = () => {
      lastHistoryState.current = history.state
    }
    router.events.on('routeChangeComplete', storeLastHistoryState)
    return () => {
      router.events.off('routeChangeComplete', storeLastHistoryState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let isWarned = false

    const routeChangeStart = (url: string) => {
      if (router.asPath !== url && showConfirm && !isWarned) {
        isWarned = true
        if (window.confirm(confirmSentence)) {
          router.push(url)
        } else {
          isWarned = false
          router.events.emit('routeChangeError')

          // HACK: https://github.com/vercel/next.js/issues/2476
          const state = lastHistoryState.current
          if (state != null && history.state != null && state.idx !== history.state.idx) {
            history.go(state.idx < history.state.idx ? -1 : 1)
          }

          // eslint-disable-next-line no-throw-literal
          throw 'Abort route change. Please ignore this error.'
        }
      }
    }

    router.events.on('routeChangeStart', routeChangeStart)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmSentence, showConfirm])

  const handleReload = useCallback((e: Event) => {
    e.returnValue = true
  }, [])

  // 更新ボタンを押した時のイベント
  useEffect(() => {
    if (showConfirm) window.addEventListener('beforeunload', handleReload)
    return () => {
      window.removeEventListener('beforeunload', handleReload)
    }
  }, [handleReload, showConfirm, router])
}
