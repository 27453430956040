/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateSiteSectionDocumentRequestSiteSectionDocument,
  UpdateSiteSectionDocumentRequestSiteSectionDocumentFromJSON,
  UpdateSiteSectionDocumentRequestSiteSectionDocumentFromJSONTyped,
  UpdateSiteSectionDocumentRequestSiteSectionDocumentToJSON
} from './UpdateSiteSectionDocumentRequestSiteSectionDocument'

/**
 *
 * @export
 * @interface UpdateSiteSectionDocumentRequest
 */
export interface UpdateSiteSectionDocumentRequest {
  /**
   *
   * @type {UpdateSiteSectionDocumentRequestSiteSectionDocument}
   * @memberof UpdateSiteSectionDocumentRequest
   */
  siteSectionDocument?: UpdateSiteSectionDocumentRequestSiteSectionDocument
}

export function UpdateSiteSectionDocumentRequestFromJSON(json: any): UpdateSiteSectionDocumentRequest {
  return UpdateSiteSectionDocumentRequestFromJSONTyped(json, false)
}

export function UpdateSiteSectionDocumentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSiteSectionDocumentRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteSectionDocument: !exists(json, 'site_section_document')
      ? undefined
      : UpdateSiteSectionDocumentRequestSiteSectionDocumentFromJSON(json['site_section_document'])
  }
}

export function UpdateSiteSectionDocumentRequestToJSON(value?: UpdateSiteSectionDocumentRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_section_document: UpdateSiteSectionDocumentRequestSiteSectionDocumentToJSON(value.siteSectionDocument)
  }
}
