import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { Colors, SvgIcon } from 'src/components/atoms'
import { DeleteCardProps } from '../type'

interface CardProps {
  children: ReactNode
  deleteProps: DeleteCardProps
}

const useStyles = makeStyles(() =>
  createStyles({
    deleteButton: {
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px #fff solid',
      borderRadius: '50%',
      backgroundColor: Colors.base.black,
      position: 'absolute',
      top: '-6px',
      right: '-8px'
    }
  })
)

export const Card = ({ children, deleteProps }: CardProps) => {
  const classes = useStyles()

  return (
    <Box
      sx={{
        height: '58px',
        borderRadius: '6px',
        bgcolor: '#fff',
        border: `1px solid ${Colors.background.gray}`,
        position: 'relative'
      }}
    >
      {children}
      {deleteProps.isDeletable && (
        <ButtonBase onClick={deleteProps.onDelete} className={classes.deleteButton}>
          <SvgIcon variant="close" size="8px" color={'#fff'} />
        </ButtonBase>
      )}
    </Box>
  )
}
