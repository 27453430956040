import { Box, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Button, Typography, SelectBox, SvgIcon, Colors, DynamicMuiIcon, Tooltip } from 'src/components/atoms'
import { Edit } from './Edit'
import { Read } from './Read'
import { Contact } from '@noco/http-client/lib/noco'
import { useUpdateContact } from 'src/fixtures/modules/contact/hooks'
import {
  ContactSettingValueMap,
  convertFromContactSettingValueMap,
  initializeContactSettingValueMap
} from 'src/fixtures/modules/contact/utils'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { useConfirmDiscard } from 'src/fixtures/utils/url'
import { useListSelectorUsersList } from 'src/fixtures/modules/selector/hooks'

export interface ContactInfoValues {
  lastName?: string
  firstName?: string
  department?: string
  status?: string
  email?: string
  tel?: string
  mobile?: string
}

export interface ContactInfoProps {
  contact: Contact
  isEditMode: boolean
  userId?: string

  onChangeEdit: () => void
  onSubmit: (values: ContactInfoValues) => void
  onChangeUserId: (userId: string) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    }
  })
})

export const ContactInfo = ({ contact, isEditMode, userId, onChangeEdit, onChangeUserId }: ContactInfoProps) => {
  const classes = useStyles()
  const { data: responseSetting } = useListContactSettings()
  const { data: selectorUserRes } = useListSelectorUsersList({ page: 1, per: 9999 })
  const selectorUserList = selectorUserRes?.selectorItems
  const activeSettings = responseSetting?.activeContactSettings
  const handleButtonClick = useCallback(() => {
    onChangeEdit()
  }, [onChangeEdit])

  const [settingMap, setSettingMap] = useState<ContactSettingValueMap>({})
  const { handleUpdateContact, isLoading, error } = useUpdateContact(contact.id!)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm && isEditMode)
  const resetValues = () => {
    onChangeEdit()
  }

  // TODO: 3つ以上複数同じようなものがあった為、部品化を検討
  const userOptionList = useMemo(() => {
    return (selectorUserList || []).map(item => ({
      value: item.id!,
      label: item.text,
      startElement: (
        <Box width="20px" height="20px" mr="4px" position="relative">
          {item.extend?.avatar ? (
            <img src={item.extend.avatar.url} className={classes.img} />
          ) : (
            <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
          )}
        </Box>
      )
    }))
  }, [selectorUserList, classes.img])

  // 初期化
  useEffect(() => {
    const initialSettingValueMap = initializeContactSettingValueMap(activeSettings || [])
    contact.contactSettings?.forEach(item => {
      const target = initialSettingValueMap[item.id!]
      if (!target) return

      const kind = item.contactSettingFields?.[0].kind
      // TODO: 以下ロジックは company の setting field の出しわけロジックと同等である。union type を利用して型の整合性を取りつつリファクタする
      item.contactSettingFields?.forEach(f => {
        //- 会社の場合
        if (kind === 'dropdown' && f.itemUrl === '/user/v1/selector/companies') {
          target[f.id!] = f.contactSettingFieldValues?.[0].value
        }
        //- dropdown || radio の場合
        else if (kind === 'dropdown' || kind === 'radio') {
          target[f.id!] = f.contactSettingFieldValues?.[0]?.contactSettingFieldItem?.id
        }
        //- checkbox の場合
        else if (kind === 'checkbox') {
          target[f.id!] = f.contactSettingFieldValues?.map(value => value.contactSettingFieldItem?.id)
        }
        //- テキスト系 の場合
        else {
          target[f.id!] = f.contactSettingFieldValues?.[0]?.value
        }
      })
    })

    setSettingMap(initialSettingValueMap)
  }, [activeSettings, contact.contactSettings])

  // 連絡先作成
  const handleUpdate = useCallback(() => {
    const contactSettingFieldValues = convertFromContactSettingValueMap(settingMap)
    handleUpdateContact({
      contact: {
        contactSettingFieldValues
      }
    })

    setIsSubmitting(true)
  }, [handleUpdateContact, settingMap])

  // MEMO:連絡先の更新が終了したときに此処が呼ばれる
  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setIsSubmitting(false)
      onChangeEdit()
    }
  }, [isSubmitting, isLoading, error, onChangeEdit])

  // MEMO: エラーが発生した時
  useEffect(() => {
    if (isSubmitting && !isLoading && error) {
      setIsSubmitting(false)
    }
  }, [isSubmitting, isLoading, error])

  return (
    <>
      {!isEditMode && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="32px">
          <Tooltip
            content={`連絡先（顧客）には、必ず担当者を設定する必要があります。担当している顧客が、資料閲覧やチャットメッセージを送信すると、担当者宛に通知が届きます。`}
          >
            <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px" fontWeight="bold">
              担当者
            </Typography>
          </Tooltip>

          <Box width="150px">
            <SelectBox
              placeholder="選択してください"
              value={userId || 0}
              name="user"
              optionList={userOptionList}
              onChange={e => onChangeUserId(e.target.value as string)}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="16px">
        <Typography variant="h5" fontSize="s" lineheight="14px" letterSpacing="none">
          連絡先情報
        </Typography>
        {isEditMode ? (
          <Box display="flex">
            <Button
              kind="neutral"
              variant="outlined"
              size="small"
              title="キャンセル"
              disabled={isSubmitting}
              onClick={resetValues}
            />
            <Box ml="12px">
              <Button
                size="small"
                title={isSubmitting ? '' : '保存'}
                width={isSubmitting ? '45px' : undefined}
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={handleUpdate}
              />
            </Box>
          </Box>
        ) : (
          <Button
            style={{ padding: '0px 6px' }}
            startIcon={<DynamicMuiIcon variant="edit" size="16px" />}
            variant="outlined"
            size="small"
            kind="secondary"
            title="編集"
            onClick={handleButtonClick}
            starticonmargin="0px 4px 0px 0px"
          />
        )}
      </Box>
      {!isEditMode ? (
        <Read contact={contact} />
      ) : (
        <Edit
          onUpdateValue={map => {
            setSettingMap(map)
            setShowConfirm(true)
          }}
          contact={contact}
          userId={userId}
          settingMap={settingMap}
        />
      )}
    </>
  )
}
