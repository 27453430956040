/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateDocumentMessageRequestDocumentMessage
 */
export interface UpdateDocumentMessageRequestDocumentMessage {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentMessageRequestDocumentMessage
   */
  isView?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentMessageRequestDocumentMessage
   */
  text?: string
  /**
   *
   * @type {number}
   * @memberof UpdateDocumentMessageRequestDocumentMessage
   */
  viewTimingSec?: number
}

export function UpdateDocumentMessageRequestDocumentMessageFromJSON(
  json: any
): UpdateDocumentMessageRequestDocumentMessage {
  return UpdateDocumentMessageRequestDocumentMessageFromJSONTyped(json, false)
}

export function UpdateDocumentMessageRequestDocumentMessageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentMessageRequestDocumentMessage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    isView: !exists(json, 'is_view') ? undefined : json['is_view'],
    text: !exists(json, 'text') ? undefined : json['text'],
    viewTimingSec: !exists(json, 'view_timing_sec') ? undefined : json['view_timing_sec']
  }
}

export function UpdateDocumentMessageRequestDocumentMessageToJSON(
  value?: UpdateDocumentMessageRequestDocumentMessage | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_view: value.isView,
    text: value.text,
    view_timing_sec: value.viewTimingSec
  }
}
