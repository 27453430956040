import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { StepTitle } from 'src/components/atoms'
import { RecordPorterHistory } from '@noco/http-client/lib/noco'
import { StepperImport } from './StepperImport'

export interface ImportExecutionProps {
  result: RecordPorterHistory | undefined
  onSubmit: (value: File) => void
  requesting: boolean
  error: Error | undefined
}

export const ImportExecution = ({ onSubmit, result, requesting, error }: ImportExecutionProps) => {
  const [importing, setImporting] = useState(false)
  return (
    <Box display="flex" flexDirection="column">
      <StepTitle index={2} title="インポートを実行する" />
      <StepperImport
        importing={importing}
        result={result}
        setImporting={setImporting}
        onSubmit={onSubmit}
        requesting={requesting}
        error={error}
      />
    </Box>
  )
}
