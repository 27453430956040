/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateOrganizationTriggerEmailRequestOrganizationFromJSON, UpdateOrganizationTriggerEmailRequestOrganizationToJSON } from './UpdateOrganizationTriggerEmailRequestOrganization';
export function UpdateOrganizationTriggerEmailRequestFromJSON(json) {
    return UpdateOrganizationTriggerEmailRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationTriggerEmailRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organization: !exists(json, 'organization')
            ? undefined
            : UpdateOrganizationTriggerEmailRequestOrganizationFromJSON(json['organization'])
    };
}
export function UpdateOrganizationTriggerEmailRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization: UpdateOrganizationTriggerEmailRequestOrganizationToJSON(value.organization)
    };
}
