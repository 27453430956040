/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CouponFromJSON, CouponToJSON } from './Coupon';
import { OrganizationPaymentContractFromJSON, OrganizationPaymentContractToJSON } from './OrganizationPaymentContract';
import { OrganizationPaymentUsageFromJSON, OrganizationPaymentUsageToJSON } from './OrganizationPaymentUsage';
import { OrganizationPlanDetailFromJSON, OrganizationPlanDetailToJSON } from './OrganizationPlanDetail';
export function GetOrganizationPaymentCurrentPlanResponseDataFromJSON(json) {
    return GetOrganizationPaymentCurrentPlanResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationPaymentCurrentPlanResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        plan: OrganizationPlanDetailFromJSON(json['plan']),
        usage: OrganizationPaymentUsageFromJSON(json['usage']),
        contract: !exists(json, 'contract') ? undefined : OrganizationPaymentContractFromJSON(json['contract']),
        coupon: !exists(json, 'coupon') ? undefined : CouponFromJSON(json['coupon'])
    };
}
export function GetOrganizationPaymentCurrentPlanResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        plan: OrganizationPlanDetailToJSON(value.plan),
        usage: OrganizationPaymentUsageToJSON(value.usage),
        contract: OrganizationPaymentContractToJSON(value.contract),
        coupon: CouponToJSON(value.coupon)
    };
}
