/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumDocumentSlideProcessingStatus,
  EnumDocumentSlideProcessingStatusFromJSON,
  EnumDocumentSlideProcessingStatusFromJSONTyped,
  EnumDocumentSlideProcessingStatusToJSON
} from './EnumDocumentSlideProcessingStatus'

/**
 *
 * @export
 * @interface ShallowDocument
 */
export interface ShallowDocument {
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  organizationId?: string
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  memo?: string | null
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  publishedAt?: string | null
  /**
   *
   * @type {EnumDocumentSlideProcessingStatus}
   * @memberof ShallowDocument
   */
  slideProcessingStatus?: EnumDocumentSlideProcessingStatus
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ShallowDocument
   */
  updatedAt?: string
}

export function ShallowDocumentFromJSON(json: any): ShallowDocument {
  return ShallowDocumentFromJSONTyped(json, false)
}

export function ShallowDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShallowDocument {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
    slideProcessingStatus: !exists(json, 'slide_processing_status')
      ? undefined
      : EnumDocumentSlideProcessingStatusFromJSON(json['slide_processing_status']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function ShallowDocumentToJSON(value?: ShallowDocument | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    organization_id: value.organizationId,
    title: value.title,
    description: value.description,
    memo: value.memo,
    published_at: value.publishedAt,
    slide_processing_status: EnumDocumentSlideProcessingStatusToJSON(value.slideProcessingStatus),
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
