import { Box } from '@material-ui/core'
import React from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { BillingInvoice, BillingInvoiceItem } from '@noco/http-client/lib/noco'
import { InvoiceItemRow } from './InvoiceItemRow'
import { DateFormat, dateFormat } from 'src/fixtures/utils/time'
import { numberWithCommas } from 'src/fixtures/utils/number'

export interface ScheduleBillingInvoiceDetailProps {
  isPaid: boolean
  scheduleBillingInvoice: BillingInvoice
}

const convertItemName = (item: BillingInvoiceItem, isPaid: boolean): string => {
  let humanizedQuantity: string = ''
  if (item.quantity > 1) {
    humanizedQuantity = ` ${item.quantity}${item.unitLabel}`
  }

  let humanizedRecurring: string = ''
  if (isPaid) {
    if (item.periodStart && item.periodEnd) {
      const convertPeriodStart = dateFormat(item.periodStart, DateFormat.BASIC)
      const convertPeriodEnd = dateFormat(item.periodEnd, DateFormat.BASIC)
      humanizedRecurring = `（${convertPeriodStart} 〜 ${convertPeriodEnd}）`
    } else if (item.isMonthlyRecurring) {
      humanizedRecurring = '（毎月）'
    }
  }

  return `${item.productName}${humanizedQuantity}${humanizedRecurring}`
}

export const ScheduleBillingInvoiceDetail = ({ scheduleBillingInvoice, isPaid }: ScheduleBillingInvoiceDetailProps) => {
  return (
    <Box mb="48px">
      <Box mb="16px">
        <Typography fontSize="s" fontWeight="bold" lineheight="1">
          ご利用料金
        </Typography>
      </Box>
      <Box>
        <Box mb="16px">
          {scheduleBillingInvoice.items.map((item, i) => (
            <InvoiceItemRow key={i} itemName={convertItemName(item, isPaid)} amount={item.amount} />
          ))}
          {scheduleBillingInvoice.discount && (
            <InvoiceItemRow
              itemName={scheduleBillingInvoice.discount.couponName}
              amount={-1 * scheduleBillingInvoice.discount.amount}
            />
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box width="270px">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', color: Colors.base.black }}>
              <Typography fontSize="s" lineheight="1" color="inherit">
                小計
              </Typography>
              <Typography fontSize="s" lineheight="1" fontWeight="bold" color="inherit">
                ¥ {numberWithCommas(scheduleBillingInvoice.subtotal)}
              </Typography>
            </Box>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', py: '12px', borderBottom: '1px solid #EEF1F4' }}
            >
              <Typography fontSize="s" lineheight="1" color="inherit">
                消費税
              </Typography>
              <Typography fontSize="s" lineheight="1" fontWeight="bold" color="inherit">
                ¥ {numberWithCommas(scheduleBillingInvoice.tax)}
              </Typography>
            </Box>
            <Box mb="12px" />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography fontSize="s" lineheight="1" fontWeight="bold" color="inherit">
                お支払額（税込、初月）
              </Typography>
              <Typography fontSize="l" lineheight="1" fontWeight="bold" color="inherit">
                ¥ {numberWithCommas(scheduleBillingInvoice.total)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
