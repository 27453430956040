/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateSiteSectionDocumentRequestSiteSectionDocument
 */
export interface CreateSiteSectionDocumentRequestSiteSectionDocument {
  /**
   *
   * @type {string}
   * @memberof CreateSiteSectionDocumentRequestSiteSectionDocument
   */
  documentId?: string
  /**
   *
   * @type {string}
   * @memberof CreateSiteSectionDocumentRequestSiteSectionDocument
   */
  siteSectionId?: string
  /**
   *
   * @type {number}
   * @memberof CreateSiteSectionDocumentRequestSiteSectionDocument
   */
  sort?: number
}

export function CreateSiteSectionDocumentRequestSiteSectionDocumentFromJSON(
  json: any
): CreateSiteSectionDocumentRequestSiteSectionDocument {
  return CreateSiteSectionDocumentRequestSiteSectionDocumentFromJSONTyped(json, false)
}

export function CreateSiteSectionDocumentRequestSiteSectionDocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateSiteSectionDocumentRequestSiteSectionDocument {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    siteSectionId: !exists(json, 'site_section_id') ? undefined : json['site_section_id'],
    sort: !exists(json, 'sort') ? undefined : json['sort']
  }
}

export function CreateSiteSectionDocumentRequestSiteSectionDocumentToJSON(
  value?: CreateSiteSectionDocumentRequestSiteSectionDocument | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_id: value.documentId,
    site_section_id: value.siteSectionId,
    sort: value.sort
  }
}
