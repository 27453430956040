/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  DocumentContact,
  DocumentContactFromJSON,
  DocumentContactFromJSONTyped,
  DocumentContactToJSON
} from './DocumentContact'

/**
 *
 * @export
 * @interface UpdateDocumentContactResponseData
 */
export interface UpdateDocumentContactResponseData {
  /**
   *
   * @type {DocumentContact}
   * @memberof UpdateDocumentContactResponseData
   */
  document?: DocumentContact
}

export function UpdateDocumentContactResponseDataFromJSON(json: any): UpdateDocumentContactResponseData {
  return UpdateDocumentContactResponseDataFromJSONTyped(json, false)
}

export function UpdateDocumentContactResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentContactResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    document: !exists(json, 'document') ? undefined : DocumentContactFromJSON(json['document'])
  }
}

export function UpdateDocumentContactResponseDataToJSON(value?: UpdateDocumentContactResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document: DocumentContactToJSON(value.document)
  }
}
