/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ConfirmMeChangeEmailRequest,
  ConfirmMeChangeEmailRequestFromJSON,
  ConfirmMeChangeEmailRequestToJSON,
  ConfirmMeChangeEmailResponse,
  ConfirmMeChangeEmailResponseFromJSON,
  ConfirmMeChangeEmailResponseToJSON,
  GetMeEmailNotificationSettingResponse,
  GetMeEmailNotificationSettingResponseFromJSON,
  GetMeEmailNotificationSettingResponseToJSON,
  GetMeProfileResponse,
  GetMeProfileResponseFromJSON,
  GetMeProfileResponseToJSON,
  GetMeResponse,
  GetMeResponseFromJSON,
  GetMeResponseToJSON,
  UpdateMeAvatarRequest,
  UpdateMeAvatarRequestFromJSON,
  UpdateMeAvatarRequestToJSON,
  UpdateMeAvatarResponse,
  UpdateMeAvatarResponseFromJSON,
  UpdateMeAvatarResponseToJSON,
  UpdateMeEmailNotificationSettingRequest,
  UpdateMeEmailNotificationSettingRequestFromJSON,
  UpdateMeEmailNotificationSettingRequestToJSON,
  UpdateMeEmailNotificationSettingResponse,
  UpdateMeEmailNotificationSettingResponseFromJSON,
  UpdateMeEmailNotificationSettingResponseToJSON,
  UpdateMePasswordRequest,
  UpdateMePasswordRequestFromJSON,
  UpdateMePasswordRequestToJSON,
  UpdateMePasswordResponse,
  UpdateMePasswordResponseFromJSON,
  UpdateMePasswordResponseToJSON,
  UpdateMeProfileRequest,
  UpdateMeProfileRequestFromJSON,
  UpdateMeProfileRequestToJSON,
  UpdateMeProfileResponse,
  UpdateMeProfileResponseFromJSON,
  UpdateMeProfileResponseToJSON,
  UpdateMeRequest,
  UpdateMeRequestFromJSON,
  UpdateMeRequestToJSON,
  UpdateMeResponse,
  UpdateMeResponseFromJSON,
  UpdateMeResponseToJSON
} from '../models'

export interface UserV1MeAvatarPutRequest {
  updateMeAvatarRequest?: UpdateMeAvatarRequest
}

export interface UserV1MeConfirmChangeEmailPostRequest {
  confirmMeChangeEmailRequest?: ConfirmMeChangeEmailRequest
}

export interface UserV1MeEmailNotificationSettingPutRequest {
  updateMeEmailNotificationSettingRequest?: UpdateMeEmailNotificationSettingRequest
}

export interface UserV1MePasswordPutRequest {
  updateMePasswordRequest?: UpdateMePasswordRequest
}

export interface UserV1MeProfilePutRequest {
  updateMeProfileRequest?: UpdateMeProfileRequest
}

export interface UserV1MePutRequest {
  updateMeRequest?: UpdateMeRequest
}

/**
 *
 */
export class ApiUserV1MeApi extends runtime.BaseAPI {
  /**
   * ユーザーアイコンを設定する
   * update
   */
  async userV1MeAvatarPutRaw(
    requestParameters: UserV1MeAvatarPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateMeAvatarResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/me/avatar`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateMeAvatarRequestToJSON(requestParameters.updateMeAvatarRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateMeAvatarResponseFromJSON(jsonValue))
  }

  /**
   * ユーザーアイコンを設定する
   * update
   */
  async userV1MeAvatarPut(
    requestParameters: UserV1MeAvatarPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateMeAvatarResponse> {
    const response = await this.userV1MeAvatarPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールアドレスの変更を認証する
   * confirm
   */
  async userV1MeConfirmChangeEmailPostRaw(
    requestParameters: UserV1MeConfirmChangeEmailPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ConfirmMeChangeEmailResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/me/confirm_change_email`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ConfirmMeChangeEmailRequestToJSON(requestParameters.confirmMeChangeEmailRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ConfirmMeChangeEmailResponseFromJSON(jsonValue))
  }

  /**
   * メールアドレスの変更を認証する
   * confirm
   */
  async userV1MeConfirmChangeEmailPost(
    requestParameters: UserV1MeConfirmChangeEmailPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ConfirmMeChangeEmailResponse> {
    const response = await this.userV1MeConfirmChangeEmailPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * email通知設定情報を取得する
   * show
   */
  async userV1MeEmailNotificationSettingGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetMeEmailNotificationSettingResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/me/email_notification_setting`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetMeEmailNotificationSettingResponseFromJSON(jsonValue))
  }

  /**
   * email通知設定情報を取得する
   * show
   */
  async userV1MeEmailNotificationSettingGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetMeEmailNotificationSettingResponse> {
    const response = await this.userV1MeEmailNotificationSettingGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * email通知設定情報を設定する
   * update
   */
  async userV1MeEmailNotificationSettingPutRaw(
    requestParameters: UserV1MeEmailNotificationSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateMeEmailNotificationSettingResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/me/email_notification_setting`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateMeEmailNotificationSettingRequestToJSON(requestParameters.updateMeEmailNotificationSettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateMeEmailNotificationSettingResponseFromJSON(jsonValue)
    )
  }

  /**
   * email通知設定情報を設定する
   * update
   */
  async userV1MeEmailNotificationSettingPut(
    requestParameters: UserV1MeEmailNotificationSettingPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateMeEmailNotificationSettingResponse> {
    const response = await this.userV1MeEmailNotificationSettingPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * ログイン中のユーザ情報を取得する
   * show
   */
  async userV1MeGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetMeResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/me`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetMeResponseFromJSON(jsonValue))
  }

  /**
   * ログイン中のユーザ情報を取得する
   * show
   */
  async userV1MeGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GetMeResponse> {
    const response = await this.userV1MeGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * ログイン中のユーザ情報のパスワードを変更
   * password
   */
  async userV1MePasswordPutRaw(
    requestParameters: UserV1MePasswordPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateMePasswordResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/me/password`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateMePasswordRequestToJSON(requestParameters.updateMePasswordRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateMePasswordResponseFromJSON(jsonValue))
  }

  /**
   * ログイン中のユーザ情報のパスワードを変更
   * password
   */
  async userV1MePasswordPut(
    requestParameters: UserV1MePasswordPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateMePasswordResponse> {
    const response = await this.userV1MePasswordPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * プロフィール情報を取得する
   * show
   */
  async userV1MeProfileGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetMeProfileResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/me/profile`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetMeProfileResponseFromJSON(jsonValue))
  }

  /**
   * プロフィール情報を取得する
   * show
   */
  async userV1MeProfileGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<GetMeProfileResponse> {
    const response = await this.userV1MeProfileGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * プロフィール情報を設定する
   * update
   */
  async userV1MeProfilePutRaw(
    requestParameters: UserV1MeProfilePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateMeProfileResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/me/profile`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateMeProfileRequestToJSON(requestParameters.updateMeProfileRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateMeProfileResponseFromJSON(jsonValue))
  }

  /**
   * プロフィール情報を設定する
   * update
   */
  async userV1MeProfilePut(
    requestParameters: UserV1MeProfilePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateMeProfileResponse> {
    const response = await this.userV1MeProfilePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * ログイン中のユーザ情報を編集する
   * update
   */
  async userV1MePutRaw(
    requestParameters: UserV1MePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateMeResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/me`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateMeRequestToJSON(requestParameters.updateMeRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateMeResponseFromJSON(jsonValue))
  }

  /**
   * ログイン中のユーザ情報を編集する
   * update
   */
  async userV1MePut(
    requestParameters: UserV1MePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateMeResponse> {
    const response = await this.userV1MePutRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
