/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationTriggerEmailRequestOrganization,
  UpdateOrganizationTriggerEmailRequestOrganizationFromJSON,
  UpdateOrganizationTriggerEmailRequestOrganizationFromJSONTyped,
  UpdateOrganizationTriggerEmailRequestOrganizationToJSON
} from './UpdateOrganizationTriggerEmailRequestOrganization'

/**
 *
 * @export
 * @interface UpdateOrganizationTriggerEmailRequest
 */
export interface UpdateOrganizationTriggerEmailRequest {
  /**
   *
   * @type {UpdateOrganizationTriggerEmailRequestOrganization}
   * @memberof UpdateOrganizationTriggerEmailRequest
   */
  organization?: UpdateOrganizationTriggerEmailRequestOrganization
}

export function UpdateOrganizationTriggerEmailRequestFromJSON(json: any): UpdateOrganizationTriggerEmailRequest {
  return UpdateOrganizationTriggerEmailRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationTriggerEmailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationTriggerEmailRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organization: !exists(json, 'organization')
      ? undefined
      : UpdateOrganizationTriggerEmailRequestOrganizationFromJSON(json['organization'])
  }
}

export function UpdateOrganizationTriggerEmailRequestToJSON(value?: UpdateOrganizationTriggerEmailRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization: UpdateOrganizationTriggerEmailRequestOrganizationToJSON(value.organization)
  }
}
