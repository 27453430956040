/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateMePasswordRequestUser
 */
export interface UpdateMePasswordRequestUser {
  /**
   *
   * @type {string}
   * @memberof UpdateMePasswordRequestUser
   */
  password: string
  /**
   *
   * @type {string}
   * @memberof UpdateMePasswordRequestUser
   */
  passwordConfirmation: string
}

export function UpdateMePasswordRequestUserFromJSON(json: any): UpdateMePasswordRequestUser {
  return UpdateMePasswordRequestUserFromJSONTyped(json, false)
}

export function UpdateMePasswordRequestUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateMePasswordRequestUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    password: json['password'],
    passwordConfirmation: json['password_confirmation']
  }
}

export function UpdateMePasswordRequestUserToJSON(value?: UpdateMePasswordRequestUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    password: value.password,
    password_confirmation: value.passwordConfirmation
  }
}
