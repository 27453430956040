import { Box } from '@material-ui/core'
import { Card, Typography } from 'src/components/atoms'
import Image from 'next/image'
import { center } from '../../styles'

export const StepComplete = () => {
  return (
    <Box>
      <Card sx={{ pb: '36.5px' }}>
        <Box sx={{ textAlign: 'center', mb: '27px' }}>
          <Typography fontSize="s" lineheight="23.8px" style={{ whiteSpace: 'pre-wrap' }}>
            {`ご解約手続きが完了しました`}
          </Typography>
          <Box height="12px" />
          <Typography fontSize="s" lineheight="23.8px" style={{ whiteSpace: 'pre-wrap' }}>
            {`ご指定のアドレスに、受付確認メールを送信致しましたので、ご確認ください`}
          </Typography>
          <Box height="12px" />
          <Typography fontSize="s" lineheight="23.8px" style={{ whiteSpace: 'pre-wrap' }}>
            {`またのご利用を心よりお待ちしております`}
          </Typography>
        </Box>
        <Box sx={{ ...center }}>
          <Image src="/images/leave/complete.svg" width={315} height={218} objectFit={'contain'} />
        </Box>
      </Card>
    </Box>
  )
}
