import React, { useState } from 'react'
import { Input } from 'src/components/atoms'
import { Box, ButtonBase, Typography, createStyles, makeStyles } from '@material-ui/core'
import ja from 'date-fns/locale/ja'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  format
} from 'date-fns'
import { DateRange, RangeKeyDict } from 'react-date-range'

export interface useDateRangePickerProps {
  startDate?: Date
  endDate?: Date
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .rdrMonth:first-child': {
        marginRight: '26px'
      },
      '& .rdrMonthName': {
        padding: '18px 6px'
      }
    }
  })
)

export const useDateRangePicker = ({ startDate, endDate }: useDateRangePickerProps) => {
  const [dateRange, setDateRange] = useState({
    startDate: startDate,
    endDate: endDate,
    key: 'selection'
  })
  const onChangeDateRange = (rangesByKey: RangeKeyDict) => {
    setDateRange({ ...dateRange, ...rangesByKey.selection })
  }
  return {
    dateRange,
    setDateRange,
    onChangeDateRange
  }
}

export type Props = ReturnType<typeof useDateRangePicker>

export const DateRangePicker = ({ dateRange, setDateRange, onChangeDateRange }: Props) => {
  const classes = useStyles()
  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" pr={6} mr={6} boxShadow="inset -1px 0px 0px #DADCE0">
        <Box display="flex" flexDirection="column" pb={1} mb={1} borderBottom="1px solid #DADCE0">
          <ButtonBase onClick={() => setDateRange({ ...dateRange, startDate: new Date(), endDate: new Date() })}>
            <Box pb={4}>今日</Box>
          </ButtonBase>
          <ButtonBase
            onClick={() =>
              setDateRange({ ...dateRange, startDate: addDays(new Date(), -1), endDate: addDays(new Date(), -1) })
            }
          >
            <Box pb={4}>昨日</Box>
          </ButtonBase>
        </Box>
        <Box display="flex" flexDirection="column" pb={1} mb={1} borderBottom="1px solid #DADCE0">
          <ButtonBase
            onClick={() => setDateRange({ ...dateRange, startDate: defineds.startOfWeek, endDate: defineds.endOfWeek })}
          >
            <Box py={4}>今週</Box>
          </ButtonBase>
          <ButtonBase
            onClick={() =>
              setDateRange({ ...dateRange, startDate: defineds.startOfLastWeek, endDate: defineds.endOfLastWeek })
            }
          >
            <Box pb={4}>先週</Box>
          </ButtonBase>
        </Box>
        <Box display="flex" flexDirection="column">
          <ButtonBase
            onClick={() =>
              setDateRange({ ...dateRange, startDate: defineds.startOfMonth, endDate: defineds.endOfMonth })
            }
          >
            <Box py={4}>今月</Box>
          </ButtonBase>
          <ButtonBase
            onClick={() =>
              setDateRange({ ...dateRange, startDate: defineds.startOfLastMonth, endDate: defineds.endOfLastMonth })
            }
          >
            <Box pb={4}>先月</Box>
          </ButtonBase>
        </Box>
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          <Input
            type="text"
            name="startDate"
            value={dateRange.startDate ? format(dateRange.startDate, 'yyyy/MM/dd') : ''}
            placeholder="開始日"
            borderRadius="4"
            width={240}
          />
          <Box>
            <Typography>&nbsp;〜&nbsp;</Typography>
          </Box>
          <Input
            type="text"
            name="endDate"
            value={dateRange.endDate ? format(dateRange.endDate, 'yyyy/MM/dd') : ''}
            placeholder="終了日"
            borderRadius="4"
            width={240}
          />
        </Box>
        <DateRange
          className={classes.root}
          direction="horizontal"
          months={2}
          locale={ja}
          moveRangeOnFirstSelection={false}
          ranges={[dateRange]}
          monthDisplayFormat="yyyy年 MM月"
          onChange={onChangeDateRange}
        />
      </Box>
    </Box>
  )
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastQuarter: startOfMonth(addMonths(new Date(), -3)),
  startOfLastSemester: startOfMonth(addMonths(new Date(), -6)),
  startOfLastYear: startOfMonth(addMonths(new Date(), -12))
} as const
