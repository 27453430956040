/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GetContactSiteResponseDataFromJSON, GetContactSiteResponseDataToJSON } from './GetContactSiteResponseData';
export function GetContactShareSiteResponseFromJSON(json) {
    return GetContactShareSiteResponseFromJSONTyped(json, false);
}
export function GetContactShareSiteResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        status: !exists(json, 'status') ? undefined : json['status'],
        data: !exists(json, 'data') ? undefined : GetContactSiteResponseDataFromJSON(json['data'])
    };
}
export function GetContactShareSiteResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        status: value.status,
        data: GetContactSiteResponseDataToJSON(value.data)
    };
}
