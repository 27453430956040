import React, { ChangeEvent, useCallback, useState } from 'react'
import clsx from 'clsx'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Box, createStyles, makeStyles, Tab, Typography } from '@material-ui/core'
import { Colors, Pallete, TextPaneInput, Tooltip } from 'src/components/atoms'
import {
  useCreateTimeLineMemo,
  useDeleteTimeLineMemo,
  useListCompanyTimelines,
  useUpdateTimeLineMemo
} from 'src/fixtures/modules/company/hooks'
import { ClientTimeLineItem } from 'src/components/molecules/ClientTimeLineItem'
import { useConfirmDiscard } from 'src/fixtures/utils/url'
import { CompanyContactTable } from 'src/components/molecules'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.functional.background.default,
      boxShadow: 'none',
      borderBottom: `1px solid ${Colors.background.gray}`
    },
    tablist: {
      minHeight: 'auto !important',
      flex: '1 0 auto'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      minWidth: 'auto',
      padding: '6px 16px',
      borderRadius: '6px 6px 0px 0px',
      color: Colors.base.middleGray,
      '&.Mui-selected': {
        color: Colors.accent.keyPurple.default,
        background: Colors.selected.background
      }
    },
    scrollableTabPanel: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      padding: 0,
      paddingTop: '16px'
    },
    timeline: {
      backgroundColor: Colors.functional.background.default
    },
    timeLineItemWrap: {
      '&::after': {
        position: 'absolute',
        content: '""',
        height: 12,
        width: 4,
        background: Colors.background.silver,
        left: 20
      }
    },
    memoInputWrap: {
      border: `1px solid ${Colors.background.silver}`,
      borderRadius: 4
    },
    messageInput: { flex: 1, marginRight: 8, border: 'none', width: '100%' },
    tabpanelMemo: {
      width: '100%',
      height: '100%',
      padding: '12px 12px 0px 12px',
      overflow: 'auto'
    }
  })
})

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

type TabValueType = 'contact' | 'memo' | 'timeline'

interface CompanyDetailContentProps {
  companyId: string
}

export const CompanyDetailContent = ({ companyId }: CompanyDetailContentProps) => {
  const classes = useStyles()
  const [memo, changeMemo] = useState('')
  const [value, setValue] = useState<TabValueType>('contact')
  const { data: timelineRes } = useListCompanyTimelines(companyId)
  const { handleCreateTimeLineMemo, isLoading } = useCreateTimeLineMemo(companyId)
  const { handleDeleteTimeLineMemo } = useDeleteTimeLineMemo(companyId)
  const { handleUpdateTimeLineMemo } = useUpdateTimeLineMemo(companyId)
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm)

  const onCreateMemo = useCallback(() => {
    handleCreateTimeLineMemo(memo)
    changeMemo('')
    setShowConfirm(false)
  }, [memo, changeMemo, handleCreateTimeLineMemo])

  const handleChange = useCallback((event: ChangeEvent<{}>, newValue: TabValueType) => {
    setValue(newValue)
  }, [])

  return (
    <Box bgcolor={Colors.background.lightGray} height={1} display="flex" flexDirection="column" overflow="hidden">
      <TabContext value={value}>
        <Box flex="1 0 auto" bgcolor="white" pt="10px" borderBottom={`1px solid ${Colors.background.silver}`} px="16px">
          <TabList
            onChange={handleChange}
            aria-label="company detail tablist"
            indicatorColor="primary"
            classes={{ root: classes.tablist }}
          >
            <Tab
              value="contact"
              label={
                <Tooltip content={'会社に所属する連絡先（名前・メールアドレスなど）を表示します。'}>
                  <Typography variant="h5">連絡先</Typography>
                </Tooltip>
              }
              className={classes.tab}
              {...a11yProps(0)}
            />
            <Tab
              value="timeline"
              label={
                <Tooltip content={'会社に所属する顧客の行動履歴を時系列で表示します。'}>
                  <Typography variant="h5">タイムライン</Typography>
                </Tooltip>
              }
              className={classes.tab}
              {...a11yProps(2)}
            />
          </TabList>
        </Box>
        <Box width={1} height={1} display="flex" flexDirection="column" overflow="auto">
          <TabPanel value="contact" classes={{ root: classes.scrollableTabPanel }}>
            <CompanyContactTable companyId={companyId} />
          </TabPanel>
          <TabPanel value="timeline" classes={{ root: classes.scrollableTabPanel }}>
            <Box
              boxSizing="border-box"
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              px="16px"
            >
              <Box overflow="auto" px="16px" pt="18px" bgcolor="#fff" height="100%">
                {timelineRes?.companyTimelines?.map((item, index) => (
                  <Box
                    position="relative"
                    key={item.id}
                    mb={3}
                    className={clsx({
                      [classes.timeLineItemWrap]: (timelineRes?.companyTimelines?.length ?? 0) !== index + 1
                    })}
                  >
                    <ClientTimeLineItem
                      item={item}
                      onDelete={handleDeleteTimeLineMemo}
                      onEdit={handleUpdateTimeLineMemo}
                      type="company"
                    />
                  </Box>
                ))}
              </Box>
              <Box bgcolor={Pallete.functional.background.default} position="sticky" bottom="0px" px="16px" py="18px">
                <TextPaneInput
                  value={memo}
                  onChange={({ target: { value } }) => {
                    changeMemo(value)
                    setShowConfirm(true)
                  }}
                  onClick={onCreateMemo}
                  placeholder="メモを入力（例: 週明けにカタログを送付する）"
                  disabled={isLoading}
                  multiline
                  minRows={1}
                  title="メモを追加"
                />
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  )
}
