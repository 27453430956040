import React, { useEffect, useState } from 'react'
import { Box, LinearProgress } from '@material-ui/core'

export interface LineProgressProps {
  isFetching: boolean
  isPlaying: boolean
  setIsPlaying: (isStarting: boolean) => void
  isCompleted: boolean
  setIsCompleted: (isCompleted: boolean) => void
  margin?: string | number
  width?: string | number
  speed?: number
  normalDiff?: number
  upSpeedDiff?: number
  stopLine?: number
}

export const LineProgress = ({
  isFetching,
  isPlaying,
  setIsPlaying,
  isCompleted,
  setIsCompleted,
  margin = 0,
  width = '100%',
  speed = 100,
  normalDiff = 0.17,
  upSpeedDiff = 10,
  stopLine = 80
}: LineProgressProps) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!isPlaying) {
      setProgress(0)
      return
    }
    const timer = setInterval(() => {
      let diff = normalDiff
      setProgress(oldProgress => {
        if (isFetching && oldProgress >= stopLine) {
          return stopLine
        }
        if (!isFetching) {
          diff = upSpeedDiff
        }
        if (oldProgress === 100) {
          setTimeout(() => {
            setIsCompleted(true)
            setIsPlaying(false)
          }, 1000)
          return 100
        }
        return Math.min(oldProgress + diff, 100)
      })
    }, speed)

    return () => {
      clearInterval(timer)
    }
  }, [
    isFetching,
    isPlaying,
    progress,
    isCompleted,
    setIsPlaying,
    setIsCompleted,
    speed,
    normalDiff,
    stopLine,
    upSpeedDiff
  ])

  return (
    <Box margin={margin} width={width}>
      <div>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </Box>
  )
}
