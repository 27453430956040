/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentLimit,
  OrganizationPaymentLimitFromJSON,
  OrganizationPaymentLimitFromJSONTyped,
  OrganizationPaymentLimitToJSON
} from './OrganizationPaymentLimit'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers
 */
export interface GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers
   */
  currentUsage: number
  /**
   *
   * @type {OrganizationPaymentLimit}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers
   */
  currentLimit: OrganizationPaymentLimit
  /**
   *
   * @type {OrganizationPaymentLimit}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers
   */
  incomingLimit: OrganizationPaymentLimit
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSON(
  json: any
): GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers {
  return GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers {
  if (json === undefined || json === null) {
    return json
  }
  return {
    currentUsage: json['current_usage'],
    currentLimit: OrganizationPaymentLimitFromJSON(json['current_limit']),
    incomingLimit: OrganizationPaymentLimitFromJSON(json['incoming_limit'])
  }
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersToJSON(
  value?: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    current_usage: value.currentUsage,
    current_limit: OrganizationPaymentLimitToJSON(value.currentLimit),
    incoming_limit: OrganizationPaymentLimitToJSON(value.incomingLimit)
  }
}
