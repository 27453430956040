/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetMeResponseData,
  GetMeResponseDataFromJSON,
  GetMeResponseDataFromJSONTyped,
  GetMeResponseDataToJSON
} from './GetMeResponseData'

/**
 *
 * @export
 * @interface UpdateMePasswordResponse
 */
export interface UpdateMePasswordResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateMePasswordResponse
   */
  status: number
  /**
   *
   * @type {GetMeResponseData}
   * @memberof UpdateMePasswordResponse
   */
  data: GetMeResponseData
}

export function UpdateMePasswordResponseFromJSON(json: any): UpdateMePasswordResponse {
  return UpdateMePasswordResponseFromJSONTyped(json, false)
}

export function UpdateMePasswordResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateMePasswordResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetMeResponseDataFromJSON(json['data'])
  }
}

export function UpdateMePasswordResponseToJSON(value?: UpdateMePasswordResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetMeResponseDataToJSON(value.data)
  }
}
