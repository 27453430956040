/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumRecordPorterHistoryOperation = {
  ImportContactCompanies: 'import_contact_companies',
  ExportContacts: 'export_contacts',
  ExportCompanies: 'export_companies',
  BulkUpdateContacts: 'bulk_update_contacts',
  BulkUpdateCompanies: 'bulk_update_companies'
} as const
export type EnumRecordPorterHistoryOperation =
  typeof EnumRecordPorterHistoryOperation[keyof typeof EnumRecordPorterHistoryOperation]

export function EnumRecordPorterHistoryOperationFromJSON(json: any): EnumRecordPorterHistoryOperation {
  return EnumRecordPorterHistoryOperationFromJSONTyped(json, false)
}

export function EnumRecordPorterHistoryOperationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumRecordPorterHistoryOperation {
  return json as EnumRecordPorterHistoryOperation
}

export function EnumRecordPorterHistoryOperationToJSON(value?: EnumRecordPorterHistoryOperation | null): any {
  return value as any
}
