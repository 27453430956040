/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  FilterBasicResourceListPins,
  FilterBasicResourceListPinsFromJSON,
  FilterBasicResourceListPinsFromJSONTyped,
  FilterBasicResourceListPinsToJSON
} from './FilterBasicResourceListPins'

/**
 *
 * @export
 * @interface FilterBasicResourceList
 */
export interface FilterBasicResourceList {
  /**
   *
   * @type {Array<FilterBasicResourceListPins>}
   * @memberof FilterBasicResourceList
   */
  pins?: Array<FilterBasicResourceListPins>
  /**
   *
   * @type {number}
   * @memberof FilterBasicResourceList
   */
  sizeAll?: number
}

export function FilterBasicResourceListFromJSON(json: any): FilterBasicResourceList {
  return FilterBasicResourceListFromJSONTyped(json, false)
}

export function FilterBasicResourceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterBasicResourceList {
  if (json === undefined || json === null) {
    return json
  }
  return {
    pins: !exists(json, 'pins') ? undefined : (json['pins'] as Array<any>).map(FilterBasicResourceListPinsFromJSON),
    sizeAll: !exists(json, 'size_all') ? undefined : json['size_all']
  }
}

export function FilterBasicResourceListToJSON(value?: FilterBasicResourceList | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    pins: value.pins === undefined ? undefined : (value.pins as Array<any>).map(FilterBasicResourceListPinsToJSON),
    size_all: value.sizeAll
  }
}
