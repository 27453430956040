import React, { useCallback, useMemo } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Button, Typography } from 'src/components/atoms'
import { ContactList, CompanyList, FilterStaffPins1, FilterContentPins1 } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat } from 'src/fixtures/utils/time'

export interface FilteredItemsProps {
  listValue: string
  userIdList: string[]
  createdDateFromTo: string[]
  documentIdList?: string[]
  siteIdList?: string[]
  variableList: ContactList[] | CompanyList[] | undefined
  staffList: FilterStaffPins1[] | undefined
  documentList?: FilterContentPins1[] | undefined
  siteList?: FilterContentPins1[] | undefined
  isContactFilter: boolean
  onClearShowFilteredItems: () => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    chip: {
      backgroundColor: Colors.background.gray,
      flexDirection: 'row',
      alignItems: 'center',
      padding: '4px 6px',
      borderRadius: '4px',
      margin: '0 6px 6px 0',
      fontSize: '13px',
      lineHeight: '132%',
      wordBreak: 'break-all'
    }
  })
})

export const FilteredItems = ({
  listValue,
  userIdList,
  createdDateFromTo,
  documentIdList,
  siteIdList,
  variableList,
  staffList,
  documentList,
  siteList,
  isContactFilter,
  onClearShowFilteredItems
}: FilteredItemsProps) => {
  const classes = useStyles()

  const formatDate = useCallback(value => {
    const date = new Date(value)
    return dateFormat(date, DateFormat.DATE_WITH_DAY)
  }, [])

  const filteredVariableList = useMemo(
    () => variableList?.filter(list => list.id === listValue),
    [variableList, listValue]
  )

  const filteredDocumentList = useMemo(() => {
    return documentList?.filter(list => documentIdList?.some(id => id === list.materialableId))
  }, [documentList, documentIdList])

  const filteredSiteList = useMemo(() => {
    return siteList?.filter(list => siteIdList?.some(id => id === list.materialableId))
  }, [siteList, siteIdList])

  return (
    <Box mx="12px" my="16px">
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center" mr="6px">
          <DynamicMuiIcon variant="filterAlt" size="16px" color="action" />
        </Box>
        <Typography fontSize="s" lineheight="14px" fontWeight="bold">
          絞り込み条件
        </Typography>
      </Box>
      {listValue.length > 0 && (
        <Box mt="17px">
          <Typography fontSize="xs" lineheight="20.4px" fontWeight="bold">
            リスト
          </Typography>
          <Box mb="4px" />
          <Box display="inline-flex" className={classes.chip}>
            {filteredVariableList?.[0].name as string}
          </Box>
        </Box>
      )}
      {userIdList.length > 0 && (
        <Box mt="17px">
          <Typography fontSize="xs" lineheight="20.4px" fontWeight="bold">
            担当者
          </Typography>
          <Box mb="4px" />
          {userIdList.map((staffId, index) => {
            const user = staffList?.find(list => list.userId === staffId)
            return (
              <Box display="inline-flex" className={classes.chip} key={index}>
                {user?.text as string}
              </Box>
            )
          })}
        </Box>
      )}
      {createdDateFromTo.length > 0 && (
        <Box mt="17px">
          <Typography fontSize="xs" lineheight="20.4px" fontWeight="bold">
            登録日
          </Typography>
          <Box mb="4px" />
          <Box>
            {createdDateFromTo[0] === createdDateFromTo[1] ? (
              <Box display="inline-flex" className={classes.chip}>
                {formatDate(createdDateFromTo[0])}
              </Box>
            ) : (
              <Box display="inline-flex" className={classes.chip}>
                {`${formatDate(createdDateFromTo[0])}〜 ${formatDate(createdDateFromTo[1])}`}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {isContactFilter && (
        <>
          {documentIdList
            ? documentIdList.length > 0 && (
                <Box mt="17px">
                  <Typography fontSize="xs" lineheight="20.4px" fontWeight="bold">
                    コンテンツ
                  </Typography>
                  <Box mb="4px" />
                  {filteredDocumentList?.map((document, index) => {
                    return (
                      <Box display="inline-flex" className={classes.chip} key={index}>
                        {document?.text as string}
                      </Box>
                    )
                  })}
                </Box>
              )
            : undefined}
          {siteIdList
            ? siteIdList.length > 0 && (
                <Box mt="17px">
                  <Typography fontSize="xs" lineheight="20.4px" fontWeight="bold">
                    チャネル
                  </Typography>
                  <Box mb="4px" />
                  {filteredSiteList?.map((site, index) => {
                    return (
                      <Box display="inline-flex" className={classes.chip} key={index}>
                        labelText={site?.text as string}
                      </Box>
                    )
                  })}
                </Box>
              )
            : undefined}
        </>
      )}
      <Box mt="16px">
        <Button kind="secondary" variant="outlined" title="クリア" onClick={onClearShowFilteredItems} />
      </Box>
    </Box>
  )
}
