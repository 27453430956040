import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CircleNote: React.VFC<SvgIconProps> = ({ color = '#41C9B4', ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 14C0 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6265 11.8787V10.8722C12.6265 10.6888 12.636 10.6276 12.6642 10.5335C12.7397 10.2749 12.9945 10.0821 13.2964 10.0821C13.5983 10.0821 13.853 10.2795 13.9285 10.5335C13.9568 10.6276 13.9663 10.6888 13.9663 10.8722V12.4243C13.9663 12.5184 13.9663 12.6124 13.9474 12.6971C13.8955 12.9322 13.6833 13.144 13.4473 13.1957C13.3625 13.2144 13.2681 13.2144 13.1737 13.2144H11.617C11.433 13.2144 11.3716 13.205 11.2773 13.1768C11.0225 13.1016 10.8244 12.8476 10.8244 12.5466C10.8244 12.2455 11.0225 11.9916 11.2773 11.9163C11.3716 11.8881 11.433 11.8787 11.617 11.8787H12.6265ZM18.1743 18.915H9.82432V11.5824C9.82432 11.4883 9.85735 11.4084 9.92341 11.3425L12.0887 9.18364C12.1548 9.11787 12.2349 9.08494 12.3293 9.08494H18.1743V18.915ZM19.1225 7.42931C19.0801 7.42461 19.0234 7.41992 18.9149 7.41992H12.1075C12.0321 7.41992 11.9567 7.42461 11.9047 7.42931C11.5933 7.45754 11.315 7.60334 11.0933 7.82438L8.55997 10.3501C8.33835 10.5712 8.19203 10.8487 8.16379 11.1591C8.159 11.2108 8.1543 11.286 8.1543 11.3613V19.6534C8.1543 19.7615 8.159 19.818 8.16379 19.8603C8.20145 20.2131 8.52224 20.5328 8.87606 20.5705C8.91859 20.5752 8.97515 20.5799 9.08365 20.5799H18.9149C19.0234 20.5799 19.0801 20.5752 19.1225 20.5705C19.4764 20.5328 19.7971 20.2131 19.8349 19.8603C19.8395 19.818 19.8443 19.7615 19.8443 19.6534V8.34649C19.8443 8.23831 19.8395 8.18184 19.8349 8.13952C19.7971 7.78676 19.4764 7.46693 19.1225 7.42931Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default CircleNote
