/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentAddon,
  OrganizationPaymentAddonFromJSON,
  OrganizationPaymentAddonFromJSONTyped,
  OrganizationPaymentAddonToJSON
} from './OrganizationPaymentAddon'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentAddonUserOptionRequest
 */
export interface UpdateOrganizationPaymentAddonUserOptionRequest {
  /**
   *
   * @type {OrganizationPaymentAddon}
   * @memberof UpdateOrganizationPaymentAddonUserOptionRequest
   */
  addon: OrganizationPaymentAddon
}

export function UpdateOrganizationPaymentAddonUserOptionRequestFromJSON(
  json: any
): UpdateOrganizationPaymentAddonUserOptionRequest {
  return UpdateOrganizationPaymentAddonUserOptionRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentAddonUserOptionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentAddonUserOptionRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    addon: OrganizationPaymentAddonFromJSON(json['addon'])
  }
}

export function UpdateOrganizationPaymentAddonUserOptionRequestToJSON(
  value?: UpdateOrganizationPaymentAddonUserOptionRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    addon: OrganizationPaymentAddonToJSON(value.addon)
  }
}
