/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateDocumentCtaRequestDocumentCta,
  UpdateDocumentCtaRequestDocumentCtaFromJSON,
  UpdateDocumentCtaRequestDocumentCtaFromJSONTyped,
  UpdateDocumentCtaRequestDocumentCtaToJSON
} from './UpdateDocumentCtaRequestDocumentCta'

/**
 *
 * @export
 * @interface UpdateDocumentCtaRequest
 */
export interface UpdateDocumentCtaRequest {
  /**
   *
   * @type {UpdateDocumentCtaRequestDocumentCta}
   * @memberof UpdateDocumentCtaRequest
   */
  documentCta?: UpdateDocumentCtaRequestDocumentCta
}

export function UpdateDocumentCtaRequestFromJSON(json: any): UpdateDocumentCtaRequest {
  return UpdateDocumentCtaRequestFromJSONTyped(json, false)
}

export function UpdateDocumentCtaRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentCtaRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentCta: !exists(json, 'document_cta')
      ? undefined
      : UpdateDocumentCtaRequestDocumentCtaFromJSON(json['document_cta'])
  }
}

export function UpdateDocumentCtaRequestToJSON(value?: UpdateDocumentCtaRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_cta: UpdateDocumentCtaRequestDocumentCtaToJSON(value.documentCta)
  }
}
