import { GetContactDocumentResponseData, PutContactVisitorContactRequest } from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'

import useSWR from 'swr'
import { useContactAuthentication } from '../contactAuth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'
import { useGetVisitor } from '../visitor/hooks'

// document 情報の取得
export const useGetCotnactDocument = (documentId: string, siteId?: string) => {
  const { data: auth } = useContactAuthentication()
  const documentApi = nocoSDK.client?.contactService.documentApi
  const visitorApi = nocoSDK.client?.contactService.visitorApi
  const func = useCallback(async () => {
    if (!auth || !auth.token) {
      console.warn('getContactDocument: no contact token')
      return
    }
    // visitor と contact が結びついていない限り document は取得ができないので存在チェックをしている
    const res = await visitorApi?.ApiFactory(auth.token).contactV1VisitorGet({ xNocoVisitorToken: auth.token })
    const visitor = res?.data?.visitor
    if (!visitor || !visitor.hasContact) {
      console.warn('getContactDocument: insufficient visitor information', visitor)
      return
    }
    return await documentApi
      ?.ApiFactory(auth.token)
      .contactV1DocumentsDocumentIdGet({ documentId, siteId })
      .then(item => item.data)
  }, [auth, documentApi, documentId, siteId, visitorApi])
  return useSWR(auth && SWRCachePath.getContactDocument(documentId, siteId), func)
}

export const useGetCotnactDocumentShare = ({
  documentShareId,
  siteShareId
}: {
  documentShareId?: string
  siteShareId?: string
}) => {
  const { data: auth } = useContactAuthentication()
  const documentApi = nocoSDK.client?.contactService.documentApi
  const visitorApi = nocoSDK.client?.contactService.visitorApi
  const func = useCallback(async () => {
    if (!documentShareId) return
    if (!auth || !auth.token) {
      console.warn('getContactDocument: no contact token')
      return
    }

    // visitor と contact が結びついていない限り document は取得ができないので存在チェックをしている
    const res = await visitorApi?.ApiFactory(auth.token).contactV1VisitorGet({ xNocoVisitorToken: auth.token })
    const visitor = res?.data?.visitor
    if (!visitor) {
      console.warn('getContactDocument: insufficient visitor information', visitor)
      return
    }

    return await documentApi
      ?.ApiFactory(auth.token)
      .contactV1DocumentsSharePublicLinkUlidGet({
        publicLinkUlid: documentShareId,
        siteSharePublicLinkUlid: siteShareId
      })
      .then(item => item.data)
  }, [auth, documentApi, documentShareId, siteShareId, visitorApi])

  return useSWR(auth && documentShareId && SWRCachePath.getContactDocumentShare(documentShareId, siteShareId), func)
}

export const useGetCotnactDocumentOrShare = ({
  documentShareId,
  siteShareId,
  documentId,
  siteId,
  isPreview
}: {
  documentShareId?: string
  siteShareId?: string
  documentId?: string
  siteId?: string
  isPreview?: boolean
}) => {
  const { data: auth } = useContactAuthentication()
  const documentApi = nocoSDK.client?.contactService.documentApi
  const visitorApi = nocoSDK.client?.contactService.visitorApi
  const func = useCallback(async () => {
    if (!auth || !auth.token) {
      console.warn('getContactDocument: no contact token')
      return
    }

    if (!isPreview) {
      // visitor と contact が結びついていない限り document は取得ができないので存在チェックをしている
      const res = await visitorApi?.ApiFactory(auth.token).contactV1VisitorGet({ xNocoVisitorToken: auth.token })
      const visitor = res?.data?.visitor
      if (!visitor || !visitor.hasContact) {
        console.warn('getContactDocument: insufficient visitor information', visitor)
        return
      }
    }

    let docRes: GetContactDocumentResponseData | undefined = undefined

    if (documentShareId) {
      docRes = await documentApi
        ?.ApiFactory(auth.token)
        .contactV1DocumentsSharePublicLinkUlidGet({
          publicLinkUlid: documentShareId,
          siteSharePublicLinkUlid: siteShareId
        })
        .then(item => item.data)
    } else if (documentId) {
      if (documentId) {
        docRes = await documentApi
          ?.ApiFactory(auth.token)
          .contactV1DocumentsDocumentIdGet({ documentId, siteId })
          .then(item => item.data)
      }
    }

    return docRes
  }, [auth, documentApi, documentId, documentShareId, siteId, siteShareId, visitorApi, isPreview])
  return useSWR(
    auth &&
      (documentShareId
        ? SWRCachePath.getContactDocumentShare(documentShareId, siteShareId)
        : SWRCachePath.getContactDocument(documentId!, siteId)),
    func
  )
}

export const useGetContactDocumentShareForm = (documentShareId?: string, siteShareId?: string) => {
  const { data: auth } = useContactAuthentication()
  const documentApi = nocoSDK.client?.contactService.documentApi
  const visitorApi = nocoSDK.client?.contactService.visitorApi
  const func = useCallback(async () => {
    if (!auth || !auth.token) {
      console.warn('getContactDocument: no contact token')
      return
    }

    if (!documentShareId) {
      return
    }

    // visitor と contact が結びついていない限り document は取得ができないので存在チェックをしている
    const res = await visitorApi?.ApiFactory(auth.token).contactV1VisitorGet({ xNocoVisitorToken: auth.token })
    const visitor = res?.data?.visitor
    if (!visitor || !visitor.hasContact) {
      console.warn('getContactDocument: insufficient visitor information', visitor)
      return
    }
    return await documentApi
      ?.ApiFactory(auth.token)
      .contactV1DocumentsSharePublicLinkUlidShareFormsGet({
        publicLinkUlid: documentShareId,
        siteSharePublicLinkUlid: siteShareId
      })
      .then(item => item.data)
  }, [auth, documentApi, documentShareId, siteShareId, visitorApi])

  return useSWR(auth && documentShareId && SWRCachePath.getContactDocumentShareForm(documentShareId, siteShareId), func)
}

export const useListCotnactDocumentOpportunityUsers = (documentId: string) => {
  const { data: auth } = useContactAuthentication()
  const documentApi = nocoSDK.client?.contactService.documentApi
  const func = useCallback(async () => {
    if (!auth) return
    return await documentApi
      ?.ApiFactory(auth.token)
      .contactV1DocumentsDocumentIdOpportunityUsersGet({ documentId }, { cache: 'no-store' })
      .then(item => item.data?.documentOpportunityUsers)
  }, [auth, documentApi, documentId])
  return useSWR(auth && SWRCachePath.listCotnactDocumentOpportunityUser(documentId), func)
}

export const useReviewRequest = () => {
  const { data: auth } = useContactAuthentication()
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const chatMessageApi = nocoSDK.client?.contactService.chatMessageApi
  const handleReviewRequest = useCallback(
    async (documentId?: string) => {
      try {
        if (!auth || !documentId) return
        setError(undefined)
        setIsLoading(true)
        await chatMessageApi
          ?.ApiFactory(auth.token)
          .contactV1ChatMessagesReviewRequestPost({ createChatMessageRequest1: { documentId } })
        Toaster.success('閲覧権限をリクエストしました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Review Request' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, chatMessageApi]
  )

  return { error, isLoading, handleReviewRequest }
}

export const useUpdateContact = (documentShareId?: string, siteShareId?: string) => {
  const { data: auth } = useContactAuthentication()
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: mutateDocumentShare } = useGetCotnactDocumentShare({ documentShareId })
  const { mutate: mutateVisitor } = useGetVisitor()
  const { mutate: mutateSiteShareForms } = useGetContactDocumentShareForm(documentShareId, siteShareId)

  const visitorApi = nocoSDK.client?.contactService.visitorApi

  const handleUpdateContact = useCallback(
    async (putContactVisitorContactRequest?: PutContactVisitorContactRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await visitorApi
          ?.ApiFactory(auth.token)
          .contactV1VisitorContactPut({ xNocoVisitorToken: auth.token, putContactVisitorContactRequest })
        Toaster.success('登録が完了しました')
        mutateDocumentShare()
        mutateVisitor()
        mutateSiteShareForms()
      } catch (err) {
        await handleError(err, { setError, tag: 'update contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mutateDocumentShare, mutateSiteShareForms, mutateVisitor, visitorApi]
  )

  return { error, isLoading, handleUpdateContact }
}
