import React, { useCallback, useMemo } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Typography, SelectBox, SvgIcon, Colors } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { useListSelectorUsersList } from 'src/fixtures/modules/selector/hooks'

export interface ModalChangeUserProps {
  open: boolean
  title: string
  count: number
  onClose: () => void
  onConfirm: () => void
  value: string
  onChangeUser: (userId: string) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    avatarImg: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    }
  })
})

export const ModalChangeUser = ({
  open,
  title,
  count,
  value,
  onClose,
  onConfirm,
  onChangeUser
}: ModalChangeUserProps) => {
  const classes = useStyles()

  const { data: selectorUsers } = useListSelectorUsersList({ page: 1, per: 9999 })
  const selectorUserList = selectorUsers?.selectorItems

  const userOptionList = useMemo(() => {
    return (
      selectorUserList?.map(item => ({
        value: item.id!,
        label: item.text,
        startElement: (
          <Box width="20px" height="20px" mr="4px" position="relative">
            {item.extend?.avatar ? (
              <img src={item.extend.avatar.url} className={classes.avatarImg} />
            ) : (
              <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
            )}
          </Box>
        )
      })) || []
    )
  }, [selectorUserList, classes.avatarImg])

  const handleUserChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      onChangeUser?.(event.target.value as string)
    },
    [onChangeUser]
  )

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle={title}
            headerSubTitle={`${count}件 選択中`}
            buttonTitle="OK"
            hideSettings
            onClose={onClose}
            onCancel={onClose}
            onConfirm={() => onConfirm()}
            buttonDisabled={!value}
          >
            <Box px="24px" py="16px">
              <Box>
                <Typography fontSize="s" lineheight="14px" fontWeight="bold">
                  担当者
                </Typography>
                <Box mt="10px" />
                <SelectBox
                  placeholder="選択してください"
                  value={value}
                  name="personnel"
                  optionList={userOptionList}
                  onChange={handleUserChange}
                  height="34px"
                />
              </Box>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
