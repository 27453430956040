/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Job, JobFromJSON, JobFromJSONTyped, JobToJSON } from './Job'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetJobsResponseData
 */
export interface GetJobsResponseData {
  /**
   *
   * @type {Array<Job>}
   * @memberof GetJobsResponseData
   */
  jobs?: Array<Job>
  /**
   *
   * @type {PageInfo}
   * @memberof GetJobsResponseData
   */
  pageInfo?: PageInfo
}

export function GetJobsResponseDataFromJSON(json: any): GetJobsResponseData {
  return GetJobsResponseDataFromJSONTyped(json, false)
}

export function GetJobsResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetJobsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    jobs: !exists(json, 'jobs') ? undefined : (json['jobs'] as Array<any>).map(JobFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetJobsResponseDataToJSON(value?: GetJobsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    jobs: value.jobs === undefined ? undefined : (value.jobs as Array<any>).map(JobToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
