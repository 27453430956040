import { Box, ButtonBase } from '@material-ui/core'
import { ShallowContactList } from '@noco/http-client/lib/noco'
import React, { useCallback, useState } from 'react'
import { Button, DynamicMuiIcon, Pallete, Typography } from 'src/components/atoms'
import { PopupTemplate } from 'src/components/modals'
import { useListContactListsByContactId } from 'src/fixtures/modules/contact/hooks'
import { useRemoveContactListContactId } from 'src/fixtures/modules/contact/hooks'
import { ContactListModal } from './Modal'

export interface ContactListProps {
  contactId: string
  contactDisplayName: string
}

export const ContactList = ({ contactId, contactDisplayName }: ContactListProps) => {
  const [deleteListItem, setDeleteListItem] = useState<ShallowContactList>()
  const { handleRemoveContactListContactId } = useRemoveContactListContactId({ page: 1, per: 9999 })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { data, mutate } = useListContactListsByContactId({
    contactId
  })
  const onCloseDeleteModal = useCallback(() => {
    setDeleteListItem(undefined)
  }, [])
  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const onDelete = useCallback(async () => {
    if (!deleteListItem) return
    await handleRemoveContactListContactId(deleteListItem.id, [contactId])
    mutate()
    onCloseDeleteModal()
  }, [deleteListItem, handleRemoveContactListContactId, mutate, onCloseDeleteModal, contactId])

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="8px">
        <Typography fontSize="s" lineheight="14px" fontWeight="bold">
          掲載先のリスト
        </Typography>

        <Button
          style={{ padding: '0px 6px' }}
          size="small"
          kind="secondary"
          variant="outlined"
          startIcon={<DynamicMuiIcon variant="add" size="16px" color="inherit" />}
          title="リストに追加"
          onClick={onOpen}
          starticonmargin="0px 4px 0px 0px"
        />
      </Box>

      <Box display="flex" flexDirection="column">
        {data?.contactLists.map(item => (
          <Box
            key={item.id}
            height="40px"
            width={1}
            display="flex"
            alignItems="center"
            boxShadow="inset 0px -1px 0px #EEF1F4"
            justifyContent="space-between"
            bgcolor={Pallete.functional.background.default}
          >
            <Typography fontSize="s" lh="tight" letterSpacing="normal">
              {item.name}
            </Typography>
            <Box ml="4px" />
            <ButtonBase
              onClick={() => {
                setDeleteListItem(item)
              }}
            >
              <DynamicMuiIcon variant="close" size="20px" />
            </ButtonBase>
          </Box>
        ))}
      </Box>

      {deleteListItem && (
        <PopupTemplate
          open={!!deleteListItem}
          title={`掲載先のリストを削除`}
          onClose={onCloseDeleteModal}
          hasClose
          hasCancel
          hasConfirm
          onConfirm={onDelete}
          cancelButtonLabel="キャンセル"
          confirmButtonLabel="OK"
        >
          <Box p="24px">
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {`『${deleteListItem.name}』から、${contactDisplayName} を取り除きます。\n本当によろしいですか？`}
            </Typography>
          </Box>
        </PopupTemplate>
      )}
      {isOpen && <ContactListModal contactId={contactId} isOpen={isOpen} setIsOpen={setIsOpen} mutateList={mutate} />}
    </Box>
  )
}
