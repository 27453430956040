import { useEffect, useMemo, useCallback } from 'react'
import { useTablePagination } from 'src/components/organisms'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { useListContacts } from 'src/fixtures/modules/contact/hooks'
import { CompanyContactTableProps } from '.'

export const useFunctions = ({ companyId }: CompanyContactTableProps) => {
  const { page, per, totalPages, setTotalPages, changePage, changePerPage } = useTablePagination('company-contact')
  const { data: contactSettingsData } = useListContactSettings()
  const { data: contactsData } = useListContacts({ companyId, page: page + 1, per })

  const activeSettingList = contactSettingsData?.activeContactSettings
  const columns = useMemo(
    () =>
      activeSettingList
        ?.filter(item => item.settingType !== 'company')
        .map(item => ({ name: item.id!, width: 120, title: item.name })) || [],
    [activeSettingList]
  )

  const contactList = useMemo(() => contactsData?.contacts || [], [contactsData])
  const numberOfRows = contactsData?.pageInfo?.totalCount

  const handleChangePage = useCallback((page: number) => changePage(page), [changePage])
  const handleChangePerPage = useCallback((per: number) => changePerPage(per), [changePerPage])

  useEffect(() => {
    if (contactsData?.pageInfo?.totalPages && contactsData?.pageInfo?.totalPages !== totalPages) {
      setTotalPages(contactsData?.pageInfo?.totalPages)
    }
  }, [contactsData?.pageInfo?.totalPages, setTotalPages, totalPages])

  return {
    contactList,
    columns,
    numberOfRows,
    handleChangePage,
    handleChangePerPage,
    totalPages,
    page,
    per
  }
}
