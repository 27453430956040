import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useGlobalStyles } from 'src/styles/theme'
import { Colors, DynamicMuiIcon, Typography } from '..'
import { MuiIconVariant } from '../DynamicMuiIcon'

export interface IconTextProps {
  icon: MuiIconVariant
  text: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    text: {
      display: 'inline',
      '& p': {
        display: 'inline'
      }
    }
  })
})

export const IconText = ({ icon, text }: IconTextProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  return (
    <Box display="flex" alignItems="center" color={Colors.base.middleGray}>
      <DynamicMuiIcon variant={icon} size="18px" />
      <Box display="inline" width="4px" flexShrink={0} />
      <Box whiteSpace="pre-wrap" className={(classes.text, globalClasses.lineclamp1)}>
        <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
          {text}
        </Typography>
      </Box>
    </Box>
  )
}
