/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumPublicationStatus,
  EnumPublicationStatusFromJSON,
  EnumPublicationStatusFromJSONTyped,
  EnumPublicationStatusToJSON
} from './EnumPublicationStatus'
import {
  EnumSharingStatus,
  EnumSharingStatusFromJSON,
  EnumSharingStatusFromJSONTyped,
  EnumSharingStatusToJSON
} from './EnumSharingStatus'
import {
  SiteCoverPhoto,
  SiteCoverPhotoFromJSON,
  SiteCoverPhotoFromJSONTyped,
  SiteCoverPhotoToJSON
} from './SiteCoverPhoto'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface IndexSite
 */
export interface IndexSite {
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  publishedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  title?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  memo?: string | null
  /**
   *
   * @type {boolean}
   * @memberof IndexSite
   */
  isConfidential?: boolean
  /**
   *
   * @type {boolean}
   * @memberof IndexSite
   */
  isHeaderCustom?: boolean
  /**
   *
   * @type {boolean}
   * @memberof IndexSite
   */
  isFooterCustom?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  footerText?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  confidentialLabelName?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  archivedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof IndexSite
   */
  updatedAt?: string
  /**
   *
   * @type {SiteCoverPhoto}
   * @memberof IndexSite
   */
  coverPhoto?: SiteCoverPhoto | null
  /**
   *
   * @type {boolean}
   * @memberof IndexSite
   */
  isPublished?: boolean
  /**
   *
   * @type {EnumSharingStatus}
   * @memberof IndexSite
   */
  sharingStatus?: EnumSharingStatus
  /**
   *
   * @type {EnumPublicationStatus}
   * @memberof IndexSite
   */
  publicationStatus?: EnumPublicationStatus
  /**
   *
   * @type {SiteCoverPhoto}
   * @memberof IndexSite
   */
  headerLogo?: SiteCoverPhoto | null
  /**
   *
   * @type {UserForDisplay}
   * @memberof IndexSite
   */
  user?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof IndexSite
   */
  createdBy?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof IndexSite
   */
  updatedBy?: UserForDisplay
}

export function IndexSiteFromJSON(json: any): IndexSite {
  return IndexSiteFromJSONTyped(json, false)
}

export function IndexSiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndexSite {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    isConfidential: !exists(json, 'is_confidential') ? undefined : json['is_confidential'],
    isHeaderCustom: !exists(json, 'is_header_custom') ? undefined : json['is_header_custom'],
    isFooterCustom: !exists(json, 'is_footer_custom') ? undefined : json['is_footer_custom'],
    footerText: !exists(json, 'footer_text') ? undefined : json['footer_text'],
    confidentialLabelName: !exists(json, 'confidential_label_name') ? undefined : json['confidential_label_name'],
    archivedAt: !exists(json, 'archived_at') ? undefined : json['archived_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    coverPhoto: !exists(json, 'cover_photo') ? undefined : SiteCoverPhotoFromJSON(json['cover_photo']),
    isPublished: !exists(json, 'is_published') ? undefined : json['is_published'],
    sharingStatus: !exists(json, 'sharing_status') ? undefined : EnumSharingStatusFromJSON(json['sharing_status']),
    publicationStatus: !exists(json, 'publication_status')
      ? undefined
      : EnumPublicationStatusFromJSON(json['publication_status']),
    headerLogo: !exists(json, 'header_logo') ? undefined : SiteCoverPhotoFromJSON(json['header_logo']),
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
    updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by'])
  }
}

export function IndexSiteToJSON(value?: IndexSite | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    published_at: value.publishedAt,
    title: value.title,
    description: value.description,
    memo: value.memo,
    is_confidential: value.isConfidential,
    is_header_custom: value.isHeaderCustom,
    is_footer_custom: value.isFooterCustom,
    footer_text: value.footerText,
    confidential_label_name: value.confidentialLabelName,
    archived_at: value.archivedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    cover_photo: SiteCoverPhotoToJSON(value.coverPhoto),
    is_published: value.isPublished,
    sharing_status: EnumSharingStatusToJSON(value.sharingStatus),
    publication_status: EnumPublicationStatusToJSON(value.publicationStatus),
    header_logo: SiteCoverPhotoToJSON(value.headerLogo),
    user: UserForDisplayToJSON(value.user),
    created_by: UserForDisplayToJSON(value.createdBy),
    updated_by: UserForDisplayToJSON(value.updatedBy)
  }
}
