/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { ContactList, ContactListFromJSON, ContactListFromJSONTyped, ContactListToJSON } from './ContactList'

/**
 *
 * @export
 * @interface CreateContactListResponseData
 */
export interface CreateContactListResponseData {
  /**
   *
   * @type {ContactList}
   * @memberof CreateContactListResponseData
   */
  contactList?: ContactList
}

export function CreateContactListResponseDataFromJSON(json: any): CreateContactListResponseData {
  return CreateContactListResponseDataFromJSONTyped(json, false)
}

export function CreateContactListResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactListResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactList: !exists(json, 'contact_list') ? undefined : ContactListFromJSON(json['contact_list'])
  }
}

export function CreateContactListResponseDataToJSON(value?: CreateContactListResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_list: ContactListToJSON(value.contactList)
  }
}
