import { Box } from '@material-ui/core'
import { OrganizationPaymentContractBasicInfo, OrganizationPlanDetail } from '@noco/http-client/lib/noco'
import { useRouter } from 'next/router'
import React, { useCallback, useMemo, useState } from 'react'
import { Stepper } from 'src/components/molecules/Stepper'
import { StepperBoard } from 'src/components/molecules/StepperBoard'
import { StepperContext } from 'src/components/molecules/StepperContext'
import { pushUserActionDataLayer } from 'src/utils/gtm'
import { ContractFirstStep } from './ContractFirstStep'
import { ContractSecondStep } from './ContractSecondStep'
import { ContractThirdStep } from './ContractThirdStep'
import { ContractFourthStep } from './ContractFourthStep'

export interface ContractFreePlanStepperProps {
  plan: OrganizationPlanDetail
}

export const ContractFreePlanStepper = ({ plan }: ContractFreePlanStepperProps) => {
  const router = useRouter()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [userOptionQuantity, setUserOptionQuantity] = useState(0)
  const [contractBasicInfoValues, setContractBasicInfoValues] = useState<OrganizationPaymentContractBasicInfo>()

  const prevStep = useCallback(() => {
    if (currentIndex <= 0) return

    window.scrollTo({ top: 0 })
    setCurrentIndex(value => value - 1)
  }, [currentIndex])

  const nextStep = useCallback(() => {
    window.scrollTo({ top: 0 })
    setCurrentIndex(value => value + 1)
  }, [])

  const contractFirstStep = useMemo(() => {
    return (
      <ContractFirstStep
        plan={plan}
        onSubmit={userOptionQuantity => {
          setUserOptionQuantity(userOptionQuantity)
          nextStep()
        }}
      />
    )
  }, [nextStep, plan])

  const contractSecondStep = useMemo(() => {
    return (
      <ContractSecondStep
        plan={plan}
        backButtonDisabled={!plan.optionUserPlan}
        onSubmit={contractBasicInfo => {
          setContractBasicInfoValues(contractBasicInfo)
          nextStep()
        }}
        onBack={() => prevStep()}
      />
    )
  }, [nextStep, plan, prevStep])

  const contractThirdStep = useMemo(() => {
    return (
      <ContractThirdStep
        plan={plan}
        userOptionQuantity={userOptionQuantity}
        contractBasicInfo={contractBasicInfoValues}
        onSubmit={() => {
          pushUserActionDataLayer('contract', 'completed_contract', 'free_plan')
          nextStep()
        }}
        onBack={() => prevStep()}
      />
    )
  }, [contractBasicInfoValues, nextStep, plan, prevStep, userOptionQuantity])

  const items = useMemo(() => {
    const items = []

    // ユーザー追加オプションの利用が可能なプランの場合
    if (plan.optionUserPlan) {
      items.push({
        label: 'ユーザーご利用枠の追加',
        renderItem: contractFirstStep
      })
    }

    items.push(
      {
        label: 'ご契約者情報を入力',
        renderItem: contractSecondStep
      },
      {
        label: '確認',
        renderItem: contractThirdStep
      },
      {
        label: '完了',
        renderItem: (
          <ContractFourthStep
            plan={plan}
            onConfirm={() => {
              router.push('/setting/team/contract')
            }}
          />
        )
      }
    )

    return items
  }, [plan, plan.optionUserPlan, contractSecondStep, contractThirdStep, contractFirstStep, router])

  return (
    <Box>
      <StepperContext currentIndex={currentIndex}>
        <Stepper items={items} onClick={idx => setCurrentIndex(idx)} />
        <StepperBoard boardItems={items} />
      </StepperContext>
    </Box>
  )
}
