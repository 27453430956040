/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateMeAvatarRequestUser,
  UpdateMeAvatarRequestUserFromJSON,
  UpdateMeAvatarRequestUserFromJSONTyped,
  UpdateMeAvatarRequestUserToJSON
} from './UpdateMeAvatarRequestUser'

/**
 *
 * @export
 * @interface UpdateOrganizationAvatarRequest
 */
export interface UpdateOrganizationAvatarRequest {
  /**
   *
   * @type {UpdateMeAvatarRequestUser}
   * @memberof UpdateOrganizationAvatarRequest
   */
  organization?: UpdateMeAvatarRequestUser
}

export function UpdateOrganizationAvatarRequestFromJSON(json: any): UpdateOrganizationAvatarRequest {
  return UpdateOrganizationAvatarRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationAvatarRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationAvatarRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organization: !exists(json, 'organization') ? undefined : UpdateMeAvatarRequestUserFromJSON(json['organization'])
  }
}

export function UpdateOrganizationAvatarRequestToJSON(value?: UpdateOrganizationAvatarRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization: UpdateMeAvatarRequestUserToJSON(value.organization)
  }
}
