/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CancelContactChatMessageResponseFromJSON, CreateContactChatMessageRequestToJSON, CreateContactChatMessageResponseFromJSON, GetContactChatMessagesResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ContactChatMessageApi extends runtime.BaseAPI {
    /**
     * チャットメッセージをキャンセルする
     * cancel
     */
    async userV1ChatMessagesChatMessageIdCancelPutRaw(requestParameters, initOverrides) {
        if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
            throw new runtime.RequiredError('chatMessageId', 'Required parameter requestParameters.chatMessageId was null or undefined when calling userV1ChatMessagesChatMessageIdCancelPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/chat_messages/{chat_message_id}/cancel`.replace(`{${'chat_message_id'}}`, encodeURIComponent(String(requestParameters.chatMessageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CancelContactChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージをキャンセルする
     * cancel
     */
    async userV1ChatMessagesChatMessageIdCancelPut(requestParameters, initOverrides) {
        const response = await this.userV1ChatMessagesChatMessageIdCancelPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャットメッセージを取得する
     * index
     */
    async userV1ContactsContactIdChatMessagesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdChatMessagesGet.');
        }
        const queryParameters = {};
        if (requestParameters.fromAt !== undefined) {
            queryParameters['from_at'] = requestParameters.fromAt;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/chat_messages`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactChatMessagesResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージを取得する
     * index
     */
    async userV1ContactsContactIdChatMessagesGet(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdChatMessagesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャットメッセージを作成する
     * create
     */
    async userV1ContactsContactIdChatMessagesPostRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdChatMessagesPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/chat_messages`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactChatMessageRequestToJSON(requestParameters.createContactChatMessageRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateContactChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージを作成する
     * create
     */
    async userV1ContactsContactIdChatMessagesPost(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdChatMessagesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
