import { Box } from '@material-ui/core'
import { Button, Colors, Typography } from 'src/components/atoms'

export interface SenderNameProps {
  userName: string | undefined
  userAvatarUrl: string | undefined
  senderName: string | undefined
  hasEditPermission?: boolean
  onClick: () => void
}

export const SenderName = ({
  userAvatarUrl,
  userName,
  senderName,
  hasEditPermission = false,
  onClick
}: SenderNameProps) => {
  return (
    <Box display="flex" ml="16px" color={Colors.base.black} height="56px" borderBottom={`1px solid #EEF1F4`}>
      <Box display="flex" alignItems="center" width="152px" pl="8px">
        <Box width="24px" height="24px" flexShrink={0} mr="8px" borderRadius="24px" overflow="hidden">
          <img src={userAvatarUrl} alt={userName} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
        <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
          {userName}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} pl="12px">
        <Typography fontSize="s" lineheight="14px" noWrap>
          {senderName}
        </Typography>
        <Box sx={{ flexShrink: 0 }}>
          <Button
            title="編集"
            size="small"
            variant="outlined"
            style={{ height: '34px', padding: '10px 8px' }}
            onClick={onClick}
            disabled={!hasEditPermission}
          />
        </Box>
      </Box>
    </Box>
  )
}
