import React, { useCallback } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import Image from 'next/image'
import { Button, Typography } from 'src/components/atoms'
import { LineProgress } from 'src/components/atoms'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'

const useStyles = makeStyles(() => {
  return createStyles({
    mailIcon: {
      position: 'absolute',
      top: '82.17px',
      left: 0,
      right: 0,
      marginBottom: '22.67px',
      height: '27px'
    },
    sendingText: {
      fontSize: '14px',
      letterSpacing: '0.08em',
      fontWeight: 300
    },
    sendingContents: {
      minWidth: '247px',
      maxWidth: '274px',
      margin: '0 auto',
      position: 'absolute',
      left: 0,
      right: 0,
      top: '131.5px'
    },
    errorContents: {
      minWidth: '247px',
      maxWidth: '274px',
      margin: '0 auto',
      position: 'absolute',
      left: 0,
      right: 0,
      top: '136.5px'
    },
    errorText: {
      fontSize: '14px',
      letterSpacing: '0.08em',
      fontWeight: 300,
      color: '#DE1B63',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    errorIcon: {
      width: '18.67px',
      height: '18.67px',
      marginRight: '7.67px'
    },
    errorButton: {
      margin: '18px auto'
    }
  })
})

export interface SendEmailContentsProps {
  isSubmitting: boolean
  isSending: boolean
  setIsSending: (isSending: boolean) => void
  progressCompleted: boolean
  setProgressCompleted: (progressCompleted: boolean) => void
  isError?: Error | undefined
  setIsError?: (isError: Error | undefined) => void
}

export const SendEmailContents = ({
  isSubmitting,
  isSending,
  setIsSending,
  progressCompleted,
  setProgressCompleted,
  isError,
  setIsError
}: SendEmailContentsProps) => {
  const classes = useStyles()

  const handleCompletedReset = useCallback(() => {
    setProgressCompleted?.(false)
  }, [setProgressCompleted])

  const handleErrorReset = useCallback(() => {
    setIsError?.(undefined)
    setProgressCompleted?.(false)
    setIsSending(false)
  }, [setIsError, setIsSending, setProgressCompleted])

  return (
    <Box
      height={'280px'}
      bgcolor={'#F8FAFD'}
      width={'100%'}
      p={'24px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
    >
      <Box textAlign={'center'} width={'247px'}>
        <Box className={classes.mailIcon}>
          <Image src={'/images/send_email_icon.svg'} width={33.33} height={27} />
        </Box>

        {isError ? (
          <Box className={classes.errorContents}>
            <Typography className={classes.errorText}>
              <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
              メールの送信に失敗しました。
            </Typography>
            <Button className={classes.errorButton} onClick={handleErrorReset} title={'メール共有画面に戻る'} />
          </Box>
        ) : (
          <Box className={classes.sendingContents}>
            <Typography className={classes.sendingText}>
              {progressCompleted ? 'メールを送信しました' : 'メールを送信中です'}
            </Typography>
            <Box pt={progressCompleted ? '16px' : '8px'}>
              {!isSubmitting && isSending && progressCompleted && <Typography>画面を閉じずにお待ちください</Typography>}
              {isSending && !progressCompleted ? (
                <>
                  <Typography className={classes.sendingText}>画面を閉じずにお待ちください</Typography>
                  <LineProgress
                    isFetching={isSubmitting}
                    isPlaying={isSending}
                    setIsPlaying={setIsSending}
                    isCompleted={progressCompleted}
                    setIsCompleted={setProgressCompleted}
                    margin={'16px auto 0 auto'}
                    width={'220px'}
                  />
                </>
              ) : (
                <Button width={'270px'} onClick={handleCompletedReset} title={'続けて連絡先を選択してメールを送る'} />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
