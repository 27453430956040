/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RecordPorterHistoryContactListsInner
 */
export interface RecordPorterHistoryContactListsInner {
  /**
   *
   * @type {string}
   * @memberof RecordPorterHistoryContactListsInner
   */
  id?: string
}

export function RecordPorterHistoryContactListsInnerFromJSON(json: any): RecordPorterHistoryContactListsInner {
  return RecordPorterHistoryContactListsInnerFromJSONTyped(json, false)
}

export function RecordPorterHistoryContactListsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecordPorterHistoryContactListsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id']
  }
}

export function RecordPorterHistoryContactListsInnerToJSON(value?: RecordPorterHistoryContactListsInner | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id
  }
}
