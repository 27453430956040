import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Rocket = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.50033 20C7.39366 20 6.39366 20.4533 5.67366 21.1733C4.10033 22.7467 3.16699 29.3333 3.16699 29.3333C3.16699 29.3333 9.75366 28.4 11.327 26.8267C12.047 26.1067 12.5003 25.1067 12.5003 24C12.5003 21.7867 10.7137 20 8.50033 20ZM9.44699 24.9467C9.07366 25.32 6.55366 25.96 6.55366 25.96C6.55366 25.96 7.18033 23.4533 7.56699 23.0667C7.79366 22.8133 8.12699 22.6667 8.50033 22.6667C9.23366 22.6667 9.83366 23.2667 9.83366 24C9.83366 24.3733 9.68699 24.7067 9.44699 24.9467ZM23.727 18.2C32.207 9.71999 29.3803 3.11999 29.3803 3.11999C29.3803 3.11999 22.7803 0.293325 14.3003 8.77332L10.9803 8.10666C10.1137 7.93332 9.20699 8.21332 8.56699 8.83999L3.16699 14.2533L9.83366 17.1067L15.3937 22.6667L18.247 29.3333L23.647 23.9333C24.2737 23.3067 24.5537 22.4 24.3803 21.52L23.727 18.2ZM10.3803 14.44L7.83366 13.3467L10.4603 10.72L12.3803 11.1067C11.6203 12.2133 10.9403 13.3733 10.3803 14.44ZM19.1537 24.6667L18.0603 22.12C19.127 21.56 20.287 20.88 21.3803 20.12L21.767 22.04L19.1537 24.6667ZM21.8337 16.32C20.0737 18.08 17.327 19.52 16.447 19.96L12.5403 16.0533C12.967 15.1867 14.407 12.44 16.1803 10.6667C22.4203 4.42666 27.1537 5.34666 27.1537 5.34666C27.1537 5.34666 28.0737 10.08 21.8337 16.32ZM20.5003 14.6667C21.967 14.6667 23.167 13.4667 23.167 12C23.167 10.5333 21.967 9.33332 20.5003 9.33332C19.0337 9.33332 17.8337 10.5333 17.8337 12C17.8337 13.4667 19.0337 14.6667 20.5003 14.6667Z" />
    </SvgIcon>
  )
}
export default Rocket
