/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetContactListsResponseData,
  GetContactListsResponseDataFromJSON,
  GetContactListsResponseDataFromJSONTyped,
  GetContactListsResponseDataToJSON
} from './GetContactListsResponseData'

/**
 *
 * @export
 * @interface GetContactListsResponse
 */
export interface GetContactListsResponse {
  /**
   *
   * @type {number}
   * @memberof GetContactListsResponse
   */
  status?: number
  /**
   *
   * @type {GetContactListsResponseData}
   * @memberof GetContactListsResponse
   */
  data?: GetContactListsResponseData
}

export function GetContactListsResponseFromJSON(json: any): GetContactListsResponse {
  return GetContactListsResponseFromJSONTyped(json, false)
}

export function GetContactListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactListsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetContactListsResponseDataFromJSON(json['data'])
  }
}

export function GetContactListsResponseToJSON(value?: GetContactListsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetContactListsResponseDataToJSON(value.data)
  }
}
