/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateMaterialableTrackResponseData,
  CreateMaterialableTrackResponseDataFromJSON,
  CreateMaterialableTrackResponseDataFromJSONTyped,
  CreateMaterialableTrackResponseDataToJSON
} from './CreateMaterialableTrackResponseData'

/**
 *
 * @export
 * @interface CreateMaterialableTrackResponse
 */
export interface CreateMaterialableTrackResponse {
  /**
   *
   * @type {number}
   * @memberof CreateMaterialableTrackResponse
   */
  status?: number
  /**
   *
   * @type {CreateMaterialableTrackResponseData}
   * @memberof CreateMaterialableTrackResponse
   */
  data?: CreateMaterialableTrackResponseData
}

export function CreateMaterialableTrackResponseFromJSON(json: any): CreateMaterialableTrackResponse {
  return CreateMaterialableTrackResponseFromJSONTyped(json, false)
}

export function CreateMaterialableTrackResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateMaterialableTrackResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : CreateMaterialableTrackResponseDataFromJSON(json['data'])
  }
}

export function CreateMaterialableTrackResponseToJSON(value?: CreateMaterialableTrackResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CreateMaterialableTrackResponseDataToJSON(value.data)
  }
}
