/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateDocumentMiscSettingRequestDocumentMiscSetting
 */
export interface UpdateDocumentMiscSettingRequestDocumentMiscSetting {
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentMiscSettingRequestDocumentMiscSetting
   */
  isShowShareButton?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentMiscSettingRequestDocumentMiscSetting
   */
  isShowSnsButton?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentMiscSettingRequestDocumentMiscSetting
   */
  isPublicSearchEngine?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentMiscSettingRequestDocumentMiscSetting
   */
  ogpTitle?: string
  /**
   *
   * @type {string}
   * @memberof UpdateDocumentMiscSettingRequestDocumentMiscSetting
   */
  ogpDescription?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentMiscSettingRequestDocumentMiscSetting
   */
  isShowPrintingButton?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateDocumentMiscSettingRequestDocumentMiscSetting
   */
  isShowDownloadButton?: boolean
}

export function UpdateDocumentMiscSettingRequestDocumentMiscSettingFromJSON(
  json: any
): UpdateDocumentMiscSettingRequestDocumentMiscSetting {
  return UpdateDocumentMiscSettingRequestDocumentMiscSettingFromJSONTyped(json, false)
}

export function UpdateDocumentMiscSettingRequestDocumentMiscSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentMiscSettingRequestDocumentMiscSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    isShowShareButton: !exists(json, 'is_show_share_button') ? undefined : json['is_show_share_button'],
    isShowSnsButton: !exists(json, 'is_show_sns_button') ? undefined : json['is_show_sns_button'],
    isPublicSearchEngine: !exists(json, 'is_public_search_engine') ? undefined : json['is_public_search_engine'],
    ogpTitle: !exists(json, 'ogp_title') ? undefined : json['ogp_title'],
    ogpDescription: !exists(json, 'ogp_description') ? undefined : json['ogp_description'],
    isShowPrintingButton: !exists(json, 'is_show_printing_button') ? undefined : json['is_show_printing_button'],
    isShowDownloadButton: !exists(json, 'is_show_download_button') ? undefined : json['is_show_download_button']
  }
}

export function UpdateDocumentMiscSettingRequestDocumentMiscSettingToJSON(
  value?: UpdateDocumentMiscSettingRequestDocumentMiscSetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    is_show_share_button: value.isShowShareButton,
    is_show_sns_button: value.isShowSnsButton,
    is_public_search_engine: value.isPublicSearchEngine,
    ogp_title: value.ogpTitle,
    ogp_description: value.ogpDescription,
    is_show_printing_button: value.isShowPrintingButton,
    is_show_download_button: value.isShowDownloadButton
  }
}
