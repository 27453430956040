/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SiteSectionFromJSON, SiteSectionToJSON } from './SiteSection';
export function GetSiteSectionsResponseDataFromJSON(json) {
    return GetSiteSectionsResponseDataFromJSONTyped(json, false);
}
export function GetSiteSectionsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        siteSections: !exists(json, 'site_sections')
            ? undefined
            : json['site_sections'].map(SiteSectionFromJSON)
    };
}
export function GetSiteSectionsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        site_sections: value.siteSections === undefined ? undefined : value.siteSections.map(SiteSectionToJSON)
    };
}
