/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting
 */
export interface UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting
   */
  subjectName?: string
}

export function UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSON(
  json: any
): UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting {
  return UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSONTyped(json, false)
}

export function UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    subjectName: !exists(json, 'subject_name') ? undefined : json['subject_name']
  }
}

export function UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingToJSON(
  value?: UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    subject_name: value.subjectName
  }
}
