/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumMaterialContactType = {
    DocumentContact: 'document_contact',
    SiteContact: 'site_contact'
};
export function EnumMaterialContactTypeFromJSON(json) {
    return EnumMaterialContactTypeFromJSONTyped(json, false);
}
export function EnumMaterialContactTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumMaterialContactTypeToJSON(value) {
    return value;
}
