import { NocoSDKApp } from '@noco/sdk'
import { fetchApi } from './fetch-client'

export const nocoSDK = new (class NocoSDK {
  client?: NocoSDKApp
  constructor() {}
  initalize() {
    this.client = NocoSDKApp.initializeApp(fetchApi)
    return this
  }
})()
