import { useCallback } from 'react'
import { createStyles, makeStyles, Box, Modal, Slide } from '@material-ui/core'
import { SlideForm } from 'src/components/organisms'
import { Input, Typography } from 'src/components/atoms'
import { useFormik, FormikErrors } from 'formik'

interface ModalChangeSenderNameProps {
  senderName: string | undefined
  open: boolean
  onCancel: () => void
  onClose: () => void
  onConfirm: (senderName: string) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
)

interface SendValue {
  senderName: string
}
export const ModalChangeSenderName = ({
  senderName = '',
  open,
  onCancel,
  onClose,
  onConfirm
}: ModalChangeSenderNameProps) => {
  const classes = useStyles()

  const validate = useCallback((values: SendValue) => {
    const errors: FormikErrors<SendValue> = {}
    if ((values.senderName && values.senderName?.length > 30) || !values.senderName) {
      errors.senderName = 'error'
    }
    return errors
  }, [])

  const formik = useFormik<SendValue>({
    enableReinitialize: true,
    initialValues: { senderName },
    validate,
    onSubmit: values => {
      onConfirm(values.senderName)
      onClose()
    }
  })

  const cancelAndResetValues = useCallback(() => {
    formik.resetForm()
    onCancel()
  }, [formik, onCancel])

  const closeAndResetValues = useCallback(() => {
    formik.resetForm()
    onClose()
  }, [formik, onClose])

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="送信者名を編集"
            onClose={cancelAndResetValues}
            onCancel={closeAndResetValues}
            onConfirm={formik.handleSubmit}
            hideSettings
            buttonDisabled={!!formik.errors.senderName}
          >
            <Box p="24px">
              <Typography fontSize="s" fontWeight="bold">
                送信者名（最大30文字）
              </Typography>
              <Box mt="5px" />
              <Input {...formik.getFieldProps('senderName')} fullWidth />
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
