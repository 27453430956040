/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  FilterContentPins,
  FilterContentPinsFromJSON,
  FilterContentPinsFromJSONTyped,
  FilterContentPinsToJSON
} from './FilterContentPins'

/**
 *
 * @export
 * @interface FilterContent
 */
export interface FilterContent {
  /**
   *
   * @type {Array<FilterContentPins>}
   * @memberof FilterContent
   */
  pins?: Array<FilterContentPins>
  /**
   *
   * @type {number}
   * @memberof FilterContent
   */
  sizeAll?: number
}

export function FilterContentFromJSON(json: any): FilterContent {
  return FilterContentFromJSONTyped(json, false)
}

export function FilterContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterContent {
  if (json === undefined || json === null) {
    return json
  }
  return {
    pins: !exists(json, 'pins') ? undefined : (json['pins'] as Array<any>).map(FilterContentPinsFromJSON),
    sizeAll: !exists(json, 'size_all') ? undefined : json['size_all']
  }
}

export function FilterContentToJSON(value?: FilterContent | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    pins: value.pins === undefined ? undefined : (value.pins as Array<any>).map(FilterContentPinsToJSON),
    size_all: value.sizeAll
  }
}
