import React, { useCallback, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Typography } from '../../atoms'
import { PopupTemplate } from '../'
import { OrganizationPlanDetailGradeEnum } from '@noco/http-client/lib/noco'
import Image from 'next/image'
import { PlanConfirmInfo } from './type'

export interface ModalConfirmToPaidPlanProps {
  open: boolean
  // TODO: @enta PlanConfirmInfo に置き換えたい
  lightPlanText?: string
  // TODO: @enta PlanConfirmInfo に置き換えたい
  standardNewText?: string
  premiumPlanConfirmInfo?: PlanConfirmInfo
  currentPlanGrade?: OrganizationPlanDetailGradeEnum
  onClose: () => void
}

export const ModalConfirmToPaidPlan = ({
  open,
  lightPlanText,
  standardNewText,
  premiumPlanConfirmInfo,
  currentPlanGrade,
  onClose
}: ModalConfirmToPaidPlanProps) => {
  const routerTo = useMemo<{ path: string; title: string }>(() => {
    return {
      path: premiumPlanConfirmInfo?.submitPath || '/setting/team/pricing',
      title: premiumPlanConfirmInfo?.submitTitle || '料金プランを見る'
    }
  }, [premiumPlanConfirmInfo?.submitPath, premiumPlanConfirmInfo?.submitTitle])
  const { title, description } = useMemo(() => {
    if (!currentPlanGrade) return { title: '', description: '' }
    switch (currentPlanGrade) {
      case OrganizationPlanDetailGradeEnum.Light:
        return { title: 'プレミアムプランのパワフルな機能で、さらに顧客獲得！', description: lightPlanText || '' }
      case OrganizationPlanDetailGradeEnum.StandardNew:
        return { title: 'プレミアムプランのパワフルな機能で、さらに顧客獲得！', description: standardNewText || '' }
      case OrganizationPlanDetailGradeEnum.Premium:
        return { title: premiumPlanConfirmInfo?.title || '', description: premiumPlanConfirmInfo?.description || '' }
      default:
        return {
          title: 'プレミアムプランのパワフルな機能で、さらに顧客獲得！',
          description: `現在のプランでは、この機能を利用することはできません\nプレミアムプランなら、営業を強化するさまざまな機能がご利用いただけます。`
        }
    }
  }, [
    currentPlanGrade,
    lightPlanText,
    standardNewText,
    premiumPlanConfirmInfo?.title,
    premiumPlanConfirmInfo?.description
  ])

  const onConfirm = useCallback(() => {
    window.open(routerTo.path, '_blank')
  }, [routerTo.path])

  return (
    <PopupTemplate
      open={open}
      title={title}
      onClose={onClose}
      hasClose
      hasCancel
      hasConfirm
      onConfirm={onConfirm}
      cancelButtonLabel="キャンセル"
      confirmButtonLabel={routerTo.title}
    >
      <Box px="24px" pt="24px" pb="32px" minWidth={'581px'} width={1}>
        <Typography variant="body2" fontSize="s" lh="relaxed" style={{ whiteSpace: 'pre-wrap' }}>
          {description}
        </Typography>
        <Box mt="13px" display="flex" justifyContent="center">
          <Image src="/images/paid-plan.svg" width="532px" height="208px" alt="paid-plan" objectFit="contain" />
        </Box>
      </Box>
    </PopupTemplate>
  )
}
