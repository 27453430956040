import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var DocumentContactApi = /** @class */ (function () {
    function DocumentContactApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    DocumentContactApi.initialize = function (basePath, fetchApi) {
        if (!DocumentContactApi.instance) {
            DocumentContactApi.instance = new DocumentContactApi(basePath, fetchApi);
        }
        return DocumentContactApi.instance;
    };
    DocumentContactApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1DocumentContactApi(new Configuration(config));
    };
    return DocumentContactApi;
}());
export { DocumentContactApi };
