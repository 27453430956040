/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { IndexDocument, IndexDocumentFromJSON, IndexDocumentFromJSONTyped, IndexDocumentToJSON } from './IndexDocument'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetDocumentsResponseData
 */
export interface GetDocumentsResponseData {
  /**
   *
   * @type {Array<IndexDocument>}
   * @memberof GetDocumentsResponseData
   */
  documents?: Array<IndexDocument>
  /**
   *
   * @type {PageInfo}
   * @memberof GetDocumentsResponseData
   */
  pageInfo?: PageInfo
}

export function GetDocumentsResponseDataFromJSON(json: any): GetDocumentsResponseData {
  return GetDocumentsResponseDataFromJSONTyped(json, false)
}

export function GetDocumentsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documents: !exists(json, 'documents') ? undefined : (json['documents'] as Array<any>).map(IndexDocumentFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetDocumentsResponseDataToJSON(value?: GetDocumentsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    documents: value.documents === undefined ? undefined : (value.documents as Array<any>).map(IndexDocumentToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
