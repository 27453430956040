/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingInvoiceFromJSON, BillingInvoiceToJSON } from './BillingInvoice';
import { ScheduleBillingContractFromJSON, ScheduleBillingContractToJSON } from './ScheduleBillingContract';
export function CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSON(json) {
    return CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSONTyped(json, false);
}
export function CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        scheduleBillingContract: ScheduleBillingContractFromJSON(json['schedule_billing_contract']),
        scheduleBillingInvoice: BillingInvoiceFromJSON(json['schedule_billing_invoice']),
        scheduleBillingNextInvoice: BillingInvoiceFromJSON(json['schedule_billing_next_invoice'])
    };
}
export function CreateOrganizationPaymentScheduleBillingInvoiceResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        schedule_billing_contract: ScheduleBillingContractToJSON(value.scheduleBillingContract),
        schedule_billing_invoice: BillingInvoiceToJSON(value.scheduleBillingInvoice),
        schedule_billing_next_invoice: BillingInvoiceToJSON(value.scheduleBillingNextInvoice)
    };
}
