/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ActivityStatusObjectFromJSON, ActivityStatusObjectToJSON } from './ActivityStatusObject';
import { CompanyForDisplayFromJSON, CompanyForDisplayToJSON } from './CompanyForDisplay';
import { ContactForDisplayFromJSON, ContactForDisplayToJSON } from './ContactForDisplay';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
import { ViewingStatusObjectFromJSON, ViewingStatusObjectToJSON } from './ViewingStatusObject';
export function MaterialableViewHistoryFromJSON(json) {
    return MaterialableViewHistoryFromJSONTyped(json, false);
}
export function MaterialableViewHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contact: !exists(json, 'contact') ? undefined : ContactForDisplayFromJSON(json['contact']),
        company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company']),
        user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
        activityStatusObjects: !exists(json, 'activity_status_objects')
            ? undefined
            : json['activity_status_objects'].map(ActivityStatusObjectFromJSON),
        viewingStatusObject: !exists(json, 'viewing_status_object')
            ? undefined
            : ViewingStatusObjectFromJSON(json['viewing_status_object'])
    };
}
export function MaterialableViewHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact: ContactForDisplayToJSON(value.contact),
        company: CompanyForDisplayToJSON(value.company),
        user: UserForDisplayToJSON(value.user),
        activity_status_objects: value.activityStatusObjects === undefined
            ? undefined
            : value.activityStatusObjects.map(ActivityStatusObjectToJSON),
        viewing_status_object: ViewingStatusObjectToJSON(value.viewingStatusObject)
    };
}
