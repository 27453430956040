/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateContactSettingRequestContactSettingFromJSON, CreateContactSettingRequestContactSettingToJSON } from './CreateContactSettingRequestContactSetting';
import { UpdateContactSettingRequestContactSettingFieldsInnerFromJSON, UpdateContactSettingRequestContactSettingFieldsInnerToJSON } from './UpdateContactSettingRequestContactSettingFieldsInner';
export function UpdateContactSettingRequestFromJSON(json) {
    return UpdateContactSettingRequestFromJSONTyped(json, false);
}
export function UpdateContactSettingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contactSetting: !exists(json, 'contact_setting')
            ? undefined
            : CreateContactSettingRequestContactSettingFromJSON(json['contact_setting']),
        contactSettingFields: !exists(json, 'contact_setting_fields')
            ? undefined
            : json['contact_setting_fields'].map(UpdateContactSettingRequestContactSettingFieldsInnerFromJSON)
    };
}
export function UpdateContactSettingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact_setting: CreateContactSettingRequestContactSettingToJSON(value.contactSetting),
        contact_setting_fields: value.contactSettingFields === undefined
            ? undefined
            : value.contactSettingFields.map(UpdateContactSettingRequestContactSettingFieldsInnerToJSON)
    };
}
