/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ChatMessageAttachmentFileFile,
  ChatMessageAttachmentFileFileFromJSON,
  ChatMessageAttachmentFileFileFromJSONTyped,
  ChatMessageAttachmentFileFileToJSON
} from './ChatMessageAttachmentFileFile'

/**
 *
 * @export
 * @interface ChatMessageAttachmentFile
 */
export interface ChatMessageAttachmentFile {
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachmentFile
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachmentFile
   */
  organizationId: string
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachmentFile
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachmentFile
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof ChatMessageAttachmentFile
   */
  updatedAt: string
  /**
   *
   * @type {ChatMessageAttachmentFileFile}
   * @memberof ChatMessageAttachmentFile
   */
  file: ChatMessageAttachmentFileFile
}

export function ChatMessageAttachmentFileFromJSON(json: any): ChatMessageAttachmentFile {
  return ChatMessageAttachmentFileFromJSONTyped(json, false)
}

export function ChatMessageAttachmentFileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChatMessageAttachmentFile {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    organizationId: json['organization_id'],
    userId: json['user_id'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    file: ChatMessageAttachmentFileFileFromJSON(json['file'])
  }
}

export function ChatMessageAttachmentFileToJSON(value?: ChatMessageAttachmentFile | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    organization_id: value.organizationId,
    user_id: value.userId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    file: ChatMessageAttachmentFileFileToJSON(value.file)
  }
}
