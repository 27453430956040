/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  DiscardContactMemoResponse,
  DiscardContactMemoResponseFromJSON,
  DiscardContactMemoResponseToJSON,
  UpdateContactMemoRequest,
  UpdateContactMemoRequestFromJSON,
  UpdateContactMemoRequestToJSON,
  UpdateContactMemoResponse,
  UpdateContactMemoResponseFromJSON,
  UpdateContactMemoResponseToJSON
} from '../models'

export interface UserV1ContactsContactIdMemosContactMemoIdDeleteRequest {
  contactId: string
  contactMemoId: string
}

export interface UserV1ContactsContactIdMemosContactMemoIdDiscardPutRequest {
  contactId: string
  contactMemoId: string
}

export interface UserV1ContactsContactIdMemosContactMemoIdPutRequest {
  contactId: string
  contactMemoId: string
  updateContactMemoRequest?: UpdateContactMemoRequest
}

/**
 *
 */
export class ApiUserV1ContactMemoApi extends runtime.BaseAPI {
  /**
   */
  async userV1ContactsContactIdMemosContactMemoIdDeleteRaw(
    requestParameters: UserV1ContactsContactIdMemosContactMemoIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDelete.'
      )
    }

    if (requestParameters.contactMemoId === null || requestParameters.contactMemoId === undefined) {
      throw new runtime.RequiredError(
        'contactMemoId',
        'Required parameter requestParameters.contactMemoId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/memos/{contact_memo_id}`
          .replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId)))
          .replace(`{${'contact_memo_id'}}`, encodeURIComponent(String(requestParameters.contactMemoId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   */
  async userV1ContactsContactIdMemosContactMemoIdDelete(
    requestParameters: UserV1ContactsContactIdMemosContactMemoIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1ContactsContactIdMemosContactMemoIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async userV1ContactsContactIdMemosContactMemoIdDiscardPutRaw(
    requestParameters: UserV1ContactsContactIdMemosContactMemoIdDiscardPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<DiscardContactMemoResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDiscardPut.'
      )
    }

    if (requestParameters.contactMemoId === null || requestParameters.contactMemoId === undefined) {
      throw new runtime.RequiredError(
        'contactMemoId',
        'Required parameter requestParameters.contactMemoId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDiscardPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/memos/{contact_memo_id}/discard`
          .replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId)))
          .replace(`{${'contact_memo_id'}}`, encodeURIComponent(String(requestParameters.contactMemoId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => DiscardContactMemoResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1ContactsContactIdMemosContactMemoIdDiscardPut(
    requestParameters: UserV1ContactsContactIdMemosContactMemoIdDiscardPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<DiscardContactMemoResponse> {
    const response = await this.userV1ContactsContactIdMemosContactMemoIdDiscardPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async userV1ContactsContactIdMemosContactMemoIdPutRaw(
    requestParameters: UserV1ContactsContactIdMemosContactMemoIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateContactMemoResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdPut.'
      )
    }

    if (requestParameters.contactMemoId === null || requestParameters.contactMemoId === undefined) {
      throw new runtime.RequiredError(
        'contactMemoId',
        'Required parameter requestParameters.contactMemoId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/memos/{contact_memo_id}`
          .replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId)))
          .replace(`{${'contact_memo_id'}}`, encodeURIComponent(String(requestParameters.contactMemoId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateContactMemoRequestToJSON(requestParameters.updateContactMemoRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateContactMemoResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1ContactsContactIdMemosContactMemoIdPut(
    requestParameters: UserV1ContactsContactIdMemosContactMemoIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateContactMemoResponse> {
    const response = await this.userV1ContactsContactIdMemosContactMemoIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
