/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentAddon,
  OrganizationPaymentAddonFromJSON,
  OrganizationPaymentAddonFromJSONTyped,
  OrganizationPaymentAddonToJSON
} from './OrganizationPaymentAddon'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentAddonUserOptionResponseData
 */
export interface UpdateOrganizationPaymentAddonUserOptionResponseData {
  /**
   *
   * @type {OrganizationPaymentAddon}
   * @memberof UpdateOrganizationPaymentAddonUserOptionResponseData
   */
  addon: OrganizationPaymentAddon
}

export function UpdateOrganizationPaymentAddonUserOptionResponseDataFromJSON(
  json: any
): UpdateOrganizationPaymentAddonUserOptionResponseData {
  return UpdateOrganizationPaymentAddonUserOptionResponseDataFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentAddonUserOptionResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentAddonUserOptionResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    addon: OrganizationPaymentAddonFromJSON(json['addon'])
  }
}

export function UpdateOrganizationPaymentAddonUserOptionResponseDataToJSON(
  value?: UpdateOrganizationPaymentAddonUserOptionResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    addon: OrganizationPaymentAddonToJSON(value.addon)
  }
}
