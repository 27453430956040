import { rest } from "msw";
//import type { Source } from "../types";

export const handlers = [
  rest.get(`${process.env.API_HOST}/contact/v1/test/sample`, (_, res, ctx) => {
    return res(
      ctx.json({
        title: "hello test sample",
        text: "test sample is called",
      })
    );
  }),
  rest.post(`${process.env.API_HOST}/contact/v1/materialable_tracks`, (req, res, ctx) => {
    return res(
      ctx.status(201),
      ctx.json({document_id: "01GG7AGBNGC64WKB7W0SHF0DY3", event: "view", number_of_page: 1})
    )
  })
];
