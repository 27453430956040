import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Twitter = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.2918 16.3677C13.8371 16.3677 17.9652 10.1149 17.9652 4.69423C17.9652 4.51845 17.9613 4.33876 17.9535 4.16298C18.7566 3.58223 19.4496 2.86289 20 2.03876C19.2521 2.37151 18.458 2.58883 17.6449 2.68329C18.5011 2.1701 19.1421 1.3639 19.4492 0.414148C18.6438 0.891474 17.763 1.22819 16.8445 1.40985C16.2257 0.75231 15.4075 0.316939 14.5164 0.171052C13.6253 0.0251638 12.711 0.176884 11.9148 0.602756C11.1186 1.02863 10.4848 1.70493 10.1115 2.5271C9.73825 3.34927 9.64619 4.27152 9.84961 5.15126C8.21874 5.06942 6.62328 4.64576 5.16665 3.90776C3.71002 3.16975 2.42474 2.13387 1.39414 0.867273C0.870333 1.77038 0.710047 2.83905 0.945859 3.85609C1.18167 4.87312 1.79589 5.76222 2.66367 6.34266C2.01219 6.32198 1.37498 6.14658 0.804688 5.83095V5.88173C0.804104 6.82947 1.13175 7.74817 1.73192 8.48165C2.3321 9.21514 3.16777 9.71814 4.09687 9.90516C3.49338 10.0703 2.85999 10.0943 2.2457 9.97548C2.50788 10.7905 3.01798 11.5034 3.70481 12.0146C4.39164 12.5258 5.22093 12.8098 6.07695 12.827C4.62369 13.9686 2.82848 14.5878 0.980469 14.5849C0.652739 14.5844 0.325333 14.5643 0 14.5247C1.87738 15.7291 4.06128 16.3688 6.2918 16.3677Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default Twitter
