import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState, VFC } from 'react'

interface IStepperContext {
  idPrefix?: string
  currentIndex: number
}
const Context = createContext<IStepperContext>({ idPrefix: '', currentIndex: 0 })

const useUniquePrefix = () => {
  const [id, setId] = useState<string | undefined>(undefined)
  useEffect(() => {
    setId(`stepper-context-${Math.round(Math.random() * 1e5)}`)
  }, [])
  return id
}

export interface StepperContextProps {
  currentIndex: number
  children: ReactNode
}
export const StepperContext: VFC<StepperContextProps> = ({ children, currentIndex }) => {
  const idPrefix = useUniquePrefix()
  const context = useMemo(() => {
    return { idPrefix, currentIndex }
  }, [idPrefix, currentIndex])

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export const useStepperContext = () => {
  return useContext(Context)
}
