/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EuOrganization,
  EuOrganizationFromJSON,
  EuOrganizationFromJSONTyped,
  EuOrganizationToJSON
} from './EuOrganization'
import { EuSite, EuSiteFromJSON, EuSiteFromJSONTyped, EuSiteToJSON } from './EuSite'

/**
 *
 * @export
 * @interface GetContactSiteResponseData
 */
export interface GetContactSiteResponseData {
  /**
   *
   * @type {EuSite}
   * @memberof GetContactSiteResponseData
   */
  site?: EuSite
  /**
   *
   * @type {EuOrganization}
   * @memberof GetContactSiteResponseData
   */
  organization?: EuOrganization
  /**
   *
   * @type {string}
   * @memberof GetContactSiteResponseData
   */
  expiredOn?: string | null
  /**
   *
   * @type {boolean}
   * @memberof GetContactSiteResponseData
   */
  canViewMatreialableContent?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetContactSiteResponseData
   */
  canRequestToExtendExpiredAt?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetContactSiteResponseData
   */
  hasMaterialContactable?: boolean
}

export function GetContactSiteResponseDataFromJSON(json: any): GetContactSiteResponseData {
  return GetContactSiteResponseDataFromJSONTyped(json, false)
}

export function GetContactSiteResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactSiteResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    site: !exists(json, 'site') ? undefined : EuSiteFromJSON(json['site']),
    organization: !exists(json, 'organization') ? undefined : EuOrganizationFromJSON(json['organization']),
    expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
    canViewMatreialableContent: !exists(json, 'can_view_matreialable_content')
      ? undefined
      : json['can_view_matreialable_content'],
    canRequestToExtendExpiredAt: !exists(json, 'can_request_to_extend_expired_at')
      ? undefined
      : json['can_request_to_extend_expired_at'],
    hasMaterialContactable: !exists(json, 'has_material_contactable') ? undefined : json['has_material_contactable']
  }
}

export function GetContactSiteResponseDataToJSON(value?: GetContactSiteResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site: EuSiteToJSON(value.site),
    organization: EuOrganizationToJSON(value.organization),
    expired_on: value.expiredOn,
    can_view_matreialable_content: value.canViewMatreialableContent,
    can_request_to_extend_expired_at: value.canRequestToExtendExpiredAt,
    has_material_contactable: value.hasMaterialContactable
  }
}
