import { Box, ButtonBase, createStyles, Dialog, makeStyles, Typography, Slide } from '@material-ui/core'
import React from 'react'
import { Button, Colors, DynamicMuiIcon } from 'src/components/atoms'

export interface ModalUpgradeProps {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      backgroundColor: 'rgba(194, 193, 208, 0.5)'
    }
  })
)

export const ModalUpgrade = ({ open, onClose }: ModalUpgradeProps) => {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose} BackdropProps={{ className: classes.backdrop }}>
      <Slide in={open} direction="up">
        <Box width="581px" bgcolor={Colors.functional.background.default}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p="16px 24px"
            borderBottom={`1px solid ${Colors.background.silver}`}
          >
            <Typography variant="h3">プレミアムプランならブランドをカスタマイズできます</Typography>
            <ButtonBase onClick={onClose}>
              <Box color={Colors.base.middleGray}>
                <DynamicMuiIcon variant="close" color="inherit" size="28px" />
              </Box>
            </ButtonBase>
          </Box>
          <Box pt="24px" px="24px" pb="32px">
            <Typography variant="subtitle1">ライトプランでは、この機能を利用することはできません</Typography>
            <Box height="14px" />
            <Typography variant="subtitle1">
              プレミアムプランなら、営業を強化するさまざまな機能がご利用いただけます
            </Typography>
            <Box pt="16px" display="flex" justifyContent="center">
              <img src="/images/upgrade.png" width="340px" height="208px" alt="upgrade" style={{ display: 'block' }} />
            </Box>
          </Box>
          <Box
            bgcolor={Colors.background.lightGray}
            p="12px 24px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button title="キャンセル" kind="neutral" variant="outlined" onClick={onClose} />
            <Box width="12px" />
            <Button
              title="料金プランを見る"
              kind="primary"
              onClick={() => {
                window.open('/setting/team/pricing', '_blank')
              }}
            />
          </Box>
        </Box>
      </Slide>
    </Dialog>
  )
}
