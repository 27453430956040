/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DocumentSlideImageLink
 */
export interface DocumentSlideImageLink {
  /**
   *
   * @type {string}
   * @memberof DocumentSlideImageLink
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DocumentSlideImageLink
   */
  uri?: string
  /**
   * 0.00 ~ 1.00 の範囲で、0% ~ 100% の百分率を表します
   * @type {number}
   * @memberof DocumentSlideImageLink
   */
  rectRatioTop?: number
  /**
   * 0.00 ~ 1.00 の範囲で、0% ~ 100% の百分率を表します
   * @type {number}
   * @memberof DocumentSlideImageLink
   */
  rectRatioLeft?: number
  /**
   * 0.00 ~ 1.00 の範囲で、0% ~ 100% の百分率を表します
   * @type {number}
   * @memberof DocumentSlideImageLink
   */
  rectRatioRight?: number
  /**
   * 0.00 ~ 1.00 の範囲で、0% ~ 100% の百分率を表します
   * @type {number}
   * @memberof DocumentSlideImageLink
   */
  rectRatioBottom?: number
}

export function DocumentSlideImageLinkFromJSON(json: any): DocumentSlideImageLink {
  return DocumentSlideImageLinkFromJSONTyped(json, false)
}

export function DocumentSlideImageLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSlideImageLink {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    uri: !exists(json, 'uri') ? undefined : json['uri'],
    rectRatioTop: !exists(json, 'rect_ratio_top') ? undefined : json['rect_ratio_top'],
    rectRatioLeft: !exists(json, 'rect_ratio_left') ? undefined : json['rect_ratio_left'],
    rectRatioRight: !exists(json, 'rect_ratio_right') ? undefined : json['rect_ratio_right'],
    rectRatioBottom: !exists(json, 'rect_ratio_bottom') ? undefined : json['rect_ratio_bottom']
  }
}

export function DocumentSlideImageLinkToJSON(value?: DocumentSlideImageLink | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    uri: value.uri,
    rect_ratio_top: value.rectRatioTop,
    rect_ratio_left: value.rectRatioLeft,
    rect_ratio_right: value.rectRatioRight,
    rect_ratio_bottom: value.rectRatioBottom
  }
}
