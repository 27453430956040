import React, { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable'
import { Box, createStyles, useMediaQuery } from '@material-ui/core'
import { DocumentItem, SectionStyleData } from './DocumentItem'
import { Colors, Input, Typography } from 'src/components/atoms'
import { makeStyles } from '@material-ui/styles'
import { CSS } from '@dnd-kit/utilities'
import { ButtonList } from './ButtonList'
import theme, { useGlobalStyles } from 'src/styles/theme'
import { DocumentAdd } from './DocumentAdd'
import { SiteSection } from '@noco/http-client/lib/noco'
import {
  useCreateSiteSectionDocument,
  useDeleteSiteSection,
  useGetSite,
  useUpdateSiteSection
} from 'src/fixtures/modules/site/hooks'
import { PageSiteMode } from '../../SiteDetail'
import { ModalAlertShare, ModalAlertShareProps } from 'src/components/modals'
import { useListSelectorDocuments } from 'src/fixtures/modules/selector/hooks'
import clsx from 'clsx'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      transform: ({ transform }: SectionStyleData) => (transform ? CSS.Transform.toString(transform) : ''),
      transition: ({ transition }: SectionStyleData) => transition,
      opacity: ({ isDragging }: SectionStyleData) => (isDragging ? 0.5 : 1),
      '&:hover': {
        '& $target': {
          display: 'block'
        }
      }
    },
    inputStyle: {
      '& .MuiInput-root': {
        minHeight: '48px',
        padding: '8px 0',
        boxShadow: 'none',
        margin: '0 0 6px',
        '& textarea': {
          margin: '0 7px !important',
          padding: '0',
          fontSize: '21px',
          fontWeight: 'bold',
          lineHeight: '1.5 !important',
          letterSpacing: '0.02em'
        }
      }
    },
    hoverStyle: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default,
        boxShadow: `0 0 0 1px ${Colors.accent.keyPurple.default} inset`,
        borderRadius: '6px',
        cursor: 'pointer'
      }
    },
    target: {
      display: 'none',
      top: '10px'
    }
  })
)

export interface DocumentSectionProps {
  siteId: string
  section: SiteSection
  isSP?: boolean
  selectedDocumentIds?: string[]
  mode: PageSiteMode
}

export interface onChangeDocumentSection {
  id: string
  title: string
}

export const DocumentSection = ({ siteId, section, isSP, selectedDocumentIds, mode }: DocumentSectionProps) => {
  const { listeners, setNodeRef, transition, transform, isDragging, setActivatorNodeRef } = useSortable({
    id: section.id!,
    data: { type: 'container', children: section.siteSectionDocuments },
    disabled: mode !== 'setting'
  })

  const classes = useStyles({ transform, transition, isDragging })
  const globalClasses = useGlobalStyles()
  const [isEditing, toggleIsEditing] = useReducer(value => !value, false)
  const [titleText, setTitleText] = useState<string>(section.name || '')
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>('')
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { data: siteResponse } = useGetSite(siteId)
  const { handleUpdateSiteSection } = useUpdateSiteSection(siteId, section.id!)
  const { handleDeleteSiteSection } = useDeleteSiteSection({ siteId, siteSectionId: section.id! })
  const { handleCreateSiteSectionDocument, instructions } = useCreateSiteSectionDocument(siteId)
  const { data: documentResponse } = useListSelectorDocuments({ id: selectedDocumentId })

  const alertShareData: Omit<ModalAlertShareProps, 'open' | 'onClose'> = useMemo(() => {
    if (instructions?.includes('document_is_not_open')) {
      return {
        title: '資料を追加できません',
        description: `資料「${documentResponse?.selectorItems?.[0].text}」の「リンクの共有」がOFFであるため、資料サイト「${siteResponse?.site?.title}」に追加できません`,
        infoList: [
          {
            question: 'この資料を資料サイトに追加するには？',
            answerList: [
              {
                label: '資料の「リンクの共有」をONにする'
              },
              {
                label: '資料サイトの「リンクの共有」をOFFにする'
              }
            ]
          }
        ],
        image: '/images/alert-share/document-and-site.svg'
      }
    } else if (instructions?.includes('document_is_not_published')) {
      return {
        title: '資料を追加できません',
        description: `資料「${documentResponse?.selectorItems?.[0].text}」が非公開のため、資料サイト「${siteResponse?.site?.title}」に追加できません`,
        infoList: [
          {
            question: 'この資料を資料サイトに追加するには？',
            answerList: [
              {
                label: '資料を公開にする'
              }
            ]
          }
        ],
        image: '/images/alert-share/document-unpublished-to-open.svg'
      }
    }
    return {
      title: '',
      description: '',
      infoList: []
    }
  }, [documentResponse?.selectorItems, siteResponse?.site, instructions])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitleText(e.target.value)
  }, [])
  const handleSave = useCallback(() => {
    toggleIsEditing()
    handleUpdateSiteSection({ siteSection: { name: titleText } })
  }, [titleText, handleUpdateSiteSection])

  const handleAddDocument = useCallback(
    (value: string) => {
      const _length = section?.siteSectionDocuments?.length
      setSelectedDocumentId(value)
      handleCreateSiteSectionDocument({
        siteSectionDocument: {
          documentId: value,
          siteSectionId: section.id,
          sort: _length ? _length + 1 : 1
        }
      })
    },
    [handleCreateSiteSectionDocument, section]
  )

  useEffect(() => {
    if (instructions?.includes('document_is_not_open') || instructions?.includes('document_is_not_published')) {
      setOpenAlert(true)
    }
  }, [instructions])

  return (
    <>
      <div ref={setNodeRef} className={classes.root}>
        {mode === 'setting' && (
          <Box position="absolute" top="14px" right="16px" className={classes.target}>
            <ButtonList
              onDelete={handleDeleteSiteSection}
              onMove={() => {}}
              ref={setActivatorNodeRef}
              listeners={listeners}
            />
          </Box>
        )}
        {isEditing && mode === 'setting' ? (
          <Box className={classes.inputStyle}>
            <Input
              name="title"
              value={titleText}
              onChange={handleChange}
              onBlur={handleSave}
              fullWidth
              autoFocus
              multiline
            />
          </Box>
        ) : (
          <Box
            width={1}
            px="8px"
            py="8px"
            mb="6px"
            borderBottom={`1px solid ${Colors.background.silver}`}
            className={clsx(
              mode === 'setting' ? globalClasses.cursorPointer : undefined,
              mode === 'setting' ? classes.hoverStyle : undefined
            )}
            onClick={toggleIsEditing}
            style={{ wordBreak: 'break-all' }}
          >
            <Typography variant="h2" fontSize="xl" letterSpacing="default" fontWeight="bold">
              {titleText || 'セクションタイトルを入力'}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexWrap="wrap" mx="-20px">
          <SortableContext
            items={section.siteSectionDocuments?.map(item => item.id!) || []}
            strategy={rectSortingStrategy}
            id={section.id}
          >
            {section.siteSectionDocuments?.map(item => (
              <Box key={item.id} width={isDownSm || isSP ? 1 : '33.3%'} boxSizing="border-box" p="20px">
                <DocumentItem siteId={siteId} document={item} mode={mode} />
              </Box>
            ))}
            {mode === 'setting' && (
              <Box width={isDownSm || isSP ? 1 : '33.3%'} boxSizing="border-box" p="20px">
                <DocumentAdd onSelect={handleAddDocument} selectedDocumentIds={selectedDocumentIds} />
              </Box>
            )}
          </SortableContext>
        </Box>
      </div>
      <ModalAlertShare open={openAlert} onClose={() => setOpenAlert(false)} {...alertShareData} />
    </>
  )
}
