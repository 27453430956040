import {
  UpdateDocumentOpportunityRequest,
  UpdateDocumentCtaRequest,
  UpdateDocumentRequest,
  UserV1DocumentsGetRequest,
  CreateDocumentShareWithContactRequest,
  UpdateDocumentShareByLinkRequest,
  UpdateDocumentMessageRequest,
  UpdateDocumentMiscSettingRequest,
  UpdateDocumentChatSettingRequest,
  UserV1DocumentsDocumentIdSitesGetRequest,
  UserV1DocumentsDocumentIdViewHistoriesGetRequest,
  UserV1DocumentsDocumentIdDocumentContactsGetRequest
} from '@noco/http-client/lib/noco'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR, { mutate } from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'

// list の request パラメーターが現状ない問題
export const useListDocuments = (params: UserV1DocumentsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const dapi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth) return
    return await dapi
      ?.ApiFactory(auth.token)
      .userV1DocumentsGet(params, { cache: 'no-store' })
      .then(res => res.data)
  }, [auth, params, dapi])
  return useSWR(auth?.token && SWRCachePath.listDocuments(params), func)
}

export const useListDocumentFilter = () => {
  const { data: auth } = useAuthenticate()
  const filterApi = nocoSDK.client?.userService.filterApi

  const funcFilter = useCallback(async () => {
    if (!auth) return
    return await filterApi
      ?.ApiFactory(auth.token)
      .userV1DocumentFilterGet()
      .then(res => res.data)
  }, [auth, filterApi])
  return useSWR(auth?.token && SWRCachePath.documentsFilter(), funcFilter)
}

export const useGetDocument = (documentId?: string) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth || !documentId) return undefined
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdGet({ documentId })
      .then(item => item.data?.document)
  }, [auth, documentApi, documentId])
  return useSWR(auth?.token && documentId ? SWRCachePath.getDocument(documentId) : null, func, {
    onError: err => handleError(err, { tag: 'Get document' })
  })
}

export const useCreateDocument = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [isFirstCreatedDocument, setIsFirstCreatedDocument] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const router = useRouter()
  const [progress, setProgress] = useState(0)
  const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec))

  const handleCreateDocument = useCallback(
    async (file: File) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        setProgress(90)
        const res = await nocoSDK.client?.userService.documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsFileUploadPost({ file: file })
        setIsFirstCreatedDocument(res?.data.isFirstCreatedDocument || false)
        setProgress(100)
        // NOTE: プログレスバーのアニメーションを待つ
        await sleep(100)
        const documentId = res?.data?.document?.id!
        router.push('/documents/[documentId]', `/documents/${documentId}`)
        Toaster.success('資料を処理中です。しばらくお待ち下さい。')
      } catch (err) {
        setProgress(0)
        await handleError(err, { setError, tag: 'Create document' })
        throw new Error('error')
      } finally {
        setIsLoading(false)
      }
    },
    [auth, router]
  )

  return { error, isLoading, handleCreateDocument, progress, isFirstCreatedDocument }
}

export const useUpdateDocumentSlide = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const [progress, setProgress] = useState(0)
  const sleep = (msec: number) => new Promise(resolve => setTimeout(resolve, msec))

  const handleUpdateDocumentSlide = useCallback(
    async (documentId: string, file: File) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        setProgress(90)
        await nocoSDK.client?.userService.documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsDocumentIdFileUpdatePost({ documentId, file })
        setProgress(100)
        // NOTE: プログレスバーのアニメーションを待つ
        await sleep(100)
        mutate(SWRCachePath.getDocument(documentId))
        Toaster.success('資料のアップデートを開始しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document slide' })
        setProgress(0)
        throw new Error('error')
      } finally {
        setIsLoading(false)
      }
    },
    [auth]
  )

  return { error, isLoading, handleUpdateDocumentSlide, progress }
}

export const useUpdateDocument = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mudateDocument } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocument = useCallback(
    async (updateDocumentRequest: UpdateDocumentRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsDocumentIdPut({ documentId: documentId, updateDocumentRequest })
        mudateDocument()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mudateDocument]
  )

  return { error, isLoading, handleUpdateDocument }
}

export const useUpdateDocumentCta = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocumentCta = useCallback(
    async (updateDocumentCtaRequest: UpdateDocumentCtaRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsDocumentIdCtaPut({ documentId: documentId, updateDocumentCtaRequest })
        mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document cta' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutate]
  )

  return { error, isLoading, handleUpdateDocumentCta }
}

export const useUpdateDocumentChat = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocumentChat = useCallback(
    async (updateDocumentChatSettingRequest: UpdateDocumentChatSettingRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsDocumentIdChatSettingPut({ documentId, updateDocumentChatSettingRequest })
        mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document chat' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutate]
  )

  return { error, isLoading, handleUpdateDocumentChat }
}

export const useUpdateDocumentMessage = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocumentMessage = useCallback(
    async (updateDocumentMessageRequest: UpdateDocumentMessageRequest) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        await documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsDocumentIdMessagePut({ documentId: documentId, updateDocumentMessageRequest })
        mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document chat' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutate]
  )

  return { error, isLoading, handleUpdateDocumentMessage }
}

export const useUpdateDocumentPublish = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const [instructions, setInstructions] = useState<string[] | undefined>()
  const { mutate } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocumentPublish = useCallback(
    async (publish: boolean) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)

        await documentApi?.ApiFactory(auth.token).userV1DocumentsDocumentIdPublishPut({
          documentId: documentId,
          createDocumentPublishRequest: {
            publish
          }
        })
        mutate()
        Toaster.success('公開設定を変更しました')
      } catch (err) {
        await handleError(err, { setError, setInstructions, tag: 'Update document publish' }, false)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutate]
  )

  return { error, isLoading, handleUpdateDocumentPublish, instructions }
}

export const useUpdateDocumentOpportunity = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocumentOpportunity = useCallback(
    async (updateDocumentOpportunityRequest: UpdateDocumentOpportunityRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsDocumentIdOpportunityPut({ documentId: documentId, updateDocumentOpportunityRequest })
        mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document cta' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutate]
  )

  return { error, isLoading, handleUpdateDocumentOpportunity }
}

export const useShareDocumentToContact = (documentId: string, onSuccess?: () => void) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleShareDocumentToContact = useCallback(
    async (
      createDocumentShareWithContactRequest: CreateDocumentShareWithContactRequest,
      selectedDocumentId: string
    ) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await documentApi?.ApiFactory(auth.token).userV1DocumentsDocumentIdShareToContactPost({
          documentId: selectedDocumentId!,
          createDocumentShareWithContactRequest
        })

        mutate()
        onSuccess?.()
        // ! メール送信時の挙動変更に付き、トーストを出さないようにした
        //Toaster.success('メールを送信しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'share document to contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, mutate, onSuccess]
  )

  return { error, isLoading, handleShareDocumentToContact }
}

export const useShareDocumentByLink = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const [instructions, setInstructions] = useState<string[] | undefined>()
  const { mutate } = useGetDocument(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateShareDocumentByLink = useCallback(
    async (updateDocumentShareByLinkRequest: UpdateDocumentShareByLinkRequest, successToastMessage?: string) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await documentApi?.ApiFactory(auth.token).userV1DocumentsDocumentIdShareByLinkPut({
          documentId,
          updateDocumentShareByLinkRequest
        })
        mutate()
        Toaster.success(successToastMessage || '共有の設定を変更しました')
      } catch (err) {
        await handleError(err, { setError, setInstructions, tag: 'share document to contact' }, false)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutate]
  )
  return {
    error,
    isLoading,
    handleUpdateShareDocumentByLink,
    instructions
  }
}

export const useListDocumentContacts = (params: UserV1DocumentsDocumentIdDocumentContactsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth || !params.documentId) return
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdDocumentContactsGet(params)
      .then(res => res.data)
  }, [auth, params, documentApi])

  return useSWR(auth?.token && SWRCachePath.listDocumentContact(params), func)
}

export const useListDocumentViewHistories = (params: UserV1DocumentsDocumentIdViewHistoriesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth) return
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdViewHistoriesGet(params)
      .then(res => res.data)
  }, [auth, params, documentApi])

  return useSWR(auth?.token && SWRCachePath.listDocumentViewHistories(params), func)
}

export const useListDocumentViewHistoriesGraph = (
  documentId: string,
  contactId?: string,
  visitorSessionId?: string
) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth || !contactId) return
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdViewHistoriesGraphGet({ documentId, contactId, visitorSessionId })
      .then(res => res.data)
  }, [auth, documentId, documentApi, contactId, visitorSessionId])

  return useSWR(
    auth?.token && contactId && SWRCachePath.listDocumentViewHistoriesGraph(documentId, contactId, visitorSessionId),
    func
  )
}

export const useListDocumentViewHistoriesSessions = (
  documentId: string,
  page: number,
  per: number,
  contactId?: string
) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth || !contactId) return
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdViewHistoriesSessionsGet({ documentId, contactId, page, per })
      .then(res => res.data)
  }, [auth, documentId, documentApi, contactId, page, per])

  return useSWR(
    auth?.token && contactId && SWRCachePath.listDocumentViewHistoriesSession({ documentId, contactId, page, per }),
    func
  )
}

export const useListDocumentRegisteredOpportunityUsers = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth) return
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdOpportunityUsersGet({ documentId })
      .then(res => res.data)
  }, [auth, documentId, documentApi])

  return useSWR(auth?.token && SWRCachePath.listDocumentRegisteredOpportunityUsers(documentId), func)
}

export const useListDocumentSearchOpportunityUsers = (documentId: string, text?: string) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth) return
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdOpportunityUsersUsersGet({ documentId, text, page: 1, per: 999 })
      .then(res => res.data)
  }, [auth, documentId, documentApi, text])

  return useSWR(
    auth?.token ? SWRCachePath.listDocumentOpportunityUsers({ documentId, text, page: 1, per: 999 }) : null,
    func
  )
}

export const useUnRegisterOpportunityUser = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateListDocumentOpportunityUsers } = useListDocumentSearchOpportunityUsers(documentId)
  const { mutate: mutateListDocumentRegisteredOpportunityUsers } = useListDocumentRegisteredOpportunityUsers(documentId)
  const archiveApi = nocoSDK.client?.userService.documentApi

  const handleUnRegisterOpportunityUser = useCallback(
    async (id: string) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await archiveApi?.ApiFactory(auth.token).userV1OpportunityUsersIdDelete({ id })
        await mutateListDocumentOpportunityUsers(), mutateListDocumentRegisteredOpportunityUsers()
        Toaster.success('担当者を削除しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive Document' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, archiveApi, mutateListDocumentOpportunityUsers, mutateListDocumentRegisteredOpportunityUsers]
  )
  return { error, isLoading, handleUnRegisterOpportunityUser }
}

export const useRegisterOpportunityUser = (documentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateListDocumentOpportunityUsers } = useListDocumentSearchOpportunityUsers(documentId)
  const { mutate: mutateListDocumentRegisteredOpportunityUsers } = useListDocumentRegisteredOpportunityUsers(documentId)
  const archiveApi = nocoSDK.client?.userService.documentApi

  const handleRegisterOpportunityUser = useCallback(
    async (userId: string) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await archiveApi?.ApiFactory(auth.token).userV1DocumentsDocumentIdOpportunityUsersPost({
          documentId,
          createDocumentOpporunityUserRequest: { userId }
        })
        await mutateListDocumentOpportunityUsers(), mutateListDocumentRegisteredOpportunityUsers()
        Toaster.success('担当者を追加しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive Document' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, archiveApi, documentId, mutateListDocumentOpportunityUsers, mutateListDocumentRegisteredOpportunityUsers]
  )
  return { error, isLoading, handleRegisterOpportunityUser }
}

export const useArchiveDocument = (params: UserV1DocumentsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [isPosted, setIsPosted] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateListDocument } = useListDocuments(params)
  const { mutate: mutateDocumentsFilter } = useListDocumentFilter()
  const archiveApi = nocoSDK.client?.userService.documentApi

  const handleSaveArchived = useCallback(
    async (ids: string[], isArchived: boolean) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await archiveApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsArchivePut({ archiveDocumentsRequest: { archive: !isArchived, ids } })
        await mutateListDocument(), mutateDocumentsFilter()
        Toaster.success(`資料をアーカイブ${isArchived ? 'から移動' : ''}しました`)
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive Document' })
      } finally {
        setIsLoading(false)
        setIsPosted(true)
      }
    },
    [auth, archiveApi, mutateListDocument, mutateDocumentsFilter]
  )
  return { error, isLoading, handleSaveArchived, isPosted }
}

export const useGetDocumentMiscSetting = (documentId?: string) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const func = useCallback(async () => {
    if (!auth || !documentId) return undefined
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdMiscSettingGet({ documentId })
      .then(item => item.data?.documentMiscSetting)
  }, [auth, documentApi, documentId])
  return useSWR(auth?.token && documentId ? SWRCachePath.getDocumentMiscSetting(documentId) : null, func, {
    onError: err => handleError(err, { tag: 'Get document misc setting' })
  })
}

export const useUpdateDocumentMiscSetting = (documentId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateDocumentMiscSetting } = useGetDocumentMiscSetting(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocument = useCallback(
    async (updateDocumentMiscSettingRequest: UpdateDocumentMiscSettingRequest) => {
      try {
        if (!auth || !documentId) return undefined
        setError(undefined)
        setIsLoading(true)
        await documentApi
          ?.ApiFactory(auth.token)
          .userV1DocumentsDocumentIdMiscSettingPut({ documentId: documentId, updateDocumentMiscSettingRequest })
        mutateDocumentMiscSetting()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document misc setting' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutateDocumentMiscSetting]
  )

  return { error, isLoading, handleUpdateDocument }
}

export const useUpdateDocumentMiscSettingOgpFile = (documentId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateDocumentMiscSetting } = useGetDocumentMiscSetting(documentId)
  const documentApi = nocoSDK.client?.userService.documentApi
  const handleUpdateDocumentOgpFile = useCallback(
    async (file?: File) => {
      try {
        if (!auth || !documentId) return undefined
        setError(undefined)
        setIsLoading(true)
        await documentApi?.ApiFactory(auth.token).userV1DocumentsDocumentIdMiscSettingOgpFilePut({
          documentId: documentId,
          file
        })
        mutateDocumentMiscSetting()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update document misc setting ogp file' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, documentApi, documentId, mutateDocumentMiscSetting]
  )

  return { error, isLoading, handleUpdateDocumentOgpFile }
}

export const useListDocumentsDocumentIdSites = (parmas: UserV1DocumentsDocumentIdSitesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.documentApi
  const funcDocument = useCallback(async () => {
    if (!auth) return

    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1DocumentsDocumentIdSitesGet(parmas)
      .then(res => res.data)
  }, [auth, parmas, documentApi])
  return useSWR(auth?.token && SWRCachePath.listDocumentsDocumentIdSites(parmas), funcDocument)
}
