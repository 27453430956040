import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Colors } from 'src/components/atoms'
import { AttachmentFile, DeleteCardProps } from '../type'
import { Card } from '../Card'
import { MaterialCardContent } from './MaterialCardContent'

export type MaterialType = 'document' | 'site'

export interface MaterialCardProps {
  type: MaterialType
  attachmentFile: AttachmentFile
  deleteProps: DeleteCardProps
}

const useStyles = makeStyles(() =>
  createStyles({
    linkBox: {
      display: 'block',
      padding: '8px',
      textDecoration: 'none',
      color: Colors.base.black
    }
  })
)

export const MaterialCard = ({ type, attachmentFile, deleteProps }: MaterialCardProps) => {
  const classes = useStyles()

  return (
    <Card deleteProps={deleteProps}>
      {deleteProps.isDeletable ? (
        <Box p="8px">
          <MaterialCardContent type={type} attachmentFile={attachmentFile} isEditing />
        </Box>
      ) : (
        <a className={classes.linkBox} href={attachmentFile.url!} target="_blank" rel="noreferrer">
          <MaterialCardContent type={type} attachmentFile={attachmentFile} />
        </a>
      )}
    </Card>
  )
}
