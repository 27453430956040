/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ContactSettingFromJSON, ContactSettingToJSON } from './ContactSetting';
export function DocumentShareFormFromJSON(json) {
    return DocumentShareFormFromJSONTyped(json, false);
}
export function DocumentShareFormFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        contactSetting: !exists(json, 'contact_setting') ? undefined : ContactSettingFromJSON(json['contact_setting']),
        sort: !exists(json, 'sort') ? undefined : json['sort'],
        isRequired: !exists(json, 'is_required') ? undefined : json['is_required'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function DocumentShareFormToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        contact_setting: ContactSettingToJSON(value.contactSetting),
        sort: value.sort,
        is_required: value.isRequired,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
