import { AuthAPI } from './authApi';
import { ChatMessageAPI } from './chatMessageApi';
import { ChatMessagesAttachmentFileAPI } from './chatMessagesAttachmentFileApi';
import { ChatSummaryAPI } from './chatSummaryApi';
import { CompanyApi } from './companyApi';
import { CompanyListApi } from './companyListApi';
import { CompanyMemoApi } from './companyMemoApi';
import { CompanySettingApi } from './companySettingApi';
import { ContactApi } from './contactApi';
import { ContactChatMessageApi } from './contactChatMessageApi';
import { ContactEmailTransactionApi } from './contactEmailTransactionApi';
import { ContactListApi } from './contactListApi';
import { ContactMemoApi } from './contactMemoApi';
import { ContactSettingApi } from './contactSettingApi';
import { DefaultImageApi } from './defaultImageApi';
import { DocumentApi } from './documentApi';
import { DocumentContactApi } from './documentContactApi';
import { FilterApi } from './filterApi';
import { InboxApi } from './inboxApi';
import { MaterialListApi } from './materialListApi';
import { MeApi } from './meApi';
import { OrganizationApi } from './organizationApi';
import { OrganizationPaymentApi } from './organizationPaymentApi';
import { OrganizationUsersApi } from './organizationUsersApi';
import { OrganizationUserEmailSettingsApi } from './organizationUserEmailSettingsApi';
import { SelectorApi } from './selectorApi';
import { SiteApi } from './siteApi';
import { StaffListApi } from './staffListApi';
import { MailTemplateApi } from './mailTemplateApi';
import { JobApi } from './jobApi';
import { PorterApi } from './porterApi';
import { RecordPorterHistoryApi } from './recordPorterHistoryApi';
import { SendErrorApi } from './sendErrorApi';
import { UtilKenallApi } from './utilKenallApi';
var UserService = /** @class */ (function () {
    function UserService(basePath, fetchApi) {
        this.authApi = AuthAPI.initialize(basePath, fetchApi);
        this.chatMessageApi = ChatMessageAPI.initialize(basePath, fetchApi);
        this.chatMessagesAttachmentFileApi = ChatMessagesAttachmentFileAPI.initialize(basePath, fetchApi);
        this.chatSummaryApi = ChatSummaryAPI.initialize(basePath, fetchApi);
        this.companyApi = CompanyApi.initialize(basePath, fetchApi);
        this.companyListApi = CompanyListApi.initialize(basePath, fetchApi);
        this.companyMemoApi = CompanyMemoApi.initialize(basePath, fetchApi);
        this.companySettingApi = CompanySettingApi.initialize(basePath, fetchApi);
        this.contactApi = ContactApi.initialize(basePath, fetchApi);
        this.contactChatMessageApi = ContactChatMessageApi.initialize(basePath, fetchApi);
        this.contactEmailTransactionApi = ContactEmailTransactionApi.initialize(basePath, fetchApi);
        this.companyApi = CompanyApi.initialize(basePath, fetchApi);
        this.contactListApi = ContactListApi.initialize(basePath, fetchApi);
        this.contactMemoApi = ContactMemoApi.initialize(basePath, fetchApi);
        this.contactSettingApi = ContactSettingApi.initialize(basePath, fetchApi);
        this.defaultImageApi = DefaultImageApi.initialize(basePath, fetchApi);
        this.documentApi = DocumentApi.initialize(basePath, fetchApi);
        this.documentContactApi = DocumentContactApi.initialize(basePath, fetchApi);
        this.filterApi = FilterApi.initialize(basePath, fetchApi);
        this.meApi = MeApi.initialize(basePath, fetchApi);
        this.organizationApi = OrganizationApi.initialize(basePath, fetchApi);
        this.organizationPaymentApi = OrganizationPaymentApi.initialize(basePath, fetchApi);
        this.organizationUsersApi = OrganizationUsersApi.initialize(basePath, fetchApi);
        this.organizationUserEmailSettingsApi = OrganizationUserEmailSettingsApi.initialize(basePath, fetchApi);
        this.siteApi = SiteApi.initialize(basePath, fetchApi);
        this.staffListApi = StaffListApi.initialize(basePath, fetchApi);
        this.mailTemplateApi = MailTemplateApi.initialize(basePath, fetchApi);
        this.materialListApi = MaterialListApi.initialize(basePath, fetchApi);
        this.inboxApi = InboxApi.initialize(basePath, fetchApi);
        this.selectorApi = SelectorApi.initialize(basePath, fetchApi);
        this.jobApi = JobApi.initialize(basePath, fetchApi);
        this.porterApi = PorterApi.initialize(basePath, fetchApi);
        this.recordPorterHistoryApi = RecordPorterHistoryApi.initialize(basePath, fetchApi);
        this.sendErrorApi = SendErrorApi.initialize(basePath, fetchApi);
        this.utilKenallApi = UtilKenallApi.initialize(basePath, fetchApi);
    }
    UserService.initialize = function (basePath, fetchApi) {
        if (!UserService.instance) {
            UserService.instance = new UserService(basePath, fetchApi);
        }
        return UserService.instance;
    };
    return UserService;
}());
export { UserService };
