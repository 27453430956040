import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Colors, DynamicMuiIcon, Typography, MuiIconVariant } from 'src/components/atoms'
import { AddOrRemoveContact } from './AddOrRemoveContact'
import { SelectorItemObject } from '@noco/http-client/lib/noco'

export interface CustomButtonListType {
  showIcon?: MuiIconVariant
  buttonTitle: string
  onConfirm: () => void
}
export interface SelectTableRowProps {
  count: number
  onClose: () => void
  onCreateList?: (listName: string, contactIds: string[]) => void
  onUpdateList?: (contactListId: string, contactIds: string[]) => void
  selectedIds?: string[]
  keyword?: string
  selectorList?: SelectorItemObject
  onSetKeyword?: (value: string) => void
  customButtonList: CustomButtonListType[]
  modalAddContactOpen?: boolean
  onSetModalAddContactOpen?: (value: boolean) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      width: '100%',
      height: '100%'
    }
  })
})

export const SelectTableRow = ({
  count,
  onClose,
  onCreateList,
  onUpdateList,
  selectedIds,
  keyword,
  selectorList,
  onSetKeyword,
  customButtonList,
  modalAddContactOpen = false,
  onSetModalAddContactOpen
}: SelectTableRowProps) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        border={`2px solid ${Colors.base.black}`}
        borderRadius="4px"
        height="40px"
        bgcolor={Colors.functional.background.default}
        boxShadow="rgb(36 31 71 / 5%) 0px 0px 40px, rgb(36 31 71 / 10%) 0px 16px 24px, rgb(36 31 71 / 8%) 0px 4px 8px, rgb(36 31 71 / 8%) 0px 0px 1px"
        pl="18px"
      >
        <Box flex={1} display="flex" alignItems="center">
          <ButtonBase onClick={onClose} color={Colors.base.middleGray}>
            <DynamicMuiIcon variant="close" size="20px" />
          </ButtonBase>
          <Box mr="18px" />
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight" fontWeight="bold">
            {count}件 選択中
          </Typography>
        </Box>
        {customButtonList.map((list, i) => (
          <CustomButton key={i} showIcon={list.showIcon} buttonTitle={list.buttonTitle} onConfirm={list.onConfirm} />
        ))}
      </Box>
      <AddOrRemoveContact
        count={count}
        selectedIds={selectedIds}
        keyword={keyword}
        selectorList={selectorList}
        onArchivedBoxClose={onClose}
        onSetKeyword={onSetKeyword}
        onCreateList={onCreateList}
        onUpdateList={onUpdateList}
        modalAddContactOpen={modalAddContactOpen}
        onSetModalAddContactOpen={onSetModalAddContactOpen}
      />
    </>
  )
}

const CustomButton = ({ showIcon, buttonTitle, onConfirm }: CustomButtonListType) => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderLeft={`1px solid ${Colors.base.middleGray}`}
      px="16px"
      py="8px"
      width="fit-content"
      height={1}
    >
      <ButtonBase className={classes.button} onClick={onConfirm}>
        {showIcon && (
          <Box mr="10px" display="flex" alignItems="center" justifyContent="center" color={Colors.base.middleGray}>
            <DynamicMuiIcon variant={showIcon} size="20px" />
          </Box>
        )}
        <Typography fontWeight="bold" fontSize="s" lineheight="12px" letterSpacing="tight">
          {buttonTitle}
        </Typography>
      </ButtonBase>
    </Box>
  )
}
