/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ActivityStatusObjectFromJSON, ActivityStatusObjectToJSON } from './ActivityStatusObject';
import { EnumMaterialContactTypeFromJSON, EnumMaterialContactTypeToJSON } from './EnumMaterialContactType';
import { EnumMaterialableTypeFromJSON, EnumMaterialableTypeToJSON } from './EnumMaterialableType';
import { MaterialableFromJSON, MaterialableToJSON } from './Materialable';
import { ViewingStatusObjectFromJSON, ViewingStatusObjectToJSON } from './ViewingStatusObject';
export function ContactMaterialFromJSON(json) {
    return ContactMaterialFromJSONTyped(json, false);
}
export function ContactMaterialFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        materialContactType: !exists(json, 'material_contact_type')
            ? undefined
            : EnumMaterialContactTypeFromJSON(json['material_contact_type']),
        materialContactId: !exists(json, 'material_contact_id') ? undefined : json['material_contact_id'],
        contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
        isExpired: !exists(json, 'is_expired') ? undefined : json['is_expired'],
        expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
        canViewable: !exists(json, 'can_viewable') ? undefined : json['can_viewable'],
        materialableType: !exists(json, 'materialable_type')
            ? undefined
            : EnumMaterialableTypeFromJSON(json['materialable_type']),
        materialableId: !exists(json, 'materialable_id') ? undefined : json['materialable_id'],
        displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
        invitedAt: !exists(json, 'invited_at') ? undefined : json['invited_at'],
        viewingStatusObject: !exists(json, 'viewing_status_object')
            ? undefined
            : ViewingStatusObjectFromJSON(json['viewing_status_object']),
        activityStatusObjects: !exists(json, 'activity_status_objects')
            ? undefined
            : json['activity_status_objects'].map(ActivityStatusObjectFromJSON),
        materialable: !exists(json, 'materialable') ? undefined : MaterialableFromJSON(json['materialable'])
    };
}
export function ContactMaterialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        material_contact_type: EnumMaterialContactTypeToJSON(value.materialContactType),
        material_contact_id: value.materialContactId,
        contact_id: value.contactId,
        is_expired: value.isExpired,
        expired_on: value.expiredOn,
        can_viewable: value.canViewable,
        materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
        materialable_id: value.materialableId,
        display_name: value.displayName,
        invited_at: value.invitedAt,
        viewing_status_object: ViewingStatusObjectToJSON(value.viewingStatusObject),
        activity_status_objects: value.activityStatusObjects === undefined
            ? undefined
            : value.activityStatusObjects.map(ActivityStatusObjectToJSON),
        materialable: MaterialableToJSON(value.materialable)
    };
}
