import { Contact, ContactSetting, GetContactSettingsResponseData } from '@noco/http-client/lib/noco'
import { MuiIconVariant } from 'src/components/atoms'
import { DraggableData } from 'src/components/molecules'
import { TableColumn } from 'src/components/organisms'
import { ContactCompanyCell, NameCell } from 'src/components/organisms/Table/TableParts'
import { nonNullableFilter } from 'src/fixtures/utils/types'

export type ContactSettingValueMap = { [key in string]: { [fieldId: string]: any } }
export const initializeContactSettingValueMap = (settings: ContactSetting[], isEu = false): ContactSettingValueMap => {
  const initalSettingValueMap: ContactSettingValueMap = {}
  settings.forEach(s => {
    initalSettingValueMap[s.id!] = {}
    s.contactSettingFields?.forEach(item => {
      if (isEu && item.isHideOnEu) return
      if (!isEu && item.isHideOnUser) return
      initalSettingValueMap[s.id!][item.id!] = ''
    })
  })

  return initalSettingValueMap
}

export const convertFromContactSettingValueMap = (settingValueMap: ContactSettingValueMap) => {
  const contactSettingFieldValues: {
    contactSettingId: string
    contactSettingFieldId: string
    values?: { value: string }[]
  }[] = []
  Object.keys(settingValueMap).forEach(settingId => {
    const filedMap = settingValueMap[settingId]

    Object.keys(filedMap).forEach(fieldId => {
      // v は checkbox の場合のみ string[] / 他の場合は string
      const v: string[] | string = filedMap[fieldId]

      contactSettingFieldValues.push({
        contactSettingId: settingId,
        contactSettingFieldId: fieldId,
        values: Array.isArray(v) ? v.map(item => ({ value: item })) : [{ value: v }]
      })
    })
  })
  return contactSettingFieldValues
}

export const convertCompanyDataFromContact = (contact: Contact) => {
  const targetContactSetting = contact?.contactSettings?.find(item => item.settingType === 'company')
  const contactSettingId = targetContactSetting?.id
  const contactSettingFieldId = targetContactSetting?.contactSettingFields?.[0].id
  return { contactSettingId, contactSettingFieldId }
}

export const createDraggableSettingList = (
  data: GetContactSettingsResponseData,
  options?: { hideEdit?: boolean }
): DraggableData[] => {
  const activeData: DraggableData[] =
    data.activeContactSettings?.map(item => {
      // MEMO: snuffy isInputRequired は contactSettingFields 下を参照しつつ item.isInputRequiredOnEu || item.isInputRequired
      const isInputRequired = item?.contactSettingFields?.every(
        item => item.isInputRequiredOnEu || item.isInputRequired
      )
      return {
        id: item.id!,
        label: item.name!,
        icon: item.icon as MuiIconVariant,
        sort: item.sort || 0,
        description:
          item.kind === 'multiple_field' ? item?.contactSettingFields?.map(item => item.name).join('、') : undefined,
        isSelected: true,
        switchDisabled: isInputRequired,
        switchValue: isInputRequired,
        disabled: isInputRequired,
        isListType: item.contactSettingFields?.[0].itemType === 'list',
        canEdit: item.canEdit!,
        hideEdit: options?.hideEdit
      }
    }) || []

  const inactiveData =
    data.inactiveContactSettings?.map(item => ({
      id: item.id!,
      label: item.name!,
      icon: item.icon as MuiIconVariant,
      sort: item.sort || 0,
      isSelected: false,
      description:
        item.kind === 'multiple_field' ? item?.contactSettingFields?.map(item => item.name).join(' ,') : undefined,
      isListType: item.contactSettingFields?.[0].itemType === 'list',
      canEdit: item.canEdit!,
      hideEdit: options?.hideEdit
    })) || []

  return activeData.concat(inactiveData)
}

export const convertContactTableColumns = (activeContactSettings: ContactSetting[]) => {
  const priority = ['名前', '名前（ふりがな）', '会社名']
  const columns =
    activeContactSettings?.map(item => {
      if (item?.name === '名前') {
        return {
          name: item.id!,
          width: 132,
          title: '名前',
          fixed: true
        }
      }
      if (item?.name === '会社名') {
        return {
          name: 'company',
          width: 132,
          title: '会社名',
          render: ContactCompanyCell
        }
      }
      if (item.name === '担当者') {
        return {
          name: item.id!,
          width: 132,
          title: '担当者',
          render: NameCell
        }
      }
      return {
        name: item.id!,
        width: 120,
        title: item?.name
      }
    }) || []

  // TODO: sort 一回で全て済ませる
  const priorityColumns =
    columns.length > 0
      ? priority
          .map(label => {
            return columns.find(item => item?.title === label) as TableColumn | undefined
          })
          .concat(columns.filter(b => !priority.includes(b?.title!)))
          .filter(nonNullableFilter)
      : []

  return priorityColumns
}
