import { Box } from '@material-ui/core'
import { ContactForDisplay } from '@noco/http-client/lib/noco'
import React from 'react'
import { Colors, SvgIcon, Typography } from 'src/components/atoms'

export interface ContactCellProps {
  value?: ContactForDisplay
}

export const ContactCell = ({ value }: ContactCellProps) => {
  return (
    <Box display="flex" alignItems="center">
      <SvgIcon variant="avatar" size="18px" color={Colors.base.middleGray} />
      <Box width="6px" />

      <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
        {value?.displayName ?? '未設定'}
      </Typography>
    </Box>
  )
}
