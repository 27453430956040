import React, { useCallback, useState, useEffect } from 'react'
import { Box, createStyles, makeStyles, IconButton, ButtonBase, Popover } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography, SvgIcon } from 'src/components/atoms'
import { PopupTemplate } from 'src/components/modals'
import { ListNameModal } from './ListNameModal'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    },
    '& .dotsWrap': {
      padding: 0
    }
  })
})

export const Avatar = ({ avatar }: { avatar: string | undefined }) => {
  const classes = useStyles()
  return (
    <Box width="20px" height="20px" mr="6px" position="relative">
      {avatar ? (
        <img src={avatar} className={classes.img} />
      ) : (
        <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
      )}
    </Box>
  )
}

export const ExpandMore = () => {
  return (
    <Box width="20px" height="20px" display="flex" justifyContent="center" alignItems="center">
      <DynamicMuiIcon variant="expandMore" size="16px" color="action" />
    </Box>
  )
}

export const ExpandLess = () => {
  return (
    <Box width="20px" height="20px" display="flex" justifyContent="center" alignItems="center">
      <DynamicMuiIcon variant="expandLess" size="16px" color="action" />
    </Box>
  )
}

export const AddBox = ({
  selectedContactIds,
  onConfirm
}: {
  selectedContactIds?: string[]
  onConfirm: (listName: string, contactIds: string[]) => void
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState('')
  const handleOpenModal = useCallback(() => {
    setOpenModal(true)
  }, [])
  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
  }, [])
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }
  const handleConfirm = useCallback(() => {
    onConfirm(value, selectedContactIds as string[])
    setOpenModal(false)
    setValue('')
  }, [value, selectedContactIds, onConfirm])

  return (
    <>
      <Box width="20px" height="20px" display="flex" justifyContent="center" alignItems="center">
        <IconButton onClick={handleOpenModal} disabled={selectedContactIds?.length ? false : true}>
          <DynamicMuiIcon variant="addBoxOutlined" size="20px" />
        </IconButton>
      </Box>
      <ListNameModal
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        title="リストを新規作成"
        value={value}
        onChange={onChange}
      />
    </>
  )
}

interface DotsProps {
  label: string | undefined
  contactId: string | undefined
  onUpdateListName: (contactListId: string, name: string) => void
  onDeleteList: (contactId: string) => void
  onShowCount: (value: boolean) => void
}

export const Dots = ({ label, contactId, onUpdateListName, onDeleteList, onShowCount }: DotsProps) => {
  const globalClasses = useGlobalStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState('')
  const [openDeleteModal, setDeleteModal] = useState(false)
  const handleDotsClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleEdit = useCallback(() => {
    setOpenModal(true)
    setAnchorEl(null)
  }, [])
  const handleDelete = useCallback(() => {
    setDeleteModal(true)
    setAnchorEl(null)
  }, [])

  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
    onShowCount(false)
  }, [onShowCount])
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])
  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal(false)
    onShowCount(false)
  }, [onShowCount])
  const handleEditConfirm = useCallback(() => {
    onUpdateListName(contactId!, value)
    setOpenModal(false)
    setValue('')
    onShowCount(false)
  }, [value, contactId, onUpdateListName, onShowCount])

  const handlePopupDeleteConfirm = useCallback(() => {
    onDeleteList(contactId!)
    setDeleteModal(false)
    onShowCount(false)
  }, [onDeleteList, contactId, onShowCount])

  useEffect(() => {
    if (!label) {
      return
    }
    setValue(label)
  }, [label])

  return (
    <>
      <IconButton onClick={handleDotsClick} className="dotsWrap" style={{ padding: '0', marginRight: '-5px' }}>
        <DynamicMuiIcon variant="dots" size="20px" />
      </IconButton>
      <Popover
        PaperProps={{ style: { marginTop: '34px' } }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Box className={globalClasses.tooltip} display="flex" flexDirection="column">
          <ButtonBase onClick={handleEdit}>
            <Box
              borderBottom={`1px solid ${Colors.background.gray}`}
              p="10px 14px"
              display="flex"
              alignItems="center"
              color={Colors.base.middleGray}
            >
              <DynamicMuiIcon color="inherit" variant="edit" size="20px" />
              <Box width="4px" />
              <Typography fontSize="s" lineheight="14px">
                編集する
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase onClick={handleDelete}>
            <Box p="10px 14px" display="flex" alignItems="center" color={Colors.accent.keyRed.default}>
              <DynamicMuiIcon variant="delete" size="20px" />
              <Box width="4px" />
              <Typography fontSize="s" lineheight="14px">
                削除する
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
      </Popover>
      <ListNameModal
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={handleEditConfirm}
        title="リスト名を編集"
        value={value}
        onChange={onChange}
      />
      <PopupTemplate
        open={openDeleteModal}
        title="リストを削除"
        onClose={handleCloseDeleteModal}
        hasClose
        hasCancel
        hasConfirm
        onConfirm={handlePopupDeleteConfirm}
        cancelButtonLabel="キャンセル"
        confirmButtonLabel="削除する"
        confirmButtonKind="negative"
      >
        <Box p="24px" whiteSpace="pre-wrap">
          <Typography variant="body2">{`このリストを削除しますか？\n\n【注意】リストを削除しても、登録された会社・連絡先のデータは削除されません`}</Typography>
        </Box>
      </PopupTemplate>
    </>
  )
}
