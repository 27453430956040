import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CustomBranding = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9993 18.3333H19.9993C19.0827 18.3333 18.3327 19.0833 18.3327 20V26.6667C18.3327 27.5833 19.0827 28.3333 19.9993 28.3333H29.9993C30.916 28.3333 31.666 27.5833 31.666 26.6667V20C31.666 19.0833 30.916 18.3333 29.9993 18.3333ZM38.3327 31.6667V8.3C38.3327 6.46667 36.8327 5 34.9993 5H4.99935C3.16602 5 1.66602 6.46667 1.66602 8.3V31.6667C1.66602 33.5 3.16602 35 4.99935 35H34.9993C36.8327 35 38.3327 33.5 38.3327 31.6667ZM33.3327 31.7H6.66602C5.74935 31.7 4.99935 30.95 4.99935 30.0333V9.95C4.99935 9.03333 5.74935 8.28333 6.66602 8.28333H33.3327C34.2493 8.28333 34.9993 9.03333 34.9993 9.95V30.0333C34.9993 30.95 34.2493 31.7 33.3327 31.7Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84154C1.50202 1.98324 2.89359 1.98324 3.75188 2.84154L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.9497C-0.214574 5.0914 -0.214574 3.69983 0.643721 2.84154Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default CustomBranding
