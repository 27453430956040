/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EmailTransaction,
  EmailTransactionFromJSON,
  EmailTransactionFromJSONTyped,
  EmailTransactionToJSON
} from './EmailTransaction'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetContactsEmailTransactionsSendErrorsResponseData
 */
export interface GetContactsEmailTransactionsSendErrorsResponseData {
  /**
   *
   * @type {Array<EmailTransaction>}
   * @memberof GetContactsEmailTransactionsSendErrorsResponseData
   */
  emailTransactions?: Array<EmailTransaction>
  /**
   *
   * @type {PageInfo}
   * @memberof GetContactsEmailTransactionsSendErrorsResponseData
   */
  pageInfo?: PageInfo
}

export function GetContactsEmailTransactionsSendErrorsResponseDataFromJSON(
  json: any
): GetContactsEmailTransactionsSendErrorsResponseData {
  return GetContactsEmailTransactionsSendErrorsResponseDataFromJSONTyped(json, false)
}

export function GetContactsEmailTransactionsSendErrorsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactsEmailTransactionsSendErrorsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    emailTransactions: !exists(json, 'email_transactions')
      ? undefined
      : (json['email_transactions'] as Array<any>).map(EmailTransactionFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetContactsEmailTransactionsSendErrorsResponseDataToJSON(
  value?: GetContactsEmailTransactionsSendErrorsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    email_transactions:
      value.emailTransactions === undefined
        ? undefined
        : (value.emailTransactions as Array<any>).map(EmailTransactionToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
