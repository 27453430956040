import { useCallback, useState } from 'react'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useContactAuthentication } from '../contactAuth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'

// visitor 情報を返す
export const useGetVisitor = (isPreview = false) => {
  const { data: auth } = useContactAuthentication()
  const visitorApi = nocoSDK.client?.contactService.visitorApi

  const func = useCallback(async () => {
    if (!auth?.token || isPreview) return
    return await visitorApi
      ?.ApiFactory(auth.token)
      .contactV1VisitorGet({ xNocoVisitorToken: auth.token })
      .then(item => item.data?.visitor)
  }, [auth?.token, visitorApi, isPreview])
  return useSWR(visitorApi && auth?.token && !isPreview ? SWRCachePath.getVisitor() : null, func)
}

// vistor を作成する
export const useCreateVisitor = () => {
  const { data: auth } = useContactAuthentication()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()

  const visitorApi = nocoSDK.client?.contactService.visitorApi
  const handleCreateCompanySettings = useCallback(
    async (organizationId: string) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await visitorApi?.ApiFactory(auth.token).contactV1VisitorPost({ createVisitorRequest: { organizationId } })
      } catch (err) {
        await handleError(err, { setError, tag: 'Create visitor' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, visitorApi]
  )

  return { error, isLoading, handleCreateCompanySettings }
}
