import React, { ReactElement, ReactNode, useMemo } from 'react'
import { Box, BoxProps } from '@material-ui/core'
import { Colors, Typography } from 'src/components/atoms'

export interface CardProps {
  label?: string
  sx?: BoxProps['sx']
  children: ReactNode
  actionComponent?: ReactElement
}

export const Card: React.VFC<CardProps> = ({ label, sx, children, actionComponent }: CardProps) => {
  const isCardHeaderVisbled = useMemo(() => !!(label || actionComponent), [label, actionComponent])

  return (
    <Box
      sx={{
        padding: isCardHeaderVisbled ? '16px 24px 24px' : '24px',
        border: `1px solid ${Colors.background.silver}`,
        borderRadius: '10px',
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 0 1px rgba(0, 0, 0, 0.04)`,
        ...sx
      }}
    >
      {isCardHeaderVisbled && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="8px" height="28px">
          <Box>
            {label && (
              <Typography fontSize="s" fontWeight="bold" lineheight="1">
                {label}
              </Typography>
            )}
          </Box>
          <Box>{actionComponent}</Box>
        </Box>
      )}
      {children}
    </Box>
  )
}
