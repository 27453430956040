import React from 'react'
import { Box } from '@material-ui/core'
import { PopupTemplate } from '..'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import Link from 'next/link'
import Image from 'next/image'
import { useGlobalStyles } from 'src/styles/theme'

export interface InfoList {
  question: string
  answerList: {
    label: string
    link?: string
  }[]
}

export interface ModalAlertShareProps {
  open: boolean
  title: string
  description: string
  infoList: InfoList[]
  image?: string
  onClose: () => void
}

export const ModalAlertShare = ({ open, title, description, infoList, image, onClose }: ModalAlertShareProps) => {
  const globalClasses = useGlobalStyles()

  return (
    <PopupTemplate open={open} title={title} hasClose onClose={onClose} hasCancel cancelButtonLabel="閉じる">
      <Box px="24px" pt="24px" pb="32px">
        <Box fontWeight="bold" mb="21px">
          <Typography fontSize="s" color="inherit">
            {description}
          </Typography>
        </Box>

        {infoList.map((info, index) => (
          <Box key={info.question} mb={infoList.length !== index + 1 && '21px'}>
            <Box fontWeight="bold" mb="13px" display="flex" alignItems="center">
              <DynamicMuiIcon variant="infoOutlined" size="16px" color="action" />

              <Box ml="5px" />

              <Typography fontSize="s" lineheight="1" color="inherit" fontWeight="bold">
                {info.question}
              </Typography>
            </Box>

            {info.answerList.map(answer => (
              <React.Fragment key={answer.label}>
                {answer.link ? (
                  <Link href={answer.link}>
                    <Box color={Colors.accent.keyBlue.default} className={globalClasses.cursorPointer}>
                      <a>
                        <Typography fontSize="s" lineheight="1.7" color="inherit">
                          ・{answer.label}
                        </Typography>
                      </a>
                    </Box>
                  </Link>
                ) : (
                  <Typography fontSize="s" lineheight="1.7" color="inherit">
                    ・{answer.label}
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Box>
        ))}

        {image && (
          <Box mt="16px" height="162px" position="relative" display="flex" justifyContent="center">
            <Image src={image} objectFit="contain" layout="fill" />
          </Box>
        )}
      </Box>
    </PopupTemplate>
  )
}
