/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  DocumentShareForm,
  DocumentShareFormFromJSON,
  DocumentShareFormFromJSONTyped,
  DocumentShareFormToJSON
} from './DocumentShareForm'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface DocumentShare
 */
export interface DocumentShare {
  /**
   *
   * @type {string}
   * @memberof DocumentShare
   */
  id?: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof DocumentShare
   */
  user?: UserForDisplay
  /**
   *
   * @type {Array<DocumentShareForm>}
   * @memberof DocumentShare
   */
  documentShareForms?: Array<DocumentShareForm>
  /**
   *
   * @type {string}
   * @memberof DocumentShare
   */
  publicLinkUlid?: string
  /**
   *
   * @type {string}
   * @memberof DocumentShare
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof DocumentShare
   */
  description?: string | null
  /**
   *
   * @type {boolean}
   * @memberof DocumentShare
   */
  isActive?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentShare
   */
  expiredOn?: string | null
  /**
   *
   * @type {boolean}
   * @memberof DocumentShare
   */
  isFormActive?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DocumentShare
   */
  isAuthCodeActive?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentShare
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof DocumentShare
   */
  updatedAt?: string
}

export function DocumentShareFromJSON(json: any): DocumentShare {
  return DocumentShareFromJSONTyped(json, false)
}

export function DocumentShareFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentShare {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    documentShareForms: !exists(json, 'document_share_forms')
      ? undefined
      : (json['document_share_forms'] as Array<any>).map(DocumentShareFormFromJSON),
    publicLinkUlid: !exists(json, 'public_link_ulid') ? undefined : json['public_link_ulid'],
    url: !exists(json, 'url') ? undefined : json['url'],
    description: !exists(json, 'description') ? undefined : json['description'],
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
    expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
    isFormActive: !exists(json, 'is_form_active') ? undefined : json['is_form_active'],
    isAuthCodeActive: !exists(json, 'is_auth_code_active') ? undefined : json['is_auth_code_active'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function DocumentShareToJSON(value?: DocumentShare | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    user: UserForDisplayToJSON(value.user),
    document_share_forms:
      value.documentShareForms === undefined
        ? undefined
        : (value.documentShareForms as Array<any>).map(DocumentShareFormToJSON),
    public_link_ulid: value.publicLinkUlid,
    url: value.url,
    description: value.description,
    is_active: value.isActive,
    expired_on: value.expiredOn,
    is_form_active: value.isFormActive,
    is_auth_code_active: value.isAuthCodeActive,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
