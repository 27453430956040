/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetJobResponseFromJSON, GetJobsResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1JobApi extends runtime.BaseAPI {
    /**
     * ジョブ一覧を取得する
     * index
     */
    async userV1JobsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetJobsResponseFromJSON(jsonValue));
    }
    /**
     * ジョブ一覧を取得する
     * index
     */
    async userV1JobsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1JobsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ジョブ詳細を取得する
     * show
     */
    async userV1JobsJobIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId', 'Required parameter requestParameters.jobId was null or undefined when calling userV1JobsJobIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/jobs/{job_id}`.replace(`{${'job_id'}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetJobResponseFromJSON(jsonValue));
    }
    /**
     * ジョブ詳細を取得する
     * show
     */
    async userV1JobsJobIdGet(requestParameters, initOverrides) {
        const response = await this.userV1JobsJobIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
