import { Box } from '@material-ui/core'
import { Colors } from '../Colors'
import { Typography } from '../Typography'

export interface TextErrorProps {
  label: string
}

export const TextError = ({ label }: TextErrorProps) => {
  return (
    <Box color={Colors.accent.keyRed.default}>
      <Typography fontSize="s" lineheight="relaxed">
        {label}
      </Typography>
    </Box>
  )
}
