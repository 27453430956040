/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetCompanyFilterResponseData,
  GetCompanyFilterResponseDataFromJSON,
  GetCompanyFilterResponseDataFromJSONTyped,
  GetCompanyFilterResponseDataToJSON
} from './GetCompanyFilterResponseData'

/**
 *
 * @export
 * @interface GetCompanyFilterResponse
 */
export interface GetCompanyFilterResponse {
  /**
   *
   * @type {number}
   * @memberof GetCompanyFilterResponse
   */
  status?: number
  /**
   *
   * @type {GetCompanyFilterResponseData}
   * @memberof GetCompanyFilterResponse
   */
  data?: GetCompanyFilterResponseData
}

export function GetCompanyFilterResponseFromJSON(json: any): GetCompanyFilterResponse {
  return GetCompanyFilterResponseFromJSONTyped(json, false)
}

export function GetCompanyFilterResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCompanyFilterResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetCompanyFilterResponseDataFromJSON(json['data'])
  }
}

export function GetCompanyFilterResponseToJSON(value?: GetCompanyFilterResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetCompanyFilterResponseDataToJSON(value.data)
  }
}
