import React from 'react'
import Autolinker from 'autolinker'
import dompurify from 'dompurify'
const sanitizer = dompurify.sanitize

export interface LinkParserProps {
  text: string
  truncate?: number
  textColor?: string
}

export const LinkParser = ({ text, truncate, textColor }: LinkParserProps) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizer(
          Autolinker.link(text, {
            truncate, // 省略するかどうか
            stripPrefix: false, // https:// or http:// で始まるようにする
            replaceFn: match => {
              const tag = match.buildTag()
              tag.setAttr('style', `color: ${textColor ?? 'inherit'}; word-break: break-all`)

              return tag
            }
          }),
          {
            ALLOWED_ATTR: ['target', 'rel', 'href', 'style']
          }
        )
      }}
    />
  )
}
