import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() =>
  createStyles({
    textColor: {
      color: '#6D7784'
    },
    avatarImg: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    }
  })
)
