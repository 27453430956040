/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function CopySiteRequestFromJSON(json) {
    return CopySiteRequestFromJSONTyped(json, false);
}
export function CopySiteRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        fromId: json['from_id'],
        toId: json['to_id'],
        copyInfo: json['copy_info'],
        copyCoverPhoto: json['copy_cover_photo'],
        copySetting: json['copy_setting'],
        copyShareByLink: json['copy_share_by_link'],
        copyContent: json['copy_content']
    };
}
export function CopySiteRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        from_id: value.fromId,
        to_id: value.toId,
        copy_info: value.copyInfo,
        copy_cover_photo: value.copyCoverPhoto,
        copy_setting: value.copySetting,
        copy_share_by_link: value.copyShareByLink,
        copy_content: value.copyContent
    };
}
