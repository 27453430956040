import React, { useState } from 'react'
import ja from 'date-fns/locale/ja'
import { Calendar } from 'react-date-range'
import { createStyles, makeStyles } from '@material-ui/core'

export const useDatePicker = () => {
  const [date, setDate] = useState<Date | undefined>(new Date())
  return {
    date,
    onChangeDate: setDate
  }
}

const useStyles = makeStyles(() =>
  createStyles({
    datePicker: {
      '& .rdrMonthAndYearWrapper': {
        padding: '0 16px'
      },
      '& .rdrMonth': {
        /* 上書き先でimportantを使用しているため*/
        width: '278px !important',
        padding: '0 18px 18px 18px !important'
      },
      '& .rdrMonth:first-child .rdrMonthName': {
        textAlign: 'center'
      }
    }
  })
)

export type Props = ReturnType<typeof useDatePicker>

export const DatePicker = ({ date, onChangeDate }: Props) => {
  const classes = useStyles()
  return (
    <Calendar
      className={classes.datePicker}
      direction="horizontal"
      months={1}
      locale={ja}
      date={date}
      monthDisplayFormat="yyyy年 MM月"
      onChange={onChangeDate}
    />
  )
}
