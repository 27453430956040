/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetContactShareDocumentShareFormsResponseData,
  GetContactShareDocumentShareFormsResponseDataFromJSON,
  GetContactShareDocumentShareFormsResponseDataFromJSONTyped,
  GetContactShareDocumentShareFormsResponseDataToJSON
} from './GetContactShareDocumentShareFormsResponseData'

/**
 *
 * @export
 * @interface GetContactShareDocumentShareFormsResponse
 */
export interface GetContactShareDocumentShareFormsResponse {
  /**
   *
   * @type {number}
   * @memberof GetContactShareDocumentShareFormsResponse
   */
  status?: number
  /**
   *
   * @type {GetContactShareDocumentShareFormsResponseData}
   * @memberof GetContactShareDocumentShareFormsResponse
   */
  data?: GetContactShareDocumentShareFormsResponseData
}

export function GetContactShareDocumentShareFormsResponseFromJSON(
  json: any
): GetContactShareDocumentShareFormsResponse {
  return GetContactShareDocumentShareFormsResponseFromJSONTyped(json, false)
}

export function GetContactShareDocumentShareFormsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactShareDocumentShareFormsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetContactShareDocumentShareFormsResponseDataFromJSON(json['data'])
  }
}

export function GetContactShareDocumentShareFormsResponseToJSON(
  value?: GetContactShareDocumentShareFormsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetContactShareDocumentShareFormsResponseDataToJSON(value.data)
  }
}
