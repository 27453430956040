/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { RequestContactSettingFieldValueValuesInnerFromJSON, RequestContactSettingFieldValueValuesInnerToJSON } from './RequestContactSettingFieldValueValuesInner';
export function RequestContactSettingFieldValueFromJSON(json) {
    return RequestContactSettingFieldValueFromJSONTyped(json, false);
}
export function RequestContactSettingFieldValueFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contactSettingId: !exists(json, 'contact_setting_id') ? undefined : json['contact_setting_id'],
        contactSettingFieldId: !exists(json, 'contact_setting_field_id') ? undefined : json['contact_setting_field_id'],
        values: !exists(json, 'values')
            ? undefined
            : json['values'] === null
                ? null
                : json['values'].map(RequestContactSettingFieldValueValuesInnerFromJSON)
    };
}
export function RequestContactSettingFieldValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact_setting_id: value.contactSettingId,
        contact_setting_field_id: value.contactSettingFieldId,
        values: value.values === undefined
            ? undefined
            : value.values === null
                ? null
                : value.values.map(RequestContactSettingFieldValueValuesInnerToJSON)
    };
}
