/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentContractBasicInfo,
  OrganizationPaymentContractBasicInfoFromJSON,
  OrganizationPaymentContractBasicInfoFromJSONTyped,
  OrganizationPaymentContractBasicInfoToJSON
} from './OrganizationPaymentContractBasicInfo'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentApplyPlanFreeRequest
 */
export interface UpdateOrganizationPaymentApplyPlanFreeRequest {
  /**
   *
   * @type {OrganizationPaymentContractBasicInfo}
   * @memberof UpdateOrganizationPaymentApplyPlanFreeRequest
   */
  contractBasicInfo: OrganizationPaymentContractBasicInfo
}

export function UpdateOrganizationPaymentApplyPlanFreeRequestFromJSON(
  json: any
): UpdateOrganizationPaymentApplyPlanFreeRequest {
  return UpdateOrganizationPaymentApplyPlanFreeRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentApplyPlanFreeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentApplyPlanFreeRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contractBasicInfo: OrganizationPaymentContractBasicInfoFromJSON(json['contract_basic_info'])
  }
}

export function UpdateOrganizationPaymentApplyPlanFreeRequestToJSON(
  value?: UpdateOrganizationPaymentApplyPlanFreeRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contract_basic_info: OrganizationPaymentContractBasicInfoToJSON(value.contractBasicInfo)
  }
}
