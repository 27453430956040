/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { UploadingFile, UploadingFileFromJSON, UploadingFileFromJSONTyped, UploadingFileToJSON } from './UploadingFile'

/**
 *
 * @export
 * @interface UpdateMeAvatarRequestUser
 */
export interface UpdateMeAvatarRequestUser {
  /**
   *
   * @type {UploadingFile}
   * @memberof UpdateMeAvatarRequestUser
   */
  avatar?: UploadingFile
}

export function UpdateMeAvatarRequestUserFromJSON(json: any): UpdateMeAvatarRequestUser {
  return UpdateMeAvatarRequestUserFromJSONTyped(json, false)
}

export function UpdateMeAvatarRequestUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateMeAvatarRequestUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    avatar: !exists(json, 'avatar') ? undefined : UploadingFileFromJSON(json['avatar'])
  }
}

export function UpdateMeAvatarRequestUserToJSON(value?: UpdateMeAvatarRequestUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    avatar: UploadingFileToJSON(value.avatar)
  }
}
