import React, { useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Box, Modal, ButtonBase, Slide, Input } from '@material-ui/core'
import { Colors, Pallete } from '../../atoms/Colors'
import { useGlobalStyles } from 'src/styles/theme'
import clsx from 'clsx'
import { Button, DynamicMuiIcon, SvgIcon, Tooltip, Typography } from '../../atoms'
import {
  ChartViewsAndAverageViewingTimePerPage,
  DataItem
} from 'src/components/molecules/ChartViewsAndAverageViewingTimePerPage'
import {
  useGetDocument,
  useListDocumentViewHistoriesGraph,
  useListDocumentViewHistoriesSessions
} from 'src/fixtures/modules/documents/hooks'
import { convertMs, DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { ContactForDisplay, MaterialableViewHistorySession } from '@noco/http-client/lib/noco'
import { useTablePagination } from 'src/components/organisms'
import { useGetContact } from 'src/fixtures/modules/contact/hooks'

interface ModalDocumentViewHistoriesProps {
  open: boolean
  documentId: string
  contact?: ContactForDisplay
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiList-padding': {
        padding: 0
      },
      '& .MuiListItem-secondaryAction': {
        paddingLeft: 4,
        borderBottom: `1px solid ${Colors.background.gray}`
      },
      '& .MuiListItemSecondaryAction-root': {
        right: 4
      }
    },
    selectedItem: {
      '&.Mui-selected': {
        background: Colors.selected.background
      }
    },
    itemText: {
      color: '#6D7784'
    },
    selectedItemText: {
      color: Colors.accent.keyPurple.default,
      fontWeight: 'bold'
    },
    base: {
      height: '20px',
      backgroundColor: '#fff'
    },
    per: {
      '& .MuiSelect-root': {
        fontSize: '12px'
      }
    },
    page: {
      '& .MuiInputBase-input': {
        fontSize: '12px',
        width: '12px'
      }
    }
  })
)

export const ModalDocumentViewHistories = ({
  open,
  documentId,
  contact: contactForDisplay,
  onClose
}: ModalDocumentViewHistoriesProps) => {
  const classes = useStyles()
  const { page, per, totalPages, setTotalPages, changePage } = useTablePagination('document-history')
  const contactId = contactForDisplay?.id
  const { data: resContact } = useGetContact(contactId)
  const contact = resContact?.contact
  const [selectedSession, setSession] = useState<MaterialableViewHistorySession>()
  const { data } = useListDocumentViewHistoriesGraph(documentId, contactId, selectedSession?.visitorSessionId)
  const { data: document } = useGetDocument(documentId)

  const { data: sessionsData } = useListDocumentViewHistoriesSessions(documentId, page, per, contactId)
  const graphData: DataItem[] = (data?.graphObjects ?? []).map(item => ({
    name: `${item.numberOfPage}p`,
    vt: item.averageElapsedSecond ?? 0,
    pv: item.countOfViews ?? 0
  }))
  const globalClasses = useGlobalStyles()

  const onNext = () => {
    if (sessionsData?.pageInfo?.currentPage && sessionsData?.pageInfo?.totalPages) {
      if (sessionsData?.pageInfo?.currentPage < sessionsData?.pageInfo?.totalPages) {
        changePage(page + 1)
      }
    }
  }
  const onPrev = () => {
    if (sessionsData?.pageInfo?.currentPage && sessionsData?.pageInfo?.currentPage > 1) {
      changePage(page - 1)
    }
  }
  const onCloseModal = () => {
    onClose()
    setSession(undefined)
  }

  useEffect(() => {
    if (sessionsData?.pageInfo?.totalPages && sessionsData.viewHistorySessions) {
      setTotalPages(sessionsData?.pageInfo.totalPages)
      sessionsData.viewHistorySessions[0] && setSession(sessionsData.viewHistorySessions[0])
    }
  }, [sessionsData, setTotalPages])

  return (
    <Modal open={open} onClose={onCloseModal} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box display="flex" flexDirection="column">
          <Box display="flex" bgcolor={Pallete.functional.background.default} borderRadius="10px 10px 0 0">
            <Box width={330} py="16px" px="22px">
              <Box width="fit-content">
                <Tooltip
                  content={
                    '顧客の資料閲覧の履歴を表示します。 最後の閲覧時刻から30分経過後に、閲覧を再開すると、新しい閲覧履歴として表示されます。'
                  }
                >
                  <Typography variant="h3">閲覧履歴</Typography>
                </Tooltip>
              </Box>

              <Box mt="16px" mb="40px" display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" width="calc(100% - 100px)" alignItems="center">
                  <Box display="flex" color={Colors.base.middleGray} mr="8px" alignItems="center">
                    <DynamicMuiIcon variant="account" size="20px" color="inherit" />
                  </Box>
                  <Typography variant="subtitle1">{contact?.displayName}</Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <Box display="flex" mr="8px">
                    <Typography
                      variant="h6"
                      style={{
                        color: '#6D7784'
                      }}
                    >
                      閲覧回数
                    </Typography>
                  </Box>
                  <Typography variant="h5">{sessionsData?.pageInfo?.totalCount ?? 0}</Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" pb="8px" justifyContent="space-between" borderBottom="1px solid #DADCE0">
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6D7784'
                    }}
                  >
                    閲覧日時
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: '#6D7784'
                    }}
                  >
                    閲覧時間
                  </Typography>
                </Box>
                <Box height={400} overflow="scroll">
                  <List>
                    {(sessionsData?.viewHistorySessions ?? []).map(item => {
                      const isSelected = item.visitorSessionId === selectedSession?.visitorSessionId
                      return (
                        <ListItem
                          className={isSelected ? classes.selectedItem : undefined}
                          button
                          selected={isSelected}
                          key={item.visitorSessionId}
                          onClick={() => setSession(item)}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                className={isSelected ? classes.selectedItemText : undefined}
                                variant="subtitle1"
                              >
                                {dateFormat(dateStringToMilliseconds(item.startedAt), DateFormat.DATE_WITH_TIME)}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Typography
                              className={isSelected ? classes.selectedItemText : classes.itemText}
                              variant="h5"
                            >
                              {convertMs(item.totalElapsedSecond)}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" px="12px">
                  <Box
                    px="9px"
                    display="flex"
                    height="20px"
                    alignItems="center"
                    className={globalClasses.cursorPointer}
                    onClick={onPrev}
                  >
                    <SvgIcon variant="leftArrow" color={Colors.base.middleGray} size="12px" />
                  </Box>
                  <Input
                    className={clsx(classes.base, classes.page)}
                    value={page === 0 ? 1 : page}
                    name="pageNum"
                    onChange={e => {
                      changePage(e.target.value as unknown as number)
                    }}
                  />
                  <Box width="4px" />
                  <Typography fontSize="xs" lineheight="12px">
                    / {totalPages}ページ
                  </Typography>
                  <Box
                    px="9px"
                    display="flex"
                    height="20px"
                    alignItems="center"
                    className={globalClasses.cursorPointer}
                    onClick={onNext}
                  >
                    <SvgIcon variant="rightArrow" color={Colors.base.middleGray} size="12px" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              bgcolor={Pallete.functional.background.default}
              borderRadius="0 10px 0 0"
              borderLeft={`1px solid ${Colors.background.silver}`}
            >
              <Box
                px="24px"
                py="16px"
                borderBottom={`1px solid ${Colors.background.silver}`}
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex">
                  <Box mr="12px">
                    <Tooltip
                      content={
                        '顧客が閲覧した資料のページごとに、閲覧数と閲覧時間をグラフで表示します。顧客の注目もしくは関心が高いページがわかります。'
                      }
                    >
                      <Typography variant="h3">ページ別 閲覧数・閲覧時間</Typography>
                    </Tooltip>
                  </Box>

                  {selectedSession && (
                    <Typography>
                      {dateFormat(dateStringToMilliseconds(selectedSession.startedAt), DateFormat.DATE_WITH_TIME)}
                    </Typography>
                  )}
                </Box>
                <ButtonBase onClick={onCloseModal}>
                  <DynamicMuiIcon variant="close" color="action" size="28px" />
                </ButtonBase>
              </Box>
              <Box display="flex" p="24px">
                <ChartViewsAndAverageViewingTimePerPage data={graphData} document={document} />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            py="12px"
            bgcolor={Colors.background.lightGray}
            borderRadius="0 0 10px 10px"
            boxShadow={`inset 0px 1px 0px ${Colors.background.silver}`}
          >
            <Box display="flex" mr="24px">
              <Button kind="neutral" variant="outlined" title="閉じる" onClick={onCloseModal} />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
