import React from 'react'
import { LayoutCard } from 'src/components/commons/LayoutCard'
import { SignIn } from 'src/components/organisms'
import Image from 'next/image'
import { useMediaQuery } from '@material-ui/core'
import theme from 'src/styles/theme'

export const PageSignIn = ({ subdomain }: { subdomain?: string }) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <LayoutCard
      direction="rtl"
      maxWidth={640}
      justify="center"
      isSP={isDownSm}
      innerCardProps={{
        minHeight: !isDownSm && '85vh'
      }}
      innerCardComponent={<SignIn subdomain={subdomain} />}
      outerCardComponent={<Image src="/images/signin.svg" width={560} height={367} alt="" />}
    />
  )
}
