import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Material = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99935 3.33333C8.16602 3.33333 6.68268 4.83333 6.68268 6.66666L6.66602 33.3333C6.66602 35.1667 8.14935 36.6667 9.98268 36.6667H29.9993C31.8327 36.6667 33.3327 35.1667 33.3327 33.3333V14.7167C33.3327 13.8333 32.9827 12.9833 32.3493 12.3667L24.2993 4.31666C23.6827 3.68333 22.8327 3.33333 21.9493 3.33333H9.99935ZM21.666 13.3333V5.83333L30.8327 15H23.3327C22.416 15 21.666 14.25 21.666 13.3333Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84152C1.50202 1.98323 2.89359 1.98323 3.75188 2.84152L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.94968C-0.214574 5.09139 -0.214574 3.69982 0.643721 2.84152Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default Material
