import { useState, useEffect } from 'react'

export const useVirtualProgress = () => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>

    if (progress <= 90) {
      timer = setTimeout(() => setProgress(v => v + 0.2), 32)
    }

    return () => clearTimeout(timer)
  }, [progress])

  return { progress }
}
