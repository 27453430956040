import React, { useCallback, useState } from 'react'
import { Box } from '@material-ui/core'
import { Checkbox, OptionListType, Typography } from '..'

// チェックボックス 一行
export interface CheckBoxRowProps {
  option: OptionListType
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
}

export const CheckBoxRow = ({ option, onChange, isChecked }: CheckBoxRowProps) => {
  const [checked, setChecked] = useState(isChecked)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(prev => !prev)
      onChange(event)
    },
    [onChange]
  )

  return (
    <Box component="label" display="flex" alignItems="center" marginLeft="-9px">
      <Checkbox value={option.value} checked={checked} onChange={handleChange} />
      <Typography fontSize="s">{option.label}</Typography>
    </Box>
  )
}
