import { Box, createStyles, LinearProgress, makeStyles, Modal, Slide, Typography } from '@material-ui/core'
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { SlideForm } from 'src/components/organisms'
import { Accept, useDropzone } from 'react-dropzone'
import { Colors, DynamicMuiIcon, SvgIcon } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { ModalAlert } from '..'
import { useCreateDocument, useUpdateDocumentSlide } from 'src/fixtures/modules/documents/hooks'
import { pushUserActionDataLayer } from 'src/utils/gtm'

const MAX_FILE_SIZE = 104857600
const FILE_TYPE: Accept = { 'application/pdf': ['.pdf'] }

export interface DocumentData {
  file: File
  versionName: string
}

export interface ModalDocumentRegisterProps {
  documentId?: string
  open: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
)

export const ModalDocumentRegister = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  documentId
}: ModalDocumentRegisterProps) => {
  const [file, setFile] = useState<File | null>(null)
  // const [versionName, setVersionName] = useState<string>('')
  const [isOpenAlert, setIsOpenAlert] = useState<boolean>(false)
  const { handleCreateDocument, progress, error, isFirstCreatedDocument } = useCreateDocument()
  const { handleUpdateDocumentSlide, progress: progressUpdate, error: updateError } = useUpdateDocumentSlide()
  const progressRef = useRef<HTMLDivElement>(null)

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const checkCanSubmit = useCallback(() => {
    !file || (file && file.type !== 'application/pdf' && setIsOpenAlert(true))
  }, [file])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      checkCanSubmit()
      setFile(acceptedFiles[0])
    },
    [checkCanSubmit]
  )
  // TODO: 正式版以降 verison 管理の時に対応
  // const handleChangeVersionName = useCallback((value: string) => {
  //   setVersionName(value)
  // }, [])

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop: handleDrop,
    accept: FILE_TYPE,
    maxSize: MAX_FILE_SIZE
  })

  // TODO: hooks に変える(元々の対応を変える)
  const handleConfirm = useCallback(async () => {
    if (progressRef?.current) progressRef.current.style.display = 'block'
    setIsSubmitting(true)
    setTimeout(async () => {
      try {
        if (file && !isSubmitting) {
          if (documentId) {
            await handleUpdateDocumentSlide(documentId, file)
          } else {
            await handleCreateDocument(file)
          }
        }
        onConfirm()
        setFile(null)
      } catch (err) {
        console.error(err)
      } finally {
        setIsSubmitting(false)
        if (progressRef?.current) progressRef.current.style.display = 'none'
        if (inputRef?.current?.value) inputRef.current.value = ''
      }
    }, 0)
  }, [documentId, file, handleCreateDocument, handleUpdateDocumentSlide, inputRef, isSubmitting, onConfirm])

  useEffect(() => {
    if (!open) {
      setIsSubmitting(false)
      setFile(null)
    }
  }, [open])

  useEffect(() => {
    if (isFirstCreatedDocument) {
      pushUserActionDataLayer('document', 'create', 'first_create_document')
    }
  }, [isFirstCreatedDocument])
  return (
    <>
      <Modal open={open} onClose={onClose} className={classes.modal}>
        <Slide direction="left" in={open}>
          <Box width="376px" height={1}>
            <SlideForm
              headerTitle={documentId ? '新しい資料に差し替える' : '資料を登録'}
              onClose={onClose}
              onCancel={onCancel}
              onConfirm={handleConfirm}
              buttonTitle="登録"
              hideSettings
              buttonDisabled={!file}
              isSubmitting={isSubmitting}
            >
              <Box p="24px">
                <Box
                  {...getRootProps()}
                  bgcolor={Colors.background.lightGray}
                  borderRadius="4px"
                  py="108px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  color={Colors.base.black}
                  className={globalClasses.cursorPointer}
                >
                  <input {...getInputProps()} />
                  {file ? (
                    <>
                      <Box color={Colors.base.middleGray}>
                        <DynamicMuiIcon variant="pdf" color="inherit" size="34px" />
                      </Box>
                      <Box height="20px" />
                      <Typography variant="subtitle1">{file.name}</Typography>
                      <Box width="100%">
                        <div ref={progressRef} style={{ display: 'none' }}>
                          <LinearProgress variant="determinate" value={progress || progressUpdate} />
                        </div>
                      </Box>
                      {(error || updateError) && (
                        <Box display="flex" alignItems="center" color={Colors.accent.keyRed.default} ml="12px">
                          <DynamicMuiIcon variant="errorOutlineOutlined" size="16px" />
                          <Box mr="6px" />
                          <Typography variant="body2">{error?.message || updateError?.message}</Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      <SvgIcon variant="uploadFile" color={Colors.accent.keyPurple.default} />
                      <Box height="20px" />
                      <Box color={Colors.accent.keyPurple.default}>
                        <Typography variant="subtitle1">
                          PDFファイルをドラッグ
                          <br />
                          またはファイルを選択してください
                          <br />
                          （最大100MBまで）
                        </Typography>
                      </Box>
                      <Box margin="24px 24px 0">
                        <Typography variant="subtitle1">
                          ※特殊なフォントや動画などを利用したPDFファイルは正常に表示できない場合があります
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                {/* {file && (
                  <Box pt="18px" mb="50px">
                    <Typography variant="h6">バージョン名</Typography>
                    <Box height="6px" />
                    <Input
                      name="versionName"
                      placeholder="ファイルの変更点を記載"
                      onChange={e => handleChangeVersionName(e.target.value as string)}
                      fullWidth
                    />
                  </Box>
                )} */}
              </Box>
            </SlideForm>
          </Box>
        </Slide>
      </Modal>
      <ModalAlert message="PDFファイルを選択してください" open={isOpenAlert} onClose={() => setIsOpenAlert(false)} />
    </>
  )
}
