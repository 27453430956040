/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SiteSectionDocumentFromJSON, SiteSectionDocumentToJSON } from './SiteSectionDocument';
export function SiteSectionFromJSON(json) {
    return SiteSectionFromJSONTyped(json, false);
}
export function SiteSectionFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        sort: !exists(json, 'sort') ? undefined : json['sort'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        siteSectionDocuments: !exists(json, 'site_section_documents')
            ? undefined
            : json['site_section_documents'].map(SiteSectionDocumentFromJSON)
    };
}
export function SiteSectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        sort: value.sort,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        site_section_documents: value.siteSectionDocuments === undefined
            ? undefined
            : value.siteSectionDocuments.map(SiteSectionDocumentToJSON)
    };
}
