/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateSiteSectionRequestSiteSection,
  UpdateSiteSectionRequestSiteSectionFromJSON,
  UpdateSiteSectionRequestSiteSectionFromJSONTyped,
  UpdateSiteSectionRequestSiteSectionToJSON
} from './UpdateSiteSectionRequestSiteSection'

/**
 *
 * @export
 * @interface UpdateContactListRequest
 */
export interface UpdateContactListRequest {
  /**
   *
   * @type {UpdateSiteSectionRequestSiteSection}
   * @memberof UpdateContactListRequest
   */
  contactList?: UpdateSiteSectionRequestSiteSection
}

export function UpdateContactListRequestFromJSON(json: any): UpdateContactListRequest {
  return UpdateContactListRequestFromJSONTyped(json, false)
}

export function UpdateContactListRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateContactListRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactList: !exists(json, 'contact_list')
      ? undefined
      : UpdateSiteSectionRequestSiteSectionFromJSON(json['contact_list'])
  }
}

export function UpdateContactListRequestToJSON(value?: UpdateContactListRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_list: UpdateSiteSectionRequestSiteSectionToJSON(value.contactList)
  }
}
