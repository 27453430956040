import React, { ChangeEvent, Component, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import styled from '@emotion/styled'
import { Box, createStyles, makeStyles, Input, BoxProps } from '@material-ui/core'
import { Button, Colors, DynamicMuiIcon, SvgIcon, Toaster, Tooltip, Typography } from 'src/components/atoms'
import { ChatMessageItem } from 'src/components/molecules/ChatMessageItem'
import {
  useListUserContactChatMessages,
  useCreateUserChatMessage,
  useListContactMaterials,
  useGetContact
} from 'src/fixtures/modules/contact/hooks'
import { useSubscribeChatChannel } from 'src/fixtures/modules/webSocket'
import { Message } from 'src/fixtures/types/chat-channel'
import { useGlobalStyles } from 'src/styles/theme'
import { Alert } from 'src/components/atoms/Alert'
import {
  ChatMessage,
  ContactMaterial,
  CreateContactChatMessageRequestAttachableObjectsInner,
  EnumChatSummaryRepliedStatus
} from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { useChatSummariesReplied } from 'src/fixtures/modules/chatSummary/hooks'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { LocalStorageMaterialDraft } from 'src/fixtures/utils/localStorageMaterialDraft'
import { useConfirmDiscard } from 'src/fixtures/utils/url'
import { SelectedMaterial, SelectSharableMaterial } from 'src/components/organisms'
import { AttachmentCard } from 'src/components/molecules'
import {
  useCreateChatMessagesAttachmentFile,
  useGetChatMessagesAttachmentFileLazy
} from 'src/fixtures/modules/chatMessagesAttachmentFile/hooks'
import { UploadingCard } from 'src/components/molecules/AttachmentCard/UploadingCard'
import { isImageType } from 'src/components/molecules/AttachmentCard/utils'
import { FileUploadTask } from 'src/fixtures/modules/chatMessagesAttachmentFile/type'
import { ChatRestrictionBanner } from 'src/components/molecules/ChatRestrictionBanner '

const useStyles = makeStyles(() =>
  createStyles({
    messageRoom: {
      background: Colors.background.gray,
      overflow: 'hidden auto',
      height: '100%',
      scrollBehavior: 'smooth',
      flex: 1
    },
    messageInput: {
      display: 'flex',
      overflowY: 'auto',
      width: '100%',
      height: '100%',
      alignItems: 'flex-start',
      flex: 1,
      marginRight: 8,
      border: 'none',
      '& .MuiInput-inputMultiline': { padding: 0 }
    }
  })
)

export interface MessageRoomProps {
  contactId: string
  chatMessages: ChatMessage[]
  contactMaterials: ContactMaterial[]
  onOpenUpdateExpiredOnModal?: (id: string) => void
  onViewMoreMaterials?: () => void
  onClickShare?: () => void
  width?: string | number
  chatSummaryId?: string
  onChangeRepliedStatus?: () => void
  onRefreshCount?: () => void
  onChangeMessage?: (message: string) => void
}

/* eslint-disable react/display-name */
export const MessageRoom = forwardRef<Component<BoxProps>, MessageRoomProps>(
  (
    {
      contactId,
      chatMessages,
      contactMaterials,
      onOpenUpdateExpiredOnModal,
      onViewMoreMaterials,
      onClickShare,
      width,
      chatSummaryId,
      onChangeRepliedStatus,
      onRefreshCount,
      onChangeMessage
    }: MessageRoomProps,
    ref
  ) => {
    const classes = useStyles()
    const globalClasses = useGlobalStyles()
    const inputFileRef = useRef<HTMLInputElement>(null)
    const initLoadDraft = useRef(false)
    const [message, changeMessage] = useState('')
    const [errorMessages, seteErorMessages] = useState<Message[]>([])
    const { data: contact, mutate: contactReload } = useGetContact(contactId)
    const { data: me } = useGetMe()
    const { data } = useListContactMaterials(contactId)
    const { mutate, data: chatRes } = useListUserContactChatMessages(contactId)

    const { handleCreateChatMessage, isLoading } = useCreateUserChatMessage(contactId)
    const [showConfirm, setShowConfirm] = useState(false)
    useConfirmDiscard(showConfirm)
    const { fileUploadTaskList, setFileUploadTaskList, handleCreateChatMessagesAttachmentFile } =
      useCreateChatMessagesAttachmentFile()
    const { handleGetChatMessagesAttachmentFile } = useGetChatMessagesAttachmentFileLazy()
    const boxWidth = (ref as any)?.current?.clientWidth
    const localStorageMaterialDraft = useMemo(() => {
      return typeof window !== 'undefined' && me ? new LocalStorageMaterialDraft(me?.user?.id!) : undefined
    }, [me])

    const repliedStatus = contact?.contact?.chatSummary?.repliedStatus

    const attachableObjects: CreateContactChatMessageRequestAttachableObjectsInner[] = useMemo(() => {
      if (initLoadDraft.current) {
        localStorageMaterialDraft?.saveContactChatDraftFile(
          me?.user?.id!,
          contactId,
          fileUploadTaskList.filter(task => task.status === 'success')
        )
      }

      return fileUploadTaskList
        .filter(task => task.status === 'success')
        .map((task, i) => {
          const attachmentIdType = ['image', 'file'].includes(task.type) ? 'file_id' : `${task.type}_id`
          return {
            id: task.id,
            type: attachmentIdType,
            sortNumber: i
          } as CreateContactChatMessageRequestAttachableObjectsInner
        })
    }, [fileUploadTaskList, contactId, localStorageMaterialDraft, me])

    useEffect(() => {
      const fn = async () => {
        if (contactId && !initLoadDraft.current && me?.user?.id) {
          const contactChatDraft = localStorageMaterialDraft?.getContactChatDraftByContactId(me.user.id, contactId)
          const fileUploadTaskList: FileUploadTask[] = await Promise.all(
            contactChatDraft?.fileUploadTaskList?.map(async task => {
              if (task.type === 'site' || task.type === 'document') {
                return task
              }

              // ファイル系は再取得する
              const file = await handleGetChatMessagesAttachmentFile(task.id)
              return {
                ...task,
                file,
                status: 'success'
              } as FileUploadTask
            }) ?? []
          )

          changeMessage(contactChatDraft?.message ?? '')
          setFileUploadTaskList(fileUploadTaskList)
          initLoadDraft.current = true
        }
      }

      fn()
    }, [contactId, localStorageMaterialDraft, me, setFileUploadTaskList, handleGetChatMessagesAttachmentFile])

    const { handleUpdateChatSummariesReplied } = useChatSummariesReplied({})
    useSubscribeChatChannel(
      'User',
      () => {
        // Note: Messageを受信したため、メッセージを再取得する
        mutate()
      },
      message => {
        // Note: メッセージの送信エラー
        seteErorMessages([...errorMessages, message])
      },
      contactId,
      false,
      null
    )

    const onCreateMessage = async () => {
      await handleCreateChatMessage(message, attachableObjects)
      changeMessage('')
      contactReload()
      onRefreshCount?.()
      setFileUploadTaskList(fileUploadTaskList.filter(task => task.status !== 'success'))
      localStorageMaterialDraft?.removeContactChatDraftByContactId(me?.user?.id!, contactId)
      const mutableRef = ref as React.MutableRefObject<React.Component<BoxProps, {}, any> | null>
      if (mutableRef?.current) {
        // NOTE: scrollの関数が生えていないのでキャストする
        const devRef: HTMLDivElement = mutableRef?.current as unknown as HTMLDivElement
        devRef.scrollTop = devRef.scrollHeight
      }

      setShowConfirm(false)
    }

    const handleChangeMessage = useCallback(
      ({ target: { value } }) => {
        changeMessage(value)
        setShowConfirm(true)
        localStorageMaterialDraft?.saveContactChatDraft(me?.user?.id!, contactId, value)
      },
      [contactId, localStorageMaterialDraft, me]
    )

    const handleChangeToNoReplyRequired = useCallback(() => {
      if (chatSummaryId) {
        handleUpdateChatSummariesReplied([chatSummaryId], EnumChatSummaryRepliedStatus.NoReplyRequired).then(() => {
          onChangeRepliedStatus?.()
        })
      }
    }, [chatSummaryId, handleUpdateChatSummariesReplied, onChangeRepliedStatus])

    const handleInputFileClick = useCallback(() => {
      inputFileRef.current?.click()
    }, [])

    const validateAttachmentFileCount = useCallback(
      (upcomingAttachmentFileCount: number = 0): boolean => {
        const attachableFileCount = 6
        if (fileUploadTaskList.length + upcomingAttachmentFileCount > attachableFileCount) {
          Toaster.error(`一度に添付できるファイルは${attachableFileCount}つまでです`)
          return false
        }
        return true
      },
      [fileUploadTaskList.length]
    )

    const handleInputFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const files: FileList | undefined = e.target.files || undefined
      if (!files || files.length === 0) {
        e.target.value = ''
        return
      }
      if (!validateAttachmentFileCount(files.length)) return

      const newFileUploadTaskList: FileUploadTask[] = Array.from(files).map(file => {
        return {
          id: uuidv4(),
          type: isImageType(file.name) ? 'image' : 'file',
          file,
          status: 'pending',
          attachmentFile: {
            title: file.name
          }
        }
      })

      setFileUploadTaskList(fileUploadTaskList.concat(newFileUploadTaskList))
      e.target.value = ''
    }

    const handleSelectMaterial = useCallback(
      (material: SelectedMaterial) => {
        if (!validateAttachmentFileCount(1)) return

        const newFileUploadTask: FileUploadTask = {
          id: material.id,
          type: material.kind,
          status: 'success',
          attachmentFile: {
            title: material.title,
            thumbnailImageUrl: material.thumbnailUrl
          }
        }
        const sameTask = fileUploadTaskList.find(
          task => task.id === newFileUploadTask.id && task.type === newFileUploadTask.type
        )
        if (sameTask) return

        setFileUploadTaskList([...fileUploadTaskList, newFileUploadTask])
      },
      [fileUploadTaskList, setFileUploadTaskList, validateAttachmentFileCount]
    )

    const handleDeleteFileUploadTask = useCallback(
      (fileUploadTask: FileUploadTask) => {
        setFileUploadTaskList(
          fileUploadTaskList.filter(task => !(task.id === fileUploadTask.id && task.type === fileUploadTask.type))
        )
      },
      [fileUploadTaskList, setFileUploadTaskList]
    )

    useEffect(() => {
      onChangeMessage?.(message)
    }, [message, onChangeMessage])

    useEffect(() => {
      fileUploadTaskList.forEach(task => {
        if (task.status === 'pending' && task.file) {
          const taskIndex = fileUploadTaskList.findIndex(t => t.id === task.id && t.type === task.type)
          if (taskIndex === -1) return

          // NOTE: FileUploadTask の status を inProgress に変更
          const convFileUploadTask: FileUploadTask = { ...task, status: 'inProgress' }
          fileUploadTaskList.splice(taskIndex, 1, convFileUploadTask)
          setFileUploadTaskList([...fileUploadTaskList])

          handleCreateChatMessagesAttachmentFile(task.id, task.type, { file: task.file })
        }
      })
    }, [handleCreateChatMessagesAttachmentFile, fileUploadTaskList, setFileUploadTaskList])

    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {chatRes?.hiddenOldChatMessages && <ChatRestrictionBanner size={!width ? 'small' : 'medium'} />}
        <StyledBox
          ref={ref}
          flexGrow={1}
          p={4}
          pb={30}
          minWidth="434px"
          display="flex"
          flexDirection="column"
          className={classes.messageRoom}
        >
          {data?.contactMaterials?.length === 0 ? (
            <Box height={1} display="flex" alignItems="center" justifyContent="center">
              <Box maxWidth="300px">
                <Typography variant="h2" fontSize="xl" lineheight="default">
                  お客様に資料を共有して
                  <br />
                  チャット営業をはじめよう！
                </Typography>
                <Box mt="24px" />
                <Typography fontSize="s" lineheight="relax">
                  資料を共有すると、お客様にお渡しした資料にチャットが表示され、メッセージを送れるようになります。チャットを使って、営業をはじめましょう
                </Typography>
                <Box mt="24px" />
                <Button
                  title="お客様へ資料を共有"
                  width="184px"
                  startIcon={<SvgIcon variant="share" size="16px" />}
                  onClick={onClickShare}
                />
              </Box>
              <Box mr="24px" />
              <Box width="380px">
                <img src="/images/contacts/empty_chat.svg" className={globalClasses.imgContain} />
              </Box>
            </Box>
          ) : (
            <>
              {chatMessages.map(item => (
                <Box key={item.id} pb={6}>
                  <ChatMessageItem item={item} formDocumentName={item.materialable?.title} width={width} />
                </Box>
              ))}

              {chatMessages.length > 0 && chatSummaryId && repliedStatus === EnumChatSummaryRepliedStatus.Unresponsive && (
                <>
                  <Box pl="40px" mt="-16px">
                    <Button
                      title={'返信不要にする'}
                      variant="outlined"
                      size="small"
                      width="144px"
                      disabled={false}
                      startIcon={<DynamicMuiIcon size={14} variant="doDisturbAlt" />}
                      onClick={() => handleChangeToNoReplyRequired()}
                    />
                  </Box>
                  <Box width="100%" height="100px" flexShrink={0}></Box>
                </>
              )}
            </>
          )}
          {contactMaterials.length < 3 &&
            onOpenUpdateExpiredOnModal &&
            contactMaterials.map(item => (
              <Box mb={3} key={item.materialableId}>
                <Alert
                  status="warning"
                  text={`${
                    item.expiredOn ? dateFormat(dateStringToMilliseconds(item.expiredOn), DateFormat.DATE_WITH_DAY) : ''
                  } ${item.displayName}の閲覧期限が切れました`}
                  actionButton={
                    <Button
                      kind="primary"
                      variant="outlined"
                      title="閲覧期限を変更"
                      onClick={() => onOpenUpdateExpiredOnModal(String(item.materialableId))}
                    />
                  }
                />
              </Box>
            ))}
          {contactMaterials.length > 3 && (
            <Box mb={3}>
              <Alert
                status="warning"
                text="閲覧期限の切れている資料が複数あります"
                actionButton={
                  <Button kind="primary" variant="outlined" title="閲覧履歴を確認" onClick={onViewMoreMaterials} />
                }
              />
            </Box>
          )}
        </StyledBox>

        {(data?.contactMaterials ?? []).length > 0 && (
          <Box
            display="flex"
            width="100%"
            bgcolor={Colors.background.gray}
            p="10px"
            height="auto"
            maxHeight="60%"
            overflow="hidden"
          >
            <Box
              sx={{
                border: `1px solid ${Colors.background.silver}`,
                bgcolor: Colors.functional.background.default,
                borderRadius: '4px',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  p: '10px 12px',
                  borderBottom: `1px solid ${Colors.background.silver}`,
                  display: 'flex',
                  height: '100%',
                  overflow: 'hidden',
                  flex: '0 1 auto'
                }}
              >
                <Input
                  value={message}
                  placeholder="メッセージを入力してください"
                  className={classes.messageInput}
                  multiline
                  onChange={handleChangeMessage}
                />
                {fileUploadTaskList.length > 0 && (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, 322px)',
                      gridGap: '10px',
                      p: '6px 0',
                      // NOTE: 幅が大きくても、2カラム以上にならないようにする
                      maxWidth: boxWidth >= 680 ? '680px' : '100%'
                    }}
                  >
                    {fileUploadTaskList.map((fileUploadTask, i) => (
                      <Box key={i}>
                        {fileUploadTask.status === 'success' && (
                          <AttachmentCard
                            type={fileUploadTask.type}
                            attachmentFile={fileUploadTask.attachmentFile}
                            onDelete={() => {
                              handleDeleteFileUploadTask(fileUploadTask)
                            }}
                          />
                        )}
                        {['pending', 'inProgress'].includes(fileUploadTask.status) && (
                          <UploadingCard
                            title={fileUploadTask.attachmentFile.title}
                            onDelete={() => {
                              handleDeleteFileUploadTask(fileUploadTask)
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip content={'画像、またはファイルを添付できます。'}>
                    <Button
                      startIcon={<DynamicMuiIcon variant="attachFile" size="20px" />}
                      kind="neutral"
                      title="ファイル"
                      onClick={handleInputFileClick}
                    />
                  </Tooltip>
                  <Box mr="8px" />
                  <SelectSharableMaterial onChange={handleSelectMaterial} />
                  <Box display="none">
                    <input
                      ref={inputFileRef}
                      type="file"
                      onChange={handleInputFileChange}
                      multiple
                      accept=".ai,.psd,.doc,.docx,.mp4,.mov,.svg,.txt,.pdf,.zip,.xls,.xlsx,.csv,.pptx,.ppt,.pps,.ps,.jpg,.jpeg,.png,.gif,.webp,.mp3,.m4a"
                    />
                  </Box>
                </Box>
                <Button title="送信" disabled={!message || isLoading} onClick={onCreateMessage} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    )
  }
)

// NOTE: Boxでrefを使うため
const StyledBox = styled(Box)``
