/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ContactSettingFieldItemFromJSON, ContactSettingFieldItemToJSON } from './ContactSettingFieldItem';
import { ContactSettingFieldValueFromJSON, ContactSettingFieldValueToJSON } from './ContactSettingFieldValue';
export function ContactSettingFieldFromJSON(json) {
    return ContactSettingFieldFromJSONTyped(json, false);
}
export function ContactSettingFieldFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        label: !exists(json, 'label') ? undefined : json['label'],
        kind: !exists(json, 'kind') ? undefined : json['kind'],
        isRequired: !exists(json, 'is_required') ? undefined : json['is_required'],
        isInputRequired: !exists(json, 'is_input_required') ? undefined : json['is_input_required'],
        isInputRequiredOnUser: !exists(json, 'is_input_required_on_user') ? undefined : json['is_input_required_on_user'],
        isInputRequiredOnEu: !exists(json, 'is_input_required_on_eu') ? undefined : json['is_input_required_on_eu'],
        fieldValidate: !exists(json, 'field_validate') ? undefined : json['field_validate'],
        sort: !exists(json, 'sort') ? undefined : json['sort'],
        itemType: !exists(json, 'item_type') ? undefined : json['item_type'],
        itemUrl: !exists(json, 'item_url') ? undefined : json['item_url'],
        placeholder: !exists(json, 'placeholder') ? undefined : json['placeholder'],
        isHideOnUser: !exists(json, 'is_hide_on_user') ? undefined : json['is_hide_on_user'],
        isHideOnEu: !exists(json, 'is_hide_on_eu') ? undefined : json['is_hide_on_eu'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        contactSettingFieldValues: !exists(json, 'contact_setting_field_values')
            ? undefined
            : json['contact_setting_field_values'].map(ContactSettingFieldValueFromJSON),
        contactSettingFieldItems: !exists(json, 'contact_setting_field_items')
            ? undefined
            : json['contact_setting_field_items'] === null
                ? null
                : json['contact_setting_field_items'].map(ContactSettingFieldItemFromJSON)
    };
}
export function ContactSettingFieldToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        label: value.label,
        kind: value.kind,
        is_required: value.isRequired,
        is_input_required: value.isInputRequired,
        is_input_required_on_user: value.isInputRequiredOnUser,
        is_input_required_on_eu: value.isInputRequiredOnEu,
        field_validate: value.fieldValidate,
        sort: value.sort,
        item_type: value.itemType,
        item_url: value.itemUrl,
        placeholder: value.placeholder,
        is_hide_on_user: value.isHideOnUser,
        is_hide_on_eu: value.isHideOnEu,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        contact_setting_field_values: value.contactSettingFieldValues === undefined
            ? undefined
            : value.contactSettingFieldValues.map(ContactSettingFieldValueToJSON),
        contact_setting_field_items: value.contactSettingFieldItems === undefined
            ? undefined
            : value.contactSettingFieldItems === null
                ? null
                : value.contactSettingFieldItems.map(ContactSettingFieldItemToJSON)
    };
}
