/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting,
  UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSON,
  UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSONTyped,
  UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingToJSON
} from './UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting'

/**
 *
 * @export
 * @interface UpdateOrganizationUserEmailSettingRequest
 */
export interface UpdateOrganizationUserEmailSettingRequest {
  /**
   *
   * @type {UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting}
   * @memberof UpdateOrganizationUserEmailSettingRequest
   */
  organizationUserEmailSetting?: UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSetting
}

export function UpdateOrganizationUserEmailSettingRequestFromJSON(
  json: any
): UpdateOrganizationUserEmailSettingRequest {
  return UpdateOrganizationUserEmailSettingRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationUserEmailSettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationUserEmailSettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationUserEmailSetting: !exists(json, 'organization_user_email_setting')
      ? undefined
      : UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingFromJSON(
          json['organization_user_email_setting']
        )
  }
}

export function UpdateOrganizationUserEmailSettingRequestToJSON(
  value?: UpdateOrganizationUserEmailSettingRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_user_email_setting: UpdateOrganizationUserEmailSettingRequestOrganizationUserEmailSettingToJSON(
      value.organizationUserEmailSetting
    )
  }
}
