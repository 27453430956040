/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumSupportStatus = {
  Solved: 'solved',
  Unsolved: 'unsolved',
  SendEmailError: 'send_email_error'
} as const
export type EnumSupportStatus = typeof EnumSupportStatus[keyof typeof EnumSupportStatus]

export function EnumSupportStatusFromJSON(json: any): EnumSupportStatus {
  return EnumSupportStatusFromJSONTyped(json, false)
}

export function EnumSupportStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumSupportStatus {
  return json as EnumSupportStatus
}

export function EnumSupportStatusToJSON(value?: EnumSupportStatus | null): any {
  return value as any
}
