/* eslint-disable prettier/prettier */
import { Box } from '@material-ui/core'
import React from 'react'
import { Card, Colors, Typography } from 'src/components/atoms'
import { numberWithCommas } from 'src/fixtures/utils/number'

export interface SelectPlanCardProps {
  planName: string
  unitAmount: number
}

export const SelectPlanCard = ({ planName, unitAmount }: SelectPlanCardProps) => {
  return (
    <Card label="ご選択中のプラン" sx={{ color: '#6D7784', bgcolor: Colors.background.lightGray }} >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: "20px 16px",
          border: `1px solid ${Colors.background.silver}`,
          borderRadius: "6px"
        }}
      >
        <Typography fontSize="l" lineheight="1.5" fontWeight="bold">
          {planName}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box mr="4px">
            <Typography fontSize="m" lineheight="1">¥</Typography>
          </Box>
          <Box mr="8px">
            <Typography fontSize="l" lineheight="1.5" fontWeight="bold">{numberWithCommas(unitAmount)}</Typography>
          </Box>
          <Box>
            <Typography fontSize="s" lineheight="1">/ 月（税別）</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}
