/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PageInfoFromJSON(json) {
    return PageInfoFromJSONTyped(json, false);
}
export function PageInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        totalCount: !exists(json, 'total_count') ? undefined : json['total_count'],
        offsetValue: !exists(json, 'offset_value') ? undefined : json['offset_value'],
        perPage: !exists(json, 'per_page') ? undefined : json['per_page'],
        totalPages: !exists(json, 'total_pages') ? undefined : json['total_pages'],
        currentPage: !exists(json, 'current_page') ? undefined : json['current_page'],
        nextPage: !exists(json, 'next_page') ? undefined : json['next_page'],
        prevPage: !exists(json, 'prev_page') ? undefined : json['prev_page'],
        isFirstPage: !exists(json, 'is_first_page') ? undefined : json['is_first_page'],
        isLastPage: !exists(json, 'is_last_page') ? undefined : json['is_last_page']
    };
}
export function PageInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        total_count: value.totalCount,
        offset_value: value.offsetValue,
        per_page: value.perPage,
        total_pages: value.totalPages,
        current_page: value.currentPage,
        next_page: value.nextPage,
        prev_page: value.prevPage,
        is_first_page: value.isFirstPage,
        is_last_page: value.isLastPage
    };
}
