/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetDocumentMiscSettingResponseData,
  GetDocumentMiscSettingResponseDataFromJSON,
  GetDocumentMiscSettingResponseDataFromJSONTyped,
  GetDocumentMiscSettingResponseDataToJSON
} from './GetDocumentMiscSettingResponseData'

/**
 *
 * @export
 * @interface GetDocumentMiscSettingResponse
 */
export interface GetDocumentMiscSettingResponse {
  /**
   *
   * @type {number}
   * @memberof GetDocumentMiscSettingResponse
   */
  status?: number
  /**
   *
   * @type {GetDocumentMiscSettingResponseData}
   * @memberof GetDocumentMiscSettingResponse
   */
  data?: GetDocumentMiscSettingResponseData
}

export function GetDocumentMiscSettingResponseFromJSON(json: any): GetDocumentMiscSettingResponse {
  return GetDocumentMiscSettingResponseFromJSONTyped(json, false)
}

export function GetDocumentMiscSettingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentMiscSettingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetDocumentMiscSettingResponseDataFromJSON(json['data'])
  }
}

export function GetDocumentMiscSettingResponseToJSON(value?: GetDocumentMiscSettingResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetDocumentMiscSettingResponseDataToJSON(value.data)
  }
}
