import { Box, createStyles, ListSubheader, makeStyles, MenuItem } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { Colors, DynamicMuiIcon, Input, Typography } from 'src/components/atoms'
import { useListSelectorShareTargets } from 'src/fixtures/modules/selector/hooks'
import { useCreateContactWithEmailAddress } from 'src/fixtures/modules/contact/hooks'
import { useGlobalStyles } from 'src/styles/theme'
import { SelectorItem } from '@noco/http-client/lib/noco'

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      textAlign: 'left'
    },
    customBox: {
      border: `1px solid ${Colors.accent.keyPurple.default}`
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    },
    listSubheader: {
      color: Colors.base.placeHolder,
      fontWeight: 'bold',
      lineHeight: '170%'
    },
    menuItem: {
      height: '34px',
      display: 'flex',
      alignItems: 'center',
      padding: '0px 16px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    createCotactMenuItem: {
      minHeight: '28px',
      display: 'flex',
      alignItems: 'center',
      padding: '4px 12px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      cursor: 'pointer',
      color: Colors.accent.keyPurple.default,
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    menuItemEmail: {
      '& p': {
        color: Colors.base.middleGray
      }
    }
  })
})
const OPTION_HEIGHT = 34

export interface SelectorItemWithType extends SelectorItem {
  type?: string
  email?: string | null
}
export interface AddressSearchBoxProps {
  onClose?: () => void
  onlyContact?: boolean
  selectedList: SelectorItemWithType[]
  onSelect?: (selectedList: SelectorItemWithType[]) => void
  onContactCreating?: (isCreating: boolean) => void
}

export interface SelectAddressOptionData {
  label: string
  value: string
  type: string
}

const validationSchema = yup.object().shape({
  email: yup.string().email('メールアドレスの形式で入力してください').required('メールアドレスは必須です')
})

export const AddressSearchBox = ({
  selectedList,
  onSelect,
  onClose,
  onlyContact = false,
  onContactCreating
}: AddressSearchBoxProps) => {
  const [keyword, setKeyword] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  const {
    data: resSelectorList,
    mutate,
    revalidateAllMutation
  } = useListSelectorShareTargets({ page: 1, per: 25, text: keyword })
  const { error, isLoading, handleCreateContactWithEmailAddress, newContact } = useCreateContactWithEmailAddress()

  const companiesListOptions = useMemo(
    () =>
      resSelectorList?.selectorItemObjects
        ?.find(item => item.itemType === 'company')
        ?.selectorItems?.map(c => ({
          value: c.id!,
          label: c.text!,
          type: 'companyList'
        })) || [],
    [resSelectorList]
  )
  const contactsListOptions = useMemo(
    () =>
      resSelectorList?.selectorItemObjects
        ?.find(item => item.itemType === 'contact_list')
        ?.selectorItems?.map(c => ({
          value: c.id!,
          label: c.text!,
          type: 'contact_list'
        })) || [],
    [resSelectorList]
  )
  const contactsOptions = useMemo(
    () =>
      resSelectorList?.selectorItemObjects
        ?.find(item => item.itemType === 'contact')
        ?.selectorItems?.map(c => ({
          value: c.id!,
          label: c.text!,
          type: 'contact',
          company: c.extend?.company?.displayName,
          email: c.extend?.encryptedEmail!
        })) || [],
    [resSelectorList]
  )

  const handleSelect = useCallback(
    ({ value }: SelectAddressOptionData) => {
      const idx = selectedList.findIndex(item => item.id === value)
      let newList = [...selectedList]
      if (idx !== -1) {
        newList = newList.splice(idx, 1)
      } else {
        const contactsTarget = resSelectorList?.selectorItemObjects
          ?.find(item => item.itemType === 'contact')
          ?.selectorItems?.find(item => item.id === value)
        if (contactsTarget) {
          const contactsTargetWithType: SelectorItemWithType = {
            id: contactsTarget.id,
            text: contactsTarget.text,
            type: 'contact',
            email: contactsTarget.extend?.encryptedEmail
          }
          newList.push(contactsTargetWithType)
        }
        const contactsListTarget = resSelectorList?.selectorItemObjects
          ?.find(item => item.itemType === 'contact_list')
          ?.selectorItems?.find(item => item.id === value)
        if (contactsListTarget) {
          const contactsListTargetWithType: SelectorItemWithType = {
            id: contactsListTarget.id,
            text: contactsListTarget.text,
            type: 'contactList'
          }
          newList.push(contactsListTargetWithType)
        }
        const companyListTarget = resSelectorList?.selectorItemObjects
          ?.find(item => item.itemType === 'company')
          ?.selectorItems?.find(item => item.id === value)
        if (companyListTarget) {
          const companyListTargetWithType: SelectorItemWithType = {
            id: companyListTarget.id,
            text: companyListTarget.text,
            type: 'companyList'
          }
          newList.push(companyListTargetWithType)
        }
      }
      onSelect?.(newList)
      onClose?.()
    },
    [onClose, onSelect, resSelectorList, selectedList]
  )

  const handleCreateContact = useCallback(() => {
    if (!keyword) return

    setIsSubmitting(true)
    onContactCreating?.(true)
    handleCreateContactWithEmailAddress({ emailAddress: keyword })
  }, [handleCreateContactWithEmailAddress, keyword, onContactCreating])

  useEffect(() => {
    if (isSubmitting && !isLoading) {
      setIsSubmitting(false)
      onContactCreating?.(false)

      if (error) return
      if (!newContact) return

      const contactWithType: SelectorItemWithType = {
        id: newContact.id,
        type: 'contact',
        text: newContact.encryptedEmail,
        email: newContact.encryptedEmail
      }
      onSelect?.([...selectedList, contactWithType])
      setKeyword('')
      revalidateAllMutation()
      onClose?.()
    }
  }, [
    isSubmitting,
    isLoading,
    error,
    newContact,
    onSelect,
    selectedList,
    onClose,
    mutate,
    revalidateAllMutation,
    onContactCreating
  ])

  useEffect(() => {
    try {
      setIsEmailValid(validationSchema.isValidSync({ email: keyword }))
    } catch {
      setIsEmailValid(false)
    }
  }, [keyword])

  return (
    <Box pt="12px" pb="16px">
      <Box px="12px">
        <Input
          onChange={e => setKeyword(e.currentTarget.value as string)}
          name="contactSearch"
          width="100%"
          value={keyword}
          startAdornment={
            <Box
              pl="14px"
              color={Colors.base.middleGray}
              fontSize="18px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
            </Box>
          }
          endAdornment={
            <Box
              p="10px"
              color={Colors.base.middleGray}
              fontSize="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                if (keyword) {
                  setKeyword('')
                } else {
                  onClose?.()
                }
              }}
              className={globalClasses.cursorPointer}
            >
              <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
            </Box>
          }
        />
      </Box>
      <Box height="10px" />
      <Box overflow="auto" maxHeight={OPTION_HEIGHT * 7}>
        {!onlyContact && (
          <>
            <ListSubheader disableSticky className={classes.listSubheader}>
              会社からまとめて追加
            </ListSubheader>
            {companiesListOptions.map((company, i) => (
              <MenuItem
                key={i}
                value={company.value}
                className={`${classes.menuItem}`}
                onClick={() => handleSelect(company)}
              >
                <Typography fontSize="s" lh="default" noWrap>
                  {company.label}
                </Typography>
              </MenuItem>
            ))}
            {companiesListOptions.length === 0 && (
              <MenuItem className={`${classes.menuItem}`}>
                <Typography fontSize="s" lh="default" noWrap>
                  選択できる項目がありません
                </Typography>
              </MenuItem>
            )}
            <Box mt="16px" />
          </>
        )}
        {!onlyContact && (
          <>
            <ListSubheader disableSticky className={classes.listSubheader}>
              リストからまとめて追加
            </ListSubheader>
            {contactsListOptions.map((list, i) => (
              <MenuItem key={i} value={list.value} className={`${classes.menuItem}`} onClick={() => handleSelect(list)}>
                <Typography fontSize="s" lh="default" noWrap>
                  {list.label}
                </Typography>
              </MenuItem>
            ))}
            {contactsListOptions.length === 0 && (
              <MenuItem className={`${classes.menuItem}`}>
                <Typography fontSize="s" lh="default" noWrap>
                  選択できる項目がありません
                </Typography>
              </MenuItem>
            )}
            <Box mt="16px" />
          </>
        )}
        {!onlyContact && (
          <ListSubheader disableSticky className={classes.listSubheader}>
            連絡先を指定して追加
          </ListSubheader>
        )}
        {contactsOptions.map((person, i) => (
          <Box key={i} className={`${classes.menuItem}`} onClick={() => handleSelect(person)}>
            <Box maxWidth="60%">
              <Typography fontSize="s" lh="default" noWrap>
                {person.label}
                {person.company && <>&nbsp;{person.company}</>}
              </Typography>
            </Box>
            <Box className={classes.menuItemEmail} maxWidth="40%">
              <Typography fontSize="xs" lh="default" noWrap>
                &nbsp;{person.email}
              </Typography>
            </Box>
          </Box>
        ))}
        {contactsOptions.length === 0 &&
          (!onlyContact && isEmailValid ? (
            <MenuItem className={`${classes.createCotactMenuItem}`} onClick={() => handleCreateContact()}>
              <Box mr="6px" fontSize="0">
                <DynamicMuiIcon variant="add" size="20px" />
              </Box>
              <Box sx={{ flex: '1', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                <Box sx={{ flexShrink: 0 }}>
                  <Typography fontSize="s" lh="default">
                    新しい連絡先 “
                  </Typography>
                </Box>
                <Box sx={{ overflow: 'hidden' }}>
                  <Typography fontSize="s" lh="default" noWrap>
                    {keyword}
                  </Typography>
                </Box>
                <Box sx={{ flexShrink: 0 }}>
                  <Typography fontSize="s" lh="default">
                    ”
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          ) : (
            <MenuItem className={`${classes.menuItem}`}>
              <Typography fontSize="s" lh="default" noWrap>
                選択できる項目がありません
              </Typography>
            </MenuItem>
          ))}
      </Box>
    </Box>
  )
}
