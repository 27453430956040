/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateOrganizationPolicySettingRequestOrganizationPolicySetting
 */
export interface UpdateOrganizationPolicySettingRequestOrganizationPolicySetting {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationPolicySettingRequestOrganizationPolicySetting
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationPolicySettingRequestOrganizationPolicySetting
   */
  url?: string | null
}

export function UpdateOrganizationPolicySettingRequestOrganizationPolicySettingFromJSON(
  json: any
): UpdateOrganizationPolicySettingRequestOrganizationPolicySetting {
  return UpdateOrganizationPolicySettingRequestOrganizationPolicySettingFromJSONTyped(json, false)
}

export function UpdateOrganizationPolicySettingRequestOrganizationPolicySettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPolicySettingRequestOrganizationPolicySetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    label: !exists(json, 'label') ? undefined : json['label'],
    url: !exists(json, 'url') ? undefined : json['url']
  }
}

export function UpdateOrganizationPolicySettingRequestOrganizationPolicySettingToJSON(
  value?: UpdateOrganizationPolicySettingRequestOrganizationPolicySetting | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    label: value.label,
    url: value.url
  }
}
