import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Glitter = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19" fill={props.color} xmlns="http://www.w3.org/2000/svg">
      <path d="M18.9984 9.50478L16.8218 8.80322C15.4296 8.34385 14.614 7.21728 14.2312 5.80166L13.2734 1.13604C13.2484 1.04385 13.1874 0.92041 12.9999 0.92041C12.8421 0.92041 12.7515 1.04385 12.7265 1.13604L11.7687 5.80322C11.3843 7.21885 10.5702 8.34541 9.17805 8.80478L7.00148 9.50635C6.69367 9.60635 6.68898 10.0407 6.99523 10.1454L9.18742 10.9001C10.5749 11.361 11.3843 12.486 11.7687 13.8954L12.728 18.5095C12.753 18.6017 12.8046 18.7673 13.0015 18.7673C13.2093 18.7673 13.2499 18.6017 13.2749 18.5095L14.2343 13.8954C14.6187 12.4845 15.428 11.3595 16.8155 10.9001L19.0077 10.1454C19.3109 10.0392 19.3062 9.60478 18.9984 9.50478Z" />
      <path d="M19.2046 9.7002C19.1702 9.61426 19.1031 9.53926 18.9984 9.50488L16.8218 8.80332C15.4296 8.34395 14.614 7.21738 14.2312 5.80176L13.2734 1.13613C13.2593 1.08301 13.2093 0.986133 13.1515 0.958008L13.4609 5.64082C13.6906 7.77832 13.8874 8.78457 15.5937 9.07832C17.0593 9.33144 18.7546 9.62207 19.2046 9.7002Z" />
      <path d="M19.2093 9.94385L15.4343 10.8095C14.1046 11.147 13.3718 11.9173 13.3718 13.9329L13.0015 18.7673C13.128 18.7548 13.2312 18.686 13.2749 18.5095L14.2343 13.8954C14.6187 12.4845 15.428 11.3595 16.8155 10.9001L19.0077 10.1454C19.1108 10.1079 19.1765 10.0313 19.2093 9.94385Z" />
      <path d="M6.53273 13.564C5.23116 13.1343 5.11241 12.6499 4.89366 11.8327L4.34835 9.92021C4.31554 9.79678 3.99366 9.79678 3.95929 9.92021L3.58898 11.6874C3.36866 12.5015 2.89991 13.1483 2.09991 13.4124L0.824913 13.9655C0.64835 14.0233 0.645225 14.2733 0.821788 14.3327L2.10616 14.7874C2.90304 15.0515 3.36866 15.6983 3.59054 16.5093L3.96085 18.1999C3.99523 18.3233 4.31554 18.3233 4.34835 18.1999L4.78273 16.5171C5.00304 15.7015 5.34054 15.053 6.42335 14.7874L7.6296 14.3327C7.80616 14.2718 7.8046 14.0218 7.62648 13.964L6.53273 13.564Z" />
      <path d="M4.45151 11.8047C4.58433 13.0328 4.65151 13.3734 5.64683 13.6281L7.72026 14.0328C7.69839 14.0031 7.66714 13.9781 7.62495 13.9656L6.5312 13.5641C5.38901 13.1734 5.07339 12.6766 4.85933 11.6828C4.64526 10.6891 4.41714 10.0406 4.41714 10.0406C4.33745 9.82656 4.22339 9.8375 4.22339 9.8375L4.45151 11.8047Z" />
      <path d="M4.48906 16.0922C4.48906 14.9344 5.12188 14.3609 6.12813 14.3609L7.69375 14.2969C7.69375 14.2969 7.60313 14.4172 7.44531 14.4547L6.42344 14.7875C5.55625 15.1359 5.16094 15.3109 4.89219 16.4969C4.89219 16.4969 4.54531 17.8766 4.47969 18.0219C4.38906 18.225 4.28125 18.2672 4.28125 18.2672L4.48906 16.0922Z" />
      <path d="M9.33431 4.39678C9.42181 4.36709 9.41868 4.24209 9.32962 4.21709L8.12337 3.89209C7.87025 3.82334 7.67337 3.62803 7.6015 3.37647L7.1265 1.41553C7.10306 1.32022 6.96712 1.32022 6.94368 1.41709L6.49681 3.37022C6.42806 3.62959 6.22493 3.82959 5.96556 3.89678L4.76712 4.20772C4.67806 4.23115 4.67181 4.35615 4.75931 4.3874L6.02337 4.8249C6.24993 4.90303 6.42493 5.0874 6.49368 5.31709L6.94525 7.12803C6.96868 7.22178 7.10306 7.22334 7.1265 7.12803L7.59368 5.31084C7.664 5.08115 7.84056 4.89834 8.06868 4.82178L9.33431 4.39678Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59652 1.33334C6.70647 0.878869 7.35907 0.866073 7.47327 1.33048L7.47357 1.33168L7.94658 3.28444C7.98557 3.41339 8.08692 3.51227 8.21672 3.5476C8.21679 3.54762 8.21665 3.54758 8.21672 3.5476L9.42611 3.87345C9.8548 3.99378 9.86727 4.59331 9.44902 4.73521L9.44796 4.73557L8.18234 5.16057C8.06661 5.19941 7.97538 5.29171 7.9372 5.40911L7.4727 7.21582C7.35552 7.68373 6.70904 7.65611 6.59873 7.21488L6.14944 5.41307C6.11187 5.29516 6.02108 5.20213 5.90691 5.16277L4.63914 4.72398C4.21605 4.57288 4.2527 3.97401 4.67619 3.86256L4.67739 3.86225L5.87583 3.55131C6.01 3.51656 6.11362 3.41483 6.15021 3.28387L6.59652 1.33334ZM7.04254 2.58636L6.84356 3.45605L6.84199 3.46195C6.73983 3.84737 6.43798 4.14355 6.05508 4.24274L5.69929 4.33505L6.13976 4.4875C6.13984 4.48753 6.13968 4.48747 6.13976 4.4875C6.47646 4.60369 6.73455 4.87668 6.83579 5.21491L6.83817 5.22286L7.03974 6.03123L7.24977 5.21429L7.25215 5.20653C7.35588 4.86766 7.61658 4.59703 7.95495 4.48344C7.95497 4.48344 7.95494 4.48345 7.95495 4.48344L8.39547 4.33552L8.02972 4.23698C7.65534 4.13529 7.36413 3.84605 7.25806 3.47481L7.25606 3.46783L7.04254 2.58636Z"
      />
    </SvgIcon>
  )
}
export default Glitter
