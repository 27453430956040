import React from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import Image from 'next/image'
import { OrganizationPaymentCardInfoBrandEnum } from '@noco/http-client/lib/noco'

export interface CreditCardBrandIconProps {
  variant: OrganizationPaymentCardInfoBrandEnum
  width?: string
  height?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  })
)

export const CreditCardBrandIcon = ({ variant, width, height }: CreditCardBrandIconProps) => {
  const classes = useStyles()
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: width ?? '42px',
        height: height ?? '26px',
        border: '1px solid #F3F3F3',
        borderRadius: '2px',
        bgcolor: '#fff'
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%', maxHeight: '20px', textAlign: 'center' }}>
        <Image
          src={`/images/card-brands/${variant}.png`}
          alt={variant}
          layout="fill"
          objectFit="contain"
          className={classes.image}
        />
      </Box>
    </Box>
  )
}
