import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const RightArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.500098 8.22917L3.73343 4.99583L0.500098 1.7625C0.175098 1.4375 0.175098 0.9125 0.500098 0.5875C0.825098 0.2625 1.3501 0.2625 1.6751 0.5875L5.5001 4.4125C5.8251 4.7375 5.8251 5.2625 5.5001 5.5875L1.6751 9.4125C1.3501 9.7375 0.825098 9.7375 0.500098 9.4125C0.183431 9.0875 0.175098 8.55417 0.500098 8.22917Z" />
    </SvgIcon>
  )
}
export default RightArrow
