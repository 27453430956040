import React, { useState, useMemo, useCallback, ComponentType, ComponentProps, useEffect, memo } from 'react'
import {
  Column,
  TableColumnWidthInfo,
  SelectionState,
  IntegratedSelection,
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SelectionStateProps,
  SortingState,
  Sorting,
  IntegratedSorting
} from '@devexpress/dx-react-grid'
import {
  Grid,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
  TableColumnResizing,
  TableFixedColumns,
  VirtualTable,
  DragDropProvider,
  TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui'
import { Box, createStyles, makeStyles, CircularProgress } from '@material-ui/core'
import { Colors } from 'src/components/atoms'
import { SelectionHeaderCell, SelectionBodyCell, PagingContainer, PagingOptions } from './TableParts'
import { useGlobalStyles } from 'src/styles/theme'
import Image from 'next/image'
import { localStorageUtils, StorageKey } from 'src/fixtures/utils/localstorage'

const HEADER_HEIGHT = 28
const INITIAL_PAGE = 0
const INITIAL_TOTAL_PAGES = 1
const INITIAL_PER_PAGE = 25
export const useTablePagination = (name: string) => {
  const [page, setPage] = useState(INITIAL_PAGE)
  const [totalPages, setTotalPages] = useState(INITIAL_TOTAL_PAGES)
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_PER_PAGE)

  const changePerPage = useCallback(
    (perPage: number) => {
      setRowsPerPage(perPage)
      setPage(INITIAL_PAGE)
      setTotalPages(INITIAL_TOTAL_PAGES)
      localStorageUtils.set(StorageKey.TABLE_PAGINATION(name), perPage)
    },
    [name]
  )

  const resetPagination = useCallback(() => {
    setPage(INITIAL_PAGE)
    setTotalPages(INITIAL_TOTAL_PAGES)
    const perPage = localStorageUtils.get<number>(StorageKey.TABLE_PAGINATION(name))
    setRowsPerPage(perPage ?? INITIAL_PER_PAGE)
  }, [name])

  const changePage = useCallback((page: number) => {
    setPage(page)
  }, [])

  useEffect(() => {
    const perPage = localStorageUtils.get<number>(StorageKey.TABLE_PAGINATION(name))
    if (perPage) {
      setRowsPerPage(perPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    page,
    per: rowsPerPage,
    totalPages,
    changePage,
    changePerPage,
    resetPagination,
    setTotalPages
  }
}

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      '& thead': {
        '& tr:first-child': {
          height: `${HEADER_HEIGHT}px`,
          '& th:not(:nth-last-child(2))': {
            borderRight: `1px solid ${Colors.background.silver}`
          }
        }
      },
      '& tbody': {
        '& tr': {
          cursor: 'pointer',
          '& td': {
            transition: '512ms'
          },
          '&:hover td': {
            backgroundColor: Colors.hover.white.default
          }
        }
      },
      '& input': {
        width: '12px'
      }
    },
    virtualTableContainer: {
      height: '100%',
      paddingBottom: '100px',
      backgroundColor: 'white'
    }
  })
})

export interface TableColumn extends Column {
  render?: ComponentType<DataTypeProvider.ValueFormatterProps>
  width: number
  fixed?: boolean
  sortingEnabled?: boolean
}

export type TableProps<T> = {
  columns: TableColumn[]
  rows: T[]
  actionMenu?: JSX.Element
  selectable?: boolean
  selection?: SelectionStateProps['selection']
  setSelection?: (selection: SelectionStateProps['selection']) => void
  onClickRow?: (row: T) => void
  padding?: boolean
  showTotalCount?: boolean
  rowHeight?: string | undefined
  numberOfRows?: number
  droppable?: boolean

  page?: number
  per?: number
  totalPages?: number
  changePage?: (page: number) => void
  changePerPage?: (perPage: number) => void
  showNoDataImage?: string
  onOpenModalSelectTableColumn?: () => void
  handleUpdateTableColumnWidth?: (nextColumnWidths: TableColumnWidthInfo[]) => void
}

const VContainer = (props: ComponentProps<typeof VirtualTable.Container>) => {
  const classes = useStyles()
  return <VirtualTable.Container {...props} className={classes.virtualTableContainer} />
}

type VTableRowProps<T> = ComponentProps<typeof VirtualTable.Row> & {
  onClickRow: (rowData: T) => void
  rowData: T
  rowHeight: string | undefined
}

const VTableRow = <T extends {}>({ rowData, onClickRow, rowHeight, ...restProps }: VTableRowProps<T>) => (
  <VirtualTable.Row
    {...restProps}
    onClick={() => onClickRow?.(rowData)}
    style={{
      cursor: 'pointer',
      height: rowHeight
    }}
  />
)

function VTable<T extends {}>({
  columns,
  rows,
  actionMenu,
  selectable = false,
  droppable = false,
  onClickRow,
  setSelection,
  padding = true,
  showTotalCount = false,
  rowHeight,
  page = 0,
  per = 999,
  totalPages = 1,
  changePage,
  changePerPage,
  numberOfRows,
  showNoDataImage,
  onOpenModalSelectTableColumn,
  handleUpdateTableColumnWidth
}: TableProps<T>) {
  const _columnWidths: TableColumnWidthInfo[] = useMemo(
    () => columns.map(column => ({ columnName: column.name, width: column?.width || 120 })),
    [columns]
  )

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>(_columnWidths)
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [currentPageSize, setCurrentPageSize] = useState<number>(PagingOptions[0])
  const [currentPage, setCurrentPage] = useState<number>(0)

  const [columnOrder, setColumnOrder] = useState<string[]>(columns.map(column => column.name))
  const [sortingColumns, setSortingColumns] = useState<Sorting[]>(
    columns.filter(column => column?.sortingEnabled).map(column => ({ columnName: column.name, direction: 'asc' }))
  )
  const onSortingChange = useCallback(
    (sortingColumns: Sorting[]) => {
      setSortingColumns(sortingColumns)
    },
    [setSortingColumns]
  )

  const gridColumns: Column[] = useMemo(
    () => columns.map(column => ({ name: column.name, title: column.title, getCellValue: column.getCellValue })),
    [columns]
  )

  const renderColumns: JSX.Element[] = useMemo(
    () =>
      columns
        .filter(column => column.render)
        .map(column => <DataTypeProvider key={column.name} for={[column.name]} formatterComponent={column.render} />),
    [columns]
  )

  const fixedColumns: string[] = useMemo(
    () => columns.filter(column => column.fixed).map(column => column.name),
    [columns]
  )

  const sortingEnabledColumns: SortingState.ColumnExtension[] = useMemo(
    () => columns.map(column => ({ columnName: column.name, sortingEnabled: column.sortingEnabled ?? false })),
    [columns]
  )

  const handleClickRow = useCallback(
    (row: T) => {
      onClickRow?.(row)
    },
    [onClickRow]
  )

  useEffect(() => {
    if (_columnWidths.length > 0) {
      setColumnWidths(_columnWidths)
    }
  }, [_columnWidths])

  const PagingContainerWithTotal = useCallback(
    (props: PagingPanel.ContainerProps) => {
      if (changePage && changePerPage) {
        return (
          <PagingContainer
            {...props}
            currentPage={page}
            pageSize={per}
            totalPages={totalPages}
            showTotalCount={showTotalCount}
            count={numberOfRows}
            onCurrentPageChange={changePage}
            onPageSizeChange={changePerPage}
            onOpenModalSelectTableColumn={onOpenModalSelectTableColumn}
          />
        )
      }
      return <PagingContainer {...props} showTotalCount={showTotalCount} count={numberOfRows} />
    },
    [changePage, changePerPage, numberOfRows, page, per, showTotalCount, totalPages, onOpenModalSelectTableColumn]
  )

  // NOTE: 各pageでCircularProgressをVirtualTableのcenterに表示するためsizeを取得
  const [widthAndHeight, setWidthAndHeight] = useState([0, 0])
  const width = widthAndHeight[0]
  const height = widthAndHeight[1]
  const setPosition = useCallback(
    (ref: HTMLDivElement) => {
      if (ref && ref.parentElement) {
        const {
          parentElement: { clientWidth, clientHeight }
        } = ref
        if (clientWidth != width && clientHeight != height) {
          setWidthAndHeight([clientWidth, clientHeight])
        }
      }
    },
    [width, height]
  )

  return (
    <Box className={classes.root} height={1}>
      <Grid
        rows={rows}
        columns={gridColumns}
        rootComponent={({ children }) => (
          <Box height={1} display="flex" flexDirection="column" overflow="hidden" px={padding ? '16px' : 0}>
            {children}
          </Box>
        )}
      >
        {actionMenu && (
          <Box position="relative">
            <Box
              position="absolute"
              zIndex={600}
              top={0}
              right={'16px'}
              display="flex"
              justifyContent="center"
              alignItems="center"
              px="8px"
              height={`32px`}
              bgcolor={Colors.functional.background.default}
              borderLeft={`1px solid ${Colors.background.silver}`}
              className={globalClasses.cursorPointer}
            >
              {actionMenu}
            </Box>
          </Box>
        )}

        <SortingState
          sorting={sortingColumns}
          onSortingChange={onSortingChange}
          columnExtensions={sortingEnabledColumns}
        />
        <IntegratedSorting />

        {renderColumns}
        {changePage && changePerPage ? (
          <PagingState currentPage={page} pageSize={per} />
        ) : (
          <PagingState
            currentPage={currentPage}
            pageSize={currentPageSize}
            onCurrentPageChange={page => setCurrentPage(page)}
            onPageSizeChange={pageSize => setCurrentPageSize(pageSize)}
          />
        )}
        <SelectionState onSelectionChange={setSelection} />
        <IntegratedPaging />
        <IntegratedSelection />

        <Box {...{ ref: setPosition }}>
          <VirtualTable
            height="100%"
            noDataRowComponent={() => (
              <Box
                width={`${width}px`}
                // 68px = テーブルヘッダーの高さ + ページネーションエリアの高さ
                height={`calc(${height}px - 68px)`}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <NoData numberOfRows={numberOfRows} showNoDataImage={showNoDataImage} />
              </Box>
            )}
            containerComponent={VContainer}
            rowComponent={props => (
              <VTableRow<T> onClickRow={handleClickRow} rowData={props.row} rowHeight={rowHeight} {...props} />
            )}
          />
        </Box>
        {columnWidths && columns.length === columnWidths.length && (
          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={nextColumnWidths => {
              setColumnWidths(nextColumnWidths)
              if (handleUpdateTableColumnWidth) {
                handleUpdateTableColumnWidth(nextColumnWidths)
              }
            }}
          />
        )}
        {droppable && <DragDropProvider />}
        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />

        <TableHeaderRow showSortingControls />
        {selectable && (
          <TableSelection
            showSelectAll
            cellComponent={SelectionBodyCell}
            headerCellComponent={SelectionHeaderCell}
            selectionColumnWidth={34}
          />
        )}
        <TableFixedColumns leftColumns={[TableSelection.COLUMN_TYPE, ...fixedColumns]} />
        <PagingPanel containerComponent={PagingContainerWithTotal} />
      </Grid>
    </Box>
  )
}

export const Table = memo(
  VTable,
  // MEMO: 再レンダリングを防ぐため deep な比較を行なっている + selection のレンダリングコストを排除するため、削除
  (a, b) => {
    let compareA = { ...a }
    let compareB = { ...b }
    delete compareA.selection
    delete compareB.selection
    return JSON.stringify(compareA) === JSON.stringify(compareB)
  }
) as typeof VTable

const NoData = ({ numberOfRows, showNoDataImage }: { numberOfRows: number | undefined; showNoDataImage?: string }) => {
  if (numberOfRows === 0 && showNoDataImage)
    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: 458,
          height: 368,
          overflow: 'hidden'
        }}
      >
        <Image src={showNoDataImage} alt="no_result" layout="fill" objectFit="contain" />
      </Box>
    )
  else if (numberOfRows === 0 && !showNoDataImage) {
    return <Box>データがありません</Box>
  } else {
    return <CircularProgress />
  }
}

export default Table
