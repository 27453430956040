import React from 'react'
import { createStyles, Dialog, DialogProps, Paper, PaperProps, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Draggable from 'react-draggable'
import theme from 'src/styles/theme'
import { PC_HEADER_HEIGHT } from '../DocumentSlideDetail/PC/Header'
import { PC_FOOTER_HEIGHT } from '../DocumentSlideDetail/PC'

export interface FloatingWindowProps {
  width?: string | number
  paperMaxWidth?: string | number
  paperHeight?: string | number
  top?: string | number
  right?: string | number
  bottom?: string | number
  left?: string | number
}

export interface FloatingWindowStyleProps extends FloatingWindowProps {
  isDownSm: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: 'absolute',
      top: ({ top, isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? '40px' : top || '48px'),
      right: ({ right, isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? 0 : right || '15px'),
      bottom: ({ bottom, isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? 0 : bottom),
      left: ({ left, isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? 0 : left),
      width: ({ width, isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? '100%' : width || 'auto'),
      height: ({ paperHeight, isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? 'auto' : paperHeight),
      maxHeight: 700,
      maxWidth: ({ paperMaxWidth, isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? '100%' : paperMaxWidth),
      borderRadius: ({ isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? '10px 10px 0 0' : '10px'),
      margin: 0,
      pointerEvents: 'auto'
    },
    backdrop: {
      backgroundColor: ({ isDownSm }: Partial<FloatingWindowStyleProps>) => (isDownSm ? '' : 'transparent')
    }
  })
)

const PaperComponent = (props: PaperProps) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Draggable bounds="parent" disabled={isDownSm} handle=".handle" cancel=".cancel">
      <Paper {...props} />
    </Draggable>
  )
}

export const FloatingWindow = ({
  open,
  children,
  width,
  paperHeight = `calc(100vh - ${PC_HEADER_HEIGHT + PC_FOOTER_HEIGHT}px)`,
  paperMaxWidth = '360px',
  top,
  right,
  bottom,
  left,
  ...otherProps
}: FloatingWindowProps & DialogProps) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ width, paperHeight, paperMaxWidth, top, right, bottom, left, isDownSm })
  return (
    <Dialog
      style={{ zIndex: 99999, pointerEvents: 'none' }}
      open={open}
      PaperComponent={PaperComponent}
      PaperProps={{ className: classes.paper }}
      BackdropProps={{ className: classes.backdrop }}
      {...otherProps}
    >
      {children}
    </Dialog>
  )
}
