import React from 'react'
import { Box } from '@material-ui/core'
import { Colors, StepTitle, StepDetailTitle, Typography, Button, DynamicMuiIcon } from 'src/components/atoms'
import { ImportExecution } from 'src/components/molecules'
import { useCreateFormatExportContactCompany, useCreateImportContactCompany } from 'src/fixtures/modules/porter/hooks'
import { useGetPorterHistoriesLatest } from 'src/fixtures/modules/recordPorterHistory/hooks'
import { EnumRecordPorterHistoryOperation } from '@noco/http-client/lib/noco'
import Image from 'next/image'

export interface BulkUpdateImportProps {}

export const BulkUpdateImport = (_: BulkUpdateImportProps) => {
  const { data: result } = useGetPorterHistoriesLatest(EnumRecordPorterHistoryOperation.ImportContactCompanies)
  const { handleCreateFormatExportContactCompany } = useCreateFormatExportContactCompany()
  const { handleCreateImportContactCompany, isLoading, error } = useCreateImportContactCompany(
    EnumRecordPorterHistoryOperation.ImportContactCompanies
  )

  return (
    <>
      <Box
        sx={{
          bgcolor: Colors.background.lightGray,
          width: '100%',
          borderRadius: '20px',
          position: 'relative',
          p: '20px',
          mb: '40px'
        }}
      >
        <StepTitle index={1} title="インポートを行う前に" />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ mt: '32px', pl: '12px' }}>
            <StepDetailTitle index={1} title="インポート用Excelファイルをダウンロード" />
            <Box sx={{ pl: '20px', pt: '8px', mt: '4px', mb: '36px', display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                会社・連絡先の一括編集用ファイルを作成するための、
                <br />
                インポート用Excelファイルをダウンロードしてください
              </Typography>
              <Box sx={{ mt: '16px' }} />
              <Button
                startIcon={<DynamicMuiIcon variant="fileDownload" size="20px" />}
                kind="primary"
                variant="outlined"
                title="インポート用Excelファイルのダウンロード"
                width="335px"
                onClick={handleCreateFormatExportContactCompany}
              />
            </Box>
            <StepDetailTitle index={2} title="ExcelファイルをPCで開いてインポートファイルを作成しよう" />
            <Box sx={{ pl: '20px', pt: '12px' }}>
              <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                インポート用Excelファイルを使って、一括編集用ファイルを作成します
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '244px', height: '192px', position: 'relative', mr: '20px' }}>
            <Image alt="bulk_import" src="/images/bulk_import.svg" layout="fill" objectFit="contain" />
          </Box>
        </Box>
        <Box sx={{ ml: '32px', mt: '16px', mr: '12px', height: '184px', position: 'relative' }}>
          <Image
            alt="bulk_import"
            src="/images/bulk-update/import_contact_company_sample_image_01.png"
            layout="fill"
            objectFit="contain"
          />
        </Box>
      </Box>
      <ImportExecution
        onSubmit={(value: File) => {
          handleCreateImportContactCompany(value)
        }}
        result={result?.recordPorterHistory}
        requesting={isLoading}
        error={error}
      />
    </>
  )
}
