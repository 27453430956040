/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Contact, ContactFromJSON, ContactFromJSONTyped, ContactToJSON } from './Contact'

/**
 *
 * @export
 * @interface CreateContactResponseData
 */
export interface CreateContactResponseData {
  /**
   *
   * @type {Contact}
   * @memberof CreateContactResponseData
   */
  contact?: Contact
}

export function CreateContactResponseDataFromJSON(json: any): CreateContactResponseData {
  return CreateContactResponseDataFromJSONTyped(json, false)
}

export function CreateContactResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contact: !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact'])
  }
}

export function CreateContactResponseDataToJSON(value?: CreateContactResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact: ContactToJSON(value.contact)
  }
}
