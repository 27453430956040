import React, { PropsWithChildren } from 'react'
import { createStyles, makeStyles, Box, Modal, ButtonBase, Slide } from '@material-ui/core'
import { Colors, Pallete } from '../../atoms/Colors'
import { Button, ButtonProps, DynamicMuiIcon, Typography } from '../../atoms'

export interface PopupTemplateProps {
  open: boolean
  title?: string
  subTitle?: string
  width?: string
  onClose: () => void
  hasClose?: boolean
  hasCancel?: boolean
  hasConfirm?: boolean
  onConfirm?: () => void
  cancelButtonLabel?: string
  confirmButtonLabel?: string
  confirmButtonKind?: ButtonProps['kind']
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: Colors.base.black,
      zIndex: 9999
    }
  })
)

export const PopupTemplate = ({
  open,
  title,
  subTitle,
  width = '580px',
  onClose,
  hasClose,
  hasCancel,
  hasConfirm,
  onConfirm,
  cancelButtonLabel,
  confirmButtonLabel,
  confirmButtonKind,
  children
}: PropsWithChildren<PopupTemplateProps>) => {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={width}
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.silver}`}
          overflow="hidden"
        >
          {title && (
            <Box
              px="24px"
              py="16px"
              borderBottom={`1px solid ${Colors.background.silver}`}
              display="flex"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h3" fontWeight="bold" fontSize="m" lineheight="27.9px">
                  {title}
                </Typography>
                {subTitle && (
                  <Box ml="12px">
                    <Typography fontSize="s" lineheight="18.84px" letterSpacing="tight">
                      {subTitle}
                    </Typography>
                  </Box>
                )}
              </Box>
              {hasClose && (
                <ButtonBase onClick={onClose}>
                  <DynamicMuiIcon variant="close" color="action" size="28px" />
                </ButtonBase>
              )}
            </Box>
          )}
          {children}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            py="12px"
            bgcolor={Colors.background.lightGray}
            boxShadow={`inset 0px 1px 0px ${Colors.background.silver}`}
          >
            <Box display="flex" mr="24px">
              {hasCancel && (
                <Button kind="neutral" variant="outlined" title={cancelButtonLabel || ''} onClick={onClose} />
              )}
              {hasConfirm && (
                <>
                  <Box ml="12px" />
                  <Button kind={confirmButtonKind} title={confirmButtonLabel || ''} onClick={onConfirm} />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
