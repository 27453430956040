/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function PurgeUserAndMailTemplateOrganizationRequestFromJSON(json) {
    return PurgeUserAndMailTemplateOrganizationRequestFromJSONTyped(json, false);
}
export function PurgeUserAndMailTemplateOrganizationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        liveUserIds: !exists(json, 'live_user_ids') ? undefined : json['live_user_ids'],
        liveMailTemplateIds: !exists(json, 'live_mail_template_ids') ? undefined : json['live_mail_template_ids'],
        takeOverUserId: !exists(json, 'take_over_user_id') ? undefined : json['take_over_user_id'],
        planIdOfTransitionDst: !exists(json, 'plan_id_of_transition_dst') ? undefined : json['plan_id_of_transition_dst']
    };
}
export function PurgeUserAndMailTemplateOrganizationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        live_user_ids: value.liveUserIds,
        live_mail_template_ids: value.liveMailTemplateIds,
        take_over_user_id: value.takeOverUserId,
        plan_id_of_transition_dst: value.planIdOfTransitionDst
    };
}
