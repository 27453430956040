/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CompanySettingField,
  CompanySettingFieldFromJSON,
  CompanySettingFieldFromJSONTyped,
  CompanySettingFieldToJSON
} from './CompanySettingField'
import {
  EnumSettingSettingType,
  EnumSettingSettingTypeFromJSON,
  EnumSettingSettingTypeFromJSONTyped,
  EnumSettingSettingTypeToJSON
} from './EnumSettingSettingType'
import {
  EnumSettingStatus,
  EnumSettingStatusFromJSON,
  EnumSettingStatusFromJSONTyped,
  EnumSettingStatusToJSON
} from './EnumSettingStatus'
import {
  SettingValueText,
  SettingValueTextFromJSON,
  SettingValueTextFromJSONTyped,
  SettingValueTextToJSON
} from './SettingValueText'

/**
 *
 * @export
 * @interface CompanySetting
 */
export interface CompanySetting {
  /**
   *
   * @type {string}
   * @memberof CompanySetting
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof CompanySetting
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CompanySetting
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof CompanySetting
   */
  icon?: string
  /**
   *
   * @type {number}
   * @memberof CompanySetting
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof CompanySetting
   */
  kind?: string
  /**
   *
   * @type {EnumSettingSettingType}
   * @memberof CompanySetting
   */
  settingType?: EnumSettingSettingType
  /**
   *
   * @type {EnumSettingStatus}
   * @memberof CompanySetting
   */
  status?: EnumSettingStatus
  /**
   *
   * @type {number}
   * @memberof CompanySetting
   */
  numberOfColumns?: number | null
  /**
   *
   * @type {boolean}
   * @memberof CompanySetting
   */
  isRequired?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CompanySetting
   */
  isRequiredOnUser?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CompanySetting
   */
  isRequiredOnEu?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CompanySetting
   */
  canEdit?: boolean
  /**
   *
   * @type {string}
   * @memberof CompanySetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof CompanySetting
   */
  updatedAt?: string
  /**
   *
   * @type {Array<CompanySettingField>}
   * @memberof CompanySetting
   */
  companySettingFields?: Array<CompanySettingField>
  /**
   *
   * @type {SettingValueText}
   * @memberof CompanySetting
   */
  companySettingValueText?: SettingValueText | null
}

export function CompanySettingFromJSON(json: any): CompanySetting {
  return CompanySettingFromJSONTyped(json, false)
}

export function CompanySettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    label: !exists(json, 'label') ? undefined : json['label'],
    icon: !exists(json, 'icon') ? undefined : json['icon'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    kind: !exists(json, 'kind') ? undefined : json['kind'],
    settingType: !exists(json, 'setting_type') ? undefined : EnumSettingSettingTypeFromJSON(json['setting_type']),
    status: !exists(json, 'status') ? undefined : EnumSettingStatusFromJSON(json['status']),
    numberOfColumns: !exists(json, 'number_of_columns') ? undefined : json['number_of_columns'],
    isRequired: !exists(json, 'is_required') ? undefined : json['is_required'],
    isRequiredOnUser: !exists(json, 'is_required_on_user') ? undefined : json['is_required_on_user'],
    isRequiredOnEu: !exists(json, 'is_required_on_eu') ? undefined : json['is_required_on_eu'],
    canEdit: !exists(json, 'can_edit') ? undefined : json['can_edit'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    companySettingFields: !exists(json, 'company_setting_fields')
      ? undefined
      : (json['company_setting_fields'] as Array<any>).map(CompanySettingFieldFromJSON),
    companySettingValueText: !exists(json, 'company_setting_value_text')
      ? undefined
      : SettingValueTextFromJSON(json['company_setting_value_text'])
  }
}

export function CompanySettingToJSON(value?: CompanySetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    label: value.label,
    icon: value.icon,
    sort: value.sort,
    kind: value.kind,
    setting_type: EnumSettingSettingTypeToJSON(value.settingType),
    status: EnumSettingStatusToJSON(value.status),
    number_of_columns: value.numberOfColumns,
    is_required: value.isRequired,
    is_required_on_user: value.isRequiredOnUser,
    is_required_on_eu: value.isRequiredOnEu,
    can_edit: value.canEdit,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    company_setting_fields:
      value.companySettingFields === undefined
        ? undefined
        : (value.companySettingFields as Array<any>).map(CompanySettingFieldToJSON),
    company_setting_value_text: SettingValueTextToJSON(value.companySettingValueText)
  }
}
