/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function ScheduleBillingContractFromJSON(json) {
    return ScheduleBillingContractFromJSONTyped(json, false);
}
export function ScheduleBillingContractFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        validSince: new Date(json['valid_since']),
        validUntil: new Date(json['valid_until']),
        contractAt: new Date(json['contract_at']),
        updateContractAt: new Date(json['update_contract_at']),
        nextBillingAt: new Date(json['next_billing_at'])
    };
}
export function ScheduleBillingContractToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        valid_since: value.validSince.toISOString(),
        valid_until: value.validUntil.toISOString(),
        contract_at: value.contractAt.toISOString(),
        update_contract_at: value.updateContractAt.toISOString(),
        next_billing_at: value.nextBillingAt.toISOString()
    };
}
