/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationUserEmailSettingFromJSON, OrganizationUserEmailSettingToJSON } from './OrganizationUserEmailSetting';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetOrganizationUserEmailSettingsResponseDataFromJSON(json) {
    return GetOrganizationUserEmailSettingsResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationUserEmailSettingsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organizationUserEmailSettings: !exists(json, 'organization_user_email_settings')
            ? undefined
            : json['organization_user_email_settings'].map(OrganizationUserEmailSettingFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetOrganizationUserEmailSettingsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization_user_email_settings: value.organizationUserEmailSettings === undefined
            ? undefined
            : value.organizationUserEmailSettings.map(OrganizationUserEmailSettingToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
