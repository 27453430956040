import { Box } from '@material-ui/core'
import React from 'react'
import { Typography } from 'src/components/atoms'

export const Address = ({ address }: { address?: string }) => {
  return (
    <Box display="flex" height="16px">
      {address && (
        <Box mr="6px">
          <Typography fontSize="s" lineheight="15.84px" letterSpacing="tight">
            {address}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
