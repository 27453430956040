/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from './User'

/**
 *
 * @export
 * @interface GetOrganizationUsersResponseData
 */
export interface GetOrganizationUsersResponseData {
  /**
   *
   * @type {Array<User>}
   * @memberof GetOrganizationUsersResponseData
   */
  users?: Array<User>
  /**
   *
   * @type {PageInfo}
   * @memberof GetOrganizationUsersResponseData
   */
  pageInfo?: PageInfo
}

export function GetOrganizationUsersResponseDataFromJSON(json: any): GetOrganizationUsersResponseData {
  return GetOrganizationUsersResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationUsersResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationUsersResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    users: !exists(json, 'users') ? undefined : (json['users'] as Array<any>).map(UserFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetOrganizationUsersResponseDataToJSON(value?: GetOrganizationUsersResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    users: value.users === undefined ? undefined : (value.users as Array<any>).map(UserToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
