/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UploadingFileFromJSON(json) {
    return UploadingFileFromJSONTyped(json, false);
}
export function UploadingFileFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        data: !exists(json, 'data') ? undefined : json['data'],
        fileName: !exists(json, 'file_name') ? undefined : json['file_name']
    };
}
export function UploadingFileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        data: value.data,
        file_name: value.fileName
    };
}
