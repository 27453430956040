/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { MailTemplateFromJSON, MailTemplateToJSON } from './MailTemplate';
export function CreateMailTemplateResponseDataFromJSON(json) {
    return CreateMailTemplateResponseDataFromJSONTyped(json, false);
}
export function CreateMailTemplateResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mailTemplate: !exists(json, 'mail_template') ? undefined : MailTemplateFromJSON(json['mail_template'])
    };
}
export function CreateMailTemplateResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mail_template: MailTemplateToJSON(value.mailTemplate)
    };
}
