/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  AuthVisitorAuthenticationCodeRequestResponseVisitor,
  AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSON,
  AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSONTyped,
  AuthVisitorAuthenticationCodeRequestResponseVisitorToJSON
} from './AuthVisitorAuthenticationCodeRequestResponseVisitor'

/**
 *
 * @export
 * @interface AuthVisitorSharedLinkSigninResponse
 */
export interface AuthVisitorSharedLinkSigninResponse {
  /**
   *
   * @type {AuthVisitorAuthenticationCodeRequestResponseVisitor}
   * @memberof AuthVisitorSharedLinkSigninResponse
   */
  visitor?: AuthVisitorAuthenticationCodeRequestResponseVisitor
}

export function AuthVisitorSharedLinkSigninResponseFromJSON(json: any): AuthVisitorSharedLinkSigninResponse {
  return AuthVisitorSharedLinkSigninResponseFromJSONTyped(json, false)
}

export function AuthVisitorSharedLinkSigninResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthVisitorSharedLinkSigninResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    visitor: !exists(json, 'visitor')
      ? undefined
      : AuthVisitorAuthenticationCodeRequestResponseVisitorFromJSON(json['visitor'])
  }
}

export function AuthVisitorSharedLinkSigninResponseToJSON(value?: AuthVisitorSharedLinkSigninResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    visitor: AuthVisitorAuthenticationCodeRequestResponseVisitorToJSON(value.visitor)
  }
}
