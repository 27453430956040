/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumChatSummaryRepliedStatus = {
  Unresponsive: 'unresponsive',
  NoReplyRequired: 'no_reply_required',
  Sent: 'sent'
} as const
export type EnumChatSummaryRepliedStatus =
  typeof EnumChatSummaryRepliedStatus[keyof typeof EnumChatSummaryRepliedStatus]

export function EnumChatSummaryRepliedStatusFromJSON(json: any): EnumChatSummaryRepliedStatus {
  return EnumChatSummaryRepliedStatusFromJSONTyped(json, false)
}

export function EnumChatSummaryRepliedStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumChatSummaryRepliedStatus {
  return json as EnumChatSummaryRepliedStatus
}

export function EnumChatSummaryRepliedStatusToJSON(value?: EnumChatSummaryRepliedStatus | null): any {
  return value as any
}
