import { createTheme, createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { lightBlue, grey } from '@material-ui/core/colors'
import { Colors } from 'src/components/atoms'

export const useGlobalStyles = makeStyles((_: Theme) =>
  createStyles({
    fontEmoji: {
      fontFamily:
        'apple color emoji,segoe ui emoji,noto color emoji,android emoji,emojisymbols,emojione mozilla,twemoji mozilla,segoe ui symbol'
    },
    scrollbar: {
      '&::-webkit-scrollbar': {
        width: '3px',
        height: '6px'
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '3px',
        boxShadow: 'none',
        backgroundColor: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '3px',
        background: 'rgba(0,0,0,0.4)'
      },
      '&::-webkit-scrollbar-button': {
        display: 'none',
        background: 'rgba(0,0,0,0.4)'
      },
      // 右下に出る角の部分の色を消している
      '&::-webkit-scrollbar-corner': {
        background: 'transparent'
      }
    },
    lineclamp1: {
      '-webkit-line-clamp': 1,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-all'
    },
    lineclamp2: {
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-all'
    },
    textLightGray: {
      color: '#B2B2B2'
    },
    cursorPointer: {
      cursor: 'pointer'
    },
    tooltip: {
      border: `1px solid ${Colors.background.gray}`,
      borderRadius: '4px',
      backgroundColor: Colors.functional.background.default,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)',
      cursor: 'pointer'
    },
    imgContainLeft: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      objectPosition: '0%'
    },
    imgContain: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    },
    imgCover: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    wordBreakAll: {
      wordBreak: 'break-all'
    }
  })
)

// sample theme
const theme = createTheme({
  typography: {
    // font family
    fontFamily: [
      'Helvetica Neue',
      'Arial',
      'Hiragino Kaku Gothic ProN',
      'Hiragino Sans',
      'BIZ UDPGothic',
      'Meiryo',
      'sans-serif'
    ].join(','),

    h1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: 1.5,
      letterSpacing: '0.02em'
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '21px',
      lineHeight: 1.5,
      letterSpacing: '0.02em'
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: 1.55,
      letterSpacing: '0.02em'
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: 1.7,
      letterSpacing: '0.04em'
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: 1.44,
      letterSpacing: '0.02em'
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: 1.32,
      letterSpacing: '0.02em'
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.32,
      letterSpacing: '0.04em'
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.32,
      letterSpacing: '0.02em'
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 1.7,
      letterSpacing: '0.04em'
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.7,
      letterSpacing: '0.04em'
    }
  },

  // 基本使用しない
  palette: {
    text: {
      primary: Colors.base.black
    },
    primary: {
      main: Colors.primary.default,
      contrastText: '#000'
    },
    secondary: {
      main: Colors.secondary.default,
      contrastText: '#000'
    },
    error: {
      main: Colors.error.default
    },
    background: {
      default: Colors.functional.background.default
    },
    action: {
      hoverOpacity: 0.8
    }
  },
  spacing: 4,
  overrides: {
    MuiListItemText: {
      root: {
        color: Colors.base.black
      }
    },
    MuiInputBase: {
      input: {
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '150%',
        fontWeight: 'normal',
        color: Colors.base.black,
        borderRadius: '4px',
        padding: '10px',
        '&::placeholder': {
          color: `black !important`
        }
      }
    },
    MuiRadio: {
      root: {
        padding: '0 !important'
      }
    },
    MuiInput: {
      root: {
        transition: '512ms',
        position: 'relative',
        borderRadius: '4px',
        border: `solid 1px ${grey[400]}`,
        '&:hover': {},
        '&.Mui-disabled': {}
      },
      inputMultiline: {
        padding: '10px'
      },
      underline: {
        '&:after': {
          display: 'none'
        },
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        border: 'none !important',
        backgroundColor: 'transparent',
        position: 'absolute',
        bottom: '-20px',
        left: '4px',
        '&.Mui-focused': {
          border: 'none',
          borderRadius: '0px'
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200
      }
    },
    MuiPickersCalendar: {
      week: {}
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.1) !important'
        }
      }
    },
    MuiPickersDatePickerRoot: {},
    MuiPickersCalendarHeader: {
      transitionContainer: {
        color: Colors.secondary.default
      },
      iconButton: {
        padding: '6px',
        '&:hover': {
          color: 'white'
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: Colors.secondary.default,
        '&:hover': {
          color: 'white'
        }
      },
      daySelected: {
        color: 'white'
      }
    },

    MuiPaper: {
      root: {
        margin: 'auto',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
      },
      elevation1: {
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
      },
      rounded: {
        borderRadius: '8px'
      }
    },
    MuiStepper: {
      horizontal: {
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
      },
      root: {}
    },
    MuiButton: {
      root: {
        minWidth: 'auto',
        '&:disabled': {
          color: '#eaeaea',
          backgroundColor: Colors.functional.disable.button
        }
      },
      label: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    MuiTableContainer: {
      root: {
        maxWidth: '1200px',
        margin: '60px auto',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
        width: '100%'
      }
    },
    MuiTableRow: {
      hover: {
        backgroundColor: 'red'
      }
    },

    MuiTableCell: {
      stickyHeader: {
        backgroundColor: 'white'
      },
      root: {
        backgroundColor: 'white',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: Colors.base.middleGray,
        borderBottom: `1px solid ${Colors.background.gray}`,
        padding: '0 8px !important'
      },
      body: {
        color: Colors.base.black,
        wordBreak: 'break-all',
        height: '40px'
      },
      head: {
        // height: '28px',
        fontSize: '12px',
        height: '100%',
        fontStyle: 'normal',
        fontWeight: 700,
        // lineHeight: '18px',
        letterSpacing: '0em',
        // textAlign: 'left',
        borderColor: Colors.background.silver,
        color: Colors.base.middleGray
        // borderRight: `1px solid ${Colors.background.silver}`
      }
    },
    MuiTooltip: {
      popper: {
        whiteSpace: 'pre-wrap'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '14px'
      }
    }
  }
})

export default theme
