import { Box } from '@material-ui/core'
import { useCallback, useState } from 'react'
import { Colors, SelectBox, ToggleSwitch, Typography } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { ModalConfirmToPaidPlan } from 'src/components/modals'
import { Header } from 'src/components/organisms'
import {
  useGetOrganization,
  useGetOrganizationTriggerOptionList,
  useUpdateTriggerEmail
} from 'src/fixtures/modules/organization/hooks'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
import { useGlobalStyles } from 'src/styles/theme'

export const PageTeamTriggerMail = () => {
  const globalClasses = useGlobalStyles()
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { hasPermission: isUpdatable } = useFeatureFlag('triggerEmail', 'update')
  const { data: organizationRes } = useGetOrganization()
  const isTriggerResentEmail = organizationRes?.organization?.isTriggerResentEmail
  const triggerResentTiming = organizationRes?.organization?.triggerResentTiming
  const { TriggerOptionList } = useGetOrganizationTriggerOptionList()
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const { handleUpdateTriggerEmail } = useUpdateTriggerEmail()

  const handleChangeTriggerResentTiming = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === '') return
    handleUpdateTriggerEmail({
      organization: { isTriggerResentEmail: true, triggerResentTiming: event.target.value as string }
    })
  }

  const handleChangeToggle = useCallback(() => {
    if (!planGrade?.plan?.canUseTriggerEmail) {
      setOpenModalUpgrade(true)
      return
    }
    handleUpdateTriggerEmail({
      organization: { isTriggerResentEmail: !isTriggerResentEmail }
    })
  }, [handleUpdateTriggerEmail, isTriggerResentEmail, planGrade?.plan?.canUseTriggerEmail])

  return (
    <>
      <LayoutTeam>
        <Box width={1} bgcolor={Colors.functional.background.default} px="16px" color={Colors.base.black}>
          <Header svgIcon="rocket" title="トリガーメール" back={false} textSize="large" iconSize="32px" />
          <Box px="16px">
            <Typography fontSize="s" letterSpacing="tight" fontWeight="normal" lineheight="23.8px">
              顧客に送付した資料が未開封の場合、設定した時間後に資料の開封を促すメールを自動送信します
            </Typography>
            <Box maxWidth="782px" display="flex" justifyContent="space-between" mt="40px">
              <Box>
                <Box width="260px" display="flex" alignItems="center" mb="28px">
                  <Typography fontSize="s" letterSpacing="tight" fontWeight="bold" lineheight="14px">
                    再送する
                  </Typography>
                  <Box mr="8px" />
                  <ToggleSwitch
                    checked={!!isTriggerResentEmail}
                    onChange={handleChangeToggle}
                    disabled={!isUpdatable}
                  />
                </Box>
                <Typography fontSize="s" letterSpacing="tight" fontWeight="bold" lineheight="14px">
                  メールの再送タイミングを選択
                </Typography>
                <Box mb="12px" />
                <SelectBox
                  size="medium"
                  placeholder="選択してください"
                  value={triggerResentTiming || ''}
                  optionList={TriggerOptionList || []}
                  onChange={handleChangeTriggerResentTiming}
                  disabled={!isUpdatable}
                />
              </Box>
              <Box height="260px">
                <img src="/images/team/trigger-mail.png" width="100%" className={globalClasses.imgContain} />
              </Box>
            </Box>
          </Box>
        </Box>
      </LayoutTeam>

      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、トリガーメール機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、トリガーメール機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
}
