/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateCompanyResponseData,
  CreateCompanyResponseDataFromJSON,
  CreateCompanyResponseDataFromJSONTyped,
  CreateCompanyResponseDataToJSON
} from './CreateCompanyResponseData'

/**
 *
 * @export
 * @interface CreateCompanyResponse
 */
export interface CreateCompanyResponse {
  /**
   *
   * @type {number}
   * @memberof CreateCompanyResponse
   */
  status?: number
  /**
   *
   * @type {CreateCompanyResponseData}
   * @memberof CreateCompanyResponse
   */
  data?: CreateCompanyResponseData
}

export function CreateCompanyResponseFromJSON(json: any): CreateCompanyResponse {
  return CreateCompanyResponseFromJSONTyped(json, false)
}

export function CreateCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompanyResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : CreateCompanyResponseDataFromJSON(json['data'])
  }
}

export function CreateCompanyResponseToJSON(value?: CreateCompanyResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CreateCompanyResponseDataToJSON(value.data)
  }
}
