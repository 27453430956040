import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { TrialBanner, TRIAL_BANNER_HEIGHT } from 'src/components/molecules'
import { SideMenu, SubSideMenu, MenuList, MIN_SIDE_MENU_SIZE } from 'src/components/organisms'
import { useGetOrganizationStatus } from 'src/fixtures/modules/organization/hooks'

export interface LayoutTeamProps {
  children: ReactNode
}

const menuList: MenuList = [
  {
    title: 'チーム',
    menuLists: [
      { label: 'チーム情報', path: '/setting/team/info', icon: 'roomPreferences' },
      { label: 'ユーザー管理', path: '/setting/team/member', icon: 'group' }
    ]
  },
  {
    title: '資料',
    menuLists: [{ label: 'チャットの表示設定', path: '/setting/team/chat', icon: 'chatBubble' }]
  },
  {
    title: 'メール',
    menuLists: [
      { label: 'メールテンプレート', path: '/setting/team/email_templates', icon: 'email' },
      { label: 'メール送信設定', path: '/setting/team/email_sender', icon: 'forwardToInbox' },
      { label: 'トリガーメール', path: '/setting/team/trigger_email', svgIcon: 'rocket' }
    ]
  },
  {
    title: 'フォーム',
    menuLists: [{ label: 'ポリシーの表示設定', path: '/setting/team/policy', icon: 'localPolice' }]
  },
  {
    title: '顧客管理',
    menuLists: [
      { label: '会社の項目', path: '/setting/team/company', icon: 'listAlt' },
      { label: '連絡先の項目', path: '/setting/team/contact', icon: 'listAlt' }
    ]
  },
  {
    title: 'プラン表&ご契約内容',
    menuLists: [
      { label: 'プラン表', path: '/setting/team/pricing', icon: 'map' },
      { label: 'ご契約内容', path: '/setting/team/contract', icon: 'payment', permissions: ['administrator'] }
    ]
  },
  {
    title: '管理者設定',
    permissions: ['administrator'],
    menuLists: [{ label: 'サービスのご解約', path: '/setting/team/contract/cancel', icon: 'exit' }]
  }
]

export const LayoutTeam: React.VFC<LayoutTeamProps> = ({ children }) => {
  const { isTrialing, organization } = useGetOrganizationStatus()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isTrialing && <TrialBanner trialEndAt={organization?.trialEndAt ?? undefined} />}
      <Box height="100%" maxWidth="100vw" pl={`${MIN_SIDE_MENU_SIZE}px`} style={{ overflowX: 'hidden' }} display="flex">
        <Box
          position="fixed"
          height="100%"
          top={0}
          left={0}
          pt={isTrialing ? `${TRIAL_BANNER_HEIGHT}px` : 0}
          zIndex={1000}
        >
          <SideMenu />
        </Box>
        <SubSideMenu menuList={menuList} />
        {children}
      </Box>
    </Box>
  )
}
