/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner
 */
export interface UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner {
  /**
   *
   * @type {string}
   * @memberof UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner
   */
  id?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner
   */
  _delete?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner
   */
  sort?: number
}

export function UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerFromJSON(
  json: any
): UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner {
  return UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerFromJSONTyped(json, false)
}

export function UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    _delete: !exists(json, 'delete') ? undefined : json['delete'],
    name: !exists(json, 'name') ? undefined : json['name'],
    sort: !exists(json, 'sort') ? undefined : json['sort']
  }
}

export function UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInnerToJSON(
  value?: UpdateContactSettingRequestContactSettingFieldsInnerContactSettingFieldItemsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    delete: value._delete,
    name: value.name,
    sort: value.sort
  }
}
