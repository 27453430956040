import { Box, makeStyles, createStyles, Typography, ButtonBase } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Toaster } from '..'
import Link from 'next/link'
import { useMemo, ReactElement, useCallback } from 'react'
import { OpenInNew } from '@material-ui/icons'
import CopyToClipboard from 'react-copy-to-clipboard'

export interface InformationRowProps {
  variant?: string
  label: string
  content: string
  contentColor?: string
  icon?: ReactElement
  disableCopy?: boolean
  margin?: string
  padding?: string
  link?: string
}

type InformationRowStyleProps = Pick<InformationRowProps, 'contentColor' | 'disableCopy' | 'margin' | 'padding'>

const useStyles = makeStyles(() => {
  return createStyles({
    body: {
      position: 'relative',
      borderBottom: `1px solid ${Colors.background.gray}`,
      padding: ({ padding = '4px 0px' }: InformationRowStyleProps) => padding,
      borderRadius: '4px',
      margin: ({ margin = '0px' }: InformationRowStyleProps) => margin,

      '& .copy': {
        position: 'absolute',
        top: '2px',
        right: '10px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: Colors.functional.background.default,
        border: `1px solid ${Colors.background.silver}`,
        borderRadius: '4px',
        color: Colors.base.middleGray,
        opacity: 0,
        cursor: 'pointer'
      },
      '&:hover': {
        background: ({ disableCopy }: InformationRowStyleProps) =>
          !disableCopy ? Colors.hover.white.default : undefined,
        '& .copy': {
          opacity: 1
        }
      }
    },
    labelBody: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      width: '124px',
      marginRight: '10px'
    },
    label: {
      color: Colors.base.middleGray,
      lineHeight: 'normal'
    },
    content: {
      color: ({ contentColor = Colors.base.black }: InformationRowStyleProps) => contentColor,
      overflowWrap: 'anywhere'
    },
    linkWrapper: {
      cursor: 'pointer'
    },
    link: {
      color: Colors.accent.keyBlue.default,
      fontSize: 14,
      lineHeight: 1.32,
      letterSpacing: '4%',
      wordBreak: 'break-all',
      textDecoration: 'none'
    }
  })
})

export const InformationRow = ({
  variant = 'plain',
  label,
  content,
  contentColor,
  icon,
  disableCopy = false,
  margin,
  padding,
  link = ''
}: InformationRowProps) => {
  const classes = useStyles({ contentColor, disableCopy, margin, padding })

  const isExternalLink = useMemo(() => {
    return /https|https/.test(content)
  }, [content])

  const handleCopy = useCallback((text, result) => {
    if (result && text) {
      Toaster.success(`「${text}」をクリップボードにコピーしました。`)
    } else {
      Toaster.error('コピーに失敗しました。リロードして再度お試しください。')
    }
  }, [])

  return (
    <Box className={classes.body} display="flex">
      <Box className={classes.labelBody}>
        <Typography variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
      </Box>
      {(variant === 'plain' || variant === 'company_name' || variant === 'phone_number') && (
        <Typography variant="subtitle1" className={classes.content}>
          {content}
        </Typography>
      )}
      {variant === 'url' &&
        (isExternalLink ? (
          <Box lineHeight={1.32} className={classes.linkWrapper} onClick={() => window.open(link, '_blank')}>
            <a className={classes.link}>{content}</a>
          </Box>
        ) : content ? (
          <Link href={link}>
            <a className={classes.link}>{content}</a>
          </Link>
        ) : (
          <Typography variant="subtitle1" className={classes.content}>
            {content}
          </Typography>
        ))}
      {variant === 'textWithIcon' && (
        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center" mr="6px">
            {icon}
          </Box>
          <Typography variant="subtitle1" className={classes.content}>
            {content}
          </Typography>
        </Box>
      )}
      {variant === 'address' && (
        <Box
          lineHeight={1.32}
          className={classes.linkWrapper}
          onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${content}`, '_blank')}
        >
          <Typography variant="subtitle1" className={classes.content}>
            {content}
          </Typography>
          {variant === 'address' && content && (
            <Box display="flex" alignItems="center">
              <OpenInNew className={classes.link} fontSize="small" />
              <Typography variant="subtitle1" className={classes.link}>
                地図を表示
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {!disableCopy && (
        <CopyToClipboard text={content} onCopy={handleCopy}>
          <ButtonBase className="copy">
            <DynamicMuiIcon variant="copy" size="13px" />
          </ButtonBase>
        </CopyToClipboard>
      )}
    </Box>
  )
}
