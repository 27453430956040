/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { DiscardContactMemoResponseFromJSON, UpdateContactMemoRequestToJSON, UpdateContactMemoResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ContactMemoApi extends runtime.BaseAPI {
    /**
     */
    async userV1ContactsContactIdMemosContactMemoIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDelete.');
        }
        if (requestParameters.contactMemoId === null || requestParameters.contactMemoId === undefined) {
            throw new runtime.RequiredError('contactMemoId', 'Required parameter requestParameters.contactMemoId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/memos/{contact_memo_id}`
                .replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId)))
                .replace(`{${'contact_memo_id'}}`, encodeURIComponent(String(requestParameters.contactMemoId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async userV1ContactsContactIdMemosContactMemoIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdMemosContactMemoIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1ContactsContactIdMemosContactMemoIdDiscardPutRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDiscardPut.');
        }
        if (requestParameters.contactMemoId === null || requestParameters.contactMemoId === undefined) {
            throw new runtime.RequiredError('contactMemoId', 'Required parameter requestParameters.contactMemoId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdDiscardPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/memos/{contact_memo_id}/discard`
                .replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId)))
                .replace(`{${'contact_memo_id'}}`, encodeURIComponent(String(requestParameters.contactMemoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => DiscardContactMemoResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1ContactsContactIdMemosContactMemoIdDiscardPut(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdMemosContactMemoIdDiscardPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1ContactsContactIdMemosContactMemoIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdPut.');
        }
        if (requestParameters.contactMemoId === null || requestParameters.contactMemoId === undefined) {
            throw new runtime.RequiredError('contactMemoId', 'Required parameter requestParameters.contactMemoId was null or undefined when calling userV1ContactsContactIdMemosContactMemoIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/memos/{contact_memo_id}`
                .replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId)))
                .replace(`{${'contact_memo_id'}}`, encodeURIComponent(String(requestParameters.contactMemoId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactMemoRequestToJSON(requestParameters.updateContactMemoRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateContactMemoResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1ContactsContactIdMemosContactMemoIdPut(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdMemosContactMemoIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
