import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Word = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#0263D1" />
      <path
        d="M13.964 14.6279H17.024L19.058 22.7999H19.094L21.758 14.6279H24.368L27.014 23.0159H27.05L29.192 14.6279H32.036L28.31 27.3719H25.808L23 18.5519H22.964L20.156 27.3719H17.744L13.964 14.6279Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Word
