import { Box, createStyles, LinearProgress, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { useVirtualProgress } from 'src/fixtures/utils/progress'
import { Card } from '../Card'
import { DeleteCardProps } from '../type'

export interface UploadingCardProps {
  title: string
  onDelete: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    colorPrimary: {
      backgroundColor: '#D9D9D9'
    },
    barColorPrimary: {
      backgroundColor: Colors.option.green
    }
  })
)

export const UploadingCard = ({ title, onDelete }: UploadingCardProps) => {
  const classes = useStyles()
  const { progress } = useVirtualProgress()
  const deleteProps = useMemo<DeleteCardProps>(() => {
    return { isDeletable: !!onDelete, onDelete: onDelete }
  }, [onDelete])

  return (
    <Card deleteProps={deleteProps}>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '46px',
            height: '42px',
            borderRadius: '4px',
            marginRight: '10px',
            bgcolor: Colors.background.lightGray
          }}
        >
          <DynamicMuiIcon variant="fileUpload" htmlColor={Colors.base.middleGray} size="30px" />
        </Box>

        <Box sx={{ overflow: 'hidden' }}>
          <Box sx={{ color: Colors.base.black, mb: '4px' }}>
            <Typography fontSize="s" letterSpacing="tight" lh="tight" noWrap>
              {title}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={progress}
            classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
          />
        </Box>
      </Box>
    </Card>
  )
}
