/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumChatSummaryRepliedStatusFromJSON, EnumChatSummaryRepliedStatusToJSON } from './EnumChatSummaryRepliedStatus';
export function InboxFilterChatSummaryStatusesInnerFromJSON(json) {
    return InboxFilterChatSummaryStatusesInnerFromJSONTyped(json, false);
}
export function InboxFilterChatSummaryStatusesInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        key: !exists(json, 'key') ? undefined : EnumChatSummaryRepliedStatusFromJSON(json['key']),
        text: !exists(json, 'text') ? undefined : json['text']
    };
}
export function InboxFilterChatSummaryStatusesInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        key: EnumChatSummaryRepliedStatusToJSON(value.key),
        text: value.text
    };
}
