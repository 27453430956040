import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React, { FormEvent, ReactNode, useCallback, useMemo } from 'react'
import { Colors, Tooltip } from 'src/components/atoms'
import { SvgIcon, Button, Typography } from '../../atoms'

interface SlideFormProps {
  headerTitle: string
  hideSettings?: boolean
  children: ReactNode
  buttonTitle?: string
  buttonDisabled?: boolean
  cancelButtonTitle?: string
  headerSubTitle?: string
  isSubmitting?: boolean
  hideSubmitButton?: boolean
  toolTipText?: string
  isSubmitEnd?: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
  onClickSettings?: () => void
  clearSubmitEnd?: () => void
  isSending?: boolean
  progressCompleted?: boolean
  submitStatus?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    closeButton: {
      padding: '22px'
    }
  })
)

export const SlideForm = ({
  headerTitle,
  hideSettings = false,
  children,
  buttonTitle,
  buttonDisabled = false,
  cancelButtonTitle = 'キャンセル',
  headerSubTitle,
  isSubmitting,
  hideSubmitButton = false,
  toolTipText = '',
  isSubmitEnd,
  onClose,
  onCancel,
  onConfirm,
  onClickSettings,
  clearSubmitEnd,
  isSending = false,
  progressCompleted = false,
  submitStatus
}: SlideFormProps) => {
  const classes = useStyles()
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      onConfirm()
    },
    [onConfirm]
  )

  //ボタンのdisabled判定をメモ化
  const disabledStatus = useMemo(() => {
    return buttonDisabled || isSubmitting || (submitStatus !== undefined && !submitStatus)
  }, [buttonDisabled, isSubmitting, submitStatus])

  return (
    <Box width={1} height={1} display="flex" flexDirection="column" bgcolor={Colors.functional.background.default}>
      <Box
        height="60px"
        boxShadow={`inset 0px -1px 0px ${Colors.background.silver}`}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pl="24px"
        pr="8px"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h3">{headerTitle}</Typography>
          {headerSubTitle && (
            <Box ml="16px">
              <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                {headerSubTitle}
              </Typography>
            </Box>
          )}
        </Box>
        {!isSending && (
          <ButtonBase onClick={onClose} className={classes.closeButton}>
            <SvgIcon variant="close" size="16px" color={Colors.base.middleGray} />
          </ButtonBase>
        )}
      </Box>
      <Box height={1} overflow="auto">
        {children}
      </Box>
      <Box
        boxShadow={`inset 0px 1px 0px ${Colors.background.gray}`}
        bgcolor={Colors.background.lightGray}
        height="58px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="24px"
      >
        <Box>
          {!hideSettings && (
            <>
              {toolTipText ? (
                <Tooltip content={toolTipText}>
                  <Button kind="secondary" variant="outlined" title="項目設定" onClick={onClickSettings} />
                </Tooltip>
              ) : (
                <Button kind="secondary" variant="outlined" title="項目設定" onClick={onClickSettings} />
              )}
            </>
          )}
        </Box>
        {!isSending && !progressCompleted && (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="center">
              <Button
                kind="neutral"
                variant="outlined"
                title={cancelButtonTitle}
                disabled={isSubmitting}
                onClick={onCancel}
              />

              {!hideSubmitButton && (
                <>
                  <Box width="12px" />
                  <Button
                    type="submit"
                    title={buttonTitle ?? '保存'}
                    disabled={disabledStatus}
                    completed={isSubmitEnd}
                    clearCompleted={clearSubmitEnd}
                  />
                </>
              )}
            </Box>
          </form>
        )}
      </Box>
    </Box>
  )
}
