/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnumOrganizationPolicySettingNameFromJSON, EnumOrganizationPolicySettingNameToJSON } from './EnumOrganizationPolicySettingName';
export function GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSON(json) {
    return GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSONTyped(json, false);
}
export function GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: EnumOrganizationPolicySettingNameFromJSON(json['name']),
        label: json['label'],
        url: json['url']
    };
}
export function GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: EnumOrganizationPolicySettingNameToJSON(value.name),
        label: value.label,
        url: value.url
    };
}
