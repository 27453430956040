/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumSettingSettingType = {
  Plain: 'plain',
  ContactName: 'contact_name',
  CompanyName: 'company_name',
  Email: 'email',
  Address: 'address',
  Url: 'url',
  Company: 'company',
  Domain: 'domain',
  PhoneNumber: 'phone_number'
} as const
export type EnumSettingSettingType = typeof EnumSettingSettingType[keyof typeof EnumSettingSettingType]

export function EnumSettingSettingTypeFromJSON(json: any): EnumSettingSettingType {
  return EnumSettingSettingTypeFromJSONTyped(json, false)
}

export function EnumSettingSettingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumSettingSettingType {
  return json as EnumSettingSettingType
}

export function EnumSettingSettingTypeToJSON(value?: EnumSettingSettingType | null): any {
  return value as any
}
