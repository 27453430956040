import {
  UserV1ChatSummariesGetRequest,
  UserV1ChatSummariesCountGetRequest,
  UserV1ChatSummariesSendedByUserGetRequest,
  EnumChatSummaryRepliedStatus
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import useSWR from 'swr'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'
import { useGetMe } from '../me/hooks'
import { SWRConfiguration } from 'swr/dist/types'

export const useListChatSummaries = (params: UserV1ChatSummariesGetRequest, config?: SWRConfiguration) => {
  const { data: auth } = useAuthenticate()
  const chatSummaryApi = nocoSDK.client?.userService.chatSummaryApi
  const func = useCallback(async () => {
    if (!auth) return
    return await chatSummaryApi
      ?.ApiFactory(auth.token)
      .userV1ChatSummariesGet(params)
      .then(res => res.data)
  }, [auth, chatSummaryApi, params])
  return useSWR(auth?.token && SWRCachePath.listChatSummaries(params), func, config)
}

export const useListChatSummariesCount = (params: UserV1ChatSummariesCountGetRequest, config?: SWRConfiguration) => {
  const { data: auth } = useAuthenticate()
  const chatSummaryApi = nocoSDK.client?.userService.chatSummaryApi
  const func = useCallback(async () => {
    if (!auth) return

    return await chatSummaryApi
      ?.ApiFactory(auth.token)
      .userV1ChatSummariesCountGet(params)
      .then(res => res.data)
  }, [auth, chatSummaryApi, params])
  return useSWR(auth?.token && SWRCachePath.listChatSummariesCount(params), func, config)
}

export const useListChatSummariesSendedByUser = (
  params: UserV1ChatSummariesSendedByUserGetRequest,
  config?: SWRConfiguration
) => {
  const { data: auth } = useAuthenticate()
  const chatSummaryApi = nocoSDK.client?.userService.chatSummaryApi
  const func = useCallback(async () => {
    if (!auth) return

    return await chatSummaryApi
      ?.ApiFactory(auth.token)
      .userV1ChatSummariesSendedByUserGet(params)
      .then(res => res.data)
  }, [auth, chatSummaryApi, params])
  return useSWR(auth?.token && SWRCachePath.listChatSummariesSendedByUser(params), func, config)
}

export const useChatSummariesReplied = (params: UserV1ChatSummariesGetRequest, config?: SWRConfiguration) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListChatSummaries } = useListChatSummaries(params, config)
  const { page, per, ...newParams } = params
  const { mutate: mutateListChatSummariesCount } = useListChatSummariesCount(
    {
      ...newParams,
      repliedStatus: EnumChatSummaryRepliedStatus.Unresponsive
    },
    config
  )
  const chatSummaryApi = nocoSDK.client?.userService.chatSummaryApi

  const handleUpdateChatSummariesReplied = useCallback(
    async (ids: string[], repliedStatus: EnumChatSummaryRepliedStatus) => {
      try {
        setError(undefined)
        if (!auth || !me) return
        setIsLoading(true)

        await chatSummaryApi?.ApiFactory(auth.token).userV1ChatSummariesRepliedPut({
          updateChatSummariesRepliedRequest: { ids, repliedStatus }
        })

        await mutateListChatSummaries(), mutateListChatSummariesCount()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update ChatSummariesReplied' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, chatSummaryApi, me, mutateListChatSummaries, mutateListChatSummariesCount]
  )

  return { error, isLoading, handleUpdateChatSummariesReplied }
}

export const useChatSummariesChangeUser = (
  params: UserV1ChatSummariesGetRequest | UserV1ChatSummariesSendedByUserGetRequest,
  type: string,
  config?: SWRConfiguration
) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListChatSummaries } = useListChatSummaries(params, config)
  const { mutate: mutateListSendedByUser } = useListChatSummariesSendedByUser(params, config)
  const { page, per, ...newParams } = params
  const { mutate: mutateListChatSummariesCount } = useListChatSummariesCount(
    {
      ...newParams,
      repliedStatus: EnumChatSummaryRepliedStatus.Unresponsive
    },
    config
  )
  const chatSummaryApi = nocoSDK.client?.userService.chatSummaryApi

  const handleUpdateChatSummariesChangeUser = useCallback(
    async (ids: string[], userId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await chatSummaryApi?.ApiFactory(auth.token).userV1ChatSummariesChangeUserPut({
          updateChatSummariesChangeUserRequest: { ids, userId }
        })
        if (type === 'incoming') {
          await mutateListChatSummaries()
        } else {
          await mutateListSendedByUser()
        }
        await mutateListChatSummariesCount()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update ChatSummariesChangeUser' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, chatSummaryApi, me, type, mutateListChatSummaries, mutateListSendedByUser, mutateListChatSummariesCount]
  )

  return { error, isLoading, handleUpdateChatSummariesChangeUser }
}
