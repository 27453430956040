/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationUserEmailSettingFromJSON, OrganizationUserEmailSettingToJSON } from './OrganizationUserEmailSetting';
export function UpdateOrganizationUserEmailSettingResponseDataFromJSON(json) {
    return UpdateOrganizationUserEmailSettingResponseDataFromJSONTyped(json, false);
}
export function UpdateOrganizationUserEmailSettingResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organizationUserEmailSetting: !exists(json, 'organization_user_email_setting')
            ? undefined
            : OrganizationUserEmailSettingFromJSON(json['organization_user_email_setting'])
    };
}
export function UpdateOrganizationUserEmailSettingResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization_user_email_setting: OrganizationUserEmailSettingToJSON(value.organizationUserEmailSetting)
    };
}
