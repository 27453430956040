import { createStyles, makeStyles, OutlinedInputProps, TextField, InputProps } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors } from '../Colors'

export interface TextareaProps {
  value: string
  minRows?: number
  maxRows?: number
  onChange: OutlinedInputProps['onChange']
  name?: string
  placeholder?: string
  multiline?: boolean
  variant?: 'standard' | 'outlined'
  minHeight?: string | number
}

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      '& fieldset': {
        borderRadius: '4px',
        border: `1px solid ${Colors.background.silver}`
      },
      '& .MuiOutlinedInput-root': {
        padding: '10px 12px !important',
        display: 'flex',
        alignItems: 'flex-start',
        '&.Mui-focused fieldset': {
          border: `1px solid ${Colors.accent.keyPurple.default}`
        },
        '&:hover fieldset': {
          border: `1px solid ${Colors.accent.keyPurple.default}`
        }
      }
    },
    root: {
      border: 'none',
      minHeight: ({ minHeight = '37px' }: { minHeight?: string | number }) => minHeight
    },
    inputMultiline: {
      paddingTop: 0,
      paddingBottom: 0
    }
  })
)

export const Textarea = ({
  minRows = 2,
  maxRows = 4,
  value,
  onChange,
  name,
  placeholder,
  multiline = false,
  variant,
  minHeight
}: TextareaProps) => {
  const classes = useStyles({ minHeight })

  const inputProps: Partial<InputProps> = useMemo(() => {
    return {
      classes: {
        root: classes.root,
        inputMultiline: classes.inputMultiline
      }
    }
  }, [classes])

  return (
    <TextField
      className={classes.textField}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      fullWidth
      variant={variant}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      InputProps={inputProps}
    />
  )
}
