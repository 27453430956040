import React, { ReactElement, useState, useCallback } from 'react'
import { Box, createStyles, makeStyles, IconButton } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography, MuiIconVariant } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import clsx from 'clsx'

export interface LabelProps {
  id?: string
  label: string | undefined
  icon?: MuiIconVariant
  active?: boolean
  rightComponent?: React.ReactNode
  indent?: boolean
  avatar?: ReactElement
  large?: boolean
  border?: boolean
  isPin?: boolean
  showPinIcon?: boolean
  align?: string
  unit?: boolean
  disableHover?: boolean
  onClick?: () => void
  onSetPin?: (id: string) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    activeBox: {
      backgroundColor: Colors.selected.background,
      color: Colors.primary.default,
      '&:hover': {
        backgroundColor: Colors.selected.background,
        color: Colors.primary.default
      }
    },
    normalFont: {
      fontSize: '14px',
      wordBreak: 'break-all',
      color: Colors.base.black,
      '&:hover': {
        backgroundColor: ({ disableHover = false }: { disableHover?: boolean }) =>
          !disableHover ? Colors.selected.background : undefined,
        color: ({ disableHover = false }: { disableHover?: boolean }) =>
          !disableHover ? `${Colors.primary.default} !important` : undefined,
        cursor: ({ disableHover = false }: { disableHover?: boolean }) => (!disableHover ? 'pointer' : undefined)
      }
    },
    activeFont: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '14px',
      wordBreak: 'break-all'
    },
    labelBox: {
      width: '100%',
      '&:hover': {
        backgroundColor: Colors.selected.background,
        color: Colors.primary.default,
        cursor: 'pointer'
      }
    }
  })
})

export const Label = ({
  id,
  label,
  icon,
  active,
  rightComponent,
  avatar,
  large,
  border,
  isPin,
  showPinIcon,
  align,
  unit,
  disableHover,
  onClick,
  onSetPin
}: LabelProps) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles({ disableHover })

  const [enablePin, setEnabledPin] = useState(isPin)
  const handleSetPin = useCallback(
    id => () => {
      setEnabledPin(!enablePin)
      onSetPin?.(id)
    },
    [enablePin, onSetPin]
  )

  return (
    <>
      <Box
        width="100%"
        py={large ? '8px' : '4px'}
        px={large ? '0' : '14px'}
        className={clsx(`${active && classes.activeBox} ${!disableHover && classes.labelBox}`, 'childItem')}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        color="#6D7784"
      >
        <Box display="flex" alignItems="center" flex={1} onClick={onClick}>
          {showPinIcon && (
            <Box mr="10px" width="20px" height="20px" display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={handleSetPin(id)}>
                <DynamicMuiIcon variant="pushPin" size="20px" color={enablePin ? 'action' : 'disabled'} />
              </IconButton>
            </Box>
          )}
          {icon != 'pushPin' && icon && (
            <Box mr="10px" width="20px" height="20px" display="flex" justifyContent="center" alignItems="center">
              <DynamicMuiIcon variant={icon} size="20px" />
            </Box>
          )}
          {avatar && avatar}
          <Box flex={1}>
            <Typography className={`${active ? classes.activeFont : classes.normalFont} ${globalClasses.lineclamp1}`}>
              {label}
            </Typography>
          </Box>
        </Box>
        <Box
          width={unit ? 'auto' : '20px'}
          height="20px"
          display="flex"
          paddingRight="2px"
          justifyContent={align === 'flex-end' ? 'center' : 'flex-end'}
          alignItems="center"
          color={Colors.base.middleGray}
        >
          {rightComponent}
          {unit && (
            <Typography fontSize="s" lineheight="18.48px" fontWeight="bold">
              件
            </Typography>
          )}
        </Box>
      </Box>
      {border && <Box borderBottom={`1px solid ${Colors.background.silver}`} />}
    </>
  )
}
