import React, { ChangeEvent, Component, useCallback, useEffect, useRef, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import styled from '@emotion/styled'
import clsx from 'clsx'
import { AppBar, Box, createStyles, makeStyles, Tab, Typography, TextareaAutosize, BoxProps } from '@material-ui/core'
import { Button, Colors, Tooltip } from 'src/components/atoms'
import { MessageRoom } from 'src/components/modals/ModalClientDetail/MessageRoom'
import {
  useCreateTimeLineMemo,
  useListContactExpiredMaterials,
  useListContactMaterials,
  useListContactTimelines,
  useUpdateTimeLineMemo,
  useListUserContactChatMessages,
  useDiscardTimeLineMemo
} from 'src/fixtures/modules/contact/hooks'
import { BrowsingHistoryItem } from 'src/components/molecules/BrowsingHistoryItem'
import { ClientTimeLineItem } from 'src/components/molecules/ClientTimeLineItem'
import { ModalExpiryUpdate } from 'src/components/modals'
import { useExpiredDocumentContact } from 'src/fixtures/modules/documentContact/hooks'
import { ContactMaterial, DocumentContact, EnumMaterialableType, SiteContact } from '@noco/http-client/lib/noco'
import { useExpiredSiteContact } from 'src/fixtures/modules/site/hooks'
import { useConfirmDiscard } from 'src/fixtures/utils/url'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.functional.background.default,
      boxShadow: 'none',
      borderBottom: `1px solid ${Colors.background.silver}`
    },
    tablist: {
      minHeight: 'auto !important',
      padding: '10px 10px 0'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      minWidth: 'auto',
      padding: '6px 16px',
      borderRadius: '6px 6px 0px 0px',
      color: Colors.base.middleGray,
      '&.Mui-selected': {
        color: Colors.accent.keyPurple.default,
        background: Colors.selected.background
      }
    },
    tabPanel: {
      height: '100%'
    },
    scrollableTabPanel: {
      overflow: 'hidden auto',
      height: '100%',
      bgcolor: Colors.background.gray
    },
    timelineTabPanel: {
      height: '100%',
      padding: '12px 12px 0 12px'
    },
    timelineWrap: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: Colors.functional.background.default,
      height: '100%'
    },
    timeline: {
      overflow: 'hidden auto',
      flex: 1
    },
    timeLineItemWrap: {
      '&::after': {
        position: 'absolute',
        content: '""',
        height: 12,
        width: 4,
        background: Colors.background.silver,
        left: 20
      }
    },
    memoInputWrap: {
      border: `1px solid ${Colors.background.silver}`,
      borderRadius: 4,
      marginTop: 'auto',
      maxHeight: '60%',
      overflow: 'hidden',
      flex: '0 1 auto',
      display: 'flex',
      flexDirection: 'column'
    },
    textarea: {
      overflow: 'hidden auto !important',
      maxHeight: '100% !important',
      resize: 'vertical',
      width: '100%',
      border: 'none',
      padding: '12px 10px',
      color: Colors.base.black,
      backgroundColor: 'inherit',
      fontSize: '14px',
      lineHeight: '18,48px !important',
      letterSpacing: '4% !important',
      '&:focus': {
        outline: 'none'
      },
      '&::placeholder': {
        color: Colors.base.placeHolder
      },
      '&::-webkit-resizer': {
        display: 'none'
      }
    },
    icon: {
      display: 'inline-flex',
      height: 16,
      width: 16,
      verticalAlign: 'text-bottom',
      marginLeft: '2px'
    }
  })
})

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

export type TabValueType = 'chat' | 'contents' | 'timeline'

interface ContactDetailContentProps {
  contactId: string
  tab: TabValueType
  handleChangeTab: (event: ChangeEvent<{}>, newValue: TabValueType) => void
  onClickShare?: () => void
}

export const ContactDetailContent = ({ contactId, tab, handleChangeTab, onClickShare }: ContactDetailContentProps) => {
  const classes = useStyles()

  const timelineBoxRef = useRef<Component<BoxProps> | null>(null)
  const chatBoxRef = useRef<Component<BoxProps> | null>(null)
  const [memo, changeMemo] = useState('')
  const [targetMaterilableId, setTargetMaterilableId] = useState<string | undefined>(undefined)
  const { data: materialsRes } = useListContactMaterials(contactId)
  const { data: timelineRes } = useListContactTimelines(contactId)
  const { data: chatMessagesRes } = useListUserContactChatMessages(contactId)
  const { data: contactExpiredMaterialsRes } = useListContactExpiredMaterials({ contactId })
  const { handleCreateTimeLineMemo, isLoading } = useCreateTimeLineMemo(contactId)
  const { handleDiscardTimeLineMemo } = useDiscardTimeLineMemo(contactId)
  const { handleUpdateTimeLineMemo } = useUpdateTimeLineMemo(contactId)
  const { handleExpiredDocumentContact } = useExpiredDocumentContact(contactId)
  const { handleExpiredSiteContact } = useExpiredSiteContact(contactId)
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm)

  const handleExpiryConfirm = ({ canViewable, expiredOn }: DocumentContact | SiteContact) => {
    const targetMaterilable = contactExpiredMaterialsRes?.contactMaterials?.find(
      item => item.materialableId === targetMaterilableId
    )
    if (targetMaterilable) {
      if (targetMaterilable?.materialableType === EnumMaterialableType.Document) {
        handleExpiredDocumentContact({
          id: targetMaterilable.materialContactId,
          documentId: targetMaterilableId,
          canViewable,
          expiredOn
        })
      } else {
        handleExpiredSiteContact({
          siteId: targetMaterilableId,
          contactId: targetMaterilable.materialContactId,
          canViewable,
          expiredOn
        })
      }
      setTargetMaterilableId(undefined)
    }
  }

  const handleOpenModal = useCallback((item: ContactMaterial) => {
    setTargetMaterilableId(item.materialableId!)
  }, [])

  const onCreateMemo = useCallback(() => {
    handleCreateTimeLineMemo(memo)
    changeMemo('')
    setShowConfirm(false)
  }, [memo, changeMemo, handleCreateTimeLineMemo])

  const onViewMoreMaterials = useCallback(() => {
    handleChangeTab({} as ChangeEvent<{}>, 'contents')
  }, [handleChangeTab])

  useEffect(() => {
    if (tab === 'timeline') {
      const devTimelineBoxRef: HTMLDivElement = timelineBoxRef.current as unknown as HTMLDivElement
      devTimelineBoxRef.scrollTop = devTimelineBoxRef.scrollHeight
    }
    if (tab === 'chat' && chatMessagesRes?.chatMessages?.length) {
      const devChatBoxRef: HTMLDivElement = chatBoxRef.current as unknown as HTMLDivElement
      devChatBoxRef.scrollTop = devChatBoxRef.scrollHeight
    }
  }, [tab, chatMessagesRes?.chatMessages])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', bgcolor: Colors.background.lightGray }}>
      <TabContext value={tab}>
        <AppBar position="relative" className={classes.root}>
          <TabList
            onChange={handleChangeTab}
            aria-label="contact detail tablist"
            indicatorColor="primary"
            classes={{ root: classes.tablist }}
          >
            <Tab
              value="chat"
              label={
                <Tooltip content={'顧客とのチャットメッセージの履歴を表示します。'}>
                  <Typography variant="h5">チャット</Typography>
                </Tooltip>
              }
              className={classes.tab}
              {...a11yProps(0)}
            />
            <Tab
              value="timeline"
              label={
                <Tooltip content={'顧客の行動履歴や変更履歴を時系列で表示します。'}>
                  <Typography variant="h5">タイムライン</Typography>
                </Tooltip>
              }
              className={classes.tab}
              {...a11yProps(2)}
            />
            <Tab
              value="contents"
              label={
                <Tooltip
                  content={
                    '顧客の資料および資料サイトの閲覧履歴を表示します。また、資料上でのCTA（ポップアップ形式のバナー）やアポイントのクリック有無も確認できます。'
                  }
                >
                  <Typography variant="h5">閲覧履歴</Typography>
                </Tooltip>
              }
              className={classes.tab}
              {...a11yProps(1)}
            />
          </TabList>
        </AppBar>
        <Box overflow="hidden" flexGrow={1}>
          <TabPanel className={classes.tabPanel} value="chat">
            <MessageRoom
              ref={chatBoxRef}
              chatMessages={chatMessagesRes?.chatMessages ?? []}
              contactId={contactId}
              contactMaterials={contactExpiredMaterialsRes?.contactMaterials ?? []}
              onOpenUpdateExpiredOnModal={setTargetMaterilableId}
              onViewMoreMaterials={onViewMoreMaterials}
              onClickShare={onClickShare}
              width="378px"
            />
          </TabPanel>

          <TabPanel className={classes.timelineTabPanel} value="timeline">
            <Box p={4} className={classes.timelineWrap}>
              <TimelineBox ref={timelineBoxRef} className={classes.timeline}>
                {timelineRes?.contactTimelines?.map((item, index) => (
                  <Box
                    position="relative"
                    key={item.id}
                    mb={3}
                    className={clsx({
                      [classes.timeLineItemWrap]: (timelineRes?.contactTimelines?.length ?? 0) !== index + 1
                    })}
                  >
                    <ClientTimeLineItem
                      item={item}
                      onDelete={handleDiscardTimeLineMemo}
                      onEdit={handleUpdateTimeLineMemo}
                      type="contact"
                    />
                  </Box>
                ))}
              </TimelineBox>
              <Box className={classes.memoInputWrap}>
                <TextareaAutosize
                  placeholder="メモを入力（例：週明けにカタログを送付する）"
                  value={memo}
                  onChange={({ target: { value } }) => {
                    changeMemo(value)
                    setShowConfirm(true)
                  }}
                  minRows={1}
                  disabled={isLoading}
                  className={classes.textarea}
                />
                <Box display="flex" justifyContent="flex-end" p={3} borderTop={`1px solid ${Colors.background.gray}`}>
                  <Button title="メモを追加" onClick={onCreateMemo} />
                </Box>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel className={classes.scrollableTabPanel} value="contents">
            <Box height={1}>
              {materialsRes?.contactMaterials?.map(item => (
                <Box key={item.materialContactId} mb={3}>
                  <BrowsingHistoryItem
                    item={item}
                    isExpired={item.isExpired}
                    onOpenUpdateExpiredOnModal={handleOpenModal}
                  />
                </Box>
              ))}
            </Box>
          </TabPanel>
        </Box>
      </TabContext>

      <ModalExpiryUpdate
        open={Boolean(targetMaterilableId)}
        onClose={() => setTargetMaterilableId(undefined)}
        onCancel={() => setTargetMaterilableId(undefined)}
        onConfirm={handleExpiryConfirm}
        value={{
          canViewable: false,
          expiredOn: null
        }}
      />
    </Box>
  )
}

// NOTE: Boxでrefを使うため
const TimelineBox = styled(Box)``
