import React from 'react'
import { Box, ButtonBase, IconButton } from '@material-ui/core'
import { CloseRounded, CheckCircle, Error } from '@material-ui/icons'
import { toast, ToastOptions } from 'react-toastify'
import { toastBackgroundColorMaps, toastColorMaps, toastTypeMaps } from './toastUtils'
import { initialStyles } from './styles'

export type ToastType = 'info' | 'error' | 'warning' | 'success'

export interface TosterOptions {
  position?: ToastOptions['position']
  duration?: ToastOptions['autoClose']
  closeButton?: ToastOptions['closeButton']
  onRetry?: () => void
}

const toastDefaultOptions: Partial<ToastOptions> = {
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 3000
}

const getCloseButton = ({
  closeButton,
  onRetry
}: {
  closeButton?: ToastOptions['closeButton']
  onRetry?: () => void
}) => {
  return onRetry ? (
    <ButtonBase onClick={onRetry}>
      <Box color="black" px="12px">
        Retry
      </Box>
    </ButtonBase>
  ) : (
    closeButton || (
      <Box display="flex" alignItems="flex-start" mt="10px" mr="10px" position="absolute" top="0px" right="0px">
        <IconButton
          size="small"
          aria-label="close"
          style={{ border: '1px solid #ffffff', width: '14px', height: '14px' }}
        >
          <CloseRounded fontSize="small" style={{ color: 'white', fontSize: '12px' }} />
        </IconButton>
      </Box>
    )
  )
}
const getIntialToastStyles = (type: ToastType): React.CSSProperties => {
  return {
    ...initialStyles,
    border: '2px solid #ffffff',
    whiteSpace: 'pre-wrap',
    borderRadius: '8px',
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    boxShadow:
      '0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)',
    backgroundColor: toastBackgroundColorMaps[type],
    color: toastColorMaps[type],
    minWidth: '350px'
  }
}

const toaster = (message: string, type: ToastType, options?: TosterOptions) => {
  return toast(message, {
    type: toastTypeMaps[type],
    position: options?.position,
    autoClose: options?.duration || 3000,
    style: getIntialToastStyles(type),
    icon: () => {
      switch (type) {
        case 'success':
          return <CheckCircle style={{ fontSize: '16px', marginRight: '-4px' }} />
        case 'error':
          return <Error style={{ fontSize: '16px' }} />
        case 'info':
          return <Error style={{ fontSize: '16px' }} />
        case 'warning':
          return <Error style={{ fontSize: '16px' }} />
      }
    },
    closeButton: getCloseButton({ onRetry: options?.onRetry, closeButton: options?.closeButton }),
    ...toastDefaultOptions
  })
}

export const Toaster = {
  info: (message: string, options?: TosterOptions) => toaster(message, 'info', options),
  success: (message: string, options?: TosterOptions) => toaster(message, 'success', options),
  error: (message: string, options?: TosterOptions) => toaster(message, 'error', options),
  warn: (message: string, options?: TosterOptions) => toaster(message, 'warning', options)
}
