import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { DocumentLabelItem } from 'src/components/atoms'

export interface LabelCellProps {
  value: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    labelItem: {
      '& .MuiBox-root': {
        overflow: 'hidden'
      }
    }
  })
})

export const LabelCell = ({ value }: LabelCellProps) => {
  const classes = useStyles()
  return (
    <Box width={1} display="flex" alignItems="center" className={classes.labelItem}>
      <DocumentLabelItem value={value}></DocumentLabelItem>
    </Box>
  )
}
