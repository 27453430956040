import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Box, createStyles, makeStyles, ButtonBase } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Button, Typography, Checkbox, Tooltip } from 'src/components/atoms'
import { ShowAllList } from 'src/components/organisms/FilteringMenu/ShowAllList'
import { Avatar } from 'src/components/organisms/FilteringMenu/Icons'
import { TabValueType } from '../'
import {
  InboxFilter,
  FilterStaffPins1,
  GetMeResponseData,
  EnumChatSummaryRepliedStatus
} from '@noco/http-client/lib/noco'
import { SelectedCondition } from 'src/components/pages/PageInbox'
import { GetRequest } from 'src/components/modals/ModalInboxFilter'
import { InboxFilteredItems } from './InboxFilteredItems'
import clsx from 'clsx'

const useStyles = makeStyles(() => {
  return createStyles({
    userlistWrap: {
      height: '32px',
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      },
      '& .MuiTypography-root': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    },
    userlist: {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      justifyContent: 'flex-start'
    },
    button: {
      padding: '0'
    },
    active: {
      backgroundColor: '#F0F2FF',
      color: '#6C60F0',
      border: '1px solid #6C60F0',
      '&:hover': {
        backgroundColor: '#F0F2FF',
        color: '#6C60F0',
        border: '1px solid #6C60F0'
      }
    }
  })
})

export interface MenuProps {
  open?: boolean
  onOpen: () => void
  filter: InboxFilter
  params: GetRequest
  onSetParams: (value: {}) => void
  allStaffList: FilterStaffPins1[] | undefined
  onStaffListsPinConfirm: (userIds: string[]) => void
  tabValue: TabValueType
  selectedCondition?: SelectedCondition
  setSelectedCondition?: (condition: SelectedCondition | undefined) => void
  loginUser: GetMeResponseData | undefined
  onClearCondition?: () => void
  resetPagination: () => void
  selectedIds?: string[]
}

export const Menu = ({
  open,
  onOpen,
  filter,
  params,
  onSetParams,
  allStaffList,
  onStaffListsPinConfirm,
  tabValue,
  selectedCondition,
  setSelectedCondition,
  loginUser,
  onClearCondition,
  resetPagination,
  selectedIds
}: MenuProps) => {
  const classes = useStyles()
  const [checkDisabled, setCheckDisabled] = useState(false)
  const [beforeModalOpenCondition, setBeforeModalOpenCondition] = useState(params)

  const hasFilter = selectedCondition

  const pinsStaffList = useMemo(() => filter.staffList?.pins, [filter])
  const statusList = useMemo(() => filter.chatSummaryStatuses, [filter])
  const userIds = useMemo(() => (tabValue === 'incoming' ? 'userIds' : 'sendUserIds'), [tabValue])

  const mappedStaffList = useMemo(
    () =>
      allStaffList?.map(list => {
        return {
          ...list,
          id: list.userId
        }
      }) ?? [],
    [allStaffList]
  )

  const handleClickModalOpen = useCallback(() => {
    onOpen()
    setCheckDisabled(true)
    setBeforeModalOpenCondition(params)
  }, [onOpen, params])

  const handleChange = useCallback(
    (keyName: 'repliedStatuses' | typeof userIds, keyId) => () => {
      const newParams = { ...params }
      newParams[keyName] ||= []
      const foundIndex = newParams[keyName]!.findIndex(id => id === keyId)
      if (foundIndex != -1) {
        newParams[keyName]!.splice(foundIndex, 1)
      } else if (foundIndex == -1) {
        newParams[keyName]!.push(keyId)
      }

      if (newParams[keyName]!.length == 0) {
        delete newParams[keyName]
      }
      onSetParams(newParams)
      resetPagination()
    },
    [params, onSetParams, resetPagination]
  )

  const clearCondition = useCallback(() => {
    setSelectedCondition?.(undefined)
    onSetParams({})
    setCheckDisabled(false)
    onClearCondition?.()
    setBeforeModalOpenCondition({})
  }, [setSelectedCondition, onSetParams, onClearCondition])

  const getToolTipText = useCallback((status: EnumChatSummaryRepliedStatus) => {
    switch (status) {
      case EnumChatSummaryRepliedStatus.Unresponsive:
        return '新着のチャットメッセージが届くと、自動で付与されるステータスです。返信済み/返信不要のステータスに変更することで、顧客とのコミュニケーション状態が把握できます。'
      case EnumChatSummaryRepliedStatus.Sent:
        return 'チャットで返信した状態を表すステータスです。'
      case EnumChatSummaryRepliedStatus.NoReplyRequired:
        return '返信の必要がないと判断した場合に選択したステータスです。'
      default:
        return ''
    }
  }, [])

  useEffect(() => {
    if (!open && !hasFilter) {
      setCheckDisabled(false)
      onSetParams(beforeModalOpenCondition)
    }
  }, [open, beforeModalOpenCondition, onSetParams, hasFilter])

  return (
    <Box borderRight={`1px solid ${Colors.background.silver}`} height="100%">
      <Box px="14px">
        <Button
          width="100%"
          disabled={tabValue === 'error' || (selectedIds && selectedIds.length > 0)}
          kind="neutral"
          variant="outlined"
          title="詳細な絞り込み"
          onClick={handleClickModalOpen}
          startIcon={<DynamicMuiIcon variant="filterAlt" size="16px" color="inherit" />}
          className={clsx(hasFilter ? classes.active : '', classes.button)}
        />
      </Box>
      {hasFilter ? (
        <>
          <Box mt="8px" px="14px">
            <Button kind="secondary" variant="outlined" title="クリア" onClick={clearCondition} width="100%" />
          </Box>
          <InboxFilteredItems condition={selectedCondition} tabValue={tabValue} />
        </>
      ) : (
        <>
          <Box width="fit-content" mt="28px" mb="10px" px="14px">
            <Tooltip
              content={
                '選択した担当している顧客を表示します。 「すべてを見る」から、担当者を選択することで、 サイドバーに担当者を常時表示できます。'
              }
            >
              <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                {tabValue === 'incoming' ? '担当者' : '送信者'}
              </Typography>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center" padding="0 14px 0 8px" className={classes.userlistWrap}>
            <Checkbox
              checked={checkDisabled ? false : !!params[userIds]?.includes(loginUser?.user?.id as any)}
              onChange={handleChange(userIds, loginUser?.user?.id)}
              name={loginUser?.user?.id}
              disabled={selectedIds && selectedIds.length > 0}
            />
            <ButtonBase
              onClick={handleChange(userIds, loginUser?.user?.id)}
              className={classes.userlist}
              disabled={selectedIds && selectedIds.length > 0}
            >
              <Box flexShrink="0">
                <Avatar avatar={loginUser?.user?.avatar?.url} />
              </Box>
              <Box>
                <Typography fontSize="s" lineheight="14px">
                  {loginUser?.user?.lastName} {loginUser?.user?.firstName}
                </Typography>
              </Box>
            </ButtonBase>
          </Box>
          {pinsStaffList?.map(({ text, avatar, userId }, i) => {
            if (userId === loginUser?.user?.id) {
              return
            }
            return (
              <Box key={i} display="flex" alignItems="center" padding="0 14px 0 8px" className={classes.userlistWrap}>
                <Checkbox
                  checked={checkDisabled ? false : !!params[userIds]?.includes(userId as any)}
                  onChange={handleChange(userIds, userId)}
                  name={userId}
                  disabled={selectedIds && selectedIds.length > 0}
                />
                <ButtonBase
                  onClick={handleChange(userIds, userId)}
                  className={classes.userlist}
                  disabled={selectedIds && selectedIds.length > 0}
                >
                  <Box flexShrink="0">
                    <Avatar avatar={avatar?.url} />
                  </Box>
                  <Typography fontSize="s" lineheight="14px">
                    {text}
                  </Typography>
                </ButtonBase>
              </Box>
            )
          })}
          <ShowAllList
            allCount={allStaffList ? allStaffList.length : 0}
            title="すべての担当者"
            variableList={mappedStaffList}
            icon="pushPin"
            onPinConfirm={onStaffListsPinConfirm}
          />
          {tabValue === 'incoming' && (
            <Box>
              <Box mt="28px" mb="10px" px="14px">
                <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                  ステータス
                </Typography>
              </Box>
              {statusList?.map((list, i) => {
                return (
                  <Box
                    key={i}
                    display="flex"
                    alignItems="center"
                    padding="0 14px 0 8px"
                    className={classes.userlistWrap}
                  >
                    <Checkbox
                      checked={
                        checkDisabled
                          ? false
                          : !!params.repliedStatuses?.includes(list.key as EnumChatSummaryRepliedStatus)
                      }
                      onChange={handleChange('repliedStatuses', list.key)}
                      name={list.key}
                      disabled={selectedIds && selectedIds.length > 0}
                    />
                    <Tooltip content={getToolTipText(list.key!)}>
                      <ButtonBase
                        onClick={handleChange('repliedStatuses', list.key)}
                        disabled={selectedIds && selectedIds.length > 0}
                      >
                        <Typography fontSize="s" lineheight="14px">
                          {list.text}
                        </Typography>
                      </ButtonBase>
                    </Tooltip>
                  </Box>
                )
              })}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
