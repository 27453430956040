import { Box, CircularProgress, Typography } from '@material-ui/core'
import { useRouter } from 'next/router'
import React, { useCallback, useState, useMemo, ChangeEvent, useEffect } from 'react'
import { Button, Colors, DynamicMuiIcon, SvgIcon, Tooltip } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { ModalDocumentShareFromContact, PopupTemplate } from 'src/components/modals'
import { ActionMenu, ActionMenuItemType } from 'src/components/molecules'
import { ContactContent, ContactDetailContent, Header, TabValueType } from 'src/components/organisms'
import { ContactInfoValues } from 'src/components/organisms/ContactContent/ContactInfo'
import { useArchiveContact, useGetContact, useUpdateContact } from 'src/fixtures/modules/contact/hooks'

export interface RightSectionProps {
  menuItemList: ActionMenuItemType[]
  status: string
  onChangeStatus: (event: React.ChangeEvent<{ value: unknown }>) => void
  onClickShare: () => void
}

export const RightSection = ({ menuItemList, onClickShare }: RightSectionProps) => {
  return (
    <Box display="flex" alignItems="center">
      {/* <Box flexShrink={0} pl="12px" mr="6px">
        <Typography variant="subtitle2">ステータス</Typography>
      </Box>
      <SelectBox
        placeholder="選択してください"
        value={status}
        name="status"
        optionList={statusList}
        onChange={onChangeStatus}
      /> */}
      <Box mx="12px" flexShrink={0}>
        <Tooltip
          content={`顧客だけが閲覧できる共有リンクを作成し、メール機能で資料を共有できます。共有された本人だけが閲覧でき、それ以外の方は閲覧できません。`}
        >
          <Button
            title="資料の共有"
            size="medium"
            startIcon={<SvgIcon variant="share" size="16px" />}
            onClick={onClickShare}
            starticonmargin="0px 6px 0px 0px"
          />
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" color={Colors.base.middleGray}>
        <ActionMenu menuItemList={menuItemList} />
      </Box>
    </Box>
  )
}

export const PageContactDetail = () => {
  const router = useRouter()
  const { id, tab: queryTab = 'chat' } = router.query as { id: string; tab: TabValueType }
  const [status, setStatus] = useState('')
  const [tab, setTab] = useState<TabValueType>(queryTab)
  const [, setContact] = useState<ContactInfoValues>({})
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)
  const [openModalShare, setOpenModalShare] = useState(false)
  const { handleUpdateContact } = useUpdateContact(id)

  const { data } = useGetContact(id)

  const handleChangeStatus = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setStatus(event.target.value as string)
    },
    [setStatus]
  )
  const handleSubmitContact = useCallback(
    (values: ContactInfoValues) => {
      setContact(values)
    },
    [setContact]
  )

  const handleChangeUser = useCallback(
    userId => {
      handleUpdateContact({ contact: { userId: userId } })
    },
    [handleUpdateContact]
  )

  const handleChangeTab = useCallback((event: ChangeEvent<{}>, newValue: TabValueType) => {
    setTab(newValue)
  }, [])

  const onViewMoreTimeLine = useCallback(() => {
    setTab('timeline')
  }, [])

  const [name, companyName, department, post, email] = useMemo(() => {
    return [
      data?.contact?.contactSettings?.find(item => item.name === '名前')?.contactSettingValueText?.text || '',
      data?.contact?.company?.displayName || '',
      data?.contact?.contactSettings?.find(item => item.name === '所属部門名')?.contactSettingValueText?.text || '',
      data?.contact?.contactSettings?.find(item => item.name === '役職')?.contactSettingValueText?.text || '',
      data?.contact?.contactSettings?.find(item => item.name === 'メールアドレス')?.contactSettingValueText?.text || ''
    ]
  }, [data?.contact?.contactSettings, data?.contact?.company?.displayName])

  const title = useMemo(() => {
    return data?.contact?.archivedAt ? 'アーカイブから取り出す' : 'アーカイブ'
  }, [data?.contact?.archivedAt])

  const {
    handleSaveArchived,
    error: archiveError,
    isLoading: isLoadingArchive,
    isPosted
  } = useArchiveContact({ page: 1, per: 25 })

  const handleClickModalArchiveConfirm = useCallback(
    (isArchived: boolean) => () => {
      handleSaveArchived([id], isArchived)
      setModalArchiveOpen(false)
    },
    [id, handleSaveArchived]
  )

  useEffect(() => {
    if (isPosted && !archiveError) router.push('/contacts')
  }, [archiveError, isPosted, router])

  const menuItemList: ActionMenuItemType[] = useMemo(
    () => [
      {
        label: title,
        onClick: () => setModalArchiveOpen(true),
        icon: <DynamicMuiIcon variant="archive" />,
        toolTipText: '連絡をとる必要がない顧客を、削除することなく一時保管できます。'
      }
    ],
    [title]
  )

  const onClickShare = useCallback(() => {
    setOpenModalShare(true)
  }, [])

  return (
    <>
      <Layout>
        {isLoadingArchive && (
          <Box
            sx={{
              position: 'fixed',
              zIndex: 9999,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: 'rgba(0,0,0,0.6)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Box bgcolor={Colors.functional.background.default} width={1} height={1} display="flex" flexDirection="column">
          <Box borderBottom={`1px solid ${Colors.background.silver}`}>
            <Header
              iconSize="28px"
              back
              title={name || email}
              icon="account"
              companyName={companyName}
              department={department}
              post={post}
              rightSection={
                <RightSection
                  menuItemList={menuItemList}
                  status={status}
                  onChangeStatus={handleChangeStatus}
                  onClickShare={onClickShare}
                />
              }
            />
          </Box>
          <Box display="flex" height={1} overflow="hidden">
            <Box
              width="358px"
              flex="1 0 auto"
              overflow="hidden auto"
              p="16px"
              borderRight={`1px solid ${Colors.background.silver}`}
            >
              {id && (
                <ContactContent
                  contactId={id}
                  onSubmit={handleSubmitContact}
                  onViewMoreTimeLine={onViewMoreTimeLine}
                  userId={data?.contact?.user?.id}
                  onChangeUserId={handleChangeUser}
                />
              )}
            </Box>
            <Box width={1} height={1}>
              <ContactDetailContent
                contactId={id}
                tab={tab}
                handleChangeTab={handleChangeTab}
                onClickShare={onClickShare}
              />
            </Box>
          </Box>
        </Box>
        <PopupTemplate
          open={modalArchiveOpen}
          title={`連絡先を${title}`}
          onClose={() => setModalArchiveOpen(false)}
          hasClose
          hasCancel
          hasConfirm
          onConfirm={handleClickModalArchiveConfirm(!!data?.contact?.archivedAt)}
          cancelButtonLabel="キャンセル"
          confirmButtonLabel="OK"
        >
          <Box p="24px">
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {`選択した連絡先をアーカイブ${
                data?.contact?.archivedAt ? 'から' : 'へ'
              }移動します\n本当によろしいですか？`}
            </Typography>
          </Box>
        </PopupTemplate>
      </Layout>
      <ModalDocumentShareFromContact
        open={openModalShare}
        contactId={id}
        onClose={() => {
          setOpenModalShare(false)
        }}
        onCancel={() => {
          setOpenModalShare(false)
        }}
      />
    </>
  )
}
