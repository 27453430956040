import React, { useCallback } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useGlobalStyles } from 'src/styles/theme'
import { DefualtImage } from '@noco/http-client/lib/noco'

const useStyles = makeStyles(() => {
  return createStyles({
    imgList: {
      display: 'grid',
      rowGap: '8px',
      columnGap: '8px',
      gridTemplateColumns: 'repeat(3, 1fr)'
    }
  })
})
export interface ImageListProps {
  images: DefualtImage[]
  onClick: (id: string) => void
}

export const ImageList = ({ images, onClick }: ImageListProps) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const handleClick = useCallback(
    (id?: string) => {
      id && onClick(id)
    },
    [onClick]
  )
  return (
    <Box p="12px" className={classes.imgList}>
      {images.map((image, i) => (
        <Box key={i} onClick={() => handleClick(image.id!)} className={globalClasses.cursorPointer} height="54px">
          <img src={image.fileUrl} width="96px" height="100%" style={{ objectFit: 'cover' }} />
        </Box>
      ))}
    </Box>
  )
}
