/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetSiteResponseData,
  GetSiteResponseDataFromJSON,
  GetSiteResponseDataFromJSONTyped,
  GetSiteResponseDataToJSON
} from './GetSiteResponseData'

/**
 *
 * @export
 * @interface ShareByLinkSiteResponse
 */
export interface ShareByLinkSiteResponse {
  /**
   *
   * @type {number}
   * @memberof ShareByLinkSiteResponse
   */
  status?: number
  /**
   *
   * @type {GetSiteResponseData}
   * @memberof ShareByLinkSiteResponse
   */
  data?: GetSiteResponseData
}

export function ShareByLinkSiteResponseFromJSON(json: any): ShareByLinkSiteResponse {
  return ShareByLinkSiteResponseFromJSONTyped(json, false)
}

export function ShareByLinkSiteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareByLinkSiteResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetSiteResponseDataFromJSON(json['data'])
  }
}

export function ShareByLinkSiteResponseToJSON(value?: ShareByLinkSiteResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetSiteResponseDataToJSON(value.data)
  }
}
