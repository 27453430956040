import { Box } from '@material-ui/core'
import React from 'react'
import { DocumentSettingMessage } from 'src/components/organisms/DocumentSettingMessage'

export const MessageContainer = ({ onClose }: { onClose?: () => void }) => {
  return (
    <Box width="350px" px="10px" height="100%" py="18px">
      <DocumentSettingMessage onClose={() => onClose?.()} />
    </Box>
  )
}
