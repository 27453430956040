import { Box } from '@material-ui/core'
import { EnumRecordPorterHistoryOperation } from '@noco/http-client/lib/noco'
import { ImportExecution } from 'src/components/molecules'
import { useBulkUpdateCompanies, useCreateCompaniesExport } from 'src/fixtures/modules/porter/hooks'
import { useGetPorterHistoriesLatest } from 'src/fixtures/modules/recordPorterHistory/hooks'
import { BulkUpdateExport } from '../BulkUpdateExport'
import { RequestBodyExportCommon } from '@noco/http-client/lib/noco'

export const BulkUpdateCompany = () => {
  const { data: exportResult } = useGetPorterHistoriesLatest(EnumRecordPorterHistoryOperation.ExportCompanies)
  const { handleCreateCompaniesExport, error: exportError } = useCreateCompaniesExport(
    EnumRecordPorterHistoryOperation.ExportCompanies
  )

  const { data: result } = useGetPorterHistoriesLatest(EnumRecordPorterHistoryOperation.BulkUpdateCompanies)
  const { handleBulkUpdateCompanies, isLoading, error } = useBulkUpdateCompanies(
    EnumRecordPorterHistoryOperation.BulkUpdateCompanies
  )

  return (
    <>
      <BulkUpdateExport
        mode="company"
        onSubmit={(params: RequestBodyExportCommon, hasToast: boolean) => handleCreateCompaniesExport(params, hasToast)}
        result={exportResult?.recordPorterHistory}
        error={exportError}
      />
      <Box px="20px" mt="40px">
        <ImportExecution
          onSubmit={(value: File) => {
            handleBulkUpdateCompanies(value)
          }}
          result={result?.recordPorterHistory}
          requesting={isLoading}
          error={error}
        />
      </Box>
    </>
  )
}
