import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import { Site } from '@noco/http-client/lib/noco/models'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { Colors, DynamicMuiIcon, IconText, Input, MuiIconVariant, Typography, Tooltip } from 'src/components/atoms'
import { useUpdateSite } from 'src/fixtures/modules/site/hooks'
import { publicationStatusIconTextMap, publicationStatusTextMap } from 'src/fixtures/utils/text'
import { convertMs } from 'src/fixtures/utils/time'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      display: 'flex',
      width: '100%'
    }
  })
)

export interface Folder {
  [K: string]: number | string
}

const rowBlocks: FolderRowData[][] = [
  [
    {
      id: 'countOfContactFromEmail',
      label: 'メール送付済の人数',
      icon: 'email',
      tooltipText: `メール機能を使って、資料サイトを送付した顧客の人数です。

資料サイトを閲覧していない顧客も含みます。`
    },
    {
      id: 'countOfViewedByContactFromEmail',
      label: 'メール経由の閲覧者数',
      icon: 'drafts',
      tooltipText: `メール機能を使って、資料サイトを送付した顧客のうち、閲覧した人数です。`
    },
    {
      id: 'rateOfViewedByContactFromEmail',
      label: 'メール経由の閲覧率',
      icon: 'drafts',
      tooltipText: `メール機能を使って、資料サイトを共有した顧客のうち、閲覧した人数の割合です。`
    },
    {
      id: 'countOfViewedByPublicLink',
      label: '公開用URLの閲覧者数',
      icon: 'link',
      tooltipText: `公開用URLから、資料を閲覧した人数です。メールアドレスを登録していない閲覧者は別々にカウントされます`
    }
  ],
  [
    {
      id: 'secondOfAverageBrowsingTime',
      label: '一人あたりの閲覧時間',
      icon: 'accessTime',
      tooltipText: `顧客1人あたりの資料サイトの閲覧時間です。`
    },
    { id: 'countOfPageView', label: '閲覧回数', icon: 'visibility', tooltipText: `資料サイトが訪問された回数です。` },
    {
      id: 'countOfUniqueVisitor',
      label: '閲覧者数',
      icon: 'account',
      tooltipText: `資料サイトを訪問した人数です。

同一人物が複数回閲覧した場合した場合、1人とカウントします。`
    },
    {
      id: 'rateOfAbandonment',
      label: '離脱率',
      icon: 'exit',
      unit: '%',
      tooltipText: `資料サイトを訪問したが、掲載している資料を閲覧しなかった人数の割合です。`
    }
  ]
]

export interface FolderRowData {
  id: keyof Folder
  label: string
  icon: MuiIconVariant
  unit?: string
  tooltipText: string
}

export interface FolderDescriptionProps {
  site?: Site
}

export const FolderDescription = ({ site }: FolderDescriptionProps) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()
  const { handleUpdateSite } = useUpdateSite(site?.id!)

  const FolderData = useMemo<Folder>(() => {
    return {
      countOfContactFromEmail: site?.statisticObject?.countOfContactFromEmail || 0,
      countOfViewedByContactFromEmail: site?.statisticObject?.countOfViewedByContactFromEmail || 0,
      rateOfViewedByContactFromEmail: Math.round((site?.statisticObject?.rateOfViewedByContactFromEmail || 0) * 100),
      countOfViewedByPublicLink: site?.statisticObject?.countOfViewedByPublicLink || 0,
      secondOfAverageBrowsingTime: convertMs(site?.statisticObject?.secondOfAverageBrowsingTime ?? 0),
      countOfPageView: site?.statisticObject?.countOfPageView || 0,
      countOfUniqueVisitor: site?.statisticObject?.countOfUniqueVisitor || 0,
      rateOfAbandonment: Math.round((site?.statisticObject?.rateOfAbandonment || 0) * 100)
    }
  }, [site?.statisticObject])

  return (
    <Box display="flex" width={1}>
      <Box display="flex" width={1}>
        <Box width="256px" height="144px" flexShrink={0} mr="20px">
          {site?.coverPhoto?.url ? (
            <img src={site?.coverPhoto?.url} alt="thumbnail" className={globalClasses.imgCover} />
          ) : (
            <Box width={1} height={1}>
              no image
            </Box>
          )}
          <ButtonBase
            className={classes.button}
            onClick={() => window.open(`/multi-documents/${site?.id}/preview`, undefined, '_blank')}
          >
            <Box
              width={1}
              height={1}
              p="4px 8px"
              bgcolor={Colors.base.placeHolder}
              borderRadius="0 0 4px 4px"
              color={Colors.functional.whiteText.default}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <DynamicMuiIcon variant="openNew" size="16px" />
              <Typography variant="h6">プレビュー</Typography>
            </Box>
          </ButtonBase>
        </Box>
        <Box width={1}>
          <Typography variant="h3">{site?.title}</Typography>
          <Box display="flex" width={1} maxWidth="1080px" justifyContent="flex-start">
            <Box width={1} maxWidth="530px" mr="24px">
              <Box display="flex" alignItems="center" flexWrap="wrap" pt="8px" mb="8px">
                <Box width="20px" height="20px" borderRadius="100px" overflow="hidden" color={Colors.base.middleGray}>
                  {site?.user?.avatar.url ? (
                    <img src={site?.user?.avatar.url} alt="avatar" className={globalClasses.imgCover} />
                  ) : (
                    <DynamicMuiIcon variant="account" size="20px" color="inherit" />
                  )}
                </Box>
                <Box ml="6px" mr="8px">
                  <Typography fontSize="s" lh="none">
                    {site?.user?.lastName}
                    &nbsp;
                    {site?.user?.firstName}
                  </Typography>
                </Box>
                <Box mr="12px" />
                <DynamicMuiIcon variant="meeting" size="16px" color="action" />
                <Box mr="6px" />
                <Typography fontSize="s" lh="none">
                  {site?.createdAt &&
                    format(new Date(site?.createdAt).getTime(), 'yyyy/MM/dd (E) hh:mm', { locale: ja })}
                </Typography>
                <Box mr="12px" />
                {site?.publicationStatus && (
                  <Tooltip
                    // eslint-disable-next-line no-irregular-whitespace
                    content={`非公開：顧客は閲覧できません\n限定　：指定した顧客のみが閲覧できます\n誰でも：公開用URLを使って誰でも閲覧できます`}
                  >
                    <IconText
                      icon={publicationStatusIconTextMap[site?.publicationStatus]}
                      text={publicationStatusTextMap[site?.publicationStatus]}
                    />
                  </Tooltip>
                )}
              </Box>
              <Box display="flex" justifyContent="space-between" width={1} mt="14px">
                {rowBlocks.map((rows, i) => (
                  <Box
                    key={`block_${i}`}
                    width={i === 0 ? '100%' : '100%'}
                    minWidth={i === 0 ? '172px' : '172px'}
                    mr={i === 0 ? '32px' : ''}
                  >
                    {rows.map(row => (
                      <Box
                        key={row.id}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        color={Colors.base.middleGray}
                        borderBottom={`1px solid ${Colors.background.gray}`}
                        width={1}
                        mb="6px"
                      >
                        <Box display="flex" alignItems="center">
                          <DynamicMuiIcon variant={row.icon} size="16px" />
                          <Box width="6px" />
                          <Tooltip content={row.tooltipText}>
                            <Typography variant="subtitle2">{row.label}</Typography>
                          </Tooltip>
                        </Box>
                        <Typography variant="h5">
                          {FolderData[row.id]}
                          {row.unit && row.unit}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box width={1} mt="8px">
              <Tooltip content={'社内の情報共有のために利用するメモです。 外部には公開されません。'}>
                <Box display="flex" alignItems="center" mb="8px">
                  <DynamicMuiIcon variant="summarize" size="16px" color="action" />
                  <Box mr="6px" />
                  <Typography fontSize="s" lh="none">
                    社内メモ
                  </Typography>
                </Box>
              </Tooltip>
              <Box width={1}>
                <Input
                  name="memo"
                  defaultValue={site?.memo ?? ''}
                  onBlur={e => handleUpdateSite({ memo: e.target.value as string })}
                  multiline
                  rows={5}
                  placeholder="メモを入力"
                  width="100%"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
