import { Box, Slide } from '@material-ui/core'
import { useState } from 'react'
import { MultiDocumentsSetting } from 'src/components/organisms/MultiDocumentsSetting'

export interface SettingContainerProps {
  onClose?: () => void
  siteId: string
}

export const SettingContainer = ({ onClose, siteId }: SettingContainerProps) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  return (
    <Slide direction="left" in={open} style={{ backgroundColor: 'white' }}>
      <Box width="351px" minHeight="auto" bgcolor="white" px="10px" py="18px">
        <MultiDocumentsSetting onClose={handleClose} siteId={siteId} />
      </Box>
    </Slide>
  )
}
