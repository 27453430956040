/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 「公開状態」の表示に使用
 * @export
 */
export const EnumPublicationStatus = {
  Open: 'open',
  Restricted: 'restricted',
  Unpublished: 'unpublished'
} as const
export type EnumPublicationStatus = typeof EnumPublicationStatus[keyof typeof EnumPublicationStatus]

export function EnumPublicationStatusFromJSON(json: any): EnumPublicationStatus {
  return EnumPublicationStatusFromJSONTyped(json, false)
}

export function EnumPublicationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumPublicationStatus {
  return json as EnumPublicationStatus
}

export function EnumPublicationStatusToJSON(value?: EnumPublicationStatus | null): any {
  return value as any
}
