import { Company } from '@noco/http-client/lib/noco'
import React, { useCallback } from 'react'
import { SettingInputRow } from 'src/components/molecules'
import { CompanySettingValueMap } from 'src/fixtures/modules/company/utils'

export interface EditProps {
  company: Company
  settingMap: CompanySettingValueMap
  onUpdateValue: (settingMap: CompanySettingValueMap) => void
}
export const Edit = ({ company, settingMap, onUpdateValue }: EditProps) => {
  const activeSettings = company?.companySettings

  const handleUpdateValue = useCallback(
    (settingId: string, fieldId: string, value: any) => {
      const newValueMap = { ...settingMap }
      newValueMap[settingId][fieldId] = value
      onUpdateValue(newValueMap)
    },
    [onUpdateValue, settingMap]
  )
  return (
    <>
      {activeSettings?.map(setting => {
        const values = settingMap[setting.id!]
        return (
          <SettingInputRow
            required={setting.isRequiredOnUser}
            kind="company"
            key={setting.id}
            name={setting.id || ''}
            values={values}
            setting={setting}
            onChange={data => handleUpdateValue(setting.id!, data.fieldId, data.value)}
            gap="narrow"
          />
        )
      })}
    </>
  )
}
