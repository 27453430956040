/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function CreateDocumentShareWithContactRequestFromJSON(json) {
    return CreateDocumentShareWithContactRequestFromJSONTyped(json, false);
}
export function CreateDocumentShareWithContactRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companyIds: !exists(json, 'company_ids') ? undefined : json['company_ids'],
        contactIds: !exists(json, 'contact_ids') ? undefined : json['contact_ids'],
        contactListIds: !exists(json, 'contact_list_ids') ? undefined : json['contact_list_ids'],
        messageSubject: !exists(json, 'message_subject') ? undefined : json['message_subject'],
        messageBody: !exists(json, 'message_body') ? undefined : json['message_body'],
        expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on']
    };
}
export function CreateDocumentShareWithContactRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company_ids: value.companyIds,
        contact_ids: value.contactIds,
        contact_list_ids: value.contactListIds,
        message_subject: value.messageSubject,
        message_body: value.messageBody,
        expired_on: value.expiredOn
    };
}
