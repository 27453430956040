import { Box } from '@material-ui/core'
import React from 'react'
import { BillingInvoice, ScheduleBillingContract } from '@noco/http-client/lib/noco'
import {
  ScheduleBillingContractDetail,
  ScheduleBillingInvoiceDetail,
  ScheduleBillingNextInvoiceDetail
} from './components'

export interface PaymentScheduleBillingInvoiceProps {
  isPaid?: boolean
  scheduleBillingContract: ScheduleBillingContract
  scheduleBillingInvoice: BillingInvoice
  scheduleBillingNextInvoice: BillingInvoice
}

export const PaymentScheduleBillingInvoice = ({
  isPaid = false,
  scheduleBillingContract,
  scheduleBillingInvoice,
  scheduleBillingNextInvoice
}: PaymentScheduleBillingInvoiceProps) => {
  return (
    <>
      <ScheduleBillingContractDetail scheduleBillingContract={scheduleBillingContract} isPaid={isPaid} />
      <Box mb="48px" />
      <ScheduleBillingInvoiceDetail scheduleBillingInvoice={scheduleBillingInvoice} isPaid={isPaid} />
      <Box mb="48px" />
      {isPaid && <ScheduleBillingNextInvoiceDetail scheduleBillingNextInvoice={scheduleBillingNextInvoice} />}
    </>
  )
}
