import { Box } from '@material-ui/core'
import { DocumentShareForm, SiteShareForm } from '@noco/http-client/lib/noco'
import React from 'react'
import { Button, Colors, ToggleSwitch, Typography } from 'src/components/atoms'
import { ShareLinkFormSection } from 'src/components/molecules/ShareLinkFormSection'

import Image from 'next/image'

export interface ShareLinkFormContactFormSectionProps {
  isActive: boolean
  formList?: Array<DocumentShareForm | SiteShareForm>
  onChangeActive: (isActive: boolean) => void
  onOpenSettingForm: () => void
}

export const ShareLinkFormContactFormSection = ({
  isActive,
  onChangeActive,
  onOpenSettingForm,
  formList
}: ShareLinkFormContactFormSectionProps) => {
  return (
    <>
      <ShareLinkFormSection>
        <Box display="flex" alignItems="flex-start">
          <Box width={1} mr="16px">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
              <ToggleSwitch
                checked={isActive}
                onChange={() => {
                  onChangeActive(!isActive)
                }}
              />
              <Box width="12px" />
              <Typography variant="h6" fontSize="s" lineheight="14px" letterSpacing="none">
                顧客情報の入力フォーム設定
              </Typography>
            </Box>

            <Typography fontSize="s" lh="tight" letterSpacing="default">
              閲覧者が資料を開封した時に、顧客情報を取得するためのフォームを設定します。
            </Typography>

            {isActive && (
              <>
                <Box
                  bgcolor="white"
                  borderRadius="4px"
                  border={`1px solid ${Colors.background.gray}`}
                  px="12px"
                  py="10px"
                  mt="4px"
                  mb="8px"
                >
                  <Typography
                    variant="h5"
                    fontSize="s"
                    fontWeight="bold"
                    style={{ color: Colors.base.middleGray }}
                    lineheight="18.48px"
                  >
                    フォームに表示する項目
                  </Typography>
                  <Box display="flex" flexWrap="wrap" pt="8px">
                    {formList?.map(item => (
                      <Box
                        key={item.id}
                        border={`1px solid ${Colors.base.middleGray}`}
                        color={Colors.base.middleGray}
                        borderRadius="4px"
                        px="4px"
                        // height="20px"
                        py="2px"
                        display="flex"
                        alignItems="center"
                        mr="4px"
                        mb="4px"
                      >
                        <Typography variant="subtitle2" fontSize="s" lineheight="14px">
                          {item.contactSetting?.name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Button
                    title="項目を変更"
                    variant="outlined"
                    kind="primary"
                    size="small"
                    onClick={onOpenSettingForm}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box width="295px" height={1} flex="1 0 auto">
            <Image src="/images/documents/form-setting.svg" width="295" height="187" objectFit="contain" />
            <Box fontSize="12px" lineHeight="12px" color="#6D7784" display="flex" justifyContent="flex-end">
              フォーム設置のイメージです。
            </Box>
          </Box>
        </Box>
      </ShareLinkFormSection>
    </>
  )
}
