import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import { useMemo } from 'react'
import { Colors } from 'src/components/atoms'
import { useListContactsForSearch } from 'src/fixtures/modules/contact/hooks'
import { Option, SearchAndSuggestOptionData } from '..'
import clsx from 'clsx'
import { Paging } from '../../Paging'

interface ContactSuggestListProps {
  currentPage: number
  keyword: string
  onSelect: (value: SearchAndSuggestOptionData['value']) => () => void
  onCurrentPageChange: (page: number) => void
}

const pickUpList = ['名前', '名前（ふりがな）', '所属部門名', '役職', 'メールアドレス', '携帯電話番号', '電話番号']

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      height: '100%',
      textAlign: 'left'
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    }
  })
})

export const ContactSuggestList = ({
  currentPage,
  keyword,
  onSelect,
  onCurrentPageChange
}: ContactSuggestListProps) => {
  const classes = useStyles()

  const { data: resForSearch } = useListContactsForSearch({
    text: keyword,
    page: currentPage,
    per: 10
  })

  // 検索用のoption listの生成
  const options = useMemo(() => {
    const suggestList = resForSearch?.contacts?.map(item => {
      const setting = item.contactSettings?.filter(setting => pickUpList.some(list => list === setting.name))
      const contactSettingValueText = setting?.map(s => {
        return {
          name: s.name,
          settings: s.contactSettingValueText
        }
      })
      return {
        id: item.id,
        company: item.company?.displayName,
        setting: contactSettingValueText
      }
    })

    return suggestList?.map(list => {
      const labels = list?.setting?.map(s => {
        return {
          [s.name!]: s.settings?.text
        }
      })
      return {
        value: list.id!,
        company: list.company,
        label: labels
      }
    })
  }, [resForSearch?.contacts])

  return (
    <>
      <Box overflow="hidden auto" height="600px" borderTop={`1px solid ${Colors.background.gray}`}>
        {options?.map((option, i) => {
          return (
            <Box key={i} borderBottom={`1px solid ${Colors.background.gray}`} height="80px">
              <ButtonBase
                onClick={onSelect(option.value)}
                key={option.value}
                className={clsx(classes.buttonBase, classes.hoverButton)}
              >
                <Box width={1} px="24px" py="8px">
                  <Option option={option} type="contact" />
                </Box>
              </ButtonBase>
            </Box>
          )
        })}
      </Box>
      <Box bgcolor={Colors.background.lightGray} height="34px">
        <Paging
          totalCount={resForSearch?.pageInfo?.totalCount!}
          totalPages={resForSearch?.pageInfo?.totalPages!}
          currentPage={currentPage}
          onCurrentPageChange={onCurrentPageChange}
        />
      </Box>
    </>
  )
}
