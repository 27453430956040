/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PublishSiteRequest
 */
export interface PublishSiteRequest {
  /**
   *
   * @type {boolean}
   * @memberof PublishSiteRequest
   */
  publish?: boolean
}

export function PublishSiteRequestFromJSON(json: any): PublishSiteRequest {
  return PublishSiteRequestFromJSONTyped(json, false)
}

export function PublishSiteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishSiteRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    publish: !exists(json, 'publish') ? undefined : json['publish']
  }
}

export function PublishSiteRequestToJSON(value?: PublishSiteRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    publish: value.publish
  }
}
