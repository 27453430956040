/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AuthVisitorAuthenticationCodeRequestRequest,
  AuthVisitorAuthenticationCodeRequestRequestFromJSON,
  AuthVisitorAuthenticationCodeRequestRequestToJSON,
  AuthVisitorAuthenticationCodeRequestResponse,
  AuthVisitorAuthenticationCodeRequestResponseFromJSON,
  AuthVisitorAuthenticationCodeRequestResponseToJSON,
  AuthVisitorAuthenticationCodeRequestWithEmailAddressRequest,
  AuthVisitorAuthenticationCodeRequestWithEmailAddressRequestFromJSON,
  AuthVisitorAuthenticationCodeRequestWithEmailAddressRequestToJSON,
  AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse,
  AuthVisitorAuthenticationCodeRequestWithEmailAddressResponseFromJSON,
  AuthVisitorAuthenticationCodeRequestWithEmailAddressResponseToJSON,
  AuthVisitorAuthenticationCodeVerifiyRequest,
  AuthVisitorAuthenticationCodeVerifiyRequestFromJSON,
  AuthVisitorAuthenticationCodeVerifiyRequestToJSON,
  AuthVisitorSharedLinkSigninRequest,
  AuthVisitorSharedLinkSigninRequestFromJSON,
  AuthVisitorSharedLinkSigninRequestToJSON,
  AuthVisitorSharedLinkSigninResponse,
  AuthVisitorSharedLinkSigninResponseFromJSON,
  AuthVisitorSharedLinkSigninResponseToJSON
} from '../models'

export interface ContactV1AuthenticationCodeRequestPostRequest {
  xNocoVisitorToken?: string
  authVisitorAuthenticationCodeRequestRequest?: AuthVisitorAuthenticationCodeRequestRequest
}

export interface ContactV1AuthenticationCodeRequestWithEmailAddressPostRequest {
  xNocoVisitorToken?: string
  authVisitorAuthenticationCodeRequestWithEmailAddressRequest?: AuthVisitorAuthenticationCodeRequestWithEmailAddressRequest
}

export interface ContactV1AuthenticationCodeVerifiyPostRequest {
  xNocoVisitorToken?: string
  authVisitorAuthenticationCodeVerifiyRequest?: AuthVisitorAuthenticationCodeVerifiyRequest
}

export interface ContactV1SharedLinkSigninPostRequest {
  xNocoVisitorToken?: string
  authVisitorSharedLinkSigninRequest?: AuthVisitorSharedLinkSigninRequest
}

/**
 *
 */
export class ApiContactV1VisitorAuthApi extends runtime.BaseAPI {
  /**
   * visitor の認証に必要な code の要求
   * authentication_code_request
   */
  async contactV1AuthenticationCodeRequestPostRaw(
    requestParameters: ContactV1AuthenticationCodeRequestPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AuthVisitorAuthenticationCodeRequestResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xNocoVisitorToken !== undefined && requestParameters.xNocoVisitorToken !== null) {
      headerParameters['X-Noco-Visitor-Token'] = String(requestParameters.xNocoVisitorToken)
    }

    const response = await this.request(
      {
        path: `/contact/v1/authentication_code_request`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthVisitorAuthenticationCodeRequestRequestToJSON(
          requestParameters.authVisitorAuthenticationCodeRequestRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuthVisitorAuthenticationCodeRequestResponseFromJSON(jsonValue)
    )
  }

  /**
   * visitor の認証に必要な code の要求
   * authentication_code_request
   */
  async contactV1AuthenticationCodeRequestPost(
    requestParameters: ContactV1AuthenticationCodeRequestPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AuthVisitorAuthenticationCodeRequestResponse> {
    const response = await this.contactV1AuthenticationCodeRequestPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * emailアドレスを使用しての visitor の認証に必要な code の要求
   * authentication_code_request_with_email_address
   */
  async contactV1AuthenticationCodeRequestWithEmailAddressPostRaw(
    requestParameters: ContactV1AuthenticationCodeRequestWithEmailAddressPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xNocoVisitorToken !== undefined && requestParameters.xNocoVisitorToken !== null) {
      headerParameters['X-Noco-Visitor-Token'] = String(requestParameters.xNocoVisitorToken)
    }

    const response = await this.request(
      {
        path: `/contact/v1/authentication_code_request_with_email_address`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthVisitorAuthenticationCodeRequestWithEmailAddressRequestToJSON(
          requestParameters.authVisitorAuthenticationCodeRequestWithEmailAddressRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      AuthVisitorAuthenticationCodeRequestWithEmailAddressResponseFromJSON(jsonValue)
    )
  }

  /**
   * emailアドレスを使用しての visitor の認証に必要な code の要求
   * authentication_code_request_with_email_address
   */
  async contactV1AuthenticationCodeRequestWithEmailAddressPost(
    requestParameters: ContactV1AuthenticationCodeRequestWithEmailAddressPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AuthVisitorAuthenticationCodeRequestWithEmailAddressResponse> {
    const response = await this.contactV1AuthenticationCodeRequestWithEmailAddressPostRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * 認証 code のチェック
   * authentication_code_verifiy
   */
  async contactV1AuthenticationCodeVerifiyPostRaw(
    requestParameters: ContactV1AuthenticationCodeVerifiyPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xNocoVisitorToken !== undefined && requestParameters.xNocoVisitorToken !== null) {
      headerParameters['X-Noco-Visitor-Token'] = String(requestParameters.xNocoVisitorToken)
    }

    const response = await this.request(
      {
        path: `/contact/v1/authentication_code_verifiy`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthVisitorAuthenticationCodeVerifiyRequestToJSON(
          requestParameters.authVisitorAuthenticationCodeVerifiyRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * 認証 code のチェック
   * authentication_code_verifiy
   */
  async contactV1AuthenticationCodeVerifiyPost(
    requestParameters: ContactV1AuthenticationCodeVerifiyPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.contactV1AuthenticationCodeVerifiyPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 共有リンクにemailアドレス使用してサインイン
   * shared_link_signin
   */
  async contactV1SharedLinkSigninPostRaw(
    requestParameters: ContactV1SharedLinkSigninPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AuthVisitorSharedLinkSigninResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xNocoVisitorToken !== undefined && requestParameters.xNocoVisitorToken !== null) {
      headerParameters['X-Noco-Visitor-Token'] = String(requestParameters.xNocoVisitorToken)
    }

    const response = await this.request(
      {
        path: `/contact/v1/shared_link_signin`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AuthVisitorSharedLinkSigninRequestToJSON(requestParameters.authVisitorSharedLinkSigninRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AuthVisitorSharedLinkSigninResponseFromJSON(jsonValue))
  }

  /**
   * 共有リンクにemailアドレス使用してサインイン
   * shared_link_signin
   */
  async contactV1SharedLinkSigninPost(
    requestParameters: ContactV1SharedLinkSigninPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AuthVisitorSharedLinkSigninResponse> {
    const response = await this.contactV1SharedLinkSigninPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
