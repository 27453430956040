/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ShallowCompanyList,
  ShallowCompanyListFromJSON,
  ShallowCompanyListFromJSONTyped,
  ShallowCompanyListToJSON
} from './ShallowCompanyList'

/**
 *
 * @export
 * @interface GetCompanyCompanyListsResponseData
 */
export interface GetCompanyCompanyListsResponseData {
  /**
   *
   * @type {string}
   * @memberof GetCompanyCompanyListsResponseData
   */
  companyId: string
  /**
   *
   * @type {Array<ShallowCompanyList>}
   * @memberof GetCompanyCompanyListsResponseData
   */
  companyLists: Array<ShallowCompanyList>
}

export function GetCompanyCompanyListsResponseDataFromJSON(json: any): GetCompanyCompanyListsResponseData {
  return GetCompanyCompanyListsResponseDataFromJSONTyped(json, false)
}

export function GetCompanyCompanyListsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCompanyCompanyListsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyId: json['company_id'],
    companyLists: (json['company_lists'] as Array<any>).map(ShallowCompanyListFromJSON)
  }
}

export function GetCompanyCompanyListsResponseDataToJSON(value?: GetCompanyCompanyListsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_id: value.companyId,
    company_lists: (value.companyLists as Array<any>).map(ShallowCompanyListToJSON)
  }
}
