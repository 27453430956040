import { ContactSetting } from '@noco/http-client/lib/noco'

export const removeFreeCompanyFromContactSettings = (settings: ContactSetting[]) => {
  settings?.forEach(setting => {
    if (setting.name === '会社名') {
      setting.contactSettingFields?.splice(1, 1)
    }
  })
  return settings
}
