import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { SelectCompany } from 'src/components/organisms'

interface SelectProps {
  companyId?: string
  onChange: (value: string) => void
}

export const Select = ({ companyId, onChange }: SelectProps) => {
  return (
    <>
      <Typography variant="h5">会社名</Typography>
      <Box mt="8px" />
      <SelectCompany value={companyId} onChange={onChange} />
    </>
  )
}
