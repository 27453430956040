import { Box } from '@material-ui/core'
import React from 'react'
import { Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

export interface CountOfCtaCellProps {
  value: number
}

export const CountOfCtaCell = ({ value }: CountOfCtaCellProps) => {
  const globalClasses = useGlobalStyles()

  return (
    <Box display="flex" alignItems="center">
      <Box width={1} whiteSpace="pre-wrap" className={globalClasses.lineclamp1}>
        <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
