/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DefualtImage
 */
export interface DefualtImage {
  /**
   *
   * @type {string}
   * @memberof DefualtImage
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof DefualtImage
   */
  fileUrl?: string
  /**
   *
   * @type {string}
   * @memberof DefualtImage
   */
  division?: string
}

export function DefualtImageFromJSON(json: any): DefualtImage {
  return DefualtImageFromJSONTyped(json, false)
}

export function DefualtImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefualtImage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    fileUrl: !exists(json, 'file_url') ? undefined : json['file_url'],
    division: !exists(json, 'division') ? undefined : json['division']
  }
}

export function DefualtImageToJSON(value?: DefualtImage | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    file_url: value.fileUrl,
    division: value.division
  }
}
