import { ReactElement } from 'react'

export const tabValueList = ['import', 'company', 'contact', 'history'] as const
export type TabValueType = typeof tabValueList[number]

export interface TabType {
  value: TabValueType
  label: string
  icon?: ReactElement
  disabled: boolean
}
