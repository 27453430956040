/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationPaymentPlanTransaction
 */
export interface OrganizationPaymentPlanTransaction {
  /**
   *
   * @type {string}
   * @memberof OrganizationPaymentPlanTransaction
   */
  planName: string
  /**
   *
   * @type {Date}
   * @memberof OrganizationPaymentPlanTransaction
   */
  startAt: Date
  /**
   *
   * @type {Date}
   * @memberof OrganizationPaymentPlanTransaction
   */
  endAt: Date | null
}

export function OrganizationPaymentPlanTransactionFromJSON(json: any): OrganizationPaymentPlanTransaction {
  return OrganizationPaymentPlanTransactionFromJSONTyped(json, false)
}

export function OrganizationPaymentPlanTransactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPaymentPlanTransaction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    planName: json['plan_name'],
    startAt: new Date(json['start_at']),
    endAt: json['end_at'] === null ? null : new Date(json['end_at'])
  }
}

export function OrganizationPaymentPlanTransactionToJSON(value?: OrganizationPaymentPlanTransaction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan_name: value.planName,
    start_at: value.startAt.toISOString(),
    end_at: value.endAt === null ? null : value.endAt.toISOString()
  }
}
