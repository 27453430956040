/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetDocumentOpportunityUsersResponse1Data,
  GetDocumentOpportunityUsersResponse1DataFromJSON,
  GetDocumentOpportunityUsersResponse1DataFromJSONTyped,
  GetDocumentOpportunityUsersResponse1DataToJSON
} from './GetDocumentOpportunityUsersResponse1Data'

/**
 *
 * @export
 * @interface GetDocumentOpportunityUsersResponse1
 */
export interface GetDocumentOpportunityUsersResponse1 {
  /**
   *
   * @type {number}
   * @memberof GetDocumentOpportunityUsersResponse1
   */
  status?: number
  /**
   *
   * @type {GetDocumentOpportunityUsersResponse1Data}
   * @memberof GetDocumentOpportunityUsersResponse1
   */
  data?: GetDocumentOpportunityUsersResponse1Data
}

export function GetDocumentOpportunityUsersResponse1FromJSON(json: any): GetDocumentOpportunityUsersResponse1 {
  return GetDocumentOpportunityUsersResponse1FromJSONTyped(json, false)
}

export function GetDocumentOpportunityUsersResponse1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentOpportunityUsersResponse1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetDocumentOpportunityUsersResponse1DataFromJSON(json['data'])
  }
}

export function GetDocumentOpportunityUsersResponse1ToJSON(value?: GetDocumentOpportunityUsersResponse1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetDocumentOpportunityUsersResponse1DataToJSON(value.data)
  }
}
