import React, { useMemo } from 'react'
import { Message, Typography } from 'src/components/atoms'
import { useGetPlan } from 'src/fixtures/modules/organizationPayment/hooks'

export const WarningMesasge = ({ nextPlanId }: { nextPlanId: string }) => {
  const { plan: nextPlan } = useGetPlan(nextPlanId)
  const nextPlanNameText = useMemo(() => nextPlan?.name, [nextPlan])
  return (
    <Message type="warn">
      <Typography fontWeight="bold" fontSize="s" lh="tight" style={{ whiteSpace: 'pre-wrap' }}>
        {`${nextPlanNameText}では、一部の機能にデータの制限がございます\n画面のガイダンスに従って、ご利用するデータを選択してください`}
      </Typography>
    </Message>
  )
}
