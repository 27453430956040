/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { MailTemplateFromJSON, MailTemplateToJSON } from './MailTemplate';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetMailTemplatesResponseDataFromJSON(json) {
    return GetMailTemplatesResponseDataFromJSONTyped(json, false);
}
export function GetMailTemplatesResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mailTemplates: !exists(json, 'mail_templates')
            ? undefined
            : json['mail_templates'].map(MailTemplateFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetMailTemplatesResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mail_templates: value.mailTemplates === undefined ? undefined : value.mailTemplates.map(MailTemplateToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
