import React, { useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { LayoutCard } from 'src/components/commons/LayoutCard'
import { SignUpWithInvited, SignUpWithExpired } from 'src/components/organisms'
import { Box, CircularProgress, useMediaQuery } from '@material-ui/core'
import theme, { useGlobalStyles } from 'src/styles/theme'
import { useGetUserInfoFromToken } from 'src/fixtures/modules/auth/hooks'

export const PageSignUpWithInvited = () => {
  const globalClasses = useGlobalStyles()
  const [invitedToken, setInvitedToken] = useState('')
  const { data, error } = useGetUserInfoFromToken(invitedToken)
  const router = useRouter()
  const { token } = router.query as { token?: string }
  const isLoading = useMemo(() => !data && !error, [data, error])
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (token) {
      setInvitedToken(token)
    }
  }, [token])

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <LayoutCard
      direction="rtl"
      isSP={isDownSm}
      innerCardComponent={
        data != null ? <SignUpWithInvited invitedToken={invitedToken} data={data} /> : <SignUpWithExpired />
      }
      outerCardComponent={
        <Box>
          <Image
            src={data != null ? '/images/team-invite.svg' : '/images/team-invite-expired.svg'}
            width="560"
            height="357"
            className={globalClasses.imgContain}
          />
        </Box>
      }
      justify="center"
      maxWidth={640}
      maxHeight={674}
    />
  )
}
