import { Box, createStyles, makeStyles, Switch } from '@material-ui/core'
import { useRouter } from 'next/router'
import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, Tooltip } from 'src/components/atoms'
import { ModalAlertShare, ModalAlertShareProps, ModalDocumentShare } from 'src/components/modals'
import { useListDocumentsDocumentIdSites, useUpdateDocumentPublish } from 'src/fixtures/modules/documents/hooks'
import { Document } from '@noco/http-client/lib/noco'
import { ActionMenu, ActionMenuItemType } from 'src/components/molecules'

interface HeaderRightSectionProps {
  menuItemList: ActionMenuItemType[]
  document: Document
  hasEditPermission?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '80px',
      height: '24px',
      padding: '0px',
      '& .MuiSwitch-switchBase.Mui-checked': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '& .MuiIconButton-root': {
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    switchBase: {
      color: Colors.base.middleGray,
      backgroundColor: 'none',
      padding: '1px',
      '&$hover': {
        backgroundColor: 'none'
      },
      '&$checked': {
        '& + $track': {
          backgroundColor: Colors.accent.keyPurple.default,
          '&:after': {
            content: "'公開'",
            left: '14px'
          },
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    thumb: {
      color: 'white',
      width: '20px',
      height: '20px',
      margin: '1px'
    },
    track: {
      borderRadius: '20px',
      backgroundColor: Colors.base.middleGray,
      opacity: '1 !important',
      '&:after, &:before': {
        color: 'white',
        fontSize: '14px',
        fontWeight: '700',
        position: 'absolute'
      },
      '&:before': {
        content: "'非公開'",
        right: '10px'
      }
    },
    checked: {
      transform: 'translateX(56px) !important'
    }
  })
)

export const HeaderRightSection = ({ document, menuItemList, hasEditPermission = false }: HeaderRightSectionProps) => {
  const classes = useStyles()

  const router = useRouter()
  const documentId = router.query.documentId as string

  const [open, setOpen] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [instructions, setInstructions] = useState<string[] | undefined>(undefined)

  const { handleUpdateDocumentPublish, instructions: publishInstructions } = useUpdateDocumentPublish(document.id!)
  const { data: documentSiteResponse } = useListDocumentsDocumentIdSites({ documentId: document.id! })

  const alertShareData: Omit<ModalAlertShareProps, 'open' | 'onClose'> = useMemo(() => {
    if (instructions?.includes('document_belongs_to_open_site')) {
      return {
        title: 'リンクの共有をOFFにできません',
        description: `資料「${document.title}」が設定された以下の資料サイトの「リンクの共有」がONであるため、この資料の「リンクの共有」をOFFにできません`,
        infoList: [
          {
            question: 'この資料の「リンクの共有」をOFFにするには？',
            answerList: [
              {
                label: '資料サイトからこの資料を削除する'
              },
              {
                label: '資料サイトの「リンクの共有」をOFFにする'
              }
            ]
          },
          {
            question: 'この資料を表示している資料サイト',
            answerList:
              documentSiteResponse?.documentSites?.map(site => ({
                label: site.displayName,
                link: `/multi-documents/${site.id}`
              })) || []
          }
        ]
      }
    } else if (instructions?.includes('document_belongs_to_site')) {
      return {
        title: '非公開に変更できません',
        description: `資料「${document.title}」が設定された以下の資料サイトが公開されているため、この資料を非公開にできません`,
        infoList: [
          {
            question: 'この資料を非公開にするには？',
            answerList: [
              {
                label: '資料サイトからこの資料を削除する'
              },
              {
                label: '資料サイト自体を非公開にする'
              }
            ]
          },
          {
            question: 'この資料を表示している資料サイト',
            answerList:
              documentSiteResponse?.documentSites?.map(site => ({
                label: site.displayName,
                link: `/multi-documents/${site.id}`
              })) || []
          }
        ]
      }
    } else {
      return {
        title: '資料の共有はできません',
        description: `資料「${document.title}」が非公開のため、資料の共有はできません`,
        infoList: [
          {
            question: '資料の共有をするには？',
            answerList: [
              {
                label: '資料を公開にする'
              }
            ]
          }
        ],
        image: '/images/alert-share/document-unpublished-to-open.svg'
      }
    }
  }, [instructions, document.title, documentSiteResponse?.documentSites])

  const handleShareClick = useCallback(() => {
    if (document.isPublished) {
      setOpen(true)
    } else {
      setOpenAlert(true)
    }
  }, [document.isPublished])

  useEffect(() => {
    if (instructions?.includes('document_belongs_to_open_site') || instructions?.includes('document_belongs_to_site')) {
      setOpenAlert(true)
    }
  }, [instructions])

  useEffect(() => {
    setInstructions(publishInstructions)
  }, [publishInstructions])

  return (
    <>
      {documentId && (
        <ModalDocumentShare
          documentId={documentId}
          open={open}
          onClose={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          setInstructions={setInstructions}
        />
      )}

      <ModalAlertShare open={openAlert} onClose={() => setOpenAlert(false)} {...alertShareData} />

      <Tooltip
        content={
          '公開すると、資料の共有ができます。非公開にすると、資料を共有することができません。また、既に共有済みの資料を非公開にすることもできます。'
        }
      >
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked
          }}
          checked={document.isPublished}
          onChange={() => handleUpdateDocumentPublish(!document.isPublished)}
          disabled={!hasEditPermission}
        />
      </Tooltip>
      <Box borderRight={`1px solid ${Colors.background.silver}`} height="24px" mx="12px" />

      <Tooltip content={'資料の各種設定や表示を自由にカスタマイズできます。'}>
        <Button
          title="資料の設定"
          kind="secondary"
          variant="outlined"
          startIcon={<DynamicMuiIcon variant="build" size="16px" />}
          size="medium"
          href={`/documents/${documentId}/setting`}
          starticonmargin="0px 6px 0px 0px"
          disabled={!hasEditPermission}
        />
      </Tooltip>

      <Box mr="12px" />

      <Tooltip
        content={
          '資料を顧客に共有できます。資料が非公開の場合やアップロード処理中の場合は「資料の設定」ボタンをクリックできません。'
        }
      >
        <Button
          title="資料の共有"
          startIcon={<DynamicMuiIcon variant="iosShare" size="16px" />}
          size="medium"
          buttonColor={!document.isPublished ? Colors.functional.disable.button : undefined}
          onClick={handleShareClick}
          disabled={Boolean(document.archivedAt)}
          starticonmargin="0px 6px 0px 0px"
        />
      </Tooltip>
      <Box mr="12px" />
      <ActionMenu menuItemList={menuItemList} />
    </>
  )
}
