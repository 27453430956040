import { UserV1MaterialListsGetRequest, EnumMaterialableType } from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'
import { useListFilterContacts } from '../contact/hooks'

export const useListMaterialDocumentsLists = (params: UserV1MaterialListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const materialListApi = nocoSDK.client?.userService.materialListApi
  const func = useCallback(async () => {
    if (!auth) return

    return await materialListApi
      ?.ApiFactory(auth.token)
      .userV1MaterialListsGet(params)
      .then(res => res.data)
  }, [auth, params, materialListApi])
  return useSWR(auth?.token && SWRCachePath.listMaterialDocumentsLists(), func)
}

export const useListMaterialSitesLists = (params: UserV1MaterialListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const materialListApi = nocoSDK.client?.userService.materialListApi
  const func = useCallback(async () => {
    if (!auth) return

    return await materialListApi
      ?.ApiFactory(auth.token)
      .userV1MaterialListsGet(params)
      .then(res => res.data)
  }, [auth, params, materialListApi])
  return useSWR(auth?.token && SWRCachePath.listMaterialSitesLists(), func)
}

export const useUpdateMaterialDocumentsListsPin = (params: UserV1MaterialListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListMaterialDocumentsLists } = useListMaterialDocumentsLists(params)
  const materialListApi = nocoSDK.client?.userService.materialListApi

  const handleUpdateMaterialDocumentsListsPin = useCallback(
    async (ids: string[], materialableType: EnumMaterialableType) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await materialListApi?.ApiFactory(auth.token).userV1MaterialListsPinPost({
          pinMaterialListRequest: { materialableIds: ids, materialableType: materialableType }
        })

        await mutateListFilterContacts(), mutateListMaterialDocumentsLists()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update MaterialDocumentsListPinApi' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, materialListApi, me, mutateListFilterContacts, mutateListMaterialDocumentsLists]
  )

  return { error, isLoading, handleUpdateMaterialDocumentsListsPin }
}

export const useUpdateMaterialSitesListsPin = (params: UserV1MaterialListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListMaterialSitesLists } = useListMaterialSitesLists(params)
  const materialListApi = nocoSDK.client?.userService.materialListApi

  const handleUpdateMaterialSitesListsPin = useCallback(
    async (ids: string[], materialableType: EnumMaterialableType) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await materialListApi?.ApiFactory(auth.token).userV1MaterialListsPinPost({
          pinMaterialListRequest: { materialableIds: ids, materialableType: materialableType }
        })

        await mutateListFilterContacts(), mutateListMaterialSitesLists()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update MaterialSitesListPinApi' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, materialListApi, me, mutateListFilterContacts, mutateListMaterialSitesLists]
  )

  return { error, isLoading, handleUpdateMaterialSitesListsPin }
}
