import { Box } from '@material-ui/core'
import { differenceInDays } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Message, Toaster, Typography } from 'src/components/atoms'
import {
  useGetOrganizationPaymentCurrentPlan,
  useRevokeCancelPayment
} from 'src/fixtures/modules/organizationPayment/hooks'
import { dateFormat, DateFormat } from 'src/fixtures/utils/time'
import { ContractCard } from './ContractCard'
import { center } from './styles'

export interface PaymentCanceledProps {
  onSubmit?: () => void
}

export const PaymentCanceled = ({ onSubmit }: PaymentCanceledProps) => {
  const { data } = useGetOrganizationPaymentCurrentPlan()
  const { isLoading, error, handleRevokeCancelPayment } = useRevokeCancelPayment()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const plan = data?.plan
  const contract = data?.contract
  const planText = useMemo(() => (plan ? plan.name : '-'), [plan])
  const startAtText = useMemo(
    () => (contract?.startAt ? dateFormat(contract.startAt, DateFormat.DATE_WITH_DAY) : '-'),
    [contract]
  )
  const endAtText = useMemo(
    () => (contract?.endAt ? dateFormat(contract.endAt, DateFormat.DATE_WITH_DAY) : '-'),
    [contract]
  )
  const days = useMemo(() => {
    return contract?.endAt ? differenceInDays(contract?.endAt, new Date()) : 0
  }, [contract?.endAt])

  const revokeCancel = useCallback(() => {
    if (window.confirm('ご契約手続きをキャンセルします。よろしいですか？')) {
      setIsSubmitting(true)
      handleRevokeCancelPayment()
    }
  }, [handleRevokeCancelPayment])

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setIsSubmitting(false)
      Toaster.success('ご契約手続きをキャンセルしました')
      onSubmit?.()
    }
  }, [isSubmitting, setIsSubmitting, isLoading, error, onSubmit])

  return (
    <>
      <Box sx={{ textAlign: 'center', mb: '16px' }}>
        <Typography fontSize="xl" fontWeight="bold">
          ご解約の手続きを承りました
        </Typography>
        <Typography fontSize="xl" fontWeight="bold">
          ご契約終了日までサービスをご利用いただけます
        </Typography>
      </Box>

      <Message type="error">
        <Typography fontSize="s" fontWeight="bold">
          ご契約終了まで{days}日です
        </Typography>
      </Message>

      <Box height="16px" />
      <ContractCard planText={planText} startAtText={startAtText} endAtText={endAtText} />

      <Box height="16px" />
      <Message type="warn">
        <Typography fontSize="s" fontWeight="bold">
          ご解約手続きをキャンセルすることで、月間契約の自動更新が再開されます
        </Typography>
      </Message>

      <Box sx={{ ...center, mt: '24px' }}>
        <Button onClick={revokeCancel} title={'ご契約手続きをキャンセルする'} />
      </Box>
    </>
  )
}
