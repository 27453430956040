/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationUserEmailSettingResponseData,
  UpdateOrganizationUserEmailSettingResponseDataFromJSON,
  UpdateOrganizationUserEmailSettingResponseDataFromJSONTyped,
  UpdateOrganizationUserEmailSettingResponseDataToJSON
} from './UpdateOrganizationUserEmailSettingResponseData'

/**
 *
 * @export
 * @interface UpdateOrganizationUserEmailSettingResponse
 */
export interface UpdateOrganizationUserEmailSettingResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateOrganizationUserEmailSettingResponse
   */
  status?: number
  /**
   *
   * @type {UpdateOrganizationUserEmailSettingResponseData}
   * @memberof UpdateOrganizationUserEmailSettingResponse
   */
  data?: UpdateOrganizationUserEmailSettingResponseData
}

export function UpdateOrganizationUserEmailSettingResponseFromJSON(
  json: any
): UpdateOrganizationUserEmailSettingResponse {
  return UpdateOrganizationUserEmailSettingResponseFromJSONTyped(json, false)
}

export function UpdateOrganizationUserEmailSettingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationUserEmailSettingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : UpdateOrganizationUserEmailSettingResponseDataFromJSON(json['data'])
  }
}

export function UpdateOrganizationUserEmailSettingResponseToJSON(
  value?: UpdateOrganizationUserEmailSettingResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: UpdateOrganizationUserEmailSettingResponseDataToJSON(value.data)
  }
}
