/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationPaymentAddonFromJSON, OrganizationPaymentAddonToJSON } from './OrganizationPaymentAddon';
export function UpdateOrganizationPaymentAddonUserOptionRequestFromJSON(json) {
    return UpdateOrganizationPaymentAddonUserOptionRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationPaymentAddonUserOptionRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        addon: OrganizationPaymentAddonFromJSON(json['addon'])
    };
}
export function UpdateOrganizationPaymentAddonUserOptionRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        addon: OrganizationPaymentAddonToJSON(value.addon)
    };
}
