import { useMemo, useState, useEffect, useCallback } from 'react'
import { Box, ButtonBase, ClickAwayListener, IconButton } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Button, Colors, DynamicMuiIcon, Pallete, SvgIcon, Typography } from 'src/components/atoms'
import { PageDocumentDetailPreviewSPHeader } from './Header'
import { ModalDocumentPageSelect } from 'src/components/modals/ModalDocumentPageSelect'
import { FloatingWindowButton } from '../FloatingWindowButton'
import { Document, Organization, OrganizationPolicySetting, Visitor } from '@noco/http-client/lib/noco/models'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { CTADialog } from '../../CTADialog'
import { useDocumentSlideDetail } from '../hooks'
import SwiperClass, { Swiper, SwiperSlide } from 'swiper/react'
import { SpHorizontalMode } from './SpHorizontalMode'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { CONTROLLER_SHOW_TIMEOUT } from './contant'
import { LinkImage } from '../LinkImage'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      background: Colors.background.lightGray
    },
    swiper: {
      width: '100%'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      width: 'calc(100% - 350px)'
    },
    currentSlideImg: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: ({ isOverImageHeight }: { isOverImageHeight: boolean }) => (isOverImageHeight ? 'fit-content' : '100%'),
      maxHeight: '100%',
      '& > img': {
        objectFit: 'contain',
        maxHeight: '100%',
        filter: `drop-shadow(0px 0px 1px ${Colors.background.silver})`
      }
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 80,
      zIndex: 100,
      background: '#fff',
      boxShadow: 'inset 0px 1px 0px #EEF1F4',
      gridArea: 'footer',
      flexShrink: 0
    },
    documentTitle: { lineHeight: 1, marginLeft: 8 },
    actionMenu: { background: 'white', borderTop: '1px solid #DADCE0', borderBottom: '1px solid #DADCE0', height: 40 }
  })
)

// NOTE @toshi1127 ライブラリがSwiperClassを外出ししていないので、一旦anyにしている
type SwiperClass = any

export const SPPageDocumentDetailPreview = ({
  document,
  isPreview,
  organization,
  visitor,
  expiredOn,
  siteId,
  documentShareId,
  siteShareId,
  canRequestToExtendExpiredAt,
  policySettings,
  isSpHorizontalMode,
  isActivated
}: {
  document: Document
  isPreview: boolean
  organization: Organization
  visitor?: Visitor
  expiredOn?: string | null
  siteId?: string
  documentShareId?: string
  siteShareId?: string
  canRequestToExtendExpiredAt?: boolean
  isSpHorizontalMode?: boolean
  policySettings?: OrganizationPolicySetting[]
  isActivated: boolean // 資料が認証されたかどうか (SP View の時は現状使っていない)
}) => {
  const [open, setOpen] = useState(false)
  const [swiper, setSwiper] = useState<SwiperClass>(undefined)
  const data = useDocumentSlideDetail({
    document,
    isPreview,
    organization,
    siteId,
    canRequestToExtendExpiredAt,
    isActivated
  })
  const {
    slideImages,
    totalPage,
    openPageSelectModal,
    isReviewRequestLoading,
    displayCta,
    shownPage,
    setShownPage,
    onOpenPageSelectModal,
    onClosePageSelectModal,
    setOpenCta,
    onPrevPage,
    onNextPage,
    onReviewRequest,
    handleCreateTrackCTA
  } = data
  const [isInitFloatingWindowButton, setIsInitFloatingWindowButton] = useState(false)
  const ctaPosition = useMemo(() => {
    return { top: '50%', left: '50%', transform: 'translate3d(-50%, -50%, 0px)' }
  }, [])
  const [slideHeight, setSlideHeight] = useState<number | string>('100%')
  const [showController, setShowController] = useState(false)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>()

  useEffect(() => {
    const currentSlideElements = window?.document.getElementsByClassName('swiper-slide-active')
    const height = currentSlideElements[0]?.firstElementChild?.clientHeight
    if (shownPage) {
      setSlideHeight(slideImages.length < shownPage ? '100%' : height || '100%')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownPage])

  useEffect(() => {
    const currentSlideElements = window?.document.getElementsByClassName('swiper-slide-active')
    const height = currentSlideElements[0]?.firstElementChild?.clientHeight
    if (shownPage) {
      setSlideHeight(slideImages.length < shownPage ? '100%' : height || '100%')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpHorizontalMode])

  const handleSetTimeout = useCallback(() => {
    setShowController(true)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const tId = setTimeout(() => {
      setShowController(false)
    }, CONTROLLER_SHOW_TIMEOUT)
    setTimeoutId(tId)
  }, [timeoutId])

  const [isOverImageHeight, setIsOverImageHeight] = useState(false)
  const classes = useStyles({ isOverImageHeight })

  useEffect(() => {
    const handler = () => {
      const wrapperElement = window.document.getElementById('imageWrapper') as HTMLDivElement | undefined
      const imgElement = window.document.getElementsByClassName('image-map__content__img')?.[0] as
        | HTMLImageElement
        | undefined

      const isOver = wrapperElement && imgElement ? wrapperElement.clientHeight > imgElement.clientHeight : false
      setIsOverImageHeight(isOver)
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  if (isSpHorizontalMode) {
    return (
      <SpHorizontalMode
        {...data}
        open={open}
        siteId={siteId}
        organization={organization}
        policySettings={policySettings}
        slideHeight={slideHeight}
        document={document}
        ctaPosition={ctaPosition}
        visitor={visitor}
        isPreview={isPreview}
        documentShareId={documentShareId}
        siteShareId={siteShareId}
        isSpHorizontalMode={isSpHorizontalMode}
        isInitFloatingWindowButton={isInitFloatingWindowButton}
        isActivated={isActivated}
        onNextPage={() => {
          onNextPage()
          swiper.slideNext()
        }}
        onPrevPage={() => {
          onPrevPage()
          swiper.slidePrev()
        }}
        setOpen={setOpen}
        setSwiper={setSwiper}
        handleCreateTrackCTA={handleCreateTrackCTA}
        setSlideHeight={setSlideHeight}
        setIsInitFloatingWindowButton={setIsInitFloatingWindowButton}
        changeShownPage={v => {
          setShownPage(v)
          swiper?.slideTo((v || 1) - 1)
          onClosePageSelectModal()
        }}
      />
    )
  }

  return (
    <Box className={classes.root} height="100%" width="100%" overflow={'hidden'}>
      {/* CTA */}
      {isActivated && (
        <Box
          style={{
            ...ctaPosition,
            position: 'absolute',
            pointerEvents: displayCta ? 'auto' : 'none',
            zIndex: 9999,
            opacity: displayCta ? 1 : 0,
            transition: '512ms',
            padding: '32px'
          }}
        >
          <ClickAwayListener disableReactTree onClickAway={() => setOpenCta(false)}>
            <CTADialog
              cta={document.documentCta}
              onClose={() => setOpenCta(false)}
              onClickCtaButton={() => handleCreateTrackCTA()}
            />
          </ClickAwayListener>
        </Box>
      )}

      <Box
        height="-webkit-fill-available"
        width={1}
        display="grid"
        gridTemplateColumns="1fr"
        gridTemplateRows="minmax(97px, auto)"
        gridTemplateAreas='"header" "main" "footer"'
        overflow={'hidden'}
      >
        <PageDocumentDetailPreviewSPHeader
          document={document}
          organization={organization}
          isEuView={!!visitor}
          siteId={siteId}
        />
        <Box
          height={1}
          width={1}
          display="flex"
          alignItems={'center'}
          justifyContent="center"
          overflow="hidden"
          flexDirection="column"
          gridArea="main"
        >
          {canRequestToExtendExpiredAt ? (
            <Box
              position="relative"
              p="16px"
              display="flex"
              alignItems="center"
              bgcolor={Pallete.functional.background.default}
              height={1}
              width={1}
              flexDirection="column"
              justifyContent="center"
            >
              <Box mb="16px">
                <Typography fontSize="xxl" fontWeight="bold">
                  資料の公開が終了しました
                </Typography>
              </Box>
              <Box mb="16px">
                <Button
                  disabled={isReviewRequestLoading}
                  title="資料の閲覧をリクエストする"
                  onClick={onReviewRequest}
                />
              </Box>
              <img src="/images/request_document.png" width="100%" />
            </Box>
          ) : (
            <Swiper
              className={classes.swiper}
              initialSlide={(shownPage || 1) - 1}
              style={{ height: '100%' }}
              onSwiper={setSwiper}
              onClick={() => {
                handleSetTimeout()
              }}
              onSlideChange={swipe => {
                setShownPage(swipe.activeIndex + 1)
              }}
            >
              <Box
                width="100%"
                height="100%"
                position={'absolute'}
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                justifyContent="space-between"
                style={{
                  pointerEvents: 'none',
                  zIndex: 9999999,
                  opacity: showController ? 1.0 : 0,
                  transition: '512ms'
                }}
              >
                <ButtonBase
                  style={{
                    height: '100%',
                    width: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    pointerEvents: showController ? 'auto' : 'none',
                    backgroundColor: 'rgba(0,0,0,0.3)'
                  }}
                  onClick={() => {
                    onPrevPage()
                    swiper.slidePrev()
                    handleSetTimeout()
                  }}
                >
                  <KeyboardArrowLeft style={{ color: 'white', marginTop: '0px' }} />
                </ButtonBase>
                <ButtonBase
                  style={{
                    height: '100%',
                    width: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    pointerEvents: showController ? 'auto' : 'none',
                    backgroundColor: 'rgba(0,0,0,0.4)'
                  }}
                  onClick={() => {
                    onNextPage()
                    swiper.slideNext()
                    handleSetTimeout()
                  }}
                >
                  <KeyboardArrowRight style={{ color: 'white', marginTop: '0px' }} />
                </ButtonBase>
              </Box>
              {slideImages.map((slideImage, index) => {
                const currentPage = (shownPage ?? 0) - 1
                const shownImage = currentPage - 3 <= index && index <= currentPage + 3
                return (
                  <SwiperSlide key={slideImage.file?.url}>
                    {shownImage && (
                      <Box height="100vh" maxHeight={'100%'} display="flex">
                        <LinkImage
                          className={classes.currentSlideImg}
                          imgSrc={slideImage.file?.url}
                          links={slideImage.slideImageLinks}
                          onLoad={() => {
                            const currentSlideElements = window?.document.getElementsByClassName('swiper-slide-active')
                            const height = currentSlideElements[0]?.firstElementChild?.clientHeight
                            if (height) {
                              setSlideHeight(height)
                            }
                          }}
                        />
                      </Box>
                    )}
                  </SwiperSlide>
                )
              })}
              {!organization.hideAd && (
                <SwiperSlide>
                  <Box
                    position="relative"
                    style={{
                      padding: '16px',
                      paddingTop: '24px'
                    }}
                    display="flex"
                    alignItems="center"
                    bgcolor={Pallete.functional.background.default}
                    height="100%"
                  >
                    <Box
                      position="absolute"
                      top={16}
                      right={16}
                      borderRadius="4px"
                      border={`2px solid ${Colors.accent.keyPurple.default}`}
                    >
                      <Typography variant="h5" style={{ padding: '0 8px', color: Colors.accent.keyPurple.default }}>
                        PR
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      height={1}
                      flex={1}
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <img src="/images/nocosell_logo.svg" width={120} />
                      <Box mt="20px">
                        <Typography variant="h2">クラウドで顧客獲得はじめよう</Typography>
                      </Box>

                      <Box mt="20px">
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
                        >{`ノコセルは 資料を共有するだけで\n資料の閲覧追跡・顧客管理・コミュニケーションまで行える\nクラウド顧客獲得ツールです`}</Typography>
                      </Box>

                      <Box mt="20px">
                        <Button
                          title="無料ではじめる"
                          onClick={() => {
                            window.open(
                              'https://noco.sale/?utm_source=noco_sales&utm_medium=service_pr&utm_campaign=last_page_pr',
                              '_blank'
                            )
                          }}
                        />
                      </Box>

                      <Box mt="20px">
                        <Typography
                          variant="h5"
                          style={{ textAlign: 'center', color: Colors.accent.keyPurple.default }}
                        >
                          この資料はノコセルで作成されています
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </SwiperSlide>
              )}
            </Swiper>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            className={classes.actionMenu}
          >
            <Box>
              <IconButton
                onClick={() => {
                  onPrevPage()
                  swiper.slidePrev()
                }}
              >
                <SvgIcon variant="leftArrow" color={Colors.base.middleGray} size="12px" />
              </IconButton>

              <IconButton
                onClick={() => {
                  onNextPage()
                  swiper.slideNext()
                }}
              >
                <SvgIcon variant="rightArrow" color={Colors.base.middleGray} size="12px" />
              </IconButton>
            </Box>

            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                {shownPage ?? 1}/{totalPage}ページ
              </Typography>
              <Box ml={1}>
                <IconButton onClick={onOpenPageSelectModal}>
                  <SvgIcon variant="viewModule" color="#6D7784" size="16px" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Box width={1} position="relative" height={'100%'} sx={{ px: '14px', display: 'flex', alignItems: 'center' }}>
            <Box display="flex" flexDirection="column" width={1}>
              <Box display="flex" alignItems="center" mb="8px">
                <SvgIcon variant="company" color={Colors.base.middleGray} size="14px" />
                <Typography className={classes.documentTitle} variant="subtitle2">
                  {organization?.name}
                </Typography>
              </Box>
              {expiredOn && (
                <Box display="flex" alignItems="center">
                  <DynamicMuiIcon variant="meeting" size="14px" color="action" />
                  <Typography className={classes.documentTitle} variant="subtitle2">
                    閲覧期限 {dateFormat(dateStringToMilliseconds(expiredOn), DateFormat.DATE_WITH_DAY)} まで
                  </Typography>
                </Box>
              )}
            </Box>
            <Box position={'absolute'} zIndex={99} right="12px" bottom="8px">
              <FloatingWindowButton
                isSp
                policySettings={policySettings}
                document={document}
                siteId={siteId}
                organization={organization}
                visitor={visitor}
                isPreview={isPreview}
                documentShareId={documentShareId}
                siteShareId={siteShareId}
                isSpHorizontalMode={isSpHorizontalMode}
                init={isInitFloatingWindowButton}
                setInit={setIsInitFloatingWindowButton}
                open={open}
                setOpen={setOpen}
                isActivated={isActivated}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalDocumentPageSelect
        slideImages={document?.currentSlide?.slideImages ?? []}
        open={openPageSelectModal}
        onClose={onClosePageSelectModal}
        setShownPage={v => {
          setShownPage(v)
          swiper?.slideTo((v || 1) - 1)
          onClosePageSelectModal()
        }}
      />
    </Box>
  )
}
