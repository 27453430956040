import { useEffect, useState } from 'react'
import { useChannelIOApi } from 'react-channel-plugin'
import { useAuthenticate } from 'src/fixtures/modules/auth/hooks'
import { useGetMe, useGetMeProfile } from 'src/fixtures/modules/me/hooks'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'

export const useChannelTalk = () => {
  const { boot, updateUser, showMessenger } = useChannelIOApi()

  const [isInitialize, setIsInitialize] = useState(false)
  const { data: meRes, mutate: mutateMe } = useGetMe()
  const { data: orgRes, mutate: mutateOrg } = useGetOrganization()
  const { data: profile, mutate: mutateProfile } = useGetMeProfile()
  const { data: auth } = useAuthenticate()

  useEffect(() => {
    Promise.all([mutateMe(), mutateOrg(), mutateProfile()])
  }, [mutateMe, mutateOrg, mutateProfile])

  useEffect(() => {
    if (auth && meRes?.user && orgRes?.organization && profile && !isInitialize) {
      setIsInitialize(true)
      const me = meRes.user!
      const org = orgRes.organization!
      boot().then(() => {
        setTimeout(() => {
          updateUser({
            profile: {
              name: `${me.lastName} ${me.firstName}`,
              jobPosition: profile.position || undefined,
              department: profile.department || undefined,
              email: me.encryptedEmail,
              organizationName: org.name,
              organizationID: org.id,
              servicePlan: org.plan?.name,
              appEnv: process.env.APP_ENV
            }
          })
        })
      })
    }
  }, [auth, boot, isInitialize, meRes?.user, orgRes?.organization, profile, updateUser])

  // ログアウト時
  useEffect(() => {
    if (isInitialize && !auth) {
      setIsInitialize(false)
    }
  }, [auth, isInitialize])
  return {
    showMessenger
  }
}
