/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { ContactList, ContactListFromJSON, ContactListFromJSONTyped, ContactListToJSON } from './ContactList'

/**
 *
 * @export
 * @interface GetContactListsResponseData
 */
export interface GetContactListsResponseData {
  /**
   *
   * @type {Array<ContactList>}
   * @memberof GetContactListsResponseData
   */
  contactLists?: Array<ContactList>
}

export function GetContactListsResponseDataFromJSON(json: any): GetContactListsResponseData {
  return GetContactListsResponseDataFromJSONTyped(json, false)
}

export function GetContactListsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactListsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactLists: !exists(json, 'contact_lists')
      ? undefined
      : (json['contact_lists'] as Array<any>).map(ContactListFromJSON)
  }
}

export function GetContactListsResponseDataToJSON(value?: GetContactListsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_lists:
      value.contactLists === undefined ? undefined : (value.contactLists as Array<any>).map(ContactListToJSON)
  }
}
