/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  BillingInvoiceDiscount,
  BillingInvoiceDiscountFromJSON,
  BillingInvoiceDiscountFromJSONTyped,
  BillingInvoiceDiscountToJSON
} from './BillingInvoiceDiscount'
import {
  BillingInvoiceItem,
  BillingInvoiceItemFromJSON,
  BillingInvoiceItemFromJSONTyped,
  BillingInvoiceItemToJSON
} from './BillingInvoiceItem'

/**
 *
 * @export
 * @interface BillingInvoice
 */
export interface BillingInvoice {
  /**
   *
   * @type {Array<BillingInvoiceItem>}
   * @memberof BillingInvoice
   */
  items: Array<BillingInvoiceItem>
  /**
   * 小計
   * @type {number}
   * @memberof BillingInvoice
   */
  subtotal: number
  /**
   * 消費税
   * @type {number}
   * @memberof BillingInvoice
   */
  tax: number
  /**
   * 合計
   * @type {number}
   * @memberof BillingInvoice
   */
  total: number
  /**
   *
   * @type {BillingInvoiceDiscount}
   * @memberof BillingInvoice
   */
  discount?: BillingInvoiceDiscount
}

export function BillingInvoiceFromJSON(json: any): BillingInvoice {
  return BillingInvoiceFromJSONTyped(json, false)
}

export function BillingInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingInvoice {
  if (json === undefined || json === null) {
    return json
  }
  return {
    items: (json['items'] as Array<any>).map(BillingInvoiceItemFromJSON),
    subtotal: json['subtotal'],
    tax: json['tax'],
    total: json['total'],
    discount: !exists(json, 'discount') ? undefined : BillingInvoiceDiscountFromJSON(json['discount'])
  }
}

export function BillingInvoiceToJSON(value?: BillingInvoice | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    items: (value.items as Array<any>).map(BillingInvoiceItemToJSON),
    subtotal: value.subtotal,
    tax: value.tax,
    total: value.total,
    discount: BillingInvoiceDiscountToJSON(value.discount)
  }
}
