/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AvatarFromJSON, AvatarToJSON } from './Avatar';
import { EnumPublicationStatusFromJSON, EnumPublicationStatusToJSON } from './EnumPublicationStatus';
import { EnumSharingStatusFromJSON, EnumSharingStatusToJSON } from './EnumSharingStatus';
import { SelectorItemExtendCompanyFromJSON, SelectorItemExtendCompanyToJSON } from './SelectorItemExtendCompany';
import { SelectorItemExtendContactFromJSON, SelectorItemExtendContactToJSON } from './SelectorItemExtendContact';
import { UploadedFileFromJSON, UploadedFileToJSON } from './UploadedFile';
export function SelectorItemExtendFromJSON(json) {
    return SelectorItemExtendFromJSONTyped(json, false);
}
export function SelectorItemExtendFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
        contact: !exists(json, 'contact') ? undefined : SelectorItemExtendContactFromJSON(json['contact']),
        company: !exists(json, 'company') ? undefined : SelectorItemExtendCompanyFromJSON(json['company']),
        domain: !exists(json, 'domain') ? undefined : json['domain'],
        avatar: !exists(json, 'avatar') ? undefined : AvatarFromJSON(json['avatar']),
        description: !exists(json, 'description') ? undefined : json['description'],
        currentSlideThumbnail: !exists(json, 'current_slide_thumbnail')
            ? undefined
            : UploadedFileFromJSON(json['current_slide_thumbnail']),
        isPublished: !exists(json, 'is_published') ? undefined : json['is_published'],
        sharingStatus: !exists(json, 'sharing_status') ? undefined : EnumSharingStatusFromJSON(json['sharing_status']),
        publicationStatus: !exists(json, 'publication_status')
            ? undefined
            : EnumPublicationStatusFromJSON(json['publication_status']),
        datetime: !exists(json, 'datetime') ? undefined : json['datetime']
    };
}
export function SelectorItemExtendToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        encrypted_email: value.encryptedEmail,
        contact: SelectorItemExtendContactToJSON(value.contact),
        company: SelectorItemExtendCompanyToJSON(value.company),
        domain: value.domain,
        avatar: AvatarToJSON(value.avatar),
        description: value.description,
        current_slide_thumbnail: UploadedFileToJSON(value.currentSlideThumbnail),
        is_published: value.isPublished,
        sharing_status: EnumSharingStatusToJSON(value.sharingStatus),
        publication_status: EnumPublicationStatusToJSON(value.publicationStatus),
        datetime: value.datetime
    };
}
