import { Box } from '@material-ui/core'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { SelectTableRow, CustomButtonListType } from 'src/components/molecules'
import {
  UserV1SelectorContactListsGetRequest,
  UserV1SelectorCompanyListsGetRequest,
  SelectorItemObject
} from '@noco/http-client/lib/noco'
import { SWRResponse } from 'swr'

export interface FloatingDialogProps {
  domain: 'company' | 'contact'
  selectedIds: string[]
  activeType: string
  setModalArchiveOpen: (value: boolean) => void
  setSelection: (value: number[]) => void
  onCreateListConfirm: (listName: string, contactIds: string[]) => void
  onUpdateListConfirm: (contactListId: string, contactIds: string[]) => void
  onClickRemove: () => void
  useList: (
    params: UserV1SelectorContactListsGetRequest | UserV1SelectorCompanyListsGetRequest
  ) => SWRResponse<SelectorItemObject | undefined, any>
}

export const FloatingDialog = ({
  domain,
  selectedIds,
  activeType,
  setModalArchiveOpen,
  setSelection,
  onCreateListConfirm,
  onUpdateListConfirm,
  onClickRemove,
  useList
}: FloatingDialogProps) => {
  const [keyword, setKeyword] = useState('')
  const [modalAddOpen, setModalAddOpen] = useState(false)

  const { data: selectorList, mutate: selectorListMutate } = useList({ page: 1, per: 9999, text: keyword })

  useEffect(() => {
    selectorListMutate()
  }, [selectorListMutate, selectorList])

  const customButtonList: CustomButtonListType[] = useMemo(() => {
    switch (activeType) {
      case 'archive':
        return [{ buttonTitle: 'アーカイブから戻す', onConfirm: () => setModalArchiveOpen(true) }]
      case 'list':
        return [
          { buttonTitle: 'リストに追加', onConfirm: () => setModalAddOpen(true) },
          { buttonTitle: 'リストから取り除く', onConfirm: onClickRemove },
          {
            showIcon: 'archive',
            buttonTitle: `${domain === 'company' ? '会社' : '連絡先'}のアーカイブ`,
            onConfirm: () => setModalArchiveOpen(true)
          }
        ]
    }
    return [
      { buttonTitle: 'リストに追加', onConfirm: () => setModalAddOpen(true) },
      {
        showIcon: 'archive',
        buttonTitle: `${domain === 'company' ? '会社' : '連絡先'}のアーカイブ`,
        onConfirm: () => setModalArchiveOpen(true)
      }
    ]
  }, [activeType, domain, onClickRemove, setModalArchiveOpen])

  const handleClickCloseArchive = useCallback(() => {
    setSelection([])
  }, [setSelection])

  if (selectedIds.length == 0) {
    return null
  }
  return (
    <Box position="absolute" bottom="60px" px="16px" width="calc(100% - 296px)" zIndex={999} right={0}>
      <SelectTableRow
        count={selectedIds.length}
        selectedIds={selectedIds}
        onClose={handleClickCloseArchive}
        keyword={keyword}
        selectorList={selectorList}
        onSetKeyword={setKeyword}
        onCreateList={onCreateListConfirm}
        onUpdateList={onUpdateListConfirm}
        customButtonList={customButtonList}
        modalAddContactOpen={modalAddOpen}
        onSetModalAddContactOpen={setModalAddOpen}
      />
    </Box>
  )
}
