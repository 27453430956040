/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ChatMessageAttachment,
  ChatMessageAttachmentFromJSON,
  ChatMessageAttachmentFromJSONTyped,
  ChatMessageAttachmentToJSON
} from './ChatMessageAttachment'
import {
  ChatMessageContactEmailTransaction,
  ChatMessageContactEmailTransactionFromJSON,
  ChatMessageContactEmailTransactionFromJSONTyped,
  ChatMessageContactEmailTransactionToJSON
} from './ChatMessageContactEmailTransaction'
import {
  EnumChatMessageCreateCausedBy,
  EnumChatMessageCreateCausedByFromJSON,
  EnumChatMessageCreateCausedByFromJSONTyped,
  EnumChatMessageCreateCausedByToJSON
} from './EnumChatMessageCreateCausedBy'
import {
  EnumChatMessageSendedByType,
  EnumChatMessageSendedByTypeFromJSON,
  EnumChatMessageSendedByTypeFromJSONTyped,
  EnumChatMessageSendedByTypeToJSON
} from './EnumChatMessageSendedByType'
import {
  EnumEmailTransactionableSendStatus,
  EnumEmailTransactionableSendStatusFromJSON,
  EnumEmailTransactionableSendStatusFromJSONTyped,
  EnumEmailTransactionableSendStatusToJSON
} from './EnumEmailTransactionableSendStatus'
import { Materialable, MaterialableFromJSON, MaterialableFromJSONTyped, MaterialableToJSON } from './Materialable'
import { SendedBy, SendedByFromJSON, SendedByFromJSONTyped, SendedByToJSON } from './SendedBy'
import { UserProfile, UserProfileFromJSON, UserProfileFromJSONTyped, UserProfileToJSON } from './UserProfile'

/**
 *
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  contactId?: string
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  documentId?: string
  /**
   *
   * @type {EnumChatMessageCreateCausedBy}
   * @memberof ChatMessage
   */
  createCausedBy?: EnumChatMessageCreateCausedBy
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  message?: string
  /**
   *
   * @type {Array<any>}
   * @memberof ChatMessage
   */
  files?: Array<any>
  /**
   *
   * @type {EnumEmailTransactionableSendStatus}
   * @memberof ChatMessage
   */
  sendEmailStatus?: EnumEmailTransactionableSendStatus | null
  /**
   *
   * @type {EnumChatMessageSendedByType}
   * @memberof ChatMessage
   */
  sendedByType?: EnumChatMessageSendedByType
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  sendedById?: string
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  readAt?: string | null
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  editedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ChatMessage
   */
  updatedAt?: string
  /**
   *
   * @type {ChatMessageContactEmailTransaction}
   * @memberof ChatMessage
   */
  contactEmailTransaction?: ChatMessageContactEmailTransaction | null
  /**
   *
   * @type {ChatMessageContactEmailTransaction}
   * @memberof ChatMessage
   */
  userEmailTransaction?: ChatMessageContactEmailTransaction | null
  /**
   *
   * @type {Array<ChatMessageAttachment>}
   * @memberof ChatMessage
   */
  chatMessageAttachments?: Array<ChatMessageAttachment> | null
  /**
   *
   * @type {Materialable}
   * @memberof ChatMessage
   */
  materialable?: Materialable
  /**
   *
   * @type {SendedBy}
   * @memberof ChatMessage
   */
  sendedBy?: SendedBy
  /**
   *
   * @type {UserProfile}
   * @memberof ChatMessage
   */
  userProfile?: UserProfile
}

export function ChatMessageFromJSON(json: any): ChatMessage {
  return ChatMessageFromJSONTyped(json, false)
}

export function ChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessage {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    createCausedBy: !exists(json, 'create_caused_by')
      ? undefined
      : EnumChatMessageCreateCausedByFromJSON(json['create_caused_by']),
    message: !exists(json, 'message') ? undefined : json['message'],
    files: !exists(json, 'files') ? undefined : json['files'],
    sendEmailStatus: !exists(json, 'send_email_status')
      ? undefined
      : EnumEmailTransactionableSendStatusFromJSON(json['send_email_status']),
    sendedByType: !exists(json, 'sended_by_type')
      ? undefined
      : EnumChatMessageSendedByTypeFromJSON(json['sended_by_type']),
    sendedById: !exists(json, 'sended_by_id') ? undefined : json['sended_by_id'],
    readAt: !exists(json, 'read_at') ? undefined : json['read_at'],
    editedAt: !exists(json, 'edited_at') ? undefined : json['edited_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    contactEmailTransaction: !exists(json, 'contact_email_transaction')
      ? undefined
      : ChatMessageContactEmailTransactionFromJSON(json['contact_email_transaction']),
    userEmailTransaction: !exists(json, 'user_email_transaction')
      ? undefined
      : ChatMessageContactEmailTransactionFromJSON(json['user_email_transaction']),
    chatMessageAttachments: !exists(json, 'chat_message_attachments')
      ? undefined
      : json['chat_message_attachments'] === null
      ? null
      : (json['chat_message_attachments'] as Array<any>).map(ChatMessageAttachmentFromJSON),
    materialable: !exists(json, 'materialable') ? undefined : MaterialableFromJSON(json['materialable']),
    sendedBy: !exists(json, 'sended_by') ? undefined : SendedByFromJSON(json['sended_by']),
    userProfile: !exists(json, 'user_profile') ? undefined : UserProfileFromJSON(json['user_profile'])
  }
}

export function ChatMessageToJSON(value?: ChatMessage | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    contact_id: value.contactId,
    document_id: value.documentId,
    create_caused_by: EnumChatMessageCreateCausedByToJSON(value.createCausedBy),
    message: value.message,
    files: value.files,
    send_email_status: EnumEmailTransactionableSendStatusToJSON(value.sendEmailStatus),
    sended_by_type: EnumChatMessageSendedByTypeToJSON(value.sendedByType),
    sended_by_id: value.sendedById,
    read_at: value.readAt,
    edited_at: value.editedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    contact_email_transaction: ChatMessageContactEmailTransactionToJSON(value.contactEmailTransaction),
    user_email_transaction: ChatMessageContactEmailTransactionToJSON(value.userEmailTransaction),
    chat_message_attachments:
      value.chatMessageAttachments === undefined
        ? undefined
        : value.chatMessageAttachments === null
        ? null
        : (value.chatMessageAttachments as Array<any>).map(ChatMessageAttachmentToJSON),
    materialable: MaterialableToJSON(value.materialable),
    sended_by: SendedByToJSON(value.sendedBy),
    user_profile: UserProfileToJSON(value.userProfile)
  }
}
