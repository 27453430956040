/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
import { SiteDocumentFromJSON, SiteDocumentToJSON } from './SiteDocument';
export function GetSiteDocumentsResponseDataFromJSON(json) {
    return GetSiteDocumentsResponseDataFromJSONTyped(json, false);
}
export function GetSiteDocumentsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        siteDocuments: !exists(json, 'site_documents')
            ? undefined
            : json['site_documents'].map(SiteDocumentFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetSiteDocumentsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        site_documents: value.siteDocuments === undefined ? undefined : value.siteDocuments.map(SiteDocumentToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
