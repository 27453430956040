import { Box, ButtonBase, Slide, Typography } from '@material-ui/core'
import { Colors, DynamicMuiIcon, OptionListType, SvgIcon, Tooltip } from 'src/components/atoms'
import { useGetDocument, useUpdateDocumentCta } from 'src/fixtures/modules/documents/hooks'
import { CTASettingCard, SelectDisplayPopup, SettingToggleSectionCard } from '..'
import { EnumCtaPopupPosition } from '@noco/http-client/lib/noco/models/EnumCtaPopupPosition'
import { useMemo } from 'react'
export interface DocumentSettingCtaProps {
  open: boolean
  documentId: string
  onClose: () => void
}

export const PopupPosition = {
  TOP_LEFT: EnumCtaPopupPosition.TopLeft,
  TOP_RIGHT: EnumCtaPopupPosition.TopRight,
  BOTTOM_LEFT: EnumCtaPopupPosition.BottomLeft,
  BOTTOM_RIGHT: EnumCtaPopupPosition.BottomRight,
  CENTER: EnumCtaPopupPosition.Center
}

export type PopupPosition = typeof PopupPosition[keyof typeof PopupPosition]

export const DocumentSettingCta = ({ documentId, open, onClose }: DocumentSettingCtaProps) => {
  const { handleUpdateDocumentCta } = useUpdateDocumentCta(documentId)
  const { data: document } = useGetDocument(documentId)
  const cta = document?.documentCta

  const pageOptionList: OptionListType[] = useMemo(() => {
    return (
      document?.currentSlide?.slideImages?.map((_, index) => {
        const p = index + 1
        return { value: `${p}`, label: `${p}ページ` }
      }) || []
    )
  }, [document])
  return (
    <Box flexShrink={0} overflow="auto">
      <Slide direction="left" in={open}>
        <Box width="100%" bgcolor={Colors.functional.background.default} p="18px 10px" color={Colors.base.black}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="8px" px="6px">
            <Box display="flex" alignItems="center">
              <SvgIcon variant="adsClick" size="16px" color={Colors.base.middleGray} />
              <Box mr="6px" />
              <Tooltip
                icon
                iconSize="16px"
                content={`特定の資料ページを顧客が閲覧すると、ポップアップ形式のバナーを画面に表示する機能です。

イベントやキャンペーンなど、さまざまな情報を案内したい時に役立ちます。`}
              >
                <Typography variant="h5">CTA</Typography>
              </Tooltip>
            </Box>
            <ButtonBase onClick={onClose}>
              <DynamicMuiIcon variant="close" size="20px" color="action" />
            </ButtonBase>
          </Box>
          {cta && (
            <>
              <SettingToggleSectionCard
                title="CTAを資料に表示"
                checked={cta.isView!}
                onChange={() => {
                  handleUpdateDocumentCta({
                    documentCta: { isView: cta?.isView !== undefined ? !cta?.isView : false }
                  })
                }}
              />

              <Box mt="32px" />
              <Box ml="6px">
                <Typography variant="h5">表示位置</Typography>
              </Box>
              <Box mt="8px" />
              <SelectDisplayPopup
                currentPage={String(cta?.page ?? '')}
                pageOptionList={pageOptionList}
                position={cta?.popupPosition || PopupPosition.CENTER}
                onChangePosition={position =>
                  handleUpdateDocumentCta({ documentCta: { popupPosition: position.toString() } })
                }
                onChangePage={page => handleUpdateDocumentCta({ documentCta: { page } })}
              />
              <Box mt="32px" />

              <CTASettingCard
                headline={cta?.title || ''}
                onChangeHeadline={e => handleUpdateDocumentCta({ documentCta: { title: e.target.value } })}
                description={cta?.description || ''}
                onChangeDescription={e => handleUpdateDocumentCta({ documentCta: { description: e.target.value } })}
                color={cta?.buttonColor || Colors.accent.keyRed.default}
                onChangeColor={color => handleUpdateDocumentCta({ documentCta: { buttonColor: color } })}
                url={cta?.buttonUrl || ''}
                onChangeUrl={e => handleUpdateDocumentCta({ documentCta: { buttonUrl: e.target.value } })}
                buttonName={cta?.buttonName || ''}
                onChangeButtonName={e => handleUpdateDocumentCta({ documentCta: { buttonName: e.target.value } })}
                // TODO: 正式版以降 @snuffy image 実装
                // image={cta?.image}
                // onChangeImage={onChangeImage}
              />
            </>
          )}
        </Box>
      </Slide>
    </Box>
  )
}
