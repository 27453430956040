import { Avatar, Box, createStyles, IconButton, makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core'
import { Close, Info } from '@material-ui/icons'
import { GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner } from '@noco/http-client/lib/noco'
import { useState, useCallback } from 'react'
import { Button, Colors, Pallete } from 'src/components/atoms'
import { ProfileCard } from 'src/components/organisms'

const useStyles = makeStyles(() =>
  createStyles({
    toolTipCloseButton: {
      position: 'absolute',
      alignItems: 'baseline',
      padding: 0,
      right: 16,
      top: 16,
      color: Pallete.functional.background.default,
      height: 'fit-content',
      pointerEvents: 'auto'
    },
    infoButton: { padding: 0 }
  })
)

interface AppointmentListItemProps {
  user: GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner
  onOpportunity: () => void
  onViewProfile: () => void
}

export const AppointmentListItem = ({ user, onOpportunity, onViewProfile }: AppointmentListItemProps) => {
  const classes = useStyles()
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltipClose = useCallback(() => {
    setOpenTooltip(false)
  }, [])

  const handleTooltipOpen = useCallback(() => {
    onViewProfile()
    setOpenTooltip(true)
  }, [onViewProfile])

  const handleClick = useCallback(() => {
    onOpportunity()
    window.open(String(user.opportunityToolUrl))
  }, [onOpportunity, user.opportunityToolUrl])

  return (
    <Box
      p="16px"
      borderRadius="10px"
      border={`1px solid ${Colors.background.gray}`}
      bgcolor={Colors.background.lightGray}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Avatar src={user.userProfile?.userAvatar?.url ?? ''} />

          <Box display="flex" flexDirection="column" ml={3}>
            <Typography variant="subtitle2">{user.userProfile?.department}</Typography>

            <Box display="flex" alignItems="center">
              <Typography variant="h4" style={{ color: Colors.accent.keyBlue.default }}>
                {user.userName}
              </Typography>

              <HtmlTooltip
                onClose={handleTooltipClose}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                PopperProps={{
                  style: {
                    zIndex: 100000
                  }
                }}
                title={
                  <Box display="flex" alignContent="flex-start">
                    <ProfileCard
                      birthplace={user.userProfile?.birthplace}
                      department={user.userProfile?.department}
                      hobby={user.userProfile?.hobby}
                      organizationName={user.organizationName}
                      position={user.userProfile?.position}
                      selfIntroduction={user.userProfile?.selfIntroduction}
                      socialLinkYoutube={user.userProfile?.socialLinkYoutube}
                      socialLinkFacebook={user.userProfile?.socialLinkFacebook}
                      socialLinkTwitter={user.userProfile?.socialLinkTwitter}
                      socialLinkNote={user.userProfile?.socialLinkNote}
                      socialLinkWebSite={user.userProfile?.socialLinkWebSite}
                      userName={user.userName}
                      userAvatarUrl={user.userAvatar?.url}
                    />
                    <IconButton className={classes.toolTipCloseButton} onClick={handleTooltipClose}>
                      <Close />
                    </IconButton>
                  </Box>
                }
              >
                <IconButton className={classes.infoButton} onClick={handleTooltipOpen}>
                  <Info fontSize="small" htmlColor={Colors.accent.keyBlue.default} />
                </IconButton>
              </HtmlTooltip>
            </Box>
          </Box>
        </Box>

        {user.opportunityToolUrl && (
          <Box flexShrink="0">
            <Button size="medium" title="予約" onClick={handleClick} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    position: 'relative',
    padding: 0,
    borderRadius: 10
  }
}))(Tooltip)
