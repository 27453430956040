/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateOrganizationPaymentScheduleBillingInvoiceResponseData,
  CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSON,
  CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSONTyped,
  CreateOrganizationPaymentScheduleBillingInvoiceResponseDataToJSON
} from './CreateOrganizationPaymentScheduleBillingInvoiceResponseData'

/**
 *
 * @export
 * @interface CreateOrganizationPaymentScheduleBillingInvoiceResponse
 */
export interface CreateOrganizationPaymentScheduleBillingInvoiceResponse {
  /**
   *
   * @type {number}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceResponse
   */
  status: number
  /**
   *
   * @type {CreateOrganizationPaymentScheduleBillingInvoiceResponseData}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceResponse
   */
  data: CreateOrganizationPaymentScheduleBillingInvoiceResponseData
}

export function CreateOrganizationPaymentScheduleBillingInvoiceResponseFromJSON(
  json: any
): CreateOrganizationPaymentScheduleBillingInvoiceResponse {
  return CreateOrganizationPaymentScheduleBillingInvoiceResponseFromJSONTyped(json, false)
}

export function CreateOrganizationPaymentScheduleBillingInvoiceResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateOrganizationPaymentScheduleBillingInvoiceResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSON(json['data'])
  }
}

export function CreateOrganizationPaymentScheduleBillingInvoiceResponseToJSON(
  value?: CreateOrganizationPaymentScheduleBillingInvoiceResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CreateOrganizationPaymentScheduleBillingInvoiceResponseDataToJSON(value.data)
  }
}
