/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers,
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSON,
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSONTyped,
  GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersToJSON
} from './GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit
 */
export interface GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit {
  /**
   *
   * @type {GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit
   */
  users: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers
  /**
   *
   * @type {GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers}
   * @memberof GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit
   */
  emailTemplates: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsers
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSON(
  json: any
): GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit {
  return GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit {
  if (json === undefined || json === null) {
    return json
  }
  return {
    users: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSON(json['users']),
    emailTemplates: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSON(json['email_templates'])
  }
}

export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitToJSON(
  value?: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    users: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersToJSON(value.users),
    email_templates: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersToJSON(value.emailTemplates)
  }
}
