/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ShareByLinkSiteRequestSiteShareSiteShareFormsInnerFromJSON(json) {
    return ShareByLinkSiteRequestSiteShareSiteShareFormsInnerFromJSONTyped(json, false);
}
export function ShareByLinkSiteRequestSiteShareSiteShareFormsInnerFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contactSettingId: !exists(json, 'contact_setting_id') ? undefined : json['contact_setting_id'],
        isRequired: !exists(json, 'is_required') ? undefined : json['is_required']
    };
}
export function ShareByLinkSiteRequestSiteShareSiteShareFormsInnerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact_setting_id: value.contactSettingId,
        is_required: value.isRequired
    };
}
