/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationPaymentLimitFromJSON, OrganizationPaymentLimitToJSON } from './OrganizationPaymentLimit';
export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSON(json) {
    return GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSONTyped(json, false);
}
export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        currentUsage: json['current_usage'],
        currentLimit: OrganizationPaymentLimitFromJSON(json['current_limit']),
        incomingLimit: OrganizationPaymentLimitFromJSON(json['incoming_limit'])
    };
}
export function GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitUsersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        current_usage: value.currentUsage,
        current_limit: OrganizationPaymentLimitToJSON(value.currentLimit),
        incoming_limit: OrganizationPaymentLimitToJSON(value.incomingLimit)
    };
}
