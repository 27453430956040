import { Box, ButtonBase, CircularProgress } from '@material-ui/core'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { Button, Card, Checkbox, Typography } from 'src/components/atoms'
import { useGetOrganizationPaymentCurrentPlan, useGetPlanLimit } from 'src/fixtures/modules/organizationPayment/hooks'
import { useListMailTemplates } from 'src/fixtures/modules/selector/hooks'
import { useSelectedIdMap } from 'src/fixtures/utils'
import { BackButton } from '../BackButton'
import { center } from '../styles'
import { WarningMesasge } from './WarningMessage'

export interface StepSelectMailTemplateProps {
  onNext: (mailTemplateIds: string[]) => void
  onBack?: () => void
}
export const StepSelectMailTemplate = ({ onNext, onBack }: StepSelectMailTemplateProps) => {
  const { data } = useGetOrganizationPaymentCurrentPlan()
  const plan = data?.plan
  const { data: tempRes } = useListMailTemplates({ page: 1, per: 9999 })
  const router = useRouter()
  const planId = router.query.id as string
  const list = useMemo(() => tempRes?.selectorItems || [], [tempRes])
  const { emailTemplateCountLimit } = useGetPlanLimit(planId)

  const {
    selectMap: mailTemplateIdMap,
    selectIdList: mailTemplateIds,
    selectedCount,
    handleSelectId: handleSelectTemplateId
  } = useSelectedIdMap()

  const isMaxSelected = useMemo(() => {
    return selectedCount >= emailTemplateCountLimit.incoming
  }, [selectedCount, emailTemplateCountLimit])

  const isValid = useMemo(() => mailTemplateIds.length >= 0, [mailTemplateIds])

  return plan ? (
    <Box width={'100%'}>
      <Box mb="24px">{planId && <WarningMesasge nextPlanId={planId} />}</Box>
      <Box mb="24px">
        <Card label="メールテンプレートを選択" sx={{ py: '24px', bgcolor: 'white' }}>
          <Box sx={{ py: '8px' }}>
            {emailTemplateCountLimit.incoming > 1 ? (
              <Typography fontSize="s" lh="tight" style={{ whiteSpace: 'pre-wrap' }}>
                {`引き続きご利用になるメールテンプレートを${emailTemplateCountLimit.incoming}つ選択してください\n選択されなかったテンプレートは削除されます`}
              </Typography>
            ) : emailTemplateCountLimit.incoming === 0 ? (
              <Typography fontSize="s" lh="tight" style={{ whiteSpace: 'pre-wrap' }}>
                {`登録されたメールテンプレートが無いため、プラン移行に伴い利用を継続するメールテンプレートを選択する必要はありません。\nそのまま「確認へ進む」を押して、確認画面へ進んでください`}
              </Typography>
            ) : (
              <Box />
            )}

            <Box height="22px" />

            {list?.map((item, index) => {
              const isChecked = mailTemplateIdMap.get(item.id!)
              return (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #E9ECF1' }}>
                  <ButtonBase
                    disabled={isMaxSelected && !isChecked}
                    onClick={() => handleSelectTemplateId(item.id!)}
                    style={{ display: 'flex', alignItems: 'center', marginLeft: '-1px' }}
                  >
                    <Checkbox checked={isChecked || false} disabled={isMaxSelected && !isChecked} />
                    <Typography fontSize="s" lineheight="tight">
                      {item.text}
                    </Typography>
                  </ButtonBase>
                </Box>
              )
            })}
          </Box>
        </Card>
      </Box>

      <Box sx={{ ...center, mt: '24px' }}>
        <Button size="large" onClick={() => onNext(mailTemplateIds)} title={'確認へ進む'} disabled={!isValid} />
      </Box>
      <Box sx={{ ...center, mt: '24px', mb: '31px' }}>
        <BackButton onClick={() => onBack?.()} />
      </Box>

      <Box height="48px" />
    </Box>
  ) : (
    <Box sx={{ ...center }}>
      <CircularProgress />
    </Box>
  )
}
