/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function DocumentOpportunityFromJSON(json) {
    return DocumentOpportunityFromJSONTyped(json, false);
}
export function DocumentOpportunityFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        buttonName: !exists(json, 'button_name') ? undefined : json['button_name'],
        buttonColor: !exists(json, 'button_color') ? undefined : json['button_color'],
        isView: !exists(json, 'is_view') ? undefined : json['is_view'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function DocumentOpportunityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        title: value.title,
        description: value.description,
        button_name: value.buttonName,
        button_color: value.buttonColor,
        is_view: value.isView,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
