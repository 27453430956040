import React, { useCallback, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Colors, Input, SectionCard, Button, Pallete, SvgIcon, Typography } from 'src/components/atoms'
import { ColorPicker } from 'src/components/molecules'
import { useGetDocument, useUpdateDocumentOpportunity } from 'src/fixtures/modules/documents/hooks'

export interface DisplaySettingAppointmentProps {
  documentId: string
}

const useStyles = makeStyles(() =>
  createStyles({
    textColor: {
      color: '#3C3C3C',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '18.48px',
      fontWeight: 400,
      letter: '4%',
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap'
    }
  })
)

type ErrorMessage = {
  title?: string
  description?: string
  buttonName?: string
}

export const DisplaySettingAppointment = ({ documentId }: DisplaySettingAppointmentProps) => {
  const classes = useStyles()

  const { data: document } = useGetDocument(documentId)
  const { handleUpdateDocumentOpportunity } = useUpdateDocumentOpportunity(documentId)
  const appointment = document?.documentOpportunity
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>()

  const handleSubmit = useCallback(
    (name: string, e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
      switch (name) {
        case 'title':
          if (e.target.value === '') {
            setErrorMessage(state => ({ ...state, title: '見出しを入力してください' }))
            return
          }
          if (e.target.value.length > 50) {
            setErrorMessage(state => ({ ...state, title: '50文字以内で入力してください' }))
            return
          } else {
            setErrorMessage(state => ({ ...state, title: undefined }))
            handleUpdateDocumentOpportunity({ documentOpportunity: { title: e.target.value } })
          }
          break
        case 'description':
          {
            if (e.target.value === '') {
              setErrorMessage(state => ({ ...state, description: '説明文を入力してください' }))
              return
            }
            if (e.target.value.length > 100) {
              setErrorMessage(state => ({ ...state, description: '100文字以内で入力してください' }))
              return
            } else {
              setErrorMessage(state => ({ ...state, description: undefined }))
              handleUpdateDocumentOpportunity({ documentOpportunity: { description: e.target.value } })
            }
          }
          break
        case 'buttonName': {
          if (e.target.value === '') {
            setErrorMessage(state => ({ ...state, buttonName: 'ボタン名を入力してください' }))
            return
          }
          if (e.target.value.length > 12) {
            setErrorMessage(state => ({ ...state, buttonName: '12文字以内で入力してください' }))
            return
          } else {
            setErrorMessage(state => ({ ...state, buttonName: undefined }))
            handleUpdateDocumentOpportunity({ documentOpportunity: { buttonName: e.target.value } })
          }
        }
      }
    },
    [handleUpdateDocumentOpportunity, setErrorMessage]
  )

  // onChangeでエラーハンドリング

  const handleChange = useCallback(
    (name: string) => {
      switch (name) {
        case 'title':
          setErrorMessage(state => ({ ...state, title: undefined }))
          break
        case 'description':
          setErrorMessage(state => ({ ...state, description: undefined }))
          break
        case 'buttonName':
          setErrorMessage(state => ({ ...state, buttonName: undefined }))
      }
    },
    [setErrorMessage]
  )

  return (
    <>
      <Box px="8px">
        <Typography variant="h5" fontSize="s" lineheight="14px">
          表示設定
        </Typography>
      </Box>
      <Box mt="8px" />
      <SectionCard>
        <Box mt="2px" />
        <Box display="flex" alignItems="center">
          <Typography variant="h6" fontSize="xs" lineheight="12px">
            見出し
          </Typography>
          <Typography fontSize="xs" lineheight="12px">
            （50文字以内）
          </Typography>
        </Box>
        <Box mt="6px" />
        <Input
          name="headline"
          placeholder="オンライン商談のお申し込み"
          fullWidth
          backgroundColor={Pallete.functional.background.default}
          defaultValue={appointment?.title || ''}
          onChange={() => handleChange('title')}
          onBlur={e => handleSubmit('title', e)}
          error={errorMessage?.title}
        />
        <Box mt="14px" />
        <Box display="flex" alignItems="center">
          <Typography variant="h6" fontSize="xs" lineheight="12px">
            説明文
          </Typography>
          <Typography fontSize="xs" lineheight="12px">
            （100文字以内）
          </Typography>
        </Box>
        <Box mt="6px" />
        <Input
          name="explanation"
          placeholder="お申込み後、営業担当からご連絡させていただきます"
          multiline
          resize="vertical"
          rows={3}
          fullWidth
          backgroundColor={Pallete.functional.background.default}
          defaultValue={appointment?.description || ''}
          onChange={() => handleChange('description')}
          onBlur={e => handleSubmit('description', e)}
          error={errorMessage?.description}
        />
        <Box mt="14px" />
        <Box display="flex" alignItems="center">
          <Typography variant="h6" fontSize="xs" lineheight="12px">
            ボタン名
          </Typography>
          <Typography fontSize="xs" lineheight="12px">
            （12文字以内）
          </Typography>
        </Box>
        <Box mt="6px" />
        <Input
          name="buttonName"
          placeholder="商談を希望する"
          fullWidth
          backgroundColor={Pallete.functional.background.default}
          defaultValue={appointment?.buttonName || ''}
          onChange={() => handleChange('buttonName')}
          onBlur={e => handleSubmit('buttonName', e)}
          error={errorMessage?.buttonName}
        />
        <Box mt="14px" />
        <Typography variant="h6" fontSize="xs" lineheight="12px">
          ボタンのカラー
        </Typography>
        <Box mt="6px" />
        <ColorPicker
          onChangeColor={color => handleUpdateDocumentOpportunity({ documentOpportunity: { buttonColor: color } })}
          color={appointment?.buttonColor || Colors.accent.keyPurple.default}
        />
        <Box mt="28px" />
        <Typography variant="h6" fontSize="xs" lineheight="12px" color="textSecondary">
          表示プレビュー
        </Typography>
        <Box bgcolor={`${Colors.background.silver}`} px="10px" py="16px" mt="6px" borderRadius="4px">
          <Box
            border={`1px solid ${Colors.background.silver}`}
            borderRadius="4px"
            bgcolor={Pallete.functional.background.default}
            padding="8px 10px 10px 10px"
          >
            <Box display="flex">
              <SvgIcon variant="people" size="36px" />
              <Box mt="5px" ml="7px">
                <Typography variant="h5" fontSize="m" lineheight="21.6px" letterSpacing="default">
                  {appointment?.title || 'オンライン商談のお申し込み'}
                </Typography>
                <Box mt="4px" />
                <Typography
                  variant="subtitle1"
                  fontSize="s"
                  lineheight="18.48px"
                  letterSpacing="tight"
                  className={classes.textColor}
                >
                  {appointment?.description || 'お申込み後、営業担当からご連絡させていただきます'}
                </Typography>
                <Box mt="4px" display="flex" justifyContent="center">
                  <Button
                    title={appointment?.buttonName || '商談を希望する'}
                    buttonColor={appointment?.buttonColor || Colors.accent.keyPurple.default}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </SectionCard>
    </>
  )
}
