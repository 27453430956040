import React, { useCallback, useEffect, useState } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Toaster } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { useRouter } from 'next/router'
import { useCreateCompany } from 'src/fixtures/modules/company/hooks'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import {
  CompanySettingValueMap,
  convertFromCompanySettingValueMap,
  initializeCompanySettingValueMap
} from 'src/fixtures/modules/company/utils'
import { useListCompanySettings } from 'src/fixtures/modules/companySetting/hooks'
import { UserV1CompaniesGetRequest } from '@noco/http-client/lib/noco'
import { CompanyCreateForm, CompanyCreateFormChangeValueProps } from 'src/components/molecules/CompanyCreateForm'
import { useConfirmDiscard } from 'src/fixtures/utils/url'

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

interface ModalCompanyRegisterProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
  onClickSettings?: () => void
  params: UserV1CompaniesGetRequest
}

export const ModalCompanyRegister = ({ open, onClose, onCancel, onConfirm, params }: ModalCompanyRegisterProps) => {
  const classes = useStyles()
  const router = useRouter()
  const { data: responseSetting } = useListCompanySettings()
  const activeSettings = responseSetting?.activeCompanySettings
  const [settingValues, setSettingValues] = useState<CompanySettingValueMap>({})
  const [isSubitting, setIsSubmitting] = useState(false)
  const { handleCreateCompany, isLoading, error } = useCreateCompany({ params })

  const { data: me } = useGetMe()
  const [userId, setUserId] = useState<string | undefined>()
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm)

  const handleChangeUser = useCallback(e => {
    setUserId(e.target.value as string)
    setShowConfirm(true)
  }, [])

  const handleUpdateValue = useCallback(
    ({ settingId, fieldId, value }: CompanyCreateFormChangeValueProps) => {
      const newValueMap = { ...settingValues }
      newValueMap[settingId][fieldId] = value
      setSettingValues(newValueMap)
      setShowConfirm(true)
    },
    [settingValues]
  )

  const handleCreate = useCallback(async () => {
    if (!me) return Toaster.error('ユーザーがありません')
    if (!userId) return Toaster.error('担当者を選択してください')
    const companySettingFieldValues = convertFromCompanySettingValueMap(settingValues)
    setIsSubmitting(true)
    handleCreateCompany({
      company: {
        userId,
        companySettingFieldValues
      }
    })

    setShowConfirm(false)
  }, [handleCreateCompany, me, settingValues, userId])

  const handleInitialize = useCallback(() => {
    const initalSettingValueMap = initializeCompanySettingValueMap(activeSettings || [])
    setSettingValues(initalSettingValueMap)
  }, [activeSettings])

  // 画面をクローズする前に confirm する対応
  const handleClose = useCallback(() => {
    if (showConfirm && window.confirm('変更がまだ保存されていません。画面を離れますか？')) {
      onClose()
      setShowConfirm(false)
      return
    }

    if (!showConfirm) {
      onClose()
      setShowConfirm(false)
      return
    }
  }, [showConfirm, onClose])

  // 初期化
  useEffect(() => {
    handleInitialize()
  }, [handleInitialize])

  // MEMO: (@snuffy) 連絡先作成が完了した場合に此処が呼ばれる
  useEffect(() => {
    if (isSubitting && !isLoading && !error) {
      onConfirm()
      setIsSubmitting(false)
      handleInitialize()
    }
  }, [isSubitting, isLoading, error, onConfirm, handleInitialize])

  useEffect(() => {
    if (me?.user?.id) {
      setUserId(me?.user?.id)
    }
  }, [me?.user?.id])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="会社を登録"
            isSubmitting={isLoading}
            toolTipText="会社の項目を自由にカスタマイズできます。"
            onClose={handleClose}
            onCancel={() => {
              onCancel()
              setShowConfirm(false)
            }}
            onConfirm={handleCreate}
            onClickSettings={() => router.push('/setting/team/company')}
          >
            <CompanyCreateForm
              userId={userId!}
              settingValues={settingValues}
              onChangeUser={handleChangeUser}
              onChangeValues={handleUpdateValue}
            />
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
