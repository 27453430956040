/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumUserPermission = {
    Administrator: 'administrator',
    Manager: 'manager',
    Member: 'member',
    Assistant: 'assistant'
};
export function EnumUserPermissionFromJSON(json) {
    return EnumUserPermissionFromJSONTyped(json, false);
}
export function EnumUserPermissionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumUserPermissionToJSON(value) {
    return value;
}
