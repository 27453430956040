import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Calendar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.334 5H31.6673V1.66667H28.334V5H11.6673V1.66667H8.33398V5H6.66732C4.83398 5 3.33398 6.5 3.33398 8.33334V35C3.33398 36.8333 4.83398 38.3333 6.66732 38.3333H33.334C35.1673 38.3333 36.6673 36.8333 36.6673 35V8.33334C36.6673 6.5 35.1673 5 33.334 5ZM33.334 35H6.66732V16.6667H33.334V35ZM33.334 13.3333H6.66732V8.33334H33.334V13.3333Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84152C1.50202 1.98323 2.89359 1.98323 3.75188 2.84152L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.94968C-0.214574 5.09139 -0.214574 3.69982 0.643721 2.84152Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default Calendar
