/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Coupon, CouponFromJSON, CouponFromJSONTyped, CouponToJSON } from './Coupon'
import {
  OrganizationPaymentContract,
  OrganizationPaymentContractFromJSON,
  OrganizationPaymentContractFromJSONTyped,
  OrganizationPaymentContractToJSON
} from './OrganizationPaymentContract'
import {
  OrganizationPaymentUsage,
  OrganizationPaymentUsageFromJSON,
  OrganizationPaymentUsageFromJSONTyped,
  OrganizationPaymentUsageToJSON
} from './OrganizationPaymentUsage'
import {
  OrganizationPlanDetail,
  OrganizationPlanDetailFromJSON,
  OrganizationPlanDetailFromJSONTyped,
  OrganizationPlanDetailToJSON
} from './OrganizationPlanDetail'

/**
 *
 * @export
 * @interface GetOrganizationPaymentCurrentPlanResponseData
 */
export interface GetOrganizationPaymentCurrentPlanResponseData {
  /**
   *
   * @type {OrganizationPlanDetail}
   * @memberof GetOrganizationPaymentCurrentPlanResponseData
   */
  plan: OrganizationPlanDetail
  /**
   *
   * @type {OrganizationPaymentUsage}
   * @memberof GetOrganizationPaymentCurrentPlanResponseData
   */
  usage: OrganizationPaymentUsage
  /**
   *
   * @type {OrganizationPaymentContract}
   * @memberof GetOrganizationPaymentCurrentPlanResponseData
   */
  contract?: OrganizationPaymentContract
  /**
   *
   * @type {Coupon}
   * @memberof GetOrganizationPaymentCurrentPlanResponseData
   */
  coupon?: Coupon
}

export function GetOrganizationPaymentCurrentPlanResponseDataFromJSON(
  json: any
): GetOrganizationPaymentCurrentPlanResponseData {
  return GetOrganizationPaymentCurrentPlanResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationPaymentCurrentPlanResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentCurrentPlanResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    plan: OrganizationPlanDetailFromJSON(json['plan']),
    usage: OrganizationPaymentUsageFromJSON(json['usage']),
    contract: !exists(json, 'contract') ? undefined : OrganizationPaymentContractFromJSON(json['contract']),
    coupon: !exists(json, 'coupon') ? undefined : CouponFromJSON(json['coupon'])
  }
}

export function GetOrganizationPaymentCurrentPlanResponseDataToJSON(
  value?: GetOrganizationPaymentCurrentPlanResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan: OrganizationPlanDetailToJSON(value.plan),
    usage: OrganizationPaymentUsageToJSON(value.usage),
    contract: OrganizationPaymentContractToJSON(value.contract),
    coupon: CouponToJSON(value.coupon)
  }
}
