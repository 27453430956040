/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  RequestCompanySettingFieldValue,
  RequestCompanySettingFieldValueFromJSON,
  RequestCompanySettingFieldValueFromJSONTyped,
  RequestCompanySettingFieldValueToJSON
} from './RequestCompanySettingFieldValue'

/**
 *
 * @export
 * @interface CreateCompanyRequestCompany
 */
export interface CreateCompanyRequestCompany {
  /**
   *
   * @type {string}
   * @memberof CreateCompanyRequestCompany
   */
  userId?: string
  /**
   *
   * @type {Array<RequestCompanySettingFieldValue>}
   * @memberof CreateCompanyRequestCompany
   */
  companySettingFieldValues?: Array<RequestCompanySettingFieldValue>
}

export function CreateCompanyRequestCompanyFromJSON(json: any): CreateCompanyRequestCompany {
  return CreateCompanyRequestCompanyFromJSONTyped(json, false)
}

export function CreateCompanyRequestCompanyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateCompanyRequestCompany {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    companySettingFieldValues: !exists(json, 'company_setting_field_values')
      ? undefined
      : (json['company_setting_field_values'] as Array<any>).map(RequestCompanySettingFieldValueFromJSON)
  }
}

export function CreateCompanyRequestCompanyToJSON(value?: CreateCompanyRequestCompany | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_id: value.userId,
    company_setting_field_values:
      value.companySettingFieldValues === undefined
        ? undefined
        : (value.companySettingFieldValues as Array<any>).map(RequestCompanySettingFieldValueToJSON)
  }
}
