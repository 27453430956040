import { useCallback } from 'react'
import useSWR from 'swr'
import { useContactAuthentication } from '../contactAuth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'

// policySettings の取得
export const useGetOrganizationPolicySettingSubdomain = (subdomain?: string) => {
  const organizationApi = nocoSDK.client?.contactService.organizationApi
  const func = useCallback(async () => {
    if (!subdomain) {
      console.warn('no subdomain')
      return
    }
    return organizationApi
      ?.ApiFactory()
      .contactV1OrganizationPolicySettingsGet({ organizationSubdomain: subdomain })
      .then(res => res.data?.policySettings)
  }, [subdomain, organizationApi])
  return useSWR(subdomain && SWRCachePath.getOrganizationPolicySetting(subdomain), func)
}

export const useGetContactOrganizationChatSetting = (isPreview = false) => {
  const { data: auth } = useContactAuthentication()
  const organizationApi = nocoSDK.client?.contactService.organizationApi
  const func = useCallback(async () => {
    if (!auth?.token || isPreview) return
    return organizationApi
      ?.ApiFactory(auth.token)
      .contactV1OrganizationChatSettingGet()
      .then(res => res.data)
  }, [auth, organizationApi, isPreview])
  return useSWR(auth && !isPreview ? SWRCachePath.getOrganizationUserChatSettings() : null, func)
}

export const useGetContactOrganizationDisplaySetting = (subdomain?: string) => {
  const organizationApi = nocoSDK.client?.contactService.organizationApi
  const func = useCallback(async () => {
    if (!subdomain) return
    return organizationApi
      ?.ApiFactory()
      .contactV1OrganizationDisplaySettingGet({ organizationSubdomain: subdomain })
      .then(res => res.data)
  }, [subdomain, organizationApi])
  return useSWR(subdomain ? SWRCachePath.getContactOrganizationDisplaySetting(subdomain) : null, func)
}
