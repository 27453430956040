import { Box } from '@material-ui/core'
import { EnumInflowSource } from '@noco/http-client/lib/noco'
import React from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'

export interface InflowCellProps {
  value: EnumInflowSource
}

export const InflowCell = ({ value }: InflowCellProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box color={Colors.base.middleGray} mr="6px" display="flex" alignItems="center">
        <DynamicMuiIcon variant={value === 'email' ? 'email' : 'link'} size="20px" color="inherit" />
      </Box>
      <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
        {value === 'email' ? 'メール' : 'リンク'}
      </Typography>
    </Box>
  )
}
