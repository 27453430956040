import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Rocket = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0007 25C8.61732 25 7.36732 25.5667 6.46732 26.4667C4.50065 28.4333 3.33398 36.6667 3.33398 36.6667C3.33398 36.6667 11.5673 35.5 13.534 33.5333C14.434 32.6333 15.0007 31.3833 15.0007 30C15.0007 27.2333 12.7673 25 10.0007 25ZM11.184 31.1833C10.7173 31.65 7.56732 32.45 7.56732 32.45C7.56732 32.45 8.35065 29.3167 8.83399 28.8333C9.11732 28.5167 9.53399 28.3333 10.0007 28.3333C10.9173 28.3333 11.6673 29.0833 11.6673 30C11.6673 30.4667 11.484 30.8833 11.184 31.1833ZM29.034 22.75C39.634 12.15 36.1007 3.9 36.1007 3.9C36.1007 3.9 27.8507 0.366671 17.2507 10.9667L13.1007 10.1333C12.0173 9.91667 10.884 10.2667 10.084 11.05L3.33398 17.8167L11.6673 21.3833L18.6173 28.3333L22.184 36.6667L28.934 29.9167C29.7173 29.1333 30.0673 28 29.8507 26.9L29.034 22.75ZM12.3507 18.05L9.16732 16.6833L12.4507 13.4L14.8507 13.8833C13.9007 15.2667 13.0507 16.7167 12.3507 18.05ZM23.3173 30.8333L21.9507 27.65C23.284 26.95 24.734 26.1 26.1007 25.15L26.584 27.55L23.3173 30.8333ZM26.6673 20.4C24.4673 22.6 21.034 24.4 19.934 24.95L15.0507 20.0667C15.584 18.9833 17.384 15.55 19.6007 13.3333C27.4007 5.53334 33.3173 6.68334 33.3173 6.68334C33.3173 6.68334 34.4673 12.6 26.6673 20.4ZM25.0007 18.3333C26.834 18.3333 28.334 16.8333 28.334 15C28.334 13.1667 26.834 11.6667 25.0007 11.6667C23.1673 11.6667 21.6673 13.1667 21.6673 15C21.6673 16.8333 23.1673 18.3333 25.0007 18.3333Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84152C1.50202 1.98323 2.89359 1.98323 3.75188 2.84152L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.94968C-0.214574 5.09139 -0.214574 3.69982 0.643721 2.84152Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default Rocket
