/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OptionUserPlan,
  OptionUserPlanFromJSON,
  OptionUserPlanFromJSONTyped,
  OptionUserPlanToJSON
} from './OptionUserPlan'
import {
  OrganizationPlanBasic,
  OrganizationPlanBasicFromJSON,
  OrganizationPlanBasicFromJSONTyped,
  OrganizationPlanBasicToJSON
} from './OrganizationPlanBasic'
import {
  OrganizationPlanDetailAllOf,
  OrganizationPlanDetailAllOfFromJSON,
  OrganizationPlanDetailAllOfFromJSONTyped,
  OrganizationPlanDetailAllOfToJSON
} from './OrganizationPlanDetailAllOf'

/**
 *
 * @export
 * @interface OrganizationPlanDetail
 */
export interface OrganizationPlanDetail {
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanDetail
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanDetail
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  trial: boolean
  /**
   * 有料かどうか
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  paid: boolean
  /**
   * 連絡先のインポート
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canImportContact: boolean
  /**
   * 連絡先のエクスポート
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canExportContact: boolean
  /**
   * 会社のインポート
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canImportCompany: boolean
  /**
   * 会社のエクスポート
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canExportCompany: boolean
  /**
   * プランにバンドルされたメールテープレート登録が有限か
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  emailTemplateFinite: boolean
  /**
   * プランにバンドルされたメールテープレート登録上限
   * @type {number}
   * @memberof OrganizationPlanDetail
   */
  emailTemplateQuantity: number | null
  /**
   * トリガーメール
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canUseTriggerEmail: boolean
  /**
   * 資料ファイルのダウンロード・印刷設定
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canDownloadMaterial: boolean
  /**
   * 資料の公開期限
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canSetMaterialPublicationDue: boolean
  /**
   * 広告の非表示
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canHideAd: boolean
  /**
   * ユーザー権限管理
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canManageUserPermission: boolean
  /**
   * 60日以上前のチャット閲覧許可
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  canViewChatsOlderThan60Days: boolean
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanDetail
   */
  grade: OrganizationPlanDetailGradeEnum
  /**
   *
   * @type {number}
   * @memberof OrganizationPlanDetail
   */
  gradeAsNumber: number
  /**
   * プランにバンドルされたユーザー登録上限
   * @type {number}
   * @memberof OrganizationPlanDetail
   */
  userQuantity: number
  /**
   *
   * @type {string}
   * @memberof OrganizationPlanDetail
   */
  stripePriceId: string
  /**
   * 請求サイクル単価
   * @type {number}
   * @memberof OrganizationPlanDetail
   */
  unitAmount: number
  /**
   * 初期費用
   * @type {number}
   * @memberof OrganizationPlanDetail
   */
  initialCost: number
  /**
   *
   * @type {OptionUserPlan}
   * @memberof OrganizationPlanDetail
   */
  optionUserPlan?: OptionUserPlan
  /**
   * お試し期間中、または上位プランへの契約は必ず True、下位プランへの変更は検査によって False になる場合がある
   * @type {boolean}
   * @memberof OrganizationPlanDetail
   */
  isContractablePeriod?: boolean | null
}

/**
 * @export
 */
export const OrganizationPlanDetailGradeEnum = {
  Trial: 'trial',
  Free: 'free',
  Entry: 'entry',
  Standard: 'standard',
  Light: 'light',
  StandardNew: 'standard_new',
  Premium: 'premium',
  FreePremium: 'free_premium'
} as const
export type OrganizationPlanDetailGradeEnum =
  typeof OrganizationPlanDetailGradeEnum[keyof typeof OrganizationPlanDetailGradeEnum]

export function OrganizationPlanDetailFromJSON(json: any): OrganizationPlanDetail {
  return OrganizationPlanDetailFromJSONTyped(json, false)
}

export function OrganizationPlanDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPlanDetail {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    trial: json['trial'],
    paid: json['paid'],
    canImportContact: json['can_import_contact'],
    canExportContact: json['can_export_contact'],
    canImportCompany: json['can_import_company'],
    canExportCompany: json['can_export_company'],
    emailTemplateFinite: json['email_template_finite'],
    emailTemplateQuantity: json['email_template_quantity'],
    canUseTriggerEmail: json['can_use_trigger_email'],
    canDownloadMaterial: json['can_download_material'],
    canSetMaterialPublicationDue: json['can_set_material_publication_due'],
    canHideAd: json['can_hide_ad'],
    canManageUserPermission: json['can_manage_user_permission'],
    canViewChatsOlderThan60Days: json['can_view_chats_older_than_60_days'],
    grade: json['grade'],
    gradeAsNumber: json['grade_as_number'],
    userQuantity: json['user_quantity'],
    stripePriceId: json['stripe_price_id'],
    unitAmount: json['unit_amount'],
    initialCost: json['initial_cost'],
    optionUserPlan: !exists(json, 'option_user_plan') ? undefined : OptionUserPlanFromJSON(json['option_user_plan']),
    isContractablePeriod: !exists(json, 'is_contractable_period') ? undefined : json['is_contractable_period']
  }
}

export function OrganizationPlanDetailToJSON(value?: OrganizationPlanDetail | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    trial: value.trial,
    paid: value.paid,
    can_import_contact: value.canImportContact,
    can_export_contact: value.canExportContact,
    can_import_company: value.canImportCompany,
    can_export_company: value.canExportCompany,
    email_template_finite: value.emailTemplateFinite,
    email_template_quantity: value.emailTemplateQuantity,
    can_use_trigger_email: value.canUseTriggerEmail,
    can_download_material: value.canDownloadMaterial,
    can_set_material_publication_due: value.canSetMaterialPublicationDue,
    can_hide_ad: value.canHideAd,
    can_manage_user_permission: value.canManageUserPermission,
    can_view_chats_older_than_60_days: value.canViewChatsOlderThan60Days,
    grade: value.grade,
    grade_as_number: value.gradeAsNumber,
    user_quantity: value.userQuantity,
    stripe_price_id: value.stripePriceId,
    unit_amount: value.unitAmount,
    initial_cost: value.initialCost,
    option_user_plan: OptionUserPlanToJSON(value.optionUserPlan),
    is_contractable_period: value.isContractablePeriod
  }
}
