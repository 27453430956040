/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumOrganizationPolicySettingName,
  EnumOrganizationPolicySettingNameFromJSON,
  EnumOrganizationPolicySettingNameFromJSONTyped,
  EnumOrganizationPolicySettingNameToJSON
} from './EnumOrganizationPolicySettingName'

/**
 *
 * @export
 * @interface GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner
 */
export interface GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner {
  /**
   *
   * @type {EnumOrganizationPolicySettingName}
   * @memberof GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner
   */
  name: EnumOrganizationPolicySettingName
  /**
   * 画面表示に使用する文字列
   * @type {string}
   * @memberof GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner
   */
  label: string
  /**
   * linkに使用するURL
   * @type {string}
   * @memberof GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner
   */
  url: string
}

export function GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSON(
  json: any
): GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner {
  return GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSONTyped(json, false)
}

export function GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: EnumOrganizationPolicySettingNameFromJSON(json['name']),
    label: json['label'],
    url: json['url']
  }
}

export function GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerToJSON(
  value?: GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: EnumOrganizationPolicySettingNameToJSON(value.name),
    label: value.label,
    url: value.url
  }
}
