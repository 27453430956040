/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateSortContactSettingRequestContactSettingsInner
 */
export interface UpdateSortContactSettingRequestContactSettingsInner {
  /**
   *
   * @type {string}
   * @memberof UpdateSortContactSettingRequestContactSettingsInner
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof UpdateSortContactSettingRequestContactSettingsInner
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof UpdateSortContactSettingRequestContactSettingsInner
   */
  status?: UpdateSortContactSettingRequestContactSettingsInnerStatusEnum
}

/**
 * @export
 */
export const UpdateSortContactSettingRequestContactSettingsInnerStatusEnum = {
  Active: 'active',
  Required: 'required',
  Inactive: 'inactive'
} as const
export type UpdateSortContactSettingRequestContactSettingsInnerStatusEnum =
  typeof UpdateSortContactSettingRequestContactSettingsInnerStatusEnum[keyof typeof UpdateSortContactSettingRequestContactSettingsInnerStatusEnum]

export function UpdateSortContactSettingRequestContactSettingsInnerFromJSON(
  json: any
): UpdateSortContactSettingRequestContactSettingsInner {
  return UpdateSortContactSettingRequestContactSettingsInnerFromJSONTyped(json, false)
}

export function UpdateSortContactSettingRequestContactSettingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSortContactSettingRequestContactSettingsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    status: !exists(json, 'status') ? undefined : json['status']
  }
}

export function UpdateSortContactSettingRequestContactSettingsInnerToJSON(
  value?: UpdateSortContactSettingRequestContactSettingsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    sort: value.sort,
    status: value.status
  }
}
