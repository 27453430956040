import { useCallback, useState, useMemo, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { useRouter } from 'next/router'
import { LayoutTeam } from 'src/components/commons'
import { Header, Table, TableColumn, useTablePagination } from 'src/components/organisms'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { ModalConfirmToPaidPlan, PopupTemplate } from '../../modals'
import {
  useListMailTemplate,
  useCopyMailTemplate,
  useDeleteMailTemplate
} from '../../../fixtures/modules/mailTemplates/hooks'
import { UserV1MailTemplatesGetRequest } from '@noco/http-client/lib/noco'
import { DatetimeCell, UserNameCell } from 'src/components/organisms/Table/TableParts'
import { useGlobalStyles } from 'src/styles/theme'
import dynamic from 'next/dynamic'
const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

interface ControlCellProps {
  id: string
  onEdit: (mailTemplateId: string) => void
  onCopy: (mailTemplateId: string) => void
  onDelete: (mailTemplateId: string) => void
}
const ControlCell: React.VFC<ControlCellProps> = ({ id, onEdit, onCopy, onDelete }) => {
  const globalClasses = useGlobalStyles()
  return (
    <>
      <Box
        minWidth="100px"
        height="20px"
        display="flex"
        alignItems="center"
        justifyContent="end"
        color={Colors.base.middleGray}
      >
        <Box mx="8px" top="2px" position="relative" className={globalClasses.cursorPointer}>
          <DynamicMuiIcon variant="edit" size="20px" color="inherit" onClick={() => onEdit(id)} />
        </Box>
        <Box mx="8px" top="2px" position="relative" className={globalClasses.cursorPointer}>
          <DynamicMuiIcon variant="copy" size="20px" color="inherit" onClick={() => onCopy(id)} />
        </Box>
        <Box mx="8px" top="2px" position="relative" className={globalClasses.cursorPointer}>
          <DynamicMuiIcon variant="delete" size="20px" color="inherit" onClick={() => onDelete(id)} />
        </Box>
      </Box>
    </>
  )
}

import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'

export const PageTeamMailTemplate = () => {
  const router = useRouter()
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { page, per, totalPages, setTotalPages, changePage, changePerPage, resetPagination } =
    useTablePagination('mail-template')
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const [isSubmittingError, setIsSubmittingError] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>('')

  const [params] = useMemo(() => {
    const params: UserV1MailTemplatesGetRequest = {
      page: page + 1,
      per
    }
    return [params]
  }, [page, per])

  const { handleCopyMailTemplate } = useCopyMailTemplate(params)
  const { handleDeleteMailTemplate, isLoading: isLoadingDelete, error: errorDelete } = useDeleteMailTemplate(params)
  const { data: listResponse, mutate: mutateList } = useListMailTemplate(params)

  const currentMailTemplateCount = useMemo(
    () => listResponse?.mailTemplates?.length || 0,
    [listResponse?.mailTemplates?.length]
  )

  const handleCreate = useCallback(() => {
    const limit = planGrade?.plan?.emailTemplateQuantity || 0
    const isInfinite = planGrade?.plan?.emailTemplateFinite === false
    if (!isInfinite && currentMailTemplateCount >= limit) {
      setOpenModalUpgrade(true)
      return
    }
    router.push(`/setting/team/email_templates/new`)
  }, [router, currentMailTemplateCount, planGrade?.plan])

  const handleEdit = useCallback(
    mailTemplateId => {
      router.push(`/setting/team/email_templates/${mailTemplateId}/edit`)
    },
    [router]
  )

  const handleCopy = useCallback(
    mailTemplateId => {
      handleCopyMailTemplate({ sourceId: mailTemplateId })
    },
    [handleCopyMailTemplate]
  )

  const handleDelete = useCallback(
    mailTemplateId => {
      handleDeleteMailTemplate({ mailTemplateId })
      setIsSubmittingError(true)
    },
    [handleDeleteMailTemplate]
  )

  const columns: TableColumn[] = useMemo(() => {
    return [
      { name: 'name', width: 236, title: 'テンプレート名', render: ({ value }) => <strong>{value}</strong> },
      { name: 'createdBy', width: 214, title: '作成者', render: UserNameCell },
      { name: 'createdAt', width: 260, title: '作成日', render: DatetimeCell },
      {
        name: 'id',
        width: 100,
        title: ' ',
        render: ({ value }) => (
          <ControlCell id={value} onEdit={handleEdit} onCopy={handleCopy} onDelete={id => setDeleteId(id)} />
        )
      }
    ]
  }, [handleCopy, handleEdit])

  useEffect(() => {
    resetPagination()
  }, [resetPagination])

  useEffect(() => {
    mutateList()
  }, [mutateList])

  useEffect(() => {
    if (listResponse?.pageInfo?.totalPages && listResponse?.pageInfo?.totalPages !== totalPages) {
      setTotalPages(listResponse?.pageInfo?.totalPages)
    }
  }, [listResponse?.pageInfo?.totalPages, setTotalPages, totalPages])

  // 削除成功
  useEffect(() => {
    if (isSubmittingError && !isLoadingDelete && !errorDelete) {
      setIsSubmittingError(false)
      setDeleteId(undefined)
    }
  }, [isLoadingDelete, errorDelete, isSubmittingError])

  // 削除失敗
  useEffect(() => {
    if (isSubmittingError && !isLoadingDelete && errorDelete) {
      setIsSubmittingError(false)
    }
  }, [isLoadingDelete, errorDelete, isSubmittingError])

  return (
    <>
      <LayoutTeam>
        <Box
          width={1}
          bgcolor={Colors.functional.background.default}
          px="16px"
          color={Colors.base.black}
          display="flex"
          flexDirection="column"
        >
          <Header
            textSize="large"
            iconSize="32px"
            icon="email"
            title="メールテンプレート"
            back={false}
            rightSection={
              <Button
                startIcon={<DynamicMuiIcon variant="add" size="16px" />}
                title="テンプレートの新規作成"
                onClick={handleCreate}
              />
            }
          />
          <Box display="flex" width={1} height="100%" flexGrow={1} overflow="hidden">
            <Box position="relative" width={1} height={1} pt="16px">
              <DynamicTable
                columns={columns}
                rows={listResponse?.mailTemplates || []}
                page={page}
                per={per}
                totalPages={totalPages}
                changePage={changePage}
                changePerPage={changePerPage}
                numberOfRows={listResponse?.pageInfo?.totalCount}
                showTotalCount
              />
            </Box>
          </Box>
        </Box>
      </LayoutTeam>

      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、メールテンプレートを2つ以上作成することはできません。プレミアムプランなら、無制限のメールテンプレート作成に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、メールテンプレートを6つ以上作成することはできません。プレミアムプランなら、無制限のメールテンプレート作成に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />

      <PopupTemplate
        open={Boolean(deleteId)}
        title={`メールテンプレートの削除`}
        onClose={() => {
          setDeleteId(undefined)
        }}
        hasClose
        hasCancel
        hasConfirm
        onConfirm={() => {
          if (deleteId) handleDelete(deleteId)
        }}
        cancelButtonLabel="キャンセル"
        confirmButtonLabel="OK"
      >
        <Box p="24px">
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {`メールテンプレートを削除します、よろしいですか？`}
          </Typography>
        </Box>
      </PopupTemplate>
    </>
  )
}
