/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateOrganizationPaymentScheduleBillingInvoiceRequestToJSON, CreateOrganizationPaymentScheduleBillingInvoiceResponseFromJSON, DeleteOrganizationPaymentCancelResponseFromJSON, GetOrganizationPaymentAddonUserOptionResponseFromJSON, GetOrganizationPaymentBillingInfoResponseFromJSON, GetOrganizationPaymentBillingTransactionsResponseFromJSON, GetOrganizationPaymentCurrentPlanResponseFromJSON, GetOrganizationPaymentPlanCheckUsageLimitFromJSON, GetOrganizationPaymentPlanResponseFromJSON, GetOrganizationPaymentPlanTransactionsResponseFromJSON, GetOrganizationPaymentPlansResponseFromJSON, GetOrganizationPaymentPromotionCodeResponseFromJSON, UpdateOrganizationPaymentAddonUserOptionRequestToJSON, UpdateOrganizationPaymentAddonUserOptionResponseFromJSON, UpdateOrganizationPaymentApplyPlanFreeRequestToJSON, UpdateOrganizationPaymentApplyPlanPaidRequestToJSON, UpdateOrganizationPaymentCancelRequestToJSON, UpdateOrganizationPaymentCancelResponseFromJSON, UpdateOrganizationPaymentCurrentPlanRequestToJSON, UpdateOrganizationPaymentCurrentPlanResponseFromJSON, UpdateOrganizationPaymentDiscountRequestToJSON, UpdateOrganizationPaymentDiscountResponseFromJSON, UpdateOrganizationPaymentPlanFreeResponseFromJSON, UpdateOrganizationPaymentPlanPaidResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1OrganizationPaymentApi extends runtime.BaseAPI {
    /**
     * ユーザー追加オプション情報
     * show
     */
    async userV1OrganizationPaymentAddonUserOptionGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/addon/user_option`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentAddonUserOptionResponseFromJSON(jsonValue));
    }
    /**
     * ユーザー追加オプション情報
     * show
     */
    async userV1OrganizationPaymentAddonUserOptionGet(initOverrides) {
        const response = await this.userV1OrganizationPaymentAddonUserOptionGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * ユーザー追加オプションの更新
     * update
     */
    async userV1OrganizationPaymentAddonUserOptionPatchRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/payment/addon/user_option`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationPaymentAddonUserOptionRequestToJSON(requestParameters.updateOrganizationPaymentAddonUserOptionRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationPaymentAddonUserOptionResponseFromJSON(jsonValue));
    }
    /**
     * ユーザー追加オプションの更新
     * update
     */
    async userV1OrganizationPaymentAddonUserOptionPatch(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPaymentAddonUserOptionPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 請求先情報登録（無料）
     * update
     */
    async userV1OrganizationPaymentBillingInfoFreePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/payment/billing_info/free`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationPaymentApplyPlanFreeRequestToJSON(requestParameters.updateOrganizationPaymentApplyPlanFreeRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationPaymentPlanFreeResponseFromJSON(jsonValue));
    }
    /**
     * 請求先情報登録（無料）
     * update
     */
    async userV1OrganizationPaymentBillingInfoFreePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPaymentBillingInfoFreePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ご請求先情報
     * show
     */
    async userV1OrganizationPaymentBillingInfoGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/billing_info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentBillingInfoResponseFromJSON(jsonValue));
    }
    /**
     * ご請求先情報
     * show
     */
    async userV1OrganizationPaymentBillingInfoGet(initOverrides) {
        const response = await this.userV1OrganizationPaymentBillingInfoGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * 請求先情報登録（有料）
     * update
     */
    async userV1OrganizationPaymentBillingInfoPaidPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/payment/billing_info/paid`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationPaymentApplyPlanPaidRequestToJSON(requestParameters.updateOrganizationPaymentApplyPlanPaidRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationPaymentPlanPaidResponseFromJSON(jsonValue));
    }
    /**
     * 請求先情報登録（有料）
     * update
     */
    async userV1OrganizationPaymentBillingInfoPaidPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPaymentBillingInfoPaidPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * お支払い履歴
     * index
     */
    async userV1OrganizationPaymentBillingTransactionsGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/billing_transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentBillingTransactionsResponseFromJSON(jsonValue));
    }
    /**
     * お支払い履歴
     * index
     */
    async userV1OrganizationPaymentBillingTransactionsGet(initOverrides) {
        const response = await this.userV1OrganizationPaymentBillingTransactionsGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * 解約のキャンセル（有料プランをご契約中のチームのみ）
     * delete
     */
    async userV1OrganizationPaymentCancelDeleteRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/cancel`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => DeleteOrganizationPaymentCancelResponseFromJSON(jsonValue));
    }
    /**
     * 解約のキャンセル（有料プランをご契約中のチームのみ）
     * delete
     */
    async userV1OrganizationPaymentCancelDelete(initOverrides) {
        const response = await this.userV1OrganizationPaymentCancelDeleteRaw(initOverrides);
        return await response.value();
    }
    /**
     * 解約申請
     * update
     */
    async userV1OrganizationPaymentCancelPatchRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/payment/cancel`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationPaymentCancelRequestToJSON(requestParameters.updateOrganizationPaymentCancelRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationPaymentCancelResponseFromJSON(jsonValue));
    }
    /**
     * 解約申請
     * update
     */
    async userV1OrganizationPaymentCancelPatch(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPaymentCancelPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * クーポンの確認
     * show
     */
    async userV1OrganizationPaymentCouponsCouponCodeGetRaw(requestParameters, initOverrides) {
        if (requestParameters.couponCode === null || requestParameters.couponCode === undefined) {
            throw new runtime.RequiredError('couponCode', 'Required parameter requestParameters.couponCode was null or undefined when calling userV1OrganizationPaymentCouponsCouponCodeGet.');
        }
        const queryParameters = {};
        if (requestParameters.incomingPlanId !== undefined) {
            queryParameters['incoming_plan_id'] = requestParameters.incomingPlanId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/coupons/{coupon_code}`.replace(`{${'coupon_code'}}`, encodeURIComponent(String(requestParameters.couponCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentPromotionCodeResponseFromJSON(jsonValue));
    }
    /**
     * クーポンの確認
     * show
     */
    async userV1OrganizationPaymentCouponsCouponCodeGet(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationPaymentCouponsCouponCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 現在のプラン
     * show
     */
    async userV1OrganizationPaymentCurrentPlanGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/current_plan`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentCurrentPlanResponseFromJSON(jsonValue));
    }
    /**
     * 現在のプラン
     * show
     */
    async userV1OrganizationPaymentCurrentPlanGet(initOverrides) {
        const response = await this.userV1OrganizationPaymentCurrentPlanGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * プラン変更
     * update
     */
    async userV1OrganizationPaymentCurrentPlanPatchRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/payment/current_plan`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationPaymentCurrentPlanRequestToJSON(requestParameters.updateOrganizationPaymentCurrentPlanRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationPaymentCurrentPlanResponseFromJSON(jsonValue));
    }
    /**
     * プラン変更
     * update
     */
    async userV1OrganizationPaymentCurrentPlanPatch(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPaymentCurrentPlanPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 割引の適用
     * update
     */
    async userV1OrganizationPaymentDiscountPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/payment/discount`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationPaymentDiscountRequestToJSON(requestParameters.updateOrganizationPaymentDiscountRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationPaymentDiscountResponseFromJSON(jsonValue));
    }
    /**
     * 割引の適用
     * update
     */
    async userV1OrganizationPaymentDiscountPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPaymentDiscountPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * プラン変更履歴
     * index
     */
    async userV1OrganizationPaymentPlanTransactionsGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/plan_transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentPlanTransactionsResponseFromJSON(jsonValue));
    }
    /**
     * プラン変更履歴
     * index
     */
    async userV1OrganizationPaymentPlanTransactionsGet(initOverrides) {
        const response = await this.userV1OrganizationPaymentPlanTransactionsGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * 変更可能なプラン一覧
     * index
     */
    async userV1OrganizationPaymentPlansGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentPlansResponseFromJSON(jsonValue));
    }
    /**
     * 変更可能なプラン一覧
     * index
     */
    async userV1OrganizationPaymentPlansGet(initOverrides) {
        const response = await this.userV1OrganizationPaymentPlansGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * プラン制限超過情報
     * show
     */
    async userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGetRaw(requestParameters, initOverrides) {
        if (requestParameters.planId === null || requestParameters.planId === undefined) {
            throw new runtime.RequiredError('planId', 'Required parameter requestParameters.planId was null or undefined when calling userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/plans/{plan_id}/check_usage_limit`.replace(`{${'plan_id'}}`, encodeURIComponent(String(requestParameters.planId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentPlanCheckUsageLimitFromJSON(jsonValue));
    }
    /**
     * プラン制限超過情報
     * show
     */
    async userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGet(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 利用可能なプラン
     * show
     */
    async userV1OrganizationPaymentPlansPlanIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.planId === null || requestParameters.planId === undefined) {
            throw new runtime.RequiredError('planId', 'Required parameter requestParameters.planId was null or undefined when calling userV1OrganizationPaymentPlansPlanIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/payment/plans/{plan_id}`.replace(`{${'plan_id'}}`, encodeURIComponent(String(requestParameters.planId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentPlanResponseFromJSON(jsonValue));
    }
    /**
     * 利用可能なプラン
     * show
     */
    async userV1OrganizationPaymentPlansPlanIdGet(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationPaymentPlansPlanIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 契約内容の確認・請求予定金額の計算
     * create
     */
    async userV1OrganizationPaymentScheduleBillingInvoicePostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/payment/schedule_billing_invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrganizationPaymentScheduleBillingInvoiceRequestToJSON(requestParameters.createOrganizationPaymentScheduleBillingInvoiceRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateOrganizationPaymentScheduleBillingInvoiceResponseFromJSON(jsonValue));
    }
    /**
     * 契約内容の確認・請求予定金額の計算
     * create
     */
    async userV1OrganizationPaymentScheduleBillingInvoicePost(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPaymentScheduleBillingInvoicePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
