/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UserEmailNotificationSettingFromJSON(json) {
    return UserEmailNotificationSettingFromJSONTyped(json, false);
}
export function UserEmailNotificationSettingFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        onFirstView: !exists(json, 'on_first_view') ? undefined : json['on_first_view'],
        onView: !exists(json, 'on_view') ? undefined : json['on_view'],
        onChat: !exists(json, 'on_chat') ? undefined : json['on_chat'],
        onCta: !exists(json, 'on_cta') ? undefined : json['on_cta'],
        onViewSite: !exists(json, 'on_view_site') ? undefined : json['on_view_site'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function UserEmailNotificationSettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        on_first_view: value.onFirstView,
        on_view: value.onView,
        on_chat: value.onChat,
        on_cta: value.onCta,
        on_view_site: value.onViewSite,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
