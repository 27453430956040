/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CompanyFromJSON, CompanyToJSON } from './Company';
import { CompanySettingFromJSON, CompanySettingToJSON } from './CompanySetting';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetCompaniesResponseDataFromJSON(json) {
    return GetCompaniesResponseDataFromJSONTyped(json, false);
}
export function GetCompaniesResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companies: !exists(json, 'companies') ? undefined : json['companies'].map(CompanyFromJSON),
        companySettings: !exists(json, 'company_settings')
            ? undefined
            : json['company_settings'].map(CompanySettingFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetCompaniesResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        companies: value.companies === undefined ? undefined : value.companies.map(CompanyToJSON),
        company_settings: value.companySettings === undefined ? undefined : value.companySettings.map(CompanySettingToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
