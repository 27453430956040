/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationSignupCheckResponseData
 */
export interface OrganizationSignupCheckResponseData {
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupCheckResponseData
   */
  companyName?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupCheckResponseData
   */
  encryptedEmail?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupCheckResponseData
   */
  expiresAt?: string
}

export function OrganizationSignupCheckResponseDataFromJSON(json: any): OrganizationSignupCheckResponseData {
  return OrganizationSignupCheckResponseDataFromJSONTyped(json, false)
}

export function OrganizationSignupCheckResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationSignupCheckResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyName: !exists(json, 'company_name') ? undefined : json['company_name'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
    expiresAt: !exists(json, 'expires_at') ? undefined : json['expires_at']
  }
}

export function OrganizationSignupCheckResponseDataToJSON(value?: OrganizationSignupCheckResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_name: value.companyName,
    encrypted_email: value.encryptedEmail,
    expires_at: value.expiresAt
  }
}
