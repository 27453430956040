/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AppendContactsCompanyRequestToJSON, AppendContactsCompanyResponseFromJSON, ArchiveCompaniesRequestToJSON, ArchiveCompaniesResponseFromJSON, CompanyTimelinesResponseFromJSON, CreateCompanyMemoRequestToJSON, CreateCompanyMemoResponseFromJSON, CreateCompanyRequestToJSON, CreateCompanyResponseFromJSON, GetCompaniesResponseFromJSON, GetCompanyCompanyListsResponseFromJSON, GetCompanyResponseFromJSON, UpdateCompanyRequestToJSON, UpdateCompanyResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1CompanyApi extends runtime.BaseAPI {
    /**
     * 会社のアーカイブ設定
     * archive
     */
    async userV1CompaniesArchivePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/companies/archive`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveCompaniesRequestToJSON(requestParameters.archiveCompaniesRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ArchiveCompaniesResponseFromJSON(jsonValue));
    }
    /**
     * 会社のアーカイブ設定
     * archive
     */
    async userV1CompaniesArchivePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1CompaniesArchivePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 会社にコンタクトを紐づける
     * append_contacts
     */
    async userV1CompaniesCompanyIdAppendContactsPutRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdAppendContactsPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/companies/{company_id}/append_contacts`.replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppendContactsCompanyRequestToJSON(requestParameters.appendContactsCompanyRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => AppendContactsCompanyResponseFromJSON(jsonValue));
    }
    /**
     * 会社にコンタクトを紐づける
     * append_contacts
     */
    async userV1CompaniesCompanyIdAppendContactsPut(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdAppendContactsPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 会社の詳細情報を取得する
     * show
     */
    async userV1CompaniesCompanyIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/companies/{company_id}`.replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetCompanyResponseFromJSON(jsonValue));
    }
    /**
     * 会社の詳細情報を取得する
     * show
     */
    async userV1CompaniesCompanyIdGet(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 会社が所属している会社リスト一覧を取得
     * list
     */
    async userV1CompaniesCompanyIdListsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdListsGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/companies/{company_id}/lists`.replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetCompanyCompanyListsResponseFromJSON(jsonValue));
    }
    /**
     * 会社が所属している会社リスト一覧を取得
     * list
     */
    async userV1CompaniesCompanyIdListsGet(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdListsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1CompaniesCompanyIdMemosPostRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/companies/{company_id}/memos`.replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyMemoRequestToJSON(requestParameters.createCompanyMemoRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateCompanyMemoResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1CompaniesCompanyIdMemosPost(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdMemosPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 会社情報の編集をする
     * update
     */
    async userV1CompaniesCompanyIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/companies/{company_id}`.replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyRequestToJSON(requestParameters.updateCompanyRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateCompanyResponseFromJSON(jsonValue));
    }
    /**
     * 会社情報の編集をする
     * update
     */
    async userV1CompaniesCompanyIdPut(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1CompaniesCompanyIdTimelinesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId', 'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdTimelinesGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/companies/{company_id}/timelines`.replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CompanyTimelinesResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1CompaniesCompanyIdTimelinesGet(requestParameters, initOverrides) {
        const response = await this.userV1CompaniesCompanyIdTimelinesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 会社情報の一覧を取得する
     * index
     */
    async userV1CompaniesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.companyListId !== undefined) {
            queryParameters['company_list_id'] = requestParameters.companyListId;
        }
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }
        if (requestParameters.userIds) {
            queryParameters['user_ids[]'] = requestParameters.userIds;
        }
        if (requestParameters.createdOnFrom !== undefined) {
            queryParameters['created_on_from'] = requestParameters.createdOnFrom;
        }
        if (requestParameters.createdOnTo !== undefined) {
            queryParameters['created_on_to'] = requestParameters.createdOnTo;
        }
        if (requestParameters.isArchived !== undefined) {
            queryParameters['is_archived'] = requestParameters.isArchived;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetCompaniesResponseFromJSON(jsonValue));
    }
    /**
     * 会社情報の一覧を取得する
     * index
     */
    async userV1CompaniesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1CompaniesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 会社の作成をする
     * create
     */
    async userV1CompaniesPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/companies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyRequestToJSON(requestParameters.createCompanyRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateCompanyResponseFromJSON(jsonValue));
    }
    /**
     * 会社の作成をする
     * create
     */
    async userV1CompaniesPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1CompaniesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
