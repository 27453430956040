/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { CompanyList, CompanyListFromJSON, CompanyListFromJSONTyped, CompanyListToJSON } from './CompanyList'

/**
 *
 * @export
 * @interface CreateCompanyListResponseData
 */
export interface CreateCompanyListResponseData {
  /**
   *
   * @type {CompanyList}
   * @memberof CreateCompanyListResponseData
   */
  companyList?: CompanyList
}

export function CreateCompanyListResponseDataFromJSON(json: any): CreateCompanyListResponseData {
  return CreateCompanyListResponseDataFromJSONTyped(json, false)
}

export function CreateCompanyListResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateCompanyListResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyList: !exists(json, 'company_list') ? undefined : CompanyListFromJSON(json['company_list'])
  }
}

export function CreateCompanyListResponseDataToJSON(value?: CreateCompanyListResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_list: CompanyListToJSON(value.companyList)
  }
}
