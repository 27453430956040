import { Box, ButtonBase, createStyles, makeStyles, Typography } from '@material-ui/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Colors, DynamicMuiIcon, MuiIconVariant } from 'src/components/atoms'

export type MenuType = 'info' | 'setting' | 'history'

interface MenuProps {
  icon: MuiIconVariant
  label: string
  query: MenuType
  disabled: boolean
}

const menuList: MenuProps[] = [
  { icon: 'info', label: 'サイト情報', query: 'info', disabled: false },
  { icon: 'setting', label: '設定', query: 'setting', disabled: false }
]

const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      display: 'block',
      width: '62px',
      height: '62px',
      textAlign: 'center',
      borderBottom: `1px solid ${Colors.background.silver}`,
      '&.active': {
        background: Colors.selected.background,
        color: Colors.primary.default,
        borderRadius: '6px'
      }
    },
    link: {
      textDecoration: 'none'
    }
  })
})

export const Menu = () => {
  const classes = useStyles()
  const router = useRouter()
  const { mode, id } = router.query

  return (
    <Box
      bgcolor="#ffffff"
      width="78px"
      height={1}
      overflow={'auto'}
      borderLeft={`1px solid ${Colors.background.silver}`}
      p="8px"
    >
      {menuList.map(menu => {
        return (
          <Box key={menu.icon} style={{ pointerEvents: menu.disabled ? 'none' : 'auto' }}>
            <Link
              href={{
                pathname: `/multi-documents/${id}/setting`,
                query: { mode: menu.query }
              }}
              replace
            >
              <a className={classes.link}>
                <Box color={menu.disabled ? Colors.background.silver : Colors.base.middleGray}>
                  <ButtonBase className={`${classes.button} ${mode === menu.query && 'active'}`}>
                    <DynamicMuiIcon variant={menu.icon} size="18px" />
                    <Typography variant="h6" display="block">
                      {menu.label}
                    </Typography>
                  </ButtonBase>
                </Box>
              </a>
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}
