/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateSortContactSettingRequestContactSettingsInner,
  UpdateSortContactSettingRequestContactSettingsInnerFromJSON,
  UpdateSortContactSettingRequestContactSettingsInnerFromJSONTyped,
  UpdateSortContactSettingRequestContactSettingsInnerToJSON
} from './UpdateSortContactSettingRequestContactSettingsInner'

/**
 *
 * @export
 * @interface UpdateSortCompanySettingRequest
 */
export interface UpdateSortCompanySettingRequest {
  /**
   *
   * @type {Array<UpdateSortContactSettingRequestContactSettingsInner>}
   * @memberof UpdateSortCompanySettingRequest
   */
  companySettings?: Array<UpdateSortContactSettingRequestContactSettingsInner>
}

export function UpdateSortCompanySettingRequestFromJSON(json: any): UpdateSortCompanySettingRequest {
  return UpdateSortCompanySettingRequestFromJSONTyped(json, false)
}

export function UpdateSortCompanySettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSortCompanySettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companySettings: !exists(json, 'company_settings')
      ? undefined
      : (json['company_settings'] as Array<any>).map(UpdateSortContactSettingRequestContactSettingsInnerFromJSON)
  }
}

export function UpdateSortCompanySettingRequestToJSON(value?: UpdateSortCompanySettingRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_settings:
      value.companySettings === undefined
        ? undefined
        : (value.companySettings as Array<any>).map(UpdateSortContactSettingRequestContactSettingsInnerToJSON)
  }
}
