import { Box, ButtonBase, makeStyles, Popover, createStyles } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Colors, DynamicMuiIcon, Input, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { useListSelectorDocuments, useListSelectorSites } from 'src/fixtures/modules/selector/hooks'

export interface SelectSiteOrDocumentProps {
  onChange?: (option: SelectedtSiteOrDocument) => void
}

export interface SelectedtSiteOrDocument {
  label: string
  value: string
  thumbnailUrl?: string
  kind?: 'site' | 'document'
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      textAlign: 'left'
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    }
  })
})

const OPTION_HEIGHT = 34

export const SelectSiteOrDocument = ({ onChange }: SelectSiteOrDocumentProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  //- popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setIsOpen] = useState(false)
  const id = open ? 'simple-popover' : undefined

  const [inputValue, setInputValue] = useState('')
  const [selectedOption, setSelectedOption] = useState<SelectedtSiteOrDocument>({
    value: '',
    label: '',
    thumbnailUrl: undefined,
    kind: undefined
  })
  const [keyword, setKeyword] = useState('')

  //- options
  const { data: documentList } = useListSelectorDocuments({ page: 1, per: 9999, text: keyword })
  const { data: sitesList } = useListSelectorSites({ page: 1, per: 9999, text: keyword })
  const documentOptions: SelectedtSiteOrDocument[] = useMemo(
    () =>
      documentList?.selectorItems?.map(item => ({
        value: item.id!,
        label: item.text!,
        thumbnailUrl: item.extend?.currentSlideThumbnail?.url || undefined,
        kind: 'document'
      })) || [],
    [documentList]
  )
  const siteOptions: SelectedtSiteOrDocument[] = useMemo(
    () => sitesList?.selectorItems?.map(item => ({ value: item.id!, label: item.text!, kind: 'site' })) || [],
    [sitesList]
  )

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setIsOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setInputValue('')
    setIsOpen(false)
  }, [])
  const handleChange = useCallback((value: string) => {
    setInputValue(value)
    setKeyword(value)
  }, [])
  const handleSelect = useCallback(
    (option: SelectedtSiteOrDocument) => {
      setSelectedOption(option)
      onChange?.(option)
      handleClose()
    },
    [onChange, handleClose]
  )

  //- popover
  const position = useMemo(() => {
    if (!anchorEl) return
    const rect = anchorEl.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [anchorEl])

  return (
    <Box>
      <ButtonBase className={classes.buttonBase} onClick={handleClick}>
        <Box
          width={1}
          height="34px"
          border={`1px solid ${Colors.background.silver}`}
          borderRadius="4px"
          px="14px"
          display="flex"
          alignItems="center"
          boxShadow="inset 0px 1px 2px rgba(10, 10, 10, 0.1)"
        >
          <Typography variant="subtitle1" noWrap>
            {selectedOption?.label}
          </Typography>
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorReference="anchorPosition"
        anchorPosition={position}
        PaperProps={{
          className: classes.paper,
          style: { width: (anchorEl && anchorEl.offsetWidth - 2) || 0 }
        }}
      >
        <Box pt="12px" pb="16px">
          <Box px="12px">
            <Input
              name="keyword"
              value={inputValue}
              onChange={e => handleChange(e.currentTarget.value as string)}
              width="100%"
              startAdornment={
                <Box
                  pl="14px"
                  color={Colors.base.middleGray}
                  fontSize="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
                </Box>
              }
              endAdornment={
                <Box
                  p="10px"
                  color={Colors.base.middleGray}
                  fontSize="16px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setInputValue('')}
                  className={globalClasses.cursorPointer}
                >
                  <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
                </Box>
              }
            />
          </Box>
          <Box mt="10px" />
          <Box overflow="auto" maxHeight={OPTION_HEIGHT * 7}>
            <Box px="13px" color={Colors.base.placeHolder}>
              <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
                サイト
              </Typography>
            </Box>
            {siteOptions.map(option => {
              return (
                <ButtonBase
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  className={clsx(classes.buttonBase, classes.hoverButton)}
                >
                  <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                    <Typography variant="body2" noWrap>
                      {option.label || '(no name)'}
                    </Typography>
                  </Box>
                </ButtonBase>
              )
            })}
            {siteOptions.length === 0 && (
              <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                <Typography variant="body2" noWrap>
                  選択できる項目がありません
                </Typography>
              </Box>
            )}
            <Box px="13px" mt="20px" color={Colors.base.placeHolder}>
              <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
                資料
              </Typography>
            </Box>
            {documentOptions.map(option => {
              return (
                <ButtonBase
                  key={option.value}
                  onClick={() => handleSelect(option)}
                  className={clsx(classes.buttonBase, classes.hoverButton)}
                >
                  <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                    <Typography variant="body2" noWrap>
                      {option.label || '(no name)'}
                    </Typography>
                  </Box>
                </ButtonBase>
              )
            })}
            {documentOptions.length === 0 && (
              <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                <Typography variant="body2" noWrap>
                  選択できる項目がありません
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
