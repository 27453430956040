/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetSiteContactsResponseData,
  GetSiteContactsResponseDataFromJSON,
  GetSiteContactsResponseDataFromJSONTyped,
  GetSiteContactsResponseDataToJSON
} from './GetSiteContactsResponseData'

/**
 *
 * @export
 * @interface GetSiteContactsResponse
 */
export interface GetSiteContactsResponse {
  /**
   *
   * @type {number}
   * @memberof GetSiteContactsResponse
   */
  status?: number
  /**
   *
   * @type {GetSiteContactsResponseData}
   * @memberof GetSiteContactsResponse
   */
  data?: GetSiteContactsResponseData
}

export function GetSiteContactsResponseFromJSON(json: any): GetSiteContactsResponse {
  return GetSiteContactsResponseFromJSONTyped(json, false)
}

export function GetSiteContactsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSiteContactsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetSiteContactsResponseDataFromJSON(json['data'])
  }
}

export function GetSiteContactsResponseToJSON(value?: GetSiteContactsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetSiteContactsResponseDataToJSON(value.data)
  }
}
