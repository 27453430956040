/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { MaterialableFromJSON, MaterialableToJSON } from './Materialable';
export function MaterialContactableFromJSON(json) {
    return MaterialContactableFromJSONTyped(json, false);
}
export function MaterialContactableFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        materialContactType: !exists(json, 'material_contact_type') ? undefined : json['material_contact_type'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        materialable: !exists(json, 'materialable') ? undefined : MaterialableFromJSON(json['materialable'])
    };
}
export function MaterialContactableToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        material_contact_type: value.materialContactType,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        materialable: MaterialableToJSON(value.materialable)
    };
}
