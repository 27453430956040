import React, { useEffect, useState, useCallback } from 'react'
import { EnumRecordPorterHistoryProgressStatus } from '@noco/http-client/lib/noco'
import { CheckFile } from './CheckFile'
import { ExportList } from './ExportList'
import { ExportComplete } from './ExportComplete'
import { ExportError } from './ExportError'
import { RequestBodyExportCommon, RecordPorterHistory } from '@noco/http-client/lib/noco'

export interface ExportExecutionProps {
  mode: 'company' | 'contact'
  onSubmit: (params: RequestBodyExportCommon, hasToast: boolean) => void
  result: RecordPorterHistory | undefined
  error: Error | undefined
}

export const ExportExecution = ({ mode, onSubmit, result, error }: ExportExecutionProps) => {
  const [exporting, setExporting] = useState(false)
  const [selectedListId, setSelectedListId] = useState<string>('')
  const [isStartingExport, setIsStartingExport] = useState(false)

  const handleExport = useCallback(() => {
    if (!selectedListId) return
    const params = selectedListId === 'all' ? {} : { basicResourceListId: selectedListId }
    onSubmit(params, false)
    setExporting(false)
    setIsStartingExport(true)
  }, [onSubmit, selectedListId])

  useEffect(() => {
    if (result?.progressStatus === EnumRecordPorterHistoryProgressStatus.Progress) {
      setIsStartingExport(false)
    }
    if (error) {
      setIsStartingExport(false)
      setSelectedListId('')
    }
  }, [result?.progressStatus, error])

  if (!result || exporting || error) {
    return (
      <ExportList
        mode={mode}
        onSubmit={handleExport}
        onSetExporting={() => setExporting(false)}
        onChangeList={(value: string) => setSelectedListId(value)}
        selectedListId={selectedListId}
      />
    )
  } else if (result.progressStatus === EnumRecordPorterHistoryProgressStatus.Progress || isStartingExport) {
    return <CheckFile />
  } else if (result.progressStatus === EnumRecordPorterHistoryProgressStatus.Complete) {
    return (
      <ExportComplete
        mode={mode}
        outputFileName={result.outputFileName}
        fileUrl={result.file.url}
        onClick={() => setExporting(true)}
        onChangeList={(value: string) => setSelectedListId(value)}
      />
    )
  } else {
    return <ExportError onClick={() => setExporting(false)} />
  }
}
