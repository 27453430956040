/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CopySiteRequest
 */
export interface CopySiteRequest {
  /**
   *
   * @type {string}
   * @memberof CopySiteRequest
   */
  fromId: string
  /**
   *
   * @type {string}
   * @memberof CopySiteRequest
   */
  toId: string
  /**
   *
   * @type {boolean}
   * @memberof CopySiteRequest
   */
  copyInfo: boolean
  /**
   *
   * @type {boolean}
   * @memberof CopySiteRequest
   */
  copyCoverPhoto: boolean
  /**
   *
   * @type {boolean}
   * @memberof CopySiteRequest
   */
  copySetting: boolean
  /**
   *
   * @type {boolean}
   * @memberof CopySiteRequest
   */
  copyShareByLink: boolean
  /**
   *
   * @type {boolean}
   * @memberof CopySiteRequest
   */
  copyContent: boolean
}

export function CopySiteRequestFromJSON(json: any): CopySiteRequest {
  return CopySiteRequestFromJSONTyped(json, false)
}

export function CopySiteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopySiteRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    fromId: json['from_id'],
    toId: json['to_id'],
    copyInfo: json['copy_info'],
    copyCoverPhoto: json['copy_cover_photo'],
    copySetting: json['copy_setting'],
    copyShareByLink: json['copy_share_by_link'],
    copyContent: json['copy_content']
  }
}

export function CopySiteRequestToJSON(value?: CopySiteRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    from_id: value.fromId,
    to_id: value.toId,
    copy_info: value.copyInfo,
    copy_cover_photo: value.copyCoverPhoto,
    copy_setting: value.copySetting,
    copy_share_by_link: value.copyShareByLink,
    copy_content: value.copyContent
  }
}
