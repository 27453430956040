import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { CheckCircle, CheckCircleOutline, SignalCellularAlt } from '@material-ui/icons'
import { EnumActivityStatus, MaterialableViewHistory } from '@noco/http-client/lib/noco'
import dynamic from 'next/dynamic'
import React, { useMemo } from 'react'
import { Button, Colors } from 'src/components/atoms'
import { convertMs, DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import Table, { TableColumn, TableProps } from '../Table'
import { CompanyNameCell, NameCell } from '../Table/TableParts'
import { ContactCell } from '../Table/TableParts/ContactCell'
const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      margin: '8px 0',
      '& .MuiButton-startIcon': {
        marginRight: '6px',
        marginLeft: '0'
      }
    }
  })
)

export interface AnaliticsTableProps {
  histories: MaterialableViewHistory[]
  onClickGraphButton: (history: MaterialableViewHistory) => void
  page?: number
  per?: number
  totalPages?: number
  changePage?: (page: number) => void
  changePerPage?: (perPage: number) => void
  numberOfRows?: number
  onClickRow?: TableProps<MaterialableViewHistory>['onClickRow']
}
export const AnaliticsTable = ({
  histories,
  onClickGraphButton,
  page = 0,
  per = 999,
  totalPages = 1,
  changePage,
  changePerPage,
  numberOfRows,
  onClickRow
}: AnaliticsTableProps) => {
  const classes = useStyles()
  const analyticsColumns: TableColumn[] = useMemo(
    () => [
      {
        name: 'latestViewedAt',
        title: '最終閲覧日時',
        width: 199,
        getCellValue: (value: Required<MaterialableViewHistory>) =>
          value.viewingStatusObject.latestViewedAt
            ? dateFormat(dateStringToMilliseconds(value.viewingStatusObject.latestViewedAt), DateFormat.DATE_WITH_TIME)
            : '-'
      },
      {
        name: 'viewHistoryContact',
        title: '名前',
        width: 132,
        render: ({ row }: DataTypeProvider.ValueFormatterProps) => <ContactCell value={row?.contact} />
      },
      {
        name: 'viewHistoryContactCompany',
        width: 173,
        title: '会社名',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
          <CompanyNameCell value={row?.company?.displayName || '未設定'} />
        )
      },
      {
        name: 'viewHistoryCountOfView',
        width: 98,
        title: '閲覧回数',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
          <Box textAlign="right">{row.viewingStatusObject.countOfView ?? 0}回</Box>
        )
      },
      {
        name: 'viewHistoryPagesOfView',
        width: 98,
        title: '総閲覧時間',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
          <Box textAlign="right">{convertMs(row.viewingStatusObject.secondsOfView ?? 0)}</Box>
        )
      },
      {
        name: 'viewHistoryViewInfoPerPage',
        width: 116,
        title: 'ページ別閲覧情報',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) => (
          <Button
            startIcon={<SignalCellularAlt />}
            kind="secondary"
            variant="outlined"
            size="small"
            title="分析"
            classes={{ root: classes.button }}
            onClick={e => {
              e.stopPropagation()
              onClickGraphButton(row as MaterialableViewHistory)
            }}
          />
        )
      },
      {
        name: 'viewHistoryViewProfile',
        width: 97,
        title: '担当者情報',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) =>
          (row as Required<MaterialableViewHistory>)?.activityStatusObjects?.some(
            item => item.activityStatus === EnumActivityStatus.ViewProfile && item.countOfAction
          ) ? (
            <CheckCircle htmlColor={Colors.base.placeHolder} fontSize="small" />
          ) : (
            <CheckCircleOutline htmlColor={Colors.background.silver} fontSize="small" />
          )
      },
      {
        name: 'viewHistoryOpportunity',
        width: 106,
        title: 'アポイント設定',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) =>
          (row as Required<MaterialableViewHistory>)?.activityStatusObjects?.some(
            item => item.activityStatus === EnumActivityStatus.Opportunity && item.countOfAction
          ) ? (
            <CheckCircle htmlColor={Colors.base.placeHolder} fontSize="small" />
          ) : (
            <CheckCircleOutline htmlColor={Colors.background.silver} fontSize="small" />
          )
      },
      {
        name: 'viewHistoryCta',
        width: 97,
        title: 'CTA',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) => {
          return (row as Required<MaterialableViewHistory>)?.activityStatusObjects?.some(
            item => item.activityStatus === EnumActivityStatus.Cta && item.countOfAction
          ) ? (
            <CheckCircle htmlColor={Colors.base.placeHolder} fontSize="small" />
          ) : (
            <CheckCircleOutline htmlColor={Colors.background.silver} fontSize="small" />
          )
        }
      },
      {
        name: 'viewHistoryChat',
        width: 97,
        title: 'チャット',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) =>
          (row as Required<MaterialableViewHistory>)?.activityStatusObjects?.some(
            item => item.activityStatus === EnumActivityStatus.Chat && item.countOfAction
          ) ? (
            <CheckCircle htmlColor={Colors.base.placeHolder} fontSize="small" />
          ) : (
            <CheckCircleOutline htmlColor={Colors.background.silver} fontSize="small" />
          )
      },
      {
        name: 'viewHistoryDownload',
        width: 97,
        title: 'ダウンロード',
        render: ({ row }: DataTypeProvider.ValueFormatterProps) =>
          (row as Required<MaterialableViewHistory>)?.activityStatusObjects?.some(
            item => item.activityStatus === EnumActivityStatus.Download && item.countOfAction
          ) ? (
            <CheckCircle htmlColor={Colors.base.placeHolder} fontSize="small" />
          ) : (
            <CheckCircleOutline htmlColor={Colors.background.silver} fontSize="small" />
          )
      },
      {
        name: 'viewHistoryUser',
        title: '担当者',
        width: 137,
        render: ({ row }: DataTypeProvider.ValueFormatterProps) => <NameCell value={row.user} iconSize="large" />
      }
    ],
    [onClickGraphButton, classes.button]
  )
  return (
    <DynamicTable
      columns={analyticsColumns}
      rows={histories || []}
      page={page}
      per={per}
      totalPages={totalPages}
      changePage={changePage}
      changePerPage={changePerPage}
      numberOfRows={numberOfRows}
      onClickRow={onClickRow}
    />
  )
}
