/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumMaterialableType = {
  Document: 'document',
  Site: 'site'
} as const
export type EnumMaterialableType = typeof EnumMaterialableType[keyof typeof EnumMaterialableType]

export function EnumMaterialableTypeFromJSON(json: any): EnumMaterialableType {
  return EnumMaterialableTypeFromJSONTyped(json, false)
}

export function EnumMaterialableTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumMaterialableType {
  return json as EnumMaterialableType
}

export function EnumMaterialableTypeToJSON(value?: EnumMaterialableType | null): any {
  return value as any
}
