import { DocumentSlideImageLink } from '@noco/http-client/lib/noco'

const canDisplayLinkRect = (item: DocumentSlideImageLink) => {
  return item.rectRatioTop && item.rectRatioRight && item.rectRatioBottom && item.rectRatioLeft
}

export const getLinkRectData = (item: DocumentSlideImageLink) => {
  if (!canDisplayLinkRect) return undefined
  const width = item.rectRatioRight! - item.rectRatioLeft!
  const height = item.rectRatioBottom! - item.rectRatioTop!
  return { width: width * 100, height: height * 100, top: item.rectRatioTop! * 100, left: item.rectRatioLeft! * 100 }
}
