import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import Link from 'next/link'
import React from 'react'
import { Colors, DynamicMuiIcon, MuiIconVariant, Typography } from 'src/components/atoms'
import { FolderData } from '..'

const useStyles = makeStyles(() => {
  return createStyles({
    link: {
      textDecoration: 'none'
    },
    button: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      color: Colors.base.middleGray,
      padding: '5px 18px 5px 14px',
      '&:hover': {
        background: Colors.selected.background,
        color: Colors.accent.keyPurple.default
      }
    },
    activeButton: {
      background: Colors.selected.background,
      color: Colors.accent.keyPurple.default,
      '& .MuiTypography-root': {
        fontWeight: 'bold'
      }
    }
  })
})

export interface FolderLinkProps extends FolderData {
  isActive?: boolean
  icon?: MuiIconVariant
  label: string
}

export const FolderLink = ({ path, label, count, isActive = false, icon = 'folder' }: FolderLinkProps) => {
  const classes = useStyles()
  return (
    <Link href={path}>
      <a className={classes.link}>
        <ButtonBase className={`${classes.button} ${isActive && classes.activeButton}`}>
          <Box display="flex" alignItems="center">
            <DynamicMuiIcon variant={icon} size="20px" />
            <Box ml="8px" />
            <Typography fontSize="s" lh="tight">
              {label}
            </Typography>
          </Box>
          <Typography fontSize="xs" fontWeight="bold" lh="tight">
            {count}
          </Typography>
        </ButtonBase>
      </a>
    </Link>
  )
}
