/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import { GetDefaultImagesResponse, GetDefaultImagesResponseFromJSON, GetDefaultImagesResponseToJSON } from '../models'

export interface UserV1DefaultImagesGetRequest {
  division?: string
}

/**
 *
 */
export class ApiUserV1DefaultImageApi extends runtime.BaseAPI {
  /**
   * デフォルトイメージ一覧取得
   * index
   */
  async userV1DefaultImagesGetRaw(
    requestParameters: UserV1DefaultImagesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetDefaultImagesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.division !== undefined) {
      queryParameters['division'] = requestParameters.division
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/default_images`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetDefaultImagesResponseFromJSON(jsonValue))
  }

  /**
   * デフォルトイメージ一覧取得
   * index
   */
  async userV1DefaultImagesGet(
    requestParameters: UserV1DefaultImagesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetDefaultImagesResponse> {
    const response = await this.userV1DefaultImagesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
