/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationPaymentPlanTransactionFromJSON, OrganizationPaymentPlanTransactionToJSON } from './OrganizationPaymentPlanTransaction';
export function GetOrganizationPaymentPlanTransactionsResponseDataFromJSON(json) {
    return GetOrganizationPaymentPlanTransactionsResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationPaymentPlanTransactionsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        usageStartAt: new Date(json['usage_start_at']),
        planTransactions: json['plan_transactions'].map(OrganizationPaymentPlanTransactionFromJSON)
    };
}
export function GetOrganizationPaymentPlanTransactionsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        usage_start_at: value.usageStartAt.toISOString(),
        plan_transactions: value.planTransactions.map(OrganizationPaymentPlanTransactionToJSON)
    };
}
