import { Box, Typography } from '@material-ui/core'

import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Colors, Input } from 'src/components/atoms'
import { useForgotPassword } from 'src/fixtures/modules/auth/hooks'
import * as yup from 'yup'
import { useGetUserLocalAuthEmail } from 'src/fixtures/modules/auth/hooks'

export interface FormProps {
  onSubmitSuccess: (email: string) => void
  subdomain?: string
}

interface FormValue {
  email: string
  subdomain: string
}

export const formValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('メールアドレスの形式で入力してください')
    .label('メールアドレス')
    .required('メールアドレスは必須です'),
  subdomain: yup.string().label('サブドメイン').required('サブドメインは必須です')
})

export const Form = ({ onSubmitSuccess, subdomain }: FormProps) => {
  const { handleForgotPassword, isLoading, error } = useForgotPassword()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: email, mutate: mutateEmail } = useGetUserLocalAuthEmail()

  const formik = useFormik<FormValue>({
    enableReinitialize: true,
    isInitialValid: false,
    initialValues: {
      email: '',
      subdomain: subdomain || ''
    },
    validationSchema: formValidationSchema,
    onSubmit: values => {
      formik.resetForm()
      handleForgotPassword(values.email, values.subdomain)
      setIsSubmitting(true)
      mutateEmail(undefined)
    }
  })

  useEffect(() => {
    if (subdomain) {
      formik.setFieldValue('subdomain', subdomain)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldValue, subdomain])

  useEffect(() => {
    if (email) {
      formik.setFieldValue('email', email)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.setFieldValue, email])

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      onSubmitSuccess(formik.values.email)
      setIsSubmitting(false)
      formik.resetForm()
    }
  }, [isLoading, error, isSubmitting, formik, onSubmitSuccess])

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box color={Colors.base.black}>
        <Typography variant="h1">パスワードの再設定</Typography>
        <Box mt="20px" />
        <Typography variant="body2">登録しているメールアドレスを入力ください</Typography>
        <Box mt="24px" />
        <Typography variant="h5">チームID</Typography>
        <Box mt="6px" />
        {subdomain ? (
          <Typography variant="subtitle1">{subdomain}</Typography>
        ) : (
          <Input
            {...formik.getFieldProps('subdomain')}
            fullWidth
            required
            error={formik.touched.subdomain ? formik.errors.subdomain : undefined}
            large
          />
        )}
        <Box height="24px" />
        <Typography variant="h5">メールアドレス</Typography>
        <Box mt="6px" />
        <Input
          name="email"
          type="text"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={e => {
            formik.handleBlur(e)
            formik.setFieldValue('email', formik.values.email.trim())
          }}
          required
          fullWidth
          large
          error={formik.touched.email ? formik.errors.email : undefined}
        />
        <Box mt="24px" />
        <Button title="送信" loading={isLoading} size="large" width="100%" type="submit" disabled={!formik.isValid} />
      </Box>
    </form>
  )
}
