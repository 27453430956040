import React, { useMemo } from 'react'
import { Box, ButtonBase } from '@material-ui/core'
import { isBefore } from 'date-fns'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'

export interface ExpiryBaseProps {
  canViewable: boolean
  expiredOn: string | null
}
export interface ExpiryCellProps extends ExpiryBaseProps {
  onClick: (e: React.MouseEvent) => void
}

export const ExpiryCell = ({ canViewable, expiredOn, onClick }: ExpiryCellProps) => {
  const text = useMemo(() => {
    if (!canViewable) return '閲覧不可'
    return expiredOn ? dateFormat(dateStringToMilliseconds(expiredOn), DateFormat.DATE_WITH_DAY) : '閲覧可（期限なし）'
  }, [canViewable, expiredOn])

  const color = useMemo(() => {
    // NOTE: 閲覧可（期限なし）
    if (!canViewable) return Colors.accent.keyRed.default
    // NOTE: 閲覧不可
    if (!expiredOn) return Colors.base.middleGray
    // NOTE: 閲覧期限切れ
    if (isBefore(new Date(expiredOn), new Date())) return Colors.accent.keyRed.default

    return Colors.base.black
  }, [canViewable, expiredOn])

  return (
    <Box display="flex" alignItems="center">
      <Box color={color}>
        <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
          {text}
        </Typography>
      </Box>
      <Box ml="6px" color={Colors.base.placeHolder}>
        <ButtonBase onClick={onClick}>
          <DynamicMuiIcon variant="edit" size="16px" />
        </ButtonBase>
      </Box>
    </Box>
  )
}
