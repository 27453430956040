/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumOrganizationUser = {
  Assistant: 'assistant',
  Member: 'member',
  Manager: 'manager',
  Administrator: 'administrator'
} as const
export type EnumOrganizationUser = typeof EnumOrganizationUser[keyof typeof EnumOrganizationUser]

export function EnumOrganizationUserFromJSON(json: any): EnumOrganizationUser {
  return EnumOrganizationUserFromJSONTyped(json, false)
}

export function EnumOrganizationUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumOrganizationUser {
  return json as EnumOrganizationUser
}

export function EnumOrganizationUserToJSON(value?: EnumOrganizationUser | null): any {
  return value as any
}
