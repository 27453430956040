/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function CopyMailTemplateRequestFromJSON(json) {
    return CopyMailTemplateRequestFromJSONTyped(json, false);
}
export function CopyMailTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        sourceId: !exists(json, 'source_id') ? undefined : json['source_id']
    };
}
export function CopyMailTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        source_id: value.sourceId
    };
}
