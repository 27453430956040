/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'
import {
  RecordPorterHistory,
  RecordPorterHistoryFromJSON,
  RecordPorterHistoryFromJSONTyped,
  RecordPorterHistoryToJSON
} from './RecordPorterHistory'

/**
 *
 * @export
 * @interface GetRecordPorterHistoriesResponseData
 */
export interface GetRecordPorterHistoriesResponseData {
  /**
   *
   * @type {Array<RecordPorterHistory>}
   * @memberof GetRecordPorterHistoriesResponseData
   */
  recordPorterHistories?: Array<RecordPorterHistory>
  /**
   *
   * @type {PageInfo}
   * @memberof GetRecordPorterHistoriesResponseData
   */
  pageInfo?: PageInfo
}

export function GetRecordPorterHistoriesResponseDataFromJSON(json: any): GetRecordPorterHistoriesResponseData {
  return GetRecordPorterHistoriesResponseDataFromJSONTyped(json, false)
}

export function GetRecordPorterHistoriesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetRecordPorterHistoriesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    recordPorterHistories: !exists(json, 'record_porter_histories')
      ? undefined
      : (json['record_porter_histories'] as Array<any>).map(RecordPorterHistoryFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetRecordPorterHistoriesResponseDataToJSON(value?: GetRecordPorterHistoriesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    record_porter_histories:
      value.recordPorterHistories === undefined
        ? undefined
        : (value.recordPorterHistories as Array<any>).map(RecordPorterHistoryToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
