/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ShareByLinkSiteRequestSiteShareFromJSON, ShareByLinkSiteRequestSiteShareToJSON } from './ShareByLinkSiteRequestSiteShare';
export function ShareByLinkSiteRequestFromJSON(json) {
    return ShareByLinkSiteRequestFromJSONTyped(json, false);
}
export function ShareByLinkSiteRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        siteShare: !exists(json, 'site_share') ? undefined : ShareByLinkSiteRequestSiteShareFromJSON(json['site_share'])
    };
}
export function ShareByLinkSiteRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        site_share: ShareByLinkSiteRequestSiteShareToJSON(value.siteShare)
    };
}
