import {
  MailTemplate,
  CreateMailTemplateRequest,
  CopyMailTemplateRequest,
  UpdateMailTemplateRequest,
  UserV1MailTemplatesGetRequest,
  UserV1MailTemplatesMailTemplateIdDeleteRequest
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'

export const useListMailTemplate = (requestParameters: UserV1MailTemplatesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi
  const func = useCallback(async () => {
    if (!auth) return
    return await mailTemplateApi
      ?.ApiFactory(auth.token)
      .userV1MailTemplatesGet(requestParameters, { cache: 'no-store' })
      .then(res => res.data)
  }, [auth, mailTemplateApi, requestParameters])

  return useSWR(auth && SWRCachePath.listMailTemplate(requestParameters), func)
}

export const useGetMailTemplate = (mailTemplateId?: string) => {
  const { data: auth } = useAuthenticate()
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi
  const func = useCallback(async () => {
    if (!auth || !mailTemplateId) return undefined
    return await mailTemplateApi
      ?.ApiFactory(auth.token)
      .userV1MailTemplatesMailTemplateIdGet({ mailTemplateId: mailTemplateId })
      .then(item => item.data?.mailTemplate)
  }, [auth, mailTemplateApi, mailTemplateId])
  return useSWR(auth && SWRCachePath.getMailTemplate(mailTemplateId), func, {
    onError: err => handleError(err, { tag: 'Get mail template' })
  })
}

// mail template の bulkGet。
export const useBulkGetMailTemplates = (mailTemplateIds: string[]) => {
  const { data: auth } = useAuthenticate()
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi
  const func = useCallback(async () => {
    if (!auth || !mailTemplateIds) return undefined
    const promises = mailTemplateIds.map(id =>
      mailTemplateApi
        ?.ApiFactory(auth.token)
        .userV1MailTemplatesMailTemplateIdGet({ mailTemplateId: id })
        .then(item => item.data?.mailTemplate)
    )
    // TODO:(正式版以降対応) bulkGet 中にエラーが発生した時にどうするかを考える
    return (await Promise.allSettled(promises))
      .filter((item): item is PromiseFulfilledResult<MailTemplate> => item !== undefined && item.status === 'fulfilled')
      .map(item => item.value)
  }, [auth, mailTemplateApi, mailTemplateIds])
  return useSWR(auth && SWRCachePath.bulkGetMailTemplates(mailTemplateIds), func, {
    onError: err => handleError(err, { tag: 'Bulk get mail templates' })
  })
}

export const useCreateMailTemplate = (params: UserV1MailTemplatesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListMailTemplate } = useListMailTemplate(params)
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi

  const handleCreateMailTemplate = useCallback(
    async (createMailTemplateRequest: CreateMailTemplateRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        const res = await mailTemplateApi?.ApiFactory(auth.token).userV1MailTemplatesPost({ createMailTemplateRequest })
        if (!res?.data?.mailTemplate) throw new Error('mail template not exist')
        mutateListMailTemplate()
        Toaster.success('メールテンプレートを作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create mail template' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mailTemplateApi, me, mutateListMailTemplate]
  )

  return { error, isLoading, handleCreateMailTemplate }
}

export const useUpdateMailTemplate = (mailTemplateId: string, params: UserV1MailTemplatesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListMailTemplate } = useListMailTemplate(params)
  const { mutate: mutateGetMailTemplate } = useGetMailTemplate(mailTemplateId)
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi

  const handleUpdateMailTemplate = useCallback(
    async (updateMailTemplateRequest: UpdateMailTemplateRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        const res = await mailTemplateApi?.ApiFactory(auth.token).userV1MailTemplatesMailTemplateIdPut({
          mailTemplateId,
          updateMailTemplateRequest
        })
        if (!res?.data?.mailTemplate) throw new Error('mail template not exist')

        mutateListMailTemplate()
        mutateGetMailTemplate(res.data.mailTemplate)
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mailTemplateApi, mailTemplateId, me, mutateGetMailTemplate, mutateListMailTemplate]
  )

  return { error, isLoading, handleUpdateMailTemplate }
}

export const useCopyMailTemplate = (params: UserV1MailTemplatesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListMailTemplate } = useListMailTemplate(params)
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi

  const handleCopyMailTemplate = useCallback(
    async (copyMailTemplateRequest: CopyMailTemplateRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        const res = await mailTemplateApi
          ?.ApiFactory(auth.token)
          .userV1MailTemplatesCopyPost({ copyMailTemplateRequest })
        if (!res?.data?.mailTemplate) throw new Error('mail template not exist')
        mutateListMailTemplate()
        Toaster.success('メールテンプレートを複製しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Copy mail template' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mailTemplateApi, me, mutateListMailTemplate]
  )

  return { error, isLoading, handleCopyMailTemplate }
}

export const useDeleteMailTemplate = (params: UserV1MailTemplatesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListMailTemplate } = useListMailTemplate(params)
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi

  const handleDeleteMailTemplate = useCallback(
    async (deleteMailTemplateRequest: UserV1MailTemplatesMailTemplateIdDeleteRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        const res = await mailTemplateApi
          ?.ApiFactory(auth.token)
          .userV1MailTemplatesMailTemplateIdDeleteRaw(deleteMailTemplateRequest)
        if (!res?.raw.ok) throw new Error('error mail template delete')
        mutateListMailTemplate()
        Toaster.success('メールテンプレートを削除しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Delete mail template' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mailTemplateApi, me, mutateListMailTemplate]
  )

  return { error, isLoading, handleDeleteMailTemplate }
}

export const useGetMailTemplateVariables = () => {
  const { data: auth } = useAuthenticate()
  const mailTemplateApi = nocoSDK.client?.userService.mailTemplateApi
  const func = useCallback(async () => {
    if (!auth) return

    return await mailTemplateApi
      ?.ApiFactory(auth.token)
      .userV1MailTemplatesVariablesGet()
      .then(res => res.data?.mailTemplateVariableObjects || [])
  }, [auth, mailTemplateApi])

  return useSWR(auth && SWRCachePath.getMailTemplateVariables(), func)
}
