import React from 'react'
import {
  createStyles,
  makeStyles,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps
} from '@material-ui/core'

export interface TypographyProps extends MuiTypographyProps {
  fontSize?: 'xxxl' | 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs'
  letterSpacing?: 'none' | 'default' | 'tight' | 'normal'
  fontWeight?: 'normal' | 'bold' | 600 | 300
  lineheight?: string
  lh?: 'default' | 'tight' | 'relaxed' | 'none'
}

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      fontSize: ({ fontSize }: Partial<TypographyProps>) => {
        switch (fontSize) {
          case 'xxxl':
            return '56px'
          case 'xxl':
            return '24px'
          case 'xl':
            return '21px'
          case 'l':
            return '18px'
          case 'm':
            return '16px'
          case 's':
            return '14px !important'
          case 'xs':
            return '12px !important'
          default:
            return ''
        }
      },
      letterSpacing: ({ letterSpacing = 'none' }: Partial<TypographyProps>) => {
        switch (letterSpacing) {
          case 'default':
            return '0.02em'
          case 'tight':
            return '0.04em'
          case 'normal':
            return 'normal'
          default:
            return 'unset'
        }
      },
      fontWeight: ({ fontWeight }: Partial<TypographyProps>) => fontWeight,
      lineHeight: ({ lineheight, lh }: Partial<TypographyProps>) => {
        if (lineheight !== undefined) return lineheight
        switch (lh) {
          case 'default':
            return '1.5'
          case 'tight':
            return '1.35'
          case 'relaxed':
            return '1.7'
          case 'none':
            return '1'
          default:
            return ''
        }
      }
    }
  })
)

export const Typography = ({ children, className, ...otherProps }: TypographyProps) => {
  const classes = useStyles(otherProps)
  return (
    <MuiTypography {...otherProps} className={className || classes.typography}>
      {children}
    </MuiTypography>
  )
}
