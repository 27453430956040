import React, { useCallback, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { PricingTableColumn } from './PricingTableColumn'
import { Button, Colors, Typography } from 'src/components/atoms'
import { OrganizationPlanDetail as Plan, OrganizationStatusEnum as Status } from '@noco/http-client/lib/noco/models'
import { Coupon } from '@noco/http-client/lib/noco'

export interface PricingTableProps {
  currentPlan: Plan
  targetPlans: Plan[]
  isAdmin: boolean
  onSelectPlan: (plan: Plan) => void
  status?: Status
  isCouponApplied?: Coupon
}

const STRIPE_PRICE_LIGHT_ID = process.env.STRIPE_PRICE_LIGHT_ID ?? ''
const STRIPE_PRICE_STANDARD_NEW_ID = process.env.STRIPE_PRICE_STANDARD_NEW_ID ?? ''
const STRIPE_PRICE_PREMIUM_ID = process.env.STRIPE_PRICE_PREMIUM_ID ?? ''

const findTargetPlan = (plans: Plan[], stripePriceId: string): Plan | undefined => {
  return plans.find(plan => plan.stripePriceId === stripePriceId)
}

export const PricingTable = ({
  currentPlan,
  targetPlans,
  isAdmin = false,
  onSelectPlan,
  status,
  isCouponApplied
}: PricingTableProps) => {
  // HACK: reduce などでもう少し計算量減らしたい
  const lightPlan = useMemo(() => findTargetPlan(targetPlans, STRIPE_PRICE_LIGHT_ID), [targetPlans])
  const standardNewPlan = useMemo(() => findTargetPlan(targetPlans, STRIPE_PRICE_STANDARD_NEW_ID), [targetPlans])
  const premiumPlan = useMemo(() => findTargetPlan(targetPlans, STRIPE_PRICE_PREMIUM_ID), [targetPlans])

  const isPaused = status === Status.Paused

  const handleClickPlan = useCallback(
    (plan: Plan) => {
      if (currentPlan === plan && (!isPaused || !isCouponApplied)) return
      onSelectPlan(plan)
    },
    [currentPlan, onSelectPlan, isPaused, isCouponApplied]
  )

  return (
    <Box display="flex">
      <Box display="flex" flex="1">
        {lightPlan && (
          <PricingTableColumn
            variant="light"
            targetPlan={lightPlan}
            currentPlan={currentPlan}
            disabled={!isAdmin}
            onClick={() => handleClickPlan(lightPlan)}
            status={status}
            isCouponApplied={isCouponApplied}
          />
        )}
      </Box>
      <Box display="flex" flex="1">
        {standardNewPlan && (
          <PricingTableColumn
            variant="standard_new"
            targetPlan={standardNewPlan}
            currentPlan={currentPlan}
            disabled={!isAdmin}
            onClick={() => handleClickPlan(standardNewPlan)}
            status={status}
            isCouponApplied={isCouponApplied}
          />
        )}
      </Box>
      <Box display="flex" flex="1">
        {premiumPlan && (
          <PricingTableColumn
            variant="premium"
            targetPlan={premiumPlan}
            currentPlan={currentPlan}
            disabled={!isAdmin}
            onClick={() => handleClickPlan(premiumPlan)}
            status={status}
            isCouponApplied={isCouponApplied}
          />
        )}
      </Box>
      <Box
        sx={{
          flex: '1',
          display: 'flex',
          marginTop: '40px',
          flexDirection: 'column',
          alignItems: 'center',
          borderTop: `16px solid ${Colors.accent.keyPurple.default}`,
          borderRight: '1px solid #DADCE0',
          borderBottom: '1px solid #DADCE0',
          borderLeft: '1px solid #DADCE0'
        }}
      >
        <Box width={1} p="24px 9px" textAlign="center">
          <Box mb="20px">
            <Typography fontSize="l" fontWeight="bold">
              アドバンス
            </Typography>
          </Box>
          <Box mb="90px">
            <Typography fontSize="s" fontWeight="bold">
              お問い合わせください
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button title="coming soon" size="medium" kind="neutral" variant="outlined" />
          </Box>
        </Box>
        <Box p="23px 0">
          <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
            お問い合わせください
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
