/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateDocumentChatSettingRequestDocumentChatSetting,
  UpdateDocumentChatSettingRequestDocumentChatSettingFromJSON,
  UpdateDocumentChatSettingRequestDocumentChatSettingFromJSONTyped,
  UpdateDocumentChatSettingRequestDocumentChatSettingToJSON
} from './UpdateDocumentChatSettingRequestDocumentChatSetting'

/**
 *
 * @export
 * @interface UpdateDocumentChatSettingRequest
 */
export interface UpdateDocumentChatSettingRequest {
  /**
   *
   * @type {UpdateDocumentChatSettingRequestDocumentChatSetting}
   * @memberof UpdateDocumentChatSettingRequest
   */
  documentChatSetting?: UpdateDocumentChatSettingRequestDocumentChatSetting
}

export function UpdateDocumentChatSettingRequestFromJSON(json: any): UpdateDocumentChatSettingRequest {
  return UpdateDocumentChatSettingRequestFromJSONTyped(json, false)
}

export function UpdateDocumentChatSettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentChatSettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentChatSetting: !exists(json, 'document_chat_setting')
      ? undefined
      : UpdateDocumentChatSettingRequestDocumentChatSettingFromJSON(json['document_chat_setting'])
  }
}

export function UpdateDocumentChatSettingRequestToJSON(value?: UpdateDocumentChatSettingRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_chat_setting: UpdateDocumentChatSettingRequestDocumentChatSettingToJSON(value.documentChatSetting)
  }
}
