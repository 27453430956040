import { Box, Typography } from '@material-ui/core'

export const SignUpWithExpired = () => {
  return (
    <>
      <Typography variant="h1">招待リンクの有効期限が切れています</Typography>
      <Box mt="24px" />
      <Typography variant="subtitle1">
        招待されたメールの有効期限が切れているため、チームに参加することができませんでした
        <br />
        チームの管理者にご連絡いただき、再度招待メールを受け取ってください
      </Typography>
    </>
  )
}
