/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumRecordPorterHistoryStep = {
  FileCheck: 'file_check',
  Import: 'import',
  Export: 'export',
  BulkUpdate: 'bulk_update'
} as const
export type EnumRecordPorterHistoryStep = typeof EnumRecordPorterHistoryStep[keyof typeof EnumRecordPorterHistoryStep]

export function EnumRecordPorterHistoryStepFromJSON(json: any): EnumRecordPorterHistoryStep {
  return EnumRecordPorterHistoryStepFromJSONTyped(json, false)
}

export function EnumRecordPorterHistoryStepFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumRecordPorterHistoryStep {
  return json as EnumRecordPorterHistoryStep
}

export function EnumRecordPorterHistoryStepToJSON(value?: EnumRecordPorterHistoryStep | null): any {
  return value as any
}
