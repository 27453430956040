/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumSettingStatus = {
    Active: 'active',
    Inactive: 'inactive',
    Required: 'required'
};
export function EnumSettingStatusFromJSON(json) {
    return EnumSettingStatusFromJSONTyped(json, false);
}
export function EnumSettingStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumSettingStatusToJSON(value) {
    return value;
}
