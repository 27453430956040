import React, { useCallback, useEffect, useState } from 'react'
import { OptionListType } from '..'
import { CheckBoxRow } from './CheckBoxRow'

export interface CheckBoxListProps {
  value?: string[]
  optionList: OptionListType[]
  onChange: (selectedValueList: string[]) => void
}

export const CheckBoxList = ({ value, optionList, onChange }: CheckBoxListProps) => {
  const [selectedValueList, setSelectedValueList] = useState<string[]>(value || [])

  useEffect(() => {
    onChange(selectedValueList)
    // 無限ループに入るため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueList])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newList = [...selectedValueList]

      const newValue = event.target.value
      const index = newList.findIndex(item => item === newValue)

      if (index === -1) {
        newList.push(newValue)
      } else {
        newList.splice(index, 1)
      }

      setSelectedValueList(newList)
    },
    [selectedValueList]
  )

  return (
    <>
      {optionList?.map(option => {
        return (
          <CheckBoxRow
            key={option.value}
            option={option}
            onChange={event => handleChange(event)}
            isChecked={selectedValueList.some(value => value === option.value)}
          />
        )
      })}
    </>
  )
}
