/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  DocumentOpportunityUser,
  DocumentOpportunityUserFromJSON,
  DocumentOpportunityUserFromJSONTyped,
  DocumentOpportunityUserToJSON
} from './DocumentOpportunityUser'

/**
 *
 * @export
 * @interface GetDocumentOpportunityUsersResponseData
 */
export interface GetDocumentOpportunityUsersResponseData {
  /**
   *
   * @type {Array<DocumentOpportunityUser>}
   * @memberof GetDocumentOpportunityUsersResponseData
   */
  documentOpportunityUsers?: Array<DocumentOpportunityUser>
}

export function GetDocumentOpportunityUsersResponseDataFromJSON(json: any): GetDocumentOpportunityUsersResponseData {
  return GetDocumentOpportunityUsersResponseDataFromJSONTyped(json, false)
}

export function GetDocumentOpportunityUsersResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentOpportunityUsersResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentOpportunityUsers: !exists(json, 'document_opportunity_users')
      ? undefined
      : (json['document_opportunity_users'] as Array<any>).map(DocumentOpportunityUserFromJSON)
  }
}

export function GetDocumentOpportunityUsersResponseDataToJSON(
  value?: GetDocumentOpportunityUsersResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_opportunity_users:
      value.documentOpportunityUsers === undefined
        ? undefined
        : (value.documentOpportunityUsers as Array<any>).map(DocumentOpportunityUserToJSON)
  }
}
