import { Box } from '@material-ui/core'
import { useRouter } from 'next/router'
import { ButtonIcon, Colors, Pallete, SvgIcon, Typography } from 'src/components/atoms'

export const BulkUpdateHeader = () => {
  const router = useRouter()

  return (
    <Box
      height="60px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px="16px"
      bgcolor={Pallete.functional.background.default}
      borderBottom={`1px solid ${Colors.background.silver}`}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start" color={Colors.base.middleGray}>
        <ButtonIcon
          iconBefore={<SvgIcon variant="leftArrow" size="14px" color={Colors.base.middleGray} />}
          onClick={() => router.back()}
        >
          <Typography fontSize="s" fontWeight="bold">
            戻る
          </Typography>
        </ButtonIcon>
      </Box>

      <Typography fontSize="l" letterSpacing="default" fontWeight="bold">
        連絡先・会社のインポート・エクスポート
      </Typography>
    </Box>
  )
}
