/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationPaymentContractFromJSON, OrganizationPaymentContractToJSON } from './OrganizationPaymentContract';
import { OrganizationPlanDetailFromJSON, OrganizationPlanDetailToJSON } from './OrganizationPlanDetail';
export function UpdateOrganizationPaymentCancelResponseDataFromJSON(json) {
    return UpdateOrganizationPaymentCancelResponseDataFromJSONTyped(json, false);
}
export function UpdateOrganizationPaymentCancelResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        plan: OrganizationPlanDetailFromJSON(json['plan']),
        contract: !exists(json, 'contract') ? undefined : OrganizationPaymentContractFromJSON(json['contract']),
        cancelNow: json['cancel_now']
    };
}
export function UpdateOrganizationPaymentCancelResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        plan: OrganizationPlanDetailToJSON(value.plan),
        contract: OrganizationPaymentContractToJSON(value.contract),
        cancel_now: value.cancelNow
    };
}
