import { Avatar, Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import Image from 'next/image'
import React, { useRef, useMemo } from 'react'
import clsx from 'clsx'
import { Colors, Typography, DynamicMuiIcon, Pallete } from 'src/components/atoms'
import { LinkIcon } from 'src/components/atoms/LinkIcon'
import { ErrorOutline } from '@material-ui/icons'
import {
  ChatMessage,
  EnumChatMessageSendedByType,
  EnumEmailTransactionableSendStatus,
  EnumMaterialableType,
  OrganizationChatSetting,
  Visitor
} from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { formatDistanceToNow } from 'date-fns'
import { ja } from 'date-fns/locale'
import { LinkParser } from 'src/components/atoms/LinkParser'
import { AttachmentCard, AttachmentCardProps } from '../AttachmentCard'
import { AttachmentFile, AttachmentType } from '../AttachmentCard/type'

export type ChatMessageItemProps = {
  item: ChatMessage
  formDocumentName?: string
  width?: string | number
  isPreview?: boolean
  isEuView?: boolean
  visitor?: Visitor
  organizationChatSetting?: OrganizationChatSetting
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: { width: 32, height: 32 },
    message: {
      borderRadius: 10,
      background: Colors.functional.background.default
    },
    date: {
      color: Colors.base.middleGray,
      textAlign: 'end'
    },
    euViewmessage: {},
    userMessage: {
      background: '#F5F9FF'
    },
    ownMessage: {
      borderRadius: 10,
      color: Pallete.functional.background.default,
      padding: '8px 10px',
      width: 'fit-content',
      marginLeft: 'auto'
    },
    document: {
      background: theme.palette.background.default,
      border: `1px solid ${Colors.background.gray}`,
      borderRadius: 6
    },
    formDocumentName: {
      color: Colors.accent.keyBlue.default
    },
    icon: {
      color: Colors.accent.keyBlue.default
    },
    customLink: {
      color: Colors.accent.keyBlue.default,
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center'
    },
    lineClamp: {
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      fontSize: '14px',
      lineHeight: '18.48px',
      letterSpacing: '0.04em'
    },
    readAtText: {
      color: Colors.base.middleGray,
      fontSize: '12px',
      lineHeight: '15.84px',
      letterSpacing: '0.02em'
    },
    openInNew: {
      verticalAlign: 'middle',
      fontSize: '16px',
      marginLeft: '4px',
      position: 'relative',
      bottom: '1px'
    }
  })
)

const materialableOpenLink = (
  materialableId: string,
  isUserView: boolean = true,
  isDocument: boolean = true
): string => {
  if (isUserView) {
    if (isDocument) {
      return `/documents/${materialableId}`
    } else {
      return `/multi-documents/${materialableId}`
    }
  } else {
    if (isDocument) {
      return `/share/d/m/${materialableId}`
    } else {
      return `/share/s/m/${materialableId}`
    }
  }
}

export const ChatMessageItem = ({
  item,
  formDocumentName,
  width = '346px',
  isEuView = false,
  isPreview = false,
  visitor,
  organizationChatSetting
}: ChatMessageItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const textRef = ref.current?.firstChild as HTMLDivElement
  const classes = useStyles()

  const euReadInfo = useMemo(() => {
    if (!item.readAt) {
      return []
    } else {
      return [
        formatDistanceToNow(new Date(item.readAt), { addSuffix: true, locale: ja }),
        dateFormat(dateStringToMilliseconds(item.readAt), DateFormat.DATE_WITH_TIME)
      ]
    }
  }, [item.readAt])

  const openLink = useMemo(() => {
    return materialableOpenLink(
      item.materialable?.materialableId!,
      !isEuView,
      item.materialable?.materialableType === 'document'
    )
  }, [isEuView, item.materialable])

  const isExistsChatMessageAttachment = useMemo<boolean>(() => {
    return (item.chatMessageAttachments || []).length > 0
  }, [item.chatMessageAttachments])

  const renderFileAttachment = useMemo(() => {
    const chatMessageAttachments: AttachmentCardProps[] = (item.chatMessageAttachments || []).map(attachment => {
      if (attachment.type === 'material_contactable') {
        const materialable = attachment?.materialContactable?.materialable
        return {
          type: materialable?.materialableType as AttachmentType,
          attachmentFile: {
            title: materialable?.title,
            thumbnailImageUrl: materialable?.thumbnail?.url,
            url: materialableOpenLink(
              materialable?.materialableId!,
              !isEuView,
              materialable?.materialableType === 'document'
            )
          } as AttachmentFile
        }
      } else {
        return {
          type: attachment.type as AttachmentType,
          attachmentFile: {
            title: attachment.chatMessageAttachmentFile?.file.filename,
            url: attachment.chatMessageAttachmentFile?.file.url,
            thumbnailImageUrl: attachment.chatMessageAttachmentFile?.file.thumbnailImageUrl,
            humanizedFileSize: attachment.chatMessageAttachmentFile?.file.humanizedFileSize
          } as AttachmentFile
        }
      }
    })

    return (
      <>
        {isExistsChatMessageAttachment && (
          <Box
            mt="10px"
            mb="2px"
            sx={{
              display: 'grid',
              gridGap: '8px',
              gridTemplateColumns: `repeat(auto-fill, ${isEuView ? '100%' : '322px'})`
            }}
          >
            {chatMessageAttachments?.map((attachment, i) => (
              <AttachmentCard key={i} {...attachment} />
            ))}
          </Box>
        )}
      </>
    )
  }, [isEuView, isExistsChatMessageAttachment, item.chatMessageAttachments])

  // TODO: preview時の暫定的な対応のため、後日リファクタ
  if (visitor?.contactId === item.sendedBy?.id) {
    return (
      <Box key={item.id} pb={4}>
        <Box ml={1} mb="6px">
          <Typography className={classes.date} variant="subtitle2">
            {item.createdAt ? dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME) : ''}
          </Typography>
        </Box>
        <Box
          key={item.id}
          p={1}
          className={classes.ownMessage}
          bgcolor={organizationChatSetting?.chatColor ?? Colors.accent.keyPurple.default}
        >
          <Typography variant="h5" style={{ whiteSpace: 'pre-wrap' }}>
            <LinkParser text={item.message ?? ''} />
          </Typography>
        </Box>
      </Box>
    )
  }

  return isEuView || isPreview ? (
    // EuView
    <Box display="flex" alignItems="start">
      <Box mr={2}>
        <Avatar alt={item.sendedBy?.displayName} src={item.sendedBy?.avatar?.url} className={classes.avatar} />
      </Box>

      <Box sx={isExistsChatMessageAttachment ? { width: '100%', maxWidth: '680px', overflow: 'hidden' } : {}}>
        <Box
          className={clsx(classes.message, {
            [classes.euViewmessage]: item.sendedByType === EnumChatMessageSendedByType.User
          })}
        >
          <Box display="flex" alignItems="center" mb="6px">
            <Typography variant="h6">{item.sendedBy?.displayName}</Typography>
            <Box ml={2}>
              <Typography className={classes.date} variant="subtitle2">
                {item.createdAt ? dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME) : ''}
              </Typography>
            </Box>
          </Box>

          <Box py={2} px="10px" border={`1px solid ${Colors.background.silver}`} borderRadius="10px">
            <Typography variant="subtitle1" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              <LinkParser text={item.message ?? ''} />
            </Typography>
            {item.createCausedBy === 'publish_to_contact' && item.materialable && (
              <Box
                p={2}
                mt="8px"
                mb="2px"
                display="flex"
                alignItems="center"
                className={classes.document}
                width={isEuView ? '100%' : width}
              >
                {item.materialable.thumbnail?.url &&
                  item.materialable.materialableType === EnumMaterialableType.Document && (
                    <Image src={item.materialable.thumbnail.url} width={75} height={42} objectFit="contain" />
                  )}
                {item.materialable.materialableType === EnumMaterialableType.Site && (
                  <Box color={Colors.accent.keyBlue.default} display="flex" alignItems="center">
                    <DynamicMuiIcon variant="computer" size="28px" color="inherit" />
                  </Box>
                )}
                <Box ml="10px" flex={1} position="relative">
                  <LinkIcon href={openLink} isBlank className={classes.customLink}>
                    <Box
                      {...{ ref: ref }}
                      display="flex"
                      alignItems={textRef && textRef.clientHeight === textRef.scrollHeight ? 'center' : 'end'}
                    >
                      <Typography className={classes.lineClamp}>
                        {item.materialable.title}
                        <DynamicMuiIcon variant="openNew" className={classes.openInNew} />
                      </Typography>
                      {textRef && textRef.clientHeight != textRef.scrollHeight && (
                        <Box display="flex" alignItems="center" ml="4px">
                          <DynamicMuiIcon variant="openNew" size="16px" />
                        </Box>
                      )}
                    </Box>
                  </LinkIcon>
                </Box>
              </Box>
            )}

            {renderFileAttachment}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    // UserView
    <Box display="flex" alignItems="start">
      <Box mr={2}>
        <Avatar alt={item.sendedBy?.displayName} src={item.sendedBy?.avatar?.url} className={classes.avatar} />
      </Box>

      <Box sx={isExistsChatMessageAttachment ? { width: '100%', maxWidth: '680px' } : {}}>
        <Box
          py={2}
          px="10px"
          className={clsx(classes.message, {
            [classes.userMessage]: item.sendedByType === EnumChatMessageSendedByType.User
          })}
        >
          <Box display="flex" alignItems="center" justifyContent={'space-between'} mb="8px">
            <Typography variant="h6">{item.sendedBy?.displayName}</Typography>
            <Box ml={1}>
              <Typography className={classes.date} variant="subtitle2">
                {item.createdAt ? dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME) : ''}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="subtitle1" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
              <LinkParser text={item.message ?? ''} />
            </Typography>
          </Box>
          {item.createCausedBy === 'publish_to_contact' && item.materialable && (
            <Box
              p={2}
              mt="8px"
              mb="2px"
              display="flex"
              alignItems="center"
              className={classes.document}
              width={isEuView ? '100%' : width}
            >
              {item.materialable.thumbnail?.url &&
                item.materialable.materialableType === EnumMaterialableType.Document && (
                  <Image src={item.materialable.thumbnail.url} width={75} height={42} objectFit="contain" />
                )}
              {item.materialable.materialableType === EnumMaterialableType.Site && (
                <Box color={Colors.accent.keyBlue.default} display="flex" alignItems="center">
                  <DynamicMuiIcon variant="computer" size="28px" color="inherit" />
                </Box>
              )}
              <Box ml="10px" flex={1} position="relative">
                <LinkIcon href={openLink} isBlank className={classes.customLink}>
                  <Box
                    {...{ ref: ref }}
                    display="flex"
                    alignItems={textRef && textRef.clientHeight === textRef.scrollHeight ? 'center' : 'end'}
                  >
                    <Typography className={classes.lineClamp}>
                      {item.materialable.title}
                      <DynamicMuiIcon variant="openNew" className={classes.openInNew} />
                    </Typography>
                    {textRef && textRef.clientHeight != textRef.scrollHeight && (
                      <Box display="flex" alignItems="center" ml="4px">
                        <DynamicMuiIcon variant="openNew" size="16px" />
                      </Box>
                    )}
                  </Box>
                </LinkIcon>
              </Box>
            </Box>
          )}

          {renderFileAttachment}
        </Box>

        {!isEuView && item.createCausedBy === 'publish_to_contact' && euReadInfo.length > 0 && (
          <Box mt="10px" ml="8px">
            <Typography className={classes.readAtText}>
              既読 {euReadInfo[0]} {`(${euReadInfo[1]})`}
            </Typography>
          </Box>
        )}

        {item.createCausedBy != 'publish_to_contact' && formDocumentName && item.materialable && (
          <Box mt="8px" ml="8px" display="flex" alignItems="center">
            <LinkIcon
              href={
                item.materialable.materialableType === 'document'
                  ? `/documents/${item.materialable.materialableId}`
                  : `/multi-documents/${item.materialable.materialableId}`
              }
              isBlank
              className={classes.customLink}
            >
              <DynamicMuiIcon
                variant={item.materialable.materialableType === 'document' ? 'document' : 'computer'}
                size="16px"
                className={classes.icon}
              />
              <Box ml="4px" mr="2px">
                <Typography className={classes.formDocumentName} variant="subtitle2">
                  {formDocumentName}
                </Typography>
              </Box>
            </LinkIcon>
            <Typography variant="subtitle2" style={{ color: '#6D7784', whiteSpace: 'nowrap' }}>
              からチャット
            </Typography>
          </Box>
        )}

        {item.sendEmailStatus === EnumEmailTransactionableSendStatus.Fail && (
          <Box display="flex" mt="8px" color={Colors.accent.keyRed.default}>
            <Box mr="10px">
              <ErrorOutline fontSize="small" />
            </Box>
            <Typography fontSize="s">
              受信側の問題で送信エラーになりました。送信先のメールアドレスをご確認ください。
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
