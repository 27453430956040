import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Ad = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.9993 5H4.99935C3.16602 5 1.66602 6.5 1.66602 8.33333V31.6667C1.66602 33.5 3.16602 35 4.99935 35H34.9993C36.8327 35 38.3327 33.5 38.3327 31.6667V8.33333C38.3327 6.5 36.8327 5 34.9993 5ZM33.3327 31.6667H21.666C20.7493 31.6667 19.9993 30.9167 19.9993 30V23.3333C19.9993 22.4167 20.7493 21.6667 21.666 21.6667H33.3327C34.2493 21.6667 34.9993 22.4167 34.9993 23.3333V30C34.9993 30.9167 34.2493 31.6667 33.3327 31.6667Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84154C1.50202 1.98324 2.89359 1.98324 3.75188 2.84154L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.9497C-0.214574 5.0914 -0.214574 3.69983 0.643721 2.84154Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default Ad
