/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumSettingStatus = {
  Active: 'active',
  Inactive: 'inactive',
  Required: 'required'
} as const
export type EnumSettingStatus = typeof EnumSettingStatus[keyof typeof EnumSettingStatus]

export function EnumSettingStatusFromJSON(json: any): EnumSettingStatus {
  return EnumSettingStatusFromJSONTyped(json, false)
}

export function EnumSettingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumSettingStatus {
  return json as EnumSettingStatus
}

export function EnumSettingStatusToJSON(value?: EnumSettingStatus | null): any {
  return value as any
}
