import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Button, Password, Typography } from 'src/components/atoms'
import { useResetPassword } from 'src/fixtures/modules/auth/hooks'
import * as yup from 'yup'

export const resetPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .label('パスワード')
    .matches(
      /^(?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[\x20\W_])[\x20!-~]{12,}/,
      'パスワードはアルファベットと数字と記号を含めた12文字以上で設定してください'
    )
    .required('パスワードは必須です')
})

export const ResetPassword = () => {
  const { handleResetPassword } = useResetPassword()
  const router = useRouter()
  const [resetToken, setResetToken] = useState('')
  const { token } = router.query as { token?: string }
  const formik = useFormik({
    validationSchema: resetPasswordValidationSchema,
    initialValues: {
      password: ''
    },
    onSubmit: values => {
      formik.resetForm()
      handleResetPassword(resetToken, values.password)
    }
  })

  useEffect(() => {
    if (token) {
      setResetToken(token)
    }
  }, [token])
  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h1">パスワードの再設定</Typography>
      <Box mt="24px" />
      <Typography variant="h5">パスワードの設定</Typography>
      <Box mt="6px" />
      <Box display="flex" pt="6px" pb="4px">
        <Password
          name="password"
          password={formik.values.password}
          required
          error={formik.touched.password ? formik.errors.password : undefined}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          height="large"
          fullWidth
        />
      </Box>
      <Box mt="8px">
        <Typography fontSize="s" lh="relaxed">
          アルファベットと数字と記号を含めた12文字以上で設定してください
        </Typography>
      </Box>
      <Box mt="24px" />
      <Button title="再設定" size="large" width="100%" type="submit" />
    </form>
  )
}
