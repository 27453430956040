/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const OrganizationPaymentBillingTransactionBillingStatusEnum = {
    Draft: 'draft',
    Open: 'open',
    Paid: 'paid',
    Uncollectible: 'uncollectible',
    Void: 'void'
};
export function OrganizationPaymentBillingTransactionFromJSON(json) {
    return OrganizationPaymentBillingTransactionFromJSONTyped(json, false);
}
export function OrganizationPaymentBillingTransactionFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        planName: json['plan_name'],
        billingStatus: json['billing_status'],
        humanizedStatus: json['humanized_status'],
        amount: json['amount'],
        paidAt: json['paid_at'] === null ? null : new Date(json['paid_at']),
        receiptUrl: json['receipt_url']
    };
}
export function OrganizationPaymentBillingTransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        plan_name: value.planName,
        billing_status: value.billingStatus,
        humanized_status: value.humanizedStatus,
        amount: value.amount,
        paid_at: value.paidAt === null ? null : value.paidAt.toISOString(),
        receipt_url: value.receiptUrl
    };
}
