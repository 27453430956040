import { Box, ButtonBase, createStyles, makeStyles, Typography } from '@material-ui/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { Colors, DynamicMuiIcon, IconVariant, MuiIconVariant, SvgIcon } from 'src/components/atoms'

export type MenuType = 'info' | 'chat' | 'negotiation' | 'cta' | 'setting' | 'message' | undefined

interface MenuProps {
  icon: MuiIconVariant | IconVariant
  iconType: 'muiIcon' | 'svgIcon'
  label: string
  query: MenuType
}

const menuList: MenuProps[] = [
  { icon: 'info', label: '資料情報', query: 'info', iconType: 'muiIcon' },
  { icon: 'chatBubble', label: 'チャット', query: 'chat', iconType: 'muiIcon' },
  { icon: 'message', label: 'メッセージ', query: 'message', iconType: 'muiIcon' },
  { icon: 'editCalendar', label: 'アポイント', query: 'negotiation', iconType: 'svgIcon' },
  { icon: 'adsClick', label: 'CTA', query: 'cta', iconType: 'svgIcon' },
  { icon: 'setting', label: '設定', query: 'setting', iconType: 'muiIcon' }
]

const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      display: 'block',
      width: '62px',
      height: '62px',
      borderRadius: '6px',
      textAlign: 'center',
      '&.active': {
        background: Colors.selected.background,
        color: Colors.primary.default
      }
    },
    link: {
      borderRadius: '6px',
      color: Colors.base.middleGray,
      textDecoration: 'none',
      '&:hover': {
        background: Colors.background.lightGray,
        color: Colors.primary.default
      }
    }
  })
})

export const Menu = () => {
  const classes = useStyles()
  const router = useRouter()
  const { mode, documentId } = router.query

  return (
    <Box
      bgcolor={'white'}
      width="78px"
      height={1}
      borderLeft={`1px solid ${Colors.background.silver}`}
      padding="8px 14px"
      display="flex"
      flexDirection={'column'}
      alignItems="center"
    >
      {menuList.map(menu => {
        return (
          <Link
            key={menu.icon}
            href={{
              pathname: `/documents/${documentId}/setting`,
              query: { mode: menu.query }
            }}
            replace
          >
            <a className={classes.link}>
              <ButtonBase className={`${classes.button} ${mode === menu.query && 'active'}`}>
                {menu.iconType === 'muiIcon' ? (
                  <DynamicMuiIcon variant={menu.icon as MuiIconVariant} size="20px" />
                ) : (
                  <SvgIcon variant={menu.icon as IconVariant} size="20px" color="inherit" />
                )}
                <Box mt="4px" />
                <Typography variant="h6" display="block">
                  {menu.label}
                </Typography>
              </ButtonBase>
              {<Box height="1px" bgcolor={mode !== menu.query ? '#EEF1F4' : '#fff'} width="62px" />}
            </a>
          </Link>
        )
      })}
    </Box>
  )
}
