import React, { useMemo } from 'react'
import { Avatar, Box, createStyles, makeStyles } from '@material-ui/core'
import { Colors, DynamicMuiIcon, IconVariant, MuiIconVariant, SvgIcon, Typography } from 'src/components/atoms'
import { ContactTimeline, EnumActivityStatus, EnumInflowSource } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import Link from 'next/link'
import { useGlobalStyles } from 'src/styles/theme'
import clsx from 'clsx'

const labelList: Record<
  string,
  { icon: MuiIconVariant | IconVariant; label: string; iconType: 'muiIcon' | 'svgIcon' }
> = {
  [EnumActivityStatus.Notyet]: { icon: 'email', label: '未閲覧', iconType: 'muiIcon' },
  [EnumActivityStatus.Chat]: { icon: 'chatBubble', label: 'チャット', iconType: 'muiIcon' },
  [EnumActivityStatus.FirstView]: { icon: 'drafts', label: '初回閲覧', iconType: 'muiIcon' },
  [EnumActivityStatus.View]: { icon: 'drafts', label: '閲覧', iconType: 'muiIcon' },
  [EnumActivityStatus.Opportunity]: { icon: 'meeting', label: 'アポイント', iconType: 'muiIcon' },
  [EnumActivityStatus.Cta]: { icon: 'adsClick', label: 'CTA', iconType: 'svgIcon' }
}

const useStyles = makeStyles(() =>
  createStyles({
    lineLineLabel: {
      background: Colors.label.blue,
      color: Colors.base.navyBlue,
      borderRadius: 4,
      padding: '3px 8px',
      alignItems: 'center',
      '& .MuiTypography-root': {
        marginLeft: '4px'
      }
    },
    avatar: {
      display: 'inline-flex',
      height: 20,
      width: 20,
      verticalAlign: 'sub',
      marginRight: 6
    },
    icon: {
      display: 'inline-flex',
      height: 16,
      width: 16,
      verticalAlign: 'text-bottom',
      marginLeft: '2px'
    },
    link: {
      color: Colors.accent.keyBlue.default
    },
    small: {
      padding: '8px 10px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '13px'
        }
      }
    },
    medium: {
      padding: '10px 12px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '14px'
        }
      }
    }
  })
)

interface ClientTimeLineItemActionProps {
  item: ContactTimeline
  size?: 'small' | 'medium'
}

export const ClientTimeLineItemAction = ({ item, size = 'medium' }: ClientTimeLineItemActionProps) => {
  const classes = useStyles()
  if (
    item.action?.activityStatus === EnumActivityStatus.ViewProfile ||
    item.action?.activityStatus === EnumActivityStatus.Download
  ) {
    return null
  }
  return (
    <Box
      className={size === 'medium' ? classes.medium : classes.small}
      border={`1px solid ${Colors.background.silver}`}
      borderRadius="6px"
      bgcolor="#fff"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="6px">
        <Box display="flex" alignItems="center">
          {item.action?.activityStatus && (
            <Box
              mr={2}
              display="flex"
              width="fit-content"
              className={clsx(classes.lineLineLabel, 'timeLineItemheading')}
            >
              {labelList[item.action.activityStatus].iconType === 'muiIcon' ? (
                <DynamicMuiIcon variant={labelList[item.action.activityStatus].icon as MuiIconVariant} size="16px" />
              ) : (
                <SvgIcon
                  variant={labelList[item.action.activityStatus].icon as IconVariant}
                  size="16px"
                  color="inherit"
                />
              )}
              <Typography fontWeight="bold">{labelList[item.action.activityStatus].label}</Typography>
            </Box>
          )}
          {item.action?.inflowSource && (
            <Box display="flex" alignItems="center" color={Colors.base.middleGray}>
              <DynamicMuiIcon
                variant={item.action?.inflowSource === EnumInflowSource.Email ? 'email' : 'link'}
                size="16px"
              />
              <Box mr="4px" />
              {size === 'medium' && (
                <Typography fontSize="xs" fontWeight="normal" lineheight="12px">
                  {item.action?.inflowSource === EnumInflowSource.Email ? 'メール' : 'リンク'}から流入
                </Typography>
              )}
            </Box>
          )}
        </Box>

        <Typography fontSize="xs" style={{ color: Colors.base.middleGray }}>
          {item.createdAt && dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME)}
        </Typography>
      </Box>

      <Box>
        <Box display="inline">
          <Avatar className={classes.avatar} />
          <Typography display="inline" variant="h5">
            {item.action?.contact?.displayName}
          </Typography>
        </Box>
        <Typography display="inline" variant="subtitle1">
          様が
        </Typography>
        {item.action?.activityStatus === EnumActivityStatus.Cta ? (
          <>
            <MaterialLink item={item} />
            <Typography display="inline" variant="subtitle1">
              から{item.action?.label}をクリック
            </Typography>
          </>
        ) : item.action?.activityStatus === EnumActivityStatus.Opportunity ? (
          <>
            <MaterialLink item={item} />
            {/* サーバーの文言がおかしい+Opportunityの時でそもそも分岐が必要なので、ハードコードしてしまう（どこかでitem.action?.labelを参照するように戻す） */}
            <Typography display="inline" variant="subtitle1">
              からアポイント設定をクリック
            </Typography>
          </>
        ) : item.action?.activityStatus === EnumActivityStatus.Chat ? (
          <>
            <MaterialLink item={item} />
            <Typography display="inline" variant="subtitle1">
              から{item.action?.label}
            </Typography>
          </>
        ) : (
          <>
            <MaterialLink item={item} />
            <Typography display="inline" variant="subtitle1">
              を{item.action?.label}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )
}

const MaterialLink = ({ item }: ClientTimeLineItemActionProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const siteLink = useMemo(() => {
    return (
      <Link href={`/multi-documents/${item.action?.siteId}`}>
        <Box display="inline" color={Colors.accent.keyBlue.default} className={globalClasses.cursorPointer}>
          <DynamicMuiIcon variant="computer" size="16px" className={classes.icon} />
          <Typography className={classes.link} display="inline" variant="subtitle1">
            {item.action?.site?.displayName}
          </Typography>
        </Box>
      </Link>
    )
  }, [item, classes, globalClasses])

  if (item.action?.documentId) {
    return (
      <>
        {item.action?.siteId && (
          <>
            <Box display="inline">{siteLink}</Box>
            <Typography display="inline" variant="subtitle1">
              経由で
            </Typography>
          </>
        )}
        <Link href={`/documents/${item.action.documentId}`}>
          <Box display="inline" color={Colors.accent.keyBlue.default} className={globalClasses.cursorPointer}>
            <DynamicMuiIcon variant="document" size="16px" className={classes.icon} />
            <Typography className={classes.link} display="inline" variant="subtitle1">
              {item.action?.document?.displayName}
            </Typography>
          </Box>
        </Link>
      </>
    )
  } else if (item.action?.siteId) {
    return <Box display="inline">{siteLink}</Box>
  } else {
    return (
      <Box display="inline">
        <DynamicMuiIcon
          variant={item.action?.document ? 'document' : 'computer'}
          size="16px"
          className={classes.icon}
        />
        <Typography display="inline" variant="subtitle1">
          {item.action?.document ? item.action?.document?.displayName : item.action?.site?.displayName}
        </Typography>
      </Box>
    )
  }
}
