/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateCompanySettingRequestCompanySettingFromJSON, CreateCompanySettingRequestCompanySettingToJSON } from './CreateCompanySettingRequestCompanySetting';
import { CreateCompanySettingRequestCompanySettingFieldsInnerFromJSON, CreateCompanySettingRequestCompanySettingFieldsInnerToJSON } from './CreateCompanySettingRequestCompanySettingFieldsInner';
export function CreateCompanySettingRequestFromJSON(json) {
    return CreateCompanySettingRequestFromJSONTyped(json, false);
}
export function CreateCompanySettingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companySetting: !exists(json, 'company_setting')
            ? undefined
            : CreateCompanySettingRequestCompanySettingFromJSON(json['company_setting']),
        companySettingFields: !exists(json, 'company_setting_fields')
            ? undefined
            : json['company_setting_fields'].map(CreateCompanySettingRequestCompanySettingFieldsInnerFromJSON)
    };
}
export function CreateCompanySettingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company_setting: CreateCompanySettingRequestCompanySettingToJSON(value.companySetting),
        company_setting_fields: value.companySettingFields === undefined
            ? undefined
            : value.companySettingFields.map(CreateCompanySettingRequestCompanySettingFieldsInnerToJSON)
    };
}
