import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Site, SiteSection } from '@noco/http-client/lib/noco'
import { ChangeEvent, useCallback, useReducer, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, Input, Typography } from 'src/components/atoms'
import { ModalImageList } from 'src/components/modals'
import { Banner } from 'src/components/molecules'
import { DocumentSectionList } from 'src/components/organisms'
import { useUpdateSite } from 'src/fixtures/modules/site/hooks'
import { useGlobalStyles } from 'src/styles/theme'
import clsx from 'clsx'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'

const useStyles = makeStyles(() => {
  return createStyles({
    titleStyle: {
      '& .MuiInput-root': {
        minHeight: '40px',
        padding: '0 0',
        boxShadow: 'none',
        margin: '0',
        '& textarea': {
          margin: '0 9px !important',
          padding: '0',
          fontSize: '24px',
          fontWeight: 'bold',
          lineHeight: '1.7 !important',
          letterSpacing: '0.02em',
          textAlign: 'center'
        }
      }
    },
    descStyle: {
      '& .MuiInput-root': {
        minHeight: '48px',
        padding: '8px 0',
        boxShadow: 'none',
        margin: '0',
        '& textarea': {
          margin: '0 7px !important',
          fontSize: '16px',
          lineHeight: '1.7 !important',
          letterSpacing: '0.04em'
        }
      }
    },
    hoverStyle: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default,
        boxShadow: `0 0 0 1px ${Colors.accent.keyPurple.default} inset`,
        borderRadius: '6px',
        cursor: 'pointer'
      }
    },
    breakAll: {
      wordBreak: 'break-all'
    }
  })
})

export interface MainProps {
  site: Site
  siteSectionList: SiteSection[]
}

export const Main = ({ site, siteSectionList }: MainProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { data: resOrganization } = useGetOrganization()
  const organization = resOrganization?.organization
  const { handleUpdateSite } = useUpdateSite(site?.id!)
  const [isEditingTitle, toggleIsEditingTitle] = useReducer(v => !v, false)
  const [isEditingDescription, toggleIsEditingDescription] = useReducer(v => !v, false)
  const [title, setTitle] = useState<string>(site.title || '')
  const [description, setDescription] = useState<string>(site.description || '')
  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }, [])
  const handleSaveTitle = useCallback(() => {
    toggleIsEditingTitle()
    handleUpdateSite({ title })
  }, [title, handleUpdateSite])
  const handleChangeDescription = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }, [])
  const handleSaveDescription = useCallback(() => {
    toggleIsEditingDescription()
    handleUpdateSite({ description })
  }, [description, handleUpdateSite])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  const handleOpenModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setOpenModal(true)
  }, [])
  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
    setTimeout(() => {
      setAnchorEl(null)
    }, 256)
  }, [])

  return (
    <Box display="flex" flexDirection="column">
      <Box borderBottom={`1px solid ${Colors.background.silver}`}>
        <Banner site={site} mode="setting" organization={organization} />
      </Box>
      <Box width={1} height="156px" position="relative">
        <img
          src={site?.coverPhoto?.url || '/images/dummy/banner.png'}
          alt="banner"
          className={globalClasses.imgCover}
        />
        <Box position="absolute" bottom="16px" right="16px" bgcolor="#fff" borderRadius="4px">
          <Button
            kind="neutral"
            variant="outlined"
            title="カバーを変更"
            onClick={handleOpenModal}
            startIcon={<DynamicMuiIcon variant="image" />}
          />
        </Box>
      </Box>
      <ModalImageList
        siteId={site.id!}
        open={isOpenModal}
        onClose={handleCloseModal}
        rectsBottom={anchorEl?.getClientRects() ? anchorEl?.getClientRects()[0].bottom : 0}
        rectsRight={anchorEl?.getClientRects() ? anchorEl?.getClientRects()[0].right : 0}
      />
      <Box height={1} bgcolor={Colors.functional.background.default} pb="42px">
        <Box pt="48px" pb="56px" px="16px" width={1} maxWidth="712px" margin="0 auto">
          {isEditingTitle ? (
            <Box className={classes.titleStyle}>
              <Input
                value={title}
                onChange={handleChangeTitle}
                onBlur={handleSaveTitle}
                name="title"
                fullWidth
                multiline
                autoFocus
                isAlignCenter
              />
            </Box>
          ) : (
            <Box
              width={1}
              textAlign="center"
              onClick={toggleIsEditingTitle}
              className={clsx(classes.hoverStyle, classes.breakAll)}
            >
              <Typography fontSize="xxl" fontWeight="bold" letterSpacing="default">
                {title || site.title || '資料サイト名を入力してください'}
              </Typography>
            </Box>
          )}
          {isEditingDescription ? (
            <Box mt="16px" className={classes.descStyle}>
              <Input
                value={description}
                onChange={handleChangeDescription}
                onBlur={handleSaveDescription}
                name="description"
                fullWidth
                autoFocus
                multiline
              />
            </Box>
          ) : (
            <Box
              mt="16px"
              p="8px"
              onClick={toggleIsEditingDescription}
              className={clsx(classes.hoverStyle, classes.breakAll)}
            >
              <Typography fontSize="m" letterSpacing="tight">
                {description || site.description || '資料サイトの説明文を入力してください'}
              </Typography>
            </Box>
          )}
        </Box>
        <Box width={1} maxWidth="1096px" margin="0 auto" px="16px">
          <DocumentSectionList siteId={site.id!} items={siteSectionList} mode="setting" />
        </Box>
      </Box>
    </Box>
  )
}
