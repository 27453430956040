import { CSSProperties } from '@material-ui/core/styles/withStyles'

export const Pallete = {
  functional: {
    title: {
      default: '#2E2E2E'
    },
    body1: {
      default: '#111111'
    },
    body2: {
      default: '#6C6C6C'
    },
    disable: {
      default: '#BCBCBC',
      bg: '#D6D6D6',
      button: '#CCCCCC'
    },
    border: {
      default: '#2A5491',
      pale: '#BCBCBC',
      paleblue: '#CEE2F5',
      lightGray: '#DDDDDD'
    },
    background: {
      default: '#FFFFFF',
      palegray: '#EFEFEF',
      gray: '#B7B7B7',
      dark: '#333333',
      opacityGray: 'rgba(17, 17, 17, 30%)',
      opacityBlack: 'rgba(0, 0, 0, 80%)'
    },
    whiteText: {
      default: '#ffffff'
    },
    shadow: {
      box: '0px 0px 4px rgba(0, 0, 0, 0.2)'
    }
  }
}

export const Colors = {
  // Theme Color
  accent: {
    keyPurple: { default: '#6C60F0', opacity90: 'rgba(108, 96, 240, 1)' },
    keyBlue: { default: '#4573D2' },
    keyRed: { default: '#DE1B63' },
    keyYellow: { default: '#FFC127' }
  },
  background: {
    gray: '#E3E7ED',
    silver: '#DADCE0',
    lightGray: '#F8FAFD'
  },
  base: {
    black: '#222B35',
    middleGray: '#707B89',
    placeHolder: '#A1A2B0',
    navyBlue: '#383C5A'
  },
  option: {
    blue: '#0099D9',
    deepBlue: '#8493C7',
    green: '#00A498',
    orange: '#FF5A72',
    pink: '#F153A9',
    purple: '#9073B5',
    red: '#FF5A72',
    violet: '#9642E9'
  },
  label: {
    blue: '#CADFFF',
    brown: '#EBCDCD',
    gray: '#DADCE0',
    green: '#CFEDC6',
    lightBlue: '#C5E9F2',
    mintGreen: '#C6F3E6',
    pink: '#FDD5E6',
    purple: '#E7D3F3',
    red: '#FFC7C7',
    yellow: '#FBF4E5'
  },
  primary: {
    default: '#6C60F0'
  },
  secondary: {
    default: '#EE2253'
  },
  selected: {
    background: '#F0F2FF'
  },
  hover: {
    white: {
      default: '#F2F6FF'
    }
  },

  // Function Color
  error: {
    default: '#EE2253'
  },
  warning: {
    default: '#EE2253'
  },
  success: {
    default: '#6C60F0'
  },
  ...Pallete
}

// input 系の複雑な色定義

// normal
const NORMAL_COLOR = Colors.base.black
const NORMAL_BG_COLOR = Colors.functional.whiteText.default
const NORMAL_BORDER_COLOR = Colors.functional.border.lightGray

// success color
const SUCCESS_COLOR = Colors.success.default
const SUCCESS_BORDER_COLOR = Colors.success.default
const SUCCESS_BG_COLOR = Colors.functional.whiteText.default

// error color
const ERROR_COLOR = Colors.error.default
const ERROR_BORDER_COLOR = Colors.error.default
const ERROR_BG_COLOR = Colors.functional.whiteText.default

// disabled
const DISABLED_COLOR = Colors.base.black
const DISABLED_BG_COLOR = Colors.background.gray
const DISABLED_BORDER_COLOR = Colors.functional.border.lightGray

// focused
const HOVER_COLOR = Colors.base.black
const HOVER_BG_COLOR = Colors.functional.whiteText.default
const HOVER_BORDER_COLOR = '#ababab'

interface InputStatusStyles {
  normal: CSSProperties
  success: CSSProperties
  error: CSSProperties
  disabled: CSSProperties
  hover: CSSProperties
}

export const inputStatusStyles: InputStatusStyles = {
  normal: {
    color: NORMAL_COLOR,
    backgroundColor: NORMAL_BG_COLOR,
    borderColor: NORMAL_BORDER_COLOR
  },
  success: {
    color: SUCCESS_COLOR,
    backgroundColor: SUCCESS_BG_COLOR,
    borderColor: SUCCESS_BORDER_COLOR
  },
  error: {
    color: ERROR_COLOR,
    backgroundColor: ERROR_BG_COLOR,
    borderColor: ERROR_BORDER_COLOR
  },
  disabled: {
    color: DISABLED_COLOR,
    backgroundColor: DISABLED_BG_COLOR,
    borderColor: DISABLED_BORDER_COLOR
  },
  hover: {
    color: HOVER_COLOR,
    backgroundColor: HOVER_BG_COLOR,
    borderColor: HOVER_BORDER_COLOR
  }
}
