import { Box, Popover } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { Colors, Input, Pallete } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { ColorBox } from 'src/components/molecules/ColorPicker/ColorBox'
import colorString from 'color-string'

export interface ColorPickerProps {
  name?: string
  color: string
  hasEditPermission?: boolean
  onChangeColor: (value: string) => void
  onBlur?: (event: React.FocusEvent<Element>) => void
}

const colorList = [
  Colors.accent.keyPurple.default,
  Colors.option.violet,
  Colors.accent.keyRed.default,
  Colors.option.pink,
  Colors.option.blue,
  Colors.option.green
]

export const ColorPicker = ({
  onChangeColor,
  color,
  name = 'selectedColor',
  onBlur,
  hasEditPermission = true
}: ColorPickerProps) => {
  const globalClasses = useGlobalStyles()

  const [colorText, setColorText] = useState(color)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleColorClick = useCallback(
    color => {
      if (!hasEditPermission) return

      setColorText(color)
      onChangeColor(color)
      setAnchorEl(null)
    },
    [hasEditPermission, onChangeColor]
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setColorText(event.currentTarget.value)
      const regexp = /#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})/
      const valid = !!colorString.get.rgb(event.currentTarget.value) && regexp.test(event.currentTarget.value)

      if (valid) {
        onChangeColor(event.currentTarget.value)
      }
    },
    [onChangeColor]
  )

  useEffect(() => {
    setColorText(color)
  }, [color])

  return (
    <Box display="flex" alignItems="center">
      <Box
        width="34px"
        height="34px"
        bgcolor={colorText}
        onClick={handleButtonClick}
        className={hasEditPermission ? globalClasses.cursorPointer : undefined}
        borderRadius="4px"
        mr="8px"
      />
      <Box flex={1}>
        <Input
          name={name}
          fullWidth
          value={colorText}
          onChange={onChange}
          backgroundColor={Pallete.functional.background.default}
          onBlur={onBlur}
          disabled={!hasEditPermission}
        />
      </Box>
      <Popover
        open={hasEditPermission && Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box display="flex" flexDirection="column" padding="8px 0px 0px 8px">
          <Box display="flex" mb="8px">
            {colorList.map((color, j) => (
              <ColorBox key={j} color={color} onClick={handleColorClick} />
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
