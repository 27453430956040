import { useEffect, useState } from 'react'

export type BrowserStatus = 'active' | 'inactive'
export const useGetBrowserActiveStatus = () => {
  const [status, setStatus] = useState<BrowserStatus>('active')
  useEffect(() => {
    const focusHandler = () => {
      setStatus('active')
    }
    const blurHandler = () => {
      setStatus('inactive')
    }
    window.addEventListener('focus', focusHandler)
    window.addEventListener('blur', blurHandler)
    return () => {
      window.removeEventListener('focus', focusHandler)
      window.removeEventListener('blur', blurHandler)
    }
  }, [])
  return { status }
}

export const useSetBrowserActiveEvent = ({
  page,
  onActive,
  isActivated
}: {
  page?: number | null
  onActive: () => void
  isActivated?: boolean
}) => {
  const { status } = useGetBrowserActiveStatus()
  const [beforeBrowserStatus, setBeforeBrowserStatus] = useState<BrowserStatus>('active')
  const [currentActivate, setCurrentActivate] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number | null>(null)
  const [calledCount, setCalledCount] = useState(0)
  useEffect(() => {
    // * 発火タイミングは[inactive から active に変わった時 isActivatedがfalseからtrueになった時 pageが変わった時]
    if (
      (beforeBrowserStatus === 'inactive' && status === 'active') ||
      (status === 'active' && page && page !== currentPage) ||
      (!currentActivate && isActivated !== undefined && isActivated)
    ) {
      onActive()
      setCalledCount(prevState => prevState + 1)
    }

    if (status === 'active' && page && page !== currentPage) {
      setCurrentPage(page!)
    }

    if (isActivated !== undefined && currentActivate !== isActivated) {
      setCurrentActivate(isActivated)
    }
    // MEMO: before status の更新
    if (beforeBrowserStatus !== status) {
      setBeforeBrowserStatus(status)
    }
  }, [status, beforeBrowserStatus, page, currentPage, onActive, currentActivate, isActivated])
  return { calledCount }
}
