/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ActivityStatusObjectFromJSON, ActivityStatusObjectToJSON } from './ActivityStatusObject';
import { ContactWithAssociationFromJSON, ContactWithAssociationToJSON } from './ContactWithAssociation';
import { EnumInflowSourceFromJSON, EnumInflowSourceToJSON } from './EnumInflowSource';
import { EnumSupportStatusFromJSON, EnumSupportStatusToJSON } from './EnumSupportStatus';
export function SiteContactFromJSON(json) {
    return SiteContactFromJSONTyped(json, false);
}
export function SiteContactFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        siteId: !exists(json, 'site_id') ? undefined : json['site_id'],
        contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
        expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
        canViewable: !exists(json, 'can_viewable') ? undefined : json['can_viewable'],
        inflowSource: !exists(json, 'inflow_source') ? undefined : EnumInflowSourceFromJSON(json['inflow_source']),
        actedAt: !exists(json, 'acted_at') ? undefined : json['acted_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        currentActivityStatusObject: !exists(json, 'current_activity_status_object')
            ? undefined
            : ActivityStatusObjectFromJSON(json['current_activity_status_object']),
        activityStatusObjects: !exists(json, 'activity_status_objects')
            ? undefined
            : json['activity_status_objects'].map(ActivityStatusObjectFromJSON),
        supportStatus: !exists(json, 'support_status') ? undefined : EnumSupportStatusFromJSON(json['support_status']),
        contact: !exists(json, 'contact') ? undefined : ContactWithAssociationFromJSON(json['contact'])
    };
}
export function SiteContactToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        site_id: value.siteId,
        contact_id: value.contactId,
        expired_on: value.expiredOn,
        can_viewable: value.canViewable,
        inflow_source: EnumInflowSourceToJSON(value.inflowSource),
        acted_at: value.actedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        current_activity_status_object: ActivityStatusObjectToJSON(value.currentActivityStatusObject),
        activity_status_objects: value.activityStatusObjects === undefined
            ? undefined
            : value.activityStatusObjects.map(ActivityStatusObjectToJSON),
        support_status: EnumSupportStatusToJSON(value.supportStatus),
        contact: ContactWithAssociationToJSON(value.contact)
    };
}
