import { Box, ButtonBase, makeStyles, Popover, Typography, createStyles } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Colors, DynamicMuiIcon, Input, MuiIconVariant } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { SelectorItemObject } from '@noco/http-client/lib/noco'

export interface SelectAndCreateListProps {
  onSetListId?: (value: string) => void
  selectorList?: SelectorItemObject['selectorItems']
  onSetKeyword?: (value: string) => void
  keyword?: string
  inputIcon?: MuiIconVariant
  keywordIcon?: MuiIconVariant
  selectedLabel?: string
  onSetSelectedLabel?: (value: string) => void
}

export interface SelectAndCreateListOptionData {
  label: string
  value: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      textAlign: 'left'
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    },
    wordBreak: {
      wordBreak: 'break-all'
    }
  })
})

const OPTION_HEIGHT = 28

export const SelectAndCreateList = ({
  onSetListId,
  selectorList,
  onSetKeyword,
  keyword,
  inputIcon,
  keywordIcon = 'search',
  selectedLabel,
  onSetSelectedLabel
}: SelectAndCreateListProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setIsOpen] = useState(false)
  const id = open ? 'simple-popover' : undefined

  const options = useMemo(() => selectorList?.map(c => ({ value: c.id!, label: c.text! })) ?? [], [selectorList])

  const handleOpenOptionList = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setIsOpen(true)
  }, [])
  const handleCloseOptionList = useCallback(() => {
    onSetKeyword?.('')
    setIsOpen(false)
  }, [onSetKeyword])

  const handleDeleteSelectedLabel = useCallback(() => {
    onSetSelectedLabel?.('')
    onSetListId?.('')
  }, [onSetSelectedLabel, onSetListId])

  const handleChangeKeyword = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSetKeyword?.(event.target.value)
    },
    [onSetKeyword]
  )
  const handleSetKeyword = useCallback(
    (value: string) => () => {
      onSetSelectedLabel?.(value)
      handleCloseOptionList()
    },
    [handleCloseOptionList, onSetSelectedLabel]
  )
  const handleSelectOption = useCallback(
    ({ value, label }: SelectAndCreateListOptionData) =>
      () => {
        onSetSelectedLabel?.(label)
        onSetListId?.(value)
        handleCloseOptionList()
      },
    [onSetListId, handleCloseOptionList, onSetSelectedLabel]
  )

  const position = useMemo(() => {
    if (!anchorEl) return
    const rect = anchorEl.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [anchorEl])

  return (
    <Box>
      <Box
        width={1}
        height="34px"
        border={`1px solid ${Colors.background.silver}`}
        borderRadius="4px"
        pl="14px"
        pr="4px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="inset 0px 1px 2px rgba(10, 10, 10, 0.1)"
        onClick={handleOpenOptionList}
      >
        <Box display="flex" alignItems="center" height="auto">
          {inputIcon && (
            <Box color={Colors.base.middleGray} display="flex" alignItems="center" mr="8px">
              <DynamicMuiIcon variant={inputIcon} size="20px" color="inherit" fontSize="inherit" />
            </Box>
          )}
          <Typography variant="subtitle1" className={classes.wordBreak}>
            {selectedLabel}
          </Typography>
        </Box>
        {selectedLabel && (
          <Box
            p="8px"
            color={Colors.base.middleGray}
            display="flex"
            alignItems="center"
            className={globalClasses.cursorPointer}
            onClick={handleDeleteSelectedLabel}
          >
            <DynamicMuiIcon variant="close" size="20px" color="inherit" fontSize="inherit" />
          </Box>
        )}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseOptionList}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorReference="anchorPosition"
        anchorPosition={position}
        PaperProps={{
          className: classes.paper,
          style: { width: (anchorEl && anchorEl.offsetWidth - 2) || 0 }
        }}
      >
        <Box pt="12px" pb="16px">
          <Box px="12px">
            <Input
              onChange={handleChangeKeyword}
              name="companyName"
              width="100%"
              value={keyword}
              startAdornment={
                <Box
                  pl="14px"
                  color={Colors.base.middleGray}
                  fontSize="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
                </Box>
              }
              endAdornment={
                <Box
                  p="10px"
                  color={Colors.base.middleGray}
                  fontSize="16px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => onSetKeyword?.('')}
                  className={globalClasses.cursorPointer}
                >
                  <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
                </Box>
              }
            />
          </Box>
          <Box height="10px" />
          {keyword && (
            <ButtonBase className={classes.buttonBase} onClick={handleSetKeyword(keyword)}>
              <Box
                width={1}
                display="flex"
                alignItems="center"
                color={Colors.accent.keyPurple.default}
                py="8px"
                px="20px"
              >
                {keywordIcon && (
                  <Box display="flex" justifyContent="center" alignItems="center" fontSize="16px" mr="10px">
                    <DynamicMuiIcon variant={keywordIcon} color="inherit" fontSize="inherit" />
                  </Box>
                )}
                <Typography variant="body2">{`"${keyword}"`}を追加</Typography>
              </Box>
            </ButtonBase>
          )}
          <Box overflow="auto" maxHeight={OPTION_HEIGHT * 7}>
            {options.map(option => {
              return (
                <ButtonBase
                  onClick={handleSelectOption(option)}
                  key={option.value}
                  className={clsx(classes.buttonBase, classes.hoverButton)}
                >
                  <Box px="20px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                    <Typography variant="body2">{option.label}</Typography>
                  </Box>
                </ButtonBase>
              )
            })}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
