import { Box, ButtonBase } from '@material-ui/core'
import React, { useMemo } from 'react'

import { Colors, Typography } from 'src/components/atoms'

export interface StepperItemProps {
  index: number
  currentIndex: number
  label: string
  isFirst?: boolean
  isLast?: boolean
  onClick?: () => void
}

export const StepperItem = ({
  index,
  currentIndex,
  label,
  isFirst = false,
  isLast = false,
  onClick
}: StepperItemProps) => {
  const status = useMemo(() => {
    if (currentIndex > index) return 'past'
    if (currentIndex === index) return 'current'
    if (currentIndex < index) return 'future'
    return 'past'
  }, [currentIndex, index])

  const rightBarColor = useMemo(() => {
    switch (status) {
      case 'current':
        return '#E9ECF1'
      case 'future':
        return '#E9ECF1'
      case 'past':
        return '#A1A2B0'
    }
  }, [status])

  const leftBarColor = useMemo(() => {
    switch (status) {
      case 'current':
        return '#A1A2B0'
      case 'future':
        return '#E9ECF1'
      case 'past':
        return '#A1A2B0'
    }
  }, [status])

  const badgeColor = useMemo(() => {
    switch (status) {
      case 'current':
        return Colors.primary.default
      case 'future':
        return Colors.background.gray
      case 'past':
        return Colors.base.placeHolder
    }
  }, [status])

  const labelColor = useMemo(() => {
    switch (status) {
      case 'current':
        return Colors.base.black
      case 'future':
        return Colors.base.placeHolder
      case 'past':
        return Colors.base.placeHolder
    }
  }, [status])

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '100%' }}>
      {/* left bar */}
      <Box height="4px" width="50%" bgcolor={isFirst ? 'transparent' : leftBarColor} />

      {/* badge */}
      <ButtonBase style={{ position: 'absolute' }} onClick={onClick} disabled={status === 'future'}>
        <Box
          sx={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            bgcolor: badgeColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white'
          }}
        >
          <Typography fontSize="s" fontWeight="bold" style={{ display: 'block' }}>
            {index + 1}
          </Typography>
        </Box>
      </ButtonBase>

      {/* right bar */}
      <Box height="4px" width="50%" bgcolor={isLast ? 'transparent' : rightBarColor} />

      {/* label */}
      <Box sx={{ position: 'absolute', top: '-8px', color: labelColor }}>
        <Typography fontSize="m" fontWeight="bold">
          {label}
        </Typography>
      </Box>
    </Box>
  )
}
