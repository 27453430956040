/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ShallowContactList
 */
export interface ShallowContactList {
  /**
   *
   * @type {string}
   * @memberof ShallowContactList
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ShallowContactList
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ShallowContactList
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof ShallowContactList
   */
  updatedAt: string
}

export function ShallowContactListFromJSON(json: any): ShallowContactList {
  return ShallowContactListFromJSONTyped(json, false)
}

export function ShallowContactListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShallowContactList {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at']
  }
}

export function ShallowContactListToJSON(value?: ShallowContactList | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
