import {
  CreateCompanySettingRequest,
  UpdateCompanySettingRequest,
  UpdateSortCompanySettingRequest
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'

export const useListCompanySettings = () => {
  const { data: auth } = useAuthenticate()
  const companySettingApi = nocoSDK.client?.userService.companySettingApi
  const func = useCallback(async () => {
    if (!auth) return
    return await companySettingApi
      ?.ApiFactory(auth.token)
      .userV1CompanySettingsGet()
      .then(res => res.data)
  }, [auth, companySettingApi])
  return useSWR(auth?.token ?? SWRCachePath.listCompanySettings(), func)
}

export const useCreateCompanySettings = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateList } = useListCompanySettings()
  const companySettingApi = nocoSDK.client?.userService.companySettingApi
  const handleCreateCompanySettings = useCallback(
    async (createCompanySettingRequest: CreateCompanySettingRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await companySettingApi?.ApiFactory(auth.token).userV1CompanySettingsPost({ createCompanySettingRequest })
        mutateList()
        Toaster.success('会社の項目を新規作成完了')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create company setting' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companySettingApi, me, mutateList]
  )

  return { error, isLoading, handleCreateCompanySettings }
}

export const useUpdateCompanySettings = (companySettingId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateList } = useListCompanySettings()
  const companySettingApi = nocoSDK.client?.userService.companySettingApi

  const handleUpdateCompanySettings = useCallback(
    async (updateCompanySettingRequest: UpdateCompanySettingRequest) => {
      try {
        if (!auth || !me || !companySettingId) return
        setError(undefined)
        setIsLoading(true)
        await companySettingApi
          ?.ApiFactory(auth.token)
          .userV1CompanySettingsCompanySettingIdPut({ companySettingId, updateCompanySettingRequest })
        mutateList()
        Toaster.success('会社の項目を編集しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update company settings' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companySettingApi, companySettingId, me, mutateList]
  )

  return { error, isLoading, handleUpdateCompanySettings }
}

export const useDeleteCompanySettings = (companySettingId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateList } = useListCompanySettings()
  const companySettingApi = nocoSDK.client?.userService.companySettingApi

  const handleDeleteCompanySettings = useCallback(async () => {
    try {
      if (!auth || !me || !companySettingId) return
      setError(undefined)
      setIsLoading(true)
      await companySettingApi?.ApiFactory(auth.token).userV1CompanySettingsCompanySettingIdDelete({ companySettingId })
      mutateList()
      Toaster.success('削除完了')
    } catch (err) {
      await handleError(err, { setError, tag: 'Delete company settings' })
    } finally {
      setIsLoading(false)
    }
  }, [auth, companySettingId, me, mutateList, companySettingApi])

  return { error, isLoading, handleDeleteCompanySettings }
}

export const useSortCompanySettings = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListCompanySettings()
  const companySettingApi = nocoSDK.client?.userService.companySettingApi
  const handleSortCompanySettings = useCallback(
    async (updateSortCompanySettingRequest: UpdateSortCompanySettingRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await companySettingApi
          ?.ApiFactory(auth.token)
          .userV1CompanySettingsSortPost({ updateSortCompanySettingRequest })
        mutate()
      } catch (err) {
        await handleError(err, { setError, tag: 'Sort company setting' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, companySettingApi, me, mutate]
  )

  return { error, isLoading, handleSortCompanySettings }
}
