/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetDocumentMiscSettingResponseData,
  GetDocumentMiscSettingResponseDataFromJSON,
  GetDocumentMiscSettingResponseDataFromJSONTyped,
  GetDocumentMiscSettingResponseDataToJSON
} from './GetDocumentMiscSettingResponseData'

/**
 *
 * @export
 * @interface GetDocumentViewHistoriesGraphResponse1
 */
export interface GetDocumentViewHistoriesGraphResponse1 {
  /**
   *
   * @type {number}
   * @memberof GetDocumentViewHistoriesGraphResponse1
   */
  status?: number
  /**
   *
   * @type {GetDocumentMiscSettingResponseData}
   * @memberof GetDocumentViewHistoriesGraphResponse1
   */
  data?: GetDocumentMiscSettingResponseData
}

export function GetDocumentViewHistoriesGraphResponse1FromJSON(json: any): GetDocumentViewHistoriesGraphResponse1 {
  return GetDocumentViewHistoriesGraphResponse1FromJSONTyped(json, false)
}

export function GetDocumentViewHistoriesGraphResponse1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentViewHistoriesGraphResponse1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetDocumentMiscSettingResponseDataFromJSON(json['data'])
  }
}

export function GetDocumentViewHistoriesGraphResponse1ToJSON(
  value?: GetDocumentViewHistoriesGraphResponse1 | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetDocumentMiscSettingResponseDataToJSON(value.data)
  }
}
