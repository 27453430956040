import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { Colors } from 'src/components/atoms'

export interface SectionCardProps {
  children: ReactNode
}

export const SectionCard: React.VFC<SectionCardProps> = ({ children }) => {
  return (
    <Box
      p="10px"
      bgcolor={Colors.background.lightGray}
      border={`1px solid ${Colors.background.silver}`}
      borderRadius="4px"
    >
      {children}
    </Box>
  )
}
