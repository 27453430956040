/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ForgotOrganizationMailRequest,
  ForgotOrganizationMailRequestFromJSON,
  ForgotOrganizationMailRequestToJSON,
  ForgotPasswordMailRequest,
  ForgotPasswordMailRequestFromJSON,
  ForgotPasswordMailRequestToJSON,
  InvitedToOrganizationSignupRequest,
  InvitedToOrganizationSignupRequestFromJSON,
  InvitedToOrganizationSignupRequestToJSON,
  OrganizationSignupCheckResponse,
  OrganizationSignupCheckResponseFromJSON,
  OrganizationSignupCheckResponseToJSON,
  OrganizationSignupMailRequest,
  OrganizationSignupMailRequestFromJSON,
  OrganizationSignupMailRequestToJSON,
  OrganizationSignupRequest,
  OrganizationSignupRequestFromJSON,
  OrganizationSignupRequestToJSON,
  ResetPasswordRequest,
  ResetPasswordRequestFromJSON,
  ResetPasswordRequestToJSON,
  ResetPasswordResponse,
  ResetPasswordResponseFromJSON,
  ResetPasswordResponseToJSON,
  SigninRequest,
  SigninRequestFromJSON,
  SigninRequestToJSON,
  VerifyEmailRequest,
  VerifyEmailRequestFromJSON,
  VerifyEmailRequestToJSON
} from '../models'

export interface UserSigninRequest {
  signinRequest?: SigninRequest
}

export interface UserV1AuthInvitedToOrganizationCheckGetRequest {
  token: string
}

export interface UserV1AuthInvitedToOrganizationSignupPostRequest {
  invitedToOrganizationSignupRequest?: InvitedToOrganizationSignupRequest
}

export interface UserV1ForgotOrganizationMailPostRequest {
  forgotOrganizationMailRequest?: ForgotOrganizationMailRequest
}

export interface UserV1ForgotPasswordMailPostRequest {
  forgotPasswordMailRequest?: ForgotPasswordMailRequest
}

export interface UserV1OrganizationSignupCheckGetRequest {
  token: string
}

export interface UserV1OrganizationSignupMailPostRequest {
  organizationSignupMailRequest?: OrganizationSignupMailRequest
}

export interface UserV1OrganizationSignupPostRequest {
  organizationSignupRequest?: OrganizationSignupRequest
}

export interface UserV1ResetPasswordPostRequest {
  resetPasswordRequest?: ResetPasswordRequest
}

export interface UserV1VerifyEmailPostRequest {
  verifyEmailRequest?: VerifyEmailRequest
}

/**
 *
 */
export class ApiUserV1AuthApi extends runtime.BaseAPI {
  /**
   * チームにログインをする
   * signin
   */
  async userSigninRaw(
    requestParameters: UserSigninRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/signin`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SigninRequestToJSON(requestParameters.signinRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * チームにログインをする
   * signin
   */
  async userSignin(
    requestParameters: UserSigninRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userSigninRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームへの招待メールで得たtokenから情報を取得する
   * invited_to_organization_check
   */
  async userV1AuthInvitedToOrganizationCheckGetRaw(
    requestParameters: UserV1AuthInvitedToOrganizationCheckGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.token === null || requestParameters.token === undefined) {
      throw new runtime.RequiredError(
        'token',
        'Required parameter requestParameters.token was null or undefined when calling userV1AuthInvitedToOrganizationCheckGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.token !== undefined) {
      queryParameters['token'] = requestParameters.token
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/auth/invited_to_organization_check`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * チームへの招待メールで得たtokenから情報を取得する
   * invited_to_organization_check
   */
  async userV1AuthInvitedToOrganizationCheckGet(
    requestParameters: UserV1AuthInvitedToOrganizationCheckGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1AuthInvitedToOrganizationCheckGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームへの招待メールから新規ユーザ登録をする
   * invited_to_organization_signup
   */
  async userV1AuthInvitedToOrganizationSignupPostRaw(
    requestParameters: UserV1AuthInvitedToOrganizationSignupPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/auth/invited_to_organization_signup`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InvitedToOrganizationSignupRequestToJSON(requestParameters.invitedToOrganizationSignupRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * チームへの招待メールから新規ユーザ登録をする
   * invited_to_organization_signup
   */
  async userV1AuthInvitedToOrganizationSignupPost(
    requestParameters: UserV1AuthInvitedToOrganizationSignupPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1AuthInvitedToOrganizationSignupPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームIDを忘れた場合の通知処理をする
   * forgot_organization_mail
   */
  async userV1ForgotOrganizationMailPostRaw(
    requestParameters: UserV1ForgotOrganizationMailPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/forgot_organization_mail`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ForgotOrganizationMailRequestToJSON(requestParameters.forgotOrganizationMailRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * チームIDを忘れた場合の通知処理をする
   * forgot_organization_mail
   */
  async userV1ForgotOrganizationMailPost(
    requestParameters: UserV1ForgotOrganizationMailPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1ForgotOrganizationMailPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * パスワードを忘れた場合の再設定連絡をする
   * forgot_password_mail
   */
  async userV1ForgotPasswordMailPostRaw(
    requestParameters: UserV1ForgotPasswordMailPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/forgot_password_mail`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ForgotPasswordMailRequestToJSON(requestParameters.forgotPasswordMailRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * パスワードを忘れた場合の再設定連絡をする
   * forgot_password_mail
   */
  async userV1ForgotPasswordMailPost(
    requestParameters: UserV1ForgotPasswordMailPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1ForgotPasswordMailPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームサインアップのメールで得たtokenから情報を取得する
   * organization_signup_check
   */
  async userV1OrganizationSignupCheckGetRaw(
    requestParameters: UserV1OrganizationSignupCheckGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<OrganizationSignupCheckResponse>> {
    if (requestParameters.token === null || requestParameters.token === undefined) {
      throw new runtime.RequiredError(
        'token',
        'Required parameter requestParameters.token was null or undefined when calling userV1OrganizationSignupCheckGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.token !== undefined) {
      queryParameters['token'] = requestParameters.token
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization_signup_check`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => OrganizationSignupCheckResponseFromJSON(jsonValue))
  }

  /**
   * チームサインアップのメールで得たtokenから情報を取得する
   * organization_signup_check
   */
  async userV1OrganizationSignupCheckGet(
    requestParameters: UserV1OrganizationSignupCheckGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<OrganizationSignupCheckResponse> {
    const response = await this.userV1OrganizationSignupCheckGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームサインアップのためのメール検証
   * organization_signup_mail
   */
  async userV1OrganizationSignupMailPostRaw(
    requestParameters: UserV1OrganizationSignupMailPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization_signup_mail`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationSignupMailRequestToJSON(requestParameters.organizationSignupMailRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * チームサインアップのためのメール検証
   * organization_signup_mail
   */
  async userV1OrganizationSignupMailPost(
    requestParameters: UserV1OrganizationSignupMailPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1OrganizationSignupMailPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チームサインアップ処理
   * organization_signup
   */
  async userV1OrganizationSignupPostRaw(
    requestParameters: UserV1OrganizationSignupPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization_signup`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OrganizationSignupRequestToJSON(requestParameters.organizationSignupRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * チームサインアップ処理
   * organization_signup
   */
  async userV1OrganizationSignupPost(
    requestParameters: UserV1OrganizationSignupPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1OrganizationSignupPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * パスワードの再設定をする
   * reset_password
   */
  async userV1ResetPasswordPostRaw(
    requestParameters: UserV1ResetPasswordPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ResetPasswordResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/reset_password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ResetPasswordResponseFromJSON(jsonValue))
  }

  /**
   * パスワードの再設定をする
   * reset_password
   */
  async userV1ResetPasswordPost(
    requestParameters: UserV1ResetPasswordPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ResetPasswordResponse> {
    const response = await this.userV1ResetPasswordPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールアドレス認証
   * verify_email
   */
  async userV1VerifyEmailPostRaw(
    requestParameters: UserV1VerifyEmailPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/verify_email`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: VerifyEmailRequestToJSON(requestParameters.verifyEmailRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * メールアドレス認証
   * verify_email
   */
  async userV1VerifyEmailPost(
    requestParameters: UserV1VerifyEmailPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1VerifyEmailPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
