import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { Button, Colors, Typography } from 'src/components/atoms'
import { useLogout } from 'src/fixtures/modules/auth/hooks'
import { PopupTemplate } from 'src/components/modals'

export interface LogoutProps {}

export const Logout = (_: LogoutProps) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const { handleLogout } = useLogout()

  return (
    <>
      <Box borderBottom={`1px solid ${Colors.background.silver}`} py="16px">
        <Box display="flex" alignItems={'center'} justifyContent="space-between">
          <Box display="flex" width="150px" flexShrink={0} color={Colors.base.black}>
            <Typography fontSize="s" fontWeight="bold" lh="tight">
              ログアウト
            </Typography>
          </Box>
          <Box width={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box width={1} color={Colors.base.middleGray} pr="24px">
                <Typography fontSize="s">サービスから完全にログアウトします</Typography>
              </Box>
              <Box flexShrink={0}>
                <Button title="ログアウト" kind="attention" onClick={() => setOpenConfirm(true)} variant="outlined" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <PopupTemplate
        open={openConfirm}
        hasCancel
        cancelButtonLabel="キャンセル"
        onClose={() => setOpenConfirm(false)}
        hasConfirm
        onConfirm={() => handleLogout()}
        confirmButtonLabel="ログアウト"
        confirmButtonKind="primary"
      >
        <Box px="24px" pt="24px" pb="32px">
          <Typography fontSize="s" letterSpacing="tight" fontWeight="normal">
            ログアウトしますか？
            <br />
            サービスから完全にログアウトします
          </Typography>
        </Box>
      </PopupTemplate>
    </>
  )
}
