import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { ScheduleBillingContract } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat } from 'src/fixtures/utils/time'

export interface ScheduleBillingContractDetailProps {
  isPaid: boolean
  scheduleBillingContract: ScheduleBillingContract
}

const ContentRow = ({ label, value }: { label: string; value?: string }) => {
  return (
    <Box sx={{ display: 'flex', py: '12px', borderBottom: '1px solid #EEF1F4' }}>
      <Box sx={{ width: '260px', pr: '40px', color: Colors.base.black, boxSizing: 'border-box', flexShrink: 0 }}>
        <Typography fontSize="s" lineheight="1" color="inherit">
          {label}
        </Typography>
      </Box>
      <Box sx={{ color: Colors.base.black }}>
        <Typography fontSize="s" lineheight="1" color="inherit">
          {value || ''}
        </Typography>
      </Box>
    </Box>
  )
}

export const ScheduleBillingContractDetail = ({
  scheduleBillingContract,
  isPaid
}: ScheduleBillingContractDetailProps) => {
  const period = useMemo<string>(() => {
    const { validSince, validUntil } = scheduleBillingContract
    const convertValidSince = dateFormat(validSince, DateFormat.DATE_WITH_DAY)
    const convertValidUntil = dateFormat(validUntil, DateFormat.DATE_WITH_DAY)
    return `${convertValidSince} 〜 ${convertValidUntil}`
  }, [scheduleBillingContract])

  return (
    <Box mb="48px">
      <Box mb="16px">
        <Typography fontSize="s" fontWeight="bold" lineheight="1">
          ご契約内容
        </Typography>
      </Box>
      <Box mb="16px">
        <ContentRow label="ご契約期間" value={period} />
        <ContentRow label="ご契約日" value={dateFormat(scheduleBillingContract.contractAt, DateFormat.DATE_WITH_DAY)} />
        {isPaid && (
          <Box>
            <ContentRow
              label="次回更新日"
              value={dateFormat(scheduleBillingContract.updateContractAt, DateFormat.DATE_WITH_DAY)}
            />
            <ContentRow
              label="次回クレジットカード決済予定日"
              value={dateFormat(scheduleBillingContract.nextBillingAt, DateFormat.DATE_WITH_DAY)}
            />
          </Box>
        )}
      </Box>
      <Box mb="16px" color="#6D7784">
        <Typography fontSize="s" lineheight="1.7" color="inherit">
          ※ 本日 23:59までにお申し込み頂いた場合
        </Typography>
      </Box>
    </Box>
  )
}
