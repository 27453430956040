import {
  UserV1CompaniesGetRequest,
  UserV1ContactsGetRequest,
  UserV1DocumentsGetRequest,
  UserV1SelectorCompaniesGetRequest,
  UserV1SelectorContactListsGetRequest,
  UserV1SelectorContactsGetRequest,
  UserV1InboxItemsGetRequest,
  UserV1SitesGetRequest,
  UserV1DocumentsDocumentIdDocumentContactsGetRequest,
  UserV1SelectorDocumentsGetRequest,
  UserV1SelectorCompanyListsGetRequest,
  UserV1StaffListsGetRequest,
  UserV1SelectorSitesGetRequest,
  UserV1ChatSummariesGetRequest,
  UserV1ChatSummariesCountGetRequest,
  UserV1ChatSummariesSendedByUserGetRequest,
  UserV1ContactsEmailTransactionsSendErrorsGetRequest,
  UserV1ContactsEmailTransactionsCountGetRequest,
  UserV1SelectorChatMessagesGetRequest,
  UserV1MailTemplatesGetRequest,
  UserV1DocumentsDocumentIdOpportunityUsersUsersGetRequest,
  UserV1DocumentsDocumentIdSitesGetRequest,
  UserV1SelectorShareTargetsGetRequest,
  UserV1DocumentsDocumentIdViewHistoriesSessionsGetRequest,
  UserV1DocumentsDocumentIdViewHistoriesGetRequest,
  EnumRecordPorterHistoryOperation,
  UserV1SelectorMaterialablesGetRequest,
  UserV1ContactsContactIdListsGetRequest,
  UserV1CompaniesCompanyIdListsGetRequest
} from '@noco/http-client/lib/noco'
import { createQueryParams } from '../utils/url'

export const SWRCachePath = {
  getAuthenticate: () => 'getAuthenticate',
  getContactAuthenticate: () => 'getContactAuthenticate',
  getMagicLinkToken: () => 'getMagicLinkToken',
  getUserSignInEmail: () => 'getUserSignInEmail',
  // Visitor
  getVisitor: () => 'visitor',

  checkOrganizationSignupToken: (token?: string) => `checkOrganizationSignupToken?token=${token}`,

  getContactDocument: (id: string, siteId?: string) => `/contactDocuments/${id}?siteId=${siteId}`,
  getContactDocumentOrShare: (documentId?: string, siteId?: string, docuemntShareId?: string, siteShareId?: string) =>
    `/contactDocuments?documentId=${documentId}?siteId=${siteId}?documentShareId=${docuemntShareId}?siteShareId=${siteShareId}`,
  getContactDocumentShare: (shareId: string, siteShareId?: string) => {
    return `/contactDocumentShare/${shareId}` + siteShareId ? `?siteShareId=${siteShareId}` : ''
  },
  getContactDocumentShareForm: (documentShareId: string, siteShareId?: string) =>
    `/contactDocumentShareForm?documentShareId=${documentShareId}?siteShareId=${siteShareId}`,

  getContactSiteShareForm: (siteShareId: string) => `/contactSiteShareForm?documentShareId=${siteShareId}`,
  getSiteDocument: (id: string) => `/contactSites/${id}`,
  listCotnactDocumentOpportunityUser: (id: string) => `/listCotnactDocumentOpportunityUser/${id}`,
  // Contact
  listContacts: (params: UserV1ContactsGetRequest, cacheVariant: string) =>
    `listContacts?${createQueryParams(params)}//${cacheVariant}`,
  listSelectorContacts: (params: UserV1SelectorContactsGetRequest) =>
    `listSelectorContacts?${createQueryParams(params)}`,
  listSelectorContactsList: (params: UserV1SelectorContactListsGetRequest) =>
    `listSelectorContactsList?${createQueryParams(params)}`,
  listContactListsByContactId: (params: UserV1ContactsContactIdListsGetRequest) =>
    `listContactListsByContactId?${createQueryParams(params)}`,
  listSelectorContactsListAll: () => `listSelectorContactsList`,
  getContactOrganizationDisplaySetting: (subdomain?: string) =>
    `getContactOrganizationDisplaySetting?subdomain=${subdomain}`,

  getContact: (contactId: string) => `getContact?contactId=${contactId}`,
  getContactSiteShare: (shareId: string) => `getContactSiteShare?shareId=${shareId}`,
  listContactMaterials: (contactId: string) => `listContactMaterials?contactId=${contactId}`,
  listContactTimelines: (contactId: string) => `listContactTimelines?contactId=${contactId}`,
  listContactChatMessages: (contactId: string) => `listContactChatMessages?contactId=${contactId}`,
  listContactExpiredMaterials: (contactId: string) => `listContactExpiredMaterials?contactId=${contactId}`,
  listContactFilter: () => `listContactFilter`,
  listUserContactChatMessages: (contactId: string) => `listUserContactChatMessages?contactId=${contactId}`,
  // ContactSetting TODO: pagination
  listContactSettings: () => `listContactSettings?${createQueryParams({})}`,
  listContactLists: () => `listContactLists`,

  // Document
  getDocument: (documentId: string) => `getDocument/${documentId}`,
  listDocuments: (params: UserV1DocumentsGetRequest) => `listDocuments?${createQueryParams(params)}`,
  listDocumentContacts: (params: UserV1DocumentsDocumentIdDocumentContactsGetRequest) =>
    `listDocumentContacts?${createQueryParams(params)}`,
  listDocumentContact: (params: UserV1DocumentsDocumentIdDocumentContactsGetRequest) =>
    `listDocumentContact?${createQueryParams(params)}`,
  listDocumentViewHistories: (params: UserV1DocumentsDocumentIdViewHistoriesGetRequest) =>
    `listDocumentViewHistories?${createQueryParams(params)}`,
  listDocumentViewHistoriesGraph: (documentId: string, contactId: string, visitorSessionId?: string) =>
    `listDocumentViewHistories?documentId=${documentId}&contactId=${contactId}&visitorSessionId=${visitorSessionId}`,
  listDocumentViewHistoriesSession: (params: UserV1DocumentsDocumentIdViewHistoriesSessionsGetRequest) =>
    `listDocumentViewHistories${createQueryParams(params)}`,
  listDocumentsDocumentIdSites: (params: UserV1DocumentsDocumentIdSitesGetRequest) =>
    `listDocumentsDocumentIdSites${createQueryParams(params)}`,

  listSiteViewHistoriesGraph: (siteId: string, contactId: string) =>
    `listSiteViewHistoriesGraph?siteId=${siteId}&contactId=${contactId}`,

  listDocumentOpportunityUsers: (params: UserV1DocumentsDocumentIdOpportunityUsersUsersGetRequest) =>
    `listDocumentOpportunityUsers?${createQueryParams(params)}`,
  listDocumentRegisteredOpportunityUsers: (documentId: string) =>
    `listDocumentRegisteredOpportunityUsers?documentId=${documentId}`,
  listSelectorDocuments: (params: UserV1SelectorDocumentsGetRequest) =>
    `listSelectorDocuments?${createQueryParams(params)}`,
  documentsFilter: () => `documentsFilter`,
  getDocumentMiscSetting: (documentId: string) => `getDocumentMiscSetting/${documentId}`,

  // Site
  getSite: (siteId: string) => `getSite?siteId=${siteId}`,
  listSiteContact: (siteId: string) => `listSiteContact?siteId=${siteId}`,
  listSites: (params: UserV1SitesGetRequest) => `listSites?${createQueryParams(params)}`,
  sitesFilter: () => `sitesFilter`,
  updateCoverPhoto: (siteId: string) => `updateCoverPhoto?${createQueryParams(siteId)}`,
  listSiteSection: (siteId: string) => `listSiteSection?siteId=${siteId}`,
  listSitesSiteIdDocuments: (siteId: string) => `listSitesSiteIdDocuments?siteId=${siteId}`,
  listSelectorSites: (params: UserV1SelectorSitesGetRequest) => `listSelectorSites?${createQueryParams(params)}`,
  getSiteMiscSetting: (siteId: string) => `getSiteMiscSetting/${siteId}`,

  // Me
  getMe: () => 'getMe',
  getMeProfile: () => 'getMeProfile',
  getEmailNotificationSetting: () => 'getEmailNotificationSetting',

  // Organization
  getOrganization: () => `getOrganization`,
  listOrganizationUsers: () => `listOrganizationUsers`,
  listOrganizationAdministrators: () => `listOrganizationAdministrators`,
  listOrganizationUserEmailSettings: () => `listOrganizationUserEmailSettings`,
  getOrganizationUserChatSettings: () => `listOrganizationUserEmailSettings`,

  // Organization Payment
  listOrganizationPaymentPlans: () => `listOrganizationPaymentPlans`,
  getOrganizationPaymentCurrentPlan: () => `getOrganizationPaymentCurrentPlan`,
  getOrganizationPaymentnAddonUserOption: () => `getOrganizationPaymentnAddonUserOption`,
  getOrganizationPaymentBillingInfo: () => `getOrganizationPaymentBillingInfo`,
  getPlanCheckUsageLimit: (planId?: string) => `getPlanCheckUsageLimit?planId=${planId}`,
  listOrganizationPaymentPlanTransactions: () => `listOrganizationPaymentPlanTransactions`,
  listOrganizationPaymentBillingTransactions: () => `listOrganizationPaymentBillingTransactions`,

  userInfoFromToken: (token: string) => `userInfoFromToken${token}`,

  listCompanies: (params: UserV1CompaniesGetRequest, cacheVariant: string) =>
    `listCompanies?${createQueryParams(params)}//${cacheVariant}`,
  listSelectorCompanies: (params: UserV1SelectorCompaniesGetRequest) =>
    `listSelectorCompanies${createQueryParams(params)}`,
  listSelectorCompaniesList: (params: UserV1SelectorCompanyListsGetRequest) =>
    `listSelectorCompaniesList?${createQueryParams(params)}`,
  listSelectorCompaniesListAll: () => `listSelectorCompaniesList`,

  getCompany: (companyId: string) => `getCompany?companyId=${companyId}`,
  listCompanyTimelines: (companyId: string) => `listCompanyTimelines?companyId=${companyId}`,
  listCompanySettings: () => `listCompanySettings?${createQueryParams({})}`,
  listCompanyFilter: () => `listCompanyFilter`,
  listCompanyLists: () => `listCompanyLists`,
  listCompanyListsByCompanyId: (params: UserV1CompaniesCompanyIdListsGetRequest) =>
    `listCompanyListsByCompanyId?${createQueryParams(params)}`,

  // Inbox
  listInboxItems: (params: UserV1InboxItemsGetRequest) => `listInboxItems?${createQueryParams(params)}`,
  // sendErrorItems: (params: UserV1SendErrorsGetRequest) => `sendErrorItems?${createQueryParams(params)}`,
  inboxFilter: () => `inboxFilter`,
  listSelectorInboxChats: (params: UserV1SelectorChatMessagesGetRequest) =>
    `listSelectorInboxChats?${createQueryParams(params)}`,

  listPolicySettings: () => `listPolicySettings`,

  getDefaultImage: (division: string) => `defaultImage?${division}`,

  listSelectorUsersList: (params: UserV1ContactsGetRequest) => `listSelectorUsersList?${createQueryParams(params)}`,
  listSelectorShareTargets: (params: UserV1SelectorShareTargetsGetRequest) =>
    `listSelectorShareTargets?${createQueryParams(params)}`,

  // job
  getJob: (jobId: string) => `getJob?jobId=${jobId}`,

  // template
  getTemplate: () => `getTemplate`,

  // material
  listMaterialDocumentsLists: () => `listMaterialDocumentsLists`,
  listMaterialSitesLists: () => `listMaterialSitesLists`,
  listSelectorMaterialables: (params: UserV1SelectorMaterialablesGetRequest) =>
    `listSelectorMaterialables?${createQueryParams(params)}`,

  // staff
  listStaffLists: (params: UserV1StaffListsGetRequest) => `listStaffLists?${createQueryParams(params)}`,

  // chatSummries
  listChatSummaries: (params: UserV1ChatSummariesGetRequest) => `listChatSummaries?${createQueryParams(params)}`,
  listChatSummariesCount: (params: UserV1ChatSummariesCountGetRequest) =>
    `listChatSummariesCount?${createQueryParams(params)}`,
  listChatSummariesSendedByUser: (params: UserV1ChatSummariesSendedByUserGetRequest) =>
    `listChatSummariesSendedByUser?${createQueryParams(params)}`,

  // contactsEmailTransactionsSendErrors
  listSendErrors: (params: UserV1ContactsEmailTransactionsSendErrorsGetRequest) =>
    `listSendErrors?${createQueryParams(params)}`,
  listSendErrorsCount: (params: UserV1ContactsEmailTransactionsCountGetRequest) =>
    `listSendErrorsCount?${createQueryParams(params)}`,

  // mailtemplate
  listMailTemplate: (params: UserV1MailTemplatesGetRequest) => `listMailTemplate?${createQueryParams(params)}`,
  getMailTemplate: (mailTemplateId?: string) => `getMailTemplate?mailTemplateId=${mailTemplateId}`,
  bulkGetMailTemplates: (mailTemplateIds: string[]) => `getMailTemplate?mailTemplateIds=[${mailTemplateIds.join(',')}]`,
  listSelectorMailTemplates: (params: UserV1SelectorSitesGetRequest) =>
    `listSelectorMailTemplates?${createQueryParams(params)}`,
  getMailTemplateVariables: () => `getMailTemplateVariables`,

  getOrganizationPolicySetting: (subdomain: string) => `policySettings?subdomain=${subdomain}`,
  getPlanLimit: (planId?: string) => `getPlanLimit?planId=${planId}`,

  listDocumentLabels: () => `listDocumentLabels`,

  // porter
  listRecordPorterHistory: () => `listRecordPorterHistory`,
  getPorterHistoriesLatest: (operation?: EnumRecordPorterHistoryOperation) => `getPorterHistoriesLatest=${operation}`
}
