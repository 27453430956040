import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var UtilKenallApi = /** @class */ (function () {
    function UtilKenallApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    UtilKenallApi.initialize = function (basePath, fetchApi) {
        if (!UtilKenallApi.instance) {
            UtilKenallApi.instance = new UtilKenallApi(basePath, fetchApi);
        }
        return UtilKenallApi.instance;
    };
    UtilKenallApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1UtilKenallApi(new Configuration(config));
    };
    return UtilKenallApi;
}());
export { UtilKenallApi };
