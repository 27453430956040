import { Box, CircularProgress } from '@material-ui/core'
import { Button, Message, Typography } from 'src/components/atoms'
import { center } from '../../styles'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { differenceInDays } from 'date-fns'
import { useMemo } from 'react'
import { OrganizationPlanDetailGradeEnum } from '@noco/http-client/lib/noco'

export interface StepBeforeStartProps {
  onNext: () => void
}
export const StepBeforeStart = ({ onNext }: StepBeforeStartProps) => {
  const { data } = useGetOrganizationPaymentCurrentPlan()
  const plan = data?.plan
  const contract = data?.contract

  const day = useMemo(() => {
    if (contract) {
      return differenceInDays(contract.endAt, new Date())
    }
    return 0
  }, [contract])

  const isFreePlan = useMemo<boolean>(() => {
    return plan?.grade === OrganizationPlanDetailGradeEnum.Free
  }, [plan?.grade])

  const cautionText = useMemo(() => {
    return isFreePlan
      ? `ご解約手続きを完了することで、サービスはご利用いただけなくなります\nご解約後、ご利用していたアカウントおよびデータの復元は出来ません\nご解約日から翌々月に、すべてのデータを完全削除いたします`
      : `ご解約手続きを完了することで、サービスのご解約となります\nご契約の最終日までご利用いただけますが、下記の点にご留意ください\n\n・ご契約終了後、ご利用していたアカウント及びデータの復元はできません\n・ご契約終了日から翌々月に、すべてのデータを完全削除いたします`
  }, [isFreePlan])

  return plan ? (
    <Box width={'100%'}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: '16px' }}>
        <Typography variant="h2" fontWeight="bold" fontSize="xl">
          ご解約する前にご確認ください
        </Typography>
      </Box>

      {/* MEMO: contract があるときには有料プランを契約中なので残日数のメッセージを出す */}
      {contract && (
        <Message type="error">
          <Typography fontSize="s" fontWeight="bold">
            {plan.name}は、残り{day}日ご利用可能です
          </Typography>
        </Message>
      )}

      <Box height="16px" />

      <Message type="warn">
        <Typography fontWeight="bold" fontSize="s" style={{ whiteSpace: 'pre-wrap' }}>
          {cautionText}
        </Typography>
      </Message>

      <Box height="24px" />

      <Box sx={{ ...center, mt: '24px' }}>
        <Button onClick={() => onNext()} title={'ご解約のお手続きへ進む'} />
      </Box>

      <Box height="48px" />
    </Box>
  ) : (
    <Box sx={{ ...center }}>
      <CircularProgress />
    </Box>
  )
}
