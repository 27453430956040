/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { UserProfile, UserProfileFromJSON, UserProfileFromJSONTyped, UserProfileToJSON } from './UserProfile'

/**
 *
 * @export
 * @interface GetMeProfileResponseData
 */
export interface GetMeProfileResponseData {
  /**
   *
   * @type {UserProfile}
   * @memberof GetMeProfileResponseData
   */
  userProfile?: UserProfile
}

export function GetMeProfileResponseDataFromJSON(json: any): GetMeProfileResponseData {
  return GetMeProfileResponseDataFromJSONTyped(json, false)
}

export function GetMeProfileResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMeProfileResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userProfile: !exists(json, 'user_profile') ? undefined : UserProfileFromJSON(json['user_profile'])
  }
}

export function GetMeProfileResponseDataToJSON(value?: GetMeProfileResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_profile: UserProfileToJSON(value.userProfile)
  }
}
