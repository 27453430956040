/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  MaterialableViewHistorySession,
  MaterialableViewHistorySessionFromJSON,
  MaterialableViewHistorySessionFromJSONTyped,
  MaterialableViewHistorySessionToJSON
} from './MaterialableViewHistorySession'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetDocumentViewHistorySessionsResponseData
 */
export interface GetDocumentViewHistorySessionsResponseData {
  /**
   *
   * @type {Array<MaterialableViewHistorySession>}
   * @memberof GetDocumentViewHistorySessionsResponseData
   */
  viewHistorySessions?: Array<MaterialableViewHistorySession>
  /**
   *
   * @type {PageInfo}
   * @memberof GetDocumentViewHistorySessionsResponseData
   */
  pageInfo?: PageInfo
}

export function GetDocumentViewHistorySessionsResponseDataFromJSON(
  json: any
): GetDocumentViewHistorySessionsResponseData {
  return GetDocumentViewHistorySessionsResponseDataFromJSONTyped(json, false)
}

export function GetDocumentViewHistorySessionsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentViewHistorySessionsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    viewHistorySessions: !exists(json, 'view_history_sessions')
      ? undefined
      : (json['view_history_sessions'] as Array<any>).map(MaterialableViewHistorySessionFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetDocumentViewHistorySessionsResponseDataToJSON(
  value?: GetDocumentViewHistorySessionsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    view_history_sessions:
      value.viewHistorySessions === undefined
        ? undefined
        : (value.viewHistorySessions as Array<any>).map(MaterialableViewHistorySessionToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
