import { Box } from '@material-ui/core'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { ModalFolderCreate } from 'src/components/modals'
import { SelectTableRow, CustomButtonListType } from 'src/components/molecules'
import { PopupTemplate } from 'src/components/modals'
import { Header, SubSideFolderList, Table, TableProps, useTablePagination } from 'src/components/organisms'
import { DatetimeCell, SiteNameCell, NameCell, PublicationStatusCell } from 'src/components/organisms/Table/TableParts'
import { useListSites, useListSiteFilter, useArchiveSite, useCreateSite } from 'src/fixtures/modules/site/hooks'
import { Site, EnumPublicationStatus, UserWithToken } from '@noco/http-client/lib/noco'
import { useRouter } from 'next/router'
import { UserV1SitesGetRequest } from '@noco/http-client/lib/noco'
import dynamic from 'next/dynamic'
const DynamicTable = dynamic(() => import('../../organisms/Table'), { ssr: false }) as typeof Table

interface SiteTableRow {
  id: string | undefined
  name: string | null | undefined
  author: {
    firstName: string
    lastName: string
    avatar: string | UserWithToken['avatar']
  }
  createdAt: string | undefined
  publicationStatus: EnumPublicationStatus | undefined
  description: string | null | undefined
}

const columns = [
  {
    name: 'name',
    title: 'サイト名',
    width: 250,
    fixed: true,
    render: SiteNameCell
  },
  {
    name: 'author',
    width: 126,
    title: '作成者',
    render: NameCell
  },
  { name: 'createdAt', width: 157, title: '作成日', render: DatetimeCell },
  { name: 'publicationStatus', width: 86, title: '公開状態', render: PublicationStatusCell },
  { name: 'description', width: 168, title: 'メモ' }
]

export const PageMultiDocuments = ({ mode }: { mode: 'normal' | 'archive' }) => {
  const { page, per, totalPages, setTotalPages, changePage, changePerPage, resetPagination } =
    useTablePagination('multi-document')
  const [confirmOpenModal, setConfirmOpenModal] = useState(false)
  const [isOpenModalFolderCreate, setIsOpenModalFolderCreate] = useState<boolean>(false)

  const handleConfirmCreate = useCallback((name: string) => {
    alert(name)
  }, [])
  const handleCloseModalFolderCreate = useCallback(() => {
    setIsOpenModalFolderCreate(false)
  }, [])

  const [siteName, params] = useMemo(() => {
    const params: UserV1SitesGetRequest = {
      page: page + 1,
      per,
      isArchived: mode === 'archive' ? true : false,
      orderBy: 'created_at-desc'
    }
    const siteName = mode === 'archive' ? 'すべて' : 'アーカイブ'
    return [siteName, params]
  }, [mode, page, per])

  const { data: siteData, mutate: mutateSiteList } = useListSites(params)
  const { data: filterData } = useListSiteFilter()
  const { handleSaveArchived, isLoading: isLoadingArchive } = useArchiveSite(params)
  const { handleCreateMultiDocuments, isLoading: isLoadingCreateSite } = useCreateSite()
  const siteList = siteData?.sites

  const countNormal = filterData?.filter?.normal?.counts ?? 0
  const countArchive = filterData?.filter?.archived?.counts ?? 0
  const folderList = {
    normal: { path: '/multi-documents', count: countNormal },
    archive: {
      path: '/multi-documents?mode=archive',
      count: countArchive
    }
  }
  const router = useRouter()
  //TODO: 正式版以降 クエリパラメータで取得 https://github.com/nocoinc/noco-client/issues/959
  const currentPage = router.asPath

  const [selection, setSelection] = useState<Array<number>>([])

  const rows: SiteTableRow[] = useMemo(
    () => (siteList ? siteList.map(site => convertSiteToTableRow(site)) : []),
    [siteList]
  )
  const selectedIds = useMemo<string[]>(() => {
    return selection.map(rowNumber => rows?.[rowNumber]?.id as string)
  }, [selection, rows])

  useEffect(() => {
    setSelection([])
  }, [router.asPath])

  const handleClickArchiveCloseButton = useCallback(() => {
    setSelection([])
  }, [])
  const handleCloseModal = useCallback(() => {
    setConfirmOpenModal(false)
    setSelection([])
  }, [])

  const handleClickModalOkButton = useCallback(
    (isArchived: boolean) => () => {
      if (!selectedIds || selectedIds.length == 0) {
        return
      }
      handleSaveArchived(selectedIds, isArchived)
      handleCloseModal()
    },
    [selectedIds, handleSaveArchived, handleCloseModal]
  )

  const handleCreateSite = useCallback(() => {
    handleCreateMultiDocuments({})
  }, [handleCreateMultiDocuments])

  const customButtonList: CustomButtonListType[] = useMemo(() => {
    if (mode === 'archive') {
      return [{ buttonTitle: 'アーカイブから戻す', onConfirm: () => setConfirmOpenModal(true) }]
    } else;
    return [{ showIcon: 'archive', buttonTitle: 'アーカイブ', onConfirm: () => setConfirmOpenModal(true) }]
  }, [mode])

  const showDialog = useMemo(() => {
    return (
      <Box position="absolute" bottom="60px" px="16px" width={1} zIndex={999}>
        <SelectTableRow
          count={selectedIds.length}
          onClose={handleClickArchiveCloseButton}
          customButtonList={customButtonList}
        />
      </Box>
    )
  }, [selectedIds, handleClickArchiveCloseButton, customButtonList])

  const isShowTable = useMemo(() => {
    return !isLoadingCreateSite && !isLoadingArchive
  }, [isLoadingArchive, isLoadingCreateSite])

  useEffect(() => {
    resetPagination()
    mutateSiteList()
  }, [mode, resetPagination, mutateSiteList])

  useEffect(() => {
    if (siteData?.pageInfo?.totalPages && siteData?.pageInfo?.totalPages !== totalPages) {
      setTotalPages(siteData?.pageInfo?.totalPages)
    }
  }, [siteData?.pageInfo?.totalPages, setTotalPages, totalPages])

  return (
    <Layout>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box bgcolor={Colors.functional.background.default} borderBottom={`1px solid ${Colors.background.silver}`}>
          <Header
            iconSize="28px"
            icon="computer"
            title="資料サイト"
            rightSection={
              <Button
                title="資料サイトの登録"
                startIcon={<DynamicMuiIcon variant="add" />}
                onClick={handleCreateSite}
              />
            }
          />
        </Box>
        <Box height="100%" flexGrow={1} display="flex" overflow="hidden">
          <Box
            flexShrink={0}
            width="174px"
            bgcolor={Colors.functional.background.default}
            height={1}
            borderRight={`1px solid ${Colors.background.silver}`}
          >
            <SubSideFolderList folderList={folderList} currentPage={currentPage} />
          </Box>
          <Box width={1} height={1} pt="16px" overflow="auto" position="relative">
            {isShowTable && (
              <DynamicTable
                columns={columns}
                rows={rows}
                onClickRow={row => router.push('/multi-documents/[multiDocumentId]', `/multi-documents/${row.id}`)}
                selectable={true}
                selection={selection}
                setSelection={setSelection as TableProps<SiteTableRow>['setSelection']}
                page={page}
                per={per}
                totalPages={totalPages}
                changePage={page => {
                  changePage(page)
                  setSelection([])
                }}
                changePerPage={perPage => {
                  changePerPage(perPage)
                  setSelection([])
                }}
                numberOfRows={siteData?.pageInfo?.totalCount}
                showTotalCount
              />
            )}
            {selectedIds.length > 0 && showDialog}
          </Box>
        </Box>
      </Box>
      {/* NOTE: 現状未使用だが、フォルダ実装の時に使用 */}
      <ModalFolderCreate
        open={isOpenModalFolderCreate}
        onClose={handleCloseModalFolderCreate}
        onCancel={handleCloseModalFolderCreate}
        onConfirm={handleConfirmCreate}
      />
      <PopupTemplate
        open={confirmOpenModal}
        title={`資料サイトを${customButtonList[0].buttonTitle}`}
        subTitle={`${selectedIds.length}件  選択中`}
        onClose={handleCloseModal}
        hasClose
        hasCancel
        hasConfirm
        onConfirm={handleClickModalOkButton(mode === 'archive')}
        cancelButtonLabel="キャンセル"
        confirmButtonLabel="OK"
      >
        <Box p="24px">
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {`選択した資料サイトを${siteName}へ移動します\n本当によろしいですか？`}
          </Typography>
        </Box>
      </PopupTemplate>
    </Layout>
  )
}

const convertSiteToTableRow = (site: Site): SiteTableRow => {
  const { id, title, user, createdAt, publicationStatus, memo } = site
  return {
    id: id,
    name: title,
    author: {
      firstName: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      avatar: user ? user.avatar : ''
    },
    createdAt: createdAt,
    publicationStatus: publicationStatus,
    description: memo
  }
}
