import { Box, ButtonBase } from '@material-ui/core'
import { ShallowCompanyList } from '@noco/http-client/lib/noco'
import React, { useCallback, useState } from 'react'
import { Button, DynamicMuiIcon, Pallete, Typography } from 'src/components/atoms'
import { PopupTemplate } from 'src/components/modals'
import { useRemoveCompanyListCompanyId, useListCompanyListsByCompanyId } from 'src/fixtures/modules/company/hooks'
import { CompanyListModal } from './Modal'

export interface CompanyListProps {
  companyId: string
  companyName: string
}

export const CompanyList = ({ companyId, companyName }: CompanyListProps) => {
  const [deleteListItem, setDeleteListItem] = useState<ShallowCompanyList>()
  const { handleRemoveCompanyListCompanyId } = useRemoveCompanyListCompanyId({ page: 1, per: 9999 })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { data, mutate } = useListCompanyListsByCompanyId({
    companyId
  })
  const onCloseDeleteModal = useCallback(() => {
    setDeleteListItem(undefined)
  }, [])
  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [])
  const onDelete = useCallback(async () => {
    if (!deleteListItem) return
    await handleRemoveCompanyListCompanyId(deleteListItem.id, [companyId])
    mutate()
    onCloseDeleteModal()
  }, [deleteListItem, handleRemoveCompanyListCompanyId, mutate, onCloseDeleteModal, companyId])

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="8px">
        <Typography fontSize="s" lineheight="14px" fontWeight="bold">
          掲載先のリスト
        </Typography>

        <Button
          style={{ padding: '0px 6px' }}
          size="small"
          kind="secondary"
          variant="outlined"
          startIcon={<DynamicMuiIcon variant="add" size="16px" color="inherit" />}
          title="リストに追加"
          onClick={onOpen}
          starticonmargin="0px 4px 0px 0px"
        />
      </Box>

      <Box display="flex" flexDirection="column">
        {data?.companyLists.map(item => (
          <ButtonBase
            key={item.id}
            onClick={() => {
              setDeleteListItem(item)
            }}
          >
            <Box
              height="40px"
              width={1}
              display="flex"
              alignItems="center"
              boxShadow="inset 0px -1px 0px #EEF1F4"
              justifyContent="space-between"
              bgcolor={Pallete.functional.background.default}
            >
              <Typography fontSize="s" lh="tight" letterSpacing="normal">
                {item.name}
              </Typography>
              <Box ml="4px" />
              <DynamicMuiIcon variant="close" size="20px" />
            </Box>
          </ButtonBase>
        ))}
      </Box>

      {deleteListItem && (
        <PopupTemplate
          open={!!deleteListItem}
          title={`掲載先のリストを削除`}
          onClose={onCloseDeleteModal}
          hasClose
          hasCancel
          hasConfirm
          onConfirm={onDelete}
          cancelButtonLabel="キャンセル"
          confirmButtonLabel="OK"
        >
          <Box p="24px">
            <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
              {`『${deleteListItem.name}』から、${companyName} を取り除きます。\n本当によろしいですか？`}
            </Typography>
          </Box>
        </PopupTemplate>
      )}
      {isOpen && <CompanyListModal companyId={companyId} isOpen={isOpen} setIsOpen={setIsOpen} mutateList={mutate} />}
    </Box>
  )
}
