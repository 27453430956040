/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Company, CompanyFromJSON, CompanyFromJSONTyped, CompanyToJSON } from './Company'
import {
  CompanySetting,
  CompanySettingFromJSON,
  CompanySettingFromJSONTyped,
  CompanySettingToJSON
} from './CompanySetting'

/**
 *
 * @export
 * @interface UpdateCompanyResponseData
 */
export interface UpdateCompanyResponseData {
  /**
   *
   * @type {Company}
   * @memberof UpdateCompanyResponseData
   */
  company?: Company
  /**
   *
   * @type {Array<CompanySetting>}
   * @memberof UpdateCompanyResponseData
   */
  companySettings?: Array<CompanySetting>
}

export function UpdateCompanyResponseDataFromJSON(json: any): UpdateCompanyResponseData {
  return UpdateCompanyResponseDataFromJSONTyped(json, false)
}

export function UpdateCompanyResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCompanyResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    company: !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
    companySettings: !exists(json, 'company_settings')
      ? undefined
      : (json['company_settings'] as Array<any>).map(CompanySettingFromJSON)
  }
}

export function UpdateCompanyResponseDataToJSON(value?: UpdateCompanyResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company: CompanyToJSON(value.company),
    company_settings:
      value.companySettings === undefined ? undefined : (value.companySettings as Array<any>).map(CompanySettingToJSON)
  }
}
