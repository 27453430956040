import { Box } from '@material-ui/core'
import React from 'react'
import { ToggleSwitch, Typography } from 'src/components/atoms'
import { ShareLinkFormSection } from 'src/components/molecules/ShareLinkFormSection'

export interface ShareLinkFormAuthCodeSectionProps {
  isActive: boolean
  disabled: boolean
  onChangeActive: (isActive: boolean) => void
}

export const ShareLinkFormAuthCodeSection = ({
  isActive,
  disabled,
  onChangeActive
}: ShareLinkFormAuthCodeSectionProps) => {
  return (
    <>
      <ShareLinkFormSection>
        <Box display="flex" alignItems="flex-start">
          <Box width={1} mr="16px">
            <Box sx={{ display: 'flex', alignItems: 'center', mb: '12px' }}>
              <ToggleSwitch
                checked={isActive}
                disabled={disabled}
                onChange={() => {
                  onChangeActive(!isActive)
                }}
              />
              <Box width="12px" />
              <Typography variant="h6" fontSize="s" lineheight="14px" letterSpacing="none">
                セキュリティコードの設定
              </Typography>
            </Box>
            <Typography fontSize="s" lh="tight" letterSpacing="default">
              閲覧者が資料を開封した時に、本人認証のためのパスコードを表示します。
              <br />
              *設定を「ON」にするには、【顧客情報の入力フォーム設定】を「ON」にする必要があります。
            </Typography>
          </Box>
        </Box>
      </ShareLinkFormSection>
    </>
  )
}
