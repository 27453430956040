import {
  UpdateSiteRequest,
  UserV1SitesSiteIdDefaultPhotoPutRequest,
  UserV1SitesSiteIdUploadCoverPhotoPostRequest,
  UserV1SitesGetRequest,
  ArchiveSitesRequest,
  UserV1SitesSiteIdSectionsSiteSectionIdDeleteRequest,
  UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRequest,
  UpdateSiteSectionRequest,
  SortSiteSectionRequest,
  CreateSiteSectionRequest,
  CreateSiteSectionDocumentRequest,
  CreateSiteRequest,
  ShareByLinkSiteRequest,
  ShareToContactSiteRequest,
  UserV1SitesSiteIdSiteContactsSiteContactIdPutRequest,
  UpdateSiteMiscSettingRequest,
  SiteContact
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'
import { useRouter } from 'next/router'
import { SortSiteSectionDocumentRequest, UpdateSiteSectionDocumentRequest } from '@noco/http-client/lib/noco'
import { useListContactExpiredMaterials, useListContactMaterials } from '../contact/hooks'

export const useListSites = (params: UserV1SitesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const apiSite = nocoSDK.client?.userService.siteApi
  const funcSite = useCallback(async () => {
    if (!auth) return

    return await apiSite
      ?.ApiFactory(auth.token)
      .userV1SitesGet(params, { cache: 'no-store' })
      .then(res => res.data)
  }, [auth, params, apiSite])
  return useSWR(auth?.token && SWRCachePath.listSites(params), funcSite)
}

export const useListSiteFilter = () => {
  const { data: auth } = useAuthenticate()
  const apiFilter = nocoSDK.client?.userService.filterApi

  const funcFilter = useCallback(async () => {
    if (!auth) return

    return await apiFilter
      ?.ApiFactory(auth.token)
      .userV1SiteFilterGet()
      .then(res => res.data)
  }, [auth, apiFilter])
  return useSWR(auth?.token && SWRCachePath.sitesFilter(), funcFilter)
}

export const useArchiveSite = (params: UserV1SitesGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateListSite } = useListSites(params)
  const { mutate: mutateSitesFilter } = useListSiteFilter()
  const archiveApi = nocoSDK.client?.userService.siteApi

  const handleSaveArchived = useCallback(
    async (ids: ArchiveSitesRequest['ids'], isArchived: boolean) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await archiveApi
          ?.ApiFactory(auth.token)
          .userV1SitesArchivePut({ archiveSitesRequest: { archive: !isArchived, ids } })
        await mutateListSite(), mutateSitesFilter()
        Toaster.success(`資料サイトをアーカイブ${isArchived ? 'から移動' : ''}しました`)
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive site' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, archiveApi, mutateListSite, mutateSitesFilter]
  )
  return { error, isLoading, handleSaveArchived }
}

export const useGetSite = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const siteApi = nocoSDK.client?.userService.siteApi
  const func = useCallback(async () => {
    if (!auth) return

    return await siteApi
      ?.ApiFactory(auth.token)
      .userV1SitesSiteIdGet({ siteId })
      .then(res => res.data)
  }, [auth, siteId, siteApi])
  return useSWR(auth?.token && SWRCachePath.getSite(siteId), func)
}

export const useListSiteContacts = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const siteApi = nocoSDK.client?.userService.siteApi
  const func = useCallback(async () => {
    if (!auth || !siteId) return

    return await siteApi
      ?.ApiFactory(auth.token)
      .userV1SitesSiteIdSiteContactsGet({ siteId })
      .then(res => res.data)
  }, [auth, siteId, siteApi])
  return useSWR(auth?.token && siteId ? SWRCachePath.listSiteContact(siteId) : null, func)
}

export const useCreateSite = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const router = useRouter()

  const handleCreateMultiDocuments = useCallback(
    async (createSiteRequest: CreateSiteRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        const res = await nocoSDK.client?.userService.siteApi
          ?.ApiFactory(auth.token)
          .userV1SitesPost({ createSiteRequest })
        const multiDocumentId = res?.data?.site?.id!
        router.push('/multi-documents/[multiDocumentId]/setting', `/multi-documents/${multiDocumentId}/setting`)
        Toaster.success('資料サイトを作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create site' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, router]
  )

  return { error, isLoading, handleCreateMultiDocuments }
}

export const useUpdateSite = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateSite } = useGetSite(siteId)
  const siteApi = nocoSDK.client?.userService.siteApi

  const handleUpdateSite = useCallback(
    async (updateSiteRequest: UpdateSiteRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdPut({ siteId, updateSiteRequest })
        await mutateSite()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update site' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, siteId, mutateSite]
  )
  return { error, isLoading, handleUpdateSite }
}

export const useUpdateCoverPhoto = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate: mutateSite } = useGetSite(siteId)
  const handleUpdateCoverPhoto = useCallback(
    async (updateDefaultImageRequest: UserV1SitesSiteIdDefaultPhotoPutRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        const res = await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdDefaultPhotoPut(updateDefaultImageRequest)
        await mutateSite(res?.data)
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'update cover photo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutateSite]
  )
  return { error, isLoading, handleUpdateCoverPhoto }
}

export const useCreateCoverPhoto = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate: mutateSite } = useGetSite(siteId)
  const handleCreateCoverPhoto = useCallback(
    async (createCoverPhotoRequest: UserV1SitesSiteIdUploadCoverPhotoPostRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdUploadCoverPhotoPost(createCoverPhotoRequest)
        await mutateSite()
        Toaster.success('アップロードしました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create cover photo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutateSite]
  )
  return { error, isLoading, handleCreateCoverPhoto }
}

export const useArchiveSiteDetail = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [isPosted, setIsPosted] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutateGetSite } = useGetSite(siteId)
  const siteApi = nocoSDK.client?.userService.siteApi

  const handleSaveArchivedSiteDetail = useCallback(
    async (ids: ArchiveSitesRequest['ids'], isArchived: boolean) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi
          ?.ApiFactory(auth.token)
          .userV1SitesArchivePut({ archiveSitesRequest: { archive: !isArchived, ids } })
        await mutateGetSite()
        Toaster.success(`資料サイトをアーカイブ${isArchived ? 'から移動' : ''}しました`)
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive site' })
      } finally {
        setIsLoading(false)
        setIsPosted(true)
      }
    },
    [auth, siteApi, mutateGetSite]
  )
  return { error, isLoading, handleSaveArchivedSiteDetail, isPosted }
}

export const useListSiteSection = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const siteApi = nocoSDK.client?.userService.siteApi
  const func = useCallback(async () => {
    if (!auth) return

    return await siteApi
      ?.ApiFactory(auth.token)
      .userV1SitesSiteIdSectionsGet({ siteId })
      .then(res => res.data)
  }, [auth, siteId, siteApi])
  return useSWR(auth?.token && SWRCachePath.listSiteSection(siteId), func)
}

export const useCreateSiteSection = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: mutateSiteSection } = useListSiteSection(siteId)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const handleCreateSiteSection = useCallback(
    async (createSiteSectionRequest: CreateSiteSectionRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdSectionsPost({ siteId, createSiteSectionRequest })
        await mutateSiteSection()
        Toaster.success('セクションを作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create Site Section' })
      } finally {
        setIsLoading(true)
      }
    },
    [auth, siteApi, mutateSiteSection, siteId]
  )
  return { error, isLoading, handleCreateSiteSection }
}

export const useUpdateSiteSection = (siteId: string, siteSectionId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate: mutateSiteSection } = useListSiteSection(siteId)
  const handleUpdateSiteSection = useCallback(
    async (updateSiteSectionRequest: UpdateSiteSectionRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        const res = await siteApi
          ?.ApiFactory(auth.token)
          .userV1SitesSiteIdSectionsSiteSectionIdPut({ siteId, siteSectionId, updateSiteSectionRequest })
        await mutateSiteSection(res?.data)
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'update site section' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutateSiteSection, siteId, siteSectionId]
  )
  return { error, isLoading, handleUpdateSiteSection }
}

export const useUpdatePublish = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate: mutateSite } = useGetSite(siteId)
  const handleUpdatePublish = useCallback(
    async (siteId: string, publish: boolean) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdPublishPut({ siteId, publishSiteRequest: { publish } })
        await mutateSite()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update published' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutateSite]
  )
  return { error, isLoading, handleUpdatePublish }
}

export const useUpdateSiteSectionSort = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate: mutateSiteSection } = useListSiteSection(siteId)
  const handleUpdateSiteSectionSort = useCallback(
    async (sortSiteSectionRequest: SortSiteSectionRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdSectionsSortPut({ siteId, sortSiteSectionRequest })
        await mutateSiteSection()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'update site section sort' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutateSiteSection, siteId]
  )
  return { error, isLoading, handleUpdateSiteSectionSort }
}

export const useDeleteSiteSection = (params: UserV1SitesSiteIdSectionsSiteSectionIdDeleteRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate } = useListSiteSection(params.siteId)
  const handleDeleteSiteSection = useCallback(async () => {
    try {
      if (!auth) return
      setIsLoading(true)
      setError(undefined)
      await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdSectionsSiteSectionIdDelete(params)
      await mutate()
      Toaster.success('セクションを削除しました')
    } catch (err) {
      await handleError(err, { setError, tag: 'Delete Site section' })
    } finally {
      setIsLoading(false)
    }
  }, [auth, mutate, siteApi, params])

  return { error, isLoading, handleDeleteSiteSection }
}

export const useCreateSiteSectionDocument = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: mutateSiteSectionDocument } = useListSiteSection(siteId)
  const [error, setError] = useState<Error | undefined>()
  const [instructions, setInstructions] = useState<string[] | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const handleCreateSiteSectionDocument = useCallback(
    async (createSiteSectionDocumentRequest: CreateSiteSectionDocumentRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi
          ?.ApiFactory(auth.token)
          .userV1SitesSiteIdSectionDocumentsPost({ siteId, createSiteSectionDocumentRequest })
        await mutateSiteSectionDocument()
        Toaster.success('セクション資料を作成しました')
      } catch (err) {
        await handleError(err, { setError, setInstructions, tag: 'Create Site Section Document' }, false)
      } finally {
        setIsLoading(true)
      }
    },
    [auth, siteApi, mutateSiteSectionDocument, siteId]
  )
  return { error, instructions, isLoading, handleCreateSiteSectionDocument }
}

export const useUpdateSiteSectionDocument = (siteId: string, siteSectionDocumentId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate: mutateSiteSection } = useListSiteSection(siteId)
  const handleUpdateSiteSectionDocument = useCallback(
    async (updateSiteSectionDocumentRequest: UpdateSiteSectionDocumentRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPut({
          siteId,
          siteSectionDocumentId,
          updateSiteSectionDocumentRequest
        })
        await mutateSiteSection()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'update site section document' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutateSiteSection, siteId, siteSectionDocumentId]
  )
  return { error, isLoading, handleUpdateSiteSectionDocument }
}

export const useUpdateSiteSectionDocumentSort = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate: mutateSiteSection } = useListSiteSection(siteId)
  const handleUpdateSiteSectionDocumentSort = useCallback(
    async (sortSiteSectionDocumentRequest: SortSiteSectionDocumentRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi
          ?.ApiFactory(auth.token)
          .userV1SitesSiteIdSectionDocumentsSortPut({ siteId, sortSiteSectionDocumentRequest })
        await mutateSiteSection()
        Toaster.success('変更を保存しました')
      } catch (err) {
        console.error(err)
        await handleError(err, { setError, tag: 'update site section document sort' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutateSiteSection, siteId]
  )
  return { error, isLoading, handleUpdateSiteSectionDocumentSort }
}

export const useDeleteSiteSectionDocument = (
  params: UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRequest
) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { data: me } = useGetMe()
  const { mutate } = useListSiteSection(params.siteId)
  const handleDeleteSiteSectionDocument = useCallback(async () => {
    try {
      if (!auth || !me) return
      setError(undefined)
      setIsLoading(true)
      await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDelete(params)
      await mutate()
      Toaster.success('資料を削除しました')
    } catch (err) {
      await handleError(err, { setError, tag: 'Delete Site section document' })
    } finally {
      setIsLoading(false)
    }
  }, [auth, me, mutate, siteApi, params])

  return { error, isLoading, handleDeleteSiteSectionDocument }
}

export const useShareSiteToContact = (siteId: string, onSuccess?: () => void) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetSite(siteId)
  const siteApi = nocoSDK.client?.userService.siteApi
  const handleShareSiteToContact = useCallback(
    async (shareToContactSiteRequest: ShareToContactSiteRequest, selectedSiteId?: string) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdShareToContactPost({
          siteId: selectedSiteId || siteId,
          shareToContactSiteRequest
        })

        mutate()
        onSuccess?.()
        // ! メール送信時の挙動変更に付き、トーストを出さないようにした
        //Toaster.success('メールを送信しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'share site to contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutate, siteId, onSuccess]
  )

  return { error, isLoading, handleShareSiteToContact }
}

export const useShareSiteByLink = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const [instructions, setInstructions] = useState<string[] | undefined>()
  const { mutate } = useGetSite(siteId)
  const siteApi = nocoSDK.client?.userService.siteApi
  const handleUpdateShareSiteByLink = useCallback(
    async (shareByLinkSiteRequest: ShareByLinkSiteRequest, successToastMessage?: string) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdShareByLinkPut({
          siteId,
          shareByLinkSiteRequest
        })
        mutate()
        Toaster.success(successToastMessage || '共有の設定を変更しました')
      } catch (err) {
        await handleError(err, { setError, setInstructions, tag: 'share site to contact' }, false)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, mutate, siteId]
  )

  return { error, isLoading, handleUpdateShareSiteByLink, instructions }
}

export const useListSiteViewHistoriesGraph = (siteId: string, contactId?: string) => {
  const { data: auth } = useAuthenticate()
  const siteApi = nocoSDK.client?.userService.siteApi
  const func = useCallback(async () => {
    if (!auth || !contactId) return
    return await siteApi
      ?.ApiFactory(auth.token)
      .userV1SitesSiteIdViewHistoriesGet({ siteId })
      .then(res => res.data)
  }, [auth, siteId, siteApi, contactId])

  return useSWR(auth?.token && contactId && SWRCachePath.listSiteViewHistoriesGraph(siteId, contactId), func)
}

export const useUpdateSiteContact = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const siteApi = nocoSDK.client?.userService.siteApi
  const { mutate } = useListSiteContacts(siteId)

  const handleUpdateSiteContact = useCallback(
    async ({
      siteId,
      siteContactId,
      updateSiteContactRequest
    }: UserV1SitesSiteIdSiteContactsSiteContactIdPutRequest) => {
      try {
        if (!auth || !siteId || !siteContactId) return
        setError(undefined)
        setIsLoading(true)
        await siteApi
          ?.ApiFactory(auth.token)
          .userV1SitesSiteIdSiteContactsSiteContactIdPut({ siteId, siteContactId, updateSiteContactRequest })
        mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update site contact' })
      }
    },
    [auth, siteApi, mutate]
  )

  return { error, isLoading, handleUpdateSiteContact }
}

export const useGetSiteMiscSetting = (siteId?: string) => {
  const { data: auth } = useAuthenticate()
  const documentApi = nocoSDK.client?.userService.siteApi
  const func = useCallback(async () => {
    if (!auth || !siteId) return undefined
    return await documentApi
      ?.ApiFactory(auth.token)
      .userV1SitesSiteIdMiscSettingGet({ siteId })
      .then(item => item.data?.siteMiscSetting)
  }, [auth, documentApi, siteId])
  return useSWR(auth?.token && siteId ? SWRCachePath.getSiteMiscSetting(siteId) : null, func, {
    onError: err => handleError(err, { tag: 'Get site misc setting' })
  })
}

export const useUpdateSiteMiscSetting = (siteId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mudateSiteMiscSetting } = useGetSiteMiscSetting(siteId)
  const siteApi = nocoSDK.client?.userService.siteApi
  const handleUpdateSiteMiscSetting = useCallback(
    async (updateSiteMiscSettingRequest: UpdateSiteMiscSettingRequest) => {
      try {
        if (!auth || !siteId) return undefined
        setError(undefined)
        setIsLoading(true)
        await siteApi
          ?.ApiFactory(auth.token)
          .userV1SitesSiteIdMiscSettingPut({ siteId: siteId, updateSiteMiscSettingRequest })
        mudateSiteMiscSetting()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update site misc setting' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, siteId, mudateSiteMiscSetting]
  )

  return { error, isLoading, handleUpdateSiteMiscSetting }
}

export const useUpdateSiteMiscSettingOgpFile = (siteId?: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mudateSiteMiscSetting } = useGetSiteMiscSetting(siteId)
  const siteApi = nocoSDK.client?.userService.siteApi
  const handleUpdateSiteMiscSettingOgpFile = useCallback(
    async (file?: File) => {
      try {
        if (!auth || !siteId) return undefined
        setError(undefined)
        setIsLoading(true)
        await siteApi?.ApiFactory(auth.token).userV1SitesSiteIdMiscSettingOgpFilePut({
          siteId: siteId,
          file
        })
        mudateSiteMiscSetting()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update site misc setting ogp file' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, siteApi, siteId, mudateSiteMiscSetting]
  )

  return { error, isLoading, handleUpdateSiteMiscSettingOgpFile }
}

export const useListSitesSiteIdDocuments = (siteId: string) => {
  const { data: auth } = useAuthenticate()
  const apiSite = nocoSDK.client?.userService.siteApi
  const funcSite = useCallback(async () => {
    if (!auth) return

    return await apiSite
      ?.ApiFactory(auth.token)
      .userV1SitesSiteIdDocumentsGet({ siteId })
      .then(res => res.data)
  }, [auth, siteId, apiSite])
  return useSWR(auth?.token && SWRCachePath.listSitesSiteIdDocuments(siteId), funcSite)
}

export const useExpiredSiteContact = (contactId: string) => {
  const { mutate } = useListContactMaterials(contactId)
  const { mutate: mutateListContactExpiredMaterials } = useListContactExpiredMaterials({ contactId })
  const { handleUpdateSiteContact } = useUpdateSiteContact('')

  const handleExpiredSiteContact = useCallback(
    async ({ siteId, contactId, canViewable, expiredOn }: SiteContact) => {
      await handleUpdateSiteContact({
        siteId: siteId!,
        siteContactId: contactId!,
        updateSiteContactRequest: { siteContact: { canViewable, expiredOn } }
      })
      await mutate()
      await mutateListContactExpiredMaterials()
    },

    [handleUpdateSiteContact, mutate, mutateListContactExpiredMaterials]
  )

  return { handleExpiredSiteContact }
}
