/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateContactMemoRequestContactMemo,
  CreateContactMemoRequestContactMemoFromJSON,
  CreateContactMemoRequestContactMemoFromJSONTyped,
  CreateContactMemoRequestContactMemoToJSON
} from './CreateContactMemoRequestContactMemo'

/**
 *
 * @export
 * @interface UpdateCompanyMemoRequest
 */
export interface UpdateCompanyMemoRequest {
  /**
   *
   * @type {CreateContactMemoRequestContactMemo}
   * @memberof UpdateCompanyMemoRequest
   */
  companyMemo?: CreateContactMemoRequestContactMemo
}

export function UpdateCompanyMemoRequestFromJSON(json: any): UpdateCompanyMemoRequest {
  return UpdateCompanyMemoRequestFromJSONTyped(json, false)
}

export function UpdateCompanyMemoRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCompanyMemoRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyMemo: !exists(json, 'company_memo')
      ? undefined
      : CreateContactMemoRequestContactMemoFromJSON(json['company_memo'])
  }
}

export function UpdateCompanyMemoRequestToJSON(value?: UpdateCompanyMemoRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_memo: CreateContactMemoRequestContactMemoToJSON(value.companyMemo)
  }
}
