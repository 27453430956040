/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SortSiteSectionRequestSiteSectionsInnerFromJSON, SortSiteSectionRequestSiteSectionsInnerToJSON } from './SortSiteSectionRequestSiteSectionsInner';
export function SortSiteSectionDocumentRequestFromJSON(json) {
    return SortSiteSectionDocumentRequestFromJSONTyped(json, false);
}
export function SortSiteSectionDocumentRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        siteSectionDocuments: !exists(json, 'site_section_documents')
            ? undefined
            : json['site_section_documents'].map(SortSiteSectionRequestSiteSectionsInnerFromJSON)
    };
}
export function SortSiteSectionDocumentRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        site_section_documents: value.siteSectionDocuments === undefined
            ? undefined
            : value.siteSectionDocuments.map(SortSiteSectionRequestSiteSectionsInnerToJSON)
    };
}
