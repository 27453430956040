import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { WarningAmber } from '@material-ui/icons'
import React, { CSSProperties } from 'react'

interface StyleProps {
  status: AlertStatus
}

const AlertStatusColorMap: Record<AlertStatus, CSSProperties> = {
  error: {
    borderLeft: '3px solid #FF8E5E',
    backgroundColor: '#FFFBD9'
  },
  success: {
    borderLeft: '3px solid #FF8E5E',
    backgroundColor: '#FFFBD9'
  },
  warning: {
    borderLeft: '3px solid #FF8E5E',
    backgroundColor: '#FFFBD9'
  },
  info: {
    borderLeft: '3px solid #FF8E5E',
    backgroundColor: '#FFFBD9'
  }
} as const

const useStyles = makeStyles<Theme, StyleProps>(() => {
  return createStyles({
    wrapper: {
      borderLeft: ({ status }) => AlertStatusColorMap[status].borderLeft,
      backgroundColor: ({ status }) => AlertStatusColorMap[status].backgroundColor
    },
    text: {
      flex: 1,
      marginLeft: 8,
      fontWeight: 'bold'
    }
  })
})

type AlertStatus = 'error' | 'success' | 'warning' | 'info'

interface Props {
  status: AlertStatus
  text: string
  actionButton?: React.ReactNode
}

export const Alert = ({ status, text, actionButton }: Props) => {
  const classes = useStyles({ status })
  return (
    <Box className={classes.wrapper} display="flex" alignItems="center" p={2}>
      <WarningAmber />

      <Typography className={classes.text}>{text}</Typography>

      <Box mr={2}>{actionButton}</Box>
    </Box>
  )
}
