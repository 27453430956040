import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Colors, DynamicMuiIcon, MuiIconVariant } from 'src/components/atoms'

const useStyles = makeStyles(() =>
  createStyles({
    buttonBase: {
      display: 'block',
      width: '100%'
    }
  })
)

interface ButtonData {
  icon: MuiIconVariant
  onClick: (e: React.MouseEvent) => void
}

export interface ButtonListProps {
  onDelete: () => void
  onMove: () => void
  listeners: SyntheticListenerMap | undefined
}

// MEMO: forward ref を利用するために disabled
// eslint-disable-next-line react/display-name
export const ButtonList = React.forwardRef<HTMLButtonElement, ButtonListProps>(
  ({ onDelete, onMove, listeners }, ref) => {
    const classes = useStyles()
    return (
      <Box display="flex" alignItems="center">
        <Box mr={'12px'}>
          <ButtonBase className={classes.buttonBase} onClick={onMove} {...{ ref: ref, ...listeners }}>
            <Box
              width="28px"
              height="28px"
              borderRadius="4px"
              border={`1px solid ${Colors.background.silver}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={Colors.functional.background.default}
              color={Colors.base.middleGray}
            >
              <DynamicMuiIcon variant={'openWith'} size="20px" color="inherit" />
            </Box>
          </ButtonBase>
        </Box>
        <Box>
          <ButtonBase className={classes.buttonBase} onClick={onDelete}>
            <Box
              width="28px"
              height="28px"
              borderRadius="4px"
              border={`1px solid ${Colors.background.silver}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor={Colors.functional.background.default}
              color={Colors.base.middleGray}
            >
              <DynamicMuiIcon variant={'delete'} size="20px" color="inherit" />
            </Box>
          </ButtonBase>
        </Box>
      </Box>
    )
  }
)
