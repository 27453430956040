/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DocumentChatSettingFromJSON, DocumentChatSettingToJSON } from './DocumentChatSetting';
import { DocumentCtaFromJSON, DocumentCtaToJSON } from './DocumentCta';
import { DocumentMiscSettingFromJSON, DocumentMiscSettingToJSON } from './DocumentMiscSetting';
import { DocumentOpportunityFromJSON, DocumentOpportunityToJSON } from './DocumentOpportunity';
import { DocumentShareFromJSON, DocumentShareToJSON } from './DocumentShare';
import { DocumentSlideFromJSON, DocumentSlideToJSON } from './DocumentSlide';
import { EnumPublicationStatusFromJSON, EnumPublicationStatusToJSON } from './EnumPublicationStatus';
import { EnumSharingStatusFromJSON, EnumSharingStatusToJSON } from './EnumSharingStatus';
import { EuDocumentMessageWithOrganizationChatSettingFromJSON, EuDocumentMessageWithOrganizationChatSettingToJSON } from './EuDocumentMessageWithOrganizationChatSetting';
import { UploadedFileFromJSON, UploadedFileToJSON } from './UploadedFile';
export function EuDocumentFromJSON(json) {
    return EuDocumentFromJSONTyped(json, false);
}
export function EuDocumentFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
        title: !exists(json, 'title') ? undefined : json['title'],
        description: !exists(json, 'description') ? undefined : json['description'],
        thumbnail: !exists(json, 'thumbnail') ? undefined : UploadedFileFromJSON(json['thumbnail']),
        currentSlide: !exists(json, 'current_slide') ? undefined : DocumentSlideFromJSON(json['current_slide']),
        publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
        archivedAt: !exists(json, 'archived_at') ? undefined : json['archived_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        isPublished: !exists(json, 'is_published') ? undefined : json['is_published'],
        sharingStatus: !exists(json, 'sharing_status') ? undefined : EnumSharingStatusFromJSON(json['sharing_status']),
        publicationStatus: !exists(json, 'publication_status')
            ? undefined
            : EnumPublicationStatusFromJSON(json['publication_status']),
        documentCta: !exists(json, 'document_cta') ? undefined : DocumentCtaFromJSON(json['document_cta']),
        documentOpportunity: !exists(json, 'document_opportunity')
            ? undefined
            : DocumentOpportunityFromJSON(json['document_opportunity']),
        documentMessage: !exists(json, 'document_message')
            ? undefined
            : EuDocumentMessageWithOrganizationChatSettingFromJSON(json['document_message']),
        documentChatSetting: !exists(json, 'document_chat_setting')
            ? undefined
            : DocumentChatSettingFromJSON(json['document_chat_setting']),
        documentShare: !exists(json, 'document_share') ? undefined : DocumentShareFromJSON(json['document_share']),
        documentMiscSetting: !exists(json, 'document_misc_setting')
            ? undefined
            : DocumentMiscSettingFromJSON(json['document_misc_setting'])
    };
}
export function EuDocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        organization_id: value.organizationId,
        title: value.title,
        description: value.description,
        thumbnail: UploadedFileToJSON(value.thumbnail),
        current_slide: DocumentSlideToJSON(value.currentSlide),
        published_at: value.publishedAt,
        archived_at: value.archivedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        is_published: value.isPublished,
        sharing_status: EnumSharingStatusToJSON(value.sharingStatus),
        publication_status: EnumPublicationStatusToJSON(value.publicationStatus),
        document_cta: DocumentCtaToJSON(value.documentCta),
        document_opportunity: DocumentOpportunityToJSON(value.documentOpportunity),
        document_message: EuDocumentMessageWithOrganizationChatSettingToJSON(value.documentMessage),
        document_chat_setting: DocumentChatSettingToJSON(value.documentChatSetting),
        document_share: DocumentShareToJSON(value.documentShare),
        document_misc_setting: DocumentMiscSettingToJSON(value.documentMiscSetting)
    };
}
