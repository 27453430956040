import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var OrganizationUserEmailSettingsApi = /** @class */ (function () {
    function OrganizationUserEmailSettingsApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    OrganizationUserEmailSettingsApi.initialize = function (basePath, fetchApi) {
        if (!OrganizationUserEmailSettingsApi.instance) {
            OrganizationUserEmailSettingsApi.instance = new OrganizationUserEmailSettingsApi(basePath, fetchApi);
        }
        return OrganizationUserEmailSettingsApi.instance;
    };
    OrganizationUserEmailSettingsApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1OrganizationUserEmailSettingsApi(new Configuration(config));
    };
    return OrganizationUserEmailSettingsApi;
}());
export { OrganizationUserEmailSettingsApi };
