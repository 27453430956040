import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var ContactChatMessageApi = /** @class */ (function () {
    function ContactChatMessageApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    ContactChatMessageApi.initialize = function (basePath, fetchApi) {
        if (!ContactChatMessageApi.instance) {
            ContactChatMessageApi.instance = new ContactChatMessageApi(basePath, fetchApi);
        }
        return ContactChatMessageApi.instance;
    };
    ContactChatMessageApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1ContactChatMessageApi(new Configuration(config));
    };
    return ContactChatMessageApi;
}());
export { ContactChatMessageApi };
