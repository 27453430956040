/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumUserPermissionFromJSON, EnumUserPermissionToJSON } from './EnumUserPermission';
import { GetUsersResponseDataUsersInnerUserAvatarFromJSON, GetUsersResponseDataUsersInnerUserAvatarToJSON } from './GetUsersResponseDataUsersInnerUserAvatar';
export function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
export function UserFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
        firstLogin: !exists(json, 'first_login') ? undefined : json['first_login'],
        avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
        permission: !exists(json, 'permission') ? undefined : EnumUserPermissionFromJSON(json['permission']),
        opportunityToolUrl: !exists(json, 'opportunity_tool_url') ? undefined : json['opportunity_tool_url'],
        activatedAt: !exists(json, 'activated_at') ? undefined : json['activated_at'],
        invitedAt: !exists(json, 'invited_at') ? undefined : json['invited_at'],
        discardedAt: !exists(json, 'discarded_at') ? undefined : json['discarded_at'],
        accessedAt: !exists(json, 'accessed_at') ? undefined : json['accessed_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        first_name: value.firstName,
        last_name: value.lastName,
        encrypted_email: value.encryptedEmail,
        first_login: value.firstLogin,
        avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
        permission: EnumUserPermissionToJSON(value.permission),
        opportunity_tool_url: value.opportunityToolUrl,
        activated_at: value.activatedAt,
        invited_at: value.invitedAt,
        discarded_at: value.discardedAt,
        accessed_at: value.accessedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
