/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateSiteMiscSettingRequestSiteMiscSettingFromJSON(json) {
    return UpdateSiteMiscSettingRequestSiteMiscSettingFromJSONTyped(json, false);
}
export function UpdateSiteMiscSettingRequestSiteMiscSettingFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        isPublicSearchEngine: !exists(json, 'is_public_search_engine') ? undefined : json['is_public_search_engine'],
        ogpTitle: !exists(json, 'ogp_title') ? undefined : json['ogp_title'],
        ogpDescription: !exists(json, 'ogp_description') ? undefined : json['ogp_description']
    };
}
export function UpdateSiteMiscSettingRequestSiteMiscSettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        is_public_search_engine: value.isPublicSearchEngine,
        ogp_title: value.ogpTitle,
        ogp_description: value.ogpDescription
    };
}
