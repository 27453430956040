/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumUserOperationKind = {
    AppendContactsToCompany: 'append_contacts_to_company',
    RemoveContactsFromCompany: 'remove_contacts_from_company',
    CreateMaterialContactable: 'create_material_contactable',
    SolveContact: 'solve_contact',
    UnsolveContact: 'unsolve_contact',
    Import: 'import',
    Export: 'export'
};
export function EnumUserOperationKindFromJSON(json) {
    return EnumUserOperationKindFromJSONTyped(json, false);
}
export function EnumUserOperationKindFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumUserOperationKindToJSON(value) {
    return value;
}
