import { Box } from '@material-ui/core'
import Image from 'next/image'
import React from 'react'
import { Card, Colors, Typography } from 'src/components/atoms'

export interface PaymentCompletedContractCardProps {
  message?: string
}

export const PaymentCompletedContractCard = ({ message }: PaymentCompletedContractCardProps) => {
  return (
    <Card sx={{ bgcolor: '#fff' }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {message && (
          <Box mb="16px" textAlign="center" whiteSpace="pre-wrap" color={Colors.base.black}>
            <Typography fontSize="s" lineheight="1.7" color="inherit">
              {message}
            </Typography>
          </Box>
        )}
        <Image src={`/images/payment/completed-contract.svg`} width="344" height="241" alt="" />
      </Box>
    </Card>
  )
}
