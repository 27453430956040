import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Avatar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.00002 0.666504C4.40002 0.666504 0.666687 4.39984 0.666687 8.99984C0.666687 13.5998 4.40002 17.3332 9.00002 17.3332C13.6 17.3332 17.3334 13.5998 17.3334 8.99984C17.3334 4.39984 13.6 0.666504 9.00002 0.666504ZM9.00002 3.1665C10.3834 3.1665 11.5 4.28317 11.5 5.6665C11.5 7.04984 10.3834 8.1665 9.00002 8.1665C7.61669 8.1665 6.50002 7.04984 6.50002 5.6665C6.50002 4.28317 7.61669 3.1665 9.00002 3.1665ZM9.00002 14.9998C6.91669 14.9998 5.07502 13.9332 4.00002 12.3165C4.02502 10.6582 7.33335 9.74984 9.00002 9.74984C10.6584 9.74984 13.975 10.6582 14 12.3165C12.925 13.9332 11.0834 14.9998 9.00002 14.9998Z" />
    </SvgIcon>
  )
}
export default Avatar
