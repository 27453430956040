import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Account = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.034 20.875C17.6673 20.8583 17.3006 20.8417 16.9173 20.8417C12.884 20.8417 9.11732 21.9583 5.90065 23.875C4.43398 24.7417 3.58398 26.375 3.58398 28.0917V30.8417C3.58398 31.7583 4.33398 32.5083 5.25065 32.5083H19.0173C17.7007 30.625 16.9173 28.325 16.9173 25.8417C16.9173 24.0583 17.334 22.3917 18.034 20.875Z"
        fill="#DE1B63"
      />
      <path
        d="M16.9173 19.175C20.5992 19.175 23.584 16.1902 23.584 12.5083C23.584 8.82644 20.5992 5.84167 16.9173 5.84167C13.2354 5.84167 10.2507 8.82644 10.2507 12.5083C10.2507 16.1902 13.2354 19.175 16.9173 19.175Z"
        fill="#DE1B63"
      />
      <path
        d="M34.834 25.8417C34.834 25.475 34.784 25.1417 34.734 24.7917L36.134 23.575C36.434 23.3083 36.5006 22.875 36.3006 22.525L35.3173 20.825C35.1173 20.475 34.7006 20.325 34.334 20.4583L32.5673 21.0583C32.034 20.6083 31.434 20.2583 30.7673 20.0083L30.4006 18.1917C30.3173 17.8083 29.984 17.525 29.584 17.525H27.6173C27.2173 17.525 26.884 17.8083 26.8006 18.1917L26.434 20.0083C25.7673 20.2583 25.1673 20.6083 24.634 21.0583L22.8673 20.4583C22.484 20.325 22.084 20.4917 21.884 20.825L20.9006 22.525C20.7006 22.875 20.7673 23.3083 21.0673 23.575L22.4673 24.7917C22.4173 25.1417 22.3673 25.475 22.3673 25.8417C22.3673 26.2083 22.4173 26.5417 22.4673 26.8917L21.0673 28.1083C20.7673 28.375 20.7006 28.8083 20.9006 29.1583L21.884 30.8583C22.084 31.2083 22.5006 31.3583 22.8673 31.225L24.634 30.625C25.1673 31.075 25.7673 31.425 26.434 31.675L26.8006 33.4917C26.884 33.875 27.2173 34.1583 27.6173 34.1583H29.584C29.984 34.1583 30.3173 33.875 30.4006 33.4917L30.7673 31.675C31.434 31.425 32.034 31.075 32.5673 30.625L34.334 31.225C34.7173 31.3583 35.1173 31.1917 35.3173 30.8583L36.3006 29.1583C36.5006 28.8083 36.434 28.375 36.134 28.1083L34.734 26.8917C34.784 26.5417 34.834 26.2083 34.834 25.8417ZM28.584 29.175C26.7506 29.175 25.2506 27.675 25.2506 25.8417C25.2506 24.0083 26.7506 22.5083 28.584 22.5083C30.4173 22.5083 31.9173 24.0083 31.9173 25.8417C31.9173 27.675 30.4173 29.175 28.584 29.175Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84154C1.50202 1.98324 2.89359 1.98324 3.75188 2.84154L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.9497C-0.214574 5.0914 -0.214574 3.69983 0.643721 2.84154Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default Account
