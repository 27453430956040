/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface EuOrganizationChatSetting
 */
export interface EuOrganizationChatSetting {
  /**
   *
   * @type {string}
   * @memberof EuOrganizationChatSetting
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof EuOrganizationChatSetting
   */
  autoResponseName?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof EuOrganizationChatSetting
   */
  avatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {string}
   * @memberof EuOrganizationChatSetting
   */
  chatColor?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof EuOrganizationChatSetting
   */
  chatButtonImage?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {string}
   * @memberof EuOrganizationChatSetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof EuOrganizationChatSetting
   */
  updatedAt?: string
}

export function EuOrganizationChatSettingFromJSON(json: any): EuOrganizationChatSetting {
  return EuOrganizationChatSettingFromJSONTyped(json, false)
}

export function EuOrganizationChatSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EuOrganizationChatSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    autoResponseName: !exists(json, 'auto_response_name') ? undefined : json['auto_response_name'],
    avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
    chatColor: !exists(json, 'chat_color') ? undefined : json['chat_color'],
    chatButtonImage: !exists(json, 'chat_button_image')
      ? undefined
      : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['chat_button_image']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function EuOrganizationChatSettingToJSON(value?: EuOrganizationChatSetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    auto_response_name: value.autoResponseName,
    avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
    chat_color: value.chatColor,
    chat_button_image: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.chatButtonImage),
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
