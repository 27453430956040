import { Box, createStyles, Input, makeStyles } from '@material-ui/core'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { ButtonIcon, Colors, DynamicMuiIcon, Pallete } from 'src/components/atoms'
import clsx from 'clsx'

export interface CounterProps {
  value?: number
  onChange?: (count: number) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      border: '1px solid #DADCE0',
      backgroundColor: Colors.background.lightGray,
      padding: '6px',
      color: '#6D7784'
    },
    sub: {
      borderRadius: '4px 0 0 4px'
    },
    add: {
      borderRadius: '0 4px 4px 0'
    },
    numberFieldRoot: {
      minWidth: '93px',
      height: '34px',
      width: '100%',
      border: `1px solid ${Pallete.functional.border.lightGray}`
    },
    numberFieldInput: {
      textAlign: 'center',
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: '0'
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: '0'
      }
    }
  })
})

export const Counter = ({ value = 0, onChange }: CounterProps) => {
  const classes = useStyles()
  const [count, setCount] = useState<number | string>(value)

  const handleAddCount = useCallback(() => setCount(value => Number(value) + 1), [])
  const handleSubCount = useCallback(() => setCount(value => Number(value) - 1), [])
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const count = e.target.value || ''
    setCount(count === '' ? count : Number(count))
  }, [])

  useEffect(() => {
    onChange?.(count === '' ? 0 : Number(count))
  }, [count, onChange])

  return (
    <Box display="flex" alignItems="center">
      <ButtonIcon className={clsx(classes.button, classes.sub)} onClick={handleSubCount}>
        <DynamicMuiIcon variant="horizontalRule" size="20px" color="inherit" />
      </ButtonIcon>
      <Box>
        <Input
          classes={{ root: classes.numberFieldRoot, input: classes.numberFieldInput }}
          type="number"
          value={count}
          onChange={handleChange}
        />
      </Box>
      <ButtonIcon className={clsx(classes.button, classes.add)} onClick={handleAddCount}>
        <DynamicMuiIcon variant="add" size="20px" color="inherit" />
      </ButtonIcon>
    </Box>
  )
}
