import { Box, ButtonBase, Avatar, createStyles, makeStyles } from '@material-ui/core'
import {
  Document,
  EnumVisitorMaterialableTrackEvent,
  Organization,
  OrganizationPolicySetting,
  Visitor
} from '@noco/http-client/lib/noco'
import { mutate as globalMutate } from 'swr'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { MenuListItemProps, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { ChatContent, ChatContentMenuType } from 'src/components/organisms'
import { FloatingWindow } from 'src/components/organisms/FloatingWindow'
import { SWRCachePath } from 'src/fixtures/modules/swr-cach-path'
import { useListContactChatMessages } from 'src/fixtures/modules/user/hooks'
import { useSubscribeChatChannel } from 'src/fixtures/modules/webSocket'
import { Message } from 'src/fixtures/types/chat-channel'
import { useCreateTrack } from '../../../../fixtures/modules/ContactMaterialableTrack/hooks'
import { ModalTowAuthenticateWithEmail } from './ModalAuth'
import { useGetChatSetting } from './hooks'
import theme from 'src/styles/theme'

const useStyles = makeStyles(() =>
  createStyles({
    newMessageNotice: {
      position: ({ isSp }: FloatingWindowButtonProps) => (isSp ? 'absolute' : 'relative'),
      background: Colors.functional.background.default,
      marginBottom: 12,
      cursor: 'pointer',
      animation: `$bounce 2s infinite ${theme.transitions.easing.sharp}`,
      left: ({ isSp, isSpHorizontalMode }: FloatingWindowButtonProps) =>
        isSpHorizontalMode ? '-132px' : isSp ? '-170px' : 0,

      '&::after': {
        borderTop: `8px solid ${Colors.functional.background.default}`,
        borderRight: '8px solid transparent',
        borderLeft: '8px solid transparent',
        content: ({ isSp }: FloatingWindowButtonProps) => (isSp ? '' : '""'),
        marginLeft: -10,
        position: 'absolute',
        bottom: () => -8,
        left: '80%'
      }
    },
    newMessage: {
      maxWidth: 105,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 14
    },
    chatButtonImage: {
      width: 64,
      height: 64,
      borderRadius: '50%',
      objectFit: 'contain'
    },
    '@keyframes bounce': {
      '0%': { transform: 'translate(0, 0)' },
      '50%': { transform: 'translate(0, -5px)' },
      '100%': { transform: 'translate(0, 0)' }
    }
  })
)

interface FloatingWindowButtonProps {
  document: Document
  documentShareId?: string
  siteShareId?: string
  organization: Organization
  visitor?: Visitor
  isPreview?: boolean
  siteId?: string
  policySettings?: OrganizationPolicySetting[]
  isSp?: boolean
  isSpHorizontalMode?: boolean
  open: boolean
  setOpen: (open: boolean) => void
  init: boolean
  setInit: (init: boolean) => void
  isActivated: boolean
}

export const FloatingWindowButton = (props: FloatingWindowButtonProps) => {
  const {
    document,
    organization,
    visitor,
    documentShareId,
    siteShareId,
    isPreview = false,
    isSpHorizontalMode,
    siteId,
    policySettings,
    open,
    setOpen,
    init,
    setInit,
    isActivated
  } = props
  const classes = useStyles(props)
  const { organizationChatSetting } = useGetChatSetting(isPreview)
  const [selectedMenu, selectChatRoom] = useState<ChatContentMenuType>(null)
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false)
  const [goto, setGoto] = useState<ChatContentMenuType>(null)
  const [errorMessages, seteErorMessages] = useState<Message[]>([])
  const [newMessage, setNewMessage] = useState<{
    sendedById?: string
    message: string
    avater: string
    onClick?: () => void
  }>()
  const { data: chatMessagesRes, mutate } = useListContactChatMessages(isPreview, visitor)
  const { handleCreateTrack } = useCreateTrack()

  const messageOrgName = organizationChatSetting?.autoResponseName ?? organization.name
  const messageAvaterUrl = organizationChatSetting?.avatar?.url || document.user?.avatar.url

  const receiveMessage = (message: Message, contactId: string, open: boolean, selectedMenu: ChatContentMenuType) => {
    mutate()
    // NOTE 相手からのメッセージであれば、新着メッセージとして表示する（チャットモーダル開いている最中に、ユーザーからメッセージがあった場合、ボタン上に出さない）
    if (message.data.chat_message.sended_by_id !== contactId && !(open && selectedMenu === 'chat')) {
      setNewMessage({
        sendedById: message.data.chat_message.sended_by_id,
        avater: message.data.chat_message.sended_by.avatar.url ?? '',
        message: message.data.chat_message.message ?? '',
        onClick: () => {
          setOpen(true)
          selectChatRoom('chat')
          setNewMessage(undefined)
        }
      })
    }
  }

  // subscribing message
  useSubscribeChatChannel(
    isPreview ? 'User' : 'Visitor',
    receiveMessage,
    message => {
      seteErorMessages([...errorMessages, message])
    },
    visitor?.contactId ?? '',
    open,
    selectedMenu,
    isPreview
  )

  const onClose = useCallback(() => {
    setOpen(false)
    selectChatRoom(null)
  }, [setOpen, selectChatRoom])

  const menuList = useMemo(() => {
    const menuList: MenuListItemProps[] = []
    if (document.documentMessage && document.documentMessage.isView) {
      menuList.push({
        avatar: messageAvaterUrl,
        title: messageOrgName,
        body: String(document.documentMessage.text || '')
      })
    }
    menuList.push({
      kind: 'chat',
      buttonColor: organizationChatSetting?.chatColor,
      onClick: () => {
        // プレビュー
        if (isPreview) {
          selectChatRoom('chat')
        } else {
          // visitor が contact だった場合
          if (visitor && visitor.hasContact) {
            selectChatRoom('chat')
          } else {
            setGoto('chat')
            setOpenSendEmailModal(true)
          }
        }
      }
    })
    if (document.documentCta && document.documentCta.isView) {
      menuList.push({
        kind: 'notification',
        title: document.documentCta.title ?? '',
        body: document.documentCta.description ?? '',
        buttonTitle: document.documentCta.buttonName ?? '',
        buttonColor: document.documentCta.buttonColor ?? '',
        onClick: () => {
          handleCreateTrack({
            event: EnumVisitorMaterialableTrackEvent.Cta,
            documentId: document.id
          })
          window.open(String(document.documentCta?.buttonUrl))
        }
      })
    }
    if (document.documentOpportunity && document.documentOpportunity.isView) {
      menuList.push({
        kind: 'appointment',
        title: document.documentOpportunity.title ?? '',
        body: document.documentOpportunity.description ?? '',
        buttonTitle: document.documentOpportunity.buttonName ?? '',
        buttonColor: document.documentOpportunity.buttonColor ?? '',
        onClick: () => {
          if (isPreview) {
            selectChatRoom('appointment')
          } else {
            // visitor が contact だった場合
            if (visitor && visitor.hasContact) {
              selectChatRoom('appointment')
            } else {
              setGoto('appointment')
              setOpenSendEmailModal(true)
            }
          }
        }
      })
    }
    return menuList
  }, [
    document.documentCta,
    document.documentMessage,
    document.documentOpportunity,
    document.id,
    handleCreateTrack,
    isPreview,
    messageAvaterUrl,
    messageOrgName,
    organizationChatSetting?.chatColor,
    visitor
  ])

  const onBack = useCallback(() => {
    selectChatRoom(null)
  }, [selectChatRoom])

  useEffect(() => {
    // chatとdocumentが揃ったら初期化をする（visitorのコンタクトがない場合は、documentのみ）
    if (!isActivated || !document || !visitor || (!chatMessagesRes && visitor.hasContact) || init) {
      return
    }

    if (chatMessagesRes?.chatMessages && chatMessagesRes?.chatMessages.length > 0) {
      const { sendedBy } = chatMessagesRes.chatMessages[chatMessagesRes.chatMessages.length - 1]
      // NOTE 最新のメッセージがユーザーからのものだった場合, モーダルを開く
      if (sendedBy?.id !== visitor?.contactId) {
        setOpen(true)
        selectChatRoom('chat')
        setInit(true)
        return
      }
    }

    if (
      document?.documentMessage?.isView &&
      document.documentMessage?.viewTimingSec &&
      document.documentMessage?.text
    ) {
      const timer = setTimeout(() => {
        setNewMessage({
          avater: messageAvaterUrl ?? '',
          message: document.documentMessage?.text ?? '',
          onClick: () => {
            setOpen(true)
            setNewMessage(undefined)
          }
        })

        clearTimeout(timer)
      }, document.documentMessage.viewTimingSec * 1000)
      setInit(true)
    }
  }, [document, chatMessagesRes, visitor, messageAvaterUrl, init, setInit, setOpen, isActivated])

  const handleAuthSuccess = useCallback(async () => {
    if (documentShareId) {
      globalMutate(SWRCachePath.getContactDocumentShare(documentShareId, siteShareId))
      mutate()
      if (visitor && visitor.hasContact) {
        selectChatRoom(goto)
        setGoto(null)
        setOpenSendEmailModal(false)
      }
    } else {
      location.reload()
    }
  }, [documentShareId, goto, mutate, siteShareId, visitor])

  const onClickFloatingButton = useCallback(() => {
    if (isPreview) {
      setOpen(true)
    } else {
      // 相手から新着メッセージが来ている場合、チャットを開く
      if (newMessage && newMessage.sendedById && newMessage.sendedById !== visitor?.contactId) {
        selectChatRoom('chat')
        setOpen(true)
        setNewMessage(undefined)
        return
      }
      setOpen(true)
    }
  }, [isPreview, setOpen, newMessage, visitor])

  return (
    <>
      <Box position={'relative'}>
        {!open && (
          <Box display="flex" flexDirection="column" alignItems="end" zIndex={99}>
            {newMessage?.message && (
              <Box
                className={classes.newMessageNotice}
                p={2}
                display="flex"
                alignItems="center"
                borderRadius={5}
                border="1px solid #E9ECF1"
                maxWidth={170}
                boxShadow="0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
              >
                {newMessage.avater && <Avatar src={newMessage.avater} />}
                <Box ml={2} overflow="hidden" whiteSpace="nowrap" onClick={newMessage.onClick}>
                  <Typography fontSize="xs" style={{ color: '#6D7784' }}>
                    新着メッセージ
                  </Typography>

                  <Typography className={classes.newMessage}>{newMessage.message}</Typography>
                </Box>
              </Box>
            )}
            <ButtonBase
              onClick={onClickFloatingButton}
              style={{
                width: '64px',
                height: '64px',

                backgroundColor: organizationChatSetting?.chatColor || Colors.primary.default,
                borderRadius: '50%',
                color: 'white',
                boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 16px 24px 0px #0000000F;'
              }}
            >
              <DynamicMuiIcon variant="chatBubble" />
            </ButtonBase>
            {/* TODO @toshi1127 チャットボタンのカスタマイズが有効になったら表示
          organizationChatSetting?.chatButtonImage ? (
            <ButtonBase
              onClick={() => setOpen(true)}
              style={{
                borderRadius: '50%',
                boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 16px 24px 0px #0000000F;'
              }}
            >
              <img className={classes.chatButtonImage} src={organizationChatSetting?.chatButtonImage.url} />
            </ButtonBase>
          ) : (
            <ButtonBase
              onClick={() => setOpen(true)}
              style={{
                width: '64px',
                height: '64px',
                backgroundColor: Colors.primary.default,
                borderRadius: '50%',
                color: 'white',
                boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 16px 24px 0px #0000000F;'
              }}
            >
              <DynamicMuiIcon variant="chatBubble" />
            </ButtonBase>
          )} */}
          </Box>
        )}
        {isSpHorizontalMode && open ? (
          <Box width="350px">
            <ChatContent
              document={document}
              siteId={siteId}
              companyName={organization.name || ''}
              selectedMenu={selectedMenu}
              selectChatRoom={selectChatRoom}
              visitor={visitor}
              chatMessages={chatMessagesRes?.chatMessages}
              menuList={menuList}
              onClose={onClose}
              onBack={onBack}
              organizationChatSetting={organizationChatSetting}
              organization={organization}
            />
          </Box>
        ) : (
          <FloatingWindow open={open}>
            <ChatContent
              document={document}
              siteId={siteId}
              companyName={organization.name || ''}
              selectedMenu={selectedMenu}
              selectChatRoom={selectChatRoom}
              visitor={visitor}
              chatMessages={chatMessagesRes?.chatMessages}
              menuList={menuList}
              onClose={onClose}
              onBack={onBack}
              organizationChatSetting={organizationChatSetting}
              organization={organization}
            />
          </FloatingWindow>
        )}
      </Box>

      {/* 2段階認証モーダル */}
      {document.id && (
        <ModalTowAuthenticateWithEmail
          title={
            goto === 'chat'
              ? 'チャットのご利用には、下記ご入力ください'
              : 'オンライン商談のお申し込みには下記ご入力ください'
          }
          document={document}
          organization={organization}
          siteId={siteId}
          open={openSendEmailModal}
          policySettings={policySettings}
          isPreview={isPreview}
          onClose={() => {
            setOpenSendEmailModal(false)
            setGoto(null)
          }}
          onSuccess={handleAuthSuccess}
        />
      )}
    </>
  )
}
