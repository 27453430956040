import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { LayoutTeam } from 'src/components/commons'
import { Header } from 'src/components/organisms'
import { Colors, Typography } from 'src/components/atoms'
import { ModalChangeSenderName } from 'src/components/modals'
import Image from 'next/image'
import {
  useGetOrganizationUserEmailSettings,
  useUpdateUserEmailSettings
} from 'src/fixtures/modules/organization/hooks'
import { OrganizationUserEmailSetting } from '@noco/http-client/lib/noco'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
import { SenderName } from './SenderName'

export const PageTeamSenderSetting = () => {
  const [userInfo, setUserInfo] = useState<OrganizationUserEmailSetting | undefined>(undefined)
  const { hasPermission: isUpdatable } = useFeatureFlag('sendEmail', 'update')
  const { data: res, mutate: emailSettingsMutate, error } = useGetOrganizationUserEmailSettings()
  const userEmailSettings: OrganizationUserEmailSetting[] | undefined = res?.organizationUserEmailSettings
  const { handleUpdateUserEmailSettings } = useUpdateUserEmailSettings()
  const isLoading = useMemo(() => !error && !res, [error, res])

  const handleOpenModal = useCallback(
    (user: OrganizationUserEmailSetting) => () => {
      setUserInfo(user)
    },
    []
  )

  const handleConfirm = useCallback(
    (senderName: string) => {
      if (!userInfo?.id) {
        return
      }
      handleUpdateUserEmailSettings(userInfo.id, senderName)
    },
    [handleUpdateUserEmailSettings, userInfo]
  )

  useEffect(() => {
    emailSettingsMutate()
  }, [emailSettingsMutate])

  return (
    <>
      <LayoutTeam>
        <Box
          width={1}
          bgcolor={Colors.functional.background.default}
          pl="16px"
          pr="32px"
          color={Colors.base.black}
          overflow="hidden"
          pb="16px"
        >
          <Header textSize="large" iconSize="32px" icon="forwardToInbox" title="メール送信設定" back={false} />
          <Box mt="24px" pl="16px">
            <Typography fontSize="s" lineheight="23.8px">
              お客様のメール受信ボックスで表示される「送信者名」の表示を設定できます
            </Typography>
          </Box>
          <Box display="flex" mt="24px" height="100%">
            <Box display="flex" flexDirection="column" flex={1}>
              <Box
                display="flex"
                ml="16px"
                color={Colors.base.middleGray}
                height="28px"
                borderBottom={`1px solid ${Colors.background.silver}`}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width="152px"
                  borderRight={`1px solid ${Colors.background.silver}`}
                  pl="8px"
                >
                  <Typography fontSize="xs" lineheight="12px" fontWeight="bold">
                    ユーザー名
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" flex={1} pl="12px">
                  <Typography fontSize="xs" lineheight="12px" fontWeight="bold">
                    送信者名
                  </Typography>
                </Box>
              </Box>
              <Box height="calc(100% - 160px)" overflow="auto">
                {isLoading && (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: '16px' }}>
                    <CircularProgress size={32} />
                  </Box>
                )}
                {userEmailSettings?.map((user, i) => (
                  <Box key={i}>
                    <SenderName
                      userAvatarUrl={user?.user?.avatar?.url}
                      userName={user?.user?.displayName}
                      senderName={user?.subjectName}
                      onClick={handleOpenModal(user)}
                      hasEditPermission={isUpdatable}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                maxWidth: 324,
                height: 353,
                overflow: 'hidden'
              }}
              ml="32px"
            >
              <Image src={'/images/sender-image.png'} alt="no_result" layout="fill" objectFit="contain" />
            </Box>
          </Box>
        </Box>
      </LayoutTeam>
      <ModalChangeSenderName
        senderName={userInfo?.subjectName}
        open={Boolean(userInfo)}
        onClose={() => setUserInfo(undefined)}
        onCancel={() => setUserInfo(undefined)}
        onConfirm={handleConfirm}
      />
    </>
  )
}
