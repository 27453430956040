/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumRecordPorterHistoryAction,
  EnumRecordPorterHistoryActionFromJSON,
  EnumRecordPorterHistoryActionFromJSONTyped,
  EnumRecordPorterHistoryActionToJSON
} from './EnumRecordPorterHistoryAction'
import {
  EnumRecordPorterHistoryOperation,
  EnumRecordPorterHistoryOperationFromJSON,
  EnumRecordPorterHistoryOperationFromJSONTyped,
  EnumRecordPorterHistoryOperationToJSON
} from './EnumRecordPorterHistoryOperation'
import {
  EnumRecordPorterHistoryProgressStatus,
  EnumRecordPorterHistoryProgressStatusFromJSON,
  EnumRecordPorterHistoryProgressStatusFromJSONTyped,
  EnumRecordPorterHistoryProgressStatusToJSON
} from './EnumRecordPorterHistoryProgressStatus'
import {
  EnumRecordPorterHistoryStep,
  EnumRecordPorterHistoryStepFromJSON,
  EnumRecordPorterHistoryStepFromJSONTyped,
  EnumRecordPorterHistoryStepToJSON
} from './EnumRecordPorterHistoryStep'
import {
  RecordPorterHistoryContactListsInner,
  RecordPorterHistoryContactListsInnerFromJSON,
  RecordPorterHistoryContactListsInnerFromJSONTyped,
  RecordPorterHistoryContactListsInnerToJSON
} from './RecordPorterHistoryContactListsInner'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface RecordPorterHistory
 */
export interface RecordPorterHistory {
  /**
   *
   * @type {string}
   * @memberof RecordPorterHistory
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof RecordPorterHistory
   */
  organizationId?: string
  /**
   *
   * @type {string}
   * @memberof RecordPorterHistory
   */
  originalFileName?: string | null
  /**
   *
   * @type {string}
   * @memberof RecordPorterHistory
   */
  outputFileName?: string | null
  /**
   *
   * @type {number}
   * @memberof RecordPorterHistory
   */
  totalCount?: number | null
  /**
   *
   * @type {number}
   * @memberof RecordPorterHistory
   */
  successCount?: number | null
  /**
   *
   * @type {number}
   * @memberof RecordPorterHistory
   */
  errorCount?: number | null
  /**
   *
   * @type {EnumRecordPorterHistoryOperation}
   * @memberof RecordPorterHistory
   */
  operation?: EnumRecordPorterHistoryOperation
  /**
   *
   * @type {EnumRecordPorterHistoryStep}
   * @memberof RecordPorterHistory
   */
  step?: EnumRecordPorterHistoryStep
  /**
   *
   * @type {EnumRecordPorterHistoryProgressStatus}
   * @memberof RecordPorterHistory
   */
  progressStatus?: EnumRecordPorterHistoryProgressStatus
  /**
   *
   * @type {EnumRecordPorterHistoryAction}
   * @memberof RecordPorterHistory
   */
  action?: EnumRecordPorterHistoryAction | null
  /**
   *
   * @type {string}
   * @memberof RecordPorterHistory
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof RecordPorterHistory
   */
  updatedAt?: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof RecordPorterHistory
   */
  user?: UserForDisplay
  /**
   *
   * @type {boolean}
   * @memberof RecordPorterHistory
   */
  hasNextRecordPorterHistory?: boolean
  /**
   *
   * @type {Array<RecordPorterHistoryContactListsInner>}
   * @memberof RecordPorterHistory
   */
  contactLists?: Array<RecordPorterHistoryContactListsInner>
  /**
   *
   * @type {Array<RecordPorterHistoryContactListsInner>}
   * @memberof RecordPorterHistory
   */
  companyLists?: Array<RecordPorterHistoryContactListsInner>
  /**
   *
   * @type {any}
   * @memberof RecordPorterHistory
   */
  file?: any
}

export function RecordPorterHistoryFromJSON(json: any): RecordPorterHistory {
  return RecordPorterHistoryFromJSONTyped(json, false)
}

export function RecordPorterHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordPorterHistory {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    originalFileName: !exists(json, 'original_file_name') ? undefined : json['original_file_name'],
    outputFileName: !exists(json, 'output_file_name') ? undefined : json['output_file_name'],
    totalCount: !exists(json, 'total_count') ? undefined : json['total_count'],
    successCount: !exists(json, 'success_count') ? undefined : json['success_count'],
    errorCount: !exists(json, 'error_count') ? undefined : json['error_count'],
    operation: !exists(json, 'operation') ? undefined : EnumRecordPorterHistoryOperationFromJSON(json['operation']),
    step: !exists(json, 'step') ? undefined : EnumRecordPorterHistoryStepFromJSON(json['step']),
    progressStatus: !exists(json, 'progress_status')
      ? undefined
      : EnumRecordPorterHistoryProgressStatusFromJSON(json['progress_status']),
    action: !exists(json, 'action') ? undefined : EnumRecordPorterHistoryActionFromJSON(json['action']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    hasNextRecordPorterHistory: !exists(json, 'has_next_record_porter_history')
      ? undefined
      : json['has_next_record_porter_history'],
    contactLists: !exists(json, 'contact_lists')
      ? undefined
      : (json['contact_lists'] as Array<any>).map(RecordPorterHistoryContactListsInnerFromJSON),
    companyLists: !exists(json, 'company_lists')
      ? undefined
      : (json['company_lists'] as Array<any>).map(RecordPorterHistoryContactListsInnerFromJSON),
    file: !exists(json, 'file') ? undefined : json['file']
  }
}

export function RecordPorterHistoryToJSON(value?: RecordPorterHistory | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    organization_id: value.organizationId,
    original_file_name: value.originalFileName,
    output_file_name: value.outputFileName,
    total_count: value.totalCount,
    success_count: value.successCount,
    error_count: value.errorCount,
    operation: EnumRecordPorterHistoryOperationToJSON(value.operation),
    step: EnumRecordPorterHistoryStepToJSON(value.step),
    progress_status: EnumRecordPorterHistoryProgressStatusToJSON(value.progressStatus),
    action: EnumRecordPorterHistoryActionToJSON(value.action),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    user: UserForDisplayToJSON(value.user),
    has_next_record_porter_history: value.hasNextRecordPorterHistory,
    contact_lists:
      value.contactLists === undefined
        ? undefined
        : (value.contactLists as Array<any>).map(RecordPorterHistoryContactListsInnerToJSON),
    company_lists:
      value.companyLists === undefined
        ? undefined
        : (value.companyLists as Array<any>).map(RecordPorterHistoryContactListsInnerToJSON),
    file: value.file
  }
}
