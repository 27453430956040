import { Box, Popover } from '@material-ui/core'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Button, Colors, DynamicMuiIcon, Input, ToggleSwitch, Typography, Tooltip } from 'src/components/atoms'
import { DraggableData } from 'src/components/molecules'
import { ModalSelectAttribute } from '../..'
import { Document, DocumentShareForm } from '@noco/http-client/lib/noco'
import { useShareDocumentByLink } from 'src/fixtures/modules/documents/hooks'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { createDraggableSettingList } from 'src/fixtures/modules/contact/utils'
import { useGetMe } from 'src/fixtures/modules/me/hooks'

import { ShareLinkFormUserSelectSection } from 'src/components/organisms/ShareLinkFormUserSelectSection'
import { ShareLinkFormAuthCodeSection } from 'src/components/organisms/ShareLinkFormAuthCodeSection'
import { ShareLinkFormExpiredOnSection } from 'src/components/organisms/ShareLinkFormExpiredOnSection'
import { ShareLinkFormContactFormSection } from 'src/components/organisms/ShareLinkFormContactFormSection'
import { getShareUrl } from 'src/fixtures/utils/url'
import { pushUserActionDataLayer } from 'src/utils/gtm'

export interface DocumentShareWithLinkProps {
  document: Document
  onClick?: () => void
  onSubmit?: () => void
  onError?: () => void
  setInstructions?: React.Dispatch<React.SetStateAction<string[] | undefined>>
}

const getSendShareFormData = (forms: DraggableData[]) => {
  return forms
    .filter(item => item.isSelected)
    .map(item => ({
      contactSettingId: item.id,
      isRequired: item.switchValue || false
    }))
}

export const DocumentShareWithLink = React.forwardRef<HTMLButtonElement, DocumentShareWithLinkProps>(
  function DocumentShareWithLink({ document, onError, onSubmit, setInstructions }, ref) {
    const [formItems, setFormItems] = useState<DraggableData[]>([])
    const [documentShareForms, setDocumentShareForms] = useState<DocumentShareForm[]>(
      document.documentShare?.documentShareForms!
    )
    const { data: me } = useGetMe()
    const [userId, setUserId] = useState<string>(me?.user?.id!)
    const { handleUpdateShareDocumentByLink, isLoading, error, instructions } = useShareDocumentByLink(document.id!)
    const [isOpenModalSelectAttribute, setIsOpenModalSelectAttribute] = useState<boolean>(false)
    const [checked, setChecked] = useState(false)
    const [isActiveForm, setIsActiveForm] = useState<boolean>(false)
    const [isAuthCodeActive, setIsAuthCodeActive] = useState<boolean>(true)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmitEnd, setIsSubmitEnd] = useState(false)

    const [isSubmittingFormActive, setIsSubmittingFormActive] = useState(false)
    const [isSubmittingAuthCodeActive, setIsSubmittingAuthCodeActive] = useState(false)

    const [hasExpiredOn, setHasExpiredOn] = useState(!!document?.documentShare?.expiredOn)

    const [expiredValue, setExpiredValue] = useState<string | null>(document.documentShare?.expiredOn!)
    const { data: responseContactSettings } = useListContactSettings()
    const [isInitialized, setIsInitialized] = useState(false)

    const shareUrl = useMemo(() => {
      return getShareUrl(document.documentShare?.url || '')
    }, [document.documentShare?.url])

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleOpenSetting = useCallback(() => {
      setIsOpenModalSelectAttribute(true)
    }, [])
    const handleCloseSetting = useCallback(() => {
      setIsOpenModalSelectAttribute(false)
    }, [])

    const copyTextToClipboard = useCallback((event: React.MouseEvent<HTMLButtonElement>, text: string) => {
      navigator.clipboard.writeText(text)
      setAnchorEl(event.currentTarget)
    }, [])

    const handleClosePopover = () => {
      setAnchorEl(null)
    }

    const formData = useMemo(() => {
      const documentShareForms = getSendShareFormData(formItems)
      return {
        isActive: checked,
        isFormActive: isActiveForm,
        isAuthCodeActive: isAuthCodeActive,
        documentShareForms,
        expiredOn: expiredValue,
        userId: userId
      }
    }, [formItems, checked, isActiveForm, isAuthCodeActive, expiredValue, userId])

    const handleCalendarConfirm = useCallback(
      selectedExpiredValue => {
        setExpiredValue(selectedExpiredValue)
        handleUpdateShareDocumentByLink(
          { documentShare: { ...formData, expiredOn: selectedExpiredValue } },
          '閲覧期限を設定しました'
        )
      },
      [formData, handleUpdateShareDocumentByLink]
    )

    const handleConfirm = useCallback(
      (items: DraggableData[]) => {
        setFormItems(items)
        const documentShareForms = items
          .filter(item => item.isSelected)
          .map(item => ({
            contactSettingId: item.id,
            isRequired: item.switchValue || false
          }))

        handleUpdateShareDocumentByLink(
          { documentShare: { ...formData, documentShareForms } },
          'フォームを編集しました'
        )
        handleCloseSetting()
      },
      [handleUpdateShareDocumentByLink, formData, handleCloseSetting]
    )

    const handleUpdateUserId = useCallback(
      (userId: string) => {
        setUserId(userId)
        handleUpdateShareDocumentByLink({ documentShare: { ...formData, userId } }, '担当者を設定しました')
      },
      [formData, handleUpdateShareDocumentByLink]
    )

    const handleUpdateHasExpired = useCallback(
      (isActive: boolean) => {
        // MEMO: 閲覧期限は閲覧期限をカレンダーピッカーで設定して初めてデータが送られる
        setHasExpiredOn(isActive)
        if (!isActive) {
          setExpiredValue(null)
          handleUpdateShareDocumentByLink(
            { documentShare: { ...formData, expiredOn: null } },
            '閲覧期限を無しに設定しました'
          )
        }
      },
      [formData, handleUpdateShareDocumentByLink]
    )

    useEffect(() => {
      setDocumentShareForms(document.documentShare?.documentShareForms || [])
    }, [document])

    useEffect(() => {
      if (document?.documentShare && !isInitialized) {
        setChecked(document.documentShare.isActive || false)
        setIsActiveForm(document.documentShare.isFormActive || false)
        setIsAuthCodeActive(document.documentShare.isAuthCodeActive ?? true)
        setHasExpiredOn(!!document.documentShare.expiredOn || false)
        setExpiredValue(document.documentShare.expiredOn || null)
        setUserId(document.documentShare.user!.id!)
        setIsInitialized(true)
      }
    }, [document, isInitialized])

    useEffect(() => {
      if (me?.user?.id && !document.documentShare?.user) {
        setUserId(me?.user?.id)
      }
    }, [document.documentShare?.user, me?.user?.id])

    // 編集完了
    useEffect(() => {
      if (!isLoading && isSubmitting && !isSubmitEnd && error) {
        setIsSubmitting(false)
        onError?.()
      } else if (!isLoading && isSubmitting && !isSubmitEnd && !error) {
        setIsSubmitEnd(true)
        onSubmit?.()
      } else if (!isLoading && isSubmitting && isSubmitEnd && !error) {
        setIsSubmitting(false)
        setIsSubmitEnd(false)
      }
    }, [isLoading, isSubmitting, isSubmitEnd, error, onError, onSubmit])

    // 設定されているものがあれば再度マッピングし直す
    useEffect(() => {
      if (responseContactSettings) {
        let settingList = createDraggableSettingList(responseContactSettings, { hideEdit: true })
        if (documentShareForms.length > 0) {
          settingList = settingList.map(item => ({ ...item, isSelected: false }))
          documentShareForms.forEach(item => {
            const isInputRequired = item.contactSetting?.contactSettingFields?.some(
              item => item.isInputRequiredOnEu || item.isInputRequired
            )
            const index = settingList.findIndex(s => s.id === item.contactSetting?.id)
            if (index != -1) {
              settingList[index] = {
                ...settingList[index],
                isSelected: true,
                sort: item.sort || settingList[index]?.sort,
                switchDisabled: isInputRequired,
                switchValue: isInputRequired ? true : item.isRequired
              }
            }
          })

          settingList = settingList.sort((a, b) => a.sort - b.sort)
        }
        setFormItems(settingList)
      }
    }, [documentShareForms, responseContactSettings])

    useEffect(() => {
      setInstructions?.(instructions)
      // site に属しているため、off にできない
      if (instructions?.includes('document_belongs_to_open_site')) {
        setChecked(true)
      }
    }, [instructions, setInstructions])

    // リンクの共有のトグルがあった時
    useEffect(() => {
      if (isSubmitting) {
        handleUpdateShareDocumentByLink(
          { documentShare: { ...formData, isActive: checked } },
          checked ? 'リンク共有をオンにしました' : 'リンク共有をオフにしました'
        )
        setIsSubmitting(false)
      }
    }, [checked, formData, handleUpdateShareDocumentByLink, isSubmitting])

    // 顧客情報のフォーム設定のトグルがあった時
    useEffect(() => {
      if (isSubmittingFormActive) {
        if (isAuthCodeActive && !isActiveForm) {
          setIsAuthCodeActive(false)
          handleUpdateShareDocumentByLink(
            { documentShare: { ...formData, isAuthCodeActive: false } },
            'セキュリティコードをオフにしました'
          ).then(() => {
            handleUpdateShareDocumentByLink(
              { documentShare: { ...formData, isFormActive: false, isAuthCodeActive: false } },
              'フォーム設定をオフにしました'
            )
          })
        } else {
          handleUpdateShareDocumentByLink(
            { documentShare: { ...formData, isFormActive: isActiveForm, isAuthCodeActive: isAuthCodeActive } },
            isActiveForm ? 'フォーム設定をオンにしました' : 'フォーム設定をオフにしました'
          )
        }
        setIsSubmittingFormActive(false)
      }
    }, [isActiveForm, isSubmittingFormActive, handleUpdateShareDocumentByLink, isAuthCodeActive, formData])

    // 認証コードのトグルがあった時
    useEffect(() => {
      if (isSubmittingAuthCodeActive) {
        handleUpdateShareDocumentByLink(
          { documentShare: { ...formData, isAuthCodeActive: isAuthCodeActive } },
          isAuthCodeActive ? 'セキュリティコードをオンにしました' : 'セキュリティコードをオフにしました'
        )
        setIsSubmittingAuthCodeActive(false)
      }
    }, [isAuthCodeActive, isSubmittingAuthCodeActive, handleUpdateShareDocumentByLink, formData])

    return (
      <>
        <form color={Colors.base.black}>
          <Box display="flex" alignItems="center">
            <ToggleSwitch
              checked={checked}
              onChange={() => {
                setIsSubmitting(true)
                setChecked(v => !v)
              }}
            />
            <Box width="12px" />
            <Box display="flex" alignItems="center">
              <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                リンクの共有
              </Typography>
              <Box mr="6px" />
              <Tooltip content={'誰でも閲覧可能な共有リンクを作成できます。'}>
                <DynamicMuiIcon variant="helpRounded" color="action" size="16px" />
              </Tooltip>
            </Box>
          </Box>
          {checked && (
            <>
              {/* リンクセクション */}
              <Box mt="26.5px">
                <Typography fontSize="s" lineheight="14px" fontWeight="bold">
                  こちらのリンクを共有してください。
                </Typography>
                <Box display="flex" alignItems="center" mt="6px">
                  <Input
                    backgroundColor={`${Colors.background.lightGray} !important`}
                    readonly
                    value={shareUrl}
                    name="shareLink"
                    fullWidth
                  />
                  <Box flexShrink={0} ml="12px">
                    <Button
                      title="リンクをコピー"
                      startIcon={<DynamicMuiIcon variant="copy" size="20px" />}
                      onClick={e => {
                        pushUserActionDataLayer('document', 'copy_shared_link')
                        copyTextToClipboard(e, shareUrl || '')
                      }}
                    />
                  </Box>
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                >
                  <Box p="2px 4px">
                    <Typography variant="body2">コピーしました！</Typography>
                  </Box>
                </Popover>
              </Box>

              <Box borderBottom={`1px solid ${Colors.background.silver}`} my="24px" />

              {/* オプション設定 */}
              <Box component={'section'}>
                <Box display="flex" alignItems="center" mb="4px">
                  <DynamicMuiIcon variant="setting" color="action" size="20px" />
                  <Box mr="6px" />
                  <Typography variant="h5" fontSize="s" lineheight="14px">
                    オプション設定
                  </Typography>
                </Box>
                {/* 担当者セクション */}
                <ShareLinkFormUserSelectSection value={userId} onChange={handleUpdateUserId} />
                <Box height="12px" />
                {/* 認証コードセクション */}
                <ShareLinkFormAuthCodeSection
                  isActive={isAuthCodeActive}
                  disabled={!isActiveForm}
                  onChangeActive={() => {
                    setIsSubmittingAuthCodeActive(true)
                    setIsAuthCodeActive(v => !v)
                  }}
                />
                <Box height="12px" />
                {/* 閲覧期限セクション */}
                <ShareLinkFormExpiredOnSection
                  expiredOn={expiredValue}
                  isActive={hasExpiredOn}
                  onChangeExpiredOn={handleCalendarConfirm}
                  onChangeIsActive={handleUpdateHasExpired}
                />
                <Box height="12px" />

                {/* 顧客情報入力フォーム */}
                <ShareLinkFormContactFormSection
                  isActive={isActiveForm}
                  formList={documentShareForms}
                  onChangeActive={() => {
                    setIsSubmittingFormActive(true)
                    setIsActiveForm(v => !v)
                  }}
                  onOpenSettingForm={handleOpenSetting}
                />
              </Box>
            </>
          )}
          <button ref={ref} type="submit" hidden />
        </form>
        <ModalSelectAttribute
          open={isOpenModalSelectAttribute}
          items={formItems}
          onConfirm={handleConfirm}
          onClose={handleCloseSetting}
          kind="document"
        />
      </>
    )
  }
)
