/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  MaterialableFilter,
  MaterialableFilterFromJSON,
  MaterialableFilterFromJSONTyped,
  MaterialableFilterToJSON
} from './MaterialableFilter'

/**
 *
 * @export
 * @interface GetDocumentFilterResponseData
 */
export interface GetDocumentFilterResponseData {
  /**
   *
   * @type {MaterialableFilter}
   * @memberof GetDocumentFilterResponseData
   */
  filter?: MaterialableFilter
}

export function GetDocumentFilterResponseDataFromJSON(json: any): GetDocumentFilterResponseData {
  return GetDocumentFilterResponseDataFromJSONTyped(json, false)
}

export function GetDocumentFilterResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentFilterResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    filter: !exists(json, 'filter') ? undefined : MaterialableFilterFromJSON(json['filter'])
  }
}

export function GetDocumentFilterResponseDataToJSON(value?: GetDocumentFilterResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    filter: MaterialableFilterToJSON(value.filter)
  }
}
