/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSON, GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerToJSON } from './GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner';
export function GetEuOrganizationPolicySettingsResponseDataFromJSON(json) {
    return GetEuOrganizationPolicySettingsResponseDataFromJSONTyped(json, false);
}
export function GetEuOrganizationPolicySettingsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        policySettings: !exists(json, 'policy_settings')
            ? undefined
            : json['policy_settings'].map(GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSON)
    };
}
export function GetEuOrganizationPolicySettingsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        policy_settings: value.policySettings === undefined
            ? undefined
            : value.policySettings.map(GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerToJSON)
    };
}
