import { Box, Typography } from '@material-ui/core'
import { Button, Colors } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { IconEditProps } from './type'

export const IconEdit = ({ image, onChange, error }: IconEditProps) => {
  const globalClasses = useGlobalStyles()
  return (
    <>
      <Box maxWidth="210px" height="60px" mb="16px">
        <img src={image} alt="team-icon" className={globalClasses.imgContainLeft} />
      </Box>
      <Button kind="secondary" variant="outlined" title="画像をアップロード" onClick={onChange} />
      <Typography variant="body2" color="error">
        {error?.message}
      </Typography>
      <Box color={Colors.base.middleGray} mt="6px">
        <Typography variant="subtitle2">※画像サイズは縦90px以上が推奨です</Typography>
      </Box>
    </>
  )
}
