/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ActivityStatusObject,
  ActivityStatusObjectFromJSON,
  ActivityStatusObjectFromJSONTyped,
  ActivityStatusObjectToJSON
} from './ActivityStatusObject'
import {
  CompanyForDisplay,
  CompanyForDisplayFromJSON,
  CompanyForDisplayFromJSONTyped,
  CompanyForDisplayToJSON
} from './CompanyForDisplay'
import {
  ContactForDisplay,
  ContactForDisplayFromJSON,
  ContactForDisplayFromJSONTyped,
  ContactForDisplayToJSON
} from './ContactForDisplay'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'
import {
  ViewingStatusObject,
  ViewingStatusObjectFromJSON,
  ViewingStatusObjectFromJSONTyped,
  ViewingStatusObjectToJSON
} from './ViewingStatusObject'

/**
 *
 * @export
 * @interface MaterialableViewHistory
 */
export interface MaterialableViewHistory {
  /**
   *
   * @type {ContactForDisplay}
   * @memberof MaterialableViewHistory
   */
  contact?: ContactForDisplay
  /**
   *
   * @type {CompanyForDisplay}
   * @memberof MaterialableViewHistory
   */
  company?: CompanyForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof MaterialableViewHistory
   */
  user?: UserForDisplay
  /**
   *
   * @type {Array<ActivityStatusObject>}
   * @memberof MaterialableViewHistory
   */
  activityStatusObjects?: Array<ActivityStatusObject>
  /**
   *
   * @type {ViewingStatusObject}
   * @memberof MaterialableViewHistory
   */
  viewingStatusObject?: ViewingStatusObject
}

export function MaterialableViewHistoryFromJSON(json: any): MaterialableViewHistory {
  return MaterialableViewHistoryFromJSONTyped(json, false)
}

export function MaterialableViewHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialableViewHistory {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contact: !exists(json, 'contact') ? undefined : ContactForDisplayFromJSON(json['contact']),
    company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company']),
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    activityStatusObjects: !exists(json, 'activity_status_objects')
      ? undefined
      : (json['activity_status_objects'] as Array<any>).map(ActivityStatusObjectFromJSON),
    viewingStatusObject: !exists(json, 'viewing_status_object')
      ? undefined
      : ViewingStatusObjectFromJSON(json['viewing_status_object'])
  }
}

export function MaterialableViewHistoryToJSON(value?: MaterialableViewHistory | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact: ContactForDisplayToJSON(value.contact),
    company: CompanyForDisplayToJSON(value.company),
    user: UserForDisplayToJSON(value.user),
    activity_status_objects:
      value.activityStatusObjects === undefined
        ? undefined
        : (value.activityStatusObjects as Array<any>).map(ActivityStatusObjectToJSON),
    viewing_status_object: ViewingStatusObjectToJSON(value.viewingStatusObject)
  }
}
