import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useMemo } from 'react'
import { Typography } from 'src/components/atoms'
import { useGetOrganization, useListOrganizationAdministrators } from 'src/fixtures/modules/organization/hooks'
import { getUserFullName } from 'src/fixtures/modules/user/utils'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '56px',
      lineHeight: '84px',
      fontWeight: 'bold',
      whiteSpace: 'pre-line'
    },
    list: {
      paddingInlineStart: '24px'
    }
  })
)

interface Administrator {
  fullName: string
  encryptedEmail: string
}

export const PagePermissionError = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { data: res } = useGetOrganization()
  const orgs = res?.organization
  const { data: dataUsers } = useListOrganizationAdministrators()
  const administratorList: Administrator[] = useMemo(() => {
    return (
      dataUsers?.users?.map(user => {
        return {
          fullName: getUserFullName(user),
          encryptedEmail: user.encryptedEmail || ''
        }
      }) || []
    )
  }, [dataUsers?.users])

  return (
    <Box width="100vw" height="100vh" maxWidth="1180px" p="40px" margin="0 auto">
      <Box position="relative" width={1} height={1}>
        <img src="/images/nocosell_logo.svg" alt="logo" width="104px" height="30px" />
        <Box mt="60px" px="100px">
          <Typography variant="h1" className={classes.title}>
            閲覧権限がないため{`\n`}ページにアクセスできません
          </Typography>
          <Box height="24px" />
          <Typography fontSize="m" lineheight="27.2px" letterSpacing="tight">
            チーム「{orgs?.name}」の管理者にお問い合わせください
          </Typography>
          <Box height="24px" />
          <Typography fontSize="m" lineheight="27.2px" letterSpacing="tight" fontWeight="bold">
            管理者
          </Typography>
          <ul className={classes.list}>
            {administratorList.map((administrator, index) => (
              <li key={index}>
                <Typography fontSize="m" lineheight="27.2px" letterSpacing="tight">
                  {`${administrator.fullName} (${administrator.encryptedEmail}`}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Box position="absolute" zIndex={-1} right={0} bottom="30px" width="456px" height="314px">
          <img src="/images/errors/permission.svg" alt="errorImage" className={globalClasses.imgContain} />
        </Box>
      </Box>
    </Box>
  )
}
