/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateSiteSectionDocumentRequestSiteSectionDocument,
  CreateSiteSectionDocumentRequestSiteSectionDocumentFromJSON,
  CreateSiteSectionDocumentRequestSiteSectionDocumentFromJSONTyped,
  CreateSiteSectionDocumentRequestSiteSectionDocumentToJSON
} from './CreateSiteSectionDocumentRequestSiteSectionDocument'

/**
 *
 * @export
 * @interface CreateSiteSectionDocumentRequest
 */
export interface CreateSiteSectionDocumentRequest {
  /**
   *
   * @type {CreateSiteSectionDocumentRequestSiteSectionDocument}
   * @memberof CreateSiteSectionDocumentRequest
   */
  siteSectionDocument?: CreateSiteSectionDocumentRequestSiteSectionDocument
}

export function CreateSiteSectionDocumentRequestFromJSON(json: any): CreateSiteSectionDocumentRequest {
  return CreateSiteSectionDocumentRequestFromJSONTyped(json, false)
}

export function CreateSiteSectionDocumentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateSiteSectionDocumentRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteSectionDocument: !exists(json, 'site_section_document')
      ? undefined
      : CreateSiteSectionDocumentRequestSiteSectionDocumentFromJSON(json['site_section_document'])
  }
}

export function CreateSiteSectionDocumentRequestToJSON(value?: CreateSiteSectionDocumentRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_section_document: CreateSiteSectionDocumentRequestSiteSectionDocumentToJSON(value.siteSectionDocument)
  }
}
