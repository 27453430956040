/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateDocumentMessageRequestDocumentMessage,
  UpdateDocumentMessageRequestDocumentMessageFromJSON,
  UpdateDocumentMessageRequestDocumentMessageFromJSONTyped,
  UpdateDocumentMessageRequestDocumentMessageToJSON
} from './UpdateDocumentMessageRequestDocumentMessage'

/**
 *
 * @export
 * @interface UpdateDocumentMessageRequest
 */
export interface UpdateDocumentMessageRequest {
  /**
   *
   * @type {UpdateDocumentMessageRequestDocumentMessage}
   * @memberof UpdateDocumentMessageRequest
   */
  documentMessage?: UpdateDocumentMessageRequestDocumentMessage
}

export function UpdateDocumentMessageRequestFromJSON(json: any): UpdateDocumentMessageRequest {
  return UpdateDocumentMessageRequestFromJSONTyped(json, false)
}

export function UpdateDocumentMessageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentMessageRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentMessage: !exists(json, 'document_message')
      ? undefined
      : UpdateDocumentMessageRequestDocumentMessageFromJSON(json['document_message'])
  }
}

export function UpdateDocumentMessageRequestToJSON(value?: UpdateDocumentMessageRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_message: UpdateDocumentMessageRequestDocumentMessageToJSON(value.documentMessage)
  }
}
