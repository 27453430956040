import React, { useCallback } from 'react'
import { Box, createStyles, makeStyles, ButtonBase, Link } from '@material-ui/core'
import { Typography, Colors, DynamicMuiIcon, Button } from 'src/components/atoms'
import { useRouter } from 'next/router'
import { RecordPorterHistory, EnumRecordPorterHistoryStep } from '@noco/http-client/lib/noco'
import { useListFilterContacts, useListContactLists } from 'src/fixtures/modules/contact/hooks'
import { useListFilterCompany, useListCompanyLists } from 'src/fixtures/modules/company/hooks'

export interface ImportResultProps {
  result: RecordPorterHistory | undefined
  setImporting: (value: boolean) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    linefeed: {
      whiteSpace: 'pre-wrap'
    },
    textStyle: {
      color: Colors.accent.keyBlue.default,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '14px'
    },
    button: {
      display: 'block'
    },
    link: {
      color: Colors.accent.keyBlue.default,
      cursor: 'pointer',
      textDecoration: 'none',
      margin: '0 4px'
    }
  })
})

export const ImportResult = ({ result, setImporting }: ImportResultProps) => {
  const classes = useStyles()
  const router = useRouter()

  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContactLists } = useListContactLists()

  const { mutate: mutateListFilterCompany } = useListFilterCompany()
  const { mutate: mutateListCompanyLists } = useListCompanyLists()

  const onClickImport = useCallback(() => {
    setImporting(true)
  }, [setImporting])

  const handleClickContactsPageLink = useCallback(() => {
    mutateListFilterContacts()
    mutateListContactLists()
    router.push('/contacts')
  }, [router, mutateListFilterContacts, mutateListContactLists])

  const handleClickCompaniesPageLink = useCallback(() => {
    mutateListFilterCompany()
    mutateListCompanyLists()
    router.push('/companies')
  }, [router, mutateListFilterCompany, mutateListCompanyLists])

  // NOTE: @KaoriSakurai resultがundefinedの場合(import実行前)は表示する結果がないためreturn
  if (!result) {
    return <></>
  }
  if (result.step === EnumRecordPorterHistoryStep.FileCheck) {
    return (
      <Box display="flex" flexDirection="column" ml="32px">
        <Box display="flex" color={Colors.base.middleGray}>
          <Box color={Colors.accent.keyRed.default}>
            <DynamicMuiIcon variant="close" size="20px" />
          </Box>
          <Box ml="6px" />
          <Box className={classes.linefeed}>
            <Typography fontWeight={600} fontSize="s" lineheight="18.48px" letterSpacing="tight">
              ファイルのチェックに失敗しました
              <br />
              データの行数：{result.totalCount}
              <br />
              エラーの行数：{result.errorCount}
              <br />
              エラー理由を記載したファイルをダウンロードしてエラーの内容を修正してください
            </Typography>
          </Box>
        </Box>
        <Box mt="16px">
          <Link href={result.file.url} download>
            <Button
              kind="primary"
              title="エラー理由を記載したファイルをダウンロード"
              width="328px"
              typography={{
                fontSize: 's',
                fontWeight: 600,
                lineheight: '14px'
              }}
            />
          </Link>
          <Box mt="16px" />
          <ImportButton onClick={onClickImport} />
        </Box>
      </Box>
    )
  } else if (result.step === EnumRecordPorterHistoryStep.Import && result.errorCount) {
    return (
      <Box ml="32px">
        <Box display="flex" color={Colors.base.middleGray}>
          <Box color={Colors.accent.keyRed.default}>
            <DynamicMuiIcon variant="close" size="20px" />
          </Box>
          <Box ml="6px" />
          <Box className={classes.linefeed}>
            <Typography fontWeight={600} fontSize="s" lineheight="18.48px" letterSpacing="tight">
              ファイルのインポートに失敗しました
              <br />
              データの行数：{result.totalCount}
              <br />
              成功した行数：{result.successCount}
              <br />
              エラーの行数：{result.errorCount}
              <br />
              エラー理由を記載したファイルをダウンロードしてエラーの内容を修正してください
            </Typography>
          </Box>
        </Box>
        <Box mt="16px">
          <Link href={result.file.url} download>
            <Button
              kind="primary"
              title="エラー理由を記載したファイルをダウンロード"
              width="328px"
              typography={{
                fontSize: 's',
                fontWeight: 600,
                lineheight: '14px'
              }}
            />
          </Link>
          <Box mt="16px" />
          <ImportButton onClick={onClickImport} />
        </Box>
      </Box>
    )
  } else {
    return (
      <Box display="flex" flexDirection="column" ml="32px">
        <Box display="flex" alignItems="center" mt="16px" mb="18px" color={Colors.base.middleGray}>
          <Box color={Colors.option.green} display="flex" alignItems="center">
            <DynamicMuiIcon variant="checkCircle" size="20px" />
          </Box>
          <Box ml="6px">
            <Typography fontWeight={600} fontSize="s" lineheight="18.48px" letterSpacing="tight">
              ファイルのインポートに成功しました
            </Typography>
          </Box>
        </Box>
        {result.contactLists && result.contactLists.length > 0 && (
          <ButtonBase className={classes.button} onClick={handleClickContactsPageLink}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.textStyle}>インポートしたファイルを連絡先リストで確認</Typography>
              <Box color={Colors.accent.keyBlue.default}>
                <DynamicMuiIcon variant="arrowRight" size="28px" />
              </Box>
            </Box>
          </ButtonBase>
        )}
        {result.companyLists && result.companyLists.length > 0 && (
          <ButtonBase className={classes.button} onClick={handleClickCompaniesPageLink}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.textStyle}>インポートしたファイルを会社リストで確認</Typography>
              <Box color={Colors.accent.keyBlue.default}>
                <DynamicMuiIcon variant="arrowRight" size="28px" />
              </Box>
            </Box>
          </ButtonBase>
        )}
        <Box mt="16px" />
        <ImportButton onClick={onClickImport} />
      </Box>
    )
  }
}

const ImportButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    kind="primary"
    variant="outlined"
    title="別のファイルをインポートする"
    width="230px"
    typography={{
      fontSize: 's',
      fontWeight: 600,
      lineheight: '14px'
    }}
    onClick={onClick}
  />
)
