/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function OrganizationPaymentContractBasicInfoFromJSON(json) {
    return OrganizationPaymentContractBasicInfoFromJSONTyped(json, false);
}
export function OrganizationPaymentContractBasicInfoFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companyName: !exists(json, 'company_name') ? undefined : json['company_name'],
        postalCode: !exists(json, 'postal_code') ? undefined : json['postal_code'],
        prefectureCode: !exists(json, 'prefecture_code') ? undefined : json['prefecture_code'],
        city: !exists(json, 'city') ? undefined : json['city'],
        line1: !exists(json, 'line1') ? undefined : json['line1'],
        line2: !exists(json, 'line2') ? undefined : json['line2'],
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        phoneNumber: !exists(json, 'phone_number') ? undefined : json['phone_number'],
        contactEmail: !exists(json, 'contact_email') ? undefined : json['contact_email']
    };
}
export function OrganizationPaymentContractBasicInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company_name: value.companyName,
        postal_code: value.postalCode,
        prefecture_code: value.prefectureCode,
        city: value.city,
        line1: value.line1,
        line2: value.line2,
        first_name: value.firstName,
        last_name: value.lastName,
        phone_number: value.phoneNumber,
        contact_email: value.contactEmail
    };
}
