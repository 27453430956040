import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { LabelFormRow } from 'src/components/molecules/LabelFormRow'
import { useUpdateOrganization, useUpdateOrganizationAdvertisement } from 'src/fixtures/modules/organization/hooks'
import { useGlobalStyles } from 'src/styles/theme'
import { FormikValues, useFormik } from 'formik'
import { Button, Colors, Input, ToggleSwitch, Typography } from 'src/components/atoms'
import { ModalConfirmToPaidPlan } from 'src/components/modals'
import { IconEdit } from './IconEdit.tsx'
import { useInputImage } from 'src/fixtures/utils/image'
import Image from 'next/image'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import Link from 'next/link'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
import { TeamInfoListProps } from './type'

const useStyles = makeStyles(() => {
  return createStyles({
    defaultIcon: {
      display: 'inline-flex',
      verticalAlign: 'middle'
    },
    inlineBlock: {
      display: 'inline-block'
    }
  })
})

export const TeamInfoList = ({ organization }: TeamInfoListProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState<string>(organization.avatar?.url ? organization.avatar?.url : '')
  const { data: base64, handleInputImage, name, error, clear } = useInputImage()
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const { hasPermission: isUpdatable } = useFeatureFlag('team', 'update')
  const { handleUpdateOrganization } = useUpdateOrganization()
  const { handleUpdateOrganizationAdvertisement } = useUpdateOrganizationAdvertisement()

  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      name: organization.name || '',
      icon: organization.avatar?.url || ''
    },
    onSubmit: value => {
      if (image) {
        handleUpdateOrganization({ name: value.name }, { organization: { avatar: { data: base64, fileName: name } } })
      } else {
        handleUpdateOrganization({ name: value.name })
      }
    }
  })

  const onOpenModal = useCallback(() => setOpen(true), [])
  const onCloseModal = useCallback(() => setOpen(false), [])
  const handleAdsDisplay = useCallback(() => {
    if (!planGrade?.plan.canHideAd) {
      onOpenModal()
      return
    }
    handleUpdateOrganizationAdvertisement({
      organization: {
        hideAd: !organization.hideAd
      }
    })
  }, [planGrade?.plan.canHideAd, handleUpdateOrganizationAdvertisement, organization.hideAd, onOpenModal])

  useEffect(() => {
    if (base64) {
      setImage(base64)
    }
  }, [base64])

  const labelContents = useMemo(
    () => [
      {
        label: 'チームID',
        readElement: <Typography fontSize="s">{organization.organizationSubdomain}</Typography>,
        onCancel: formik.resetForm
      },
      {
        label: 'チーム名',
        readElement: <Typography fontSize="s">{organization.name}</Typography>,
        editElement: <Input {...formik?.getFieldProps('name')} />,
        onCancel: formik.resetForm
      },
      {
        label: 'アイコン',
        readElement: (
          <>
            {image ? (
              <Box maxWidth="210px" height="60px">
                <img src={image} alt="team-icon" className={globalClasses.imgContain} />
              </Box>
            ) : (
              <Box className={classes.defaultIcon}>
                <Image src={`/images/dummy-logo.png`} width={210} height={60} objectFit="contain" />
              </Box>
            )}
          </>
        ),
        editElement: <IconEdit image={image || `/images/dummy-logo.png`} error={error} onChange={handleInputImage} />,
        onCancel: () => {
          formik.resetForm()
          clear()
        }
      }
    ],
    [
      clear,
      error,
      organization.organizationSubdomain,
      organization.name,
      formik,
      image,
      globalClasses.imgContain,
      classes.defaultIcon,
      handleInputImage
    ]
  )

  return (
    <>
      {labelContents.map((item, i) => (
        <Box key={i} p="16px 12px" borderBottom={`1px solid ${Colors.background.silver}`}>
          <LabelFormRow
            label={item.label}
            onSave={formik.handleSubmit}
            onCancel={item.onCancel}
            readElement={item.readElement}
            editElement={item.editElement}
            hasEditPermission={isUpdatable}
          />
        </Box>
      ))}
      <Box p="16px 12px" borderBottom={`1px solid ${Colors.background.silver}`}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" width="150px" flexShrink={0} color={Colors.base.black}>
            <Typography variant="h5">広告の表示</Typography>
          </Box>
          <Box width={1}>
            <ToggleSwitch
              checked={!organization.hideAd}
              onChange={handleAdsDisplay}
              onLabel="表示"
              offLabel="非表示"
              width={80}
              disabled={!isUpdatable}
            />
            <Box
              maxWidth="664px"
              bgcolor={Colors.background.lightGray}
              border={`1px solid ${Colors.background.silver}`}
              borderRadius="6px"
              p="12px 23px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt="8px"
            >
              <Box width={1} mr="8px">
                <Typography variant="h4">貴社ブランド名で お客様にお届けしましょう</Typography>
                <Box my="8px">
                  <Typography fontSize="s" lineheight="132%" letterSpacing="tight">
                    有料プランをご利用いただくと、サービスロゴや広告が非表示になるほか、資料やメールに貴社名やロゴを表示できます
                  </Typography>
                </Box>
                <Link href="/setting/team/pricing">
                  <a>
                    <Button
                      title="プラン表を確認する"
                      kind="secondary"
                      variant="outlined"
                      className={classes.inlineBlock}
                    />
                  </a>
                </Link>
              </Box>
              <Box width="213px" height="159px" flexShrink={0}>
                <img src="/images/your_logo.png" alt="your-logo" className={globalClasses.imgCover} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={open}
        onClose={onCloseModal}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、広告の非表示に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText=""
      />
    </>
  )
}
