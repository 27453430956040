import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Svg = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#FF6600" />
      <path
        d="M33.6502 24.3607H32.9548C32.723 20.9631 30.7833 17.9595 27.9652 16.3099H30.4417C30.7223 17.0855 31.4543 17.6517 32.3204 17.6517C33.4184 17.6517 34.3089 16.7408 34.3089 15.6329C34.3089 14.525 33.4184 13.614 32.3204 13.614C31.4543 13.614 30.7223 14.1803 30.4417 14.9558H25.0007V14.2911C25.0007 13.9218 24.6957 13.614 24.3297 13.614H21.6702C21.3043 13.614 21.0115 13.9218 21.0115 14.2911V14.9558H15.5583C15.2899 14.1803 14.5579 13.614 13.6918 13.614C12.5938 13.614 11.691 14.525 11.691 15.6329C11.691 16.7408 12.5938 17.6517 13.6918 17.6517C14.5579 17.6517 15.2899 17.0855 15.5583 16.3099H18.047C15.2167 17.9595 13.277 20.9631 13.0574 24.3607H12.362C11.996 24.3607 11.691 24.6561 11.691 25.0254V27.7213C11.691 28.0906 11.996 28.3861 12.362 28.3861H15.0215C15.3875 28.3861 15.6803 28.0906 15.6803 27.7213V25.0254C15.6803 24.6561 15.3875 24.3607 15.0215 24.3607H14.4115C14.7043 20.6061 17.3882 17.4424 21.0115 16.5807V16.9747C21.0115 17.344 21.3043 17.6517 21.6702 17.6517H24.3297C24.6957 17.6517 25.0007 17.344 25.0007 16.9747V16.5807C28.6118 17.4424 31.2957 20.6061 31.5885 24.3607H30.9907C30.6125 24.3607 30.3197 24.6561 30.3197 25.0254V27.7213C30.3197 28.0906 30.6125 28.3861 30.9907 28.3861H33.6502C34.0162 28.3861 34.3089 28.0906 34.3089 27.7213V25.0254C34.3089 24.6561 34.0162 24.3607 33.6502 24.3607Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Svg
