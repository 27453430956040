/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BillingInvoiceDiscount
 */
export interface BillingInvoiceDiscount {
  /**
   * 割引金額
   * @type {number}
   * @memberof BillingInvoiceDiscount
   */
  amount: number
  /**
   * クーポン名
   * @type {string}
   * @memberof BillingInvoiceDiscount
   */
  couponName: string
}

export function BillingInvoiceDiscountFromJSON(json: any): BillingInvoiceDiscount {
  return BillingInvoiceDiscountFromJSONTyped(json, false)
}

export function BillingInvoiceDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingInvoiceDiscount {
  if (json === undefined || json === null) {
    return json
  }
  return {
    amount: json['amount'],
    couponName: json['coupon_name']
  }
}

export function BillingInvoiceDiscountToJSON(value?: BillingInvoiceDiscount | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    amount: value.amount,
    coupon_name: value.couponName
  }
}
