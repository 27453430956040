import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Note = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12105 6.10269V4.72511C6.12105 4.47406 6.13405 4.39034 6.1727 4.26155C6.276 3.90752 6.62477 3.64363 7.03795 3.64363C7.45114 3.64363 7.79979 3.91394 7.90309 4.26155C7.94185 4.39034 7.95485 4.47406 7.95485 4.72511V6.8494C7.95485 6.97819 7.95485 7.10687 7.92897 7.22281C7.858 7.54462 7.56743 7.83442 7.24455 7.90518C7.12837 7.93087 6.99919 7.93087 6.87001 7.93087H4.73932C4.48752 7.93087 4.40355 7.91803 4.27437 7.87938C3.92572 7.77639 3.6546 7.42879 3.6546 7.01684C3.6546 6.60478 3.92572 6.25717 4.27437 6.15419C4.40355 6.11554 4.48752 6.10269 4.73932 6.10269H6.12105ZM13.7143 15.7332H2.28575V5.69717C2.28575 5.56838 2.33095 5.45897 2.42137 5.36883L5.38498 2.41399C5.4754 2.32396 5.58513 2.27889 5.71431 2.27889H13.7143V15.7332ZM15.0121 0.0128444C14.954 0.0064221 14.8765 0 14.728 0H5.41075C5.30757 0 5.20427 0.0064221 5.13318 0.0128444C4.707 0.051493 4.32602 0.251044 4.02257 0.553581L0.555247 4.01051C0.251914 4.31316 0.0516485 4.69288 0.0129987 5.11778C0.00644201 5.18854 0 5.29153 0 5.39452V16.7438C0 16.8918 0.00644201 16.9691 0.0129987 17.0271C0.0645318 17.5099 0.503599 17.9475 0.987872 17.999C1.04608 18.0055 1.12349 18.012 1.27199 18.012H14.728C14.8765 18.012 14.954 18.0055 15.0121 17.999C15.4964 17.9475 15.9355 17.5099 15.9871 17.0271C15.9934 16.9691 16 16.8918 16 16.7438V1.26818C16 1.12012 15.9934 1.04282 15.9871 0.984909C15.9355 0.502088 15.4964 0.0643376 15.0121 0.0128444Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default Note
