/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPolicySetting,
  OrganizationPolicySettingFromJSON,
  OrganizationPolicySettingFromJSONTyped,
  OrganizationPolicySettingToJSON
} from './OrganizationPolicySetting'

/**
 *
 * @export
 * @interface UpdateOrganizationPolicySettingResponseData
 */
export interface UpdateOrganizationPolicySettingResponseData {
  /**
   *
   * @type {OrganizationPolicySetting}
   * @memberof UpdateOrganizationPolicySettingResponseData
   */
  organizationPolicySetting?: OrganizationPolicySetting
}

export function UpdateOrganizationPolicySettingResponseDataFromJSON(
  json: any
): UpdateOrganizationPolicySettingResponseData {
  return UpdateOrganizationPolicySettingResponseDataFromJSONTyped(json, false)
}

export function UpdateOrganizationPolicySettingResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPolicySettingResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationPolicySetting: !exists(json, 'organization_policy_setting')
      ? undefined
      : OrganizationPolicySettingFromJSON(json['organization_policy_setting'])
  }
}

export function UpdateOrganizationPolicySettingResponseDataToJSON(
  value?: UpdateOrganizationPolicySettingResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_policy_setting: OrganizationPolicySettingToJSON(value.organizationPolicySetting)
  }
}
