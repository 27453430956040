import { Box } from '@material-ui/core'
import React, { useState } from 'react'
import { OptionListType, SelectBox, Tooltip, Typography } from 'src/components/atoms'
import { ModalConfirmToPaidPlan } from 'src/components/modals'
import { SelectDatePicker } from 'src/components/molecules'
import { ShareLinkFormSection } from 'src/components/molecules/ShareLinkFormSection'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'

export interface ShareLinkFormExpiredOnSectionProps {
  expiredOn: string | null
  isActive: boolean
  onChangeExpiredOn: (expiredOn: string | null) => void
  onChangeIsActive: (isActive: boolean) => void
}

const optionAuthList: OptionListType[] = [
  {
    value: 'disactive',
    label: '閲覧可（期限なし）'
  },
  {
    value: 'active',
    label: '閲覧可（期限あり）'
  }
]

export const ShareLinkFormExpiredOnSection = ({
  expiredOn,
  isActive,
  onChangeExpiredOn,
  onChangeIsActive
}: ShareLinkFormExpiredOnSectionProps) => {
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)

  return (
    <>
      <ShareLinkFormSection>
        <Box width="fit-content">
          <Tooltip
            content={
              '資料の閲覧期限が設定できます。資料の共有後に期限を変更する場合は、資料サイトの「連絡先」タブから設定できます。'
            }
          >
            <Typography variant="h6" fontSize="s" lineheight="14px" letterSpacing="none">
              閲覧可能な期間を設定
            </Typography>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center" mt="8px" position="relative">
          {!planGrade?.plan.canSetMaterialPublicationDue && (
            <Box
              width="100%"
              height="100%"
              zIndex={999}
              position="absolute"
              top={0}
              onClick={() => {
                setOpenModalUpgrade(true)
              }}
              style={{
                cursor: 'pointer'
              }}
            />
          )}

          <SelectBox
            value={isActive ? 'active' : 'disactive'}
            name="term"
            optionList={optionAuthList}
            onChange={e => onChangeIsActive(e.target.value === 'active')}
            height="34px"
          />
          <Box mr="24px" />
          <SelectDatePicker disabled={!isActive} expiredValue={expiredOn} onConfirm={onChangeExpiredOn} />
        </Box>
      </ShareLinkFormSection>
      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
}
