import { Box } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon, Input, ToggleSwitch, Typography, Toaster, Tooltip } from 'src/components/atoms'
import { DraggableData } from 'src/components/molecules'
import { ModalConfirmToPaidPlan, ModalSelectAttribute } from 'src/components/modals'
import { DocumentShareForm, Site } from '@noco/http-client/lib/noco'
import { useShareSiteByLink } from 'src/fixtures/modules/site/hooks'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { createDraggableSettingList } from 'src/fixtures/modules/contact/utils'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'

import { ShareLinkFormUserSelectSection } from 'src/components/organisms/ShareLinkFormUserSelectSection'
import { ShareLinkFormAuthCodeSection } from 'src/components/organisms/ShareLinkFormAuthCodeSection'
import { ShareLinkFormExpiredOnSection } from 'src/components/organisms/ShareLinkFormExpiredOnSection'
import { ShareLinkFormContactFormSection } from 'src/components/organisms/ShareLinkFormContactFormSection'
import { getShareUrl } from 'src/fixtures/utils/url'
import { pushUserActionDataLayer } from 'src/utils/gtm'

export interface SiteShareWithLinkProps {
  site: Site
  onSubmit?: () => void
  onError?: () => void
  onSuccess?: () => void
  setInstructions?: React.Dispatch<React.SetStateAction<string[] | undefined>>
}

const getSendShareFormData = (forms: DraggableData[]) => {
  return forms
    .filter(item => item.isSelected)
    .map(item => ({
      contactSettingId: item.id,
      isRequired: item.switchValue || false
    }))
}

export const SiteShareWithLink = React.forwardRef<HTMLButtonElement, SiteShareWithLinkProps>(function SiteShareWithLink(
  { site, onError, onSubmit, setInstructions },
  ref
) {
  //- 共通
  const [userId, setUserId] = useState('')
  const [checked, setChecked] = useState(false)
  const { data: me } = useGetMe()
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const [isActiveForm, setIsActiveForm] = useState(false)
  const [isAuthCodeActive, setIsAuthCodeActive] = useState<boolean>(true)
  const [isSubmittingFormActive, setIsSubmittingFormActive] = useState(false)
  const [isSubmittingAuthCodeActive, setIsSubmittingAuthCodeActive] = useState(false)
  const [hasExpiredOn, setHasExpiredOn] = useState(!!site.siteShare?.expiredOn)
  const [isOpenModalSelectAttribute, setIsOpenModalSelectAttribute] = useState<boolean>(false)
  const [formItems, setFormItems] = useState<DraggableData[]>([])
  const [siteShareForms, setSiteShareForms] = useState<DocumentShareForm[]>(site.siteShare?.siteShareForms!)
  const { data: responseContactSettings } = useListContactSettings()
  const { handleUpdateShareSiteByLink, isLoading, error, instructions } = useShareSiteByLink(site.id!)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitEnd, setIsSubmitEnd] = useState(false)
  const shareUrl = useMemo(() => {
    return getShareUrl(site.siteShare?.url || '')
  }, [site.siteShare?.url])

  useEffect(() => {
    setInstructions?.(instructions)
    if (instructions?.includes('site_has_restricted_document')) setChecked(false)
  }, [instructions, setInstructions])

  //- 閲覧可能な期限の設定
  const [expiredValue, setExpiredValue] = useState<string | null>(site.siteShare?.expiredOn!)
  const [isInitialized, setIsInitialized] = useState(false)

  const formData = useMemo(() => {
    const siteShareForms = getSendShareFormData(formItems)
    return {
      isActive: checked,
      isFormActive: isActiveForm,
      isAuthCodeActive: isAuthCodeActive,
      siteShareForms,
      expiredOn: expiredValue,
      userId: userId
    }
  }, [formItems, checked, isActiveForm, isAuthCodeActive, expiredValue, userId])

  const handleCalendarConfirm = useCallback(
    selectedExpiredValue => {
      setExpiredValue(selectedExpiredValue)
      handleUpdateShareSiteByLink(
        { siteShare: { ...formData, expiredOn: selectedExpiredValue } },
        '閲覧期限を設定しました'
      )
    },
    [formData, handleUpdateShareSiteByLink]
  )

  const handleOpenSetting = useCallback(() => {
    setIsOpenModalSelectAttribute(true)
  }, [])
  const handleCloseSetting = useCallback(() => {
    setIsOpenModalSelectAttribute(false)
  }, [])

  const handleUpdateUserId = useCallback(
    (userId: string) => {
      setUserId(userId)
      handleUpdateShareSiteByLink({ siteShare: { ...formData, userId } }, '担当者を設定しました')
    },
    [formData, handleUpdateShareSiteByLink]
  )
  const handleUpdateHasExpired = useCallback(
    (isActive: boolean) => {
      // MEMO: 閲覧期限は閲覧期限をカレンダーピッカーで設定して初めてデータが送られる
      setHasExpiredOn(isActive)
      if (!isActive) {
        handleUpdateShareSiteByLink({ siteShare: { ...formData, expiredOn: null } }, '閲覧期限を無しに設定しました')
      }
    },
    [formData, handleUpdateShareSiteByLink]
  )

  const handleConfirm = useCallback(
    (items: DraggableData[]) => {
      setFormItems(items)
      const siteShareForms = items
        .filter(item => item.isSelected)
        .map(item => ({
          contactSettingId: item.id,
          isRequired: item.switchValue || false
        }))

      handleUpdateShareSiteByLink({ siteShare: { ...formData, siteShareForms } }, 'フォームを編集しました')
      handleCloseSetting()
    },
    [handleUpdateShareSiteByLink, formData, handleCloseSetting]
  )

  //- 共有リンク
  const copyTextToClipboard = useCallback(() => {
    pushUserActionDataLayer('site', 'copy_shared_link')
    navigator.clipboard.writeText(shareUrl)
    Toaster.success('コピーしました！')
  }, [shareUrl])

  useEffect(() => {
    if (me?.user?.id) {
      setUserId(me?.user?.id)
    }
  }, [me?.user?.id])

  useEffect(() => {
    if (site?.siteShare && !isInitialized) {
      setChecked(site.siteShare.isActive || false)
      setUserId(site.siteShare.user!.id!)
      setIsActiveForm(site.siteShare.isFormActive || false)
      setIsAuthCodeActive(site.siteShare.isAuthCodeActive ?? true)
      setHasExpiredOn(!!site.siteShare.expiredOn || false)
      setExpiredValue(site.siteShare.expiredOn || null)
      setIsInitialized(false)
    }
  }, [isInitialized, site])

  // 編集完了
  useEffect(() => {
    if (!isLoading && isSubmitting && !isSubmitEnd && error) {
      setIsSubmitting(false)
      onError?.()
    } else if (!isLoading && isSubmitting && !isSubmitEnd && !error) {
      setIsSubmitEnd(true)
      onSubmit?.()
    } else if (!isLoading && isSubmitting && isSubmitEnd && !error) {
      setIsSubmitting(false)
      setIsSubmitEnd(false)
    }
  }, [isLoading, isSubmitting, isSubmitEnd, error, onError, onSubmit])

  // 設定されているものがあれば再度マッピングし直す
  useEffect(() => {
    // TODO: (正式版以降) site と document のロジックを一つにまとめる
    if (responseContactSettings) {
      let settingList = createDraggableSettingList(responseContactSettings, { hideEdit: true })
      if (siteShareForms.length > 0) {
        settingList = settingList.map(item => ({ ...item, isSelected: false }))
        siteShareForms.forEach(item => {
          const index = settingList.findIndex(s => s.id === item.contactSetting?.id)
          if (index != -1) {
            settingList[index] = {
              ...settingList[index],
              isSelected: true,
              sort: item.sort || settingList[index]?.sort,
              switchValue: item.isRequired
            }
          }
          settingList = settingList.sort((a, b) => a.sort - b.sort)
        })
      }
      setFormItems(settingList)
    }
  }, [siteShareForms, responseContactSettings])

  // リンクの共有のトグルがあった時
  useEffect(() => {
    if (isSubmitting) {
      handleUpdateShareSiteByLink(
        { siteShare: { ...formData, isActive: checked } },
        checked ? 'リンク共有をオンにしました' : 'リンク共有をオフにしました'
      )
      setIsSubmitting(false)
    }
  }, [checked, formData, handleUpdateShareSiteByLink, isSubmitting])

  // 顧客情報のフォーム設定のトグルがあった時
  useEffect(() => {
    if (isSubmittingFormActive) {
      if (isAuthCodeActive && !isActiveForm) {
        setIsAuthCodeActive(false)
        handleUpdateShareSiteByLink(
          { siteShare: { ...formData, isAuthCodeActive: false } },
          'セキュリティコードをオフにしました'
        ).then(() => {
          handleUpdateShareSiteByLink(
            { siteShare: { ...formData, isFormActive: false, isAuthCodeActive: false } },
            'フォーム設定をオフにしました'
          )
        })
      } else {
        handleUpdateShareSiteByLink(
          { siteShare: { ...formData, isFormActive: isActiveForm, isAuthCodeActive: isAuthCodeActive } },
          isActiveForm ? 'フォーム設定をオンにしました' : 'フォーム設定をオフにしました'
        )
      }
      setIsSubmittingFormActive(false)
    }
  }, [isActiveForm, isSubmittingFormActive, handleUpdateShareSiteByLink, isAuthCodeActive, formData])

  // 認証コードのトグルがあった時
  useEffect(() => {
    if (isSubmittingAuthCodeActive) {
      handleUpdateShareSiteByLink(
        { siteShare: { ...formData, isAuthCodeActive: isAuthCodeActive } },
        isAuthCodeActive ? 'セキュリティコードをオンにしました' : 'セキュリティコードをオフにしました'
      )
      setIsSubmittingAuthCodeActive(false)
    }
  }, [isAuthCodeActive, isSubmittingAuthCodeActive, handleUpdateShareSiteByLink, formData])

  useEffect(() => {
    setSiteShareForms(site.siteShare?.siteShareForms!)
  }, [site])

  return (
    <>
      <form color={Colors.base.black}>
        <Box display="flex" alignItems="center" mb="24px">
          <ToggleSwitch
            checked={checked}
            onChange={() => {
              setIsSubmitting(true)
              setChecked(v => !v)
            }}
          />
          <Box mr="12px" />
          <Box display="flex" alignItems="center">
            <Typography fontSize="s" fontWeight="bold" lineheight="14px">
              リンクの共有
            </Typography>
            <Box mr="6px" />
            <Tooltip content={'誰でも閲覧可能な共有リンクを作成できます。'}>
              <DynamicMuiIcon variant="helpRounded" color="action" size="16px" />
            </Tooltip>
          </Box>
        </Box>
        {checked && (
          <>
            {/* リンクセクション */}
            <Box mt="26.5px">
              <Typography fontSize="s" lineheight="14px" fontWeight="bold">
                こちらのリンクを共有してください
              </Typography>
              <Box display="flex" alignItems="center" mt="6px">
                <Input
                  backgroundColor={`${Colors.background.lightGray} !important`}
                  readonly
                  value={shareUrl}
                  name="shareLink"
                  fullWidth
                />
                <Box flexShrink={0} ml="12px">
                  <Button
                    title="リンクをコピー"
                    startIcon={<DynamicMuiIcon variant="copy" size="20px" />}
                    onClick={copyTextToClipboard}
                  />
                </Box>
              </Box>
            </Box>

            <Box borderBottom={`1px solid ${Colors.background.silver}`} my="24px" />
            <Box component={'section'}>
              <Box display="flex" alignItems="center" mb="4px">
                <DynamicMuiIcon variant="setting" color="action" size="20px" />
                <Box mr="6px" />
                <Typography variant="h5" fontSize="s" lineheight="14px">
                  オプション設定
                </Typography>
              </Box>
              {/* 担当者セクション */}
              <ShareLinkFormUserSelectSection value={userId} onChange={handleUpdateUserId} />
              <Box height="12px" />
              {/* 認証コードセクション */}
              <ShareLinkFormAuthCodeSection
                isActive={isAuthCodeActive}
                disabled={!isActiveForm}
                onChangeActive={() => {
                  setIsSubmittingAuthCodeActive(true)
                  setIsAuthCodeActive(v => !v)
                }}
              />
              <Box height="12px" />
              {/* 閲覧期限セクション */}
              <ShareLinkFormExpiredOnSection
                expiredOn={expiredValue}
                isActive={hasExpiredOn}
                onChangeExpiredOn={handleCalendarConfirm}
                onChangeIsActive={handleUpdateHasExpired}
              />
              <Box height="12px" />

              {/* 顧客情報入力フォーム */}
              <ShareLinkFormContactFormSection
                isActive={isActiveForm}
                formList={siteShareForms}
                onChangeActive={() => {
                  setIsSubmittingFormActive(true)
                  setIsActiveForm(v => !v)
                }}
                onOpenSettingForm={handleOpenSetting}
              />
            </Box>
          </>
        )}
        <button ref={ref} type="submit" hidden />
      </form>

      <ModalSelectAttribute
        open={isOpenModalSelectAttribute}
        items={formItems}
        onConfirm={handleConfirm}
        onClose={handleCloseSetting}
        kind="site"
      />

      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
})
