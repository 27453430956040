import { Box, Input, createStyles, makeStyles } from '@material-ui/core'
import { Coupon } from '@noco/http-client/lib/noco'
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, DynamicMuiIcon, Typography } from 'src/components/atoms'

export interface PaymentCouponCardProps {
  coupon?: Coupon
  onSubmit?: (couponCode: string) => void
  onClear?: () => void
  actionComponent?: ReactElement
}

const useStyles = makeStyles(() =>
  createStyles({
    formField: {
      height: '34px'
    }
  })
)

export const PaymentCouponCard = ({
  coupon: initCoupon,
  onSubmit,
  onClear,
  actionComponent
}: PaymentCouponCardProps) => {
  const classes = useStyles()
  const [coupon, setCoupon] = useState<Coupon | undefined>()
  const [couponCode, setCouponCode] = useState<string | undefined>()

  useEffect(() => {
    setCoupon(initCoupon)
  }, [initCoupon])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value === '' ? undefined : e.target.value
    setCouponCode(code)
  }, [])

  const handleSubmit = useCallback(() => {
    if (!couponCode) return

    onSubmit?.(couponCode)
  }, [couponCode, onSubmit])

  const handleClearCoupon = useCallback(() => {
    setCoupon(undefined)
    setCouponCode(undefined)
    onClear?.()
  }, [onClear])

  const customComponent = useMemo<ReactElement | undefined>(() => {
    if (actionComponent) {
      return actionComponent
    } else {
      return coupon ? (
        <Button
          hasBorder={false}
          title="変更"
          kind="secondary"
          variant="outlined"
          size="small"
          onClick={handleClearCoupon}
        />
      ) : undefined
    }
  }, [actionComponent, coupon, handleClearCoupon])

  return (
    <Card label="クーポンのご利用" sx={{ bgcolor: '#fff' }} actionComponent={customComponent}>
      {coupon ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #DADCE0',
            borderRadius: '6px',
            padding: '16px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '20px',
              height: '20px',
              color: '#6D7784',
              marginRight: '6px'
            }}
          >
            <DynamicMuiIcon variant="sellOutlined" size="16px" color="inherit" />
          </Box>
          <Typography fontSize="s" lineheight="1" fontWeight="bold">
            {coupon.name}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box mb="16px">
            <Typography fontSize="s" lineheight="1.32" color="inherit">
              クーポンをお持ちの方はクーポンに記載されているコードをご入力ください
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              maxWidth: '398px'
            }}
          >
            <Box width="100%" marginRight="12px" height="34px">
              <Input
                type="text"
                fullWidth
                placeholder="クーポンコードを入力"
                onChange={handleChange}
                className={classes.formField}
              />
            </Box>
            <Box flexShrink="0">
              <Button title="登録" kind="primary" size="medium" onClick={handleSubmit} disabled={!couponCode} />
            </Box>
          </Box>
        </Box>
      )}
    </Card>
  )
}
