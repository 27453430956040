import { Box } from '@material-ui/core'
import React, { FormEvent, ReactNode, useCallback, useState } from 'react'
import { Button, Colors, Typography } from 'src/components/atoms'

export interface LabelFormRowProps {
  submitButtonTitle?: string
  label: string
  onSave: (e: FormEvent<HTMLFormElement>) => void
  onCancel: () => void
  readElement: ReactNode
  editElement?: ReactNode
  canSubmit?: boolean
  hasEditPermission?: boolean
}

export const LabelFormRow = ({
  submitButtonTitle,
  label,
  onSave,
  onCancel,
  readElement,
  editElement,
  canSubmit = false,
  hasEditPermission = true
}: LabelFormRowProps) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const handleCancel = useCallback(() => {
    setIsEditMode(false)
    onCancel()
  }, [setIsEditMode, onCancel])

  const handleEditMode = useCallback(() => {
    setIsEditMode(hasEditPermission)
  }, [hasEditPermission])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      setIsEditMode(false)
      onSave(e)
    },
    [setIsEditMode, onSave]
  )

  return (
    <Box display="flex" alignItems={!isEditMode ? 'center' : ''} justifyContent="space-between">
      <Box display="flex" width="150px" flexShrink={0} color={Colors.base.black}>
        <Typography fontSize="s" fontWeight="bold" lh="tight">
          {label}
        </Typography>
      </Box>
      <Box width={1}>
        {isEditMode ? (
          <form onSubmit={handleSubmit}>
            <Box mb="16px">{editElement}</Box>
            <Box display="flex" alignItems="center">
              <Button title="キャンセル" kind="neutral" onClick={handleCancel} />
              <Box width="12px" />
              <Button title={submitButtonTitle ?? '保存'} type="submit" disabled={canSubmit} />
            </Box>
          </form>
        ) : (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box width={1} color={Colors.base.black} pr="24px">
              {readElement}
            </Box>
            {editElement && (
              <Box flexShrink={0}>
                <Button title="編集" onClick={handleEditMode} variant="outlined" disabled={!hasEditPermission} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
