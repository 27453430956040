import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import {
  OrganizationPlanDetail as Plan,
  OrganizationPlanDetailGradeEnum as PlanVariant,
  OrganizationStatusEnum as Status
} from '@noco/http-client/lib/noco/models'
import { numberWithCommas } from 'src/fixtures/utils/number'
import { Circle, Cross } from './SvgComponents'
import { PlanButtonProps, PlanInfo } from './type'
import { Coupon } from '@noco/http-client/lib/noco'

export interface PricingTableColumnProps {
  variant: PlanVariant
  targetPlan: Plan
  currentPlan: Plan
  disabled: boolean
  onClick: () => void
  status?: Status
  isCouponApplied?: Coupon
}

const trialPlanInfo: PlanInfo = {
  isPopular: false,
  planName: 'プレミアムプランお試し',
  unitAmount: 0,
  hiddenButton: false,
  planChangeButton: false,
  planDescription: '',
  userAuantity: 5,
  hasOptionUserPlan: false,
  canHideAd: true,
  featureList: []
}

const freePlanInfo: PlanInfo = {
  isPopular: false,
  planName: '無料',
  unitAmount: 0,
  hiddenButton: true,
  planChangeButton: false,
  planDescription: '月額0円でサービスを試したい方\n向けのフリープラン',
  userAuantity: 1,
  hasOptionUserPlan: false,
  emailTemplateQuantity: 1,
  canHideAd: false,
  featureList: ['資料管理', '資料共有', '顧客管理', 'チャット機能', '日程調整ツール連携', 'データ分析']
}

const entryPlanInfo: PlanInfo = {
  isPopular: false,
  planName: 'エントリー',
  unitAmount: 15_000,
  hiddenButton: false,
  planChangeButton: false,
  planDescription: '営業や顧客管理をはじめたい小規模\n事業者向けのミニマムプラン',
  userAuantity: 3,
  hasOptionUserPlan: false,
  emailTemplateQuantity: 5,
  canHideAd: true,
  basePlanName: '無料プラン',
  featureList: ['資料ダウンロード、印刷の設定', '連絡先のインポート', 'カスタムブランディング', '広告の非表示 など']
}

const standardPlanInfo: PlanInfo = {
  isPopular: true,
  planName: '旧スタンダード',
  unitAmount: 30_000,
  hiddenButton: false,
  planChangeButton: false,
  initialCost: 150_000,
  planDescription: 'チームでパワフルに営業活動や顧客\n管理を進めたい方向けの基本プラン',
  userAuantity: 5,
  hasOptionUserPlan: true,
  emailTemplateQuantity: undefined,
  canHideAd: true,
  basePlanName: 'エントリープラン',
  featureList: [
    'ユーザー枠の追加',
    '連絡先エクスポート',
    '資料の公開期限',
    '資料未閲覧者への追客メール',
    'ユーザー権限管理 など'
  ]
}

const lightPlanInfo: PlanInfo = {
  isPopular: false,
  planName: 'ライト',
  unitAmount: 30_000,
  hiddenButton: false,
  planChangeButton: true,
  planDescription: 'すぐに使いたい方\n向けのミニマムプラン',
  userAuantity: 1,
  hasOptionUserPlan: false,
  emailTemplateQuantity: 1,
  canHideAd: false,
  featureList: ['資料管理', '資料共有', '顧客管理', 'チャット機能', '日程調整ツール連携', 'データ分析']
}

const standardNewPlanInfo: PlanInfo = {
  isPopular: false,
  planName: 'スタンダード',
  unitAmount: 100_000,
  hiddenButton: false,
  planChangeButton: true,
  planDescription: '営業や顧客管理をはじめたい小規模\n事業者向けのミニマムプラン',
  userAuantity: 3,
  hasOptionUserPlan: false,
  emailTemplateQuantity: 5,
  canHideAd: true,
  basePlanName: 'ライトプラン',
  featureList: ['資料ダウンロード、印刷の設定', '連絡先のインポート', 'カスタムブランディング', '広告の非表示 など']
}

const premiumPlanInfo: PlanInfo = {
  isPopular: true,
  planName: 'プレミアム',
  unitAmount: 150_000,
  hiddenButton: false,
  planChangeButton: true,
  planDescription: 'チームでパワフルに営業活動や顧客\n管理を進めたい方向けの基本プラン',
  userAuantity: 5,
  hasOptionUserPlan: true,
  emailTemplateQuantity: undefined,
  canHideAd: true,
  basePlanName: 'スタンダードプラン',
  featureList: [
    'ユーザー枠の追加',
    '連絡先エクスポート',
    '資料の公開期限',
    '資料未閲覧者への追客メール',
    'ユーザー権限管理 など'
  ]
}

const freePremiumPlanInfo: PlanInfo = {
  isPopular: false,
  planName: 'アイドマ',
  unitAmount: 0,
  hiddenButton: false,
  planChangeButton: false,
  planDescription: 'チームでパワフルに営業活動や顧客\n管理を進めたい方向けの基本プラン',
  userAuantity: 5,
  hasOptionUserPlan: true,
  emailTemplateQuantity: undefined,
  canHideAd: true,
  basePlanName: 'アイドマプラン',
  featureList: [
    'ユーザー枠の追加',
    '連絡先エクスポート',
    '資料の公開期限',
    '資料未閲覧者への追客メール',
    'ユーザー権限管理 など'
  ]
}

const planInfoMap: { [key in PlanVariant]: PlanInfo } = {
  trial: trialPlanInfo,
  free: freePlanInfo,
  entry: entryPlanInfo,
  standard: standardPlanInfo,
  light: lightPlanInfo,
  standard_new: standardNewPlanInfo,
  premium: premiumPlanInfo,
  free_premium: freePremiumPlanInfo
}

const useStyles = makeStyles(() =>
  createStyles({
    plan: {
      width: '100%',
      marginTop: '40px',
      borderRight: '1px solid #DADCE0',
      borderBottom: '1px solid #DADCE0',
      borderLeft: '1px solid #DADCE0'
    },
    isPopular: {
      marginTop: '0',
      border: '2px solid #1DA7F5',
      boxShadow:
        '0px 7px 20px rgba(36, 31, 71, 0.1), 0px 0px 14px rgba(36, 31, 71, 0.1), 0px 0px 8px rgba(36, 31, 71, 0.02), 0px 0px 1px rgba(36, 31, 71, 0.08)'
    },
    planDetail: {
      padding: '24px 9px',
      textAlign: 'center',
      borderBottom: '1px solid #EEF1F4',
      '&.light': {
        borderTop: '16px solid #FFD25F'
      },
      '&.standard_new': {
        borderTop: '16px solid #76F4ED'
      },
      '&.premium': {
        borderTop: '16px solid #1DA7F5'
      }
    },
    button: {
      margin: '0 auto',
      padding: '0 8px',
      boxShadow: 'none'
    },
    featureBox: {
      padding: '14px 0',
      textAlign: 'center',
      borderBottom: '1px solid #EEF1F4'
    },
    featureListBox: {
      padding: '10px 0 12px'
    },
    icon: {
      width: '27px',
      height: '27px'
    }
  })
)

const getPlanNameText = (plan: Plan): string => {
  // 無料プランの場合は 「〇〇プラン」 の Suffix あり、無料プラン以外のは Suffix なし
  return plan.grade === 'free' ? plan.name : planInfoMap[plan.grade].planName
}

const getButtonProps = (
  targetPlan: Plan,
  currentPlan: Plan,
  disabled: boolean,
  status?: Status,
  isCouponApplied?: Coupon
): PlanButtonProps => {
  const isLight = targetPlan.grade === 'light'
  const isPaused = status === Status.Paused
  if (currentPlan.grade === 'trial') {
    return {
      title: isLight ? `${getPlanNameText(targetPlan)}に変更` : `${getPlanNameText(targetPlan)}にアップグレード`,
      variant: isLight ? 'outlined' : 'contained',
      disabled: !targetPlan.isContractablePeriod || disabled
    }
  } else if (currentPlan.id === targetPlan.id) {
    return {
      title: isPaused || isCouponApplied ? '有料プランへアップグレード' : '現在ご利用中',
      variant: isLight ? 'outlined' : 'contained',
      disabled: !isPaused && !isCouponApplied
    }
  } else {
    // TODO: プランの比較ロジックはよく使いそうなので、Fixture にまとめる
    const title =
      targetPlan.gradeAsNumber > currentPlan.gradeAsNumber
        ? `${getPlanNameText(targetPlan)}にアップグレード`
        : `${getPlanNameText(targetPlan)}に変更`
    return {
      title,
      variant: isLight ? 'outlined' : 'contained',
      disabled: !targetPlan.isContractablePeriod || disabled
    }
  }
}

export const PricingTableColumn = ({
  variant,
  targetPlan,
  currentPlan,
  disabled = false,
  onClick,
  status,
  isCouponApplied
}: PricingTableColumnProps) => {
  const classes = useStyles()
  const planInfo = useMemo(() => planInfoMap[variant], [variant])
  const buttonProps = useMemo(
    () => getButtonProps(targetPlan, currentPlan, disabled, status, isCouponApplied),
    [targetPlan, currentPlan, disabled, status, isCouponApplied]
  )
  const isActionable = useMemo(
    () => !disabled && targetPlan.isContractablePeriod,
    [disabled, targetPlan.isContractablePeriod]
  )

  return (
    <Box className={clsx(classes.plan, planInfo.isPopular ? classes.isPopular : '')}>
      <Box className={clsx(classes.planDetail, variant)}>
        {planInfo.isPopular && (
          <Box mb="24px" color={Colors.accent.keyBlue.default}>
            <Typography fontSize="s" fontWeight="bold" lineheight="1" color="inherit">
              人気のプランです
            </Typography>
          </Box>
        )}

        <Box mb="8px">
          <Typography fontSize="l" fontWeight="bold">
            {planInfo.planName}
          </Typography>
        </Box>

        <Box height="52px" mb="12px">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center'
            }}
          >
            <Box mr="4px">
              <Typography fontSize="xxl" fontWeight="bold" lineheight="1.5">
                {numberWithCommas(planInfo.unitAmount)}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize="s" fontWeight="bold">
                円/月額
              </Typography>
            </Box>
          </Box>
          {/* {planInfo.initialCost && (
            <Box mt="2px">
              <Typography fontSize="s" fontWeight="bold" lineheight="1">
                （初期費用&nbsp;{numberWithCommas(planInfo.initialCost)}円）
              </Typography>
            </Box>
          )} */}
        </Box>

        <Box mb="20px" whiteSpace="pre-wrap">
          <Typography fontSize="xs">{planInfo.planDescription}</Typography>
        </Box>
        {/* {planInfo.hiddenButton && (
          <Box display="flex" justifyContent="center">
            <Button title="ご利用できません" size="medium" kind="neutral" variant="outlined" />
          </Box>
        )} */}
        {planInfo.planChangeButton && (
          <Button
            {...buttonProps}
            className={classes.button}
            size="medium"
            onClick={isActionable ? onClick : () => {}}
          />
        )}
      </Box>
      <Box className={classes.featureBox}>
        <Box mb="4px">
          <Typography fontSize="s" lineheight="1">
            ご利用ユーザー枠
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
            {planInfo.userAuantity}人分
          </Typography>
        </Box>
      </Box>
      <Box className={classes.featureBox}>
        <Box mb="4px">
          <Typography fontSize="s" lineheight="1">
            ご利用ユーザー枠の追加
          </Typography>
        </Box>
        <Box lineHeight="1">
          {planInfo.hasOptionUserPlan ? (
            <Circle classes={{ root: classes.icon }} />
          ) : (
            <Cross classes={{ root: classes.icon }} />
          )}
        </Box>
      </Box>
      <Box className={classes.featureBox}>
        <Box mb="4px">
          <Typography fontSize="s" lineheight="1">
            連絡先の登録数
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
            無制限
          </Typography>
        </Box>
      </Box>
      <Box className={classes.featureBox}>
        <Box mb="4px">
          <Typography fontSize="s" lineheight="1">
            資料の登録数
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
            無制限
          </Typography>
        </Box>
      </Box>
      <Box className={classes.featureBox}>
        <Box mb="4px">
          <Typography fontSize="s" lineheight="1">
            資料サイトの作成数
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
            無制限
          </Typography>
        </Box>
      </Box>
      <Box className={classes.featureBox}>
        <Box mb="4px">
          <Typography fontSize="s" lineheight="1">
            メールテンプレート登録数
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
            {planInfo.emailTemplateQuantity || '無制限'}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.featureBox}>
        <Box mb="4px">
          <Typography fontSize="s" lineheight="1">
            広告の非表示
          </Typography>
        </Box>
        <Box lineHeight="1">
          {planInfo.canHideAd ? (
            <Circle classes={{ root: classes.icon }} />
          ) : (
            <Cross classes={{ root: classes.icon }} />
          )}
        </Box>
      </Box>
      <Box className={classes.featureListBox}>
        <Box p="10px 16px">
          <Typography fontSize="s" lineheight="1">
            主な機能
          </Typography>
        </Box>
        {planInfo.basePlanName && (
          <Box display="flex" alignItems="center" p="6px 16px" color={Colors.accent.keyPurple.default}>
            <Box mr="6px" lineHeight="1">
              <DynamicMuiIcon variant="add" size="20px" color="inherit" />
            </Box>
            <Typography fontSize="s" lineheight="1" fontWeight="bold">
              {planInfo.basePlanName}の全機能
            </Typography>
          </Box>
        )}
        {planInfo.featureList.map((featureListItem, i) => {
          return (
            <Box key={i} display="flex" alignItems="flex-start" p="6px 16px">
              <Box mr="6px" lineHeight="1" color={Colors.accent.keyPurple.default}>
                <DynamicMuiIcon variant="checkCircle" size="20px" color="inherit" />
              </Box>
              <Typography fontSize="s" lineheight="132%">
                {featureListItem}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
