/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ArchiveContactsRequestToJSON, ArchiveContactsResponseFromJSON, ContactTimelinesResponseFromJSON, CreateContactMemoRequestToJSON, CreateContactMemoResponseFromJSON, CreateContactRequestToJSON, CreateContactResponseFromJSON, CreateWithEmailAddressContactRequestToJSON, CreateWithEmailAddressContactResponseFromJSON, GetContactContactListsResponseFromJSON, GetContactMaterialsExpiredListResponseFromJSON, GetContactMaterialsResponseFromJSON, GetContactResponseFromJSON, GetContactsResponseFromJSON, UpdateContactCompanyRequestToJSON, UpdateContactCompanyResponseFromJSON, UpdateContactNoReplyRequiredChatResponseFromJSON, UpdateContactRequestToJSON, UpdateContactResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ContactApi extends runtime.BaseAPI {
    /**
     * 連絡先のアーカイブ設定
     * archive
     */
    async userV1ContactsArchivePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/archive`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveContactsRequestToJSON(requestParameters.archiveContactsRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ArchiveContactsResponseFromJSON(jsonValue));
    }
    /**
     * 連絡先のアーカイブ設定
     * archive
     */
    async userV1ContactsArchivePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactsArchivePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクトの関連する会社を変更する
     * company
     */
    async userV1ContactsContactIdCompanyPutRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdCompanyPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/company`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactCompanyRequestToJSON(requestParameters.updateContactCompanyRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateContactCompanyResponseFromJSON(jsonValue));
    }
    /**
     * コンタクトの関連する会社を変更する
     * company
     */
    async userV1ContactsContactIdCompanyPut(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdCompanyPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 連絡先の詳細情報を取得する
     * show
     */
    async userV1ContactsContactIdGetRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactResponseFromJSON(jsonValue));
    }
    /**
     * 連絡先の詳細情報を取得する
     * show
     */
    async userV1ContactsContactIdGet(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 連絡先が所属している連絡先リスト一覧を取得
     * list
     */
    async userV1ContactsContactIdListsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdListsGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/lists`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactContactListsResponseFromJSON(jsonValue));
    }
    /**
     * 連絡先が所属している連絡先リスト一覧を取得
     * list
     */
    async userV1ContactsContactIdListsGet(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdListsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 連絡先でコンテンツの有効期限が切れてる一覧を取得
     * expired
     */
    async userV1ContactsContactIdMaterialsExpiredGetRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMaterialsExpiredGet.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/materials/expired`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactMaterialsExpiredListResponseFromJSON(jsonValue));
    }
    /**
     * 連絡先でコンテンツの有効期限が切れてる一覧を取得
     * expired
     */
    async userV1ContactsContactIdMaterialsExpiredGet(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdMaterialsExpiredGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 連絡先のコンテンツ一覧を取得
     * index
     */
    async userV1ContactsContactIdMaterialsGetRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMaterialsGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/materials`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactMaterialsResponseFromJSON(jsonValue));
    }
    /**
     * 連絡先のコンテンツ一覧を取得
     * index
     */
    async userV1ContactsContactIdMaterialsGet(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdMaterialsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1ContactsContactIdMemosPostRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/memos`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactMemoRequestToJSON(requestParameters.createContactMemoRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateContactMemoResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1ContactsContactIdMemosPost(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdMemosPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクトのチャットを返信不要にする
     * no_reply_required_chat
     */
    async userV1ContactsContactIdNoReplyRequiredChatPutRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdNoReplyRequiredChatPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/no_reply_required_chat`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateContactNoReplyRequiredChatResponseFromJSON(jsonValue));
    }
    /**
     * コンタクトのチャットを返信不要にする
     * no_reply_required_chat
     */
    async userV1ContactsContactIdNoReplyRequiredChatPut(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdNoReplyRequiredChatPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト情報の編集をする
     * update
     */
    async userV1ContactsContactIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactRequestToJSON(requestParameters.updateContactRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateContactResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト情報の編集をする
     * update
     */
    async userV1ContactsContactIdPut(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async userV1ContactsContactIdTimelinesGetRaw(requestParameters, initOverrides) {
        if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
            throw new runtime.RequiredError('contactId', 'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdTimelinesGet.');
        }
        const queryParameters = {};
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts/{contact_id}/timelines`.replace(`{${'contact_id'}}`, encodeURIComponent(String(requestParameters.contactId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ContactTimelinesResponseFromJSON(jsonValue));
    }
    /**
     */
    async userV1ContactsContactIdTimelinesGet(requestParameters, initOverrides) {
        const response = await this.userV1ContactsContactIdTimelinesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * メールアドレスから連絡先を作成
     * create_with_email_address
     */
    async userV1ContactsCreateWithEmailAddressPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts/create_with_email_address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWithEmailAddressContactRequestToJSON(requestParameters.createWithEmailAddressContactRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateWithEmailAddressContactResponseFromJSON(jsonValue));
    }
    /**
     * メールアドレスから連絡先を作成
     * create_with_email_address
     */
    async userV1ContactsCreateWithEmailAddressPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactsCreateWithEmailAddressPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト情報の一覧を取得する
     * index
     */
    async userV1ContactsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.hasCompany !== undefined) {
            queryParameters['has_company'] = requestParameters.hasCompany;
        }
        if (requestParameters.companyId !== undefined) {
            queryParameters['company_id'] = requestParameters.companyId;
        }
        if (requestParameters.contactListId !== undefined) {
            queryParameters['contact_list_id'] = requestParameters.contactListId;
        }
        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }
        if (requestParameters.userIds) {
            queryParameters['user_ids[]'] = requestParameters.userIds;
        }
        if (requestParameters.documentId !== undefined) {
            queryParameters['document_id'] = requestParameters.documentId;
        }
        if (requestParameters.documentIds) {
            queryParameters['document_ids[]'] = requestParameters.documentIds;
        }
        if (requestParameters.siteId !== undefined) {
            queryParameters['site_id'] = requestParameters.siteId;
        }
        if (requestParameters.siteIds) {
            queryParameters['site_ids[]'] = requestParameters.siteIds;
        }
        if (requestParameters.createdOnFrom !== undefined) {
            queryParameters['created_on_from'] = requestParameters.createdOnFrom;
        }
        if (requestParameters.createdOnTo !== undefined) {
            queryParameters['created_on_to'] = requestParameters.createdOnTo;
        }
        if (requestParameters.isArchived !== undefined) {
            queryParameters['is_archived'] = requestParameters.isArchived;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        if (requestParameters.orderBy !== undefined) {
            queryParameters['order_by'] = requestParameters.orderBy;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactsResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト情報の一覧を取得する
     * index
     */
    async userV1ContactsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト情報を作成する
     * create
     */
    async userV1ContactsPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactRequestToJSON(requestParameters.createContactRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateContactResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト情報を作成する
     * create
     */
    async userV1ContactsPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ContactsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
