import { SiteApi } from './siteApi';
import { AuthAPI } from './authApi';
import { ChatMessageApi } from './chatMessageApi';
import { DocumentApi } from './documentApi';
import { VisitorApi } from './visitorApi';
import { VisitorAuthApi } from './visitorAuthApi';
import { MaterialableTrackApi } from './materialableTrackApi';
import { OrganizationApi } from './organizationApi';
var ContactService = /** @class */ (function () {
    function ContactService(basePath, fetchApi) {
        this.authApi = AuthAPI.initialize(basePath, fetchApi);
        this.chatMessageApi = ChatMessageApi.initialize(basePath, fetchApi);
        this.documentApi = DocumentApi.initialize(basePath, fetchApi);
        this.visitorApi = VisitorApi.initialize(basePath, fetchApi);
        this.visitorAuthApi = VisitorAuthApi.initialize(basePath, fetchApi);
        this.siteApi = SiteApi.initialize(basePath, fetchApi);
        this.materialableTrackApi = MaterialableTrackApi.initialize(basePath, fetchApi);
        this.organizationApi = OrganizationApi.initialize(basePath, fetchApi);
    }
    ContactService.initialize = function (basePath, fetchApi) {
        if (!ContactService.instance) {
            ContactService.instance = new ContactService(basePath, fetchApi);
        }
        return ContactService.instance;
    };
    return ContactService;
}());
export { ContactService };
