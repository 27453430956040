import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var OrganizationApi = /** @class */ (function () {
    function OrganizationApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    OrganizationApi.initialize = function (basePath, fetchApi) {
        if (!OrganizationApi.instance) {
            OrganizationApi.instance = new OrganizationApi(basePath, fetchApi);
        }
        return OrganizationApi.instance;
    };
    OrganizationApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1OrganizationApi(new Configuration(config));
    };
    return OrganizationApi;
}());
export { OrganizationApi };
