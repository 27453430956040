import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Edit as IconEdit } from '@material-ui/icons'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, SvgIcon, SelectBox, Typography, Tooltip } from 'src/components/atoms'
import { useGetCompany, useUpdateCompany } from 'src/fixtures/modules/company/hooks'
import {
  CompanySettingValueMap,
  convertFromCompanySettingValueMap,
  initializeCompanySettingValueMap
} from 'src/fixtures/modules/company/utils'
import { useListCompanySettings } from 'src/fixtures/modules/companySetting/hooks'
import { Edit } from './Edit'
import { CompanyInfoList } from '../CompanyInfoList'
import { UpdateInformation } from 'src/components/molecules'
import { useConfirmDiscard } from 'src/fixtures/utils/url'
import { useListSelectorUsersList } from 'src/fixtures/modules/selector/hooks'
import { CompanyList } from './CompanyList'

const useStyles = makeStyles(() => {
  return createStyles({
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    }
  })
})

export const CompanyDetail = ({
  companyId,
  onChangeUserId,
  userId
}: {
  companyId: string
  userId?: string
  onChangeUserId: (userId: string) => void
}) => {
  const classes = useStyles()
  const [isEditMode, setIsEditMode] = useState(false)
  const handleButtonClick = useCallback(() => {
    setIsEditMode(prev => !prev)
  }, [])
  const { data: company } = useGetCompany(companyId)
  const { data: responseSetting } = useListCompanySettings()
  const activeSettings = responseSetting?.activeCompanySettings
  const { handleUpdateCompany, isLoading, error } = useUpdateCompany(companyId)
  const [settingMap, setSettingMap] = useState<CompanySettingValueMap>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm && isEditMode)

  // 初期化
  useEffect(() => {
    const initalSettingValueMap = initializeCompanySettingValueMap(activeSettings || [])
    company?.companySettings?.forEach(item => {
      const target = initalSettingValueMap[item.id!]
      if (!target) return false

      // TODO: 以下ロジックは contact の setting field の出しわけロジックと同等である。union type を利用して型の整合性を取りつつリファクタする
      item.companySettingFields?.forEach(f => {
        const kind = f.kind
        if (kind === 'dropdown' && f.itemUrl === '/user/v1/selector/companies') {
          target[f.id!] = f.companySettingFieldValues?.[0].value
        }
        //- dropdown || radio の場合
        else if (kind === 'dropdown' || kind === 'radio') {
          target[f.id!] = f.companySettingFieldValues?.[0]?.companySettingFieldItem?.id
        }
        //- checkbox の場合
        else if (kind === 'checkbox') {
          target[f.id!] = f.companySettingFieldValues?.map(value => value.companySettingFieldItem?.id)
        }
        //- テキスト系 の場合
        else {
          target[f.id!] = f.companySettingFieldValues?.[0]?.value
        }
      })
    })
    setSettingMap(initalSettingValueMap)
  }, [activeSettings, company?.companySettings])

  const handleUpdate = useCallback(() => {
    const companySettingFieldValues = convertFromCompanySettingValueMap(settingMap)
    handleUpdateCompany({
      company: {
        companySettingFieldValues
      }
    })

    setIsSubmitting(true)
  }, [handleUpdateCompany, settingMap])

  // MEMO:会社の更新が終了したときに此処が呼ばれる
  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setIsSubmitting(false)
      setIsEditMode(false)
    }
  }, [isSubmitting, isLoading, error])

  // MEMO: エラーが発生した時
  useEffect(() => {
    if (isSubmitting && !isLoading && error) {
      setIsSubmitting(false)
    }
  }, [isSubmitting, isLoading, error])

  const { data: selectorUserRes } = useListSelectorUsersList({ page: 1, per: 9999 })
  const selectorUserList = selectorUserRes?.selectorItems
  const userOptionList = useMemo(() => {
    return selectorUserList?.map(item => ({
      value: item.id!,
      label: item.text,
      startElement: (
        <Box width="20px" height="20px" mr="4px" position="relative">
          {item.extend?.avatar ? (
            <img src={item.extend.avatar.url} className={classes.img} />
          ) : (
            <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
          )}
        </Box>
      )
    }))
  }, [selectorUserList, classes.img])

  return (
    <>
      {!isEditMode && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="32px" width="100%">
          <Tooltip content="会社の担当者を設定します。" icon iconSize="16px">
            <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px" fontWeight="bold">
              担当者
            </Typography>
          </Tooltip>

          <Box width="150px">
            <SelectBox
              placeholder="選択してください"
              value={userId || 0}
              name="user"
              optionList={userOptionList || []}
              onChange={e => onChangeUserId(e.target.value as string)}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="16px">
        <Typography variant="h5" fontSize="s" lineheight="14px" letterSpacing="none">
          会社情報
        </Typography>

        {isEditMode ? (
          <Box display="flex">
            <Button
              variant="outlined"
              kind="neutral"
              size="small"
              title="キャンセル"
              disabled={isSubmitting}
              onClick={handleButtonClick}
            />
            <Box ml="12px" />
            <Button
              size="small"
              title={isSubmitting ? '' : '保存'}
              width={isSubmitting ? '45px' : undefined}
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={handleUpdate}
            />
          </Box>
        ) : (
          <Button
            startIcon={<IconEdit />}
            kind="secondary"
            variant="outlined"
            size="small"
            title="編集"
            onClick={handleButtonClick}
          />
        )}
      </Box>
      {!isEditMode
        ? company && (
            <>
              <CompanyInfoList company={company} disabledCompanyLink />
              <Box mt="32px">
                <CompanyList companyId={String(company.id)} companyName={company.name ?? ''} />
              </Box>
            </>
          )
        : company && (
            <Edit
              onUpdateValue={map => {
                setSettingMap(map)
                setShowConfirm(true)
              }}
              company={company}
              settingMap={settingMap}
            />
          )}
      <Box my="35px">
        <UpdateInformation
          createdAt={company?.createdAt!}
          createdBy={company?.createdBy!}
          updatedAt={company?.updatedAt!}
          updatedBy={company?.updatedBy!}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Box color={Colors.base.middleGray} mr="8px">
          <Tooltip
            content={
              <>
                会社の識別番号です。変更・修正はできません。
                <br />
                <br />
                会社IDは、会社情報および連絡先のデータインポート時に使用します。
              </>
            }
          >
            <Typography variant="subtitle2">会社ID</Typography>
          </Tooltip>
        </Box>
        <Typography variant="body2">{companyId}</Typography>
      </Box>
    </>
  )
}
