/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Contact, ContactFromJSON, ContactFromJSONTyped, ContactToJSON } from './Contact'
import {
  ContactSetting,
  ContactSettingFromJSON,
  ContactSettingFromJSONTyped,
  ContactSettingToJSON
} from './ContactSetting'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetContactsResponseData
 */
export interface GetContactsResponseData {
  /**
   *
   * @type {Array<Contact>}
   * @memberof GetContactsResponseData
   */
  contacts?: Array<Contact>
  /**
   *
   * @type {Array<ContactSetting>}
   * @memberof GetContactsResponseData
   */
  contactSettings?: Array<ContactSetting>
  /**
   *
   * @type {PageInfo}
   * @memberof GetContactsResponseData
   */
  pageInfo?: PageInfo
}

export function GetContactsResponseDataFromJSON(json: any): GetContactsResponseData {
  return GetContactsResponseDataFromJSONTyped(json, false)
}

export function GetContactsResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetContactsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contacts: !exists(json, 'contacts') ? undefined : (json['contacts'] as Array<any>).map(ContactFromJSON),
    contactSettings: !exists(json, 'contact_settings')
      ? undefined
      : (json['contact_settings'] as Array<any>).map(ContactSettingFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetContactsResponseDataToJSON(value?: GetContactsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contacts: value.contacts === undefined ? undefined : (value.contacts as Array<any>).map(ContactToJSON),
    contact_settings:
      value.contactSettings === undefined ? undefined : (value.contactSettings as Array<any>).map(ContactSettingToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
