/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumUserPermission,
  EnumUserPermissionFromJSON,
  EnumUserPermissionFromJSONTyped,
  EnumUserPermissionToJSON
} from './EnumUserPermission'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface UserForDisplay
 */
export interface UserForDisplay {
  /**
   *
   * @type {string}
   * @memberof UserForDisplay
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof UserForDisplay
   */
  firstName: string
  /**
   *
   * @type {string}
   * @memberof UserForDisplay
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof UserForDisplay
   */
  displayName?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof UserForDisplay
   */
  avatar: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {EnumUserPermission}
   * @memberof UserForDisplay
   */
  permission: EnumUserPermission
  /**
   *
   * @type {string}
   * @memberof UserForDisplay
   */
  discardedAt?: string | null
}

export function UserForDisplayFromJSON(json: any): UserForDisplay {
  return UserForDisplayFromJSONTyped(json, false)
}

export function UserForDisplayFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserForDisplay {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    firstName: json['first_name'],
    lastName: json['last_name'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    avatar: GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
    permission: EnumUserPermissionFromJSON(json['permission']),
    discardedAt: !exists(json, 'discarded_at') ? undefined : json['discarded_at']
  }
}

export function UserForDisplayToJSON(value?: UserForDisplay | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    first_name: value.firstName,
    last_name: value.lastName,
    display_name: value.displayName,
    avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
    permission: EnumUserPermissionToJSON(value.permission),
    discarded_at: value.discardedAt
  }
}
