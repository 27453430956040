/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetVisitorResponseData,
  GetVisitorResponseDataFromJSON,
  GetVisitorResponseDataFromJSONTyped,
  GetVisitorResponseDataToJSON
} from './GetVisitorResponseData'

/**
 *
 * @export
 * @interface GetVisitorResponse
 */
export interface GetVisitorResponse {
  /**
   *
   * @type {number}
   * @memberof GetVisitorResponse
   */
  status?: number
  /**
   *
   * @type {GetVisitorResponseData}
   * @memberof GetVisitorResponse
   */
  data?: GetVisitorResponseData
}

export function GetVisitorResponseFromJSON(json: any): GetVisitorResponse {
  return GetVisitorResponseFromJSONTyped(json, false)
}

export function GetVisitorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVisitorResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetVisitorResponseDataFromJSON(json['data'])
  }
}

export function GetVisitorResponseToJSON(value?: GetVisitorResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetVisitorResponseDataToJSON(value.data)
  }
}
