/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactSettingFieldItem,
  ContactSettingFieldItemFromJSON,
  ContactSettingFieldItemFromJSONTyped,
  ContactSettingFieldItemToJSON
} from './ContactSettingFieldItem'
import {
  ContactSettingFieldValue,
  ContactSettingFieldValueFromJSON,
  ContactSettingFieldValueFromJSONTyped,
  ContactSettingFieldValueToJSON
} from './ContactSettingFieldValue'

/**
 *
 * @export
 * @interface ContactSettingField
 */
export interface ContactSettingField {
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  label?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  kind?: string
  /**
   *
   * @type {boolean}
   * @memberof ContactSettingField
   */
  isRequired?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContactSettingField
   */
  isInputRequired?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContactSettingField
   */
  isInputRequiredOnUser?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContactSettingField
   */
  isInputRequiredOnEu?: boolean
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  fieldValidate?: string | null
  /**
   *
   * @type {number}
   * @memberof ContactSettingField
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  itemType?: string | null
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  itemUrl?: string | null
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  placeholder?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ContactSettingField
   */
  isHideOnUser?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ContactSettingField
   */
  isHideOnEu?: boolean
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ContactSettingField
   */
  updatedAt?: string
  /**
   *
   * @type {Array<ContactSettingFieldValue>}
   * @memberof ContactSettingField
   */
  contactSettingFieldValues?: Array<ContactSettingFieldValue>
  /**
   *
   * @type {Array<ContactSettingFieldItem>}
   * @memberof ContactSettingField
   */
  contactSettingFieldItems?: Array<ContactSettingFieldItem> | null
}

export function ContactSettingFieldFromJSON(json: any): ContactSettingField {
  return ContactSettingFieldFromJSONTyped(json, false)
}

export function ContactSettingFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSettingField {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    label: !exists(json, 'label') ? undefined : json['label'],
    kind: !exists(json, 'kind') ? undefined : json['kind'],
    isRequired: !exists(json, 'is_required') ? undefined : json['is_required'],
    isInputRequired: !exists(json, 'is_input_required') ? undefined : json['is_input_required'],
    isInputRequiredOnUser: !exists(json, 'is_input_required_on_user') ? undefined : json['is_input_required_on_user'],
    isInputRequiredOnEu: !exists(json, 'is_input_required_on_eu') ? undefined : json['is_input_required_on_eu'],
    fieldValidate: !exists(json, 'field_validate') ? undefined : json['field_validate'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    itemType: !exists(json, 'item_type') ? undefined : json['item_type'],
    itemUrl: !exists(json, 'item_url') ? undefined : json['item_url'],
    placeholder: !exists(json, 'placeholder') ? undefined : json['placeholder'],
    isHideOnUser: !exists(json, 'is_hide_on_user') ? undefined : json['is_hide_on_user'],
    isHideOnEu: !exists(json, 'is_hide_on_eu') ? undefined : json['is_hide_on_eu'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    contactSettingFieldValues: !exists(json, 'contact_setting_field_values')
      ? undefined
      : (json['contact_setting_field_values'] as Array<any>).map(ContactSettingFieldValueFromJSON),
    contactSettingFieldItems: !exists(json, 'contact_setting_field_items')
      ? undefined
      : json['contact_setting_field_items'] === null
      ? null
      : (json['contact_setting_field_items'] as Array<any>).map(ContactSettingFieldItemFromJSON)
  }
}

export function ContactSettingFieldToJSON(value?: ContactSettingField | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    label: value.label,
    kind: value.kind,
    is_required: value.isRequired,
    is_input_required: value.isInputRequired,
    is_input_required_on_user: value.isInputRequiredOnUser,
    is_input_required_on_eu: value.isInputRequiredOnEu,
    field_validate: value.fieldValidate,
    sort: value.sort,
    item_type: value.itemType,
    item_url: value.itemUrl,
    placeholder: value.placeholder,
    is_hide_on_user: value.isHideOnUser,
    is_hide_on_eu: value.isHideOnEu,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    contact_setting_field_values:
      value.contactSettingFieldValues === undefined
        ? undefined
        : (value.contactSettingFieldValues as Array<any>).map(ContactSettingFieldValueToJSON),
    contact_setting_field_items:
      value.contactSettingFieldItems === undefined
        ? undefined
        : value.contactSettingFieldItems === null
        ? null
        : (value.contactSettingFieldItems as Array<any>).map(ContactSettingFieldItemToJSON)
  }
}
