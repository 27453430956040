import { Box } from '@material-ui/core'
import React from 'react'
import { useGlobalStyles } from 'src/styles/theme'

export interface ColorBoxProps {
  color: string
  onClick: (color: string) => void
}
export const ColorBox = ({ color, onClick }: ColorBoxProps) => {
  const globalClasses = useGlobalStyles()
  return (
    <Box
      width="34px"
      height="34px"
      className={globalClasses.cursorPointer}
      borderRadius="4px"
      bgcolor={color}
      mr="8px"
      onClick={() => onClick(color)}
    />
  )
}
