import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const OpenInFull = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 7.15833V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5H12.8417C12.1 2.5 11.725 3.4 12.25 3.925L13.575 5.25L5.24167 13.5833L3.91667 12.2583C3.4 11.7333 2.5 12.1 2.5 12.8417V16.6667C2.5 17.125 2.875 17.5 3.33333 17.5H7.15833C7.9 17.5 8.275 16.6 7.75 16.075L6.425 14.75L14.7583 6.41667L16.0833 7.74167C16.6 8.26667 17.5 7.9 17.5 7.15833Z"
        fill="#6D7784"
      />
    </SvgIcon>
  )
}
export default OpenInFull
