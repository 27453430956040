import { Box, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors, DynamicMuiIcon, ToggleSwitch } from 'src/components/atoms'
import { LayoutUser } from 'src/components/commons'
import { useGetEmailNotificationSetting, useUpdateEmailNotificationSetting } from 'src/fixtures/modules/me/hooks'
import { UserNotificationItem } from './type'

export const PageUserNotification = () => {
  const { data: res } = useGetEmailNotificationSetting()
  const userEmailNotificationSetting = res?.userEmailNotificationSetting
  const { handleUpdateEmailNotificationSetting } = useUpdateEmailNotificationSetting()

  const NotificationList: UserNotificationItem[] = useMemo(() => {
    return [
      {
        label: '顧客が資料を閲覧した時（初回）',
        checked: userEmailNotificationSetting?.onFirstView,
        onChange: () =>
          handleUpdateEmailNotificationSetting({
            userEmailNotificationSetting: {
              onFirstView: !userEmailNotificationSetting?.onFirstView
            }
          })
      },
      {
        label: '顧客が資料を閲覧した時（2回目以降）',
        checked: userEmailNotificationSetting?.onView,
        onChange: () =>
          handleUpdateEmailNotificationSetting({
            userEmailNotificationSetting: {
              onView: !userEmailNotificationSetting?.onView
            }
          })
      },
      {
        label: '顧客がチャットした時',
        checked: userEmailNotificationSetting?.onChat,
        onChange: () =>
          handleUpdateEmailNotificationSetting({
            userEmailNotificationSetting: {
              onChat: !userEmailNotificationSetting?.onChat
            }
          })
      },
      {
        label: '顧客がCTAをクリックした時',
        checked: userEmailNotificationSetting?.onCta,
        onChange: () =>
          handleUpdateEmailNotificationSetting({
            userEmailNotificationSetting: {
              onCta: !userEmailNotificationSetting?.onCta
            }
          })
      },
      {
        label: '顧客が資料サイトを閲覧した時',
        checked: userEmailNotificationSetting?.onViewSite,
        onChange: () =>
          handleUpdateEmailNotificationSetting({
            userEmailNotificationSetting: {
              onViewSite: !userEmailNotificationSetting?.onViewSite
            }
          })
      }
    ]
  }, [userEmailNotificationSetting, handleUpdateEmailNotificationSetting])

  return (
    <LayoutUser>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        bgcolor={Colors.functional.background.default}
        px="32px"
        overflow="auto"
      >
        <Box display="flex" alignItems="center" pt="16px" pb="24px">
          <DynamicMuiIcon variant="markEmail" />
          <Box width="8px" />
          <Typography variant="h2">メール通知</Typography>
        </Box>
        {NotificationList.map((item, i) => (
          <Box
            key={i}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`1px solid ${Colors.background.silver}`}
            p="16px 12px"
          >
            <Typography variant="h5">{item.label}</Typography>
            <ToggleSwitch checked={!!item.checked} onChange={item.onChange} />
          </Box>
        ))}
      </Box>
    </LayoutUser>
  )
}
