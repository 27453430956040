/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateOrganizationUserRequestUserFromJSON, UpdateOrganizationUserRequestUserToJSON } from './UpdateOrganizationUserRequestUser';
export function UpdateOrganizationUserRequestFromJSON(json) {
    return UpdateOrganizationUserRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationUserRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        user: !exists(json, 'user') ? undefined : UpdateOrganizationUserRequestUserFromJSON(json['user']),
        password: !exists(json, 'password') ? undefined : json['password']
    };
}
export function UpdateOrganizationUserRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user: UpdateOrganizationUserRequestUserToJSON(value.user),
        password: value.password
    };
}
