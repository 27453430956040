import { useState } from 'react'
import { Box, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Colors, Pallete } from 'src/components/atoms'
import { Info, Close } from '@material-ui/icons'
import { Document, Organization } from '@noco/http-client/lib/noco'
import { DocumentSlideHeaderTooltip } from 'src/components/organisms/DocumentSlideHeaderTooltip'
import { useGlobalStyles } from 'src/styles/theme'

export const SP_HEADER_HEIGHT = 104

const useStyles = makeStyles(() =>
  createStyles({
    toolTipCloseButton: {
      padding: 1,
      alignItems: 'baseline',
      marginLeft: 8,
      color: Pallete.functional.background.default,
      height: 'fit-content',
      pointerEvents: 'auto'
    },
    infoButton: { padding: 8, color: Colors.base.middleGray },
    nocoIcon: {
      marginBottom: 10
    }
  })
)

export const PageDocumentDetailPreviewSPHeader = ({
  document,
  organization,
  isEuView = false,
  siteId
}: {
  document: Document
  organization: Organization
  isEuView?: boolean
  siteId?: string
}) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="8px 12px 12px"
      borderBottom="1px solid #DADCE0"
      bgcolor="#fff"
      zIndex={100}
      gridArea="header"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="4px">
        {organization.hideAd ? (
          organization.avatar?.url ? (
            <Box height="42px">
              <img
                src={organization.avatar.url!}
                alt="カスタムロゴ"
                className={globalClasses.imgContain}
                style={{ objectPosition: '0 50%' }}
              />
            </Box>
          ) : (
            <Box />
          )
        ) : (
          <Box
            display="flex"
            alignItems="center"
            onClick={() => window.open('https://noco.sale/')}
            className={globalClasses.cursorPointer}
          >
            <img style={{ width: '22px', height: '22px' }} src={'/images/nocosell_icon.svg'} />
          </Box>
        )}

        <Box display="flex">
          <DocumentSlideHeaderTooltip document={document} isEuView={isEuView} siteId={siteId} />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" width={1}>
        <Typography variant="h3" style={{ width: '100%', wordBreak: 'break-all' }}>
          {document.title}
        </Typography>
        {document?.description && (
          <HtmlTooltip
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box display="flex" alignContent="flex-start">
                <Typography variant="body2">{document?.description}</Typography>
                <IconButton className={classes.toolTipCloseButton} onClick={handleTooltipClose}>
                  <Close />
                </IconButton>
              </Box>
            }
          >
            <IconButton className={classes.infoButton} onClick={handleTooltipOpen}>
              <Info />
            </IconButton>
          </HtmlTooltip>
        )}
      </Box>
    </Box>
  )
}

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#3B5D81',
    padding: 16
  }
}))(Tooltip)
