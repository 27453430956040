import { Box, Typography, createStyles, makeStyles } from '@material-ui/core'
import { DesktopWindows, PhoneAndroid } from '@material-ui/icons'
import { Colors } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

interface SelectDeviceTabs {
  isPCPreview?: boolean
  onChangePCPreview?: () => void
  onChangeSPPreview?: () => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    tab: {
      background: '#EEF1F4',
      border: '1px solid #DADCE0',
      color: Colors.base.middleGray
    },
    activeTab: {
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      color: Colors.accent.keyPurple.default
    }
  })
})

export const SelectDeviceTabs = ({ isPCPreview = true, onChangePCPreview, onChangeSPPreview }: SelectDeviceTabs) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  return (
    <Box display="flex" alignItems="center" justifyContent="center" className={globalClasses.cursorPointer}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={isPCPreview ? classes.activeTab : classes.tab}
        onClick={onChangePCPreview}
        style={{ borderRadius: '6px 0px 0px 6px' }}
        width={64}
        height={28}
      >
        <DesktopWindows
          fontSize="small"
          htmlColor={isPCPreview ? Colors.accent.keyPurple.default : Colors.base.middleGray}
        />
        <Box ml="4px">
          <Typography variant="h6">PC</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={isPCPreview ? classes.tab : classes.activeTab}
        onClick={onChangeSPPreview}
        style={{ borderRadius: '0px 6px 6px 0px' }}
        width={64}
        height={28}
      >
        <PhoneAndroid
          fontSize="small"
          htmlColor={isPCPreview ? Colors.base.middleGray : Colors.accent.keyPurple.default}
        />
        <Box ml="4px">
          <Typography variant="h6">SP</Typography>
        </Box>
      </Box>
    </Box>
  )
}
