/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DocumentSlideImageFromJSON, DocumentSlideImageToJSON } from './DocumentSlideImage';
import { EnumSlideProcessingStatusFromJSON, EnumSlideProcessingStatusToJSON } from './EnumSlideProcessingStatus';
import { UploadedFileFromJSON, UploadedFileToJSON } from './UploadedFile';
export function DocumentSlideFromJSON(json) {
    return DocumentSlideFromJSONTyped(json, false);
}
export function DocumentSlideFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
        version: !exists(json, 'version') ? undefined : json['version'],
        title: !exists(json, 'title') ? undefined : json['title'],
        slideImages: !exists(json, 'slide_images')
            ? undefined
            : json['slide_images'].map(DocumentSlideImageFromJSON),
        processingStatus: !exists(json, 'processing_status')
            ? undefined
            : EnumSlideProcessingStatusFromJSON(json['processing_status']),
        file: !exists(json, 'file') ? undefined : UploadedFileFromJSON(json['file']),
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function DocumentSlideToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        document_id: value.documentId,
        version: value.version,
        title: value.title,
        slide_images: value.slideImages === undefined ? undefined : value.slideImages.map(DocumentSlideImageToJSON),
        processing_status: EnumSlideProcessingStatusToJSON(value.processingStatus),
        file: UploadedFileToJSON(value.file),
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
