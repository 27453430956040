import { GetContactContactListsResponseData } from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useState } from 'react'
import { AddOrRemoveContact } from 'src/components/molecules/SelectTableRow/AddOrRemoveContact'
import { useCreateContactList, useUpdateContactList } from 'src/fixtures/modules/contact/hooks'
import { useListSelectorContactsList } from 'src/fixtures/modules/selector/hooks'
import { KeyedMutator } from 'swr'

export interface ContactListProps {
  contactId: string
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  mutateList: KeyedMutator<GetContactContactListsResponseData | undefined>
}

export const ContactListModal = ({ contactId, isOpen, setIsOpen, mutateList }: ContactListProps) => {
  const [keyword, setKeyword] = useState('')
  const { handleCreateContactList } = useCreateContactList()
  const { handleUpdateContactList } = useUpdateContactList({
    page: 1,
    per: 9999
  })
  const { data: selectorList, mutate } = useListSelectorContactsList({ page: 1, per: 9999, text: keyword })

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleUpdateContactListConfirm = useCallback(
    async (contactListId: string, contactIds: string[]) => {
      await handleUpdateContactList(contactListId, contactIds)
      mutateList()
      onClose()
      setKeyword('')
    },
    [handleUpdateContactList, onClose, mutateList]
  )

  const handleContactListConfirm = useCallback(
    async (listName: string, contactIds: string[]) => {
      await handleCreateContactList(listName, contactIds)
      mutateList()
      onClose()
      setKeyword('')
    },
    [handleCreateContactList, onClose, mutateList]
  )

  useEffect(() => {
    if (isOpen) {
      mutate()
      setKeyword('')
    }
  }, [isOpen, mutate])

  return (
    <AddOrRemoveContact
      selectedIds={[contactId]}
      keyword={keyword}
      selectorList={selectorList}
      onArchivedBoxClose={onClose}
      onSetKeyword={setKeyword}
      onCreateList={handleContactListConfirm}
      onUpdateList={handleUpdateContactListConfirm}
      modalAddContactOpen={isOpen}
      onSetModalAddContactOpen={setIsOpen}
    />
  )
}
