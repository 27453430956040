/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateDocumentMiscSettingRequestDocumentMiscSetting,
  UpdateDocumentMiscSettingRequestDocumentMiscSettingFromJSON,
  UpdateDocumentMiscSettingRequestDocumentMiscSettingFromJSONTyped,
  UpdateDocumentMiscSettingRequestDocumentMiscSettingToJSON
} from './UpdateDocumentMiscSettingRequestDocumentMiscSetting'

/**
 *
 * @export
 * @interface UpdateDocumentMiscSettingRequest
 */
export interface UpdateDocumentMiscSettingRequest {
  /**
   *
   * @type {UpdateDocumentMiscSettingRequestDocumentMiscSetting}
   * @memberof UpdateDocumentMiscSettingRequest
   */
  documentMiscSetting?: UpdateDocumentMiscSettingRequestDocumentMiscSetting
}

export function UpdateDocumentMiscSettingRequestFromJSON(json: any): UpdateDocumentMiscSettingRequest {
  return UpdateDocumentMiscSettingRequestFromJSONTyped(json, false)
}

export function UpdateDocumentMiscSettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentMiscSettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentMiscSetting: !exists(json, 'document_misc_setting')
      ? undefined
      : UpdateDocumentMiscSettingRequestDocumentMiscSettingFromJSON(json['document_misc_setting'])
  }
}

export function UpdateDocumentMiscSettingRequestToJSON(value?: UpdateDocumentMiscSettingRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_misc_setting: UpdateDocumentMiscSettingRequestDocumentMiscSettingToJSON(value.documentMiscSetting)
  }
}
