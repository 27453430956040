import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { LabelFormRow } from 'src/components/molecules'
import { Colors, Input, Typography } from 'src/components/atoms'
import { useFormik } from 'formik'
import { useUpdateMe } from 'src/fixtures/modules/me/hooks'
import { ModalChangeEmailConfirm } from '../../../modals/ModalChangeEmailConfirm/index'
import { EmailProps } from './type'

export const Email = ({ email }: EmailProps) => {
  const [open, setOpen] = useState(false)
  const { handleUpdateMe } = useUpdateMe()
  const { values, getFieldProps, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: { email },
    onSubmit: values => {
      handleUpdateMe({ user: { encryptedEmail: values.email } }, () => setOpen(true))
    }
  })

  return (
    <Box borderBottom={`1px solid ${Colors.background.silver}`} py="16px">
      <LabelFormRow
        label="メールアドレス"
        submitButtonTitle="認証メールを送信する"
        onSave={() => handleSubmit()}
        onCancel={() => {
          setFieldValue('email', email)
        }}
        readElement={
          <>
            <Box display="flex" alignItems="center">
              <Typography fontSize="s" lh="tight">
                {values.email}
              </Typography>
            </Box>
          </>
        }
        editElement={
          <Box display="flex" alignItems="center">
            <Box display="flex" flexDirection="column">
              <Box pb="24px">
                <Typography variant="subtitle1">{email}</Typography>
              </Box>
              <Box mb="4px">
                <Typography fontSize="s" fontWeight="bold" lh="tight">
                  新しいメールアドレス
                </Typography>
              </Box>
              <Input
                {...getFieldProps('email')}
                onBlur={() => {
                  setFieldValue('email', values.email.trim())
                }}
              />
              <Box mt="16px" />
              <Typography fontSize="xs" fontWeight="normal" lh="tight" color="textSecondary">
                メールアドレスの変更には、新しいメールアドレス宛に届く認証メールの認証ボタンを押す必要があります
                <br />
                尚、認証ボタンを押すまで、新しいメールアドレスに変更されませんのでご注意ください
              </Typography>
            </Box>
          </Box>
        }
      />

      <ModalChangeEmailConfirm email={values.email} open={open} onClose={() => setOpen(false)} />
    </Box>
  )
}
