import localStorageMemory from 'localstorage-memory'

export const StorageKey = {
  CONTACTS_TABLE_COLUMNS: (orgId: string) => `contacts-table-columns-${orgId}`,
  COMPANY_TABLE_COLUMNS: (orgId: string) => `company-table-columns-${orgId}`,
  DOCUMENT_TABLE_COLUMNS: (orgId: string) => `document-table-columns-${orgId}`,
  TABLE_PAGINATION: (tableName: string) => `table-pagination-${tableName}`,
  DRAFT_MATERIAL: (name: string) => `draft_${name}`
}

const STORAGE_PREFIX_KEY = 'noco_'

export enum StorageType {
  NATIVE,
  MEMORY
}

export class LocalStorageUtils {
  private storageType: StorageType
  private storage: Storage
  private prefix: string

  public constructor(storage: Storage, prefix: string) {
    this.prefix = prefix
    this.storage = storage

    if (typeof localStorage !== 'undefined' && storage === localStorage) {
      this.storageType = StorageType.NATIVE
    } else if (storage === localStorageMemory) {
      this.storageType = StorageType.MEMORY
    } else {
      throw new Error('Invalid storage type')
    }
  }

  private assertValidEnvironment() {
    if (typeof window === 'undefined') {
      throw new Error('Do not refer to storage outside the browser enviroment')
    }
  }

  private createKey(key: string) {
    return `${this.prefix}${key}`
  }

  public is(storageType: StorageType) {
    return this.storageType === storageType
  }

  public set<T>(key: string, value: T) {
    this.assertValidEnvironment()

    this.storage.setItem(this.createKey(key), JSON.stringify(value))
  }

  public get<T>(key: string): T | null {
    this.assertValidEnvironment()

    return JSON.parse(this.storage.getItem(this.createKey(key)) as any)
  }

  public has(key: string) {
    this.assertValidEnvironment()

    return this.storage.getItem(this.createKey(key)) != null
  }

  public remove(key: string) {
    this.assertValidEnvironment()

    this.storage.removeItem(this.createKey(key))
  }

  public pull<T>(key: string): T | null {
    const value = this.get<T>(key)

    this.remove(key)

    return value
  }

  public clear() {
    this.assertValidEnvironment()

    this.storage.clear()
  }
}

function canUseStorage(): boolean {
  try {
    const key = '__test__'
    localStorage.setItem(key, key)
    localStorage.removeItem(key)

    return true
  } catch (e) {
    // 容量を超えた場合は、古いキーを削除して再評価する
    if (e == DOMException.QUOTA_EXCEEDED_ERR) {
      const keys = Object.keys(localStorage)
      if (keys.length > 0) {
        localStorage.removeItem(keys[0])
        return canUseStorage()
      }
      return false
    }
    return false
  }
}

export const localStorageUtils: LocalStorageUtils = new LocalStorageUtils(
  typeof window !== 'undefined' && canUseStorage() ? localStorage : localStorageMemory,
  STORAGE_PREFIX_KEY
)
