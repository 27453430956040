/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CompanyTimeline,
  CompanyTimelineFromJSON,
  CompanyTimelineFromJSONTyped,
  CompanyTimelineToJSON
} from './CompanyTimeline'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface CompanyTimelinesResponseData
 */
export interface CompanyTimelinesResponseData {
  /**
   *
   * @type {Array<CompanyTimeline>}
   * @memberof CompanyTimelinesResponseData
   */
  companyTimelines?: Array<CompanyTimeline>
  /**
   *
   * @type {PageInfo}
   * @memberof CompanyTimelinesResponseData
   */
  pageInfo?: PageInfo
}

export function CompanyTimelinesResponseDataFromJSON(json: any): CompanyTimelinesResponseData {
  return CompanyTimelinesResponseDataFromJSONTyped(json, false)
}

export function CompanyTimelinesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CompanyTimelinesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyTimelines: !exists(json, 'company_timelines')
      ? undefined
      : (json['company_timelines'] as Array<any>).map(CompanyTimelineFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function CompanyTimelinesResponseDataToJSON(value?: CompanyTimelinesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_timelines:
      value.companyTimelines === undefined
        ? undefined
        : (value.companyTimelines as Array<any>).map(CompanyTimelineToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
