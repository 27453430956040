/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumChatSummaryRepliedStatusFromJSON, EnumChatSummaryRepliedStatusToJSON } from './EnumChatSummaryRepliedStatus';
export function ShallowChatSummaryFromJSON(json) {
    return ShallowChatSummaryFromJSONTyped(json, false);
}
export function ShallowChatSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        contactId: !exists(json, 'contact_id') ? undefined : json['contact_id'],
        latestChatMessageId: !exists(json, 'latest_chat_message_id') ? undefined : json['latest_chat_message_id'],
        repliedAt: !exists(json, 'replied_at') ? undefined : json['replied_at'],
        repliedStatus: !exists(json, 'replied_status')
            ? undefined
            : EnumChatSummaryRepliedStatusFromJSON(json['replied_status']),
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function ShallowChatSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        contact_id: value.contactId,
        latest_chat_message_id: value.latestChatMessageId,
        replied_at: value.repliedAt,
        replied_status: EnumChatSummaryRepliedStatusToJSON(value.repliedStatus),
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
