/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  SiteSectionDocument,
  SiteSectionDocumentFromJSON,
  SiteSectionDocumentFromJSONTyped,
  SiteSectionDocumentToJSON
} from './SiteSectionDocument'

/**
 *
 * @export
 * @interface CreateSiteSectionDocumentResponseData
 */
export interface CreateSiteSectionDocumentResponseData {
  /**
   *
   * @type {SiteSectionDocument}
   * @memberof CreateSiteSectionDocumentResponseData
   */
  siteSectionDocument?: SiteSectionDocument
}

export function CreateSiteSectionDocumentResponseDataFromJSON(json: any): CreateSiteSectionDocumentResponseData {
  return CreateSiteSectionDocumentResponseDataFromJSONTyped(json, false)
}

export function CreateSiteSectionDocumentResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateSiteSectionDocumentResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteSectionDocument: !exists(json, 'site_section_document')
      ? undefined
      : SiteSectionDocumentFromJSON(json['site_section_document'])
  }
}

export function CreateSiteSectionDocumentResponseDataToJSON(value?: CreateSiteSectionDocumentResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_section_document: SiteSectionDocumentToJSON(value.siteSectionDocument)
  }
}
