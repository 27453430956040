/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { VisitorMaterialableTrackFromJSON, VisitorMaterialableTrackToJSON } from './VisitorMaterialableTrack';
export function CreateMaterialableTrackResponseDataFromJSON(json) {
    return CreateMaterialableTrackResponseDataFromJSONTyped(json, false);
}
export function CreateMaterialableTrackResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        materialableTrack: !exists(json, 'materialable_track')
            ? undefined
            : VisitorMaterialableTrackFromJSON(json['materialable_track'])
    };
}
export function CreateMaterialableTrackResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        materialable_track: VisitorMaterialableTrackToJSON(value.materialableTrack)
    };
}
