/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function InvitedToOrganizationSignupRequestFromJSON(json) {
    return InvitedToOrganizationSignupRequestFromJSONTyped(json, false);
}
export function InvitedToOrganizationSignupRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        password: !exists(json, 'password') ? undefined : json['password'],
        token: !exists(json, 'token') ? undefined : json['token']
    };
}
export function InvitedToOrganizationSignupRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        first_name: value.firstName,
        last_name: value.lastName,
        password: value.password,
        token: value.token
    };
}
