/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  FilterContentPins1,
  FilterContentPins1FromJSON,
  FilterContentPins1FromJSONTyped,
  FilterContentPins1ToJSON
} from './FilterContentPins1'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetMaterialListsResponseData
 */
export interface GetMaterialListsResponseData {
  /**
   *
   * @type {Array<FilterContentPins1>}
   * @memberof GetMaterialListsResponseData
   */
  materialLists?: Array<FilterContentPins1>
  /**
   *
   * @type {PageInfo}
   * @memberof GetMaterialListsResponseData
   */
  pageInfo?: PageInfo
}

export function GetMaterialListsResponseDataFromJSON(json: any): GetMaterialListsResponseData {
  return GetMaterialListsResponseDataFromJSONTyped(json, false)
}

export function GetMaterialListsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMaterialListsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    materialLists: !exists(json, 'material_lists')
      ? undefined
      : (json['material_lists'] as Array<any>).map(FilterContentPins1FromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetMaterialListsResponseDataToJSON(value?: GetMaterialListsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    material_lists:
      value.materialLists === undefined ? undefined : (value.materialLists as Array<any>).map(FilterContentPins1ToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
