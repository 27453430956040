/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  RecordPorterHistory,
  RecordPorterHistoryFromJSON,
  RecordPorterHistoryFromJSONTyped,
  RecordPorterHistoryToJSON
} from './RecordPorterHistory'

/**
 *
 * @export
 * @interface GetLatestRecordPorterHistoryResponseData
 */
export interface GetLatestRecordPorterHistoryResponseData {
  /**
   *
   * @type {RecordPorterHistory}
   * @memberof GetLatestRecordPorterHistoryResponseData
   */
  recordPorterHistory?: RecordPorterHistory
}

export function GetLatestRecordPorterHistoryResponseDataFromJSON(json: any): GetLatestRecordPorterHistoryResponseData {
  return GetLatestRecordPorterHistoryResponseDataFromJSONTyped(json, false)
}

export function GetLatestRecordPorterHistoryResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetLatestRecordPorterHistoryResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    recordPorterHistory: !exists(json, 'record_porter_history')
      ? undefined
      : RecordPorterHistoryFromJSON(json['record_porter_history'])
  }
}

export function GetLatestRecordPorterHistoryResponseDataToJSON(
  value?: GetLatestRecordPorterHistoryResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    record_porter_history: RecordPorterHistoryToJSON(value.recordPorterHistory)
  }
}
