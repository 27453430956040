/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateMeRequestUser
 */
export interface UpdateMeRequestUser {
  /**
   *
   * @type {string}
   * @memberof UpdateMeRequestUser
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateMeRequestUser
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateMeRequestUser
   */
  encryptedEmail?: string
  /**
   *
   * @type {string}
   * @memberof UpdateMeRequestUser
   */
  opportunityToolUrl?: string
}

export function UpdateMeRequestUserFromJSON(json: any): UpdateMeRequestUser {
  return UpdateMeRequestUserFromJSONTyped(json, false)
}

export function UpdateMeRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeRequestUser {
  if (json === undefined || json === null) {
    return json
  }
  return {
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
    opportunityToolUrl: !exists(json, 'opportunity_tool_url') ? undefined : json['opportunity_tool_url']
  }
}

export function UpdateMeRequestUserToJSON(value?: UpdateMeRequestUser | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    last_name: value.lastName,
    first_name: value.firstName,
    encrypted_email: value.encryptedEmail,
    opportunity_tool_url: value.opportunityToolUrl
  }
}
