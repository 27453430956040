/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateCompanyResponseData,
  UpdateCompanyResponseDataFromJSON,
  UpdateCompanyResponseDataFromJSONTyped,
  UpdateCompanyResponseDataToJSON
} from './UpdateCompanyResponseData'

/**
 *
 * @export
 * @interface UpdateCompanyResponse
 */
export interface UpdateCompanyResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateCompanyResponse
   */
  status?: number
  /**
   *
   * @type {UpdateCompanyResponseData}
   * @memberof UpdateCompanyResponse
   */
  data?: UpdateCompanyResponseData
}

export function UpdateCompanyResponseFromJSON(json: any): UpdateCompanyResponse {
  return UpdateCompanyResponseFromJSONTyped(json, false)
}

export function UpdateCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : UpdateCompanyResponseDataFromJSON(json['data'])
  }
}

export function UpdateCompanyResponseToJSON(value?: UpdateCompanyResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: UpdateCompanyResponseDataToJSON(value.data)
  }
}
