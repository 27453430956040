import React from 'react'
import { Avatar, Box, createStyles, makeStyles } from '@material-ui/core'
import { Colors, Typography, DynamicMuiIcon } from 'src/components/atoms'
import { ContactTimeline, EnumUserOperationKind } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'

const useStyles = makeStyles(() =>
  createStyles({
    lineLineLabel: {
      background: Colors.label.blue,
      color: Colors.base.navyBlue,
      borderRadius: 4,
      padding: '3px 8px'
    },
    avatar: {
      display: 'inline-flex',
      height: 20,
      width: 20,
      verticalAlign: 'sub',
      marginRight: 6
    },
    icon: {
      display: 'inline-flex',
      height: 16,
      width: 16,
      verticalAlign: 'text-bottom',
      marginRight: 4
    },
    link: {
      color: Colors.accent.keyBlue.default
    },
    small: {
      padding: '8px 10px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '13px'
        }
      }
    },
    medium: {
      padding: '10px 12px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '14px'
        }
      }
    }
  })
)

interface ClientTimeLineItemUserOperationProps {
  item: ContactTimeline
  size?: 'small' | 'medium'
}

export const ClientTimeLineItemUserOperation = ({ item, size = 'medium' }: ClientTimeLineItemUserOperationProps) => {
  const classes = useStyles()
  return (
    <Box
      className={size === 'medium' ? classes.medium : classes.small}
      border={`1px solid ${Colors.background.silver}`}
      borderRadius="6px"
      bgcolor="#fff"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="6px">
        <Box display="flex" alignItems="center" className="timeLineItemheading">
          <Box mr={1} display="flex" width="fit-content" color={Colors.base.middleGray}>
            <DynamicMuiIcon
              variant={
                item.userOperation?.kind === EnumUserOperationKind.CreateMaterialContactable ? 'iosShare' : 'sync'
              }
              style={{ fontSize: '16px' }}
            />
          </Box>
          <Typography lineheight="14px" fontWeight="bold" style={{ color: Colors.base.middleGray }}>
            {item.userOperation?.label}
          </Typography>
        </Box>

        <Typography variant="subtitle2" style={{ color: Colors.base.middleGray }}>
          {item.createdAt && dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME)}
        </Typography>
      </Box>

      <Box>
        <Box display="inline" mr="6px">
          <Avatar className={classes.avatar} src={item.userOperation?.user?.avatar?.url} />
          <Typography display="inline" fontSize="s" lineheight="18.48px" letterSpacing="tight" fontWeight="bold">
            {item.userOperation?.user?.displayName}
          </Typography>
        </Box>
        <Typography display="inline" fontSize="s" lineheight="18.48px" letterSpacing="tight">
          が
        </Typography>
        <Box display="inline" mr="6px">
          <Typography display="inline" fontSize="s" lineheight="18.48px" letterSpacing="tight">
            {item?.userOperation?.displayObject?.source?.text}
          </Typography>
        </Box>
        <Typography display="inline" fontSize="s" lineheight="18.48px" letterSpacing="tight">
          を
        </Typography>
        <Typography display="inline" fontSize="s" lineheight="18.48px" letterSpacing="tight">
          {item?.userOperation?.displayObject?.operate?.text}しました
        </Typography>
      </Box>
    </Box>
  )
}
