import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var FilterApi = /** @class */ (function () {
    function FilterApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    FilterApi.initialize = function (basePath, fetchApi) {
        if (!FilterApi.instance) {
            FilterApi.instance = new FilterApi(basePath, fetchApi);
        }
        return FilterApi.instance;
    };
    FilterApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1FilterApi(new Configuration(config));
    };
    return FilterApi;
}());
export { FilterApi };
