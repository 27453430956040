/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CompanySetting,
  CompanySettingFromJSON,
  CompanySettingFromJSONTyped,
  CompanySettingToJSON
} from './CompanySetting'

/**
 *
 * @export
 * @interface GetCompanySettingsResponseData
 */
export interface GetCompanySettingsResponseData {
  /**
   *
   * @type {Array<CompanySetting>}
   * @memberof GetCompanySettingsResponseData
   */
  activeCompanySettings?: Array<CompanySetting>
  /**
   *
   * @type {Array<CompanySetting>}
   * @memberof GetCompanySettingsResponseData
   */
  inactiveCompanySettings?: Array<CompanySetting>
}

export function GetCompanySettingsResponseDataFromJSON(json: any): GetCompanySettingsResponseData {
  return GetCompanySettingsResponseDataFromJSONTyped(json, false)
}

export function GetCompanySettingsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCompanySettingsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activeCompanySettings: !exists(json, 'active_company_settings')
      ? undefined
      : (json['active_company_settings'] as Array<any>).map(CompanySettingFromJSON),
    inactiveCompanySettings: !exists(json, 'inactive_company_settings')
      ? undefined
      : (json['inactive_company_settings'] as Array<any>).map(CompanySettingFromJSON)
  }
}

export function GetCompanySettingsResponseDataToJSON(value?: GetCompanySettingsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    active_company_settings:
      value.activeCompanySettings === undefined
        ? undefined
        : (value.activeCompanySettings as Array<any>).map(CompanySettingToJSON),
    inactive_company_settings:
      value.inactiveCompanySettings === undefined
        ? undefined
        : (value.inactiveCompanySettings as Array<any>).map(CompanySettingToJSON)
  }
}
