import { Box, ButtonBase, createStyles, IconButton, makeStyles, Popover } from '@material-ui/core'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DatePicker, DynamicMuiIcon, Typography } from 'src/components/atoms'

export interface SelectDatePickerProps {
  disabled: boolean
  expiredValue: string | null
  onConfirm: (value: string | null) => void
  onClear?: (value: null) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonBase: {
      width: '100%',
      '&.Mui-disabled': {
        background: Colors.background.gray
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    }
  })
)

export const SelectDatePicker = ({ disabled, expiredValue, onConfirm, onClear }: SelectDatePickerProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())

  const position = useMemo(() => {
    if (!anchorEl) return
    const rect = anchorEl.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [anchorEl])

  useEffect(() => {
    if (!expiredValue) return
    setSelectedDate(new Date(expiredValue))
  }, [expiredValue])

  const handleClose = useCallback(() => {
    setCalendarOpen(false)
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setCalendarOpen(true)
  }, [])

  const handleCalnedarChange = useCallback(date => {
    setSelectedDate(date)
  }, [])

  const handleCalendarConfirm = useCallback(() => {
    onConfirm(selectedDate ? format(new Date(selectedDate).getTime(), 'yyyy-MM-dd', { locale: ja }) : null)
    handleClose()
  }, [selectedDate, onConfirm, handleClose])

  const handleInputDateClear = useCallback(() => {
    onClear?.(null)
    setSelectedDate(null)
  }, [onClear])

  return (
    <>
      <Box
        width={1}
        height="34px"
        border={`1px solid ${Colors.background.silver}`}
        borderRadius="4px"
        pl="14px"
        pr="4px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="inset 0px 1px 2px rgba(10, 10, 10, 0.1)"
        bgcolor={disabled ? Colors.background.gray : 'white'}
      >
        <ButtonBase className={classes.buttonBase} onClick={handleClick} disabled={disabled}>
          <Box display="flex" alignItems="center" width={1}>
            <Box display="flex" alignItems="center" color={Colors.base.middleGray} mr="8px">
              <DynamicMuiIcon variant="meeting" size="20px" color="inherit" fontSize="inherit" />
              {!expiredValue && (
                <Box color={Colors.base.placeHolder} pl="8px">
                  <Typography fontSize="s" lineheight="14px">
                    日付を選択してください
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography variant="subtitle1">
              {expiredValue ? format(new Date(expiredValue).getTime(), 'yyyy/MM/dd (E)', { locale: ja }) : ''}
            </Typography>
          </Box>
        </ButtonBase>
        <Box color={Colors.base.middleGray} mr="10px" flex={1} justifyContent="flex-start" display="flex">
          {expiredValue && onClear && (
            <IconButton onClick={handleInputDateClear}>
              <DynamicMuiIcon variant="close" size="20px" color="inherit" fontSize="inherit" />
            </IconButton>
          )}
        </Box>
      </Box>

      <Popover
        id="calendar-popover"
        open={calendarOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        anchorReference="anchorPosition"
        anchorPosition={position}
        PaperProps={{
          className: classes.paper,
          style: { width: 278 }
        }}
      >
        <Box>
          <DatePicker date={selectedDate ? selectedDate : undefined} onChangeDate={handleCalnedarChange} />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            py="12px"
            pr="24px"
            bgcolor={Colors.background.lightGray}
            boxShadow={`inset 0px 1px 0px ${Colors.background.silver}`}
          >
            <Button kind="neutral" variant="outlined" title="キャンセル" onClick={handleClose} />
            <Box ml="12px" />
            <Button title="保存" onClick={handleCalendarConfirm} />
          </Box>
        </Box>
      </Popover>
    </>
  )
}
