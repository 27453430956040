import { useCallback } from 'react'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'

export const useGetDefaultImage = (division?: string) => {
  const { data: auth } = useAuthenticate()
  const defaultImageApi = nocoSDK.client?.userService.defaultImageApi
  const func = useCallback(async () => {
    if (!auth) return
    return await defaultImageApi
      ?.ApiFactory(auth?.token)
      .userV1DefaultImagesGet({ division })
      .then(res => res.data)
  }, [auth, defaultImageApi, division])
  return useSWR(auth?.token && SWRCachePath.getDefaultImage, func)
}
