/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumUserOperationKindFromJSON, EnumUserOperationKindToJSON } from './EnumUserOperationKind';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
import { UserOperationDisplayObjectFromJSON, UserOperationDisplayObjectToJSON } from './UserOperationDisplayObject';
export function UserOperationFromJSON(json) {
    return UserOperationFromJSONTyped(json, false);
}
export function UserOperationFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json['id'],
        userId: json['user_id'],
        operableType: json['operable_type'],
        operableId: json['operable_id'],
        kind: EnumUserOperationKindFromJSON(json['kind']),
        label: json['label'],
        description: !exists(json, 'description') ? undefined : json['description'],
        createdAt: json['created_at'],
        updatedAt: json['updated_at'],
        user: UserForDisplayFromJSON(json['user']),
        displayObject: UserOperationDisplayObjectFromJSON(json['display_object'])
    };
}
export function UserOperationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        user_id: value.userId,
        operable_type: value.operableType,
        operable_id: value.operableId,
        kind: EnumUserOperationKindToJSON(value.kind),
        label: value.label,
        description: value.description,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        user: UserForDisplayToJSON(value.user),
        display_object: UserOperationDisplayObjectToJSON(value.displayObject)
    };
}
