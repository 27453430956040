/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateDocumentShareByLinkRequestDocumentShare,
  UpdateDocumentShareByLinkRequestDocumentShareFromJSON,
  UpdateDocumentShareByLinkRequestDocumentShareFromJSONTyped,
  UpdateDocumentShareByLinkRequestDocumentShareToJSON
} from './UpdateDocumentShareByLinkRequestDocumentShare'

/**
 *
 * @export
 * @interface UpdateDocumentShareByLinkRequest
 */
export interface UpdateDocumentShareByLinkRequest {
  /**
   *
   * @type {UpdateDocumentShareByLinkRequestDocumentShare}
   * @memberof UpdateDocumentShareByLinkRequest
   */
  documentShare?: UpdateDocumentShareByLinkRequestDocumentShare
}

export function UpdateDocumentShareByLinkRequestFromJSON(json: any): UpdateDocumentShareByLinkRequest {
  return UpdateDocumentShareByLinkRequestFromJSONTyped(json, false)
}

export function UpdateDocumentShareByLinkRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentShareByLinkRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentShare: !exists(json, 'document_share')
      ? undefined
      : UpdateDocumentShareByLinkRequestDocumentShareFromJSON(json['document_share'])
  }
}

export function UpdateDocumentShareByLinkRequestToJSON(value?: UpdateDocumentShareByLinkRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_share: UpdateDocumentShareByLinkRequestDocumentShareToJSON(value.documentShare)
  }
}
