import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const UserAttention = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="74" height="40" viewBox="0 0 74 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9993 20C23.6827 20 26.666 17.0167 26.666 13.3333C26.666 9.65 23.6827 6.66667 19.9993 6.66667C16.316 6.66667 13.3327 9.65 13.3327 13.3333C13.3327 17.0167 16.316 20 19.9993 20ZM19.9993 23.3333C15.5493 23.3333 6.66602 25.5667 6.66602 30V31.6667C6.66602 32.5833 7.41602 33.3333 8.33268 33.3333H31.666C32.5827 33.3333 33.3327 32.5833 33.3327 31.6667V30C33.3327 25.5667 24.4493 23.3333 19.9993 23.3333Z"
        fill="#DE1B63"
      />
      <path
        d="M59 18.75C59.6875 18.75 60.25 19.3125 60.25 20V25C60.25 25.6875 59.6875 26.25 59 26.25C58.3125 26.25 57.75 25.6875 57.75 25V20C57.75 19.3125 58.3125 18.75 59 18.75ZM58.9875 12.5C52.0875 12.5 46.5 18.1 46.5 25C46.5 31.9 52.0875 37.5 58.9875 37.5C65.9 37.5 71.5 31.9 71.5 25C71.5 18.1 65.9 12.5 58.9875 12.5ZM59 35C53.475 35 49 30.525 49 25C49 19.475 53.475 15 59 15C64.525 15 69 19.475 69 25C69 30.525 64.525 35 59 35ZM60.25 31.25H57.75V28.75H60.25V31.25Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default UserAttention
