/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface JobFile
 */
export interface JobFile {
  /**
   *
   * @type {string}
   * @memberof JobFile
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof JobFile
   */
  division?: string
  /**
   *
   * @type {string}
   * @memberof JobFile
   */
  name?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof JobFile
   */
  file?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {number}
   * @memberof JobFile
   */
  rows?: number
  /**
   *
   * @type {number}
   * @memberof JobFile
   */
  size?: number
  /**
   *
   * @type {string}
   * @memberof JobFile
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof JobFile
   */
  updatedAt?: string
  /**
   *
   * @type {string}
   * @memberof JobFile
   */
  jobId?: string
}

export function JobFileFromJSON(json: any): JobFile {
  return JobFileFromJSONTyped(json, false)
}

export function JobFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFile {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    division: !exists(json, 'division') ? undefined : json['division'],
    name: !exists(json, 'name') ? undefined : json['name'],
    file: !exists(json, 'file') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['file']),
    rows: !exists(json, 'rows') ? undefined : json['rows'],
    size: !exists(json, 'size') ? undefined : json['size'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    jobId: !exists(json, 'job_id') ? undefined : json['job_id']
  }
}

export function JobFileToJSON(value?: JobFile | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    division: value.division,
    name: value.name,
    file: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.file),
    rows: value.rows,
    size: value.size,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    job_id: value.jobId
  }
}
