/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumEmailTransactionableSendStatus = {
    Wait: 'wait',
    Ready: 'ready',
    Sent: 'sent',
    Resent: 'resent',
    Fail: 'fail',
    Ignore: 'ignore'
};
export function EnumEmailTransactionableSendStatusFromJSON(json) {
    return EnumEmailTransactionableSendStatusFromJSONTyped(json, false);
}
export function EnumEmailTransactionableSendStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumEmailTransactionableSendStatusToJSON(value) {
    return value;
}
