import { useState } from 'react'

// TODO: packages/web/src/components/modals/ModalDocumentShare/hooks.tsx とマージする

export interface FormSubmitter {
  isDisabled: boolean
  setIsDisabled: (isDisabled: boolean) => void
}

export const useFormSubmitter = (): FormSubmitter => {
  const [isDisabled, setIsDisabled] = useState(false)

  return { isDisabled, setIsDisabled }
}
