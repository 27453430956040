/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { UploadingFile, UploadingFileFromJSON, UploadingFileFromJSONTyped, UploadingFileToJSON } from './UploadingFile'

/**
 *
 * @export
 * @interface UpdateChatMessageRequest
 */
export interface UpdateChatMessageRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateChatMessageRequest
   */
  documentId?: string
  /**
   *
   * @type {string}
   * @memberof UpdateChatMessageRequest
   */
  message?: string
  /**
   *
   * @type {Array<UploadingFile>}
   * @memberof UpdateChatMessageRequest
   */
  files?: Array<UploadingFile>
}

export function UpdateChatMessageRequestFromJSON(json: any): UpdateChatMessageRequest {
  return UpdateChatMessageRequestFromJSONTyped(json, false)
}

export function UpdateChatMessageRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateChatMessageRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    message: !exists(json, 'message') ? undefined : json['message'],
    files: !exists(json, 'files') ? undefined : (json['files'] as Array<any>).map(UploadingFileFromJSON)
  }
}

export function UpdateChatMessageRequestToJSON(value?: UpdateChatMessageRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_id: value.documentId,
    message: value.message,
    files: value.files === undefined ? undefined : (value.files as Array<any>).map(UploadingFileToJSON)
  }
}
