import { Box, makeStyles, createStyles, Input } from '@material-ui/core'
import React, { useCallback } from 'react'
import clsx from 'clsx'
import { Colors, Typography, SvgIcon } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    base: {
      height: '20px',
      backgroundColor: '#fff'
    },
    per: {
      '& .MuiSelect-root': {
        fontSize: '12px'
      }
    },
    page: {
      '& .MuiInputBase-input': {
        fontSize: '12px',
        padding: 0,
        textAlign: 'center'
      }
    }
  })
})

export interface PagingProps {
  totalCount: number
  totalPages: number
  currentPage: number
  onCurrentPageChange: (page: number) => void
}

export const Paging = ({ totalCount, totalPages, currentPage, onCurrentPageChange }: PagingProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const handleInput = useCallback(
    (value: string) => {
      if (value.match(/[0-9]+/i)) {
        onCurrentPageChange(Number(value))
      } else {
        onCurrentPageChange(0)
      }
    },
    [onCurrentPageChange]
  )
  const handleCurrentPage = useCallback(
    (direction: 'prev' | 'next') => {
      if (currentPage) {
        if (direction === 'prev' && currentPage > 1) {
          const prevPage = currentPage - 1
          onCurrentPageChange(prevPage)
        } else if (direction === 'next' && currentPage + 1 <= totalPages) {
          const nextPage = currentPage + 1
          onCurrentPageChange(nextPage)
        }
      }
    },
    [currentPage, onCurrentPageChange, totalPages]
  )

  return (
    <Box
      width={1}
      display="flex"
      alignItems="center"
      height="34px"
      flex="1 0 auto"
      color={Colors.base.middleGray}
      borderTop={`1px solid ${Colors.background.gray}`}
      px="16px"
    >
      <Box display="flex" alignItems="center" flex={1}>
        <Typography fontSize="xs" fontWeight="bold" lineheight="12px">
          検索結果
        </Typography>
        <Box ml="6px" color={Colors.base.black}>
          <Typography fontSize="xs" fontWeight="bold" lineheight="12px">
            {totalCount}件
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <Box
          px="9px"
          display="flex"
          height="20px"
          alignItems="center"
          className={globalClasses.cursorPointer}
          onClick={() => handleCurrentPage('prev')}
        >
          <SvgIcon variant="leftArrow" color={Colors.base.middleGray} size="12px" />
        </Box>
        <Box width="34px">
          <Input
            fullWidth
            className={clsx(classes.base, classes.page)}
            value={currentPage != 0 ? currentPage : ''}
            name="pageNum"
            onChange={e => handleInput(e.target.value as string)}
          />
        </Box>
        <Box width="4px" />
        <Typography fontSize="xs" lineheight="12px">
          / {totalPages}ページ
        </Typography>
        <Box
          px="9px"
          display="flex"
          height="20px"
          alignItems="center"
          className={globalClasses.cursorPointer}
          onClick={() => handleCurrentPage('next')}
        >
          <SvgIcon variant="rightArrow" color={Colors.base.middleGray} size="12px" />
        </Box>
      </Box>
      <Box flex={1} />
    </Box>
  )
}
