import React from 'react'
import { Table as MuiTable } from '@devexpress/dx-react-grid-material-ui'
import { Box } from '@material-ui/core'
import { TableSelection } from '@devexpress/dx-react-grid'
import { Checkbox } from 'src/components/atoms/Checkbox'

export const SelectionBodyCell = (props: TableSelection.CellProps) => {
  const { selected, onToggle, ...otherProps } = props
  return (
    <MuiTable.Cell {...(otherProps as any)}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Checkbox
          size="small"
          checked={selected}
          onClick={e => {
            e.stopPropagation()
            onToggle()
          }}
        />
      </Box>
    </MuiTable.Cell>
  )
}
