import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { DynamicMuiIcon, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      /* 元のスタイルで important指定がされているため*/
      padding: '16px 8px !important'
    }
  })
})

export interface SiteNameCellProps {
  value: string
}

export const SiteNameCell = ({ value }: SiteNameCellProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <DynamicMuiIcon variant="computer" size="20px" />
      <Box width="8px" flexShrink={0} />
      <Box whiteSpace="pre-wrap" className={globalClasses.lineclamp1}>
        <Typography fontSize="s" lh="tight" fontWeight="bold">
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
