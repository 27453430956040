/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationPaymentCardInfoFromJSON, OrganizationPaymentCardInfoToJSON } from './OrganizationPaymentCardInfo';
export function OrganizationPaymentContractFromJSON(json) {
    return OrganizationPaymentContractFromJSONTyped(json, false);
}
export function OrganizationPaymentContractFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        startAt: new Date(json['start_at']),
        endAt: new Date(json['end_at']),
        nextBillingAt: new Date(json['next_billing_at']),
        card: OrganizationPaymentCardInfoFromJSON(json['card'])
    };
}
export function OrganizationPaymentContractToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        start_at: value.startAt.toISOString(),
        end_at: value.endAt.toISOString(),
        next_billing_at: value.nextBillingAt.toISOString(),
        card: OrganizationPaymentCardInfoToJSON(value.card)
    };
}
