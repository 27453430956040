/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { UploadedFile, UploadedFileFromJSON, UploadedFileFromJSONTyped, UploadedFileToJSON } from './UploadedFile'

/**
 *
 * @export
 * @interface DocumentMiscSetting
 */
export interface DocumentMiscSetting {
  /**
   *
   * @type {string}
   * @memberof DocumentMiscSetting
   */
  id?: string
  /**
   *
   * @type {boolean}
   * @memberof DocumentMiscSetting
   */
  isShowShareButton?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DocumentMiscSetting
   */
  isShowSnsButton?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DocumentMiscSetting
   */
  isPublicSearchEngine?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentMiscSetting
   */
  ogpTitle?: string | null
  /**
   *
   * @type {UploadedFile}
   * @memberof DocumentMiscSetting
   */
  ogpFile?: UploadedFile | null
  /**
   *
   * @type {string}
   * @memberof DocumentMiscSetting
   */
  ogpDescription?: string | null
  /**
   *
   * @type {boolean}
   * @memberof DocumentMiscSetting
   */
  isShowPrintingButton?: boolean
  /**
   *
   * @type {boolean}
   * @memberof DocumentMiscSetting
   */
  isShowDownloadButton?: boolean
  /**
   *
   * @type {string}
   * @memberof DocumentMiscSetting
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof DocumentMiscSetting
   */
  updatedAt?: string
}

export function DocumentMiscSettingFromJSON(json: any): DocumentMiscSetting {
  return DocumentMiscSettingFromJSONTyped(json, false)
}

export function DocumentMiscSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentMiscSetting {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    isShowShareButton: !exists(json, 'is_show_share_button') ? undefined : json['is_show_share_button'],
    isShowSnsButton: !exists(json, 'is_show_sns_button') ? undefined : json['is_show_sns_button'],
    isPublicSearchEngine: !exists(json, 'is_public_search_engine') ? undefined : json['is_public_search_engine'],
    ogpTitle: !exists(json, 'ogp_title') ? undefined : json['ogp_title'],
    ogpFile: !exists(json, 'ogp_file') ? undefined : UploadedFileFromJSON(json['ogp_file']),
    ogpDescription: !exists(json, 'ogp_description') ? undefined : json['ogp_description'],
    isShowPrintingButton: !exists(json, 'is_show_printing_button') ? undefined : json['is_show_printing_button'],
    isShowDownloadButton: !exists(json, 'is_show_download_button') ? undefined : json['is_show_download_button'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function DocumentMiscSettingToJSON(value?: DocumentMiscSetting | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    is_show_share_button: value.isShowShareButton,
    is_show_sns_button: value.isShowSnsButton,
    is_public_search_engine: value.isPublicSearchEngine,
    ogp_title: value.ogpTitle,
    ogp_file: UploadedFileToJSON(value.ogpFile),
    ogp_description: value.ogpDescription,
    is_show_printing_button: value.isShowPrintingButton,
    is_show_download_button: value.isShowDownloadButton,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
