/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { ChatMessageFromJSON, ChatMessageToJSON } from './ChatMessage';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetContactChatMessagesResponseDataFromJSON(json) {
    return GetContactChatMessagesResponseDataFromJSONTyped(json, false);
}
export function GetContactChatMessagesResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        chatMessages: !exists(json, 'chat_messages')
            ? undefined
            : json['chat_messages'].map(ChatMessageFromJSON),
        hiddenOldChatMessages: !exists(json, 'hidden_old_chat_messages') ? undefined : json['hidden_old_chat_messages'],
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetContactChatMessagesResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        chat_messages: value.chatMessages === undefined ? undefined : value.chatMessages.map(ChatMessageToJSON),
        hidden_old_chat_messages: value.hiddenOldChatMessages,
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
