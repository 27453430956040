/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShallowContactListFromJSON, ShallowContactListToJSON } from './ShallowContactList';
export function GetContactContactListsResponseDataFromJSON(json) {
    return GetContactContactListsResponseDataFromJSONTyped(json, false);
}
export function GetContactContactListsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contactId: json['contact_id'],
        contactLists: json['contact_lists'].map(ShallowContactListFromJSON)
    };
}
export function GetContactContactListsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact_id: value.contactId,
        contact_lists: value.contactLists.map(ShallowContactListToJSON)
    };
}
