/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ShareByLinkSiteRequestSiteShare,
  ShareByLinkSiteRequestSiteShareFromJSON,
  ShareByLinkSiteRequestSiteShareFromJSONTyped,
  ShareByLinkSiteRequestSiteShareToJSON
} from './ShareByLinkSiteRequestSiteShare'

/**
 *
 * @export
 * @interface ShareByLinkSiteRequest
 */
export interface ShareByLinkSiteRequest {
  /**
   *
   * @type {ShareByLinkSiteRequestSiteShare}
   * @memberof ShareByLinkSiteRequest
   */
  siteShare?: ShareByLinkSiteRequestSiteShare
}

export function ShareByLinkSiteRequestFromJSON(json: any): ShareByLinkSiteRequest {
  return ShareByLinkSiteRequestFromJSONTyped(json, false)
}

export function ShareByLinkSiteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShareByLinkSiteRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteShare: !exists(json, 'site_share') ? undefined : ShareByLinkSiteRequestSiteShareFromJSON(json['site_share'])
  }
}

export function ShareByLinkSiteRequestToJSON(value?: ShareByLinkSiteRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_share: ShareByLinkSiteRequestSiteShareToJSON(value.siteShare)
  }
}
