/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationPolicySettingRequestOrganizationPolicySetting,
  UpdateOrganizationPolicySettingRequestOrganizationPolicySettingFromJSON,
  UpdateOrganizationPolicySettingRequestOrganizationPolicySettingFromJSONTyped,
  UpdateOrganizationPolicySettingRequestOrganizationPolicySettingToJSON
} from './UpdateOrganizationPolicySettingRequestOrganizationPolicySetting'

/**
 *
 * @export
 * @interface UpdateOrganizationPolicySettingRequest
 */
export interface UpdateOrganizationPolicySettingRequest {
  /**
   *
   * @type {UpdateOrganizationPolicySettingRequestOrganizationPolicySetting}
   * @memberof UpdateOrganizationPolicySettingRequest
   */
  organizationPolicySetting?: UpdateOrganizationPolicySettingRequestOrganizationPolicySetting
}

export function UpdateOrganizationPolicySettingRequestFromJSON(json: any): UpdateOrganizationPolicySettingRequest {
  return UpdateOrganizationPolicySettingRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationPolicySettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPolicySettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationPolicySetting: !exists(json, 'organization_policy_setting')
      ? undefined
      : UpdateOrganizationPolicySettingRequestOrganizationPolicySettingFromJSON(json['organization_policy_setting'])
  }
}

export function UpdateOrganizationPolicySettingRequestToJSON(
  value?: UpdateOrganizationPolicySettingRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_policy_setting: UpdateOrganizationPolicySettingRequestOrganizationPolicySettingToJSON(
      value.organizationPolicySetting
    )
  }
}
