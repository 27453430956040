import React, { useCallback } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'

export type Id = number | string

export interface ChipProps {
  id: Id
  label: string
  onClose: (value: string | number) => void
  stopPropagation?: boolean
}

const useStyles = makeStyles(() => {
  return createStyles({
    closeBtn: {
      cursor: 'pointer'
    },
    wordBreak: {
      wordBreak: 'break-all'
    }
  })
})

export const Chip = ({ label, onClose, id, stopPropagation = false }: ChipProps) => {
  const classes = useStyles()

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        e.stopPropagation()
      }
      onClose(id)
    },
    [onClose, stopPropagation, id]
  )

  return (
    <Box px="8px" display="flex" alignItems="center">
      <Box
        borderRadius="4px"
        bgcolor={Colors.background.gray}
        justifyContent="center"
        alignItems="center"
        display="flex"
        padding="3px 8px"
        my="3px"
        className={classes.wordBreak}
      >
        <Typography variant="h5" fontSize="s" fontWeight="normal" lineheight="14px">
          {label}
        </Typography>
        <Box
          ml="8px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={handleClose}
          className={classes.closeBtn}
        >
          <DynamicMuiIcon variant="close" size="16px" />
        </Box>
      </Box>
    </Box>
  )
}
