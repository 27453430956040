import { Box, ButtonBase, createStyles, makeStyles, Modal, Slide, Typography } from '@material-ui/core'
import React, { useEffect, useState, useCallback } from 'react'
import { Colors, Link, SvgIcon } from 'src/components/atoms'
import { DraggableData } from 'src/components/molecules'
import { SelectAttribute, SlideForm } from 'src/components/organisms'

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      color: Colors.accent.keyBlue.default
    }
  })
})

interface ModalSelectAttributeProps {
  open: boolean
  items: DraggableData[]
  onClose: () => void
  onConfirm: (items: DraggableData[]) => void
  kind: 'document' | 'site'
}

export const ModalSelectAttribute = ({ open, items, onClose, onConfirm, kind }: ModalSelectAttributeProps) => {
  const classes = useStyles()

  const [editingItems, setEditingItems] = useState(items)

  const resetItems = useCallback(() => setEditingItems(items), [items])

  useEffect(() => {
    setEditingItems(items)
  }, [items])

  const handleChangeItems = useCallback((items: DraggableData[]) => {
    setEditingItems([...items])
  }, [])

  const handleCloseAndResetItems = useCallback(() => {
    resetItems()
    onClose()
  }, [onClose, resetItems])

  const handleConfirm = useCallback(() => onConfirm(editingItems), [editingItems, onConfirm])

  const handleClickBack = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="764px" height={1}>
          <SlideForm
            headerTitle="顧客情報の取得フォーム"
            onClose={handleCloseAndResetItems}
            onCancel={handleCloseAndResetItems}
            onConfirm={handleConfirm}
            hideSettings
          >
            <Box height={1} px="24px" pt="18px">
              <ButtonBase className={classes.link} onClick={handleClickBack}>
                <SvgIcon variant="leftArrow" color={Colors.accent.keyBlue.default} size="14px" />
                <Box ml="8px" />
                <Typography variant="h5">{`${kind === 'document' ? '資料' : 'サイト'}の共有設定に戻る`}</Typography>
              </ButtonBase>
              <Box pt="10px" pb="16px" borderBottom={`1px solid ${Colors.background.silver}`}>
                <Typography variant="subtitle1">
                  顧客情報を取得するフォームを設定できます
                  <br />
                  チーム設定から、フォームに表示する
                  <Link text="プライバシーポリシーを設定" url="/setting/team/policy" />
                  することができます
                  <br />
                  メールアドレスを任意、もしくは非表示にすることはできません
                </Typography>
              </Box>
              <SelectAttribute
                items={editingItems}
                labelLeft="選択可能な項目"
                labelRight="フォーム上で表示する項目"
                switchOnLabel="必須"
                switchOffLabel="任意"
                onChange={handleChangeItems}
                showSwitch
                disableToggleAccordion
                hasEditPermission
              />
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
