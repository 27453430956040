/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CreateCompanySettingRequest,
  CreateCompanySettingRequestFromJSON,
  CreateCompanySettingRequestToJSON,
  GetCompanySettingsResponse,
  GetCompanySettingsResponseFromJSON,
  GetCompanySettingsResponseToJSON,
  UpdateCompanySettingRequest,
  UpdateCompanySettingRequestFromJSON,
  UpdateCompanySettingRequestToJSON,
  UpdateSortCompanySettingRequest,
  UpdateSortCompanySettingRequestFromJSON,
  UpdateSortCompanySettingRequestToJSON,
  UpdateSortCompanySettingsResponse,
  UpdateSortCompanySettingsResponseFromJSON,
  UpdateSortCompanySettingsResponseToJSON
} from '../models'

export interface UserV1CompanySettingsCompanySettingIdDeleteRequest {
  companySettingId: string
}

export interface UserV1CompanySettingsCompanySettingIdPutRequest {
  companySettingId: string
  updateCompanySettingRequest?: UpdateCompanySettingRequest
}

export interface UserV1CompanySettingsPostRequest {
  createCompanySettingRequest?: CreateCompanySettingRequest
}

export interface UserV1CompanySettingsSortPostRequest {
  updateSortCompanySettingRequest?: UpdateSortCompanySettingRequest
}

/**
 *
 */
export class ApiUserV1CompanySettingApi extends runtime.BaseAPI {
  /**
   * 会社設定項目の削除
   * destroy
   */
  async userV1CompanySettingsCompanySettingIdDeleteRaw(
    requestParameters: UserV1CompanySettingsCompanySettingIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.companySettingId === null || requestParameters.companySettingId === undefined) {
      throw new runtime.RequiredError(
        'companySettingId',
        'Required parameter requestParameters.companySettingId was null or undefined when calling userV1CompanySettingsCompanySettingIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/company_settings/{company_setting_id}`.replace(
          `{${'company_setting_id'}}`,
          encodeURIComponent(String(requestParameters.companySettingId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * 会社設定項目の削除
   * destroy
   */
  async userV1CompanySettingsCompanySettingIdDelete(
    requestParameters: UserV1CompanySettingsCompanySettingIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1CompanySettingsCompanySettingIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト設定項目の編集をする
   * update
   */
  async userV1CompanySettingsCompanySettingIdPutRaw(
    requestParameters: UserV1CompanySettingsCompanySettingIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.companySettingId === null || requestParameters.companySettingId === undefined) {
      throw new runtime.RequiredError(
        'companySettingId',
        'Required parameter requestParameters.companySettingId was null or undefined when calling userV1CompanySettingsCompanySettingIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/company_settings/{company_setting_id}`.replace(
          `{${'company_setting_id'}}`,
          encodeURIComponent(String(requestParameters.companySettingId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateCompanySettingRequestToJSON(requestParameters.updateCompanySettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * コンタクト設定項目の編集をする
   * update
   */
  async userV1CompanySettingsCompanySettingIdPut(
    requestParameters: UserV1CompanySettingsCompanySettingIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1CompanySettingsCompanySettingIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト設定項目の一覧を取得する
   * index
   */
  async userV1CompanySettingsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetCompanySettingsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/company_settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetCompanySettingsResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト設定項目の一覧を取得する
   * index
   */
  async userV1CompanySettingsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetCompanySettingsResponse> {
    const response = await this.userV1CompanySettingsGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * コンタクト設定項目の作成をする
   * create
   */
  async userV1CompanySettingsPostRaw(
    requestParameters: UserV1CompanySettingsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/company_settings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateCompanySettingRequestToJSON(requestParameters.createCompanySettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * コンタクト設定項目の作成をする
   * create
   */
  async userV1CompanySettingsPost(
    requestParameters: UserV1CompanySettingsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1CompanySettingsPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社設定項目のソート修正
   * sort
   */
  async userV1CompanySettingsSortPostRaw(
    requestParameters: UserV1CompanySettingsSortPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateSortCompanySettingsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/company_settings/sort`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSortCompanySettingRequestToJSON(requestParameters.updateSortCompanySettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateSortCompanySettingsResponseFromJSON(jsonValue))
  }

  /**
   * 会社設定項目のソート修正
   * sort
   */
  async userV1CompanySettingsSortPost(
    requestParameters: UserV1CompanySettingsSortPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateSortCompanySettingsResponse> {
    const response = await this.userV1CompanySettingsSortPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
