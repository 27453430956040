/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface MailTemplate
 */
export interface MailTemplate {
  /**
   *
   * @type {string}
   * @memberof MailTemplate
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof MailTemplate
   */
  organizationId: string
  /**
   *
   * @type {string}
   * @memberof MailTemplate
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof MailTemplate
   */
  subject: string
  /**
   *
   * @type {string}
   * @memberof MailTemplate
   */
  body: string
  /**
   *
   * @type {string}
   * @memberof MailTemplate
   */
  createdAt: string
  /**
   *
   * @type {string}
   * @memberof MailTemplate
   */
  updatedAt: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof MailTemplate
   */
  createdBy?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof MailTemplate
   */
  updatedBy?: UserForDisplay
}

export function MailTemplateFromJSON(json: any): MailTemplate {
  return MailTemplateFromJSONTyped(json, false)
}

export function MailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailTemplate {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    organizationId: json['organization_id'],
    name: json['name'],
    subject: json['subject'],
    body: json['body'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
    createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
    updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by'])
  }
}

export function MailTemplateToJSON(value?: MailTemplate | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    organization_id: value.organizationId,
    name: value.name,
    subject: value.subject,
    body: value.body,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    created_by: UserForDisplayToJSON(value.createdBy),
    updated_by: UserForDisplayToJSON(value.updatedBy)
  }
}
