/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { JobFile, JobFileFromJSON, JobFileFromJSONTyped, JobFileToJSON } from './JobFile'
import { JobLog, JobLogFromJSON, JobLogFromJSONTyped, JobLogToJSON } from './JobLog'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   *
   * @type {string}
   * @memberof Job
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  ownerType?: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  ownerId?: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  status?: string
  /**
   *
   * @type {number}
   * @memberof Job
   */
  countsAll?: number | null
  /**
   *
   * @type {number}
   * @memberof Job
   */
  countsProgress?: number | null
  /**
   *
   * @type {number}
   * @memberof Job
   */
  countsCompleted?: number | null
  /**
   *
   * @type {number}
   * @memberof Job
   */
  countsErrord?: number | null
  /**
   *
   * @type {string}
   * @memberof Job
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof Job
   */
  updatedAt?: string
  /**
   *
   * @type {boolean}
   * @memberof Job
   */
  hasLogs?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Job
   */
  hasFiles?: boolean
  /**
   *
   * @type {UserForDisplay}
   * @memberof Job
   */
  user?: UserForDisplay
  /**
   *
   * @type {Array<JobLog>}
   * @memberof Job
   */
  logs?: Array<JobLog>
  /**
   *
   * @type {Array<JobFile>}
   * @memberof Job
   */
  files?: Array<JobFile>
}

export function JobFromJSON(json: any): Job {
  return JobFromJSONTyped(json, false)
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    ownerType: !exists(json, 'owner_type') ? undefined : json['owner_type'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    countsAll: !exists(json, 'counts_all') ? undefined : json['counts_all'],
    countsProgress: !exists(json, 'counts_progress') ? undefined : json['counts_progress'],
    countsCompleted: !exists(json, 'counts_completed') ? undefined : json['counts_completed'],
    countsErrord: !exists(json, 'counts_errord') ? undefined : json['counts_errord'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    hasLogs: !exists(json, 'has_logs') ? undefined : json['has_logs'],
    hasFiles: !exists(json, 'has_files') ? undefined : json['has_files'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    logs: !exists(json, 'logs') ? undefined : (json['logs'] as Array<any>).map(JobLogFromJSON),
    files: !exists(json, 'files') ? undefined : (json['files'] as Array<any>).map(JobFileFromJSON)
  }
}

export function JobToJSON(value?: Job | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    owner_type: value.ownerType,
    owner_id: value.ownerId,
    name: value.name,
    status: value.status,
    counts_all: value.countsAll,
    counts_progress: value.countsProgress,
    counts_completed: value.countsCompleted,
    counts_errord: value.countsErrord,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    has_logs: value.hasLogs,
    has_files: value.hasFiles,
    user: UserForDisplayToJSON(value.user),
    logs: value.logs === undefined ? undefined : (value.logs as Array<any>).map(JobLogToJSON),
    files: value.files === undefined ? undefined : (value.files as Array<any>).map(JobFileToJSON)
  }
}
