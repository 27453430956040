import { Company, EnumSettingSettingType } from '@noco/http-client/lib/noco'
import React from 'react'
import { InformationRow } from 'src/components/atoms'

export interface CompanyInfoListProps {
  company: Company
  disabledCompanyLink?: boolean
}

export const CompanyInfoList = ({ company, disabledCompanyLink = false }: CompanyInfoListProps) => {
  return (
    <>
      {company?.companySettings?.map((item, i) => {
        if (item.settingType === EnumSettingSettingType.CompanyName && item.name === '会社名' && !disabledCompanyLink) {
          return (
            <InformationRow
              key={i}
              variant="url"
              label={item.name!}
              content={company.name!}
              link={`/companies/${company?.id!}`}
              padding="6px 0px"
            />
          )
        } else if (item.settingType === EnumSettingSettingType.Url) {
          return (
            <InformationRow
              key={i}
              variant="url"
              label={item.name!}
              content={item.companySettingValueText?.text!}
              link={item.companySettingValueText?.text!}
              padding="6px 0px"
            />
          )
        } else {
          return (
            <InformationRow
              key={i}
              variant={item.settingType!}
              label={item.name!}
              content={item.companySettingValueText?.text!}
              padding="6px 0px"
            />
          )
        }
        // update information のコンポーネント配置
      })}
    </>
  )
}
