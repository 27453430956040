/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CreateChatMessageAttachmentFileResponse,
  CreateChatMessageAttachmentFileResponseFromJSON,
  CreateChatMessageAttachmentFileResponseToJSON,
  GetChatMessageAttachmentFileResponse,
  GetChatMessageAttachmentFileResponseFromJSON,
  GetChatMessageAttachmentFileResponseToJSON
} from '../models'

export interface UserV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGetRequest {
  chatMessageAttachmentFileId: string
}

export interface UserV1ChatMessagesAttachmentFilesPostRequest {
  file: Blob
}

/**
 *
 */
export class ApiUserV1ChatMessagesAttachmentFileApi extends runtime.BaseAPI {
  /**
   * チャット添付ファイルの取得API
   * show
   */
  async userV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGetRaw(
    requestParameters: UserV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetChatMessageAttachmentFileResponse>> {
    if (
      requestParameters.chatMessageAttachmentFileId === null ||
      requestParameters.chatMessageAttachmentFileId === undefined
    ) {
      throw new runtime.RequiredError(
        'chatMessageAttachmentFileId',
        'Required parameter requestParameters.chatMessageAttachmentFileId was null or undefined when calling userV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/chat_messages/attachment_files/{chat_message_attachment_file_id}`.replace(
          `{${'chat_message_attachment_file_id'}}`,
          encodeURIComponent(String(requestParameters.chatMessageAttachmentFileId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetChatMessageAttachmentFileResponseFromJSON(jsonValue))
  }

  /**
   * チャット添付ファイルの取得API
   * show
   */
  async userV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGet(
    requestParameters: UserV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetChatMessageAttachmentFileResponse> {
    const response = await this.userV1ChatMessagesAttachmentFilesChatMessageAttachmentFileIdGetRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * チャット添付ファイルの作成
   * create
   */
  async userV1ChatMessagesAttachmentFilesPostRaw(
    requestParameters: UserV1ChatMessagesAttachmentFilesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateChatMessageAttachmentFileResponse>> {
    if (requestParameters.file === null || requestParameters.file === undefined) {
      throw new runtime.RequiredError(
        'file',
        'Required parameter requestParameters.file was null or undefined when calling userV1ChatMessagesAttachmentFilesPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/chat_messages/attachment_files`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateChatMessageAttachmentFileResponseFromJSON(jsonValue)
    )
  }

  /**
   * チャット添付ファイルの作成
   * create
   */
  async userV1ChatMessagesAttachmentFilesPost(
    requestParameters: UserV1ChatMessagesAttachmentFilesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateChatMessageAttachmentFileResponse> {
    const response = await this.userV1ChatMessagesAttachmentFilesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
