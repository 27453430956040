/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { ChatMessage, ChatMessageFromJSON, ChatMessageFromJSONTyped, ChatMessageToJSON } from './ChatMessage'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetContactChatMessagesResponseData
 */
export interface GetContactChatMessagesResponseData {
  /**
   *
   * @type {Array<ChatMessage>}
   * @memberof GetContactChatMessagesResponseData
   */
  chatMessages?: Array<ChatMessage>
  /**
   * 機能制限により非表示となるメッセージを検出した場合はtrue
   * @type {boolean}
   * @memberof GetContactChatMessagesResponseData
   */
  hiddenOldChatMessages?: boolean
  /**
   *
   * @type {PageInfo}
   * @memberof GetContactChatMessagesResponseData
   */
  pageInfo?: PageInfo
}

export function GetContactChatMessagesResponseDataFromJSON(json: any): GetContactChatMessagesResponseData {
  return GetContactChatMessagesResponseDataFromJSONTyped(json, false)
}

export function GetContactChatMessagesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactChatMessagesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    chatMessages: !exists(json, 'chat_messages')
      ? undefined
      : (json['chat_messages'] as Array<any>).map(ChatMessageFromJSON),
    hiddenOldChatMessages: !exists(json, 'hidden_old_chat_messages') ? undefined : json['hidden_old_chat_messages'],
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetContactChatMessagesResponseDataToJSON(value?: GetContactChatMessagesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    chat_messages:
      value.chatMessages === undefined ? undefined : (value.chatMessages as Array<any>).map(ChatMessageToJSON),
    hidden_old_chat_messages: value.hiddenOldChatMessages,
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
