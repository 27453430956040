import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Select } from './Select'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { AppBar, Box, createStyles, makeStyles, Modal, Slide, Tab, Typography } from '@material-ui/core'
import { Colors, Toaster } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { useRouter } from 'next/router'
import { useUpdateContactCompany } from 'src/fixtures/modules/contact/hooks'
import { CompanyCreateForm, CompanyCreateFormChangeValueProps } from 'src/components/molecules/CompanyCreateForm'
import {
  CompanySettingValueMap,
  convertFromCompanySettingValueMap,
  initializeCompanySettingValueMap
} from 'src/fixtures/modules/company/utils'
import { useListCompanySettings } from 'src/fixtures/modules/companySetting/hooks'
import { useCreateCompany } from 'src/fixtures/modules/company/hooks'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { useConfirmDiscard } from 'src/fixtures/utils/url'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.background.lightGray,
      boxShadow: 'none'
    },
    tablist: {
      minHeight: 'auto !important'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      padding: '10px 0px',
      border: `1px solid ${Colors.background.gray}`,
      color: Colors.base.placeHolder
    },
    tabPanelSelect: {
      padding: '20px 24px'
    },
    tabPanelCreate: {
      padding: '4px 0px'
    },
    selectedTab: {
      color: Colors.accent.keyPurple.default,
      background: Colors.functional.background.default,
      border: '1px solid transparent'
    },
    tabsIndicator: {
      display: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

type TabValueType = 'select' | 'create'

interface ModalCompanyAddProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
  companyId?: string
}

export const ModalCompanyAdd = ({ open, onClose, onCancel, onConfirm, companyId }: ModalCompanyAddProps) => {
  const classes = useStyles()
  const [value, setValue] = useState<TabValueType>('select')
  const [selectedCompanyId, setSelectedCompanyId] = useState('')
  const router = useRouter()
  const contactId = useMemo(() => {
    return router.query.id as string
  }, [router])
  const { data: me } = useGetMe()
  const [userId, setUserId] = useState<string | undefined>(me?.user?.id!)
  const [settingValues, setSettingValues] = useState<CompanySettingValueMap>({})
  const { data: responseSetting } = useListCompanySettings()
  const activeSettings = responseSetting?.activeCompanySettings
  const { handleCreateCompany } = useCreateCompany({ contactId })
  const { handleUpdateContactCompany } = useUpdateContactCompany(contactId)
  const [showConfirm, setShowConfirm] = useState(false)
  useConfirmDiscard(showConfirm)
  useEffect(() => {
    if (me?.user?.id) {
      setUserId(me?.user?.id)
    }
  }, [me?.user?.id])

  // 初期化
  useEffect(() => {
    const initialSettingValueMap = initializeCompanySettingValueMap(activeSettings || [])
    setSettingValues(initialSettingValueMap)
  }, [activeSettings])
  // タブ切替
  const handleChangeTab = useCallback(
    (event: ChangeEvent<{}>, newValue: TabValueType) => {
      if (showConfirm && window.confirm('変更がまだ保存されていません。画面を離れますか？')) {
        setValue(newValue)
        return
      }
      if (!showConfirm) {
        setValue(newValue)
      }
    },
    [showConfirm]
  )
  // 登録データから
  const handleSelectChange = useCallback(value => {
    setSelectedCompanyId(value)
  }, [])
  // 新規作成 担当者
  const handleChangeUser = useCallback(e => {
    setUserId(e.target.value as string)
    setShowConfirm(true)
  }, [])
  // 新規作成 設定項目
  const handleUpdateValue = useCallback(
    ({ settingId, fieldId, value }: CompanyCreateFormChangeValueProps) => {
      const newValueMap = { ...settingValues }
      newValueMap[settingId][fieldId] = value
      setSettingValues(newValueMap)
      setShowConfirm(true)
    },
    [settingValues]
  )
  // 画面をクローズする前に confirm する対応
  const handleClose = useCallback(() => {
    if (showConfirm && window.confirm('変更がまだ保存されていません。画面を離れますか？')) {
      onClose()
      return
    }

    if (!showConfirm) {
      onClose()
    }

    setShowConfirm(false)
  }, [showConfirm, onClose])

  const handleSubmit = useCallback(async () => {
    if (value === 'select') {
      if (!selectedCompanyId) {
        Toaster.error('会社を選択してください')
        return
      }
      handleUpdateContactCompany({ companyId: selectedCompanyId })
    } else if (value === 'create') {
      if (!userId) return Toaster.error('担当者を選択してください')

      const companySettingFieldValues = convertFromCompanySettingValueMap(settingValues)
      await handleCreateCompany({
        company: {
          userId,
          companySettingFieldValues
        },
        contactId
      })
    }
    onConfirm()
  }, [
    handleUpdateContactCompany,
    onConfirm,
    selectedCompanyId,
    value,
    settingValues,
    userId,
    handleCreateCompany,
    contactId
  ])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="会社情報を追加"
            hideSettings
            onClose={handleClose}
            onCancel={onCancel}
            onConfirm={handleSubmit}
            onClickSettings={() => router.push('/setting/team/company')}
          >
            <Box bgcolor={Colors.functional.background.default} height={1}>
              <TabContext value={value}>
                <AppBar position="relative" className={classes.root}>
                  <TabList
                    onChange={handleChangeTab}
                    aria-label="company add tablist"
                    classes={{ root: classes.tablist, indicator: classes.tabsIndicator }}
                    variant="fullWidth"
                  >
                    <Tab
                      value="select"
                      label={<Typography variant="h5">登録データから追加</Typography>}
                      classes={{ root: classes.tab, selected: classes.selectedTab }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      value="create"
                      label={<Typography variant="h5">新規登録</Typography>}
                      classes={{ root: classes.tab, selected: classes.selectedTab }}
                      {...a11yProps(1)}
                    />
                  </TabList>
                </AppBar>
                <Box>
                  <TabPanel value="select" className={classes.tabPanelSelect}>
                    <Select companyId={companyId} onChange={handleSelectChange} />
                  </TabPanel>
                  <TabPanel value="create" className={classes.tabPanelCreate}>
                    <CompanyCreateForm
                      userId={userId!}
                      settingValues={settingValues}
                      onChangeUser={handleChangeUser}
                      onChangeValues={handleUpdateValue}
                    />
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
