import { Box } from '@material-ui/core'
import {
  Coupon,
  OrganizationPaymentContractBasicInfo,
  OrganizationPaymentContractPaidInfo,
  OrganizationPlanDetail
} from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useState } from 'react'
import { ButtonIcon, Colors, SvgIcon, Typography } from 'src/components/atoms'
import { SelectPlanCard } from 'src/components/molecules'
import { useGetCouponCode, useGetOrganizationPaymentBillingInfo } from 'src/fixtures/modules/organizationPayment/hooks'
import {
  PaymentMethodCard,
  PaymentBillingForm,
  PaymentBillingPaidFormValue,
  PaymentMethodProps,
  PaymentCouponCard
} from '../..'

export interface ContractSecondStepProps {
  plan: OrganizationPlanDetail
  backButtonDisabled?: boolean
  onSubmit: (
    contractBasicInfo: OrganizationPaymentContractBasicInfo,
    contractPaidInfo: Pick<
      OrganizationPaymentContractPaidInfo,
      'stripePaymentMethodId' | 'card' | 'billingEmail' | 'howDidYouKnow'
    >,
    paymentMethod: PaymentMethodProps,
    coupon?: Coupon
  ) => void
  onBack: () => void
  isResumed?: boolean
}

export const ContractSecondStep = ({
  plan,
  backButtonDisabled = false,
  onSubmit,
  onBack,
  isResumed = false
}: ContractSecondStepProps) => {
  const { data } = useGetOrganizationPaymentBillingInfo()
  const contractBasicInfo = data?.contractBasicInfo
  const contractPaidInfo = data?.contractPaidInfo
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodProps>()
  const [coupon, setCoupon] = useState<Coupon | undefined>()
  const { handleGetCouponCode } = useGetCouponCode()

  // 初期化
  useEffect(() => {
    if (!contractPaidInfo) return

    setPaymentMethod({
      stripePaymentMethodId: contractPaidInfo.stripePaymentMethodId,
      creditCard: contractPaidInfo.card
    })
  }, [contractPaidInfo])

  const onChange = useCallback((paymentMethod: PaymentMethodProps) => {
    setPaymentMethod(paymentMethod)
  }, [])
  const onSubmitContractForm = useCallback(
    (contractBasicInfo: OrganizationPaymentContractBasicInfo, contractPaidInfo?: PaymentBillingPaidFormValue) => {
      if (!contractPaidInfo) return
      if (!paymentMethod || !paymentMethod?.stripePaymentMethodId || !paymentMethod?.creditCard?.last4) return

      onSubmit(
        contractBasicInfo,
        {
          ...contractPaidInfo,
          stripePaymentMethodId: paymentMethod.stripePaymentMethodId,
          card: { last4: paymentMethod.creditCard.last4 }
        },
        paymentMethod,
        coupon
      )
    },
    [paymentMethod, onSubmit, coupon]
  )
  const handleSubmitCoupon = useCallback(
    async (couponCode: string) => {
      const data = await handleGetCouponCode(couponCode, plan.id)
      const coupon = data?.coupon
      if (coupon) setCoupon(coupon)
    },
    [handleGetCouponCode, plan.id]
  )
  const handleClearCoupon = useCallback(() => {
    setCoupon(undefined)
  }, [])

  if (!contractBasicInfo) return <></>

  return (
    <Box>
      <Box mb="16px">
        <SelectPlanCard planName={plan.name} unitAmount={plan.unitAmount} />
      </Box>

      <Box mb="16px">
        <PaymentMethodCard paymentMethod={paymentMethod} onChange={onChange} />
      </Box>

      <Box mb="16px">
        <PaymentCouponCard onSubmit={handleSubmitCoupon} onClear={handleClearCoupon} coupon={coupon} />
      </Box>

      <Box mb="16px">
        <PaymentBillingForm
          isPaid={true}
          contractBasicInfo={contractBasicInfo}
          contractPaidInfo={contractPaidInfo}
          disabledButton={!paymentMethod?.stripePaymentMethodId}
          onSubmit={onSubmitContractForm}
          submitTitle="入力情報の確認へ進む"
          isResumed={isResumed}
        />
      </Box>

      {!backButtonDisabled && (
        <Box display="flex" justifyContent="center" mb="24px" color={Colors.accent.keyBlue.default}>
          <ButtonIcon
            tabIndex={-1}
            iconBefore={<SvgIcon variant="leftArrow" size="16px" color="inherit" />}
            onClick={() => onBack()}
          >
            <Typography fontSize="s" fontWeight="bold" lineheight="1" color="inherit">
              戻る
            </Typography>
          </ButtonIcon>
        </Box>
      )}
    </Box>
  )
}
