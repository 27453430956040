/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationAvatarFromJSON, OrganizationAvatarToJSON } from './OrganizationAvatar';
import { OrganizationChatSettingFromJSON, OrganizationChatSettingToJSON } from './OrganizationChatSetting';
import { OrganizationPolicySettingFromJSON, OrganizationPolicySettingToJSON } from './OrganizationPolicySetting';
export function EuOrganizationFromJSON(json) {
    return EuOrganizationFromJSONTyped(json, false);
}
export function EuOrganizationFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        hideAd: !exists(json, 'hide_ad') ? undefined : json['hide_ad'],
        avatar: !exists(json, 'avatar') ? undefined : OrganizationAvatarFromJSON(json['avatar']),
        policySettings: !exists(json, 'policy_settings')
            ? undefined
            : json['policy_settings'].map(OrganizationPolicySettingFromJSON),
        chatSetting: !exists(json, 'chat_setting') ? undefined : OrganizationChatSettingFromJSON(json['chat_setting'])
    };
}
export function EuOrganizationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        hide_ad: value.hideAd,
        avatar: OrganizationAvatarToJSON(value.avatar),
        policy_settings: value.policySettings === undefined
            ? undefined
            : value.policySettings.map(OrganizationPolicySettingToJSON),
        chat_setting: OrganizationChatSettingToJSON(value.chatSetting)
    };
}
