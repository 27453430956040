/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationSignupMailRequest
 */
export interface OrganizationSignupMailRequest {
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupMailRequest
   */
  companyName?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupMailRequest
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupMailRequest
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupMailRequest
   */
  encryptedEmail?: string
  /**
   *
   * @type {string}
   * @memberof OrganizationSignupMailRequest
   */
  tel?: string
}

export function OrganizationSignupMailRequestFromJSON(json: any): OrganizationSignupMailRequest {
  return OrganizationSignupMailRequestFromJSONTyped(json, false)
}

export function OrganizationSignupMailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationSignupMailRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyName: !exists(json, 'company_name') ? undefined : json['company_name'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
    tel: !exists(json, 'tel') ? undefined : json['tel']
  }
}

export function OrganizationSignupMailRequestToJSON(value?: OrganizationSignupMailRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_name: value.companyName,
    first_name: value.firstName,
    last_name: value.lastName,
    encrypted_email: value.encryptedEmail,
    tel: value.tel
  }
}
