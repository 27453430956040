/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface BillingInvoiceItem
 */
export interface BillingInvoiceItem {
  /**
   *
   * @type {string}
   * @memberof BillingInvoiceItem
   */
  productName: string
  /**
   *
   * @type {Date}
   * @memberof BillingInvoiceItem
   */
  periodStart: Date | null
  /**
   *
   * @type {Date}
   * @memberof BillingInvoiceItem
   */
  periodEnd: Date | null
  /**
   * 単位
   * @type {string}
   * @memberof BillingInvoiceItem
   */
  unitLabel: string | null
  /**
   *
   * @type {number}
   * @memberof BillingInvoiceItem
   */
  amount: number
  /**
   *
   * @type {number}
   * @memberof BillingInvoiceItem
   */
  quantity: number
  /**
   * 毎月発生するかどうか
   * @type {boolean}
   * @memberof BillingInvoiceItem
   */
  isMonthlyRecurring: boolean
}

export function BillingInvoiceItemFromJSON(json: any): BillingInvoiceItem {
  return BillingInvoiceItemFromJSONTyped(json, false)
}

export function BillingInvoiceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingInvoiceItem {
  if (json === undefined || json === null) {
    return json
  }
  return {
    productName: json['product_name'],
    periodStart: json['period_start'] === null ? null : new Date(json['period_start']),
    periodEnd: json['period_end'] === null ? null : new Date(json['period_end']),
    unitLabel: json['unit_label'],
    amount: json['amount'],
    quantity: json['quantity'],
    isMonthlyRecurring: json['is_monthly_recurring']
  }
}

export function BillingInvoiceItemToJSON(value?: BillingInvoiceItem | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    product_name: value.productName,
    period_start: value.periodStart === null ? null : value.periodStart.toISOString(),
    period_end: value.periodEnd === null ? null : value.periodEnd.toISOString(),
    unit_label: value.unitLabel,
    amount: value.amount,
    quantity: value.quantity,
    is_monthly_recurring: value.isMonthlyRecurring
  }
}
