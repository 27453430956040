/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentContractBasicInfo,
  OrganizationPaymentContractBasicInfoFromJSON,
  OrganizationPaymentContractBasicInfoFromJSONTyped,
  OrganizationPaymentContractBasicInfoToJSON
} from './OrganizationPaymentContractBasicInfo'
import {
  OrganizationPaymentContractPaidInfo,
  OrganizationPaymentContractPaidInfoFromJSON,
  OrganizationPaymentContractPaidInfoFromJSONTyped,
  OrganizationPaymentContractPaidInfoToJSON
} from './OrganizationPaymentContractPaidInfo'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentApplyPlanPaidRequest
 */
export interface UpdateOrganizationPaymentApplyPlanPaidRequest {
  /**
   *
   * @type {OrganizationPaymentContractBasicInfo}
   * @memberof UpdateOrganizationPaymentApplyPlanPaidRequest
   */
  contractBasicInfo: OrganizationPaymentContractBasicInfo
  /**
   *
   * @type {OrganizationPaymentContractPaidInfo}
   * @memberof UpdateOrganizationPaymentApplyPlanPaidRequest
   */
  contractPaidInfo: OrganizationPaymentContractPaidInfo
}

export function UpdateOrganizationPaymentApplyPlanPaidRequestFromJSON(
  json: any
): UpdateOrganizationPaymentApplyPlanPaidRequest {
  return UpdateOrganizationPaymentApplyPlanPaidRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentApplyPlanPaidRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentApplyPlanPaidRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contractBasicInfo: OrganizationPaymentContractBasicInfoFromJSON(json['contract_basic_info']),
    contractPaidInfo: OrganizationPaymentContractPaidInfoFromJSON(json['contract_paid_info'])
  }
}

export function UpdateOrganizationPaymentApplyPlanPaidRequestToJSON(
  value?: UpdateOrganizationPaymentApplyPlanPaidRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contract_basic_info: OrganizationPaymentContractBasicInfoToJSON(value.contractBasicInfo),
    contract_paid_info: OrganizationPaymentContractPaidInfoToJSON(value.contractPaidInfo)
  }
}
