/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumUserOperationDisplayObjectItemResourceType = {
  Document: 'Document',
  Site: 'Site'
} as const
export type EnumUserOperationDisplayObjectItemResourceType =
  typeof EnumUserOperationDisplayObjectItemResourceType[keyof typeof EnumUserOperationDisplayObjectItemResourceType]

export function EnumUserOperationDisplayObjectItemResourceTypeFromJSON(
  json: any
): EnumUserOperationDisplayObjectItemResourceType {
  return EnumUserOperationDisplayObjectItemResourceTypeFromJSONTyped(json, false)
}

export function EnumUserOperationDisplayObjectItemResourceTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumUserOperationDisplayObjectItemResourceType {
  return json as EnumUserOperationDisplayObjectItemResourceType
}

export function EnumUserOperationDisplayObjectItemResourceTypeToJSON(
  value?: EnumUserOperationDisplayObjectItemResourceType | null
): any {
  return value as any
}
