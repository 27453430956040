/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * wait,proccesing は処理中。 completed は正常終了。 failed は異常終了。
 * @export
 */
export const EnumSlideProcessingStatus = {
  Wait: 'wait',
  Proccesing: 'proccesing',
  Completed: 'completed',
  Failed: 'failed'
} as const
export type EnumSlideProcessingStatus = typeof EnumSlideProcessingStatus[keyof typeof EnumSlideProcessingStatus]

export function EnumSlideProcessingStatusFromJSON(json: any): EnumSlideProcessingStatus {
  return EnumSlideProcessingStatusFromJSONTyped(json, false)
}

export function EnumSlideProcessingStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumSlideProcessingStatus {
  return json as EnumSlideProcessingStatus
}

export function EnumSlideProcessingStatusToJSON(value?: EnumSlideProcessingStatus | null): any {
  return value as any
}
