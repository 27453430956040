import { useCallback, useState, useEffect, useMemo } from 'react'
import { Document, EnumVisitorMaterialableTrackEvent } from '@noco/http-client/lib/noco/models'
import { useCreateTrack, usePingViewTrack } from 'src/fixtures/modules/ContactMaterialableTrack/hooks'
import { Organization } from '@noco/http-client/lib/noco'
import { useGetBrowserActiveStatus, useSetBrowserActiveEvent } from 'src/fixtures/utils/browserEvent'
import { useReviewRequest } from 'src/fixtures/modules/contactDocument/hooks'

export type DocumentSlideDetailProps = ReturnType<typeof useDocumentSlideDetail>

export const useDocumentSlideDetail = ({
  document,
  isPreview,
  organization,
  siteId,
  canRequestToExtendExpiredAt,
  isActivated
}: {
  document: Document
  isPreview: boolean
  organization: Organization
  siteId?: string
  canRequestToExtendExpiredAt?: boolean
  isActivated: boolean // 資料が認証状態かどうか。メール認証後、差分フォームを送信したところまでで送信されている
}) => {
  const [shownPage, setShownPage] = useState<null | number>(1)
  const [openPageSelectModal, setOpenPageSelectModal] = useState(false)
  const { handlePingViewTrack } = usePingViewTrack()
  const { handleCreateTrack } = useCreateTrack()
  const [openCta, setOpenCta] = useState(true)
  const { status } = useGetBrowserActiveStatus()
  const { handleReviewRequest, isLoading: isReviewRequestLoading } = useReviewRequest()
  useSetBrowserActiveEvent({
    page: shownPage,
    isActivated: isActivated,
    onActive: () => {
      if (!isActivated) return
      if (shownPage && !isPreview && !isExtraPage) {
        handleCreateTrack({
          numberOfPage: shownPage,
          event: EnumVisitorMaterialableTrackEvent.View,
          documentId: document.id,
          siteId: siteId
        })
      }
    }
  })

  const displayCta = useMemo(() => {
    return (
      document.documentCta?.isView && shownPage === document.documentCta.page && openCta && !canRequestToExtendExpiredAt
    )
  }, [document.documentCta, shownPage, openCta, canRequestToExtendExpiredAt])

  const currentSlide = useMemo(
    () => document?.currentSlide?.slideImages?.find(item => item.numberOfPage === shownPage),
    [document?.currentSlide?.slideImages, shownPage]
  )

  const isDisplayedAdSlide = !currentSlide

  const isExtraPage = useMemo(
    () => isDisplayedAdSlide || canRequestToExtendExpiredAt,
    [isDisplayedAdSlide, canRequestToExtendExpiredAt]
  )

  const onOpenPageSelectModal = useCallback(() => {
    setOpenPageSelectModal(true)
  }, [setOpenPageSelectModal])

  const onClosePageSelectModal = useCallback(() => {
    setOpenPageSelectModal(false)
  }, [setOpenPageSelectModal])

  const totalPage = useMemo(() => {
    const slideNum = document.currentSlide?.slideImages?.length ?? 0
    if (organization.hideAd) {
      return slideNum
    } else {
      return slideNum + 1
    }
  }, [document, organization])

  const onPrevPage = useCallback(
    () => setShownPage((shownPage ?? 1) > 1 ? (shownPage ?? 1) - 1 : 1),
    [setShownPage, shownPage]
  )
  const onNextPage = useCallback(
    () => setShownPage((shownPage ?? 1) < totalPage ? (shownPage ?? 1) + 1 : shownPage),
    [setShownPage, shownPage, totalPage]
  )

  const onReviewRequest = useCallback(() => {
    handleReviewRequest(document.id)
  }, [document.id, handleReviewRequest])

  // NOTE: CTA track event 生成
  const handleCreateTrackCTA = useCallback(() => {
    if (!isPreview) {
      handleCreateTrack({
        event: EnumVisitorMaterialableTrackEvent.Cta,
        documentId: document.id,
        numberOfPage: shownPage!,
        siteId
      })
    }
  }, [document.id, handleCreateTrack, shownPage, siteId, isPreview])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (status === 'active' && !isExtraPage) handlePingViewTrack()
    }, 5000)
    return () => clearInterval(intervalId)
  }, [document.id, handlePingViewTrack, isExtraPage, status])

  useEffect(() => {}, [shownPage, totalPage])

  return {
    totalPage,
    currentSlide,
    slideImages: document?.currentSlide?.slideImages ?? [],
    openPageSelectModal,
    isDisplayedAdSlide,
    isReviewRequestLoading,
    displayCta,
    shownPage,
    setShownPage,
    onOpenPageSelectModal,
    onClosePageSelectModal,
    setOpenCta,
    onPrevPage,
    onNextPage,
    onReviewRequest,
    handleCreateTrackCTA,
    handleCreateTrack
  }
}
