import React from 'react'
import Link, { LinkProps } from 'next/link'

export type LinkIconProps = LinkProps & {
  className?: string
  isBlank?: boolean
}

export const LinkIcon: React.FC<LinkIconProps> = ({ children, className, isBlank = false, ...rest }) => {
  return (
    <Link {...rest}>
      {isBlank ? (
        <a className={className} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a className={className}>{children}</a>
      )}
    </Link>
  )
}
