import { Box, IconButton, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { useRouter } from 'next/router'
import { Colors, Pallete, SvgIcon, Typography } from 'src/components/atoms'

export interface PreviousPageProps {
  title: string
  path: string
}

export interface PaymentHeaderProps {
  headerTitleText: string
  previousPage?: PreviousPageProps
}

const useStyles = makeStyles(() => {
  return createStyles({
    routerBackIcon: {
      width: '28px',
      height: '28px',
      padding: 0,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '6px'
    },
    iconText: {
      color: Colors.base.middleGray,
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '14px'
    }
  })
})

export const PaymentHeader = ({
  headerTitleText,
  previousPage = { title: 'プラン表へ戻る', path: '/setting/team/pricing' }
}: PaymentHeaderProps) => {
  const classes = useStyles()
  const router = useRouter()

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60px',
        padding: '16px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #DADCE0',
        bgcolor: Pallete.functional.background.default
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <IconButton onClick={() => router.push(previousPage.path)} classes={{ root: classes.routerBackIcon }}>
          <SvgIcon variant="leftArrow" size="13px" color={Colors.base.middleGray} />
        </IconButton>
        <Typography className={classes.iconText}>{previousPage.title}</Typography>
      </Box>
      <Typography fontSize="l" fontWeight="bold" lineheight="150%">
        {headerTitleText}
      </Typography>
    </Box>
  )
}
