import { createStyles, makeStyles, Typography, Box, Modal, ButtonBase, Slide } from '@material-ui/core'
import { Colors, Pallete } from '../../atoms/Colors'
import { Alert, Button, DynamicMuiIcon } from '../../atoms'

interface ModalDocumentDownloadButtonConfirmProps {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alert: {
      '& .MuiTypography-root': {
        fontSize: '14px'
      }
    }
  })
)

export const ModalDocumentDownloadButtonConfirm = ({
  open,
  onSubmit,
  onClose
}: ModalDocumentDownloadButtonConfirmProps) => {
  const classes = useStyles()

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="580px"
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.silver}`}
        >
          <Box
            px="24px"
            py="16px"
            borderBottom={`1px solid ${Colors.background.silver}`}
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h3">資料ダウンロードボタンを表示する前に確認ください</Typography>
            <ButtonBase onClick={onClose}>
              <DynamicMuiIcon variant="close" color="action" size="28px" />
            </ButtonBase>
          </Box>
          <Box pt="24px" px="24px" pb="48px">
            <Typography variant="body2">
              資料ダウンロードボタンを表示することで、閲覧者が資料のPDFファイルをダウンロードできるようになります
            </Typography>
            <Box mt="12px" className={classes.alert}>
              <Alert
                status="warning"
                text="ダウンロードされたPDFファイルに対する閲覧データ（閲覧時間やPVなど）のトラッキングおよび計測はできません"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            py="12px"
            bgcolor={Colors.background.lightGray}
            borderRadius="0 0 10px 10px"
            boxShadow={`inset 0px 1px 0px ${Colors.background.silver}`}
          >
            <Box display="flex" mr="24px">
              <Button kind="neutral" variant="outlined" title="キャンセル" onClick={onClose} />
              <Box mr="12px" />
              <Button kind="primary" title="OK" onClick={onSubmit} />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
