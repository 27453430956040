/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumVisitorMaterialableTrackEventFromJSON, EnumVisitorMaterialableTrackEventToJSON } from './EnumVisitorMaterialableTrackEvent';
export function CreateMaterialableTrackRequestFromJSON(json) {
    return CreateMaterialableTrackRequestFromJSONTyped(json, false);
}
export function CreateMaterialableTrackRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
        siteId: !exists(json, 'site_id') ? undefined : json['site_id'],
        event: EnumVisitorMaterialableTrackEventFromJSON(json['event']),
        numberOfPage: !exists(json, 'number_of_page') ? undefined : json['number_of_page']
    };
}
export function CreateMaterialableTrackRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document_id: value.documentId,
        site_id: value.siteId,
        event: EnumVisitorMaterialableTrackEventToJSON(value.event),
        number_of_page: value.numberOfPage
    };
}
