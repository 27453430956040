/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetSiteResponseData,
  GetSiteResponseDataFromJSON,
  GetSiteResponseDataFromJSONTyped,
  GetSiteResponseDataToJSON
} from './GetSiteResponseData'

/**
 *
 * @export
 * @interface UpdateSiteResponse
 */
export interface UpdateSiteResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateSiteResponse
   */
  status?: number
  /**
   *
   * @type {GetSiteResponseData}
   * @memberof UpdateSiteResponse
   */
  data?: GetSiteResponseData
}

export function UpdateSiteResponseFromJSON(json: any): UpdateSiteResponse {
  return UpdateSiteResponseFromJSONTyped(json, false)
}

export function UpdateSiteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSiteResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetSiteResponseDataFromJSON(json['data'])
  }
}

export function UpdateSiteResponseToJSON(value?: UpdateSiteResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetSiteResponseDataToJSON(value.data)
  }
}
