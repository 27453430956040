import React, { ReactNode, useEffect } from 'react'
import Head from 'next/head'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { SWRConfig } from 'swr'
import { ToastProvider } from 'react-toast-notifications'
import { ToastContainer } from 'react-toastify'
import theme from 'src/styles/theme'
import ErrorBoundary from 'src/components/commons/ErrorBoundary'
import { Toast } from 'src/components/atoms'
import { GoogleTagManagerForUser } from 'src/utils/gtm'
import { ReactChannelIO } from 'react-channel-plugin'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { resetCookies } from 'src/fixtures/utils'

export interface GlobalLayoutUserProps {
  children: ReactNode
}

export const GlobalLayoutUser = ({ children }: GlobalLayoutUserProps) => {
  const { data: me } = useGetMe()
  // MEMO: cookie の secure 属性を再度設定している
  // js で 属性の値を参照することはできないため一律リセットになる
  // リリース三ヶ月でこちらの対応は削除
  useEffect(() => {
    resetCookies()
  }, [])
  return (
    <>
      <Head>
        <title>ノコセル</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0" />
        <meta name="theme-color" />
        <link rel="icon" href="/images/favicon/favicon.ico" sizes="any" />
        <link rel="icon" href="/images/favicon/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/images/favicon/icon-size_64.png" sizes="76x76" />
        <link rel="apple-touch-icon" href="/images/favicon/icon-size_128.png" sizes="120x120" />
        <link rel="apple-touch-icon" href="/images/favicon/icon-size_256.png" sizes="152x152" />
      </Head>

      {/* for GTM */}
      <GoogleTagManagerForUser />
      <ErrorBoundary>
        <ReactChannelIO
          pluginKey={process.env.CHANNEL_TALK_PLUGIN_KEY || ''}
          language="ja"
          memberId={me?.user?.id ? `U-${me?.user.id}` : undefined}
          hideChannelButtonOnBoot={true}
          key="channel-talk"
          autoBoot={true}
          hidePopup={true}
        >
          <SWRConfig
            value={{
              errorRetryCount: 0,
              revalidateIfStale: false
            }}
          >
            <MuiThemeProvider theme={theme}>
              <ToastProvider components={{ Toast: Toast }} autoDismissTimeout={1500} autoDismiss>
                <CssBaseline />
                <StyledThemeProvider theme={theme}>
                  {children}
                  <ToastContainer position="bottom-center" hideProgressBar autoClose={2000} />
                </StyledThemeProvider>
              </ToastProvider>
            </MuiThemeProvider>
          </SWRConfig>
        </ReactChannelIO>
      </ErrorBoundary>
    </>
  )
}
