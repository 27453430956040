/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateDocumentOpportunityRequestDocumentOpportunity,
  UpdateDocumentOpportunityRequestDocumentOpportunityFromJSON,
  UpdateDocumentOpportunityRequestDocumentOpportunityFromJSONTyped,
  UpdateDocumentOpportunityRequestDocumentOpportunityToJSON
} from './UpdateDocumentOpportunityRequestDocumentOpportunity'

/**
 *
 * @export
 * @interface UpdateDocumentOpportunityRequest
 */
export interface UpdateDocumentOpportunityRequest {
  /**
   *
   * @type {UpdateDocumentOpportunityRequestDocumentOpportunity}
   * @memberof UpdateDocumentOpportunityRequest
   */
  documentOpportunity?: UpdateDocumentOpportunityRequestDocumentOpportunity
}

export function UpdateDocumentOpportunityRequestFromJSON(json: any): UpdateDocumentOpportunityRequest {
  return UpdateDocumentOpportunityRequestFromJSONTyped(json, false)
}

export function UpdateDocumentOpportunityRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentOpportunityRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentOpportunity: !exists(json, 'document_opportunity')
      ? undefined
      : UpdateDocumentOpportunityRequestDocumentOpportunityFromJSON(json['document_opportunity'])
  }
}

export function UpdateDocumentOpportunityRequestToJSON(value?: UpdateDocumentOpportunityRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_opportunity: UpdateDocumentOpportunityRequestDocumentOpportunityToJSON(value.documentOpportunity)
  }
}
