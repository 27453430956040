export enum SubscribedMessagesType {
  Welcome = 'welcome',
  Ping = 'ping',
  Confirm_Subscription = 'confirm_subscription',
  Reject_Subscription = 'reject_subscription',
  Disconnect = 'disconnect'
}

export interface ChatChannelEvent {
  identifier: string
  type: SubscribedMessagesType
  message?: string
}

export interface Message {
  status: number
  timestamp: Date
  notice: string
  errors: string[]
  request: Request
  data: Data
  action_cable_consumer: ActionCableConsumer
}

interface Chat {
  message: string
}

interface Request {
  action: string
  chat: Chat
}

interface ChatMessage {
  message: string
  sended_by_id: string
  sended_by: {
    id: string
    display_name: string
    avatar: {
      url: string
    }
  }
}

interface Data {
  chat_message: ChatMessage
}

interface Avatar {
  url: string
}

interface ActionCableConsumer {
  id: number
  consumer_type: string
  display_name: string
  avatar: Avatar
}
