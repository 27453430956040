/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateSiteRequest
 */
export interface UpdateSiteRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSiteRequest
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof UpdateSiteRequest
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof UpdateSiteRequest
   */
  memo?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteRequest
   */
  isConfidential?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateSiteRequest
   */
  confidentialLabelName?: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteRequest
   */
  isHeaderCustom?: boolean
  /**
   *
   * @type {boolean}
   * @memberof UpdateSiteRequest
   */
  isFooterCustom?: boolean
  /**
   *
   * @type {string}
   * @memberof UpdateSiteRequest
   */
  footerText?: string
}

export function UpdateSiteRequestFromJSON(json: any): UpdateSiteRequest {
  return UpdateSiteRequestFromJSONTyped(json, false)
}

export function UpdateSiteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSiteRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    isConfidential: !exists(json, 'is_confidential') ? undefined : json['is_confidential'],
    confidentialLabelName: !exists(json, 'confidential_label_name') ? undefined : json['confidential_label_name'],
    isHeaderCustom: !exists(json, 'is_header_custom') ? undefined : json['is_header_custom'],
    isFooterCustom: !exists(json, 'is_footer_custom') ? undefined : json['is_footer_custom'],
    footerText: !exists(json, 'footer_text') ? undefined : json['footer_text']
  }
}

export function UpdateSiteRequestToJSON(value?: UpdateSiteRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    description: value.description,
    memo: value.memo,
    is_confidential: value.isConfidential,
    confidential_label_name: value.confidentialLabelName,
    is_header_custom: value.isHeaderCustom,
    is_footer_custom: value.isFooterCustom,
    footer_text: value.footerText
  }
}
