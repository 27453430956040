import { Box } from '@material-ui/core'
import React from 'react'
import { Typography } from 'src/components/atoms/Typography'
import Image from 'next/image'

export interface ErrorBodyProps {
  title: string
  descriptionJa: string
  descriptionEn: string
  imgUrl: string
  isSP?: boolean
}

export const ErrorBody = ({ title, descriptionJa, descriptionEn, imgUrl, isSP = false }: ErrorBodyProps) => {
  return (
    <Box position="relative" width={1} height={1}>
      <Image src="/images/nocosell_logo.svg" alt="logo" width={104} height={30} />
      <Box mt={isSP ? '20px' : '60px'} px={isSP ? '0' : '100px'}>
        <Typography variant="h1" fontSize={isSP ? 'xxl' : 'xxxl'} fontWeight="bold">
          {title}
        </Typography>
        <Box height="24px" />
        <Typography fontSize={isSP ? 's' : 'm'} lineheight="27.2px" letterSpacing="tight">
          {descriptionJa}
        </Typography>
        <Box height="24px" />
        <Typography fontSize={isSP ? 's' : 'm'} lineheight="27.2px" letterSpacing="tight" fontWeight="bold">
          {descriptionEn}
        </Typography>
      </Box>
      <Box
        position={isSP ? 'relative' : 'absolute'}
        zIndex={-1}
        right={0}
        bottom={isSP ? '0' : '30px'}
        width={isSP ? '100%' : '456px'}
        height="314px"
        mt={isSP ? '30px' : '0'}
      >
        <Image alt="errorImage" src={imgUrl} width={456} height={314} objectFit="contain" />
      </Box>
    </Box>
  )
}
