import React, { useCallback, useRef, ChangeEvent } from 'react'
import { Box } from '@material-ui/core'
import { DynamicMuiIcon, Button } from 'src/components/atoms'

export interface SelectFileProps {
  onChangeFile: (value: File) => void
}

export const SelectFile = ({ onChangeFile }: SelectFileProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFileClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (!e.target.files?.[0]) return
      onChangeFile(e.target.files[0])
    },
    [onChangeFile]
  )

  return (
    <Box ml="32px">
      <Button
        kind="primary"
        variant="outlined"
        title="ファイルを選択"
        startIcon={<DynamicMuiIcon variant="createNewFolder" size="20px" />}
        width="156px"
        typography={{
          fontSize: 's',
          fontWeight: 600,
          lineheight: '14px'
        }}
        onClick={handleFileClick}
      />
      <Box mt="16px" />
      <Button kind="neutral" variant="contained" disabled title="インポートを実行" width="144px" />
      {/* ファイル選択用 hidden file input element*/}
      <Box display="none">
        <input
          ref={inputRef}
          type="file"
          accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}
