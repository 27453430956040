/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner,
  GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSON,
  GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSONTyped,
  GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerToJSON
} from './GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner'

/**
 *
 * @export
 * @interface GetDocumentOpportunityUsersResponse1Data
 */
export interface GetDocumentOpportunityUsersResponse1Data {
  /**
   *
   * @type {Array<GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner>}
   * @memberof GetDocumentOpportunityUsersResponse1Data
   */
  documentOpportunityUsers?: Array<GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInner>
}

export function GetDocumentOpportunityUsersResponse1DataFromJSON(json: any): GetDocumentOpportunityUsersResponse1Data {
  return GetDocumentOpportunityUsersResponse1DataFromJSONTyped(json, false)
}

export function GetDocumentOpportunityUsersResponse1DataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentOpportunityUsersResponse1Data {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentOpportunityUsers: !exists(json, 'document_opportunity_users')
      ? undefined
      : (json['document_opportunity_users'] as Array<any>).map(
          GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerFromJSON
        )
  }
}

export function GetDocumentOpportunityUsersResponse1DataToJSON(
  value?: GetDocumentOpportunityUsersResponse1Data | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_opportunity_users:
      value.documentOpportunityUsers === undefined
        ? undefined
        : (value.documentOpportunityUsers as Array<any>).map(
            GetDocumentOpportunityUsersResponse1DataDocumentOpportunityUsersInnerToJSON
          )
  }
}
