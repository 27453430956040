import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const ContentPaste = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 2.50001H12.35C12 1.53334 11.0833 0.833344 10 0.833344C8.91667 0.833344 8 1.53334 7.65 2.50001H4.16667C3.25 2.50001 2.5 3.25001 2.5 4.16668V17.5C2.5 18.4167 3.25 19.1667 4.16667 19.1667H15.8333C16.75 19.1667 17.5 18.4167 17.5 17.5V4.16668C17.5 3.25001 16.75 2.50001 15.8333 2.50001ZM10 2.50001C10.4583 2.50001 10.8333 2.87501 10.8333 3.33334C10.8333 3.79168 10.4583 4.16668 10 4.16668C9.54167 4.16668 9.16667 3.79168 9.16667 3.33334C9.16667 2.87501 9.54167 2.50001 10 2.50001ZM15 17.5H5C4.54167 17.5 4.16667 17.125 4.16667 16.6667V5.00001C4.16667 4.54168 4.54167 4.16668 5 4.16668H5.83333V5.00001C5.83333 5.91668 6.58333 6.66668 7.5 6.66668H12.5C13.4167 6.66668 14.1667 5.91668 14.1667 5.00001V4.16668H15C15.4583 4.16668 15.8333 4.54168 15.8333 5.00001V16.6667C15.8333 17.125 15.4583 17.5 15 17.5Z"
        fill="#222B35"
      />
    </SvgIcon>
  )
}
export default ContentPaste
