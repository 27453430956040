/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EuOrganizationChatSetting,
  EuOrganizationChatSettingFromJSON,
  EuOrganizationChatSettingFromJSONTyped,
  EuOrganizationChatSettingToJSON
} from './EuOrganizationChatSetting'

/**
 *
 * @export
 * @interface GetEuOrganizationChatSettingResponseData
 */
export interface GetEuOrganizationChatSettingResponseData {
  /**
   *
   * @type {EuOrganizationChatSetting}
   * @memberof GetEuOrganizationChatSettingResponseData
   */
  organizationChatSetting?: EuOrganizationChatSetting
}

export function GetEuOrganizationChatSettingResponseDataFromJSON(json: any): GetEuOrganizationChatSettingResponseData {
  return GetEuOrganizationChatSettingResponseDataFromJSONTyped(json, false)
}

export function GetEuOrganizationChatSettingResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEuOrganizationChatSettingResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationChatSetting: !exists(json, 'organization_chat_setting')
      ? undefined
      : EuOrganizationChatSettingFromJSON(json['organization_chat_setting'])
  }
}

export function GetEuOrganizationChatSettingResponseDataToJSON(
  value?: GetEuOrganizationChatSettingResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_chat_setting: EuOrganizationChatSettingToJSON(value.organizationChatSetting)
  }
}
