/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  DocumentShareForm,
  DocumentShareFormFromJSON,
  DocumentShareFormFromJSONTyped,
  DocumentShareFormToJSON
} from './DocumentShareForm'

/**
 *
 * @export
 * @interface GetContactShareDocumentShareFormsResponseData
 */
export interface GetContactShareDocumentShareFormsResponseData {
  /**
   *
   * @type {Array<DocumentShareForm>}
   * @memberof GetContactShareDocumentShareFormsResponseData
   */
  shareForms?: Array<DocumentShareForm>
}

export function GetContactShareDocumentShareFormsResponseDataFromJSON(
  json: any
): GetContactShareDocumentShareFormsResponseData {
  return GetContactShareDocumentShareFormsResponseDataFromJSONTyped(json, false)
}

export function GetContactShareDocumentShareFormsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactShareDocumentShareFormsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    shareForms: !exists(json, 'share_forms')
      ? undefined
      : (json['share_forms'] as Array<any>).map(DocumentShareFormFromJSON)
  }
}

export function GetContactShareDocumentShareFormsResponseDataToJSON(
  value?: GetContactShareDocumentShareFormsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    share_forms:
      value.shareForms === undefined ? undefined : (value.shareForms as Array<any>).map(DocumentShareFormToJSON)
  }
}
