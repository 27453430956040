/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetDocumentsResponseData,
  GetDocumentsResponseDataFromJSON,
  GetDocumentsResponseDataFromJSONTyped,
  GetDocumentsResponseDataToJSON
} from './GetDocumentsResponseData'

/**
 *
 * @export
 * @interface GetDocumentsResponse
 */
export interface GetDocumentsResponse {
  /**
   *
   * @type {number}
   * @memberof GetDocumentsResponse
   */
  status?: number
  /**
   *
   * @type {GetDocumentsResponseData}
   * @memberof GetDocumentsResponse
   */
  data?: GetDocumentsResponseData
}

export function GetDocumentsResponseFromJSON(json: any): GetDocumentsResponse {
  return GetDocumentsResponseFromJSONTyped(json, false)
}

export function GetDocumentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDocumentsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetDocumentsResponseDataFromJSON(json['data'])
  }
}

export function GetDocumentsResponseToJSON(value?: GetDocumentsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetDocumentsResponseDataToJSON(value.data)
  }
}
