/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { CompanyMemo, CompanyMemoFromJSON, CompanyMemoFromJSONTyped, CompanyMemoToJSON } from './CompanyMemo'

/**
 *
 * @export
 * @interface CreateCompanyMemoResponseData
 */
export interface CreateCompanyMemoResponseData {
  /**
   *
   * @type {CompanyMemo}
   * @memberof CreateCompanyMemoResponseData
   */
  companyMemo?: CompanyMemo
}

export function CreateCompanyMemoResponseDataFromJSON(json: any): CreateCompanyMemoResponseData {
  return CreateCompanyMemoResponseDataFromJSONTyped(json, false)
}

export function CreateCompanyMemoResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateCompanyMemoResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyMemo: !exists(json, 'company_memo') ? undefined : CompanyMemoFromJSON(json['company_memo'])
  }
}

export function CreateCompanyMemoResponseDataToJSON(value?: CreateCompanyMemoResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_memo: CompanyMemoToJSON(value.companyMemo)
  }
}
