/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function OptionUserPlanFromJSON(json) {
    return OptionUserPlanFromJSONTyped(json, false);
}
export function OptionUserPlanFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json['id'],
        name: json['name'],
        unitAmount: json['unit_amount'],
        stripePriceId: json['stripe_price_id']
    };
}
export function OptionUserPlanToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        unit_amount: value.unitAmount,
        stripe_price_id: value.stripePriceId
    };
}
