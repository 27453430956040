/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DocumentSlideImageLinkFromJSON, DocumentSlideImageLinkToJSON } from './DocumentSlideImageLink';
import { UploadedFileFromJSON, UploadedFileToJSON } from './UploadedFile';
export function DocumentSlideImageFromJSON(json) {
    return DocumentSlideImageFromJSONTyped(json, false);
}
export function DocumentSlideImageFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        documentSlideId: !exists(json, 'document_slide_id') ? undefined : json['document_slide_id'],
        numberOfPage: !exists(json, 'number_of_page') ? undefined : json['number_of_page'],
        file: !exists(json, 'file') ? undefined : UploadedFileFromJSON(json['file']),
        thumbnail: !exists(json, 'thumbnail') ? undefined : UploadedFileFromJSON(json['thumbnail']),
        inProgress: !exists(json, 'in_progress') ? undefined : json['in_progress'],
        slideImageLinks: !exists(json, 'slide_image_links')
            ? undefined
            : json['slide_image_links'].map(DocumentSlideImageLinkFromJSON),
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function DocumentSlideImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        document_slide_id: value.documentSlideId,
        number_of_page: value.numberOfPage,
        file: UploadedFileToJSON(value.file),
        thumbnail: UploadedFileToJSON(value.thumbnail),
        in_progress: value.inProgress,
        slide_image_links: value.slideImageLinks === undefined
            ? undefined
            : value.slideImageLinks.map(DocumentSlideImageLinkToJSON),
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
