import React, { useCallback, useState, useEffect } from 'react'
import { Box, createStyles, makeStyles, ButtonBase, Modal, Slide } from '@material-ui/core'
import { Colors, Typography, MuiIconVariant } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { Label } from './Label'
import { Count } from './Count'
import { Avatar } from './Icons'
import { GetUsersResponseDataUsersInnerUserAvatar } from '@noco/http-client/lib/noco'

export type variableList = {
  text?: string | undefined
  counts?: number | null | undefined
  avatar?: GetUsersResponseDataUsersInnerUserAvatar
  id?: string | undefined
  name?: string | undefined
  createdAt?: string | undefined
  updatedAt?: string | undefined
  isPin?: boolean
  countsOfListItem?: number | undefined
}[]

export interface ShowAllListProps {
  allCount: number | undefined
  title: string
  variableList: variableList
  icon: MuiIconVariant
  onPinConfirm?: (ids: string[], type?: string) => void
  materialableType?: string
}

const useStyles = makeStyles(() => {
  return createStyles({
    stretchButton: {
      width: '100%',
      '&:hover': {
        backgroundColor: Colors.selected.background,
        color: Colors.primary.default
      }
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

export const ShowAllList = ({
  allCount,
  title,
  variableList,
  icon,
  onPinConfirm,
  materialableType
}: ShowAllListProps) => {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = useCallback(() => {
    setOpenModal(true)
  }, [])
  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
  }, [])
  const handleConfirm = useCallback(
    (ids: string[], materialableType?: string) => () => {
      onPinConfirm?.(ids, materialableType)
      setOpenModal(false)
    },
    [onPinConfirm]
  )

  const [ids, setIds] = useState<string[]>([])

  useEffect(() => {
    if (!variableList) {
      return
    }
    const initialIds: string[] = variableList.filter(list => list.isPin as boolean).map(list => list.id as string)
    setIds(initialIds)
  }, [variableList])

  const handleGetPinsId = useCallback(
    (selectedId: string) => {
      let newIds = [...ids]
      if (newIds.some(id => id === selectedId)) {
        newIds = newIds.filter(id => id != selectedId)
      } else newIds.push(selectedId)
      setIds(newIds)
    },
    [ids]
  )

  return (
    <>
      <ButtonBase className={classes.stretchButton} onClick={handleOpenModal}>
        <Box color={Colors.base.middleGray} height="20px" width="100%" display="flex" alignItems="center" px="14px">
          <Typography fontSize="xs" lineheight="12px">
            すべて見る({allCount})
          </Typography>
        </Box>
      </ButtonBase>
      <Modal open={openModal} onClose={handleCloseModal} className={classes.modal}>
        <Slide direction="left" in={openModal}>
          <Box width="376px" height={1}>
            <SlideForm
              headerTitle={title}
              hideSettings
              onClose={handleCloseModal}
              onCancel={handleCloseModal}
              onConfirm={handleConfirm(ids, materialableType)}
            >
              <Box px="24px" py="24px">
                {variableList?.map(({ id, text, counts, isPin, avatar }, i) => {
                  return (
                    <Label
                      key={i}
                      id={id}
                      label={text}
                      rightComponent={<Count count={counts} fontSize="s" lineHeight="18.48px" />}
                      icon={icon}
                      showPinIcon
                      large
                      border
                      isPin={isPin}
                      unit
                      disableHover
                      avatar={avatar ? <Avatar avatar={avatar.url} /> : undefined}
                      onSetPin={handleGetPinsId}
                    />
                  )
                })}
              </Box>
            </SlideForm>
          </Box>
        </Slide>
      </Modal>
    </>
  )
}
