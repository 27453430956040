import { Box } from '@material-ui/core'
import React from 'react'
import { Colors } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { ChatSetting, Header } from 'src/components/organisms'

export const PageChatSetting = () => {
  return (
    <LayoutTeam>
      <Box width={1} bgcolor={Colors.functional.background.default} px="16px">
        <Header icon="chatBubble" title="チャットの表示設定" back={false} textSize="large" iconSize="32px" />
        <Box px="16px">
          <ChatSetting />
        </Box>
      </Box>
    </LayoutTeam>
  )
}
