/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CreateVisitorRequest,
  CreateVisitorRequestFromJSON,
  CreateVisitorRequestToJSON,
  CreateVisitorResponse,
  CreateVisitorResponseFromJSON,
  CreateVisitorResponseToJSON,
  GetVisitorResponse,
  GetVisitorResponseFromJSON,
  GetVisitorResponseToJSON,
  PutContactVisitorContactRequest,
  PutContactVisitorContactRequestFromJSON,
  PutContactVisitorContactRequestToJSON,
  PutContactVisitorContactResponse,
  PutContactVisitorContactResponseFromJSON,
  PutContactVisitorContactResponseToJSON
} from '../models'

export interface ContactV1VisitorContactPutRequest {
  xNocoVisitorToken?: string
  putContactVisitorContactRequest?: PutContactVisitorContactRequest
}

export interface ContactV1VisitorGetRequest {
  xNocoVisitorToken?: string
}

export interface ContactV1VisitorPostRequest {
  createVisitorRequest?: CreateVisitorRequest
}

/**
 *
 */
export class ApiContactV1VisitorApi extends runtime.BaseAPI {
  /**
   * 共有フォームによる contact の更新
   * update_contact
   */
  async contactV1VisitorContactPutRaw(
    requestParameters: ContactV1VisitorContactPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PutContactVisitorContactResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.xNocoVisitorToken !== undefined && requestParameters.xNocoVisitorToken !== null) {
      headerParameters['X-Noco-Visitor-Token'] = String(requestParameters.xNocoVisitorToken)
    }

    const response = await this.request(
      {
        path: `/contact/v1/visitor/contact`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PutContactVisitorContactRequestToJSON(requestParameters.putContactVisitorContactRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PutContactVisitorContactResponseFromJSON(jsonValue))
  }

  /**
   * 共有フォームによる contact の更新
   * update_contact
   */
  async contactV1VisitorContactPut(
    requestParameters: ContactV1VisitorContactPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PutContactVisitorContactResponse> {
    const response = await this.contactV1VisitorContactPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * トークンからvisitor情報の取得
   * visitor
   */
  async contactV1VisitorGetRaw(
    requestParameters: ContactV1VisitorGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetVisitorResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.xNocoVisitorToken !== undefined && requestParameters.xNocoVisitorToken !== null) {
      headerParameters['X-Noco-Visitor-Token'] = String(requestParameters.xNocoVisitorToken)
    }

    const response = await this.request(
      {
        path: `/contact/v1/visitor`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetVisitorResponseFromJSON(jsonValue))
  }

  /**
   * トークンからvisitor情報の取得
   * visitor
   */
  async contactV1VisitorGet(
    requestParameters: ContactV1VisitorGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetVisitorResponse> {
    const response = await this.contactV1VisitorGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * visitor の作成
   * visitor
   */
  async contactV1VisitorPostRaw(
    requestParameters: ContactV1VisitorPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateVisitorResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/contact/v1/visitor`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateVisitorRequestToJSON(requestParameters.createVisitorRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateVisitorResponseFromJSON(jsonValue))
  }

  /**
   * visitor の作成
   * visitor
   */
  async contactV1VisitorPost(
    requestParameters: ContactV1VisitorPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateVisitorResponse> {
    const response = await this.contactV1VisitorPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
