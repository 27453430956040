import { Box, ButtonBase, Popover, TableCell, Typography, createStyles, makeStyles } from '@material-ui/core'
import { format, isBefore, subHours } from 'date-fns'
import { ja } from 'date-fns/locale'
import React, { useCallback, useMemo, useState } from 'react'
import { Colors, DynamicMuiIcon, OptionListType, SelectBox, Tooltip } from 'src/components/atoms'
import { ModalConfirmChangeRole } from 'src/components/modals'
import { dateFormat, DateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { useGlobalStyles } from 'src/styles/theme'
import { EnumUserPermission, User } from '@noco/http-client/lib/noco'
import { useUpdateOrganizationUser } from 'src/fixtures/modules/organization/hooks'

export interface MemberRowProps {
  user: User
  isAdmin?: boolean
  onResend?: (id: string, password?: string) => void
  onDelete?: (id: string) => void
  onRestore?: (id: string) => void
  onChangeRole: (permission: string) => void
}

const optionList: OptionListType[] = [
  { value: EnumUserPermission.Administrator, label: '管理者' },
  { value: EnumUserPermission.Manager, label: 'マネージャー' },
  { value: EnumUserPermission.Member, label: 'メンバー' },
  { value: EnumUserPermission.Assistant, label: 'アシスタント' }
]

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      height: '44px',
      borderBottom: '1px solid #EEF1F4'
    }
  })
)

export const MemberRow = ({ user, isAdmin, onResend, onDelete, onRestore, onChangeRole }: MemberRowProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [openModalResendToAdmin, setOpenModalResendToAdmin] = useState(false)
  const { handleUpdateOrganizationUser } = useUpdateOrganizationUser()

  const toolTipText = useMemo(() => {
    switch (user.permission?.toString()) {
      case EnumUserPermission.Administrator:
        return '「プラン変更・決済」を含めたすべての機能が利用できます'
      case EnumUserPermission.Manager:
        return '「プラン変更・決済」「メンバー招待」を除く、すべての機能が利用できます'
      case EnumUserPermission.Member:
        return '資料や資料サイトの編集、顧客とのチャットができます'
      default:
        return '顧客とのチャットができます'
    }
  }, [user.permission])

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleDelete = useCallback(() => {
    user.id && onDelete?.(user.id)
    setAnchorEl(null)
  }, [onDelete, user])

  const handleRestore = useCallback(() => {
    user.id && onRestore?.(user.id)
    setAnchorEl(null)
  }, [onRestore, user])

  const handleChangeRole = useCallback(
    (value: string) => {
      setAnchorEl(null)
      if (value === EnumUserPermission.Administrator) {
        setModalOpen(true)
      } else {
        onChangeRole(value)
      }
    },
    [onChangeRole]
  )

  const handleRoleChangeModalConfirm = useCallback(
    async (password: string) => {
      try {
        await handleUpdateOrganizationUser(user.id!, {
          user: { permission: EnumUserPermission.Administrator },
          password
        })
        setModalOpen(false)
      } catch (error) {
        console.error(error)
      }
    },
    [handleUpdateOrganizationUser, user.id]
  )

  return (
    <>
      <TableCell classes={{ root: classes.tableCell }}>
        <Box minWidth="100px" display="flex" alignItems="center">
          <Box width="24px" height="24px" flexShrink={0} mr="8px" borderRadius="24px" overflow="hidden">
            <img src={user.avatar?.url} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </Box>
          <Box className={globalClasses.lineclamp1} color={user.discardedAt ? Colors.base.placeHolder : 'inherit'}>
            <Typography variant="h5">
              {user?.lastName} {user?.firstName}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell classes={{ root: classes.tableCell }}>
        <Box
          minWidth="140px"
          display="flex"
          alignItems="center"
          className={globalClasses.lineclamp1}
          color={user.discardedAt ? Colors.base.placeHolder : 'inherit'}
        >
          <Typography variant="subtitle1">{user.encryptedEmail}</Typography>
        </Box>
      </TableCell>
      <TableCell classes={{ root: classes.tableCell }}>
        <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box flexShrink={0} width="134px">
              {user.discardedAt ? (
                <Box color={Colors.base.placeHolder} textAlign="center">
                  <Typography variant="subtitle1">解除されたユーザー</Typography>
                </Box>
              ) : (
                <Tooltip content={toolTipText}>
                  <SelectBox
                    placeholder="選択してください"
                    value={user.permission?.toString() || '1'}
                    optionList={optionList}
                    onChange={e => handleChangeRole(e.target.value as string)}
                    disabled={!isAdmin}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell classes={{ root: classes.tableCell }}>
        <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {user.discardedAt && (
              <Box color={Colors.base.placeHolder} whiteSpace="nowrap" className={globalClasses.lineclamp1}>
                <Typography variant="subtitle1">
                  {format(new Date(user.discardedAt).getTime(), 'yyyy/MM/dd (E) 解除済み', { locale: ja })}
                </Typography>
              </Box>
            )}
            {!user.activatedAt &&
              !user.discardedAt &&
              user.invitedAt &&
              (isBefore(new Date(user.invitedAt), subHours(new Date(), 24)) ? (
                <Box className={globalClasses.lineclamp1} color={Colors.base.placeHolder}>
                  <Typography variant="subtitle1">招待メール有効期限切れ</Typography>
                </Box>
              ) : (
                <Box className={globalClasses.lineclamp1} color={Colors.accent.keyPurple.default}>
                  <Typography variant="subtitle1">{format(new Date(user.invitedAt), 'a hh:mm')} 招待済み</Typography>
                </Box>
              ))}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box
          minWidth="140px"
          display="flex"
          alignItems="center"
          className={globalClasses.lineclamp1}
          color={Colors.base.middleGray}
        >
          <Typography variant="subtitle1">
            {user.activatedAt
              ? dateFormat(dateStringToMilliseconds(user.activatedAt!), DateFormat.DATE_WITH_TIME)
              : '-'}
          </Typography>
        </Box>
      </TableCell>
      <TableCell classes={{ root: classes.tableCell }}>
        <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
          {isAdmin && (
            <Box color={Colors.base.middleGray}>
              <ButtonBase onClick={handleClick}>
                <DynamicMuiIcon variant="dots" color="inherit" size="20px" />
              </ButtonBase>
            </Box>
          )}
          <Popover
            PaperProps={{ style: { marginTop: '34px' } }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <Box className={globalClasses.tooltip} display="flex" flexDirection="column">
              {!user.activatedAt && !user.discardedAt && (
                <ButtonBase
                  onClick={() => {
                    if (user.permission === 'administrator') {
                      setOpenModalResendToAdmin(true)
                    } else {
                      onResend?.(user.id!)
                    }
                  }}
                >
                  <Box
                    borderBottom={`1px solid ${Colors.background.gray}`}
                    p="10px 14px"
                    display="flex"
                    alignItems="center"
                  >
                    <Box color={Colors.base.middleGray} mr="4px">
                      <DynamicMuiIcon color="inherit" variant="edit" size="20px" />
                    </Box>
                    <Typography variant="subtitle1">メールを再送</Typography>
                  </Box>
                </ButtonBase>
              )}
              {!user.discardedAt && (
                <ButtonBase onClick={handleDelete}>
                  <Box width={1} p="10px 14px" display="flex" alignItems="center" color={Colors.accent.keyRed.default}>
                    <DynamicMuiIcon variant="delete" size="20px" />
                    <Box width="4px" />
                    <Typography variant="subtitle1">解除する</Typography>
                  </Box>
                </ButtonBase>
              )}
              {user.discardedAt && (
                <ButtonBase onClick={handleRestore}>
                  <Box width={1} p="10px 14px" display="flex" alignItems="center" color={Colors.base.black}>
                    <Box color={Colors.base.middleGray} mr="4px">
                      <DynamicMuiIcon variant="keyboardReturn" size="20px" />
                    </Box>
                    <Typography variant="subtitle1">解除をキャンセルする</Typography>
                  </Box>
                </ButtonBase>
              )}
            </Box>
          </Popover>
        </Box>
      </TableCell>
      <ModalConfirmChangeRole
        kind={'roleChangeToAdmin'}
        user={user}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleRoleChangeModalConfirm}
      />
      {/* email 再送の場合 */}
      <ModalConfirmChangeRole
        kind={'resendEmailToAdmin'}
        user={user}
        open={openModalResendToAdmin}
        onClose={() => setOpenModalResendToAdmin(false)}
        onConfirm={password => {
          if (!user) return
          onResend?.(user.id!, password)
          setAnchorEl(null)
          setOpenModalResendToAdmin(false)
        }}
      />
    </>
  )
}
