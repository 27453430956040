/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateCompanyRequestCompany,
  CreateCompanyRequestCompanyFromJSON,
  CreateCompanyRequestCompanyFromJSONTyped,
  CreateCompanyRequestCompanyToJSON
} from './CreateCompanyRequestCompany'

/**
 *
 * @export
 * @interface CreateCompanyRequest
 */
export interface CreateCompanyRequest {
  /**
   *
   * @type {CreateCompanyRequestCompany}
   * @memberof CreateCompanyRequest
   */
  company?: CreateCompanyRequestCompany
  /**
   *
   * @type {string}
   * @memberof CreateCompanyRequest
   */
  contactId?: string
}

export function CreateCompanyRequestFromJSON(json: any): CreateCompanyRequest {
  return CreateCompanyRequestFromJSONTyped(json, false)
}

export function CreateCompanyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompanyRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    company: !exists(json, 'company') ? undefined : CreateCompanyRequestCompanyFromJSON(json['company']),
    contactId: !exists(json, 'contact_id') ? undefined : json['contact_id']
  }
}

export function CreateCompanyRequestToJSON(value?: CreateCompanyRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company: CreateCompanyRequestCompanyToJSON(value.company),
    contact_id: value.contactId
  }
}
