/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { ChatMessage, ChatMessageFromJSON, ChatMessageFromJSONTyped, ChatMessageToJSON } from './ChatMessage'

/**
 *
 * @export
 * @interface CreateContactChatMessageResponseData
 */
export interface CreateContactChatMessageResponseData {
  /**
   *
   * @type {ChatMessage}
   * @memberof CreateContactChatMessageResponseData
   */
  chatMessage?: ChatMessage
}

export function CreateContactChatMessageResponseDataFromJSON(json: any): CreateContactChatMessageResponseData {
  return CreateContactChatMessageResponseDataFromJSONTyped(json, false)
}

export function CreateContactChatMessageResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactChatMessageResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    chatMessage: !exists(json, 'chat_message') ? undefined : ChatMessageFromJSON(json['chat_message'])
  }
}

export function CreateContactChatMessageResponseDataToJSON(value?: CreateContactChatMessageResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    chat_message: ChatMessageToJSON(value.chatMessage)
  }
}
