/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingFromJSON, UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingToJSON } from './UpdateMeEmailNotificationSettingRequestUserEmailNotificationSetting';
export function UpdateMeEmailNotificationSettingRequestFromJSON(json) {
    return UpdateMeEmailNotificationSettingRequestFromJSONTyped(json, false);
}
export function UpdateMeEmailNotificationSettingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userEmailNotificationSetting: !exists(json, 'user_email_notification_setting')
            ? undefined
            : UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingFromJSON(json['user_email_notification_setting'])
    };
}
export function UpdateMeEmailNotificationSettingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user_email_notification_setting: UpdateMeEmailNotificationSettingRequestUserEmailNotificationSettingToJSON(value.userEmailNotificationSetting)
    };
}
