/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateMeRequestUserFromJSON(json) {
    return UpdateMeRequestUserFromJSONTyped(json, false);
}
export function UpdateMeRequestUserFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
        firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
        encryptedEmail: !exists(json, 'encrypted_email') ? undefined : json['encrypted_email'],
        opportunityToolUrl: !exists(json, 'opportunity_tool_url') ? undefined : json['opportunity_tool_url']
    };
}
export function UpdateMeRequestUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        last_name: value.lastName,
        first_name: value.firstName,
        encrypted_email: value.encryptedEmail,
        opportunity_tool_url: value.opportunityToolUrl
    };
}
