import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import Link from 'next/link'
import { Colors, NotificationBadge, SvgIcon, Typography } from 'src/components/atoms'
import { DynamicMuiIcon, MuiIconVariant } from '../../atoms/DynamicMuiIcon'
import { useRouter } from 'next/router'
import { ModalLeadToPaidPlan } from 'src/components/modals'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { useChannelTalk } from 'src/fixtures/utils/channelTalk'

interface SideMenuItemType {
  path: string
  icon: MuiIconVariant
  label: string
  count?: number
  targetBlank?: boolean
  onClick?: () => void
}

interface SideMenuItemListType {
  top: SideMenuItemType[]
  bottom: SideMenuItemType[]
}

const useStyles = makeStyles(() => {
  return createStyles({
    link: {
      textDecoration: 'none'
    },
    button: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      // padding: ({ isExpanded }: ButtonStyleData) => (isExpanded ? '8px 0px 8px 16px' : '8px 16px'),
      padding: '8px 16px',
      color: Colors.base.middleGray,
      transition: '512ms',
      '&:hover': {
        background: Colors.background.gray,
        color: Colors.base.black
      }
    },
    activeButton: {
      background: Colors.background.gray,
      color: Colors.base.black
    },
    transition: {
      transition: '256ms'
    },
    bannerBtn: {
      display: 'block',
      width: '100%'
    }
  })
})

interface ButtonStyleData {
  isExpanded: boolean
}

export interface SideMenuProps {
  inboxCount?: number
  notificationCount?: number
}

export const EXPANDED_SIDE_MENU_SIZE = 182
export const MIN_SIDE_MENU_SIZE = 56

const isCurrentPage = (path: string, currentPage: string) => {
  if (path.split('/')[1] === 'setting') {
    return path.split('/')[2] === currentPage.split('/')[2]
  }

  if (path.split('/')[1] === '') {
    return path === currentPage
  }
  return path.split('/')[1] === currentPage.split('/')[1]
}

export const SideMenu = ({ inboxCount }: SideMenuProps) => {
  const { data } = useGetOrganizationPaymentCurrentPlan()
  const plan = data?.plan
  const router = useRouter()

  const { showMessenger } = useChannelTalk()

  const currentPage = router.pathname
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const classes = useStyles({ isExpanded })
  const SIDE_MENU_SIZE = useMemo(() => (isExpanded ? EXPANDED_SIDE_MENU_SIZE : MIN_SIDE_MENU_SIZE), [isExpanded])
  const handleExpand = useCallback(() => {
    setIsExpanded(true)
  }, [])
  const handleShrink = useCallback(() => {
    setIsExpanded(false)
  }, [])
  const handleCloseModal = useCallback(() => {
    setOpenModal(false)
  }, [])
  const SideMenuItemList: SideMenuItemListType = useMemo(() => {
    return {
      top: [
        { path: '/inbox', icon: 'inbox', label: '受信トレイ', count: inboxCount },
        { path: '/contacts', icon: 'account', label: '連絡先' },
        { path: '/companies', icon: 'business', label: '会社' },
        { path: '/documents', icon: 'document', label: '資料' },
        { path: '/multi-documents', icon: 'computer', label: '資料サイト' }
      ],
      bottom: [
        {
          path: '#',
          icon: 'quiz',
          label: 'お問い合わせ',
          targetBlank: true,
          onClick: () => showMessenger()
        },
        { path: '/setting/user/info', icon: 'manageAccounts', label: '個人設定' },
        { path: '/setting/team/info', icon: 'groups', label: 'チーム設定' }
      ]
    }
  }, [inboxCount, showMessenger])

  return (
    <Box
      width={SIDE_MENU_SIZE}
      height={1}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      bgcolor={Colors.functional.background.default}
      borderRight={`1px solid ${Colors.background.silver}`}
      onMouseEnter={handleExpand}
      onMouseLeave={handleShrink}
      overflow="hidden"
      className={classes.transition}
    >
      <Box>
        <ButtonBase
          onClick={() => router.push('/')}
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
        >
          <Box width={1} px="17px" py="18px" display="flex" alignItems="center">
            <img
              src={isExpanded ? '/images/nocosell_logo.svg' : '/images/nocosell_icon.svg'}
              height="24px"
              style={{ objectFit: 'contain' }}
            />
            {isExpanded && plan?.grade === 'free' && (
              <Box
                border={`1px solid ${Colors.base.middleGray}`}
                borderRadius="4px"
                py="4px"
                width="58px"
                display="flex"
                justifyContent="center"
                ml="8px"
                flexShrink={0}
                color={Colors.base.middleGray}
              >
                <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                  無料版
                </Typography>
              </Box>
            )}
          </Box>
        </ButtonBase>

        <Box>
          {SideMenuItemList.top.map(({ label, path, icon, count }) => {
            return (
              <Link key={label} href={path} as={path} prefetch={false}>
                <a className={classes.link}>
                  <ButtonBase
                    // TODO: 計算コストがあるので、memo 化したい
                    className={`${classes.button} ${isCurrentPage(path, currentPage) && classes.activeButton}`}
                  >
                    <Box display="flex" alignItems="center">
                      <DynamicMuiIcon variant={icon} />
                      {isExpanded && (
                        <Box ml="8px" whiteSpace="noWrap">
                          <Typography variant="h5">{label}</Typography>
                        </Box>
                      )}
                    </Box>
                    {count && isExpanded && (
                      <Box width="31px">
                        <NotificationBadge count={count} />
                      </Box>
                    )}
                  </ButtonBase>
                </a>
              </Link>
            )
          })}
        </Box>
      </Box>
      <Box mb="16px">
        <Link key="ご利用ガイド" href="/guide">
          <a className={classes.link} target="_blank">
            <ButtonBase className={`${classes.button}`}>
              <Box display="flex" alignItems="center">
                <SvgIcon variant="guide" color="currentColor" size={24} />
                {isExpanded && (
                  <Box ml="8px" whiteSpace="noWrap">
                    <Typography variant="h5">ご利用ガイド</Typography>
                  </Box>
                )}
              </Box>
            </ButtonBase>
          </a>
        </Link>
        {SideMenuItemList.bottom.map(({ label, path, icon, count, targetBlank, onClick }) => {
          return onClick ? (
            <ButtonBase className={`${classes.button}`} onClick={onClick}>
              <Box display="flex" alignItems="center">
                <DynamicMuiIcon variant={icon} />
                {isExpanded && (
                  <Box ml="8px" whiteSpace="noWrap">
                    <Typography variant="h5">{label}</Typography>
                  </Box>
                )}
              </Box>
              {count && isExpanded && (
                <Box width="31px">
                  <NotificationBadge count={count} />
                </Box>
              )}
            </ButtonBase>
          ) : (
            <Link key={label} href={path}>
              <a className={classes.link} target={targetBlank ? '_blank' : '_self'}>
                {/* TODO: 計算コストがあるので、memo 化したい */}
                <ButtonBase className={`${classes.button} ${isCurrentPage(path, currentPage) && classes.activeButton}`}>
                  <Box display="flex" alignItems="center">
                    <DynamicMuiIcon variant={icon} />
                    {isExpanded && (
                      <Box ml="8px" whiteSpace="noWrap">
                        <Typography variant="h5">{label}</Typography>
                      </Box>
                    )}
                  </Box>
                  {count && isExpanded && (
                    <Box width="31px">
                      <NotificationBadge count={count} />
                    </Box>
                  )}
                </ButtonBase>
              </a>
            </Link>
          )
        })}
        {plan?.grade === 'free' && (
          <Box width={1} px="16px" mt="8px">
            <ButtonBase className={classes.bannerBtn} onClick={() => setOpenModal(true)}>
              <Box
                bgcolor={isExpanded ? `${Colors.base.navyBlue}` : '#fff'}
                width={1}
                height="51px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                py="7px"
                borderRadius="4px"
                color={Colors.functional.whiteText.default}
              >
                <SvgIcon variant="glitter" size="20px" color="#ffc932" />
                {isExpanded && (
                  <Box ml="8px" whiteSpace="noWrap" textAlign="left">
                    <Typography fontSize="s" fontWeight="bold" lh="tight">
                      有料プランで
                      <br />
                      もっと顧客獲得
                    </Typography>
                  </Box>
                )}
              </Box>
            </ButtonBase>
          </Box>
        )}
      </Box>
      <ModalLeadToPaidPlan open={openModal} onClose={handleCloseModal} />
    </Box>
  )
}
