import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Psd = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#0505DD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0466 27.9799V23.1789C17.3028 23.2036 17.5956 23.2159 17.925 23.2159C19.9257 23.2159 21.5361 22.6865 22.5852 21.6771C23.4514 20.8523 23.9272 19.609 23.9272 18.1934C23.9272 16.8269 23.366 15.5836 22.3656 14.7835C21.3897 13.9956 19.9623 13.614 17.986 13.614C16.2781 13.614 14.8385 13.7125 13.6918 13.9095C13.6064 13.9341 13.5332 14.0079 13.5332 14.1064V27.9799C13.5332 28.0906 13.6186 28.1768 13.7284 28.1768H16.8514C16.9612 28.1768 17.0466 28.0906 17.0466 27.9799V27.9799ZM29.5877 21.554C28.3068 21.0985 28.0872 20.8893 28.0872 20.5692C28.0872 20.0152 28.7216 19.9414 29.0876 19.9414C29.8927 19.9414 30.7223 20.2368 31.1859 20.4953C31.2469 20.52 31.3079 20.5323 31.3567 20.5076C31.4177 20.483 31.4543 20.4338 31.4665 20.3722L32.0276 18.181C32.052 18.0949 32.0154 17.9964 31.93 17.9595C31.3445 17.664 30.2709 17.3563 28.99 17.3563C26.4525 17.3563 24.6835 18.8089 24.6835 20.8893C24.6591 21.8987 25.1715 23.2897 27.6846 24.1391C28.9046 24.5577 29.0388 24.7669 29.0388 25.1239C29.0388 25.2839 29.0388 25.7887 27.892 25.7887C27.0258 25.7887 25.8669 25.3947 25.2813 25.0377C25.2325 25.0131 25.1715 25.0008 25.1105 25.0254C25.0495 25.0501 25.0129 25.0993 25.0007 25.1608L24.4395 27.4382C24.4151 27.5244 24.4517 27.6106 24.5371 27.6598C25.3911 28.1153 26.6111 28.3861 27.892 28.3861C30.7101 28.3861 32.4668 27.0443 32.4668 24.89C32.4424 23.2651 31.5641 22.2434 29.5877 21.554V21.554ZM17.0466 20.3353V16.4946C17.2418 16.4576 17.5956 16.4084 18.1568 16.4084C19.5963 16.4084 20.4259 17.0978 20.4259 18.2795C20.4259 19.5967 19.4865 20.3845 17.9006 20.3845C17.5224 20.3845 17.254 20.3599 17.0466 20.3353V20.3353Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Psd
