/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { UploadedFile, UploadedFileFromJSON, UploadedFileFromJSONTyped, UploadedFileToJSON } from './UploadedFile'

/**
 *
 * @export
 * @interface SiteSectionDocumentDocument
 */
export interface SiteSectionDocumentDocument {
  /**
   *
   * @type {string}
   * @memberof SiteSectionDocumentDocument
   */
  id?: string
  /**
   *
   * @type {UploadedFile}
   * @memberof SiteSectionDocumentDocument
   */
  largeThumbnail?: UploadedFile | null
  /**
   *
   * @type {string}
   * @memberof SiteSectionDocumentDocument
   */
  title?: string
}

export function SiteSectionDocumentDocumentFromJSON(json: any): SiteSectionDocumentDocument {
  return SiteSectionDocumentDocumentFromJSONTyped(json, false)
}

export function SiteSectionDocumentDocumentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SiteSectionDocumentDocument {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    largeThumbnail: !exists(json, 'large_thumbnail') ? undefined : UploadedFileFromJSON(json['large_thumbnail']),
    title: !exists(json, 'title') ? undefined : json['title']
  }
}

export function SiteSectionDocumentDocumentToJSON(value?: SiteSectionDocumentDocument | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    large_thumbnail: UploadedFileToJSON(value.largeThumbnail),
    title: value.title
  }
}
