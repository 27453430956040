import { PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import { Box, createStyles, Input, makeStyles, MenuItem, Select } from '@material-ui/core'
import React, { useCallback, useRef } from 'react'
import { Colors, DynamicMuiIcon, SvgIcon, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import clsx from 'clsx'

export const PagingOptions = [10, 25, 50, 100]

const useStyles = makeStyles(() => {
  return createStyles({
    base: {
      height: '20px',
      backgroundColor: '#fff'
    },
    per: {
      '& .MuiSelect-root': {
        fontSize: '12px'
      }
    },
    page: {
      '& .MuiInputBase-input': {
        fontSize: '12px'
      }
    }
  })
})
export const PagingContainer = ({
  count,
  totalPages,
  currentPage,
  pageSize,
  onPageSizeChange,
  onCurrentPageChange,
  showTotalCount = false,
  onOpenModalSelectTableColumn
}: PagingPanel.ContainerProps & { showTotalCount: boolean; onOpenModalSelectTableColumn?: () => void } & {
  count: number | undefined
}) => {
  const globalClasses = useGlobalStyles()
  const inputRef = useRef<HTMLInputElement>()
  const classes = useStyles()
  const handleInput = useCallback(
    (value: string) => {
      if (inputRef.current) {
        const number = Math.floor(Number(value))
        if (!number) return
        onCurrentPageChange(number - 1)
        if (number < 1) {
          inputRef.current.value = '1'
        } else if (number >= totalPages) {
          inputRef.current.value = totalPages.toString()
        }
      }
    },
    [inputRef, onCurrentPageChange, totalPages]
  )
  const handleCurrentPage = useCallback(
    (direction: 'prev' | 'next') => {
      if (inputRef.current) {
        if (direction === 'prev' && currentPage > 0) {
          const prevPage = currentPage - 1
          onCurrentPageChange(prevPage)
          inputRef.current.value = prevPage.toString()
        } else if (direction === 'next' && currentPage + 1 < totalPages) {
          const nextPage = currentPage + 1
          onCurrentPageChange(nextPage)
          inputRef.current.value = nextPage.toString()
        }
      }
    },
    [inputRef, currentPage, onCurrentPageChange, totalPages]
  )

  return (
    <Box
      width={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="34px"
      flex="1 0 auto"
      color={Colors.base.middleGray}
      bgcolor={'#F8FAFD'}
      borderTop="1px solid #E7ECF2"
    >
      {onOpenModalSelectTableColumn && (
        <Box
          display="flex"
          alignItems="center"
          color={Colors.accent.keyBlue.default}
          style={{ cursor: 'pointer' }}
          onClick={onOpenModalSelectTableColumn}
        >
          <DynamicMuiIcon variant="setting" size="18px" />
          <Box ml="4px">
            <Typography fontSize="s" fontWeight="bold">
              テーブルの表示列を編集
            </Typography>
          </Box>
        </Box>
      )}

      <Box display="flex" alignItems="center">
        {showTotalCount && (
          <Box mr="24px" display="flex" alignItems="center">
            <Typography fontSize="xs" fontWeight="bold" lineheight="12px">
              合計数
            </Typography>
            <Box ml="12px" color={Colors.base.black}>
              <Typography fontSize="xs" fontWeight="bold" lineheight="12px">
                {count}
              </Typography>
            </Box>
          </Box>
        )}

        <Typography fontSize="xs" fontWeight="bold" lineheight="12px">
          表示数
        </Typography>
        <Box width="13px" />
        <Select
          className={clsx(classes.base, classes.per)}
          value={pageSize}
          onChange={e => onPageSizeChange(e.target.value as number)}
        >
          {PagingOptions.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Box display="flex" justifyContent="center" alignItems="center" px="12px">
          <Box
            px="9px"
            display="flex"
            height="20px"
            alignItems="center"
            className={globalClasses.cursorPointer}
            onClick={() => handleCurrentPage('prev')}
          >
            <SvgIcon variant="leftArrow" color={Colors.base.middleGray} size="12px" />
          </Box>
          <Input
            className={clsx(classes.base, classes.page)}
            defaultValue={(currentPage + 1).toString()}
            inputRef={inputRef}
            name="pageNum"
            onChange={e => handleInput(e.target.value as string)}
          />
          <Box width="4px" />
          <Typography fontSize="xs" lineheight="12px">
            / {totalPages}ページ
          </Typography>
          <Box
            px="9px"
            display="flex"
            height="20px"
            alignItems="center"
            className={globalClasses.cursorPointer}
            onClick={() => handleCurrentPage('next')}
          >
            <SvgIcon variant="rightArrow" color={Colors.base.middleGray} size="12px" />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
