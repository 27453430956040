import { Box, ButtonBase } from '@material-ui/core'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { OgpSetting, SettingToggleSectionCard, SettingToggleSectionCardProps } from '..'
import { ModalDocumentDownloadButtonConfirm } from '../../modals/ModalDocumentDownloadButtonConfirm/index'
import {
  useGetDocument,
  useGetDocumentMiscSetting,
  useUpdateDocumentMiscSetting,
  useUpdateDocumentMiscSettingOgpFile
} from '../../../fixtures/modules/documents/hooks'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { ModalAlertShare, ModalAlertShareProps, ModalConfirmToPaidPlan } from 'src/components/modals'

export interface DocumentSettingProps {
  documentId?: string
  onClose: () => void
}

type Values = {
  isShowShareButton: boolean
  isShowSnsButton: boolean
  isPublicSearchEngine: boolean
  ogpTitle: string
  ogpDescription: string
  isShowPrintingButton: boolean
  isShowDownloadButton: boolean
}

export const DocumentSetting = ({ documentId, onClose }: DocumentSettingProps) => {
  const { data: documentMiscSetting } = useGetDocumentMiscSetting(documentId)
  const { data: document } = useGetDocument(documentId)
  const { handleUpdateDocument } = useUpdateDocumentMiscSetting(documentId)
  const { handleUpdateDocumentOgpFile } = useUpdateDocumentMiscSettingOgpFile(documentId)
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()

  const [values, setValues] = useState<Values>({
    isShowShareButton: true,
    isShowSnsButton: true,
    isPublicSearchEngine: true,
    ogpTitle: '',
    ogpDescription: '',
    isShowPrintingButton: true,
    isShowDownloadButton: true
  })

  useEffect(() => {
    if (documentMiscSetting == null) return
    setValues(documentMiscSetting as any)
  }, [documentMiscSetting, setValues])

  const handleToggle = useCallback(
    (field: keyof Values) => {
      const newValues = { ...values, [field]: !values[field] }

      setValues(newValues)
      handleUpdateDocument({ documentMiscSetting: newValues })
    },
    [handleUpdateDocument, values]
  )

  const handleToggleOnlyOpened = useCallback(
    (field: keyof Values) => {
      if (!values[field] && document?.publicationStatus !== 'open') {
        setOpenAlert(true)
      } else {
        handleToggle(field)
      }
    },
    [handleToggle, document?.publicationStatus, values]
  )

  const handleOgpSettingChange = useCallback(
    (field: string, value: string) => {
      const newValues = { ...values, [field]: value }

      setValues(newValues)
      handleUpdateDocument({ documentMiscSetting: newValues })
    },
    [handleUpdateDocument, values]
  )

  const handleOgpImageChange = useCallback(
    (file?: File) => {
      handleUpdateDocumentOgpFile(file)
    },
    [handleUpdateDocumentOgpFile]
  )

  const configItems = useMemo<SettingToggleSectionCardProps[]>(
    () => [
      {
        title: '共有リンクボタンの表示',
        description: '閲覧者が資料を第三者に共有することができるボタンを表示します',
        checked: values.isShowShareButton,
        name: 'isShowShareButton',
        onChange: () => handleToggleOnlyOpened('isShowShareButton')
      },
      {
        title: 'SNSシェアボタンの表示',
        description: '閲覧者がSNS（Facebook、Twitter、LINE、note）に投稿するためのリンクを表示することができます',
        checked: values.isShowSnsButton,
        name: 'isShowSnsButton',
        onChange: () => handleToggleOnlyOpened('isShowSnsButton')
      },
      {
        title: '検索エンジンへの公開',
        description: '資料がGoogle等の検索エンジンの登録対象になります',
        checked: values.isPublicSearchEngine,
        name: 'isPublicSearchEngine',
        onChange: () => handleToggleOnlyOpened('isPublicSearchEngine')
      }
    ],
    [handleToggleOnlyOpened, values]
  )

  const alertShareData: Omit<ModalAlertShareProps, 'open' | 'onClose'> = useMemo(
    () => ({
      title: '設定を有効にできません',
      description: `資料「${document?.title}」の「リンクの共有」がOFFであるため、設定を有効にできません`,
      infoList: [
        {
          question: '設定を有効にするには？',
          answerList: [
            {
              label: '資料の「リンクの共有」をONにする必要があります'
            }
          ]
        }
      ],
      image: '/images/alert-share/document-restricted-to-open.svg'
    }),
    [document?.title]
  )

  const [downloadButtonConfirmOpen, setDownloadButtonConfirmOpen] = useState(false)

  const handleChangeDownloadButton = useCallback(() => {
    // MEMO: @snuffy canDownloadMaterial フラグは暫定的に有料プランかどうかの判定として使う
    // ref: https://nocoinc.slack.com/archives/C02QXBK1ZNY/p1655195200339599?thread_ts=1655194748.440219&cid=C02QXBK1ZNY
    if (!planGrade?.plan.canDownloadMaterial) {
      setOpenModalUpgrade(true)
      return
    }

    if (!values.isShowDownloadButton && !downloadButtonConfirmOpen) {
      setDownloadButtonConfirmOpen(true)
      return
    }

    handleToggle('isShowDownloadButton')
    setDownloadButtonConfirmOpen(false)
  }, [planGrade?.plan.canDownloadMaterial, values.isShowDownloadButton, downloadButtonConfirmOpen, handleToggle])

  const paidMenu = useMemo<SettingToggleSectionCardProps[]>(
    () => [
      {
        title: '印刷用ボタンの表示',
        description: '閲覧者に資料の印刷を許可します',
        checked: values.isShowPrintingButton,
        name: 'isShowPrintingButton',
        onChange: () => {
          // MEMO: @snuffy canDownloadMaterial フラグは暫定的に有料プランかどうかの判定として使う
          // ref: https://nocoinc.slack.com/archives/C02QXBK1ZNY/p1655195200339599?thread_ts=1655194748.440219&cid=C02QXBK1ZNY
          if (!planGrade?.plan.canDownloadMaterial) {
            setOpenModalUpgrade(true)
            return
          }
          handleToggle('isShowPrintingButton')
        }
      },
      {
        title: '資料ダウンロードボタンの表示',
        description: '閲覧者に資料のダウンロード画面の表示を許可します',
        checked: values.isShowDownloadButton,
        name: 'isShowDownloadButton',
        onChange: handleChangeDownloadButton
      }
    ],
    [
      values.isShowPrintingButton,
      values.isShowDownloadButton,
      handleChangeDownloadButton,
      planGrade?.plan.canDownloadMaterial,
      handleToggle
    ]
  )

  return (
    <>
      <Box mb="36px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color={Colors.base.middleGray}
          mb="8px"
          pl="8px"
          pr="8px"
        >
          <Box display="flex" alignItems="center">
            <DynamicMuiIcon variant="setting" size="20px" />
            <Box pl="8px" color={Colors.base.black}>
              <Typography fontSize="s" lineheight="1" fontWeight="bold">
                設定
              </Typography>
            </Box>
          </Box>
          <ButtonBase onClick={onClose}>
            <DynamicMuiIcon variant="close" size="20px" />
          </ButtonBase>
        </Box>
        {configItems.map((item, i) => (
          <Box key={`config_${i}`} mb="16px">
            <SettingToggleSectionCard {...item} />
          </Box>
        ))}
        <OgpSetting
          title={documentMiscSetting?.ogpTitle ?? undefined}
          description={documentMiscSetting?.ogpDescription ?? undefined}
          imageUrl={documentMiscSetting?.ogpFile?.url ?? undefined}
          onChange={handleOgpSettingChange}
          onFileChange={handleOgpImageChange}
        />
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color={Colors.base.middleGray}
          mb="8px"
          pl="8px"
          pr="8px"
        >
          <Box color={Colors.base.black}>
            <Typography fontSize="s" lineheight="1" fontWeight="bold">
              有料プラン
            </Typography>
          </Box>
          <ButtonBase
            onClick={() => {
              window.open('/setting/team/pricing', '_blank')
            }}
          >
            <Box display="flex" alignItems="center" color={Colors.accent.keyBlue.default}>
              <DynamicMuiIcon variant="openNew" size="20px" />
              <Box ml="6px" />
              <Typography fontSize="s" lineheight="1" fontWeight="bold">
                プランを見る
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
        {paidMenu.map((item, i) => (
          <Box key={`paid_${i}`} mb="16px">
            <SettingToggleSectionCard {...item} />
          </Box>
        ))}
      </Box>

      <ModalDocumentDownloadButtonConfirm
        open={downloadButtonConfirmOpen}
        onSubmit={handleChangeDownloadButton}
        onClose={() => setDownloadButtonConfirmOpen(false)}
      />

      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、資料ダウンロード・印刷の設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText=""
        onClose={() => setOpenModalUpgrade(false)}
      />

      <ModalAlertShare open={openAlert} onClose={() => setOpenAlert(false)} {...alertShareData} />
    </>
  )
}
