import React from 'react'
import { Box } from '@material-ui/core'
import { Button } from 'src/components/atoms'

export type ImportFileProps = {
  clearFile: () => void
  onSubmit: () => void
}

export const ImportFile = ({ clearFile, onSubmit }: ImportFileProps) => {
  return (
    <Box display="flex" ml="32px">
      <Button
        kind="primary"
        variant="outlined"
        title="キャンセル"
        width="104px"
        typography={{
          fontSize: 's',
          fontWeight: 600,
          lineheight: '14px'
        }}
        onClick={clearFile}
      />
      <Box ml="16px" />
      <Button
        kind="primary"
        title="インポートを実行"
        width="144px"
        typography={{
          fontSize: 's',
          fontWeight: 600,
          lineheight: '14px'
        }}
        onClick={onSubmit}
      />
    </Box>
  )
}
