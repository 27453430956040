/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentCardInfo,
  OrganizationPaymentCardInfoFromJSON,
  OrganizationPaymentCardInfoFromJSONTyped,
  OrganizationPaymentCardInfoToJSON
} from './OrganizationPaymentCardInfo'

/**
 *
 * @export
 * @interface OrganizationPaymentContract
 */
export interface OrganizationPaymentContract {
  /**
   *
   * @type {Date}
   * @memberof OrganizationPaymentContract
   */
  startAt: Date
  /**
   *
   * @type {Date}
   * @memberof OrganizationPaymentContract
   */
  endAt: Date
  /**
   *
   * @type {Date}
   * @memberof OrganizationPaymentContract
   */
  nextBillingAt: Date
  /**
   *
   * @type {OrganizationPaymentCardInfo}
   * @memberof OrganizationPaymentContract
   */
  card: OrganizationPaymentCardInfo
}

export function OrganizationPaymentContractFromJSON(json: any): OrganizationPaymentContract {
  return OrganizationPaymentContractFromJSONTyped(json, false)
}

export function OrganizationPaymentContractFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPaymentContract {
  if (json === undefined || json === null) {
    return json
  }
  return {
    startAt: new Date(json['start_at']),
    endAt: new Date(json['end_at']),
    nextBillingAt: new Date(json['next_billing_at']),
    card: OrganizationPaymentCardInfoFromJSON(json['card'])
  }
}

export function OrganizationPaymentContractToJSON(value?: OrganizationPaymentContract | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    start_at: value.startAt.toISOString(),
    end_at: value.endAt.toISOString(),
    next_billing_at: value.nextBillingAt.toISOString(),
    card: OrganizationPaymentCardInfoToJSON(value.card)
  }
}
