import { Box, createStyles, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Colors, DynamicMuiIcon, SelectBox, SvgIcon, Tooltip, Typography } from 'src/components/atoms'
import { ShareLinkFormSection } from 'src/components/molecules/ShareLinkFormSection'
import { useListSelectorUsersList } from 'src/fixtures/modules/selector/hooks'

export interface ShareLinkFormUserSelectSectionProps {
  value: string
  onChange: (userId: string) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    avatarImg: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    }
  })
})

export const ShareLinkFormUserSelectSection = ({ value, onChange }: ShareLinkFormUserSelectSectionProps) => {
  const { data: selectorUserRes } = useListSelectorUsersList({ page: 1, per: 9999 })
  const selectorUserList = selectorUserRes?.selectorItems
  const classes = useStyles()
  const userOptionList = useMemo(() => {
    return (
      selectorUserList?.map(item => ({
        value: item.id!,
        label: item.text,
        startElement: (
          <Box width="20px" height="20px" mr="4px" position="relative">
            {item.extend?.avatar ? (
              <img src={item.extend.avatar.url} className={classes.avatarImg} />
            ) : (
              <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
            )}
          </Box>
        )
      })) || []
    )
  }, [selectorUserList, classes.avatarImg])
  return (
    <ShareLinkFormSection>
      <Box display="flex" alignItems="center" mb="8px">
        <Typography variant="h6" fontSize="s" lineheight="14px">
          営業担当者・お問い合わせ担当者の設定
        </Typography>
        <Box mr="6px" />
        <Tooltip content={'顧客の担当者を設定します。'}>
          <DynamicMuiIcon variant="helpRounded" color="action" size="16px" />
        </Tooltip>
      </Box>
      <Box width={0.5}>
        <SelectBox
          name="userId"
          placeholder="選択してください"
          value={value}
          onChange={e => onChange(e.target.value as string)}
          height="34px"
          optionList={userOptionList}
        />
      </Box>
    </ShareLinkFormSection>
  )
}
