import React from 'react'
import Link from 'next/link'
import { Button, ButtonProps } from '..'

export type LinkButtonProps = {
  href: string
} & ButtonProps

export const LinkButton = ({ href, ...rest }: LinkButtonProps) => {
  return (
    <Link href={href}>
      <Button {...rest} />
    </Link>
  )
}
