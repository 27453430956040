/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatMessageAttachmentFileFileFromJSON, ChatMessageAttachmentFileFileToJSON } from './ChatMessageAttachmentFileFile';
export function ChatMessageAttachmentFileFromJSON(json) {
    return ChatMessageAttachmentFileFromJSONTyped(json, false);
}
export function ChatMessageAttachmentFileFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json['id'],
        organizationId: json['organization_id'],
        userId: json['user_id'],
        createdAt: json['created_at'],
        updatedAt: json['updated_at'],
        file: ChatMessageAttachmentFileFileFromJSON(json['file'])
    };
}
export function ChatMessageAttachmentFileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        organization_id: value.organizationId,
        user_id: value.userId,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        file: ChatMessageAttachmentFileFileToJSON(value.file)
    };
}
