import React, { useCallback } from 'react'
import { Box, Link } from '@material-ui/core'
import { Button, Typography } from 'src/components/atoms'

interface ExportCompleteProps {
  mode: 'company' | 'contact'
  outputFileName?: string | null
  fileUrl: string
  onClick: () => void
  onChangeList: (value: string) => void
}

export const ExportComplete = ({ mode, outputFileName = '', fileUrl, onClick, onChangeList }: ExportCompleteProps) => {
  const handleClick = useCallback(() => {
    onClick()
    onChangeList('')
  }, [onClick, onChangeList])
  return (
    <>
      <Typography fontSize="s" fontWeight={600} lineheight="14px">
        {outputFileName}
      </Typography>
      <Box mt="16px" />
      <Link href={fileUrl} download>
        <Button kind="primary" title="Excelファイルをダウンロード" />
      </Link>
      <Box mt="16px">
        <Button
          kind="primary"
          variant="outlined"
          title={`別の${mode === 'company' ? '会社' : '連絡先'}リストをエクスポート`}
          onClick={handleClick}
        />
      </Box>
    </>
  )
}
