/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateContactMemoRequestContactMemoFromJSON, CreateContactMemoRequestContactMemoToJSON } from './CreateContactMemoRequestContactMemo';
export function CreateContactMemoRequestFromJSON(json) {
    return CreateContactMemoRequestFromJSONTyped(json, false);
}
export function CreateContactMemoRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        contactMemo: !exists(json, 'contact_memo')
            ? undefined
            : CreateContactMemoRequestContactMemoFromJSON(json['contact_memo'])
    };
}
export function CreateContactMemoRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        contact_memo: CreateContactMemoRequestContactMemoToJSON(value.contactMemo)
    };
}
