/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { InboxFilter, InboxFilterFromJSON, InboxFilterFromJSONTyped, InboxFilterToJSON } from './InboxFilter'

/**
 *
 * @export
 * @interface GetInboxFilterResponseData
 */
export interface GetInboxFilterResponseData {
  /**
   *
   * @type {InboxFilter}
   * @memberof GetInboxFilterResponseData
   */
  filter?: InboxFilter
}

export function GetInboxFilterResponseDataFromJSON(json: any): GetInboxFilterResponseData {
  return GetInboxFilterResponseDataFromJSONTyped(json, false)
}

export function GetInboxFilterResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetInboxFilterResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    filter: !exists(json, 'filter') ? undefined : InboxFilterFromJSON(json['filter'])
  }
}

export function GetInboxFilterResponseDataToJSON(value?: GetInboxFilterResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    filter: InboxFilterToJSON(value.filter)
  }
}
