import { Box } from '@material-ui/core'
import React from 'react'
import { Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

export interface RateOfViewedByContactFromEmailCellProps {
  value: number
}

export const RateOfViewedByContactFromEmailCell = ({ value }: RateOfViewedByContactFromEmailCellProps) => {
  const globalClasses = useGlobalStyles()

  return (
    <Box display="flex" alignItems="center">
      <Box width={1} whiteSpace="pre-wrap" className={globalClasses.lineclamp1}>
        <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
          {Math.round((value || 0) * 100)}%
        </Typography>
      </Box>
    </Box>
  )
}
