/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function DocumentSlideImageLinkFromJSON(json) {
    return DocumentSlideImageLinkFromJSONTyped(json, false);
}
export function DocumentSlideImageLinkFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        uri: !exists(json, 'uri') ? undefined : json['uri'],
        rectRatioTop: !exists(json, 'rect_ratio_top') ? undefined : json['rect_ratio_top'],
        rectRatioLeft: !exists(json, 'rect_ratio_left') ? undefined : json['rect_ratio_left'],
        rectRatioRight: !exists(json, 'rect_ratio_right') ? undefined : json['rect_ratio_right'],
        rectRatioBottom: !exists(json, 'rect_ratio_bottom') ? undefined : json['rect_ratio_bottom']
    };
}
export function DocumentSlideImageLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        uri: value.uri,
        rect_ratio_top: value.rectRatioTop,
        rect_ratio_left: value.rectRatioLeft,
        rect_ratio_right: value.rectRatioRight,
        rect_ratio_bottom: value.rectRatioBottom
    };
}
