/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RemoveCompanyListRequest
 */
export interface RemoveCompanyListRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof RemoveCompanyListRequest
   */
  companyIds?: Array<string>
}

export function RemoveCompanyListRequestFromJSON(json: any): RemoveCompanyListRequest {
  return RemoveCompanyListRequestFromJSONTyped(json, false)
}

export function RemoveCompanyListRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveCompanyListRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companyIds: !exists(json, 'company_ids') ? undefined : json['company_ids']
  }
}

export function RemoveCompanyListRequestToJSON(value?: RemoveCompanyListRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_ids: value.companyIds
  }
}
