/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationPaymentCancelResponseData,
  UpdateOrganizationPaymentCancelResponseDataFromJSON,
  UpdateOrganizationPaymentCancelResponseDataFromJSONTyped,
  UpdateOrganizationPaymentCancelResponseDataToJSON
} from './UpdateOrganizationPaymentCancelResponseData'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentCancelResponse
 */
export interface UpdateOrganizationPaymentCancelResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateOrganizationPaymentCancelResponse
   */
  status: number
  /**
   *
   * @type {UpdateOrganizationPaymentCancelResponseData}
   * @memberof UpdateOrganizationPaymentCancelResponse
   */
  data: UpdateOrganizationPaymentCancelResponseData
}

export function UpdateOrganizationPaymentCancelResponseFromJSON(json: any): UpdateOrganizationPaymentCancelResponse {
  return UpdateOrganizationPaymentCancelResponseFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentCancelResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentCancelResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: UpdateOrganizationPaymentCancelResponseDataFromJSON(json['data'])
  }
}

export function UpdateOrganizationPaymentCancelResponseToJSON(
  value?: UpdateOrganizationPaymentCancelResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: UpdateOrganizationPaymentCancelResponseDataToJSON(value.data)
  }
}
