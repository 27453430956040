import { useMemo } from 'react'
import { Box, createStyles, FormControl, makeStyles, Select, MenuItem, MenuProps } from '@material-ui/core'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { Colors, Pallete } from '../Colors'
import { useGetOrganizationLabelSettingDocumentList } from '../../../fixtures/modules/organization/hooks'
import { DocumentLabelItem } from '../DocumentLabelItem'

export interface DocumentLabelSelectProps {
  value?: string
  name?: string
  onChange: SelectInputProps['onChange']
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      background: `${Pallete.functional.background.default}`,
      '& fieldset': {
        borderRadius: '4px',
        border: `1px solid ${Colors.background.silver}`
      },
      '& .MuiOutlinedInput-root': {
        height: '100%',
        '&.Mui-focused fieldset': {
          border: `1px solid ${Colors.base.black}`
        },
        '&:hover fieldset': {
          border: `1px solid ${Colors.base.black}`
        },
        '&.Mui-disabled': {
          background: Colors.background.gray
        }
      }
    },
    selectRoot: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: '12px !important',
      '&.MuiSelect-select:focus': {
        background: `${Pallete.functional.background.default}`
      }
    },
    menuRoot: {
      '&.MuiListItem-root': {
        minHeight: 0,
        zIndex: 9999,
        height: '34px',
        '&.Mui-selected': {
          background: `${Colors.background.lightGray}`
        },
        '&.Mui-focusVisible': {
          background: `${Colors.background.lightGray}`
        },
        '&.Mui-selected:hover': {
          background: `${Colors.background.lightGray}`
        },
        '&:hover': {
          background: `${Colors.background.lightGray}`
        },
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px'
        }
      }
    }
  })
)

export const DocumentLabelSelect: React.VFC<DocumentLabelSelectProps> = props => {
  const { value, name, onChange } = props
  const classes = useStyles(props)
  const { data: label } = useGetOrganizationLabelSettingDocumentList()

  const menuProps: Partial<MenuProps> = useMemo(() => {
    return {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      getContentAnchorEl: null,
      marginThreshold: 12,
      PaperProps: {
        style: {
          marginTop: '7px',
          border: `1px solid ${Colors.accent.keyPurple.default}`,
          maxHeight: '216px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)'
        }
      }
    }
  }, [])

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth>
      <Select
        classes={{ root: classes.selectRoot }}
        placeholder=""
        value={value}
        onChange={onChange}
        displayEmpty
        MenuProps={menuProps}
        inputProps={{
          name: name,
          id: `${name}-label`,
          'aria-label': 'Without label'
        }}
      >
        {value === '' && (
          <MenuItem value="" classes={{ root: classes.menuRoot }}>
            <Box display="flex" alignItems="center" py="5px">
              選択してください
            </Box>
          </MenuItem>
        )}

        {label?.documentLabels.map(name => (
          <MenuItem key={name} value={name} classes={{ root: classes.menuRoot }}>
            <Box display="flex" alignItems="center" py="5px">
              <DocumentLabelItem value={name} />
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
