/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function AuthVisitorAuthenticationCodeRequestWithEmailAddressRequestFromJSON(json) {
    return AuthVisitorAuthenticationCodeRequestWithEmailAddressRequestFromJSONTyped(json, false);
}
export function AuthVisitorAuthenticationCodeRequestWithEmailAddressRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
        siteId: !exists(json, 'site_id') ? undefined : json['site_id'],
        emailAddress: json['email_address']
    };
}
export function AuthVisitorAuthenticationCodeRequestWithEmailAddressRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document_id: value.documentId,
        site_id: value.siteId,
        email_address: value.emailAddress
    };
}
