import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { SelectContact } from 'src/components/organisms'

interface SelectProps {
  onChange: (contactList: string[]) => void
}

export const Select = ({ onChange }: SelectProps) => {
  return (
    <Box px="24px" pt="16px" pb="70px">
      <Typography variant="h5">名前</Typography>
      <Box height="8px" />
      <SelectContact onChange={onChange} />
    </Box>
  )
}
