/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetCompanyListsResponseData,
  GetCompanyListsResponseDataFromJSON,
  GetCompanyListsResponseDataFromJSONTyped,
  GetCompanyListsResponseDataToJSON
} from './GetCompanyListsResponseData'

/**
 *
 * @export
 * @interface GetCompanyListsResponse
 */
export interface GetCompanyListsResponse {
  /**
   *
   * @type {number}
   * @memberof GetCompanyListsResponse
   */
  status?: number
  /**
   *
   * @type {GetCompanyListsResponseData}
   * @memberof GetCompanyListsResponse
   */
  data?: GetCompanyListsResponseData
}

export function GetCompanyListsResponseFromJSON(json: any): GetCompanyListsResponse {
  return GetCompanyListsResponseFromJSONTyped(json, false)
}

export function GetCompanyListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyListsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetCompanyListsResponseDataFromJSON(json['data'])
  }
}

export function GetCompanyListsResponseToJSON(value?: GetCompanyListsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetCompanyListsResponseDataToJSON(value.data)
  }
}
