/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { BaseFilter, BaseFilterFromJSON, BaseFilterFromJSONTyped, BaseFilterToJSON } from './BaseFilter'
import {
  ContactFilterAllOf,
  ContactFilterAllOfFromJSON,
  ContactFilterAllOfFromJSONTyped,
  ContactFilterAllOfToJSON
} from './ContactFilterAllOf'
import {
  FilterBasicResourceList,
  FilterBasicResourceListFromJSON,
  FilterBasicResourceListFromJSONTyped,
  FilterBasicResourceListToJSON
} from './FilterBasicResourceList'
import { FilterCounts, FilterCountsFromJSON, FilterCountsFromJSONTyped, FilterCountsToJSON } from './FilterCounts'
import {
  FilterMaterialObject,
  FilterMaterialObjectFromJSON,
  FilterMaterialObjectFromJSONTyped,
  FilterMaterialObjectToJSON
} from './FilterMaterialObject'
import { FilterStaff, FilterStaffFromJSON, FilterStaffFromJSONTyped, FilterStaffToJSON } from './FilterStaff'

/**
 *
 * @export
 * @interface ContactFilter
 */
export interface ContactFilter {
  /**
   *
   * @type {FilterCounts}
   * @memberof ContactFilter
   */
  normal?: FilterCounts
  /**
   *
   * @type {FilterCounts}
   * @memberof ContactFilter
   */
  archived?: FilterCounts
  /**
   *
   * @type {FilterCounts}
   * @memberof ContactFilter
   */
  duplicated?: FilterCounts
  /**
   *
   * @type {FilterStaff}
   * @memberof ContactFilter
   */
  staffList?: FilterStaff
  /**
   *
   * @type {FilterMaterialObject}
   * @memberof ContactFilter
   */
  material?: FilterMaterialObject
  /**
   *
   * @type {FilterBasicResourceList}
   * @memberof ContactFilter
   */
  contactList?: FilterBasicResourceList
}

export function ContactFilterFromJSON(json: any): ContactFilter {
  return ContactFilterFromJSONTyped(json, false)
}

export function ContactFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactFilter {
  if (json === undefined || json === null) {
    return json
  }
  return {
    normal: !exists(json, 'normal') ? undefined : FilterCountsFromJSON(json['normal']),
    archived: !exists(json, 'archived') ? undefined : FilterCountsFromJSON(json['archived']),
    duplicated: !exists(json, 'duplicated') ? undefined : FilterCountsFromJSON(json['duplicated']),
    staffList: !exists(json, 'staff_list') ? undefined : FilterStaffFromJSON(json['staff_list']),
    material: !exists(json, 'material') ? undefined : FilterMaterialObjectFromJSON(json['material']),
    contactList: !exists(json, 'contact_list') ? undefined : FilterBasicResourceListFromJSON(json['contact_list'])
  }
}

export function ContactFilterToJSON(value?: ContactFilter | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    normal: FilterCountsToJSON(value.normal),
    archived: FilterCountsToJSON(value.archived),
    duplicated: FilterCountsToJSON(value.duplicated),
    staff_list: FilterStaffToJSON(value.staffList),
    material: FilterMaterialObjectToJSON(value.material),
    contact_list: FilterBasicResourceListToJSON(value.contactList)
  }
}
