import React, { useState, useCallback } from 'react'
import { Box, ButtonBase, Popover } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { format } from 'date-fns'
import { DateRangePicker, Props } from 'src/components/atoms/DateRangePicker'

export interface CalenderInputProps {
  value: Props
  onChange: (value: Props['dateRange'] | []) => void
}

export const CalenderInput = ({ value, onChange }: CalenderInputProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const openingCalendar = Boolean(anchorEl)
  const id = openingCalendar ? 'simple-popover' : undefined

  const onCalendarOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const onCalendarClose = useCallback(
    (inputValue: Props['dateRange'] | [], type?: string) => () => {
      const blankDate = { startDate: undefined, endDate: undefined, key: 'selection' }
      setAnchorEl(null)
      onChange(inputValue)
      if (type === 'close') {
        value.setDateRange(blankDate)
      }
    },
    [onChange, value]
  )

  return (
    <>
      <Box
        height="34px"
        border={`1px solid ${Colors.background.silver}`}
        borderRadius="4px"
        pl="14px"
        pr="4px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        boxShadow="inset 0px 1px 2px rgba(10, 10, 10, 0.1)"
      >
        <Box display="flex" alignItems="center" width="100%" onClick={onCalendarOpen}>
          <Box color={Colors.base.middleGray} display="flex" alignItems="center" mr="8px">
            <DynamicMuiIcon variant="meeting" size="20px" color="inherit" fontSize="inherit" />
          </Box>
          <Typography fontSize="s" lineheight="14px">
            {value.dateRange.startDate && value.dateRange.endDate
              ? `${format(value.dateRange.startDate, 'yyyy/MM/dd')}〜 ${format(value.dateRange.endDate, 'yyyy/MM/dd')}`
              : ''}
          </Typography>
        </Box>

        <Box color={Colors.base.middleGray} ml="10px">
          {value.dateRange.startDate && value.dateRange.endDate && (
            <ButtonBase onClick={onCalendarClose([], 'close')}>
              <DynamicMuiIcon variant="close" size="20px" color="inherit" fontSize="inherit" />
            </ButtonBase>
          )}
        </Box>
      </Box>
      <Popover
        id={id}
        open={openingCalendar}
        anchorEl={anchorEl}
        onClose={onCalendarClose(value.dateRange)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box p="24px">
          <DateRangePicker
            dateRange={value.dateRange}
            setDateRange={value.setDateRange}
            onChangeDateRange={value.onChangeDateRange}
          />
        </Box>
      </Popover>
    </>
  )
}
