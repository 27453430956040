/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  MaterialableViewHistory,
  MaterialableViewHistoryFromJSON,
  MaterialableViewHistoryFromJSONTyped,
  MaterialableViewHistoryToJSON
} from './MaterialableViewHistory'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetDocumentViewHistoriesResponseData
 */
export interface GetDocumentViewHistoriesResponseData {
  /**
   *
   * @type {Array<MaterialableViewHistory>}
   * @memberof GetDocumentViewHistoriesResponseData
   */
  viewHistories?: Array<MaterialableViewHistory>
  /**
   *
   * @type {PageInfo}
   * @memberof GetDocumentViewHistoriesResponseData
   */
  pageInfo?: PageInfo
}

export function GetDocumentViewHistoriesResponseDataFromJSON(json: any): GetDocumentViewHistoriesResponseData {
  return GetDocumentViewHistoriesResponseDataFromJSONTyped(json, false)
}

export function GetDocumentViewHistoriesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentViewHistoriesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    viewHistories: !exists(json, 'view_histories')
      ? undefined
      : (json['view_histories'] as Array<any>).map(MaterialableViewHistoryFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetDocumentViewHistoriesResponseDataToJSON(value?: GetDocumentViewHistoriesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    view_histories:
      value.viewHistories === undefined
        ? undefined
        : (value.viewHistories as Array<any>).map(MaterialableViewHistoryToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
