import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CheckList = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2646 3.10937C17.2646 2.65104 16.8896 2.27604 16.4313 2.27604H10.5979C10.1396 2.27604 9.76458 2.65104 9.76458 3.10937C9.76458 3.5677 10.1396 3.9427 10.5979 3.9427H16.4313C16.8896 3.9427 17.2646 3.5677 17.2646 3.10937ZM9.76458 9.77604C9.76458 10.2344 10.1396 10.6094 10.5979 10.6094H16.4313C16.8896 10.6094 17.2646 10.2344 17.2646 9.77604C17.2646 9.3177 16.8896 8.9427 16.4313 8.9427H10.5979C10.1396 8.9427 9.76458 9.3177 9.76458 9.77604ZM7.65625 0.301038C7.98125 0.626038 7.98125 1.15104 7.65625 1.47604L4.13125 5.0177C3.80625 5.3427 3.28125 5.3427 2.94792 5.0177L1.18125 3.2427C0.85625 2.9177 0.85625 2.3927 1.18125 2.0677C1.50625 1.7427 2.03125 1.7427 2.35625 2.0677L3.53958 3.25104L6.48958 0.301038C6.80625 -0.0156291 7.33958 -0.0156291 7.65625 0.301038ZM7.66458 6.97604C7.98958 7.30104 7.98958 7.82604 7.66458 8.15104L4.13958 11.6927C3.81458 12.0177 3.28958 12.0177 2.95625 11.6927L1.18125 9.90937C0.85625 9.58437 0.85625 9.05937 1.18125 8.73437C1.50625 8.40937 2.03125 8.40937 2.35625 8.73437L3.53958 9.9177L6.48958 6.9677C6.80625 6.65104 7.33958 6.65104 7.66458 6.97604Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default CheckList
