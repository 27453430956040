/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { IndexSiteFromJSON, IndexSiteToJSON } from './IndexSite';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetSitesResponseDataFromJSON(json) {
    return GetSitesResponseDataFromJSONTyped(json, false);
}
export function GetSitesResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        sites: !exists(json, 'sites') ? undefined : json['sites'].map(IndexSiteFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetSitesResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        sites: value.sites === undefined ? undefined : value.sites.map(IndexSiteToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
