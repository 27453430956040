import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Share = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9998 5.26348H10.6665C10.2998 5.26348 9.99984 5.56348 9.99984 5.93015C9.99984 6.29682 10.2998 6.59682 10.6665 6.59682H11.9998V13.9301H3.99984V6.59682H5.33317C5.69984 6.59682 5.99984 6.29682 5.99984 5.93015C5.99984 5.56348 5.69984 5.26348 5.33317 5.26348H3.99984C3.2665 5.26348 2.6665 5.86348 2.6665 6.59682V13.9301C2.6665 14.6635 3.2665 15.2635 3.99984 15.2635H11.9998C12.7332 15.2635 13.3332 14.6635 13.3332 13.9301V6.59682C13.3332 5.86348 12.7332 5.26348 11.9998 5.26348Z" />
      <path d="M7.99984 10.5968C8.3665 10.5968 8.6665 10.2968 8.6665 9.93015V3.26348H9.85984C10.1598 3.26348 10.3065 2.90348 10.0932 2.69682L8.23317 0.836816C8.09984 0.703483 7.89317 0.703483 7.75984 0.836816L5.89984 2.69682C5.69317 2.90348 5.83984 3.26348 6.13984 3.26348H7.33317V9.93015C7.33317 10.2968 7.63317 10.5968 7.99984 10.5968Z" />
    </SvgIcon>
  )
}
export default Share
