import React, { useMemo } from 'react'
import {
  Box,
  createStyles,
  makeStyles,
  Modal,
  Typography,
  ButtonBase,
  Grid,
  Slide,
  useMediaQuery
} from '@material-ui/core'
import { Button, Colors, SvgIcon } from 'src/components/atoms'
import { DocumentSlideImage } from '@noco/http-client/lib/noco'
import theme from 'src/styles/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16
    },
    closeButton: {
      padding: '22px'
    },
    menuDocumentList: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: 24
    },
    menuDocumentListItem: {
      width: '100%'
    }
  })
})

interface ModalDocumentPageSelectProps {
  open: boolean
  slideImages: DocumentSlideImage[]
  setShownPage: (page: null | number) => void
  onClose: () => void
}

export const ModalDocumentPageSelect = ({ open, setShownPage, onClose, slideImages }: ModalDocumentPageSelectProps) => {
  const classes = useStyles()
  const totalPage = slideImages?.length ?? 0
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  const renderMenuDocumentList = useMemo(() => {
    return Array.from(new Array(totalPage), (el, index) => {
      const pageNumber = index + 1
      const onChangePage = () => {
        setShownPage(pageNumber)
      }
      const slideImage = slideImages.find(item => item.numberOfPage === pageNumber)
      return (
        <Grid key={`page_${pageNumber}`} xs={4} md={3} spacing={2} alignItems="center">
          <ButtonBase onClick={onChangePage} style={{ width: '100%', height: '100%' }}>
            <Box
              display="flex"
              height="inherit"
              alignItems="center"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box
                bgcolor={Colors.background.lightGray}
                display="flex"
                height="100%"
                alignItems="center"
                margin="6px"
                borderRadius="4px"
              >
                <Box borderRadius="4px" border={`1px solid ${Colors.background.silver}`} overflow="hidden">
                  <img
                    className={classes.menuDocumentListItem}
                    src={String(slideImage?.file?.url)}
                    style={{ width: '100%', height: '100%', display: 'flex', objectFit: 'cover' }}
                  />
                </Box>
              </Box>

              <Box mt={1} mb={4} color={Colors.base.middleGray}>
                <Typography variant="h5">{index + 1}</Typography>
              </Box>
            </Box>
          </ButtonBase>
        </Grid>
      )
    })
  }, [classes, totalPage, slideImages, setShownPage])

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          width={[1, 0.5]}
          height={isDownSm ? 'auto' : 0.5}
          maxHeight={isDownSm ? 1 : 'auto'}
          display="flex"
          flexDirection="column"
          bgcolor={Colors.functional.background.default}
          borderRadius={10}
        >
          <Box
            height="60px"
            boxShadow={`inset 0px -1px 0px ${Colors.background.silver}`}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pl="24px"
            pr="8px"
          >
            <Typography variant="h3">ページ選択</Typography>
            <ButtonBase onClick={onClose} className={classes.closeButton}>
              <SvgIcon variant="close" size="16px" color={Colors.base.middleGray} />
            </ButtonBase>
          </Box>

          <Box height={1} minHeight={isDownSm ? '100px' : 'auto'} className={classes.menuDocumentList} overflow="auto">
            {renderMenuDocumentList}
          </Box>

          <Box
            boxShadow={`inset 0px 1px 0px ${Colors.background.gray}`}
            bgcolor={Colors.background.lightGray}
            height="58px"
            display="flex"
            justifyContent="end"
            alignItems="center"
            px="24px"
            borderRadius="0 0 10px 10px"
            flexShrink={0}
          >
            <Box display="flex" alignItems="center">
              <Button kind="neutral" variant="outlined" title="閉じる" onClick={onClose} />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
