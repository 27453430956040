/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetMaterialListsResponseData,
  GetMaterialListsResponseDataFromJSON,
  GetMaterialListsResponseDataFromJSONTyped,
  GetMaterialListsResponseDataToJSON
} from './GetMaterialListsResponseData'

/**
 *
 * @export
 * @interface GetMaterialListsResponse
 */
export interface GetMaterialListsResponse {
  /**
   *
   * @type {number}
   * @memberof GetMaterialListsResponse
   */
  status?: number
  /**
   *
   * @type {GetMaterialListsResponseData}
   * @memberof GetMaterialListsResponse
   */
  data?: GetMaterialListsResponseData
}

export function GetMaterialListsResponseFromJSON(json: any): GetMaterialListsResponse {
  return GetMaterialListsResponseFromJSONTyped(json, false)
}

export function GetMaterialListsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMaterialListsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetMaterialListsResponseDataFromJSON(json['data'])
  }
}

export function GetMaterialListsResponseToJSON(value?: GetMaterialListsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetMaterialListsResponseDataToJSON(value.data)
  }
}
