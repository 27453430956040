/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentContractBasicInfo,
  OrganizationPaymentContractBasicInfoFromJSON,
  OrganizationPaymentContractBasicInfoFromJSONTyped,
  OrganizationPaymentContractBasicInfoToJSON
} from './OrganizationPaymentContractBasicInfo'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentPlanFreeResponseData
 */
export interface UpdateOrganizationPaymentPlanFreeResponseData {
  /**
   *
   * @type {OrganizationPaymentContractBasicInfo}
   * @memberof UpdateOrganizationPaymentPlanFreeResponseData
   */
  contractBasicInfo: OrganizationPaymentContractBasicInfo
}

export function UpdateOrganizationPaymentPlanFreeResponseDataFromJSON(
  json: any
): UpdateOrganizationPaymentPlanFreeResponseData {
  return UpdateOrganizationPaymentPlanFreeResponseDataFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentPlanFreeResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentPlanFreeResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contractBasicInfo: OrganizationPaymentContractBasicInfoFromJSON(json['contract_basic_info'])
  }
}

export function UpdateOrganizationPaymentPlanFreeResponseDataToJSON(
  value?: UpdateOrganizationPaymentPlanFreeResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contract_basic_info: OrganizationPaymentContractBasicInfoToJSON(value.contractBasicInfo)
  }
}
