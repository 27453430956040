/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  DocumentMiscSetting,
  DocumentMiscSettingFromJSON,
  DocumentMiscSettingFromJSONTyped,
  DocumentMiscSettingToJSON
} from './DocumentMiscSetting'

/**
 *
 * @export
 * @interface GetDocumentMiscSettingResponseData
 */
export interface GetDocumentMiscSettingResponseData {
  /**
   *
   * @type {DocumentMiscSetting}
   * @memberof GetDocumentMiscSettingResponseData
   */
  documentMiscSetting?: DocumentMiscSetting
}

export function GetDocumentMiscSettingResponseDataFromJSON(json: any): GetDocumentMiscSettingResponseData {
  return GetDocumentMiscSettingResponseDataFromJSONTyped(json, false)
}

export function GetDocumentMiscSettingResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentMiscSettingResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentMiscSetting: !exists(json, 'document_misc_setting')
      ? undefined
      : DocumentMiscSettingFromJSON(json['document_misc_setting'])
  }
}

export function GetDocumentMiscSettingResponseDataToJSON(value?: GetDocumentMiscSettingResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_misc_setting: DocumentMiscSettingToJSON(value.documentMiscSetting)
  }
}
