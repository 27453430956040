/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentBillingTransaction,
  OrganizationPaymentBillingTransactionFromJSON,
  OrganizationPaymentBillingTransactionFromJSONTyped,
  OrganizationPaymentBillingTransactionToJSON
} from './OrganizationPaymentBillingTransaction'

/**
 *
 * @export
 * @interface GetOrganizationPaymentBillingTransactionsResponseData
 */
export interface GetOrganizationPaymentBillingTransactionsResponseData {
  /**
   *
   * @type {Array<OrganizationPaymentBillingTransaction>}
   * @memberof GetOrganizationPaymentBillingTransactionsResponseData
   */
  billingTransactions: Array<OrganizationPaymentBillingTransaction>
}

export function GetOrganizationPaymentBillingTransactionsResponseDataFromJSON(
  json: any
): GetOrganizationPaymentBillingTransactionsResponseData {
  return GetOrganizationPaymentBillingTransactionsResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationPaymentBillingTransactionsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentBillingTransactionsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    billingTransactions: (json['billing_transactions'] as Array<any>).map(OrganizationPaymentBillingTransactionFromJSON)
  }
}

export function GetOrganizationPaymentBillingTransactionsResponseDataToJSON(
  value?: GetOrganizationPaymentBillingTransactionsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    billing_transactions: (value.billingTransactions as Array<any>).map(OrganizationPaymentBillingTransactionToJSON)
  }
}
