/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ConfirmMeChangeEmailRequestToJSON, ConfirmMeChangeEmailResponseFromJSON, GetMeEmailNotificationSettingResponseFromJSON, GetMeProfileResponseFromJSON, GetMeResponseFromJSON, UpdateMeAvatarRequestToJSON, UpdateMeAvatarResponseFromJSON, UpdateMeEmailNotificationSettingRequestToJSON, UpdateMeEmailNotificationSettingResponseFromJSON, UpdateMePasswordRequestToJSON, UpdateMePasswordResponseFromJSON, UpdateMeProfileRequestToJSON, UpdateMeProfileResponseFromJSON, UpdateMeRequestToJSON, UpdateMeResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1MeApi extends runtime.BaseAPI {
    /**
     * ユーザーアイコンを設定する
     * update
     */
    async userV1MeAvatarPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/me/avatar`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeAvatarRequestToJSON(requestParameters.updateMeAvatarRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateMeAvatarResponseFromJSON(jsonValue));
    }
    /**
     * ユーザーアイコンを設定する
     * update
     */
    async userV1MeAvatarPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1MeAvatarPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * メールアドレスの変更を認証する
     * confirm
     */
    async userV1MeConfirmChangeEmailPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/me/confirm_change_email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmMeChangeEmailRequestToJSON(requestParameters.confirmMeChangeEmailRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ConfirmMeChangeEmailResponseFromJSON(jsonValue));
    }
    /**
     * メールアドレスの変更を認証する
     * confirm
     */
    async userV1MeConfirmChangeEmailPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1MeConfirmChangeEmailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * email通知設定情報を取得する
     * show
     */
    async userV1MeEmailNotificationSettingGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/me/email_notification_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetMeEmailNotificationSettingResponseFromJSON(jsonValue));
    }
    /**
     * email通知設定情報を取得する
     * show
     */
    async userV1MeEmailNotificationSettingGet(initOverrides) {
        const response = await this.userV1MeEmailNotificationSettingGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * email通知設定情報を設定する
     * update
     */
    async userV1MeEmailNotificationSettingPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/me/email_notification_setting`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeEmailNotificationSettingRequestToJSON(requestParameters.updateMeEmailNotificationSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateMeEmailNotificationSettingResponseFromJSON(jsonValue));
    }
    /**
     * email通知設定情報を設定する
     * update
     */
    async userV1MeEmailNotificationSettingPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1MeEmailNotificationSettingPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ログイン中のユーザ情報を取得する
     * show
     */
    async userV1MeGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetMeResponseFromJSON(jsonValue));
    }
    /**
     * ログイン中のユーザ情報を取得する
     * show
     */
    async userV1MeGet(initOverrides) {
        const response = await this.userV1MeGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * ログイン中のユーザ情報のパスワードを変更
     * password
     */
    async userV1MePasswordPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/me/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMePasswordRequestToJSON(requestParameters.updateMePasswordRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateMePasswordResponseFromJSON(jsonValue));
    }
    /**
     * ログイン中のユーザ情報のパスワードを変更
     * password
     */
    async userV1MePasswordPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1MePasswordPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * プロフィール情報を取得する
     * show
     */
    async userV1MeProfileGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/me/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetMeProfileResponseFromJSON(jsonValue));
    }
    /**
     * プロフィール情報を取得する
     * show
     */
    async userV1MeProfileGet(initOverrides) {
        const response = await this.userV1MeProfileGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * プロフィール情報を設定する
     * update
     */
    async userV1MeProfilePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/me/profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeProfileRequestToJSON(requestParameters.updateMeProfileRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateMeProfileResponseFromJSON(jsonValue));
    }
    /**
     * プロフィール情報を設定する
     * update
     */
    async userV1MeProfilePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1MeProfilePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ログイン中のユーザ情報を編集する
     * update
     */
    async userV1MePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeRequestToJSON(requestParameters.updateMeRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateMeResponseFromJSON(jsonValue));
    }
    /**
     * ログイン中のユーザ情報を編集する
     * update
     */
    async userV1MePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1MePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
