import React, { useMemo, useState, ChangeEvent } from 'react'
import {
  Box,
  createStyles,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import { Button, Colors, Pallete, SvgIcon, Typography, DocumentLabelItem, Tooltip } from 'src/components/atoms'
import {
  ExpandMore,
  OpenInNew,
  InsertDriveFile,
  RemoveRedEye,
  AccessTime,
  AssignmentInd,
  CheckCircle,
  ChatBubble,
  Computer,
  Edit,
  SignalCellularAlt
} from '@material-ui/icons'
import { ContactMaterial, EnumActivityStatus, EnumMaterialableType } from '@noco/http-client/lib/noco'
import { convertMs, DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { LinkIcon } from 'src/components/atoms/LinkIcon'
import { formatDistance, isBefore } from 'date-fns'
import { ExpiryBaseProps } from 'src/components/organisms/Table/TableParts'
import { useGetDocument } from 'src/fixtures/modules/documents/hooks'
import { ModalDocumentViewHistories } from 'src/components/modals/ModalDocumentViewHistories'
import { useGetContact } from 'src/fixtures/modules/contact/hooks'
import { ja } from 'date-fns/locale'
import clsx from 'clsx'

export interface ModalClientDetailProps {
  open: boolean
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
}

interface BrowsingHistoryItemProps {
  item: ContactMaterial
  size?: 'small' | 'medium'
  isExpired?: boolean
  onOpenUpdateExpiredOnModal?: (item: ContactMaterial) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      verticalAlign: 'middle',
      marginRight: 8
    },
    link: {
      color: Colors.accent.keyBlue.default,
      wordBreak: 'break-all'
    },
    pdfPreview: {
      position: 'relative',
      width: '100%',
      maxWidth: 600,
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      border: `1px solid ${Colors.background.gray}`,
      borderRadius: 4
    },
    smallAccordion: {
      position: 'relative',
      border: `1px solid ${Colors.background.silver}`,
      boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F',
      borderRadius: 4,
      '& .MuiAccordionSummary-root': {
        alignItems: 'start',
        padding: '0 14px'
      },
      '& .MuiAccordionDetails-root': {
        padding: '0 14px 14px'
      },
      '& .MuiIconButton-edgeEnd': {
        position: 'absolute',
        right: 14,
        top: 4,
        padding: 5
      },
      '& .MuiAccordionSummary-content': {
        margin: '14px 0 0',
        '& .documentTitle': {
          marginBottom: 16
        }
      },
      '& .MuiAccordion-root.Mui-expanded': {
        margin: 0
      },
      '& .summaryInfo': {
        paddingLeft: '2px',
        paddingBottom: '14px',
        '& .svgIconWrap .MuiSvgIcon-root': {
          fontSize: '16px !important',
          marginRight: '8px !important'
        }
      },
      '& .activityWrap': {
        width: '118px',
        paddingBottom: '8px'
      },
      '& .ctaChat': {
        width: '118px',
        paddingBottom: '12px'
      },
      '& .inboxStyle': {
        marginRight: '26px'
      },
      '& .browsingInfoTitle': {
        width: '100%'
      },
      '& .browsingInfo': {
        marginBottom: '20px',
        paddingTop: '18px',
        marginTop: '6px',
        borderTop: `1px solid ${Colors.background.silver}`
      }
    },
    accordion: {
      border: `1px solid ${Colors.background.silver}`,
      boxShadow: '0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F',
      borderRadius: 4,
      '& .MuiAccordionSummary-root': {
        padding: '0 16px'
      },
      '& .MuiAccordionDetails-root': {
        padding: '0 16px 16px'
      },
      '& .Mui-expanded': {
        minHeight: 'inherit'
      },
      '& .MuiAccordionSummary-content': {
        margin: '16px 0'
      },
      '& .MuiIconButton-edgeEnd': {
        position: 'absolute',
        right: 20,
        top: 8,
        padding: 5
      },
      '& .summaryInfo': {
        marginTop: '16px'
      },
      '& .svgIconWrap': {
        '& .MuiSvgIcon-root': {
          marginRight: '8px'
        }
      },
      '& .activityWrap': {
        width: '118px',
        marginRight: '32px',
        padding: '4px 0'
      },
      '& .ctaChat': {
        width: 'fit-content',
        marginRight: '32px',
        padding: '4px 0'
      },
      '& .browsingInfoContent': {
        width: 'calc(100% - 240px )'
      },
      '& .browsingInfoSiteContent': {
        width: 'calc(100% - 134px )'
      },
      '& .browsingInfo': {
        marginBottom: '32px',
        paddingTop: '20px',
        borderTop: `1px solid ${Colors.background.silver}`
      },
      '& .editIcon': {
        '& .MuiButton-startIcon': {
          margin: '0 4px 0 0'
        },
        '& .MuiSvgIcon-root': {
          fontSize: '16px'
        }
      }
    },
    actionMenu: { borderTop: `1px solid ${Colors.background.gray}`, height: 40 },
    button: {
      width: 'fit-content',
      '& .MuiButton-startIcon': {
        marginRight: '6px',
        marginLeft: '0'
      }
    },
    previewIcon: {
      height: '22px'
    }
  })
)

const getText = ({ canViewable, expiredOn }: ExpiryBaseProps) => {
  if (!canViewable) return '閲覧不可'
  else {
    return expiredOn ? dateFormat(dateStringToMilliseconds(expiredOn), DateFormat.DATE_WITH_DAY) : '閲覧可（期限なし）'
  }
}

const getTextColor = ({ canViewable, expiredOn }: ExpiryBaseProps) => {
  if (!canViewable || (!!expiredOn && isBefore(new Date(expiredOn), new Date()))) {
    return Colors.accent.keyRed.default
  } else if (!expiredOn) {
    return Colors.base.middleGray
  } else {
    return Colors.base.black
  }
}

export const BrowsingHistoryItem = ({
  item,
  isExpired = false,
  size = 'medium',
  onOpenUpdateExpiredOnModal
}: BrowsingHistoryItemProps) => {
  const [documentId, setDocumentId] = useState<string>()
  const classes = useStyles()
  const [shownPage, setShownPage] = useState<null | number>(1)
  const [isOpenViewHistories, setIsOpenViewHistories] = useState<boolean>(false)
  const { data: contactData } = useGetContact(item.contactId ?? '')
  const { data, isValidating } = useGetDocument(documentId)
  const totalPage = data?.currentSlide?.slideImages?.length ?? 0
  const currentSlide = useMemo(
    () => data?.currentSlide?.slideImages?.find(item => item.numberOfPage === shownPage),
    [shownPage, data?.currentSlide?.slideImages]
  )
  const onPrev = () => setShownPage((shownPage ?? 1) > 1 ? (shownPage ?? 1) - 1 : 1)
  const onNext = () => setShownPage((shownPage ?? 1) < totalPage ? (shownPage ?? 1) + 1 : shownPage)

  const elapsedTime = useMemo(() => {
    {
      const firstView = item.activityStatusObjects?.find(item => item.activityStatus === EnumActivityStatus.FirstView)
      if (!firstView || !item.invitedAt) return '-'
      return formatDistance(new Date(firstView.updatedAt!).getTime(), new Date(item.invitedAt!).getTime(), {
        locale: ja
      })
    }
  }, [item])

  const chekcedMap = useMemo(() => {
    const map: { [key in EnumActivityStatus]?: boolean } = {}
    item.activityStatusObjects?.forEach(item => {
      if (!item.activityStatus) return
      map[item.activityStatus] = !!item.updatedAt
    })
    return map
  }, [item.activityStatusObjects])

  const accordionSummaryContent = useMemo(() => {
    return (
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
        <Box width={1}>
          {size === 'medium' ? (
            <Box display="flex" width={1} justifyContent="space-between" alignItems="center">
              <Box display="flex" mr={2}>
                {item.materialableType === EnumMaterialableType.Document ? (
                  <InsertDriveFile
                    className={classes.icon}
                    fontSize="small"
                    htmlColor={Colors.accent.keyBlue.default}
                  />
                ) : (
                  <Computer className={classes.icon} fontSize="small" htmlColor={Colors.accent.keyBlue.default} />
                )}
                <Typography className={classes.link} variant="h5">
                  {item.displayName}
                </Typography>
              </Box>

              <Typography
                variant="subtitle2"
                style={{ color: Colors.base.middleGray, whiteSpace: 'nowrap', paddingRight: '24px' }}
              >
                {`閲覧日時 ${
                  item.viewingStatusObject?.latestViewedAt
                    ? dateFormat(
                        dateStringToMilliseconds(item.viewingStatusObject?.latestViewedAt),
                        DateFormat.DATE_WITH_TIME
                      )
                    : '-'
                }`}
              </Typography>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" width={1}>
              <Typography
                variant="subtitle2"
                style={{
                  color: Colors.base.middleGray,
                  whiteSpace: 'nowrap',
                  marginBottom: '10px',
                  paddingRight: '18px',
                  textAlign: 'right'
                }}
              >
                {`閲覧日時 ${
                  item.viewingStatusObject?.latestViewedAt
                    ? dateFormat(
                        dateStringToMilliseconds(item.viewingStatusObject?.latestViewedAt),
                        DateFormat.DATE_WITH_TIME
                      )
                    : '-'
                }`}
              </Typography>

              <Box display="flex" mr={2} className="documentTitle">
                {item.materialableType === EnumMaterialableType.Document ? (
                  <InsertDriveFile
                    className={classes.icon}
                    fontSize="small"
                    htmlColor={Colors.accent.keyBlue.default}
                  />
                ) : (
                  <Computer className={classes.icon} fontSize="small" htmlColor={Colors.accent.keyBlue.default} />
                )}
                <Typography className={classes.link} variant="h5">
                  {item.displayName}
                </Typography>
              </Box>
            </Box>
          )}
          {item.materialableType === EnumMaterialableType.Document ? (
            <Box className="summaryInfo">
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className={clsx('activityWrap', 'inboxStyle')}
                >
                  <Tooltip
                    content={
                      '顧客が資料を閲覧した回数です。 最後の閲覧時刻から30分経過後に、閲覧を再開すると、新しい閲覧履歴として表示されます。'
                    }
                  >
                    <Box display="flex" alignItems="center" className="svgIconWrap">
                      <RemoveRedEye className={classes.icon} fontSize="small" htmlColor={Colors.base.middleGray} />
                      <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray }}>
                        閲覧回数
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Typography fontSize="xs" lh="none">
                    {item.viewingStatusObject?.countOfView}回
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className="activityWrap">
                  <Tooltip content={'顧客が資料を閲覧した時間の合計です。'}>
                    <Box display="flex" alignItems="center" className="svgIconWrap">
                      <AccessTime className={classes.icon} fontSize="small" htmlColor={Colors.base.middleGray} />
                      <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray }}>
                        閲覧時間
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Typography fontSize="xs" lh="none">
                    {convertMs(item.viewingStatusObject?.secondsOfView ?? 0, true)}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={clsx('activityWrap', 'inboxStyle')}
                  >
                    <Tooltip content={'顧客が担当者情報を閲覧した場合に、チェックマークが表示されます。'}>
                      <Box display="flex" alignItems="center" className="svgIconWrap">
                        <AssignmentInd className={classes.icon} fontSize="small" htmlColor={Colors.base.middleGray} />
                        <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray }}>
                          担当者情報
                        </Typography>
                      </Box>
                    </Tooltip>
                    {chekcedMap[EnumActivityStatus.ViewProfile] ? (
                      <CheckCircle style={{ fontSize: '18px', color: `${Colors.accent.keyPurple.default}` }} />
                    ) : (
                      <Box color={Colors.background.silver}>-</Box>
                    )}
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="activityWrap">
                    <Tooltip content={'顧客がアポイント機能を利用した場合に、チェックマークが表示されます。'}>
                      <Box display="flex" alignItems="center" className="svgIconWrap">
                        <SvgIcon variant="editCalendar" size="20px" color={Colors.base.middleGray} />
                        <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray }}>
                          アポイント
                        </Typography>
                      </Box>
                    </Tooltip>
                    {chekcedMap[EnumActivityStatus.Opportunity] ? (
                      <CheckCircle style={{ fontSize: '18px', color: `${Colors.accent.keyPurple.default}` }} />
                    ) : (
                      <Box color={Colors.background.silver}>-</Box>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={clsx('ctaChat', 'inboxStyle')}
                  >
                    <Tooltip
                      content={
                        '顧客がCTA（ポップアップ形式のバナー）内に設置したボタンを押した場合に、チェックマークが表示されます。'
                      }
                    >
                      <Box display="flex" alignItems="center" mr="6px" className="svgIconWrap">
                        <SvgIcon variant="adsClick" size="20px" color={Colors.base.middleGray} />
                        <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray }}>
                          CTA
                        </Typography>
                      </Box>
                    </Tooltip>
                    {chekcedMap[EnumActivityStatus.Cta] ? (
                      <CheckCircle style={{ fontSize: '18px', color: `${Colors.accent.keyPurple.default}` }} />
                    ) : (
                      <Box color={Colors.background.silver}>-</Box>
                    )}
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" className="ctaChat">
                    <Tooltip content={'顧客がチャットを利用した場合に、チェックマークが表示されます。'}>
                      <Box display="flex" alignItems="center" mr="6px" className="svgIconWrap">
                        <ChatBubble className={classes.icon} fontSize="small" htmlColor={Colors.base.middleGray} />
                        <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray }}>
                          チャット
                        </Typography>
                      </Box>
                    </Tooltip>
                    {chekcedMap[EnumActivityStatus.Chat] ? (
                      <CheckCircle style={{ fontSize: '18px', color: `${Colors.accent.keyPurple.default}` }} />
                    ) : (
                      <Box color={Colors.background.silver}>-</Box>
                    )}
                  </Box>
                </Box>
                <Button
                  startIcon={<SignalCellularAlt />}
                  kind="secondary"
                  variant="outlined"
                  size="small"
                  title="閲覧履歴の分析"
                  classes={{ root: classes.button }}
                  onClick={() => setIsOpenViewHistories(true)}
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </AccordionSummary>
    )
  }, [
    size,
    item.materialableType,
    item.displayName,
    item.viewingStatusObject?.latestViewedAt,
    item.viewingStatusObject?.countOfView,
    item.viewingStatusObject?.secondsOfView,
    classes.icon,
    classes.link,
    classes.button,
    chekcedMap
  ])

  const onOpen = (_: ChangeEvent<{}>, expanded: boolean) => {
    if (expanded && item.materialableType === EnumMaterialableType.Document) {
      setDocumentId(item.materialableId)
    }
  }

  if (isExpired && onOpenUpdateExpiredOnModal) {
    return (
      <Box>
        <Box
          style={{
            backgroundColor: Colors.option.pink,
            padding: '4px 10px',
            textAlign: 'left',
            color: Pallete.functional.whiteText.default,
            fontSize: 12,
            fontWeight: 'bold',
            borderRadius: '4px 4px 0px 0px',
            margin: '0 8px'
          }}
        >
          設定した閲覧期限が切れています
        </Box>
        <Accordion className={size === 'medium' ? classes.accordion : classes.smallAccordion} onChange={onOpen}>
          {accordionSummaryContent}
          <AccordionDetails>
            <Box
              width={1}
              py={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pt="14px"
              borderTop={`1px solid ${Colors.background.gray}`}
            >
              <Box>
                <Box mb="8px" alignItems="center">
                  <Typography variant="subtitle2" lineheight="12px" style={{ color: '#6D7784' }}>
                    閲覧権限・期限
                  </Typography>
                </Box>
                <Typography variant="subtitle1" lineheight="14px" style={{ color: Colors.accent.keyRed.default }}>
                  {item.expiredOn ? dateFormat(dateStringToMilliseconds(item.expiredOn), DateFormat.DATE_WITH_DAY) : ''}
                </Typography>
              </Box>
              <Button
                startIcon={<Edit />}
                kind="secondary"
                variant="outlined"
                size="small"
                title="編集"
                className="editIcon"
                onClick={() => onOpenUpdateExpiredOnModal(item)}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }
  return (
    <Accordion className={size === 'medium' ? classes.accordion : classes.smallAccordion} onChange={onOpen}>
      {accordionSummaryContent}
      <AccordionDetails>
        {documentId && (
          <ModalDocumentViewHistories
            open={isOpenViewHistories}
            contact={contactData?.contact}
            documentId={documentId}
            onClose={() => setIsOpenViewHistories(false)}
          />
        )}
        {item.materialableType === EnumMaterialableType.Document ? (
          <Box width={1} pt={1} display="flex" flexDirection="column">
            <Box className="browsingInfo">
              <Box display="flex" justifyContent="space-between" alignItems="center" mb="4px">
                <Typography variant="h5">閲覧情報</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                py={3}
                borderBottom={`1px solid ${Colors.background.gray}`}
              >
                <Box width="240px" className="browsingInfoTitle">
                  <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray, padding: '4px 0' }}>
                    送信日時
                  </Typography>
                </Box>
                <Typography variant="subtitle1" style={{ wordBreak: 'break-all' }}>
                  {item.invitedAt
                    ? dateFormat(dateStringToMilliseconds(item.invitedAt), DateFormat.DATE_WITH_TIME)
                    : '-'}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                py={3}
                borderBottom={`1px solid ${Colors.background.gray}`}
              >
                <Box width="240px" className="browsingInfoTitle">
                  <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray, padding: '4px 0' }}>
                    送信日から初回閲覧までの経過時間
                  </Typography>
                </Box>
                <Typography variant="subtitle1">{elapsedTime}</Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                py={2}
                borderBottom={`1px solid ${Colors.background.gray}`}
              >
                <Box width="240px" className="browsingInfoTitle">
                  <Typography variant="subtitle2" style={{ color: Colors.base.middleGray, padding: '4px 0' }}>
                    閲覧権限・期限
                  </Typography>
                </Box>
                <Box
                  width={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className="browsingInfoContent"
                >
                  <Box color={getTextColor({ canViewable: item.canViewable!, expiredOn: item.expiredOn! })}>
                    <Typography variant="subtitle1" style={{ wordBreak: 'break-all' }}>
                      {getText({ canViewable: item.canViewable!, expiredOn: item.expiredOn! })}
                    </Typography>
                  </Box>
                  {onOpenUpdateExpiredOnModal && (
                    <Button
                      startIcon={<Edit />}
                      kind="secondary"
                      variant="outlined"
                      size="small"
                      title="編集"
                      className="editIcon"
                      onClick={() => onOpenUpdateExpiredOnModal(item)}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb="12px">
                <Typography variant="h5">資料情報</Typography>
                <Button
                  startIcon={<OpenInNew />}
                  kind="secondary"
                  variant="outlined"
                  size="small"
                  title="詳細"
                  className="editIcon"
                  onClick={() => window.open(`/documents/${documentId}`, '_blank')}
                />
              </Box>

              <Box mb={4}>
                <Typography variant="subtitle2" style={{ color: Colors.base.middleGray }}>
                  ラベル
                </Typography>
                <Box mb="4px" />
                <Box width={1} display="flex" alignItems="center">
                  {data?.label && <DocumentLabelItem value={data?.label}></DocumentLabelItem>}
                </Box>
              </Box>
              <Box pb={3} borderBottom={`1px solid ${Colors.background.gray}`}>
                <Typography variant="subtitle2" style={{ color: Colors.base.middleGray }}>
                  社内メモ
                </Typography>
                <Box mt="4px" />
                <Typography variant="subtitle1">{item.materialable?.memo}</Typography>
              </Box>
            </Box>

            {currentSlide && (
              <Box mt={4}>
                <Box display="flex" alignItems="center" mb="6px">
                  <Typography fontSize="xs" lh="none" style={{ color: Colors.base.middleGray }}>
                    プレビュー
                  </Typography>
                  <Box mr="4px" />
                  <Box className={classes.previewIcon}>
                    <LinkIcon href={`/documents/${documentId}/preview`} isBlank>
                      <OpenInNew fontSize="small" htmlColor={Colors.accent.keyBlue.default} />
                    </LinkIcon>
                  </Box>
                </Box>

                <Box className={classes.pdfPreview}>
                  <img
                    id="setting-page-wrapper"
                    src={String(currentSlide.file?.url)}
                    style={{ width: '100%', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
                  />

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width={1}
                    className={classes.actionMenu}
                  >
                    <Box>
                      <IconButton onClick={onPrev}>
                        <SvgIcon variant="leftArrow" color={Colors.base.middleGray} size="12px" />
                      </IconButton>

                      <IconButton onClick={onNext}>
                        <SvgIcon variant="rightArrow" color={Colors.base.middleGray} size="12px" />
                      </IconButton>
                    </Box>

                    <Box pr={3} display="flex" alignItems="center">
                      <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                        {shownPage ?? 1}/{totalPage}ページ
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {isValidating && !currentSlide && (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            width={1}
            flexWrap="wrap"
            pt="10px"
            borderTop={`1px solid ${Colors.background.gray}`}
          >
            <Box width="134px" mt="4px" className="browsingInfoTitle">
              <Typography variant="subtitle2" style={{ color: Colors.base.middleGray, padding: '4px 0' }}>
                閲覧権限・期限
              </Typography>
            </Box>
            <Box
              width={1}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="browsingInfoSiteContent"
            >
              <Box>
                {/*  canViewableの時だけ、カラーを変えたい */}
                <Box color={getTextColor({ canViewable: item.canViewable!, expiredOn: item.expiredOn! })}>
                  <Typography variant="subtitle1" style={{ wordBreak: 'break-all' }}>
                    {getText({ canViewable: item.canViewable!, expiredOn: item.expiredOn! })}
                  </Typography>
                </Box>
              </Box>
              {onOpenUpdateExpiredOnModal && (
                <Button
                  startIcon={<Edit />}
                  kind="secondary"
                  variant="outlined"
                  size="small"
                  title="編集"
                  className="editIcon"
                  onClick={() => onOpenUpdateExpiredOnModal(item)}
                />
              )}
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
