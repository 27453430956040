import { Box } from '@material-ui/core'
import { Typography } from 'src/components/atoms'

export const ContentRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box sx={{ display: 'flex', py: '12px', borderBottom: '1px solid #E9ECF1' }}>
      <Box sx={{ width: '210px', mr: '40px' }}>
        <Typography fontSize="s">{label}</Typography>
      </Box>
      <Box>
        <Typography fontSize="s">{value}</Typography>
      </Box>
    </Box>
  )
}
