/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { JobFileFromJSON, JobFileToJSON } from './JobFile';
import { JobLogFromJSON, JobLogToJSON } from './JobLog';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function JobFromJSON(json) {
    return JobFromJSONTyped(json, false);
}
export function JobFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        ownerType: !exists(json, 'owner_type') ? undefined : json['owner_type'],
        ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        status: !exists(json, 'status') ? undefined : json['status'],
        countsAll: !exists(json, 'counts_all') ? undefined : json['counts_all'],
        countsProgress: !exists(json, 'counts_progress') ? undefined : json['counts_progress'],
        countsCompleted: !exists(json, 'counts_completed') ? undefined : json['counts_completed'],
        countsErrord: !exists(json, 'counts_errord') ? undefined : json['counts_errord'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        hasLogs: !exists(json, 'has_logs') ? undefined : json['has_logs'],
        hasFiles: !exists(json, 'has_files') ? undefined : json['has_files'],
        user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
        logs: !exists(json, 'logs') ? undefined : json['logs'].map(JobLogFromJSON),
        files: !exists(json, 'files') ? undefined : json['files'].map(JobFileFromJSON)
    };
}
export function JobToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        owner_type: value.ownerType,
        owner_id: value.ownerId,
        name: value.name,
        status: value.status,
        counts_all: value.countsAll,
        counts_progress: value.countsProgress,
        counts_completed: value.countsCompleted,
        counts_errord: value.countsErrord,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        has_logs: value.hasLogs,
        has_files: value.hasFiles,
        user: UserForDisplayToJSON(value.user),
        logs: value.logs === undefined ? undefined : value.logs.map(JobLogToJSON),
        files: value.files === undefined ? undefined : value.files.map(JobFileToJSON)
    };
}
