import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import Image from 'next/image'
import { Card, Colors, TextError, Typography } from 'src/components/atoms'
import { Counter } from '../Counter'
import { OrganizationPlanDetail } from '@noco/http-client/lib/noco'

export interface ManageUserQuantityCardProps {
  plan: OrganizationPlanDetail
  bundledUserQuantity: number
  usageUserCount: number
  userOptionQuantity: number
  unitAmount: number
  onChange?: (userOptionQuantity: number, isValid: boolean) => void
}

const border = '1px solid #DADCE0'

export const ManageUserQuantityCard = ({
  plan,
  bundledUserQuantity,
  usageUserCount,
  userOptionQuantity,
  onChange
}: ManageUserQuantityCardProps) => {
  // 増減するユーザー数数量
  const [diffQuantity, setDiffQuantity] = useState(0)
  // 変更後のユーザー追加枠数
  const [newUserOptionQuantity, setNewUserOptionQuantity] = useState(0)
  // 変更後のユーザー利用枠
  const [newBundledUserQuantity, setNewBundledUserQuantity] = useState(0)

  // 変更後のユーザー利用枠総数が、プラン制限のユーザー数以上であること
  const isValidPlanUserQuantity = useMemo(() => {
    return newBundledUserQuantity >= plan.userQuantity
  }, [newBundledUserQuantity, plan.userQuantity])
  // 変更後のユーザー利用枠総数が、現在利用中のユーザー数以上であること
  const isValidUsageUserQuantity = useMemo(() => {
    return newBundledUserQuantity >= usageUserCount
  }, [newBundledUserQuantity, usageUserCount])
  const isValid = useMemo(
    () => isValidPlanUserQuantity && isValidUsageUserQuantity,
    [isValidPlanUserQuantity, isValidUsageUserQuantity]
  )

  useEffect(() => {
    onChange?.(newUserOptionQuantity, isValid)
  }, [newUserOptionQuantity, onChange, isValid])

  useEffect(() => {
    setNewUserOptionQuantity(userOptionQuantity + diffQuantity)
  }, [diffQuantity, userOptionQuantity])

  useEffect(() => {
    setNewBundledUserQuantity(plan.userQuantity + newUserOptionQuantity)
  }, [plan.userQuantity, newUserOptionQuantity])

  return (
    <Card sx={{ bgcolor: '#fff' }}>
      <Box mb="16px" textAlign="center">
        <Typography fontSize="l" fontWeight="bold" lineheight="1.5">
          ユーザーご利用枠を追加しませんか？
        </Typography>
      </Box>
      <Box textAlign="center" mb="16px">
        <Typography fontSize="s" lineheight="1">
          「ユーザーご利用枠」を追加することで、多人数でサービスをご利用いただけます
        </Typography>
      </Box>

      <Box display="flex" justifyContent="space-between" mb="28px" paddingTop="12px">
        <Box>
          <Box mb="12px">
            <Typography fontSize="s" fontWeight="bold" lineheight="1">
              ユーザーご利用情報
            </Typography>
          </Box>
          <Box
            sx={{
              width: '320px',
              display: 'grid',
              gridTemplateAreas: `
                "bundled-heading usage-heading"
                "bundled-value   usage-value"`,
              gridTemplateColumns: '1fr 1fr',
              gridTemplateRows: '28px 40px',
              border
            }}
          >
            <Box
              gridArea="bundled-heading"
              borderRight={border}
              borderBottom={border}
              textAlign="center"
              padding="8px 0"
              bgcolor={Colors.background.lightGray}
              color="#6D7784"
            >
              <Typography fontSize="xs" fontWeight="bold" lineheight="1" color="inherit">
                ユーザーご利用枠
              </Typography>
            </Box>
            <Box
              gridArea="usage-heading"
              borderBottom={border}
              textAlign="center"
              padding="8px 0"
              bgcolor={Colors.background.lightGray}
              color="#6D7784"
            >
              <Typography fontSize="xs" fontWeight="bold" lineheight="1" color="inherit">
                現在ご利用中のユーザー数
              </Typography>
            </Box>
            <Box gridArea="bundled-value" borderRight={border} textAlign="center" padding="13px 0">
              <Typography fontSize="s" lineheight="1">
                {bundledUserQuantity}名
              </Typography>
            </Box>
            <Box gridArea="usage-value" textAlign="center" padding="13px 0">
              <Typography fontSize="s" lineheight="1">
                {usageUserCount}名
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Image src={`/images/payment/addon-user-option.svg`} width="167" height="102" alt="" />
        </Box>
      </Box>

      <Box mb="24px" paddingTop="8px">
        <Box mb="16px">
          <Typography fontSize="s" fontWeight="bold" lineheight="1">
            ユーザーご利用枠の追加・削除
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box width="160px" mr="8px">
            <Counter value={diffQuantity} onChange={value => setDiffQuantity(value)} />
          </Box>
          <Typography fontSize="s" lineheight="1">
            名
          </Typography>
        </Box>

        {!isValid && (
          <Box mt="16px">
            {!isValidPlanUserQuantity && (
              <TextError label={`${plan.name}ではユーザーご利用枠を${plan.userQuantity}名より下回ることができません`} />
            )}
            {!isValidUsageUserQuantity && (
              <TextError label="変更後のユーザーご利用枠は、現在ご利用中のユーザー数を下回ることはできません" />
            )}
          </Box>
        )}
      </Box>

      <Box mb="16px" display="flex" alignItems="baseline">
        <Typography fontSize="s" lineheight="1.32">
          変更後のユーザーご利用枠は
        </Typography>
        <Box padding="0 6px 0 10px">
          <Typography fontSize="l" lineheight="1.5" fontWeight="bold">
            {newBundledUserQuantity}
          </Typography>
        </Box>
        <Typography fontSize="s" lineheight="1.32">
          名になります
        </Typography>
      </Box>

      <Box color="#6D7784">
        <Typography fontSize="s" lineheight="1.32" letterSpacing="tight" color="inherit">
          ※ 当月のユーザーご利用枠の追加の場合、日割り計算となり翌月のご請求額合計に反映されます
          <br />※ 当月のユーザーご利用枠の削除の場合、当月の差額料金は返金いたしません
        </Typography>
      </Box>
    </Card>
  )
}
