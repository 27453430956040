import React, { useCallback, useEffect, useState } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { DynamicMuiIcon, Checkbox, Typography, Pallete } from 'src/components/atoms'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { SlideForm, TableColumn } from 'src/components/organisms'

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
)

export interface ModalSelectTableColumnOption extends TableColumn {
  checked: boolean
}

export interface ModalSelectTableColumnProps {
  open: boolean
  initialColumnItems: ModalSelectTableColumnOption[]
  onClose: () => void
  onCancel: () => void
  onConfirm: (result: ModalSelectTableColumnOption[]) => void
  onClickSettings?: () => void
  hideSettings?: boolean
}

export const ModalSelectTableColumn = ({
  open,
  initialColumnItems,
  onClose,
  onCancel,
  onConfirm,
  onClickSettings,
  hideSettings
}: ModalSelectTableColumnProps) => {
  const [columnItems, setColumnItems] = useState<ModalSelectTableColumnOption[]>(initialColumnItems)
  const classes = useStyles()
  const handleChange = useCallback(
    (i: number) => {
      const newColumnItems = [...columnItems]
      newColumnItems[i].checked = !newColumnItems[i].checked
      setColumnItems(newColumnItems)
    },
    [setColumnItems, columnItems]
  )

  const reorder = useCallback((list: ModalSelectTableColumnOption[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }, [])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) return
    const columnItemsList = reorder(columnItems, result.source.index, result.destination.index)
    setColumnItems(columnItemsList)
  }

  const handleCancel = useCallback(() => {
    setColumnItems(initialColumnItems)
    onCancel()
  }, [onCancel, initialColumnItems])

  const handleClose = useCallback(() => {
    setColumnItems(initialColumnItems)
    onClose()
  }, [onClose, initialColumnItems])

  const onSubmit = useCallback(() => {
    onConfirm(columnItems)
    onClose()
  }, [onConfirm, onClose, columnItems])

  useEffect(() => {
    setColumnItems(initialColumnItems)
  }, [initialColumnItems])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="列の表示設定"
            onClose={handleClose}
            onCancel={handleCancel}
            onConfirm={onSubmit}
            onClickSettings={onClickSettings}
            buttonTitle="保存"
            hideSettings={hideSettings}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="columnItemsList">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {columnItems.map((item, i) => (
                      <Draggable key={i} index={i} draggableId={`draggable-${i}`}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                          >
                            <Box
                              height="40px"
                              px="16px"
                              display="flex"
                              alignItems="center"
                              boxShadow="inset 0px -1px 0px #EEF1F4"
                              bgcolor={Pallete.functional.background.default}
                            >
                              <DynamicMuiIcon variant="dragIndicator" size="20px" />
                              <Box ml="4px" />
                              <Checkbox size="small" checked={item.checked} onClick={() => handleChange(i)} />
                              <Box ml="4px" />
                              <Typography fontSize="s" lh="tight" letterSpacing="normal">
                                {item.title}
                              </Typography>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
