import { format } from 'date-fns'
import { ja } from 'date-fns/locale'

export const isValidDate = (v: any): v is Date => {
  return !v || (v instanceof Date && !isNaN(v.getTime()))
}

export enum DateFormat {
  BASIC = 'yyyy/MM/dd',
  DATE_WITH_DAY = 'yyyy/MM/dd（E）',
  DATE_WITH_TIME = 'yyyy/MM/dd（E）HH:mm'
}

export const dateStringToMilliseconds = (data: string): number => {
  return new Date(data).getTime()
}

export const dateFormat = (date: Date | number, dateFormat: DateFormat): string => {
  return format(date, dateFormat, {
    locale: ja
  })
}

/**
 * 時間表示を分秒で返す
 *
 * @param sec - 秒数
 * @param isCeilSec - 秒数を切り上げる
 * @returns 整形した時間文字列
 *
 * @example
 * 1分未満は、「0分01秒」
 * 1分以上は、「123分45秒」
 * と返す
 */
export const convertMs = (sec: number, isCeilSec: boolean = false) => {
  if (isCeilSec) {
    const minutes = Math.ceil(sec / 60)

    return `${minutes}分`
  } else {
    const minutes = Math.floor(sec / 60)
    const seconds = sec % 60

    return `${minutes}分${String(seconds).padStart(2, '0')}秒`
  }
}
