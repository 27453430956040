/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnumMaterialableTypeFromJSON, EnumMaterialableTypeToJSON } from './EnumMaterialableType';
export function MaterialableViewHistorySessionFromJSON(json) {
    return MaterialableViewHistorySessionFromJSONTyped(json, false);
}
export function MaterialableViewHistorySessionFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        materialableType: EnumMaterialableTypeFromJSON(json['materialable_type']),
        materialableId: json['materialable_id'],
        contactId: json['contact_id'],
        visitorId: json['visitor_id'],
        visitorSessionId: json['visitor_session_id'],
        startedAt: json['started_at'],
        totalElapsedSecond: json['total_elapsed_second']
    };
}
export function MaterialableViewHistorySessionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        materialable_type: EnumMaterialableTypeToJSON(value.materialableType),
        materialable_id: value.materialableId,
        contact_id: value.contactId,
        visitor_id: value.visitorId,
        visitor_session_id: value.visitorSessionId,
        started_at: value.startedAt,
        total_elapsed_second: value.totalElapsedSecond
    };
}
