import { createStyles, makeStyles, Box } from '@material-ui/core'
import { Colors } from '../Colors'
import { Typography } from '../Typography'

export interface StepDetailTitleProps {
  index: number
  title: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '0 12px'
    },
    index: {
      color: Colors.base.middleGray,
      marginRight: '12px'
    }
  })
)

export const StepDetailTitle = ({ index, title }: StepDetailTitleProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.index}>
        <Typography fontSize="s" lineheight="14px" letterSpacing="tight">
          {index}
        </Typography>
      </Box>
      <Box color={Colors.base.black}>
        <Typography fontWeight={600} fontSize="s" lineheight="14px">
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
