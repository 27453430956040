import { Box } from '@material-ui/core'
import React from 'react'
import { Button, Colors, Typography } from 'src/components/atoms'

export interface PaymentBannerProps {
  onClick: () => void
}

export const PaymentBanner = ({ onClick }: PaymentBannerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '23px 31px',
        border: `1px solid ${Colors.primary.default}`,
        borderRadius: '6px'
      }}
    >
      <Box mr="8px">
        <Box mb="8px">
          <Typography variant="h3">ライトプランに不満を感じていませんか？</Typography>
        </Box>
        <Box mb="8px">
          <Typography fontSize="xs" letterSpacing="default" lineheight="170%">
            プレミアムプランなら、パワフルな機能をご利用いただけます
            <br />
            チームの営業力を高めて、顧客獲得を加速しましょう！
          </Typography>
        </Box>
        <Button title="プラン表を確認する" size="medium" variant="outlined" onClick={onClick} />
      </Box>
      <Box>
        <img src="/images/payment-banner.svg" alt="" width="175" height="118" />
      </Box>
    </Box>
  )
}
