/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumChatMessageSendedByType = {
  User: 'User',
  Contact: 'Contact'
} as const
export type EnumChatMessageSendedByType = typeof EnumChatMessageSendedByType[keyof typeof EnumChatMessageSendedByType]

export function EnumChatMessageSendedByTypeFromJSON(json: any): EnumChatMessageSendedByType {
  return EnumChatMessageSendedByTypeFromJSONTyped(json, false)
}

export function EnumChatMessageSendedByTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumChatMessageSendedByType {
  return json as EnumChatMessageSendedByType
}

export function EnumChatMessageSendedByTypeToJSON(value?: EnumChatMessageSendedByType | null): any {
  return value as any
}
