import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { Button, Colors, Input, Label, Password, Typography } from 'src/components/atoms'
import { useGetUserInfoFromToken, useSignup } from 'src/fixtures/modules/auth/hooks'
import * as yup from 'yup'
import { TERMS_LINK, PRIVACY_LINK } from 'src/fixtures/utils/constant'

export interface SignUpWithInvitedProps {
  invitedToken: string
  data: ReturnType<typeof useGetUserInfoFromToken>['data']
}

export const signUpWithInvitedValidationSchema = yup.object().shape({
  firstName: yup.string().label('名').required('名は必須です'),
  lastName: yup.string().label('姓').required('姓は必須です'),
  password: yup
    .string()
    .label('パスワード')
    .matches(
      /^(?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[\x20\W_])[\x20!-~]{12,}/,
      'パスワードはアルファベットと数字と記号を含めた12文字以上で設定してください'
    )
    .required('パスワードは必須です')
})

export const SignUpWithInvited: React.VFC<SignUpWithInvitedProps> = ({ invitedToken, data }) => {
  const { handleSignUp } = useSignup()
  const formik = useFormik({
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: signUpWithInvitedValidationSchema,
    initialValues: {
      company: data?.organization.name,
      firstName: '',
      lastName: '',
      email: data?.user.encryptedEmail,
      password: ''
    },
    onSubmit: values => {
      handleSignUp(
        {
          token: invitedToken,
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password
        },
        data?.user.encryptedEmail
      )

      formik.resetForm()
    }
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h1">チームに参加する</Typography>
      <Box mt="24px" />
      <Typography variant="h5">会社名</Typography>
      <Box mt="6px" />
      <Typography variant="subtitle1">{formik.values.company}</Typography>
      <Box mt="24px" />
      <Typography variant="h5">メールアドレス</Typography>
      <Box mt="6px" />
      <Typography variant="subtitle1">{formik.values.email}</Typography>
      <Box mt="24px" />
      <Box display="flex">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex">
            <Box>
              <Typography variant="h5">姓</Typography>
            </Box>
            <Box ml="6px" />
            <Label type="negative" labelText="必須" />
          </Box>
          <Box display="flex" alignItems="flex-end" pt="6px" pb="4px">
            <Input
              {...formik.getFieldProps('lastName')}
              fullWidth
              required
              error={formik.touched.lastName ? formik.errors.lastName : undefined}
              large
            />
          </Box>
        </Box>
        <Box mr="20px" />
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex">
            <Box>
              <Typography variant="h5">名</Typography>
            </Box>
            <Box ml="6px" />
            <Label type="negative" labelText="必須" />
          </Box>
          <Box display="flex" alignItems="flex-end" pt="6px" pb="4px">
            <Input
              {...formik.getFieldProps('firstName')}
              fullWidth
              required
              error={formik.touched.firstName ? formik.errors.firstName : undefined}
              large
            />
          </Box>
        </Box>
      </Box>
      <Box mt="24px" />
      <Box display="flex">
        <Box>
          <Typography variant="h5">パスワードの設定</Typography>
        </Box>
        <Box ml="6px" />
        <Label type="negative" labelText="必須" />
      </Box>
      <Box display="flex" pt="6px" pb="4px">
        <Password
          name="password"
          password={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.touched.password ? formik.errors.password : undefined}
          fullWidth
          height="large"
        />
      </Box>
      <Box mt="8px" />
      <Typography variant="subtitle1">アルファベットと数字と記号を含めた12文字以上で設定してください</Typography>
      <Box mt="24px" />
      <Button type="submit" title="登録" size="large" width="100%" />
      <Box mt="20px" />
      <Typography fontSize="s" lh="relaxed">
        続行することにより、noco の
        <a
          href={TERMS_LINK}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: Colors.accent.keyBlue.default }}
        >
          利用規約
        </a>
        および
        <a
          href={PRIVACY_LINK}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: Colors.accent.keyBlue.default }}
        >
          プライバシーポリシー
        </a>
        に同意したものとみなされます
      </Typography>
    </form>
  )
}
