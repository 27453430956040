/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UploadedFileFromJSON, UploadedFileToJSON } from './UploadedFile';
export function SiteMiscSettingFromJSON(json) {
    return SiteMiscSettingFromJSONTyped(json, false);
}
export function SiteMiscSettingFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        isPublicSearchEngine: !exists(json, 'is_public_search_engine') ? undefined : json['is_public_search_engine'],
        ogpTitle: !exists(json, 'ogp_title') ? undefined : json['ogp_title'],
        ogpFile: !exists(json, 'ogp_file') ? undefined : UploadedFileFromJSON(json['ogp_file']),
        ogpDescription: !exists(json, 'ogp_description') ? undefined : json['ogp_description'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function SiteMiscSettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        is_public_search_engine: value.isPublicSearchEngine,
        ogp_title: value.ogpTitle,
        ogp_file: UploadedFileToJSON(value.ogpFile),
        ogp_description: value.ogpDescription,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
