/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { DocumentSite, DocumentSiteFromJSON, DocumentSiteFromJSONTyped, DocumentSiteToJSON } from './DocumentSite'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetDocumentSitesResponseData
 */
export interface GetDocumentSitesResponseData {
  /**
   *
   * @type {Array<DocumentSite>}
   * @memberof GetDocumentSitesResponseData
   */
  documentSites?: Array<DocumentSite>
  /**
   *
   * @type {PageInfo}
   * @memberof GetDocumentSitesResponseData
   */
  pageInfo?: PageInfo
}

export function GetDocumentSitesResponseDataFromJSON(json: any): GetDocumentSitesResponseData {
  return GetDocumentSitesResponseDataFromJSONTyped(json, false)
}

export function GetDocumentSitesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetDocumentSitesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentSites: !exists(json, 'document_sites')
      ? undefined
      : (json['document_sites'] as Array<any>).map(DocumentSiteFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetDocumentSitesResponseDataToJSON(value?: GetDocumentSitesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_sites:
      value.documentSites === undefined ? undefined : (value.documentSites as Array<any>).map(DocumentSiteToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
