/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function CopyDocumentRequestFromJSON(json) {
    return CopyDocumentRequestFromJSONTyped(json, false);
}
export function CopyDocumentRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        fromId: json['from_id'],
        toId: json['to_id'],
        copyInfo: json['copy_info'],
        copyChat: json['copy_chat'],
        copyMessage: json['copy_message'],
        copyOpportunity: json['copy_opportunity'],
        copyCta: json['copy_cta'],
        copySetting: json['copy_setting'],
        copyShareByLink: json['copy_share_by_link']
    };
}
export function CopyDocumentRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        from_id: value.fromId,
        to_id: value.toId,
        copy_info: value.copyInfo,
        copy_chat: value.copyChat,
        copy_message: value.copyMessage,
        copy_opportunity: value.copyOpportunity,
        copy_cta: value.copyCta,
        copy_setting: value.copySetting,
        copy_share_by_link: value.copyShareByLink
    };
}
