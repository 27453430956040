/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { FilterBasicResourceListFromJSON, FilterBasicResourceListToJSON } from './FilterBasicResourceList';
import { FilterCountsFromJSON, FilterCountsToJSON } from './FilterCounts';
import { FilterMaterialObjectFromJSON, FilterMaterialObjectToJSON } from './FilterMaterialObject';
import { FilterStaffFromJSON, FilterStaffToJSON } from './FilterStaff';
export function ContactFilterFromJSON(json) {
    return ContactFilterFromJSONTyped(json, false);
}
export function ContactFilterFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        normal: !exists(json, 'normal') ? undefined : FilterCountsFromJSON(json['normal']),
        archived: !exists(json, 'archived') ? undefined : FilterCountsFromJSON(json['archived']),
        duplicated: !exists(json, 'duplicated') ? undefined : FilterCountsFromJSON(json['duplicated']),
        staffList: !exists(json, 'staff_list') ? undefined : FilterStaffFromJSON(json['staff_list']),
        material: !exists(json, 'material') ? undefined : FilterMaterialObjectFromJSON(json['material']),
        contactList: !exists(json, 'contact_list') ? undefined : FilterBasicResourceListFromJSON(json['contact_list'])
    };
}
export function ContactFilterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        normal: FilterCountsToJSON(value.normal),
        archived: FilterCountsToJSON(value.archived),
        duplicated: FilterCountsToJSON(value.duplicated),
        staff_list: FilterStaffToJSON(value.staffList),
        material: FilterMaterialObjectToJSON(value.material),
        contact_list: FilterBasicResourceListToJSON(value.contactList)
    };
}
