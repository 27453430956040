import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var VisitorAuthApi = /** @class */ (function () {
    function VisitorAuthApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    VisitorAuthApi.initialize = function (basePath, fetchApi) {
        if (!VisitorAuthApi.instance) {
            VisitorAuthApi.instance = new VisitorAuthApi(basePath, fetchApi);
        }
        return VisitorAuthApi.instance;
    };
    VisitorAuthApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'contact'
        });
        return new NocoHttpClient.ApiContactV1VisitorAuthApi(new Configuration(config));
    };
    return VisitorAuthApi;
}());
export { VisitorAuthApi };
