import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { StepperBoard } from 'src/components/molecules/StepperBoard'
import { StepperContext } from 'src/components/molecules/StepperContext'
import { Stepper } from 'src/components/molecules/Stepper'
import { PaymentCanceled } from './PaymentCanceled'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import { OrganizationStatusEnum } from '@noco/http-client/lib/noco'
import { StepBeforeStart, StepComplete, StepConfirmation, StepReasonSelection } from './step'
import { pushUserActionDataLayer } from 'src/utils/gtm'

export const PageTeamDelete = () => {
  const [otherReason, setOtherReason] = useState<string | undefined>()
  const [reasonList, setReasonList] = useState<string[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const { data, mutate: mutateOrganization } = useGetOrganization()
  const organization = data?.organization

  // MEMO: organization.status が active で、organization.canceledAt に値が入っている場合、退会申請が完了している状態（契約終了日時まではサービスが利用できる）
  const isCanceled = useMemo(() => {
    return organization?.status === OrganizationStatusEnum.Active && organization.canceledAt
  }, [organization])

  const handleIncrementIndex = useCallback(() => {
    if (ref.current) ref.current.scrollTop = 0
    setCurrentIndex(v => v + 1)
  }, [])

  const handleDecrementIndex = useCallback(() => {
    if (ref.current) ref.current.scrollTop = 0
    setCurrentIndex(v => v - 1)
  }, [])

  const items = useMemo(
    () => [
      {
        label: 'ご解約前の確認',
        renderItem: <StepBeforeStart onNext={handleIncrementIndex} />
      },
      {
        label: 'ご解約理由',
        renderItem: (
          <StepReasonSelection
            onNext={(list, other) => {
              handleIncrementIndex()
              setReasonList(list)
              setOtherReason(other)
            }}
            onBack={() => {
              setReasonList([])
              setOtherReason(undefined)
              handleDecrementIndex()
            }}
          />
        )
      },
      {
        label: '確認',
        renderItem: (
          <StepConfirmation
            selectedList={reasonList}
            otherReason={otherReason}
            onNext={() => {
              pushUserActionDataLayer('contract', 'completed_contract_cancellation')
              handleIncrementIndex()
            }}
            onBack={handleDecrementIndex}
          />
        )
      },
      {
        label: '完了',
        renderItem: <StepComplete />
      }
    ],
    [handleDecrementIndex, handleIncrementIndex, otherReason, reasonList]
  )

  const handleSubmitPaymentCanceled = useCallback(() => {
    setCurrentIndex(0)
    mutateOrganization()
  }, [mutateOrganization])

  return (
    <>
      <LayoutTeam>
        <Box {...{ ref: ref }} sx={{ width: '100%', bgcolor: 'white', overflow: 'hidden auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', color: Colors.base.black, pl: '37px', pt: '17px' }}>
            <DynamicMuiIcon variant="exit" size={32} />
            <Box width="8px" />
            <Typography variant="h1" fontSize="xl" fontWeight="bold" style={{ color: Colors.base.black }}>
              サービスのご解約
            </Typography>
          </Box>

          <Box height={'37px'} />

          {isCanceled ? (
            <Box sx={{ maxWidth: '680px', width: '100%', mx: 'auto' }}>
              <PaymentCanceled onSubmit={handleSubmitPaymentCanceled} />
            </Box>
          ) : (
            <Box sx={{ maxWidth: '800px', width: '100%', mx: 'auto' }}>
              <StepperContext currentIndex={currentIndex}>
                <Stepper items={items} />
                <StepperBoard boardItems={items} />
              </StepperContext>
            </Box>
          )}
        </Box>
      </LayoutTeam>
    </>
  )
}
