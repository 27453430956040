/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CompanyForDisplayFromJSON, CompanyForDisplayToJSON } from './CompanyForDisplay';
import { ContactActionFromJSON, ContactActionToJSON } from './ContactAction';
import { ContactForDisplayFromJSON, ContactForDisplayToJSON } from './ContactForDisplay';
import { EnumActivityStatusFromJSON, EnumActivityStatusToJSON } from './EnumActivityStatus';
import { EnumSupportStatusFromJSON, EnumSupportStatusToJSON } from './EnumSupportStatus';
import { MaterialableForDisplayFromJSON, MaterialableForDisplayToJSON } from './MaterialableForDisplay';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function InboxItemFromJSON(json) {
    return InboxItemFromJSONTyped(json, false);
}
export function InboxItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        createdAt: !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        updatedAt: !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        id: !exists(json, 'id') ? undefined : json['id'],
        activityStatus: !exists(json, 'activity_status') ? undefined : EnumActivityStatusFromJSON(json['activity_status']),
        supportStatus: !exists(json, 'support_status') ? undefined : EnumSupportStatusFromJSON(json['support_status']),
        isSolved: !exists(json, 'is_solved') ? undefined : json['is_solved'],
        additionalText: !exists(json, 'additional_text') ? undefined : json['additional_text'],
        latestContactActionedAt: !exists(json, 'latest_contact_actioned_at')
            ? undefined
            : json['latest_contact_actioned_at'],
        contact: !exists(json, 'contact') ? undefined : ContactForDisplayFromJSON(json['contact']),
        company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company']),
        materialable: !exists(json, 'materialable') ? undefined : MaterialableForDisplayFromJSON(json['materialable']),
        contactAction: !exists(json, 'contact_action') ? undefined : ContactActionFromJSON(json['contact_action']),
        user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user'])
    };
}
export function InboxItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        created_at: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        updated_at: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        id: value.id,
        activity_status: EnumActivityStatusToJSON(value.activityStatus),
        support_status: EnumSupportStatusToJSON(value.supportStatus),
        is_solved: value.isSolved,
        additional_text: value.additionalText,
        latest_contact_actioned_at: value.latestContactActionedAt,
        contact: ContactForDisplayToJSON(value.contact),
        company: CompanyForDisplayToJSON(value.company),
        materialable: MaterialableForDisplayToJSON(value.materialable),
        contact_action: ContactActionToJSON(value.contactAction),
        user: UserForDisplayToJSON(value.user)
    };
}
