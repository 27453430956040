/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  EnumChatSummaryRepliedStatus,
  EnumChatSummaryRepliedStatusFromJSON,
  EnumChatSummaryRepliedStatusToJSON,
  GetChatSummariesCountResponse,
  GetChatSummariesCountResponseFromJSON,
  GetChatSummariesCountResponseToJSON,
  GetChatSummariesResponse,
  GetChatSummariesResponseFromJSON,
  GetChatSummariesResponseToJSON,
  GetChatSummariesSendedByUserResponse,
  GetChatSummariesSendedByUserResponseFromJSON,
  GetChatSummariesSendedByUserResponseToJSON,
  UpdateChatSummariesChangeUserRequest,
  UpdateChatSummariesChangeUserRequestFromJSON,
  UpdateChatSummariesChangeUserRequestToJSON,
  UpdateChatSummariesRepliedRequest,
  UpdateChatSummariesRepliedRequestFromJSON,
  UpdateChatSummariesRepliedRequestToJSON,
  UpdateChatSummariesRepliedResponse,
  UpdateChatSummariesRepliedResponseFromJSON,
  UpdateChatSummariesRepliedResponseToJSON
} from '../models'

export interface UserV1ChatSummariesChangeUserPutRequest {
  updateChatSummariesChangeUserRequest?: UpdateChatSummariesChangeUserRequest
}

export interface UserV1ChatSummariesCountGetRequest {
  repliedStatus?: EnumChatSummaryRepliedStatus
  userIds?: Array<string>
  documentIds?: Array<string>
  siteIds?: Array<string>
  repliedStatuses?: Array<EnumChatSummaryRepliedStatus>
  latestSendedAtOnFrom?: string
  latestSendedAtOnTo?: string
}

export interface UserV1ChatSummariesGetRequest {
  userIds?: Array<string>
  documentIds?: Array<string>
  siteIds?: Array<string>
  repliedStatuses?: Array<EnumChatSummaryRepliedStatus>
  latestSendedAtOnFrom?: string
  latestSendedAtOnTo?: string
  text?: string
  page?: number
  per?: number
  orderBy?: string
}

export interface UserV1ChatSummariesRepliedPutRequest {
  updateChatSummariesRepliedRequest?: UpdateChatSummariesRepliedRequest
}

export interface UserV1ChatSummariesSendedByUserGetRequest {
  sendUserIds?: Array<string>
  documentIds?: Array<string>
  siteIds?: Array<string>
  sendedAtOnFrom?: string
  sendedAtOnTo?: string
  text?: string
  page?: number
  per?: number
  orderBy?: string
}

/**
 *
 */
export class ApiUserV1ChatSummaryApi extends runtime.BaseAPI {
  /**
   * 受信メッセージから担当者の変更
   * index
   */
  async userV1ChatSummariesChangeUserPutRaw(
    requestParameters: UserV1ChatSummariesChangeUserPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateChatSummariesRepliedResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/chat_summaries/change_user`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateChatSummariesChangeUserRequestToJSON(requestParameters.updateChatSummariesChangeUserRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateChatSummariesRepliedResponseFromJSON(jsonValue))
  }

  /**
   * 受信メッセージから担当者の変更
   * index
   */
  async userV1ChatSummariesChangeUserPut(
    requestParameters: UserV1ChatSummariesChangeUserPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateChatSummariesRepliedResponse> {
    const response = await this.userV1ChatSummariesChangeUserPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 受信メッセージのカウント
   * count
   */
  async userV1ChatSummariesCountGetRaw(
    requestParameters: UserV1ChatSummariesCountGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetChatSummariesCountResponse>> {
    const queryParameters: any = {}

    if (requestParameters.repliedStatus !== undefined) {
      queryParameters['replied_status'] = requestParameters.repliedStatus
    }

    if (requestParameters.userIds) {
      queryParameters['user_ids[]'] = requestParameters.userIds
    }

    if (requestParameters.documentIds) {
      queryParameters['document_ids[]'] = requestParameters.documentIds
    }

    if (requestParameters.siteIds) {
      queryParameters['site_ids[]'] = requestParameters.siteIds
    }

    if (requestParameters.repliedStatuses) {
      queryParameters['replied_statuses[]'] = requestParameters.repliedStatuses
    }

    if (requestParameters.latestSendedAtOnFrom !== undefined) {
      queryParameters['latest_sended_at_on_from'] = requestParameters.latestSendedAtOnFrom
    }

    if (requestParameters.latestSendedAtOnTo !== undefined) {
      queryParameters['latest_sended_at_on_to'] = requestParameters.latestSendedAtOnTo
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/chat_summaries/count`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetChatSummariesCountResponseFromJSON(jsonValue))
  }

  /**
   * 受信メッセージのカウント
   * count
   */
  async userV1ChatSummariesCountGet(
    requestParameters: UserV1ChatSummariesCountGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetChatSummariesCountResponse> {
    const response = await this.userV1ChatSummariesCountGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 受信メッセージの一覧取得
   * index
   */
  async userV1ChatSummariesGetRaw(
    requestParameters: UserV1ChatSummariesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetChatSummariesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.userIds) {
      queryParameters['user_ids[]'] = requestParameters.userIds
    }

    if (requestParameters.documentIds) {
      queryParameters['document_ids[]'] = requestParameters.documentIds
    }

    if (requestParameters.siteIds) {
      queryParameters['site_ids[]'] = requestParameters.siteIds
    }

    if (requestParameters.repliedStatuses) {
      queryParameters['replied_statuses[]'] = requestParameters.repliedStatuses
    }

    if (requestParameters.latestSendedAtOnFrom !== undefined) {
      queryParameters['latest_sended_at_on_from'] = requestParameters.latestSendedAtOnFrom
    }

    if (requestParameters.latestSendedAtOnTo !== undefined) {
      queryParameters['latest_sended_at_on_to'] = requestParameters.latestSendedAtOnTo
    }

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/chat_summaries`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetChatSummariesResponseFromJSON(jsonValue))
  }

  /**
   * 受信メッセージの一覧取得
   * index
   */
  async userV1ChatSummariesGet(
    requestParameters: UserV1ChatSummariesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetChatSummariesResponse> {
    const response = await this.userV1ChatSummariesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 受信メッセージの応答更新
   * replied
   */
  async userV1ChatSummariesRepliedPutRaw(
    requestParameters: UserV1ChatSummariesRepliedPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateChatSummariesRepliedResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/chat_summaries/replied`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateChatSummariesRepliedRequestToJSON(requestParameters.updateChatSummariesRepliedRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateChatSummariesRepliedResponseFromJSON(jsonValue))
  }

  /**
   * 受信メッセージの応答更新
   * replied
   */
  async userV1ChatSummariesRepliedPut(
    requestParameters: UserV1ChatSummariesRepliedPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateChatSummariesRepliedResponse> {
    const response = await this.userV1ChatSummariesRepliedPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 送信済みメッセージサマリーの一覧取得
   * sended_by_user
   */
  async userV1ChatSummariesSendedByUserGetRaw(
    requestParameters: UserV1ChatSummariesSendedByUserGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetChatSummariesSendedByUserResponse>> {
    const queryParameters: any = {}

    if (requestParameters.sendUserIds) {
      queryParameters['send_user_ids[]'] = requestParameters.sendUserIds
    }

    if (requestParameters.documentIds) {
      queryParameters['document_ids[]'] = requestParameters.documentIds
    }

    if (requestParameters.siteIds) {
      queryParameters['site_ids[]'] = requestParameters.siteIds
    }

    if (requestParameters.sendedAtOnFrom !== undefined) {
      queryParameters['sended_at_on_from'] = requestParameters.sendedAtOnFrom
    }

    if (requestParameters.sendedAtOnTo !== undefined) {
      queryParameters['sended_at_on_to'] = requestParameters.sendedAtOnTo
    }

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/chat_summaries/sended_by_user`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetChatSummariesSendedByUserResponseFromJSON(jsonValue))
  }

  /**
   * 送信済みメッセージサマリーの一覧取得
   * sended_by_user
   */
  async userV1ChatSummariesSendedByUserGet(
    requestParameters: UserV1ChatSummariesSendedByUserGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetChatSummariesSendedByUserResponse> {
    const response = await this.userV1ChatSummariesSendedByUserGetRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
