import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var PorterApi = /** @class */ (function () {
    function PorterApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    PorterApi.initialize = function (basePath, fetchApi) {
        if (!PorterApi.instance) {
            PorterApi.instance = new PorterApi(basePath, fetchApi);
        }
        return PorterApi.instance;
    };
    PorterApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1PorterApi(new Configuration(config));
    };
    return PorterApi;
}());
export { PorterApi };
