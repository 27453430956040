/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateContactMemoRequestContactMemo
 */
export interface CreateContactMemoRequestContactMemo {
  /**
   *
   * @type {string}
   * @memberof CreateContactMemoRequestContactMemo
   */
  text?: string
}

export function CreateContactMemoRequestContactMemoFromJSON(json: any): CreateContactMemoRequestContactMemo {
  return CreateContactMemoRequestContactMemoFromJSONTyped(json, false)
}

export function CreateContactMemoRequestContactMemoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactMemoRequestContactMemo {
  if (json === undefined || json === null) {
    return json
  }
  return {
    text: !exists(json, 'text') ? undefined : json['text']
  }
}

export function CreateContactMemoRequestContactMemoToJSON(value?: CreateContactMemoRequestContactMemo | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    text: value.text
  }
}
