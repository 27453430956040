/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateMailTemplateRequestMailTemplate,
  CreateMailTemplateRequestMailTemplateFromJSON,
  CreateMailTemplateRequestMailTemplateFromJSONTyped,
  CreateMailTemplateRequestMailTemplateToJSON
} from './CreateMailTemplateRequestMailTemplate'

/**
 *
 * @export
 * @interface UpdateMailTemplateRequest
 */
export interface UpdateMailTemplateRequest {
  /**
   *
   * @type {CreateMailTemplateRequestMailTemplate}
   * @memberof UpdateMailTemplateRequest
   */
  mailTemplate?: CreateMailTemplateRequestMailTemplate
}

export function UpdateMailTemplateRequestFromJSON(json: any): UpdateMailTemplateRequest {
  return UpdateMailTemplateRequestFromJSONTyped(json, false)
}

export function UpdateMailTemplateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateMailTemplateRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mailTemplate: !exists(json, 'mail_template')
      ? undefined
      : CreateMailTemplateRequestMailTemplateFromJSON(json['mail_template'])
  }
}

export function UpdateMailTemplateRequestToJSON(value?: UpdateMailTemplateRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mail_template: CreateMailTemplateRequestMailTemplateToJSON(value.mailTemplate)
  }
}
