/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateContactSettingRequestContactSetting,
  CreateContactSettingRequestContactSettingFromJSON,
  CreateContactSettingRequestContactSettingFromJSONTyped,
  CreateContactSettingRequestContactSettingToJSON
} from './CreateContactSettingRequestContactSetting'
import {
  UpdateCompanySettingRequestCompanySettingFieldsInner,
  UpdateCompanySettingRequestCompanySettingFieldsInnerFromJSON,
  UpdateCompanySettingRequestCompanySettingFieldsInnerFromJSONTyped,
  UpdateCompanySettingRequestCompanySettingFieldsInnerToJSON
} from './UpdateCompanySettingRequestCompanySettingFieldsInner'

/**
 *
 * @export
 * @interface UpdateCompanySettingRequest
 */
export interface UpdateCompanySettingRequest {
  /**
   *
   * @type {CreateContactSettingRequestContactSetting}
   * @memberof UpdateCompanySettingRequest
   */
  companySetting?: CreateContactSettingRequestContactSetting
  /**
   *
   * @type {Array<UpdateCompanySettingRequestCompanySettingFieldsInner>}
   * @memberof UpdateCompanySettingRequest
   */
  companySettingFields?: Array<UpdateCompanySettingRequestCompanySettingFieldsInner>
}

export function UpdateCompanySettingRequestFromJSON(json: any): UpdateCompanySettingRequest {
  return UpdateCompanySettingRequestFromJSONTyped(json, false)
}

export function UpdateCompanySettingRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCompanySettingRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companySetting: !exists(json, 'company_setting')
      ? undefined
      : CreateContactSettingRequestContactSettingFromJSON(json['company_setting']),
    companySettingFields: !exists(json, 'company_setting_fields')
      ? undefined
      : (json['company_setting_fields'] as Array<any>).map(UpdateCompanySettingRequestCompanySettingFieldsInnerFromJSON)
  }
}

export function UpdateCompanySettingRequestToJSON(value?: UpdateCompanySettingRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    company_setting: CreateContactSettingRequestContactSettingToJSON(value.companySetting),
    company_setting_fields:
      value.companySettingFields === undefined
        ? undefined
        : (value.companySettingFields as Array<any>).map(UpdateCompanySettingRequestCompanySettingFieldsInnerToJSON)
  }
}
