import React, { useCallback, useMemo, ReactElement } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useFormik, FormikErrors, FormikValues } from 'formik'
import { Colors, Input, SvgIcon, Typography, Textarea } from 'src/components/atoms'
import { ProfileRow } from './ProfileRow'
import { UserProfile } from '@noco/http-client/lib/noco'
import { useUpdateMeProfile } from 'src/fixtures/modules/me/hooks'

export interface RowDataType {
  label: string
  field?: keyof UserProfile
  readElement: ReactElement
  editElement: ReactElement
}

const useStyles = makeStyles(() =>
  createStyles({
    textColor: {
      color: '#707B89'
    }
  })
)

export interface ProfileProps {
  profile: UserProfile
}

export const Profile = ({ profile }: ProfileProps) => {
  const classes = useStyles()
  const { handleUpdateMeProfile } = useUpdateMeProfile()

  const validate = useCallback((values: UserProfile) => {
    const errors: FormikErrors<UserProfile> = {}
    if (values.selfIntroduction && values.selfIntroduction?.length > 100) {
      errors.selfIntroduction = '100文字以内で記入してください'
    }
    return errors
  }, [])

  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      ...profile
    },
    validate,
    onSubmit: values => {
      handleUpdateMeProfile({
        userProfile: { ...values }
      })
    }
  })

  const rowData: Array<RowDataType> = useMemo(() => {
    const {
      department,
      position,
      birthplace,
      hobby,
      selfIntroduction,
      socialLinkYoutube,
      socialLinkFacebook,
      socialLinkTwitter,
      socialLinkNote,
      socialLinkWebSite
    } = formik.values
    return [
      {
        label: '所属部門',
        readElement: (
          <Typography fontSize="s" lh="tight">
            {department}
          </Typography>
        ),
        editElement: <Input {...formik.getFieldProps('department')} width="260px" placeholder="営業部" />
      },
      {
        label: '役職',
        readElement: (
          <Typography fontSize="s" lh="tight">
            {position}
          </Typography>
        ),
        editElement: <Input {...formik.getFieldProps('position')} width="260px" placeholder="リーダー" />
      },
      {
        label: '出身地',
        readElement: (
          <Typography fontSize="s" lh="tight">
            {birthplace}
          </Typography>
        ),
        editElement: <Input {...formik.getFieldProps('birthplace')} width="260px" placeholder="北海道" />
      },
      {
        label: '趣味',
        readElement: (
          <Typography fontSize="s" lh="tight">
            {hobby}
          </Typography>
        ),
        editElement: <Input {...formik.getFieldProps('hobby')} width="260px" placeholder="旅行・ゲーム" />
      },
      {
        label: '自己紹介',
        field: 'selfIntroduction',
        readElement: (
          <Typography fontSize="s" lh="relaxed">
            {selfIntroduction}
          </Typography>
        ),
        editElement: (
          <Box display="flex" flexDirection="column">
            <Textarea
              {...formik.getFieldProps('selfIntroduction')}
              multiline
              variant="outlined"
              minHeight="104px"
              placeholder="営業一筋で10年目です。日々変化する市場やトレンドを調べ、お客様の課題解決につながる情報をお伝えしたいと心がけております。岩田さんに任せて良かった！と言っていただけるよう、精一杯お手伝いさせていただきます。"
            />
            {formik.errors.selfIntroduction ? (
              <Box mt="8px" color={Colors.error.default}>
                {formik.errors.selfIntroduction}
              </Box>
            ) : null}
            <Box mt="8px" />
            <Typography variant="subtitle2" className={classes.textColor}>
              ※100文字以内で記入してください
            </Typography>
          </Box>
        )
      },
      {
        label: 'ソーシャルリンク',
        readElement: (
          <Box display="flex" mr="23px">
            <Box mr="16px" display="flex" alignItems="center">
              <SvgIcon
                key="youtube"
                variant="youtube"
                size="20px"
                color={socialLinkYoutube ? '#FF0302' : Colors.base.placeHolder}
              />
            </Box>
            <Box mr="16px" display="flex" alignItems="center">
              <SvgIcon
                key="facebook"
                variant="facebook"
                size="20px"
                color={socialLinkFacebook ? '#1877F2' : Colors.base.placeHolder}
              />
            </Box>
            <Box mr="16px" display="flex" alignItems="center">
              <SvgIcon
                key="twitter"
                variant="twitter"
                size="20px"
                color={socialLinkTwitter ? '#1D9BF0' : Colors.base.placeHolder}
              />
            </Box>
            <Box mr="16px" display="flex" alignItems="center">
              <SvgIcon
                key="note"
                variant="note"
                size="20px"
                color={socialLinkNote ? '#41C9B4' : Colors.base.placeHolder}
              />
            </Box>
            <Box mr="16px" display="flex" alignItems="center">
              <SvgIcon
                key="link"
                variant="link"
                size="20px"
                color={socialLinkWebSite ? Colors.accent.keyBlue.default : Colors.base.placeHolder}
              />
            </Box>
          </Box>
        ),
        editElement: (
          <Box>
            {shareList.map((s, i) => (
              <Box key={i} display="flex" flexDirection="column" mb="16px">
                <Typography fontSize="s" fontWeight="bold" lh="tight">
                  {labelFromSocialField(s)}
                </Typography>
                <Box mt="4px" />
                <Input {...formik.getFieldProps(s)} fullWidth />
              </Box>
            ))}
          </Box>
        )
      }
    ]
  }, [classes.textColor, formik])

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {rowData.map((data, index) => (
        <ProfileRow key={index} formik={formik} {...data} />
      ))}
    </Box>
  )
}

const labelFromSocialField = (field: string) => {
  switch (field) {
    case 'socialLinkYoutube':
      return 'Youtube'
    case 'socialLinkFacebook':
      return 'Facebook'
    case 'socialLinkNote':
      return 'note'
    case 'socialLinkTwitter':
      return 'Twitter'
    case 'socialLinkWebSite':
      return 'Webサイト'
    default:
      return field
  }
}
const shareList = [
  'socialLinkYoutube',
  'socialLinkFacebook',
  'socialLinkTwitter',
  'socialLinkNote',
  'socialLinkWebSite'
]
