/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SelectorItemExtendCompany
 */
export interface SelectorItemExtendCompany {
  /**
   *
   * @type {string}
   * @memberof SelectorItemExtendCompany
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SelectorItemExtendCompany
   */
  displayName?: string
  /**
   *
   * @type {string}
   * @memberof SelectorItemExtendCompany
   */
  domain?: string | null
}

export function SelectorItemExtendCompanyFromJSON(json: any): SelectorItemExtendCompany {
  return SelectorItemExtendCompanyFromJSONTyped(json, false)
}

export function SelectorItemExtendCompanyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SelectorItemExtendCompany {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
    domain: !exists(json, 'domain') ? undefined : json['domain']
  }
}

export function SelectorItemExtendCompanyToJSON(value?: SelectorItemExtendCompany | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    display_name: value.displayName,
    domain: value.domain
  }
}
