import { Box, ButtonBase, Slide } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { useGetDocument, useUpdateDocumentChat } from 'src/fixtures/modules/documents/hooks'
import { SettingToggleSectionCard } from '..'
import Image from 'next/image'
export interface DocumentSettingChatProps {
  open: boolean
  documentId: string
  onClose: () => void
}

export const DocumentSettingChat = ({ documentId, open, onClose }: DocumentSettingChatProps) => {
  const { handleUpdateDocumentChat } = useUpdateDocumentChat(documentId)
  const { data: document } = useGetDocument(documentId)
  const documentChatSetting = document?.documentChatSetting
  return (
    <Box flexShrink={0} overflow="auto" height>
      <Slide direction="left" in={open}>
        <Box width="100%" bgcolor={Colors.functional.background.default} p="18px 0px" color={Colors.base.black}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="8px" pl="18px" pr="20px">
            <Box display="flex" alignItems="center">
              <DynamicMuiIcon variant="chatBubble" size="20px" color="action" />
              <Box mr="6px" />
              <Typography variant="h5">チャット</Typography>
            </Box>
            <ButtonBase onClick={onClose}>
              <DynamicMuiIcon variant="close" size="20px" color="action" />
            </ButtonBase>
          </Box>
          {documentChatSetting && (
            <>
              <Box px="10px">
                <SettingToggleSectionCard
                  title="チャット機能"
                  checked={documentChatSetting.canChat!}
                  onChange={() => {
                    handleUpdateDocumentChat({
                      documentChatSetting: {
                        canChat: documentChatSetting?.canChat !== undefined ? !documentChatSetting?.canChat : false
                      }
                    })
                  }}
                />
              </Box>

              <Box px="20px">
                <Box mt="20px">
                  <Typography variant="body1" fontSize="s" letterSpacing="tight" lineheight="18.48px">
                    チャット機能を利用することで、閲覧者が資料閲覧画面からチャットによるコミュニケーションができます
                  </Typography>
                  <Box mt="16px" />
                  <Typography variant="body1" fontSize="s" letterSpacing="tight" lineheight="18.48px">
                    閲覧者が資料を見ていない時には、相手のメールアドレス宛にメッセージが届きます
                  </Typography>
                </Box>

                <Box sx={{ position: 'relative', width: '100%', height: 181, overflow: 'hidden', mt: '8px' }}>
                  <Image src={'/images/chattime.png'} layout="fill" objectFit="cover" />
                </Box>
              </Box>

              <Box mt="32px" />
            </>
          )}
        </Box>
      </Slide>
    </Box>
  )
}
