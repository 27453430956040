import { RequestBodyExportCommon } from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import { donwloadFileFromJob } from 'src/fixtures/utils/file'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetPorterHistoriesLatest } from '../recordPorterHistory/hooks'
import { EnumRecordPorterHistoryOperation } from '@noco/http-client/lib/noco'

// Company

export const useCreateCompaniesExport = (operation?: EnumRecordPorterHistoryOperation) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutatePorterHistoriesLatest } = useGetPorterHistoriesLatest(operation)
  const porterApi = nocoSDK.client?.userService.porterApi

  const handleCreateCompaniesExport = useCallback(
    async (requestBodyExportCommon: RequestBodyExportCommon, hasToast: boolean) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await porterApi?.ApiFactory(auth.token).userV1PorterExportCompaniesPost({ requestBodyExportCommon })
        await mutatePorterHistoriesLatest()
        if (hasToast) Toaster.success('エクスポートを開始しました。完了したらメールが送信されます。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create Companies Export' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, porterApi, mutatePorterHistoriesLatest]
  )

  return { error, isLoading, handleCreateCompaniesExport }
}

// Contact
export const useCreateContactsExport = (operation?: EnumRecordPorterHistoryOperation) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate: mutatePorterHistoriesLatest } = useGetPorterHistoriesLatest(operation)
  const porterApi = nocoSDK.client?.userService.porterApi

  const handleCreateContactsExport = useCallback(
    async (requestBodyExportCommon: RequestBodyExportCommon, hasToast: boolean) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        await porterApi?.ApiFactory(auth.token).userV1PorterExportContactsPost({ requestBodyExportCommon })
        await mutatePorterHistoriesLatest()
        if (hasToast) Toaster.success('エクスポートを開始しました。完了したらメールが送信されます。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create Contacts Export' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, porterApi, mutatePorterHistoriesLatest]
  )

  return { error, isLoading, handleCreateContactsExport }
}

// contacts_bulk_update
export const useBulkUpdateContacts = (operation?: EnumRecordPorterHistoryOperation) => {
  const { data: auth } = useAuthenticate()
  const { mutate: mutatePorterHistoriesLatest } = useGetPorterHistoriesLatest(operation)
  const porterApi = nocoSDK.client?.userService.porterApi
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const handleBulkUpdateContacts = useCallback(
    async file => {
      if (!auth) return
      setError(undefined)
      setIsLoading(true)
      try {
        await porterApi?.ApiFactory(auth.token).userV1PorterBulkUpdateContactsPost({
          file
        })
        await mutatePorterHistoriesLatest()
        Toaster.success('インポートを開始しました。完了後にメールが届きます。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Bulk Update Contacts' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, porterApi, mutatePorterHistoriesLatest]
  )
  return { error, isLoading, handleBulkUpdateContacts }
}

// companies_bulk_update
export const useBulkUpdateCompanies = (operation?: EnumRecordPorterHistoryOperation) => {
  const { data: auth } = useAuthenticate()
  const { mutate: mutatePorterHistoriesLatest } = useGetPorterHistoriesLatest(operation)
  const porterApi = nocoSDK.client?.userService.porterApi
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const handleBulkUpdateCompanies = useCallback(
    async file => {
      if (!auth) return
      setError(undefined)
      setIsLoading(true)
      try {
        await porterApi?.ApiFactory(auth.token).userV1PorterBulkUpdateCompaniesPost({
          file
        })
        await mutatePorterHistoriesLatest()
        Toaster.success('インポートを開始しました。完了後にメールが届きます。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Bulk Update Companies' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, porterApi, mutatePorterHistoriesLatest]
  )
  return { error, isLoading, handleBulkUpdateCompanies }
}

// format_export_import_v2
export const useCreateFormatExportContactCompany = () => {
  const { data: auth } = useAuthenticate()
  const porterApi = nocoSDK.client?.userService.porterApi

  const handleCreateFormatExportContactCompany = useCallback(async () => {
    if (!auth) return
    const res = await porterApi?.ApiFactory(auth.token).userV1PorterFormatExportContactCompaniesPost()
    donwloadFileFromJob(res!.data!.job!)
  }, [auth, porterApi])

  return { handleCreateFormatExportContactCompany }
}

// import_v2
export const useCreateImportContactCompany = (operation?: EnumRecordPorterHistoryOperation) => {
  const { data: auth } = useAuthenticate()
  const { mutate: mutatePorterHistoriesLatest } = useGetPorterHistoriesLatest(operation)
  const porterApi = nocoSDK.client?.userService.porterApi
  const [error, setError] = useState<Error | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const handleCreateImportContactCompany = useCallback(
    async file => {
      if (!auth) return
      setError(undefined)
      setIsLoading(true)
      try {
        await porterApi?.ApiFactory(auth.token).userV1PorterImportContactCompaniesPost({
          file
        })
        await mutatePorterHistoriesLatest()
        Toaster.success('インポートを開始しました。完了後にメールが届きます。')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create Import Contact Company' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, porterApi, mutatePorterHistoriesLatest]
  )
  return { error, isLoading, handleCreateImportContactCompany }
}
