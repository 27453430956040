/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ShareByLinkSiteRequestSiteShareSiteShareFormsInner,
  ShareByLinkSiteRequestSiteShareSiteShareFormsInnerFromJSON,
  ShareByLinkSiteRequestSiteShareSiteShareFormsInnerFromJSONTyped,
  ShareByLinkSiteRequestSiteShareSiteShareFormsInnerToJSON
} from './ShareByLinkSiteRequestSiteShareSiteShareFormsInner'

/**
 *
 * @export
 * @interface ShareByLinkSiteRequestSiteShare
 */
export interface ShareByLinkSiteRequestSiteShare {
  /**
   *
   * @type {string}
   * @memberof ShareByLinkSiteRequestSiteShare
   */
  expiredOn?: string | null
  /**
   *
   * @type {string}
   * @memberof ShareByLinkSiteRequestSiteShare
   */
  userId?: string
  /**
   *
   * @type {boolean}
   * @memberof ShareByLinkSiteRequestSiteShare
   */
  isActive?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ShareByLinkSiteRequestSiteShare
   */
  isFormActive?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ShareByLinkSiteRequestSiteShare
   */
  isAuthCodeActive?: boolean
  /**
   *
   * @type {Array<ShareByLinkSiteRequestSiteShareSiteShareFormsInner>}
   * @memberof ShareByLinkSiteRequestSiteShare
   */
  siteShareForms?: Array<ShareByLinkSiteRequestSiteShareSiteShareFormsInner>
}

export function ShareByLinkSiteRequestSiteShareFromJSON(json: any): ShareByLinkSiteRequestSiteShare {
  return ShareByLinkSiteRequestSiteShareFromJSONTyped(json, false)
}

export function ShareByLinkSiteRequestSiteShareFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShareByLinkSiteRequestSiteShare {
  if (json === undefined || json === null) {
    return json
  }
  return {
    expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
    isFormActive: !exists(json, 'is_form_active') ? undefined : json['is_form_active'],
    isAuthCodeActive: !exists(json, 'is_auth_code_active') ? undefined : json['is_auth_code_active'],
    siteShareForms: !exists(json, 'site_share_forms')
      ? undefined
      : (json['site_share_forms'] as Array<any>).map(ShareByLinkSiteRequestSiteShareSiteShareFormsInnerFromJSON)
  }
}

export function ShareByLinkSiteRequestSiteShareToJSON(value?: ShareByLinkSiteRequestSiteShare | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    expired_on: value.expiredOn,
    user_id: value.userId,
    is_active: value.isActive,
    is_form_active: value.isFormActive,
    is_auth_code_active: value.isAuthCodeActive,
    site_share_forms:
      value.siteShareForms === undefined
        ? undefined
        : (value.siteShareForms as Array<any>).map(ShareByLinkSiteRequestSiteShareSiteShareFormsInnerToJSON)
  }
}
