/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPlanDetail,
  OrganizationPlanDetailFromJSON,
  OrganizationPlanDetailFromJSONTyped,
  OrganizationPlanDetailToJSON
} from './OrganizationPlanDetail'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanResponseData
 */
export interface GetOrganizationPaymentPlanResponseData {
  /**
   *
   * @type {OrganizationPlanDetail}
   * @memberof GetOrganizationPaymentPlanResponseData
   */
  plan: OrganizationPlanDetail
}

export function GetOrganizationPaymentPlanResponseDataFromJSON(json: any): GetOrganizationPaymentPlanResponseData {
  return GetOrganizationPaymentPlanResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    plan: OrganizationPlanDetailFromJSON(json['plan'])
  }
}

export function GetOrganizationPaymentPlanResponseDataToJSON(
  value?: GetOrganizationPaymentPlanResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan: OrganizationPlanDetailToJSON(value.plan)
  }
}
