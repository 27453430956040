import React, { ReactElement, useCallback, useState } from 'react'
import { Box, ButtonBase, createStyles, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Tooltip, Typography } from 'src/components/atoms'

export interface ActionMenuItemType {
  label: string
  icon?: ReactElement
  disabled?: boolean
  toolTipText?: string
  onClick: () => void
  color?: string
}

export interface ActionMenuProps {
  menuItemList: ActionMenuItemType[]
  icon?: ReactElement
}

const useStyles = makeStyles(() => {
  return createStyles({
    MuiList: {
      '& .MuiMenu-paper': {
        borderRadius: '4px',
        boxShadow: '0 0 1px 0 #0000000A, 0 0 2px 0 #0000000F, 0 4px 8px 0 #0000000F',
        border: `1px solid ${Colors.background.gray}`
      },
      '& .MuiMenu-list': {
        padding: '0'
      }
    },
    MuiListItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '10px 12px',

      '&:nth-child(n+2)': {
        borderTop: `1px solid ${Colors.background.gray}`
      }
    }
  })
})

const DEFAULT_ACTION_MENU_ITEM_COLOR = Colors.base.black

export const ActionMenu = ({ menuItemList, icon }: ActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget), [])
  const handleClose = useCallback(() => setAnchorEl(null), [])
  const classes = useStyles()

  return (
    <>
      <ButtonBase
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {icon ? icon : <DynamicMuiIcon variant="dots" color="action" />}
      </ButtonBase>
      <Menu
        className={classes.MuiList}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        getContentAnchorEl={null}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItemList.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                if (item.disabled) return

                setAnchorEl(null)
                item.onClick()
              }}
              className={classes.MuiListItem}
              disabled={item.disabled}
            >
              <Box color={Colors.base.middleGray} display="flex" alignItems="center">
                {item.icon && (
                  <>
                    {item.icon}
                    <Box width="4px" />
                  </>
                )}
                <Box color={item.color || DEFAULT_ACTION_MENU_ITEM_COLOR}>
                  {item.toolTipText ? (
                    <Tooltip content={item.toolTipText}>
                      <Typography fontSize="s" lineheight="14px">
                        {item.label}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography fontSize="s" lineheight="14px">
                      {item.label}
                    </Typography>
                  )}
                </Box>
              </Box>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}
