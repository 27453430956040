/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ArchiveContactsRequest,
  ArchiveContactsRequestFromJSON,
  ArchiveContactsRequestToJSON,
  ArchiveContactsResponse,
  ArchiveContactsResponseFromJSON,
  ArchiveContactsResponseToJSON,
  ContactTimelinesResponse,
  ContactTimelinesResponseFromJSON,
  ContactTimelinesResponseToJSON,
  CreateContactMemoRequest,
  CreateContactMemoRequestFromJSON,
  CreateContactMemoRequestToJSON,
  CreateContactMemoResponse,
  CreateContactMemoResponseFromJSON,
  CreateContactMemoResponseToJSON,
  CreateContactRequest,
  CreateContactRequestFromJSON,
  CreateContactRequestToJSON,
  CreateContactResponse,
  CreateContactResponseFromJSON,
  CreateContactResponseToJSON,
  CreateWithEmailAddressContactRequest,
  CreateWithEmailAddressContactRequestFromJSON,
  CreateWithEmailAddressContactRequestToJSON,
  CreateWithEmailAddressContactResponse,
  CreateWithEmailAddressContactResponseFromJSON,
  CreateWithEmailAddressContactResponseToJSON,
  GetContactContactListsResponse,
  GetContactContactListsResponseFromJSON,
  GetContactContactListsResponseToJSON,
  GetContactMaterialsExpiredListResponse,
  GetContactMaterialsExpiredListResponseFromJSON,
  GetContactMaterialsExpiredListResponseToJSON,
  GetContactMaterialsResponse,
  GetContactMaterialsResponseFromJSON,
  GetContactMaterialsResponseToJSON,
  GetContactResponse,
  GetContactResponseFromJSON,
  GetContactResponseToJSON,
  GetContactsResponse,
  GetContactsResponseFromJSON,
  GetContactsResponseToJSON,
  UpdateContactCompanyRequest,
  UpdateContactCompanyRequestFromJSON,
  UpdateContactCompanyRequestToJSON,
  UpdateContactCompanyResponse,
  UpdateContactCompanyResponseFromJSON,
  UpdateContactCompanyResponseToJSON,
  UpdateContactNoReplyRequiredChatResponse,
  UpdateContactNoReplyRequiredChatResponseFromJSON,
  UpdateContactNoReplyRequiredChatResponseToJSON,
  UpdateContactRequest,
  UpdateContactRequestFromJSON,
  UpdateContactRequestToJSON,
  UpdateContactResponse,
  UpdateContactResponseFromJSON,
  UpdateContactResponseToJSON
} from '../models'

export interface UserV1ContactsArchivePutRequest {
  archiveContactsRequest?: ArchiveContactsRequest
}

export interface UserV1ContactsContactIdCompanyPutRequest {
  contactId: string
  updateContactCompanyRequest?: UpdateContactCompanyRequest
}

export interface UserV1ContactsContactIdGetRequest {
  contactId: string
}

export interface UserV1ContactsContactIdListsGetRequest {
  contactId: string
}

export interface UserV1ContactsContactIdMaterialsExpiredGetRequest {
  contactId: string
}

export interface UserV1ContactsContactIdMaterialsGetRequest {
  contactId: string
  page?: number
  per?: number
}

export interface UserV1ContactsContactIdMemosPostRequest {
  contactId: string
  createContactMemoRequest?: CreateContactMemoRequest
}

export interface UserV1ContactsContactIdNoReplyRequiredChatPutRequest {
  contactId: string
}

export interface UserV1ContactsContactIdPutRequest {
  contactId: string
  updateContactRequest?: UpdateContactRequest
}

export interface UserV1ContactsContactIdTimelinesGetRequest {
  contactId: string
  page?: number
  per?: number
}

export interface UserV1ContactsCreateWithEmailAddressPostRequest {
  createWithEmailAddressContactRequest?: CreateWithEmailAddressContactRequest
}

export interface UserV1ContactsGetRequest {
  text?: string
  hasCompany?: boolean
  companyId?: string
  contactListId?: string
  userId?: string
  userIds?: Array<string>
  documentId?: string
  documentIds?: Array<string>
  siteId?: string
  siteIds?: Array<string>
  createdOnFrom?: string
  createdOnTo?: string
  isArchived?: boolean
  page?: number
  per?: number
  orderBy?: string
}

export interface UserV1ContactsPostRequest {
  createContactRequest?: CreateContactRequest
}

/**
 *
 */
export class ApiUserV1ContactApi extends runtime.BaseAPI {
  /**
   * 連絡先のアーカイブ設定
   * archive
   */
  async userV1ContactsArchivePutRaw(
    requestParameters: UserV1ContactsArchivePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ArchiveContactsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/archive`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ArchiveContactsRequestToJSON(requestParameters.archiveContactsRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ArchiveContactsResponseFromJSON(jsonValue))
  }

  /**
   * 連絡先のアーカイブ設定
   * archive
   */
  async userV1ContactsArchivePut(
    requestParameters: UserV1ContactsArchivePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ArchiveContactsResponse> {
    const response = await this.userV1ContactsArchivePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクトの関連する会社を変更する
   * company
   */
  async userV1ContactsContactIdCompanyPutRaw(
    requestParameters: UserV1ContactsContactIdCompanyPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateContactCompanyResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdCompanyPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/company`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateContactCompanyRequestToJSON(requestParameters.updateContactCompanyRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateContactCompanyResponseFromJSON(jsonValue))
  }

  /**
   * コンタクトの関連する会社を変更する
   * company
   */
  async userV1ContactsContactIdCompanyPut(
    requestParameters: UserV1ContactsContactIdCompanyPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateContactCompanyResponse> {
    const response = await this.userV1ContactsContactIdCompanyPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 連絡先の詳細情報を取得する
   * show
   */
  async userV1ContactsContactIdGetRaw(
    requestParameters: UserV1ContactsContactIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactResponseFromJSON(jsonValue))
  }

  /**
   * 連絡先の詳細情報を取得する
   * show
   */
  async userV1ContactsContactIdGet(
    requestParameters: UserV1ContactsContactIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactResponse> {
    const response = await this.userV1ContactsContactIdGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 連絡先が所属している連絡先リスト一覧を取得
   * list
   */
  async userV1ContactsContactIdListsGetRaw(
    requestParameters: UserV1ContactsContactIdListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactContactListsResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdListsGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/lists`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactContactListsResponseFromJSON(jsonValue))
  }

  /**
   * 連絡先が所属している連絡先リスト一覧を取得
   * list
   */
  async userV1ContactsContactIdListsGet(
    requestParameters: UserV1ContactsContactIdListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactContactListsResponse> {
    const response = await this.userV1ContactsContactIdListsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 連絡先でコンテンツの有効期限が切れてる一覧を取得
   * expired
   */
  async userV1ContactsContactIdMaterialsExpiredGetRaw(
    requestParameters: UserV1ContactsContactIdMaterialsExpiredGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactMaterialsExpiredListResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMaterialsExpiredGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/materials/expired`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactMaterialsExpiredListResponseFromJSON(jsonValue))
  }

  /**
   * 連絡先でコンテンツの有効期限が切れてる一覧を取得
   * expired
   */
  async userV1ContactsContactIdMaterialsExpiredGet(
    requestParameters: UserV1ContactsContactIdMaterialsExpiredGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactMaterialsExpiredListResponse> {
    const response = await this.userV1ContactsContactIdMaterialsExpiredGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 連絡先のコンテンツ一覧を取得
   * index
   */
  async userV1ContactsContactIdMaterialsGetRaw(
    requestParameters: UserV1ContactsContactIdMaterialsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactMaterialsResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMaterialsGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/materials`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactMaterialsResponseFromJSON(jsonValue))
  }

  /**
   * 連絡先のコンテンツ一覧を取得
   * index
   */
  async userV1ContactsContactIdMaterialsGet(
    requestParameters: UserV1ContactsContactIdMaterialsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactMaterialsResponse> {
    const response = await this.userV1ContactsContactIdMaterialsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async userV1ContactsContactIdMemosPostRaw(
    requestParameters: UserV1ContactsContactIdMemosPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateContactMemoResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdMemosPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/memos`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateContactMemoRequestToJSON(requestParameters.createContactMemoRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateContactMemoResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1ContactsContactIdMemosPost(
    requestParameters: UserV1ContactsContactIdMemosPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateContactMemoResponse> {
    const response = await this.userV1ContactsContactIdMemosPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクトのチャットを返信不要にする
   * no_reply_required_chat
   */
  async userV1ContactsContactIdNoReplyRequiredChatPutRaw(
    requestParameters: UserV1ContactsContactIdNoReplyRequiredChatPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateContactNoReplyRequiredChatResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdNoReplyRequiredChatPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/no_reply_required_chat`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateContactNoReplyRequiredChatResponseFromJSON(jsonValue)
    )
  }

  /**
   * コンタクトのチャットを返信不要にする
   * no_reply_required_chat
   */
  async userV1ContactsContactIdNoReplyRequiredChatPut(
    requestParameters: UserV1ContactsContactIdNoReplyRequiredChatPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateContactNoReplyRequiredChatResponse> {
    const response = await this.userV1ContactsContactIdNoReplyRequiredChatPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト情報の編集をする
   * update
   */
  async userV1ContactsContactIdPutRaw(
    requestParameters: UserV1ContactsContactIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateContactResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateContactRequestToJSON(requestParameters.updateContactRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateContactResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト情報の編集をする
   * update
   */
  async userV1ContactsContactIdPut(
    requestParameters: UserV1ContactsContactIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateContactResponse> {
    const response = await this.userV1ContactsContactIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async userV1ContactsContactIdTimelinesGetRaw(
    requestParameters: UserV1ContactsContactIdTimelinesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ContactTimelinesResponse>> {
    if (requestParameters.contactId === null || requestParameters.contactId === undefined) {
      throw new runtime.RequiredError(
        'contactId',
        'Required parameter requestParameters.contactId was null or undefined when calling userV1ContactsContactIdTimelinesGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts/{contact_id}/timelines`.replace(
          `{${'contact_id'}}`,
          encodeURIComponent(String(requestParameters.contactId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ContactTimelinesResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1ContactsContactIdTimelinesGet(
    requestParameters: UserV1ContactsContactIdTimelinesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ContactTimelinesResponse> {
    const response = await this.userV1ContactsContactIdTimelinesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * メールアドレスから連絡先を作成
   * create_with_email_address
   */
  async userV1ContactsCreateWithEmailAddressPostRaw(
    requestParameters: UserV1ContactsCreateWithEmailAddressPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateWithEmailAddressContactResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts/create_with_email_address`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateWithEmailAddressContactRequestToJSON(requestParameters.createWithEmailAddressContactRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateWithEmailAddressContactResponseFromJSON(jsonValue))
  }

  /**
   * メールアドレスから連絡先を作成
   * create_with_email_address
   */
  async userV1ContactsCreateWithEmailAddressPost(
    requestParameters: UserV1ContactsCreateWithEmailAddressPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateWithEmailAddressContactResponse> {
    const response = await this.userV1ContactsCreateWithEmailAddressPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト情報の一覧を取得する
   * index
   */
  async userV1ContactsGetRaw(
    requestParameters: UserV1ContactsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactsResponse>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.hasCompany !== undefined) {
      queryParameters['has_company'] = requestParameters.hasCompany
    }

    if (requestParameters.companyId !== undefined) {
      queryParameters['company_id'] = requestParameters.companyId
    }

    if (requestParameters.contactListId !== undefined) {
      queryParameters['contact_list_id'] = requestParameters.contactListId
    }

    if (requestParameters.userId !== undefined) {
      queryParameters['user_id'] = requestParameters.userId
    }

    if (requestParameters.userIds) {
      queryParameters['user_ids[]'] = requestParameters.userIds
    }

    if (requestParameters.documentId !== undefined) {
      queryParameters['document_id'] = requestParameters.documentId
    }

    if (requestParameters.documentIds) {
      queryParameters['document_ids[]'] = requestParameters.documentIds
    }

    if (requestParameters.siteId !== undefined) {
      queryParameters['site_id'] = requestParameters.siteId
    }

    if (requestParameters.siteIds) {
      queryParameters['site_ids[]'] = requestParameters.siteIds
    }

    if (requestParameters.createdOnFrom !== undefined) {
      queryParameters['created_on_from'] = requestParameters.createdOnFrom
    }

    if (requestParameters.createdOnTo !== undefined) {
      queryParameters['created_on_to'] = requestParameters.createdOnTo
    }

    if (requestParameters.isArchived !== undefined) {
      queryParameters['is_archived'] = requestParameters.isArchived
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contacts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactsResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト情報の一覧を取得する
   * index
   */
  async userV1ContactsGet(
    requestParameters: UserV1ContactsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactsResponse> {
    const response = await this.userV1ContactsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト情報を作成する
   * create
   */
  async userV1ContactsPostRaw(
    requestParameters: UserV1ContactsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateContactResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contacts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateContactRequestToJSON(requestParameters.createContactRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateContactResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト情報を作成する
   * create
   */
  async userV1ContactsPost(
    requestParameters: UserV1ContactsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateContactResponse> {
    const response = await this.userV1ContactsPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
