import { Box, ButtonBase, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { Colors, DynamicMuiIcon, IconText, Typography } from 'src/components/atoms'
import { OptionData, Popover } from 'src/components/molecules'
import { useListSelectorDocuments } from 'src/fixtures/modules/selector/hooks'
import { publicationStatusIconTextMap, publicationStatusTextMap } from 'src/fixtures/utils/text'

const useStyles = makeStyles(() =>
  createStyles({
    buttonBase: {
      display: 'block',
      width: '100%',
      '&:hover': {
        backgroundColor: Colors.hover.white.default,
        boxShadow: `0 0 0 1px ${Colors.accent.keyPurple.default} inset`,
        borderRadius: '6px',
        cursor: 'pointer'
      }
    }
  })
)

export interface DocumentAddProps {
  onSelect: (value: string) => void
  selectedDocumentIds?: string[]
}

export interface DocumentOptionData {
  value: string
  label: string
  publishRange: 'all' | 'stricted'
}

export const DocumentAdd = ({ onSelect, selectedDocumentIds }: DocumentAddProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [keyword, SetKeyword] = useState<string>('')
  const { data: res } = useListSelectorDocuments({ text: keyword })
  const optionList = useMemo(() => {
    let _list = res?.selectorItems || []
    return _list.filter(item => selectedDocumentIds?.indexOf(item.id!) === -1)
  }, [res, selectedDocumentIds])
  const open = Boolean(anchorEl)
  const id = useMemo(() => (open ? 'simple-popover' : undefined), [open])
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSelect = useCallback(
    (option: OptionData) => {
      onSelect(option.value)
    },
    [onSelect]
  )

  const handleChangeKeyword = useCallback(value => {
    SetKeyword(value)
  }, [])

  const optionListWithSectionInfo = useMemo(() => {
    const _items: OptionData[] = optionList.map(item => {
      return {
        value: item.id!,
        label: item.text!,
        rightSection: item.extend?.publicationStatus && (
          <Box width="80px" flex="0 0 auto" pl="8px">
            <IconText
              icon={publicationStatusIconTextMap[item.extend?.publicationStatus]}
              text={publicationStatusTextMap[item.extend?.publicationStatus]}
            />
          </Box>
        )
      }
    })
    return _items
  }, [optionList])

  return (
    <>
      <ButtonBase className={classes.buttonBase} onClick={handleClick}>
        <Box
          py="50px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          borderRadius="6px"
          border={`1px solid ${Colors.accent.keyPurple.default}`}
          color={Colors.accent.keyPurple.default}
        >
          <DynamicMuiIcon variant="document" color="inherit" size="40px" />
          <Box mt="20px" display="flex" alignItems="center">
            <DynamicMuiIcon variant="add" size="20px" color="inherit" />
            <Box width="6px" />
            <Typography fontSize="m" fontWeight="bold">
              資料を追加
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onChangeKeyword={handleChangeKeyword}
        onSelect={handleSelect}
        options={optionListWithSectionInfo}
      />
    </>
  )
}
