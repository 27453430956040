/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumVisitorMaterialableTrackEvent,
  EnumVisitorMaterialableTrackEventFromJSON,
  EnumVisitorMaterialableTrackEventFromJSONTyped,
  EnumVisitorMaterialableTrackEventToJSON
} from './EnumVisitorMaterialableTrackEvent'

/**
 *
 * @export
 * @interface CreateMaterialableTrackRequest
 */
export interface CreateMaterialableTrackRequest {
  /**
   *
   * @type {string}
   * @memberof CreateMaterialableTrackRequest
   */
  documentId?: string
  /**
   *
   * @type {string}
   * @memberof CreateMaterialableTrackRequest
   */
  siteId?: string
  /**
   *
   * @type {EnumVisitorMaterialableTrackEvent}
   * @memberof CreateMaterialableTrackRequest
   */
  event: EnumVisitorMaterialableTrackEvent
  /**
   *
   * @type {number}
   * @memberof CreateMaterialableTrackRequest
   */
  numberOfPage?: number
}

export function CreateMaterialableTrackRequestFromJSON(json: any): CreateMaterialableTrackRequest {
  return CreateMaterialableTrackRequestFromJSONTyped(json, false)
}

export function CreateMaterialableTrackRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateMaterialableTrackRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    siteId: !exists(json, 'site_id') ? undefined : json['site_id'],
    event: EnumVisitorMaterialableTrackEventFromJSON(json['event']),
    numberOfPage: !exists(json, 'number_of_page') ? undefined : json['number_of_page']
  }
}

export function CreateMaterialableTrackRequestToJSON(value?: CreateMaterialableTrackRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_id: value.documentId,
    site_id: value.siteId,
    event: EnumVisitorMaterialableTrackEventToJSON(value.event),
    number_of_page: value.numberOfPage
  }
}
