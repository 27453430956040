/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CreateOrganizationPaymentScheduleBillingInvoiceRequest,
  CreateOrganizationPaymentScheduleBillingInvoiceRequestFromJSON,
  CreateOrganizationPaymentScheduleBillingInvoiceRequestToJSON,
  CreateOrganizationPaymentScheduleBillingInvoiceResponse,
  CreateOrganizationPaymentScheduleBillingInvoiceResponseFromJSON,
  CreateOrganizationPaymentScheduleBillingInvoiceResponseToJSON,
  DeleteOrganizationPaymentCancelResponse,
  DeleteOrganizationPaymentCancelResponseFromJSON,
  DeleteOrganizationPaymentCancelResponseToJSON,
  GetOrganizationPaymentAddonUserOptionResponse,
  GetOrganizationPaymentAddonUserOptionResponseFromJSON,
  GetOrganizationPaymentAddonUserOptionResponseToJSON,
  GetOrganizationPaymentBillingInfoResponse,
  GetOrganizationPaymentBillingInfoResponseFromJSON,
  GetOrganizationPaymentBillingInfoResponseToJSON,
  GetOrganizationPaymentBillingTransactionsResponse,
  GetOrganizationPaymentBillingTransactionsResponseFromJSON,
  GetOrganizationPaymentBillingTransactionsResponseToJSON,
  GetOrganizationPaymentCurrentPlanResponse,
  GetOrganizationPaymentCurrentPlanResponseFromJSON,
  GetOrganizationPaymentCurrentPlanResponseToJSON,
  GetOrganizationPaymentPlanCheckUsageLimit,
  GetOrganizationPaymentPlanCheckUsageLimitFromJSON,
  GetOrganizationPaymentPlanCheckUsageLimitToJSON,
  GetOrganizationPaymentPlanResponse,
  GetOrganizationPaymentPlanResponseFromJSON,
  GetOrganizationPaymentPlanResponseToJSON,
  GetOrganizationPaymentPlanTransactionsResponse,
  GetOrganizationPaymentPlanTransactionsResponseFromJSON,
  GetOrganizationPaymentPlanTransactionsResponseToJSON,
  GetOrganizationPaymentPlansResponse,
  GetOrganizationPaymentPlansResponseFromJSON,
  GetOrganizationPaymentPlansResponseToJSON,
  GetOrganizationPaymentPromotionCodeResponse,
  GetOrganizationPaymentPromotionCodeResponseFromJSON,
  GetOrganizationPaymentPromotionCodeResponseToJSON,
  UpdateOrganizationPaymentAddonUserOptionRequest,
  UpdateOrganizationPaymentAddonUserOptionRequestFromJSON,
  UpdateOrganizationPaymentAddonUserOptionRequestToJSON,
  UpdateOrganizationPaymentAddonUserOptionResponse,
  UpdateOrganizationPaymentAddonUserOptionResponseFromJSON,
  UpdateOrganizationPaymentAddonUserOptionResponseToJSON,
  UpdateOrganizationPaymentApplyPlanFreeRequest,
  UpdateOrganizationPaymentApplyPlanFreeRequestFromJSON,
  UpdateOrganizationPaymentApplyPlanFreeRequestToJSON,
  UpdateOrganizationPaymentApplyPlanPaidRequest,
  UpdateOrganizationPaymentApplyPlanPaidRequestFromJSON,
  UpdateOrganizationPaymentApplyPlanPaidRequestToJSON,
  UpdateOrganizationPaymentCancelRequest,
  UpdateOrganizationPaymentCancelRequestFromJSON,
  UpdateOrganizationPaymentCancelRequestToJSON,
  UpdateOrganizationPaymentCancelResponse,
  UpdateOrganizationPaymentCancelResponseFromJSON,
  UpdateOrganizationPaymentCancelResponseToJSON,
  UpdateOrganizationPaymentCurrentPlanRequest,
  UpdateOrganizationPaymentCurrentPlanRequestFromJSON,
  UpdateOrganizationPaymentCurrentPlanRequestToJSON,
  UpdateOrganizationPaymentCurrentPlanResponse,
  UpdateOrganizationPaymentCurrentPlanResponseFromJSON,
  UpdateOrganizationPaymentCurrentPlanResponseToJSON,
  UpdateOrganizationPaymentDiscountRequest,
  UpdateOrganizationPaymentDiscountRequestFromJSON,
  UpdateOrganizationPaymentDiscountRequestToJSON,
  UpdateOrganizationPaymentDiscountResponse,
  UpdateOrganizationPaymentDiscountResponseFromJSON,
  UpdateOrganizationPaymentDiscountResponseToJSON,
  UpdateOrganizationPaymentPlanFreeResponse,
  UpdateOrganizationPaymentPlanFreeResponseFromJSON,
  UpdateOrganizationPaymentPlanFreeResponseToJSON,
  UpdateOrganizationPaymentPlanPaidResponse,
  UpdateOrganizationPaymentPlanPaidResponseFromJSON,
  UpdateOrganizationPaymentPlanPaidResponseToJSON
} from '../models'

export interface UserV1OrganizationPaymentAddonUserOptionPatchRequest {
  updateOrganizationPaymentAddonUserOptionRequest?: UpdateOrganizationPaymentAddonUserOptionRequest
}

export interface UserV1OrganizationPaymentBillingInfoFreePutRequest {
  updateOrganizationPaymentApplyPlanFreeRequest?: UpdateOrganizationPaymentApplyPlanFreeRequest
}

export interface UserV1OrganizationPaymentBillingInfoPaidPutRequest {
  updateOrganizationPaymentApplyPlanPaidRequest?: UpdateOrganizationPaymentApplyPlanPaidRequest
}

export interface UserV1OrganizationPaymentCancelPatchRequest {
  updateOrganizationPaymentCancelRequest?: UpdateOrganizationPaymentCancelRequest
}

export interface UserV1OrganizationPaymentCouponsCouponCodeGetRequest {
  couponCode: string
  incomingPlanId?: string
}

export interface UserV1OrganizationPaymentCurrentPlanPatchRequest {
  updateOrganizationPaymentCurrentPlanRequest?: UpdateOrganizationPaymentCurrentPlanRequest
}

export interface UserV1OrganizationPaymentDiscountPutRequest {
  updateOrganizationPaymentDiscountRequest?: UpdateOrganizationPaymentDiscountRequest
}

export interface UserV1OrganizationPaymentPlansPlanIdCheckUsageLimitGetRequest {
  planId: string
}

export interface UserV1OrganizationPaymentPlansPlanIdGetRequest {
  planId: string
}

export interface UserV1OrganizationPaymentScheduleBillingInvoicePostRequest {
  createOrganizationPaymentScheduleBillingInvoiceRequest?: CreateOrganizationPaymentScheduleBillingInvoiceRequest
}

/**
 *
 */
export class ApiUserV1OrganizationPaymentApi extends runtime.BaseAPI {
  /**
   * ユーザー追加オプション情報
   * show
   */
  async userV1OrganizationPaymentAddonUserOptionGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentAddonUserOptionResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/addon/user_option`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetOrganizationPaymentAddonUserOptionResponseFromJSON(jsonValue)
    )
  }

  /**
   * ユーザー追加オプション情報
   * show
   */
  async userV1OrganizationPaymentAddonUserOptionGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentAddonUserOptionResponse> {
    const response = await this.userV1OrganizationPaymentAddonUserOptionGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * ユーザー追加オプションの更新
   * update
   */
  async userV1OrganizationPaymentAddonUserOptionPatchRaw(
    requestParameters: UserV1OrganizationPaymentAddonUserOptionPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationPaymentAddonUserOptionResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/addon/user_option`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationPaymentAddonUserOptionRequestToJSON(
          requestParameters.updateOrganizationPaymentAddonUserOptionRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationPaymentAddonUserOptionResponseFromJSON(jsonValue)
    )
  }

  /**
   * ユーザー追加オプションの更新
   * update
   */
  async userV1OrganizationPaymentAddonUserOptionPatch(
    requestParameters: UserV1OrganizationPaymentAddonUserOptionPatchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationPaymentAddonUserOptionResponse> {
    const response = await this.userV1OrganizationPaymentAddonUserOptionPatchRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 請求先情報登録（無料）
   * update
   */
  async userV1OrganizationPaymentBillingInfoFreePutRaw(
    requestParameters: UserV1OrganizationPaymentBillingInfoFreePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationPaymentPlanFreeResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/billing_info/free`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationPaymentApplyPlanFreeRequestToJSON(
          requestParameters.updateOrganizationPaymentApplyPlanFreeRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationPaymentPlanFreeResponseFromJSON(jsonValue)
    )
  }

  /**
   * 請求先情報登録（無料）
   * update
   */
  async userV1OrganizationPaymentBillingInfoFreePut(
    requestParameters: UserV1OrganizationPaymentBillingInfoFreePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationPaymentPlanFreeResponse> {
    const response = await this.userV1OrganizationPaymentBillingInfoFreePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * ご請求先情報
   * show
   */
  async userV1OrganizationPaymentBillingInfoGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentBillingInfoResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/billing_info`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetOrganizationPaymentBillingInfoResponseFromJSON(jsonValue)
    )
  }

  /**
   * ご請求先情報
   * show
   */
  async userV1OrganizationPaymentBillingInfoGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentBillingInfoResponse> {
    const response = await this.userV1OrganizationPaymentBillingInfoGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * 請求先情報登録（有料）
   * update
   */
  async userV1OrganizationPaymentBillingInfoPaidPutRaw(
    requestParameters: UserV1OrganizationPaymentBillingInfoPaidPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationPaymentPlanPaidResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/billing_info/paid`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationPaymentApplyPlanPaidRequestToJSON(
          requestParameters.updateOrganizationPaymentApplyPlanPaidRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationPaymentPlanPaidResponseFromJSON(jsonValue)
    )
  }

  /**
   * 請求先情報登録（有料）
   * update
   */
  async userV1OrganizationPaymentBillingInfoPaidPut(
    requestParameters: UserV1OrganizationPaymentBillingInfoPaidPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationPaymentPlanPaidResponse> {
    const response = await this.userV1OrganizationPaymentBillingInfoPaidPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * お支払い履歴
   * index
   */
  async userV1OrganizationPaymentBillingTransactionsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentBillingTransactionsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/billing_transactions`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetOrganizationPaymentBillingTransactionsResponseFromJSON(jsonValue)
    )
  }

  /**
   * お支払い履歴
   * index
   */
  async userV1OrganizationPaymentBillingTransactionsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentBillingTransactionsResponse> {
    const response = await this.userV1OrganizationPaymentBillingTransactionsGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * 解約のキャンセル（有料プランをご契約中のチームのみ）
   * delete
   */
  async userV1OrganizationPaymentCancelDeleteRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<DeleteOrganizationPaymentCancelResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/cancel`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      DeleteOrganizationPaymentCancelResponseFromJSON(jsonValue)
    )
  }

  /**
   * 解約のキャンセル（有料プランをご契約中のチームのみ）
   * delete
   */
  async userV1OrganizationPaymentCancelDelete(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<DeleteOrganizationPaymentCancelResponse> {
    const response = await this.userV1OrganizationPaymentCancelDeleteRaw(initOverrides)
    return await response.value()
  }

  /**
   * 解約申請
   * update
   */
  async userV1OrganizationPaymentCancelPatchRaw(
    requestParameters: UserV1OrganizationPaymentCancelPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationPaymentCancelResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/cancel`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationPaymentCancelRequestToJSON(requestParameters.updateOrganizationPaymentCancelRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationPaymentCancelResponseFromJSON(jsonValue)
    )
  }

  /**
   * 解約申請
   * update
   */
  async userV1OrganizationPaymentCancelPatch(
    requestParameters: UserV1OrganizationPaymentCancelPatchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationPaymentCancelResponse> {
    const response = await this.userV1OrganizationPaymentCancelPatchRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * クーポンの確認
   * show
   */
  async userV1OrganizationPaymentCouponsCouponCodeGetRaw(
    requestParameters: UserV1OrganizationPaymentCouponsCouponCodeGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentPromotionCodeResponse>> {
    if (requestParameters.couponCode === null || requestParameters.couponCode === undefined) {
      throw new runtime.RequiredError(
        'couponCode',
        'Required parameter requestParameters.couponCode was null or undefined when calling userV1OrganizationPaymentCouponsCouponCodeGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.incomingPlanId !== undefined) {
      queryParameters['incoming_plan_id'] = requestParameters.incomingPlanId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/coupons/{coupon_code}`.replace(
          `{${'coupon_code'}}`,
          encodeURIComponent(String(requestParameters.couponCode))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetOrganizationPaymentPromotionCodeResponseFromJSON(jsonValue)
    )
  }

  /**
   * クーポンの確認
   * show
   */
  async userV1OrganizationPaymentCouponsCouponCodeGet(
    requestParameters: UserV1OrganizationPaymentCouponsCouponCodeGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentPromotionCodeResponse> {
    const response = await this.userV1OrganizationPaymentCouponsCouponCodeGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 現在のプラン
   * show
   */
  async userV1OrganizationPaymentCurrentPlanGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentCurrentPlanResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/current_plan`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetOrganizationPaymentCurrentPlanResponseFromJSON(jsonValue)
    )
  }

  /**
   * 現在のプラン
   * show
   */
  async userV1OrganizationPaymentCurrentPlanGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentCurrentPlanResponse> {
    const response = await this.userV1OrganizationPaymentCurrentPlanGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * プラン変更
   * update
   */
  async userV1OrganizationPaymentCurrentPlanPatchRaw(
    requestParameters: UserV1OrganizationPaymentCurrentPlanPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationPaymentCurrentPlanResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/current_plan`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationPaymentCurrentPlanRequestToJSON(
          requestParameters.updateOrganizationPaymentCurrentPlanRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationPaymentCurrentPlanResponseFromJSON(jsonValue)
    )
  }

  /**
   * プラン変更
   * update
   */
  async userV1OrganizationPaymentCurrentPlanPatch(
    requestParameters: UserV1OrganizationPaymentCurrentPlanPatchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationPaymentCurrentPlanResponse> {
    const response = await this.userV1OrganizationPaymentCurrentPlanPatchRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 割引の適用
   * update
   */
  async userV1OrganizationPaymentDiscountPutRaw(
    requestParameters: UserV1OrganizationPaymentDiscountPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateOrganizationPaymentDiscountResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/discount`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrganizationPaymentDiscountRequestToJSON(requestParameters.updateOrganizationPaymentDiscountRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      UpdateOrganizationPaymentDiscountResponseFromJSON(jsonValue)
    )
  }

  /**
   * 割引の適用
   * update
   */
  async userV1OrganizationPaymentDiscountPut(
    requestParameters: UserV1OrganizationPaymentDiscountPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateOrganizationPaymentDiscountResponse> {
    const response = await this.userV1OrganizationPaymentDiscountPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * プラン変更履歴
   * index
   */
  async userV1OrganizationPaymentPlanTransactionsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentPlanTransactionsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/plan_transactions`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetOrganizationPaymentPlanTransactionsResponseFromJSON(jsonValue)
    )
  }

  /**
   * プラン変更履歴
   * index
   */
  async userV1OrganizationPaymentPlanTransactionsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentPlanTransactionsResponse> {
    const response = await this.userV1OrganizationPaymentPlanTransactionsGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * 変更可能なプラン一覧
   * index
   */
  async userV1OrganizationPaymentPlansGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentPlansResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/plans`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentPlansResponseFromJSON(jsonValue))
  }

  /**
   * 変更可能なプラン一覧
   * index
   */
  async userV1OrganizationPaymentPlansGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentPlansResponse> {
    const response = await this.userV1OrganizationPaymentPlansGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * プラン制限超過情報
   * show
   */
  async userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGetRaw(
    requestParameters: UserV1OrganizationPaymentPlansPlanIdCheckUsageLimitGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentPlanCheckUsageLimit>> {
    if (requestParameters.planId === null || requestParameters.planId === undefined) {
      throw new runtime.RequiredError(
        'planId',
        'Required parameter requestParameters.planId was null or undefined when calling userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/plans/{plan_id}/check_usage_limit`.replace(
          `{${'plan_id'}}`,
          encodeURIComponent(String(requestParameters.planId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      GetOrganizationPaymentPlanCheckUsageLimitFromJSON(jsonValue)
    )
  }

  /**
   * プラン制限超過情報
   * show
   */
  async userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGet(
    requestParameters: UserV1OrganizationPaymentPlansPlanIdCheckUsageLimitGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentPlanCheckUsageLimit> {
    const response = await this.userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGetRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * 利用可能なプラン
   * show
   */
  async userV1OrganizationPaymentPlansPlanIdGetRaw(
    requestParameters: UserV1OrganizationPaymentPlansPlanIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetOrganizationPaymentPlanResponse>> {
    if (requestParameters.planId === null || requestParameters.planId === undefined) {
      throw new runtime.RequiredError(
        'planId',
        'Required parameter requestParameters.planId was null or undefined when calling userV1OrganizationPaymentPlansPlanIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/plans/{plan_id}`.replace(
          `{${'plan_id'}}`,
          encodeURIComponent(String(requestParameters.planId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPaymentPlanResponseFromJSON(jsonValue))
  }

  /**
   * 利用可能なプラン
   * show
   */
  async userV1OrganizationPaymentPlansPlanIdGet(
    requestParameters: UserV1OrganizationPaymentPlansPlanIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetOrganizationPaymentPlanResponse> {
    const response = await this.userV1OrganizationPaymentPlansPlanIdGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 契約内容の確認・請求予定金額の計算
   * create
   */
  async userV1OrganizationPaymentScheduleBillingInvoicePostRaw(
    requestParameters: UserV1OrganizationPaymentScheduleBillingInvoicePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateOrganizationPaymentScheduleBillingInvoiceResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/organization/payment/schedule_billing_invoice`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateOrganizationPaymentScheduleBillingInvoiceRequestToJSON(
          requestParameters.createOrganizationPaymentScheduleBillingInvoiceRequest
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateOrganizationPaymentScheduleBillingInvoiceResponseFromJSON(jsonValue)
    )
  }

  /**
   * 契約内容の確認・請求予定金額の計算
   * create
   */
  async userV1OrganizationPaymentScheduleBillingInvoicePost(
    requestParameters: UserV1OrganizationPaymentScheduleBillingInvoicePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateOrganizationPaymentScheduleBillingInvoiceResponse> {
    const response = await this.userV1OrganizationPaymentScheduleBillingInvoicePostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
