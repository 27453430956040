import React from 'react'
import { Box } from '@material-ui/core'
import { PopupTemplate } from '..'
import { Typography } from 'src/components/atoms'

export interface ModalConfirmDeleteSettingProps {
  domain: 'company' | 'contact'
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ModalConfirmDeleteSetting = ({ domain, open, onClose, onConfirm }: ModalConfirmDeleteSettingProps) => {
  return (
    <PopupTemplate
      open={open}
      hasCancel
      cancelButtonLabel="キャンセル"
      onClose={onClose}
      hasConfirm
      onConfirm={onConfirm}
      confirmButtonLabel="削除"
      confirmButtonKind="attention"
    >
      <Box px="24px" pt="24px" pb="32px">
        <Typography fontSize="s" letterSpacing="tight" fontWeight="normal">
          この項目を削除しますか？
          <br />
          登録された{domain === 'company' ? '会社' : '連絡先'}情報は、復元出来ませんのでご注意ください
        </Typography>
      </Box>
    </PopupTemplate>
  )
}
