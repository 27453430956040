import { Box } from '@material-ui/core'
import { Colors, Typography } from 'src/components/atoms'

export const ContentRow = ({ label, value }: { label: string; value?: string }) => {
  return (
    <Box sx={{ display: 'flex', py: '12px', borderBottom: '1px solid #EEF1F4' }}>
      <Box sx={{ width: '250px', pr: '40px', color: Colors.base.black, boxSizing: 'border-box', flexShrink: 0 }}>
        <Typography fontSize="s" lineheight="1" color="inherit">
          {label}
        </Typography>
      </Box>
      <Box>
        <Typography fontSize="s" lineheight="1" color="inherit">
          {value || ''}
        </Typography>
      </Box>
    </Box>
  )
}
