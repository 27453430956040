import { Box, ButtonBase, createStyles, makeStyles, Popover } from '@material-ui/core'
import { SelectorItem } from '@noco/http-client/lib/noco'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Chip, Colors } from 'src/components/atoms'
import { AddressSearchBox } from '../AddressSearchBox'

const useStyles = makeStyles(() => {
  return createStyles({
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    }
  })
})

interface SelectContactProps {
  onChange: (contactList: string[]) => void
}

export const SelectContact = ({ onChange }: SelectContactProps) => {
  const classes = useStyles()
  const [contactList, setContactList] = useState<SelectorItem[]>([])
  const [openAddressSerachBox, setOpenAddressSearchbox] = useState(false)
  const addressBoxRef = useRef<HTMLElement>(null)

  const position = useMemo(() => {
    if (!addressBoxRef.current) return
    const rect = addressBoxRef.current.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [addressBoxRef])

  const handleChipClose = useCallback(id => {
    setContactList(prev => prev.filter(contact => contact.id !== id))
  }, [])

  useEffect(() => {
    onChange(contactList.map(contact => contact.id!))
  }, [contactList, onChange])

  return (
    <>
      {addressBoxRef?.current && (
        <Popover
          id={'id'}
          open={openAddressSerachBox}
          anchorEl={addressBoxRef.current}
          onClose={() => setOpenAddressSearchbox(false)}
          anchorPosition={position}
          PaperProps={{
            className: classes.paper,
            style: { width: (addressBoxRef.current && addressBoxRef.current.offsetWidth - 2) || 0 }
          }}
        >
          <AddressSearchBox
            selectedList={contactList}
            onSelect={list => setContactList(list)}
            onClose={() => setOpenAddressSearchbox(false)}
            onlyContact
          />
        </Popover>
      )}
      <Box border={`1px solid ${Colors.background.silver}`} borderRadius="4px" px="12px">
        <Box {...{ ref: addressBoxRef }} borderRadius="4px" display="flex" flexWrap="wrap">
          {contactList.map(p => {
            return <Chip key={p.id} id={`${p.id}`} onClose={handleChipClose} label={p.text || ''} />
          })}

          <ButtonBase style={{ padding: '12px 10px' }} onClick={() => setOpenAddressSearchbox(true)}>
            新しく追加
          </ButtonBase>
        </Box>
      </Box>
    </>
  )
}
