import {
  CreateContactRequest,
  UpdateContactRequest,
  UserV1ContactsGetRequest,
  UserV1ContactsContactIdMaterialsExpiredGetRequest,
  UpdateContactCompanyRequest,
  CreateWithEmailAddressContactRequest,
  ShallowContact,
  CreateContactChatMessageRequestAttachableObjectsInner,
  UserV1ContactsContactIdListsGetRequest
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'

export const useListContacts = (requestParameters: UserV1ContactsGetRequest, cacheVariant = 'default') => {
  const { data: auth } = useAuthenticate()
  const contactApi = nocoSDK.client?.userService.contactApi
  const func = useCallback(async () => {
    if (!auth) return
    return await contactApi
      ?.ApiFactory(auth.token)
      // MEMO: ブラウザキャッシュ
      .userV1ContactsGet(requestParameters, { cache: 'no-store' })
      .then(res => {
        const convertedList = res.data?.contacts?.map(item => {
          const listRow: { [settingId in string]: string | undefined } = {}
          item.contactSettings?.forEach(setting => {
            listRow[setting.id!] =
              setting.settingType === 'company' ? item.company?.displayName : setting.contactSettingValueText?.text!
          })
          return { ...item, ...listRow }
        })

        return { ...res.data, contacts: convertedList }
      })
  }, [auth, contactApi, requestParameters])
  return useSWR(auth && SWRCachePath.listContacts(requestParameters, cacheVariant), func)
}

// PageContact Header部 検索Box用の仮API
// Tableに表示するdateの取得と分けるため、同じAPIをKeyだけ別にして実装
export const useListContactsForSearch = (requestParameters: UserV1ContactsGetRequest) => {
  return useListContacts(requestParameters, 'search')
}

export const useGetContact = (contactId?: string) => {
  const { data: auth } = useAuthenticate()
  const contactApi = nocoSDK.client?.userService.contactApi
  const func = useCallback(async () => {
    if (!auth || !contactId) return undefined
    return await contactApi
      ?.ApiFactory(auth.token)
      .userV1ContactsContactIdGet({ contactId: contactId })
      .then(item => {
        return item.data
      })
  }, [auth, contactApi, contactId])
  return useSWR(auth && contactId && SWRCachePath.getContact(contactId), func, {
    onError: err => handleError(err, { tag: 'Get contact' })
  })
}

export const useListContactMaterials = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const contactApi = nocoSDK.client?.userService.contactApi
  const func = useCallback(async () => {
    if (!auth || !contactId) return undefined
    return await contactApi
      ?.ApiFactory(auth.token)
      .userV1ContactsContactIdMaterialsGet({ contactId: contactId, page: 1, per: 999 })
      .then(item => {
        return item.data
      })
  }, [auth, contactApi, contactId])
  return useSWR(auth && SWRCachePath.listContactMaterials(contactId), func, {
    onError: err => handleError(err, { tag: 'Get contact list materials' })
  })
}

export const useListContactTimelines = (contactId?: string) => {
  const { data: auth } = useAuthenticate()
  const contactApi = nocoSDK.client?.userService.contactApi
  const func = useCallback(async () => {
    if (!auth || !contactId) return undefined
    return await contactApi
      ?.ApiFactory(auth.token)
      .userV1ContactsContactIdTimelinesGet({ contactId: contactId, page: 1, per: 9999 })
      .then(item => {
        return item.data
      })
  }, [auth, contactApi, contactId])
  return useSWR(auth && contactId && SWRCachePath.listContactTimelines(contactId), func, {
    onError: err => handleError(err, { tag: 'Get contact timelines' })
  })
}

export const useCreateContact = (params: UserV1ContactsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListContacts(params)
  const contactApi = nocoSDK.client?.userService.contactApi

  const handleCreateContact = useCallback(
    async (createContactRequest: CreateContactRequest, companyId?: string) => {
      try {
        if (!auth || !me) return
        setIsLoading(true)
        setError(undefined)
        const contact = await contactApi
          ?.ApiFactory(auth.token)
          .userV1ContactsPost({ createContactRequest })
          .then(item => item.data?.contact)

        // MEMO: @snuffy company id があった場合には company と紐付けを実行する
        // contact setting の一項目として company が存在しているのでそこに設定してあげるイメージ
        if (contact && companyId) {
          const setting = contact?.contactSettings?.find(item => item.settingType === 'company')
          const field = setting?.contactSettingFields?.[0]
          const fieldValues = createContactRequest.contact?.contactSettingFieldValues
          if (!fieldValues) throw new Error('error')
          const updateContactRequest: CreateContactRequest = {
            contact: {
              contactSettingFieldValues: [
                ...fieldValues,
                {
                  contactSettingFieldId: field?.id,
                  contactSettingId: setting?.id,
                  values: [{ value: companyId.toString() }]
                }
              ]
            }
          }
          await contactApi
            ?.ApiFactory(auth.token)
            .userV1ContactsContactIdPut({ contactId: contact.id!, updateContactRequest })
        }

        await mutate(undefined, { revalidate: true })
        Toaster.success('連絡先を作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactApi, me, mutate]
  )

  return { error, isLoading, handleCreateContact }
}

export const useCreateContactWithEmailAddress = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [newContact, setNewContact] = useState<ShallowContact | undefined>()
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const contactApi = nocoSDK.client?.userService.contactApi

  const handleCreateContactWithEmailAddress = useCallback(
    async (createWithEmailAddressContactRequest: CreateWithEmailAddressContactRequest) => {
      try {
        if (!auth || !me) return

        setIsLoading(true)
        setNewContact(undefined)
        setError(undefined)
        const contact = await contactApi
          ?.ApiFactory(auth.token)
          .userV1ContactsCreateWithEmailAddressPost({ createWithEmailAddressContactRequest })
          .then(item => item.data?.contact)
        setNewContact(contact)

        Toaster.success('連絡先を作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create contact with email address' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactApi, me]
  )

  return { error, isLoading, handleCreateContactWithEmailAddress, newContact }
}

export const useUpdateContact = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()

  const { data, mutate: mutateContact } = useGetContact(contactId)
  const contactApi = nocoSDK.client?.userService.contactApi

  const handleUpdateContact = useCallback(
    async (updateContactRequest: UpdateContactRequest, companyId?: string) => {
      try {
        if (!auth || !me) return
        setIsLoading(true)
        setError(undefined)
        if (companyId && data?.contact) {
          const setting = data?.contact?.contactSettings?.find(item => item.settingType === 'company')
          const companyNameField = updateContactRequest.contact?.contactSettingFieldValues?.find(
            item => item.contactSettingId === setting?.id
          )
          if (companyNameField) companyNameField.values?.push({ value: companyId })
        }

        await contactApi?.ApiFactory(auth.token).userV1ContactsContactIdPut({
          contactId,
          updateContactRequest
        })

        await mutateContact()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update contact' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, data, contactApi, contactId, me, mutateContact]
  )

  return { error, isLoading, handleUpdateContact }
}

export const useUpdateContactCompany = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateContact } = useGetContact(contactId)

  const contactApi = nocoSDK.client?.userService.contactApi

  const handleUpdateContactCompany = useCallback(
    async (updateContactCompanyRequest: UpdateContactCompanyRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactApi?.ApiFactory(auth.token).userV1ContactsContactIdCompanyPut({
          contactId,
          updateContactCompanyRequest
        })

        await mutateContact()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update contact company' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactApi, contactId, me, mutateContact]
  )

  return { error, isLoading, handleUpdateContactCompany }
}

export const useCreateTimeLineMemo = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListContactTimelines(contactId)
  const contactApi = nocoSDK.client?.userService.contactApi

  const handleCreateTimeLineMemo = useCallback(
    async (memo: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactApi?.ApiFactory(auth.token).userV1ContactsContactIdMemosPost({
          contactId,
          createContactMemoRequest: {
            contactMemo: {
              text: memo
            }
          }
        })

        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactApi, contactId, me, mutate]
  )

  return { error, isLoading, handleCreateTimeLineMemo }
}

export const useUpdateTimeLineMemo = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListContactTimelines(contactId)
  const contactMemoApi = nocoSDK.client?.userService.contactMemoApi

  const handleUpdateTimeLineMemo = useCallback(
    async (contactMemoId: string, memo: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactMemoApi?.ApiFactory(auth.token).userV1ContactsContactIdMemosContactMemoIdPut({
          contactId,
          contactMemoId,
          updateContactMemoRequest: {
            contactMemo: {
              text: memo
            }
          }
        })

        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactMemoApi, contactId, me, mutate]
  )

  return { error, isLoading, handleUpdateTimeLineMemo }
}

export const useDeleteTimeLineMemo = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListContactTimelines(contactId)
  const contactMemoApi = nocoSDK.client?.userService.contactMemoApi

  const handleDeleteTimeLineMemo = useCallback(
    async (contactMemoId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactMemoApi?.ApiFactory(auth.token).userV1ContactsContactIdMemosContactMemoIdDelete({
          contactId,
          contactMemoId
        })

        await mutate()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactMemoApi, contactId, me, mutate]
  )

  return { error, isLoading, handleDeleteTimeLineMemo }
}

export const useDiscardTimeLineMemo = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListContactTimelines(contactId)
  const contactMemoApi = nocoSDK.client?.userService.contactMemoApi

  const handleDiscardTimeLineMemo = useCallback(
    async (contactMemoId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactMemoApi?.ApiFactory(auth.token).userV1ContactsContactIdMemosContactMemoIdDiscardPut({
          contactId,
          contactMemoId
        })

        await mutate()
        Toaster.success('メモを削除しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactMemoApi, contactId, me, mutate]
  )

  return { error, isLoading, handleDiscardTimeLineMemo }
}

export const useListUserContactChatMessages = (contactId?: string) => {
  const { data: auth } = useAuthenticate()
  const contactChatMessageApi = nocoSDK.client?.userService.contactChatMessageApi
  const func = useCallback(async () => {
    if (!auth || !contactId) return undefined
    return await contactChatMessageApi
      ?.ApiFactory(auth.token)
      .userV1ContactsContactIdChatMessagesGet({ contactId: contactId, per: 999 })
      .then(item => {
        return item.data
      })
  }, [auth, contactChatMessageApi, contactId])
  return useSWR(auth && contactId && SWRCachePath.listUserContactChatMessages(contactId), func, {
    onError: err => handleError(err, { tag: 'Get contact list' })
  })
}

export const useCreateUserChatMessage = (contactId: string) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate } = useListUserContactChatMessages(contactId)
  const contactChatMessageApi = nocoSDK.client?.userService.contactChatMessageApi

  const handleCreateChatMessage = useCallback(
    async (message: string, attachableObjects?: CreateContactChatMessageRequestAttachableObjectsInner[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactChatMessageApi?.ApiFactory(auth.token).userV1ContactsContactIdChatMessagesPost({
          contactId,
          createContactChatMessageRequest: {
            message,
            attachableObjects
          }
        })

        await mutate()
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactChatMessageApi, contactId, me, mutate]
  )

  return { error, isLoading, handleCreateChatMessage }
}

export const useListFilterContacts = () => {
  const { data: auth } = useAuthenticate()
  const filterApi = nocoSDK.client?.userService.filterApi
  const func = useCallback(async () => {
    if (!auth) return
    return await filterApi
      ?.ApiFactory(auth.token)
      .userV1ContactFilterGet()
      .then(res => res.data)
  }, [auth, filterApi])
  return useSWR(auth && SWRCachePath.listContactFilter(), func)
}

export const useCreateContactList = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContactLists } = useListContactLists()
  const contactListApi = nocoSDK.client?.userService.contactListApi

  const handleCreateContactList = useCallback(
    async (name: string, ids: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        const response = await contactListApi
          ?.ApiFactory(auth.token)
          .userV1ContactListsPost({ createContactListRequest: { contactList: { name } } })
        const id = response?.data?.contactList?.id
        if (!id) return
        await contactListApi?.ApiFactory(auth.token).userV1ContactListsContactListIdAppendPost({
          contactListId: id,
          appendContactListRequest: { contactIds: ids }
        })

        await mutateListFilterContacts(), mutateListContactLists()
        Toaster.success('リストを作成しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Create ContactList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactListApi, me, mutateListFilterContacts, mutateListContactLists]
  )

  return { error, isLoading, handleCreateContactList }
}

export const useListContactLists = () => {
  const { data: auth } = useAuthenticate()
  const contactListApi = nocoSDK.client?.userService.contactListApi
  const func = useCallback(async () => {
    if (!auth) return

    return await contactListApi
      ?.ApiFactory(auth.token)
      .userV1ContactListsGet()
      .then(res => res.data)
  }, [auth, contactListApi])
  return useSWR(auth?.token && SWRCachePath.listContactLists(), func)
}

export const useUpdateContactListsPin = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContactLists } = useListContactLists()
  const contactListApi = nocoSDK.client?.userService.contactListApi

  const handleUpdateContactListsPin = useCallback(
    async (contactIds: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactListApi
          ?.ApiFactory(auth.token)
          .userV1ContactListsPinPost({ pinContactListRequest: { contactListIds: contactIds } })

        await mutateListFilterContacts(), mutateListContactLists()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update ContactListsPin' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactListApi, me, mutateListFilterContacts, mutateListContactLists]
  )

  return { error, isLoading, handleUpdateContactListsPin }
}

export const useUpdateContactListName = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContactLists } = useListContactLists()
  const contactListApi = nocoSDK.client?.userService.contactListApi

  const handleUpdateContactListName = useCallback(
    async (contactListId: string, name: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactListApi?.ApiFactory(auth.token).userV1ContactListsContactListIdPut({
          contactListId: contactListId,
          updateContactListRequest: { contactList: { name } }
        })

        await mutateListFilterContacts(), mutateListContactLists()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update ContactList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactListApi, me, mutateListFilterContacts, mutateListContactLists]
  )
  return { error, isLoading, handleUpdateContactListName }
}

export const useDeleteContactList = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContactLists } = useListContactLists()
  const contactListApi = nocoSDK.client?.userService.contactListApi

  const handleDeleteContactList = useCallback(
    async (contactListId: string) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactListApi?.ApiFactory(auth.token).userV1ContactListsContactListIdDelete({
          contactListId
        })

        await mutateListFilterContacts(), mutateListContactLists()
        Toaster.success('リストを削除しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Delete ContactList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactListApi, me, mutateListFilterContacts, mutateListContactLists]
  )
  return { error, isLoading, handleDeleteContactList }
}

export const useArchiveContact = (params: UserV1ContactsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [isPosted, setIsPosted] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContacts } = useListContacts(params)
  const contactApi = nocoSDK.client?.userService.contactApi

  const handleSaveArchived = useCallback(
    async (ids: string[], isArchived: boolean) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactApi
          ?.ApiFactory(auth.token)
          .userV1ContactsArchivePut({ archiveContactsRequest: { archive: !isArchived, ids } })
        await mutateListFilterContacts(), mutateListContacts()
        Toaster.success(`連絡先をアーカイブ${isArchived ? 'から移動' : ''}しました`)
      } catch (err) {
        await handleError(err, { setError, tag: 'Archive contacts' })
      } finally {
        setIsLoading(false)
        setIsPosted(true)
      }
    },
    [auth, me, contactApi, mutateListFilterContacts, mutateListContacts]
  )
  return { error, isLoading, handleSaveArchived, isPosted }
}

export const useRemoveContactListContactId = (params: UserV1ContactsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContacts } = useListContacts(params)
  const contactListApi = nocoSDK.client?.userService.contactListApi

  const handleRemoveContactListContactId = useCallback(
    async (contactListId: string, contactIds: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactListApi?.ApiFactory(auth.token).userV1ContactListsContactListIdRemovePost({
          contactListId,
          removeContactListRequest: { contactIds }
        })
        await mutateListFilterContacts(), mutateListContacts()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Remove ContactList ContactId' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactListApi, me, mutateListFilterContacts, mutateListContacts]
  )
  return { error, isLoading, handleRemoveContactListContactId }
}

export const useUpdateContactList = (params: UserV1ContactsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateListFilterContacts } = useListFilterContacts()
  const { mutate: mutateListContacts } = useListContacts(params)
  const contactListApi = nocoSDK.client?.userService.contactListApi

  const handleUpdateContactList = useCallback(
    async (contactListId: string, contactIds: string[]) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)

        await contactListApi?.ApiFactory(auth.token).userV1ContactListsContactListIdAppendPost({
          contactListId,
          appendContactListRequest: { contactIds }
        })

        await mutateListFilterContacts(), mutateListContacts()
        Toaster.success('変更を保存しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update ContactList' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactListApi, me, mutateListFilterContacts, mutateListContacts]
  )

  return { error, isLoading, handleUpdateContactList }
}

export const useListContactExpiredMaterials = (
  requestParameters: UserV1ContactsContactIdMaterialsExpiredGetRequest
) => {
  const { data: auth } = useAuthenticate()
  const contactApi = nocoSDK.client?.userService.contactApi
  const func = useCallback(async () => {
    if (!auth) return

    return await contactApi
      ?.ApiFactory(auth.token)
      .userV1ContactsContactIdMaterialsExpiredGet(requestParameters)
      .then(res => res.data)
  }, [auth, contactApi, requestParameters])
  return useSWR(
    auth?.token && requestParameters.contactId
      ? SWRCachePath.listContactExpiredMaterials(requestParameters.contactId)
      : null,
    func
  )
}

export const useListContactListsByContactId = (params: UserV1ContactsContactIdListsGetRequest) => {
  const { data: auth } = useAuthenticate()
  const contactApi = nocoSDK.client?.userService.contactApi
  const func = useCallback(async () => {
    if (!auth) return
    return await contactApi
      ?.ApiFactory(auth.token)
      .userV1ContactsContactIdListsGet(params)
      .then(res => {
        return res.data
      })
  }, [auth, params, contactApi])

  return useSWR(auth && SWRCachePath.listContactListsByContactId(params), func)
}
