import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { LabelFormRow } from 'src/components/molecules'
import { Colors, SvgIcon, Button } from 'src/components/atoms'
import { useStyles } from './styles'
import { useInputImage } from 'src/fixtures/utils/image'
import { useUpdateMeAvatar } from 'src/fixtures/modules/me/hooks'
import { AvatarProps } from './type'

export const Avatar = ({ avatarUrl }: AvatarProps) => {
  const { data: base64, handleInputImage, clear, name, error } = useInputImage()
  const { handleUpdateMeAvatar } = useUpdateMeAvatar()
  const classes = useStyles()
  const [editAvatar, setEditAvatar] = useState<string | undefined>(avatarUrl)

  const onSave = useCallback(() => {
    if (editAvatar) {
      handleUpdateMeAvatar({ user: { avatar: { data: base64, fileName: name } } })
    }
  }, [base64, editAvatar, handleUpdateMeAvatar, name])

  const onCancel = useCallback(() => {
    clear()
  }, [clear])

  useEffect(() => {
    if (base64) {
      setEditAvatar(base64)
    }
  }, [base64])

  return (
    <Box borderBottom={`1px solid ${Colors.background.silver}`} py="16px">
      <LabelFormRow
        label="アイコン"
        onSave={onSave}
        onCancel={onCancel}
        canSubmit={base64 == null || error != null}
        readElement={
          <>
            <Box width="48px" height="48px" position="relative" mb="8px">
              {avatarUrl || base64 ? (
                <img src={base64 || avatarUrl} className={classes.avatarImg} />
              ) : (
                <SvgIcon variant="avatar" size="48px" color={Colors.base.middleGray} />
              )}
            </Box>
            <Typography variant="subtitle2" className={classes.textColor}>
              顧客と社内に公開される情報です
            </Typography>
          </>
        }
        editElement={
          <>
            <Box width="48px" height="48px" position="relative" mb="16px">
              {editAvatar ? (
                <img src={editAvatar} className={classes.avatarImg} />
              ) : (
                <SvgIcon variant="avatar" size="48px" color={Colors.base.middleGray} />
              )}
            </Box>
            <Button variant="outlined" kind="secondary" title="画像をアップロード" onClick={() => handleInputImage()} />
            <Typography variant="body2" color="error">
              {error?.message}
            </Typography>
            <Box height="10px" />
            <Typography variant="subtitle2" className={classes.textColor}>
              ※写真があると、他のユーザーが顔と名前を一致しやすくなります
            </Typography>
          </>
        }
      />
    </Box>
  )
}
