/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumUserOperationKind = {
  AppendContactsToCompany: 'append_contacts_to_company',
  RemoveContactsFromCompany: 'remove_contacts_from_company',
  CreateMaterialContactable: 'create_material_contactable',
  SolveContact: 'solve_contact',
  UnsolveContact: 'unsolve_contact',
  Import: 'import',
  Export: 'export'
} as const
export type EnumUserOperationKind = typeof EnumUserOperationKind[keyof typeof EnumUserOperationKind]

export function EnumUserOperationKindFromJSON(json: any): EnumUserOperationKind {
  return EnumUserOperationKindFromJSONTyped(json, false)
}

export function EnumUserOperationKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnumUserOperationKind {
  return json as EnumUserOperationKind
}

export function EnumUserOperationKindToJSON(value?: EnumUserOperationKind | null): any {
  return value as any
}
