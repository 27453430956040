import { Box, IconButton, Typography, createStyles, makeStyles, ButtonBase, CircularProgress } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { ChatMessage, Visitor } from '@noco/http-client/lib/noco'
import { useFormik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { Input, Button, Colors, DynamicMuiIcon } from 'src/components/atoms'
import { ColorPicker } from 'src/components/molecules'
import { getInputImage } from 'src/fixtures/utils/image'
import { ChatRoom } from 'src/components/organisms'
import {
  useGetOrganizationChatSetting,
  useGetOrganization,
  useUpdateAvatar,
  // TODO @toshi1127 チャットボタンのカスタマイズが有効になったら表示
  // useUpdateButtonImage,
  useUpdateChatSetting
} from 'src/fixtures/modules/organization/hooks'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'

const useStyles = makeStyles(() =>
  createStyles({
    avatarImage: {
      height: 48,
      width: 48,
      objectFit: 'contain',
      borderRadius: '50%'
    },
    chatButtonImage: {
      width: 64,
      height: 64,
      borderRadius: '50%',
      objectFit: 'contain'
    }
  })
)

interface FormValues {
  autoResponseName: string
  avatarImage: string
  chatColor: string
  chatButtonImage: string
}

const initialValues: FormValues = {
  autoResponseName: '株式会社hogehoge',
  avatarImage: '',
  chatColor: Colors.primary.default,
  chatButtonImage: ''
}

export const chatMessages: ChatMessage[] = [
  {
    id: '01FXYAV1WAW3ZTVQCTXDQH9TT8',
    contactId: '01FXXB7F07RT1SQPHB9V9XHYX1',
    documentId: '01FWVH3AGXKN5RD2NPZDCTEGCM',
    message:
      '資料をご覧いただきありがとうございます！何かお困りのことがあればこちからご相談ください。料金・システム・導入方法などをご相談いただくことが多いです。',
    sendedByType: 'User',
    sendedById: '01FWWYSHK7CSRXASTX08682PKZ',
    sendEmailStatus: null,
    editedAt: null,
    createdAt: '2022-03-12T14:50:21.000+09:00',
    updatedAt: '2022-03-12T15:05:14.000+09:00',
    files: [],
    sendedBy: {
      id: '01FWWYSHK7CSRXASTX08682PKZ',
      displayName: '株式会社hogehoge',
      avatar: {
        url: 'https://noco-staging-app-public.s3.amazonaws.com/staging/uploads/01FWVH324KQQENHQ047D6PM1CG/user/avatar/01FWWYSHK7CSRXASTX08682PKZ/f74658b1-5d24-41fd-b84b-9523b93a2a0d.jpg'
      }
    },
    userProfile: {
      id: '01FXANZ07D1Y5PH22EC96TKD99',
      department: 'abc',
      position: 'あいう',
      birthplace: '123',
      hobby: null,
      selfIntroduction: null,
      socialLinkYoutube: null,
      socialLinkFacebook: null,
      socialLinkTwitter: null,
      socialLinkNote: null,
      socialLinkWebSite: null,
      createdAt: '2022-03-04T23:39:56.000+09:00',
      updatedAt: '2022-03-08T16:43:52.000+09:00',
      userName: '舟口 翔梧',
      organizationName: 'test_organization_3',
      userAvatar: {
        url: 'https://noco-staging-app-public.s3.amazonaws.com/staging/uploads/01FWVH324KQQENHQ047D6PM1CG/user/avatar/01FWWYSHK7CSRXASTX08682PKZ/f74658b1-5d24-41fd-b84b-9523b93a2a0d.jpg'
      }
    }
  },
  {
    id: '01FXYATV69RX837V4E6ABTNM7S',
    contactId: '01FXXB7F07RT1SQPHB9V9XHYX1',
    documentId: '01FWVH3AGXKN5RD2NPZDCTEGCM',
    message: 'アポイントメントを希望したいです！',
    sendedByType: 'Contact',
    sendedById: '01FXXB7F07RT1SQPHB9V9XHYX1',
    sendEmailStatus: null,
    editedAt: null,
    createdAt: '2022-03-12T14:50:14.000+09:00',
    updatedAt: '2022-03-12T14:50:14.000+09:00',
    files: [],
    sendedBy: {
      id: '01FXXB7F07RT1SQPHB9V9XHYX1',
      displayName: '松本 駿',
      avatar: {
        url: 'https://api.stg-noco.sale/images/default_contact_avatar.png'
      }
    }
  }
]

export const visitor: Visitor = {
  id: 'id',
  contactId: '01FXXB7F07RT1SQPHB9V9XHYX1',
  organizationId: 'organizationId',
  hasContact: true,
  token: 'token'
}

export const ChatSetting = () => {
  const classes = useStyles()
  const { handleUpdateChatSetting } = useUpdateChatSetting()
  const { handleUpdateAvatar } = useUpdateAvatar()
  const { data: dataOrganization } = useGetOrganization()
  const { data, mutate: mutateChatSettings, isLoading } = useGetOrganizationChatSetting()
  const { hasPermission: isUpdatable } = useFeatureFlag('chatSetting', 'update')

  // TODO @toshi1127 チャットボタンのカスタマイズが有効になったら表示
  // const { handleUpdateButtonImage } = useUpdateButtonImage()
  const formik = useFormik<typeof initialValues>({
    initialValues,
    onSubmit: () => {}
  })

  const handleUploadAvatarImage = useCallback(async () => {
    const image = await getInputImage()
    formik.setFieldValue('avatarImage', image.base64)
    await handleUpdateAvatar(image.file)
  }, [formik, handleUpdateAvatar])

  // TODO @toshi1127 チャットボタンのカスタマイズが有効になったら表示
  // const handleUploadChatButtonImage = useCallback(async () => {
  //   const image = await getInputImage()
  //   formik.setFieldValue('chatButtonImage', image.base64)
  //   await handleUpdateButtonImage(image.file)
  // }, [formik, handleUpdateButtonImage])

  const handleResetAvatarImage = useCallback(async () => {
    formik.setFieldValue('avatarImage', `${process.env.API_HOST}/images/default_contact_avatar.png`)
    await handleUpdateAvatar(undefined)
  }, [formik, handleUpdateAvatar])

  useEffect(() => {
    if (data?.organizationChatSetting) {
      const { avatar, autoResponseName, chatColor, chatButtonImage } = data?.organizationChatSetting
      formik.resetForm({
        values: {
          autoResponseName: autoResponseName ?? initialValues.autoResponseName,
          avatarImage: avatar?.url ?? initialValues.avatarImage,
          chatColor: chatColor ?? initialValues.chatColor,
          chatButtonImage: chatButtonImage?.url ?? initialValues.chatButtonImage
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    mutateChatSettings()
  }, [mutateChatSettings])

  if (isLoading)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: '32px' }}>
        <CircularProgress />
      </Box>
    )
  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box display="flex" mb="52px">
        <Box display="flex" flexDirection="column" minWidth="280px">
          <Typography variant="h6">自動応答時の表示名</Typography>
          <Box mt="8px" />
          <Input
            name="autoResponseName"
            value={formik.values.autoResponseName}
            onChange={formik.handleChange}
            onBlur={e =>
              handleUpdateChatSetting({
                autoResponseName: e.target.value as string,
                chatColor: data?.organizationChatSetting?.chatColor
              })
            }
            fullWidth
            disabled={!isUpdatable}
          />

          <Box mt="28px" />

          <Typography variant="h6">自動応答時のアイコン</Typography>
          <Box display="flex" flexDirection="column" mt="8px">
            <img className={classes.avatarImage} src={formik.values.avatarImage} />

            <Box mt="8px" display="flex">
              <Box
                display="flex"
                justifyContent="center"
                alignContent="center"
                minWidth="34px"
                minHeight="34px"
                mr="12px"
                border="1px solid #DADCE0"
                boxSizing="border-box"
                borderRadius="4px"
              >
                <IconButton style={{ padding: 0 }} onClick={handleResetAvatarImage} disabled={!isUpdatable}>
                  <Delete />
                </IconButton>
              </Box>

              <Button
                kind="primary"
                variant="outlined"
                title="画像をアップロード"
                onClick={handleUploadAvatarImage}
                disabled={!isUpdatable}
              />
            </Box>
          </Box>

          <Box mt="28px" />

          <Typography variant="h6">チャットのカラー</Typography>
          <Box mt="8px" />
          <ColorPicker
            name="chatColor"
            color={formik.values.chatColor}
            onChangeColor={color =>
              handleUpdateChatSetting({
                autoResponseName: data?.organizationChatSetting?.autoResponseName,
                chatColor: color as string
              })
            }
            hasEditPermission={isUpdatable}
          />
        </Box>

        <Box
          ml="64px"
          display="flex"
          flexDirection="column"
          width="400px"
          border="1px solid #DADCE0"
          height={1}
          boxShadow="0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
          borderRadius={6}
        >
          <Box
            px="18px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py="10px"
            borderBottom={`1px solid ${Colors.background.gray}`}
          >
            <Box display="flex" alignItems="center">
              <Box mr="8px" minWidth={28}>
                <ButtonBase color={Colors.base.middleGray} className="cancel">
                  <DynamicMuiIcon variant="arrowLeft" color="inherit" size="20px" />
                </ButtonBase>
              </Box>
              <Box width={1}>
                <Typography variant="h5">{dataOrganization?.organization?.name}</Typography>
              </Box>
            </Box>
            <ButtonBase color={Colors.base.middleGray} className="cancel">
              <DynamicMuiIcon variant="close" color="inherit" size="20px" />
            </ButtonBase>
          </Box>

          <Box overflow="auto" height={1}>
            <ChatRoom
              document={{}}
              chatMessages={chatMessages.map(item => ({
                ...item,
                sendedBy: {
                  ...item.sendedBy,
                  displayName: formik.values.autoResponseName,
                  avatar: {
                    url: formik.values.avatarImage
                  }
                }
              }))}
              visitor={visitor}
              isPreview
              isAvailableSettingNegotiationOpportunity={false}
              organizationChatSetting={data?.organizationChatSetting}
              organization={dataOrganization?.organization}
            />
          </Box>
        </Box>
      </Box>

      {/* TODO @toshi1127 チャットボタンのカスタマイズが有効になったら表示
      <Box display="flex">
        <Box minWidth="280px">
          <Typography variant="h6">チャットボタンのカスタマイズ</Typography>
          <Box mt="8px" />
          <Button kind="primary" variant="outlined" title="画像をアップロード" onClick={handleUploadChatButtonImage} />
        </Box>

        <Box ml="64px">
          <ButtonBase
            style={{
              borderRadius: '50%',
              boxShadow: '0px 0px 1px 0px #0000000A, 0px 2px 6px 0px #0000000A, 0px 16px 24px 0px #0000000F;'
            }}
          >
            <img className={classes.chatButtonImage} src={formik.values.chatButtonImage} />
          </ButtonBase>
        </Box>
      </Box> */}
    </Box>
  )
}
