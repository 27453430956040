/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScheduleBillingContract
 */
export interface ScheduleBillingContract {
  /**
   * ご契約期間（開始日）
   * @type {Date}
   * @memberof ScheduleBillingContract
   */
  validSince: Date
  /**
   * ご契約期間（終了日）
   * @type {Date}
   * @memberof ScheduleBillingContract
   */
  validUntil: Date
  /**
   * ご契約予定日
   * @type {Date}
   * @memberof ScheduleBillingContract
   */
  contractAt: Date
  /**
   * 次回契約更新日
   * @type {Date}
   * @memberof ScheduleBillingContract
   */
  updateContractAt: Date
  /**
   * 次回クレジットカード決済予定日
   * @type {Date}
   * @memberof ScheduleBillingContract
   */
  nextBillingAt: Date
}

export function ScheduleBillingContractFromJSON(json: any): ScheduleBillingContract {
  return ScheduleBillingContractFromJSONTyped(json, false)
}

export function ScheduleBillingContractFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleBillingContract {
  if (json === undefined || json === null) {
    return json
  }
  return {
    validSince: new Date(json['valid_since']),
    validUntil: new Date(json['valid_until']),
    contractAt: new Date(json['contract_at']),
    updateContractAt: new Date(json['update_contract_at']),
    nextBillingAt: new Date(json['next_billing_at'])
  }
}

export function ScheduleBillingContractToJSON(value?: ScheduleBillingContract | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    valid_since: value.validSince.toISOString(),
    valid_until: value.validUntil.toISOString(),
    contract_at: value.contractAt.toISOString(),
    update_contract_at: value.updateContractAt.toISOString(),
    next_billing_at: value.nextBillingAt.toISOString()
  }
}
