/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetContactResponseData,
  GetContactResponseDataFromJSON,
  GetContactResponseDataFromJSONTyped,
  GetContactResponseDataToJSON
} from './GetContactResponseData'

/**
 *
 * @export
 * @interface UpdateContactCompanyResponse
 */
export interface UpdateContactCompanyResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateContactCompanyResponse
   */
  status?: number
  /**
   *
   * @type {GetContactResponseData}
   * @memberof UpdateContactCompanyResponse
   */
  data?: GetContactResponseData
}

export function UpdateContactCompanyResponseFromJSON(json: any): UpdateContactCompanyResponse {
  return UpdateContactCompanyResponseFromJSONTyped(json, false)
}

export function UpdateContactCompanyResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateContactCompanyResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetContactResponseDataFromJSON(json['data'])
  }
}

export function UpdateContactCompanyResponseToJSON(value?: UpdateContactCompanyResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetContactResponseDataToJSON(value.data)
  }
}
