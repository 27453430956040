/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateChatMessageRequest1
 */
export interface CreateChatMessageRequest1 {
  /**
   *
   * @type {string}
   * @memberof CreateChatMessageRequest1
   */
  documentId?: string
  /**
   *
   * @type {string}
   * @memberof CreateChatMessageRequest1
   */
  siteId?: string
}

export function CreateChatMessageRequest1FromJSON(json: any): CreateChatMessageRequest1 {
  return CreateChatMessageRequest1FromJSONTyped(json, false)
}

export function CreateChatMessageRequest1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateChatMessageRequest1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    siteId: !exists(json, 'site_id') ? undefined : json['site_id']
  }
}

export function CreateChatMessageRequest1ToJSON(value?: CreateChatMessageRequest1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_id: value.documentId,
    site_id: value.siteId
  }
}
