import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Link = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.167 0.0759277H10.667C10.2087 0.0759277 9.83366 0.450928 9.83366 0.909261C9.83366 1.36759 10.2087 1.74259 10.667 1.74259H13.167C14.542 1.74259 15.667 2.86759 15.667 4.24259C15.667 5.61759 14.542 6.74259 13.167 6.74259H10.667C10.2087 6.74259 9.83366 7.11759 9.83366 7.57593C9.83366 8.03426 10.2087 8.40926 10.667 8.40926H13.167C15.467 8.40926 17.3337 6.54259 17.3337 4.24259C17.3337 1.94259 15.467 0.0759277 13.167 0.0759277ZM5.66699 4.24259C5.66699 4.70093 6.04199 5.07593 6.50033 5.07593H11.5003C11.9587 5.07593 12.3337 4.70093 12.3337 4.24259C12.3337 3.78426 11.9587 3.40926 11.5003 3.40926H6.50033C6.04199 3.40926 5.66699 3.78426 5.66699 4.24259ZM7.33366 6.74259H4.83366C3.45866 6.74259 2.33366 5.61759 2.33366 4.24259C2.33366 2.86759 3.45866 1.74259 4.83366 1.74259H7.33366C7.79199 1.74259 8.16699 1.36759 8.16699 0.909261C8.16699 0.450928 7.79199 0.0759277 7.33366 0.0759277H4.83366C2.53366 0.0759277 0.666992 1.94259 0.666992 4.24259C0.666992 6.54259 2.53366 8.40926 4.83366 8.40926H7.33366C7.79199 8.40926 8.16699 8.03426 8.16699 7.57593C8.16699 7.11759 7.79199 6.74259 7.33366 6.74259Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default Link
