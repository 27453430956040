import { Box } from '@material-ui/core'
import React from 'react'
import { Colors, Typography, SvgIcon } from 'src/components/atoms'

export const ContactName = ({ name, kana }: { name?: string; kana?: string | null }) => {
  return (
    <Box display="flex" alignItems="center" height="20px" mb="4px">
      {(name || kana) && (
        <Box mr="6px">
          <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
        </Box>
      )}
      {name && (
        <Box mr="6px">
          <Typography fontSize="s" fontWeight="bold" lineheight="14px">
            {name}
          </Typography>
        </Box>
      )}
      {kana && (
        <Typography fontSize="s" fontWeight="bold" lineheight="14px">
          ({kana})
        </Typography>
      )}
    </Box>
  )
}
