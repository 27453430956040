/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ForgotOrganizationMailRequestToJSON, ForgotPasswordMailRequestToJSON, InvitedToOrganizationSignupRequestToJSON, OrganizationSignupCheckResponseFromJSON, OrganizationSignupMailRequestToJSON, OrganizationSignupRequestToJSON, ResetPasswordRequestToJSON, ResetPasswordResponseFromJSON, SigninRequestToJSON, VerifyEmailRequestToJSON } from '../models';
/**
 *
 */
export class ApiUserV1AuthApi extends runtime.BaseAPI {
    /**
     * チームにログインをする
     * signin
     */
    async userSigninRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SigninRequestToJSON(requestParameters.signinRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * チームにログインをする
     * signin
     */
    async userSignin(requestParameters = {}, initOverrides) {
        const response = await this.userSigninRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームへの招待メールで得たtokenから情報を取得する
     * invited_to_organization_check
     */
    async userV1AuthInvitedToOrganizationCheckGetRaw(requestParameters, initOverrides) {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling userV1AuthInvitedToOrganizationCheckGet.');
        }
        const queryParameters = {};
        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/auth/invited_to_organization_check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * チームへの招待メールで得たtokenから情報を取得する
     * invited_to_organization_check
     */
    async userV1AuthInvitedToOrganizationCheckGet(requestParameters, initOverrides) {
        const response = await this.userV1AuthInvitedToOrganizationCheckGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームへの招待メールから新規ユーザ登録をする
     * invited_to_organization_signup
     */
    async userV1AuthInvitedToOrganizationSignupPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/auth/invited_to_organization_signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvitedToOrganizationSignupRequestToJSON(requestParameters.invitedToOrganizationSignupRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * チームへの招待メールから新規ユーザ登録をする
     * invited_to_organization_signup
     */
    async userV1AuthInvitedToOrganizationSignupPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1AuthInvitedToOrganizationSignupPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームIDを忘れた場合の通知処理をする
     * forgot_organization_mail
     */
    async userV1ForgotOrganizationMailPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/forgot_organization_mail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotOrganizationMailRequestToJSON(requestParameters.forgotOrganizationMailRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * チームIDを忘れた場合の通知処理をする
     * forgot_organization_mail
     */
    async userV1ForgotOrganizationMailPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ForgotOrganizationMailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * パスワードを忘れた場合の再設定連絡をする
     * forgot_password_mail
     */
    async userV1ForgotPasswordMailPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/forgot_password_mail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordMailRequestToJSON(requestParameters.forgotPasswordMailRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * パスワードを忘れた場合の再設定連絡をする
     * forgot_password_mail
     */
    async userV1ForgotPasswordMailPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ForgotPasswordMailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームサインアップのメールで得たtokenから情報を取得する
     * organization_signup_check
     */
    async userV1OrganizationSignupCheckGetRaw(requestParameters, initOverrides) {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token', 'Required parameter requestParameters.token was null or undefined when calling userV1OrganizationSignupCheckGet.');
        }
        const queryParameters = {};
        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization_signup_check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => OrganizationSignupCheckResponseFromJSON(jsonValue));
    }
    /**
     * チームサインアップのメールで得たtokenから情報を取得する
     * organization_signup_check
     */
    async userV1OrganizationSignupCheckGet(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationSignupCheckGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームサインアップのためのメール検証
     * organization_signup_mail
     */
    async userV1OrganizationSignupMailPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization_signup_mail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationSignupMailRequestToJSON(requestParameters.organizationSignupMailRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * チームサインアップのためのメール検証
     * organization_signup_mail
     */
    async userV1OrganizationSignupMailPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationSignupMailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームサインアップ処理
     * organization_signup
     */
    async userV1OrganizationSignupPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization_signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationSignupRequestToJSON(requestParameters.organizationSignupRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * チームサインアップ処理
     * organization_signup
     */
    async userV1OrganizationSignupPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationSignupPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * パスワードの再設定をする
     * reset_password
     */
    async userV1ResetPasswordPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/reset_password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResetPasswordResponseFromJSON(jsonValue));
    }
    /**
     * パスワードの再設定をする
     * reset_password
     */
    async userV1ResetPasswordPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1ResetPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * メールアドレス認証
     * verify_email
     */
    async userV1VerifyEmailPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/verify_email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyEmailRequestToJSON(requestParameters.verifyEmailRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * メールアドレス認証
     * verify_email
     */
    async userV1VerifyEmailPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1VerifyEmailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
