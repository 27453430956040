/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { FilterCounts, FilterCountsFromJSON, FilterCountsFromJSONTyped, FilterCountsToJSON } from './FilterCounts'

/**
 *
 * @export
 * @interface MaterialableFilter
 */
export interface MaterialableFilter {
  /**
   *
   * @type {FilterCounts}
   * @memberof MaterialableFilter
   */
  normal?: FilterCounts
  /**
   *
   * @type {FilterCounts}
   * @memberof MaterialableFilter
   */
  archived?: FilterCounts
}

export function MaterialableFilterFromJSON(json: any): MaterialableFilter {
  return MaterialableFilterFromJSONTyped(json, false)
}

export function MaterialableFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialableFilter {
  if (json === undefined || json === null) {
    return json
  }
  return {
    normal: !exists(json, 'normal') ? undefined : FilterCountsFromJSON(json['normal']),
    archived: !exists(json, 'archived') ? undefined : FilterCountsFromJSON(json['archived'])
  }
}

export function MaterialableFilterToJSON(value?: MaterialableFilter | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    normal: FilterCountsToJSON(value.normal),
    archived: FilterCountsToJSON(value.archived)
  }
}
