/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  SortSiteSectionRequestSiteSectionsInner,
  SortSiteSectionRequestSiteSectionsInnerFromJSON,
  SortSiteSectionRequestSiteSectionsInnerFromJSONTyped,
  SortSiteSectionRequestSiteSectionsInnerToJSON
} from './SortSiteSectionRequestSiteSectionsInner'

/**
 *
 * @export
 * @interface SortSiteSectionRequest
 */
export interface SortSiteSectionRequest {
  /**
   *
   * @type {Array<SortSiteSectionRequestSiteSectionsInner>}
   * @memberof SortSiteSectionRequest
   */
  siteSections?: Array<SortSiteSectionRequestSiteSectionsInner>
}

export function SortSiteSectionRequestFromJSON(json: any): SortSiteSectionRequest {
  return SortSiteSectionRequestFromJSONTyped(json, false)
}

export function SortSiteSectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortSiteSectionRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteSections: !exists(json, 'site_sections')
      ? undefined
      : (json['site_sections'] as Array<any>).map(SortSiteSectionRequestSiteSectionsInnerFromJSON)
  }
}

export function SortSiteSectionRequestToJSON(value?: SortSiteSectionRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_sections:
      value.siteSections === undefined
        ? undefined
        : (value.siteSections as Array<any>).map(SortSiteSectionRequestSiteSectionsInnerToJSON)
  }
}
