import { Box, createStyles, makeStyles } from '@material-ui/core'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { EnumSettingSettingType } from '@noco/http-client/lib/noco'
import { useMemo } from 'react'
import { Colors, SelectBox, SvgIcon, Tooltip, Typography } from 'src/components/atoms'
import { ContactSettingValueMap } from 'src/fixtures/modules/contact/utils'
import { useListContactSettings } from 'src/fixtures/modules/contactSetting/hooks'
import { useListSelectorUsersList } from 'src/fixtures/modules/selector/hooks'
import { SettingInputRow } from '..'

export interface ContactCreateFormChangeValueProps {
  settingId: string
  fieldId: string
  value: any
}

interface ContactCreateFormProps {
  userId: string
  settingValues: ContactSettingValueMap
  onChangeUser: SelectInputProps['onChange']
  onChangeValues: ({ settingId, fieldId, value }: ContactCreateFormChangeValueProps) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    }
  })
})

export const ContactCreateForm = ({ userId, settingValues, onChangeUser, onChangeValues }: ContactCreateFormProps) => {
  const classes = useStyles()
  const { data: responseSetting } = useListContactSettings()
  const activeSettings = responseSetting?.activeContactSettings
  const { data: selectorUserRes } = useListSelectorUsersList({ page: 1, per: 9999 })
  const selectorUserList = selectorUserRes?.selectorItems

  const userOptionList = useMemo(() => {
    return (
      selectorUserList?.map(item => ({
        value: item.id!,
        label: item.text,
        startElement: (
          <Box width="20px" height="20px" mr="4px" position="relative">
            {item.extend?.avatar ? (
              <img src={item.extend.avatar.url} className={classes.img} />
            ) : (
              <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
            )}
          </Box>
        )
      })) || []
    )
  }, [selectorUserList, classes.img])

  return (
    <Box px="24px" pt="16px" pb="70px">
      <Box width="50%">
        <Box width="fit-content">
          <Tooltip content="連絡先（顧客）には、必ず担当者を設定する必要があります。担当している顧客が、資料閲覧やチャットメッセージを送信すると、担当者宛に通知が届きます。">
            <Typography fontSize="s" lineheight="14px" fontWeight="bold">
              担当者
            </Typography>
          </Tooltip>
        </Box>
        <Box mt="6px" />
        <SelectBox
          placeholder="選択してください"
          value={userId || ''}
          name="users"
          optionList={userOptionList}
          onChange={onChangeUser}
        />
      </Box>
      <Box mt="16px" />
      <Box width="100%" height="1px" bgcolor={Colors.background.gray} />
      <Box mt="18px" />

      {activeSettings?.map(setting => {
        const values = settingValues[setting.id!]
        return (
          <SettingInputRow
            required={setting.isRequiredOnUser}
            kind="contact"
            key={setting.id}
            name={String(setting.id)}
            userId={userId}
            values={values}
            setting={setting}
            toolTipText={
              setting.settingType === EnumSettingSettingType.Company
                ? '連絡先（顧客）に、会社を紐付けることができます。既に登録されている会社から選択もしくは、新しく登録できます。'
                : ''
            }
            onChange={data => {
              onChangeValues({ settingId: setting.id!, fieldId: data.fieldId, value: data.value })
            }}
          />
        )
      })}
    </Box>
  )
}
