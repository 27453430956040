/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface FilterStaffPins1
 */
export interface FilterStaffPins1 {
  /**
   *
   * @type {number}
   * @memberof FilterStaffPins1
   */
  counts?: number | null
  /**
   *
   * @type {string}
   * @memberof FilterStaffPins1
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof FilterStaffPins1
   */
  text?: string
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof FilterStaffPins1
   */
  avatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {boolean}
   * @memberof FilterStaffPins1
   */
  isPin?: boolean
}

export function FilterStaffPins1FromJSON(json: any): FilterStaffPins1 {
  return FilterStaffPins1FromJSONTyped(json, false)
}

export function FilterStaffPins1FromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterStaffPins1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    counts: !exists(json, 'counts') ? undefined : json['counts'],
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    text: !exists(json, 'text') ? undefined : json['text'],
    avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
    isPin: !exists(json, 'is_pin') ? undefined : json['is_pin']
  }
}

export function FilterStaffPins1ToJSON(value?: FilterStaffPins1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    counts: value.counts,
    user_id: value.userId,
    text: value.text,
    avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
    is_pin: value.isPin
  }
}
