import { useMediaQuery, Box, useTheme } from '@material-ui/core'
import { DocumentShare, EuDocument, EuOrganization, Visitor } from '@noco/http-client/lib/noco'
import { destroyCookie } from 'nookies'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ModalAuthentication } from 'src/components/modals/ModalAuthentication'
import { ModalSendEmailWithAuthenticationCode } from 'src/components/modals/ModalSendEmailWithAuthenticationCode'
import { ModalVisitorConnectToContact } from 'src/components/modals/ModalVisitorConnectToContact'
import { CookieAgreement } from 'src/components/molecules/CookieAgreement'
import { DocumentSlideDetail } from 'src/components/organisms/DocumentSlideDetail'
import {
  useSendAuthenticationCode,
  useVerifyAuthenticationCode,
  useSharedLinkSignin
} from 'src/fixtures/modules/contactAuth/hooks'
import {
  useGetContactDocumentShareForm,
  useGetCotnactDocumentShare,
  useUpdateContact
} from 'src/fixtures/modules/contactDocument/hooks'
import { useGetVisitor } from 'src/fixtures/modules/visitor/hooks'
import { getTokenFromCookie } from 'src/fixtures/utils'

export interface PageDocumentShareWithLinkProps {
  document?: EuDocument
  visitor?: Visitor
  organization?: EuOrganization
  documentId: string
  documentShareId: string
  siteShareId?: string
  siteId?: string
  documentShare?: DocumentShare
  hasMaterialContactable?: boolean
  canRequestToExtendExpiredAt?: boolean
  expiredOn?: string | null
}

export const PageDocumentShareWithLink = ({
  documentId,
  documentShare,
  documentShareId,
  document,
  organization,
  siteId,
  visitor,
  siteShareId,
  canRequestToExtendExpiredAt,
  expiredOn
}: PageDocumentShareWithLinkProps) => {
  const theme = useTheme()
  const isPCView = useMediaQuery(theme.breakpoints.up('sm'))
  const [openForm, setOpenForm] = useState(false)
  const { showCookieAgreement } = getTokenFromCookie()
  const [openCookie, setOpenCookie] = useState<boolean>(false)

  const { mutate: mutateDocumentShare } = useGetCotnactDocumentShare({ documentShareId, siteShareId })
  const { mutate: mutateVisitor } = useGetVisitor()
  const { handleSendAuthCodeWithEmail, isLoading, error } = useSendAuthenticationCode()
  const { handleSharedLinkSignin } = useSharedLinkSignin()
  const [isSubmittingUpdateContact, setIsSubmittingUpdateContact] = useState(false)
  const [isSubmittedCode, setIsSubmittedCode] = useState(false)
  const { handleVerifyAuthCode } = useVerifyAuthenticationCode()
  const { data: shareFormsRes, mutate: mutateDocumentShareForms } = useGetContactDocumentShareForm(
    documentShareId,
    siteShareId
  )

  const [openVerifyCodeModal, setOpenVerifyCodeModal] = useState(false)
  const {
    handleUpdateContact,
    isLoading: isLoadingUpdateContact,
    error: errorUpdateContact
  } = useUpdateContact(documentShareId)
  const [email, setEmail] = useState('')
  const [openMailForm, setOpenMailForm] = useState(false)
  const [isInit, setIsInit] = useState(false)

  const isFormActive = documentShare?.isFormActive
  const isActivated = useMemo(
    () => !isFormActive || (isFormActive && shareFormsRes?.shareForms && shareFormsRes.shareForms.length === 0),
    [isFormActive, shareFormsRes?.shareForms]
  )
  const noForms = useMemo(() => !!shareFormsRes?.shareForms && shareFormsRes.shareForms.length === 0, [shareFormsRes])

  const openEntryForm = useMemo(() => {
    return (!noForms || openForm) && !openMailForm && !!isFormActive
  }, [noForms, openForm, openMailForm, isFormActive])

  const handleCookieClick = useCallback(() => {
    setOpenCookie(false)
    destroyCookie(null, 'showCookieAgreement', { path: '/' })
  }, [])

  useEffect(() => {
    if (showCookieAgreement) setOpenCookie(true)
  }, [showCookieAgreement])

  const syncDocument = useCallback(() => {
    setOpenVerifyCodeModal(false)
    setOpenMailForm(false)
    mutateDocumentShare()
    setTimeout(() => {
      mutateVisitor().then(() => {
        mutateDocumentShareForms()
      })
    }, 128)
  }, [mutateDocumentShare, mutateDocumentShareForms, mutateVisitor])

  const onSubmitModalAuthentication = useCallback(
    (code: string) => {
      handleVerifyAuthCode({ code })
        .then(() => {
          setIsSubmittedCode(true)
          syncDocument()
        })
        .catch(() => {
          setOpenVerifyCodeModal(false)
          setOpenMailForm(true)
        })
    },
    [handleVerifyAuthCode, syncDocument]
  )

  const onSubmitModalSendEmailWithAuthenticationCode = useCallback(
    async email => {
      if (documentShare?.isAuthCodeActive) {
        handleSendAuthCodeWithEmail({ emailAddress: email, documentId, siteId })
        setOpenMailForm(false)
        setOpenVerifyCodeModal(true)
      } else {
        handleSharedLinkSignin({ emailAddress: email, documentId, siteId }).then(() => {
          syncDocument()
          setOpenMailForm(false)
        })
      }
      setEmail(email)
    },
    [documentId, siteId, documentShare, handleSendAuthCodeWithEmail, syncDocument, handleSharedLinkSignin]
  )

  useEffect(() => {
    if (isSubmittedCode && !isLoading && !error) {
      syncDocument()
    }
  }, [error, isLoading, isSubmittedCode, syncDocument])

  useEffect(() => {
    if (isSubmittingUpdateContact && !isLoadingUpdateContact && !errorUpdateContact) {
      syncDocument()
    }
  }, [errorUpdateContact, isLoadingUpdateContact, isSubmittingUpdateContact, syncDocument])

  // MEMO: @snuffy
  // - form active (顧客情報入力フォーム)が有効
  // - eu のユーザー(visitor) が email情報(hasContact) を持っていない場合に email 表示フォームを出現させる
  useEffect(() => {
    if (document?.documentShare?.isFormActive && !visitor?.hasContact) {
      setOpenMailForm(true)
    } else {
      setOpenMailForm(false)
    }
  }, [document?.documentShare, visitor?.hasContact])

  useEffect(() => {
    if (visitor && document) {
      setIsInit(true)
    }
  }, [visitor, document, documentShare, shareFormsRes])

  return (
    <>
      <Box width="100vw" height={isPCView ? '100vh' : '100%'}>
        {document && organization && (
          <DocumentSlideDetail
            isActivated={!!isActivated}
            documentShareId={documentShareId}
            siteShareId={siteShareId}
            policySettings={organization.policySettings}
            document={document}
            organization={organization}
            isPCView={isPCView}
            canRequestToExtendExpiredAt={canRequestToExtendExpiredAt}
            visitor={visitor}
            siteId={siteId}
            expiredOn={expiredOn}
          />
        )}
      </Box>
      {/* メール入力モーダル */}
      <ModalSendEmailWithAuthenticationCode
        onSubmit={(email: string) => {
          if (email && documentId) {
            onSubmitModalSendEmailWithAuthenticationCode(email)
          }
        }}
        open={openMailForm && !openVerifyCodeModal}
        onClose={() => {}}
        title={document?.title!}
        organizationName={organization?.name}
        policySettings={organization?.policySettings}
      />
      {/* code 入力モーダル */}
      <ModalAuthentication
        open={openVerifyCodeModal}
        email={email || ''}
        resend={() => {
          if (email && documentId) {
            handleSendAuthCodeWithEmail({ emailAddress: email, documentId, siteId })
          }
        }}
        onSubmit={onSubmitModalAuthentication}
        onClose={() => {}}
      />
      {/* フォーム差分入力モーダル */}
      {isInit && (
        <ModalVisitorConnectToContact
          organizationName={organization?.name}
          policySettings={organization?.policySettings}
          documentId={documentId}
          siteId={siteId}
          open={openEntryForm && !!shareFormsRes}
          onClose={() => setOpenForm(false)}
          onSubmit={values => {
            setOpenForm(false)
            handleUpdateContact({ contactSettingFieldValues: values, documentId, siteId })
            setIsSubmittingUpdateContact(true)
          }}
          documentShareForms={shareFormsRes?.shareForms}
        />
      )}
      {/* visitor かつ 同意するボタンが押されてない と非表示 */}
      <CookieAgreement open={openCookie} onClick={handleCookieClick} />
    </>
  )
}
