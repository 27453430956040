import React from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Typography, Input } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'

export interface ListNameModalProps {
  open: boolean
  title: string
  onClose: () => void
  onConfirm: () => void
  value: string | undefined
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

export const ListNameModal = ({ open, title, onClose, onConfirm, value, onChange }: ListNameModalProps) => {
  const classes = useStyles()
  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle={title}
            hideSettings
            onClose={onClose}
            onCancel={onClose}
            onConfirm={onConfirm}
            buttonDisabled={!value}
          >
            <Box px="24px" py="16px">
              <Box>
                <Typography fontSize="s" lineheight="14px" fontWeight="bold">
                  リスト名
                </Typography>
                <Box mt="10px" />
                <Input name="listName" fullWidth value={value} onChange={onChange} />
              </Box>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
