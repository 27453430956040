/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UpdateOrganizationAdvertisementRequestOrganizationFromJSON, UpdateOrganizationAdvertisementRequestOrganizationToJSON } from './UpdateOrganizationAdvertisementRequestOrganization';
export function UpdateOrganizationAdvertisementRequestFromJSON(json) {
    return UpdateOrganizationAdvertisementRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationAdvertisementRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organization: !exists(json, 'organization')
            ? undefined
            : UpdateOrganizationAdvertisementRequestOrganizationFromJSON(json['organization'])
    };
}
export function UpdateOrganizationAdvertisementRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization: UpdateOrganizationAdvertisementRequestOrganizationToJSON(value.organization)
    };
}
