import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { Colors } from 'src/components/atoms'
import { PaymentHeader, PreviousPageProps } from 'src/components/organisms'

export interface LayoutPaymentProps {
  title: string
  width?: string
  previousPage?: PreviousPageProps
  children: ReactNode
}

export const LayoutPayment = ({ title, width, previousPage, children }: LayoutPaymentProps) => {
  return (
    <Box
      sx={{
        minHeight: '100%',
        position: 'relative',
        bgcolor: Colors.background.lightGray
      }}
    >
      <style global jsx>{`
        html,
        body,
        div#__next {
          height: 100%;
        }
      `}</style>

      <PaymentHeader headerTitleText={title} previousPage={previousPage} />
      <Box
        sx={{
          width: '100%',
          maxWidth: width ?? '800px',
          margin: '0 auto',
          padding: '24px 0 85px'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
