import { Box, ButtonBase, createStyles, makeStyles, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import Link from 'next/link'
import { Colors, IconVariant, SvgIcon } from 'src/components/atoms'
import { DynamicMuiIcon, MuiIconVariant } from '../../atoms/DynamicMuiIcon'
import { useRouter } from 'next/router'
import { EnumUserPermission } from '@noco/http-client/lib/noco'
import { useGetMe } from 'src/fixtures/modules/me/hooks'

export interface MenuType {
  path: string
  icon?: MuiIconVariant
  svgIcon?: IconVariant
  label: string
  permissions?: EnumUserPermission[]
}

export interface HaveMenuTitleType {
  title: string
  permissions?: EnumUserPermission[]
  menuLists: MenuType[]
}

export type MenuList = HaveMenuTitleType[] | MenuType[]

export interface SubSideMenuProps {
  menuList: MenuList
}

const useStyles = makeStyles(() => {
  return createStyles({
    link: {
      textDecoration: 'none'
    },
    button: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      color: Colors.base.middleGray,
      '&:hover': {
        background: Colors.selected.background,
        color: Colors.accent.keyPurple.default
      }
    },
    haveMenuTitlePadding: {
      padding: '5px 0px 5px 20px'
    },
    noMenuTitlePadding: {
      padding: '6px 0px 6px 16px'
    },
    activeButton: {
      background: Colors.selected.background,
      color: Colors.accent.keyPurple.default
    }
  })
})

export const SubSideMenu = ({ menuList }: SubSideMenuProps) => {
  const router = useRouter()
  const currentSubSideMenu = router.pathname

  return (
    <Box
      width={215}
      height={1}
      flex="1 0 auto"
      display="flex"
      flexDirection="column"
      bgcolor={Colors.functional.background.default}
      borderRight={`1px solid ${Colors.background.silver}`}
    >
      <Box pt="12px">
        {menuList.map((menuGroup, i) =>
          Object.prototype.hasOwnProperty.call(menuGroup, 'title') ? (
            <HaveMenuTitle key={i} menuGroup={menuGroup as HaveMenuTitleType} currentSubSideMenu={currentSubSideMenu} />
          ) : (
            <NoMenuTitle key={i} menuGroup={menuGroup as MenuType} currentSubSideMenu={currentSubSideMenu} />
          )
        )}
      </Box>
    </Box>
  )
}

// タイトル有りメニューリスト @locco
interface HaveMenuTitleProps {
  menuGroup: HaveMenuTitleType
  currentSubSideMenu: string
}

const HaveMenuTitle = ({ menuGroup, currentSubSideMenu }: HaveMenuTitleProps) => {
  const classes = useStyles()
  const { data: me } = useGetMe()
  const user = me?.user
  const { title, menuLists, permissions } = menuGroup

  const filteredMemoLists = useMemo(() => {
    if (!user) return menuLists

    return menuLists.filter(menuListItem => {
      if (!menuListItem.permissions || !user?.permission) return true

      return menuListItem.permissions.includes(user.permission)
    })
  }, [menuLists, user])

  const isShowMenu = useMemo(() => {
    if (!user || !user?.permission) return true
    if (!permissions) return true

    return permissions.includes(user.permission)
  }, [user, permissions])

  if (!isShowMenu) return <></>

  return (
    <Box pb="16px">
      <Box padding="4px 16px" color={Colors.base.middleGray}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {filteredMemoLists.map(({ label, path, icon, svgIcon }: MenuType) => {
        return (
          <Link key={label} href={path} as={path} prefetch={false}>
            <a className={classes.link}>
              <ButtonBase
                className={`${classes.button} ${classes.haveMenuTitlePadding} ${
                  currentSubSideMenu === path && classes.activeButton
                }`}
              >
                {icon && <DynamicMuiIcon variant={icon} />}
                {svgIcon && (
                  <SvgIcon
                    variant={svgIcon}
                    size="24px"
                    color={currentSubSideMenu === path ? Colors.accent.keyPurple.default : 'inherit'}
                  />
                )}
                <Box ml="8px" />
                <Typography variant="h5">{label}</Typography>
              </ButtonBase>
            </a>
          </Link>
        )
      })}
    </Box>
  )
}

// タイトル無しメニューリスト @locco
interface NoMenuTitleProps {
  menuGroup: MenuType
  currentSubSideMenu: string
}

const NoMenuTitle = ({ menuGroup, currentSubSideMenu }: NoMenuTitleProps) => {
  const classes = useStyles()
  const { data: me } = useGetMe()
  const user = me?.user
  const { label, path, icon, svgIcon, permissions } = menuGroup

  const isShowMenu = useMemo(() => {
    if (!user || !user?.permission) return true
    if (!permissions) return true

    return permissions.includes(user.permission)
  }, [user, permissions])

  if (!isShowMenu) return <></>

  return (
    <Link href={path} as={path} prefetch={false}>
      <a className={classes.link}>
        <ButtonBase
          className={`${classes.button} ${classes.noMenuTitlePadding} ${
            currentSubSideMenu === path && classes.activeButton
          }`}
        >
          {icon && <DynamicMuiIcon variant={icon} />}
          {svgIcon && (
            <SvgIcon
              variant={svgIcon}
              size="24px"
              color={currentSubSideMenu === path ? Colors.accent.keyPurple.default : 'inherit'}
            />
          )}
          <Box ml="8px" />
          <Typography variant="h5">{label}</Typography>
        </ButtonBase>
      </a>
    </Link>
  )
}
