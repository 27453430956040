/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateOrganizationRequestFromJSON(json) {
    return UpdateOrganizationRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: !exists(json, 'name') ? undefined : json['name'],
        organizationSubdomain: !exists(json, 'organization_subdomain') ? undefined : json['organization_subdomain']
    };
}
export function UpdateOrganizationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        organization_subdomain: value.organizationSubdomain
    };
}
