import React, { ReactNode } from 'react'
import { Box } from '@material-ui/core'
import { Colors } from 'src/components/atoms'

export interface BoardProps {
  children: ReactNode
}

export const Board: React.VFC<BoardProps> = ({ children }) => {
  return (
    <Box
      sx={{
        p: '31px',
        bgcolor: '#fff',
        border: `1px solid ${Colors.background.gray}`,
        borderRadius: '10px',
        boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)`
      }}
    >
      {children}
    </Box>
  )
}
