import React from 'react'
import NextLink from 'next/link'
import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { Colors } from '..'

export interface LinkProps {
  text: string
  url: string
  variant?: Variant
}

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: Colors.accent.keyBlue.default,
      textDecoration: 'none'
    },
    text: {
      display: 'inline'
    }
  })
)

export const Link = ({ text, url, variant = 'subtitle1' }: LinkProps) => {
  const classes = useStyles()
  return (
    <NextLink href={url} as={url}>
      <a className={classes.link}>
        <Typography variant={variant} className={classes.text}>
          {text}
        </Typography>
      </a>
    </NextLink>
  )
}
