/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetCountContactsEmailTransactionsResponseData
 */
export interface GetCountContactsEmailTransactionsResponseData {
  /**
   *
   * @type {number}
   * @memberof GetCountContactsEmailTransactionsResponseData
   */
  count?: number
}

export function GetCountContactsEmailTransactionsResponseDataFromJSON(
  json: any
): GetCountContactsEmailTransactionsResponseData {
  return GetCountContactsEmailTransactionsResponseDataFromJSONTyped(json, false)
}

export function GetCountContactsEmailTransactionsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCountContactsEmailTransactionsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    count: !exists(json, 'count') ? undefined : json['count']
  }
}

export function GetCountContactsEmailTransactionsResponseDataToJSON(
  value?: GetCountContactsEmailTransactionsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    count: value.count
  }
}
