/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { CompanyFilter, CompanyFilterFromJSON, CompanyFilterFromJSONTyped, CompanyFilterToJSON } from './CompanyFilter'

/**
 *
 * @export
 * @interface GetCompanyFilterResponseData
 */
export interface GetCompanyFilterResponseData {
  /**
   *
   * @type {CompanyFilter}
   * @memberof GetCompanyFilterResponseData
   */
  filter?: CompanyFilter
}

export function GetCompanyFilterResponseDataFromJSON(json: any): GetCompanyFilterResponseData {
  return GetCompanyFilterResponseDataFromJSONTyped(json, false)
}

export function GetCompanyFilterResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCompanyFilterResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    filter: !exists(json, 'filter') ? undefined : CompanyFilterFromJSON(json['filter'])
  }
}

export function GetCompanyFilterResponseDataToJSON(value?: GetCompanyFilterResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    filter: CompanyFilterToJSON(value.filter)
  }
}
