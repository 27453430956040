/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumRecordPorterHistoryAction = {
  NextPlayImport: 'next_play_import',
  NextPlayBulkUpdate: 'next_play_bulk_update',
  FileDownload: 'file_download',
  ErrorFileDownload: 'error_file_download',
  ResultFileDownload: 'result_file_download'
} as const
export type EnumRecordPorterHistoryAction =
  typeof EnumRecordPorterHistoryAction[keyof typeof EnumRecordPorterHistoryAction]

export function EnumRecordPorterHistoryActionFromJSON(json: any): EnumRecordPorterHistoryAction {
  return EnumRecordPorterHistoryActionFromJSONTyped(json, false)
}

export function EnumRecordPorterHistoryActionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumRecordPorterHistoryAction {
  return json as EnumRecordPorterHistoryAction
}

export function EnumRecordPorterHistoryActionToJSON(value?: EnumRecordPorterHistoryAction | null): any {
  return value as any
}
