/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { ResponseDataSelectorCommonFromJSON, ResponseDataSelectorObjectCommonFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1SelectorApi extends runtime.BaseAPI {
    /**
     * selector向け チャットメッセージ一覧
     * index
     */
    async userV1SelectorChatMessagesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/chat_messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け チャットメッセージ一覧
     * index
     */
    async userV1SelectorChatMessagesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorChatMessagesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 会社一覧
     * index
     */
    async userV1SelectorCompaniesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 会社一覧
     * index
     */
    async userV1SelectorCompaniesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorCompaniesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 会社リスト一覧
     * index
     */
    async userV1SelectorCompanyListsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/company_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 会社リスト一覧
     * index
     */
    async userV1SelectorCompanyListsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorCompanyListsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 連絡先リスト一覧
     * index
     */
    async userV1SelectorContactListsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/contact_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 連絡先リスト一覧
     * index
     */
    async userV1SelectorContactListsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorContactListsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 連絡先一覧
     * index
     */
    async userV1SelectorContactsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 連絡先一覧
     * index
     */
    async userV1SelectorContactsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorContactsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 資料一覧
     * index
     */
    async userV1SelectorDocumentsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 資料一覧
     * index
     */
    async userV1SelectorDocumentsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorDocumentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け メールテンプレート一覧
     * index
     */
    async userV1SelectorMailTemplatesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/mail_templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け メールテンプレート一覧
     * index
     */
    async userV1SelectorMailTemplatesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorMailTemplatesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 共有可能資料・資料サイト一覧
     * index
     */
    async userV1SelectorMaterialablesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.canShareToContact !== undefined) {
            queryParameters['can_share_to_contact'] = requestParameters.canShareToContact;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/materialables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorObjectCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 共有可能資料・資料サイト一覧
     * index
     */
    async userV1SelectorMaterialablesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorMaterialablesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 共有先選択一覧
     * index
     */
    async userV1SelectorShareTargetsGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/share_targets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorObjectCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 共有先選択一覧
     * index
     */
    async userV1SelectorShareTargetsGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorShareTargetsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け 資料サイト一覧
     * index
     */
    async userV1SelectorSitesGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け 資料サイト一覧
     * index
     */
    async userV1SelectorSitesGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorSitesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * selector向け ユーザー一覧
     * index
     */
    async userV1SelectorUsersGetRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }
        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }
        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }
        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }
        if (requestParameters.per !== undefined) {
            queryParameters['per'] = requestParameters.per;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/selector/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => ResponseDataSelectorCommonFromJSON(jsonValue));
    }
    /**
     * selector向け ユーザー一覧
     * index
     */
    async userV1SelectorUsersGet(requestParameters = {}, initOverrides) {
        const response = await this.userV1SelectorUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
