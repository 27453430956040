/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Visitor, VisitorFromJSON, VisitorFromJSONTyped, VisitorToJSON } from './Visitor'

/**
 *
 * @export
 * @interface AuthContactMagicLinkLoginResponseDataVisitor
 */
export interface AuthContactMagicLinkLoginResponseDataVisitor {
  /**
   *
   * @type {Visitor}
   * @memberof AuthContactMagicLinkLoginResponseDataVisitor
   */
  contact?: Visitor
  /**
   *
   * @type {string}
   * @memberof AuthContactMagicLinkLoginResponseDataVisitor
   */
  token?: string
}

export function AuthContactMagicLinkLoginResponseDataVisitorFromJSON(
  json: any
): AuthContactMagicLinkLoginResponseDataVisitor {
  return AuthContactMagicLinkLoginResponseDataVisitorFromJSONTyped(json, false)
}

export function AuthContactMagicLinkLoginResponseDataVisitorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthContactMagicLinkLoginResponseDataVisitor {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contact: !exists(json, 'contact') ? undefined : VisitorFromJSON(json['contact']),
    token: !exists(json, 'token') ? undefined : json['token']
  }
}

export function AuthContactMagicLinkLoginResponseDataVisitorToJSON(
  value?: AuthContactMagicLinkLoginResponseDataVisitor | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact: VisitorToJSON(value.contact),
    token: value.token
  }
}
