/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CompanyForDisplayFromJSON, CompanyForDisplayToJSON } from './CompanyForDisplay';
import { ContactWithAssociationFromJSON, ContactWithAssociationToJSON } from './ContactWithAssociation';
import { EnumActivityStatusFromJSON, EnumActivityStatusToJSON } from './EnumActivityStatus';
import { EnumSupportStatusFromJSON, EnumSupportStatusToJSON } from './EnumSupportStatus';
import { MaterialableForDisplayFromJSON, MaterialableForDisplayToJSON } from './MaterialableForDisplay';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function EmailTransactionFromJSON(json) {
    return EmailTransactionFromJSONTyped(json, false);
}
export function EmailTransactionFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        supportStatus: !exists(json, 'support_status') ? undefined : EnumSupportStatusFromJSON(json['support_status']),
        activityStatus: !exists(json, 'activity_status') ? undefined : EnumActivityStatusFromJSON(json['activity_status']),
        subject: !exists(json, 'subject') ? undefined : json['subject'],
        sendedAt: !exists(json, 'sended_at') ? undefined : json['sended_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        isSolved: !exists(json, 'is_solved') ? undefined : json['is_solved'],
        contact: !exists(json, 'contact') ? undefined : ContactWithAssociationFromJSON(json['contact']),
        company: !exists(json, 'company') ? undefined : CompanyForDisplayFromJSON(json['company']),
        materialable: !exists(json, 'materialable') ? undefined : MaterialableForDisplayFromJSON(json['materialable']),
        user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user'])
    };
}
export function EmailTransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        support_status: EnumSupportStatusToJSON(value.supportStatus),
        activity_status: EnumActivityStatusToJSON(value.activityStatus),
        subject: value.subject,
        sended_at: value.sendedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        is_solved: value.isSolved,
        contact: ContactWithAssociationToJSON(value.contact),
        company: CompanyForDisplayToJSON(value.company),
        materialable: MaterialableForDisplayToJSON(value.materialable),
        user: UserForDisplayToJSON(value.user)
    };
}
