/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentPlanTransactionsResponseData,
  GetOrganizationPaymentPlanTransactionsResponseDataFromJSON,
  GetOrganizationPaymentPlanTransactionsResponseDataFromJSONTyped,
  GetOrganizationPaymentPlanTransactionsResponseDataToJSON
} from './GetOrganizationPaymentPlanTransactionsResponseData'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlanTransactionsResponse
 */
export interface GetOrganizationPaymentPlanTransactionsResponse {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentPlanTransactionsResponse
   */
  status: number
  /**
   *
   * @type {GetOrganizationPaymentPlanTransactionsResponseData}
   * @memberof GetOrganizationPaymentPlanTransactionsResponse
   */
  data: GetOrganizationPaymentPlanTransactionsResponseData
}

export function GetOrganizationPaymentPlanTransactionsResponseFromJSON(
  json: any
): GetOrganizationPaymentPlanTransactionsResponse {
  return GetOrganizationPaymentPlanTransactionsResponseFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlanTransactionsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlanTransactionsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetOrganizationPaymentPlanTransactionsResponseDataFromJSON(json['data'])
  }
}

export function GetOrganizationPaymentPlanTransactionsResponseToJSON(
  value?: GetOrganizationPaymentPlanTransactionsResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationPaymentPlanTransactionsResponseDataToJSON(value.data)
  }
}
