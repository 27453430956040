import { useCallback, useState } from 'react'
import { SWRCachePath } from '../swr-cach-path'
import { IAuth, IContactAuth } from 'src/fixtures/types/auth'
import { handleError } from 'src/fixtures/utils/error'
import { getTokenFromCookie, setTokenToCookie } from 'src/fixtures/utils'
import useSWR from 'swr'
import { nocoSDK } from '../initialize'
import { Toaster } from 'src/components/atoms'
import { useGetVisitor } from '../visitor/hooks'
import {
  AuthVisitorAuthenticationCodeRequestRequest,
  AuthVisitorAuthenticationCodeRequestWithEmailAddressRequest,
  AuthVisitorAuthenticationCodeVerifiyRequest,
  AuthVisitorSharedLinkSigninRequest
} from '@noco/http-client/lib/noco'

// MEMO: (@snuffy) magic リンク token を保持するのり利用する
export const useGetMagicLinkToken = () => {
  const func = useCallback(async () => {
    const cookie = getTokenFromCookie()
    return { token: cookie.magicLinkToken }
  }, [])
  const response = useSWR<{ token?: string }>(SWRCachePath.getMagicLinkToken(), func, {})
  const setToken = useCallback(
    (token: string) => {
      setTokenToCookie({ magicLinkToken: token })
      response.mutate()
    },
    [response]
  )

  return { ...response, setToken }
}

// MEMO: (@snuffy) magic リンク token を保持する
export const useContactAuthentication = () => {
  const [isLoading, setIsLoading] = useState(true)
  const func = useCallback(async () => {
    const cookie = getTokenFromCookie()
    setIsLoading(false)
    return cookie.contactToken ? ({ token: cookie.contactToken } as IContactAuth) : undefined
  }, [setIsLoading])
  const response = useSWR<IAuth | undefined>(SWRCachePath.getContactAuthenticate(), func, {})
  return { ...response, isLoading }
}

// MEMO: (@snuffy) visitor が email からログインする
export const useMagicLinkLogin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const authApi = nocoSDK.client?.contactService.authApi
  const { mutate: mutateVisitor } = useGetVisitor()
  const { data } = useGetMagicLinkToken()
  const handleLogin = useCallback(
    async (email: string) => {
      try {
        setIsLoading(true)
        setError(undefined)
        if (!data?.token) return

        const res = await authApi?.ApiFactory(data.token).contactV1MagicLinkSigninPost({
          authContactMagicLinkLoginRequest: { encryptedEmail: email, token: data?.token }
        })
        const token = res?.data?.visitor?.token
        if (!token) {
          console.error('token がありません')
          return
        }

        setTokenToCookie({ contactToken: token })

        mutateVisitor()
        Toaster.success('登録が完了しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Login' })
      } finally {
        setIsLoading(false)
      }
    },
    [authApi, data?.token, mutateVisitor]
  )
  return { error, isLoading, handleLogin }
}

// 認証コードの送信
export const useSendAuthenticationCode = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const visistorAuthApi = nocoSDK.client?.contactService.visitorAuthApi
  const { data: auth } = useContactAuthentication()
  const [email, setEmail] = useState('')
  const handleSendAuthCode = useCallback(
    async (authVisitorAuthenticationCodeRequestRequest: AuthVisitorAuthenticationCodeRequestRequest) => {
      try {
        setError(undefined)
        setIsLoading(true)
        setEmail('')
        if (!auth?.token) return
        await visistorAuthApi?.ApiFactory().contactV1AuthenticationCodeRequestPost({
          authVisitorAuthenticationCodeRequestRequest,
          xNocoVisitorToken: auth.token
        })

        Toaster.success('認証コードを送信しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'send authentication code' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth?.token, visistorAuthApi]
  )

  const handleSendAuthCodeWithEmail = useCallback(
    async (
      authVisitorAuthenticationCodeRequestWithEmailAddressRequest: AuthVisitorAuthenticationCodeRequestWithEmailAddressRequest
    ) => {
      try {
        setError(undefined)
        setIsLoading(true)
        setEmail('')
        if (!auth?.token) return

        await visistorAuthApi?.ApiFactory().contactV1AuthenticationCodeRequestWithEmailAddressPost({
          authVisitorAuthenticationCodeRequestWithEmailAddressRequest,
          xNocoVisitorToken: auth.token
        })
        setEmail(authVisitorAuthenticationCodeRequestWithEmailAddressRequest.emailAddress)
        Toaster.success('認証コードを送信しました')
      } catch (err) {
        console.error(err)
        await handleError(err, { setError, tag: 'send authentication code' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth?.token, visistorAuthApi]
  )
  return { error, isLoading, email, handleSendAuthCode, handleSendAuthCodeWithEmail }
}

export const useVerifyAuthenticationCode = (isPreview = false) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const visistorAuthApi = nocoSDK.client?.contactService.visitorAuthApi
  const { data: auth } = useContactAuthentication()
  const { mutate: mutateVisitor } = useGetVisitor(isPreview)

  const handleVerifyAuthCode = useCallback(
    async (authVisitorAuthenticationCodeVerifiyRequest: AuthVisitorAuthenticationCodeVerifiyRequest) => {
      try {
        setIsLoading(true)
        if (!auth?.token) return
        await visistorAuthApi?.ApiFactory().contactV1AuthenticationCodeVerifiyPost({
          authVisitorAuthenticationCodeVerifiyRequest,
          xNocoVisitorToken: auth.token
        })
        Toaster.success('認証コードを確認しました')
        await mutateVisitor()
      } catch (err) {
        console.error(err)
        await handleError(err, { setError, tag: 'Login' })
        throw err
      } finally {
        setIsLoading(false)
      }
    },
    [auth?.token, mutateVisitor, visistorAuthApi]
  )
  return { error, isLoading, handleVerifyAuthCode }
}

export const useSharedLinkSignin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const visistorAuthApi = nocoSDK.client?.contactService.visitorAuthApi
  const { data: auth } = useContactAuthentication()
  const [email, setEmail] = useState('')

  const handleSharedLinkSignin = useCallback(
    async (authVisitorSharedLinkSigninRequest: AuthVisitorSharedLinkSigninRequest) => {
      try {
        setError(undefined)
        setIsLoading(true)
        setEmail('')
        if (!auth?.token) return

        await visistorAuthApi?.ApiFactory(auth.token).contactV1SharedLinkSigninPost({
          authVisitorSharedLinkSigninRequest,
          xNocoVisitorToken: auth.token
        })
        setEmail(authVisitorSharedLinkSigninRequest.emailAddress)
        Toaster.success('登録が完了しました')
      } catch (err) {
        console.error(err)
        await handleError(err, { setError, tag: 'Login' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth?.token, visistorAuthApi]
  )
  return { error, isLoading, email, handleSharedLinkSignin }
}
