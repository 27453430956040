/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  UpdateChatMessageRequest,
  UpdateChatMessageRequestFromJSON,
  UpdateChatMessageRequestToJSON,
  UpdateChatMessageResponse,
  UpdateChatMessageResponseFromJSON,
  UpdateChatMessageResponseToJSON
} from '../models'

export interface UserV1ChatMessagesChatMessageIdDeleteRequest {
  chatMessageId: string
}

export interface UserV1ChatMessagesChatMessageIdPutRequest {
  chatMessageId: string
  updateChatMessageRequest?: UpdateChatMessageRequest
}

/**
 *
 */
export class ApiUserV1ChatMessageApi extends runtime.BaseAPI {
  /**
   */
  async userV1ChatMessagesChatMessageIdDeleteRaw(
    requestParameters: UserV1ChatMessagesChatMessageIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
      throw new runtime.RequiredError(
        'chatMessageId',
        'Required parameter requestParameters.chatMessageId was null or undefined when calling userV1ChatMessagesChatMessageIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/chat_messages/{chat_message_id}`.replace(
          `{${'chat_message_id'}}`,
          encodeURIComponent(String(requestParameters.chatMessageId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.TextApiResponse(response) as any
  }

  /**
   */
  async userV1ChatMessagesChatMessageIdDelete(
    requestParameters: UserV1ChatMessagesChatMessageIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<any> {
    const response = await this.userV1ChatMessagesChatMessageIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャットメッセージの編集をする
   * update
   */
  async userV1ChatMessagesChatMessageIdPutRaw(
    requestParameters: UserV1ChatMessagesChatMessageIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateChatMessageResponse>> {
    if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
      throw new runtime.RequiredError(
        'chatMessageId',
        'Required parameter requestParameters.chatMessageId was null or undefined when calling userV1ChatMessagesChatMessageIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/chat_messages/{chat_message_id}`.replace(
          `{${'chat_message_id'}}`,
          encodeURIComponent(String(requestParameters.chatMessageId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateChatMessageRequestToJSON(requestParameters.updateChatMessageRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateChatMessageResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージの編集をする
   * update
   */
  async userV1ChatMessagesChatMessageIdPut(
    requestParameters: UserV1ChatMessagesChatMessageIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateChatMessageResponse> {
    const response = await this.userV1ChatMessagesChatMessageIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
