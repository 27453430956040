/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AppendContactListRequest,
  AppendContactListRequestFromJSON,
  AppendContactListRequestToJSON,
  AppendContactListResponse,
  AppendContactListResponseFromJSON,
  AppendContactListResponseToJSON,
  CreateContactListRequest,
  CreateContactListRequestFromJSON,
  CreateContactListRequestToJSON,
  CreateContactListResponse,
  CreateContactListResponseFromJSON,
  CreateContactListResponseToJSON,
  GetContactListsResponse,
  GetContactListsResponseFromJSON,
  GetContactListsResponseToJSON,
  PinContactListRequest,
  PinContactListRequestFromJSON,
  PinContactListRequestToJSON,
  RemoveContactListRequest,
  RemoveContactListRequestFromJSON,
  RemoveContactListRequestToJSON,
  RemoveContactListResponse,
  RemoveContactListResponseFromJSON,
  RemoveContactListResponseToJSON,
  UpdateContactListRequest,
  UpdateContactListRequestFromJSON,
  UpdateContactListRequestToJSON,
  UpdateContactListResponse,
  UpdateContactListResponseFromJSON,
  UpdateContactListResponseToJSON
} from '../models'

export interface UserV1ContactListsContactListIdAppendPostRequest {
  contactListId: string
  appendContactListRequest?: AppendContactListRequest
}

export interface UserV1ContactListsContactListIdDeleteRequest {
  contactListId: string
}

export interface UserV1ContactListsContactListIdPutRequest {
  contactListId: string
  updateContactListRequest?: UpdateContactListRequest
}

export interface UserV1ContactListsContactListIdRemovePostRequest {
  contactListId: string
  removeContactListRequest?: RemoveContactListRequest
}

export interface UserV1ContactListsPinPostRequest {
  pinContactListRequest?: PinContactListRequest
}

export interface UserV1ContactListsPostRequest {
  createContactListRequest?: CreateContactListRequest
}

/**
 *
 */
export class ApiUserV1ContactListApi extends runtime.BaseAPI {
  /**
   * コンタクト一覧にコンタクトを追加する
   * append
   */
  async userV1ContactListsContactListIdAppendPostRaw(
    requestParameters: UserV1ContactListsContactListIdAppendPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AppendContactListResponse>> {
    if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
      throw new runtime.RequiredError(
        'contactListId',
        'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdAppendPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contact_lists/{contact_list_id}/append`.replace(
          `{${'contact_list_id'}}`,
          encodeURIComponent(String(requestParameters.contactListId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AppendContactListRequestToJSON(requestParameters.appendContactListRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AppendContactListResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト一覧にコンタクトを追加する
   * append
   */
  async userV1ContactListsContactListIdAppendPost(
    requestParameters: UserV1ContactListsContactListIdAppendPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AppendContactListResponse> {
    const response = await this.userV1ContactListsContactListIdAppendPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト一覧を削除する
   * destroy
   */
  async userV1ContactListsContactListIdDeleteRaw(
    requestParameters: UserV1ContactListsContactListIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
      throw new runtime.RequiredError(
        'contactListId',
        'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contact_lists/{contact_list_id}`.replace(
          `{${'contact_list_id'}}`,
          encodeURIComponent(String(requestParameters.contactListId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * コンタクト一覧を削除する
   * destroy
   */
  async userV1ContactListsContactListIdDelete(
    requestParameters: UserV1ContactListsContactListIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1ContactListsContactListIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト一覧を更新する
   * create
   */
  async userV1ContactListsContactListIdPutRaw(
    requestParameters: UserV1ContactListsContactListIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateContactListResponse>> {
    if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
      throw new runtime.RequiredError(
        'contactListId',
        'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contact_lists/{contact_list_id}`.replace(
          `{${'contact_list_id'}}`,
          encodeURIComponent(String(requestParameters.contactListId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateContactListRequestToJSON(requestParameters.updateContactListRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateContactListResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト一覧を更新する
   * create
   */
  async userV1ContactListsContactListIdPut(
    requestParameters: UserV1ContactListsContactListIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateContactListResponse> {
    const response = await this.userV1ContactListsContactListIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト一覧にコンタクトを削除する
   * remove
   */
  async userV1ContactListsContactListIdRemovePostRaw(
    requestParameters: UserV1ContactListsContactListIdRemovePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<RemoveContactListResponse>> {
    if (requestParameters.contactListId === null || requestParameters.contactListId === undefined) {
      throw new runtime.RequiredError(
        'contactListId',
        'Required parameter requestParameters.contactListId was null or undefined when calling userV1ContactListsContactListIdRemovePost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contact_lists/{contact_list_id}/remove`.replace(
          `{${'contact_list_id'}}`,
          encodeURIComponent(String(requestParameters.contactListId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RemoveContactListRequestToJSON(requestParameters.removeContactListRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => RemoveContactListResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト一覧にコンタクトを削除する
   * remove
   */
  async userV1ContactListsContactListIdRemovePost(
    requestParameters: UserV1ContactListsContactListIdRemovePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<RemoveContactListResponse> {
    const response = await this.userV1ContactListsContactListIdRemovePostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト一覧を取得する
   * index
   */
  async userV1ContactListsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactListsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/contact_lists`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactListsResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト一覧を取得する
   * index
   */
  async userV1ContactListsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactListsResponse> {
    const response = await this.userV1ContactListsGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * コンタクト一覧にpin設定をする
   * pin
   */
  async userV1ContactListsPinPostRaw(
    requestParameters: UserV1ContactListsPinPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contact_lists/pin`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PinContactListRequestToJSON(requestParameters.pinContactListRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * コンタクト一覧にpin設定をする
   * pin
   */
  async userV1ContactListsPinPost(
    requestParameters: UserV1ContactListsPinPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1ContactListsPinPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * コンタクト一覧を作成する
   * create
   */
  async userV1ContactListsPostRaw(
    requestParameters: UserV1ContactListsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateContactListResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/contact_lists`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateContactListRequestToJSON(requestParameters.createContactListRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateContactListResponseFromJSON(jsonValue))
  }

  /**
   * コンタクト一覧を作成する
   * create
   */
  async userV1ContactListsPost(
    requestParameters: UserV1ContactListsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateContactListResponse> {
    const response = await this.userV1ContactListsPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
