/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { FilterStaffFromJSON, FilterStaffToJSON } from './FilterStaff';
import { InboxFilterChatSummaryStatusesInnerFromJSON, InboxFilterChatSummaryStatusesInnerToJSON } from './InboxFilterChatSummaryStatusesInner';
export function InboxFilterFromJSON(json) {
    return InboxFilterFromJSONTyped(json, false);
}
export function InboxFilterFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        staffList: !exists(json, 'staff_list') ? undefined : FilterStaffFromJSON(json['staff_list']),
        chatSummaryStatuses: !exists(json, 'chat_summary_statuses')
            ? undefined
            : json['chat_summary_statuses'].map(InboxFilterChatSummaryStatusesInnerFromJSON)
    };
}
export function InboxFilterToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        staff_list: FilterStaffToJSON(value.staffList),
        chat_summary_statuses: value.chatSummaryStatuses === undefined
            ? undefined
            : value.chatSummaryStatuses.map(InboxFilterChatSummaryStatusesInnerToJSON)
    };
}
