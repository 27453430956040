import { Box } from '@material-ui/core'
import React from 'react'
import { Typography } from 'src/components/atoms'

export const MailAndPhone = ({
  mail,
  tel,
  mobile,
  type
}: {
  mail?: string
  tel?: string
  mobile?: string
  type?: string
}) => {
  return (
    <Box display="flex" height="16px" mb={type === 'company' ? '4px' : 'unset'}>
      {mail && (
        <Box mr="6px">
          <Typography fontSize="s" lineheight="15.84px" letterSpacing="tight">
            {mail}
          </Typography>
        </Box>
      )}
      {tel && (
        <Box mr="6px">
          <Typography fontSize="s" lineheight="15.84px" letterSpacing="tight">
            | {tel}
          </Typography>
        </Box>
      )}
      {mobile && (
        <Box mr="6px">
          <Typography fontSize="s" lineheight="15.84px" letterSpacing="tight">
            | {mobile}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
