import { Box } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { Button, Colors, DynamicMuiIcon, Input, Typography } from 'src/components/atoms'
import { LayoutUser } from 'src/components/commons'
import { useGetMe, useUpdateMe } from 'src/fixtures/modules/me/hooks'
import Image from 'next/image'
import { SectionTitle } from './SectionTitle'
import * as yup from 'yup'

export const urlValidationSchema = yup.object().shape({
  url: yup
    .string()
    .url('URLの形式で入力してください')
    .label('URL')
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[\w\-\\.\\/\\?\\,%&=\\#\\:\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+(\.[\w\-\\.\\/\\?\\,%&=\\#\\:\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+)+((\/)[\w#]+)*(\/\w+\?[\w\-\\.\\/\\?\\,%&=\\#\\:\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+=\w+(&[\w\-\\.\\/\\?\\,%&=\\#\\:\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+=\w+)*)?$/gm,
      '正しいURLの形式で入力してください。'
    )
})

export const PageNegotiationSetting = () => {
  const { handleUpdateMe } = useUpdateMe()
  const { data: me } = useGetMe()

  const formik = useFormik({
    validationSchema: urlValidationSchema,
    enableReinitialize: true,
    initialValues: {
      url: me?.user?.opportunityToolUrl || ''
    },
    onSubmit: values => {
      handleUpdateMe({ user: { opportunityToolUrl: values.url } })
    }
  })

  return (
    <LayoutUser>
      <Box width={1} bgcolor={Colors.functional.background.default} px="32px">
        <Box display="flex" alignItems="center" pt="16px" mb="24px">
          <DynamicMuiIcon variant="meeting" />
          <Box width="10px" />
          <Typography variant="h2">アポイントの設定</Typography>
        </Box>
        <Box mb="32px">
          <Typography variant="body2">
            日程調整ツールと連携することで、アポイントの可能な日程調整をスムーズに行うことができます
          </Typography>
        </Box>
        <SectionTitle title="日程調整ツール上で、アポイントの開催日時を設定する" index={1} />
        <Box>
          <Box ml="10px" pt="16px" pl="20px" pb="32px" borderLeft={`4px solid ${Colors.background.gray}`}>
            <Box
              sx={{ position: 'relative', width: '100%', maxWidth: 310, height: 213, overflow: 'hidden', mr: '24px' }}
            >
              <Image src={'/images/schedule_image.svg'} alt="schedule" layout="fill" objectFit="contain" />
            </Box>
          </Box>
        </Box>
        <SectionTitle title="日程調整ツールのURLを登録する" index={2} />
        <Box pt="18px" px="36px">
          <form onSubmit={formik.handleSubmit}>
            <Typography fontSize="s" fontWeight="bold">
              日程調整ツールで設定したURL
            </Typography>
            <Box height="6px" />
            <Input
              width="260px"
              placeholder="https://toaster.how"
              {...formik.getFieldProps('url')}
              error={formik.touched.url ? formik.errors.url : undefined}
            />
            <Box height="16px" />
            <Button title="保存" type="submit" disabled={!formik.isValid} />
          </form>
        </Box>
      </Box>
    </LayoutUser>
  )
}
