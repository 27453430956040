/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  RequestContactSettingFieldValueValuesInner,
  RequestContactSettingFieldValueValuesInnerFromJSON,
  RequestContactSettingFieldValueValuesInnerFromJSONTyped,
  RequestContactSettingFieldValueValuesInnerToJSON
} from './RequestContactSettingFieldValueValuesInner'

/**
 *
 * @export
 * @interface RequestContactSettingFieldValue
 */
export interface RequestContactSettingFieldValue {
  /**
   *
   * @type {string}
   * @memberof RequestContactSettingFieldValue
   */
  contactSettingId?: string
  /**
   *
   * @type {string}
   * @memberof RequestContactSettingFieldValue
   */
  contactSettingFieldId?: string
  /**
   *
   * @type {Array<RequestContactSettingFieldValueValuesInner>}
   * @memberof RequestContactSettingFieldValue
   */
  values?: Array<RequestContactSettingFieldValueValuesInner> | null
}

export function RequestContactSettingFieldValueFromJSON(json: any): RequestContactSettingFieldValue {
  return RequestContactSettingFieldValueFromJSONTyped(json, false)
}

export function RequestContactSettingFieldValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RequestContactSettingFieldValue {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactSettingId: !exists(json, 'contact_setting_id') ? undefined : json['contact_setting_id'],
    contactSettingFieldId: !exists(json, 'contact_setting_field_id') ? undefined : json['contact_setting_field_id'],
    values: !exists(json, 'values')
      ? undefined
      : json['values'] === null
      ? null
      : (json['values'] as Array<any>).map(RequestContactSettingFieldValueValuesInnerFromJSON)
  }
}

export function RequestContactSettingFieldValueToJSON(value?: RequestContactSettingFieldValue | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_setting_id: value.contactSettingId,
    contact_setting_field_id: value.contactSettingFieldId,
    values:
      value.values === undefined
        ? undefined
        : value.values === null
        ? null
        : (value.values as Array<any>).map(RequestContactSettingFieldValueValuesInnerToJSON)
  }
}
