/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateSiteContactRequestSiteContact,
  UpdateSiteContactRequestSiteContactFromJSON,
  UpdateSiteContactRequestSiteContactFromJSONTyped,
  UpdateSiteContactRequestSiteContactToJSON
} from './UpdateSiteContactRequestSiteContact'

/**
 *
 * @export
 * @interface UpdateDocumentContactRequest
 */
export interface UpdateDocumentContactRequest {
  /**
   *
   * @type {UpdateSiteContactRequestSiteContact}
   * @memberof UpdateDocumentContactRequest
   */
  documentContact?: UpdateSiteContactRequestSiteContact
}

export function UpdateDocumentContactRequestFromJSON(json: any): UpdateDocumentContactRequest {
  return UpdateDocumentContactRequestFromJSONTyped(json, false)
}

export function UpdateDocumentContactRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateDocumentContactRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentContact: !exists(json, 'document_contact')
      ? undefined
      : UpdateSiteContactRequestSiteContactFromJSON(json['document_contact'])
  }
}

export function UpdateDocumentContactRequestToJSON(value?: UpdateDocumentContactRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_contact: UpdateSiteContactRequestSiteContactToJSON(value.documentContact)
  }
}
