import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Zip = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#FFB11F" />
      <path
        d="M24.8607 26.2008V22.2114C24.8607 21.7801 24.5174 21.4387 24.0839 21.4387H21.9161C21.4826 21.4387 21.1394 21.7801 21.1394 22.2114V26.2008L19.929 29.723C19.5858 30.7114 19.7484 31.8076 20.3626 32.6522C20.9587 33.4968 21.9523 34 23 34C24.0477 34 25.0232 33.4968 25.6374 32.6522C26.2516 31.8076 26.4142 30.7114 26.071 29.723L24.8607 26.2008ZM24.3548 31.7357C23.7407 32.6163 22.2594 32.6163 21.6271 31.7357C21.32 31.3044 21.2297 30.7294 21.4103 30.2262L21.8078 29.0402H24.1742L24.5897 30.2262C24.7523 30.7294 24.68 31.3044 24.3548 31.7357ZM18.1949 0.0179704V2.21036C18.1949 2.64165 18.5381 2.98309 18.9716 2.98309H21.9161V6.2537H18.9716C18.5381 6.2537 18.1949 6.59514 18.1949 7.02643V8.71564C18.1949 9.14693 18.5381 9.50634 18.9716 9.50634H21.9161V12.759H18.9716C18.5381 12.759 18.1949 13.1004 18.1949 13.5317V15.2389C18.1949 15.6702 18.5381 16.0116 18.9716 16.0116H21.9161V18.4915C21.9161 18.9228 22.2774 19.2643 22.711 19.2643H27.0284C27.4439 19.2643 27.8052 18.9228 27.8052 18.4915V16.7844C27.8052 16.371 27.4439 16.0116 27.0284 16.0116H24.0658V12.759H27.0284C27.4439 12.759 27.8052 12.4175 27.8052 11.9863V10.2791C27.8052 9.84778 27.4439 9.50634 27.0284 9.50634H24.0658V6.2537H27.0284C27.4439 6.2537 27.8052 5.89429 27.8052 5.463V3.77378C27.8052 3.34249 27.4439 2.98309 27.0284 2.98309H24.0658V0L18.1949 0.0179704Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Zip
