import React, { PropsWithChildren, useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { createStyles, makeStyles, Box, Modal, ButtonBase, Input, Slide, useMediaQuery } from '@material-ui/core'
import { Button, Typography, Colors, Pallete } from '../../atoms'
import theme from 'src/styles/theme'

export interface ModalAuthenticationProps {
  open: boolean
  onClose: () => void
  email: string
  resend: () => void
  onSubmit: (code: string) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    buttonTextColor: {
      color: Colors.accent.keyBlue.default
    },
    lineFeed: {
      whiteSpace: 'pre-line'
    },
    inputRoot: {
      width: '100%',
      height: '80px'
    },
    input: {
      fontSize: '21px',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    inputFocused: {
      borderColor: Colors.success.default
    }
  })
)

export const ModalAuthentication = ({
  open,
  email,
  onClose,
  resend,
  onSubmit
}: PropsWithChildren<ModalAuthenticationProps>) => {
  const classes = useStyles()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [characters, setCharacters] = useState<(string | undefined)[]>([undefined, undefined, undefined, undefined])
  const text1 = useRef<HTMLInputElement | null>(null)
  const text2 = useRef<HTMLInputElement | null>(null)
  const text3 = useRef<HTMLInputElement | null>(null)
  const text4 = useRef<HTMLInputElement | null>(null)

  const refs = useMemo(() => [text1, text2, text3, text4], [])

  const [focusIndex, setFocusIndex] = useState(0)
  const [buttonActive, setButtonActive] = useState(false)
  const [allFocused, setAllFocused] = useState(false)
  const handleKeyDown = useCallback(
    (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, metaKey, code, keyCode } = e
      if (
        metaKey ||
        key === 'Shift' ||
        key === 'Control' ||
        key === 'Alt' ||
        key === 'ArrowLeft' ||
        key === 'ArrowRight' ||
        key === 'ArrowTop' ||
        key === 'ArrowUp' ||
        key === 'ArrowDown' ||
        code === 'Enter' ||
        code === 'Lang2' ||
        code === 'Lang1' ||
        code === 'Escape' ||
        code === 'Tab'
      ) {
        return false
      }

      const newCharacters = [...characters]
      let value = key
      let nextIndex = index < 5 ? index + 1 : index

      if (code === 'Backspace' || keyCode === 8) {
        nextIndex = index !== 0 ? index - 1 : 0
        value = ''
      }
      // MEMO: code には windows 端末の場合 'KeyV' や 'KeyC' などが入る場合があるので、その場合は
      // 対応してあげるようにする.
      if (!/[0-9]/.test(key) && code !== 'Backspace' && keyCode !== 8 && code !== 'KeyV') {
        e.preventDefault()
        return false
      }

      newCharacters[index] = value
      setCharacters(newCharacters)
      setFocusIndex(nextIndex)

      setTimeout(() => {
        refs[nextIndex].current?.focus()
      }, 64)
    },
    [characters, refs]
  )

  useEffect(() => {
    if (focusIndex == characters.length - 1) {
      setAllFocused(true)
    }
  }, [focusIndex, characters.length, refs])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const invalid = characters.some(c => !c || c.length == 0)
    setButtonActive(!invalid && allFocused)
  }, [characters])
  /* eslint-enable */

  const handleSubmit = useCallback(() => {
    onSubmit(characters.join(''))
  }, [characters, onSubmit])

  const handleResend = useCallback(() => {
    resend()
  }, [resend])

  useEffect(() => {
    if (open) {
      setCharacters([undefined, undefined, undefined, undefined])
      setFocusIndex(0)
      setButtonActive(false)
      setAllFocused(false)
      text1.current = null
      text2.current = null
      text3.current = null
      text4.current = null
    }
  }, [open])

  const handlePaste = useCallback(
    (e: React.ClipboardEvent) => {
      let paste = e.clipboardData.getData('text').trim().replace(/\s/g, '')
      const newCharacters = [...characters]
      for (let idx = 0; idx < 6; idx++) {
        const char = paste[idx]
        if (char) newCharacters[idx] = char.toUpperCase()
        if (idx === paste.length - 1 || idx === 5) {
          setFocusIndex(Math.min(idx, 5))
        }
      }
      setCharacters(newCharacters)
      if (paste.length >= 4) setAllFocused(true)
      e.preventDefault()
    },
    [characters]
  )

  return (
    <Modal
      open={open}
      disableEscapeKeyDown
      onClose={onClose}
      // MEMO: なぜか class でうまくスタイルが当たらないので inline style (mui 5 では治る)
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999999,
        paddingLeft: isDownSm ? '8px' : '0px',
        paddingRight: isDownSm ? '8px' : '0px'
      }}
    >
      <Slide in={open} direction="up">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.silver}`}
          overflow="hidden"
          zIndex={9999999999999}
          px="16px"
        >
          <Box
            width={1}
            py="16px"
            borderBottom={`1px solid ${Colors.background.silver}`}
            display="flex"
            justifyContent="center"
          >
            <Typography fontSize="l" fontWeight="bold" lineheight="27px">
              資料の閲覧には、下記ご入力ください
            </Typography>
          </Box>
          <Box px={isDownSm ? '0' : '26px'} pt="24px" mb="23px">
            <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight" className={classes.lineFeed}>
              {`${email}に確認コードを記載したメールを送信しました\n 記載されている4桁の確認コードを入力してください`}
            </Typography>
          </Box>
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight" fontWeight="bold">
            確認コードを入力
          </Typography>
          <Box mt="8px" mb="26px" display="flex" width={1} maxWidth="352px" justifyContent="space-between">
            {characters.map((c, i) => (
              <InputBox key={i} ref={refs[i]} index={i} onKeyDown={handleKeyDown(i)} onPaste={handlePaste} value={c} />
            ))}
          </Box>
          <Button title="送信" width="116px" disabled={!buttonActive} onClick={handleSubmit} />
          <Box mt="20px" />
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
            確認メールが届かない場合は、以下より再送信してください
          </Typography>
          <Box mt="9px" mb="32px">
            <ButtonBase className={classes.buttonTextColor} onClick={handleResend}>
              <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                再送信する
              </Typography>
            </ButtonBase>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}

type InputBoxProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onPaste?: React.ClipboardEventHandler
  onKeyDown?: React.KeyboardEventHandler
  onFocus?: React.FocusEventHandler
  index: number
  value?: string
}
const InputBox = React.forwardRef(function InputBoxInside(
  { onChange, index, value, onPaste, onKeyDown }: InputBoxProps,
  ref
) {
  const classes = useStyles()
  return (
    <Box width="50px" height="80px" display="flex" alignItems="center">
      <Input
        type="tel"
        name={`code${index}`}
        classes={{ root: classes.inputRoot, input: classes.input, focused: classes.inputFocused }}
        value={value}
        onChange={onChange}
        placeholder="-"
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        inputRef={ref}
        inputProps={{
          maxLength: 1
        }}
        autoFocus={index === 0 ? true : false}
      />
    </Box>
  )
})
