import React, { ReactElement, useCallback, useState } from 'react'
import { Box, ButtonBase, createStyles, makeStyles, Popover } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Input, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { User } from '@noco/http-client/lib/noco'
import clsx from 'clsx'
import { useListDocumentSearchOpportunityUsers } from 'src/fixtures/modules/documents/hooks'

export interface SelectOpportunityUserProps {
  id?: string
  documentId: string
  open: boolean
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  onChangeKeyword?: (keyword: string) => void
  onSelect?: (value: User) => Promise<void>
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      textAlign: 'left'
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    popover: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    },
    breakAll: {
      wordBreak: 'break-all'
    }
  })
})

export interface OptionData {
  documentId: string
  label: string
  value: string
  rightSection?: ReactElement
}

const OPTION_HEIGHT = 28

export const SelectOpportunityUser = ({
  id,
  open,
  anchorEl,
  documentId,
  onClose,
  onChangeKeyword,
  onSelect
}: SelectOpportunityUserProps) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const { data } = useListDocumentSearchOpportunityUsers(documentId, inputValue)
  const handleChange = useCallback(
    (value: string) => {
      setInputValue(value)
      onChangeKeyword?.(value)
    },
    [onChangeKeyword]
  )
  const globalClasses = useGlobalStyles()

  const handleClose = useCallback(() => {
    setInputValue('')
    onClose()
  }, [onClose])

  const handleSelect = useCallback(
    async (user: User) => {
      await onSelect?.(user)
      setInputValue('')
    },
    [onSelect]
  )

  const getPosition = useCallback(() => {
    if (!anchorEl) return
    const rect = anchorEl.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [anchorEl])

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      anchorReference="anchorPosition"
      anchorPosition={getPosition()}
      marginThreshold={0}
      PaperProps={{
        className: classes.popover,
        style: { width: (anchorEl && anchorEl.offsetWidth - 2) || 0 }
      }}
    >
      <Box pt="12px" pb="16px">
        <Box px="12px" mb="10px">
          <Input
            onChange={e => handleChange(e.currentTarget.value as string)}
            name="user"
            width="100%"
            value={inputValue}
            startAdornment={
              <Box
                pl="14px"
                color={Colors.base.middleGray}
                fontSize="18px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
              </Box>
            }
            endAdornment={
              inputValue ? (
                <Box
                  p="10px"
                  color={Colors.base.middleGray}
                  fontSize="16px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setInputValue('')}
                  className={globalClasses.cursorPointer}
                >
                  <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
                </Box>
              ) : undefined
            }
          />
        </Box>
        <Box display="flex" flexDirection="column" overflow="auto" maxHeight={OPTION_HEIGHT * 7}>
          {data?.users?.map(user => (
            <ButtonBase
              key={user.id}
              onClick={() => handleSelect(user)}
              className={clsx(classes.buttonBase, classes.hoverButton)}
            >
              <Box display="flex" width={1} alignItems="center" py="8px" px="12px">
                <Box display="flex" alignItems="center" mr="4px">
                  <Box flexShrink={0} borderRadius="50%" overflow="hidden" width="20px" height="20px" mr="8px">
                    <img src={user?.avatar?.url} alt="avatar" className={globalClasses.imgCover} />
                  </Box>
                  <Box width="100px" color={Colors.base.black} className={globalClasses.lineclamp1}>
                    <Typography variant="subtitle1">
                      {user?.lastName} {user?.firstName}
                    </Typography>
                  </Box>
                </Box>

                <Box width="141px" alignItems="center" className={globalClasses.lineclamp1}>
                  <Typography variant="subtitle2" style={{ color: '#6D7784' }}>
                    {user.encryptedEmail}
                  </Typography>
                </Box>
              </Box>
            </ButtonBase>
          ))}
        </Box>
      </Box>
    </Popover>
  )
}
