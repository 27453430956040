/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  AppendContactsCompanyRequest,
  AppendContactsCompanyRequestFromJSON,
  AppendContactsCompanyRequestToJSON,
  AppendContactsCompanyResponse,
  AppendContactsCompanyResponseFromJSON,
  AppendContactsCompanyResponseToJSON,
  ArchiveCompaniesRequest,
  ArchiveCompaniesRequestFromJSON,
  ArchiveCompaniesRequestToJSON,
  ArchiveCompaniesResponse,
  ArchiveCompaniesResponseFromJSON,
  ArchiveCompaniesResponseToJSON,
  CompanyTimelinesResponse,
  CompanyTimelinesResponseFromJSON,
  CompanyTimelinesResponseToJSON,
  CreateCompanyMemoRequest,
  CreateCompanyMemoRequestFromJSON,
  CreateCompanyMemoRequestToJSON,
  CreateCompanyMemoResponse,
  CreateCompanyMemoResponseFromJSON,
  CreateCompanyMemoResponseToJSON,
  CreateCompanyRequest,
  CreateCompanyRequestFromJSON,
  CreateCompanyRequestToJSON,
  CreateCompanyResponse,
  CreateCompanyResponseFromJSON,
  CreateCompanyResponseToJSON,
  GetCompaniesResponse,
  GetCompaniesResponseFromJSON,
  GetCompaniesResponseToJSON,
  GetCompanyCompanyListsResponse,
  GetCompanyCompanyListsResponseFromJSON,
  GetCompanyCompanyListsResponseToJSON,
  GetCompanyResponse,
  GetCompanyResponseFromJSON,
  GetCompanyResponseToJSON,
  UpdateCompanyRequest,
  UpdateCompanyRequestFromJSON,
  UpdateCompanyRequestToJSON,
  UpdateCompanyResponse,
  UpdateCompanyResponseFromJSON,
  UpdateCompanyResponseToJSON
} from '../models'

export interface UserV1CompaniesArchivePutRequest {
  archiveCompaniesRequest?: ArchiveCompaniesRequest
}

export interface UserV1CompaniesCompanyIdAppendContactsPutRequest {
  companyId: string
  appendContactsCompanyRequest?: AppendContactsCompanyRequest
}

export interface UserV1CompaniesCompanyIdGetRequest {
  companyId: string
}

export interface UserV1CompaniesCompanyIdListsGetRequest {
  companyId: string
}

export interface UserV1CompaniesCompanyIdMemosPostRequest {
  companyId: string
  createCompanyMemoRequest?: CreateCompanyMemoRequest
}

export interface UserV1CompaniesCompanyIdPutRequest {
  companyId: string
  updateCompanyRequest?: UpdateCompanyRequest
}

export interface UserV1CompaniesCompanyIdTimelinesGetRequest {
  companyId: string
  page?: number
  per?: number
}

export interface UserV1CompaniesGetRequest {
  companyListId?: string
  text?: string
  userId?: string
  userIds?: Array<string>
  createdOnFrom?: string
  createdOnTo?: string
  isArchived?: boolean
  page?: number
  per?: number
  orderBy?: string
}

export interface UserV1CompaniesPostRequest {
  createCompanyRequest?: CreateCompanyRequest
}

/**
 *
 */
export class ApiUserV1CompanyApi extends runtime.BaseAPI {
  /**
   * 会社のアーカイブ設定
   * archive
   */
  async userV1CompaniesArchivePutRaw(
    requestParameters: UserV1CompaniesArchivePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ArchiveCompaniesResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/companies/archive`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ArchiveCompaniesRequestToJSON(requestParameters.archiveCompaniesRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ArchiveCompaniesResponseFromJSON(jsonValue))
  }

  /**
   * 会社のアーカイブ設定
   * archive
   */
  async userV1CompaniesArchivePut(
    requestParameters: UserV1CompaniesArchivePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ArchiveCompaniesResponse> {
    const response = await this.userV1CompaniesArchivePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社にコンタクトを紐づける
   * append_contacts
   */
  async userV1CompaniesCompanyIdAppendContactsPutRaw(
    requestParameters: UserV1CompaniesCompanyIdAppendContactsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AppendContactsCompanyResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdAppendContactsPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}/append_contacts`.replace(
          `{${'company_id'}}`,
          encodeURIComponent(String(requestParameters.companyId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AppendContactsCompanyRequestToJSON(requestParameters.appendContactsCompanyRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => AppendContactsCompanyResponseFromJSON(jsonValue))
  }

  /**
   * 会社にコンタクトを紐づける
   * append_contacts
   */
  async userV1CompaniesCompanyIdAppendContactsPut(
    requestParameters: UserV1CompaniesCompanyIdAppendContactsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AppendContactsCompanyResponse> {
    const response = await this.userV1CompaniesCompanyIdAppendContactsPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社の詳細情報を取得する
   * show
   */
  async userV1CompaniesCompanyIdGetRaw(
    requestParameters: UserV1CompaniesCompanyIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetCompanyResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}`.replace(
          `{${'company_id'}}`,
          encodeURIComponent(String(requestParameters.companyId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetCompanyResponseFromJSON(jsonValue))
  }

  /**
   * 会社の詳細情報を取得する
   * show
   */
  async userV1CompaniesCompanyIdGet(
    requestParameters: UserV1CompaniesCompanyIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetCompanyResponse> {
    const response = await this.userV1CompaniesCompanyIdGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社が所属している会社リスト一覧を取得
   * list
   */
  async userV1CompaniesCompanyIdListsGetRaw(
    requestParameters: UserV1CompaniesCompanyIdListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetCompanyCompanyListsResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdListsGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}/lists`.replace(
          `{${'company_id'}}`,
          encodeURIComponent(String(requestParameters.companyId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetCompanyCompanyListsResponseFromJSON(jsonValue))
  }

  /**
   * 会社が所属している会社リスト一覧を取得
   * list
   */
  async userV1CompaniesCompanyIdListsGet(
    requestParameters: UserV1CompaniesCompanyIdListsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetCompanyCompanyListsResponse> {
    const response = await this.userV1CompaniesCompanyIdListsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async userV1CompaniesCompanyIdMemosPostRaw(
    requestParameters: UserV1CompaniesCompanyIdMemosPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateCompanyMemoResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}/memos`.replace(
          `{${'company_id'}}`,
          encodeURIComponent(String(requestParameters.companyId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateCompanyMemoRequestToJSON(requestParameters.createCompanyMemoRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateCompanyMemoResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1CompaniesCompanyIdMemosPost(
    requestParameters: UserV1CompaniesCompanyIdMemosPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateCompanyMemoResponse> {
    const response = await this.userV1CompaniesCompanyIdMemosPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社情報の編集をする
   * update
   */
  async userV1CompaniesCompanyIdPutRaw(
    requestParameters: UserV1CompaniesCompanyIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateCompanyResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}`.replace(
          `{${'company_id'}}`,
          encodeURIComponent(String(requestParameters.companyId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateCompanyRequestToJSON(requestParameters.updateCompanyRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateCompanyResponseFromJSON(jsonValue))
  }

  /**
   * 会社情報の編集をする
   * update
   */
  async userV1CompaniesCompanyIdPut(
    requestParameters: UserV1CompaniesCompanyIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateCompanyResponse> {
    const response = await this.userV1CompaniesCompanyIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async userV1CompaniesCompanyIdTimelinesGetRaw(
    requestParameters: UserV1CompaniesCompanyIdTimelinesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CompanyTimelinesResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdTimelinesGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}/timelines`.replace(
          `{${'company_id'}}`,
          encodeURIComponent(String(requestParameters.companyId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CompanyTimelinesResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1CompaniesCompanyIdTimelinesGet(
    requestParameters: UserV1CompaniesCompanyIdTimelinesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CompanyTimelinesResponse> {
    const response = await this.userV1CompaniesCompanyIdTimelinesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社情報の一覧を取得する
   * index
   */
  async userV1CompaniesGetRaw(
    requestParameters: UserV1CompaniesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetCompaniesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.companyListId !== undefined) {
      queryParameters['company_list_id'] = requestParameters.companyListId
    }

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.userId !== undefined) {
      queryParameters['user_id'] = requestParameters.userId
    }

    if (requestParameters.userIds) {
      queryParameters['user_ids[]'] = requestParameters.userIds
    }

    if (requestParameters.createdOnFrom !== undefined) {
      queryParameters['created_on_from'] = requestParameters.createdOnFrom
    }

    if (requestParameters.createdOnTo !== undefined) {
      queryParameters['created_on_to'] = requestParameters.createdOnTo
    }

    if (requestParameters.isArchived !== undefined) {
      queryParameters['is_archived'] = requestParameters.isArchived
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/companies`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetCompaniesResponseFromJSON(jsonValue))
  }

  /**
   * 会社情報の一覧を取得する
   * index
   */
  async userV1CompaniesGet(
    requestParameters: UserV1CompaniesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetCompaniesResponse> {
    const response = await this.userV1CompaniesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 会社の作成をする
   * create
   */
  async userV1CompaniesPostRaw(
    requestParameters: UserV1CompaniesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateCompanyResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/companies`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateCompanyRequestToJSON(requestParameters.createCompanyRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateCompanyResponseFromJSON(jsonValue))
  }

  /**
   * 会社の作成をする
   * create
   */
  async userV1CompaniesPost(
    requestParameters: UserV1CompaniesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateCompanyResponse> {
    const response = await this.userV1CompaniesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
