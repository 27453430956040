import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Youtube = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8008 3.24255C19.8008 3.24255 19.6055 1.86365 19.0039 1.25818C18.2422 0.461304 17.3906 0.457397 17 0.410522C14.2031 0.207397 10.0039 0.207397 10.0039 0.207397H9.99609C9.99609 0.207397 5.79688 0.207397 3 0.410522C2.60938 0.457397 1.75781 0.461304 0.996094 1.25818C0.394531 1.86365 0.203125 3.24255 0.203125 3.24255C0.203125 3.24255 0 4.86365 0 6.48084V7.99646C0 9.61365 0.199219 11.2347 0.199219 11.2347C0.199219 11.2347 0.394531 12.6136 0.992187 13.2191C1.75391 14.016 2.75391 13.9886 3.19922 14.0746C4.80078 14.2269 10 14.2738 10 14.2738C10 14.2738 14.2031 14.266 17 14.0668C17.3906 14.0199 18.2422 14.016 19.0039 13.2191C19.6055 12.6136 19.8008 11.2347 19.8008 11.2347C19.8008 11.2347 20 9.61755 20 7.99646V6.48084C20 4.86365 19.8008 3.24255 19.8008 3.24255ZM7.93359 9.8363V4.21521L13.3359 7.03552L7.93359 9.8363Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default Youtube
