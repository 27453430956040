/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactMaterial,
  ContactMaterialFromJSON,
  ContactMaterialFromJSONTyped,
  ContactMaterialToJSON
} from './ContactMaterial'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetContactMaterialsResponseData
 */
export interface GetContactMaterialsResponseData {
  /**
   *
   * @type {Array<ContactMaterial>}
   * @memberof GetContactMaterialsResponseData
   */
  contactMaterials?: Array<ContactMaterial>
  /**
   *
   * @type {PageInfo}
   * @memberof GetContactMaterialsResponseData
   */
  pageInfo?: PageInfo
}

export function GetContactMaterialsResponseDataFromJSON(json: any): GetContactMaterialsResponseData {
  return GetContactMaterialsResponseDataFromJSONTyped(json, false)
}

export function GetContactMaterialsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactMaterialsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactMaterials: !exists(json, 'contact_materials')
      ? undefined
      : (json['contact_materials'] as Array<any>).map(ContactMaterialFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetContactMaterialsResponseDataToJSON(value?: GetContactMaterialsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_materials:
      value.contactMaterials === undefined
        ? undefined
        : (value.contactMaterials as Array<any>).map(ContactMaterialToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
