import { Box, createStyles, makeStyles } from '@material-ui/core'
import { OrganizationPlanDetail, OrganizationPlanDetailGradeEnum } from '@noco/http-client/lib/noco'
import React from 'react'
import { Button } from 'src/components/atoms'
import { PaymentCompletedContractCard } from 'src/components/molecules'

export interface ContractFourthStepProps {
  plan: OrganizationPlanDetail
  onConfirm: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      padding: '0 24px'
    }
  })
)

const generatePlanMessage = (planGrade: string) => {
  if (planGrade === OrganizationPlanDetailGradeEnum.Free) {
    return '無料プランのお申し込みが完了しました！\n引き続き、ノコセルをご利用ください'
  } else if (planGrade === OrganizationPlanDetailGradeEnum.FreePremium) {
    return 'アイドマプランのお申し込みが完了しました！\n引き続き、ノコセルをご利用ください'
  } else {
    return 'プランを変更しました！\n引き続きノコセルをご活用ください'
  }
}

export const ContractFourthStep = ({ plan, onConfirm }: ContractFourthStepProps) => {
  const classes = useStyles()
  const confirmMessage = generatePlanMessage(plan.grade)

  return (
    <Box>
      <Box mb="24px">
        <PaymentCompletedContractCard message={confirmMessage} />
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          tabIndex={-1}
          className={classes.submitButton}
          title="ご契約内容へ移動する"
          onClick={() => onConfirm()}
          width="217px"
          size="large"
        />
      </Box>
    </Box>
  )
}
