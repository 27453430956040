/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AuditColumnValueObject
 */
export interface AuditColumnValueObject {
  /**
   *
   * @type {string}
   * @memberof AuditColumnValueObject
   */
  displayText?: string | null
  /**
   *
   * @type {string}
   * @memberof AuditColumnValueObject
   */
  columnType?: string | null
  /**
   *
   * @type {string}
   * @memberof AuditColumnValueObject
   */
  columnId?: string
}

export function AuditColumnValueObjectFromJSON(json: any): AuditColumnValueObject {
  return AuditColumnValueObjectFromJSONTyped(json, false)
}

export function AuditColumnValueObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditColumnValueObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    displayText: !exists(json, 'display_text') ? undefined : json['display_text'],
    columnType: !exists(json, 'column_type') ? undefined : json['column_type'],
    columnId: !exists(json, 'column_id') ? undefined : json['column_id']
  }
}

export function AuditColumnValueObjectToJSON(value?: AuditColumnValueObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    display_text: value.displayText,
    column_type: value.columnType,
    column_id: value.columnId
  }
}
