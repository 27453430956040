/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetContactFilterResponseData,
  GetContactFilterResponseDataFromJSON,
  GetContactFilterResponseDataFromJSONTyped,
  GetContactFilterResponseDataToJSON
} from './GetContactFilterResponseData'

/**
 *
 * @export
 * @interface GetContactFilterResponse
 */
export interface GetContactFilterResponse {
  /**
   *
   * @type {number}
   * @memberof GetContactFilterResponse
   */
  status?: number
  /**
   *
   * @type {GetContactFilterResponseData}
   * @memberof GetContactFilterResponse
   */
  data?: GetContactFilterResponseData
}

export function GetContactFilterResponseFromJSON(json: any): GetContactFilterResponse {
  return GetContactFilterResponseFromJSONTyped(json, false)
}

export function GetContactFilterResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactFilterResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetContactFilterResponseDataFromJSON(json['data'])
  }
}

export function GetContactFilterResponseToJSON(value?: GetContactFilterResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetContactFilterResponseDataToJSON(value.data)
  }
}
