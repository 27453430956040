import Script from 'next/script'
import React from 'react'

declare global {
  interface Window {
    dataLayer: Array<Object>
  }
}

export const pushDataLayer = (dataLayer: Object) => {
  // SSR 時は window オブジェクトを使用できないのでなにもしない
  if (!window) return
  // GTM が利用できない場合（環境変数が設定されていない場合など）は dataLayer プロパティが存在しないのでなにもしない
  if (!window.dataLayer) return

  window.dataLayer.push(dataLayer)
}

type eventCategory = 'user' | 'document' | 'site' | 'contract'
type eventAction =
  | 'invite'
  | 'create'
  | 'send_email'
  | 'copy_shared_link'
  | 'completed_contract'
  | 'completed_contract_cancellation'
type eventLabel = 'first_invited_user' | 'first_create_document' | 'free_plan' | 'paid_plan'

export const pushUserActionDataLayer = (category: eventCategory, action: eventAction, label?: eventLabel) => {
  const dataLayer = { event: 'userAction', eventCategory: category, eventAction: action, eventLabel: label }
  pushDataLayer(dataLayer)
}

const gtmInstallScript = (gtmId: string) => {
  return `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `
}

export const GTM_USER_ID = process.env.GTM_USER_ID
export const GoogleTagManagerForUser = () => {
  return (
    <>
      {GTM_USER_ID ? (
        <Script
          id="gtm-for-user"
          defer
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: gtmInstallScript(GTM_USER_ID) }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export const GTM_EU_ID = process.env.GTM_EU_ID
export const GoogleTagManagerForEu = () => {
  return (
    <>
      {GTM_EU_ID ? (
        <Script
          id="gtm-for-eu"
          defer
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: gtmInstallScript(GTM_EU_ID) }}
        />
      ) : (
        <></>
      )}
    </>
  )
}
