import React, { useCallback, useMemo, useState } from 'react'
import { Box, createStyles, makeStyles, Modal, Typography, Slide } from '@material-ui/core'
import { SelectBox, Colors } from 'src/components/atoms'
import { SlideForm } from 'src/components/organisms'
import { OptionListType } from 'src/components/atoms'
import { useListSelectorCompaniesList } from 'src/fixtures/modules/selector/hooks'
import { useCreateCompaniesExport } from 'src/fixtures/modules/porter/hooks'

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    }
  })
})

interface ModalCompanyExportProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ModalCompanyExport = ({ open, onClose, onConfirm }: ModalCompanyExportProps) => {
  const classes = useStyles()
  const [selectedListId, setSelectedListId] = useState<string>('')
  const { data: res } = useListSelectorCompaniesList({ per: 999 })
  const { handleCreateCompaniesExport } = useCreateCompaniesExport()

  const optionList: OptionListType[] = useMemo(() => {
    const tmpList =
      res?.selectorItems?.map(item => ({
        value: item.id!,
        label: item.text!
      })) || []

    return [
      {
        value: 'all',
        label: 'すべて'
      },
      ...tmpList
    ]
  }, [res])

  const handleClose = useCallback(() => {
    onClose()
    setSelectedListId('')
  }, [onClose])

  const handleConfirm = useCallback(async () => {
    const params = selectedListId === 'all' ? {} : { basicResourceListId: selectedListId }
    await handleCreateCompaniesExport(params, true)
    onConfirm()
  }, [handleCreateCompaniesExport, onConfirm, selectedListId])

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="会社をエクスポート"
            onClose={handleClose}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            hideSettings
            buttonTitle="エクスポート"
            buttonDisabled={selectedListId === ''}
          >
            <Box bgcolor={Colors.functional.background.default} height={1} padding="24px">
              <Box mb="18px">
                <Typography variant="body2">
                  エクスポートしたいリストを選んで、会社情報をExcel（.xlsx）にエクスポートできます
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" mb="16px">
                <Box mb="4px">
                  <Typography variant="h5">エクスポートするリスト</Typography>
                </Box>
                <SelectBox
                  height="34px"
                  listWidth="328px"
                  placeholder="選択してください"
                  optionList={optionList}
                  onChange={e => setSelectedListId(e.target.value as string)}
                  value={selectedListId}
                />
              </Box>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
