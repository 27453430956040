/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  GetContactShareSiteResponse,
  GetContactShareSiteResponseFromJSON,
  GetContactShareSiteResponseToJSON,
  GetContactShareSiteShareFormsResponse,
  GetContactShareSiteShareFormsResponseFromJSON,
  GetContactShareSiteShareFormsResponseToJSON,
  GetContactSiteResponse,
  GetContactSiteResponseFromJSON,
  GetContactSiteResponseToJSON,
  PutContactShareSiteVerifyMaterialContactableResponse,
  PutContactShareSiteVerifyMaterialContactableResponseFromJSON,
  PutContactShareSiteVerifyMaterialContactableResponseToJSON
} from '../models'

export interface ContactV1SitesSharePublicLinkUlidGetRequest {
  publicLinkUlid: string
}

export interface ContactV1SitesSharePublicLinkUlidShareFormsGetRequest {
  publicLinkUlid: string
}

export interface ContactV1SitesSharePublicLinkUlidVerifyMaterialContactablePutRequest {
  publicLinkUlid: string
}

export interface ContactV1SitesSiteIdGetRequest {
  siteId: string
}

/**
 *
 */
export class ApiContactV1SiteApi extends runtime.BaseAPI {
  /**
   * 資料サイトの共有設定でリンクを知っている人誰でも閲覧できる
   * share
   */
  async contactV1SitesSharePublicLinkUlidGetRaw(
    requestParameters: ContactV1SitesSharePublicLinkUlidGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactShareSiteResponse>> {
    if (requestParameters.publicLinkUlid === null || requestParameters.publicLinkUlid === undefined) {
      throw new runtime.RequiredError(
        'publicLinkUlid',
        'Required parameter requestParameters.publicLinkUlid was null or undefined when calling contactV1SitesSharePublicLinkUlidGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/contact/v1/sites/share/{public_link_ulid}`.replace(
          `{${'public_link_ulid'}}`,
          encodeURIComponent(String(requestParameters.publicLinkUlid))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactShareSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトの共有設定でリンクを知っている人誰でも閲覧できる
   * share
   */
  async contactV1SitesSharePublicLinkUlidGet(
    requestParameters: ContactV1SitesSharePublicLinkUlidGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactShareSiteResponse> {
    const response = await this.contactV1SitesSharePublicLinkUlidGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 共有フォームを取得する
   * share_forms
   */
  async contactV1SitesSharePublicLinkUlidShareFormsGetRaw(
    requestParameters: ContactV1SitesSharePublicLinkUlidShareFormsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactShareSiteShareFormsResponse>> {
    if (requestParameters.publicLinkUlid === null || requestParameters.publicLinkUlid === undefined) {
      throw new runtime.RequiredError(
        'publicLinkUlid',
        'Required parameter requestParameters.publicLinkUlid was null or undefined when calling contactV1SitesSharePublicLinkUlidShareFormsGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/contact/v1/sites/share/{public_link_ulid}/share_forms`.replace(
          `{${'public_link_ulid'}}`,
          encodeURIComponent(String(requestParameters.publicLinkUlid))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactShareSiteShareFormsResponseFromJSON(jsonValue))
  }

  /**
   * 共有フォームを取得する
   * share_forms
   */
  async contactV1SitesSharePublicLinkUlidShareFormsGet(
    requestParameters: ContactV1SitesSharePublicLinkUlidShareFormsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactShareSiteShareFormsResponse> {
    const response = await this.contactV1SitesSharePublicLinkUlidShareFormsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 有効なtokenを持っているeuに対して、対象のmaterial_contactableを作成する
   * verify_material_contactable
   */
  async contactV1SitesSharePublicLinkUlidVerifyMaterialContactablePutRaw(
    requestParameters: ContactV1SitesSharePublicLinkUlidVerifyMaterialContactablePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PutContactShareSiteVerifyMaterialContactableResponse>> {
    if (requestParameters.publicLinkUlid === null || requestParameters.publicLinkUlid === undefined) {
      throw new runtime.RequiredError(
        'publicLinkUlid',
        'Required parameter requestParameters.publicLinkUlid was null or undefined when calling contactV1SitesSharePublicLinkUlidVerifyMaterialContactablePut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/contact/v1/sites/share/{public_link_ulid}/verify_material_contactable`.replace(
          `{${'public_link_ulid'}}`,
          encodeURIComponent(String(requestParameters.publicLinkUlid))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PutContactShareSiteVerifyMaterialContactableResponseFromJSON(jsonValue)
    )
  }

  /**
   * 有効なtokenを持っているeuに対して、対象のmaterial_contactableを作成する
   * verify_material_contactable
   */
  async contactV1SitesSharePublicLinkUlidVerifyMaterialContactablePut(
    requestParameters: ContactV1SitesSharePublicLinkUlidVerifyMaterialContactablePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PutContactShareSiteVerifyMaterialContactableResponse> {
    const response = await this.contactV1SitesSharePublicLinkUlidVerifyMaterialContactablePutRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * 資料の閲覧
   * show
   */
  async contactV1SitesSiteIdGetRaw(
    requestParameters: ContactV1SitesSiteIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetContactSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling contactV1SitesSiteIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/contact/v1/sites/{site_id}`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetContactSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料の閲覧
   * show
   */
  async contactV1SitesSiteIdGet(
    requestParameters: ContactV1SitesSiteIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetContactSiteResponse> {
    const response = await this.contactV1SitesSiteIdGetRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
