/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnumMailTemplateVariableObjectTypeFromJSON, EnumMailTemplateVariableObjectTypeToJSON } from './EnumMailTemplateVariableObjectType';
export function MailTemplateVariableObjectFromJSON(json) {
    return MailTemplateVariableObjectFromJSONTyped(json, false);
}
export function MailTemplateVariableObjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: EnumMailTemplateVariableObjectTypeFromJSON(json['type']),
        sort: json['sort'],
        name: json['name'],
        label: json['label']
    };
}
export function MailTemplateVariableObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        type: EnumMailTemplateVariableObjectTypeToJSON(value.type),
        sort: value.sort,
        name: value.name,
        label: value.label
    };
}
