import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const ChatAttention = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="74" height="40" viewBox="0 0 74 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.334 3.33333H6.66732C4.83398 3.33333 3.35065 4.83333 3.35065 6.66667L3.33398 36.6667L10.0007 30H33.334C35.1673 30 36.6673 28.5 36.6673 26.6667V6.66667C36.6673 4.83333 35.1673 3.33333 33.334 3.33333ZM11.6673 15H28.334C29.2507 15 30.0007 15.75 30.0007 16.6667C30.0007 17.5833 29.2507 18.3333 28.334 18.3333H11.6673C10.7507 18.3333 10.0007 17.5833 10.0007 16.6667C10.0007 15.75 10.7507 15 11.6673 15ZM21.6673 23.3333H11.6673C10.7507 23.3333 10.0007 22.5833 10.0007 21.6667C10.0007 20.75 10.7507 20 11.6673 20H21.6673C22.584 20 23.334 20.75 23.334 21.6667C23.334 22.5833 22.584 23.3333 21.6673 23.3333ZM28.334 13.3333H11.6673C10.7507 13.3333 10.0007 12.5833 10.0007 11.6667C10.0007 10.75 10.7507 10 11.6673 10H28.334C29.2507 10 30.0007 10.75 30.0007 11.6667C30.0007 12.5833 29.2507 13.3333 28.334 13.3333Z"
        fill="#DE1B63"
      />
      <path
        d="M59 18.75C59.6875 18.75 60.25 19.3125 60.25 20V25C60.25 25.6875 59.6875 26.25 59 26.25C58.3125 26.25 57.75 25.6875 57.75 25V20C57.75 19.3125 58.3125 18.75 59 18.75ZM58.9875 12.5C52.0875 12.5 46.5 18.1 46.5 25C46.5 31.9 52.0875 37.5 58.9875 37.5C65.9 37.5 71.5 31.9 71.5 25C71.5 18.1 65.9 12.5 58.9875 12.5ZM59 35C53.475 35 49 30.525 49 25C49 19.475 53.475 15 59 15C64.525 15 69 19.475 69 25C69 30.525 64.525 35 59 35ZM60.25 31.25H57.75V28.75H60.25V31.25Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default ChatAttention
