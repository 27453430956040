import React from 'react'
import { Box } from '@material-ui/core'
import { Typography, Colors, DynamicMuiIcon, Button } from 'src/components/atoms'

interface ExportErrorProps {
  onClick: () => void
}

export const ExportError = ({ onClick }: ExportErrorProps) => {
  return (
    <Box ml="32px">
      <Box display="flex" alignItems="center" color={Colors.base.middleGray}>
        <Box color={Colors.accent.keyRed.default} height="20px">
          <DynamicMuiIcon variant="close" size="20px" />
        </Box>
        <Box ml="6px" />
        <Typography fontWeight={600} fontSize="s" lineheight="18.48px" letterSpacing="tight">
          エラーが発生しました。エクスポートを再度実行してください
        </Typography>
      </Box>
      <Box mt="16px">
        <Button kind="primary" variant="outlined" title={'リストの選択に戻る'} onClick={onClick} />
        <Box mt="16px" />
      </Box>
    </Box>
  )
}
