/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
import { UserFromJSON, UserToJSON } from './User';
export function GetOrganizationUsersResponseDataFromJSON(json) {
    return GetOrganizationUsersResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationUsersResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        users: !exists(json, 'users') ? undefined : json['users'].map(UserFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetOrganizationUsersResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        users: value.users === undefined ? undefined : value.users.map(UserToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
