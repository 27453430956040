/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { SiteShareFormFromJSON, SiteShareFormToJSON } from './SiteShareForm';
import { UserForDisplayFromJSON, UserForDisplayToJSON } from './UserForDisplay';
export function SiteShareFromJSON(json) {
    return SiteShareFromJSONTyped(json, false);
}
export function SiteShareFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        publicLinkUlid: !exists(json, 'public_link_ulid') ? undefined : json['public_link_ulid'],
        url: !exists(json, 'url') ? undefined : json['url'],
        isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
        expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
        isFormActive: !exists(json, 'is_form_active') ? undefined : json['is_form_active'],
        isAuthCodeActive: !exists(json, 'is_auth_code_active') ? undefined : json['is_auth_code_active'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
        siteShareForms: !exists(json, 'site_share_forms')
            ? undefined
            : json['site_share_forms'].map(SiteShareFormFromJSON)
    };
}
export function SiteShareToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        public_link_ulid: value.publicLinkUlid,
        url: value.url,
        is_active: value.isActive,
        expired_on: value.expiredOn,
        is_form_active: value.isFormActive,
        is_auth_code_active: value.isAuthCodeActive,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        user: UserForDisplayToJSON(value.user),
        site_share_forms: value.siteShareForms === undefined ? undefined : value.siteShareForms.map(SiteShareFormToJSON)
    };
}
