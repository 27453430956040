import { MouseEventHandler } from 'react'
import { Box, ButtonBase, IconButton, Typography } from '@material-ui/core'
import Slider from 'rc-slider'
import { Colors, SvgIcon } from 'src/components/atoms'

export interface DocumentToolBarProps {
  scale: number
  onChaneScale: (value: number | number[]) => void
  onResetScale: MouseEventHandler<HTMLButtonElement>
  onChangeDrawerStatus: MouseEventHandler<HTMLButtonElement>
  onFullSizePreview: MouseEventHandler<HTMLButtonElement>
}

export const DocumentToolBar = ({
  scale,
  onChaneScale,
  onResetScale,
  onChangeDrawerStatus
}: // onFullSizePreview
DocumentToolBarProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box display="flex" alignItems="center" mr={4} onClick={e => e.stopPropagation()}>
        <Slider
          min={0}
          max={20}
          defaultValue={10}
          value={scale * 10}
          onChange={onChaneScale}
          style={{ width: 184 }}
          trackStyle={{ backgroundColor: Colors.background.silver }}
          handleStyle={{
            borderColor: '#222B35',
            backgroundColor: '#222B35'
          }}
        />
        <Box color={Colors.base.middleGray} ml={2} width={40}>
          <Typography variant="body2">{(200 * (scale * 10)) / 20}%</Typography>
        </Box>
      </Box>
      <ButtonBase onClick={onResetScale}>
        <Box
          color={Colors.base.middleGray}
          py={1}
          px={2}
          border={`1px solid ${Colors.background.silver}`}
          borderRadius={4}
        >
          <Typography variant="h5">リセット</Typography>
        </Box>
      </ButtonBase>
      <Box ml={1}>
        <IconButton onClick={onChangeDrawerStatus}>
          <SvgIcon variant="viewModule" color="#6D7784" size="24px" />
        </IconButton>
      </Box>
      {/* MEMO:  フルスクリーンは一旦正式版仕様から落ちたので削除 */}
      {/* <Box>
        <IconButton onClick={onFullSizePreview}>
          <SvgIcon variant="openInFull" size="24px" />
        </IconButton>
      </Box> */}
    </Box>
  )
}
