import React, { useCallback, useState } from 'react'
import { LayoutCard } from 'src/components/commons/LayoutCard'
import { Form } from './Form'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import { Colors } from 'src/components/atoms'
import Image from 'next/image'
import theme from 'src/styles/theme'

export interface PageForgotPasswordProps {
  subdomain?: string
}
export const PageForgotPassword = ({ subdomain }: PageForgotPasswordProps) => {
  const [hasSent, setHasSent] = useState<boolean>(false)
  const handleSubmit = useCallback((_: string) => {
    setHasSent(true)
  }, [])
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <LayoutCard
      maxWidth={640}
      justify="center"
      direction="rtl"
      isSP={isDownSm}
      innerCardProps={{
        minHeight: !isDownSm && '85vh'
      }}
      innerCardComponent={
        hasSent ? (
          <Box color={Colors.base.black}>
            <Typography variant="h1">パスワード再設定のメールを送信しました</Typography>
            <Box mt="20px" />
            <Typography variant="body2">登録しているメールアドレスをご確認ください</Typography>
          </Box>
        ) : (
          <Form onSubmitSuccess={handleSubmit} subdomain={subdomain} />
        )
      }
      outerCardComponent={
        <Box width="560px">
          <Image
            src={`/images/${hasSent ? 'send' : 'reset-password'}.svg`}
            width={560}
            height={366}
            objectFit="cover"
          />
        </Box>
      }
    />
  )
}
