import { Box } from '@material-ui/core'
import { Organization, Site } from '@noco/http-client/lib/noco'
import { Colors, Typography } from 'src/components/atoms'

import Image from 'next/image'
import { PageSiteMode } from '../SiteDetail'
import { ModalUpgrade } from 'src/components/modals'
import { useCallback, useState } from 'react'
import { useGlobalStyles } from 'src/styles/theme'

export interface SiteFooterProps {
  site: Site
  mode: PageSiteMode
  organization?: Organization
}

export const SiteFooter = ({ site, mode, organization }: SiteFooterProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const globalClasses = useGlobalStyles()
  const plan = organization?.plan
  const handleClick = useCallback(() => {
    if (mode === 'setting' && !plan?.canHideAd) {
      setOpen(true)
    } else {
      window.open('https://noco.sale/')
    }
  }, [mode, plan?.canHideAd])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <ModalUpgrade open={open} onClose={handleClose} />
      <Box
        width={1}
        height="56px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderTop={`1px solid ${Colors.background.silver}`}
        bgcolor={Colors.functional.whiteText.default}
        color={Colors.base.middleGray}
      >
        {!organization?.hideAd ? (
          <Box display="flex" alignItems="center" onClick={handleClick} className={globalClasses.cursorPointer}>
            <Typography fontSize="s">Powered by</Typography>
            <Image
              src="/images/nocosell_logo.svg"
              alt="ロゴ"
              width="104px"
              height="28px"
              objectFit="contain"
              style={{ marginLeft: '6px' }}
            />
          </Box>
        ) : (
          site?.isFooterCustom && site?.footerText && <Typography fontSize="s">{site.footerText}</Typography>
        )}
      </Box>
    </>
  )
}
