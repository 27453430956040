import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import Image from 'next/image'
import { AttachmentFile } from '../type'
import { MaterialType } from '.'

export interface MaterialCardContentProps {
  type: MaterialType
  attachmentFile: AttachmentFile
  isEditing?: boolean
}

export const MaterialCardContent = ({ type, attachmentFile, isEditing = false }: MaterialCardContentProps) => {
  const subTitle = useMemo(() => {
    if (type === 'document') {
      return '資料'
    } else if (type === 'site') {
      return '資料サイト'
    }
  }, [type])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '46px',
          height: '42px',
          borderRadius: '4px',
          marginRight: '10px',
          bgcolor: Colors.background.lightGray
        }}
      >
        {type === 'document' && (
          <Image src={attachmentFile.thumbnailImageUrl!} alt={attachmentFile.title} layout="fill" objectFit="contain" />
        )}
        {type === 'site' && <DynamicMuiIcon variant="computer" htmlColor={Colors.accent.keyBlue.default} size="30px" />}
      </Box>

      <Box sx={{ flex: '1', overflow: 'hidden' }}>
        <Box sx={{ color: isEditing ? Colors.base.black : Colors.accent.keyBlue.default }}>
          <Typography fontSize="s" letterSpacing="tight" lh="tight" noWrap>
            {attachmentFile.title}
          </Typography>
        </Box>
        <Box color={Colors.base.middleGray}>
          <Typography fontSize="xs" lh="tight" color="inherit">
            {subTitle}
          </Typography>
        </Box>
      </Box>

      {!isEditing && (
        <Box lineHeight={1}>
          <Box ml="8px">
            <DynamicMuiIcon variant="openNew" htmlColor={Colors.accent.keyBlue.default} size="16px" />
          </Box>
        </Box>
      )}
    </Box>
  )
}
