/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumCtaPopupPosition = {
    Center: 'center',
    TopLeft: 'top_left',
    TopRight: 'top_right',
    BottomLeft: 'bottom_left',
    BottomRight: 'bottom_right'
};
export function EnumCtaPopupPositionFromJSON(json) {
    return EnumCtaPopupPositionFromJSONTyped(json, false);
}
export function EnumCtaPopupPositionFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumCtaPopupPositionToJSON(value) {
    return value;
}
