/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentAddon,
  OrganizationPaymentAddonFromJSON,
  OrganizationPaymentAddonFromJSONTyped,
  OrganizationPaymentAddonToJSON
} from './OrganizationPaymentAddon'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentCurrentPlanResponseData
 */
export interface UpdateOrganizationPaymentCurrentPlanResponseData {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationPaymentCurrentPlanResponseData
   */
  planId: string
  /**
   *
   * @type {OrganizationPaymentAddon}
   * @memberof UpdateOrganizationPaymentCurrentPlanResponseData
   */
  addon?: OrganizationPaymentAddon
}

export function UpdateOrganizationPaymentCurrentPlanResponseDataFromJSON(
  json: any
): UpdateOrganizationPaymentCurrentPlanResponseData {
  return UpdateOrganizationPaymentCurrentPlanResponseDataFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentCurrentPlanResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentCurrentPlanResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    planId: json['plan_id'],
    addon: !exists(json, 'addon') ? undefined : OrganizationPaymentAddonFromJSON(json['addon'])
  }
}

export function UpdateOrganizationPaymentCurrentPlanResponseDataToJSON(
  value?: UpdateOrganizationPaymentCurrentPlanResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan_id: value.planId,
    addon: OrganizationPaymentAddonToJSON(value.addon)
  }
}
