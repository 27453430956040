/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumEmailTransactionableSendStatus = {
  Wait: 'wait',
  Ready: 'ready',
  Sent: 'sent',
  Resent: 'resent',
  Fail: 'fail',
  Ignore: 'ignore'
} as const
export type EnumEmailTransactionableSendStatus =
  typeof EnumEmailTransactionableSendStatus[keyof typeof EnumEmailTransactionableSendStatus]

export function EnumEmailTransactionableSendStatusFromJSON(json: any): EnumEmailTransactionableSendStatus {
  return EnumEmailTransactionableSendStatusFromJSONTyped(json, false)
}

export function EnumEmailTransactionableSendStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumEmailTransactionableSendStatus {
  return json as EnumEmailTransactionableSendStatus
}

export function EnumEmailTransactionableSendStatusToJSON(value?: EnumEmailTransactionableSendStatus | null): any {
  return value as any
}
