/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function UpdateOrganizationChatSettingRequestOrganizationChatSettingFromJSON(json) {
    return UpdateOrganizationChatSettingRequestOrganizationChatSettingFromJSONTyped(json, false);
}
export function UpdateOrganizationChatSettingRequestOrganizationChatSettingFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        autoResponseName: !exists(json, 'auto_response_name') ? undefined : json['auto_response_name'],
        chatColor: !exists(json, 'chat_color') ? undefined : json['chat_color']
    };
}
export function UpdateOrganizationChatSettingRequestOrganizationChatSettingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        auto_response_name: value.autoResponseName,
        chat_color: value.chatColor
    };
}
