/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateSiteContactRequestSiteContact,
  UpdateSiteContactRequestSiteContactFromJSON,
  UpdateSiteContactRequestSiteContactFromJSONTyped,
  UpdateSiteContactRequestSiteContactToJSON
} from './UpdateSiteContactRequestSiteContact'

/**
 *
 * @export
 * @interface UpdateSiteContactRequest
 */
export interface UpdateSiteContactRequest {
  /**
   *
   * @type {UpdateSiteContactRequestSiteContact}
   * @memberof UpdateSiteContactRequest
   */
  siteContact?: UpdateSiteContactRequestSiteContact
}

export function UpdateSiteContactRequestFromJSON(json: any): UpdateSiteContactRequest {
  return UpdateSiteContactRequestFromJSONTyped(json, false)
}

export function UpdateSiteContactRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSiteContactRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteContact: !exists(json, 'site_contact')
      ? undefined
      : UpdateSiteContactRequestSiteContactFromJSON(json['site_contact'])
  }
}

export function UpdateSiteContactRequestToJSON(value?: UpdateSiteContactRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_contact: UpdateSiteContactRequestSiteContactToJSON(value.siteContact)
  }
}
