import { Box, createStyles, makeStyles } from '@material-ui/core'
import {
  HowDidYouKnowEnum,
  OrganizationPaymentContractBasicInfo as PaymentBillingBasicFormValue,
  OrganizationPaymentContractPaidInfo
} from '@noco/http-client/lib/noco'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Button, Card, Input, Label, OptionListType, SelectBox, Typography } from 'src/components/atoms'
import { PrefectureList } from 'src/fixtures/utils/prefecture'
import { useGetKenall } from 'src/fixtures/utils/zipCode'

export type PaymentBillingPaidFormValue = Pick<OrganizationPaymentContractPaidInfo, 'billingEmail' | 'howDidYouKnow'>

export interface PaymentBillingFormProps {
  contractBasicInfo: PaymentBillingBasicFormValue
  contractPaidInfo?: PaymentBillingPaidFormValue
  isPaid: boolean
  submitTitle: string
  disabledButton?: boolean
  onSubmit?: (contractBasicInfo: PaymentBillingBasicFormValue, contractPaidInfo?: PaymentBillingPaidFormValue) => void
  isResumed?: boolean
}

interface FormValue {
  basic: PaymentBillingBasicFormValue
  paid: PaymentBillingPaidFormValue
}

const useStyles = makeStyles(() =>
  createStyles({
    formField: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '8px'
    },
    submitButton: {
      padding: '0 24px'
    }
  })
)

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const basicValueValidation = {
  companyName: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .label('会社名')
    .required('会社名は必須です'),
  postalCode: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .label('郵便番号')
    .length(7, '郵便番号は7文字で入力してください')
    .required('郵便番号は必須です'),
  prefectureCode: yup.string().label('都道府県').required('都道府県は必須です'),
  city: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .label('市区町村')
    .required('市区町村は必須です'),
  line1: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .label('番地')
    .required('番地は必須です'),
  line2: yup.string().transform((_value, originaiValue) => originaiValue || ''),
  lastName: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .label('姓')
    .required('姓は必須です'),
  firstName: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .label('名')
    .required('名は必須です'),
  phoneNumber: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .label('ご担当者さまの電話番号')
    .required('ご担当者さまの電話番号は必須です')
    .matches(phoneRegExp, '電話番号の形式で入力してください'),
  contactEmail: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .email('メールアドレスの形式で入力してください')
    .label('ご担当者さまのメールアドレス ')
    .required('ご担当者さまのメールアドレス は必須です')
}
const paidValueValidation = {
  billingEmail: yup
    .string()
    .transform((_value, originaiValue) => originaiValue || '')
    .email('メールアドレスの形式で入力してください')
    .label('ご担当者さまのメールアドレス ')
    .required('領収書送付先のメールアドレスは必須です'),
  howDidYouKnow: yup.string().label('ノコセルを知ったきっかけ').required('ノコセルを知ったきっかけは必須です')
}

const howDidYouKnowOptionList: OptionListType[] = [
  { value: HowDidYouKnowEnum.Google, label: 'Google検索' },
  { value: HowDidYouKnowEnum.Sales, label: '営業を受けて' },
  { value: HowDidYouKnowEnum.OnlineAds, label: 'オンライン広告を見て' },
  { value: HowDidYouKnowEnum.Direct, label: 'ノコセルを直接見かけた' },
  { value: HowDidYouKnowEnum.ServiceSite, label: 'ノコセルのサービスサイト' },
  { value: HowDidYouKnowEnum.WebinarOrExhibition, label: 'ウェビナー・展示会' },
  { value: HowDidYouKnowEnum.SnsOrBlog, label: 'SNS・ブログ' },
  { value: HowDidYouKnowEnum.MediaArticles, label: 'メディアの記事' },
  { value: HowDidYouKnowEnum.ReferralsOrRecommendations, label: '知人・友人の紹介や推薦' },
  { value: HowDidYouKnowEnum.CampaignOrCoupon, label: 'キャンペーン・クーポン配布' },
  { value: HowDidYouKnowEnum.ReviewSite, label: 'レビューサイト・口コミサイト' },
  { value: HowDidYouKnowEnum.Others, label: 'その他' }
]

const prefectureOptionList: OptionListType[] = PrefectureList.map(prefecture => {
  return { value: prefecture.code, label: prefecture.name }
})

export const PaymentBillingForm = ({
  contractBasicInfo,
  contractPaidInfo,
  isPaid = false,
  submitTitle,
  disabledButton = false,
  onSubmit,
  isResumed = false
}: PaymentBillingFormProps) => {
  const classes = useStyles()
  const { handleGetRequestKenall, address1, address2, address3, prefecture, clear: clearKenall } = useGetKenall()

  const validationSchema = isPaid
    ? yup
        .object()
        .shape({ basic: yup.object().shape(basicValueValidation), paid: yup.object().shape(paidValueValidation) })
    : yup.object().shape({ basic: yup.object().shape(basicValueValidation) })
  const initialValues =
    isPaid && contractPaidInfo
      ? { basic: contractBasicInfo, paid: contractPaidInfo }
      : {
          basic: contractBasicInfo,
          paid: {
            billingEmail: '',
            howDidYouKnow: HowDidYouKnowEnum.CampaignOrCoupon
          }
        }
  const { values, handleSubmit, handleChange, handleBlur, touched, errors, isValid, setFieldValue } =
    useFormik<FormValue>({
      validateOnMount: true,
      validationSchema: validationSchema,
      initialValues: initialValues,
      onSubmit: values => {
        onSubmit?.(values.basic, isPaid ? values.paid : undefined)
      }
    })

  // MEMO: ケンオールを使った住所の自動補完実装
  useEffect(() => {
    if (address1) {
      setFieldValue('basic.city', address1)
    }
  }, [address1, setFieldValue])
  useEffect(() => {
    if (address2) {
      setFieldValue('basic.line1', address2)
    }
  }, [address2, setFieldValue])
  useEffect(() => {
    if (address3) {
      setFieldValue('basic.line2', address3)
    }
  }, [address3, setFieldValue])
  useEffect(() => {
    if (prefecture) {
      const code = PrefectureList.find(item => item.name === prefecture)?.code
      if (code) setFieldValue('basic.prefectureCode', code)
    }
  }, [prefecture, setFieldValue])

  return (
    <form onSubmit={handleSubmit}>
      <Box mb="24px">
        <Card label="ご契約者情報" sx={{ bgcolor: '#fff' }}>
          <Box width="328px">
            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  会社名
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Input
                type="text"
                value={values.basic.companyName || ''}
                name="basic.companyName"
                fullWidth
                placeholder="株式会社アイドマ・ホールディングス"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.basic?.companyName ? errors.basic?.companyName : undefined}
              />
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  郵便番号
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Input
                type="text"
                value={values.basic.postalCode || ''}
                name="basic.postalCode"
                fullWidth
                placeholder="1310045"
                required
                onChange={handleChange}
                onBlur={e => {
                  handleBlur(e)
                  // blur 時に郵便番号検索を行って自動挿入を行う
                  const postalCode = e.currentTarget.value.trim()
                  setFieldValue('basic.postalCode', postalCode)
                  if (/[0-9]{7}/.test(postalCode)) {
                    clearKenall()
                    handleGetRequestKenall(postalCode)
                  }
                }}
                error={touched.basic?.postalCode ? errors.basic?.postalCode : undefined}
              />
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  都道府県
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>
              <Box maxWidth="328px">
                <SelectBox
                  height="34px"
                  placeholder="選択してください"
                  optionList={prefectureOptionList}
                  value={values.basic.prefectureCode || ''}
                  name="basic.prefectureCode"
                  onChange={handleChange}
                />
              </Box>
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  市区町村
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Input
                type="text"
                value={values.basic.city || ''}
                name="basic.city"
                fullWidth
                placeholder="墨田区押上"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.basic?.city ? errors.basic?.city : undefined}
              />
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  番地
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Input
                type="text"
                value={values.basic.line1 || ''}
                name="basic.line1"
                fullWidth
                placeholder="1-1-2"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.basic?.line1 ? errors.basic?.line1 : undefined}
              />
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  建物名・部屋番号 など
                </Typography>
                <Box width="6px" />
                <Label type="neutral" labelText="任意" size="small" />
              </Box>

              <Input
                type="text"
                value={values.basic.line2 || ''}
                name="basic.line2"
                fullWidth
                placeholder="スカイスノービル1008"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  ご担当者さまの氏名
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box flex="1" mr="8px">
                  <Box mb="6px">
                    <Typography fontSize="s" lineheight="1" fontWeight="bold">
                      姓
                    </Typography>
                  </Box>
                  <Input
                    type="text"
                    value={values.basic.lastName || ''}
                    name="basic.lastName"
                    fullWidth
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.basic?.lastName ? errors.basic?.lastName : undefined}
                  />
                </Box>
                <Box flex="1">
                  <Box mb="6px">
                    <Typography fontSize="s" lineheight="1" fontWeight="bold">
                      名
                    </Typography>
                  </Box>
                  <Input
                    type="text"
                    value={values.basic.firstName || ''}
                    name="basic.firstName"
                    fullWidth
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.basic?.firstName ? errors.basic?.firstName : undefined}
                  />
                </Box>
              </Box>
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  ご担当者さまの電話番号
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Box mb="2px">
                <Typography fontSize="s" lineheight="1.32">
                  ご連絡可能な番号をご記入ください
                </Typography>
              </Box>

              <Input
                type="tel"
                value={values.basic.phoneNumber || ''}
                name="basic.phoneNumber"
                fullWidth
                placeholder="08012345678"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.basic?.phoneNumber ? errors.basic?.phoneNumber : undefined}
              />
            </Box>

            <Box mb="16px">
              <Box className={classes.formField}>
                <Typography fontSize="s" lineheight="1" fontWeight="bold">
                  ご担当者さまのメールアドレス
                </Typography>
                <Box width="6px" />
                <Label type="negative" labelText="必須" size="small" />
              </Box>

              <Input
                type="text"
                value={values.basic.contactEmail || ''}
                name="basic.contactEmail"
                fullWidth
                placeholder="yamada@example.co.jp"
                required
                onChange={handleChange}
                onBlur={e => {
                  handleBlur(e)
                  setFieldValue('basic.contactEmail', values.basic.contactEmail?.trim())
                }}
                error={touched.basic?.contactEmail ? errors.basic?.contactEmail : undefined}
              />
            </Box>

            {isPaid && (
              <Box mb="16px">
                <Box className={classes.formField}>
                  <Typography fontSize="s" lineheight="1" fontWeight="bold">
                    領収書送付先のメールアドレス
                  </Typography>
                  <Box width="6px" />
                  <Label type="negative" labelText="必須" size="small" />
                </Box>

                <Input
                  type="text"
                  fullWidth
                  value={values.paid.billingEmail || ''}
                  name="paid.billingEmail"
                  placeholder="yamada@example.co.jp"
                  required
                  error={touched.paid?.billingEmail ? errors.paid?.billingEmail : undefined}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e)
                    setFieldValue('paid.billingEmail', values.paid.billingEmail?.trim())
                  }}
                />
              </Box>
            )}
          </Box>
          <Typography fontSize="xs" lineheight="1.32">
            ご担当者さま宛に、サービスの使い方や機能アップデート、メンテナンス情報などのメルマガが届きます
          </Typography>
        </Card>
      </Box>

      {((isPaid && !isResumed) || (isPaid && isResumed && !values.paid.howDidYouKnow)) && (
        <Box mb="24px">
          <Card sx={{ bgcolor: '#fff' }}>
            <Box className={classes.formField}>
              <Typography fontSize="s" lineheight="1" fontWeight="bold">
                ノコセルを知ったきっかけを教えてください
              </Typography>
              <Box width="6px" />
              <Label type="negative" labelText="必須" size="small" />
            </Box>
            <Box maxWidth="328px">
              <SelectBox
                height="34px"
                placeholder="選択してください"
                optionList={howDidYouKnowOptionList}
                value={values.paid.howDidYouKnow || ''}
                name="paid.howDidYouKnow"
                onChange={handleChange}
              />
            </Box>
          </Card>
        </Box>
      )}

      <Box display="flex" justifyContent="center">
        <Button
          className={classes.submitButton}
          title={submitTitle}
          type="submit"
          disabled={disabledButton || !isValid}
          width="217px"
          size="large"
        />
      </Box>
    </form>
  )
}
