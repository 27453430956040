import React, { useState, useCallback } from 'react'
import { Box } from '@material-ui/core'
import { Typography } from 'src/components/atoms'
import { RecordPorterHistory, EnumRecordPorterHistoryProgressStatus } from '@noco/http-client/lib/noco'
import { SelectFile } from '../SelectFile'
import { CheckFile } from '../CheckFile'
import { ImportResult } from '../ImportResult'
import { ImportFile } from '../ImportFile'

export type StepperImportProps = {
  onSubmit: (value: File) => void
  importing: boolean
  setImporting: (value: boolean) => void
  result: RecordPorterHistory | undefined
  requesting: boolean
  error: Error | undefined
}

const CompleteStatus = EnumRecordPorterHistoryProgressStatus.Complete
const ProgressStatus = EnumRecordPorterHistoryProgressStatus.Progress

export const StepperImport = ({ onSubmit, importing, result, setImporting, requesting, error }: StepperImportProps) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [selectedFileName, setSelectedFileName] = useState<string>('')

  const clearFile = useCallback(() => setSelectedFile(undefined), [])
  const submitFile = useCallback(() => {
    if (selectedFile) {
      setImporting(false)
      setSelectedFileName(selectedFile?.name || '')
      onSubmit(selectedFile)
      clearFile()
    }
  }, [selectedFile, setImporting, onSubmit, clearFile])

  if (error) {
    if (selectedFile) {
      const fileName = selectedFile?.name || ''
      return (
        <>
          <CommonBlock fileName={fileName} />
          <ImportFile clearFile={clearFile} onSubmit={submitFile} />
        </>
      )
    }
    return (
      <>
        <CommonBlock />
        <SelectFile onChangeFile={setSelectedFile} />
      </>
    )
  } else if (selectedFile) {
    const fileName = selectedFile?.name || ''
    return (
      <>
        <CommonBlock fileName={fileName} />
        <ImportFile clearFile={clearFile} onSubmit={submitFile} />
      </>
    )
  } else if (!result) {
    if (selectedFileName) {
      return (
        <>
          <CommonBlock fileName={selectedFileName} />
          <CheckFile />
        </>
      )
    }
    return (
      <>
        <CommonBlock />
        <SelectFile onChangeFile={setSelectedFile} />
      </>
    )
  } else if (importing) {
    return (
      <>
        <CommonBlock />
        <SelectFile onChangeFile={setSelectedFile} />
      </>
    )
  } else if (requesting || result?.progressStatus === ProgressStatus) {
    const fileName = selectedFileName ?? result.originalFileName
    return (
      <>
        <CommonBlock fileName={fileName} />
        <CheckFile />
      </>
    )
  } else {
    const fileName = result.originalFileName || ''
    const showCommonBlock = result?.progressStatus != CompleteStatus
    return (
      <>
        {showCommonBlock && <CommonBlock fileName={fileName} />}
        <ImportResult result={result} setImporting={setImporting} />
      </>
    )
  }
}

const CommonBlock = ({ fileName }: { fileName?: string }) => {
  return (
    <Box mt="12.5px" mb="16px" ml="32px">
      <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
        準備したファイルを選択後、インポートを実行します
      </Typography>
      {fileName && (
        <Box mt="16px">
          <Typography fontWeight={600} fontSize="s" lineheight="14px">
            {fileName}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
