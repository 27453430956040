import { Box, TableCell, createStyles, makeStyles } from '@material-ui/core'
import React, { useCallback } from 'react'
import { Colors, DynamicMuiIcon, Typography, Button, Tooltip } from 'src/components/atoms'
import { dateFormat, DateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { useGlobalStyles } from 'src/styles/theme'
import {
  RecordPorterHistory,
  EnumRecordPorterHistoryOperation,
  EnumRecordPorterHistoryStep,
  EnumRecordPorterHistoryProgressStatus
} from '@noco/http-client/lib/noco'
import Image from 'next/image'
import Link from 'next/link'

export interface BulkUpdateHistoryRowProps {
  history: RecordPorterHistory
}

const useStyles = makeStyles(() =>
  createStyles({
    tableCell: {
      height: '48px',
      borderBottom: `1px solid ${Colors.background.gray}`
    }
  })
)

export const BulkUpdateHistoryRow = ({ history }: BulkUpdateHistoryRowProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const handleGetOperation = useCallback((type: EnumRecordPorterHistoryOperation | undefined) => {
    if (!type) {
      return
    } else {
      switch (type) {
        case EnumRecordPorterHistoryOperation.ExportContacts:
          return '連絡先のエクスポート'
        case EnumRecordPorterHistoryOperation.ExportCompanies:
          return '会社のエクスポート'
        case EnumRecordPorterHistoryOperation.BulkUpdateContacts:
          return '連絡先の一括編集'
        case EnumRecordPorterHistoryOperation.BulkUpdateCompanies:
          return '会社の一括編集'
        default:
          return '連絡先・会社のインポート'
      }
    }
  }, [])

  const handleGetStep = useCallback((step: EnumRecordPorterHistoryStep | undefined) => {
    if (!step) {
      return
    } else {
      switch (step) {
        case EnumRecordPorterHistoryStep.FileCheck:
          return 'ファイルチェック'
        case EnumRecordPorterHistoryStep.Export:
          return 'エクスポート'
        default:
          return '実行'
      }
    }
  }, [])

  const handleGetStatus = useCallback(
    (status: EnumRecordPorterHistoryProgressStatus | undefined, step: EnumRecordPorterHistoryStep | undefined) => {
      if (!status) {
        return
      } else {
        switch (status) {
          case EnumRecordPorterHistoryProgressStatus.Progress:
            return (
              <Box display="flex">
                <Box display="flex" alignItems="center" color={Colors.base.middleGray} mr="6px">
                  <DynamicMuiIcon variant="sync" size="20px" />
                </Box>
                <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                  処理中
                </Typography>
              </Box>
            )
          case EnumRecordPorterHistoryProgressStatus.Error:
            return (
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" color={Colors.accent.keyRed.default} mr="6px">
                  <DynamicMuiIcon variant="close" size="20px" />
                </Box>
                <Box mr="25px">
                  <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                    失敗
                  </Typography>
                </Box>
                <Link href={history.file.url}>
                  <a style={{ textDecoration: 'none' }} download>
                    <Button
                      kind="primary"
                      variant="outlined"
                      title="エラーファイル"
                      size="small"
                      startIcon={<DynamicMuiIcon variant="fileDownload" size="16px" />}
                    />
                  </a>
                </Link>
              </Box>
            )
          default:
            return (
              <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center" color={Colors.option.green} mr="6px">
                  <DynamicMuiIcon variant="checkCircle" size="20px" />
                </Box>
                <Box mr="25px">
                  <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                    成功
                  </Typography>
                </Box>
                {step != EnumRecordPorterHistoryStep.FileCheck && (
                  <Link href={history.file.url}>
                    <a style={{ textDecoration: 'none' }} download>
                      <Button
                        kind="primary"
                        variant="outlined"
                        title={step === 'export' ? 'ダウンロード' : '結果ファイル'}
                        size="small"
                        startIcon={<DynamicMuiIcon variant="fileDownload" size="16px" />}
                      />
                    </a>
                  </Link>
                )}
              </Box>
            )
        }
      }
    },
    [history.file]
  )

  return (
    <>
      <TableCell classes={{ root: classes.tableCell }}>
        <Box color={Colors.base.black} className={globalClasses.lineclamp1}>
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
            {history.createdAt
              ? dateFormat(dateStringToMilliseconds(history.createdAt), DateFormat.DATE_WITH_TIME)
              : '-'}
          </Typography>
        </Box>
      </TableCell>

      <TableCell classes={{ root: classes.tableCell }}>
        <Box color={Colors.base.black} className={globalClasses.lineclamp1}>
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
            {handleGetOperation(history.operation)}
          </Typography>
        </Box>
      </TableCell>

      <TableCell classes={{ root: classes.tableCell }}>
        <Box color={Colors.base.black}>
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
            {handleGetStep(history.step)}
          </Typography>
        </Box>
      </TableCell>

      <TableCell classes={{ root: classes.tableCell }}>
        <Box color={Colors.base.black} className={globalClasses.lineclamp1}>
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
            {handleGetStatus(history.progressStatus, history.step)}
          </Typography>
        </Box>
      </TableCell>

      <TableCell classes={{ root: classes.tableCell }}>
        <Tooltip
          content={history.step === 'export' ? `${history.outputFileName}` : `${history.originalFileName}`}
          placement="top"
        >
          <Box display="flex" alignItems="center" color={Colors.base.black} className={globalClasses.lineclamp1}>
            <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
              {history.step === 'export' ? history.outputFileName : history.originalFileName}
            </Typography>
          </Box>
        </Tooltip>
      </TableCell>

      <TableCell classes={{ root: classes.tableCell }}>
        <Box color={Colors.base.black} className={globalClasses.lineclamp1}>
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
            {history.totalCount?.toLocaleString() ?? '-'}件
          </Typography>
        </Box>
      </TableCell>

      <TableCell classes={{ root: classes.tableCell }}>
        <Box color={Colors.accent.keyRed.default} className={globalClasses.lineclamp1}>
          <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
            {history.errorCount?.toLocaleString() ?? '-'}件
          </Typography>
        </Box>
      </TableCell>

      <TableCell classes={{ root: classes.tableCell }}>
        <Tooltip content={`${history.user?.lastName} ${history.user?.firstName}`} placement="top">
          <Box minWidth="100px" display="flex" alignItems="center">
            <Box
              width="24px"
              height="24px"
              flexShrink={0}
              mr="8px"
              borderRadius="24px"
              overflow="hidden"
              position="relative"
            >
              <Image src={history.user?.avatar.url ?? ''} alt="avatar" objectFit="cover" layout="fill" />
            </Box>
            <Box className={globalClasses.lineclamp1} color={Colors.base.black}>
              <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                {history.user?.lastName} {history.user?.firstName}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </TableCell>
    </>
  )
}
