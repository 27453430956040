import { Box, Tab, createStyles, makeStyles, AppBar } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { useRouter } from 'next/router'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Colors, DynamicMuiIcon, SvgIcon, Typography } from 'src/components/atoms'
import { BulkUpdateCompany, BulkUpdateContact, BulkUpdateHistory, BulkUpdateImport } from 'src/components/organisms'
import { BulkUpdateHeader } from './BulkUpdateHeader'
import { TabType, tabValueList, TabValueType } from './type'
import clsx from 'clsx'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import { EnumUserPermission, OrganizationPlanBasicGradeEnum } from '@noco/http-client/lib/noco'
import { ModalConfirmToPaidPlan } from 'src/components/modals'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.background.gray,
      boxShadow: 'none',
      zIndex: 1,
      margin: 0
    },
    tabs: {
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        '& :first-child': {
          marginBottom: '0px',
          marginRight: '8px'
        }
      }
    },
    tab: {
      opacity: 1,
      minWidth: 'auto',
      maxWidth: 'none',
      minHeight: 'auto',
      padding: '15px 18px',
      color: Colors.base.middleGray,
      borderRadius: '6px 6px 0px 0px',
      '&.Mui-selected': {
        color: Colors.accent.keyPurple.default,
        background: Colors.functional.background.default,
        border: '1px solid transparent'
      },
      '&.Mui-disabled': {
        opacity: 1,
        color: Colors.label.gray
      }
    },
    tabsIndicator: {
      display: 'none'
    },
    tabPanel: {
      background: Colors.functional.background.default,
      padding: '40px',
      borderRadius: '6px',
      height: '100%',
      overflow: 'auto'
    },
    tabFirstPanel: {
      borderRadius: '0px 6px 6px 6px'
    },
    disabledTab: {
      color: Colors.label.gray
    }
  })
})

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

export const PageBulkUpdate = () => {
  const classes = useStyles()
  const router = useRouter()
  const { mode } = router.query as { mode: TabValueType }
  const { data: me } = useGetMe()
  const { data: organizationRes } = useGetOrganization()

  const permission = me?.user?.permission
  const planGrade = organizationRes?.organization?.plan?.grade

  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)

  //- NOTE: @locco 想定外の mode が入った時の対応
  const tabValue: TabValueType = useMemo(() => (tabValueList.includes(mode) ? mode : tabValueList[0]), [mode])

  const isDisabled: (value: TabValueType) => boolean = useCallback(
    value => {
      switch (value) {
        case 'import':
          return false
        case 'company':
        case 'contact':
          return !(
            (planGrade === OrganizationPlanBasicGradeEnum.Premium ||
              planGrade === OrganizationPlanBasicGradeEnum.Trial) &&
            (permission === EnumUserPermission.Administrator || permission === EnumUserPermission.Manager)
          )
        case 'history':
          return !(
            planGrade !== OrganizationPlanBasicGradeEnum.Free &&
            (permission === EnumUserPermission.Administrator || permission === EnumUserPermission.Manager)
          )
        default:
          return true
      }
    },
    [permission, planGrade]
  )

  const tabList: TabType[] = useMemo(
    () => [
      {
        value: 'import',
        label: 'インポート',
        icon: <DynamicMuiIcon variant="addToPhotosRoundedIcon" size="20px" />,
        disabled: isDisabled('import')
      },
      {
        value: 'company',
        label: '会社の一括編集',
        icon: <SvgIcon variant="editNote" size="20px" color="inherit" />,
        disabled: isDisabled('company')
      },
      {
        value: 'contact',
        label: '連絡先の一括編集',
        icon: <SvgIcon variant="editNote" size="20px" color="inherit" />,
        disabled: isDisabled('contact')
      },
      {
        value: 'history',
        label: 'インポート・エクスポート履歴',
        icon: <DynamicMuiIcon variant="history" size="20px" />,
        disabled: isDisabled('history')
      }
    ],
    [isDisabled]
  )

  const handleChange = useCallback(
    (_: ChangeEvent<{}>, value: TabValueType) => {
      // TODO: @locco-jp @enta Plan情報に一括編集が可能かどうかのフラグがついたら差し替える
      if (
        (value === 'company' || value === 'contact') &&
        (planGrade === OrganizationPlanBasicGradeEnum.Entry || planGrade === OrganizationPlanBasicGradeEnum.StandardNew)
      )
        setOpenModalUpgrade(true)

      if (isDisabled(value)) return
      router.replace(`?mode=${value}`)
    },
    [router, isDisabled, planGrade]
  )

  return (
    <>
      <Box height="100%" bgcolor={Colors.background.gray}>
        <BulkUpdateHeader />
        <Box px="90px" py="24px" display="flex" flexDirection="column" overflow="hidden" height="calc(100% - 60px)">
          <TabContext value={tabValue}>
            <AppBar position="relative" className={classes.root}>
              <TabList
                onChange={handleChange}
                aria-label="bulk-update-tablist"
                classes={{
                  indicator: classes.tabsIndicator
                }}
                className={classes.tabs}
              >
                {tabList.map((tab, i) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    label={
                      <Typography fontWeight="bold" fontSize="s" lineheight="14px">
                        {tab.label}
                      </Typography>
                    }
                    icon={tab.icon}
                    classes={{ root: classes.tab }}
                    className={tab.disabled ? classes.disabledTab : undefined}
                    {...a11yProps(i)}
                  />
                ))}
              </TabList>
            </AppBar>
            <Box height="100%" maxHeight="calc(100% - 48px)">
              <TabPanel value="import" classes={{ root: clsx(classes.tabPanel, classes.tabFirstPanel) }}>
                <BulkUpdateImport />
              </TabPanel>
              <TabPanel value="company" classes={{ root: classes.tabPanel }}>
                <BulkUpdateCompany />
              </TabPanel>
              <TabPanel value="contact" classes={{ root: classes.tabPanel }}>
                <BulkUpdateContact />
              </TabPanel>
              <TabPanel value="history" classes={{ root: classes.tabPanel }}>
                <BulkUpdateHistory />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>

      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade}
        open={openModalUpgrade}
        lightPlanText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="ライト・スタンダードプランでは、会社・連絡先のエクスポートや一括編集はできません。プレミアムプランなら、会社・連絡先のエクスポートや一括編集機能に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
}
