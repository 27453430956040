/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { AuditColumn, AuditColumnFromJSON, AuditColumnFromJSONTyped, AuditColumnToJSON } from './AuditColumn'
import {
  EnumAuditExtendAction,
  EnumAuditExtendActionFromJSON,
  EnumAuditExtendActionFromJSONTyped,
  EnumAuditExtendActionToJSON
} from './EnumAuditExtendAction'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface Audit
 */
export interface Audit {
  /**
   *
   * @type {string}
   * @memberof Audit
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Audit
   */
  createdAt?: string
  /**
   *
   * @type {UserForDisplay}
   * @memberof Audit
   */
  user?: UserForDisplay
  /**
   *
   * @type {string}
   * @memberof Audit
   */
  action?: string
  /**
   *
   * @type {EnumAuditExtendAction}
   * @memberof Audit
   */
  extendAction?: EnumAuditExtendAction
  /**
   *
   * @type {Array<AuditColumn>}
   * @memberof Audit
   */
  columns?: Array<AuditColumn>
}

export function AuditFromJSON(json: any): Audit {
  return AuditFromJSONTyped(json, false)
}

export function AuditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Audit {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    action: !exists(json, 'action') ? undefined : json['action'],
    extendAction: !exists(json, 'extend_action') ? undefined : EnumAuditExtendActionFromJSON(json['extend_action']),
    columns: !exists(json, 'columns') ? undefined : (json['columns'] as Array<any>).map(AuditColumnFromJSON)
  }
}

export function AuditToJSON(value?: Audit | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    created_at: value.createdAt,
    user: UserForDisplayToJSON(value.user),
    action: value.action,
    extend_action: EnumAuditExtendActionToJSON(value.extendAction),
    columns: value.columns === undefined ? undefined : (value.columns as Array<any>).map(AuditColumnToJSON)
  }
}
