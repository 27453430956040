import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const AddBox = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333ZM7.16667 12.1667H8.83333V8.83333H12.1667V7.16667H8.83333V3.83333H7.16667V7.16667H3.83333V8.83333H7.16667V12.1667Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
export default AddBox
