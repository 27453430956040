/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Organization, OrganizationFromJSON, OrganizationFromJSONTyped, OrganizationToJSON } from './Organization'

/**
 *
 * @export
 * @interface GetOrganizationResponseData
 */
export interface GetOrganizationResponseData {
  /**
   *
   * @type {Organization}
   * @memberof GetOrganizationResponseData
   */
  organization?: Organization
}

export function GetOrganizationResponseDataFromJSON(json: any): GetOrganizationResponseData {
  return GetOrganizationResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organization: !exists(json, 'organization') ? undefined : OrganizationFromJSON(json['organization'])
  }
}

export function GetOrganizationResponseDataToJSON(value?: GetOrganizationResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization: OrganizationToJSON(value.organization)
  }
}
