import { Box } from '@material-ui/core'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { ButtonIcon, Colors, SvgIcon, Typography } from 'src/components/atoms'

export interface LayoutSinglePageProps {
  title: string
  children: ReactNode
  back?: boolean
  isOverflowScrollable?: boolean
  onBack?: () => void
}

export const LayoutSinglePage = ({
  title,
  back = false,
  onBack,
  isOverflowScrollable = true,
  children
}: LayoutSinglePageProps) => {
  const router = useRouter()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        bgcolor: Colors.background.lightGray
      }}
    >
      <Box
        component={'header'}
        flexShrink="0"
        height="60px"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={Colors.functional.background.default}
        borderBottom={`1px solid ${Colors.background.silver}`}
      >
        {back && (
          <Box position="absolute" top={0} bottom={0} left={0} pl="26px" display="flex" alignItems="center">
            <ButtonIcon
              iconBefore={<SvgIcon variant="leftArrow" size="14px" color={Colors.base.middleGray} />}
              onClick={onBack ? onBack : () => router.back()}
            >
              戻る
            </ButtonIcon>
          </Box>
        )}
        <Typography fontSize="l" letterSpacing="default" fontWeight="bold">
          {title}
        </Typography>
      </Box>

      <Box height="100%" flexGrow="1" overflow={isOverflowScrollable ? 'auto' : 'hidden'}>
        {children}
      </Box>
    </Box>
  )
}
