import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Typography } from 'src/components/atoms'
import { IconVariant, BoardIcon } from '../BoardIcon'

export interface BoardItemProps {
  text: string
  icon: IconVariant
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    icon: {
      lineHeight: 0,
      marginBottom: '10px'
    },
    text: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    }
  })
)

export const BoardItem = ({ text, icon }: BoardItemProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>
        <BoardIcon variant={icon} />
      </Box>
      <Box className={classes.text}>
        <Typography fontSize="s" letterSpacing="tight" lineheight="132%" fontWeight="normal">
          {text}
        </Typography>
      </Box>
    </Box>
  )
}
