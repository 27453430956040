import { Box, IconButton } from '@material-ui/core'
import { useRouter } from 'next/router'
import { ReactElement, useMemo } from 'react'
import {
  Colors,
  DynamicMuiIcon,
  IconVariant,
  MuiIconVariant,
  SvgIcon,
  Typography,
  TypographyProps
} from 'src/components/atoms'

export interface HeaderProps {
  icon?: MuiIconVariant
  iconColor?: string
  svgIcon?: IconVariant
  title: string
  iconSize?: string | number
  leftSection?: ReactElement
  rightSection?: ReactElement
  back?: boolean
  textSize?: 'small' | 'medium' | 'large'
  companyName?: string
  department?: string
  post?: string
}

export const Header = ({
  icon,
  iconSize,
  title,
  leftSection,
  rightSection,
  back,
  svgIcon,
  textSize = 'medium',
  companyName,
  department,
  post
}: HeaderProps) => {
  const router = useRouter()

  const [fontSize, letterSpacing, lineheight, fontWeight, color]: [
    TypographyProps['fontSize'],
    TypographyProps['letterSpacing'],
    TypographyProps['lineheight'],
    TypographyProps['fontWeight'],
    string
  ] = useMemo(() => {
    switch (textSize) {
      case 'large':
        return ['xl', 'default', '31.5px', 'bold', Colors.base.black]
      case 'small':
        return ['s', 'tight', '18.48px', 'normal', Colors.base.middleGray]
      default:
        return ['l', 'default', '27px', 'bold', Colors.base.black]
    }
  }, [textSize])

  const companyInfo = useMemo(() => {
    return [companyName, department, post]
  }, [companyName, department, post])

  return (
    <Box
      height="60px"
      display="flex"
      alignItems="center"
      flexShrink="0"
      justifyContent="space-between"
      pr={4}
      pl={back ? 2 : 4}
    >
      <Box display="flex" alignItems="center">
        {back && (
          <IconButton style={{ marginRight: '16px' }} onClick={() => router.back()}>
            <SvgIcon variant="leftArrow" size="13px" color={Colors.base.middleGray} />
          </IconButton>
        )}
        {(icon || svgIcon) && (
          <Box display="flex" alignItems="center" mr={textSize === 'small' ? '9px' : '12px'} color={color}>
            {icon && <DynamicMuiIcon variant={icon} size={iconSize} />}
            {svgIcon && <SvgIcon variant={svgIcon} size={iconSize} color={Colors.base.black} />}
          </Box>
        )}
        <Box display="flex" flexShrink={0} color={color} flexDirection={companyInfo.length > 0 ? 'column' : 'row'}>
          <Box display="flex" alignItems="center" mb="2px">
            {companyInfo.length > 0 &&
              companyInfo.map((info, i) => (
                <Box key={i} mr="8px">
                  <Typography fontSize="xs" lineheight="12px">
                    {info}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Typography fontSize={fontSize} letterSpacing={letterSpacing} lineheight={lineheight} fontWeight={fontWeight}>
            {title}
          </Typography>
        </Box>
        {leftSection}
      </Box>
      <Box display="flex" alignItems="center">
        {rightSection}
      </Box>
    </Box>
  )
}
