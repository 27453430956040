import { Box, createStyles, makeStyles } from '@material-ui/core'
import { OrganizationPlanDetail } from '@noco/http-client/lib/noco'
import React, { useCallback, useMemo, useState } from 'react'
import { Button } from 'src/components/atoms'
import { ManageUserQuantityCard, SelectPlanCard } from 'src/components/molecules'
import { useGetOrganizationAddonUserOption } from 'src/fixtures/modules/organizationPayment/hooks'

export interface ContractFirstStepProps {
  plan: OrganizationPlanDetail
  onSubmit: (userOptionQuantity: number) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      padding: '0 16px'
    }
  })
)

export const ContractFirstStep = ({ plan, onSubmit }: ContractFirstStepProps) => {
  const classes = useStyles()
  const { data } = useGetOrganizationAddonUserOption()
  const addonUserOption = data?.addonUserOption
  const [newUserOptionQuantity, setNewUserOptionQuantity] = useState<number>(0)
  const [isValid, setIsValid] = useState<boolean>(false)

  const handleChangeUserQuantity = useCallback((userOptionQuantity, isValid) => {
    setIsValid(isValid)
    setNewUserOptionQuantity(userOptionQuantity)
  }, [])

  const handleClick = () => {
    if (!isValid) return

    onSubmit(newUserOptionQuantity)
  }

  const isVisibleManageUserQuantityCard = useMemo<boolean>(() => {
    return !!(addonUserOption && plan.optionUserPlan)
  }, [addonUserOption, plan.optionUserPlan])

  return (
    <Box>
      <Box mb="16px">
        <SelectPlanCard planName={plan.name} unitAmount={plan.unitAmount} />
      </Box>

      {isVisibleManageUserQuantityCard && (
        <Box mb="16px">
          <ManageUserQuantityCard
            plan={plan}
            bundledUserQuantity={addonUserOption?.bundledUserQuantity || 0}
            usageUserCount={addonUserOption?.usageUserCount || 0}
            userOptionQuantity={addonUserOption?.currentAddonQuantity || 0}
            unitAmount={plan.optionUserPlan?.unitAmount || 0}
            onChange={handleChangeUserQuantity}
          />
        </Box>
      )}

      <Box display="flex" justifyContent="center">
        <Button
          tabIndex={-1}
          className={classes.submitButton}
          title="お支払い情報を入力する"
          onClick={handleClick}
          width="217px"
          size="large"
          disabled={!isValid}
        />
      </Box>
    </Box>
  )
}
