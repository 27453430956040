import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { Colors, Typography, SvgIcon } from 'src/components/atoms'
import { ReceivedDatetimeCell } from 'src/components/organisms/Table/TableParts/ReceivedDatetimeCell'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() => {
  return createStyles({
    companyName: {
      wordBreak: 'break-all',
      fontSize: '14px',
      lineHeight: '15.84px',
      letterSpacing: '0.04em'
    }
  })
})
export const CompanyInformation = ({
  companyName,
  department,
  position,
  dateTime,
  type
}: {
  companyName?: string | null
  department?: string
  position?: string
  dateTime?: string | null
  type?: string
}) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      height="20px"
      mb="4px"
      justifyContent={type === 'inbox' ? 'flex-end' : 'unset'}
    >
      {companyName && (
        <>
          <Box mr="6px" color={Colors.background.gray}>
            <SvgIcon variant="company" size="20px" color={Colors.base.middleGray} />
          </Box>
          <Box mr="6px" flex={type === 'inbox' ? 1 : 'unset'}>
            <Typography className={`${classes.companyName} ${globalClasses.lineclamp1}`}>{companyName}</Typography>
          </Box>
        </>
      )}
      {department && (
        <Box mr="6px" flexShrink={0}>
          <Typography fontSize="s" lineheight="15.84px" letterSpacing="tight">
            {companyName ? `| ${department}` : `${department}`}
          </Typography>
        </Box>
      )}
      {position && (
        <Box mr="6px" flexShrink={0}>
          <Typography fontSize="s" lineheight="15.84px" letterSpacing="tight">
            {companyName || department ? `| ${position}` : `${position}`}
          </Typography>
        </Box>
      )}
      {dateTime && (
        <Box flexShrink={0}>
          <ReceivedDatetimeCell value={dateTime} />
        </Box>
      )}
    </Box>
  )
}
