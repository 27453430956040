/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumMailTemplateVariableObjectType = {
  Regular: 'regular'
} as const
export type EnumMailTemplateVariableObjectType =
  typeof EnumMailTemplateVariableObjectType[keyof typeof EnumMailTemplateVariableObjectType]

export function EnumMailTemplateVariableObjectTypeFromJSON(json: any): EnumMailTemplateVariableObjectType {
  return EnumMailTemplateVariableObjectTypeFromJSONTyped(json, false)
}

export function EnumMailTemplateVariableObjectTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumMailTemplateVariableObjectType {
  return json as EnumMailTemplateVariableObjectType
}

export function EnumMailTemplateVariableObjectTypeToJSON(value?: EnumMailTemplateVariableObjectType | null): any {
  return value as any
}
