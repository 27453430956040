import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var RecordPorterHistoryApi = /** @class */ (function () {
    function RecordPorterHistoryApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    RecordPorterHistoryApi.initialize = function (basePath, fetchApi) {
        if (!RecordPorterHistoryApi.instance) {
            RecordPorterHistoryApi.instance = new RecordPorterHistoryApi(basePath, fetchApi);
        }
        return RecordPorterHistoryApi.instance;
    };
    RecordPorterHistoryApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1RecordPorterHistoryApi(new Configuration(config));
    };
    return RecordPorterHistoryApi;
}());
export { RecordPorterHistoryApi };
