/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  CreateChatMessageRequest,
  CreateChatMessageRequestFromJSON,
  CreateChatMessageRequestToJSON,
  CreateChatMessageRequest1,
  CreateChatMessageRequest1FromJSON,
  CreateChatMessageRequest1ToJSON,
  CreateChatMessageResponse,
  CreateChatMessageResponseFromJSON,
  CreateChatMessageResponseToJSON,
  GetChatMessagesResponse,
  GetChatMessagesResponseFromJSON,
  GetChatMessagesResponseToJSON,
  ModelError,
  ModelErrorFromJSON,
  ModelErrorToJSON,
  ReviewRequestChatMessageResponse,
  ReviewRequestChatMessageResponseFromJSON,
  ReviewRequestChatMessageResponseToJSON,
  UpdateChatMessageRequest1,
  UpdateChatMessageRequest1FromJSON,
  UpdateChatMessageRequest1ToJSON,
  UpdateChatMessageResponse,
  UpdateChatMessageResponseFromJSON,
  UpdateChatMessageResponseToJSON
} from '../models'

export interface ContactV1ChatMessagesChatMessageIdDeleteRequest {
  chatMessageId: string
}

export interface ContactV1ChatMessagesChatMessageIdPutRequest {
  chatMessageId: string
  updateChatMessageRequest1?: UpdateChatMessageRequest1
}

export interface ContactV1ChatMessagesGetRequest {
  fromAt?: string
  page?: number
  per?: number
}

export interface ContactV1ChatMessagesPostRequest {
  createChatMessageRequest?: CreateChatMessageRequest
}

export interface ContactV1ChatMessagesReviewRequestPostRequest {
  createChatMessageRequest1?: CreateChatMessageRequest1
}

/**
 *
 */
export class ApiContactV1ChatMessageApi extends runtime.BaseAPI {
  /**
   */
  async contactV1ChatMessagesChatMessageIdDeleteRaw(
    requestParameters: ContactV1ChatMessagesChatMessageIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
      throw new runtime.RequiredError(
        'chatMessageId',
        'Required parameter requestParameters.chatMessageId was null or undefined when calling contactV1ChatMessagesChatMessageIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/contact/v1/chat_messages/{chat_message_id}`.replace(
          `{${'chat_message_id'}}`,
          encodeURIComponent(String(requestParameters.chatMessageId))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   */
  async contactV1ChatMessagesChatMessageIdDelete(
    requestParameters: ContactV1ChatMessagesChatMessageIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.contactV1ChatMessagesChatMessageIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャットメッセージの編集をする
   * update
   */
  async contactV1ChatMessagesChatMessageIdPutRaw(
    requestParameters: ContactV1ChatMessagesChatMessageIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateChatMessageResponse>> {
    if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
      throw new runtime.RequiredError(
        'chatMessageId',
        'Required parameter requestParameters.chatMessageId was null or undefined when calling contactV1ChatMessagesChatMessageIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/contact/v1/chat_messages/{chat_message_id}`.replace(
          `{${'chat_message_id'}}`,
          encodeURIComponent(String(requestParameters.chatMessageId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateChatMessageRequest1ToJSON(requestParameters.updateChatMessageRequest1)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateChatMessageResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージの編集をする
   * update
   */
  async contactV1ChatMessagesChatMessageIdPut(
    requestParameters: ContactV1ChatMessagesChatMessageIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateChatMessageResponse> {
    const response = await this.contactV1ChatMessagesChatMessageIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャットメッセージを取得する
   * index
   */
  async contactV1ChatMessagesGetRaw(
    requestParameters: ContactV1ChatMessagesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetChatMessagesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.fromAt !== undefined) {
      queryParameters['from_at'] = requestParameters.fromAt
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/contact/v1/chat_messages`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetChatMessagesResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージを取得する
   * index
   */
  async contactV1ChatMessagesGet(
    requestParameters: ContactV1ChatMessagesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetChatMessagesResponse> {
    const response = await this.contactV1ChatMessagesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャットメッセージを作成する
   * create
   */
  async contactV1ChatMessagesPostRaw(
    requestParameters: ContactV1ChatMessagesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateChatMessageResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/contact/v1/chat_messages`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateChatMessageRequestToJSON(requestParameters.createChatMessageRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateChatMessageResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージを作成する
   * create
   */
  async contactV1ChatMessagesPost(
    requestParameters: ContactV1ChatMessagesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateChatMessageResponse> {
    const response = await this.contactV1ChatMessagesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * チャットメッセージから資料の延長リクエスト
   * review_request
   */
  async contactV1ChatMessagesReviewRequestPostRaw(
    requestParameters: ContactV1ChatMessagesReviewRequestPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ReviewRequestChatMessageResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/contact/v1/chat_messages/review_request`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateChatMessageRequest1ToJSON(requestParameters.createChatMessageRequest1)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ReviewRequestChatMessageResponseFromJSON(jsonValue))
  }

  /**
   * チャットメッセージから資料の延長リクエスト
   * review_request
   */
  async contactV1ChatMessagesReviewRequestPost(
    requestParameters: ContactV1ChatMessagesReviewRequestPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ReviewRequestChatMessageResponse> {
    const response = await this.contactV1ChatMessagesReviewRequestPostRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
