/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { EuDocument, EuDocumentFromJSON, EuDocumentFromJSONTyped, EuDocumentToJSON } from './EuDocument'
import {
  EuOrganization,
  EuOrganizationFromJSON,
  EuOrganizationFromJSONTyped,
  EuOrganizationToJSON
} from './EuOrganization'
import {
  SiteForDisplay,
  SiteForDisplayFromJSON,
  SiteForDisplayFromJSONTyped,
  SiteForDisplayToJSON
} from './SiteForDisplay'

/**
 *
 * @export
 * @interface GetContactDocumentResponseData
 */
export interface GetContactDocumentResponseData {
  /**
   *
   * @type {EuDocument}
   * @memberof GetContactDocumentResponseData
   */
  document?: EuDocument
  /**
   *
   * @type {SiteForDisplay}
   * @memberof GetContactDocumentResponseData
   */
  site?: SiteForDisplay
  /**
   *
   * @type {EuOrganization}
   * @memberof GetContactDocumentResponseData
   */
  organization?: EuOrganization
  /**
   *
   * @type {string}
   * @memberof GetContactDocumentResponseData
   */
  expiredOn?: string | null
  /**
   *
   * @type {boolean}
   * @memberof GetContactDocumentResponseData
   */
  canViewMatreialableContent?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetContactDocumentResponseData
   */
  canRequestToExtendExpiredAt?: boolean
  /**
   *
   * @type {boolean}
   * @memberof GetContactDocumentResponseData
   */
  hasMaterialContactable?: boolean
}

export function GetContactDocumentResponseDataFromJSON(json: any): GetContactDocumentResponseData {
  return GetContactDocumentResponseDataFromJSONTyped(json, false)
}

export function GetContactDocumentResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactDocumentResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    document: !exists(json, 'document') ? undefined : EuDocumentFromJSON(json['document']),
    site: !exists(json, 'site') ? undefined : SiteForDisplayFromJSON(json['site']),
    organization: !exists(json, 'organization') ? undefined : EuOrganizationFromJSON(json['organization']),
    expiredOn: !exists(json, 'expired_on') ? undefined : json['expired_on'],
    canViewMatreialableContent: !exists(json, 'can_view_matreialable_content')
      ? undefined
      : json['can_view_matreialable_content'],
    canRequestToExtendExpiredAt: !exists(json, 'can_request_to_extend_expired_at')
      ? undefined
      : json['can_request_to_extend_expired_at'],
    hasMaterialContactable: !exists(json, 'has_material_contactable') ? undefined : json['has_material_contactable']
  }
}

export function GetContactDocumentResponseDataToJSON(value?: GetContactDocumentResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document: EuDocumentToJSON(value.document),
    site: SiteForDisplayToJSON(value.site),
    organization: EuOrganizationToJSON(value.organization),
    expired_on: value.expiredOn,
    can_view_matreialable_content: value.canViewMatreialableContent,
    can_request_to_extend_expired_at: value.canRequestToExtendExpiredAt,
    has_material_contactable: value.hasMaterialContactable
  }
}
