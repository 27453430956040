/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'
import { SelectorItem, SelectorItemFromJSON, SelectorItemFromJSONTyped, SelectorItemToJSON } from './SelectorItem'

/**
 *
 * @export
 * @interface SelectorItemObject
 */
export interface SelectorItemObject {
  /**
   *
   * @type {string}
   * @memberof SelectorItemObject
   */
  itemType?: string | null
  /**
   *
   * @type {Array<SelectorItem>}
   * @memberof SelectorItemObject
   */
  selectorItems?: Array<SelectorItem>
  /**
   *
   * @type {PageInfo}
   * @memberof SelectorItemObject
   */
  pageInfo?: PageInfo
}

export function SelectorItemObjectFromJSON(json: any): SelectorItemObject {
  return SelectorItemObjectFromJSONTyped(json, false)
}

export function SelectorItemObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectorItemObject {
  if (json === undefined || json === null) {
    return json
  }
  return {
    itemType: !exists(json, 'item_type') ? undefined : json['item_type'],
    selectorItems: !exists(json, 'selector_items')
      ? undefined
      : (json['selector_items'] as Array<any>).map(SelectorItemFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function SelectorItemObjectToJSON(value?: SelectorItemObject | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    item_type: value.itemType,
    selector_items:
      value.selectorItems === undefined ? undefined : (value.selectorItems as Array<any>).map(SelectorItemToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
