import { ContactService, UserService } from './lib';
var NocoSDKApp = /** @class */ (function () {
    function NocoSDKApp(fetchApi) {
        var apiPath = process.env.API_HOST || '';
        this.userService = UserService.initialize(apiPath, fetchApi);
        this.contactService = ContactService.initialize(apiPath, fetchApi);
    }
    NocoSDKApp.initializeApp = function (fetchApi) {
        if (!NocoSDKApp.instance) {
            NocoSDKApp.instance = new NocoSDKApp(fetchApi);
        }
        return NocoSDKApp.instance;
    };
    return NocoSDKApp;
}());
export { NocoSDKApp };
