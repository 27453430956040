/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateSiteRequest
 */
export interface CreateSiteRequest {
  /**
   *
   * @type {string}
   * @memberof CreateSiteRequest
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof CreateSiteRequest
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof CreateSiteRequest
   */
  memo?: string
}

export function CreateSiteRequestFromJSON(json: any): CreateSiteRequest {
  return CreateSiteRequestFromJSONTyped(json, false)
}

export function CreateSiteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSiteRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    memo: !exists(json, 'memo') ? undefined : json['memo']
  }
}

export function CreateSiteRequestToJSON(value?: CreateSiteRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    title: value.title,
    description: value.description,
    memo: value.memo
  }
}
