import { ButtonBase } from '@material-ui/core'
import React from 'react'
import { Colors, DynamicMuiIcon, Typography } from '..'

export const BackButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <ButtonBase style={{ color: Colors.accent.keyBlue.default }} onClick={onClick}>
      <DynamicMuiIcon variant="arrowLeft" />
      <Typography fontSize="s" fontWeight="bold">
        戻る
      </Typography>
    </ButtonBase>
  )
}
