import React, { useState, useCallback } from 'react'
import { Box, ButtonBase, ClickAwayListener } from '@material-ui/core'
import { DocumentOpportunityUser, User } from '@noco/http-client/lib/noco'
import { Button, Colors, DynamicMuiIcon, ToggleSwitch, Input, Typography } from 'src/components/atoms'
import { useRegisterOpportunityUser, useUnRegisterOpportunityUser } from 'src/fixtures/modules/documents/hooks'
import { useGlobalStyles } from 'src/styles/theme'
import { SelectOpportunityUser } from 'src/components/molecules/SelectOpportunityUser'
import { useGetMe } from 'src/fixtures/modules/me/hooks'

export interface DisplayAppointmentInChatProps {
  documentId: string
  checked: boolean
  userList: DocumentOpportunityUser[]
  onToggleSwitch: () => void
}

export const DisplayAppointmentInChat = ({
  documentId,
  checked,
  userList,
  onToggleSwitch
}: DisplayAppointmentInChatProps) => {
  const globalClasses = useGlobalStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { handleRegisterOpportunityUser } = useRegisterOpportunityUser(documentId)
  const { handleUnRegisterOpportunityUser } = useUnRegisterOpportunityUser(documentId)
  const { data: me } = useGetMe()

  const onCalendarOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onSelect = async (user: User) => {
    await handleRegisterOpportunityUser(user.id!)
    setAnchorEl(null)
  }

  return (
    <ClickAwayListener
      disableReactTree
      onClickAway={() => {
        setAnchorEl(null)
      }}
    >
      <Box
        border={`1px solid ${Colors.background.silver}`}
        borderRadius="4px"
        bgcolor={Colors.background.lightGray}
        color={Colors.base.black}
        p="10px"
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="8px">
          <Typography variant="h5">アポイントをチャットに表示</Typography>
          <ToggleSwitch checked={checked} onChange={onToggleSwitch} />
        </Box>
        <Typography variant="subtitle2">
          チャット画面で担当者毎に日程調整ツールで設定した商談スケジュールのURLリンクを表示します。
        </Typography>
        <Box mt="16px" />
        <Typography variant="h6">アポイントに表示する担当者</Typography>
        <Box mt="4px" />
        <Box onClick={onCalendarOpen}>
          <Input name="" placeholder="担当者名で検索" fullWidth />
          <SelectOpportunityUser
            documentId={documentId}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            onSelect={onSelect}
          />
        </Box>
        <Box mt="16px" />
        {userList.map(documentOpportunityUser => {
          const { id, user } = documentOpportunityUser
          return (
            <Box
              key={id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottom={`1px solid ${Colors.background.silver}`}
              pb="6px"
              mb="12px"
            >
              <Box display="flex" alignItems="center">
                <Box flexShrink={0} borderRadius="50%" overflow="hidden" width="20px" height="20px" mr="8px">
                  <img src={user?.avatar?.url} alt="avatar" className={globalClasses.imgCover} />
                </Box>
                <Box width="100px" color={Colors.base.black} className={globalClasses.lineclamp1}>
                  <Typography fontSize="s">
                    {user?.lastName} {user?.firstName}
                  </Typography>
                </Box>
                {!user?.opportunityToolUrl && (
                  <Box display="flex" alignItems="center" color={Colors.accent.keyRed.default} ml="6px">
                    <DynamicMuiIcon variant="error" size="16px" />
                    <Box mr="6px" />
                    <Typography fontSize="xs">日程調整ツール未登録</Typography>
                  </Box>
                )}
              </Box>
              <ButtonBase onClick={() => handleUnRegisterOpportunityUser(String(id))}>
                <DynamicMuiIcon variant="close" color="action" size="16px" className={globalClasses.cursorPointer} />
              </ButtonBase>
            </Box>
          )
        })}
        <Box mt="4px" />
        <Button
          disabled={Boolean(me?.user?.opportunityToolUrl)}
          title="日程調整ツールを登録"
          onClick={() => window.open('/setting/user/appointment', '_blank')}
        />
      </Box>
    </ClickAwayListener>
  )
}
