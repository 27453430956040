/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationPaymentBillingTransactionFromJSON, OrganizationPaymentBillingTransactionToJSON } from './OrganizationPaymentBillingTransaction';
export function GetOrganizationPaymentBillingTransactionsResponseDataFromJSON(json) {
    return GetOrganizationPaymentBillingTransactionsResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationPaymentBillingTransactionsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        billingTransactions: json['billing_transactions'].map(OrganizationPaymentBillingTransactionFromJSON)
    };
}
export function GetOrganizationPaymentBillingTransactionsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        billing_transactions: value.billingTransactions.map(OrganizationPaymentBillingTransactionToJSON)
    };
}
