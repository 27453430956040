import { Box, createStyles, Dialog, makeStyles, Tab, Typography } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { Colors } from 'src/components/atoms'
import { useGetDefaultImage } from 'src/fixtures/modules/defaultImage/hooks'
import { useCreateCoverPhoto, useUpdateCoverPhoto } from 'src/fixtures/modules/site/hooks'
import { ImageList } from './ImageList'
import { Upload } from './Upload'

const DIALOG_WIDTH = 328

const useStyles = makeStyles(() => {
  return createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    tabList: {
      minHeight: 0
    },
    tab: {
      width: '50%',
      height: '42px',
      minHeight: 'auto',
      minWidth: 'auto',
      color: Colors.base.placeHolder,
      backgroundColor: Colors.background.lightGray,
      borderBottom: `1px solid ${Colors.background.gray}`,
      borderRight: `1px solid ${Colors.background.gray}`,
      borderLeft: `1px solid ${Colors.background.gray}`,
      '&.Mui-selected': {
        color: Colors.accent.keyPurple.default,
        backgroundColor: Colors.functional.background.default,
        borderBottom: 'none',
        borderRight: 'none',
        borderLeft: 'none'
      }
    },
    tabpanel: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      padding: 0
    },
    paper: {
      position: 'absolute',
      top: ({ rectsBottom }: ModalImageListProps) => rectsBottom + 10,
      left: ({ rectsRight }: ModalImageListProps) => rectsRight - DIALOG_WIDTH + 16,
      margin: '0'
    },
    backdrop: {
      backgroundColor: 'transparent'
    }
  })
})

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

type TabValueType = 'list' | 'new'

export interface ModalImageListProps {
  siteId: string
  open: boolean
  onClose: () => void
  rectsBottom: number
  rectsRight: number
}

export const ModalImageList = (props: ModalImageListProps) => {
  const { siteId, open, onClose } = props
  const classes = useStyles(props)
  const [value, setValue] = useState<TabValueType>('list')
  const { data: imagesResponse } = useGetDefaultImage('site')
  const defaultImages = imagesResponse?.defaultImages
  const { handleUpdateCoverPhoto } = useUpdateCoverPhoto(siteId)
  const { handleCreateCoverPhoto } = useCreateCoverPhoto(siteId)
  const handleChange = useCallback((_: ChangeEvent<{}>, newValue: TabValueType) => {
    setValue(newValue)
  }, [])
  const handleSelectImage = useCallback(
    (id: string) => {
      siteId && handleUpdateCoverPhoto({ siteId, updateDefaultCoverPhotoSiteRequest: { defaultImageId: id } })
      onClose()
    },
    [onClose, handleUpdateCoverPhoto, siteId]
  )
  const handleUpload = useCallback(
    (file: File) => {
      siteId && handleCreateCoverPhoto({ siteId, file })
      onClose()
    },
    [onClose, handleCreateCoverPhoto, siteId]
  )
  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.paper }}
      BackdropProps={{ className: classes.backdrop }}
      onClose={onClose}
    >
      <Box width={DIALOG_WIDTH} height={1} maxHeight="400px" display="flex" flexDirection="column">
        <TabContext value={value}>
          <Box flex="1 0 auto" bgcolor={Colors.functional.background.default}>
            <TabList
              onChange={handleChange}
              aria-label="image select tablist"
              TabIndicatorProps={{ style: { display: 'none' } }}
              className={classes.tabList}
            >
              <Tab
                value="list"
                label={<Typography variant="h5">ギャラリー</Typography>}
                className={classes.tab}
                {...a11yProps(0)}
              />
              <Tab
                value="new"
                label={<Typography variant="h5">画像アップロード</Typography>}
                className={classes.tab}
                {...a11yProps(1)}
              />
            </TabList>
          </Box>
          <Box width={1} height={1} overflow="auto" bgcolor={Colors.functional.background.default}>
            <TabPanel value="list" classes={{ root: classes.tabpanel }}>
              <ImageList images={defaultImages || []} onClick={handleSelectImage} />
            </TabPanel>
            <TabPanel value="new" classes={{ root: classes.tabpanel }}>
              <Upload onSelectFile={handleUpload} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Dialog>
  )
}
