/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function IgnoreErrorsContactsEmailTransactionsRequestFromJSON(json) {
    return IgnoreErrorsContactsEmailTransactionsRequestFromJSONTyped(json, false);
}
export function IgnoreErrorsContactsEmailTransactionsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        ids: !exists(json, 'ids') ? undefined : json['ids']
    };
}
export function IgnoreErrorsContactsEmailTransactionsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ids: value.ids
    };
}
