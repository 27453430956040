import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Music = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#9900CC" />
      <path
        d="M28.6959 23.686C29.304 22.663 29.7159 21.3645 29.7944 20.0856C29.8336 19.2593 29.6571 18.3937 29.2648 17.587C28.637 16.3082 27.5777 15.5999 26.5381 14.8917C25.773 14.3801 25.0472 13.8883 24.5372 13.1997L24.4391 13.0816C24.1449 12.6685 23.7918 12.1963 23.7329 11.8028C23.6937 11.4093 23.321 11.1339 22.9483 11.1536C22.5363 11.1929 22.2421 11.5077 22.2421 11.9209V25.4173C21.6144 25.0239 20.8297 24.7878 19.9666 24.7878C17.8873 24.7878 16.2003 26.1453 16.2003 27.8176C16.2003 29.4899 17.8873 30.8474 19.9666 30.8474C22.0459 30.8474 23.7526 29.4899 23.7526 27.8176V18.9839C24.8903 19.4364 26.7342 20.5381 27.2443 23.1155C27.1462 23.2532 27.0677 23.4106 26.95 23.5286C26.6754 23.8434 26.7146 24.3156 27.0285 24.6107C27.3424 24.8861 27.8131 24.8468 28.0878 24.532C28.2839 24.2959 28.4801 24.0401 28.637 23.745C28.6566 23.7254 28.6763 23.7057 28.6959 23.686Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Music
