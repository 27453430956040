import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { convertMs } from 'src/fixtures/utils/time'

export interface secondOfAverageBrowsingTimeCellProps {
  value: number
}

export const SecondOfAverageBrowsingTimeCell = ({ value }: secondOfAverageBrowsingTimeCellProps) => {
  const globalClasses = useGlobalStyles()
  const secondOfAverageBrowsingTime = useMemo(() => {
    return convertMs(value ?? 0)
  }, [value])

  return (
    <Box display="flex" alignItems="center">
      <Box width={1} whiteSpace="pre-wrap" className={globalClasses.lineclamp1}>
        <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
          {secondOfAverageBrowsingTime}
        </Typography>
      </Box>
    </Box>
  )
}
