/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetAddressbyPostalcodeData
 */
export interface GetAddressbyPostalcodeData {
  /**
   *
   * @type {string}
   * @memberof GetAddressbyPostalcodeData
   */
  prefecture?: string
  /**
   *
   * @type {string}
   * @memberof GetAddressbyPostalcodeData
   */
  address1?: string
  /**
   *
   * @type {string}
   * @memberof GetAddressbyPostalcodeData
   */
  address2?: string
  /**
   *
   * @type {string}
   * @memberof GetAddressbyPostalcodeData
   */
  address3?: string
}

export function GetAddressbyPostalcodeDataFromJSON(json: any): GetAddressbyPostalcodeData {
  return GetAddressbyPostalcodeDataFromJSONTyped(json, false)
}

export function GetAddressbyPostalcodeDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetAddressbyPostalcodeData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    prefecture: !exists(json, 'prefecture') ? undefined : json['prefecture'],
    address1: !exists(json, 'address_1') ? undefined : json['address_1'],
    address2: !exists(json, 'address_2') ? undefined : json['address_2'],
    address3: !exists(json, 'address_3') ? undefined : json['address_3']
  }
}

export function GetAddressbyPostalcodeDataToJSON(value?: GetAddressbyPostalcodeData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    prefecture: value.prefecture,
    address_1: value.address1,
    address_2: value.address2,
    address_3: value.address3
  }
}
