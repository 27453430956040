import * as React from 'react'
import { AppProps } from 'next/app'
import { ReactElement, ReactNode, useEffect } from 'react'
import { nocoSDK } from 'src/fixtures/modules/initialize'
import { NextPage } from 'next'
import { GlobalLayoutUser } from 'src/components/commons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'rc-slider/assets/index.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'react-toastify/dist/ReactToastify.css'
import '../styles/global.css'
import '../styles/date-picker.css'
import 'swiper/css'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// ! モック使用の際は.envのNEXT_PUBLIC_API_MOCKINGをenabledにする(基本はdisabled)
if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('../../.mocks')
}

const NextApp = (props: AppPropsWithLayout) => {
  const { Component, pageProps } = props
  useEffect(() => {
    // theme の override 時に使用する
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
    nocoSDK.initalize()
  }, [])

  const getLayout = Component.getLayout
  if (getLayout) {
    return getLayout(<Component {...pageProps} />)
  } else {
    return (
      <GlobalLayoutUser>
        <Component {...pageProps} />
      </GlobalLayoutUser>
    )
  }
}

NextApp.getInitialProps = async () => {
  nocoSDK.initalize()
  return {}
}

export default NextApp
