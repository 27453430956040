/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { RequestContactSettingFieldValueFromJSON, RequestContactSettingFieldValueToJSON } from './RequestContactSettingFieldValue';
export function CreateContactRequestContactFromJSON(json) {
    return CreateContactRequestContactFromJSONTyped(json, false);
}
export function CreateContactRequestContactFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userId: !exists(json, 'user_id') ? undefined : json['user_id'],
        contactSettingFieldValues: !exists(json, 'contact_setting_field_values')
            ? undefined
            : json['contact_setting_field_values'].map(RequestContactSettingFieldValueFromJSON)
    };
}
export function CreateContactRequestContactToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user_id: value.userId,
        contact_setting_field_values: value.contactSettingFieldValues === undefined
            ? undefined
            : value.contactSettingFieldValues.map(RequestContactSettingFieldValueToJSON)
    };
}
