/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface RemoveContactListRequest
 */
export interface RemoveContactListRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof RemoveContactListRequest
   */
  contactIds?: Array<string>
}

export function RemoveContactListRequestFromJSON(json: any): RemoveContactListRequest {
  return RemoveContactListRequestFromJSONTyped(json, false)
}

export function RemoveContactListRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RemoveContactListRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactIds: !exists(json, 'contact_ids') ? undefined : json['contact_ids']
  }
}

export function RemoveContactListRequestToJSON(value?: RemoveContactListRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_ids: value.contactIds
  }
}
