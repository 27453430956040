/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSON, GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitToJSON } from './GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimit';
export function GetOrganizationPaymentPlanCheckUsageLimitDataFromJSON(json) {
    return GetOrganizationPaymentPlanCheckUsageLimitDataFromJSONTyped(json, false);
}
export function GetOrganizationPaymentPlanCheckUsageLimitDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        usageLimit: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitFromJSON(json['usage_limit'])
    };
}
export function GetOrganizationPaymentPlanCheckUsageLimitDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        usage_limit: GetOrganizationPaymentPlanCheckUsageLimitDataUsageLimitToJSON(value.usageLimit)
    };
}
