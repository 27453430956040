import React, { useMemo } from 'react'
import { Avatar, Box, createStyles, makeStyles } from '@material-ui/core'
import { Colors, Typography } from 'src/components/atoms'
import { Sync } from '@material-ui/icons'
import { ContactTimeline, AuditColumn } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { TimeLineChangeType } from 'src/components/molecules/ClientTimeLineItem'

const useStyles = makeStyles(() =>
  createStyles({
    limeLineItem: {
      border: `1px solid ${Colors.background.silver}`,
      borderRadius: 6
    },
    avatar: {
      display: 'inline-flex',
      height: 20,
      width: 20,
      verticalAlign: 'sub',
      marginRight: 6
    },
    icon: {
      display: 'inline-flex',
      height: 16,
      width: 16,
      verticalAlign: 'text-bottom',
      marginRight: 4
    },
    link: {
      color: Colors.accent.keyBlue.default
    },
    textBold: {
      display: 'inline-flex',
      fontSize: '14px',
      lineHeight: '18.48px',
      letterSpacing: '0.04em',
      fontWeight: 'bold',
      flexShrink: 0
    },
    textNormal: {
      display: 'inline-flex',
      fontSize: '14px',
      lineHeight: '18.48px',
      letterSpacing: '0.04em',
      flexShrink: 0
    },
    small: {
      padding: '8px 10px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '13px'
        }
      }
    },
    medium: {
      padding: '10px 12px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '14px'
        }
      }
    }
  })
)

interface ClientTimeLineItemAuditProps {
  item: ContactTimeline
  type: TimeLineChangeType
  size?: 'small' | 'medium'
}

export const ClientTimeLineItemAudit = ({ item, type, size = 'medium' }: ClientTimeLineItemAuditProps) => {
  const classes = useStyles()
  const targetColumn = useMemo(() => {
    if (!item.audit?.columns) {
      return []
    }
    return item.audit.columns
  }, [item.audit])

  const descriptions = useMemo(() => {
    const length = targetColumn.length
    return targetColumn.map((target, i) => {
      return <Contents key={i} target={target} item={item} number={i} length={length} />
    })
  }, [item, targetColumn])

  const changedName = useMemo(() => {
    if (!targetColumn) {
      return ''
    } else if (type === 'contact') {
      return '連絡先'
    } else {
      return '会社'
    }
  }, [targetColumn, type])

  return (
    <Box
      className={size === 'medium' ? classes.medium : classes.small}
      border={`1px solid ${Colors.background.silver}`}
      borderRadius="6px"
      bgcolor="#fff"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="6px">
        <Box display="flex" alignItems="center" className="timeLineItemheading">
          <Box mr={2} display="flex" width="fit-content">
            <Sync htmlColor={Colors.base.middleGray} style={{ fontSize: '16px' }} />
          </Box>
          <Typography lineheight="14px" fontWeight="bold" style={{ color: Colors.base.middleGray }}>
            {changedName}の{item?.audit?.action}
          </Typography>
        </Box>

        <Typography fontSize="xs" lineheight="12px" style={{ color: Colors.base.middleGray }}>
          {item.createdAt && dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME)}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Avatar className={classes.avatar} src={item.audit?.user?.avatar?.url} />
        <Typography className={classes.textBold}>
          {item.audit?.user?.lastName} {item.audit?.user?.firstName}
        </Typography>
        <Box mr="2px" />
        <Typography className={classes.textNormal}>が</Typography>
        <Box mr="2px" />
        {descriptions.map((description, i) => {
          return <Box key={i}>{description}</Box>
        })}
      </Box>
    </Box>
  )
}

const Contents = ({
  target,
  item,
  number,
  length
}: {
  target: AuditColumn
  item: ContactTimeline
  number: number
  length: number
}) => {
  const classes = useStyles()
  if (item.audit?.action === '変更') {
    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography className={classes.textNormal}>{target.name}を</Typography>
        <Box mr="2px" />
        <Typography className={classes.textBold}>{target.beforeValueObject?.displayText || '未設定'}</Typography>
        <Box mr="2px" />
        <Typography className={classes.textNormal}>から</Typography>
        <Box mr="2px" />
        <Typography className={classes.textBold}>{target.afterValueObject?.displayText || '未設定'}</Typography>
        {number + 1 === length && <Box mr="2px" />}
        <Typography className={classes.textNormal}>{number + 1 === length ? 'に変更しました' : '、'}</Typography>
      </Box>
    )
  } else if (item.audit?.action === '削除') {
    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography className={classes.textBold}>{target.name}</Typography>
        <Box mr="2px" />
        <Typography className={classes.textNormal}>から</Typography>
        <Box mr="2px" />
        <Typography className={classes.textBold}>{target.beforeValueObject?.displayText}</Typography>
        <Box mr="2px" />
        <Typography className={classes.textNormal}>を削除しました</Typography>
      </Box>
    )
  } else {
    return (
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography className={classes.textBold}>{target.name}</Typography>
        <Box mr="2px" />
        <Typography className={classes.textNormal}>に</Typography>
        <Box mr="2px" />
        <Typography className={classes.textBold}>{target.afterValueObject?.displayText || '未設定'}</Typography>
        <Box mr="2px" />
        <Typography className={classes.textNormal}>を追加しました</Typography>
      </Box>
    )
  }
}
