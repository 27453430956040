/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumUserPermission,
  EnumUserPermissionFromJSON,
  EnumUserPermissionFromJSONTyped,
  EnumUserPermissionToJSON
} from './EnumUserPermission'
import {
  GetUsersResponseDataUsersInnerUserAvatar,
  GetUsersResponseDataUsersInnerUserAvatarFromJSON,
  GetUsersResponseDataUsersInnerUserAvatarFromJSONTyped,
  GetUsersResponseDataUsersInnerUserAvatarToJSON
} from './GetUsersResponseDataUsersInnerUserAvatar'

/**
 *
 * @export
 * @interface UserWithToken
 */
export interface UserWithToken {
  /**
   *
   * @type {string}
   * @memberof UserWithToken
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof UserWithToken
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof UserWithToken
   */
  lastName?: string
  /**
   *
   * @type {boolean}
   * @memberof UserWithToken
   */
  firstLogin?: boolean
  /**
   *
   * @type {GetUsersResponseDataUsersInnerUserAvatar}
   * @memberof UserWithToken
   */
  avatar?: GetUsersResponseDataUsersInnerUserAvatar
  /**
   *
   * @type {EnumUserPermission}
   * @memberof UserWithToken
   */
  permission?: EnumUserPermission
  /**
   *
   * @type {string}
   * @memberof UserWithToken
   */
  activatedAt?: string
  /**
   *
   * @type {string}
   * @memberof UserWithToken
   */
  discardedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof UserWithToken
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof UserWithToken
   */
  updatedAt?: string
}

export function UserWithTokenFromJSON(json: any): UserWithToken {
  return UserWithTokenFromJSONTyped(json, false)
}

export function UserWithTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithToken {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    firstLogin: !exists(json, 'first_login') ? undefined : json['first_login'],
    avatar: !exists(json, 'avatar') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
    permission: !exists(json, 'permission') ? undefined : EnumUserPermissionFromJSON(json['permission']),
    activatedAt: !exists(json, 'activated_at') ? undefined : json['activated_at'],
    discardedAt: !exists(json, 'discarded_at') ? undefined : json['discarded_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
  }
}

export function UserWithTokenToJSON(value?: UserWithToken | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    first_name: value.firstName,
    last_name: value.lastName,
    first_login: value.firstLogin,
    avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
    permission: EnumUserPermissionToJSON(value.permission),
    activated_at: value.activatedAt,
    discarded_at: value.discardedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt
  }
}
