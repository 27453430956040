import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

type Props = Omit<SvgIconProps, 'color'> & {
  color?: SvgIconProps['color'] | string
}

const Facebook: React.VFC<Props> = ({ color = '#1877F2', ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 10.2426C20 4.7197 15.5229 0.242554 10 0.242554C4.47715 0.242554 0 4.7197 0 10.2426C0 15.2338 3.65684 19.3709 8.4375 20.1211V13.1332H5.89844V10.2426H8.4375V8.03943C8.4375 5.53318 9.93047 4.1488 12.2146 4.1488C13.3084 4.1488 14.4531 4.34412 14.4531 4.34412V6.80505H13.1922C11.95 6.80505 11.5625 7.57595 11.5625 8.36755V10.2426H14.3359L13.8926 13.1332H11.5625V20.1211C16.3432 19.3709 20 15.2338 20 10.2426Z"
        fill={color}
      />
    </SvgIcon>
  )
}
export default Facebook
