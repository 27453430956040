/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
import { SiteContactFromJSON, SiteContactToJSON } from './SiteContact';
export function GetSiteContactsResponseDataFromJSON(json) {
    return GetSiteContactsResponseDataFromJSONTyped(json, false);
}
export function GetSiteContactsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        siteContacts: !exists(json, 'site_contacts')
            ? undefined
            : json['site_contacts'].map(SiteContactFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetSiteContactsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        site_contacts: value.siteContacts === undefined ? undefined : value.siteContacts.map(SiteContactToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
