/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Site, SiteFromJSON, SiteFromJSONTyped, SiteToJSON } from './Site'

/**
 *
 * @export
 * @interface CreateSiteResponseData
 */
export interface CreateSiteResponseData {
  /**
   *
   * @type {Site}
   * @memberof CreateSiteResponseData
   */
  site?: Site
  /**
   *
   * @type {boolean}
   * @memberof CreateSiteResponseData
   */
  isFirstCreatedSite: boolean
}

export function CreateSiteResponseDataFromJSON(json: any): CreateSiteResponseData {
  return CreateSiteResponseDataFromJSONTyped(json, false)
}

export function CreateSiteResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSiteResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    site: !exists(json, 'site') ? undefined : SiteFromJSON(json['site']),
    isFirstCreatedSite: json['is_first_created_site']
  }
}

export function CreateSiteResponseDataToJSON(value?: CreateSiteResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site: SiteToJSON(value.site),
    is_first_created_site: value.isFirstCreatedSite
  }
}
