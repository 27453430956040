/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShallowCompanyListFromJSON, ShallowCompanyListToJSON } from './ShallowCompanyList';
export function GetCompanyCompanyListsResponseDataFromJSON(json) {
    return GetCompanyCompanyListsResponseDataFromJSONTyped(json, false);
}
export function GetCompanyCompanyListsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        companyId: json['company_id'],
        companyLists: json['company_lists'].map(ShallowCompanyListFromJSON)
    };
}
export function GetCompanyCompanyListsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        company_id: value.companyId,
        company_lists: value.companyLists.map(ShallowCompanyListToJSON)
    };
}
