import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Box, LinearProgress, Modal } from '@material-ui/core'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { LayoutFullModal } from 'src/components/commons/LayoutFullModal'
import { Accept, useDropzone } from 'react-dropzone'
import { PROGRESS } from 'src/fixtures/utils/constant'

// 100MB
const MAX_FILE_SIZE = 100 * 1_024 ** 2
const FILE_TYPE: Accept = { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }

interface ModalImportProps {
  open: boolean
  onBack: () => void
  onClose: () => void
  onDownloadFormat: () => void
  onCreateImport: (file: Blob | undefined) => void
  error: Error | undefined
  isLoading: boolean
  title: string
}

export const ModalImport = ({
  open,
  onBack,
  onClose,
  onDownloadFormat,
  onCreateImport,
  error,
  isLoading,
  title
}: ModalImportProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(true)

  const handleFileClick = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.[0]) return
      onCreateImport(e.target.files[0]!)
      onClose()
    },
    [onClose, onCreateImport]
  )

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0])
      setIsSubmitting(true)
      setIsSubmitted(false)
      onCreateImport(acceptedFiles[0])
    },
    [onCreateImport]
  )

  const handleDownload = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onDownloadFormat()
    },
    [onDownloadFormat]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: FILE_TYPE,
    maxSize: MAX_FILE_SIZE
  })

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      setProgress(0)
      setIsSubmitting(false)
      setTimeout(() => {
        setProgress(100)
        onClose()
        setFile(null)
        setIsSubmitted(true)
      }, 1024)
    }
  }, [isLoading, progress, isSubmitting, error, onClose])

  useEffect(() => {
    if (error) {
      setIsSubmitting(false)
      setProgress(0)
    }
  }, [error])

  useEffect(() => {
    if (progress <= PROGRESS.THRESHOLD && !isSubmitted) {
      setTimeout(() => setProgress(v => v + 5), PROGRESS.SPEED)
    }
  }, [progress, isSubmitted])

  return (
    <Modal open={open}>
      <LayoutFullModal title={title} back onBack={onBack}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Box
            {...getRootProps()}
            sx={{
              width: '680px',
              py: '35px',
              border: `1px solid ${Colors.background.silver}`,
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <input {...getInputProps()} />
            {file ? (
              <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                <DynamicMuiIcon variant="document" size="40px" color="action" />
                <Box mt="20px" />
                <Typography fontSize="s" lh="relaxed">
                  {file.name}
                </Typography>
                <Box mt="32px" />
                <Box width="300px">
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
              </Box>
            ) : (
              <>
                <DynamicMuiIcon variant="fileUpload" size="40px" color="action" />
                <Box mt="16px" />
                <Typography variant="h6" fontSize="m">
                  Excelファイル（.xlsx）のアップロード
                </Typography>
                <Box mt="16px" />
                <Typography fontSize="s" lh="relaxed">
                  <span style={{ display: 'block' }}>・一度にインポートできるデータは、1万件まで</span>
                  <span style={{ display: 'block' }}>・ファイルサイズは1MBまで</span>
                  <span style={{ display: 'block' }}>・サンプルファイルに無い列は無視されます</span>
                </Typography>
                <Box mt="16px" />
                <Button
                  kind="secondary"
                  variant="outlined"
                  title="サンプルファイルをダウンロード"
                  startIcon={<DynamicMuiIcon variant="fileDownload" size="20px" color="inherit" />}
                  onClick={handleDownload}
                />
              </>
            )}
          </Box>
          <Box mt="40px" />
          <Button kind="primary" title="ファイルを選択" onClick={handleFileClick} />
        </Box>
        {/* ファイルアップロード用 隠しinput */}
        <Box display="none">
          <input
            ref={inputRef}
            type="file"
            accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={handleChange}
          />
        </Box>
      </LayoutFullModal>
    </Modal>
  )
}
