/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { AppendCompanyListRequestToJSON, AppendCompanyListResponseFromJSON, CreateCompanyListRequestToJSON, CreateCompanyListResponseFromJSON, GetCompanyListsResponseFromJSON, PinCompanyListRequestToJSON, RemoveCompanyListRequestToJSON, RemoveCompanyListResponseFromJSON, UpdateCompanyListRequestToJSON, UpdateCompanyListResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1CompanyListApi extends runtime.BaseAPI {
    /**
     * コンタクト一覧にコンタクトを追加する
     * append
     */
    async userV1CompanyListsCompanyListIdAppendPostRaw(requestParameters, initOverrides) {
        if (requestParameters.companyListId === null || requestParameters.companyListId === undefined) {
            throw new runtime.RequiredError('companyListId', 'Required parameter requestParameters.companyListId was null or undefined when calling userV1CompanyListsCompanyListIdAppendPost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/company_lists/{company_list_id}/append`.replace(`{${'company_list_id'}}`, encodeURIComponent(String(requestParameters.companyListId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppendCompanyListRequestToJSON(requestParameters.appendCompanyListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => AppendCompanyListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧にコンタクトを追加する
     * append
     */
    async userV1CompanyListsCompanyListIdAppendPost(requestParameters, initOverrides) {
        const response = await this.userV1CompanyListsCompanyListIdAppendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を削除する
     * destroy
     */
    async userV1CompanyListsCompanyListIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.companyListId === null || requestParameters.companyListId === undefined) {
            throw new runtime.RequiredError('companyListId', 'Required parameter requestParameters.companyListId was null or undefined when calling userV1CompanyListsCompanyListIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/company_lists/{company_list_id}`.replace(`{${'company_list_id'}}`, encodeURIComponent(String(requestParameters.companyListId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * コンタクト一覧を削除する
     * destroy
     */
    async userV1CompanyListsCompanyListIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1CompanyListsCompanyListIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を更新する
     * create
     */
    async userV1CompanyListsCompanyListIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.companyListId === null || requestParameters.companyListId === undefined) {
            throw new runtime.RequiredError('companyListId', 'Required parameter requestParameters.companyListId was null or undefined when calling userV1CompanyListsCompanyListIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/company_lists/{company_list_id}`.replace(`{${'company_list_id'}}`, encodeURIComponent(String(requestParameters.companyListId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyListRequestToJSON(requestParameters.updateCompanyListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateCompanyListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧を更新する
     * create
     */
    async userV1CompanyListsCompanyListIdPut(requestParameters, initOverrides) {
        const response = await this.userV1CompanyListsCompanyListIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧にコンタクトを削除する
     * remove
     */
    async userV1CompanyListsCompanyListIdRemovePostRaw(requestParameters, initOverrides) {
        if (requestParameters.companyListId === null || requestParameters.companyListId === undefined) {
            throw new runtime.RequiredError('companyListId', 'Required parameter requestParameters.companyListId was null or undefined when calling userV1CompanyListsCompanyListIdRemovePost.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/company_lists/{company_list_id}/remove`.replace(`{${'company_list_id'}}`, encodeURIComponent(String(requestParameters.companyListId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveCompanyListRequestToJSON(requestParameters.removeCompanyListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => RemoveCompanyListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧にコンタクトを削除する
     * remove
     */
    async userV1CompanyListsCompanyListIdRemovePost(requestParameters, initOverrides) {
        const response = await this.userV1CompanyListsCompanyListIdRemovePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を取得する
     * index
     */
    async userV1CompanyListsGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/company_lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetCompanyListsResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧を取得する
     * index
     */
    async userV1CompanyListsGet(initOverrides) {
        const response = await this.userV1CompanyListsGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧にpin設定をする
     * pin
     */
    async userV1CompanyListsPinPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/company_lists/pin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PinCompanyListRequestToJSON(requestParameters.pinCompanyListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * コンタクト一覧にpin設定をする
     * pin
     */
    async userV1CompanyListsPinPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1CompanyListsPinPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * コンタクト一覧を作成する
     * create
     */
    async userV1CompanyListsPostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/company_lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyListRequestToJSON(requestParameters.createCompanyListRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => CreateCompanyListResponseFromJSON(jsonValue));
    }
    /**
     * コンタクト一覧を作成する
     * create
     */
    async userV1CompanyListsPost(requestParameters = {}, initOverrides) {
        const response = await this.userV1CompanyListsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
