import { Box } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { TrialBanner, TRIAL_BANNER_HEIGHT } from 'src/components/molecules'
import { SideMenu, SubSideMenu, MenuList, MIN_SIDE_MENU_SIZE } from 'src/components/organisms'
import { useGetOrganizationStatus } from 'src/fixtures/modules/organization/hooks'

interface LayoutUserProps {
  children: ReactNode
}

const menuList: MenuList = [
  { label: 'ユーザー情報', path: '/setting/user/info', icon: 'person' },
  { label: 'プロフィール', path: '/setting/user/profile', icon: 'profile' },
  { label: 'アポイントの設定', path: '/setting/user/appointment', icon: 'meeting' },
  { label: 'メール通知', path: '/setting/user/notification', icon: 'markEmail' }
]

export const LayoutUser: React.VFC<LayoutUserProps> = ({ children }) => {
  const { isTrialing, organization } = useGetOrganizationStatus()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isTrialing && <TrialBanner trialEndAt={organization?.trialEndAt ?? undefined} />}
      <Box height="100%" maxWidth="100vw" pl={`${MIN_SIDE_MENU_SIZE}px`} style={{ overflowX: 'hidden' }} display="flex">
        <Box
          position="fixed"
          height="100vh"
          top={0}
          left={0}
          zIndex={1000}
          pt={isTrialing ? `${TRIAL_BANNER_HEIGHT}px` : 0}
        >
          <SideMenu />
        </Box>
        <SubSideMenu menuList={menuList} />
        {children}
      </Box>
    </Box>
  )
}
