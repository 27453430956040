/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentPlansResponseData,
  GetOrganizationPaymentPlansResponseDataFromJSON,
  GetOrganizationPaymentPlansResponseDataFromJSONTyped,
  GetOrganizationPaymentPlansResponseDataToJSON
} from './GetOrganizationPaymentPlansResponseData'

/**
 *
 * @export
 * @interface GetOrganizationPaymentPlansResponse
 */
export interface GetOrganizationPaymentPlansResponse {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentPlansResponse
   */
  status: number
  /**
   *
   * @type {GetOrganizationPaymentPlansResponseData}
   * @memberof GetOrganizationPaymentPlansResponse
   */
  data: GetOrganizationPaymentPlansResponseData
}

export function GetOrganizationPaymentPlansResponseFromJSON(json: any): GetOrganizationPaymentPlansResponse {
  return GetOrganizationPaymentPlansResponseFromJSONTyped(json, false)
}

export function GetOrganizationPaymentPlansResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentPlansResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetOrganizationPaymentPlansResponseDataFromJSON(json['data'])
  }
}

export function GetOrganizationPaymentPlansResponseToJSON(value?: GetOrganizationPaymentPlansResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationPaymentPlansResponseDataToJSON(value.data)
  }
}
