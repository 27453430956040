/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { MailTemplate, MailTemplateFromJSON, MailTemplateFromJSONTyped, MailTemplateToJSON } from './MailTemplate'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetMailTemplatesResponseData
 */
export interface GetMailTemplatesResponseData {
  /**
   *
   * @type {Array<MailTemplate>}
   * @memberof GetMailTemplatesResponseData
   */
  mailTemplates?: Array<MailTemplate>
  /**
   *
   * @type {PageInfo}
   * @memberof GetMailTemplatesResponseData
   */
  pageInfo?: PageInfo
}

export function GetMailTemplatesResponseDataFromJSON(json: any): GetMailTemplatesResponseData {
  return GetMailTemplatesResponseDataFromJSONTyped(json, false)
}

export function GetMailTemplatesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetMailTemplatesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    mailTemplates: !exists(json, 'mail_templates')
      ? undefined
      : (json['mail_templates'] as Array<any>).map(MailTemplateFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetMailTemplatesResponseDataToJSON(value?: GetMailTemplatesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    mail_templates:
      value.mailTemplates === undefined ? undefined : (value.mailTemplates as Array<any>).map(MailTemplateToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
