import { createStyles, makeStyles, Box } from '@material-ui/core'
import { Colors } from '../Colors'
import { Typography } from '../Typography'

export interface StepTitleProps {
  index: number
  title: string
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    index: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      background: Colors.option.deepBlue,
      borderRadius: '4px',
      color: Colors.functional.whiteText.default,
      marginRight: '9px'
    },
    title: {
      color: Colors.base.black,
      marginTop: '4px'
    }
  })
)

export const StepTitle = ({ index, title }: StepTitleProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.index}>
        <Typography fontSize="s" lineheight="14px" letterSpacing="tight">
          {index}
        </Typography>
      </Box>
      <Box className={classes.title}>
        <Typography fontWeight={600} fontSize="m" lineheight="16px">
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
