/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateOrganizationAdvertisementRequestOrganization
 */
export interface UpdateOrganizationAdvertisementRequestOrganization {
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrganizationAdvertisementRequestOrganization
   */
  hideAd: boolean
}

export function UpdateOrganizationAdvertisementRequestOrganizationFromJSON(
  json: any
): UpdateOrganizationAdvertisementRequestOrganization {
  return UpdateOrganizationAdvertisementRequestOrganizationFromJSONTyped(json, false)
}

export function UpdateOrganizationAdvertisementRequestOrganizationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationAdvertisementRequestOrganization {
  if (json === undefined || json === null) {
    return json
  }
  return {
    hideAd: json['hide_ad']
  }
}

export function UpdateOrganizationAdvertisementRequestOrganizationToJSON(
  value?: UpdateOrganizationAdvertisementRequestOrganization | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    hide_ad: value.hideAd
  }
}
