/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const EnumChatMessageRepliedStatus = {
  Error: 'error',
  Replied: 'replied',
  None: 'none'
} as const
export type EnumChatMessageRepliedStatus =
  typeof EnumChatMessageRepliedStatus[keyof typeof EnumChatMessageRepliedStatus]

export function EnumChatMessageRepliedStatusFromJSON(json: any): EnumChatMessageRepliedStatus {
  return EnumChatMessageRepliedStatusFromJSONTyped(json, false)
}

export function EnumChatMessageRepliedStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnumChatMessageRepliedStatus {
  return json as EnumChatMessageRepliedStatus
}

export function EnumChatMessageRepliedStatusToJSON(value?: EnumChatMessageRepliedStatus | null): any {
  return value as any
}
