import React, { useCallback, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'

import { LayoutSinglePage } from 'src/components/commons'
import { StepperBoard } from 'src/components/molecules/StepperBoard'
import { StepperContext } from 'src/components/molecules/StepperContext'
import { Stepper } from 'src/components/molecules/Stepper'
import { StepSelectUser, StepSelectMailTemplate, StepComplete, StepConfirmation } from './step'

export const PageDowngrade = () => {
  const [stayUserIds, setStayUserIds] = useState<string[]>([])
  const [takeOverUserId, setTakeOverUserId] = useState('')
  const [mailTemplateIds, setMailTemplateIds] = useState<string[]>([])

  const [currentIndex, setCurrentIndex] = useState(0)

  const handleIncrementIndex = useCallback(() => {
    window.scrollTo({ top: 0 })
    setCurrentIndex(v => v + 1)
  }, [])

  const handleDecrementIndex = useCallback(() => {
    window.scrollTo({ top: 0 })
    setCurrentIndex(v => v - 1)
  }, [])

  const items = useMemo(
    () => [
      {
        label: 'ユーザーを選択',
        renderItem: (
          <StepSelectUser
            onNext={({ stayUserIds, takeOverUserId }) => {
              setStayUserIds(stayUserIds)
              setTakeOverUserId(takeOverUserId)
              handleIncrementIndex()
            }}
          />
        )
      },
      {
        label: 'メールテンプレートを選択',
        renderItem: (
          <StepSelectMailTemplate
            onNext={templateId => {
              setMailTemplateIds(templateId)
              handleIncrementIndex()
            }}
            onBack={() => {
              setMailTemplateIds([])
              handleDecrementIndex()
            }}
          />
        )
      },
      {
        label: '確認',
        renderItem: (
          <StepConfirmation
            mailTemplateIds={mailTemplateIds}
            stayUserIds={stayUserIds}
            takeOverUserId={takeOverUserId}
            onNext={handleIncrementIndex}
            onBack={handleDecrementIndex}
          />
        )
      },
      {
        label: '完了',
        renderItem: <StepComplete />
      }
    ],
    [handleDecrementIndex, handleIncrementIndex, mailTemplateIds, stayUserIds, takeOverUserId]
  )

  return (
    <LayoutSinglePage title="データの選択" back>
      <Box sx={{ maxWidth: '800px', width: '100%', mx: 'auto', pt: '24px' }}>
        <StepperContext currentIndex={currentIndex}>
          <Stepper items={items} />
          <StepperBoard boardItems={items} />
        </StepperContext>
      </Box>
    </LayoutSinglePage>
  )
}
