/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserEmailNotificationSettingFromJSON, UserEmailNotificationSettingToJSON } from './UserEmailNotificationSetting';
export function GetMeEmailNotificationSettingResponseDataFromJSON(json) {
    return GetMeEmailNotificationSettingResponseDataFromJSONTyped(json, false);
}
export function GetMeEmailNotificationSettingResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userEmailNotificationSetting: !exists(json, 'user_email_notification_setting')
            ? undefined
            : UserEmailNotificationSettingFromJSON(json['user_email_notification_setting'])
    };
}
export function GetMeEmailNotificationSettingResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user_email_notification_setting: UserEmailNotificationSettingToJSON(value.userEmailNotificationSetting)
    };
}
