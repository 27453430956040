import {
  UpdateMeAvatarRequest,
  UserV1MePutRequest,
  UpdateMeEmailNotificationSettingRequest,
  UpdateMeProfileRequest,
  ConfirmMeChangeEmailRequest,
  UserV1MePasswordPutRequest
} from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Toaster } from 'src/components/atoms'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { SWRCachePath } from '../swr-cach-path'

export const useGetMe = () => {
  const { data: auth } = useAuthenticate()
  const { meApi } = nocoSDK?.client?.userService || {}

  const func = useCallback(async () => {
    if (!auth) return
    return await meApi
      ?.ApiFactory(auth.token)
      .userV1MeGet()
      .then(res => res.data)
  }, [auth, meApi])
  const response = useSWR(auth && SWRCachePath.getMe(), func)
  return { ...response, isLoading: !response.data && !response.error }
}

export const useUpdateMe = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetMe()
  const { meApi } = nocoSDK?.client?.userService || {}

  const handleUpdateMe = useCallback(
    async (params: UserV1MePutRequest['updateMeRequest'], callback?: () => void) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        const res = await meApi?.ApiFactory(auth.token).userV1MePut({ updateMeRequest: params })
        mutate(res?.data)
        if (callback == null) Toaster.success('更新しました！')
        else callback()
      } catch (err) {
        await handleError(err, { setError, tag: 'Update me' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, meApi, mutate]
  )

  return { error, isLoading, handleUpdateMe }
}

export const useUpdateMeAvatar = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetMe()
  const { meApi } = nocoSDK?.client?.userService || {}

  const handleUpdateMeAvatar = useCallback(
    async (updateMeAvatarRequest: UpdateMeAvatarRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)

        const res = await meApi?.ApiFactory(auth.token).userV1MeAvatarPut({ updateMeAvatarRequest })
        mutate(res?.data)
        Toaster.success('更新しました！')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update me avatar' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, meApi, mutate]
  )

  return { error, isLoading, handleUpdateMeAvatar }
}

export const useGetMeProfile = () => {
  const { data: auth } = useAuthenticate()
  const { meApi } = nocoSDK?.client?.userService || {}

  const func = useCallback(async () => {
    if (!auth) return
    return await meApi
      ?.ApiFactory(auth.token)
      .userV1MeProfileGet()
      .then(res => res.data?.userProfile)
  }, [auth, meApi])
  const response = useSWR(auth && SWRCachePath.getMeProfile(), func)
  return { ...response, isLoading: !response.data && !response.error }
}

export const useUpdateMePassword = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetMe()
  const { meApi } = nocoSDK?.client?.userService || {}

  const handleUpdateMePassword = useCallback(
    async (params: UserV1MePasswordPutRequest['updateMePasswordRequest'], callback?: () => void) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        const res = await meApi?.ApiFactory(auth.token).userV1MePasswordPut({ updateMePasswordRequest: params })
        mutate(res?.data)
        if (callback == null) Toaster.success('パスワードを更新しました！')
        else callback()
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Me Password' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, meApi, mutate]
  )
  return { error, isLoading, handleUpdateMePassword }
}

export const useUpdateMeProfile = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetMeProfile()
  const { meApi } = nocoSDK?.client?.userService || {}

  const handleUpdateMeProfile = useCallback(
    async (updateMeProfileRequest: UpdateMeProfileRequest) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)
        const res = await meApi?.ApiFactory(auth.token).userV1MeProfilePut({ updateMeProfileRequest })
        mutate(res?.data?.userProfile)
        Toaster.success('プロフィールを更新しました！')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Me Proflie' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, meApi, mutate]
  )

  return { error, isLoading, handleUpdateMeProfile }
}

export const useGetEmailNotificationSetting = () => {
  const { data: auth } = useAuthenticate()
  const { meApi } = nocoSDK?.client?.userService || {}

  const func = useCallback(async () => {
    if (!auth) return
    return await meApi
      ?.ApiFactory(auth.token)
      .userV1MeEmailNotificationSettingGet()
      .then(res => res.data)
  }, [auth, meApi])
  const response = useSWR(auth && SWRCachePath.getEmailNotificationSetting(), func)
  return { ...response, isLoading: !response.data && !response.error }
}

export const useUpdateEmailNotificationSetting = () => {
  const { data: auth } = useAuthenticate()
  const { meApi } = nocoSDK?.client?.userService || {}
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { data: me } = useGetMe()
  const { mutate: mutateEmailNotificationSetting } = useGetEmailNotificationSetting()

  const handleUpdateEmailNotificationSetting = useCallback(
    async (updateMeEmailNotificationSettingRequest: UpdateMeEmailNotificationSettingRequest) => {
      try {
        if (!auth || !me) return
        setError(undefined)
        setIsLoading(true)
        await meApi?.ApiFactory(auth.token).userV1MeEmailNotificationSettingPut({
          updateMeEmailNotificationSettingRequest
        })
        await mutateEmailNotificationSetting()
        Toaster.success('通知設定を変更しました')
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Email Notification Setting' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, me, meApi, mutateEmailNotificationSetting]
  )

  return { error, isLoading, handleUpdateEmailNotificationSetting }
}

export const useUpdateMeEmail = () => {
  const { data: auth } = useAuthenticate()
  const [isInitialized, setIsInitialized] = useState(false)

  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useGetMe()
  const { meApi } = nocoSDK?.client?.userService || {}

  const handleUpdateEmail = useCallback(
    async (confirmMeChangeEmailRequest: ConfirmMeChangeEmailRequest) => {
      try {
        const { token } = confirmMeChangeEmailRequest

        if (!auth || !token) return

        const res = await meApi?.ApiFactory(auth.token).userV1MeConfirmChangeEmailPost({ confirmMeChangeEmailRequest })
        mutate(res?.data)

        setIsInitialized(true)
        Toaster.success('メールアドレスの認証が完了しました')
      } catch (error) {
        handleError(error, { setError, tag: 'Update Email' })

        setError(new Error('エラーが発生しました'))
        setIsInitialized(true)
      }
    },
    [auth, meApi, mutate]
  )

  return { error, isInitialized, handleUpdateEmail }
}
