import { GetCompanyCompanyListsResponseData } from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useState } from 'react'
import { AddOrRemoveContact } from 'src/components/molecules/SelectTableRow/AddOrRemoveContact'
import { useCreateCompanyList, useUpdateCompanyList } from 'src/fixtures/modules/company/hooks'
import { useListSelectorCompaniesList } from 'src/fixtures/modules/selector/hooks'
import { KeyedMutator } from 'swr'

export interface CompanyListProps {
  companyId: string
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  mutateList: KeyedMutator<GetCompanyCompanyListsResponseData | undefined>
}

export const CompanyListModal = ({ companyId, isOpen, setIsOpen, mutateList }: CompanyListProps) => {
  const [keyword, setKeyword] = useState('')
  const { handleCreateCompanyList } = useCreateCompanyList()
  const { handleUpdateCompanyList } = useUpdateCompanyList({
    page: 1,
    per: 9999
  })
  const { data: selectorList, mutate } = useListSelectorCompaniesList({ page: 1, per: 9999, text: keyword })

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const handleUpdateCompanyListConfirm = useCallback(
    async (contactListId: string, companyIds: string[]) => {
      await handleUpdateCompanyList(contactListId, companyIds)
      mutateList()
      onClose()
      setKeyword('')
    },
    [handleUpdateCompanyList, onClose, mutateList]
  )

  const handleCompanyListConfirm = useCallback(
    async (listName: string, companyIds: string[]) => {
      await handleCreateCompanyList(listName, companyIds)
      mutateList()
      onClose()
      setKeyword('')
    },
    [handleCreateCompanyList, onClose, mutateList]
  )

  useEffect(() => {
    if (isOpen) {
      mutate()
      setKeyword('')
    }
  }, [isOpen, mutate])

  return (
    <AddOrRemoveContact
      selectedIds={[companyId]}
      keyword={keyword}
      selectorList={selectorList}
      onArchivedBoxClose={onClose}
      onSetKeyword={setKeyword}
      onCreateList={handleCompanyListConfirm}
      onUpdateList={handleUpdateCompanyListConfirm}
      modalAddContactOpen={isOpen}
      onSetModalAddContactOpen={setIsOpen}
    />
  )
}
