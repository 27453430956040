/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
import { SelectorItemFromJSON, SelectorItemToJSON } from './SelectorItem';
export function SelectorItemObjectFromJSON(json) {
    return SelectorItemObjectFromJSONTyped(json, false);
}
export function SelectorItemObjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        itemType: !exists(json, 'item_type') ? undefined : json['item_type'],
        selectorItems: !exists(json, 'selector_items')
            ? undefined
            : json['selector_items'].map(SelectorItemFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function SelectorItemObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        item_type: value.itemType,
        selector_items: value.selectorItems === undefined ? undefined : value.selectorItems.map(SelectorItemToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
