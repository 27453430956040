/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateSiteSectionRequestSiteSection,
  UpdateSiteSectionRequestSiteSectionFromJSON,
  UpdateSiteSectionRequestSiteSectionFromJSONTyped,
  UpdateSiteSectionRequestSiteSectionToJSON
} from './UpdateSiteSectionRequestSiteSection'

/**
 *
 * @export
 * @interface UpdateSiteSectionRequest
 */
export interface UpdateSiteSectionRequest {
  /**
   *
   * @type {UpdateSiteSectionRequestSiteSection}
   * @memberof UpdateSiteSectionRequest
   */
  siteSection?: UpdateSiteSectionRequestSiteSection
}

export function UpdateSiteSectionRequestFromJSON(json: any): UpdateSiteSectionRequest {
  return UpdateSiteSectionRequestFromJSONTyped(json, false)
}

export function UpdateSiteSectionRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateSiteSectionRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteSection: !exists(json, 'site_section')
      ? undefined
      : UpdateSiteSectionRequestSiteSectionFromJSON(json['site_section'])
  }
}

export function UpdateSiteSectionRequestToJSON(value?: UpdateSiteSectionRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_section: UpdateSiteSectionRequestSiteSectionToJSON(value.siteSection)
  }
}
