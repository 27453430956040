/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ContactWithAssociation,
  ContactWithAssociationFromJSON,
  ContactWithAssociationFromJSONTyped,
  ContactWithAssociationToJSON
} from './ContactWithAssociation'
import {
  EnumChatSummaryRepliedStatus,
  EnumChatSummaryRepliedStatusFromJSON,
  EnumChatSummaryRepliedStatusFromJSONTyped,
  EnumChatSummaryRepliedStatusToJSON
} from './EnumChatSummaryRepliedStatus'
import {
  ShallowChatMessage,
  ShallowChatMessageFromJSON,
  ShallowChatMessageFromJSONTyped,
  ShallowChatMessageToJSON
} from './ShallowChatMessage'

/**
 *
 * @export
 * @interface ChatSummary
 */
export interface ChatSummary {
  /**
   *
   * @type {string}
   * @memberof ChatSummary
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ChatSummary
   */
  repliedAt?: string
  /**
   *
   * @type {EnumChatSummaryRepliedStatus}
   * @memberof ChatSummary
   */
  repliedStatus?: EnumChatSummaryRepliedStatus
  /**
   *
   * @type {string}
   * @memberof ChatSummary
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof ChatSummary
   */
  updatedAt?: string
  /**
   *
   * @type {ContactWithAssociation}
   * @memberof ChatSummary
   */
  contact?: ContactWithAssociation
  /**
   *
   * @type {Array<ShallowChatMessage>}
   * @memberof ChatSummary
   */
  chatMessages?: Array<ShallowChatMessage>
}

export function ChatSummaryFromJSON(json: any): ChatSummary {
  return ChatSummaryFromJSONTyped(json, false)
}

export function ChatSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatSummary {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    repliedAt: !exists(json, 'replied_at') ? undefined : json['replied_at'],
    repliedStatus: !exists(json, 'replied_status')
      ? undefined
      : EnumChatSummaryRepliedStatusFromJSON(json['replied_status']),
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    contact: !exists(json, 'contact') ? undefined : ContactWithAssociationFromJSON(json['contact']),
    chatMessages: !exists(json, 'chat_messages')
      ? undefined
      : (json['chat_messages'] as Array<any>).map(ShallowChatMessageFromJSON)
  }
}

export function ChatSummaryToJSON(value?: ChatSummary | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    replied_at: value.repliedAt,
    replied_status: EnumChatSummaryRepliedStatusToJSON(value.repliedStatus),
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    contact: ContactWithAssociationToJSON(value.contact),
    chat_messages:
      value.chatMessages === undefined ? undefined : (value.chatMessages as Array<any>).map(ShallowChatMessageToJSON)
  }
}
