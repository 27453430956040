import { useMemo } from 'react'
import { Box } from '@material-ui/core'

import { Typography } from '../Typography'

export interface DocumentLabelItemProps {
  value: string
}

const labelColorMap: { [key in string]: string } = {
  営業資料: '#CFEDC6',
  ホワイトペーパー: '#C5E9F2',
  カタログ: '#CADFFF',
  会社案内: '#FFEDBE',
  採用資料: '#FDD5E6',
  ガイドライン: '#FFC7C7',
  'マニュアル・手順書': '#E7D3F3',
  社外秘: '#EBCDCD',
  その他: '#E3E7ED'
}

export const DocumentLabelItem = ({ value }: DocumentLabelItemProps) => {
  const color = useMemo(() => {
    return labelColorMap[value] || ''
  }, [value])

  return (
    <Box display="inline" borderRadius="4px" py="4px" px="8px" bgcolor={color} lineHeight="14px">
      <Typography fontSize="s" lineheight="14px" display="block" noWrap>
        {value}
      </Typography>
    </Box>
  )
}
