import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Download = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.6507 15.8333H25.0007V7.50001C25.0007 6.58334 24.2507 5.83334 23.334 5.83334H16.6673C15.7507 5.83334 15.0007 6.58334 15.0007 7.50001V15.8333H12.3507C10.8673 15.8333 10.1173 17.6333 11.1673 18.6833L18.8173 26.3333C19.4673 26.9833 20.5173 26.9833 21.1673 26.3333L28.8173 18.6833C29.8673 17.6333 29.134 15.8333 27.6507 15.8333ZM8.33398 32.5C8.33398 33.4167 9.08398 34.1667 10.0007 34.1667H30.0007C30.9173 34.1667 31.6673 33.4167 31.6673 32.5C31.6673 31.5833 30.9173 30.8333 30.0007 30.8333H10.0007C9.08398 30.8333 8.33398 31.5833 8.33398 32.5Z"
        fill="#DE1B63"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09083 0.772466C3.12078 -0.257489 4.79066 -0.257489 5.82062 0.772466L39.2272 34.1791C40.2572 35.209 40.2572 36.8789 39.2272 37.9089C38.1973 38.9388 36.5274 38.9388 35.4974 37.9089L2.09083 4.50226C1.06087 3.47231 1.06087 1.80242 2.09083 0.772466Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.643721 2.84154C1.50202 1.98324 2.89359 1.98324 3.75188 2.84154L37.1585 36.2481C38.0168 37.1064 38.0168 38.498 37.1585 39.3563C36.3002 40.2146 34.9086 40.2146 34.0503 39.3563L0.643721 5.9497C-0.214574 5.0914 -0.214574 3.69983 0.643721 2.84154Z"
        fill="#DE1B63"
      />
    </SvgIcon>
  )
}

export default Download
