/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  ArchiveSitesRequest,
  ArchiveSitesRequestFromJSON,
  ArchiveSitesRequestToJSON,
  ArchiveSitesResponse,
  ArchiveSitesResponseFromJSON,
  ArchiveSitesResponseToJSON,
  CopySiteRequest,
  CopySiteRequestFromJSON,
  CopySiteRequestToJSON,
  CopySiteResponse,
  CopySiteResponseFromJSON,
  CopySiteResponseToJSON,
  CreateSiteRequest,
  CreateSiteRequestFromJSON,
  CreateSiteRequestToJSON,
  CreateSiteResponse,
  CreateSiteResponseFromJSON,
  CreateSiteResponseToJSON,
  CreateSiteSectionDocumentRequest,
  CreateSiteSectionDocumentRequestFromJSON,
  CreateSiteSectionDocumentRequestToJSON,
  CreateSiteSectionDocumentResponse,
  CreateSiteSectionDocumentResponseFromJSON,
  CreateSiteSectionDocumentResponseToJSON,
  CreateSiteSectionRequest,
  CreateSiteSectionRequestFromJSON,
  CreateSiteSectionRequestToJSON,
  CreateSiteSectionResponse,
  CreateSiteSectionResponseFromJSON,
  CreateSiteSectionResponseToJSON,
  GetSiteContactsResponse,
  GetSiteContactsResponseFromJSON,
  GetSiteContactsResponseToJSON,
  GetSiteDocumentsResponse,
  GetSiteDocumentsResponseFromJSON,
  GetSiteDocumentsResponseToJSON,
  GetSiteMiscSettingResponse,
  GetSiteMiscSettingResponseFromJSON,
  GetSiteMiscSettingResponseToJSON,
  GetSiteResponse,
  GetSiteResponseFromJSON,
  GetSiteResponseToJSON,
  GetSiteSectionsResponse,
  GetSiteSectionsResponseFromJSON,
  GetSiteSectionsResponseToJSON,
  GetSiteViewHistoriesResponse,
  GetSiteViewHistoriesResponseFromJSON,
  GetSiteViewHistoriesResponseToJSON,
  GetSitesResponse,
  GetSitesResponseFromJSON,
  GetSitesResponseToJSON,
  PublishSiteRequest,
  PublishSiteRequestFromJSON,
  PublishSiteRequestToJSON,
  PublishSiteResponse,
  PublishSiteResponseFromJSON,
  PublishSiteResponseToJSON,
  ShareByLinkSiteRequest,
  ShareByLinkSiteRequestFromJSON,
  ShareByLinkSiteRequestToJSON,
  ShareByLinkSiteResponse,
  ShareByLinkSiteResponseFromJSON,
  ShareByLinkSiteResponseToJSON,
  ShareToContactSiteRequest,
  ShareToContactSiteRequestFromJSON,
  ShareToContactSiteRequestToJSON,
  ShareToContactSiteResponse,
  ShareToContactSiteResponseFromJSON,
  ShareToContactSiteResponseToJSON,
  SortSiteSectionDocumentRequest,
  SortSiteSectionDocumentRequestFromJSON,
  SortSiteSectionDocumentRequestToJSON,
  SortSiteSectionRequest,
  SortSiteSectionRequestFromJSON,
  SortSiteSectionRequestToJSON,
  UpdateDefaultCoverPhotoSiteRequest,
  UpdateDefaultCoverPhotoSiteRequestFromJSON,
  UpdateDefaultCoverPhotoSiteRequestToJSON,
  UpdateDefaultCoverPhotoSiteResponse,
  UpdateDefaultCoverPhotoSiteResponseFromJSON,
  UpdateDefaultCoverPhotoSiteResponseToJSON,
  UpdateSiteContactRequest,
  UpdateSiteContactRequestFromJSON,
  UpdateSiteContactRequestToJSON,
  UpdateSiteContactResponse,
  UpdateSiteContactResponseFromJSON,
  UpdateSiteContactResponseToJSON,
  UpdateSiteMiscSettingOGPFileResponse,
  UpdateSiteMiscSettingOGPFileResponseFromJSON,
  UpdateSiteMiscSettingOGPFileResponseToJSON,
  UpdateSiteMiscSettingRequest,
  UpdateSiteMiscSettingRequestFromJSON,
  UpdateSiteMiscSettingRequestToJSON,
  UpdateSiteMiscSettingResponse,
  UpdateSiteMiscSettingResponseFromJSON,
  UpdateSiteMiscSettingResponseToJSON,
  UpdateSiteRequest,
  UpdateSiteRequestFromJSON,
  UpdateSiteRequestToJSON,
  UpdateSiteResponse,
  UpdateSiteResponseFromJSON,
  UpdateSiteResponseToJSON,
  UpdateSiteSectionDocumentRequest,
  UpdateSiteSectionDocumentRequestFromJSON,
  UpdateSiteSectionDocumentRequestToJSON,
  UpdateSiteSectionDocumentResponse,
  UpdateSiteSectionDocumentResponseFromJSON,
  UpdateSiteSectionDocumentResponseToJSON,
  UpdateSiteSectionRequest,
  UpdateSiteSectionRequestFromJSON,
  UpdateSiteSectionRequestToJSON,
  UpdateSiteSectionResponse,
  UpdateSiteSectionResponseFromJSON,
  UpdateSiteSectionResponseToJSON,
  UploadCoverPhotoSiteResponse,
  UploadCoverPhotoSiteResponseFromJSON,
  UploadCoverPhotoSiteResponseToJSON,
  UploadHeaderLogoSiteRequest,
  UploadHeaderLogoSiteRequestFromJSON,
  UploadHeaderLogoSiteRequestToJSON,
  UploadHeaderLogoSiteResponse,
  UploadHeaderLogoSiteResponseFromJSON,
  UploadHeaderLogoSiteResponseToJSON
} from '../models'

export interface UserV1SitesArchivePutRequest {
  archiveSitesRequest?: ArchiveSitesRequest
}

export interface UserV1SitesCopyPutRequest {
  copySiteRequest?: CopySiteRequest
}

export interface UserV1SitesGetRequest {
  text?: string
  isArchived?: boolean
  page?: number
  per?: number
  orderBy?: string
}

export interface UserV1SitesPostRequest {
  createSiteRequest?: CreateSiteRequest
}

export interface UserV1SitesSiteIdDefaultPhotoPutRequest {
  siteId: string
  updateDefaultCoverPhotoSiteRequest?: UpdateDefaultCoverPhotoSiteRequest
}

export interface UserV1SitesSiteIdDocumentsGetRequest {
  siteId: string
  page?: number
  per?: number
}

export interface UserV1SitesSiteIdGetRequest {
  siteId: string
}

export interface UserV1SitesSiteIdMiscSettingGetRequest {
  siteId: string
}

export interface UserV1SitesSiteIdMiscSettingOgpFilePutRequest {
  siteId: string
  file?: Blob
}

export interface UserV1SitesSiteIdMiscSettingPutRequest {
  siteId: string
  updateSiteMiscSettingRequest?: UpdateSiteMiscSettingRequest
}

export interface UserV1SitesSiteIdPublishPutRequest {
  siteId: string
  publishSiteRequest?: PublishSiteRequest
}

export interface UserV1SitesSiteIdPutRequest {
  siteId: string
  updateSiteRequest?: UpdateSiteRequest
}

export interface UserV1SitesSiteIdSectionDocumentsPostRequest {
  siteId: string
  createSiteSectionDocumentRequest?: CreateSiteSectionDocumentRequest
}

export interface UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRequest {
  siteId: string
  siteSectionDocumentId: string
}

export interface UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPutRequest {
  siteId: string
  siteSectionDocumentId: string
  updateSiteSectionDocumentRequest?: UpdateSiteSectionDocumentRequest
}

export interface UserV1SitesSiteIdSectionDocumentsSortPutRequest {
  siteId: string
  sortSiteSectionDocumentRequest?: SortSiteSectionDocumentRequest
}

export interface UserV1SitesSiteIdSectionsGetRequest {
  siteId: string
}

export interface UserV1SitesSiteIdSectionsPostRequest {
  siteId: string
  createSiteSectionRequest?: CreateSiteSectionRequest
}

export interface UserV1SitesSiteIdSectionsSiteSectionIdDeleteRequest {
  siteId: string
  siteSectionId: string
}

export interface UserV1SitesSiteIdSectionsSiteSectionIdPutRequest {
  siteId: string
  siteSectionId: string
  updateSiteSectionRequest?: UpdateSiteSectionRequest
}

export interface UserV1SitesSiteIdSectionsSortPutRequest {
  siteId: string
  sortSiteSectionRequest?: SortSiteSectionRequest
}

export interface UserV1SitesSiteIdShareByLinkPutRequest {
  siteId: string
  shareByLinkSiteRequest?: ShareByLinkSiteRequest
}

export interface UserV1SitesSiteIdShareToContactPostRequest {
  siteId: string
  shareToContactSiteRequest?: ShareToContactSiteRequest
}

export interface UserV1SitesSiteIdSiteContactsGetRequest {
  siteId: string
  page?: number
  per?: number
}

export interface UserV1SitesSiteIdSiteContactsSiteContactIdPutRequest {
  siteId: string
  siteContactId: string
  updateSiteContactRequest?: UpdateSiteContactRequest
}

export interface UserV1SitesSiteIdUploadCoverPhotoPostRequest {
  siteId: string
  file?: Blob
}

export interface UserV1SitesSiteIdUploadHeaderLogoPostRequest {
  siteId: string
  uploadHeaderLogoSiteRequest?: UploadHeaderLogoSiteRequest
}

export interface UserV1SitesSiteIdViewHistoriesGetRequest {
  siteId: string
  page?: number
  per?: number
}

/**
 *
 */
export class ApiUserV1SiteApi extends runtime.BaseAPI {
  /**
   * 資料サイトのアーカイブ設定
   * archive
   */
  async userV1SitesArchivePutRaw(
    requestParameters: UserV1SitesArchivePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ArchiveSitesResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/archive`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ArchiveSitesRequestToJSON(requestParameters.archiveSitesRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ArchiveSitesResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのアーカイブ設定
   * archive
   */
  async userV1SitesArchivePut(
    requestParameters: UserV1SitesArchivePutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ArchiveSitesResponse> {
    const response = await this.userV1SitesArchivePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのコピーを行う（コピーのみで複製は行わない）
   * copy
   */
  async userV1SitesCopyPutRaw(
    requestParameters: UserV1SitesCopyPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CopySiteResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/copy`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CopySiteRequestToJSON(requestParameters.copySiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CopySiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのコピーを行う（コピーのみで複製は行わない）
   * copy
   */
  async userV1SitesCopyPut(
    requestParameters: UserV1SitesCopyPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CopySiteResponse> {
    const response = await this.userV1SitesCopyPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトの一覧を取得する
   * index
   */
  async userV1SitesGetRaw(
    requestParameters: UserV1SitesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSitesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.text !== undefined) {
      queryParameters['text'] = requestParameters.text
    }

    if (requestParameters.isArchived !== undefined) {
      queryParameters['is_archived'] = requestParameters.isArchived
    }

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters['order_by'] = requestParameters.orderBy
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetSitesResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトの一覧を取得する
   * index
   */
  async userV1SitesGet(
    requestParameters: UserV1SitesGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSitesResponse> {
    const response = await this.userV1SitesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトの作成
   * create
   */
  async userV1SitesPostRaw(
    requestParameters: UserV1SitesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateSiteResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSiteRequestToJSON(requestParameters.createSiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトの作成
   * create
   */
  async userV1SitesPost(
    requestParameters: UserV1SitesPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateSiteResponse> {
    const response = await this.userV1SitesPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのカバー写真をデフォルトから選択したものを設定
   * default_photo
   */
  async userV1SitesSiteIdDefaultPhotoPutRaw(
    requestParameters: UserV1SitesSiteIdDefaultPhotoPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateDefaultCoverPhotoSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdDefaultPhotoPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/default_photo`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateDefaultCoverPhotoSiteRequestToJSON(requestParameters.updateDefaultCoverPhotoSiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateDefaultCoverPhotoSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのカバー写真をデフォルトから選択したものを設定
   * default_photo
   */
  async userV1SitesSiteIdDefaultPhotoPut(
    requestParameters: UserV1SitesSiteIdDefaultPhotoPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateDefaultCoverPhotoSiteResponse> {
    const response = await this.userV1SitesSiteIdDefaultPhotoPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトに関連する資料一覧を取得する
   * index
   */
  async userV1SitesSiteIdDocumentsGetRaw(
    requestParameters: UserV1SitesSiteIdDocumentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSiteDocumentsResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdDocumentsGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/documents`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetSiteDocumentsResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトに関連する資料一覧を取得する
   * index
   */
  async userV1SitesSiteIdDocumentsGet(
    requestParameters: UserV1SitesSiteIdDocumentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSiteDocumentsResponse> {
    const response = await this.userV1SitesSiteIdDocumentsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトの詳細を取得する
   * show
   */
  async userV1SitesSiteIdGetRaw(
    requestParameters: UserV1SitesSiteIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトの詳細を取得する
   * show
   */
  async userV1SitesSiteIdGet(
    requestParameters: UserV1SitesSiteIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSiteResponse> {
    const response = await this.userV1SitesSiteIdGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイト設定を取得する
   * show
   */
  async userV1SitesSiteIdMiscSettingGetRaw(
    requestParameters: UserV1SitesSiteIdMiscSettingGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSiteMiscSettingResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdMiscSettingGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/misc_setting`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetSiteMiscSettingResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイト設定を取得する
   * show
   */
  async userV1SitesSiteIdMiscSettingGet(
    requestParameters: UserV1SitesSiteIdMiscSettingGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSiteMiscSettingResponse> {
    const response = await this.userV1SitesSiteIdMiscSettingGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイト設定のOGPファイルを更新する
   * ogp_file
   */
  async userV1SitesSiteIdMiscSettingOgpFilePutRaw(
    requestParameters: UserV1SitesSiteIdMiscSettingOgpFilePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateSiteMiscSettingOGPFileResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdMiscSettingOgpFilePut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/misc_setting/ogp_file`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteMiscSettingOGPFileResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイト設定のOGPファイルを更新する
   * ogp_file
   */
  async userV1SitesSiteIdMiscSettingOgpFilePut(
    requestParameters: UserV1SitesSiteIdMiscSettingOgpFilePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateSiteMiscSettingOGPFileResponse> {
    const response = await this.userV1SitesSiteIdMiscSettingOgpFilePutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイト設定を更新する
   * update
   */
  async userV1SitesSiteIdMiscSettingPutRaw(
    requestParameters: UserV1SitesSiteIdMiscSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateSiteMiscSettingResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdMiscSettingPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/misc_setting`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSiteMiscSettingRequestToJSON(requestParameters.updateSiteMiscSettingRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteMiscSettingResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイト設定を更新する
   * update
   */
  async userV1SitesSiteIdMiscSettingPut(
    requestParameters: UserV1SitesSiteIdMiscSettingPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateSiteMiscSettingResponse> {
    const response = await this.userV1SitesSiteIdMiscSettingPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトの公開
   * publish
   */
  async userV1SitesSiteIdPublishPutRaw(
    requestParameters: UserV1SitesSiteIdPublishPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PublishSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdPublishPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/publish`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PublishSiteRequestToJSON(requestParameters.publishSiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => PublishSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトの公開
   * publish
   */
  async userV1SitesSiteIdPublishPut(
    requestParameters: UserV1SitesSiteIdPublishPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PublishSiteResponse> {
    const response = await this.userV1SitesSiteIdPublishPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトの更新
   * update
   */
  async userV1SitesSiteIdPutRaw(
    requestParameters: UserV1SitesSiteIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSiteRequestToJSON(requestParameters.updateSiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトの更新
   * update
   */
  async userV1SitesSiteIdPut(
    requestParameters: UserV1SitesSiteIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateSiteResponse> {
    const response = await this.userV1SitesSiteIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトの資料追加
   * create
   */
  async userV1SitesSiteIdSectionDocumentsPostRaw(
    requestParameters: UserV1SitesSiteIdSectionDocumentsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateSiteSectionDocumentResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/section_documents`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSiteSectionDocumentRequestToJSON(requestParameters.createSiteSectionDocumentRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateSiteSectionDocumentResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトの資料追加
   * create
   */
  async userV1SitesSiteIdSectionDocumentsPost(
    requestParameters: UserV1SitesSiteIdSectionDocumentsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateSiteSectionDocumentResponse> {
    const response = await this.userV1SitesSiteIdSectionDocumentsPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのセクションの資料の削除
   * destroy
   */
  async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRaw(
    requestParameters: UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDelete.'
      )
    }

    if (requestParameters.siteSectionDocumentId === null || requestParameters.siteSectionDocumentId === undefined) {
      throw new runtime.RequiredError(
        'siteSectionDocumentId',
        'Required parameter requestParameters.siteSectionDocumentId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/section_documents/{site_section_document_id}`
          .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
          .replace(
            `{${'site_section_document_id'}}`,
            encodeURIComponent(String(requestParameters.siteSectionDocumentId))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * 資料サイトのセクションの資料の削除
   * destroy
   */
  async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDelete(
    requestParameters: UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdDeleteRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * 資料サイトのセクションの資料の更新。section の移動も対応
   * update
   */
  async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPutRaw(
    requestParameters: UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateSiteSectionDocumentResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPut.'
      )
    }

    if (requestParameters.siteSectionDocumentId === null || requestParameters.siteSectionDocumentId === undefined) {
      throw new runtime.RequiredError(
        'siteSectionDocumentId',
        'Required parameter requestParameters.siteSectionDocumentId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/section_documents/{site_section_document_id}`
          .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
          .replace(
            `{${'site_section_document_id'}}`,
            encodeURIComponent(String(requestParameters.siteSectionDocumentId))
          ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSiteSectionDocumentRequestToJSON(requestParameters.updateSiteSectionDocumentRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteSectionDocumentResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのセクションの資料の更新。section の移動も対応
   * update
   */
  async userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPut(
    requestParameters: UserV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateSiteSectionDocumentResponse> {
    const response = await this.userV1SitesSiteIdSectionDocumentsSiteSectionDocumentIdPutRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * 資料サイトのセクションの資料のソート更新
   * sort
   */
  async userV1SitesSiteIdSectionDocumentsSortPutRaw(
    requestParameters: UserV1SitesSiteIdSectionDocumentsSortPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionDocumentsSortPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/section_documents/sort`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: SortSiteSectionDocumentRequestToJSON(requestParameters.sortSiteSectionDocumentRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * 資料サイトのセクションの資料のソート更新
   * sort
   */
  async userV1SitesSiteIdSectionDocumentsSortPut(
    requestParameters: UserV1SitesSiteIdSectionDocumentsSortPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1SitesSiteIdSectionDocumentsSortPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのセクション一覧を取得
   * index
   */
  async userV1SitesSiteIdSectionsGetRaw(
    requestParameters: UserV1SitesSiteIdSectionsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSiteSectionsResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsGet.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/sections`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetSiteSectionsResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのセクション一覧を取得
   * index
   */
  async userV1SitesSiteIdSectionsGet(
    requestParameters: UserV1SitesSiteIdSectionsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSiteSectionsResponse> {
    const response = await this.userV1SitesSiteIdSectionsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのセクションの作成
   * create
   */
  async userV1SitesSiteIdSectionsPostRaw(
    requestParameters: UserV1SitesSiteIdSectionsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreateSiteSectionResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/sections`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSiteSectionRequestToJSON(requestParameters.createSiteSectionRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => CreateSiteSectionResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのセクションの作成
   * create
   */
  async userV1SitesSiteIdSectionsPost(
    requestParameters: UserV1SitesSiteIdSectionsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreateSiteSectionResponse> {
    const response = await this.userV1SitesSiteIdSectionsPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのセクションの削除
   * destroy
   */
  async userV1SitesSiteIdSectionsSiteSectionIdDeleteRaw(
    requestParameters: UserV1SitesSiteIdSectionsSiteSectionIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdDelete.'
      )
    }

    if (requestParameters.siteSectionId === null || requestParameters.siteSectionId === undefined) {
      throw new runtime.RequiredError(
        'siteSectionId',
        'Required parameter requestParameters.siteSectionId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/sections/{site_section_id}`
          .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
          .replace(`{${'site_section_id'}}`, encodeURIComponent(String(requestParameters.siteSectionId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * 資料サイトのセクションの削除
   * destroy
   */
  async userV1SitesSiteIdSectionsSiteSectionIdDelete(
    requestParameters: UserV1SitesSiteIdSectionsSiteSectionIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1SitesSiteIdSectionsSiteSectionIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのセクションの更新
   * update
   */
  async userV1SitesSiteIdSectionsSiteSectionIdPutRaw(
    requestParameters: UserV1SitesSiteIdSectionsSiteSectionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateSiteSectionResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdPut.'
      )
    }

    if (requestParameters.siteSectionId === null || requestParameters.siteSectionId === undefined) {
      throw new runtime.RequiredError(
        'siteSectionId',
        'Required parameter requestParameters.siteSectionId was null or undefined when calling userV1SitesSiteIdSectionsSiteSectionIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/sections/{site_section_id}`
          .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
          .replace(`{${'site_section_id'}}`, encodeURIComponent(String(requestParameters.siteSectionId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSiteSectionRequestToJSON(requestParameters.updateSiteSectionRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteSectionResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのセクションの更新
   * update
   */
  async userV1SitesSiteIdSectionsSiteSectionIdPut(
    requestParameters: UserV1SitesSiteIdSectionsSiteSectionIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateSiteSectionResponse> {
    const response = await this.userV1SitesSiteIdSectionsSiteSectionIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのセクションのソート更新
   * sort
   */
  async userV1SitesSiteIdSectionsSortPutRaw(
    requestParameters: UserV1SitesSiteIdSectionsSortPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSectionsSortPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/sections/sort`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: SortSiteSectionRequestToJSON(requestParameters.sortSiteSectionRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   * 資料サイトのセクションのソート更新
   * sort
   */
  async userV1SitesSiteIdSectionsSortPut(
    requestParameters: UserV1SitesSiteIdSectionsSortPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1SitesSiteIdSectionsSortPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトをリンクからの共有設定
   * share_by_link
   */
  async userV1SitesSiteIdShareByLinkPutRaw(
    requestParameters: UserV1SitesSiteIdShareByLinkPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ShareByLinkSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdShareByLinkPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/share_by_link`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ShareByLinkSiteRequestToJSON(requestParameters.shareByLinkSiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ShareByLinkSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトをリンクからの共有設定
   * share_by_link
   */
  async userV1SitesSiteIdShareByLinkPut(
    requestParameters: UserV1SitesSiteIdShareByLinkPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ShareByLinkSiteResponse> {
    const response = await this.userV1SitesSiteIdShareByLinkPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトをcontactを指定しての共有設定
   * share_to_contact
   */
  async userV1SitesSiteIdShareToContactPostRaw(
    requestParameters: UserV1SitesSiteIdShareToContactPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ShareToContactSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdShareToContactPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/share_to_contact`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ShareToContactSiteRequestToJSON(requestParameters.shareToContactSiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => ShareToContactSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトをcontactを指定しての共有設定
   * share_to_contact
   */
  async userV1SitesSiteIdShareToContactPost(
    requestParameters: UserV1SitesSiteIdShareToContactPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ShareToContactSiteResponse> {
    const response = await this.userV1SitesSiteIdShareToContactPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのコンタクト一覧取得
   * index
   */
  async userV1SitesSiteIdSiteContactsGetRaw(
    requestParameters: UserV1SitesSiteIdSiteContactsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSiteContactsResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSiteContactsGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/site_contacts`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetSiteContactsResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのコンタクト一覧取得
   * index
   */
  async userV1SitesSiteIdSiteContactsGet(
    requestParameters: UserV1SitesSiteIdSiteContactsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSiteContactsResponse> {
    const response = await this.userV1SitesSiteIdSiteContactsGetRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのコンタクトの編集
   * update
   */
  async userV1SitesSiteIdSiteContactsSiteContactIdPutRaw(
    requestParameters: UserV1SitesSiteIdSiteContactsSiteContactIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateSiteContactResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdSiteContactsSiteContactIdPut.'
      )
    }

    if (requestParameters.siteContactId === null || requestParameters.siteContactId === undefined) {
      throw new runtime.RequiredError(
        'siteContactId',
        'Required parameter requestParameters.siteContactId was null or undefined when calling userV1SitesSiteIdSiteContactsSiteContactIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/site_contacts/{site_contact_id}`
          .replace(`{${'site_id'}}`, encodeURIComponent(String(requestParameters.siteId)))
          .replace(`{${'site_contact_id'}}`, encodeURIComponent(String(requestParameters.siteContactId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateSiteContactRequestToJSON(requestParameters.updateSiteContactRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateSiteContactResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのコンタクトの編集
   * update
   */
  async userV1SitesSiteIdSiteContactsSiteContactIdPut(
    requestParameters: UserV1SitesSiteIdSiteContactsSiteContactIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateSiteContactResponse> {
    const response = await this.userV1SitesSiteIdSiteContactsSiteContactIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのカバー写真の更新
   * upload_cover_photo
   */
  async userV1SitesSiteIdUploadCoverPhotoPostRaw(
    requestParameters: UserV1SitesSiteIdUploadCoverPhotoPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UploadCoverPhotoSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdUploadCoverPhotoPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes)

    let formParams: { append(param: string, value: any): any }
    let useForm = false
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm
    if (useForm) {
      formParams = new FormData()
    } else {
      formParams = new URLSearchParams()
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any)
    }

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/upload_cover_photo`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UploadCoverPhotoSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのカバー写真の更新
   * upload_cover_photo
   */
  async userV1SitesSiteIdUploadCoverPhotoPost(
    requestParameters: UserV1SitesSiteIdUploadCoverPhotoPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UploadCoverPhotoSiteResponse> {
    const response = await this.userV1SitesSiteIdUploadCoverPhotoPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイトのヘッダーロゴの更新（※現在は使われていない）
   * upload_header_logo
   */
  async userV1SitesSiteIdUploadHeaderLogoPostRaw(
    requestParameters: UserV1SitesSiteIdUploadHeaderLogoPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UploadHeaderLogoSiteResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdUploadHeaderLogoPost.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/upload_header_logo`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UploadHeaderLogoSiteRequestToJSON(requestParameters.uploadHeaderLogoSiteRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UploadHeaderLogoSiteResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイトのヘッダーロゴの更新（※現在は使われていない）
   * upload_header_logo
   */
  async userV1SitesSiteIdUploadHeaderLogoPost(
    requestParameters: UserV1SitesSiteIdUploadHeaderLogoPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UploadHeaderLogoSiteResponse> {
    const response = await this.userV1SitesSiteIdUploadHeaderLogoPostRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * 資料サイト閲覧履歴一覧を取得する
   * index
   */
  async userV1SitesSiteIdViewHistoriesGetRaw(
    requestParameters: UserV1SitesSiteIdViewHistoriesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GetSiteViewHistoriesResponse>> {
    if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
      throw new runtime.RequiredError(
        'siteId',
        'Required parameter requestParameters.siteId was null or undefined when calling userV1SitesSiteIdViewHistoriesGet.'
      )
    }

    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.per !== undefined) {
      queryParameters['per'] = requestParameters.per
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/sites/{site_id}/view_histories`.replace(
          `{${'site_id'}}`,
          encodeURIComponent(String(requestParameters.siteId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => GetSiteViewHistoriesResponseFromJSON(jsonValue))
  }

  /**
   * 資料サイト閲覧履歴一覧を取得する
   * index
   */
  async userV1SitesSiteIdViewHistoriesGet(
    requestParameters: UserV1SitesSiteIdViewHistoriesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GetSiteViewHistoriesResponse> {
    const response = await this.userV1SitesSiteIdViewHistoriesGetRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
