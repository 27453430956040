/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  PurgeUserAndMailTemplateOrganizationResponseData,
  PurgeUserAndMailTemplateOrganizationResponseDataFromJSON,
  PurgeUserAndMailTemplateOrganizationResponseDataFromJSONTyped,
  PurgeUserAndMailTemplateOrganizationResponseDataToJSON
} from './PurgeUserAndMailTemplateOrganizationResponseData'

/**
 *
 * @export
 * @interface PurgeUserAndMailTemplateOrganizationResponse
 */
export interface PurgeUserAndMailTemplateOrganizationResponse {
  /**
   *
   * @type {number}
   * @memberof PurgeUserAndMailTemplateOrganizationResponse
   */
  status?: number
  /**
   *
   * @type {PurgeUserAndMailTemplateOrganizationResponseData}
   * @memberof PurgeUserAndMailTemplateOrganizationResponse
   */
  data?: PurgeUserAndMailTemplateOrganizationResponseData
}

export function PurgeUserAndMailTemplateOrganizationResponseFromJSON(
  json: any
): PurgeUserAndMailTemplateOrganizationResponse {
  return PurgeUserAndMailTemplateOrganizationResponseFromJSONTyped(json, false)
}

export function PurgeUserAndMailTemplateOrganizationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PurgeUserAndMailTemplateOrganizationResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : PurgeUserAndMailTemplateOrganizationResponseDataFromJSON(json['data'])
  }
}

export function PurgeUserAndMailTemplateOrganizationResponseToJSON(
  value?: PurgeUserAndMailTemplateOrganizationResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: PurgeUserAndMailTemplateOrganizationResponseDataToJSON(value.data)
  }
}
