import React, { useState, useCallback } from 'react'
import { createStyles, makeStyles, Input, InputAdornment, IconButton, Box } from '@material-ui/core'
import { DynamicMuiIcon } from 'src/components/atoms'
import { Colors, inputStatusStyles } from '../Colors'
import { Typography } from '../Typography'

export interface PasswordProps {
  password: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  onBlur?: (event: React.FocusEvent<Element>) => void
  required?: boolean
  error?: string
  helperText?: string
  width?: number | string
  fullWidth?: boolean
  height?: 'large' | 'medium'
  fontSize?: 'm' | 's'
}

const getStatusStyle = ({ error, helperText }: PasswordProps) => {
  if (error) return inputStatusStyles.error
  if (helperText) inputStatusStyles.success
  return inputStatusStyles.success
}

const useStyles = makeStyles(() =>
  createStyles({
    inputButtonRoot: {
      fontSize: '16px',
      '&.MuiIconButton-root:hover': {
        background: 'transparent'
      }
    },
    inputRoot: {
      width: ({ width }: PasswordProps) => width ?? '100%',
      height: ({ height = 'medium' }: PasswordProps) => {
        switch (height) {
          case 'large':
            return '40px'
          case 'medium':
            return '34px'
          default:
            return '34px'
        }
      },
      fontSize: ({ fontSize = 's' }: PasswordProps) => {
        switch (fontSize) {
          case 'm':
            return '16px'
          case 's':
            return '14px'
          default:
            return ''
        }
      },
      '&.MuiInput-root': {
        borderColor: '#ddd',
        boxShadow: 'inset 0px 1px 2px rgb(10 10 10 / 10%)',
        '&:hover': {
          borderColor: inputStatusStyles.hover.borderColor
        },
        '&.Mui-error': {
          ...inputStatusStyles.error
        },
        // フォーカスされた時
        '&.Mui-focused': {
          borderColor: (props: PasswordProps) => getStatusStyle(props).borderColor,
          '&:hover': {
            borderColor: (props: PasswordProps) => getStatusStyle(props).borderColor
          }
        }
      },
      // helper text
      '& .MuiFormHelperText-root': {
        bottom: '-28px',
        wordBreak: 'keep-all',
        fontSize: '14px'
      }
    }
  })
)

export const Password = (props: PasswordProps) => {
  const { fullWidth = false, password, onChange, name, onBlur, required = false, error = undefined } = props
  const classes = useStyles(props)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const onClickShowOrNot = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])
  const mouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <Box width={fullWidth ? '100%' : props.width || '260px'}>
      <Input
        className={classes.inputRoot}
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        error={Boolean(error)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              classes={{ root: classes.inputButtonRoot }}
              disableRipple
              aria-label="toggle password visibility"
              onClick={onClickShowOrNot}
              onMouseDown={mouseDownPassword}
            >
              {showPassword ? (
                <DynamicMuiIcon variant="visibility" color="inherit" fontSize="inherit" />
              ) : (
                <DynamicMuiIcon variant="visibilityOff" color="inherit" fontSize="inherit" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && (
        <Box color={Colors.error.default}>
          <Typography variant="caption" fontSize="xs" style={{ color: Colors.error.default }}>
            {error}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
