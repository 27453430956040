/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationChatSettingResponseData,
  GetOrganizationChatSettingResponseDataFromJSON,
  GetOrganizationChatSettingResponseDataFromJSONTyped,
  GetOrganizationChatSettingResponseDataToJSON
} from './GetOrganizationChatSettingResponseData'

/**
 *
 * @export
 * @interface UpdateOrganizationChatSettingResponse
 */
export interface UpdateOrganizationChatSettingResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateOrganizationChatSettingResponse
   */
  status?: number
  /**
   *
   * @type {GetOrganizationChatSettingResponseData}
   * @memberof UpdateOrganizationChatSettingResponse
   */
  data?: GetOrganizationChatSettingResponseData
}

export function UpdateOrganizationChatSettingResponseFromJSON(json: any): UpdateOrganizationChatSettingResponse {
  return UpdateOrganizationChatSettingResponseFromJSONTyped(json, false)
}

export function UpdateOrganizationChatSettingResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationChatSettingResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : GetOrganizationChatSettingResponseDataFromJSON(json['data'])
  }
}

export function UpdateOrganizationChatSettingResponseToJSON(value?: UpdateOrganizationChatSettingResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationChatSettingResponseDataToJSON(value.data)
  }
}
