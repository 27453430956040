/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function CreateMailTemplateRequestMailTemplateFromJSON(json) {
    return CreateMailTemplateRequestMailTemplateFromJSONTyped(json, false);
}
export function CreateMailTemplateRequestMailTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: json['name'],
        subject: json['subject'],
        body: json['body']
    };
}
export function CreateMailTemplateRequestMailTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        subject: value.subject,
        body: value.body
    };
}
