/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function UpdateMePasswordRequestUserFromJSON(json) {
    return UpdateMePasswordRequestUserFromJSONTyped(json, false);
}
export function UpdateMePasswordRequestUserFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        password: json['password'],
        passwordConfirmation: json['password_confirmation']
    };
}
export function UpdateMePasswordRequestUserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        password: value.password,
        password_confirmation: value.passwordConfirmation
    };
}
