import { Avatar, Box, ButtonBase, makeStyles, createStyles } from '@material-ui/core'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import {
  Colors,
  DynamicMuiIcon,
  Input,
  Pallete,
  SectionCard,
  SelectBox,
  Tooltip,
  Typography
} from 'src/components/atoms'
import { useGetDocument, useUpdateDocumentMessage } from 'src/fixtures/modules/documents/hooks'
import { SettingToggleSectionCard } from '..'

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      height: 36,
      width: 36
    }
  })
)

export interface DocumentSettingProps {
  onClose: () => void
}

export const DocumentSettingMessage = ({ onClose }: DocumentSettingProps) => {
  const classes = useStyles()
  const { query } = useRouter()
  const { data } = useGetDocument(String(query.documentId))
  const { handleUpdateDocumentMessage } = useUpdateDocumentMessage(String(query.documentId))
  const documentMessage = data?.documentMessage

  const onEnableMessage = useCallback(() => {
    handleUpdateDocumentMessage({
      documentMessage: { isView: documentMessage?.isView !== undefined ? !documentMessage?.isView : false }
    })
  }, [handleUpdateDocumentMessage, documentMessage])

  return (
    <>
      <Box mb="36px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color={Colors.base.middleGray}
          pl="6px"
          pr="6px"
          mb="8px"
        >
          <Box display="flex" alignItems="center">
            <DynamicMuiIcon variant="message" size="20px" />
            <Box pl="8px" color={Colors.base.black}>
              <Typography variant="h5">自動メッセージ</Typography>
            </Box>
          </Box>
          <ButtonBase onClick={onClose}>
            <DynamicMuiIcon variant="close" size="20px" />
          </ButtonBase>
        </Box>
        <SettingToggleSectionCard
          title="資料に自動メッセージを表示"
          description="設定したタイミングで、チャット欄にメッセージを自動で表示します"
          checked={documentMessage?.isView ?? false}
          onChange={onEnableMessage}
        />
      </Box>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" color={Colors.base.middleGray} mb="8px">
          <Box pl="8px" color={Colors.base.black}>
            <Typography variant="h5">表示設定</Typography>
          </Box>
        </Box>
        <Box>
          <SectionCard>
            <Box>
              <Box display="flex" alignItems="center" mt="2px">
                <Typography variant="h6" fontSize="xs" lineheight="12px">
                  メッセージ
                </Typography>
                <Typography fontSize="xs" lineheight="12px">
                  （300文字以内）
                </Typography>
              </Box>
              <Box mt="8px" />
              <Input
                name="text"
                placeholder={`はじめまして！\nご質問やご不明な点がございましたら、チャットでお声がけください`}
                fullWidth
                maxLength={300}
                multiline
                rows={4}
                backgroundColor={Pallete.functional.background.default}
                defaultValue={documentMessage?.text || ''}
                onBlur={e => handleUpdateDocumentMessage({ documentMessage: { text: e.target.value } })}
              />
            </Box>

            <Box mt="16px">
              <Box display="flex" alignItems="center">
                <Typography variant="h6">メッセージの表示タイミング</Typography>
                <Box mr="6px" />
                <Tooltip
                  content={
                    '顧客が資料閲覧を始めた時から、特定の時間が経過すると自動でチャットにメッセージを表示します。商談やお問い合わせの機会につなげることができます。'
                  }
                >
                  <DynamicMuiIcon variant="helpRounded" color="action" size="16px" />
                </Tooltip>
              </Box>
              <Box mt="6px" />
              <SelectBox
                size="medium"
                listWidth="176px"
                value={documentMessage?.viewTimingSec ?? ''}
                placeholder="選択してください"
                optionList={[
                  {
                    value: 10,
                    label: '資料閲覧から10秒後'
                  },
                  {
                    value: 15,
                    label: '資料閲覧から15秒後'
                  },
                  {
                    value: 30,
                    label: '資料閲覧から30秒後'
                  },
                  {
                    value: 60,
                    label: '資料閲覧から60秒後'
                  }
                ]}
                onChange={e =>
                  handleUpdateDocumentMessage({ documentMessage: { viewTimingSec: Number(e.target.value) } })
                }
              />
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mt="28px" mb="6px">
              <Typography fontSize="xs" letterSpacing="none" fontWeight="bold" color="textSecondary">
                表示プレビュー
              </Typography>
            </Box>

            <Box p="12px" bgcolor={Colors.background.gray} display="flex" borderRadius="6px">
              <Avatar src={data?.user?.avatar.url} className={classes.avatar} />
              <Box
                p="10px"
                ml="10px"
                width="100%"
                bgcolor={Pallete.functional.background.default}
                border="1px solid #DADCE0"
                borderRadius="10px"
                whiteSpace="pre-wrap"
                lineHeight="132%"
              >
                {documentMessage?.text}
              </Box>
            </Box>
          </SectionCard>
        </Box>
      </Box>
    </>
  )
}
