import React, { useCallback, useState } from 'react'
import { createStyles, makeStyles, Box, Modal, MobileStepper, Slide } from '@material-ui/core'
import { Button, Colors, DynamicMuiIcon, Pallete, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import SwipeableViews from 'react-swipeable-views'

interface ModalOnBoardingProps {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      height: 225
    },
    stepperRoot: {
      position: 'relative',
      backgroundColor: Colors.background.lightGray,
      borderRadius: '0 0 10px 10px',
      padding: '8px 24px',
      '& > .MuiMobileStepper-dots': {
        position: 'absolute',
        top: '-48px',
        left: '42%',
        '& > .MuiMobileStepper-dot': {
          width: '6px',
          height: '6px',
          margin: '0 5px'
        }
      }
    }
  })
)

const steps = [
  {
    imageUrl: '/images/onBoarding/01.svg',
    label: 'クラウドで顧客獲得はじめよう',
    description:
      'ｎｏｃｏは 資料を共有するだけで\n資料の閲覧追跡・顧客管理・コミュニケーションまで行える\nセールスイネーブルメントツールです'
  },
  {
    imageUrl: '/images/onBoarding/02.svg',
    label: '資料を アップロードするだけ',
    description:
      'PDFファイルをアップロードするだけで\n 資料をオンライン化\n資料を共有したお客様の閲覧状況がひと目でわかります'
  },
  {
    imageUrl: '/images/onBoarding/03.svg',
    label: '顧客管理がひとつに集約',
    description:
      '会社情報やお客様の連絡先はもちろん\nお客様の状態管理やコミュニケーションの履歴まで\nあらゆる情報を まとめて一括管理できます'
  },
  {
    imageUrl: '/images/onBoarding/04.svg',
    label: '営業もチャットで完結',
    description:
      'nocoなら 共有した資料上で\nチャットによるコミュニケーションを実現\n資料が開いた状態でお客様へ営業できます'
  },
  {
    imageUrl: '/images/onBoarding/05.svg',
    label: '複数の資料も まとめて共有',
    description: '複数の資料を ひとつのサイトとして\nまとめて共有できるので\nお客さまへ効率的なご提案ができます'
  },
  {
    imageUrl: '/images/onBoarding/06.svg',
    label: '仲間と一緒に 営業を自動化しよう！',
    description:
      '有料プランなら 複数人のメンバーでご利用いただけます\nチームの仲間を招待して、営業活動をはじめましょう！'
  }
]

export const ModalOnBoarding = ({ open, onClose }: ModalOnBoardingProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = steps.length

  const handleNext = useCallback(() => {
    if (activeStep === maxSteps - 1) {
      onClose()
      setActiveStep(0)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }, [activeStep, maxSteps, onClose])

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }, [])

  const handleStepChange = useCallback((step: number) => {
    setActiveStep(step)
  }, [])

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          width="580px"
          height="470px"
          position="relative"
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.gray}`}
          pt="32px"
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" px="24px">
            <Box color={Colors.base.middleGray} mt="32px" className={globalClasses.cursorPointer} onClick={handleBack}>
              {activeStep === 0 ? (
                <Box width="28px" />
              ) : (
                <DynamicMuiIcon variant="arrowLeft" size="28px" color="inherit" />
              )}
            </Box>
            <Box>
              {/* <Paper square elevation={0} className={classes.paper}>
              <Box display="flex" justifyContent="center" width={1} height="228px">
                <img src={steps[activeStep].imageUrl} className={globalClasses.imgContain} />
              </Box>
            </Paper> */}

              <SwipeableViews axis="x" index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                {steps.map((step, index) => (
                  <Box key={index} width={1} maxWidth="580px" textAlign="center" color={Colors.base.black}>
                    <Box display="flex" justifyContent="center" width={1} height="228px" mb="18px">
                      <img src={step.imageUrl} className={globalClasses.imgContain} />
                    </Box>
                    <Typography fontSize="l" fontWeight="bold" lineheight="27px" letterSpacing="default">
                      {step.label}
                    </Typography>
                    <Box height="77px" whiteSpace="pre-wrap" mt="10px">
                      <Typography fontSize="s" lineheight="23.8px" letterSpacing="tight">
                        {step.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </SwipeableViews>
            </Box>
            <Box color={Colors.base.middleGray} mt="32px" className={globalClasses.cursorPointer} onClick={handleNext}>
              <DynamicMuiIcon variant="arrowRight" size="28px" color="inherit" />
            </Box>
          </Box>
          <Box height="110px">
            <Box height="60px" borderBottom={`1px solid ${Colors.background.gray}`} />
            <MobileStepper
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button kind="primary" onClick={handleNext} title={activeStep === maxSteps - 1 ? '開始する' : '次へ'} />
              }
              className={classes.stepperRoot}
              backButton={<Box width="60px" />}
            />
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
