/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  BillingInvoice,
  BillingInvoiceFromJSON,
  BillingInvoiceFromJSONTyped,
  BillingInvoiceToJSON
} from './BillingInvoice'
import {
  ScheduleBillingContract,
  ScheduleBillingContractFromJSON,
  ScheduleBillingContractFromJSONTyped,
  ScheduleBillingContractToJSON
} from './ScheduleBillingContract'

/**
 *
 * @export
 * @interface CreateOrganizationPaymentScheduleBillingInvoiceResponseData
 */
export interface CreateOrganizationPaymentScheduleBillingInvoiceResponseData {
  /**
   *
   * @type {ScheduleBillingContract}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceResponseData
   */
  scheduleBillingContract: ScheduleBillingContract
  /**
   *
   * @type {BillingInvoice}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceResponseData
   */
  scheduleBillingInvoice: BillingInvoice
  /**
   *
   * @type {BillingInvoice}
   * @memberof CreateOrganizationPaymentScheduleBillingInvoiceResponseData
   */
  scheduleBillingNextInvoice: BillingInvoice
}

export function CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSON(
  json: any
): CreateOrganizationPaymentScheduleBillingInvoiceResponseData {
  return CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSONTyped(json, false)
}

export function CreateOrganizationPaymentScheduleBillingInvoiceResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateOrganizationPaymentScheduleBillingInvoiceResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    scheduleBillingContract: ScheduleBillingContractFromJSON(json['schedule_billing_contract']),
    scheduleBillingInvoice: BillingInvoiceFromJSON(json['schedule_billing_invoice']),
    scheduleBillingNextInvoice: BillingInvoiceFromJSON(json['schedule_billing_next_invoice'])
  }
}

export function CreateOrganizationPaymentScheduleBillingInvoiceResponseDataToJSON(
  value?: CreateOrganizationPaymentScheduleBillingInvoiceResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    schedule_billing_contract: ScheduleBillingContractToJSON(value.scheduleBillingContract),
    schedule_billing_invoice: BillingInvoiceToJSON(value.scheduleBillingInvoice),
    schedule_billing_next_invoice: BillingInvoiceToJSON(value.scheduleBillingNextInvoice)
  }
}
