import { Box } from '@material-ui/core'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Colors, Chip, Id } from 'src/components/atoms'
import { OptionData, Popover } from 'src/components/molecules'

export interface SelectChipProps {
  value: string[]
  optionList: OptionData[]
  onChange?: (selectedList: string[]) => void
  onChangeKeyword?: (keyword: string) => void
  startIcon?: JSX.Element
}

export interface LabelProps {
  id: string
  label: string
}

export const SelectChip = ({ value, optionList, onChange, onChangeKeyword, startIcon }: SelectChipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [selectedItemIdList, setSelectedItemIdList] = useState<string[]>(value)
  const [selectedLabelList, setSelectedLabelList] = useState<LabelProps[]>([])

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    onChangeKeyword?.('')
  }, [onChangeKeyword])

  const getLabel = useCallback(
    (id: string) => {
      const item = optionList.find(option => option.value === id) || ''
      return item && item.label
    },
    [optionList]
  )

  const handleSelect = useCallback(
    ({ value }: OptionData) => {
      const label = getLabel(value)
      const newSelectedLabelList = [...selectedLabelList, { id: value, label }]
      setSelectedLabelList(newSelectedLabelList)

      const newSelectedItemIdList = [...selectedItemIdList, value]
      setSelectedItemIdList(newSelectedItemIdList)
      handleClose()
      onChange?.(newSelectedItemIdList)
    },
    [getLabel, handleClose, onChange, selectedItemIdList, selectedLabelList]
  )

  const handleCancel = useCallback(
    (id: Id) => {
      const newSelectedLabelList = selectedLabelList.filter(item => item.id !== id)
      setSelectedLabelList(newSelectedLabelList)

      const newSelectedItemIdList = selectedItemIdList.filter(item => item !== id)
      setSelectedItemIdList(newSelectedItemIdList)
      onChange?.(newSelectedItemIdList)
    },
    [onChange, selectedItemIdList, selectedLabelList]
  )

  const filteredOptiions = useMemo(() => {
    return optionList.filter(option => !selectedItemIdList.some(user => user === option.value))
  }, [optionList, selectedItemIdList])

  const ShowSelectedList = useMemo(() => {
    return selectedLabelList?.map(list => {
      return <Chip key={list.id} id={list.id} onClose={handleCancel} label={list.label} stopPropagation={true} />
    })
  }, [handleCancel, selectedLabelList])

  /* eslint-disable react-hooks/exhaustive-deps */
  let defaultLabelList: LabelProps[] = []
  useEffect(() => {
    if (value.length === 0) {
      setSelectedLabelList([])
    } else {
      value.map(v => {
        const label = getLabel(v)
        defaultLabelList.push({ id: v, label: label })
      })
      setSelectedLabelList(defaultLabelList)
    }
  }, [])
  /* eslint-enable */

  return (
    <>
      <Box
        minHeight="34px"
        border={`1px solid ${Colors.background.silver}`}
        borderRadius="4px"
        padding="3px 4px 3px 14px"
        display="flex"
        alignItems="flex-start"
        onClick={handleClick}
      >
        {/* HACK: Chip Compoent の margin に依存しているので注意 */}
        {startIcon && <Box sx={{ my: '3px', lineHeight: '0' }}>{startIcon}</Box>}
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>{ShowSelectedList}</Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onChangeKeyword={onChangeKeyword}
        onSelect={handleSelect}
        options={filteredOptiions}
      />
    </>
  )
}
