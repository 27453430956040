import { Box, ButtonBase, createStyles, makeStyles, Popover, TextareaAutosize } from '@material-ui/core'
import { Document } from '@noco/http-client/lib/noco'
import React, { useState, useCallback, useMemo, useRef, FormEvent, useEffect } from 'react'
import Image from 'next/image'
import {
  Input,
  DynamicMuiIcon,
  Colors,
  SelectBox,
  Chip,
  OptionListType,
  Typography,
  Id,
  Toaster,
  Tooltip
} from 'src/components/atoms'
import { AddressSearchBox, SelectorItemWithType } from 'src/components/organisms/AddressSearchBox'
import { useShareDocumentToContact } from 'src/fixtures/modules/documents/hooks'
import { format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import { OptionData, SelectDatePicker, SendEmailContents } from 'src/components/molecules'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import { SelectedtSiteOrDocument, SelectSiteOrDocument } from 'src/components/organisms'
import { useShareSiteToContact } from 'src/fixtures/modules/site/hooks'
import { useListSelectorContacts } from 'src/fixtures/modules/selector/hooks'
import { ModalConfirmToPaidPlan } from '../..'
import { useGetOrganizationPaymentCurrentPlan } from 'src/fixtures/modules/organizationPayment/hooks'
import { LinkIcon } from 'src/components/atoms/LinkIcon'
import { LocalStorageMaterialDraft } from 'src/fixtures/utils/localStorageMaterialDraft'
import { useConfirmDiscard } from 'src/fixtures/utils/url'
import { useListTemplates } from 'src/fixtures/modules/template/hooks'
import { FormSubmitter } from '../hooks'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'

export interface DocumentShareWithEmailProps {
  defaultDocument?: Document
  defaultContactId?: string
  formSubmitter?: FormSubmitter
  isSubmitting: boolean
  isSubmitEnd: boolean
  onSubmit?: () => void
  onError?: () => void
  onSuccess?: () => void
  onChangeShowConfirm?: (showConfirm: boolean) => void
  setIsSubmitting: (isSubmitting: boolean) => void
  setIsSubmitEnd: (isSubmitEnd: boolean) => void
  isSending: boolean
  setIsSending: (isSending: boolean) => void
  progressCompleted: boolean
  setProgressCompleted: (progressCompleted: boolean) => void
  setSubmitStatus: (submitStatus: boolean) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    customBox: {
      border: `1px solid ${Colors.accent.keyPurple.default}`
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    paper: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    },
    wordKeep: {
      wordBreak: 'keep-all'
    },
    textarea: {
      overflow: 'hidden auto !important',
      resize: 'vertical',
      width: '100%',
      border: 'none',
      paddingTop: '16px',
      color: Colors.base.black,
      fontSize: '14px',
      lineHeight: '18,48px',
      letterSpacing: '4%',
      marginBottom: '6px',
      '&:focus': {
        outline: 'none'
      },
      '&::placeholder': {
        fontFamily: 'Hiragino Kaku Gothic Pro',
        color: Colors.base.placeHolder,
        fontWeight: 300
      }
    },
    customLink: {
      display: 'inline-flex',
      alignItems: 'center',
      border: `1px solid ${Colors.background.gray}`,
      padding: '8px',
      borderRadius: '6px',
      textDecoration: 'none'
    },
    sendModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    modalPaper: {
      width: 600,
      height: 150,
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 1px 10px #222B35'
    },
    mailIcon: {
      position: 'absolute',
      top: '82.17px',
      left: 0,
      right: 0,
      marginBottom: '22.67px',
      height: '27px'
    },
    sendingText: {
      fontSize: '14px',
      letterSpacing: '0.08em',
      fontWeight: 300
    },
    sendingContents: {
      minWidth: '247px',
      maxWidth: '274px',
      margin: '0 auto',
      position: 'absolute',
      left: 0,
      right: 0,
      top: '131.5px'
    },
    linearProgress: {
      width: '220px',
      margin: '16px auto 0 auto'
    }
  })
})

const optionAuthList: OptionListType[] = [
  {
    value: 'unexpiredViewable',
    label: '閲覧可（期限なし）'
  },
  {
    value: 'expiredViewable',
    label: '閲覧可（期限あり）'
  }
]
export const DocumentShareWithEmail = React.forwardRef<HTMLButtonElement, DocumentShareWithEmailProps>(function Select(
  {
    defaultDocument,
    defaultContactId,
    formSubmitter,
    isSubmitting,
    isSubmitEnd,
    onSubmit,
    setIsSubmitting,
    setIsSubmitEnd,
    onError,
    onSuccess,
    onChangeShowConfirm,
    isSending,
    setIsSending,
    progressCompleted,
    setProgressCompleted,
    setSubmitStatus
  },
  ref
) {
  const classes = useStyles()
  const { data: me } = useGetMe()
  const { data: defaultContact } = useListSelectorContacts({ id: defaultContactId })
  const [contactList, setContactList] = useState<SelectorItemWithType[]>([])
  const addressBoxRef = useRef<HTMLElement>(null)
  const [openAddressSearchBox, setOpenAddressSearchbox] = useState(false)

  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)
  const { data: planGrade } = useGetOrganizationPaymentCurrentPlan()
  const [userId, setUserId] = useState('')
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('')
  const [authValue, setAuthValue] = useState('unexpiredViewable')
  const [expiredValue, setExpiredValue] = useState<string | null>(null)
  const [selectedTemplateValue, setSelectedTemplateValue] = useState('')
  const { data: templateList } = useListTemplates()
  const [showConfirm, setShowConfirm] = useState(false)
  const [isError, setIsError] = useState<Error | undefined>(undefined) // * 送信可能かどうかを判断する

  // * 送信可能かどうかを判断する
  useEffect(() => {
    if (contactList.length >= 1 && subject !== '' && body !== '') {
      setSubmitStatus(true)
    } else {
      setSubmitStatus(false)
    }
  }, [body, contactList, setIsSubmitting, setSubmitStatus, subject])
  // 送信をしなかった場合に画面を離れようとした時の破棄確認
  useConfirmDiscard(showConfirm)
  // 送信中の破棄確認
  useConfirmDiscard(isSending, '資料サイトのメールを送信中です。画面を離れますか？')

  // 共有する資料
  const [selectedtSiteOrDocument, setSelectedtSiteOrDocument] = useState<SelectedtSiteOrDocument | undefined>(
    defaultDocument && defaultDocument.id && defaultDocument.title
      ? {
          value: defaultDocument.id,
          label: defaultDocument.title,
          thumbnailUrl: defaultDocument?.thumbnail?.url || undefined,
          kind: 'document'
        }
      : undefined
  )
  const [selectedId, setSelectedId] = useState<string | undefined>(defaultDocument?.id)

  const localStorageMaterialDraft = useMemo(() => {
    return typeof window !== 'undefined' && me ? new LocalStorageMaterialDraft(me?.user?.id!) : undefined
  }, [me])

  const handleResetForm = useCallback(() => {
    setBody('')
    setSubject('')
    setAuthValue('unexpiredViewable')
    setExpiredValue(null)
    setSelectedTemplateValue('')
    if (defaultContactId && defaultContact) {
      const defaultContactWithType: SelectorItemWithType = {
        id: defaultContact.selectorItems?.[0].id,
        text: defaultContact.selectorItems?.[0].text,
        type: 'contact',
        email: defaultContact?.selectorItems?.[0].extend?.encryptedEmail
      }
      setContactList([defaultContactWithType])
    } else {
      setContactList([])
    }
    setShowConfirm(false)
  }, [defaultContact, defaultContactId])

  const { handleShareSiteToContact } = useShareSiteToContact('', () => {
    handleResetForm()
    onSuccess?.()
    setIsSubmitting(false)
    setIsSubmitEnd(true)
  })

  const { handleShareDocumentToContact, isLoading, error } = useShareDocumentToContact(
    defaultDocument?.id || '',
    () => {
      handleResetForm()
      onSuccess?.()
    }
  )

  // * 送信エラー時のstate変更
  useEffect(() => {
    if (error === undefined) return
    setIsError(error)
    setIsSubmitting(false)
    setIsSending(false)
    setProgressCompleted(true)
  }, [error, setIsSending, setIsSubmitting, setProgressCompleted])

  const handleCalendarConfirm = useCallback(selectedExpiredValue => {
    setExpiredValue(selectedExpiredValue)
    setShowConfirm(true)
  }, [])

  const position = useMemo(() => {
    if (!addressBoxRef.current) return
    const rect = addressBoxRef.current.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [addressBoxRef])

  const changeSubject = useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setSubject(value)
      localStorageMaterialDraft?.saveDocumentShareDraftSubject(me?.user?.id!, defaultDocument?.id!, value)
      setShowConfirm(true)
    },
    [defaultDocument?.id, localStorageMaterialDraft, me?.user?.id]
  )

  const changeBody = useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
      setBody(value), localStorageMaterialDraft?.saveDocumentShareDraftBody(me?.user?.id!, defaultDocument?.id!, value)
      setShowConfirm(true)
    },
    [defaultDocument?.id, localStorageMaterialDraft, me?.user?.id]
  )

  const handleChangeContactList = useCallback(
    async (contactList: SelectorItemWithType[]) => {
      setContactList(contactList)
      setOpenAddressSearchbox(false)
      setShowConfirm(true)
      localStorageMaterialDraft?.saveDocumentShareDraftContactList(me?.user?.id!, defaultDocument?.id!, contactList)
    },
    [setContactList, defaultDocument?.id, localStorageMaterialDraft, me?.user?.id]
  )

  const handleSiteOrDocumentChange = useCallback((item: SelectedtSiteOrDocument) => {
    setSelectedtSiteOrDocument(item)
    setSelectedId(item.value)
    setShowConfirm(true)
  }, [])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!selectedId) {
        Toaster.error('共有する資料を選択してください。')
        return
      }

      const params = {
        messageBody: body,
        messageSubject: subject,
        userId,
        contactIds: contactList.find(item => item.type === 'contact')
          ? contactList.filter(item => item.type === 'contact').map(item => item.id!)
          : undefined,
        contactListIds: contactList.find(item => item.type === 'contactList')
          ? contactList.filter(item => item.type === 'contactList').map(item => item.id!)
          : undefined,
        companyIds: contactList.find(item => item.type === 'companyList')
          ? contactList.filter(item => item.type === 'companyList').map(item => item.id!)
          : undefined,
        expiredOn: expiredValue
      }

      if (selectedtSiteOrDocument?.kind === 'document') {
        handleShareDocumentToContact(params, selectedId)
      } else if (selectedtSiteOrDocument?.kind === 'site') {
        handleShareSiteToContact(params, selectedId)
      }

      localStorageMaterialDraft?.removeDocumentShareDraftByDocumentId(me?.user?.id!, defaultDocument?.id!)
      setShowConfirm(false)
      setIsSubmitting(true)
    },
    [
      defaultDocument,
      selectedId,
      body,
      me,
      localStorageMaterialDraft,
      subject,
      userId,
      contactList,
      expiredValue,
      selectedtSiteOrDocument?.kind,
      handleShareDocumentToContact,
      handleShareSiteToContact,
      setIsSubmitting
    ]
  )

  const removeAddress = useCallback(
    async (id: Id) => {
      const newList = contactList.filter(c => c.id != id)
      await setContactList(newList)
      localStorageMaterialDraft?.saveDocumentShareDraftContactList(me?.user?.id!, defaultDocument?.id!, newList)
      setOpenAddressSearchbox(false)
      setShowConfirm(true)
    },
    [contactList, setContactList, localStorageMaterialDraft, defaultDocument?.id, me?.user?.id]
  )

  useEffect(() => {
    if (me?.user?.id) {
      setUserId(me?.user?.id)
    }
  }, [me?.user?.id])

  useEffect(() => {
    if (!isLoading && isSubmitting && !isSubmitEnd && error) {
      setIsSubmitting(false)
      onError?.()
    } else if (!isLoading && isSubmitting && !isSubmitEnd && !error) {
      setIsSubmitEnd(true)
      onSubmit?.()
    } else if (!isLoading && isSubmitting && isSubmitEnd && !error) {
      setIsSubmitting(false)
      setIsSubmitEnd(false)
      onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSubmitting, isSubmitEnd, error])

  const templateOptionList: OptionData[] | undefined = useMemo(
    () => templateList?.map(item => ({ value: item.id!, label: item.name! })),
    [templateList]
  )

  const handleTemplateChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      if (!templateList) return

      const emailTemplateId = event.target.value as string
      const template = templateList.find(t => t.id == emailTemplateId)

      setSubject(template?.subject || '')
      setBody(template?.body || '')
      setSelectedTemplateValue(emailTemplateId)

      // 下書きデータ更新
      if (template) {
        localStorageMaterialDraft?.saveDocumentShareDraftBody(me?.user?.id!, defaultDocument?.id!, template.body || '')
        localStorageMaterialDraft?.saveDocumentShareDraftSubject(
          me?.user?.id!,
          defaultDocument?.id!,
          template.subject || ''
        )
      }
    },
    [defaultDocument?.id, localStorageMaterialDraft, me?.user?.id, templateList]
  )

  const handleCreateEmailTemplate = useCallback(() => {
    window.open('/setting/team/email_templates/new', '_blank')
  }, [])

  const openPreviewLink = useMemo<string | undefined>(() => {
    if (!selectedId || !selectedtSiteOrDocument?.kind) return

    if (selectedtSiteOrDocument?.kind === 'document') {
      return `/documents/${selectedId}/preview`
    } else if (selectedtSiteOrDocument?.kind === 'site') {
      return `/multi-documents/${selectedId}/preview`
    }

    return
  }, [selectedId, selectedtSiteOrDocument?.kind])

  const siteIconOrThumbnailImage = useMemo(() => {
    if (!selectedtSiteOrDocument) return

    if (selectedtSiteOrDocument.kind === 'document' && selectedtSiteOrDocument?.thumbnailUrl) {
      return (
        <Box
          sx={{ position: 'relative', width: '75px', height: '42px', border: `1px solid ${Colors.background.gray}` }}
        >
          <Image src={selectedtSiteOrDocument.thumbnailUrl} layout="fill" objectFit="contain" />
        </Box>
      )
    } else if (selectedtSiteOrDocument.kind === 'site') {
      return <DynamicMuiIcon variant="computer" size="32px" color="inherit" />
    }

    return
  }, [selectedtSiteOrDocument])

  // 連絡先詳細ページ 初期値に宛先を追加
  useEffect(() => {
    const documentShareDraft = localStorageMaterialDraft?.getDocumentShareDraftByDocumentId(
      me?.user?.id!,
      defaultDocument?.id!
    )
    if (documentShareDraft) {
      setSubject(documentShareDraft?.subject!)
      setBody(documentShareDraft?.body!)
      setContactList(documentShareDraft?.contactList!)
    }

    if (defaultContact?.selectorItems?.length! !== 1) return

    const defaultContactWithType: SelectorItemWithType = {
      id: defaultContact?.selectorItems?.[0].id,
      text: defaultContact?.selectorItems?.[0].text,
      type: 'contact',
      email: defaultContact?.selectorItems?.[0].extend?.encryptedEmail
    }
    setContactList(prev => {
      return [...prev, defaultContactWithType]
    })
  }, [defaultContact, defaultDocument?.id, localStorageMaterialDraft, me])

  const onChangeAuthValue: SelectInputProps['onChange'] = useCallback(
    e => {
      setAuthValue(e.target.value as string)
      setShowConfirm(true)

      switch (e.target.value) {
        case 'unexpiredViewable':
          setExpiredValue(null)
          break
        case 'expiredViewable':
          expiredValue
            ? setExpiredValue(expiredValue)
            : setExpiredValue(format(new Date().getTime(), 'yyyy-MM-dd', { locale: ja }))
          break
        default:
          return
      }
    },
    [expiredValue]
  )

  useEffect(() => {
    onChangeShowConfirm?.(showConfirm)
  }, [onChangeShowConfirm, showConfirm])

  return (
    <>
      {isSending || progressCompleted ? (
        <SendEmailContents
          isSubmitting={isSubmitting}
          isSending={isSending}
          setIsSending={setIsSending}
          setProgressCompleted={isCompleted => {
            setProgressCompleted(isCompleted)
            handleResetForm()
          }}
          progressCompleted={progressCompleted}
          isError={isError}
          setIsError={setIsError}
        />
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}
        >
          <Typography variant="h6" fontSize="s">
            メール本文
          </Typography>
          <Box mt="8px" />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box width="202px" mr="16px">
                <SelectBox
                  placeholder="テンプレートを選択"
                  value={selectedTemplateValue}
                  name="template"
                  optionList={templateOptionList || []}
                  onChange={handleTemplateChange}
                  height="34px"
                  placeholderIcon={<DynamicMuiIcon variant="drafts" color="inherit" fontSize="small" />}
                />
              </Box>
              <Box color={Colors.accent.keyBlue.default}>
                <ButtonBase onClick={handleCreateEmailTemplate}>
                  <DynamicMuiIcon variant="add" color="inherit" fontSize="small" />
                  <Box mr="10px" />
                  <Typography variant="h5">テンプレート新規作成</Typography>
                </ButtonBase>
              </Box>
            </Box>

            {/* {selectedId && (
            <Box>
              <Button
                startIcon={<DynamicMuiIcon variant="openNew" color="inherit" fontSize="small" />}
                title="プレビュー"
                kind="secondary"
                variant="outlined"
                href={`/${kindPath}/${selectedId}/preview`}
              />
            </Box>
          )} */}
          </Box>

          <Box
            border={`1px solid ${Colors.background.silver}`}
            borderRadius="4px"
            mt="12px"
            px="12px"
            display="flex"
            flexDirection="column"
            overflow="hidden"
            maxHeight="60%"
          >
            <Box
              {...{ ref: addressBoxRef }}
              borderRadius="4px"
              display="flex"
              flexWrap="wrap"
              onClick={() => setOpenAddressSearchbox(true)}
            >
              <ButtonBase style={{ padding: '12px 4px 12px 0px' }}>
                <Box color={Colors.base.middleGray}>
                  <Typography variant="h6" fontSize="s" fontWeight="normal" lineheight="14px" letterSpacing="none">
                    宛先
                  </Typography>
                </Box>
                {contactList.length === 0 && (
                  <Box color={Colors.base.placeHolder} ml="14px">
                    <Typography fontSize="s" fontWeight={300} lineheight="14px" letterSpacing="none">
                      連絡先を選択、もしくはリストから一括選択
                    </Typography>
                  </Box>
                )}
              </ButtonBase>
              {contactList.map(p => {
                let label = p.text ?? ''
                if (p.type === 'contact') {
                  label = p.text && p.email ? `${p.text} (${p.email})` : ''
                }
                // 将来的には 名称 === null だったらになる
                if (p.text && p.text === '(名前設定なし)') {
                  label = p.email ?? ''
                }
                return <Chip key={p.id} id={`${p.id}`} onClose={removeAddress} label={label} />
              })}
            </Box>
            {addressBoxRef?.current && (
              <Popover
                id={'id'}
                open={openAddressSearchBox}
                anchorEl={addressBoxRef.current}
                onClose={() => setOpenAddressSearchbox(false)}
                anchorPosition={position}
                PaperProps={{
                  className: classes.paper,
                  style: { width: (addressBoxRef.current && addressBoxRef.current.offsetWidth - 2) || 0 }
                }}
              >
                <AddressSearchBox
                  selectedList={contactList}
                  onSelect={handleChangeContactList}
                  onClose={() => setOpenAddressSearchbox(false)}
                  onContactCreating={isContactCreating => formSubmitter?.setIsDisabled(isContactCreating)}
                />
              </Popover>
            )}
            <Box borderBottom={`1px solid ${Colors.background.silver}`} />
            <Box display="flex" alignItems="center" color={Colors.base.middleGray} className={classes.wordKeep}>
              <Typography variant="h6" fontSize="s" fontWeight="normal" lineheight="14px" letterSpacing="none">
                件名
              </Typography>
              <Input
                name="title"
                value={subject}
                fullWidth
                borderStyle="none"
                boxShadow="none"
                placeholder="資料送付の件"
                onChange={changeSubject}
              />
            </Box>
            <Box borderBottom={`1px solid ${Colors.background.silver}`} />
            <TextareaAutosize
              name="message"
              value={body}
              minRows={8}
              placeholder="本文を入力..."
              className={classes.textarea}
              onChange={changeBody}
            />
            {openPreviewLink && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  borderTop: `1px solid ${Colors.background.gray}`,
                  py: '12px'
                }}
              >
                <Box mr="12px" flexShrink="0">
                  <Typography fontSize="s" lineheight="1" style={{ color: '##6D7784' }}>
                    添付
                  </Typography>
                </Box>
                <LinkIcon href={openPreviewLink} isBlank className={classes.customLink}>
                  {siteIconOrThumbnailImage && (
                    <Box sx={{ color: Colors.accent.keyBlue.default, mr: '8px', lineHeight: '0' }}>
                      {siteIconOrThumbnailImage}
                    </Box>
                  )}
                  <Box sx={{ mr: '6px' }}>
                    <Typography
                      fontSize="s"
                      lineheight="1.32"
                      style={{ color: Colors.accent.keyBlue.default, wordBreak: 'break-word' }}
                    >
                      {selectedtSiteOrDocument?.label || ''}
                      <Box
                        sx={{
                          color: Colors.accent.keyBlue.default,
                          lineHeight: '0',
                          display: 'initial',
                          marginLeft: '6px'
                        }}
                        style={{ verticalAlign: 'text-bottom' }}
                      >
                        <DynamicMuiIcon variant="openNew" size="16px" color="inherit" />
                      </Box>
                    </Typography>
                  </Box>
                </LinkIcon>
              </Box>
            )}
          </Box>
          <Box mt="25px" />
          <Typography variant="h6" fontSize="s" lineheight="14px">
            メールに添付する資料
          </Typography>
          <Box mt="8px" />
          {defaultDocument ? (
            <Input
              name="document"
              disabled
              fullWidth
              large
              value={defaultDocument.title}
              startAdornment={
                <Box
                  pl="14px"
                  color={Colors.base.middleGray}
                  fontSize="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicMuiIcon variant="document" color="action" fontSize="inherit" />
                </Box>
              }
            />
          ) : (
            <SelectSiteOrDocument onChange={handleSiteOrDocumentChange} />
          )}
          <Box mt="25px" />
          <Tooltip
            content={
              '資料の閲覧期限が設定できます。資料の共有後に期限を変更する場合は、資料サイトの「連絡先」タブから設定できます。'
            }
          >
            <Typography variant="h6" fontSize="s" lineheight="14px" letterSpacing="none">
              閲覧可能な期限の設定
            </Typography>
          </Tooltip>
          <Box display="flex" alignItems="center" mt="8px" position="relative">
            {!planGrade?.plan.canSetMaterialPublicationDue && (
              <Box
                width="100%"
                height="100%"
                zIndex={999}
                position="absolute"
                top={0}
                onClick={() => {
                  setOpenModalUpgrade(true)
                }}
                style={{
                  cursor: 'pointer'
                }}
              />
            )}
            <SelectBox
              value={authValue}
              name="term"
              optionList={optionAuthList}
              onChange={onChangeAuthValue}
              height="34px"
            />
            <Box mr="24px" />
            <SelectDatePicker disabled={!expiredValue} expiredValue={expiredValue} onConfirm={handleCalendarConfirm} />
          </Box>
          <button ref={ref} type="submit" hidden />
        </form>
      )}

      <ModalConfirmToPaidPlan
        currentPlanGrade={planGrade?.plan.grade}
        open={openModalUpgrade}
        lightPlanText="ライトプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        standardNewText="スタンダードプランでは、こちらの機能はご利用いただけません。プレミアムプランなら、公開期限設定に加え、営業を強化するさまざまな機能がご利用いただけます"
        onClose={() => setOpenModalUpgrade(false)}
      />
    </>
  )
})
