/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ContactForDisplay
 */
export interface ContactForDisplay {
  /**
   *
   * @type {string}
   * @memberof ContactForDisplay
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof ContactForDisplay
   */
  displayName?: string
}

export function ContactForDisplayFromJSON(json: any): ContactForDisplay {
  return ContactForDisplayFromJSONTyped(json, false)
}

export function ContactForDisplayFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactForDisplay {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    displayName: !exists(json, 'display_name') ? undefined : json['display_name']
  }
}

export function ContactForDisplayToJSON(value?: ContactForDisplay | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    display_name: value.displayName
  }
}
