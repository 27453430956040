import { Box, useMediaQuery } from '@material-ui/core'
import React from 'react'
import theme from 'src/styles/theme'
import { ErrorBody, ErrorBodyProps } from './ErrorBody'

export interface PageErrorProps {
  statusCode: number
}

const contents: { [key: number]: ErrorBodyProps } = {
  404: {
    title: '404 Not Found',
    descriptionJa:
      'まことに申し訳ございませんが、アクセスいただいたページが見つかりません\nお探しのページは移動、非公開もしくは削除された可能性があります',
    descriptionEn:
      "Thank you for using the noco-sales Website.\nWe're sorry, but we can't find the URL you're attempting to access.\nThe page you're looking for may be moved or deleted.",
    imgUrl: '/images/errors/404.svg'
  },
  500: {
    title: '500 Internal Server Error',
    descriptionJa:
      'ご不便をおかけいたします、サーバーエラーが発生したようです\nエンジニアが現在問題の解決にあたっておりますので\nしばらくしてから、もう一度お試しください',
    descriptionEn:
      'We apologize for the inconvenience  but it appears that there\nhas been an internal server error while processing your request.\nOur engineers have been notified working to resolve the issue as soon as possible.\nPlease try again later. ',
    imgUrl: '/images/errors/500.svg'
  }
}

export const PageError = ({ statusCode }: PageErrorProps) => {
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box width="100vw" height="100vh" p={isDownSm ? '20px' : '40px'}>
      <Box width={1} height={1} maxWidth="1180px" margin="0 auto">
        <ErrorBody {...(contents[statusCode] || contents[500])} isSP={isDownSm} />
      </Box>
    </Box>
  )
}
