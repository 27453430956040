/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Contact, ContactFromJSON, ContactFromJSONTyped, ContactToJSON } from './Contact'
import {
  ContactSetting,
  ContactSettingFromJSON,
  ContactSettingFromJSONTyped,
  ContactSettingToJSON
} from './ContactSetting'

/**
 *
 * @export
 * @interface UpdateContactResponseData
 */
export interface UpdateContactResponseData {
  /**
   *
   * @type {Contact}
   * @memberof UpdateContactResponseData
   */
  contact?: Contact
  /**
   *
   * @type {Array<ContactSetting>}
   * @memberof UpdateContactResponseData
   */
  contactSettings?: Array<ContactSetting>
}

export function UpdateContactResponseDataFromJSON(json: any): UpdateContactResponseData {
  return UpdateContactResponseDataFromJSONTyped(json, false)
}

export function UpdateContactResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateContactResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contact: !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact']),
    contactSettings: !exists(json, 'contact_settings')
      ? undefined
      : (json['contact_settings'] as Array<any>).map(ContactSettingFromJSON)
  }
}

export function UpdateContactResponseDataToJSON(value?: UpdateContactResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact: ContactToJSON(value.contact),
    contact_settings:
      value.contactSettings === undefined ? undefined : (value.contactSettings as Array<any>).map(ContactSettingToJSON)
  }
}
