/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'
import { SiteContact, SiteContactFromJSON, SiteContactFromJSONTyped, SiteContactToJSON } from './SiteContact'

/**
 *
 * @export
 * @interface GetSiteContactsResponseData
 */
export interface GetSiteContactsResponseData {
  /**
   *
   * @type {Array<SiteContact>}
   * @memberof GetSiteContactsResponseData
   */
  siteContacts?: Array<SiteContact>
  /**
   *
   * @type {PageInfo}
   * @memberof GetSiteContactsResponseData
   */
  pageInfo?: PageInfo
}

export function GetSiteContactsResponseDataFromJSON(json: any): GetSiteContactsResponseData {
  return GetSiteContactsResponseDataFromJSONTyped(json, false)
}

export function GetSiteContactsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSiteContactsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    siteContacts: !exists(json, 'site_contacts')
      ? undefined
      : (json['site_contacts'] as Array<any>).map(SiteContactFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetSiteContactsResponseDataToJSON(value?: GetSiteContactsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    site_contacts:
      value.siteContacts === undefined ? undefined : (value.siteContacts as Array<any>).map(SiteContactToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
