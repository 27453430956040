/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { EnumUserPermissionFromJSON, EnumUserPermissionToJSON } from './EnumUserPermission';
import { GetUsersResponseDataUsersInnerUserAvatarFromJSON, GetUsersResponseDataUsersInnerUserAvatarToJSON } from './GetUsersResponseDataUsersInnerUserAvatar';
export function UserForDisplayFromJSON(json) {
    return UserForDisplayFromJSONTyped(json, false);
}
export function UserForDisplayFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: json['id'],
        firstName: json['first_name'],
        lastName: json['last_name'],
        displayName: !exists(json, 'display_name') ? undefined : json['display_name'],
        avatar: GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['avatar']),
        permission: EnumUserPermissionFromJSON(json['permission']),
        discardedAt: !exists(json, 'discarded_at') ? undefined : json['discarded_at']
    };
}
export function UserForDisplayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        first_name: value.firstName,
        last_name: value.lastName,
        display_name: value.displayName,
        avatar: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.avatar),
        permission: EnumUserPermissionToJSON(value.permission),
        discarded_at: value.discardedAt
    };
}
