import { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import useSWR from 'swr'
import { SWRCachePath } from '../swr-cach-path'
import { EnumRecordPorterHistoryOperation, EnumRecordPorterHistoryProgressStatus } from '@noco/http-client/lib/noco'

export const useListRecordPorterHistory = () => {
  const { data: auth } = useAuthenticate()
  const recordPorterHistoriesApi = nocoSDK.client?.userService.recordPorterHistoryApi
  const func = useCallback(async () => {
    if (!auth) return

    return await recordPorterHistoriesApi
      ?.ApiFactory(auth.token)
      .userV1RecordPorterHistoriesGet()
      .then(res => res.data)
  }, [auth, recordPorterHistoriesApi])
  return useSWR(auth?.token && SWRCachePath.listRecordPorterHistory(), func)
}

// pollingのstatusを取得するhooks
export const useCheckPollingStatus = () => {
  const [progressStatus, setProgressStatus] = useState<EnumRecordPorterHistoryProgressStatus | undefined>(undefined)

  const checkShouldPolling = (status: EnumRecordPorterHistoryProgressStatus) => {
    switch (status) {
      case EnumRecordPorterHistoryProgressStatus.Progress:
        return true
      default:
        return false
    }
  }

  const shouldPolling = useMemo(() => {
    if (progressStatus) return checkShouldPolling(progressStatus)
  }, [progressStatus])
  return { checkShouldPolling, shouldPolling, progressStatus, setProgressStatus }
}

export const useGetPorterHistoriesLatest = (operation?: EnumRecordPorterHistoryOperation) => {
  const basePath = '/bulk_update'
  const { pathname } = useRouter()
  const { data: auth } = useAuthenticate()
  const { mutate: mutateListRecordPorterHistory } = useListRecordPorterHistory()
  const { shouldPolling, setProgressStatus } = useCheckPollingStatus()
  const recordPorterHistoriesApi = nocoSDK.client?.userService.recordPorterHistoryApi
  const func = useCallback(async () => {
    if (!auth) return
    return await recordPorterHistoriesApi
      ?.ApiFactory(auth.token)
      .userV1RecordPorterHistoriesLatestGet({ operation })
      .then(res => {
        setProgressStatus(res.data?.recordPorterHistory?.progressStatus)
        mutateListRecordPorterHistory()
        return res.data
      })
  }, [auth, recordPorterHistoriesApi, operation, setProgressStatus, mutateListRecordPorterHistory])

  return useSWR(auth?.token && SWRCachePath.getPorterHistoriesLatest(operation), func, {
    refreshInterval: shouldPolling && pathname === basePath ? 5000 : undefined,
    revalidateOnMount: pathname === basePath
  })
}
