import { Box, makeStyles, createStyles } from '@material-ui/core'
import { OrganizationPolicySetting } from '@noco/http-client/lib/noco'
import { useCallback, useState } from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { LayoutTeam } from 'src/components/commons'
import { ModalPolicy } from 'src/components/modals'
import { Header } from 'src/components/organisms'
import { useGetPolicySettings, useUpdatePolicySettings } from 'src/fixtures/modules/organization/hooks'
import { useFeatureFlag } from 'src/fixtures/modules/user/hooks'
import { useGlobalStyles } from 'src/styles/theme'

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: Colors.functional.body1.default,
      textDecoration: 'none'
    }
  })
)

export const PageTeamPolicy = () => {
  const classes = useStyles()
  const globalStyles = useGlobalStyles()
  const [editItem, setEditItem] = useState<OrganizationPolicySetting | undefined>(undefined)
  const { hasPermission: isUpdatable } = useFeatureFlag('policy', 'update')
  const { data: res } = useGetPolicySettings()
  const policySettingList = res?.organizationPolicySettings
  const { handleUpdatePolicySettings } = useUpdatePolicySettings()
  const handleConfirm = useCallback(
    (item: OrganizationPolicySetting) => {
      if (!item.id) return
      handleUpdatePolicySettings(item.id, { organizationPolicySetting: { label: item.label, url: item.url } })
    },
    [handleUpdatePolicySettings]
  )

  const handleOpenModal = useCallback((item: OrganizationPolicySetting) => {
    setEditItem(item)
  }, [])

  return (
    <>
      <LayoutTeam>
        <Box width={1} bgcolor={Colors.functional.background.default} px="16px" color={Colors.base.black}>
          <Header icon="localPolice" title="ポリシーの表示設定" back={false} textSize="large" iconSize="32px" />
          <Box px="16px">
            <Typography variant="body2">
              個人情報の取り扱いや収集、活用、管理、保護などに関する貴社ポリシーの表示を設定できます
              <br />
              設定したポリシーは、顧客情報を取得する際の入力フォーム欄等に表示されます
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              borderBottom={`1px solid ${Colors.background.silver}`}
              p="6px 8px"
              color={Colors.base.middleGray}
              mt="25px"
            >
              <Box display="flex" alignItems="center" width={1}>
                <Box width={1} maxWidth="320px">
                  <Typography variant="h6">ポリシー名</Typography>
                </Box>
                <Box width={1}>
                  <Typography variant="h6">URL</Typography>
                </Box>
              </Box>
              <Box width="20px" />
            </Box>
            {policySettingList?.map((item, i) => (
              <Box
                key={i}
                display="flex"
                alignItems="center"
                borderBottom={`1px solid ${Colors.background.silver}`}
                p="12px 8px"
                color={item.url ? Colors.base.black : Colors.base.placeHolder}
              >
                <Box display="flex" alignItems="center" width={1}>
                  <Box width={1} color={Colors.base.black} maxWidth="320px">
                    <Typography fontSize="s" fontWeight="bold">
                      {item.label}
                    </Typography>
                  </Box>
                  <Box width={1}>
                    {item.url ? (
                      <a href={item.url} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        <Typography fontSize="s">{item.url}</Typography>
                      </a>
                    ) : (
                      <Typography fontSize="s">未設定</Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  p="4px"
                  color={isUpdatable ? Colors.base.middleGray : Colors.background.silver}
                  className={isUpdatable ? globalStyles.cursorPointer : undefined}
                  onClick={() => isUpdatable && handleOpenModal(item)}
                >
                  <DynamicMuiIcon variant="edit" size="20px" />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </LayoutTeam>
      <ModalPolicy
        id={editItem?.id}
        label={editItem?.label}
        url={editItem?.url}
        open={Boolean(editItem)}
        onClose={() => setEditItem(undefined)}
        onCancel={() => setEditItem(undefined)}
        onConfirm={handleConfirm}
      />
    </>
  )
}
