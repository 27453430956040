import { Visitor } from '@noco/http-client/lib/noco'
import { useCallback, useMemo, useState } from 'react'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { nocoSDK } from '../initialize'
import { useGetMe } from '../me/hooks'
import { SWRCachePath } from '../swr-cach-path'
import { useGetVisitor } from '../visitor/hooks'
import { IUserPermission } from './types'

export const useListContactChatMessages = (isPreview = false, visitor?: Visitor) => {
  const { data: auth } = useGetVisitor(isPreview)
  const contactChatMessageApi = nocoSDK.client?.contactService.chatMessageApi
  const func = useCallback(async () => {
    if (!auth || !visitor?.hasContact) return undefined
    return await contactChatMessageApi
      ?.ApiFactory(auth.token)
      .contactV1ChatMessagesGet({ per: 999 })
      .then(item => {
        return item.data
      })
  }, [auth, contactChatMessageApi, visitor])
  return useSWR(auth ? SWRCachePath.listUserContactChatMessages(visitor?.id || '') : null, func, {
    onError: err => handleError(err, { tag: 'Get contact list' })
  })
}

export const useCreateChatMessage = () => {
  const { data: auth } = useGetVisitor()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const { mutate } = useListContactChatMessages()
  const contactChatMessageApi = nocoSDK.client?.contactService.chatMessageApi

  const handleCreateChatMessage = useCallback(
    async ({ message, documentId, siteId }: { message: string; documentId?: string; siteId?: string }) => {
      try {
        if (!auth) return
        setError(undefined)
        setIsLoading(true)

        await contactChatMessageApi?.ApiFactory(auth.token).contactV1ChatMessagesPost({
          createChatMessageRequest: {
            message,
            documentId,
            siteId
          }
        })

        await mutate()
      } catch (err) {
        await handleError(err, { setError, tag: 'Create memo' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, contactChatMessageApi, mutate]
  )

  return { error, isLoading, handleCreateChatMessage }
}

export type FeatureFlagVariant =
  | 'team'
  | 'user'
  | 'sendEmail'
  | 'triggerEmail'
  | 'policy'
  | 'chatSetting'
  | 'companySetting'
  | 'contactSetting'
  | 'company'
  | 'contact'
  | 'document'
export type FeatureFlagAction = 'create' | 'read' | 'update' | 'delete' | 'import' | 'export'
export type FeatureFlagItem = { [key in FeatureFlagAction]?: IUserPermission[] }

export const FeatureFlagMap: { [key in FeatureFlagVariant]: FeatureFlagItem } = {
  team: {
    update: ['administrator', 'manager', 'member']
  },
  user: {
    create: ['administrator'],
    update: ['administrator']
  },
  sendEmail: {
    update: ['administrator', 'manager']
  },
  triggerEmail: {
    update: ['administrator', 'manager', 'member']
  },
  policy: {
    update: ['administrator', 'manager']
  },
  chatSetting: {
    update: ['administrator', 'manager']
  },
  companySetting: {
    create: ['administrator', 'manager'],
    update: ['administrator', 'manager']
  },
  contactSetting: {
    create: ['administrator', 'manager'],
    update: ['administrator', 'manager']
  },
  company: {
    export: ['administrator', 'manager']
  },
  contact: {
    export: ['administrator', 'manager']
  },
  document: {
    create: ['administrator', 'manager', 'member'],
    update: ['administrator', 'manager', 'member'],
    delete: ['administrator', 'manager', 'member']
  }
}

export const useFeatureFlag = (variant: FeatureFlagVariant, action: FeatureFlagAction) => {
  const { data: me, mutate } = useGetMe()
  const user = me?.user

  const hasPermission = useMemo(() => {
    if (!user || !user.permission) return false

    const permissions = FeatureFlagMap[variant][action]
    if (!permissions) return true

    return permissions.includes(user.permission)
  }, [action, user, variant])

  return { hasPermission, mutate }
}
