/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumChatSummaryRepliedStatus,
  EnumChatSummaryRepliedStatusFromJSON,
  EnumChatSummaryRepliedStatusFromJSONTyped,
  EnumChatSummaryRepliedStatusToJSON
} from './EnumChatSummaryRepliedStatus'

/**
 *
 * @export
 * @interface UpdateChatSummariesRepliedRequest
 */
export interface UpdateChatSummariesRepliedRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateChatSummariesRepliedRequest
   */
  ids?: Array<string>
  /**
   *
   * @type {EnumChatSummaryRepliedStatus}
   * @memberof UpdateChatSummariesRepliedRequest
   */
  repliedStatus?: EnumChatSummaryRepliedStatus
}

export function UpdateChatSummariesRepliedRequestFromJSON(json: any): UpdateChatSummariesRepliedRequest {
  return UpdateChatSummariesRepliedRequestFromJSONTyped(json, false)
}

export function UpdateChatSummariesRepliedRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateChatSummariesRepliedRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    ids: !exists(json, 'ids') ? undefined : json['ids'],
    repliedStatus: !exists(json, 'replied_status')
      ? undefined
      : EnumChatSummaryRepliedStatusFromJSON(json['replied_status'])
  }
}

export function UpdateChatSummariesRepliedRequestToJSON(value?: UpdateChatSummariesRepliedRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ids: value.ids,
    replied_status: EnumChatSummaryRepliedStatusToJSON(value.repliedStatus)
  }
}
