/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CouponFromJSON, CouponToJSON } from './Coupon';
export function GetOrganizationPaymentPromotionCodeResponseDataFromJSON(json) {
    return GetOrganizationPaymentPromotionCodeResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationPaymentPromotionCodeResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        coupon: CouponFromJSON(json['coupon'])
    };
}
export function GetOrganizationPaymentPromotionCodeResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        coupon: CouponToJSON(value.coupon)
    };
}
