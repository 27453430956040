import React, { useCallback, useMemo, useState } from 'react'
import { Avatar, Box, createStyles, makeStyles } from '@material-ui/core'
import { Button, Colors, DynamicMuiIcon, Pallete, Typography } from 'src/components/atoms'
import { ContactTimeline } from '@noco/http-client/lib/noco'
import { DateFormat, dateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import clsx from 'clsx'
import { ActionMenu, ActionMenuItemType } from 'src/components/molecules'

const useStyles = makeStyles(() =>
  createStyles({
    lineLineLabel: {
      color: Colors.accent.keyPurple.default,
      borderRadius: 4
    },
    discardTimeLineLabel: {
      color: '#6D7784',
      borderRadius: 4
    },
    avatar: {
      display: 'inline-flex',
      height: 20,
      width: 20,
      verticalAlign: 'sub',
      marginRight: '6px'
    },
    link: {
      color: Colors.accent.keyBlue.default
    },
    textarea: {
      width: '100%',
      border: `1px solid ${Pallete.functional.border.lightGray}`,
      borderRadius: '4px',
      padding: '6px 7px',
      letterSpacing: '0.04em'
    },
    memoRightWrap: {
      '& .MuiSvgIcon-root': {
        width: '16px'
      }
    },
    small: {
      padding: '8px 10px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '13px'
        }
      },
      '& .MuiIconButton-label': {
        '& .MuiSvgIcon-root': {
          fontSize: '14px',
          padding: '0'
        }
      }
    },
    medium: {
      padding: '10px 12px',
      '& .timeLineItemheading': {
        '& .MuiTypography-root': {
          fontSize: '14px'
        }
      },
      '& .MuiIconButton-label': {
        '& .MuiSvgIcon-root': {
          fontSize: '16px'
        }
      }
    }
  })
)

interface ClientTimeLineItemMemoProps {
  item: ContactTimeline
  onDelete?: (contactMemoId: string) => Promise<void>
  onEdit?: (contactMemoId: string, memo: string) => Promise<void>
  size?: 'small' | 'medium'
}

export const ClientTimeLineItemMemo = ({ item, onDelete, onEdit, size = 'medium' }: ClientTimeLineItemMemoProps) => {
  const classes = useStyles()
  const [editMessage, changeEditMessage] = useState('')

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      changeEditMessage(event.target.value)
    },
    [changeEditMessage]
  )

  const onSubmit = useCallback(() => {
    if (onEdit && item.memo?.id) {
      onEdit(item.memo?.id, editMessage)
      changeEditMessage('')
    }
  }, [onEdit, item, editMessage, changeEditMessage])

  const onCancel = useCallback(() => {
    changeEditMessage('')
  }, [changeEditMessage])

  const menuItemList = useMemo<ActionMenuItemType[]>(() => {
    return [
      {
        label: '編集',
        onClick: () => {
          changeEditMessage(item.memo?.text ?? '')
        },
        icon: <DynamicMuiIcon variant="edit" />
      },
      {
        label: '削除',
        onClick: () => {
          if (onDelete && item.memo?.id) {
            onDelete(item.memo?.id)
          }
        },
        icon: <DynamicMuiIcon variant="delete" htmlColor={Colors.accent.keyRed.default} />,
        color: `${Colors.accent.keyRed.default}`
      }
    ]
  }, [item.memo?.text, item.memo?.id, onDelete])

  const displayedUser = useMemo(() => item.memo?.updatedBy || item.memo?.createdBy, [item])

  // 削除されたメモ
  if (!item.memo?.text) {
    return (
      <Box
        className={size === 'medium' ? classes.medium : classes.small}
        border={`1px solid ${Colors.background.silver}`}
        borderRadius="6px"
        bgcolor={Pallete.functional.background.default}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="6px">
          <Box
            ml="4px"
            display="flex"
            alignItems="center"
            width="fit-content"
            className={clsx(classes.discardTimeLineLabel, 'timeLineItemheading')}
          >
            <DynamicMuiIcon variant="delete" size={16} />
            <Box mr="4px" />
            <Typography fontWeight="bold" lineheight="14px">
              削除されたメモ
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" style={{ color: Colors.base.middleGray }}>
              {item.createdAt && dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME)}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box mr="8px" display="inline">
            <Avatar className={classes.avatar} src={displayedUser?.avatar?.url} />
            <Box display="inline" pr="2px" />
            <Typography display="inline" variant="h5">
              {displayedUser?.lastName} {displayedUser?.firstName}
            </Typography>
          </Box>
          <Typography
            display="inline"
            fontSize="s"
            lineheight="18.48px"
            letterSpacing="tight"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            がこのメモを削除しました
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      className={size === 'medium' ? classes.medium : classes.small}
      border={`1px solid ${Colors.accent.keyPurple.default}`}
      borderRadius="6px"
      bgcolor={Colors.selected.background}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="6px">
        <Box
          ml="4px"
          display="flex"
          alignItems="center"
          width="fit-content"
          className={clsx(classes.lineLineLabel, 'timeLineItemheading')}
        >
          <DynamicMuiIcon variant="noteRounded" size={16} />
          <Box mr="4px" />
          <Typography fontWeight="bold" lineheight="14px">
            メモ
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" className={classes.memoRightWrap}>
          <Typography variant="subtitle2" style={{ color: Colors.base.middleGray }}>
            {item.createdAt && dateFormat(dateStringToMilliseconds(item.createdAt), DateFormat.DATE_WITH_TIME)}
          </Typography>
          <Box mr="6px" />
          <ActionMenu menuItemList={menuItemList} />
        </Box>
      </Box>

      {editMessage ? (
        <Box>
          <textarea className={classes.textarea} value={editMessage} onChange={onChange} />
          <Box display="flex">
            <Box mr={3}>
              <Button kind="neutral" variant="outlined" size="small" title="キャンセル" onClick={onCancel} />
            </Box>
            <Button size="small" title="保存" onClick={onSubmit} />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box mr="8px" display="inline">
            <Avatar className={classes.avatar} src={displayedUser?.avatar?.url} />
            <Box display="inline" pr="2px" />
            <Typography display="inline" variant="h5">
              {displayedUser?.lastName} {displayedUser?.firstName}
            </Typography>
          </Box>
          <Typography
            display="inline"
            fontSize="s"
            lineheight="18.48px"
            letterSpacing="tight"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {item.memo?.text}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
