import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var MaterialListApi = /** @class */ (function () {
    function MaterialListApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    MaterialListApi.initialize = function (basePath, fetchApi) {
        if (!MaterialListApi.instance) {
            MaterialListApi.instance = new MaterialListApi(basePath, fetchApi);
        }
        return MaterialListApi.instance;
    };
    MaterialListApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1MaterialListApi(new Configuration(config));
    };
    return MaterialListApi;
}());
export { MaterialListApi };
