import { Box } from '@material-ui/core'
import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { DocumentSettingHeader, SiteFooter } from 'src/components/organisms'
import { InformationContainer } from './InformationContainer'
import { SettingContainer } from './SettingContainer'
import { Main } from './Main'
import { Menu, MenuType } from './Menu'
import { HistoryContainer } from './Menu/HistoryContainer'
import { Colors } from 'src/components/atoms'
import { useGetSite, useListSiteSection } from 'src/fixtures/modules/site/hooks'
import { Site } from '@noco/http-client/lib/noco'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'

interface SlideSectionProps {
  currentMode: MenuType
  site?: Site
  onClose?: () => void
}

const SlideSection = ({ currentMode, onClose, site = {} }: SlideSectionProps) => {
  if (!currentMode) return <></>

  switch (currentMode) {
    case 'info':
      return <InformationContainer onClose={onClose} site={site} />
    case 'setting':
      return <SettingContainer onClose={onClose} siteId={site.id!} />
    case 'history':
      return <HistoryContainer onClose={onClose} />
  }
}

export const PageDocumentsSiteSetting = ({ siteId }: { siteId: string }) => {
  const router = useRouter()
  const currentMode = router.query.mode as MenuType
  const { data: res } = useGetOrganization()

  const handleClose = useCallback(() => {
    router.replace(location.pathname)
  }, [router])
  const { data: siteResponse } = useGetSite(siteId)
  const { data: siteSectionResponse } = useListSiteSection(siteId)
  const siteSectionList = siteSectionResponse?.siteSections

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <Box width="100vw" height="100vh" display="flex" flexDirection="column" overflow={'hidden'}>
      <Box width={1} height="61px" bgcolor="white" borderBottom={`1px solid ${Colors.background.silver}`}>
        <DocumentSettingHeader iconText="戻る" onClickBack={() => router.back()} type="site" siteId={siteId} />
      </Box>
      <Box display="flex" height={1} overflow="hidden">
        <Box
          display="flex"
          flexDirection="column"
          width={1}
          maxWidth={1}
          height={1}
          flex="0 1 auto"
          overflow={'hidden auto'}
        >
          {siteResponse?.site && <Main site={siteResponse?.site!} siteSectionList={siteSectionList || []} />}
          <Box mt="auto">
            <SiteFooter site={siteResponse?.site!} mode="setting" organization={res?.organization} />
          </Box>
        </Box>
        {currentMode && (
          <Box width="350px" flex="1 0 auto" height={1} borderLeft="1px solid #DADCE0" overflow="hidden auto">
            <SlideSection currentMode={currentMode} onClose={handleClose} site={siteResponse?.site} />
          </Box>
        )}
        <Box flex="1 0 auto" overflow="hidden">
          <Menu />
        </Box>
      </Box>
    </Box>
  )
}
