/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentAddon,
  OrganizationPaymentAddonFromJSON,
  OrganizationPaymentAddonFromJSONTyped,
  OrganizationPaymentAddonToJSON
} from './OrganizationPaymentAddon'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentCurrentPlanRequest
 */
export interface UpdateOrganizationPaymentCurrentPlanRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationPaymentCurrentPlanRequest
   */
  planId: string
  /**
   *
   * @type {OrganizationPaymentAddon}
   * @memberof UpdateOrganizationPaymentCurrentPlanRequest
   */
  addon?: OrganizationPaymentAddon
}

export function UpdateOrganizationPaymentCurrentPlanRequestFromJSON(
  json: any
): UpdateOrganizationPaymentCurrentPlanRequest {
  return UpdateOrganizationPaymentCurrentPlanRequestFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentCurrentPlanRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentCurrentPlanRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    planId: json['plan_id'],
    addon: !exists(json, 'addon') ? undefined : OrganizationPaymentAddonFromJSON(json['addon'])
  }
}

export function UpdateOrganizationPaymentCurrentPlanRequestToJSON(
  value?: UpdateOrganizationPaymentCurrentPlanRequest | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan_id: value.planId,
    addon: OrganizationPaymentAddonToJSON(value.addon)
  }
}
