import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { DocumentSettingChat } from 'src/components/organisms/DocumentSettingChat'

export const ChatContainer = ({ onClose }: { onClose?: () => void }) => {
  const { query } = useRouter()
  const { documentId } = query as { documentId: string }

  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  return documentId ? <DocumentSettingChat open={open} onClose={handleClose} documentId={documentId} /> : <></>
}
