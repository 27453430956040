/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetOrganizationUserEmailSettingsResponseFromJSON, UpdateOrganizationUserEmailSettingRequestToJSON, UpdateOrganizationUserEmailSettingResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1OrganizationUserEmailSettingsApi extends runtime.BaseAPI {
    /**
     * チームのメール送信設定一覧を取得する
     * index
     */
    async userV1OrganizationUserEmailSettingsGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization_user_email_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationUserEmailSettingsResponseFromJSON(jsonValue));
    }
    /**
     * チームのメール送信設定一覧を取得する
     * index
     */
    async userV1OrganizationUserEmailSettingsGet(initOverrides) {
        const response = await this.userV1OrganizationUserEmailSettingsGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * チームのメール送信設定を更新する
     * update
     */
    async userV1OrganizationUserEmailSettingsOrganizationUserEmailSettingIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.organizationUserEmailSettingId === null ||
            requestParameters.organizationUserEmailSettingId === undefined) {
            throw new runtime.RequiredError('organizationUserEmailSettingId', 'Required parameter requestParameters.organizationUserEmailSettingId was null or undefined when calling userV1OrganizationUserEmailSettingsOrganizationUserEmailSettingIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization_user_email_settings/{organization_user_email_setting_id}`.replace(`{${'organization_user_email_setting_id'}}`, encodeURIComponent(String(requestParameters.organizationUserEmailSettingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationUserEmailSettingRequestToJSON(requestParameters.updateOrganizationUserEmailSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationUserEmailSettingResponseFromJSON(jsonValue));
    }
    /**
     * チームのメール送信設定を更新する
     * update
     */
    async userV1OrganizationUserEmailSettingsOrganizationUserEmailSettingIdPut(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationUserEmailSettingsOrganizationUserEmailSettingIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
