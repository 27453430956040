/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { UpdateChatMessageRequestToJSON, UpdateChatMessageResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1ChatMessageApi extends runtime.BaseAPI {
    /**
     */
    async userV1ChatMessagesChatMessageIdDeleteRaw(requestParameters, initOverrides) {
        if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
            throw new runtime.RequiredError('chatMessageId', 'Required parameter requestParameters.chatMessageId was null or undefined when calling userV1ChatMessagesChatMessageIdDelete.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/chat_messages/{chat_message_id}`.replace(`{${'chat_message_id'}}`, encodeURIComponent(String(requestParameters.chatMessageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.TextApiResponse(response);
    }
    /**
     */
    async userV1ChatMessagesChatMessageIdDelete(requestParameters, initOverrides) {
        const response = await this.userV1ChatMessagesChatMessageIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チャットメッセージの編集をする
     * update
     */
    async userV1ChatMessagesChatMessageIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.chatMessageId === null || requestParameters.chatMessageId === undefined) {
            throw new runtime.RequiredError('chatMessageId', 'Required parameter requestParameters.chatMessageId was null or undefined when calling userV1ChatMessagesChatMessageIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/chat_messages/{chat_message_id}`.replace(`{${'chat_message_id'}}`, encodeURIComponent(String(requestParameters.chatMessageId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateChatMessageRequestToJSON(requestParameters.updateChatMessageRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateChatMessageResponseFromJSON(jsonValue));
    }
    /**
     * チャットメッセージの編集をする
     * update
     */
    async userV1ChatMessagesChatMessageIdPut(requestParameters, initOverrides) {
        const response = await this.userV1ChatMessagesChatMessageIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
