/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { GetUsersResponseDataUsersInnerUserAvatarFromJSON, GetUsersResponseDataUsersInnerUserAvatarToJSON } from './GetUsersResponseDataUsersInnerUserAvatar';
export function JobFileFromJSON(json) {
    return JobFileFromJSONTyped(json, false);
}
export function JobFileFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        division: !exists(json, 'division') ? undefined : json['division'],
        name: !exists(json, 'name') ? undefined : json['name'],
        file: !exists(json, 'file') ? undefined : GetUsersResponseDataUsersInnerUserAvatarFromJSON(json['file']),
        rows: !exists(json, 'rows') ? undefined : json['rows'],
        size: !exists(json, 'size') ? undefined : json['size'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        jobId: !exists(json, 'job_id') ? undefined : json['job_id']
    };
}
export function JobFileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        division: value.division,
        name: value.name,
        file: GetUsersResponseDataUsersInnerUserAvatarToJSON(value.file),
        rows: value.rows,
        size: value.size,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        job_id: value.jobId
    };
}
