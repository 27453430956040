/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { UserProfileFromJSON, UserProfileToJSON } from './UserProfile';
export function GetMeProfileResponseDataFromJSON(json) {
    return GetMeProfileResponseDataFromJSONTyped(json, false);
}
export function GetMeProfileResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        userProfile: !exists(json, 'user_profile') ? undefined : UserProfileFromJSON(json['user_profile'])
    };
}
export function GetMeProfileResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        user_profile: UserProfileToJSON(value.userProfile)
    };
}
