/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  RequestContactSettingFieldValue,
  RequestContactSettingFieldValueFromJSON,
  RequestContactSettingFieldValueFromJSONTyped,
  RequestContactSettingFieldValueToJSON
} from './RequestContactSettingFieldValue'

/**
 *
 * @export
 * @interface PutContactVisitorContactRequest
 */
export interface PutContactVisitorContactRequest {
  /**
   *
   * @type {string}
   * @memberof PutContactVisitorContactRequest
   */
  documentId?: string
  /**
   *
   * @type {string}
   * @memberof PutContactVisitorContactRequest
   */
  siteId?: string
  /**
   *
   * @type {Array<RequestContactSettingFieldValue>}
   * @memberof PutContactVisitorContactRequest
   */
  contactSettingFieldValues?: Array<RequestContactSettingFieldValue>
}

export function PutContactVisitorContactRequestFromJSON(json: any): PutContactVisitorContactRequest {
  return PutContactVisitorContactRequestFromJSONTyped(json, false)
}

export function PutContactVisitorContactRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutContactVisitorContactRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    documentId: !exists(json, 'document_id') ? undefined : json['document_id'],
    siteId: !exists(json, 'site_id') ? undefined : json['site_id'],
    contactSettingFieldValues: !exists(json, 'contact_setting_field_values')
      ? undefined
      : (json['contact_setting_field_values'] as Array<any>).map(RequestContactSettingFieldValueFromJSON)
  }
}

export function PutContactVisitorContactRequestToJSON(value?: PutContactVisitorContactRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    document_id: value.documentId,
    site_id: value.siteId,
    contact_setting_field_values:
      value.contactSettingFieldValues === undefined
        ? undefined
        : (value.contactSettingFieldValues as Array<any>).map(RequestContactSettingFieldValueToJSON)
  }
}
