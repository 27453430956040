/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetOrganizationChatSettingResponseFromJSON, GetOrganizationLabelSettingResponseFromJSON, GetOrganizationPolicySettingsResponseFromJSON, GetOrganizationResponseFromJSON, InviteOrganizationRequestToJSON, InviteOrganizationResponseFromJSON, PurgeUserAndMailTemplateOrganizationRequestToJSON, PurgeUserAndMailTemplateOrganizationResponseFromJSON, UpdateOrganizationAdvertisementRequestToJSON, UpdateOrganizationAdvertisementResponseFromJSON, UpdateOrganizationAvatarRequestToJSON, UpdateOrganizationAvatarResponseFromJSON, UpdateOrganizationChatSettingAvatarResponseFromJSON, UpdateOrganizationChatSettingChatButtonImageResponseFromJSON, UpdateOrganizationChatSettingRequestToJSON, UpdateOrganizationChatSettingResponseFromJSON, UpdateOrganizationPolicySettingRequestToJSON, UpdateOrganizationPolicySettingResponseFromJSON, UpdateOrganizationRequestToJSON, UpdateOrganizationResponseFromJSON, UpdateOrganizationTriggerEmailRequestToJSON, UpdateOrganizationTriggerEmailiResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1OrganizationApi extends runtime.BaseAPI {
    /**
     * 広告設定を変更する
     * update
     */
    async userV1OrganizationAdvertisementPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/advertisement`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationAdvertisementRequestToJSON(requestParameters.updateOrganizationAdvertisementRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationAdvertisementResponseFromJSON(jsonValue));
    }
    /**
     * 広告設定を変更する
     * update
     */
    async userV1OrganizationAdvertisementPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationAdvertisementPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームアイコンを設定する
     * update
     */
    async userV1OrganizationAvatarPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/avatar`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationAvatarRequestToJSON(requestParameters.updateOrganizationAvatarRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationAvatarResponseFromJSON(jsonValue));
    }
    /**
     * チームアイコンを設定する
     * update
     */
    async userV1OrganizationAvatarPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationAvatarPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームのチャット表示設定のアイコンの更新をする
     * avatar
     */
    async userV1OrganizationChatSettingAvatarPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/organization/chat_setting/avatar`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationChatSettingAvatarResponseFromJSON(jsonValue));
    }
    /**
     * チームのチャット表示設定のアイコンの更新をする
     * avatar
     */
    async userV1OrganizationChatSettingAvatarPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationChatSettingAvatarPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームのチャット表示設定のチャットボタン画像の更新をする（※現在は使われていない）
     * chat_button_image
     */
    async userV1OrganizationChatSettingChatButtonImagePutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const consumes = [{ contentType: 'multipart/form-data' }];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file);
        }
        const response = await this.request({
            path: `/user/v1/organization/chat_setting/chat_button_image`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationChatSettingChatButtonImageResponseFromJSON(jsonValue));
    }
    /**
     * チームのチャット表示設定のチャットボタン画像の更新をする（※現在は使われていない）
     * chat_button_image
     */
    async userV1OrganizationChatSettingChatButtonImagePut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationChatSettingChatButtonImagePutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * チームのチャット表示設定の情報を取得する
     * show
     */
    async userV1OrganizationChatSettingGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/chat_setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationChatSettingResponseFromJSON(jsonValue));
    }
    /**
     * チームのチャット表示設定の情報を取得する
     * show
     */
    async userV1OrganizationChatSettingGet(initOverrides) {
        const response = await this.userV1OrganizationChatSettingGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * チームのチャット表示設定の更新をする
     * update
     */
    async userV1OrganizationChatSettingPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/chat_setting`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationChatSettingRequestToJSON(requestParameters.updateOrganizationChatSettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationChatSettingResponseFromJSON(jsonValue));
    }
    /**
     * チームのチャット表示設定の更新をする
     * update
     */
    async userV1OrganizationChatSettingPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationChatSettingPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ログインしているユーザが所属しているチーム情報を取得する
     * show
     */
    async userV1OrganizationGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationResponseFromJSON(jsonValue));
    }
    /**
     * ログインしているユーザが所属しているチーム情報を取得する
     * show
     */
    async userV1OrganizationGet(initOverrides) {
        const response = await this.userV1OrganizationGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * チームへユーザを招待する
     * invite
     */
    async userV1OrganizationInvitePostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteOrganizationRequestToJSON(requestParameters.inviteOrganizationRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => InviteOrganizationResponseFromJSON(jsonValue));
    }
    /**
     * チームへユーザを招待する
     * invite
     */
    async userV1OrganizationInvitePost(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationInvitePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 資料に設定できるラベル一覧を取得する（現状固定値）
     * show
     */
    async userV1OrganizationLabelSettingDocumentGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/label_setting/document`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationLabelSettingResponseFromJSON(jsonValue));
    }
    /**
     * 資料に設定できるラベル一覧を取得する（現状固定値）
     * show
     */
    async userV1OrganizationLabelSettingDocumentGet(initOverrides) {
        const response = await this.userV1OrganizationLabelSettingDocumentGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * チームポリシーの表示設定情報一覧を取得する
     * index
     */
    async userV1OrganizationPolicySettingsGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/organization/policy_settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetOrganizationPolicySettingsResponseFromJSON(jsonValue));
    }
    /**
     * チームポリシーの表示設定情報一覧を取得する
     * index
     */
    async userV1OrganizationPolicySettingsGet(initOverrides) {
        const response = await this.userV1OrganizationPolicySettingsGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * チームポリシーの表示情報を設定する
     * update
     */
    async userV1OrganizationPolicySettingsOrganizationPolicySettingIdPutRaw(requestParameters, initOverrides) {
        if (requestParameters.organizationPolicySettingId === null ||
            requestParameters.organizationPolicySettingId === undefined) {
            throw new runtime.RequiredError('organizationPolicySettingId', 'Required parameter requestParameters.organizationPolicySettingId was null or undefined when calling userV1OrganizationPolicySettingsOrganizationPolicySettingIdPut.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/policy_settings/{organization_policy_setting_id}`.replace(`{${'organization_policy_setting_id'}}`, encodeURIComponent(String(requestParameters.organizationPolicySettingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationPolicySettingRequestToJSON(requestParameters.updateOrganizationPolicySettingRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationPolicySettingResponseFromJSON(jsonValue));
    }
    /**
     * チームポリシーの表示情報を設定する
     * update
     */
    async userV1OrganizationPolicySettingsOrganizationPolicySettingIdPut(requestParameters, initOverrides) {
        const response = await this.userV1OrganizationPolicySettingsOrganizationPolicySettingIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 選択したユーザーとメールテンプレート以外を削除する
     * purge_user_and_mail_template
     */
    async userV1OrganizationPurgeUserAndMailTemplatePostRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/purge_user_and_mail_template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PurgeUserAndMailTemplateOrganizationRequestToJSON(requestParameters.purgeUserAndMailTemplateOrganizationRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => PurgeUserAndMailTemplateOrganizationResponseFromJSON(jsonValue));
    }
    /**
     * 選択したユーザーとメールテンプレート以外を削除する
     * purge_user_and_mail_template
     */
    async userV1OrganizationPurgeUserAndMailTemplatePost(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPurgeUserAndMailTemplatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ログインしているユーザが所属しているチーム情報を編集する
     * update
     */
    async userV1OrganizationPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationRequestToJSON(requestParameters.updateOrganizationRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationResponseFromJSON(jsonValue));
    }
    /**
     * ログインしているユーザが所属しているチーム情報を編集する
     * update
     */
    async userV1OrganizationPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * トリガーメールの設定を更新する
     * update
     */
    async userV1OrganizationTriggerEmailPutRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/user/v1/organization/trigger_email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrganizationTriggerEmailRequestToJSON(requestParameters.updateOrganizationTriggerEmailRequest)
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => UpdateOrganizationTriggerEmailiResponseFromJSON(jsonValue));
    }
    /**
     * トリガーメールの設定を更新する
     * update
     */
    async userV1OrganizationTriggerEmailPut(requestParameters = {}, initOverrides) {
        const response = await this.userV1OrganizationTriggerEmailPutRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
