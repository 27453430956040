/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CreateMailTemplateRequestMailTemplateFromJSON, CreateMailTemplateRequestMailTemplateToJSON } from './CreateMailTemplateRequestMailTemplate';
export function UpdateMailTemplateRequestFromJSON(json) {
    return UpdateMailTemplateRequestFromJSONTyped(json, false);
}
export function UpdateMailTemplateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        mailTemplate: !exists(json, 'mail_template')
            ? undefined
            : CreateMailTemplateRequestMailTemplateFromJSON(json['mail_template'])
    };
}
export function UpdateMailTemplateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        mail_template: CreateMailTemplateRequestMailTemplateToJSON(value.mailTemplate)
    };
}
