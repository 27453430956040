import { Box, ButtonBase, ButtonBaseProps, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'

export interface ButtonIconProps extends ButtonBaseProps {
  iconBefore?: JSX.Element
  iconAfter?: JSX.Element
}

const useStyles = makeStyles(() => {
  return createStyles({
    button: {
      padding: '8px 10px'
    },
    icon: {
      display: 'inline-flex',
      alignItems: 'center'
    }
  })
})

export const ButtonIcon = ({ iconBefore, iconAfter, children, ...otherProps }: ButtonIconProps) => {
  const classes = useStyles()

  return (
    <ButtonBase className={classes.button} {...otherProps}>
      {iconBefore && (
        <Box mr="8px" className={classes.icon}>
          {iconBefore}
        </Box>
      )}

      {children}

      {iconAfter && (
        <Box ml="8px" className={classes.icon}>
          {iconAfter}
        </Box>
      )}
    </ButtonBase>
  )
}
