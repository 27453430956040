import React, { useMemo } from 'react'
import { Box, Typography, ButtonBase } from '@material-ui/core'
import { Input, DynamicMuiIcon, InformationRow, Tooltip } from 'src/components/atoms'
import { UpdateInformation } from 'src/components/molecules'
import { useGlobalStyles } from 'src/styles/theme'
import { Site } from '@noco/http-client/lib/noco'
import { publicationStatusIconTextMap, publicationStatusTextMap } from 'src/fixtures/utils/text'
import { useUpdateSite } from 'src/fixtures/modules/site/hooks'

export interface SiteInformationProps {
  site: Site
  onClose: () => void
}

export const SiteInformation = ({ site, onClose }: SiteInformationProps) => {
  const { createdAt, createdBy, updatedAt, updatedBy, publicationStatus, memo } = site
  const globalClasses = useGlobalStyles()
  const informationRowData = useMemo(
    () => [
      {
        variant: 'textWithIcon',
        label: '作成者',
        icon: (
          <Box width="20px" height="20px" borderRadius="50%" overflow="hidden">
            {createdBy?.avatar ? (
              <img src={createdBy.avatar?.url} alt="avatar" className={globalClasses.imgCover} />
            ) : (
              <DynamicMuiIcon variant="account" size="20px" color="action" />
            )}
          </Box>
        ),
        content: createdBy?.displayName
      },
      {
        variant: 'textWithIcon',
        label: '公開状態',
        icon: publicationStatus && (
          <DynamicMuiIcon variant={publicationStatusIconTextMap[publicationStatus]} size="16px" color="action" />
        ),
        content: publicationStatus && publicationStatusTextMap[publicationStatus]
      }
    ],
    [createdBy, globalClasses, publicationStatus]
  )

  const { handleUpdateSite } = useUpdateSite(site.id!)

  return (
    <>
      <Box px="6px">
        <Box display="flex" alignItems="center" mb="18px">
          <DynamicMuiIcon variant="info" size="20px" color="action" />
          <Box display="flex" flex={1} pl="6px">
            <Typography variant="h5">サイト情報</Typography>
          </Box>
          <ButtonBase onClick={onClose}>
            <DynamicMuiIcon variant="close" size="20px" color="action" />
          </ButtonBase>
        </Box>
        <Box width="fit-content">
          <Tooltip content={'社内の情報共有のために利用するメモです。 外部には公開されません。'}>
            <Typography variant="h5">社内メモ</Typography>
          </Tooltip>
        </Box>
        <Box mt="6px" />
        <Input
          name="memo"
          placeholder="サイトに関するメモを記入"
          multiline
          rows={5}
          fullWidth
          defaultValue={memo || ''}
          onBlur={e => handleUpdateSite({ memo: e.target.value as string })}
          resize="vertical"
        />
      </Box>

      <Box mt="32px" px="6px">
        {informationRowData.map((row, i) => (
          <InformationRow
            key={i}
            variant={row.variant}
            label={row.label}
            icon={row.icon}
            content={row.content!}
            disableCopy
          />
        ))}
        <Box mt="30px" />
        <UpdateInformation
          createdAt={createdAt!}
          createdBy={createdBy!}
          updatedAt={updatedAt}
          updatedBy={updatedBy}
          createdLabel="作成日"
        />
      </Box>
    </>
  )
}
