import { Box, createStyles, makeStyles } from '@material-ui/core'
import { UserForDisplay } from '@noco/http-client/lib/noco'
import React, { useCallback } from 'react'
import { Colors, SvgIcon, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

export interface NameCellProps {
  value: UserForDisplay & { encryptedEmail?: string }
  iconSize?: 'medium' | 'large'
}

const useStyles = makeStyles(() => {
  return createStyles({
    name: {
      display: 'inline',
      '& p': {
        display: 'inline'
      }
    }
  })
})

export const NameCell = ({ value, iconSize = 'medium' }: NameCellProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const getLabel = useCallback(value => {
    if (!value) return '-'
    if (!!value.lastName || !!value.firstName) {
      return `${value.lastName || ''} ${value.firstName || ''}`
    }
    // NOTE: 名前が未登録の場合、メールアドレスを表示する @cigar-jp
    return value.encryptedEmail
  }, [])

  return (
    <Box display="flex" alignItems="center">
      {value?.avatar ? (
        <Box
          width={iconSize === 'large' ? '24px' : '20px'}
          height={iconSize === 'large' ? '24px' : '20px'}
          borderRadius="50%"
          overflow="hidden"
          mr="6px"
          flexShrink={0}
        >
          <img src={value.avatar.url} alt="avatar" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
      ) : (
        <>
          <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
          <Box width="6px" flexShrink={0} />
        </>
      )}
      <Box whiteSpace="pre-wrap" className={(classes.name, globalClasses.lineclamp1)}>
        <Typography fontSize="s" letterSpacing="tight" lineheight="18.48px">
          {getLabel(value)}
        </Typography>
      </Box>
    </Box>
  )
}
