/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
export function UpdateOrganizationPaymentCancelRequestFromJSON(json) {
    return UpdateOrganizationPaymentCancelRequestFromJSONTyped(json, false);
}
export function UpdateOrganizationPaymentCancelRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        kindList: json['kind_list'],
        reason: json['reason'],
        consent: json['consent'],
        password: json['password']
    };
}
export function UpdateOrganizationPaymentCancelRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        kind_list: value.kindList,
        reason: value.reason,
        consent: value.consent,
        password: value.password
    };
}
