/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner,
  GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSON,
  GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSONTyped,
  GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerToJSON
} from './GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner'

/**
 *
 * @export
 * @interface GetEuOrganizationPolicySettingsResponseData
 */
export interface GetEuOrganizationPolicySettingsResponseData {
  /**
   *
   * @type {Array<GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner>}
   * @memberof GetEuOrganizationPolicySettingsResponseData
   */
  policySettings?: Array<GetEuOrganizationPolicySettingsResponseDataPolicySettingsInner>
}

export function GetEuOrganizationPolicySettingsResponseDataFromJSON(
  json: any
): GetEuOrganizationPolicySettingsResponseData {
  return GetEuOrganizationPolicySettingsResponseDataFromJSONTyped(json, false)
}

export function GetEuOrganizationPolicySettingsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetEuOrganizationPolicySettingsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    policySettings: !exists(json, 'policy_settings')
      ? undefined
      : (json['policy_settings'] as Array<any>).map(
          GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerFromJSON
        )
  }
}

export function GetEuOrganizationPolicySettingsResponseDataToJSON(
  value?: GetEuOrganizationPolicySettingsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    policy_settings:
      value.policySettings === undefined
        ? undefined
        : (value.policySettings as Array<any>).map(GetEuOrganizationPolicySettingsResponseDataPolicySettingsInnerToJSON)
  }
}
