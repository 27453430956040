/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ShallowContactList,
  ShallowContactListFromJSON,
  ShallowContactListFromJSONTyped,
  ShallowContactListToJSON
} from './ShallowContactList'

/**
 *
 * @export
 * @interface GetContactContactListsResponseData
 */
export interface GetContactContactListsResponseData {
  /**
   *
   * @type {string}
   * @memberof GetContactContactListsResponseData
   */
  contactId: string
  /**
   *
   * @type {Array<ShallowContactList>}
   * @memberof GetContactContactListsResponseData
   */
  contactLists: Array<ShallowContactList>
}

export function GetContactContactListsResponseDataFromJSON(json: any): GetContactContactListsResponseData {
  return GetContactContactListsResponseDataFromJSONTyped(json, false)
}

export function GetContactContactListsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactContactListsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    contactId: json['contact_id'],
    contactLists: (json['contact_lists'] as Array<any>).map(ShallowContactListFromJSON)
  }
}

export function GetContactContactListsResponseDataToJSON(value?: GetContactContactListsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    contact_id: value.contactId,
    contact_lists: (value.contactLists as Array<any>).map(ShallowContactListToJSON)
  }
}
