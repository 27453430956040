/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationSignupCheckResponseData,
  OrganizationSignupCheckResponseDataFromJSON,
  OrganizationSignupCheckResponseDataFromJSONTyped,
  OrganizationSignupCheckResponseDataToJSON
} from './OrganizationSignupCheckResponseData'

/**
 *
 * @export
 * @interface OrganizationSignupCheckResponse
 */
export interface OrganizationSignupCheckResponse {
  /**
   *
   * @type {OrganizationSignupCheckResponseData}
   * @memberof OrganizationSignupCheckResponse
   */
  data?: OrganizationSignupCheckResponseData
}

export function OrganizationSignupCheckResponseFromJSON(json: any): OrganizationSignupCheckResponse {
  return OrganizationSignupCheckResponseFromJSONTyped(json, false)
}

export function OrganizationSignupCheckResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationSignupCheckResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data') ? undefined : OrganizationSignupCheckResponseDataFromJSON(json['data'])
  }
}

export function OrganizationSignupCheckResponseToJSON(value?: OrganizationSignupCheckResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: OrganizationSignupCheckResponseDataToJSON(value.data)
  }
}
