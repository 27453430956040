import { Box } from '@material-ui/core'
import Image from 'next/image'
import { Colors, StepDetailTitle, StepTitle, Typography } from 'src/components/atoms'
import { ExportExecution } from 'src/components/molecules'
import { RequestBodyExportCommon, RecordPorterHistory } from '@noco/http-client/lib/noco'

export interface BulkUpdateExportProps {
  mode: 'company' | 'contact'
  onSubmit: (params: RequestBodyExportCommon, hasToast: boolean) => void
  result: RecordPorterHistory | undefined
  error: Error | undefined
}

export const BulkUpdateExport = ({ mode, onSubmit, result, error }: BulkUpdateExportProps) => {
  return (
    <Box bgcolor={Colors.background.lightGray} p="20px" borderRadius="20px">
      <Box display="flex" justifyContent="space-between">
        <Box pr="40px">
          <StepTitle index={1} title="一括編集を行う前に" />
          <Box mt="32px" />
          <Box mb="40px">
            <StepDetailTitle index={1} title={`${mode === 'company' ? '会社' : '連絡先'}一覧をエクスポートしよう`} />
            <Box mt="12px" />
            <Box px="32px">
              <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
                特定のリストのみエクスポートすることもできます
              </Typography>
              <Box mt="16px" />
              <ExportExecution onSubmit={onSubmit} result={result} error={error} mode={mode} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ position: 'relative', width: '248px', height: '272px', mr: '30px' }}>
          <Image src={`/images/import/bulk_update.svg`} layout="fill" objectFit="contain" />
        </Box>
      </Box>
      <StepDetailTitle index={2} title="ExcelファイルをPCで開いてインポートファイルを作成しよう" />
      <Box m="12px 12px 0 32px" whiteSpace="pre-wrap">
        <Typography fontSize="s" lineheight="18.48px" letterSpacing="tight">
          {mode === 'company' ? '会社' : '連絡先'}インポート用Excelファイルを使って、
          {mode === 'company' ? '会社' : '連絡先'}の一括編集用ファイルを作成します
          <br /> 「{mode === 'company' ? '会社' : '連絡先'}ID」は変更しないでください
        </Typography>
        <Box mt="12px" />
        <Box sx={{ position: 'relative', width: '100%', height: '184px' }}>
          <Image
            src={`/images/bulk-update/bulk_update_${mode}_sample_image_01.png`}
            layout="fill"
            objectFit="contain"
          />
        </Box>
      </Box>
    </Box>
  )
}
