/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { FilterBasicResourceListPinsFromJSON, FilterBasicResourceListPinsToJSON } from './FilterBasicResourceListPins';
export function FilterBasicResourceListFromJSON(json) {
    return FilterBasicResourceListFromJSONTyped(json, false);
}
export function FilterBasicResourceListFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        pins: !exists(json, 'pins') ? undefined : json['pins'].map(FilterBasicResourceListPinsFromJSON),
        sizeAll: !exists(json, 'size_all') ? undefined : json['size_all']
    };
}
export function FilterBasicResourceListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        pins: value.pins === undefined ? undefined : value.pins.map(FilterBasicResourceListPinsToJSON),
        size_all: value.sizeAll
    };
}
