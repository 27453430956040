/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  SiteSectionDocument,
  SiteSectionDocumentFromJSON,
  SiteSectionDocumentFromJSONTyped,
  SiteSectionDocumentToJSON
} from './SiteSectionDocument'

/**
 *
 * @export
 * @interface SiteSection
 */
export interface SiteSection {
  /**
   *
   * @type {string}
   * @memberof SiteSection
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof SiteSection
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof SiteSection
   */
  sort?: number
  /**
   *
   * @type {string}
   * @memberof SiteSection
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof SiteSection
   */
  updatedAt?: string
  /**
   *
   * @type {Array<SiteSectionDocument>}
   * @memberof SiteSection
   */
  siteSectionDocuments?: Array<SiteSectionDocument>
}

export function SiteSectionFromJSON(json: any): SiteSection {
  return SiteSectionFromJSONTyped(json, false)
}

export function SiteSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiteSection {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: !exists(json, 'name') ? undefined : json['name'],
    sort: !exists(json, 'sort') ? undefined : json['sort'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    siteSectionDocuments: !exists(json, 'site_section_documents')
      ? undefined
      : (json['site_section_documents'] as Array<any>).map(SiteSectionDocumentFromJSON)
  }
}

export function SiteSectionToJSON(value?: SiteSection | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    sort: value.sort,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    site_section_documents:
      value.siteSectionDocuments === undefined
        ? undefined
        : (value.siteSectionDocuments as Array<any>).map(SiteSectionDocumentToJSON)
  }
}
