/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationChatSettingFromJSON, OrganizationChatSettingToJSON } from './OrganizationChatSetting';
export function GetOrganizationChatSettingResponseDataFromJSON(json) {
    return GetOrganizationChatSettingResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationChatSettingResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organizationChatSetting: !exists(json, 'organization_chat_setting')
            ? undefined
            : OrganizationChatSettingFromJSON(json['organization_chat_setting'])
    };
}
export function GetOrganizationChatSettingResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization_chat_setting: OrganizationChatSettingToJSON(value.organizationChatSetting)
    };
}
