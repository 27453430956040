import { Box, createStyles, makeStyles, useMediaQuery } from '@material-ui/core'
import Link from 'next/link'
import { Button, Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import theme from 'src/styles/theme'

export interface PlanRestrictionBannerProps {
  title: string
  description: string
  remainingDays?: number
}

const useStyles = makeStyles(() => {
  return createStyles({
    link: {
      textDecoration: 'none'
    }
  })
})

export const PLAN_RESTRICTION_BANNER_HEIGHT = 56

export const PlanRestrictionBanner = ({
  title,
  description,
  remainingDays = undefined
}: PlanRestrictionBannerProps) => {
  const classes = useStyles()
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      display={isDownMd ? 'column' : 'flex'}
      alignItems="center"
      justifyContent="space-between"
      height={`${PLAN_RESTRICTION_BANNER_HEIGHT}px`}
      bgcolor={Colors.option.orange}
      color={Colors.functional.whiteText.default}
      p="8px 16px"
    >
      <Box mb={isDownMd ? '4px' : 0}>
        <Box display="flex" alignItems="center" mb="6px">
          <DynamicMuiIcon variant="info" size="16px" />
          <Box mr="8px" />
          <Typography fontSize="s" fontWeight="bold" lineheight="14px">
            {title}
          </Typography>
        </Box>
        <Typography fontSize="s" lineheight="18.48px" letterSpacing="default">
          {description}
        </Typography>
      </Box>
      <Box display="flex" alignItems="baseline">
        {remainingDays !== undefined && (
          <>
            <Typography fontSize="s" lineheight="14px">
              お試し期間終了まで残り
            </Typography>
            <Box px="4px">
              <Typography fontSize="l" fontWeight="bold" lineheight="16px">
                {remainingDays}
              </Typography>
            </Box>
            <Typography fontSize="s" lineheight="14px">
              日
            </Typography>
            <Box mr="16px" />
          </>
        )}
        <Link href="/setting/team/pricing">
          <a className={classes.link}>
            <Button
              buttonColor={Colors.functional.whiteText.default}
              textColor={Colors.option.orange}
              hasBorder={false}
              kind="neutral"
              title="料金プランを見る"
            />
          </a>
        </Link>
      </Box>
    </Box>
  )
}
