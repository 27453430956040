/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationPolicySettingFromJSON, OrganizationPolicySettingToJSON } from './OrganizationPolicySetting';
export function GetOrganizationPolicySettingsResponseDataFromJSON(json) {
    return GetOrganizationPolicySettingsResponseDataFromJSONTyped(json, false);
}
export function GetOrganizationPolicySettingsResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        organizationPolicySettings: !exists(json, 'organization_policy_settings')
            ? undefined
            : json['organization_policy_settings'].map(OrganizationPolicySettingFromJSON)
    };
}
export function GetOrganizationPolicySettingsResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        organization_policy_settings: value.organizationPolicySettings === undefined
            ? undefined
            : value.organizationPolicySettings.map(OrganizationPolicySettingToJSON)
    };
}
