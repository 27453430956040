/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Company, CompanyFromJSON, CompanyFromJSONTyped, CompanyToJSON } from './Company'
import {
  CompanySetting,
  CompanySettingFromJSON,
  CompanySettingFromJSONTyped,
  CompanySettingToJSON
} from './CompanySetting'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetCompaniesResponseData
 */
export interface GetCompaniesResponseData {
  /**
   *
   * @type {Array<Company>}
   * @memberof GetCompaniesResponseData
   */
  companies?: Array<Company>
  /**
   *
   * @type {Array<CompanySetting>}
   * @memberof GetCompaniesResponseData
   */
  companySettings?: Array<CompanySetting>
  /**
   *
   * @type {PageInfo}
   * @memberof GetCompaniesResponseData
   */
  pageInfo?: PageInfo
}

export function GetCompaniesResponseDataFromJSON(json: any): GetCompaniesResponseData {
  return GetCompaniesResponseDataFromJSONTyped(json, false)
}

export function GetCompaniesResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCompaniesResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    companies: !exists(json, 'companies') ? undefined : (json['companies'] as Array<any>).map(CompanyFromJSON),
    companySettings: !exists(json, 'company_settings')
      ? undefined
      : (json['company_settings'] as Array<any>).map(CompanySettingFromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetCompaniesResponseDataToJSON(value?: GetCompaniesResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    companies: value.companies === undefined ? undefined : (value.companies as Array<any>).map(CompanyToJSON),
    company_settings:
      value.companySettings === undefined ? undefined : (value.companySettings as Array<any>).map(CompanySettingToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
