import React, { useMemo } from 'react'
import Image from 'next/image'
import { Box } from '@material-ui/core'
import { Typography, Button, DynamicMuiIcon, MuiIconVariant, IconVariant, SvgIcon } from '../../atoms'
import { PopupTemplate } from '..'
import { useRouter } from 'next/router'
import { ContractFreePlanButtonProps } from './type'

export interface ModalLeadToPaidPlanPlanProps {
  open: boolean
  isVisibleContractFrePlaneButton?: boolean
  onClose: () => void
}

const featureList: Array<{ icon?: MuiIconVariant; svgIcon?: IconVariant; text: string }> = [
  { icon: 'groups', text: '複数人で利用可能に' },
  { icon: 'build', text: '広告の非表示' },
  { svgIcon: 'rocket', text: '資料未閲覧者への追客メール' },
  { icon: 'fileDownload', text: 'データのエクスポート' }
]

export const ModalLeadToPaidPlan = ({
  open,
  isVisibleContractFrePlaneButton,
  onClose
}: ModalLeadToPaidPlanPlanProps) => {
  const router = useRouter()
  const visibleContractFreePlanButtonProps: ContractFreePlanButtonProps = useMemo(() => {
    if (isVisibleContractFrePlaneButton) {
      return {
        hasConfirm: true,
        confirmButtonLabel: '無料プランを利用',
        onConfirm: () => {
          router.push('/setting/plans/prior_confirm_free_plan')
        }
      }
    } else {
      return {}
    }
  }, [isVisibleContractFrePlaneButton, router])

  return (
    <PopupTemplate
      width="814px"
      open={open}
      hasCancel
      cancelButtonLabel="キャンセル"
      onClose={onClose}
      {...visibleContractFreePlanButtonProps}
    >
      <Box px="24px" pt="24px" pb="32px">
        <Box display="flex">
          <Box flex={1} mr="24px">
            <Typography fontWeight="bold" fontSize="xxl" lineheight="36px">
              有料プランで
              <br />
              もっと顧客獲得を強化しよう👍🏻
            </Typography>
            <Box mt="16px" mb="16px">
              <Typography fontSize="s" lineheight="23.8px">
                有料プランをご利用いただくと、さらにパワフルな機能をご利用いただけます。チームの営業力を高め、売上アップや顧客獲得を加速しましょう！
              </Typography>
            </Box>
            {featureList.map((f, i) => (
              <Box key={i} mb="8px" display="flex" alignItems="center">
                <Box color="#6D7784" lineHeight="1">
                  {f.icon && <DynamicMuiIcon variant={f.icon} size="20px" color="inherit" />}
                  {f.svgIcon && <SvgIcon variant={f.svgIcon} size="20px" color="inherit" />}
                </Box>
                <Box mr="6px" />
                <Typography fontWeight="bold" fontSize="m" lineheight="27.2px">
                  {f.text}
                </Typography>
              </Box>
            ))}
            <Typography fontSize="s" lineheight="23.8px">
              ※上記の機能は有料プランの一部です
            </Typography>
            <Box mt="16px" />
            <Button
              title="有料プランをチェックする"
              size="large"
              width="100%"
              onClick={async () => (await router.push('/setting/team/pricing')) && onClose()}
            />
          </Box>
          <Box flex={1} display="flex" justifyContent="center" alignItems="center" bgcolor="#FFF7E2">
            <Image src="/images/paid-plan.svg" alt="paid-plan" width="340px" height="208px" />
          </Box>
        </Box>
      </Box>
    </PopupTemplate>
  )
}
