/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EnumChatSummaryRepliedStatus = {
    Unresponsive: 'unresponsive',
    NoReplyRequired: 'no_reply_required',
    Sent: 'sent'
};
export function EnumChatSummaryRepliedStatusFromJSON(json) {
    return EnumChatSummaryRepliedStatusFromJSONTyped(json, false);
}
export function EnumChatSummaryRepliedStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EnumChatSummaryRepliedStatusToJSON(value) {
    return value;
}
