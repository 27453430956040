import { Box, ButtonBase, makeStyles, Popover, createStyles } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'
import { Button, Colors, DynamicMuiIcon, Input, Tooltip, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import { useListSelectorMaterialables } from 'src/fixtures/modules/selector/hooks'

export interface SelectedMaterial {
  id: string
  title: string
  thumbnailUrl?: string
  kind: 'site' | 'document'
}

export interface SelectSharableMaterialProps {
  onChange?: (option: SelectedMaterial) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      textAlign: 'left'
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    paper: {
      width: '328px',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    }
  })
})

const OPTION_HEIGHT = 34

export const SelectSharableMaterial = ({ onChange }: SelectSharableMaterialProps) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  //- popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setIsOpen] = useState(false)

  const [inputValue, setInputValue] = useState('')
  const [keyword, setKeyword] = useState('')

  const { data: materialableList } = useListSelectorMaterialables({
    page: 1,
    per: 9999,
    text: keyword,
    canShareToContact: true
  })
  const documentOptions: SelectedMaterial[] = useMemo(() => {
    return (
      materialableList?.selectorItemObjects
        ?.filter(itemObject => itemObject.itemType === 'document')
        ?.flatMap(itemObject => itemObject.selectorItems)
        ?.map(
          item =>
            ({
              id: item?.id!,
              title: item?.text!,
              thumbnailUrl: item?.extend?.currentSlideThumbnail?.url,
              kind: 'document'
            } as SelectedMaterial)
        ) || []
    )
  }, [materialableList?.selectorItemObjects])
  const siteOptions: SelectedMaterial[] = useMemo(() => {
    return (
      materialableList?.selectorItemObjects
        ?.filter(itemObject => itemObject.itemType === 'site')
        ?.flatMap(itemObject => itemObject.selectorItems)
        ?.map(item => ({ id: item?.id!, title: item?.text!, kind: 'site' } as SelectedMaterial)) || []
    )
  }, [materialableList?.selectorItemObjects])

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setIsOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setInputValue('')
    setIsOpen(false)
  }, [])
  const handleChange = useCallback((value: string) => {
    setInputValue(value)
    setKeyword(value)
  }, [])
  const handleSelect = useCallback(
    (option: SelectedMaterial) => {
      onChange?.(option)
      handleClose()
    },
    [onChange, handleClose]
  )

  return (
    <Box>
      <Tooltip content={'共有したい資料・資料サイトを指定して、閲覧用のリンクを貼り付けることができます。'}>
        <Button
          startIcon={<DynamicMuiIcon variant="document" size="20px" />}
          kind="neutral"
          title="資料のURL"
          onClick={handleClick}
        />
      </Tooltip>
      <Popover
        id="select-materialable-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorReference="anchorEl"
        classes={{ paper: classes.paper }}
      >
        <Box pt="12px" pb="16px">
          <Box px="12px">
            <Input
              name="keyword"
              value={inputValue}
              onChange={e => handleChange(e.currentTarget.value as string)}
              width="100%"
              startAdornment={
                <Box
                  pl="14px"
                  color={Colors.base.middleGray}
                  fontSize="18px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
                </Box>
              }
              endAdornment={
                <Box
                  p="10px"
                  color={Colors.base.middleGray}
                  fontSize="16px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setInputValue('')}
                  className={globalClasses.cursorPointer}
                >
                  <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
                </Box>
              }
            />
          </Box>
          <Box mt="10px" />
          <Box overflow="auto" maxHeight={OPTION_HEIGHT * 7}>
            <Box px="13px" color={Colors.base.placeHolder}>
              <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
                サイト
              </Typography>
            </Box>
            {siteOptions.map(option => {
              return (
                <ButtonBase
                  key={option.id}
                  onClick={() => handleSelect(option)}
                  className={clsx(classes.buttonBase, classes.hoverButton)}
                >
                  <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                    <Typography variant="body2" noWrap>
                      {option.title || '(no name)'}
                    </Typography>
                  </Box>
                </ButtonBase>
              )
            })}
            {siteOptions.length === 0 && (
              <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                <Typography variant="body2" noWrap>
                  選択できる項目がありません
                </Typography>
              </Box>
            )}
            <Box px="13px" mt="20px" color={Colors.base.placeHolder}>
              <Typography fontSize="s" letterSpacing="none" fontWeight="bold">
                資料
              </Typography>
            </Box>
            {documentOptions.map(option => {
              return (
                <ButtonBase
                  key={option.id}
                  onClick={() => handleSelect(option)}
                  className={clsx(classes.buttonBase, classes.hoverButton)}
                >
                  <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                    <Typography variant="body2" noWrap>
                      {option.title || '(no name)'}
                    </Typography>
                  </Box>
                </ButtonBase>
              )
            })}
            {documentOptions.length === 0 && (
              <Box px="13px" height={`${OPTION_HEIGHT}px`} width={1} display="flex" alignItems="center">
                <Typography variant="body2" noWrap>
                  選択できる項目がありません
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}
