/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetAddressbyPostalcodeFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1UtilKenallApi extends runtime.BaseAPI {
    /**
     * 郵便番号から住所を取得する
     * show
     */
    async userV1UtilAddressGetRaw(requestParameters, initOverrides) {
        if (requestParameters.postalcode === null || requestParameters.postalcode === undefined) {
            throw new runtime.RequiredError('postalcode', 'Required parameter requestParameters.postalcode was null or undefined when calling userV1UtilAddressGet.');
        }
        const queryParameters = {};
        if (requestParameters.postalcode !== undefined) {
            queryParameters['postalcode'] = requestParameters.postalcode;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/util/address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetAddressbyPostalcodeFromJSON(jsonValue));
    }
    /**
     * 郵便番号から住所を取得する
     * show
     */
    async userV1UtilAddressGet(requestParameters, initOverrides) {
        const response = await this.userV1UtilAddressGetRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
