/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetOrganizationPaymentAddonUserOptionResponseData,
  GetOrganizationPaymentAddonUserOptionResponseDataFromJSON,
  GetOrganizationPaymentAddonUserOptionResponseDataFromJSONTyped,
  GetOrganizationPaymentAddonUserOptionResponseDataToJSON
} from './GetOrganizationPaymentAddonUserOptionResponseData'

/**
 *
 * @export
 * @interface GetOrganizationPaymentAddonUserOptionResponse
 */
export interface GetOrganizationPaymentAddonUserOptionResponse {
  /**
   *
   * @type {number}
   * @memberof GetOrganizationPaymentAddonUserOptionResponse
   */
  status: number
  /**
   *
   * @type {GetOrganizationPaymentAddonUserOptionResponseData}
   * @memberof GetOrganizationPaymentAddonUserOptionResponse
   */
  data: GetOrganizationPaymentAddonUserOptionResponseData
}

export function GetOrganizationPaymentAddonUserOptionResponseFromJSON(
  json: any
): GetOrganizationPaymentAddonUserOptionResponse {
  return GetOrganizationPaymentAddonUserOptionResponseFromJSONTyped(json, false)
}

export function GetOrganizationPaymentAddonUserOptionResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPaymentAddonUserOptionResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetOrganizationPaymentAddonUserOptionResponseDataFromJSON(json['data'])
  }
}

export function GetOrganizationPaymentAddonUserOptionResponseToJSON(
  value?: GetOrganizationPaymentAddonUserOptionResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetOrganizationPaymentAddonUserOptionResponseDataToJSON(value.data)
  }
}
