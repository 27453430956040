import { Box } from '@material-ui/core'
import { Document } from '@noco/http-client/lib/noco'

import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { DocumentSettingHeader } from 'src/components/organisms'
import { AppointmentContainer } from './AppointmentContainer'
import { ChatContainer } from './ChatContainer'
import { CtaContainer } from './CtaContainer'

import { InformationContainer } from './InformationContainer'
import { Main } from './Main'
import { Menu, MenuType } from './Menu'
import { MessageContainer } from './MessageContainer'
import { SettingContainer } from './SettingContainer'

interface SlideSectionProps {
  documentId?: string
  currentMode: MenuType
  onClose?: () => void
}

const SlideSection = ({ documentId, currentMode, onClose }: SlideSectionProps) => {
  if (!currentMode) return <></>

  switch (currentMode) {
    case 'info':
      return <InformationContainer onClose={onClose} />
    case 'negotiation':
      return <AppointmentContainer onClose={onClose} />
    case 'message':
      return <MessageContainer onClose={onClose} />
    case 'cta':
      return <CtaContainer onClose={onClose} />
    case 'setting':
      return <SettingContainer documentId={documentId} onClose={onClose} />
    case 'chat':
      return <ChatContainer onClose={onClose} />
  }
}

export const PageDocumentSetting = ({ document }: { document: Document }) => {
  const router = useRouter()
  const currentMode = router.query.mode as MenuType
  const handleClose = useCallback(() => {
    router.replace(location.pathname)
  }, [router])

  return (
    <Box width="100vw" height="100vh" display="flex" flexDirection="column">
      <Box width={1} height="61px" bgcolor="white" borderBottom="1px solid #DADCE0">
        <DocumentSettingHeader
          iconText="戻る"
          headerTitleText={document.title || ''}
          onClickBack={() => router.back()}
          documentId={document.id}
        />
      </Box>
      <Box display="flex" height={1} overflow="hidden">
        <Box width={1} maxWidth={1} height={1} flex="1 1 auto" overflow={'auto'}>
          <Main document={document} />
        </Box>
        {currentMode && (
          <Box
            width="350px"
            flex="1 0 auto"
            height="100%"
            borderLeft="1px solid #DADCE0"
            bgcolor={'white'}
            overflow="hidden auto"
          >
            <SlideSection documentId={document.id} currentMode={currentMode} onClose={handleClose} />
          </Box>
        )}
        <Box flex="1 0 auto" overflow="hidden auto">
          <Menu />
        </Box>
      </Box>
    </Box>
  )
}
