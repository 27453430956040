import useSWR, { Key, SWRResponse, useSWRConfig } from 'swr'
import { Fetcher } from 'swr/dist/types'

export const useStaticSWR = <Data = any, Error = any>(
  key: Key,
  updateData?: Data | Fetcher<Data>,
  initialData?: Data | Fetcher<Data>
): SWRResponse<Data, Error> => {
  const { cache, mutate } = useSWRConfig()
  if (updateData == null) {
    if (!cache.get(key) && initialData != null) {
      mutate(key, initialData, false)
    }
  } else {
    mutate(key, updateData)
  }

  return useSWR(key, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })
}
