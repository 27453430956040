/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const HowDidYouKnowEnum = {
    Google: 'google',
    Sales: 'sales',
    OnlineAds: 'online_ads',
    Direct: 'direct',
    ServiceSite: 'service_site',
    WebinarOrExhibition: 'webinar_or_exhibition',
    SnsOrBlog: 'sns_or_blog',
    MediaArticles: 'media_articles',
    ReferralsOrRecommendations: 'referrals_or_recommendations',
    CampaignOrCoupon: 'campaign_or_coupon',
    ReviewSite: 'review_site',
    Others: 'others'
};
export function HowDidYouKnowEnumFromJSON(json) {
    return HowDidYouKnowEnumFromJSONTyped(json, false);
}
export function HowDidYouKnowEnumFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HowDidYouKnowEnumToJSON(value) {
    return value;
}
