/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  EnumDocumentSlideProcessingStatus,
  EnumDocumentSlideProcessingStatusFromJSON,
  EnumDocumentSlideProcessingStatusFromJSONTyped,
  EnumDocumentSlideProcessingStatusToJSON
} from './EnumDocumentSlideProcessingStatus'
import {
  EnumPublicationStatus,
  EnumPublicationStatusFromJSON,
  EnumPublicationStatusFromJSONTyped,
  EnumPublicationStatusToJSON
} from './EnumPublicationStatus'
import {
  EnumSharingStatus,
  EnumSharingStatusFromJSON,
  EnumSharingStatusFromJSONTyped,
  EnumSharingStatusToJSON
} from './EnumSharingStatus'
import {
  StatisticObject,
  StatisticObjectFromJSON,
  StatisticObjectFromJSONTyped,
  StatisticObjectToJSON
} from './StatisticObject'
import { UploadedFile, UploadedFileFromJSON, UploadedFileFromJSONTyped, UploadedFileToJSON } from './UploadedFile'
import {
  UserForDisplay,
  UserForDisplayFromJSON,
  UserForDisplayFromJSONTyped,
  UserForDisplayToJSON
} from './UserForDisplay'

/**
 *
 * @export
 * @interface IndexDocument
 */
export interface IndexDocument {
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  organizationId?: string
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  description?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  memo?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  label?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  publishedAt?: string | null
  /**
   *
   * @type {EnumDocumentSlideProcessingStatus}
   * @memberof IndexDocument
   */
  slideProcessingStatus?: EnumDocumentSlideProcessingStatus
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  archivedAt?: string | null
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  createdAt?: string
  /**
   *
   * @type {string}
   * @memberof IndexDocument
   */
  updatedAt?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexDocument
   */
  isPublished?: boolean
  /**
   *
   * @type {EnumSharingStatus}
   * @memberof IndexDocument
   */
  sharingStatus?: EnumSharingStatus
  /**
   *
   * @type {EnumPublicationStatus}
   * @memberof IndexDocument
   */
  publicationStatus?: EnumPublicationStatus
  /**
   *
   * @type {UserForDisplay}
   * @memberof IndexDocument
   */
  user?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof IndexDocument
   */
  createdBy?: UserForDisplay
  /**
   *
   * @type {UserForDisplay}
   * @memberof IndexDocument
   */
  updatedBy?: UserForDisplay
  /**
   *
   * @type {UploadedFile}
   * @memberof IndexDocument
   */
  thumbnail?: UploadedFile | null
  /**
   *
   * @type {StatisticObject}
   * @memberof IndexDocument
   */
  statisticObject?: StatisticObject
}

export function IndexDocumentFromJSON(json: any): IndexDocument {
  return IndexDocumentFromJSONTyped(json, false)
}

export function IndexDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndexDocument {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    organizationId: !exists(json, 'organization_id') ? undefined : json['organization_id'],
    title: !exists(json, 'title') ? undefined : json['title'],
    description: !exists(json, 'description') ? undefined : json['description'],
    memo: !exists(json, 'memo') ? undefined : json['memo'],
    label: !exists(json, 'label') ? undefined : json['label'],
    publishedAt: !exists(json, 'published_at') ? undefined : json['published_at'],
    slideProcessingStatus: !exists(json, 'slide_processing_status')
      ? undefined
      : EnumDocumentSlideProcessingStatusFromJSON(json['slide_processing_status']),
    archivedAt: !exists(json, 'archived_at') ? undefined : json['archived_at'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
    isPublished: !exists(json, 'is_published') ? undefined : json['is_published'],
    sharingStatus: !exists(json, 'sharing_status') ? undefined : EnumSharingStatusFromJSON(json['sharing_status']),
    publicationStatus: !exists(json, 'publication_status')
      ? undefined
      : EnumPublicationStatusFromJSON(json['publication_status']),
    user: !exists(json, 'user') ? undefined : UserForDisplayFromJSON(json['user']),
    createdBy: !exists(json, 'created_by') ? undefined : UserForDisplayFromJSON(json['created_by']),
    updatedBy: !exists(json, 'updated_by') ? undefined : UserForDisplayFromJSON(json['updated_by']),
    thumbnail: !exists(json, 'thumbnail') ? undefined : UploadedFileFromJSON(json['thumbnail']),
    statisticObject: !exists(json, 'statistic_object') ? undefined : StatisticObjectFromJSON(json['statistic_object'])
  }
}

export function IndexDocumentToJSON(value?: IndexDocument | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    organization_id: value.organizationId,
    title: value.title,
    description: value.description,
    memo: value.memo,
    label: value.label,
    published_at: value.publishedAt,
    slide_processing_status: EnumDocumentSlideProcessingStatusToJSON(value.slideProcessingStatus),
    archived_at: value.archivedAt,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    is_published: value.isPublished,
    sharing_status: EnumSharingStatusToJSON(value.sharingStatus),
    publication_status: EnumPublicationStatusToJSON(value.publicationStatus),
    user: UserForDisplayToJSON(value.user),
    created_by: UserForDisplayToJSON(value.createdBy),
    updated_by: UserForDisplayToJSON(value.updatedBy),
    thumbnail: UploadedFileToJSON(value.thumbnail),
    statistic_object: StatisticObjectToJSON(value.statisticObject)
  }
}
