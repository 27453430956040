/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Coupon
 */
export interface Coupon {
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Coupon
   */
  code: string
}

export function CouponFromJSON(json: any): Coupon {
  return CouponFromJSONTyped(json, false)
}

export function CouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coupon {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    code: json['code']
  }
}

export function CouponToJSON(value?: Coupon | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    code: value.code
  }
}
