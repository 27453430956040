import { CompanySetting, GetCompanySettingsResponseData } from '@noco/http-client/lib/noco'
import { MuiIconVariant } from 'src/components/atoms'
import { DraggableData } from 'src/components/molecules'
import { CompanyNameCell, LinkCell, NameCell } from 'src/components/organisms/Table/TableParts'

export type CompanySettingValueMap = { [key in string]: { [fieldId: string]: any } }
export const initializeCompanySettingValueMap = (settings: CompanySetting[]): CompanySettingValueMap => {
  const initalSettingValueMap: CompanySettingValueMap = {}
  settings.forEach(s => {
    initalSettingValueMap[s.id!] = {}
    const target = initalSettingValueMap[s.id!]
    s.companySettingFields?.forEach(f => {
      target[f.id!] = ''
    })
  })
  return initalSettingValueMap
}

export const convertFromCompanySettingValueMap = (settingValueMap: CompanySettingValueMap) => {
  const companySettingFieldValues: {
    companySettingId: string
    companySettingFieldId: string
    values: { value: string }[]
  }[] = []
  Object.keys(settingValueMap).forEach(settingId => {
    const filedMap = settingValueMap[settingId]

    Object.keys(filedMap).forEach(fieldId => {
      // v は checkbox の場合のみ string[] / 他の場合は string
      const v: string[] | string = filedMap[fieldId]
      companySettingFieldValues.push({
        companySettingId: settingId,
        companySettingFieldId: fieldId,
        values: Array.isArray(v) ? v.map(item => ({ value: item })) : [{ value: v }]
      })
    })
  })
  return companySettingFieldValues
}

export const createDraggableSettingList = (data: GetCompanySettingsResponseData): DraggableData[] => {
  const activeData: DraggableData[] =
    data.activeCompanySettings?.map(item => ({
      id: item.id!,
      label: item.name!,
      sort: item.sort || 0,
      icon: item.icon as MuiIconVariant,
      description:
        item.kind === 'multiple_field' ? item?.companySettingFields?.map(item => item.name).join(' ,') : undefined,
      isSelected: true,
      isListType: item.companySettingFields?.[0].itemType === 'list',
      canEdit: item.canEdit!
    })) || []

  const inactiveData =
    data.inactiveCompanySettings?.map(item => ({
      id: item.id!,
      label: item.name!,
      sort: item.sort || 0,
      icon: item.icon as MuiIconVariant,
      isSelected: false,
      description:
        item.kind === 'multiple_field' ? item?.companySettingFields?.map(item => item.name).join(' ,') : undefined,
      isListType: item.companySettingFields?.[0].itemType === 'list',
      canEdit: item.canEdit!
    })) || []

  return activeData.concat(inactiveData)
}

export const convertCompanyTableColumns = (activeCompanySettings: CompanySetting[]) => {
  const columns =
    activeCompanySettings?.map(item => {
      if (item.name === '会社名') {
        return {
          name: 'name',
          width: 132,
          title: '会社名',
          render: CompanyNameCell
        }
      }
      if (item.name === 'ホームページURL') {
        return {
          name: item.id!,
          width: 132,
          title: 'ホームページURL',
          render: LinkCell
        }
      }
      if (item.name === '担当者') {
        return {
          name: item.id!,
          width: 132,
          title: '担当者',
          render: NameCell
        }
      }
      return {
        name: item.id!,
        width: 120,
        title: item.name
      }
    }) || []

  const sortedColumns = columns.sort((a, _) => {
    if (a.title === '会社名') return -1
    return 1
  })

  return sortedColumns || []
}
