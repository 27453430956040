import { Box, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { Button, Colors, DynamicMuiIcon } from 'src/components/atoms'

export interface UploadProps {
  onSelectFile: (file: File) => void
}

export const Upload = ({ onSelectFile }: UploadProps) => {
  const handleFile = useCallback(
    (files: FileList) => {
      onSelectFile(files[0])
    },
    [onSelectFile]
  )
  return (
    <Box p="16px 12px" color={Colors.base.middleGray} textAlign="center">
      <Button
        title="画像を選択"
        kind="primary"
        startIcon={<DynamicMuiIcon variant="image" />}
        width="100%"
        onSelectFile={handleFile}
      />
      <Box height="16px" />
      <Typography variant="subtitle1">画像サイズは横1500px以上が推奨です</Typography>
    </Box>
  )
}
