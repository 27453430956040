import { Box } from '@material-ui/core'
import { EnumRecordPorterHistoryOperation } from '@noco/http-client/lib/noco'
import { ImportExecution } from 'src/components/molecules'
import { useBulkUpdateContacts, useCreateContactsExport } from 'src/fixtures/modules/porter/hooks'
import { useGetPorterHistoriesLatest } from 'src/fixtures/modules/recordPorterHistory/hooks'
import { BulkUpdateExport } from '../BulkUpdateExport'
import { RequestBodyExportCommon } from '@noco/http-client/lib/noco'

export const BulkUpdateContact = () => {
  const { data: exportResult } = useGetPorterHistoriesLatest(EnumRecordPorterHistoryOperation.ExportContacts)
  const { handleCreateContactsExport, error: exportError } = useCreateContactsExport(
    EnumRecordPorterHistoryOperation.ExportContacts
  )
  const { data: result } = useGetPorterHistoriesLatest(EnumRecordPorterHistoryOperation.BulkUpdateContacts)
  const { handleBulkUpdateContacts, isLoading, error } = useBulkUpdateContacts(
    EnumRecordPorterHistoryOperation.BulkUpdateContacts
  )

  return (
    <>
      <BulkUpdateExport
        mode="contact"
        onSubmit={(params: RequestBodyExportCommon, hasToast: boolean) => handleCreateContactsExport(params, hasToast)}
        result={exportResult?.recordPorterHistory}
        error={exportError}
      />
      <Box px="20px" mt="40px">
        <ImportExecution
          onSubmit={(value: File) => {
            handleBulkUpdateContacts(value)
          }}
          result={result?.recordPorterHistory}
          requesting={isLoading}
          error={error}
        />
      </Box>
    </>
  )
}
