import { Box, CircularProgress, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Colors, DynamicMuiIcon } from 'src/components/atoms'
import { Header, CompanyDetail, CompanyDetailContent, MIN_SIDE_MENU_SIZE } from 'src/components/organisms'
import { PopupTemplate, ModalContactAdd } from '../../modals'
import { Layout } from 'src/components/commons'
import { useArchiveCompany, useGetCompany, useUpdateCompany } from 'src/fixtures/modules/company/hooks'
import { useRouter } from 'next/router'
import { ActionMenu, ActionMenuItemType } from 'src/components/molecules'

export const PageCompanyDetail = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)

  const router = useRouter()
  const { id } = router.query as { id: string }
  const { handleUpdateCompany } = useUpdateCompany(id)
  const { data } = useGetCompany(id)

  const name = useMemo(() => {
    return data?.companySettings?.find(item => item.name === '会社名')?.companySettingValueText?.text || ''
  }, [data?.companySettings])

  const title = useMemo(() => {
    return data?.archivedAt ? 'アーカイブから取り出す' : 'アーカイブ'
  }, [data?.archivedAt])

  const {
    handleSaveArchived,
    error: archiveError,
    isLoading: isLoadingArchive,
    isPosted
  } = useArchiveCompany({ page: 1, per: 25 })
  const handleClickModalArchiveConfirm = useCallback(
    (isArchived: boolean) => () => {
      handleSaveArchived([id], isArchived)
      setModalArchiveOpen(false)
    },
    [id, handleSaveArchived]
  )

  useEffect(() => {
    if (isPosted && !archiveError) router.push('/companies')
  }, [archiveError, isPosted, router])

  const menuItemList: ActionMenuItemType[] = useMemo(
    () => [
      {
        label: title,
        onClick: () => setModalArchiveOpen(true),
        icon: <DynamicMuiIcon variant="archive" />
      }
    ],
    [title]
  )

  return (
    <>
      {isLoadingArchive && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: 9999,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'rgba(0,0,0,0.6)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Layout>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            bgcolor: Colors.functional.background.default,
            position: 'relative'
          }}
        >
          <Box
            borderBottom={`1px solid ${Colors.background.silver}`}
            position="sticky"
            top={0}
            left={MIN_SIDE_MENU_SIZE + 1}
            zIndex={1000}
            bgcolor="white"
          >
            <Header
              back
              icon="business"
              iconSize="28px"
              title={name}
              rightSection={<RightSection menuItemList={menuItemList} onClick={() => setModalOpen(true)} />}
            />
          </Box>
          <Box height="100%" flexGrow={1} display="flex" overflow="hidden">
            <Box
              width="359px"
              flex="1 0 auto"
              p="16px"
              borderRight={`1px solid ${Colors.background.silver}`}
              overflow="hidden auto"
            >
              {id && (
                <CompanyDetail
                  companyId={id}
                  onChangeUserId={userId => handleUpdateCompany({ company: { userId: userId } })}
                  userId={data?.user?.id}
                />
              )}
            </Box>
            <Box width="calc(100% - 359px)" height={1}>
              <CompanyDetailContent companyId={id} />
            </Box>
          </Box>
        </Box>
      </Layout>
      <ModalContactAdd
        companyId={id}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => setModalOpen(false)}
      />
      <PopupTemplate
        open={modalArchiveOpen}
        title={`会社を${title}`}
        onClose={() => setModalArchiveOpen(false)}
        hasClose
        hasCancel
        hasConfirm
        onConfirm={handleClickModalArchiveConfirm(!!data?.archivedAt)}
        cancelButtonLabel="キャンセル"
        confirmButtonLabel="OK"
      >
        <Box p="24px">
          <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
            {`選択した会社をアーカイブ${data?.archivedAt ? 'から' : 'へ'}移動します\n本当によろしいですか？`}
          </Typography>
        </Box>
      </PopupTemplate>
    </>
  )
}

const RightSection = ({ menuItemList, onClick }: { menuItemList: ActionMenuItemType[]; onClick: () => void }) => {
  return (
    <>
      <Button
        startIcon={<DynamicMuiIcon variant="add" size="16px" color="inherit" />}
        title="連絡先の追加"
        size="medium"
        onClick={onClick}
      />
      <Box mr="12px" />
      <ActionMenu menuItemList={menuItemList} />
    </>
  )
}
