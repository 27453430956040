import { useCallback } from 'react'
import copy from 'copy-to-clipboard'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, makeStyles, Typography, Box, Modal, Slide } from '@material-ui/core'
import { Colors, Pallete } from '../../atoms/Colors'
import { Document } from '../../../../../http-client/lib/noco/models/Document'
import { ButtonIcon, DynamicMuiIcon, Input, SvgIcon } from 'src/components/atoms'

interface Props {
  document: Document
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > .MuiBox-root': {
        minWidth: '300px',
        width: '90vw',
        maxWidth: '580px'
      }
    },
    copyButton: {
      background: Colors.accent.keyPurple.default,
      color: Colors.functional.whiteText.default,
      borderRadius: '4px',
      fontWeight: 'bold'
    },
    snsIcon: {
      cursor: 'pointer'
    }
  })
)

export const ModalDocumentSlideShare: React.VFC<Props> = ({ document, open, onClose }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isPCView = useMediaQuery(theme.breakpoints.up('sm'))

  const handleCopy = useCallback(() => {
    copy(`${document.documentShare?.url}`)
  }, [document])

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Slide in={open} direction="up">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          minWidth="580px"
          minHeight="138px"
          position="relative"
          bgcolor={Pallete.functional.background.default}
          borderRadius="10px"
          border={`1px solid ${Colors.background.gray}`}
        >
          <Box display="flex" alignItems="center" justifyContent="center" p="16px">
            <Typography variant="h3">資料を共有する</Typography>
          </Box>

          {document.documentMiscSetting?.isShowShareButton && (
            <Box boxShadow={`inset 0px 1px 0px ${Colors.background.gray}`} p="28px 24px 20px">
              <Typography variant="h5">共有リンク</Typography>

              <Box mb="8px" />

              <Box display="flex" alignItems="center" flexDirection={isPCView ? 'row' : 'column'}>
                <Box flexGrow={1} sx={isPCView ? {} : { width: '100%' }}>
                  <Input name="url" value={document.documentShare?.url} fullWidth />
                </Box>

                <Box mr="12px" mb="12px" />

                <ButtonIcon
                  iconBefore={<DynamicMuiIcon variant="copy" size="16px" />}
                  classes={{ root: classes.copyButton }}
                  onClick={handleCopy}
                >
                  リンクをコピー
                </ButtonIcon>
              </Box>
            </Box>
          )}

          {document.documentMiscSetting?.isShowSnsButton && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="58px"
              borderRadius="0 0 10px 10px"
              boxShadow={`inset 0px 1px 0px ${Colors.background.gray}`}
            >
              <a
                href={`https://www.facebook.com/share.php?u=${encodeURI(document.documentShare?.url!)}`}
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon variant="facebook" size="28px" />
              </a>

              <Box mr="24px" />

              <a
                href={`http://twitter.com/share?url=${encodeURI(document.documentShare?.url!)}`}
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon variant="circleTwitter" size="28px" />
              </a>

              <Box mr="24px" />

              <a
                href={`https://timeline.line.me/social-plugin/share?url=${encodeURI(document.documentShare?.url!)}`}
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon variant="circleLine" size="28px" />
              </a>

              <Box mr="24px" />

              <a
                href={`https://note.com/intent/post?url=${encodeURI(document.documentShare?.url!)}`}
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon variant="circleNote" size="28px" />
              </a>
            </Box>
          )}
        </Box>
      </Slide>
    </Modal>
  )
}
