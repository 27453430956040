/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AuditColumnValueObjectFromJSON, AuditColumnValueObjectToJSON } from './AuditColumnValueObject';
export function AuditColumnFromJSON(json) {
    return AuditColumnFromJSONTyped(json, false);
}
export function AuditColumnFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        name: !exists(json, 'name') ? undefined : json['name'],
        beforeValueObject: !exists(json, 'before_value_object')
            ? undefined
            : AuditColumnValueObjectFromJSON(json['before_value_object']),
        afterValueObject: !exists(json, 'after_value_object')
            ? undefined
            : AuditColumnValueObjectFromJSON(json['after_value_object'])
    };
}
export function AuditColumnToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        name: value.name,
        before_value_object: AuditColumnValueObjectToJSON(value.beforeValueObject),
        after_value_object: AuditColumnValueObjectToJSON(value.afterValueObject)
    };
}
