import { NocoHttpClient } from '@noco/http-client';
import { createConfigurationWithToken } from '../utils';
var Configuration = NocoHttpClient.Configuration;
var DefaultImageApi = /** @class */ (function () {
    function DefaultImageApi(basePath, fetchApi) {
        this.basePath = basePath;
        this.fetchApi = fetchApi;
    }
    DefaultImageApi.initialize = function (basePath, fetchApi) {
        if (!DefaultImageApi.instance) {
            DefaultImageApi.instance = new DefaultImageApi(basePath, fetchApi);
        }
        return DefaultImageApi.instance;
    };
    DefaultImageApi.prototype.ApiFactory = function (idToken) {
        var config = createConfigurationWithToken({
            basePath: this.basePath,
            fetchApi: this.fetchApi,
            idToken: idToken,
            type: 'user'
        });
        return new NocoHttpClient.ApiUserV1DefaultImageApi(new Configuration(config));
    };
    return DefaultImageApi;
}());
export { DefaultImageApi };
