import { Box, Tab, createStyles, makeStyles, AppBar } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { useCallback, useState, ChangeEvent, useRef, useEffect, useMemo, memo } from 'react'
import { Colors, Tooltip, Typography } from 'src/components/atoms'
import { Layout } from 'src/components/commons'
import { MemoizedSearchAndSuggest } from 'src/components/molecules'
import { Header, useTablePagination } from 'src/components/organisms'
import { useListInboxFilter } from 'src/fixtures/modules/inbox/hooks'
import { InboxContainer } from './InboxContainer'
import { ModalInboxFilter, ModalInboxFilterHandle } from 'src/components/modals/ModalInboxFilter/index'
import { useListStaffLists, useUpdateStaffListsPin } from 'src/fixtures/modules/staffList/hook'
import {
  useListChatSummaries,
  useListChatSummariesCount,
  useListChatSummariesSendedByUser
} from 'src/fixtures/modules/chatSummary/hooks'
import { useListSendErrors, useListSendErrorsCount } from 'src/fixtures/modules/contactEmailTransaction/hooks'
import { useGetMe } from 'src/fixtures/modules/me/hooks'
import {
  UserV1ChatSummariesGetRequest,
  EnumListResourceType,
  EnumChatSummaryRepliedStatus,
  UserV1ChatSummariesSendedByUserGetRequest,
  UserV1ContactsEmailTransactionsSendErrorsGetRequest,
  EnumEmailTransactionableSendStatus
} from '@noco/http-client/lib/noco'
// import { INBOX_POLLING_INTERVAL } from './constant'

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: Colors.background.gray,
      boxShadow: 'none',
      zIndex: 1
    },
    tablist: {
      minHeight: 'auto !important'
    },
    tab: {
      opacity: 1,
      minHeight: 'auto',
      minWidth: '188px',
      padding: '10px 0px',
      color: Colors.base.placeHolder,
      borderRadius: '6px 6px 0px 0px',
      '&.Mui-selected': {
        color: Colors.accent.keyPurple.default,
        background: Colors.functional.background.default,
        border: '1px solid transparent'
      },
      '.MuiTab-wrapper': {
        flexDirection: 'row'
      }
    },
    tabsIndicator: {
      display: 'none'
    },
    tabPanel: {
      height: '100%',
      padding: 0
    },
    badge: {
      height: '18px',
      minWidth: '18px',
      borderRadius: '50px',
      background: `${Colors.accent.keyRed.default}`,
      color: '#fff',
      display: 'inline-flex',
      fontSize: '12px',
      textAlign: 'center',
      fontWeight: 'bold',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 5px',
      marginLeft: '4px'
    },
    tabs: {
      '& .MuiTab-wrapper': {
        flexDirection: 'row-reverse',
        '& :first-child': {
          marginBottom: '0px'
        }
      }
    }
  })
})

const a11yProps = (index: any) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

export type TabValueType = 'incoming' | 'send' | 'error'
export type SelectedCondition = {
  userIds?: string[]
  documentIds?: string[]
  siteIds?: string[]
  repliedStatuses?: string[]
  latestSendedAtOnFrom?: string
  latestSendedAtOnTo?: string
  sendedAtOnFrom?: string
  sendedAtOnTo?: string
  sendUserIds?: string[]
}

// 外部 Props に state の settter が入っているので memo 化
export const PageInbox = memo(({ setCount }: { setCount: (value: number | undefined) => void }) => {
  const classes = useStyles()
  const { page, per, totalPages, setTotalPages, changePage, changePerPage, resetPagination } =
    useTablePagination('inbox')
  const modalRef = useRef<ModalInboxFilterHandle>(null)
  const [openModalInboxFilter, setOpenModalInboxFilter] = useState(false)
  const [tabValue, setTabValue] = useState<TabValueType>('incoming')

  const { data: me } = useGetMe()

  const [incomingParams, setIncomingParams] = useState<UserV1ChatSummariesGetRequest>({})
  const [sendParams, setSendParams] = useState<UserV1ChatSummariesSendedByUserGetRequest>({})
  const [sendErrorParams, setSendErrorParams] = useState<UserV1ContactsEmailTransactionsSendErrorsGetRequest>({})

  const [selectedIncomingCondition, setSelectedIncomingCondition] = useState<SelectedCondition | undefined>(undefined)
  const [selectedSendCondition, setSelectedSendCondition] = useState<SelectedCondition | undefined>(undefined)

  const [openModalClientDetail, setOpenModalClientDetail] = useState(false)
  const [contactIdForModalClientDetail, setContactIdForModalClientDetail] = useState('')

  const { data: filterData } = useListInboxFilter()
  const filter = filterData?.filter

  const { data: chatData, mutate: mutateListChatSummaries } = useListChatSummaries(
    {
      ...incomingParams,
      page: page + 1,
      per
    }
    // { refreshInterval: INBOX_POLLING_INTERVAL }
  )
  const receivedList = chatData?.chatSummaries

  const { data: chatInboxCount, mutate: refreshInboxCount } = useListChatSummariesCount(
    {
      ...incomingParams,
      repliedStatus: EnumChatSummaryRepliedStatus.Unresponsive
    }
    // { refreshInterval: INBOX_POLLING_INTERVAL }
  )
  const unresponsiveCount: number | undefined = chatInboxCount?.count
  setCount(unresponsiveCount)

  const { data: chatSendedByUser, mutate: mutateListChatSummariesSendedByUser } = useListChatSummariesSendedByUser(
    {
      ...sendParams,
      page: page + 1,
      per
    }
    // { refreshInterval: INBOX_POLLING_INTERVAL }
  )
  const sendedByUserList = chatSendedByUser?.chatSummaries

  const { data: chatSendError } = useListSendErrors(
    { ...sendErrorParams, page: page + 1, per }
    // { refreshInterval: INBOX_POLLING_INTERVAL }
  )
  const sendErrorList = chatSendError?.emailTransactions

  const { data: failed } = useListSendErrorsCount(
    {
      ...sendErrorParams,
      sendStatus: EnumEmailTransactionableSendStatus.Fail
    }
    // { refreshInterval: INBOX_POLLING_INTERVAL }
  )
  const sendErrorCount: number | undefined = failed?.count

  const { data: staffList } = useListStaffLists({
    resourceType: EnumListResourceType.InboxItem,
    page: 1,
    per: 999
  })
  const allStaffList = staffList?.staffLists

  const response = useMemo(() => {
    switch (tabValue) {
      case 'send':
        return chatSendedByUser
      case 'error':
        return chatSendError
      default:
        return chatData
    }
  }, [tabValue, chatData, chatSendedByUser, chatSendError])

  const { handleUpdateStaffListsPin } = useUpdateStaffListsPin({
    resourceType: EnumListResourceType.InboxItem,
    page: 1,
    per: 999
  })
  const handleStaffListsPinConfirm = useCallback(
    (userIds: string[]) => {
      handleUpdateStaffListsPin(userIds)
    },
    [handleUpdateStaffListsPin]
  )

  const handleChange = useCallback((event: ChangeEvent<{}>, newValue: TabValueType) => {
    setTabValue(newValue)
  }, [])

  const clearInModal = () => {
    if (modalRef.current) {
      modalRef.current.clear()
    }
  }

  const handleClickRow = useCallback((contactId: string) => {
    setContactIdForModalClientDetail(contactId)
    setOpenModalClientDetail(true)
  }, [])

  const searchForm = useMemo(() => {
    return (
      <Box left="260px" position="fixed">
        <MemoizedSearchAndSuggest type="inbox" width="328px" onClick={handleClickRow} />
      </Box>
    )
  }, [handleClickRow])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (me?.user?.id) {
      setIncomingParams({ ...incomingParams, userIds: [me.user.id] })
      setSendParams({ ...sendParams, sendUserIds: [me.user.id] })
      setSendErrorParams({ ...sendErrorParams, sendUserIds: [me.user.id] })
    }
  }, [me?.user?.id])
  /* eslint-enable */

  useEffect(() => {
    resetPagination()
  }, [resetPagination, openModalInboxFilter, tabValue])

  useEffect(() => {
    if (response?.pageInfo?.totalPages && response?.pageInfo?.totalPages !== totalPages) {
      setTotalPages(response?.pageInfo?.totalPages)
    }
  }, [response?.pageInfo?.totalPages, setTotalPages, totalPages])

  // ステータスの変更の検知を行う
  useEffect(() => {
    mutateListChatSummaries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingParams])

  const tabs = useMemo(
    () => [
      {
        value: 'incoming',
        label: '受信メッセージ',
        count: unresponsiveCount,
        tooltipText: '顧客とのチャットメッセージを表示します。'
      },
      {
        value: 'send',
        label: '送信済み',
        count: 0,
        tooltipText: '顧客に送信したチャットメッセージを表示します。'
      },
      {
        value: 'error',
        label: '送信エラー',
        count: sendErrorCount,
        tooltipText: `メッセージを送信したものの、何らかの理由で顧客に送れなかったメッセージを表示します。

エラー要因としては、メールアドレスの誤り、顧客のメール受信における容量不足、スパム判定などがあります。

尚、メールアドレスに誤りがあった場合、メールアドレスの修正後、チャット画面からメッセージの再送ができます。`
      }
    ],
    [unresponsiveCount, sendErrorCount]
  )

  const image = useMemo(() => {
    if (selectedIncomingCondition && tabValue === 'incoming') {
      return '/images/no_result.png'
    } else if (selectedSendCondition && tabValue === 'send') {
      return '/images/no_result.png'
    } else if (tabValue === 'incoming') {
      return '/images/no_message.png'
    } else {
      return ''
    }
  }, [selectedIncomingCondition, selectedSendCondition, tabValue])

  return (
    <Layout>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box bgcolor={Colors.functional.background.default} borderBottom={`1px solid ${Colors.background.silver}`}>
          <Header icon="document" title="受信トレイ" iconSize="28px" leftSection={searchForm} />
        </Box>
        <Box
          flexGrow={1}
          height="100%"
          bgcolor={Colors.background.gray}
          px="16px"
          pt="16px"
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          <TabContext value={tabValue}>
            <AppBar position="relative" className={classes.root}>
              <TabList
                onChange={handleChange}
                aria-label="inbox tablist"
                classes={{
                  root: classes.tablist,
                  indicator: classes.tabsIndicator
                }}
                className={classes.tabs}
              >
                {tabs.map((tab, i) => (
                  <Tab
                    key={i}
                    value={tab.value}
                    label={
                      <Tooltip content={tab.tooltipText}>
                        <Typography variant="h5">{tab.label}</Typography>
                      </Tooltip>
                    }
                    classes={{ root: classes.tab }}
                    icon={
                      me?.user?.id && !!tab?.count && tab.count > 0 ? (
                        <Box className={classes.badge}>{tab.count}</Box>
                      ) : undefined
                    }
                    {...a11yProps(i)}
                  />
                ))}
              </TabList>
            </AppBar>
            <Box sx={{ height: '100%', flexGrow: '1', overflow: 'hidden' }}>
              <TabPanel value="incoming" classes={{ root: classes.tabPanel }}>
                <InboxContainer
                  loginUser={me}
                  tabValue={tabValue}
                  chatList={receivedList || []}
                  openModalInboxFilter={openModalInboxFilter}
                  onSetOpenModalInboxFilter={setOpenModalInboxFilter}
                  filter={filter}
                  params={incomingParams}
                  onSetParams={setIncomingParams}
                  allStaffList={allStaffList}
                  onStaffListsPinConfirm={handleUpdateStaffListsPin}
                  selectedCondition={selectedIncomingCondition}
                  setSelectedCondition={setSelectedIncomingCondition}
                  onClearCondition={clearInModal}
                  page={page}
                  per={per}
                  totalPages={totalPages}
                  changePage={changePage}
                  changePerPage={changePerPage}
                  reload={mutateListChatSummaries}
                  resetPagination={resetPagination}
                  numberOfRows={response?.pageInfo?.totalCount}
                  showNoDataImage={image}
                  openModalClientDetail={openModalClientDetail}
                  onSetOpenModalClientDetail={setOpenModalClientDetail}
                  contactIdForModalClientDetail={contactIdForModalClientDetail}
                  onClickRow={handleClickRow}
                  onRefreshCount={refreshInboxCount}
                />
              </TabPanel>
              <TabPanel value="send" classes={{ root: classes.tabPanel }}>
                <InboxContainer
                  loginUser={me}
                  tabValue={tabValue}
                  chatList={sendedByUserList || []}
                  openModalInboxFilter={openModalInboxFilter}
                  onSetOpenModalInboxFilter={setOpenModalInboxFilter}
                  filter={filter}
                  params={sendParams}
                  onSetParams={setSendParams}
                  allStaffList={allStaffList}
                  onStaffListsPinConfirm={handleStaffListsPinConfirm}
                  selectedCondition={selectedSendCondition}
                  setSelectedCondition={setSelectedSendCondition}
                  onClearCondition={clearInModal}
                  page={page}
                  per={per}
                  totalPages={totalPages}
                  changePage={changePage}
                  changePerPage={changePerPage}
                  reload={mutateListChatSummariesSendedByUser}
                  resetPagination={resetPagination}
                  numberOfRows={response?.pageInfo?.totalCount}
                  showNoDataImage={image}
                  openModalClientDetail={openModalClientDetail}
                  onSetOpenModalClientDetail={setOpenModalClientDetail}
                  contactIdForModalClientDetail={contactIdForModalClientDetail}
                  onClickRow={handleClickRow}
                  onRefreshCount={refreshInboxCount}
                />
              </TabPanel>
              <TabPanel value="error" classes={{ root: classes.tabPanel }}>
                <InboxContainer
                  loginUser={me}
                  tabValue={tabValue}
                  chatList={sendErrorList || []}
                  filter={filter}
                  params={sendErrorParams}
                  onSetParams={setSendErrorParams}
                  allStaffList={allStaffList}
                  onStaffListsPinConfirm={handleStaffListsPinConfirm}
                  page={page}
                  per={per}
                  totalPages={totalPages}
                  changePage={changePage}
                  changePerPage={changePerPage}
                  resetPagination={resetPagination}
                  numberOfRows={response?.pageInfo?.totalCount}
                  openModalClientDetail={openModalClientDetail}
                  onSetOpenModalClientDetail={setOpenModalClientDetail}
                  contactIdForModalClientDetail={contactIdForModalClientDetail}
                  onClickRow={handleClickRow}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>
      <ModalInboxFilter
        open={openModalInboxFilter}
        onClose={() => setOpenModalInboxFilter(false)}
        onSearch={tabValue === 'incoming' ? setIncomingParams : setSendParams}
        onSetSelectedCondition={tabValue === 'incoming' ? setSelectedIncomingCondition : setSelectedSendCondition}
        filteredCount={tabValue === 'incoming' ? receivedList?.length : sendedByUserList?.length}
        ref={modalRef}
        tabValue={tabValue}
        filter={filter}
      />
    </Layout>
  )
})
