/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CreateContactListResponseData,
  CreateContactListResponseDataFromJSON,
  CreateContactListResponseDataFromJSONTyped,
  CreateContactListResponseDataToJSON
} from './CreateContactListResponseData'

/**
 *
 * @export
 * @interface UpdateContactListResponse
 */
export interface UpdateContactListResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateContactListResponse
   */
  status?: number
  /**
   *
   * @type {CreateContactListResponseData}
   * @memberof UpdateContactListResponse
   */
  data?: CreateContactListResponseData
}

export function UpdateContactListResponseFromJSON(json: any): UpdateContactListResponse {
  return UpdateContactListResponseFromJSONTyped(json, false)
}

export function UpdateContactListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateContactListResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : CreateContactListResponseDataFromJSON(json['data'])
  }
}

export function UpdateContactListResponseToJSON(value?: UpdateContactListResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CreateContactListResponseDataToJSON(value.data)
  }
}
