/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  CopyDocumentResponseData,
  CopyDocumentResponseDataFromJSON,
  CopyDocumentResponseDataFromJSONTyped,
  CopyDocumentResponseDataToJSON
} from './CopyDocumentResponseData'

/**
 *
 * @export
 * @interface FileUpdateDocumentResponse
 */
export interface FileUpdateDocumentResponse {
  /**
   *
   * @type {number}
   * @memberof FileUpdateDocumentResponse
   */
  status?: number
  /**
   *
   * @type {CopyDocumentResponseData}
   * @memberof FileUpdateDocumentResponse
   */
  data?: CopyDocumentResponseData
}

export function FileUpdateDocumentResponseFromJSON(json: any): FileUpdateDocumentResponse {
  return FileUpdateDocumentResponseFromJSONTyped(json, false)
}

export function FileUpdateDocumentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FileUpdateDocumentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    data: !exists(json, 'data') ? undefined : CopyDocumentResponseDataFromJSON(json['data'])
  }
}

export function FileUpdateDocumentResponseToJSON(value?: FileUpdateDocumentResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: CopyDocumentResponseDataToJSON(value.data)
  }
}
