/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { OrganizationAvatarFromJSON, OrganizationAvatarToJSON } from './OrganizationAvatar';
import { OrganizationPlanBasicFromJSON, OrganizationPlanBasicToJSON } from './OrganizationPlanBasic';
import { OrganizationSelectTriggerResentTimingsInnerFromJSON, OrganizationSelectTriggerResentTimingsInnerToJSON } from './OrganizationSelectTriggerResentTimingsInner';
/**
 * @export
 */
export const OrganizationStatusEnum = {
    Draft: 'draft',
    Trialing: 'trialing',
    Active: 'active',
    Unpaid: 'unpaid',
    Canceled: 'canceled',
    Paused: 'paused'
};
export function OrganizationFromJSON(json) {
    return OrganizationFromJSONTyped(json, false);
}
export function OrganizationFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        name: !exists(json, 'name') ? undefined : json['name'],
        status: !exists(json, 'status') ? undefined : json['status'],
        organizationSubdomain: !exists(json, 'organization_subdomain') ? undefined : json['organization_subdomain'],
        feeOfFixed: !exists(json, 'fee_of_fixed') ? undefined : json['fee_of_fixed'],
        feeParPaidUser: !exists(json, 'fee_par_paid_user') ? undefined : json['fee_par_paid_user'],
        avatar: !exists(json, 'avatar') ? undefined : OrganizationAvatarFromJSON(json['avatar']),
        hideAd: !exists(json, 'hide_ad') ? undefined : json['hide_ad'],
        isTriggerResentEmail: !exists(json, 'is_trigger_resent_email') ? undefined : json['is_trigger_resent_email'],
        triggerResentTiming: !exists(json, 'trigger_resent_timing') ? undefined : json['trigger_resent_timing'],
        selectTriggerResentTimings: !exists(json, 'select_trigger_resent_timings')
            ? undefined
            : json['select_trigger_resent_timings'].map(OrganizationSelectTriggerResentTimingsInnerFromJSON),
        plan: !exists(json, 'plan') ? undefined : OrganizationPlanBasicFromJSON(json['plan']),
        discardedAt: !exists(json, 'discarded_at') ? undefined : json['discarded_at'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
        trialEndAt: !exists(json, 'trial_end_at') ? undefined : json['trial_end_at'],
        canceledAt: !exists(json, 'canceled_at') ? undefined : json['canceled_at']
    };
}
export function OrganizationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        name: value.name,
        status: value.status,
        organization_subdomain: value.organizationSubdomain,
        fee_of_fixed: value.feeOfFixed,
        fee_par_paid_user: value.feeParPaidUser,
        avatar: OrganizationAvatarToJSON(value.avatar),
        hide_ad: value.hideAd,
        is_trigger_resent_email: value.isTriggerResentEmail,
        trigger_resent_timing: value.triggerResentTiming,
        select_trigger_resent_timings: value.selectTriggerResentTimings === undefined
            ? undefined
            : value.selectTriggerResentTimings.map(OrganizationSelectTriggerResentTimingsInnerToJSON),
        plan: OrganizationPlanBasicToJSON(value.plan),
        discarded_at: value.discardedAt,
        created_at: value.createdAt,
        updated_at: value.updatedAt,
        trial_end_at: value.trialEndAt,
        canceled_at: value.canceledAt
    };
}
