/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  DiscardCompanyMemoResponse,
  DiscardCompanyMemoResponseFromJSON,
  DiscardCompanyMemoResponseToJSON,
  UpdateCompanyMemoRequest,
  UpdateCompanyMemoRequestFromJSON,
  UpdateCompanyMemoRequestToJSON,
  UpdateCompanyMemoResponse,
  UpdateCompanyMemoResponseFromJSON,
  UpdateCompanyMemoResponseToJSON
} from '../models'

export interface UserV1CompaniesCompanyIdMemosCompanyMemoIdDeleteRequest {
  companyId: string
  companyMemoId: string
}

export interface UserV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPutRequest {
  companyId: string
  companyMemoId: string
}

export interface UserV1CompaniesCompanyIdMemosCompanyMemoIdPutRequest {
  companyId: string
  companyMemoId: string
  updateCompanyMemoRequest?: UpdateCompanyMemoRequest
}

/**
 *
 */
export class ApiUserV1CompanyMemoApi extends runtime.BaseAPI {
  /**
   */
  async userV1CompaniesCompanyIdMemosCompanyMemoIdDeleteRaw(
    requestParameters: UserV1CompaniesCompanyIdMemosCompanyMemoIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<object>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDelete.'
      )
    }

    if (requestParameters.companyMemoId === null || requestParameters.companyMemoId === undefined) {
      throw new runtime.RequiredError(
        'companyMemoId',
        'Required parameter requestParameters.companyMemoId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDelete.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}/memos/{company_memo_id}`
          .replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId)))
          .replace(`{${'company_memo_id'}}`, encodeURIComponent(String(requestParameters.companyMemoId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse<any>(response)
  }

  /**
   */
  async userV1CompaniesCompanyIdMemosCompanyMemoIdDelete(
    requestParameters: UserV1CompaniesCompanyIdMemosCompanyMemoIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<object> {
    const response = await this.userV1CompaniesCompanyIdMemosCompanyMemoIdDeleteRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPutRaw(
    requestParameters: UserV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<DiscardCompanyMemoResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPut.'
      )
    }

    if (requestParameters.companyMemoId === null || requestParameters.companyMemoId === undefined) {
      throw new runtime.RequiredError(
        'companyMemoId',
        'Required parameter requestParameters.companyMemoId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}/memos/{company_memo_id}/discard`
          .replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId)))
          .replace(`{${'company_memo_id'}}`, encodeURIComponent(String(requestParameters.companyMemoId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => DiscardCompanyMemoResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPut(
    requestParameters: UserV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<DiscardCompanyMemoResponse> {
    const response = await this.userV1CompaniesCompanyIdMemosCompanyMemoIdDiscardPutRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   */
  async userV1CompaniesCompanyIdMemosCompanyMemoIdPutRaw(
    requestParameters: UserV1CompaniesCompanyIdMemosCompanyMemoIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UpdateCompanyMemoResponse>> {
    if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
      throw new runtime.RequiredError(
        'companyId',
        'Required parameter requestParameters.companyId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdPut.'
      )
    }

    if (requestParameters.companyMemoId === null || requestParameters.companyMemoId === undefined) {
      throw new runtime.RequiredError(
        'companyMemoId',
        'Required parameter requestParameters.companyMemoId was null or undefined when calling userV1CompaniesCompanyIdMemosCompanyMemoIdPut.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/user/v1/companies/{company_id}/memos/{company_memo_id}`
          .replace(`{${'company_id'}}`, encodeURIComponent(String(requestParameters.companyId)))
          .replace(`{${'company_memo_id'}}`, encodeURIComponent(String(requestParameters.companyMemoId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateCompanyMemoRequestToJSON(requestParameters.updateCompanyMemoRequest)
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue => UpdateCompanyMemoResponseFromJSON(jsonValue))
  }

  /**
   */
  async userV1CompaniesCompanyIdMemosCompanyMemoIdPut(
    requestParameters: UserV1CompaniesCompanyIdMemosCompanyMemoIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UpdateCompanyMemoResponse> {
    const response = await this.userV1CompaniesCompanyIdMemosCompanyMemoIdPutRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
