/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  FilterStaffPins1,
  FilterStaffPins1FromJSON,
  FilterStaffPins1FromJSONTyped,
  FilterStaffPins1ToJSON
} from './FilterStaffPins1'
import { PageInfo, PageInfoFromJSON, PageInfoFromJSONTyped, PageInfoToJSON } from './PageInfo'

/**
 *
 * @export
 * @interface GetStaffListsResponseData
 */
export interface GetStaffListsResponseData {
  /**
   *
   * @type {Array<FilterStaffPins1>}
   * @memberof GetStaffListsResponseData
   */
  staffLists?: Array<FilterStaffPins1>
  /**
   *
   * @type {PageInfo}
   * @memberof GetStaffListsResponseData
   */
  pageInfo?: PageInfo
}

export function GetStaffListsResponseDataFromJSON(json: any): GetStaffListsResponseData {
  return GetStaffListsResponseDataFromJSONTyped(json, false)
}

export function GetStaffListsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetStaffListsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    staffLists: !exists(json, 'staff_lists')
      ? undefined
      : (json['staff_lists'] as Array<any>).map(FilterStaffPins1FromJSON),
    pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
  }
}

export function GetStaffListsResponseDataToJSON(value?: GetStaffListsResponseData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    staff_lists:
      value.staffLists === undefined ? undefined : (value.staffLists as Array<any>).map(FilterStaffPins1ToJSON),
    page_info: PageInfoToJSON(value.pageInfo)
  }
}
