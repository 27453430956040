/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OrganizationPaymentBillingTransaction
 */
export interface OrganizationPaymentBillingTransaction {
  /**
   *
   * @type {string}
   * @memberof OrganizationPaymentBillingTransaction
   */
  planName: string
  /**
   *
   * @type {string}
   * @memberof OrganizationPaymentBillingTransaction
   */
  billingStatus: OrganizationPaymentBillingTransactionBillingStatusEnum
  /**
   *
   * @type {string}
   * @memberof OrganizationPaymentBillingTransaction
   */
  humanizedStatus: string
  /**
   *
   * @type {number}
   * @memberof OrganizationPaymentBillingTransaction
   */
  amount: number
  /**
   *
   * @type {Date}
   * @memberof OrganizationPaymentBillingTransaction
   */
  paidAt: Date | null
  /**
   *
   * @type {string}
   * @memberof OrganizationPaymentBillingTransaction
   */
  receiptUrl: string | null
}

/**
 * @export
 */
export const OrganizationPaymentBillingTransactionBillingStatusEnum = {
  Draft: 'draft',
  Open: 'open',
  Paid: 'paid',
  Uncollectible: 'uncollectible',
  Void: 'void'
} as const
export type OrganizationPaymentBillingTransactionBillingStatusEnum =
  typeof OrganizationPaymentBillingTransactionBillingStatusEnum[keyof typeof OrganizationPaymentBillingTransactionBillingStatusEnum]

export function OrganizationPaymentBillingTransactionFromJSON(json: any): OrganizationPaymentBillingTransaction {
  return OrganizationPaymentBillingTransactionFromJSONTyped(json, false)
}

export function OrganizationPaymentBillingTransactionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrganizationPaymentBillingTransaction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    planName: json['plan_name'],
    billingStatus: json['billing_status'],
    humanizedStatus: json['humanized_status'],
    amount: json['amount'],
    paidAt: json['paid_at'] === null ? null : new Date(json['paid_at']),
    receiptUrl: json['receipt_url']
  }
}

export function OrganizationPaymentBillingTransactionToJSON(value?: OrganizationPaymentBillingTransaction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan_name: value.planName,
    billing_status: value.billingStatus,
    humanized_status: value.humanizedStatus,
    amount: value.amount,
    paid_at: value.paidAt === null ? null : value.paidAt.toISOString(),
    receipt_url: value.receiptUrl
  }
}
