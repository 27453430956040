/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function GetAddressbyPostalcodeDataFromJSON(json) {
    return GetAddressbyPostalcodeDataFromJSONTyped(json, false);
}
export function GetAddressbyPostalcodeDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        prefecture: !exists(json, 'prefecture') ? undefined : json['prefecture'],
        address1: !exists(json, 'address_1') ? undefined : json['address_1'],
        address2: !exists(json, 'address_2') ? undefined : json['address_2'],
        address3: !exists(json, 'address_3') ? undefined : json['address_3']
    };
}
export function GetAddressbyPostalcodeDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        prefecture: value.prefecture,
        address_1: value.address1,
        address_2: value.address2,
        address_3: value.address3
    };
}
