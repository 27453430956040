import React, { useMemo } from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { Colors, SelectBox, SvgIcon, Tooltip, Typography } from 'src/components/atoms'
import { useListCompanySettings } from 'src/fixtures/modules/companySetting/hooks'
import { ContactSettingValueMap } from 'src/fixtures/modules/contact/utils'
import { SettingInputRow } from '..'
import { useListSelectorUsersList } from 'src/fixtures/modules/selector/hooks'

export interface CompanyCreateFormChangeValueProps {
  settingId: string
  fieldId: string
  value: any
}

interface CompanyCreateFormProps {
  userId: string
  settingValues: ContactSettingValueMap
  onChangeUser: SelectInputProps['onChange']
  onChangeValues: ({ settingId, fieldId, value }: CompanyCreateFormChangeValueProps) => void
}

const useStyles = makeStyles(() => {
  return createStyles({
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover'
    }
  })
})

export const CompanyCreateForm = ({ userId, settingValues, onChangeUser, onChangeValues }: CompanyCreateFormProps) => {
  const classes = useStyles()
  const { data: responseSetting } = useListCompanySettings()
  const activeSettings = responseSetting?.activeCompanySettings
  const { data: selectorUserRes } = useListSelectorUsersList({ page: 1, per: 9999 })
  const selectorUserList = selectorUserRes?.selectorItems

  const optionList = useMemo(() => {
    return selectorUserList?.map(item => ({
      value: item.id!,
      label: item.text,
      startElement: (
        <Box width="20px" height="20px" mr="4px" position="relative">
          {item.extend?.avatar ? (
            <img src={item.extend.avatar.url} className={classes.img} />
          ) : (
            <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
          )}
        </Box>
      )
    }))
  }, [selectorUserList, classes.img])

  return (
    <Box bgcolor={Colors.functional.background.default} height={1} px="24px" pt="16px">
      <Box width="50%">
        <Box width="fit-content">
          <Tooltip content="会社には、必ず担当者を設定する必要があります。">
            <Typography fontSize="s" lineheight="14px" fontWeight="bold">
              担当者
            </Typography>
          </Tooltip>
        </Box>
        <Box mt="6px" />
        <SelectBox
          listWidth="176px"
          value={userId || 0}
          placeholder="選択してください"
          optionList={optionList || []}
          onChange={onChangeUser}
        />
      </Box>
      <Box mt="16px" />
      <Box width="100%" height="1px" bgcolor={Colors.background.gray} />
      <Box mt="18px" />
      {activeSettings?.map(setting => {
        const values = settingValues[setting.id!]
        return (
          <SettingInputRow
            kind="company"
            key={setting.id}
            name={String(setting.id)}
            required={setting.isRequiredOnUser}
            values={values}
            setting={setting}
            onChange={data => {
              onChangeValues({ settingId: setting.id!, fieldId: data.fieldId, value: data.value })
            }}
          />
        )
      })}
      <Box height="120px" />
    </Box>
  )
}
