import { Box } from '@material-ui/core'
import { BillingInvoice, BillingInvoiceItem } from '@noco/http-client/lib/noco'
import React from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { InvoiceItemRow } from './InvoiceItemRow'

export interface ScheduleBillingNextInvoiceDetailProps {
  scheduleBillingNextInvoice: BillingInvoice
}

const convertItemName = (item: BillingInvoiceItem): string => {
  let humanizedQuantity: string = ''
  if (item.quantity > 1) {
    const unitLabel = item.unitLabel ?? ''
    humanizedQuantity = ` ${item.quantity}${unitLabel}`
  }

  let humanizedRecurring: string = ''
  if (item.isMonthlyRecurring) {
    humanizedRecurring = '（毎月）'
  }

  return `${item.productName}${humanizedQuantity}${humanizedRecurring}`
}

export const ScheduleBillingNextInvoiceDetail = ({
  scheduleBillingNextInvoice
}: ScheduleBillingNextInvoiceDetailProps) => {
  return (
    <Box sx={{ bgcolor: Colors.background.lightGray, borderRadius: '10px', p: '20px' }}>
      <Box mb="16px">
        <Typography fontSize="s" fontWeight="bold" lineheight="1">
          参考｜２ヶ月目以降のご利用料金
        </Typography>
      </Box>
      <Box mb="16px" color="#6D7784">
        <Typography fontSize="s" lineheight="1" color="inherit" letterSpacing="normal">
          プランの変更およびオプションの追加により、実際のご請求金額と異なる場合がございます
        </Typography>
      </Box>
      <Box>
        <Box mb="16px">
          {scheduleBillingNextInvoice.items.map((item, i) => (
            <InvoiceItemRow key={i} itemName={convertItemName(item)} amount={item.amount} />
          ))}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box width="300px">
            <InvoiceItemRow
              itemName="小計"
              amount={scheduleBillingNextInvoice.subtotal}
              sx={{ borderBottom: 0, py: 0 }}
            />
            <Box mb="4px" />
            <InvoiceItemRow itemName="消費税" amount={scheduleBillingNextInvoice.tax} sx={{ borderColor: '#EEF1F4' }} />
            <InvoiceItemRow
              itemName="お支払額（税込、2ヶ月目以降）"
              amount={scheduleBillingNextInvoice.total}
              sx={{ borderBottom: 0 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
