import { Box, createStyles, makeStyles } from '@material-ui/core'
import {
  BillingInvoice,
  OrganizationPaymentContractBasicInfo,
  OrganizationPlanDetail,
  OrganizationPlanDetailGradeEnum,
  ScheduleBillingContract
} from '@noco/http-client/lib/noco'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, ButtonIcon, Card, Colors, SvgIcon, Typography } from 'src/components/atoms'
import { Message } from 'src/components/atoms/Message'
import { SelectPlanCard } from 'src/components/molecules'
import {
  useContractFreePlan,
  useCreateOrganizationPaymentScheduleBillingInvoice
} from 'src/fixtures/modules/organizationPayment/hooks'
import { PaymentBillingDetail } from '../../PaymentBillingDetail'
import { PaymentScheduleBillingInvoice } from '../../PaymentScheduleBillingInvoice'

export interface ContractThirdStepProps {
  plan: OrganizationPlanDetail
  userOptionQuantity: number
  contractBasicInfo?: OrganizationPaymentContractBasicInfo
  onSubmit: () => void
  onBack: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      padding: '0 24px'
    }
  })
)

export const ContractThirdStep = ({
  plan,
  userOptionQuantity,
  contractBasicInfo,
  onSubmit,
  onBack
}: ContractThirdStepProps) => {
  const classes = useStyles()
  const [scheduleBillingContract, setScheduleBillingContract] = useState<ScheduleBillingContract>()
  const [scheduleBillingInvoice, setScheduleBillingInvoice] = useState<BillingInvoice>()
  const [scheduleBillingNextInvoice, setScheduleBillingNextInvoice] = useState<BillingInvoice>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isLoading, error, handleContractFreePlan } = useContractFreePlan()
  const { handleCreateScheduleBillingInvoice } = useCreateOrganizationPaymentScheduleBillingInvoice()

  const handleScheduleBilingInvoice = useCallback(
    async (planId: string, userOptionQuantity: number) => {
      const data = await handleCreateScheduleBillingInvoice({
        planId,
        addon: { userOptionQuantity }
      })
      setScheduleBillingContract(data?.scheduleBillingContract)
      setScheduleBillingInvoice(data?.scheduleBillingInvoice)
      setScheduleBillingNextInvoice(data?.scheduleBillingNextInvoice)
    },
    [handleCreateScheduleBillingInvoice]
  )

  useEffect(() => {
    if (plan.grade === OrganizationPlanDetailGradeEnum.FreePremium) {
      handleScheduleBilingInvoice(plan.id, userOptionQuantity)
    }
  }, [plan.grade, handleScheduleBilingInvoice, plan.id, userOptionQuantity])

  const handleClick = () => {
    if (!contractBasicInfo) return

    handleContractFreePlan({ contractBasicInfo }, { planId: plan.id, addon: { userOptionQuantity } })
    setIsSubmitting(true)
  }

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      onSubmit()
      setIsSubmitting(false)
    }
  }, [isSubmitting, setIsSubmitting, isLoading, error, onSubmit])

  return (
    <Box>
      <Box mb="16px">
        <SelectPlanCard planName={plan.name} unitAmount={plan.unitAmount} />
      </Box>

      {contractBasicInfo && (
        <Box mb="24px">
          <Card
            label="ご契約者情報"
            sx={{ bgcolor: '#fff' }}
            actionComponent={
              <Button
                hasBorder={false}
                title="変更"
                kind="secondary"
                variant="outlined"
                size="small"
                onClick={() => onBack()}
              />
            }
          >
            <PaymentBillingDetail isPaid={false} contractBasicInfo={contractBasicInfo} />
          </Card>
        </Box>
      )}

      {scheduleBillingContract && scheduleBillingInvoice && scheduleBillingNextInvoice && (
        <Box mb="16px">
          <Card sx={{ bgcolor: '#fff' }}>
            <PaymentScheduleBillingInvoice
              isPaid={false}
              scheduleBillingContract={scheduleBillingContract}
              scheduleBillingInvoice={scheduleBillingInvoice}
              scheduleBillingNextInvoice={scheduleBillingNextInvoice}
            />
          </Card>
        </Box>
      )}

      {error && (
        <Box mb="24px" padding="0 24px">
          <Message type="error">
            <Typography fontSize="s" fontWeight="bold">
              {error.message}
            </Typography>
          </Message>
        </Box>
      )}

      <Box display="flex" justifyContent="center" mb="24px">
        <Button
          tabIndex={-1}
          className={classes.submitButton}
          title="プランを申し込む"
          onClick={() => !isLoading && handleClick()}
          disabled={isLoading}
          loading={isLoading}
          width="217px"
          size="large"
        />
      </Box>
      <Box display="flex" justifyContent="center" mb="24px" color={Colors.accent.keyBlue.default}>
        <ButtonIcon
          tabIndex={-1}
          iconBefore={<SvgIcon variant="leftArrow" size="16px" color="inherit" />}
          onClick={() => onBack()}
        >
          <Typography fontSize="s" fontWeight="bold" lineheight="1" color="inherit">
            戻る
          </Typography>
        </ButtonIcon>
      </Box>
    </Box>
  )
}
