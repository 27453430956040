/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  RequestContactSettingFieldValue,
  RequestContactSettingFieldValueFromJSON,
  RequestContactSettingFieldValueFromJSONTyped,
  RequestContactSettingFieldValueToJSON
} from './RequestContactSettingFieldValue'

/**
 *
 * @export
 * @interface CreateContactRequestContact
 */
export interface CreateContactRequestContact {
  /**
   *
   * @type {string}
   * @memberof CreateContactRequestContact
   */
  userId?: string
  /**
   *
   * @type {Array<RequestContactSettingFieldValue>}
   * @memberof CreateContactRequestContact
   */
  contactSettingFieldValues?: Array<RequestContactSettingFieldValue>
}

export function CreateContactRequestContactFromJSON(json: any): CreateContactRequestContact {
  return CreateContactRequestContactFromJSONTyped(json, false)
}

export function CreateContactRequestContactFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactRequestContact {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userId: !exists(json, 'user_id') ? undefined : json['user_id'],
    contactSettingFieldValues: !exists(json, 'contact_setting_field_values')
      ? undefined
      : (json['contact_setting_field_values'] as Array<any>).map(RequestContactSettingFieldValueFromJSON)
  }
}

export function CreateContactRequestContactToJSON(value?: CreateContactRequestContact | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    user_id: value.userId,
    contact_setting_field_values:
      value.contactSettingFieldValues === undefined
        ? undefined
        : (value.contactSettingFieldValues as Array<any>).map(RequestContactSettingFieldValueToJSON)
  }
}
