/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { GetCompanyFilterResponseFromJSON, GetContactFilterResponseFromJSON, GetDocumentFilterResponseFromJSON, GetInboxFilterResponseFromJSON, GetSiteFilterResponseFromJSON } from '../models';
/**
 *
 */
export class ApiUserV1FilterApi extends runtime.BaseAPI {
    /**
     * フィルター（会社用）を取得する
     * show
     */
    async userV1CompanyFilterGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/company_filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetCompanyFilterResponseFromJSON(jsonValue));
    }
    /**
     * フィルター（会社用）を取得する
     * show
     */
    async userV1CompanyFilterGet(initOverrides) {
        const response = await this.userV1CompanyFilterGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * フィルター（連絡先用）を取得する
     * show
     */
    async userV1ContactFilterGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/contact_filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetContactFilterResponseFromJSON(jsonValue));
    }
    /**
     * フィルター（連絡先用）を取得する
     * show
     */
    async userV1ContactFilterGet(initOverrides) {
        const response = await this.userV1ContactFilterGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * フィルター（資料用）を取得する
     * show
     */
    async userV1DocumentFilterGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/document_filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetDocumentFilterResponseFromJSON(jsonValue));
    }
    /**
     * フィルター（資料用）を取得する
     * show
     */
    async userV1DocumentFilterGet(initOverrides) {
        const response = await this.userV1DocumentFilterGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * フィルター（受信リスト用）を取得する
     * show
     */
    async userV1InboxFilterGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/inbox_filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetInboxFilterResponseFromJSON(jsonValue));
    }
    /**
     * フィルター（受信リスト用）を取得する
     * show
     */
    async userV1InboxFilterGet(initOverrides) {
        const response = await this.userV1InboxFilterGetRaw(initOverrides);
        return await response.value();
    }
    /**
     * フィルター（資料サイト用）を取得する
     * show
     */
    async userV1SiteFilterGetRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/user/v1/site_filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters
        }, initOverrides);
        return new runtime.JSONApiResponse(response, jsonValue => GetSiteFilterResponseFromJSON(jsonValue));
    }
    /**
     * フィルター（資料サイト用）を取得する
     * show
     */
    async userV1SiteFilterGet(initOverrides) {
        const response = await this.userV1SiteFilterGetRaw(initOverrides);
        return await response.value();
    }
}
