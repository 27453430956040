/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
export function ViewHistoryGraphObjectFromJSON(json) {
    return ViewHistoryGraphObjectFromJSONTyped(json, false);
}
export function ViewHistoryGraphObjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        numberOfPage: !exists(json, 'number_of_page') ? undefined : json['number_of_page'],
        averageElapsedSecond: !exists(json, 'average_elapsed_second') ? undefined : json['average_elapsed_second'],
        countOfViews: !exists(json, 'count_of_views') ? undefined : json['count_of_views']
    };
}
export function ViewHistoryGraphObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        number_of_page: value.numberOfPage,
        average_elapsed_second: value.averageElapsedSecond,
        count_of_views: value.countOfViews
    };
}
