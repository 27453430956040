import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CircleTwitter: React.VFC<SvgIconProps> = ({ color = '#1DA1F2', ...rest }) => {
  return (
    <SvgIcon {...rest} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill={color} />
      <path
        d="M20.9132 11.3608C20.9233 11.5073 20.9233 11.6537 20.9233 11.8015C20.9233 16.3055 17.4945 21.5 11.2248 21.5V21.4973C9.37276 21.5 7.55915 20.9695 6 19.9692C6.26931 20.0016 6.53997 20.0178 6.8113 20.0185C8.34615 20.0198 9.83713 19.5048 11.0446 18.5565C9.58605 18.5288 8.307 17.5778 7.86018 16.1894C8.37113 16.288 8.89759 16.2677 9.39909 16.1307C7.80889 15.8094 6.66483 14.4123 6.66483 12.7897C6.66483 12.7748 6.66483 12.7607 6.66483 12.7465C7.13865 13.0104 7.66917 13.1569 8.21184 13.1731C6.7141 12.1721 6.25243 10.1796 7.15688 8.62181C8.88747 10.7513 11.4408 12.0459 14.1818 12.1829C13.9071 10.999 14.2824 9.75844 15.1679 8.92622C16.5408 7.6357 18.7 7.70185 19.9905 9.07403C20.7539 8.92352 21.4855 8.64341 22.1551 8.24654C21.9006 9.03556 21.3681 9.70579 20.6567 10.1317C21.3323 10.052 21.9924 9.87116 22.6141 9.5951C22.1565 10.2809 21.58 10.8782 20.9132 11.3608Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default CircleTwitter
