import {
  CreateOrganizationPaymentScheduleBillingInvoiceRequest,
  UpdateOrganizationPaymentAddonUserOptionRequest,
  UpdateOrganizationPaymentApplyPlanFreeRequest,
  UpdateOrganizationPaymentApplyPlanPaidRequest,
  UpdateOrganizationPaymentCancelRequest,
  UpdateOrganizationPaymentCurrentPlanRequest,
  UpdateOrganizationPaymentDiscountRequest
} from '@noco/http-client/lib/noco'
import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { removeTokenFromCookie } from 'src/fixtures/utils'
import { handleError } from 'src/fixtures/utils/error'
import useSWR from 'swr'
import { useAuthenticate } from '../auth/hooks'
import { nocoSDK } from '../initialize'
import { useGetOrganization } from '../organization/hooks'
import { SWRCachePath } from '../swr-cach-path'

const STRIPE_PRICE_FREE_ID = process.env.STRIPE_PRICE_FREE_ID ?? ''

// プラン一覧
export const useListOrganizationPaymentPlans = () => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentPlansGet()
      .then(res => res.data)
  }, [auth, organizationPaymentApi])
  return useSWR(auth && SWRCachePath.listOrganizationPaymentPlans(), func)
}

// 現在のプラン
export const useGetOrganizationPaymentCurrentPlan = () => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentCurrentPlanGet()
      .then(res => res.data)
  }, [auth, organizationPaymentApi])
  return useSWR(auth && SWRCachePath.getOrganizationPaymentCurrentPlan(), func)
}

// ユーザー追加オプション情報
export const useGetOrganizationAddonUserOption = () => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentAddonUserOptionGet()
      .then(res => res.data)
  }, [auth, organizationPaymentApi])
  return useSWR(auth && SWRCachePath.getOrganizationPaymentnAddonUserOption(), func)
}

// ご契約者情報の取得
export const useGetOrganizationPaymentBillingInfo = () => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentBillingInfoGet()
      .then(res => res.data)
  }, [auth, organizationPaymentApi])
  return useSWR(auth && SWRCachePath.getOrganizationPaymentBillingInfo(), func)
}

// ご請求先情報の更新（無料）
export const useUpdateOrganizationPaymentBillingInfoFree = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const handleUpdateBillingInfoFree = useCallback(
    async (
      updateOrganizationPaymentApplyPlanFreeRequest: UpdateOrganizationPaymentApplyPlanFreeRequest,
      errorNotify: boolean = false
    ) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        // 請求先情報登録（有料）
        await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentBillingInfoFreePut({ updateOrganizationPaymentApplyPlanFreeRequest })
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Billing Info Paid' }, errorNotify)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, organizationPaymentApi]
  )

  return { error, isLoading, handleUpdateBillingInfoFree }
}

// ご請求先情報の更新（有料）
export const useUpdateOrganizationPaymentBillingInfoPaid = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const handleUpdateBillingInfoPaid = useCallback(
    async (
      updateOrganizationPaymentApplyPlanPaidRequest: UpdateOrganizationPaymentApplyPlanPaidRequest,
      errorNotify: boolean = false
    ) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        // 請求先情報登録（有料）
        await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentBillingInfoPaidPut({ updateOrganizationPaymentApplyPlanPaidRequest })
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Billing Info Paid' }, errorNotify)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, organizationPaymentApi]
  )

  return { error, isLoading, handleUpdateBillingInfoPaid }
}

// 無料プランへの契約
export const useContractFreePlan = () => {
  const { data: auth } = useAuthenticate()
  const { mutate: mutateOrganization } = useGetOrganization()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const handleContractFreePlan = useCallback(
    async (
      updateOrganizationPaymentApplyPlanFreeRequest: UpdateOrganizationPaymentApplyPlanFreeRequest,
      updateOrganizationPaymentCurrentPlanRequest: UpdateOrganizationPaymentCurrentPlanRequest,
      errorNotify: boolean = false
    ) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        // 請求先情報登録（無料）
        await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentBillingInfoFreePut({ updateOrganizationPaymentApplyPlanFreeRequest })
        // プラン変更
        await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentCurrentPlanPatch({ updateOrganizationPaymentCurrentPlanRequest })
        // Organization を最新の状態に更新
        mutateOrganization()
      } catch (err) {
        await handleError(err, { setError, tag: 'Contract Free Plan' }, errorNotify)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mutateOrganization, organizationPaymentApi]
  )

  return { error, isLoading, handleContractFreePlan }
}

// 有料プランへの契約
export const useContractPaidPlan = () => {
  const { data: auth } = useAuthenticate()
  const { mutate: mutateOrganization } = useGetOrganization()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const handleContractPaidPlan = useCallback(
    async (
      updateOrganizationPaymentApplyPlanPaidRequest: UpdateOrganizationPaymentApplyPlanPaidRequest,
      updateOrganizationPaymentCurrentPlanRequest: UpdateOrganizationPaymentCurrentPlanRequest,
      updateOrganizationPaymentDiscountRequest?: UpdateOrganizationPaymentDiscountRequest,
      errorNotify: boolean = false
    ) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        // クーポンを適用
        if (updateOrganizationPaymentDiscountRequest) {
          await organizationPaymentApi
            ?.ApiFactory(auth.token)
            .userV1OrganizationPaymentDiscountPut({ updateOrganizationPaymentDiscountRequest })
        }
        // 請求先情報登録（有料）
        await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentBillingInfoPaidPut({ updateOrganizationPaymentApplyPlanPaidRequest })
        // プラン変更
        await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentCurrentPlanPatch({ updateOrganizationPaymentCurrentPlanRequest })
        // Organization を最新の状態に更新
        mutateOrganization()
      } catch (err) {
        await handleError(err, { setError, tag: 'Contract Paid Plan' }, errorNotify)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mutateOrganization, organizationPaymentApi]
  )

  return { error, isLoading, handleContractPaidPlan }
}

// 契約内容の確認・請求予定金額の計算
export const useCreateOrganizationPaymentScheduleBillingInvoice = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const handleCreateScheduleBillingInvoice = useCallback(
    async (
      createOrganizationPaymentScheduleBillingInvoiceRequest: CreateOrganizationPaymentScheduleBillingInvoiceRequest,
      errorNotify: boolean = false
    ) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        const res = await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentScheduleBillingInvoicePost({
            createOrganizationPaymentScheduleBillingInvoiceRequest
          })
        return res?.data
      } catch (err) {
        await handleError(err, { setError, tag: 'Create Schedule Billing Invoice' }, errorNotify)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, organizationPaymentApi]
  )
  return { error, isLoading, handleCreateScheduleBillingInvoice }
}

// ユーザー追加オプションの更新
export const useUpdateAddonUserOption = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const handleUpdateAddonUserOption = useCallback(
    async (
      updateOrganizationPaymentAddonUserOptionRequest: UpdateOrganizationPaymentAddonUserOptionRequest,
      errorNotify: boolean = false
    ) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)
        await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentAddonUserOptionPatch({ updateOrganizationPaymentAddonUserOptionRequest })
      } catch (err) {
        await handleError(err, { setError, tag: 'Update Addon User Option' }, errorNotify)
      } finally {
        setIsLoading(false)
      }
    },
    [auth, organizationPaymentApi]
  )
  return { error, isLoading, handleUpdateAddonUserOption }
}

// 解約
export const useCancelPayment = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi
  const router = useRouter()
  const { mutate: mutateAuth } = useAuthenticate()
  const handleCancelPayment = useCallback(
    async (updateOrganizationPaymentCancelRequest: UpdateOrganizationPaymentCancelRequest) => {
      try {
        if (!auth) return
        setIsLoading(true)
        setError(undefined)

        const res = await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentCancelPatch({ updateOrganizationPaymentCancelRequest })
        // MEMO: cancelNow が True の場合は即時退会としてログアウト・解約完了ページへ遷移。
        // cancelNow が False なら、契約最終日時までは引き続きサービスを利用できる。
        if (res?.data?.cancelNow) {
          await router.replace('/setting/team/contract/canceled', '/setting/team/contract/canceled')
          removeTokenFromCookie()
          mutateAuth(undefined)
        }
      } catch (err) {
        await handleError(err, { setError, tag: 'Cancel Payment' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, mutateAuth, organizationPaymentApi, router]
  )

  return { error, isLoading, handleCancelPayment }
}

// 解約手続きのキャンセル
export const useRevokeCancelPayment = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi
  const handleRevokeCancelPayment = useCallback(async () => {
    try {
      if (!auth) return
      setIsLoading(true)
      setError(undefined)
      await organizationPaymentApi?.ApiFactory(auth.token).userV1OrganizationPaymentCancelDelete()
    } catch (err) {
      await handleError(err, { setError, tag: 'Revoke Cancel Payment' })
    } finally {
      setIsLoading(false)
    }
  }, [auth, organizationPaymentApi])

  return { error, isLoading, handleRevokeCancelPayment }
}

// free plan を取得する
export const useGetFreePlan = () => {
  const { data } = useListOrganizationPaymentPlans()
  // TODO: @enta プラン一覧ではなく１件ごとに取れるようにしたい
  const plans = useMemo(() => data?.plans || [], [data?.plans])
  const freePlan = useMemo(() => plans.find(plan => plan.stripePriceId === STRIPE_PRICE_FREE_ID), [plans])
  return { freePlan }
}

export const useGetPlan = (planId: string) => {
  const { data } = useListOrganizationPaymentPlans()
  // TODO: @enta プラン一覧ではなく１件ごとに取れるようにしたい
  const plans = useMemo(() => data?.plans || [], [data?.plans])
  const plan = useMemo(() => plans.find(plan => plan.id === planId), [planId, plans])
  return { plan }
}

// plan の制限（メールテンプレート数とユーザー数）を確認する
export const useGetPlanCheckUsageLimit = (planId?: string) => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi
  const func = useCallback(async () => {
    if (!auth || !planId) return
    return await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGet({ planId: planId }, { cache: 'no-store' })
      .then(res => res.data)
  }, [auth, organizationPaymentApi, planId])
  return useSWR(auth && SWRCachePath.getPlanCheckUsageLimit(planId), func)
}

// plan の制限（メールテンプレート数とユーザー数）を確認する
type PlanMigrationCountLimit = {
  current: number
  incoming: number
}
export const useGetPlanLimit = (planId?: string) => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi
  const {
    data: response,
    mutate,
    error
  } = useSWR(auth && planId && SWRCachePath.getPlanLimit(planId), async () => {
    if (!auth || !planId) return
    const data = await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentPlansPlanIdCheckUsageLimitGet({ planId })
      .then(res => res.data)

    let isOverUserLimit = false
    let isOverMailTemplate = false
    let emailTemplateCountLimit: PlanMigrationCountLimit = { current: 0, incoming: 0 }
    let userCountLimit: PlanMigrationCountLimit = { current: 0, incoming: 0 }

    const user = data?.usageLimit?.users
    const template = data?.usageLimit?.emailTemplates
    // 利用可能ユーザー数の判定
    if (user?.incomingLimit?.value && user?.currentUsage) {
      isOverUserLimit = user.currentUsage > user.incomingLimit.value
      userCountLimit = { current: user.currentUsage, incoming: user.incomingLimit.value }
    }

    // 利用可能メールテンプレート数の判定。無制限の場合には finite が false になるのでその場合は常に isOverMailTemplate は false
    if (!template?.incomingLimit.finite) {
      isOverMailTemplate = false
    } else if (template?.incomingLimit?.value && template?.currentUsage) {
      isOverMailTemplate = template.currentUsage > template.incomingLimit.value
      emailTemplateCountLimit = { current: template.currentUsage, incoming: template.incomingLimit.value }
    }

    return {
      needDowngrade: isOverMailTemplate || isOverUserLimit,
      userCountLimit,
      emailTemplateCountLimit
    }
  })

  return {
    needDowngrade: response?.needDowngrade,
    emailTemplateCountLimit: response?.emailTemplateCountLimit || { current: 0, incoming: 0 },
    userCountLimit: response?.userCountLimit || { current: 0, incoming: 0 },
    mutate,
    isLoading: !response && !error
  }
}

// クーポンが利用可能か確認
export const useGetCouponCode = () => {
  const { data: auth } = useAuthenticate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi
  const handleGetCouponCode = useCallback(
    async (couponCode: string, incomingPlanId?: string) => {
      try {
        const requestParameters = { couponCode, incomingPlanId }
        if (!auth) return
        setError(undefined)
        return await organizationPaymentApi
          ?.ApiFactory(auth.token)
          .userV1OrganizationPaymentCouponsCouponCodeGet(requestParameters)
          .then(res => res.data)
      } catch (err) {
        await handleError(err, { setError, tag: 'Get Coupon Code' })
      } finally {
        setIsLoading(false)
      }
    },
    [auth, organizationPaymentApi]
  )
  return { error, isLoading, handleGetCouponCode }
}

// プラン変更履歴
export const useListOrganizationPaymentPlanTransactions = () => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentPlanTransactionsGet()
      .then(res => res.data)
  }, [auth, organizationPaymentApi])
  return useSWR(auth && SWRCachePath.listOrganizationPaymentPlanTransactions(), func)
}

// お支払い履歴一覧
export const useListOrganizationPaymentBillingTransactions = () => {
  const { data: auth } = useAuthenticate()
  const organizationPaymentApi = nocoSDK.client?.userService.organizationPaymentApi

  const func = useCallback(async () => {
    if (!auth) return
    return await organizationPaymentApi
      ?.ApiFactory(auth.token)
      .userV1OrganizationPaymentBillingTransactionsGet()
      .then(res => res.data)
  }, [auth, organizationPaymentApi])
  return useSWR(auth && SWRCachePath.listOrganizationPaymentBillingTransactions(), func)
}
