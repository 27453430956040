import { Box } from '@material-ui/core'
import React from 'react'
import { Colors, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'

export interface DocumentTitleCellProps {
  value: string
  imageUrl: string
}

export const DocumentTitleCell = ({ value, imageUrl }: DocumentTitleCellProps) => {
  const globalClasses = useGlobalStyles()
  return (
    <Box display="flex" alignItems="center">
      <Box
        width="54px"
        height="31px"
        borderRadius="2px"
        mr="8px"
        border={`1px solid ${Colors.background.silver}`}
        overflow="hidden"
        flexShrink={0}
      >
        <img src={imageUrl} alt="thumbnail" className={globalClasses.imgCover} />
      </Box>
      <Box width={1} whiteSpace="pre-wrap" className={globalClasses.lineclamp1}>
        <Typography fontSize="s" fontWeight="bold">
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
