/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ConfirmMeChangeEmailRequest
 */
export interface ConfirmMeChangeEmailRequest {
  /**
   *
   * @type {string}
   * @memberof ConfirmMeChangeEmailRequest
   */
  token?: string
}

export function ConfirmMeChangeEmailRequestFromJSON(json: any): ConfirmMeChangeEmailRequest {
  return ConfirmMeChangeEmailRequestFromJSONTyped(json, false)
}

export function ConfirmMeChangeEmailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ConfirmMeChangeEmailRequest {
  if (json === undefined || json === null) {
    return json
  }
  return {
    token: !exists(json, 'token') ? undefined : json['token']
  }
}

export function ConfirmMeChangeEmailRequestToJSON(value?: ConfirmMeChangeEmailRequest | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    token: value.token
  }
}
