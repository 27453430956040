/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPolicySetting,
  OrganizationPolicySettingFromJSON,
  OrganizationPolicySettingFromJSONTyped,
  OrganizationPolicySettingToJSON
} from './OrganizationPolicySetting'

/**
 *
 * @export
 * @interface GetOrganizationPolicySettingsResponseData
 */
export interface GetOrganizationPolicySettingsResponseData {
  /**
   *
   * @type {Array<OrganizationPolicySetting>}
   * @memberof GetOrganizationPolicySettingsResponseData
   */
  organizationPolicySettings?: Array<OrganizationPolicySetting>
}

export function GetOrganizationPolicySettingsResponseDataFromJSON(
  json: any
): GetOrganizationPolicySettingsResponseData {
  return GetOrganizationPolicySettingsResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationPolicySettingsResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationPolicySettingsResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationPolicySettings: !exists(json, 'organization_policy_settings')
      ? undefined
      : (json['organization_policy_settings'] as Array<any>).map(OrganizationPolicySettingFromJSON)
  }
}

export function GetOrganizationPolicySettingsResponseDataToJSON(
  value?: GetOrganizationPolicySettingsResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_policy_settings:
      value.organizationPolicySettings === undefined
        ? undefined
        : (value.organizationPolicySettings as Array<any>).map(OrganizationPolicySettingToJSON)
  }
}
