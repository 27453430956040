/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  GetContactContactListsResponseData,
  GetContactContactListsResponseDataFromJSON,
  GetContactContactListsResponseDataFromJSONTyped,
  GetContactContactListsResponseDataToJSON
} from './GetContactContactListsResponseData'

/**
 *
 * @export
 * @interface GetContactContactListsResponse
 */
export interface GetContactContactListsResponse {
  /**
   *
   * @type {number}
   * @memberof GetContactContactListsResponse
   */
  status: number
  /**
   *
   * @type {GetContactContactListsResponseData}
   * @memberof GetContactContactListsResponse
   */
  data: GetContactContactListsResponseData
}

export function GetContactContactListsResponseFromJSON(json: any): GetContactContactListsResponse {
  return GetContactContactListsResponseFromJSONTyped(json, false)
}

export function GetContactContactListsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetContactContactListsResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: GetContactContactListsResponseDataFromJSON(json['data'])
  }
}

export function GetContactContactListsResponseToJSON(value?: GetContactContactListsResponse | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: GetContactContactListsResponseDataToJSON(value.data)
  }
}
