/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationChatSetting,
  OrganizationChatSettingFromJSON,
  OrganizationChatSettingFromJSONTyped,
  OrganizationChatSettingToJSON
} from './OrganizationChatSetting'

/**
 *
 * @export
 * @interface GetOrganizationChatSettingResponseData
 */
export interface GetOrganizationChatSettingResponseData {
  /**
   *
   * @type {OrganizationChatSetting}
   * @memberof GetOrganizationChatSettingResponseData
   */
  organizationChatSetting?: OrganizationChatSetting
}

export function GetOrganizationChatSettingResponseDataFromJSON(json: any): GetOrganizationChatSettingResponseData {
  return GetOrganizationChatSettingResponseDataFromJSONTyped(json, false)
}

export function GetOrganizationChatSettingResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetOrganizationChatSettingResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    organizationChatSetting: !exists(json, 'organization_chat_setting')
      ? undefined
      : OrganizationChatSettingFromJSON(json['organization_chat_setting'])
  }
}

export function GetOrganizationChatSettingResponseDataToJSON(
  value?: GetOrganizationChatSettingResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    organization_chat_setting: OrganizationChatSettingToJSON(value.organizationChatSetting)
  }
}
