/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  OrganizationPaymentContract,
  OrganizationPaymentContractFromJSON,
  OrganizationPaymentContractFromJSONTyped,
  OrganizationPaymentContractToJSON
} from './OrganizationPaymentContract'
import {
  OrganizationPlanDetail,
  OrganizationPlanDetailFromJSON,
  OrganizationPlanDetailFromJSONTyped,
  OrganizationPlanDetailToJSON
} from './OrganizationPlanDetail'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentCancelResponseData
 */
export interface UpdateOrganizationPaymentCancelResponseData {
  /**
   *
   * @type {OrganizationPlanDetail}
   * @memberof UpdateOrganizationPaymentCancelResponseData
   */
  plan: OrganizationPlanDetail
  /**
   *
   * @type {OrganizationPaymentContract}
   * @memberof UpdateOrganizationPaymentCancelResponseData
   */
  contract?: OrganizationPaymentContract
  /**
   *
   * @type {boolean}
   * @memberof UpdateOrganizationPaymentCancelResponseData
   */
  cancelNow: boolean
}

export function UpdateOrganizationPaymentCancelResponseDataFromJSON(
  json: any
): UpdateOrganizationPaymentCancelResponseData {
  return UpdateOrganizationPaymentCancelResponseDataFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentCancelResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentCancelResponseData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    plan: OrganizationPlanDetailFromJSON(json['plan']),
    contract: !exists(json, 'contract') ? undefined : OrganizationPaymentContractFromJSON(json['contract']),
    cancelNow: json['cancel_now']
  }
}

export function UpdateOrganizationPaymentCancelResponseDataToJSON(
  value?: UpdateOrganizationPaymentCancelResponseData | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plan: OrganizationPlanDetailToJSON(value.plan),
    contract: OrganizationPaymentContractToJSON(value.contract),
    cancel_now: value.cancelNow
  }
}
