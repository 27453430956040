import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Ai = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="46" height="42" rx="4" fill="#FF6600" />
      <path
        d="M14.2929 28.385H17.5254C17.6116 28.385 17.6892 28.3246 17.7064 28.247L18.6546 24.8392H22.3181L23.3353 28.247C23.3611 28.3246 23.4387 28.385 23.5249 28.385H26.878C26.9901 28.385 27.0763 28.2987 27.0763 28.1952C27.0763 28.1607 27.0677 28.1348 27.0591 28.1089L22.7836 14.3224C22.7577 14.2448 22.6888 14.193 22.6026 14.193H18.5081C18.4219 14.193 18.3443 14.2448 18.3271 14.3224L14.1033 28.1348C14.0861 28.1952 14.1033 28.2556 14.1378 28.3073C14.1722 28.3505 14.2326 28.385 14.2929 28.385ZM30.033 17.1349C31.1191 17.1349 31.9035 16.4016 31.9035 15.3836C31.8776 14.3397 31.1191 13.615 30.0502 13.615C29.5071 13.615 29.0244 13.8048 28.6882 14.1413C28.3779 14.4605 28.2141 14.9091 28.2228 15.3836C28.2141 15.8495 28.3779 16.2808 28.6969 16.6C29.0244 16.9451 29.4985 17.1349 30.033 17.1349ZM28.4986 17.9804C28.3865 17.9804 28.3003 18.0667 28.3003 18.1702V28.1952C28.3003 28.2987 28.3865 28.385 28.4986 28.385H31.6104C31.7138 28.385 31.8 28.2987 31.8 28.1952V18.1702C31.8 18.0667 31.7138 17.9804 31.6104 17.9804H28.4986ZM21.7492 22.1215H19.2149L19.9649 19.4298C20.0942 18.9467 20.2235 18.3773 20.3528 17.8251C20.3786 17.6957 20.4045 17.5749 20.439 17.4455C20.6027 18.1098 20.7837 18.8259 20.9648 19.4384L21.7492 22.1215Z"
        fill="white"
      />
    </SvgIcon>
  )
}
export default Ai
