/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UpdateOrganizationPaymentPlanFreeResponseData,
  UpdateOrganizationPaymentPlanFreeResponseDataFromJSON,
  UpdateOrganizationPaymentPlanFreeResponseDataFromJSONTyped,
  UpdateOrganizationPaymentPlanFreeResponseDataToJSON
} from './UpdateOrganizationPaymentPlanFreeResponseData'

/**
 *
 * @export
 * @interface UpdateOrganizationPaymentPlanFreeResponse
 */
export interface UpdateOrganizationPaymentPlanFreeResponse {
  /**
   *
   * @type {number}
   * @memberof UpdateOrganizationPaymentPlanFreeResponse
   */
  status: number
  /**
   *
   * @type {UpdateOrganizationPaymentPlanFreeResponseData}
   * @memberof UpdateOrganizationPaymentPlanFreeResponse
   */
  data: UpdateOrganizationPaymentPlanFreeResponseData
}

export function UpdateOrganizationPaymentPlanFreeResponseFromJSON(
  json: any
): UpdateOrganizationPaymentPlanFreeResponse {
  return UpdateOrganizationPaymentPlanFreeResponseFromJSONTyped(json, false)
}

export function UpdateOrganizationPaymentPlanFreeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateOrganizationPaymentPlanFreeResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    status: json['status'],
    data: UpdateOrganizationPaymentPlanFreeResponseDataFromJSON(json['data'])
  }
}

export function UpdateOrganizationPaymentPlanFreeResponseToJSON(
  value?: UpdateOrganizationPaymentPlanFreeResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    status: value.status,
    data: UpdateOrganizationPaymentPlanFreeResponseDataToJSON(value.data)
  }
}
