import React, { useMemo, useCallback, useState, forwardRef, useImperativeHandle } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide, ButtonBase } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography, Checkbox, SvgIcon } from 'src/components/atoms'
import { OptionData } from 'src/components/molecules'
import { SelectChip, SlideForm } from 'src/components/organisms'
import { SelectorItemObject } from '@noco/http-client/lib/noco'
import {
  UserV1ChatSummariesGetRequest,
  UserV1ChatSummariesSendedByUserGetRequest,
  InboxFilter,
  EnumChatSummaryRepliedStatus
} from '@noco/http-client/lib/noco'
import { CalenderInput } from '../ModalContactCompanyFilter/CalenderInput'
import { useDateRangePicker, Props } from 'src/components/atoms/DateRangePicker'
import { format } from 'date-fns'
import {
  useListSelectorUsersList,
  useListSelectorDocuments,
  useListSelectorSites
} from 'src/fixtures/modules/selector/hooks'
import { SelectedCondition, TabValueType } from 'src/components/pages/PageInbox'
import Image from 'next/image'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: Colors.background.lightGray,
      boxShadow: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    closeButton: {
      padding: '22px'
    },
    img: {
      borderRadius: '50%'
    }
  })
)

export type GetRequest = UserV1ChatSummariesGetRequest & UserV1ChatSummariesSendedByUserGetRequest

// type declared in useImperativeHandle
export interface ModalInboxFilterHandle {
  clear: () => void
}
interface ModalInboxFilterProps {
  open: boolean
  onClose: () => void
  onSearch: (condition: UserV1ChatSummariesGetRequest | UserV1ChatSummariesSendedByUserGetRequest) => void
  onSetSelectedCondition: (value: SelectedCondition | undefined) => void
  filteredCount: number | undefined
  tabValue: TabValueType
  filter: InboxFilter | undefined
}

export const ModalInboxFilter = forwardRef<ModalInboxFilterHandle, ModalInboxFilterProps>(
  ({ open, onClose, onSearch, onSetSelectedCondition, filteredCount, tabValue, filter }, ref) => {
    const classes = useStyles()

    const [keyword, setKeyword] = useState('')
    const [incomingCondition, setIncomingCondition] = useState<GetRequest | undefined>(undefined)
    const [sendCondition, setSendCondition] = useState<GetRequest | undefined>(undefined)
    const [lastIncomingCondition, setLastIncomingCondition] = useState<GetRequest | undefined>(incomingCondition)
    const [lastSendCondition, setLastSendCondition] = useState<GetRequest | undefined>(sendCondition)

    const DateRangeIncoming = useDateRangePicker({})
    const DateRangeSend = useDateRangePicker({})

    const { data: userList } = useListSelectorUsersList({ page: 1, per: 9999, text: keyword })
    const { data: documentList } = useListSelectorDocuments({ page: 1, per: 9999, text: keyword })
    const { data: sitesList } = useListSelectorSites({ page: 1, per: 9999, text: keyword })
    const { data: sendUserList } = useListSelectorUsersList({ page: 1, per: 9999, text: keyword })

    const [condition, lastCondition, setCondition, setLastCondition, from, to, DateRange, user, list]: [
      GetRequest | undefined,
      GetRequest | undefined,
      React.Dispatch<React.SetStateAction<GetRequest | undefined>>,
      React.Dispatch<React.SetStateAction<GetRequest | undefined>>,
      'latestSendedAtOnFrom' | 'sendedAtOnFrom',
      'latestSendedAtOnTo' | 'sendedAtOnTo',
      Props,
      string,
      SelectorItemObject | undefined
    ] = useMemo(() => {
      if (tabValue === 'incoming') {
        return [
          incomingCondition,
          lastIncomingCondition,
          setIncomingCondition,
          setLastIncomingCondition,
          'latestSendedAtOnFrom',
          'latestSendedAtOnTo',
          DateRangeIncoming,
          'userIds',
          userList
        ]
      } else {
        return [
          sendCondition,
          lastSendCondition,
          setSendCondition,
          setLastSendCondition,
          'sendedAtOnFrom',
          'sendedAtOnTo',
          DateRangeSend,
          'sendUserIds',
          sendUserList
        ]
      }
    }, [
      tabValue,
      incomingCondition,
      sendCondition,
      lastIncomingCondition,
      lastSendCondition,
      DateRangeIncoming,
      DateRangeSend,
      userList,
      sendUserList
    ])

    const statusList = useMemo(() => filter?.chatSummaryStatuses, [filter])

    const handleChangeCalendarValue = useCallback(
      value => {
        if (value && value.startDate) {
          setCondition({
            ...condition,
            [from]: format(value.startDate, 'yyyy-MM-dd'),
            [to]: format(value.endDate, 'yyyy-MM-dd')
          })
          onSearch({
            ...condition,
            [from]: format(value.startDate, 'yyyy-MM-dd'),
            [to]: format(value.endDate, 'yyyy-MM-dd')
          })
        } else {
          const newCondition: GetRequest = { ...condition }
          delete newCondition[to as keyof GetRequest]
          delete newCondition[from as keyof GetRequest]
          setCondition(newCondition)
          onSearch(newCondition)
        }
      },
      [condition, onSearch, from, to, setCondition]
    )

    const ids = user as 'userIds' | 'sendUserIds'
    const handleChange = useCallback(
      (key: 'documentIds' | 'siteIds' | typeof ids) => (values: string[]) => {
        if (values.length == 0) {
          const newCondition = { ...condition }

          delete newCondition[key]

          setCondition(newCondition)
          onSearch(newCondition)
        } else {
          setCondition({ ...condition, [key]: values })
          onSearch({ ...condition, [key]: values })
        }
      },
      [condition, onSearch, setCondition]
    )

    const handleStatusChange = useCallback(
      key => () => {
        const newCondition = { ...condition }
        newCondition.repliedStatuses ||= []
        const foundIndex = newCondition.repliedStatuses.findIndex(id => id === key)
        if (foundIndex != -1) {
          newCondition.repliedStatuses.splice(foundIndex, 1)
        } else if (foundIndex == -1) {
          newCondition.repliedStatuses.push(key)
        }

        if (newCondition.repliedStatuses.length == 0) {
          delete newCondition.repliedStatuses
        }
        setCondition(newCondition)
        onSearch(newCondition)
      },
      [condition, onSearch, setCondition]
    )

    const handleSubmit = useCallback(() => {
      let selectedCondition = undefined

      const fields: {
        field1: 'documentIds' | 'siteIds' | typeof ids
        field2: 'documentIds' | 'siteIds' | typeof ids
        data: SelectorItemObject | undefined
      }[] = [
        { field1: ids, field2: ids, data: list },
        { field1: 'documentIds', field2: 'documentIds', data: documentList },
        { field1: 'siteIds', field2: 'siteIds', data: sitesList }
      ]
      for (const f of fields) {
        const { field1, field2, data } = f
        if (data && condition?.[field2]) {
          const texts = ((condition as Required<GetRequest>)[field2] as string[]).map(id => {
            const item = (data as Required<SelectorItemObject>).selectorItems.find(item => item.id === id)
            return item?.text || ''
          })
          selectedCondition ||= {} as SelectedCondition
          selectedCondition[field1] = texts
        }
      }
      if (condition?.repliedStatuses) {
        selectedCondition ||= {} as SelectedCondition
        selectedCondition.repliedStatuses = condition.repliedStatuses
      }

      if (condition?.[from] || condition?.[to]) {
        selectedCondition ||= {} as SelectedCondition
        selectedCondition[from] = condition[from]
        selectedCondition[to] = condition[to]
      }
      onSetSelectedCondition(selectedCondition)
      setLastCondition(condition)
      onClose()
      setKeyword('')
    }, [condition, onClose, documentList, sitesList, onSetSelectedCondition, from, to, setLastCondition, ids, list])

    const clear = () => {
      setCondition(undefined)
      setLastCondition(undefined)
      DateRange.setDateRange({
        ...DateRange.dateRange,
        startDate: undefined,
        endDate: undefined
      })
      setKeyword('')
    }

    const handleClose = useCallback(() => {
      if (lastCondition) {
        onSearch(lastCondition)
      }
      onClose()
      // restore the condition to the last state before opening the modal
      setCondition(lastCondition)
      DateRange.setDateRange({
        ...DateRange.dateRange,
        startDate: lastCondition?.[from] ? new Date(lastCondition[from] as string) : undefined,
        endDate: lastCondition?.[to] ? new Date(lastCondition[to] as string) : undefined
      })
    }, [onClose, lastCondition, setCondition, DateRange, from, to, onSearch])

    const optionItems = useMemo<OptionData[]>(() => {
      if (!userList?.selectorItems) return []
      return userList.selectorItems.map(item => ({
        value: `${item.id}`,
        label: `${item.text}`,
        startElement: (
          <Box width="20px" height="20px" mr="4px" position="relative">
            {item.extend?.avatar?.url ? (
              <Image src={item.extend.avatar.url} className={classes.img} layout="fill" objectFit="cover" />
            ) : (
              <SvgIcon variant="avatar" size="20px" color={Colors.base.middleGray} />
            )}
          </Box>
        )
      }))
    }, [userList, classes.img])
    const optionDocumentItems = useMemo<OptionData[]>(() => {
      if (!documentList?.selectorItems) return []
      return documentList.selectorItems.map(item => ({ value: `${item.id}`, label: `${item.text}` })) ?? []
    }, [documentList])
    const optionSiteItems = useMemo<OptionData[]>(() => {
      if (!sitesList?.selectorItems) return []
      return sitesList.selectorItems.map(item => ({ value: `${item.id}`, label: `${item.text}` })) ?? []
    }, [sitesList])

    useImperativeHandle(ref, () => ({
      clear: () => clear()
    }))

    return (
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <Slide direction="left" in={open}>
          <Box width="376px" height={1}>
            <SlideForm
              headerTitle="詳細な絞り込み"
              onClose={handleClose}
              onCancel={handleClose}
              onConfirm={handleSubmit}
              hideSettings
              buttonTitle="絞り込み"
            >
              <Box height={1} overflow="auto">
                <Box px="24px" pt="24px" pb="70px">
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5">該当結果</Typography>
                    <Typography variant="h5">{filteredCount ?? 0}件</Typography>
                  </Box>
                  <Box width="100%" height="1px" bgcolor={Colors.background.gray} mt="16px" mb="18px" />
                  <Box>
                    <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                      担当者
                    </Typography>
                    <Box mt="8px" />
                    <SelectChip
                      optionList={optionItems}
                      value={condition?.[ids] || []}
                      onChange={handleChange(ids)}
                      onChangeKeyword={setKeyword}
                      startIcon={<DynamicMuiIcon variant="person" />}
                    />
                  </Box>
                  <Box mt="20px" />
                  <Box>
                    <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                      期間
                    </Typography>
                    <Box mt="8px" />
                    <CalenderInput value={DateRange} onChange={handleChangeCalendarValue} />
                  </Box>
                  <Box mt="20px" />
                  <Box>
                    <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                      資料
                    </Typography>
                    <Box mt="8px" />
                    <SelectChip
                      optionList={optionDocumentItems}
                      value={condition?.documentIds || []}
                      onChange={handleChange('documentIds')}
                      onChangeKeyword={setKeyword}
                      startIcon={<DynamicMuiIcon variant="search" />}
                    />
                  </Box>
                  <Box mt="20px" />
                  <Box>
                    <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                      資料サイト
                    </Typography>
                    <Box mt="8px" />
                    <SelectChip
                      optionList={optionSiteItems}
                      value={condition?.siteIds || []}
                      onChange={handleChange('siteIds')}
                      onChangeKeyword={setKeyword}
                      startIcon={<DynamicMuiIcon variant="search" />}
                    />
                  </Box>
                  <Box mt="20px" />
                  {tabValue === 'incoming' && (
                    <Box>
                      <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                        ステータス
                      </Typography>
                      <Box mt="6px">
                        {statusList?.map((list, i) => {
                          return (
                            <Box key={i} display="flex" alignItems="center" ml="-6px">
                              <Checkbox
                                checked={
                                  !!condition?.repliedStatuses?.includes(list.key as EnumChatSummaryRepliedStatus)
                                }
                                onChange={handleStatusChange(list.key)}
                                name={list.key}
                              />
                              <ButtonBase onClick={handleStatusChange(list.key)}>
                                <Typography fontSize="s" lineheight="14px">
                                  {list.text}
                                </Typography>
                              </ButtonBase>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </SlideForm>
          </Box>
        </Slide>
      </Modal>
    )
  }
)
