import React, { useMemo, useCallback, useState } from 'react'
import { Box, createStyles, makeStyles, Modal, Slide } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Typography, SelectBox } from 'src/components/atoms'
import { OptionData } from 'src/components/molecules'
import { SelectChip, SlideForm } from 'src/components/organisms'
import { Props } from 'src/components/atoms/DateRangePicker'
import { CalenderInput } from './CalenderInput'
import { variableList } from 'src/components/organisms/FilteringMenu/ShowAllList'
import {
  useListSelectorUsersList,
  useListSelectorDocuments,
  useListSelectorSites
} from 'src/fixtures/modules/selector/hooks'

export interface ModalContactCompanyFilterProps {
  open: boolean
  onClose: () => void
  onCancel: (type?: string) => void
  onConfirm: () => void
  list: variableList | undefined
  isContactFilter: boolean
  filteringCount: number | undefined
  listValue: string
  staffListValue: string[]
  dateRange: Props
  documentListValue?: string[]
  siteListValue?: string[]
  onChangeListValue: (event: React.ChangeEvent<{ value: unknown }>) => void
  onChangeStaffListValue: (value: string[]) => void
  onChangeDateRange: (value: Props['dateRange'] | []) => void
  onChangeDocumentListValue: (value: string[]) => void
  onChangeSiteListValue: (value: string[]) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: Colors.background.lightGray,
      boxShadow: 'none'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right'
    },
    selectChip: {
      flexWrap: 'wrap'
    }
  })
)

export const ModalContactCompanyFilter = ({
  open,
  onClose,
  onCancel,
  onConfirm,
  list,
  isContactFilter,
  filteringCount,
  listValue,
  staffListValue,
  dateRange,
  documentListValue,
  siteListValue,
  onChangeListValue,
  onChangeStaffListValue,
  onChangeDateRange,
  onChangeDocumentListValue,
  onChangeSiteListValue
}: ModalContactCompanyFilterProps) => {
  const classes = useStyles()
  const [keyword, setKeyword] = useState('')

  const { data: userList } = useListSelectorUsersList({ text: keyword })
  const { data: documentsList } = useListSelectorDocuments({ text: keyword })
  const { data: sitesList } = useListSelectorSites({ text: keyword })

  const handleModalCancel = useCallback(() => {
    onCancel('cancel')
  }, [onCancel])

  const optionListItems = useMemo<OptionData[]>(() => {
    return list?.map(item => ({ value: `${item.id}`, label: `${item.text}` })) ?? []
  }, [list])
  const optionStaffItems = useMemo<OptionData[]>(() => {
    if (!userList?.selectorItems) return []
    return userList.selectorItems.map(item => ({ value: `${item.id}`, label: `${item.text}` }))
  }, [userList])
  const optionDocumentItems = useMemo<OptionData[]>(() => {
    if (!documentsList?.selectorItems) return []
    return documentsList.selectorItems.map(item => ({ value: `${item.id}`, label: `${item.text}` })) ?? []
  }, [documentsList])
  const optionSiteItems = useMemo<OptionData[]>(() => {
    if (!sitesList?.selectorItems) return []
    return sitesList.selectorItems.map(item => ({ value: `${item.id}`, label: `${item.text}` })) ?? []
  }, [sitesList])

  return (
    <Modal id="modal" open={open} onClose={onClose} className={classes.modal}>
      <Slide direction="left" in={open}>
        <Box width="376px" height={1}>
          <SlideForm
            headerTitle="詳細な絞り込み"
            onClose={onClose}
            onCancel={handleModalCancel}
            onConfirm={onConfirm}
            hideSettings
            buttonTitle="絞り込み"
          >
            <Box height={1} overflow="auto">
              <Box p="24px">
                <Box mb="8px" display="flex" justifyContent="space-between">
                  <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                    該当結果
                  </Typography>
                  <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                    {filteringCount}
                  </Typography>
                </Box>
                <Box width="100%" height="1px" bgcolor={Colors.background.gray} />
                <Box mt="20px">
                  <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                    リスト
                  </Typography>
                  <Box mt="8px" />
                  <SelectBox
                    placeholder="すべて"
                    selectablePlaceholder
                    value={listValue}
                    optionList={optionListItems}
                    onChange={onChangeListValue}
                    size="medium"
                  />
                </Box>
                <Box mt="20px">
                  <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                    担当者
                  </Typography>
                  <Box mt="8px" />
                  <SelectChip
                    optionList={optionStaffItems}
                    value={staffListValue}
                    onChange={onChangeStaffListValue}
                    onChangeKeyword={setKeyword}
                    startIcon={<DynamicMuiIcon variant="person" />}
                  />
                </Box>
                <Box mt="20px">
                  <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                    登録日
                  </Typography>
                  <Box mt="8px" />
                  <CalenderInput value={dateRange} onChange={onChangeDateRange} />
                </Box>
                {isContactFilter && (
                  <>
                    <Box mt="20px">
                      <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                        コンテンツ
                      </Typography>
                      <Box mt="8px" />
                      <SelectChip
                        optionList={optionDocumentItems}
                        value={documentListValue ? documentListValue : []}
                        onChange={onChangeDocumentListValue}
                        onChangeKeyword={setKeyword}
                        startIcon={<DynamicMuiIcon variant="search" />}
                      />
                    </Box>
                    <Box mt="20px">
                      <Typography fontSize="s" fontWeight="bold" lineheight="14px">
                        チャネル
                      </Typography>
                      <Box mt="8px" />
                      <SelectChip
                        optionList={optionSiteItems}
                        value={siteListValue ? siteListValue : []}
                        onChange={onChangeSiteListValue}
                        onChangeKeyword={setKeyword}
                        startIcon={<DynamicMuiIcon variant="search" />}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </SlideForm>
        </Box>
      </Slide>
    </Modal>
  )
}
