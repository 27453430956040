import { Box } from '@material-ui/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Card, Checkbox, Colors, Input, Label, Message, SvgIcon, Typography } from 'src/components/atoms'
import { useGetOrganization } from 'src/fixtures/modules/organization/hooks'
import {
  useCancelPayment,
  useGetOrganizationPaymentBillingInfo,
  useGetOrganizationPaymentCurrentPlan
} from 'src/fixtures/modules/organizationPayment/hooks'
import { BackButton } from '../../BackButton'
import { ContentRow } from '../../ContentRow'
import { center } from '../../styles'
import { StepperBaseProps } from '../../types'
import { optionList } from '../../utils'

export interface StepConfirmationProps extends StepperBaseProps {
  selectedList: string[]
  otherReason?: string
}

export const StepConfirmation = ({ onNext, onBack, selectedList = [], otherReason }: StepConfirmationProps) => {
  const [password, setPassword] = useState('')
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data } = useGetOrganization()
  const { data: planRes } = useGetOrganizationPaymentCurrentPlan()
  const { data: dataBillingInfo } = useGetOrganizationPaymentBillingInfo()
  const { handleCancelPayment, isLoading, error } = useCancelPayment()
  const org = data?.organization
  const plan = planRes?.plan
  const contractPaidInfo = dataBillingInfo?.contractPaidInfo

  const selectedLabels = useMemo(() => {
    return optionList.filter(item => selectedList.some(v => item.value === v)).map(item => item.value)
  }, [selectedList])

  const isValid = useMemo(() => {
    return selectedList.length > 0 && isConfirmed && password
  }, [selectedList.length, isConfirmed, password])

  const handleSubmit = useCallback(() => {
    if (selectedList.length > 0 && isConfirmed) {
      handleCancelPayment({ consent: isConfirmed, kindList: selectedList, password, reason: otherReason! })
      setIsSubmitting(true)
    }
  }, [handleCancelPayment, isConfirmed, otherReason, password, selectedList])

  useEffect(() => {
    if (isSubmitting && !isLoading && !error) {
      onNext?.()
    }
  }, [error, isLoading, isSubmitting, onNext])

  return (
    <Box>
      <Card sx={{ bgcolor: '#F8FAFD', pt: '30px' }}>
        {/* 契約内容表示 */}
        <Box sx={{ mb: '54px' }}>
          <Typography fontSize="s" fontWeight="bold">
            ご契約内容
          </Typography>
          <Box height="10px" />
          <ContentRow label="ご利用中のプラン" value={plan ? plan.name : '-'} />
        </Box>

        {/* 解約理由 */}
        <Box sx={{ pb: '25px' }}>
          <Box mb="16px">
            <Typography fontSize="s" fontWeight="bold">
              ご解約の理由を教えてください（複数選択）
            </Typography>
          </Box>
          {selectedLabels && selectedLabels.length > 0 && (
            <Box mb="16px">
              {selectedLabels.map((label, index) => {
                return (
                  <Box
                    key={index}
                    sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #E9ECF1', py: '7px' }}
                  >
                    <SvgIcon variant="checkedIcon" color={Colors.accent.keyPurple.default} size={17} />
                    <Box width="12px" />
                    <Typography fontSize="s" lh="tight">
                      {label}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          )}
          {otherReason && (
            <Box>
              <Typography fontSize="s">その他</Typography>
              <Box height="12px" />
              <Typography fontSize="s" lh="tight">
                {otherReason}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
      <Box height="24px" />

      {/* チーム内容 */}
      <Card sx={{ pt: '30px' }}>
        <Typography fontSize="s" fontWeight="bold">
          ご解約するチームの確認
        </Typography>
        <Box height="22px" />

        <ContentRow label="削除するチーム名" value={org?.name || ''} />
        <ContentRow label="削除するチームID" value={org?.id || ''} />

        <Box sx={{ mt: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontSize="s" fontWeight="bold">
              ログインパスワードを入力
            </Typography>
            <Box width="6px" />
            <Label type="negative" labelText="必須" size="small" />
          </Box>
          <Box sx={{ mt: '8px' }}>
            <Input
              name="password"
              type="password"
              width="292px"
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
            />
          </Box>
        </Box>
      </Card>

      <Box height="24px" />

      {/* 注意事項 */}
      <Card sx={{ pt: '30px' }}>
        <Typography fontSize="s" fontWeight="bold">
          注意事項
        </Typography>
        <Box height="22px" />
        <Message type="warn">
          <Typography fontWeight="bold" fontSize="s" style={{ whiteSpace: 'pre-wrap' }}>
            {`ご解約手続きを完了することで、サービスのご解約となります\nご契約の最終日までご利用いただけますが、下記の点にご留意ください\n\n・ご契約終了後、ご利用していたアカウント及びデータの復元はできません\n・ご契約終了日から翌々月に、すべてのデータを完全削除いたします`}
          </Typography>
        </Message>
        <Box height="16px" />
        {contractPaidInfo?.billingEmail && (
          <Message type="warn">
            <Typography fontWeight="bold" fontSize="s" style={{ whiteSpace: 'pre-wrap' }}>
              {`ご契約終了後、領収書一覧にアクセスできませんのでご注意ください\nご契約終了後の領収書はメールアドレス（${contractPaidInfo?.billingEmail}）に送付されるリンクからご覧ください`}
            </Typography>
          </Message>
        )}
      </Card>

      <Box sx={{ ...center, mt: '24px' }}>
        <Box
          onClick={() => setIsConfirmed(v => !v)}
          sx={{ display: 'flex', alignItems: 'center' }}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox checked={isConfirmed} />
          <Typography fontSize="s">上記内容を確認しました</Typography>
        </Box>
      </Box>

      <Box sx={{ ...center, mt: '24px', mb: '31px' }}>
        <Button onClick={handleSubmit} title={'月間契約の自動更新を解除する'} loading={isLoading} disabled={!isValid} />
      </Box>

      <Box sx={{ ...center, mt: '24px', mb: '31px' }}>
        <BackButton onClick={() => onBack?.()} />
      </Box>
    </Box>
  )
}
