/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  ChatMessageAttachmentIdType,
  ChatMessageAttachmentIdTypeFromJSON,
  ChatMessageAttachmentIdTypeFromJSONTyped,
  ChatMessageAttachmentIdTypeToJSON
} from './ChatMessageAttachmentIdType'

/**
 *
 * @export
 * @interface CreateContactChatMessageRequestAttachableObjectsInner
 */
export interface CreateContactChatMessageRequestAttachableObjectsInner {
  /**
   *
   * @type {ChatMessageAttachmentIdType}
   * @memberof CreateContactChatMessageRequestAttachableObjectsInner
   */
  type?: ChatMessageAttachmentIdType
  /**
   *
   * @type {string}
   * @memberof CreateContactChatMessageRequestAttachableObjectsInner
   */
  id?: string
  /**
   *
   * @type {number}
   * @memberof CreateContactChatMessageRequestAttachableObjectsInner
   */
  sortNumber?: number
}

export function CreateContactChatMessageRequestAttachableObjectsInnerFromJSON(
  json: any
): CreateContactChatMessageRequestAttachableObjectsInner {
  return CreateContactChatMessageRequestAttachableObjectsInnerFromJSONTyped(json, false)
}

export function CreateContactChatMessageRequestAttachableObjectsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateContactChatMessageRequestAttachableObjectsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: !exists(json, 'type') ? undefined : ChatMessageAttachmentIdTypeFromJSON(json['type']),
    id: !exists(json, 'id') ? undefined : json['id'],
    sortNumber: !exists(json, 'sort_number') ? undefined : json['sort_number']
  }
}

export function CreateContactChatMessageRequestAttachableObjectsInnerToJSON(
  value?: CreateContactChatMessageRequestAttachableObjectsInner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: ChatMessageAttachmentIdTypeToJSON(value.type),
    id: value.id,
    sort_number: value.sortNumber
  }
}
