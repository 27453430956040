import React from 'react'
import { Table as MuiTable } from '@devexpress/dx-react-grid-material-ui'
import { Box } from '@material-ui/core'
import { TableSelection } from '@devexpress/dx-react-grid'
import { Checkbox } from 'src/components/atoms'

export const SelectionHeaderCell = (props: TableSelection.HeaderCellProps) => {
  const { disabled, allSelected, someSelected, onToggle, ...otherProps } = props
  return (
    <MuiTable.Cell {...(otherProps as any)}>
      {someSelected ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Checkbox
            size="small"
            checked
            onClick={e => {
              e.preventDefault()
              onToggle(true)
            }}
          />
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Checkbox
            size="small"
            checked={allSelected}
            disabled={disabled}
            onClick={e => {
              e.preventDefault()
              onToggle(!allSelected)
            }}
          />
        </Box>
      )}
    </MuiTable.Cell>
  )
}
