/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { DocumentSiteFromJSON, DocumentSiteToJSON } from './DocumentSite';
import { PageInfoFromJSON, PageInfoToJSON } from './PageInfo';
export function GetDocumentSitesResponseDataFromJSON(json) {
    return GetDocumentSitesResponseDataFromJSONTyped(json, false);
}
export function GetDocumentSitesResponseDataFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        documentSites: !exists(json, 'document_sites')
            ? undefined
            : json['document_sites'].map(DocumentSiteFromJSON),
        pageInfo: !exists(json, 'page_info') ? undefined : PageInfoFromJSON(json['page_info'])
    };
}
export function GetDocumentSitesResponseDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        document_sites: value.documentSites === undefined ? undefined : value.documentSites.map(DocumentSiteToJSON),
        page_info: PageInfoToJSON(value.pageInfo)
    };
}
