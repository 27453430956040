/* tslint:disable */
/* eslint-disable */
/**
 * noco-server
 * noco-server
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { CompanySettingFieldItemFromJSON, CompanySettingFieldItemToJSON } from './CompanySettingFieldItem';
export function CompanySettingFieldValueFromJSON(json) {
    return CompanySettingFieldValueFromJSONTyped(json, false);
}
export function CompanySettingFieldValueFromJSONTyped(json, ignoreDiscriminator) {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        companySettingFieldItem: !exists(json, 'company_setting_field_item')
            ? undefined
            : CompanySettingFieldItemFromJSON(json['company_setting_field_item']),
        value: !exists(json, 'value') ? undefined : json['value'],
        createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
        updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at']
    };
}
export function CompanySettingFieldValueToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        company_setting_field_item: CompanySettingFieldItemToJSON(value.companySettingFieldItem),
        value: value.value,
        created_at: value.createdAt,
        updated_at: value.updatedAt
    };
}
