import { Job } from '@noco/http-client/lib/noco'

export const donwloadFileFromJob = (job: Job) => {
  if (!job.files) return
  const jobFile = job.files[0]
  if (!jobFile.file) return
  downloadFromURL(jobFile.file.url!, jobFile.name!)
}

export const downloadFromURL = (url: string, name: string) => {
  const linkElm: HTMLAnchorElement = document.createElement('a')
  linkElm.href = url
  linkElm.download = name
  linkElm.click()
}

export const downloadFileFromURL = (url: string, name: string) => {
  fetch(url).then(response => {
    response.blob().then(blob => {
      const url = window.URL.createObjectURL(blob)
      const linkElm: HTMLAnchorElement = document.createElement('a')
      linkElm.href = url
      linkElm.download = name
      linkElm.click()
    })
  })
}
