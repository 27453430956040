import { Box } from '@material-ui/core'
import { Organization, Site } from '@noco/http-client/lib/noco'
import React, { useCallback, useMemo, useState } from 'react'
import { Colors, DynamicMuiIcon, Typography } from 'src/components/atoms'
import { ModalUpgrade } from 'src/components/modals'
import { PageSiteMode } from 'src/components/organisms/SiteDetail'
import { dateFormat, DateFormat, dateStringToMilliseconds } from 'src/fixtures/utils/time'
import { useGlobalStyles } from 'src/styles/theme'

export interface BannerProps {
  site: Site
  mode: PageSiteMode
  isPC?: boolean
  organization?: Organization
  contactExpiredOn?: string | null
}

export const Banner = ({ site, isPC = true, mode, organization, contactExpiredOn }: BannerProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const globalClasses = useGlobalStyles()
  const plan = organization?.plan
  const handleClickBanner = useCallback(() => {
    if (mode === 'setting' && !plan?.canHideAd) {
      setOpen(true)
    } else {
      window.open('https://noco.sale/')
    }
  }, [mode, plan?.canHideAd])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const expiredOn = useMemo(() => {
    return mode === 'eu' ? contactExpiredOn : site?.siteShare?.expiredOn
  }, [contactExpiredOn, mode, site?.siteShare?.expiredOn])

  const expiredOnText = useMemo(() => {
    return expiredOn
      ? `閲覧期限 ${dateFormat(dateStringToMilliseconds(expiredOn), DateFormat.DATE_WITH_DAY)}まで`
      : undefined
  }, [expiredOn])

  const showExpiredOn = useMemo(() => expiredOn && mode !== 'setting', [expiredOn, mode])

  // NOTE: ロゴも閲覧期限も非表示の時は、バナーごと非表示 @locco
  const hideBanner = useMemo(
    () => organization?.hideAd && (!site.isHeaderCustom || !organization.avatar?.url) && !showExpiredOn,
    [organization, site, showExpiredOn]
  )

  return hideBanner ? (
    <></>
  ) : (
    <>
      <ModalUpgrade open={open} onClose={handleClose} />
      <Box position="sticky" zIndex={999} top={0} width={1}>
        <Box
          display="flex"
          flexDirection={isPC ? 'row' : 'column'}
          alignItems="center"
          justifyContent={isPC ? 'space-between' : 'center'}
          width={1}
          bgcolor={Colors.functional.background.default}
          px="24px"
          py={isPC ? '18px' : '12px'}
          borderBottom={`1px solid ${Colors.background.gray}`}
        >
          {isPC && <Box width={showExpiredOn ? '240px' : '0px'} />}

          {organization?.hideAd ? (
            site.isHeaderCustom &&
            organization.avatar?.url && (
              <Box height={isPC ? '45px' : '42px'} maxWidth="210px">
                <img src={organization.avatar.url!} alt="カスタムロゴ" className={globalClasses.imgContain} />
              </Box>
            )
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={!isPC && showExpiredOn ? '12px' : ''}
              onClick={handleClickBanner}
              className={globalClasses.cursorPointer}
            >
              <img
                src="/images/nocosell_logo.svg"
                alt="ロゴ"
                width={isPC ? '104px' : '70px'}
                height={isPC ? '28px' : '20px'}
                style={{ objectFit: 'contain' }}
              />
              <Box width="16px" />
              <Box
                py="6px"
                px="16px"
                borderLeft={`1px solid ${Colors.background.silver}`}
                color={Colors.base.middleGray}
              >
                <Typography
                  variant="h4"
                  fontSize={isPC ? 'm' : 's'}
                  fontWeight="bold"
                  letterSpacing="default"
                  lineheight={isPC ? '24px' : '20.16px'}
                >
                  クラウドで顧客獲得はじめよう
                </Typography>
              </Box>
            </Box>
          )}

          {showExpiredOn && (
            <Box display="flex" justifyContent="center" alignItems="center" color={Colors.base.middleGray}>
              <DynamicMuiIcon variant="meeting" size={20} />
              <Box mr="6px" />
              <Typography fontWeight="bold" fontSize="s">
                {expiredOnText}
              </Typography>
            </Box>
          )}
          {isPC && !showExpiredOn && <Box />}
        </Box>
      </Box>
    </>
  )
}
