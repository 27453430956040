import React, { ReactElement, useCallback, useState } from 'react'
import { Box, ButtonBase, createStyles, makeStyles, Popover as MuiPopover } from '@material-ui/core'
import { Colors, DynamicMuiIcon, Input, Typography } from 'src/components/atoms'
import { useGlobalStyles } from 'src/styles/theme'
import clsx from 'clsx'

export interface PopoverProps {
  id?: string
  open: boolean
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  onChangeKeyword?: (keyword: string) => void
  onSelect?: (value: OptionData) => void
  options: OptionData[]
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonBase: {
      width: '100%',
      textAlign: 'left'
    },
    hoverButton: {
      '&:hover': {
        backgroundColor: Colors.hover.white.default
      }
    },
    popover: {
      width: '100%',
      border: `1px solid ${Colors.accent.keyPurple.default}`,
      borderRadius: '4px',
      boxShadow: 'none'
    }
  })
})

export interface OptionData {
  label: string
  value: string
  rightSection?: ReactElement
  startElement?: ReactElement
}

const OPTION_HEIGHT = 34

export const Popover = ({ id, open, anchorEl, onClose, onChangeKeyword, onSelect, options }: PopoverProps) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const handleChange = useCallback(
    (value: string) => {
      setInputValue(value)
      onChangeKeyword?.(value)
    },
    [onChangeKeyword]
  )
  const globalClasses = useGlobalStyles()

  const handleClose = useCallback(() => {
    setInputValue('')
    onClose()
  }, [onClose])

  const handlePopOverClose = useCallback(() => {
    setInputValue('')
    onChangeKeyword?.('')
  }, [onChangeKeyword])

  const handleSelect = useCallback(
    (value: OptionData) => {
      setInputValue('')
      onSelect?.(value)
    },
    [onSelect]
  )

  const getPosition = useCallback(() => {
    if (!anchorEl) return
    const rect = anchorEl.getBoundingClientRect()
    return {
      top: rect.top + rect.height,
      left: rect.left
    }
  }, [anchorEl])

  return (
    <MuiPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      anchorReference="anchorPosition"
      anchorPosition={getPosition()}
      marginThreshold={0}
      PaperProps={{
        className: classes.popover,
        style: { width: (anchorEl && anchorEl.offsetWidth - 2) || 0 }
      }}
    >
      <Box pt="12px" pb="16px">
        <Box px="12px" mb="10px">
          <Input
            onChange={e => handleChange(e.currentTarget.value as string)}
            name="companyName"
            width="100%"
            value={inputValue}
            startAdornment={
              <Box
                pl="14px"
                color={Colors.base.middleGray}
                fontSize="18px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <DynamicMuiIcon variant="search" color="inherit" fontSize="inherit" />
              </Box>
            }
            endAdornment={
              inputValue ? (
                <Box
                  p="10px"
                  color={Colors.base.middleGray}
                  fontSize="16px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={handlePopOverClose}
                  className={globalClasses.cursorPointer}
                >
                  <DynamicMuiIcon variant="close" color="inherit" fontSize="inherit" />
                </Box>
              ) : undefined
            }
          />
        </Box>
        <Box overflow="auto" maxHeight={OPTION_HEIGHT * 7}>
          {options.map(option => {
            return (
              <ButtonBase
                onClick={() => handleSelect(option)}
                key={option.value}
                className={clsx(classes.buttonBase, classes.hoverButton)}
              >
                <Box
                  px="20px"
                  height={`${OPTION_HEIGHT}px`}
                  width={1}
                  display="flex"
                  justifyContent={option.rightSection ? 'space-between' : 'unset'}
                  alignItems="center"
                >
                  {option.startElement}
                  <Typography fontSize="s" lh="default" noWrap>
                    {option.label}
                  </Typography>
                  {option.rightSection}
                </Box>
              </ButtonBase>
            )
          })}
          {options.length === 0 && (
            <Box
              px="20px"
              height={`${OPTION_HEIGHT}px`}
              width={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize="s" lh="none" noWrap>
                選択できる項目がありません
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </MuiPopover>
  )
}
